/**
 * Component OBTMultiDropDownList
 * Luna - Orbit 개발시 템플릿 으로 사용.
 * @version 0.1
 * @author 전주빈
 * @see UFOMultiSelectField
 */
import * as React from 'react';
import { Events, CommonProps, CompositeProps, Util, Functions, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import UFOMultiSelectField from '../UFO/UFOMultiSelectField';
import OBTTooltip, { IOBTTooltip } from '../OBTTooltip/OBTTooltip';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import GuideMessageRenderer from '../Common/GuideMessageRenderer';
import { OBTContext } from '../OBTPageContainer/OBTPageContainer';
import { removeTrashElement } from '../Common/OrbitInternalUtil';

const styles = require('./OBTMultiDropDownList.module.scss');

/**
 * 컴포넌트에서 보여주는 형식 속성입니다.
 */
export enum DisplayType {
    /**
     * numbertext : 숫자. 텍스트
     * text : 텍스트
     * valueText : 값. 텍스트 (default)
     */
    'numbertext' = 'numbertext',
    'text' = 'text',
    'valueText' = 'valueText'
}

/**
 * 컴포넌트 입력필드에서 보여지는 value값의 리스트를 정의하는 속성입니다.
 */
interface TypeList {
    /**
     * value : 값
     */
    value: string,
    /**
     * labelText : 텍스트
     */
    labelText: string,
    /**
     * labelText : 텍스트
     */
    labelSubText?: string,
    /**
     * imageUrl : 이미지
     */
    imageUrl?: string
}

/**
 * style, disabled, readonly, required, value, placeHolder, type, onFocus, onBlur, onChange, onMoveFocus
 */
interface IOBTMultiDropDownList extends CompositeProps.InputClass<Array<string>>, CommonProps.useSubLang, Events.onValidate<Array<string>>, Events.onMouseDown,
    CommonProps.useSubLang, Events.onMouseMove, Events.onMouseUp, Events.onMouseLeave, Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress, Events.onKeyUp {
    /**
     * 컴포넌트 입력필드에서 보여지는 value값의 리스트를 정의하는 속성입니다.
     */
    list?: Array<any>,
    /**
     * 컴포넌트에서 보여주는 형식 속성입니다.
     */
    displayType?: DisplayType,
    /**
     * 틀팁에 대한 설정 속성입니다.
     */
    tooltip?: IOBTTooltip,
    /**
     * 컴포넌트에서 데이터소스를 이용하여 매핑 정의하는 속성입니다.
     */
    fieldName?: TypeList
}

/**
* @internal
* State 정의
*/
interface State extends hasError {
    list: Array<any>,
    displayList: Array<any>,
    typeList: Array<any>,
    useImage: boolean,
    keyPressValue: string
}

/**
* OBTNumberField
* Prop : { style, disabled, readonly, required, value, placeHolder, type, onFocus, onBlur, onChange, onMoveFocus }
*/
export default class OBTMultiDropDownList extends React.Component<IOBTMultiDropDownList, State> implements Functions.IFocusable, Functions.IRequired {

    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.InputClass(["Array<string>"]),
        { name: "list", type: "Array<any>", description: "컴포넌트 입력필드에서 보여지는 value값의 리스트를 정의하는 속성입니다." },
        { name: "displayType", type: toEnumType(DisplayType), description: "컴포넌트에서 데이터소스를 이용하여 매핑 정의하는 속성입니다.", optional: true },
        {
            name: 'fieldName', type: [{
                value: { type: CommonType.string, description: "값" },
                labelText: { type: CommonType.string, description: "텍스트" },
                labelSubText: { type: CommonType.string, description: "서브 텍스트", optional: true }
            }], description: "컴포넌트에서 데이터소스를 이용하여 매핑 정의하는 속성입니다.", optional: true
        },
        CommonDefinitions.useSubLang(),
        CommonDefinitions.InnerFunc(),
        CommonDefinitions.onValidate("Array<string>"),
        CommonDefinitions.Event()
    );

    ///////////////////////////////////////////////////////////////////////////// Initialize
    context!: React.ContextType<typeof OBTContext>;

    /**
     * Default Props 설정
     */
    public static defaultProps = {
        frozen: false,
        disabled: false,
        readonly: false,
        required: false,

    }

    /**
     * State 정의
     */
    public state: State = {
        list: [],
        displayList: [],
        typeList: [],
        useImage: false,
        keyPressValue: ''
    }

    /**
     * Ref 정의
     */
    public myRefs = {
        ref: React.createRef<UFOMultiSelectField>()
    }

    ///////////////////////////////////////////////////////////////////////////// Life Cycle API

    /**
     * 초기값 세팅
     * @param nextProps 
     * list 세팅
     * 1. typeList : { value , labelText } 하나로 맞춤
     * 2. displayList: { text } (UFO)
     * @param prevState 
     */
    static getDerivedStateFromProps(nextProps: IOBTMultiDropDownList, prevState: State): any {
        try {
            if (nextProps.list !== prevState.list) {
                const nextState: State = {
                    ...prevState,
                    list: nextProps.list || []
                }
                // list { value, labelText }
                // 통일 시키기
                let typeList: Array<TypeList> = [];
                typeList = (nextProps.list || []).map((list) => {
                    const fieldName = Object.assign({
                        value: 'value',
                        labelText: 'labelText',
                        labelSubText: 'labelSubText',
                        imageUrl: 'imageUrl'
                    }, nextProps.fieldName || {});
                    return {
                        value: list[fieldName.value],
                        labelText: list[fieldName.labelText] || list.text,
                        labelSubText: list[fieldName.labelSubText] || list.text,
                        imageUrl: list[fieldName.imageUrl]
                    };
                });
                if (typeList.length <= 0) {
                    typeList = [{
                        value: '', labelText: '', labelSubText: ''
                    }];
                }
                /**
                 * display 데이터 만들기 (text)
                 * 1. valueText
                 * 2. numberText
                 * 3. Text
                 */
                let displayList: Array<any> = [];
                let useImage: boolean = false;
                typeList.forEach((fieldData, index) => {
                    let stringData: string = (() => {
                        let displayType: any = nextProps.displayType === undefined ? DisplayType.valueText : nextProps.displayType;
                        //node일경우는 default로 빠져야하므로
                        displayType = typeof (fieldData.labelText) !== 'string' ? undefined : displayType;
                        const value = Util.cl(fieldData.labelText, fieldData.labelSubText, nextProps.useSubLang);
                        if (!value || value.length <= 0) return <span>&nbsp;</span>;
                        switch (displayType) {
                            case DisplayType.valueText:
                                return (fieldData.value ? fieldData.value + '. ' : '') + value;
                            case DisplayType.numbertext:
                                return String(index) + '. ' + value;
                            default:
                                return value;
                        }
                    })()

                    displayList.push(stringData);
                    if (fieldData.imageUrl !== undefined) {  //이미지 있으면 
                        useImage = true;
                    }
                });
                nextState.typeList = typeList;
                nextState.useImage = useImage;
                nextState.displayList = displayList;
                return nextState;
            }
            return null;
        } catch (e) {
            return Util.getErrorState(e);
        }
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        );
    }

    renderComponent = () => {
        const listAutoHeight = this.state.displayList && this.state.displayList.length > 7 ? false : true;
        //계속 변함
        const valueData = this.getSelectData(this.state.typeList, this.props.value);
        const props = {
            // Value
            ref: this.myRefs.ref,
            value: valueData,
            data: this.state.displayList,
            image: this.state.typeList,
            listAutoHeight: listAutoHeight,
            useImage: this.state.useImage,  //이미지사용
            displayType: this.props.displayType === undefined ? DisplayType.valueText : this.props.displayType,
            // Events
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            onMoveFocus: this.handleMoveFocus,
            onChange: this.handleChange,
            onKeyPress: this.handleChange,

            // Etc
            disabled: this.props.disabled,
            readonly: this.props.readonly,
            // Styling
            // style: Util.getWrapperStyle(this.props),
            styleInputSpan: Object.assign(Util.getInputStateStyle(this.props)),
            //popOverStyle: { width: this.props.width }
        };
        return <OBTTooltip {...this.props.tooltip}
            className={Util.getClassNames(styles.obtMultiDropDownList, styles.default, this.props.className)} style={Util.getWrapperStyle(this.props)}
            overrideSize={false}
            onMouseDown={this.props.onMouseDown}
            onMouseMove={this.props.onMouseMove}
            onMouseUp={this.props.onMouseUp}
            onMouseLeave={this.props.onMouseLeave}
            onMouseEnter={this.props.onMouseEnter}
            onKeyUp={this.props.onKeyUp}
            onKeyDown={this.props.onKeyDown}
            onKeyPress={this.props.onKeyPress}
            rootProps={{
                id: this.props.id,
                'data-orbit-component': 'OBTMultiDropDownList'
            }}>
            <UFOMultiSelectField {...props} />
        </OBTTooltip>

    }

    ///////////////////////////////////////////////////////////////////////////// Logics
    /**
     * @internal
     * Luna - Rocket 의 numberView
     * defaultData 기본 데이터 넘겨준다. (데이터를 text만 받아서)
     */
    private getSelectData(fieldData: Array<any>, value: Array<string>): any {
        let defaultData: Array<string> = [];
        if (value === undefined) return defaultData;
        if (value.length > 0) {
            for (let i = 0; i < value.length; i++) {
                fieldData.forEach((fieldData, index) => {
                    if (fieldData.value === value[i]) {
                        const stringData = (() => {
                            let displayType: any = this.props.displayType === undefined ? DisplayType.valueText : this.props.displayType;
                            //node일경우는 default로 빠져야하므로
                            displayType = typeof (fieldData.labelText) !== 'string' ? undefined : displayType;
                            const value = Util.cl(fieldData.labelText, fieldData.labelSubText, this.props.useSubLang);
                            if (!value || value.length <= 0) return <span>&nbsp;</span>;
                            switch (displayType) {
                                case DisplayType.valueText:
                                    return (fieldData.value ? fieldData.value + '. ' : '') + value;
                                case DisplayType.numbertext:
                                    return String(index) + '. ' + value;
                                default:
                                    return value;
                            }
                        })()
                        defaultData.push(stringData);
                    }
                });
            }
        }
        return defaultData
    }

    /**
     * @internal
     * Luna - Rocket 의 numberView
     * UFO에서 배열의 index를 넘기기때문에 value를 찾아야한다.
     */
    private getFindValue(fieldData: Array<any>, index: Array<string>): any {
        let defaultData: Array<string> = [];
        if (index.length > 0) {
            for (let i = 0; i < index.length; i++) {
                defaultData.push(fieldData[index[i]].value);
            }
        }
        return defaultData
    }

    public focus(isLast: boolean = false): void {
        if (this.myRefs.ref.current) {
            this.myRefs.ref.current.focus();
        }
    }

    private get canEdit(): boolean {
        return !this.props.disabled && !this.props.readonly;
    }

    public isEmpty(): boolean {
        return (!this.props.value || this.props.value.toString().length <= 0);
    }

    public validate(): boolean {
        return !(this.props.required === true && this.isEmpty());
    }

    public blur(): void {
        if (this.myRefs.ref.current) {
            this.myRefs.ref.current.blur();
        }
    }

    ///////////////////////////////////////////////////////////////////////////// Event Handlers
    /**
     * @internal
     * @param direction : string
     * 무브 포커스
     */
    private handleMoveFocus = (direction: string) => {
        if (!this.validate() && ['right', 'down', 'enter'].includes(direction)) return;
        Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
    }

    /**
     * @internal
     * 포커스 이벤트
     */
    private handleFocus = e => {
        Util.invokeEvent<Events.EventArgs>(this.props.onFocus, new Events.EventArgs(this));
        GuideMessageRenderer.handleFocus(
            this, this.props.id, this.context
        );
    }

    /**
     * @internal
     * blur 이벤트
     */
    private handleBlur = e => {
        Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
        removeTrashElement();
        GuideMessageRenderer.handleBlur(
            this.context,
            this.props.id,
        );
    }

    /**
     * @internal
     * @param value : Array<string>
     * index를 받기때문에 value를 넘겨주기위한 작업 필요
     */
    private handleChange = (value: Array<string>) => {
        if (this.props.value !== value) {
            let typeList: Array<any> = [];
            typeList = this.state.typeList;
            const oldValue: Array<string> = this.props.value;
            const newValue: Array<string> = this.getFindValue(typeList, value);
            const item: Array<any> = [];
            const oldItem: Array<any> = [];
            typeList.forEach(originList => {
                for (let index = 0; index < oldValue.length; index++) {
                    if (originList.value === oldValue[index]) oldItem.push(originList);
                }
                for (let index = 0; index < newValue.length; index++) {
                    if (originList.value === newValue[index]) item.push(originList);
                }
            });

            if (!Util.invokeEvent<Events.ValidateEventArgs<Array<string>>>(this.props.onValidate, new Events.ValidateEventArgs<Array<string>>(this, oldValue, newValue, oldItem, item))) {
                return;
            }
            if (this.canEdit) {
                Util.invokeEvent<Events.ChangeEventArgs<Array<string>>>(this.props.onChange, new Events.ChangeEventArgs<Array<string>>(this, newValue, item));
                removeTrashElement();
            }
        }
    }
}

OBTMultiDropDownList.contextType = OBTContext;