/**
 * Component OBTChip
 * Luna - Orbit 개발시 템플릿 으로 사용.
 * @version 0.1
 * @author 전주빈
 * @see common.js
 */
import React from 'react';
import LUXButton from 'luna-rocket/LUXButton';
import OBTTooltip, { IOBTTooltip } from '../OBTTooltip/OBTTooltip';
import img from './image/icon-btn-popup-close-normal@2x.png';
import { Events, CompositeProps, Util, CommonProps, createPropDefinitions, CommonDefinitions, CommonType } from '../Common';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

/**
 * CSS Modules 사용방식
 * styles.[className]
 * {@code <div className={styles.required}}
 */
const styles = require('./OBTChip.module.scss');

/**
 * 컴포넌트 Chip 요소 안에 들어가는 형식으로 Mapping 지정합니다.
 */
interface Item {
    /**
     * 키 (string)
     */
    key: string,
    /**
     * 텍스트 (Node)
     */
    labelText: Node,
    /**
     * 툴팁 (IOBTTooltip)
     */
    toolTip?: IOBTTooltip,
    /**
     * 이미지 (string)
     */
    imageUrl?: string,
    /**
     * 아이콘 (string)
     */
    icon?: string,
    /**
     * 사용불가 (boolean)
     */
    disabled?: boolean
}

interface IOBTChip extends CompositeProps.Default, CommonProps.disabled, CommonProps.required, Events.onChange<any> {
    /**
     * 컴포넌트의 기본 데이터 List를 지정합니다.
     */
    list: Array<any>,
    /**
     * 컴포넌트 Chip 요소 안에 들어가는 형식으로 Mapping 지정합니다.
     */
    item: Item,
    labelStyle?: React.CSSProperties
}

interface State extends hasError {
}

/**
 * withApi() HOC 를 사용하면 Props 로 Api 를 사용할 수 있다.
 * api 가 Optional 로 선언되었기에 내부에서 ! 오퍼레이터를 사용해서 호출한다.
 * {@code this.props.api!.test();}
 */
export default class OBTChip extends React.Component<IOBTChip, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        CommonDefinitions.disabled(),
        CommonDefinitions.required(),
        {
            name: 'list',
            type: 'any[]',
            description: '전체 데이터 Array.'
        },
        {
            name: 'item',
            type: {
                key: { type: CommonType.string, description: '유일키' },
                labelText: { type: CommonType.string, description: 'Chip 에 표시될 문자열' },
                toolTip: { type: 'Tooltip Props', optional: true, description: '설정시 툴팁이 표시됨' },
                imageUrl: { type: CommonType.image, optional: true, description: 'labelText 앞에 표시될 이미지 Url' },
                disabled: { type: CommonType.string, optional: true, description: '비활성 상태인 경우 이미지 Url' },
                labelStyle: { type: CommonType.any, optional: true, description: 'labelText 의 스타일 지정' },
                className: { type: CommonType.string, optional: true, description: 'Chip 의 className 지정' }
            },
            description: 'Chip 에 표시될 데이터'
        },
        CommonDefinitions.onChange()
    );

    public static defaultProps = {
        disabled: false,
        required: false
    }

    public myRefs = {
        ref: React.createRef<HTMLDivElement>()
    }

    public state: State = {
    }

    /**
     * @internal
     * Luna - Rocket 의 onMoveFocus 이벤트 핸들러를 받아 재호출
     * @param {string} direction
     */

    private handleChange = (event: any) => {
        if (this.props.disabled || this.props.item.disabled) return;
        Util.invokeEvent<Events.ChangeEventArgs<any>>(this.props.onChange, new Events.ChangeEventArgs<any>(this, this.props.list));
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        );
    }

    renderComponent = () => {
        const buttonElement = (
            <LUXButton
                type="image"
                icon={<img src={img} alt={''}></img>}
                innerStyle={{
                    width: '12px',
                    height: '12px',
                    objectFit: 'contain'
                }}
                style={Object.assign({}, { border: '0px', width: '12px', marginRight: '7px', backgroundColor: 'inherit' })}
                onTouchTap={this.handleChange}
            />
        );

        const chip =
            <div className={Util.getClassNames(styles.chip, this.props.className)}
                style={Util.getWrapperStyle(this.props)}
                id={this.props.id}
                data-orbit-component='OBTChip'>
                <div ref={this.myRefs.ref}
                    key={this.props.item.key}
                    className={Util.getClassNames(styles.default, 'obtchip-root')}
                    style={{
                        ...this.props.required === true ? Util.getInputStateStyle({ ...this.props, disabled: false }) : { backgroundColor: '#ffffff' }
                    }}>
                    {this.props.item.imageUrl !== undefined ? <img className={styles.imageStyle} src={this.props.item.imageUrl} alt={''}></img> : null}
                    <OBTTooltip {...this.props.item.toolTip} overrideSize={false}>
                        <div className={Util.getClassNames(styles.labelStyle, 'obtchip-label')} style={this.props.labelStyle}>{this.props.item.labelText} </div>
                    </OBTTooltip>
                    {!this.props.disabled ? buttonElement : undefined}
                </div>
            </div>
        return (this.props.item.key || this.props.item.labelText) !== undefined ? chip : <></>;
    }
};
