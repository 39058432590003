/**
 * Component Develment Template
 * Luna - Orbit 개발시 템플릿 으로 사용.
 * @version 0.1
 * @author 김철희
 * @see common.js
 */
import * as React from 'react';
import { CompositeProps, Util, CommonProps, createPropDefinitions, CommonDefinitions, CommonType } from '../Common';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { hasError } from '../Common/CommonState';
import { IStateLabel } from './OBTAccordion';
/**
 * CSS Modules 사용방식
 * styles.[className]
 * {@code <div className={styles.required}}
 */
const classNames = require('./OBTAccordionSection.module.scss');

interface IOBTAccordionSection extends CompositeProps.ContainerClass, CommonProps.labelText {
    /**
     * 컴포넌트 요소 안에 들어가는 버튼 요소를 지정합니다.
     */
    button?: any,
    /**
     * 상태를 우측에 표시하는 경우 이 항목을 지정합니다.
     */
    state?: IStateLabel
}

interface State extends hasError {
}

/**
 * withApi() HOC 를 사용하면 Props 로 Api 를 사용할 수 있다.
 * api 가 Optional 로 선언되었기에 내부에서 ! 오퍼레이터를 사용해서 호출한다.
 * {@code this.props.api!.test();}
 */
export default class OBTAccordionSection extends React.Component<IOBTAccordionSection, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.labelText({ description: '아코디언 섹션의 타이틀에 표시되는 문구입니다.' }),
        { name: 'button', type: CommonType.any, optional: true, description: '아코디언 섹션의 타이틀 우측에 버튼을 표시할 수 있습니다.' },
        {
            name: 'state', type: {
                labelText: { type: CommonType.string, description: '상태표시 문구' },
                color: { type: CommonType.color, description: '상태컬러(HEX)' }
            }, optional: true, description: '아코디언 섹션 타이틀 우측에 상태를 표시할 수 있습니다.'
        },
    )

    public state: State = {}

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        );
    }

    renderComponent = () => {
        const button = this.props.button ? this.props.button :
            this.props.state ? <div className={classNames.stateLabel} style={{ backgroundColor: this.props.state.color }}>{this.props.state.labelText}</div> :
                undefined;
        return (
            <div className={Util.getClassNames(classNames.root, this.props.className ? this.props.className : undefined)}>
                <div className={classNames.title}>
                    {this.props.labelText}
                    {button}
                </div>
                <div className={classNames.content}>
                    {this.props.children}
                </div>
            </div>
        )
    }
};
