/**
 *  검색시 팝 오버에 뿌려질 리스트 컨트롤 입니다.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LineGroup from './LineGroup';


class ListElement extends Component {

    // region React Base

    static propTypes = {
        /**
         * 항목삭제 가능여부입니다.
         */
        canDelete: PropTypes.bool,
        /**
         * 검색한 키워드
         */
        keyWord: PropTypes.string.isRequired,
        /**
         * 팝오버의 높이 입니다. 지정하지 않은 경우 높이는 자동으로 계산됩니다.
         */
        maxPopOverHeight: PropTypes.number,
        /**
         * 아이템 삭제 버튼을 클릭하면 발생하는 콜백입니다.
         */
        onDelete: PropTypes.func,
        /**
         * 툴팁의 float 속성입니다.
         */
        toolTipFloat: PropTypes.string,
        /**
         * null(공란) 값을 툴팁에서 skip  할지 정하는 속성입니다.
         */
        toolTipNullSkip: PropTypes.bool,
        /**
         * 툴팁을 무조건 생성할지 여부
         */
        toolTipAlwaysShow: PropTypes.bool,
        /**
         * 툴팁을 생성 여부
         */
        isShowTooltip: PropTypes.bool
    }

    // constructor() {
    //     super();
    // }

    // endregion

    // region LineItem

    /**
     * 팝오버의 리스트를 만든다.
     */
    createLineGroups = () => {
        let lineGroups = [];
        let data = this.props.data;

        if (this.props.data.length > 0) {
            // 데이터 하나 마다 하나의 라인을 만든다.
            data.forEach((group, index) => {
                lineGroups.push(
                    <LineGroup
                        toolTipAlwaysShow={this.props.toolTipAlwaysShow}
                        toolTipNullSkip={this.props.toolTipNullSkip}
                        onDelete={this.props.onDelete}
                        canDelete={this.props.canDelete}
                        key={index}
                        index={index}
                        data={group}
                        dataInfo={this.props.dataInfo}
                        keyWord={this.props.keyWord}
                        focusedInfo={this.props.focusedInfo}
                        onSelect={this.handleSelect}
                        stylePopoverATag={this.props.stylePopoverATag}
                        type={this.props.type}
                        maxPopOverHeight={this.props.maxPopOverHeight}
                        toolTipFloat={this.props.toolTipFloat}
                        isShowTooltip={this.props.isShowTooltip}
                    />);
            })
        }

        return lineGroups;
    }

    /**
     * 마우스로 아이템을 클릭해서 선택하면 호출된다.
     */
    handleSelect = (event, data, dataType) => {
        if (this.props.onSelect) {
            this.props.onSelect(event, data, dataType);
        }
    }

    handleMouseDown = (event) => {
        event.stopPropagation();
        event.preventDefault();
    }
    // endregion

    // region render

    render() {
        // 타입에 따라서 리스트 엘리멘트를 생성한다.
        let lineGroups = this.createLineGroups();

        return (
            <div style={{ border: "#dbdbdb solid 1px" }} onMouseDown={this.handleMouseDown}>
                {lineGroups}
            </div>
        )
    }

    // endregion
}

export default ListElement;