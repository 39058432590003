/**
 * @version 0.1
 * @author 하성준
 * @see common.js
 */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { CompositeProps, Util, Events, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import LUXDraggableDialog from 'luna-rocket/LUXDraggableDialog';
import OBTButton, { ButtonTheme, ButtonType } from '../OBTButton';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { OBTContext } from '../OBTPageContainer/OBTPageContainer';
import { OrbitInternalLangPack } from '../Common/Util';
import { ImageUrl } from '../OBTButton/OBTButton';
import OBTDialog2 from '../OBTDialog2';

const styles = require('./OBTDialog.module.scss');

export class MouseEventArgs extends Events.MouseEventArgs {
    constructor(target: any, event: React.MouseEvent, public readonly type: string) {
        super(target, event);
    }
    toString(): string { return JSON.stringify({ target: this.target ? 'object' : this.target, event: this.event!.toString(), type: this.type }); }
}

export interface IButton {
    key: string,
    onClick?: (e: any) => void,
    theme?: ButtonTheme,
    labelText?: string,
    type?: ButtonType,
    visible?: boolean,
    disabled?: boolean,
    isClose?: boolean,
    imageUrl?: ImageUrl,
    imageTagClassName?: string,
    buttonClassName?: string,
    width?: string,
    height?: string;
}

export interface ISampleButton {
    Confirm: (onConfirm) => IButton,
    Cancel: (onCancel) => IButton,
    Save: (onSave) => IButton,
    Close: (onClose) => IButton,
}

export interface ISampleButtons {
    ConfirmAndCancel: (onConfirm, onCancel) => [IButton, IButton],
    SaveAndClose: (onSave, onClose) => [IButton, IButton];
}

interface IOBTDialog extends CompositeProps.Default {
    /**
     * true일때 Dialog가 열립니다.
     */
    open?: boolean,

    /**
     * Dialog의 제목을 설정합니다.
     */
    title: string,

    /**
     * Dialog의 부제목을 설정합니다.
     */
    subTitle?: string,

    /**
     * 우측 상단 X 버튼(닫기)을 제외한 채 타이틀을 지정할때 설정합니다.
     */
    hasTitleCloseButton?: boolean,

    /**
     * Dialog의 크기 타입을 설정합니다.
     */
    type?: DialogType,

    /**
     * Dialog의 하단의 버튼을 설정합니다.
     */
    buttons?: Array<IButton>,

    /**
     * Dialog가 open 되었을 때 실행되는 함수 입니다.
     */
    onAfterOpen?: (e) => void,

    useOldVersion?: boolean,
}

/**
 * @internal
 * State 정의
 */
interface State extends hasError {
    propOpen: boolean,
    open: boolean;
}

export enum DialogType {

    /**
     *  1050px 720px
     */
    'big' = 'big',

    /**
     *  740px 620px
     */
    'default' = 'default',

    /**
     *  440px 520px
     */
    'small' = 'small',

}


class OBTDialog extends React.Component<IOBTDialog, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        { name: "open", type: CommonType.boolean, description: "true일때 Dialog가 열립니다.", optional: true },
        { name: "title", type: CommonType.string, description: "Dialog의 제목을 설정합니다." },
        { name: "subTitle", type: CommonType.string, description: "Dialog의 부제목을 설정합니다.", optional: true },
        {
            name: "hasTitleCloseButton", type: CommonType.boolean, optional: true, default: true,
            description: "우측 상단 X 버튼(닫기)을 제외한 채 타이틀을 지정할때 설정합니다. false로 주고 타이틀 없이 서브타이틀만 넣을 경우 우측 상단에 닫기 버튼 없는 다이얼로그가 생성됩니다."
        },
        {
            name: "type", type: toEnumType(DialogType), default: 'default', description: "Dialog의 크기 타입을 설정합니다."
                + "\n big : 1050px X 720px"
                + "\n default : 740px X 620px"
                + "\n small : 440px X 520px", optional: true
        },
        {
            name: "buttons", type: "Array<IButton>", optional: true, description: "Dialog의 하단의 버튼을 설정합니다.\n"
                + "\nOBTDialog.Button.Confirm | OBTDialog.Button.Cancel | OBTDialog.Button.Save | OBTDialog.Button.Close 배열로 감싸서 사용"
                + "\nex) buttons = {[OBTDialog.Button.Confirm(this.Confirm,OBTButton.Theme.blue)]}\n"

                + "\nOBTDialog.Buttons.ConfirmAndCancel | OBTDialog.Buttons.SaveAndClose 단일 사용 가능"
                + "\nex) buttons = {OBTDialog.Buttons.ConfirmAndCancel(this.Confirm,this.Cancel)}\n"

                + "\nCancel, Close는 기본적으로 isClose={true} 입니다."

                + "\nonClick의 funciont(target, event, type) 중 type은 "
                + "\nX버튼: closeButton, 하단버튼: button, 배경: background, 키보드 esc: esc\n"
        },
        {
            name: "onAfterOpen", type: CommonType.function, parameters: {
                name: "e",
                type: CommonType.any
            }, optional: true, description: "Dialog가 open 되었을 때 실행되는 함수 입니다."
        }
    );

    public static IButtonDefinitions = createPropDefinitions(
        { name: "key", description: "키", type: ['string', 'name'] },
        {
            name: "onClick", description: "Click 시 발생하는 Callback 함수입니다.", type: CommonType.function, parameters: {
                name: 'e',
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    event: { type: 'MouseEvent', description: '이벤트 객체' }
                }
            }
        },
        { name: "labelText", description: "버튼 이름", type: CommonType.boolean },
        { name: "isClose", description: "ESC, X버튼 클릭시 닫힘이벤트가 활성화되며 true인 요소의 onClick이 호출된다.", type: CommonType.boolean, optional: true },
        { name: "type", type: toEnumType(ButtonType), description: "버튼 타입", default: "default", optional: true },
        { name: "theme", type: toEnumType(ButtonTheme), default: 'default', description: '버튼 테마', optional: true },
        { name: 'visible', description: "false시 하단 버튼이 안보임", type: CommonType.boolean, optional: true },
        { name: "disabled", type: CommonType.boolean, description: "true시 해당 버튼은 비활성화된다.", optional: true },
        {
            name: 'imageUrl', type: [CommonType.image, {
                normal: { type: CommonType.image, description: '기본상태 이미지 Url' },
                over: { type: CommonType.image, optional: true, description: 'over상태 이미지 Url' },
                press: { type: CommonType.image, optional: true, description: 'press상태 이미지 Url' },
                disabled: { type: CommonType.image, optional: true, description: '비활성상태 이미지 Url' }
            }], optional: true, description: '버튼에 imageUrl을 넣을 때 사용하는 속성입니다.\nnormal에 해당하는 이미지 Url 혹은 Json 객체를 통해 이미지를 지정할 수 있습니다.'
        },
        {
            name: 'imageTagClassName',
            type: CommonType.string,
            description: '들어갈 이미지에 클래스 네임을 부여할 수 있습니다. 이미지 크기와 같은 조정을 할 수 있습니다. 사용하지 않을 시, 컴포넌트 내부적으로 사용하고 있는 클래스네임을 사용 (기본 이미지 크기 18 x 18)',
            optional: true
        },
        {
            name: 'buttonClassName',
            type: CommonType.string,
            description: '버튼 컴포넌트에 클래스 네임을 부여할 수 있습니다. 사용하지 않을 시, 컴포넌트 내부적으로 사용하고 있는 클래스네임을 사용',
            optional: true
        },
        {
            name: 'width',
            type: CommonType.string,
            description: '버튼 너비를 조정할 수 있습니다. 특히, 이미지가 있는 버튼과 이미지가 없는 버튼이 함께 있을 시에 너비가 맞지 않게 되는데, 이를 조정할 수 있습니다.',
            optional: true
        },
        {
            name: 'height',
            type: CommonType.string,
            description: '버튼 높이를 조정할 수 있습니다.',
            optional: true
        }
    );

    public static defaultProps = {
        frozen: false,
        hasTitleCloseButton: true,
        useOldVersion: true,
    };

    public state: State = {
        propOpen: this.props.open ? this.props.open : false,
        open: this.props.open ? this.props.open : false,
        hasError: false
    };

    private myRefs = {
        dialogRoot: React.createRef<HTMLDivElement>(),
        root: React.createRef<HTMLDivElement>(),
        dialog: React.createRef<LUXDraggableDialog>()
    };

    containerRef: React.RefObject<HTMLDivElement> | null = null;

    public static Type = DialogType;

    public static ButtonTheme = ButtonTheme;

    public static ButtonType = ButtonType;

    /**
     *  Confirm, Cancel, Save, Close 단일 버튼을 제공합니다.
     */
    public static Button: ISampleButton = {
        Confirm: (onConfirm?: (e) => void, theme?: ButtonTheme) => {
            return {
                key: 'confirm',
                onClick: onConfirm,
                labelText: OrbitInternalLangPack.getText('WE000000054', '확인'),
                type: OBTButton.Type.big,
                theme: theme ? theme : ButtonTheme.default
            };
        },
        Cancel: (onCancel?: (e) => void, theme?: ButtonTheme) => {
            return {
                key: 'cancel',
                onClick: onCancel,
                isClose: true,
                labelText: OrbitInternalLangPack.getText('WE000001945', '취소'),
                type: OBTButton.Type.big,
                theme: theme ? theme : ButtonTheme.default
            };
        },
        Save: (onSave?: (e) => void, theme?: ButtonTheme) => {
            return {
                key: 'save',
                onClick: onSave,
                labelText: OrbitInternalLangPack.getText('WE000004653', '저장'),
                type: OBTButton.Type.big,
                theme: theme ? theme : ButtonTheme.default
            };
        },
        Close: (onClose?: (e) => void, theme?: ButtonTheme) => {
            return {
                key: 'close',
                onClick: onClose,
                isClose: true,
                labelText: OrbitInternalLangPack.getText('WE000001970', '닫기'),
                type: OBTButton.Type.big,
                theme: theme ? theme : ButtonTheme.default
            };
        },
    };

    public static Buttons: ISampleButtons = {
        ConfirmAndCancel: (onConfirm?: (e) => void, onCancel?: (e) => void) => {
            return [
                {
                    key: 'cancel',
                    onClick: onCancel,
                    isClose: true,
                    labelText: OrbitInternalLangPack.getText('WE000001945', '취소'),
                    type: OBTButton.Type.big
                },
                {
                    key: 'confirm',
                    onClick: onConfirm,
                    labelText: OrbitInternalLangPack.getText('WE000000054', '확인'),
                    type: OBTButton.Type.big,
                    theme: OBTButton.Theme.blue
                }
            ];
        },
        SaveAndClose: (onSave?: (e) => void, onClose?: (e) => void) => {
            return [
                {
                    key: 'close',
                    onClick: onClose,
                    isClose: true,
                    labelText: OrbitInternalLangPack.getText('WE000001970', '닫기'),
                    type: OBTButton.Type.big
                },
                {
                    key: 'save',
                    onClick: onSave,
                    labelText: OrbitInternalLangPack.getText('WE000004653', '저장'),
                    type: OBTButton.Type.big,
                    theme: OBTButton.Theme.blue
                }
            ];
        },
    };

    static getDerivedStateFromProps(nextProps: IOBTDialog, prevState: State): any {
        if (!nextProps.useOldVersion) return null;
        try {
            if (nextProps.open !== prevState.propOpen) {
                return { propOpen: nextProps.open ? nextProps.open : false, open: nextProps.open ? nextProps.open : false };
            }
            return null;
        } catch (e) {
            return Util.getErrorState(e);
        }


    }

    componentDidMount() {
        if (this.props.useOldVersion) {
            try {
                this.setDimClicker();
                this.afterOpen();
                this.addEvent();
            } catch (e) {
                Util.handleError(this, e);
            }
        }
    }

    // shouldComponentUpdate(nextProps: IOBTDialog, nextState: State) {
    //     if(this.props.open === false && nextProps.open === false) {
    //         console.log('test11 hit')
    //         return false;
    //     }

    //     return true;
    // }

    getSnapshotBeforeUpdate(prevProps: IOBTDialog, prevState: State): any {
        if (!this.props.useOldVersion) return null;

        try {
            return prevState.open;
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    componentDidUpdate(prevProps: IOBTDialog, prevState: State, snapshot: any): void {
        if (this.props.useOldVersion) {
            try {
                this.setDimClicker();
                if ((this.state.open !== snapshot) && this.state.open === true) {
                    this.afterOpen();
                }
                this.addEvent();
            } catch (e) {
                Util.handleError(this, e);
            }
        }
    }

    componentWillUnmount() {
        if (this.props.useOldVersion) {
            try {
                this.removeEvent();
            } catch (e) {
                Util.handleError(this, e);
            }
        }
    }

    _handleAfterOpen;
    handleAfterOpen = () => {
        if (this._handleAfterOpen) {
            this._handleAfterOpen();
        }
    };

    _handleButtonClicked;
    handleButtonClicked = (key) => {
        if (this._handleButtonClicked) {
            this._handleButtonClicked(key);
        }
    };

    handleClose = () => {
        this.setState({
            open: false
        });
    };

    private afterOpen = () => {
        if (this.state.open === true) {
            setTimeout(() => {
                if (this.myRefs.root.current) {
                    this.myRefs.root.current.focus();
                }
                if (document.body &&
                    this.myRefs.dialogRoot && this.myRefs.dialogRoot.current) {
                    // IE 에서 scope 지원안함.
                    // `${(document.body.querySelectorAll(':scope > .obtdialog.open') || []).length}`;
                    this.myRefs.dialogRoot.current.style.zIndex = `${Array.from(document.body.children).filter(item =>
                        item.classList.contains('obtdialog') &&
                        item.classList.contains('open')).length}`;
                }
                Util.invokeEvent<Events.EventArgs>(this.props.onAfterOpen, new Events.EventArgs(this));
                this.handleAfterOpen();
            }, 0);
        }
    };

    addEvent = () => {
        window.removeEventListener('mousemove', this.handleMouseMove, true);
        if (this.state.open) {
            window.addEventListener('mousemove', this.handleMouseMove, true);
        }
    };

    removeEvent = () => {
        window.removeEventListener('mousemove', this.handleMouseMove, true);
    };

    handleMouseMove = (e: MouseEvent) => {
        if (this.state.open &&
            this.myRefs.dialog.current) {
            const clientTop = this.myRefs.dialog.current.state.clientTop;
            const clientLeft = this.myRefs.dialog.current.state.clientLeft;

            setTimeout(() => {
                if (this.myRefs.dialog.current &&
                    this.myRefs.root.current &&
                    (clientTop !== this.myRefs.dialog.current.state.clientTop ||
                        clientLeft !== this.myRefs.dialog.current.state.clientLeft)) {
                    this.myRefs.root.current.querySelectorAll('div[data-orbit-component="OBTFloatingPanel"]')
                        .forEach(el => {
                            if ((el as any)._OBTDialogRelocated) {
                                (el as any)._OBTDialogRelocated();
                            }
                        });
                }
            }, 0);
        }
    };

    private setDimClicker = () => {
        if (this.props.open && this.myRefs.root.current) {
            const cloest = (el: HTMLElement, className: string): HTMLElement | null => {
                if (el.classList.contains(className)) return el;
                else if (el.parentElement) {
                    return cloest(el.parentElement, className);
                }
                return null;
            };

            const dialogRoot = cloest(this.myRefs.root.current, '_isDialog');
            if (dialogRoot) {
                const dimClicker = Array.from(dialogRoot.childNodes).find(el => (el as HTMLElement).style.position === 'fixed') as (HTMLElement | null);
                if (dimClicker) {
                    if (!(dimClicker.childNodes.length > 0)) {
                        const child = document.createElement('div');
                        child.className = "_dimClicker";
                        child.tabIndex = -1;
                        child.style.width = '100%';
                        child.style.height = '100%';
                        child.style.backgroundColor = 'transparent';
                        child.onclick = (e) => this.props.buttons ? this.props.buttons.find(item => item.isClose === true ? item.onClick ? this.handleClick(item.onClick, e, 'background') : null : null) : null;
                        dimClicker.appendChild(child);
                    }
                }
            }
        }
    };

    private handleClick = (onClick: any, event: any, type: string): void => {
        Util.invokeEvent<MouseEventArgs>(onClick, new MouseEventArgs(this, event, type));
    };

    private handleKeyDown = (e: React.KeyboardEvent) => {
        if (this.state.open) {
            if (e.key === 'Escape') {
                e.stopPropagation();
            }
        }
    };

    private close = (event: any, type: string) => {
        if (this.props.buttons) {
            const closeButton = this.props.buttons.find(item => item.isClose === true && item.onClick ? true : false);
            if (closeButton) {
                this.handleClick(closeButton.onClick, event, type);
            }
        }
    };

    private handleOnEscClose = (e: KeyboardEvent) => {
        if (this.myRefs.dialogRoot.current) {
            if (document.body) {
                // IE 에서 scope 지원안함.
                // const openedCount = (document.body.querySelectorAll(':scope > .obtdialog.open') || []).length;
                const openedCount = Array.from(document.body.children).filter(item =>
                    item.classList.contains('obtdialog') &&
                    item.classList.contains('open')).length;
                if (this.myRefs.dialogRoot.current.style.zIndex && Number(this.myRefs.dialogRoot.current.style.zIndex) === openedCount) {
                    e.stopImmediatePropagation();
                    this.close(e, 'esc');
                }
            }
        }
    };

    renderComponent = (isPageVisible: boolean) => {
        const children = React.Children.map(this.props.children, (child) => {
            if (React.isValidElement(child) && typeof child.type !== 'string') {
                return React.cloneElement(child, {
                    onAfterOpen: (func) => {
                        this._handleAfterOpen = func;
                    },
                    onButtonClicked: (func) => {
                        this._handleButtonClicked = func;
                    },
                    close: () => {
                        this.handleClose();
                    }
                });
            } else {
                return child;
            }
        });

        let propButtons = this.props.buttons;
        const propButtonsLength = propButtons ? propButtons.length : 0;
        let visibleCount = 0;
        // visible 설정된 button의 개수를 셈
        if (propButtons) {
            visibleCount = propButtons.filter((button) => {
                if (button.visible === false) {
                    return true;
                }
                return false;
            }).length;
        }
        let buttons;
        if (propButtons) {
            buttons = propButtons.map((button) => {
                if (button.visible === false) {
                    return null;
                }

                return <OBTButton
                    width={button.width && button.width}
                    height={button.height && button.height}
                    className={Util.getClassNames(button.imageUrl ? styles.buttonWrapper : null, button.buttonClassName)}
                    key={button.key}
                    labelText={button.labelText}
                    disabled={button.disabled === true ? true : false}
                    theme={button.theme}
                    type={button.type ? button.type : OBTButton.Type.big}
                    onClick={(e) => {
                        if (button.onClick) this.handleClick(button.onClick, e, 'button'); this.handleButtonClicked(button.key);
                    }}
                    imageUrl={button.imageUrl && button.imageUrl}
                    imageTagClassName={button.imageTagClassName ? button.imageTagClassName : styles.useImageUrl}
                />;
            });
        }

        const subTitleStyle = {
            fontFamily: 'inherit',
            height: '19px',
            color: '#a6a6a6',
            lineHeight: '1.46',
            fontSize: '13px',
            marginLeft: '6px',
            paddingTop: '25px'
        };

        const notHaveTitleCLoseButton = {
            fontFamily: 'inherit',
            height: '27px',
            color: '#000000',
            lineHeight: '1.28',
            fontSize: '18px',
            letterSpacing: '-0.9px',
            fontWeight: 'bold'
        };

        const component =
            <>
                <LUXDraggableDialog
                    ref={this.myRefs.dialog}
                    dialogOpen={this.state.open}
                    titleStyle={{
                        paddingTop: '22px',
                        height: '27px',
                        lineHeight: '1.28',
                        letterSpacing: '-0.9px',
                        fontFamily: 'inherit'
                    }}
                    title={this.props.title}
                    subTitle={this.props.subTitle}
                    subTitleStyle={!this.props.hasTitleCloseButton ? notHaveTitleCLoseButton : subTitleStyle}
                    closeInnerControl={false}
                    onRequestClose={false}
                    handleOnButtonClose={(e: any) => this.close(e, 'closeButton')}
                    handleOnEscClose={this.handleOnEscClose}
                    dialogBoxStyle={Object.assign(Util.getWrapperStyle(this.props), { boxSizing: 'border-box', minHeight: '124px', minWidth: '100px' },
                        this.props.height ? {} : this.props.type === DialogType.big ? { height: '720px' } : this.props.type === DialogType.default ? { height: '620px' } : this.props.type === DialogType.small ? { height: '520px' } : {},
                        this.props.width ? {} : this.props.type === DialogType.big ? { width: '1050px' } : this.props.type === DialogType.default ? { width: '740px' } : this.props.type === DialogType.small ? { width: '440px' } : {},
                    )}
                    dialogDataBoxStyle={Object.assign({ display: 'flex', flexDirection: 'column' }, this.props.height ? { height: this.props.height } : this.props.type === DialogType.big ? { height: '720px' } : this.props.type === DialogType.default ? { height: '620px' } : this.props.type === DialogType.small ? { height: '520px' } : {})}
                >
                    <div className={styles.data} ref={this.myRefs.root} tabIndex={-1} style={{ outline: 'none' }} onKeyDown={this.handleKeyDown}>
                        <div className={Util.getClassNames(styles.dataWrapper, 'obtDialog')}>
                            {children}
                        </div>
                    </div>
                    <div className={Util.getClassNames(styles.bottomButton, propButtonsLength - visibleCount > 0 ? null : styles.noneButton)}>
                        {buttons}
                    </div>
                </LUXDraggableDialog>
            </>;
        return (
            <div
                id={this.props.id}
                style={{
                    display: isPageVisible ? undefined : 'none'
                }}
                data-orbit-component={'OBTDialog'}
                ref={this.myRefs.dialogRoot}
                className={Util.getClassNames('obtdialog', styles.dialogRoot, this.state.open ? 'open' : undefined, this.props.className)}
                onKeyDown={this.handleKeyDown}
                tabIndex={-1}>
                {component}
            </div>
        );
    };

    render() {
        if (this.props.useOldVersion) {
            return (
                // TODO: contextType 동작안하는 문제가 있어서 Consumer로 전환
                this.props.open ?
                    <OBTContext.Consumer>
                        {
                            value => {
                                return ReactDOM.createPortal(
                                    (
                                        <ErrorBoundary
                                            owner={this}
                                            render={() => { return this.renderComponent(value.isPageVisible); }}
                                        />
                                    ),
                                    document.body
                                );
                            }
                        }
                    </OBTContext.Consumer> : <></>
            );
        } else {
            return <OBTDialog2
                {...this.props}
            />;
        }
    }
};

// OBTDialog.contextType = OBTContext;
export default OBTDialog;

