/**
 * Component Develment Template
 * Luna - Orbit 개발시 템플릿 으로 사용.
 * @version 0.1
 * @author 김철희
 * @see common.js
 */
import * as React from 'react';
import { CompositeProps, Util, Events } from '../Common';
import OBTScrollbar from '../OBTScrollbar';
import memoizeOne from 'memoize-one';
import moment from 'moment';
const classNames = require('./OBTGanttChart.module.scss');

interface IItem {
    key: string,
    name: JSX.Element,
    fromDate?: Date,
    toDate?: Date,
    tooltip?: JSX.Element,
    children?: IItem[],
    color?: string,
    collapsed?: boolean
}

interface IOBTGanttChart extends CompositeProps.Default, Events.onChange<any> {
    title?: JSX.Element,
    items?: IItem[],
    today?: Date
}

interface IState {
    _items?: IItem[],
    items?: IItem[],
    scrollLeft?: number,
    scrollTop?: number
}

/**
 * withApi() HOC 를 사용하면 Props 로 Api 를 사용할 수 있다.
 * api 가 Optional 로 선언되었기에 내부에서 ! 오퍼레이터를 사용해서 호출한다.
 * {@code this.props.api!.test();}
 */
export default class OBTGanttChart extends React.Component<IOBTGanttChart, IState> {
    ///////////////////////////////////////////////////////////////////////////// Initialize
    /**
     * Default Props 설정
     */
    public static defaultProps = {
        width: '100%',
        height: '100%'
    }

    /**
     * State 정의
     */
    public state: IState = {}

    /**
     * Ref 정의
     */
    public myRefs = {
        root: React.createRef<HTMLDivElement>(),
        scroll: React.createRef<OBTScrollbar>()
    }

    ///////////////////////////////////////////////////////////////////////////// Life Cycle API
    public static getDerivedStateFromProps(nextProps: IOBTGanttChart, prevState: IState): any {
        if (nextProps.items !== prevState._items) {
            const colors = ['#20c997', '#2dbcb5', '#39b0d2', '#46a3f0', '#ff8787', '#f8a457', '#f0c325', '#c8b465'];
            let colorIndex = colors.length;
            const getRandColor = () => {
                if (colorIndex >= colors.length) {
                    for (let i = 0; i < colors.length; i++) {
                        const a = Math.floor(Math.random() * colors.length);
                        const b = Math.floor(Math.random() * colors.length);
                        const value = colors[a];
                        colors[a] = colors[b];
                        colors[b] = value;
                    }
                    colorIndex = 0;
                }
                return colors[colorIndex++];
            }
            const getItem = (item: IItem) => {
                if (item.children) {
                    item.children = item.children.map(child => getItem(child));
                }
                if (!item.color) {
                    item.color = getRandColor();
                }
                return item;
            }

            return {
                _items: nextProps.items,
                items: nextProps.items ? nextProps.items.map(item => getItem(item)) : nextProps.items
            }
        }
        return null;
    }

    private getDisplayItemCount = memoizeOne((items: IItem[]) => {
        const getItem = (item: IItem) => {
            return 1 + (item.collapsed !== true && item.children ? item.children.reduce((accu, child) => accu + getItem(child), 0) : 0);
        };
        return items.reduce((accu, item) => accu + getItem(item), 0);
    });

    private seriesItems = memoizeOne((items: IItem[]) => {
        const getItem = (item: IItem, depth?: number) => {
            return <React.Fragment key={item.key}>
                <div className={classNames.seriesItemRoot}>
                    <div className={classNames.indent} style={{ paddingLeft: (depth || 0) * 21 }}>
                        {item.children && item.children.length > 0 ? <button className={[classNames.serialItemCollapseButton, item.collapsed === true ? classNames.collapsed : null].filter(i => i).join(' ')} onClick={(e) => this.handleSeriesCollapseClick(item)} /> : null}
                        {/* <OBTTooltip labelText={item.tooltip} value={item.tooltip ? undefined : false}>
                            <div className={classNames.seriesItemText} onClick={this.handleChange.bind(this, item)}>
                                {item.name}
                            </div>
                        </OBTTooltip> */}
                        <div className={Util.getClassNames(classNames.seriesItemText, item.tooltip ? classNames.tooltip : undefined)}
                            onClick={this.handleChange.bind(this, item)}
                            onMouseMove={item.tooltip ? this.handleMouseMove : undefined}
                        >
                            <span>{item.tooltip}</span>
                            {item.name}
                        </div>
                    </div>
                </div>
                {item.collapsed !== true && item.children ? item.children.map(child => getItem(child, (depth || 0) + 1)) : null}
            </React.Fragment>
        };
        return items.map(item => getItem(item));
    });

    private getDates = memoizeOne((items?: IItem[], today?: Date) => {
        const getDateRange = (items?: IItem[]) => {
            if (items && items.length > 0) {
                return items.reduce((accu: {
                    fromDate?: Date, toDate?: Date
                }, curr: IItem) => {
                    const fromTo = curr.children && curr.children.length > 0 ? getDateRange(curr.children) : {
                        fromDate: curr.fromDate,
                        toDate: curr.toDate
                    };
                    if (fromTo) {
                        if (fromTo.fromDate && (!accu.fromDate || accu.fromDate > fromTo.fromDate)) {
                            accu.fromDate = fromTo.fromDate;
                        }
                        if (fromTo.toDate && (!accu.toDate || accu.toDate < fromTo.toDate)) {
                            accu.toDate = fromTo.toDate;
                        }
                    }
                    return accu;
                }, {});
            }
            return {};
        }
        const dateRange = getDateRange(items);
        let startDate = dateRange.fromDate ? dateRange.fromDate : dateRange.toDate ? moment(dateRange.toDate).subtract(1, 'months').toDate() : new Date();
        let endDate = dateRange.toDate ? dateRange.toDate : dateRange.fromDate ? moment(dateRange.fromDate).add(1, 'months').toDate() : new Date();
        if (startDate > endDate) {
            const swap = startDate;
            startDate = endDate;
            endDate = swap;
        }
        const format = (number: number) => { const value = `00${number}`; return value.substring(value.length - 2, value.length); };

        let date = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        let days: { day: Date, display: string, today: boolean, index: number }[] = [];
        const months: { month: Date, display: string, fromIndex: number, toIndex: number }[] = [];
        let weekDays: { weekDay: Date, day: number, index: number }[] = [];
        const todayUnit = {
            year: (today || new Date()).getFullYear(),
            month: (today || new Date()).getMonth(),
            date: (today || new Date()).getDate()
        }
        while (date <= endDate) {
            const endOfMonth = moment(date).set('date', 1).add(1, 'months').subtract(1, 'days').toDate();
            const year = date.getFullYear();
            const month = date.getMonth();
            const startDate = date.getDate();
            const toDate = endOfMonth > endDate ? endDate : endOfMonth;
            const fromIndex = days.length;
            const dates = [...new Array(toDate.getDate() - date.getDate() + 1)].map((item, index) => ({
                day: new Date(year, month, startDate + index),
                display: format(startDate + index),
                index: fromIndex + index,
                today: todayUnit.year === year && todayUnit.month === month && todayUnit.date === startDate + index ? true : false
            }));
            const toIndex = fromIndex + dates.length;
            days = days.concat(dates);
            months.push({ month: date, display: moment(date).format('YYYY년 MM월'), fromIndex, toIndex });
            weekDays = weekDays.concat(dates.map((item, index) => ({
                weekDay: item.day, day: item.day.getDay(), index: item.index
            })).filter(item => [0, 6].includes(item.weekDay.getDay())));
            date = moment(toDate).add(1, 'days').toDate();
        }

        return {
            days,
            months,
            weekDays,
            today: days.find(day => day.today === true)
        };
    });

    private seriesBars = memoizeOne((items: IItem[], days: { day: Date, display: string }[]) => {
        const equals = (source: Date, target: Date) => {
            return source.getFullYear() === target.getFullYear() &&
                source.getMonth() === target.getMonth() &&
                source.getDate() === target.getDate();
        }
        const getBars = (item: IItem) => {
            if (item.children && item.children.length > 0) {
                return item.children.flatMap(child => getBars(child));
            }
            let fromIndex: number = 0;
            let toIndex: number = days.length - 1;
            if (item.fromDate || item.toDate) {
                if (item.fromDate) {
                    fromIndex = days.findIndex(day => equals(day.day, item.fromDate!));
                }
                if (item.toDate) {
                    toIndex = days.findIndex(day => equals(day.day, item.toDate!));
                }
                return [{
                    left: fromIndex * 20,
                    width: (toIndex - fromIndex + 1) * 20,
                    color: item.color,
                    tooltip: item.tooltip,
                    item: item,
                }]
            } else {
                return [];
            }
        };
        const getItem = (item: IItem) => {
            const bars = getBars(item);
            return <React.Fragment key={item.key}>
                <div className={classNames.barRoot}>
                    {bars.map((bar: any, index: number) => (
                        <div className={classNames.bar} style={{ left: `${bar.left}px`, width: `${bar.width}px`, backgroundColor: bar.color }} key={index}>
                            {/* <OBTTooltip labelText={bar.tooltip} value={bar.tooltip ? undefined : false}>
                                <div className={classNames.barInner} onClick={this.handleChange.bind(this, bar.item)} style={{ width: '100%', height: '100%' }} />
                            </OBTTooltip> */}
                            <div className={Util.getClassNames(classNames.barInner, bar.tooltip ? classNames.tooltip : undefined)}
                                onClick={this.handleChange.bind(this, bar.item)}
                                onMouseMove={bar.tooltip ? this.handleMouseMove : undefined}
                                style={{ width: '100%', height: '100%' }}>
                                <span>{bar.tooltip}</span>
                            </div>
                        </div>))}
                </div>
                {item.collapsed !== true && item.children ? item.children.map(child => getItem(child)) : null}
            </React.Fragment>
        }
        return items.map(item => getItem(item));
    });

    // component 가 render 될때 호출됨.
    render() {
        const seriesItems = this.state.items ? this.seriesItems(this.state.items) : null;
        const dates = this.getDates(this.state.items, this.props.today);
        const seriesBars = this.state.items ? this.seriesBars(this.state.items, dates.days) : null;
        const itemCount = this.state.items ? this.getDisplayItemCount(this.state.items) : 0;
        return (
            <div id={this.props.id} data-orbit-component={'OBTGanttChart'} ref={this.myRefs.root} className={Util.getClassNames(classNames.root, this.props.className)} style={Util.getWrapperStyle(this.props)}>
                <div className={classNames.chartRoot}>
                    <div className={classNames.scrollRoot}>
                        <div className={classNames.chartTitle} style={{ marginLeft: this.state.scrollLeft ? `${this.state.scrollLeft * -1}px` : undefined }}>
                            {dates ? dates.months.map((item, index) => {
                                return (
                                    <div className={classNames.chartMonthRoot} key={index}>
                                        <div className={classNames.chartMonth}>{item.display}</div>
                                        <div className={classNames.chartDays}>
                                            {dates.days.slice(item.fromIndex, item.toIndex).map((item) => (<div className={Util.getClassNames(classNames.chartDay, item.today ? classNames.today : undefined)} key={item.display}>{item.display}</div>))}
                                        </div>
                                    </div>);
                            }) : null}
                        </div>
                        <OBTScrollbar className={classNames.wrapper} width={'100%'} height={'100%'} onScrollFrame={this.handleScroll} ref={this.myRefs.scroll}>
                            <div className={classNames.chartContents} style={{ width: dates.days.length * 20, minHeight: `${itemCount * 40}px` }}>
                                <div className={classNames.chartContentsRoot}>
                                    <div className={classNames.holidays}>
                                        {dates.weekDays.map(item => (<div
                                            key={item.index}
                                            className={item.day === 0 ? classNames.sunday : classNames.saturday}
                                            style={{ left: `${(item.index * 20) + 1}px` }}
                                        />))}
                                        {dates.today ? <div className={classNames.today} style={{ left: `${(dates.today.index * 20) + 1}px` }} /> : undefined}
                                    </div>
                                    <div className={classNames.contents}>
                                        {seriesBars}
                                    </div>
                                </div>
                            </div>
                        </OBTScrollbar>
                    </div>
                </div>
                <div className={classNames.seriesPanel}>
                    <div className={classNames.title}>
                        {this.props.title}
                    </div>
                    <div className={classNames.panel} style={{ marginTop: this.state.scrollTop ? `${this.state.scrollTop * -1}px` : undefined }}>
                        {seriesItems}
                    </div>
                </div>
            </div>);
    }

    ///////////////////////////////////////////////////////////////////////////// Logics
    public focus(): void {
        if (this.myRefs.root.current) {
            this.myRefs.root.current.focus();
        }
    }

    ///////////////////////////////////////////////////////////////////////////// Event Handlers
    private handleSeriesCollapseClick = (item: IItem) => {
        item.collapsed = item.collapsed ? false : true;
        this.setState({ items: ([] as IItem[]).concat(this.state.items!) });
    }

    private handleScroll = (e: Events.ChangeEventArgs<any>) => {
        if (this.myRefs.scroll.current && this.myRefs.scroll.current.element) {
            if (this.state.scrollLeft !== e.value.scrollLeft || this.state.scrollTop !== e.value.scrollTop) {
                this.setState({
                    scrollLeft: e.value.scrollLeft,
                    scrollTop: e.value.scrollTop
                });
            }
        }
    }

    // private handleMoveFocus(direction: string): void {
    //     Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
    // }

    // private handleFocus(): void {
    //     Util.invokeEvent<Events.EventArgs>(this.props.onFocus, new Events.EventArgs(this));
    // }

    private handleChange(value: any): void {
        Util.invokeEvent<Events.ChangeEventArgs<any>>(this.props.onChange, new Events.ChangeEventArgs<any>(this, value));
    }

    handleMouseMove = (e) => {
        var tooltipSpan = e.currentTarget.firstElementChild;
        var x = e.clientX,
            y = e.clientY;
        tooltipSpan.style.top = (y - 125) + 'px';
        tooltipSpan.style.left = (x) + 'px';
    }

    // private handleBlur(): void {
    //     Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
    // }
};
