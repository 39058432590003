/**
 * Component Develment Template
 * Luna - Orbit 개발시 템플릿 으로 사용.
 * @version 0.1
 * @author 박재성
 * @see LUXSmartComplete
 */
import * as React from 'react';
import LUXSmartComplete from './LUXSmartComplete';
import { Events, CompositeProps, Util, CommonProps, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import OBTTooltip, { IOBTTooltip } from '../OBTTooltip/OBTTooltip';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { OBTContext } from '../OBTPageContainer/OBTPageContainer';
import GuideMessageRenderer from '../Common/GuideMessageRenderer';
import debounce from 'lodash.debounce';
import { removeTrashElement } from '../Common/OrbitInternalUtil';

export enum StrictEnum {
    'true' = 'true',
    'false' = 'false',
    'StrictNoEmpty' = 'StrictNoEmpty'
}

class DirectionKeyDownEventArgs extends Events.EventArgs {
    constructor(target: any, public direction: string) {
        super(target)
    }
}

interface IOBTComplete extends CompositeProps.InputClass<string>, CommonProps.placeHolder, Events.onValidate<string>, Events.onClick, Events.onMouseDown,
    Events.onMouseMove, Events.onMouseUp, Events.onMouseLeave, Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress, Events.onKeyUp {
    dataInfo: {
        columnWidths: string[],
        itemInfo: { key: string, column: number, isKeyValue?: boolean }[]
    },
    onSearch: (e: string) => Promise<any>,
    tooltip?: IOBTTooltip,
    canAdd?: boolean,
    canDelete?: boolean,
    onAdd?: (e) => void,
    onDelete?: (e) => void,
    isDropDown?: boolean,
    isStrict?: boolean | StrictEnum,
    isShowTooltip?: boolean,
    useShortCut?: boolean,
    onDirectionKeyDown?: (e: DirectionKeyDownEventArgs) => void
}

interface State extends hasError {
    _value?: string,
    value: string,
}

export default class OBTComplete extends React.Component<IOBTComplete, State> {

    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.InputClass(CommonType.string),
        {
            name: "dataInfo", type: [{
                columnWidths: { type: 'string[]' },
                itemInfo: { type: ['{ key: string, column: number, isKeyValue?: boolean }'] }
            }], description: '검색할 때 나타나는 데이터들의 정보입니다.'
        },
        { name: 'canAdd', type: CommonType.boolean, optional: true, description: '팝오버 리스트의 헤더에 추가 버튼 표시 여부를 지정할 수 있습니다.', default: false },
        { name: 'canDelete', type: CommonType.boolean, optional: true, description: '항목 삭제여부를 지정할 수 있습니다.', default: false },
        { name: 'isDropDown', type: CommonType.boolean, optional: true, description: 'DropDown버튼을 통해 데이터 선택이 가능합니다.' },
        {
            name: 'isStrict', type: toEnumType(StrictEnum), optional: true, description: '데이터에 존재하지 않는 값이 입력 되면, onblur이벤트에서 이전 값으로 되돌리는 속성입니다.'
                + '\n * StrictEnum'
                + '\n true | false | StrictNoEmpty'
                + '\n 1. true : 빈값을 제외한 무효한 값이 입력되면 이전 값으로 되돌립니다.'
                + '\n 2. false : 이전 값으로 되돌리는 기능 없이 입력한 텍스트 그대로가 입력됩니다.'
                + '\n 3. StrictNoEmpty : 빈값 또한 무효한 값으로 인식하여 이전 값으로 되돌립니다.'
        },
        {
            name: 'isShowTooltip', type: CommonType.boolean, optional: true, description: '자동완성 항목(아이템) 각각에 툴팁의 사용여부를 지정할 수 있습니다.'
                + '\nfalse로 지정시 리스트 아이템에 툴팁이 뜨지 않습니다.'
        },
        CommonDefinitions.placeHolder(),
        CommonDefinitions.tooltip(),
        CommonDefinitions.InnerFunc(),
        {
            name: 'onSearch', type: CommonType.function, parameters: {
                name: 'e',
                type: CommonType.string
            }, result: 'Promise<any>', description: 'keyWord 로 검색하는 함수입니다.(외부작성 필요)'
        },
        { name: 'onAdd', type: CommonType.function, parameters: { name: 'e', type: CommonType.function }, description: '사용자가 입력한 값을 등록하는 버튼을 클릭했을 때 호출되는 콜백함수입니다.', optional: true },
        { name: 'onDelete', type: CommonType.function, parameters: { name: 'e', type: CommonType.function }, description: '데이터 삭제 버튼을 클릭하면 호출되는 Callback 함수입니다.', optional: true },
        {
            name: 'onDirectionKeyDown', type: CommonType.function, optional: true, parameters: {
                name: 'e',
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    direction: { type: CommonType.string, description: '방향' },
                }
            }, description: '팝오버 리스트가 없을 때 up, down, enter 명령을 주면 발생하는 함수 입니다.'
        },
        CommonDefinitions.onValidate(CommonType.string),
        CommonDefinitions.Event(),
    );

    public static Strict = StrictEnum;
    private useShortCut = false;

    context!: React.ContextType<typeof OBTContext>;

    public static defaultProps = {
        disabled: false,
        readonly: false,
        required: false
    }

    public state: State = {
        value: this.props.value
    }

    public myRefs = {
        id: React.createRef<LUXSmartComplete>(),
        root: React.createRef<HTMLDivElement>(),
    }

    static getDerivedStateFromProps(nextProps: IOBTComplete, prevState: State) {
        let nextState = {};

        if (prevState._value !== nextProps.value) {
            nextState = {
                ...nextState,
                _value: nextProps.value,
                value: nextProps.value
            };
        }

        if (Object.keys(nextState).length > 0) {
            return nextState;
        }
        return null;
    }

    public focus(): void {
        if (!this.props.frozen && !this.props.disabled && this.myRefs.id.current) {
            this.myRefs.id.current.focus();
        }
    }

    get canEdit(): boolean {
        return !this.props.disabled && !this.props.readonly;
    }

    public isEmpty(): boolean {
        return (!this.props.value || this.props.value.length <= 0);
    }

    public validate(): boolean {
        return !(this.props.required === true && this.isEmpty());
    }

    public blur(): void {
        if (!this.props.frozen && !this.props.disabled && this.myRefs.id.current) {
            this.myRefs.id.current.handleBlur(null);
        }
    }

    private handleSearch = (keyWord: string) => {
        return new Promise<any>((resolve, reject) => {
            if (this.props.onSearch) {
                this.props.onSearch(this.props.readonly || this.props.disabled ? this.props.value : keyWord)
                    .then(data => resolve(data))
                    .catch(error => reject(error));
            } else {
                reject('onSearch is necessary.')
            }
        })
    }

    private handleMoveFocus(direction: string): void {
        Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
    }

    private handleFocus(): void {
        Util.invokeEvent<Events.EventArgs>(this.props.onFocus, new Events.EventArgs(this));
        GuideMessageRenderer.handleFocus(
            this, this.props.id, this.context
        );
    }

    private async handleBlur() {
        if (this.nowSearching === false && this.state.value !== this.props.value) {
            await new Promise<void>((resolve) => this.setState({ value: this.props.value }, () => resolve()));
        }

        Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
        removeTrashElement();
        GuideMessageRenderer.handleBlur(
            this.context,
            this.props.id,
        );
    }

    private handleEnterKeyDown = (direction) => {
        Util.invokeEvent<DirectionKeyDownEventArgs>(this.props.onDirectionKeyDown, new DirectionKeyDownEventArgs(this, direction));
    }

    private handleAdd(): void {
        Util.invokeEvent<Events.EventArgs>(this.props.onAdd, new Events.EventArgs(this));
    }

    private handleChange = (e: any) => {
        try {
            if (this.canEdit) {
                const target = e.target;
                const oldValue = this.props.value;
                let data = e.target.data;
                const newValue = e.target.value;

                this.setState({ value: newValue }, () => {
                    if (newValue) {
                        if (this.props.isStrict === true || this.props.isStrict === StrictEnum.true || this.props.isStrict === StrictEnum.StrictNoEmpty) {
                            this.setSearchedValue(target, oldValue, newValue, data);
                            return;
                        }
                    }

                    if (this.props.isStrict === StrictEnum.StrictNoEmpty && (!newValue || newValue.length === 0)) {
                        return;
                    }

                    if (Util.invokeEvent<Events.ValidateEventArgs<string>>(this.props.onValidate, new Events.ValidateEventArgs(target, oldValue, newValue))) {
                        Util.invokeEvent<Events.ChangeEventArgs<string>>(this.props.onChange, new Events.ChangeEventArgs<string>(target, newValue, data));
                        removeTrashElement()
                    }
                });
            }
        } catch (error) {
            return;
        }
    };

    private nowSearching = false

    private setSearchedValue = debounce((target: any, oldValue: string, newValue: any, data: any) => {
        this.nowSearching = true;

        return this.props.onSearch(newValue).then((searchedList) => {
            if (searchedList && searchedList.length > 0) {
                const fieldName: (string | null) = this.props.dataInfo && this.props.dataInfo.itemInfo ? this.props.dataInfo.itemInfo.map((item: any) => item.isKeyValue === true ? item.key : undefined).find((item: (string | undefined)) => item) : null;
                if (fieldName) {
                    searchedList = searchedList.filter(item => item[fieldName] === newValue);
                }

                if (searchedList.length === 0) {
                    return false;
                }
            } else {
                return false;
            }

            // 만약 드랍다운에서 선택한 데이터라면
            if (data) {
                if (Util.invokeEvent<Events.ValidateEventArgs<string>>(this.props.onValidate, new Events.ValidateEventArgs(target, oldValue, newValue))) {
                    Util.invokeEvent<Events.ChangeEventArgs<string>>(this.props.onChange, new Events.ChangeEventArgs<string>(target, newValue, data));
                    return true;
                }
            }

            if (this.state.value === newValue) {
                if (!data) {
                    data = searchedList[0];
                }

                if (Util.invokeEvent<Events.ValidateEventArgs<string>>(this.props.onValidate, new Events.ValidateEventArgs(target, oldValue, newValue))) {
                    Util.invokeEvent<Events.ChangeEventArgs<string>>(this.props.onChange, new Events.ChangeEventArgs<string>(target, newValue, data));
                    return true;
                }
            }

            return false;
        }).then((isHitSearch) => {
            if (this.myRefs.root.current) {
                const isParentEqualsThis = (parent: Element | null): boolean => {
                    if (parent) {
                        if (parent === this.myRefs.root.current) return true;
                        else if (parent.parentElement) return isParentEqualsThis(parent.parentElement);
                    }
                    return false;
                }

                if (!isParentEqualsThis(document.activeElement)) {
                    if (isHitSearch === false || this.props.isStrict === StrictEnum.StrictNoEmpty) {
                        this.setState({
                            value: this.props.value
                        })
                    }
                }
            }
        }).finally(() => {
            this.nowSearching = false
        });
    }, 300);

    private handleKeyDown(event: React.KeyboardEvent): void {
        if ((this.props.useShortCut !== undefined ? this.props.useShortCut : this.useShortCut)) {
            const shortCut = Util.getShortCut(event);
            if (shortCut === 'CodePicker') {
                if (this.myRefs.id.current) {
                    this.myRefs.id.current.showDropDown();
                }
            }
        }
        Util.invokeEvent<Events.KeyEventArgs>(this.props.onKeyDown, new Events.KeyEventArgs(this, event));
    }


    renderComponent = () => {
        const props = {
            value: this.state.value,
            maxDataCount: 0,
            maxPopOverHeight: 250,

            onChange: this.handleChange,
            onSearch: this.handleSearch,
            dataInfo: this.props.dataInfo,
            hintText: this.props.placeHolder,

            onMoveFocus: this.handleMoveFocus.bind(this),

            showAlwaysHeaderButton: this.props.canAdd,
            canDelete: this.props.canDelete,

            onAdd: this.handleAdd.bind(this),
            onDelete: this.props.onDelete,

            disabled: this.props.disabled,

            onKeyDown: this.handleKeyDown.bind(this),

            isDropDown: this.props.isDropDown,

            isShowTooltip: this.props.isShowTooltip,

            onEnterKeyDown: this.handleEnterKeyDown,

            style: Object.assign({}, Util.getWrapperStyle(this.props), { width: '100%' }),
            styleInputDiv: Object.assign({}, Util.getInputStateStyle(this.props)),
            styleInputSpan: { padding: '3px 6px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', ...Util.getInputStateStyle(this.props) },
            styleInput: { lineHeight: 'inherit' } as any,
        };
        if (this.props.height && this.props.height.length > 0 && this.props.height !== 'auto') {
            props['styleInputDiv'] = {
                height: this.props.height
            };
            props['styleInputSpan'] = {
                height: this.props.height
            };
            props['styleInput'] = {
                height: this.props.height
            };
        }

        const component =
            <div ref={this.myRefs.root} className={this.props.className} onFocus={this.handleFocus.bind(this)} >
                <LUXSmartComplete {...props} ref={this.myRefs.id} />
            </div>

        return (
            <OBTTooltip {...this.props.tooltip}
                style={Util.getWrapperStyle(this.props)}
                overrideSize={false}
                onFocus={this.props.onFocus}
                onBlur={this.handleBlur.bind(this)}
                onClick={this.props.onClick}
                onMouseDown={this.props.onMouseDown}
                onMouseMove={this.props.onMouseMove}
                onMouseUp={this.props.onMouseUp}
                onMouseLeave={this.props.onMouseLeave}
                onMouseEnter={this.props.onMouseEnter}
                onKeyUp={this.props.onKeyUp}
                onKeyPress={this.props.onKeyPress}
                rootProps={{
                    id: this.props.id,
                    'data-orbit-component': 'OBTComplete'
                }}>
                {component}
            </OBTTooltip>
        );
    }

    render() {
        return (
            <OBTContext.Consumer>
                {
                    value => {
                        this.useShortCut = value.useShortCut;
                        return (<ErrorBoundary owner={this} render={this.renderComponent} />);
                    }
                }
            </OBTContext.Consumer>
        );
    }

};

OBTComplete.contextType = OBTContext;