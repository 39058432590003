import React from "react";
import PropTypes from "prop-types";
import InputElement from "./BaseComponents/InputElement";
import PopoverContainer from "./BaseComponents/PopoverContainer";
import keycode from "keycode";
import { ACTION_TYPE, POPOVER_STYLE } from "./enums";
import { removeTrashElement } from "../../Common/OrbitInternalUtil";

/**
 * 전체를 감싸고 있는 root div의 style
 */
const getStyles = props => {
  const { disabled, finish } = props;
  const styles = {
    style: {
      position: "relative",
      margin: 0,
      padding: 0,
      cursor: "pointer",
      backgroundColor: disabled || finish ? "rgba(247,247,247,1)" : "white"
    }
  };
  return styles;
};

class UFOMultiSelectField extends React.Component {
  // region React Base

  static propTypes = {
    /**
     * 비활성화 여부 입니다.
     */
    disabled: PropTypes.bool,

    /**
     * 입력된 글자가 없을 때 보여지는 힌트 글자 입니다.
     */
    hintText: PropTypes.string,

    /**
     * 요소에 초점(Focus)이 사라질 때 발생하는 Callback 함수입니다.
     *
     * @param {object} event 요소에 발생한 `blur` 이벤트 객체입니다.
     */
    onBlur: PropTypes.func,

    /**
     * 데이터가 변경되면 호출되는 Callback 함수입니다.
     */
    onChange: PropTypes.func.isRequired,

    /**
     * 요소에 초점(Focus)이 잡힐 때 호출되는 Callback 함수입니다.
     *
     * @param {object} event 요소에 발생한 `focus` 이벤트 객체입니다.
     */
    onFocus: PropTypes.func,

    /**
     * 요소에서 포커스가 이동되어야 할 시점에 발생하는 Callback 함수입니다.
     *
     * @param {string} move 포커스가 이동되어야 하는 방향입니다.
     */
    onMoveFocus: PropTypes.func,

    /**
     * 나타낼 데이터 배열입니다.
     */
    data: PropTypes.array,

    /**
     * 콤포넌트의 스타일 값을 오버라이딩 합니다.
     */
    style: PropTypes.object,

    /**
     * 콤포넌트 팝오버 스타일 값을 오버라이딩 합니다.
     */
    popOverStyle: PropTypes.object,

    /**
     * Input 태그의 Style 입니다.
     */
    styleInput: PropTypes.object,

    /**
     * Input 태그를 감싸고 있는 Span Style 입니다.
     */
    styleInputSpan: PropTypes.object,

    /**
     * 오른쪽 버튼 스타일입니다.
     */
    styleButton: PropTypes.object,

    /**
     * 아이템 리스트(ul)에 대한 스타일입니다.
     */
    styleUl: PropTypes.object,

    /**
     * 선택된 데이터입니다.
     */
    value: PropTypes.any,

    /**
     * 마감상태(readOnly)에 대한 속성으로 true일 때 동작됩니다.
     */
    finish: PropTypes.bool,

    useImage: PropTypes.bool
  };

  // 사용자에 의해 props가 넘어 오지 않았을 경우 default 정의
  static defaultProps = {
    disabled: false,
    finish: false,
    style: {},
    popOverStyle: {
      width: POPOVER_STYLE.WIDTH,
      height: POPOVER_STYLE.HEIGHT
    },
    onMoveFocus: () => { },
    value: ""
  };

  myRefs = {
    root: React.createRef(),
    input: React.createRef(),
    popover: React.createRef()
  }

  constructor(props) {
    super(props);
    this.state = {
      // 컴포넌트에 대한 포커스 여부
      isFocused: false,

      // popOver Open 여부
      isPopoverOpen: false,

      /**
       * items: 출력할 리스트 배열
       * position: 현재 커서 and 포커스된 위치 
       */
      data: {
        items: [],
        position: 0
      },

      // 전체 체크박스 체크여부
      isCheckedAll: false,
      keyPressValue: ''
    };

    // 변경하기전 Data
    this.prevData = {
      items: [],
      isCheckedAll: false,
      position: 0
    };
  }

  componentDidMount = () => {
    const { value, data } = this.props;
    if (value && data) {
      this.setState({
        isCheckedAll: value.length === data.length
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    const { value, data } = this.props;

    const prevValue = prevProps.value;
    const prevData = prevProps.data;

    if (value && data && prevValue && prevData) {
      if ((prevValue.length !== value.length) || (data.length !== prevData.length))
        this.setState({
          isCheckedAll: value.length === data.length
        });
    }
  };

  /**
   * 선택한 아이템에 대하여 체크를 한다
   * @param {number} 선택한 아이템의 index
   */
  handleClickCheckBoxItem = index => {
    const { data } = this.state;
    const items = data.items;
    items[index].isChecked = !items[index].isChecked;

    this.setState({
      data: { position: index, items: items },
      isCheckedAll:
        this.props.data.length === this.getCheckedItemLength() ? true : false,
      isFocused: true
    });
    // 체크박스 클릭후 포커싱이동을 다시 input으로 설정
    this.myRefs.input.current.myRefs.input.current.focus();
  };

  /**
   * 선택된 아이템 길이를 리턴한다
   */
  getCheckedItemLength = () => {
    const { data } = this.state;
    return data.items.filter(item => {
      return item.isChecked;
    }).length;
  };

  /**
   * 아이템 리스트 
   */
  search = () => {
    const { data } = this.props;
    // 사용자가 data value를 넘겨줬다면
    if (data && Array.isArray(data)) {
      const items = this.getModifiedData();
      this.setPrevData(items);
      this.setState({
        data: items,
        isFocused: true,
        isPopoverOpen: true
      });
    }
  };

  /**
   * 사용자가 넘겨준 일반 배열을 컴포넌트 내에서 사용할 오브젝트로 변경
   */
  getModifiedData = () => {
    const { data, value } = this.props;
    let searchItem = {};
    // if 현재 상태의 data가 세팅되어 있지 않다면 데이터를 세팅한다.
    // else 기존 상태의 data를 이용한다.

    const items = data.map((dataItem, index) => {
      let valueItems = [];
      if (value) {
        valueItems = value.filter(valueItem => {
          return valueItem === dataItem;
        });
      }

      // 체크로 표시할 아이템이 존재한다면 배열의 길이는 1 이상 true, 0일 경우 false
      return {
        name: dataItem,
        isChecked: valueItems.length ? true : false,
        index: index
      };
    });
    searchItem = { position: 0, items: items };
    return searchItem;
  };

  /**
   * 사용자가 팝오버를 오픈 할 경우 현재 팝오버 상태를 저장한다
   * @param {object}
   */
  setPrevData = originData => {
    const { isCheckedAll } = this.state;
    this.prevData = {
      position: 0,
      isCheckedAll: false,
      items: []
    };
    originData.items.forEach(item => {
      this.prevData.items.push({ ...item });
    });
    this.prevData.isCheckedAll = isCheckedAll;
  };

  /**
   * @param {string} 팝업을 클로즈할때 포커스를 가지고 클로즈할지 말지에 대한 여부
   */
  handleClosePopOver = actionType => {
    // 아래 변수들은 팝오버가 close될때의 default 값
    let focus = false; // 포커스 상태
    let data = this.state.data; // 현재 data 상태
    let isCheckedAll = this.state.isCheckedAll; // 헤더 전체 체크박스 체크 상태

    switch (actionType) {
      case ACTION_TYPE.PRESS_KEY_ESC:
        data.items = this.prevData.items;
        data.position = this.prevData.position;
        isCheckedAll = this.prevData.isCheckedAll;
        focus = true;
        break;
      case ACTION_TYPE.SAVE_AND_POP_OVER:
      case ACTION_TYPE.CLICK_CANCEL_BUTTON:
        data.items = this.prevData.items;
        data.position = this.prevData.position;
        isCheckedAll = this.prevData.isCheckedAll;
        focus = true;
        this.myRefs.input.current.myRefs.input.current.focus(); // 취소 확인 버튼을 누른후 다시 포커스를 가지고 온다
        break;
      case ACTION_TYPE.SAVE_AND_MOVE_POP_OVER:
        this.handleMoveFocus("enter");
        return;
      default:
        break;
    }
    this.setState({
      isPopoverOpen: false,
      isFocused: focus,
      data: data,
      isCheckedAll: isCheckedAll
    });
    removeTrashElement();
  };

  /**
   * inputElement 생성
   */
  createInputElement = (styleInput, styleInputSpan, styleButton, disabled) => {
    const { isFocused, isPopoverOpen } = this.state;
    const { value, data, finish } = this.props;

    return (
      <InputElement
        ref={this.myRefs.input}
        isFocused={isFocused}
        isPopoverOpen={isPopoverOpen}
        styleInputSpan={styleInputSpan}
        styleInput={styleInput}
        styleButton={styleButton}
        value={value}
        data={data}
        disabled={disabled}
        finish={finish}
      />
    );
  };

  /**
   * UFOTable에서 호출할때 필요한 blur 함수 
   */
  blur = () => {
    this.setState({
      isFocused: false,
      isPopoverOpen: false
    });
  };

  /**
   * UFOTable에서 호출할때 필요한 focus 함수
   */
  focus = () => {
    this.handleFocus();
  };

  /**
   * click 이벤트에 의해 포커스를 결정한다.
   */
  handleFocus = () => {
    const { isFocused, isPopoverOpen } = this.state;
    const { onFocus, disabled, finish } = this.props;
    if (finish || disabled) {
      return;
    }
    this.setPrevData(this.state.data);
    if (!isFocused && !disabled) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.myRefs.input.current.myRefs.input.current.focus();
        this.search();
        if (onFocus) {
          onFocus();
        }
      }, 50);
    } else if (isFocused && !disabled) {
      this.setState({
        isPopoverOpen: !isPopoverOpen
      });
    }
  };

  /**
   * 모든 체크박스 아이템을 활성화 or 비활성화 한다
   * @param {boolean, event} 
   */
  handleClickCheckBoxAll = checked => {
    const { data } = this.state;
    let flag = false;
    if (checked) {
      flag = true;
    }
    const items = data.items.map(item => {
      return (item = { ...item, isChecked: flag });
    });
    this.setState({
      data: { ...data, items: items },
      isCheckedAll: checked
    });
    this.myRefs.input.current.myRefs.input.current.focus();
  };

  /**
   * 확인버튼을 클릭해서 선택을 완료하면 발생하는 이벤트
   */
  handleOk = actionType => {
    const { onChange, readonly } = this.props;
    if (onChange) {
      onChange(this.getModifiedSelectedData());
    }
    actionType = readonly ? ACTION_TYPE.PRESS_KEY_ESC : actionType
    this.handleClosePopOver(actionType);
  };

  /**
   * 선택된 아이템 오브젝트를 배열로 만들어서 리턴한다
   */
  getModifiedSelectedData = () => {
    const { data } = this.state;
    const items = data.items.filter(item => {
      return item.isChecked;
    });
    return items.map(item => {
      return item.index;
    });
  };

  /**
   * 포커스가 이동되어야 하는 시점에 발생하는 이벤트
   * @param {string} 포커스가 이동되어야 하는 방향
   */
  handleMoveFocus = (move = "enter") => {
    const { onMoveFocus } = this.props;
    this.setState(
      {
        isPopoverOpen: false
      },
      () => {
        if (onMoveFocus) {
          onMoveFocus(move);
        }
      }
    );
  };

  /**
   * 각종 키다운 이벤트에 대한 처리
   * @param {event}
   */
  handleKeyDown = e => {
    const { data, isPopoverOpen } = this.state;


    let keyName = keycode(e);

    // 팝오버 오픈 여부에 따른 key 이벤트 분기
    switch (keyName) {
      case "left":
      case "right":
      case "tab":
        if (e.getModifierState("Shift")) {
          keyName = "shift+tab";
        }
        this.handleMoveFocus(keyName);
        break;
      default:
        if (isPopoverOpen) {
          switch (keyName) {
            case "space":
              const currentPosition = data.position;
              this.handleClickCheckBoxItem(currentPosition);
              e.stopPropagation();
              e.preventDefault();
              break;
            case "up":
              this.positionMove(data.position - 1);
              this.myRefs.popover.current.myRefs.scollBody.current.scrollTop -= 28;
              e.stopPropagation();
              e.preventDefault();
              break;
            case "down":
              this.positionMove(data.position + 1);
              this.myRefs.popover.current.myRefs.scollBody.current.scrollTop += 28;
              e.stopPropagation();
              e.preventDefault();
              break;
            case "esc":
              this.handleClosePopOver(ACTION_TYPE.PRESS_KEY_ESC);
              e.stopPropagation();
              e.preventDefault();
              break;
            case "enter":
              this.handleOk(ACTION_TYPE.SAVE_AND_MOVE_POP_OVER);
              e.stopPropagation();
              e.preventDefault();
              break;
            default:
              break;
          }
        } else {
          switch (keyName) {
            case "up":
              this.handleMoveFocus(keyName);
              e.stopPropagation();
              e.preventDefault();
              break;
            case "down":
              this.handleMoveFocus(keyName);
              e.stopPropagation();
              e.preventDefault();
              break;
            case "enter":
              this.handleMoveFocus(keyName);
              e.stopPropagation();
              e.preventDefault();
              break;
            default:
              break;
          }
        }
        break;
    }
  };

  handleKeyPress = e => {
    const value = Number(String.fromCharCode(e.charCode));
    const { isPopoverOpen } = this.state;
    const { image, displayType } = this.props;
    const list = image;
    let indexs = [];
    let keyPressValue = this.state.keyPressValue;
    if (isPopoverOpen) {
      keyPressValue += `${value}`;
      (list || []).forEach((list, index) => {
        if (displayType === 'valueText') {
          if (list.value.startsWith(keyPressValue, 0)) {
            indexs.push(index);
          }
        } else if (displayType === 'text') {
          if (list.value === '' && keyPressValue === '0') indexs.push(index);
          if (list.value.startsWith(keyPressValue, 0)) {
            indexs.push(index);
          }
        } else if (displayType === 'numbertext') {
          if (index === Number(keyPressValue)) indexs.push(index);
        }
      });
      //중복이 2개이상일경우
      if (indexs.length > 1) {
        this.handleClickCheckBoxItem(indexs[0]);
        this.setState({ keyPressValue: keyPressValue });
      } else if (indexs.length === 1) { //1개일때 
        this.handleClickCheckBoxItem(indexs);
        //this.setState({ keyPressValue: '' })
      } else {
        this.setState({ keyPressValue: '' })
      }
    }
  }

  /**
   * 리스트에서 현재 postion을 이동한다 
   */
  positionMove = position => {
    const { data } = this.state;
    if (position < 0 || position >= data.items.length) {
      return;
    }
    this.setState({
      data: { ...data, position: position }
    });
  };

  /**
   * 아이템 내부에서 일어나는 블러이벤트에 대하여 처리하는 함수
   * 내부용 Blur이벤트
   */
  handleBlur = () => {
    // if 포커스가 잡혀있지만 팝오버가 닫혀있을때 블러가 일어나면 포커스를 잃는다

    // else if 포커스가 잡혀있지만 팝오버도 열려있을때 테두리쪽을 클릭하여 팝오버를
    // 닫게되면 handleBlur이벤트가 먼저 동작하게 되는데 이 경우 다시 포커스를 input
    // 쪽으로 잡아준다.
    const { isFocused, isPopoverOpen } = this.state;
    if (isFocused && !isPopoverOpen) {
      this.setState({
        isFocused: false,
        keyPressValue: ''
      });
    } else if (isFocused && isPopoverOpen) {
      this.myRefs.input.current.myRefs.input.current.focus();
    }
  };

  /**
   * 체크하고 상태를 저장하고 팝오버를 닫는으며 포커스를 유지한다
   */
  handleDoubleClick = index => {
    const { onChange, data, readonly } = this.props;
    if (readonly) {
      this.handleClosePopOver(ACTION_TYPE.PRESS_KEY_ESC);
      return;
    }
    const items = this.state.data.items;
    items[index].isChecked = true;
    this.setState(
      {
        data: { position: index, items: items },
        isCheckedAll:
          data.length === this.getCheckedItemLength() ? true : false,
        isPopoverOpen: false
      },
      () => {
        if (onChange) {
          onChange(this.getModifiedSelectedData());
        }
      }
    );
  };

  /**
   * 외부 아이템을 클릭하는 행위로써 포커스를 잃을때
   */
  handleRequestClose = () => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur();
    }
    this.setState(
      {
        isPopoverOpen: false,
        isFocused: false,
        data: {
          items: this.prevData.items,
          position: this.prevData.position
        },
        isCheckedAll: this.prevData.isCheckedAll
      },
      () => {
        this.myRefs.input.current.myRefs.input.current.blur();
      }
    );
  };

  /**
   * PopOverElement 생성
   * PopOver width 값을 받아오지 못했을 경우 input width 설정
   */
  createPopOverElement = (clientHeight) => {
    const { data, isCheckedAll, isPopoverOpen } = this.state;
    const { popOverStyle, styleUl, listAutoHeight, useImage, image } = this.props;

    return (
      <PopoverContainer
        ref={this.myRefs.popover}
        anchorEl={this.myRefs.root.current}
        data={data}
        styleUl={styleUl}
        isCheckedAll={isCheckedAll}
        popOverWidth={clientHeight}
        popOverHeigth={popOverStyle.height}
        isPopoverOpen={isPopoverOpen}
        onRequestClose={this.handleRequestClose}
        onClickCheckBoxAll={this.handleClickCheckBoxAll}
        onOk={this.handleOk}
        onClosePopOver={this.handleClosePopOver}
        onClickCheckBoxItem={this.handleClickCheckBoxItem}
        onDoubleClick={this.handleDoubleClick}
        listAutoHeight={listAutoHeight}
        useImage={useImage}
        image={image}
      />
    );
  };

  render() {
    const {
      styleInputSpan,
      styleInput,
      styleButton,
      style,
      disabled,
      finish
    } = this.props;
    const { isFocused } = this.state;
    const inputElement = this.createInputElement(
      styleInput,
      styleInputSpan,
      styleButton,
      disabled
    );
    const styles = getStyles({ disabled, finish });
    let popOverElement
    if (this.myRefs.root.current !== null) {
      let clientWidth = this.myRefs.root.current.getBoundingClientRect().width;
      popOverElement = this.createPopOverElement(String(clientWidth) + 'px');
    }
    else {
      popOverElement = this.createPopOverElement(undefined);
    }
    return (
      <div
        className={`LS_ngh_select2 ${isFocused ? "view" : "close"}`}
        ref={this.myRefs.root}
        style={Object.assign({}, styles.style, style)}
        onKeyDown={this.handleKeyDown}
        onKeyPress={this.handleKeyPress}
        onClick={this.handleFocus}
        onBlur={this.handleBlur}
      >
        {inputElement}
        {popOverElement}
      </div>
    );
  }
}

export default UFOMultiSelectField;
