/**
 * OBTPopupPanel
 * @version 0.1
 * @author 안광진
 * @see common.js
 */

import * as React from 'react';
import { Events, CompositeProps, Util, CommonProps, createPropDefinitions, CommonDefinitions, CommonType } from '../Common';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
const styles = require('./OBTPopupPanel.module.scss');

interface IOBTPopupPanel extends CompositeProps.Default, CommonProps.value<boolean>, Events.onChange<boolean> {
}

interface State extends hasError {
}

export default class OBTPopupPanel extends React.Component<IOBTPopupPanel, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        { name: "value", type: CommonType.boolean, description: "콤포넌트의 표시 유무를 정의하는 속성입니다.", default: true },
        CommonDefinitions.onChange({ type: CommonType.boolean }),
    )

    public static defaultProps = {
        frozen: false,
        value: true,
        height: '300px',
    };

    public state: State = {};

    private handleChange = (event: React.MouseEvent): void => {
        if (this.props.onChange)
            Util.invokeEvent<Events.ChangeEventArgs<boolean>>(this.props.onChange, new Events.ChangeEventArgs<boolean>(this, false));
    }

    renderComponent = () => {
        const props = {
            id: this.props.id,
            'data-orbit-component': 'OBTPopupPanel',
            height: this.props.height,
            style: Util.getWrapperStyle(this.props),
        }

        const component = <div className={Util.getClassNames(this.props.className, styles.popupPanel)} {...props} >
            <button className={styles.closeButton} onClick={this.handleChange}></button>
            {this.props.children}
        </div>;
        return <>{this.props.value ? component : <></>}</>
    }

    render() {
        return <ErrorBoundary owner={this} render={this.renderComponent} />
    }
}
