/**
 * Page Container
 * 메인 영역에서 PageContainer 호출 예시                            
// <OBTPageContainer component={menu.Component} 
//         key={menu.key} 
//         userInfo={userInfo} 
//         queryParams={queryParams} 
//         style={props.style} 
//         menuItem={menu} 
//         moduleManager={moduleManager} 
//         commonGwUtil={commonGwUtil} 
//         onBookmarkClick={this.handleItemBookmarkClick} 
//         activeMenuItem={activeMenuItem} 
//         atTab={atTab} 
//         eacDownloadModule
//         specialLnb={(this.state.specialLnb && this.state.specialLnb.moduleCode === menu.moduleCode) ? this.state.specialLnb.data : null }
// />
 * @version 0.1
 * @author 김철희
 * @see common.js
 */
import { ResizeSensor } from 'css-element-queries';
import debounce from 'lodash.debounce';
import { parse } from 'node-html-parser';
import * as React from 'react';
import { Cancellable, CommonType, createPropDefinitions, Events, Excel, Util } from '../Common';
import { OrbitInternalLangPack, setLunaRocketLanguageFromSession } from '../Common/Util';
import { OBTAlert, OBTDataGridInterface, OBTTooltip } from '../index';
import { OBTCardListInterface } from '../OBTCardList';
import OBTConfirm from '../OBTConfirm';
import OBTFloatingPanel from '../OBTFloatingPanel';
import { OBTListGridInterface } from '../OBTListGrid';
import Alert from './Alert';
import Confirm from './Confirm';
import ContentsWrapper from './ContentsWrapper';
import Drawer, { IDrawerContent } from './Drawer';
import ErrorImage from './Images/img_update.png';
import ReLogin from './Images/img_re_login.png';
import Plan01BackgroundImage from './Images/Plan01.png';
import InformationSideBar from './InformationSideBar';
import OnlineManual from './OnlineManual';
import Loading from './Loading';
import MainButtons, { IMainButton } from './MainButtons';
import { IScheduleItem } from './SBCSchedule';
import SideBar, { ISideBarContent } from './SideBar';
import Snackbar from './Snackbar';
import { IERPNumberFormatType, extractNumberFormatTypeFromUserInfo } from '../Common/OrbitInternalUtil';
import axios from 'axios';
import { Type } from '../OBTLoading/OBTLoading';

const styles = require('./OBTPageContainer.module.scss');
const conditionPanelClassName = require('../OBTConditionPanel/OBTConditionPanel.module.scss').root;
const OBTGuideMessagePanel = React.lazy(() => import('./OBTGuideMessage/OBTGuideMessagePanel'));

/**
 * 유저 프로필 팝업 오픈 위한 파라미터
 */
export interface UserProfilePopUpParameter {
    compSeq: string,
    deptSeq: string,
    empSeq: string
}

interface IDialogContent {
    title: string,
    subTitle?: string,
    type?: string,
    className?: string,
    width?: string,
    height?: string,
    buttons?: any[],
    component: any
}

/**
 * initialize 함수의 파라미터
 */
interface IPageContainerInitializer {
    title?: string | JSX.Element,
    sideButtonElement?: JSX.Element,
    mainButtons?: IMainButton[],
    hideFavoriteButton: boolean,
    useSideBar?: boolean,
    useGuideMessage?: boolean,
    useInformationSideBar?: boolean,
    useOnlineManual?: boolean,
    isSideBarCollapsed?: boolean,
    useContentPadding?: boolean,
    useFixedDrawer?: boolean,
    closeHandler?: () => boolean,
    printHandler?: (e: { target: string }) => void,
    deleteHandler?: (e: { target: string }) => void,
    activateHandler?: () => void,
    sideBarHandler?: (e: { target: any, content?: ISideBarContent[] | null }) => void,
    hasErrorPage?: boolean,
    isErpModule?: boolean
}

/**
 * 컨텍스트로 넘겨지는 정보
 */
export interface IOBTContext {
    pageAuthority: IPageAuthority,
    fetch: any,
    from: string
    containerRef: React.RefObject<any> | null,
    setSideBar: ((sideBarContents: ISideBarContent[], id?: {
        target: any,
        id: string
    }) => void) | null,
    clearSideBar: (() => void) | null,
    setLastFocusedGrid: ((grid: OBTDataGridInterface | OBTListGridInterface | OBTCardListInterface) => void) | null,
    showUserProfile: ((parameter: UserProfilePopUpParameter) => void) | null,
    guideDocument: string | null,
    guideDocumentMap: Map<string, any> | null,
    useShortCut: boolean,
    isPageVisible: boolean,
    loginUserInfo: any | null,
    menuItem: any | null,
    erpNumberFormat: IERPNumberFormatType | null
}

/**
 * 함수의 인자는 페이지 컨테이너를 거치지 않은 Consumer의 기본값
 */
export const OBTContext = React.createContext<IOBTContext>(
    {
        // 조회권한 - 컨디션패널 
        // 입력권한 - 데이터그리드, 폼패널
        // 삭제권한 - 페이지컨데이터 삭제버튼, 드로어 삭제버튼
        // 인쇄 - 페이지컨테이너 인쇄, 데이터그리드 엑셀내리기
        // 권한이 안내려오면 권한 아예안본다.
        // Util에 권한 내려온 데이터 포함한다.
        // 페이지컨테이너, 데이터그리드, 폼패널등 내려오는 권한에 대해 적용받을건지 
        pageAuthority: {
            selectAuthType: "C",
            deleteAuthYn: "Y",
            modifyAuthYn: "Y",
            printAuthYn: "Y",
        },
        fetch: Util.fetch,
        from: 'default',
        containerRef: null,
        setSideBar: null,
        clearSideBar: null,
        showUserProfile: null,
        guideDocument: null,
        guideDocumentMap: null,
        useShortCut: false,
        isPageVisible: true,
        loginUserInfo: null,
        menuItem: null,
        setLastFocusedGrid: null,
        erpNumberFormat: null
    }
);

/**
 * 페이지 조작 관련 권한
 * 아직 권한관련 쳬계가 있지는 않아서 이 인터페이스를 기준으로 작업하고 추후에 매핑작업 
 */
export interface IPageAuthority {
    /**
     * UC
     *'G'일경우, 그룹 이하 모두 조회 가능
     *'C'일경우, 해당 회사 조회 가능
     * ERP
     * 'C'일경우, 회사 이하 조회 가능
     * 'B'일경우, 사업장 이하 조회 가능
     * 'D'일경우, 부서 이하 조회 가능
     * 'U'일경우, 사용자 이하 조회 가능
     */
    selectAuthType: 'NO' | 'G' | 'C' | 'B' | 'D' | 'U',
    modifyAuthYn: "Y" | "N",
    deleteAuthYn: "Y" | "N",
    printAuthYn: "Y" | "N",
}

/**
 * 콘텐츠 영역에 넘겨주는 페이지컨테이터 컨트롤 함수 
 */
export interface IPageContainerFunctions {
    initialize: (e: IPageContainerInitializer) => void,

    // 하위 컴포넌트 호출 유틸
    showDrawer: (selection: number | any, ...drawerContents: IDrawerContent[]) => void,
    hideDrawer: () => void,
    alert: (e: IPageContainerAlert | string, type?: string) => Promise<void>,
    closeAlert: () => Promise<void>,
    error: (error: string | Error) => Promise<void>,
    closeError: () => Promise<void>,
    confirm: (e: IPageContainerConfirm) => Promise<void>,
    closeConfirm: () => Promise<void>,
    snackbar: (e: { type?: string, labelText?: string } | string) => Promise<void>,
    showLoading: (labelText?: string, option?: { key?: string, lock?: boolean, type?: Type }) => Promise<void>,
    showDataLoading: () => Promise<void>,
    hideLoading: () => Promise<void>,
    useOnlineManual: (use: boolean) => void,
    // 사이드바
    setSideBar: (...sideBarContents: ISideBarContent[] | any) => void,
    setSideBarCollapse: (collapse: boolean) => void,
    useSideBar: (use: boolean) => void,
    useInformationSideBar: (use: boolean) => void,
    setInformationSideBarRender: (callback: () => JSX.Element) => void,
    setInformationSideBarData: (data: any) => void,
    isOpenInformationSideBar: () => boolean,
    /** @deprecated */
    setSideBarContent: (key: string, content: ISideBarContent) => void,
    /** @deprecated */
    setSideBarData: (key: string, data: any) => void,
    /** @deprecated */
    setSideBarVisible: (key: string, visible: boolean) => void,
    setMainButtons: (mainButtons: IMainButton[]) => void,
    hideFavoriteButton: (hide: boolean) => void,
    setCloseHandler: (handler: (e: { close: () => void }) => boolean) => void,
    setPrintHandler: (handler: (e: { target: string }) => void) => void,
    setDeleteHandler: (handler: (e: { target: string }) => void) => void,
    setActivateHandler: (handler: () => void) => void,
    setSideBarHandler: (handler: (e: { target: any, content?: ISideBarContent[] | null }) => void) => void,
    /** @deprecated */
    connectSideBar: (grid: OBTDataGridInterface, option: {
        useGridGuide: boolean,
        useCheckPen: boolean,
        useMemo: boolean,
        useInputLog: boolean,
        useGridActionAlert: boolean,
        memoCategory?: string,
        guideList?: { name: string, guide: string | JSX.Element }[]
    }) => void,
    setDeleteButtonDisabled: (disabled: boolean) => Promise<void>,
    setPrintButtonDisabled: (disabled: boolean) => Promise<void>,
    setDeleteButtonVisible: (visible: boolean) => Promise<void>,
    setPrintButtonVisible: (visible: boolean) => Promise<void>,
    showUserProfile: (userProfileParameter: UserProfilePopUpParameter) => void,
    getLastFocusedGrid: () => OBTDataGridInterface | OBTListGridInterface | OBTCardListInterface | null,
    close: (force?: boolean) => void,

    setTitle: (title: string | JSX.Element) => void,
    getTitle: () => string | JSX.Element | null,
    isDrawerOpen: () => boolean | undefined,
    isErpModule: () => boolean | undefined
}

/**
 * 내장 Alert에 대한 타입
 */
interface IPageContainerAlert {
    type?: string,
    title?: string,
    labelText?: string,
    onClose?: (e: Events.EventArgs) => void
}

/**
 * 내장 Confirm에 대한 타입
 */
interface IPageContainerConfirm {
    type?: string,
    cancelText?: string,
    confirmText?: string,
    labelText?: string,
    onConfirm?: (e: Events.EventArgs) => void,
    onCancel?: (e: Events.EventArgs) => void
}

interface State {
    title: string | JSX.Element | null,
    containerWidth: number,
    containerHeight: number,
    mainButtons?: IMainButton[] | null,
    hideFavoriteButton?: boolean,
    useSideBar: boolean,
    useInformationSideBar: boolean,
    useOnlineManual: boolean,
    informationSideBarRender: (data?: any) => JSX.Element,
    informationSideBarData: any,
    useGuideMessage: boolean,
    isSideBarCollapsed: boolean,
    isInformationSideBarCollapsed: boolean,
    dialogContent?: IDialogContent,
    hasError: boolean,
    focusedGrid: OBTDataGridInterface | null,
    showGuideMessageEditor: boolean,
    context: IOBTContext
    viewProcess?: boolean,
    printPopUp: boolean,
    isShowUserProfile: boolean,
    userProfileParameter: UserProfilePopUpParameter | null,
    useContentPadding?: boolean,
    useFixedDrawer?: boolean,
    drawerShown?: boolean,
    extraTitleElement?: JSX.Element,
    hasErrorPage: boolean,
    lastFocusedGrid: OBTDataGridInterface | OBTListGridInterface | OBTCardListInterface | null,
    // delete 와 print 버튼은 handler 를 구현했을때만 표시해야 한다.
    hasDeleteHandler?: boolean,
    hasPrintHandler?: boolean,
    isErpModule?: boolean,
    // snackbar: {
    //     key?: string,
    //     type?: string,
    //     labelText?: string,
    // } | null,
    // loading: {
    //     key?: string,
    //     loadings: IStack[],
    // },
    // confirm: {
    //     key?: string,
    //     type?: string,
    //     title?: string,
    //     cancelText?: string,
    //     confirmText?: string,
    //     labelText?: string,
    //     onConfirm?: (e: Events.EventArgs) => void,
    //     onCancel?: (e: Events.EventArgs) => void
    // },
    // alert: {
    //     key?: string,
    //     type?: string,
    //     title?: string,
    //     labelText?: string,
    //     onClose?: (e: Events.EventArgs) => void
    // }
}

const getMessage = (type: string, ...parameters: string[]) => {
    const injectParameter = (value: string, params?: string[]) => {
        return value && params && params.length > 0 ?
            value.replace(/(\{\d+\})/g, (match, value) => {
                const index = Number(value.substr(1, value.length - 2));
                if (index >= 0 && index < params.length) {
                    return params[index];
                }
                return value;
            }) : value;
    };

    switch (type) {
        case 'search.nodata':
            return '조회된 데이터가 없습니다';
        case 'search.dirty':
            return '변경된 항목이 존재합니다. 조회 하시겠습니까?';
        case 'delete.nodata':
            return '삭제할 항목이 없습니다';
        case 'delete.ask':
            return injectParameter('{0}건의 항목을 삭제하시겠습니까?', parameters);
        case 'delete.done':
            return injectParameter('{0}건의 항목이 삭제되었습니다', parameters);
        case 'print.nodata':
            return '인쇄할 항목이 없습니다';
        case 'close.dirty':
            return '변경된 항목이 존재합니다. 닫으시겠습니까?';
        default:
            return null;
    }
}

class OBTPageContainer extends React.Component<any, State> {
    public static PropDefinitions = createPropDefinitions(
        {
            name: 'initialize', type: CommonType.function, parameters: [
                { name: 'title?', type: ['JSX.Element', 'string'] },
                { name: 'extraTitleElement?', type: ['JSX.Element'] },
                { name: 'mainButtons?', type: ['[]'] },
                { name: 'hideFavoriteButton?', type: CommonType.boolean },
                { name: 'useSidebar', type: CommonType.boolean },
                { name: 'useInformationSideBar', type: CommonType.boolean },
                { name: 'useGuideMessage?', type: CommonType.boolean },
                { name: 'isSideBarCollapsed?', type: CommonType.boolean },
                { name: 'useContentPadding?', type: CommonType.boolean },
                { name: 'closeHandler?', type: ['() => boolean'] },
                { name: 'printHandler?', type: ['(e: { target: string}) => void'] },
                { name: 'deleteHandler?', type: ['(e: { target: string }) => void'] },
                { name: 'activateHandler?', type: ['() => void'] },
                { name: 'sideBarHandler', type: ['(e: { target: any, content?: ISideBarContent[] | null}) => void'] },
            ], description: 'constructor 등에서 최초 초기화 할때 사용합니다.'
                + '\n메인버튼, 사이드바, 닫기, 인쇄, 삭제 버튼 핸들러를 지정할 수 있습니다.'
                + '\n자세한 사항은 각 항목을 지정하는 함수를 참고하십시오.'
                + '\n- useSideBar : 사이드 바 사용여부. 기본은 부 입니다.'
                + '\n- useSideBar : 인포메이션 사이드 바 사용여부. 기본은 부 입니다.'
                + '\n- useGuideMessage : 사이드바의 가이드메세지 사용여부입니다. 기본은 부입니다.'
                + '\n- isSideBarCollapsed : 사이드 바 최소화 여부. 기본은 여 입니다.'
                + '\n- useContentPadding : 컨텐츠 영역에 패딩 (20px) 을 사용하는지 여부입니다. 기본은 여 입니다.'
                + '\n- hideFavoriteButton : 즐겨찾기 버튼을 숨길지에 대한 여부입니다. 기본은 부입니다.'
        },
        {
            name: 'showDrawer', type: CommonType.function, optional: true, parameters: [
                { name: 'selection', type: ['number', 'any'] },
                { name: 'drawerContetns', type: ['{ key: string, labelText?: string, icon?: Node, imageUrl?: string, Children?: IDrawerContetn[], component?: any, visible?: boolean, important?: boolean, onClick?: (e: EventsMouseEvnetArgs) => void'] }
            ], description: '하단 Drawer 를 보여줘야 할때 호출합니다. Drawer 는 그리드나, 카드리스트 등의 체크된 항목에 대한 기능을 수행해야 할 때 표시합니다.\n'
                + '\n 1. selection : 선택된 갯수를 표시하는데 사용합니다. 숫자 형태를 주면 기본 표시형식을 따르며, React Component 를 주면 해당 컴포넌트를 표시합니다.\n'
                + '\n 2. drawerContents : Drawer 에 표시될 컴포넌트 목록입니다.'
                + '\n - key (required) : 각 컴포넌트를 구분하는 Unique Key 입니다. delete, print 는 미리 지정된 key 로 각각 삭제, 인쇄 버튼을 표시합니다.'
                + '\n - labelText : 버튼형태인 경우 라벨입니다. component 를 직접 사용할 경우 필요없습니다.'
                + '\n - icon : 버튼형태에 표시할 아이콘(SVG) 입니다.'
                + '\n - imageUrl : 버튼형태에 표시할 이미지(PNG...) 입니다.'
                + '\n - children : children 에 Drawer 컴포넌트 목록이 등록되면 부모는 SplitButton 으로 동작합니다.'
                + '\n - component : 버튼형태가 아닌 다른 컴포넌트를 직접 등록하여 사용할 수 있습니다.'
                + '\n - visible : 화면 표시 여부. Default 는 true 입니다.'
                + '\n - important : true 지정시 \'중요\'(Blue Theme) 로 지정됩니다. Default 는 false 입니다.'
                + '\n - onClick : 버튼이 클릭되었을 때 처리할 이벤트 핸들러입니다.'
        },
        { name: 'hideDrawer', type: CommonType.function, optional: true, description: '하단 Drawer를 숨겨야 할때 호출합니다.' },
        {
            name: 'alert', type: CommonType.function, optional: true, parameters: [
                { name: 'type?', type: CommonType.string },
                { name: 'labelText?', type: CommonType.string },
                { name: 'onClose?', type: CommonType.function },
            ], description: 'alert 메시지를 발생시켜야 할때 호출합니다.'
                + '\n- type : alert 타입입니다. OBTAlert.Type 을 참고하십시오. ( default, success, error, question, warning )'
                + '\n- labelText: alert 메시지를 지정합니다.'
                + '\n- onClose: alert 이 닫혔을 때 이벤트 핸들러입니다.'
        },
        {
            name: 'alert', type: CommonType.function, optional: true, parameters: [
                { name: 'labelText', type: CommonType.string },
                { name: 'type?', type: CommonType.string }
            ], description: 'alert 메세지를 발생싴야 할때 호출합니다.'
                + '\n- labelText: alert 메시지를 지정합니다.'
                + '\n- type : alert 타입입니다. OBTAlert.Type 을 참고하십시오. ( default, success, error, question, warning )'
        },
        {
            name: 'closeAlert', type: CommonType.function, result: ['Promise'], optional: true,
            description: 'alert 을 강제로 닫을 때 사용합니다. alert 의 onClose 가 호출되지 않습니다. 이후 처리를 위해 Promise 를 활용하십시오.'
        },
        {
            name: 'error', type: CommonType.function, optional: true, parameters: {
                name: 'error',
                type: ['string', 'Error']
            }, description: '오류발생시 오류 메시지를 발생시켜야 할때 호출합니다.'
                + '\nalert( type: error ) 와 함께, console.error() 로 에러를 표시합니다.'
                + '\n- error : 메시지로 지정할 문자열, Error 객체입니다.'
        },
        {
            name: 'closeError', type: CommonType.function, result: ['Promise'], optional: true,
            description: 'error alert 을 강제로 닫을 때 사용합니다. 이후 처리를 위해 Promise 를 활용하십시오.'
        },
        {
            name: 'confirm', type: CommonType.function, optional: true, parameters: [
                { name: 'type?', type: CommonType.string },
                { name: 'cancelText?', type: CommonType.string },
                { name: 'labelText?', type: CommonType.string },
                { name: 'onConfirm?', type: ['(e:Events.EventArgs) => void'] },
                { name: 'onCancel?', type: ['(e: Events.EventArgs) => void'] }
            ], description: '확인/취소 버튼을 갖는 확인 메세지를 발생시켜야 할때 호출합니다.'
                + '\n- type : OBTConfirm.Type 을 참고하십시오. ( default, success, error, question, warning )'
                + '\n- cancelText : 취소버튼의 텍스트를 지정할 때 사용합니다. ( Default : 취소 )'
                + '\n- confirmText : 확인버튼의 텍스트를 지정할 때 사용합니다. ( Default : 확인 )'
                + '\n- labelText : 메시지를 지정합니다.'
                + '\n- onConfirm : 확인버튼 클릭시 이벤트 핸들러를 지정합니다.'
                + '\n- onCancel : 취소버튼 클릭시 이벤트 핸들러를 지정합니다.'
        },
        {
            name: 'closeConfirm', type: CommonType.function, result: ['Promise'], optional: true,
            description: 'confirm 을 강제로 닫을 때 사용합니다. confirm 의 onConfirm / onCancel 이 호출되지 않습니다. 이후 처리를 위해 Promise 를 활용하십시오.'
        },
        {
            name: 'snackbar', type: ['({type?: string, labelText?: string}) : Promise<void>', '(labelText: string) : Promise<void>'], optional: true,
            description: 'snackbar 메시지를 발생시켜야 할때 호출합니다.'
                + '\n- type : OBTSnackbar.Type 을 참고하십시오. ( info, success, error, warning )'
                + '\n- labelText : 메시지를 지정합니다.'
        },
        {
            name: 'showLoading', type: CommonType.function, optional: true, result: ['Promise<void>'], parameters: {
                name: 'labelText?',
                type: CommonType.string
            }, description: 'loading 표시가 필요할 때 호출합니다.'
                + '\n- labelText : 메시지를 지정합니다.'
        },
        {
            name: 'showDataLoading', type: CommonType.function, optional: true, result: ['Promise<void>'],
            description: '데이터 불러오기 loading 표시가 필요할 때 호출합니다.'
        },
        { name: 'hideLoading', type: CommonType.function, optional: true, description: 'showLoading/showDataLoading 으로 표시된 loading 을 제거할 때 호출합니다.' },
        {
            name: 'setSideBar', type: CommonType.function, optional: true, parameters: {
                name: 'sideBarContents[]',
                type: {
                    key: { type: CommonType.string },
                    component: { type: CommonType.any },
                    visible: { type: CommonType.boolean, optional: true }
                }
            }, description: '사이드 바 Component 를 지정해야 할 때 호출합니다.'
                + '\n* sideBarContents[]'
                + '\n- key (required) : 각 컴포넌트를 구분하는 Unique Key 입니다.'
                + '\n- component : 사이드바에 표시될 컴포넌트입니다.'
                + '\n- visible : 화면 표시 여부. Default 는 true 입니다.'
        },
        {
            name: 'setSideBarCollapse', type: CommonType.function, optional: true, parameters: {
                name: 'collapse',
                type: CommonType.boolean
            }, description: '사이드 바의 접힌상태(collapsed) 를 변경할 때 사용합니다.'
        },
        {
            name: 'setMainButtons', type: CommonType.function, optional: true, parameters: [
                { name: 'key', type: CommonType.string },
                { name: 'type', type: ['default', 'primary', 'secondary', 'system'] },
                { name: 'labelText?', type: CommonType.string },
                { name: 'icon?', type: ['node'] },
                { name: 'imageUrl?', type: ['string', 'object'] },
                { name: 'primary?', type: CommonType.boolean },
                { name: 'disabled?', type: CommonType.boolean },
                { name: 'visible?', type: CommonType.boolean },
                { name: 'children?', type: ['[]'] },
                { name: 'component?', type: CommonType.any },
                { name: 'theme?', type: ['OBTButton.Theme'] },
                { name: 'onClick?', type: CommonType.function },
            ], description: '상단 주요기능, 추가기능 버튼을 설정할 때 사용합니다. 주요기능은 메인버튼 좌측에 표시되며, 그 외 추가기능은 추가기능 버튼의 팝업으로 표시됩니다.\n\n'
                + '* mainButtons : 주요기능/추가기능 에 표시될 컴포넌트 목록입니다.'
                + '\n-key (required) : 각 컴포넌트를 구분하는 Unique Key 입니다.'
                + '\n-type : primary 는 주요기능으로 라벨버튼, secondary 는 고정된 주요기능으로 아이콘-라벨버튼, system 은 아이콘버튼, default 는 기능모음버튼 내 팝업으로 표시됩니다.'
                + '\n-labelText : 버튼형태인 경우 라벨입니다. type이 system 인 경우 툴팁을 표시합니다. component 를 직접 사용할 경우 필요없습니다.'
                + '\n-icon : 버튼형태에 표시할 아이콘(SVG) 입니다.'
                + '\n-imageUrl : 버튼형태에 표시할 이미지(PNG) 이미지나 오브젝트로 설정합니다. { normal: string, over: string, press: string, disabled: string }'
                + '\n-primary : ※ 제거예정 true 면 주요기능에 표시됩니다. ( Default : false )'
                + '\n-disabled : 활성여부입니다. ( Default : false )'
                + '\n-visible : 화면 표시 여부. Default 는 true 입니다.'
                + '\n-children : children 에 컴포넌트 목록이 등록되면 부모는 SplitButton 으로 동작합니다.'
                + '\n-component : 버튼형태가 아닌 다른 컴포넌트를 직접 등록하여 사용할 수 있습니다.'
                + '\n-theme: OBTButton의 theme와 동일합니다.'
                + '\n-onClick : 버튼이 클릭되었을 때 처리할 이벤트 핸들러입니다.\n'
                + '\n※ 예약된 key'
                + '\n- systemHelp : 시스템 타입 도움버튼. children 설정할 수 있고 key에 "officeManual" 설정 시에 "사내매뉴얼" 보여짐'
        },
        {
            name: 'setDeleteButtonDisabled', type: CommonType.function, optional: true, description: '메인 버튼영역의 삭제버튼의 비활성화 여부를 지정합니다.',
            parameters: { name: 'disabled', type: CommonType.boolean }, result: ['Promise<any>']
        },
        {
            name: 'setPrintButtonDisabled', type: CommonType.function, optional: true, description: '메인 버튼영역의 프린트버튼의 비활성화 여부를 지정합니다.',
            parameters: { name: 'disabled', type: CommonType.boolean }, result: ['Promise<any>']
        },
        {
            name: 'setCloseHandler', type: CommonType.function, optional: true, parameters: {
                name: 'handler',
                type: ['() => boolean']
            }, description: '메뉴를 닫을 경우 호출되는 핸들러를 변경할 때 사용합니다. 핸들러의 return 으로 true 를 리턴하면 메뉴가 닫히지 않습니다.'
        },
        {
            name: 'setPrintHandler', type: CommonType.function, optional: true, parameters: {
                name: 'handler',
                type: ['(e: { target: string }) => void']
            }, description: '인쇄 버튼 클릭시 호출되는 핸들러를 변경할 때 사용합니다.'
                + '\n- target : \'mainButtons\', \'drawer\' 둘중 하나가 전달되며 버튼이 호출된 위치입니다.'
        },
        {
            name: 'setDeleteHandler', type: CommonType.function, optional: true, parameters: {
                name: 'handler',
                type: ['(e: { target: string }) => void']
            }, description: '삭제 버튼 클릭시 호출되는 핸들러를 변경할 때 사용합니다.'
                + '\n- target : \'mainButtons\', \'drawer\' 둘중 하나가 전달되며 버튼이 호출된 위치입니다.'
        },
        {
            name: 'close', type: CommonType.function, optional: true, parameters: {
                name: 'force?',
                type: ['(boolean) => void']
            }, description: '메뉴를 닫도록 합니다. force 를 true 로 주면 closeHandler 를 호출하지 않습니다.'
        },
        {
            name: 'getLastFocusedGrid', type: CommonType.function, optional: true, result: ['OBTDataGridInterface', 'OBTCardList'],
            description: "마지막으로 포커스된 그리드의 인스턴스를 가져옵니다."
        },
        {
            name: 'setTitle', type: CommonType.function, optional: true, parameters: [{ name: "title", type: ["string", "JSX.Element"] }],
            description: "타이틀을 설정합니다."
        },
        {
            name: 'getTitle', type: CommonType.function, optional: true, result: ["string", "JSX.Element"],
            description: "타이틀을 가져옵니다."
        }
    );

    public static UtilDefinitions = createPropDefinitions(
        {
            name: 'fetch', type: 'object', optional: true, description: 'API 호출을 위한 get/post/put/delete 메서드를 제공합니다.'
                + '\n{'
                + '\n   get: (url: string, args?: any, options?: any): Promise<any>,'
                + '\n   post: (url: string, args?: any, options?: any): Promise<any>,'
                + '\n   put: (url: string, args?: any, options?: any): Promise<any>,'
                + '\n   delete: (url: string, args?: any, options?: any): Promise<any>'
                + '\n}'
        },
        { name: 'today', type: CommonType.string, optional: true, description: '오늘날짜를 yyyyMMdd 형식으로 리턴합니다.' },
        { name: 'monthFirstDay', type: CommonType.string, optional: true, description: '오늘날짜 기준 달의 첫일을 yyyyMMdd 형식으로 리턴합니다.' },
        { name: 'monthLastDay', type: CommonType.string, optional: true, description: '오늘날짜 기준 달의 말일을 yyyyMMdd 형식으로 리턴합니다.' },
        { name: 'yearFirstDay', type: CommonType.string, optional: true, description: '오늘날짜 기준 년의 첫일을 yyyyMMdd 형식으로 리턴합니다.' },
        { name: 'yearLastDay', type: CommonType.string, optional: true, description: '오늘날짜 기준 년의 말일을 yyyyMMdd 형식으로 리턴합니다.' },
    );
    private lastTargetGrid: OBTDataGridInterface | OBTListGridInterface | OBTCardListInterface | null = null;
    public static defaultProps = {
    }

    public myRefs = {
        alert: React.createRef<Alert>(),
        confirm: React.createRef<Confirm>(),
        snackbar: React.createRef<Snackbar>(),
        loading: React.createRef<Loading>(),
        drawer: React.createRef<Drawer>(),
        sideBar: React.createRef<SideBar>(),
        mainButtons: React.createRef<MainButtons>(),
        root: React.createRef<HTMLDivElement>(),
        modalContainer: React.createRef<HTMLDivElement>(),
        contentsWrapper: React.createRef<HTMLDivElement>(),
        processView: React.createRef<HTMLDivElement>(),
        informationSideBar: React.createRef<InformationSideBar>()
    }

    public state: State = {
        title: null, // Util.safeAccessProperty(this.props, 'menuItem', 'label'),
        useSideBar: false,
        useInformationSideBar: false,
        useOnlineManual: true,
        informationSideBarRender: () => <></>,
        informationSideBarData: [],
        isSideBarCollapsed: true,
        isInformationSideBarCollapsed: true,
        containerWidth: 0,
        containerHeight: 0,
        hasError: false,
        showGuideMessageEditor: false,
        focusedGrid: null,
        useGuideMessage: false,
        context: (() => {
            const moduleCode = Util.safeAccessProperty(this.props, 'menuItem', 'moduleCode');
            const isErpMenu = (moduleCode && OBTPageContainer.isERPModule(moduleCode));

            let fetch = Util.safeAccessProperty(this.props, 'commonGwUtil', 'ajaxEbp') ?
                Util.getFetch(Util.safeAccessProperty(this.props, 'commonGwUtil', 'ajaxEbp'), this.cancellable) : Util.fetch;

            return {
                fetch: fetch,
                pageAuthority: {
                    selectAuthType: Util.safeAccessProperty(this.props, 'menuItem', 'searchAuthType') ? Util.safeAccessProperty(this.props, 'menuItem', 'searchAuthType') : 'Y',
                    modifyAuthYn: Util.safeAccessProperty(this.props, 'menuItem', 'modifyAuthYn') ? Util.safeAccessProperty(this.props, 'menuItem', 'modifyAuthYn') : 'Y',
                    printAuthYn: Util.safeAccessProperty(this.props, 'menuItem', 'printAuthYn') ? Util.safeAccessProperty(this.props, 'menuItem', 'printAuthYn') : 'Y',
                    deleteAuthYn: Util.safeAccessProperty(this.props, 'menuItem', 'deleteAuthYn') ? Util.safeAccessProperty(this.props, 'menuItem', 'deleteAuthYn') : 'Y',
                },
                from: 'PageContainer',
                containerRef: this.myRefs.modalContainer,
                setSideBar: this.setSideBar.bind(this),
                clearSideBar: this.clearSideBar.bind(this),
                showUserProfile: this.showUserProfile.bind(this),
                guideDocument: null,
                guideDocumentMap: null,
                useShortCut: typeof this.props.useShortCut === 'boolean' ? this.props.useShortCut : isErpMenu ? true : false,
                isPageVisible: true,
                loginUserInfo: this.props['userInfo'],

                menuItem: this.props.menuItem,
                setLastFocusedGrid: this.setLastFocusedGrid.bind(this),
                erpNumberFormat: extractNumberFormatTypeFromUserInfo(this.props['userInfo'])
            } as IOBTContext;
        })(),
        printPopUp: false,
        isShowUserProfile: false,
        userProfileParameter: null,
        hasErrorPage: true,
        lastFocusedGrid: null,
        hideFavoriteButton: false,
        // snackbar: null,
        // loading: {
        //     loadings: []
        // },
        // confirm: {},
        // alert: {}
    }

    setLastFocusedGrid(grid: OBTDataGridInterface | OBTListGridInterface | OBTCardListInterface) {
        if (this.state.lastFocusedGrid !== grid) {
            this.setState({
                lastFocusedGrid: grid
            })
        }
    }

    getLastFocusedGrid() {
        return this.state.lastFocusedGrid;
    }

    private resizeSensor: ResizeSensor | null = null;

    private cancellable: Cancellable = new Cancellable();

    private resizeHandler = debounce(((e) => {
        this.setState({
            containerHeight: e.height,
            containerWidth: e.width
        });
    }), 100);

    handlers: any = {
        close: null,
        print: null,
        delete: null,
        activate: null,
        sideBar: null
    };

    private pageContainerFunctions: IPageContainerFunctions = {
        initialize: this.initialize.bind(this),
        showDrawer: this.showDrawer.bind(this),
        hideDrawer: this.hideDrawer.bind(this),
        alert: this.alert.bind(this),
        closeAlert: this.closeAlert.bind(this),
        error: this.error.bind(this),
        closeError: this.closeError.bind(this),
        confirm: this.confirm.bind(this),
        closeConfirm: this.closeConfirm.bind(this),
        snackbar: this.snackbar.bind(this),
        showLoading: this.showLoading.bind(this),
        showDataLoading: this.showDataLoading.bind(this),
        hideLoading: this.hideLoading.bind(this),
        setSideBar: this.setSideBar.bind(this),
        setSideBarCollapse: this.setSideBarCollapse.bind(this),
        useSideBar: this.useSideBar.bind(this),
        useInformationSideBar: this.useInformationSideBar.bind(this),
        useOnlineManual: this.useOnlineManual.bind(this),
        setInformationSideBarRender: this.setInformationSideBarRender.bind(this),
        setInformationSideBarData: this.setInformationSideBarData.bind(this),
        isOpenInformationSideBar: this.isOpenInformationSideBar.bind(this),
        setSideBarContent: this.setSideBarContent.bind(this),
        setSideBarData: this.setSideBarData.bind(this),
        setSideBarVisible: this.setSideBarVisible.bind(this),
        setMainButtons: this.setMainButtons.bind(this),
        setCloseHandler: this.setCloseHandler.bind(this),
        setPrintHandler: this.setPrintHandler.bind(this),
        setDeleteHandler: this.setDeleteHandler.bind(this),
        setActivateHandler: this.setActivateHandler.bind(this),
        setSideBarHandler: this.setSideBarHandler.bind(this),
        setDeleteButtonDisabled: this.setDeleteButtonDisabled.bind(this),
        setPrintButtonDisabled: this.setPrintButtonDisabled.bind(this),
        setDeleteButtonVisible: this.setDeleteButtonVisible.bind(this),
        setPrintButtonVisible: this.setPrintButtonVisible.bind(this),
        showUserProfile: this.showUserProfile.bind(this),
        connectSideBar: this.connectSideBar.bind(this),
        close: this.close.bind(this),
        getLastFocusedGrid: this.getLastFocusedGrid.bind(this),
        hideFavoriteButton: this.hideFavoriteButton.bind(this),

        setTitle: this.setTitle.bind(this),
        getTitle: this.getTitle.bind(this),
        isDrawerOpen: this.isDrawerOpen.bind(this),
        isErpModule: this.isErpModule.bind(this)
    }

    /**
     * 
     * @param pageContainerFunctions 
     */
    static getTypedPageContainer(pageContainerFunctions: any): IPageContainerFunctions {
        return pageContainerFunctions as IPageContainerFunctions;
    }

    /**
     * 콘텐츠 영억에 넘겨주는 유틸
     */
    private util = (() => {
        let fetch = Util.safeAccessProperty(this.props, 'commonGwUtil', 'ajaxEbp') ?
            Util.getFetch(Util.safeAccessProperty(this.props, 'commonGwUtil', 'ajaxEbp'), this.cancellable) : Util.fetch;

        return {
            fetch: fetch,
            today: Util.getDateString(Util.DateOption.default),
            monthFirstDay: Util.getDateString(Util.DateOption.monthFirstDay),
            monthLastDay: Util.getDateString(Util.DateOption.monthLastDay),
            yearFirstDay: Util.getDateString(Util.DateOption.yearFirstDay),
            yearLastDay: Util.getDateString(Util.DateOption.yearLastDay),
            getDateString: Util.getDateString,
            getMessage: getMessage,
            fetchAgent: Util.fetchAgent,
            print: (printInfo: any) => {
                return new Promise<any>((resolve, reject) => {
                    (async () => {
                        try {
                            const version = await Util.fetchAgent.version();
                            if (!version) {
                                this.showDownloadCenter();
                            } else {
                                const result = await Util.print(printInfo);
                                resolve(result);
                            }
                        } catch (error) {
                            reject(error);
                        }
                    })();
                });
            },
            scraping: Util.getScraping(this.showDownloadCenter.bind(this)),
            getLang: Util.getLang,
            cl: Util.cl,
            chooseByLang: Util.chooseByLang,
            isSubLang: Util.isSubLang,
            messenger: Util.getMessenger(fetch, this.props['userInfo']),
            pageAuthority: this.state.context.pageAuthority
        }
    })();

    private excel: any = {
        getExcelFormData: Excel.getExcelFormData,
        parseExcel: Excel.parseExcel
    }

    shouldComponentUpdate(nextProps: any, nextState: State) {
        if (this.state.context.isPageVisible !== nextState.context.isPageVisible) {
            return true;
        }

        // 화면에서 보이지 않는다면 업데이트 하지 않음.
        if (this.props.style &&
            this.props.style.display === 'none' &&
            nextProps.style &&
            nextProps.style.display === 'none') {
            return false;
        }
        return true;
    }

    handleDebugger = (e: MessageEvent) => {
        try {
            const data = typeof e.data === 'object' ? e.data as { identifier: string, command: string, payload?: { [name: string]: any } } : undefined;
            if (data && data.identifier === 'amaranth10-debugger') {
                switch (data.command) {
                    case 'start-debug':
                        Util.setDebugMode(true);
                        this.snackbar({
                            type: 'warning',
                            labelText: '디버깅을 시작합니다.'
                        });
                        break;
                    case 'stop-debug':
                        Util.setDebugMode(false);
                        this.snackbar({
                            type: 'warning',
                            labelText: '디버깅을 종료합니다.'
                        });
                        break;
                }
            }
        } catch (error) { /* ignore error */ }
    }

    componentDidMount() {
        if (this.props['menuItem']) {
            this.props['menuItem']['ref'] = this;
        }

        if (this.myRefs.root.current) {
            this.resizeSensor = new ResizeSensor(this.myRefs.root.current, this.resizeHandler);
        }

        setLunaRocketLanguageFromSession();

        try {
            // Amaranth 10 디버거 지원
            window.addEventListener('message', this.handleDebugger);

            if (Util.getDebugMode() === true) {
                this.snackbar({
                    type: 'warning',
                    labelText: '디버깅을 시작합니다.'
                });
            }
        } catch (error) {
            /* ignore error */
        }
    }

    componentDidUpdate(prevProps: any, prevState: State) {
        const prevVisible = prevProps.style && prevProps.style.display === 'none' ? false : true;
        const visible = this.props.style && this.props.style.display === 'none' ? false : true;
        if (!prevVisible && visible) {
            if (this.myRefs.contentsWrapper.current) {
                const grids = this.myRefs.contentsWrapper.current.querySelectorAll('*[data-orbit-component="OBTDataGrid"], *[data-orbit-component="OBTListGrid"]');
                if (grids) {
                    grids.forEach(el => {
                        const gridView = (el as any).gridView;
                        if (gridView && gridView.refresh) {
                            gridView.refresh();
                        }
                    })
                }
            }
        }

        if (prevVisible !== visible) {
            this.setState({
                context: {
                    ...this.state.context,
                    isPageVisible: visible
                }
            })
        }

        if (this.state.viewProcess && this.myRefs.processView.current) {
            const current = this.myRefs.processView.current.querySelector('.current') as HTMLElement;
            if (current && current.parentElement) {
                const target = current.parentElement;
                this.myRefs.processView.current.scroll(
                    target.offsetLeft - (this.myRefs.processView.current.clientWidth / 2) + (target.clientWidth / 2),
                    target.offsetTop - (this.myRefs.processView.current.clientHeight / 2) + (target.clientHeight / 2)
                );
            }
        }

        if (this.state.useGuideMessage === true && this.state.useGuideMessage !== prevState.useGuideMessage) {
            this.fetchGuideMessage();
        }
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.error('OBTPageContainer did catch', error, errorInfo);

        this.setState({
            hasError: true,
        });
    }

    componentWillUnmount() {
        this.cancellable.cancel();
        if (this.resizeSensor) {
            this.resizeSensor.detach(this.resizeHandler);
        }
        window.removeEventListener('message', this.handleDebugger);
    }

    private callCollapseLnbByModuleCode() {
        // true 가 열린거
        const sizeTool = Util.safeAccessProperty(this.props, 'layout', 'sizeTool');
        const hasChild = Util.safeAccessProperty(this.props, 'menuItem', 'hasChild');
        const sizeToolChange = Util.safeAccessProperty(this.props, 'layoutActions', 'sizeToolChange');

        // hasChild라면 메뉴트리에서 자식을 가지고 있는 영역이라 메뉴가 열렸다는 의미가 아님
        if (sizeToolChange && hasChild === true && sizeTool !== false) {
            sizeToolChange({
                sizeTool: false
            })
        }

        const moduleCode = Util.safeAccessProperty(this.props, 'menuItem', 'moduleCode');
        const isErpMenu = (moduleCode && OBTPageContainer.isERPModule(moduleCode));

        if (sizeToolChange && sizeTool !== !isErpMenu) {
            sizeToolChange({
                sizeTool: !isErpMenu,
            })
        }
    }

    /**
     * 모듈코드가 ERP계열인지 여부 리턴
     * @param moduleCode 
     */
    public static isERPModule(moduleCode: string) {
        if (moduleCode === 'AP' || moduleCode === 'HP' || moduleCode === 'NP' || moduleCode === 'LP' || moduleCode === 'MF'
            || moduleCode === 'S' || moduleCode === 'A' || moduleCode === 'H' || moduleCode === 'CU'
            || moduleCode === 'MT' || moduleCode === 'MW' || moduleCode === 'GUIDE' || moduleCode === 'BN' || moduleCode === 'CT' || moduleCode === 'PO') {
            return true;
        }

        return false;
    }

    /**
     * menu.moduleCode, menu.pageCode
     * @param menuItem 
     */
    private getMinWidthStyleByMenuItem(menuItem: any): any {
        if (!menuItem) {
            return {
                minWidth: '1024px'
            };
        }

        if (!menuItem['moduleCode']) {
            return {
                minWidth: '1024px'
            };
        }
        // 메일 모듈에서는 절반
        if ((menuItem.moduleCode as string).toUpperCase() === 'UD') {
            return {
                minWidth: '514px'
            };
        } else {
            return {
                minWidth: '1024px'
            };
        }
    }

    /**
     * element를 popup이 아닐경우에만 렌더링한다.
     * @param otherProps 
     * @param element 
     */
    private renderOnlyNotPopup(otherProps: any, element: JSX.Element): React.ReactNode {
        if (otherProps["isPopup"] === true) {
            return null;
        }

        return (
            element
        );
    }

    private isRenderOnlyNotPopup(otherProps: any): boolean {
        if (otherProps["isPopup"] === true) {
            return false;
        }

        return true;
    }

    private handleSideBarCollapseToggleButtonClicked = () => {
        let isSideBarCollapsed = this.state.isSideBarCollapsed;
        let isInformationSideBarCollapsed = this.state.isInformationSideBarCollapsed;

        isSideBarCollapsed = !this.state.isSideBarCollapsed;
        if (isSideBarCollapsed === false) {
            isInformationSideBarCollapsed = true
        }

        this.setState({
            isSideBarCollapsed: isSideBarCollapsed,
            isInformationSideBarCollapsed: isInformationSideBarCollapsed
        }, () => {
            if (!Util.safeAccessProperty(this.props, 'menuItem', 'pageCode')) {
                return;
            }

            const pageSideBarCollapsedKey = Util.safeAccessProperty(this.props, 'menuItem', 'pageCode') + '_sidebar_collapsed'
            localStorage.setItem(pageSideBarCollapsedKey, String(this.state.isSideBarCollapsed));
        });
    }


    private handleInfomationSideBarCollapseToggleButtonClicked = () => {
        let isSideBarCollapsed = this.state.isSideBarCollapsed;
        let isInformationSideBarCollapsed = this.state.isInformationSideBarCollapsed;

        isInformationSideBarCollapsed = !this.state.isInformationSideBarCollapsed;
        if (isInformationSideBarCollapsed === false) {
            isSideBarCollapsed = true
        }

        this.setState({
            isInformationSideBarCollapsed: isInformationSideBarCollapsed,
            isSideBarCollapsed: isSideBarCollapsed
        });
    }

    /**
     * 사이드바의 메모가 추가되었을때 첫 메모이면 메모코드할당 
     */
    private handleSideBarSaveMemoButtonClicked = (memoContents: any) => {
        const targetGrid = this.state.focusedGrid;
        if (!targetGrid) {
            return;
        }

        const memoColumn = targetGrid.getColumnByName(targetGrid.gridOption.reservedColumnNames!.memoCode);
        if (memoColumn) {
            const existMemoKey = targetGrid.getValue(targetGrid.getSelectedIndex(), memoColumn.name) as string;
            if (!existMemoKey || existMemoKey.length === 0) {
                targetGrid.storeMemo(targetGrid.getSelectedIndex(), memoContents.MEMO_KEY);

                this.setSideBarData('memo', {
                    menuItem: this.props['menuItem'],
                    MEMO_KEY: memoContents.MEMO_KEY,
                    userInfo: this.props['userInfo']
                });

                targetGrid.applyInternalCellStyle(targetGrid.getSelectedIndex());
            }

            this.snackbar({
                labelText: OrbitInternalLangPack.getText('WE000028534', '메모 저장이 완료되었습니다.')
            })
        }
    }

    private handleSideBarClearMemoButtonClicked = () => {
        const targetGrid = this.state.focusedGrid;
        if (!targetGrid) {
            return;
        }

        const memoColumn = targetGrid.getColumnByName(targetGrid.gridOption.reservedColumnNames!.memoCode);
        if (memoColumn) {
            targetGrid.storeMemo(targetGrid.getSelectedIndex(), null);

            this.setSideBarData('memo', {
                menuItem: this.props['menuItem'],
                MEMO_KEY: null,
                userInfo: this.props['userInfo']
            });

            targetGrid.applyInternalCellStyle(targetGrid.getSelectedIndex());
        }
    }

    private handleSideBarScheduleItemClicked = (item: IScheduleItem) => {
        const pageOpenModuleAndPage = Util.safeAccessProperty(this.props, 'moduleManager', 'pageOpenModuleAndPage');

        if (pageOpenModuleAndPage) {
            try {
                pageOpenModuleAndPage('UE', 'UEA', 'UEA0000', {
                    // ...item,
                    date: item && item.startDate ? item.startDate.substring(0, 8) : ''
                });
            } catch (e) {
                // TODO: 예외 무시
                Util.warning('pageOpenModuleAndPage 호출중 에러');
            }
        } else {
            Util.warning('pageOpenModuleAndPage가 전달되지 않았습니다.');
        }
    };

    private handleSideBarFocus = () => {
        this.focusedItem = {
            target: this.myRefs.sideBar
        }
    }

    /**
     * 초기화
     * @param e 
     */
    private initialize(e: IPageContainerInitializer): void {
        const state: any = {};
        const moduleCode = Util.safeAccessProperty(this.props, 'menuItem', 'moduleCode');

        if (e.mainButtons !== undefined && e.mainButtons !== null) {
            if (this.myRefs.mainButtons.current) {
                this.myRefs.mainButtons.current.setMainButtons(e.mainButtons);
            } else {
                state.mainButtons = e.mainButtons;
            }
        } else {
            state.mainButtons = [];
        }

        if (e.hideFavoriteButton !== undefined) {
            state.hideFavoriteButton = e.hideFavoriteButton;
        }

        if (OBTPageContainer.isERPModule(moduleCode) && state.mainButtons) {
            const hasSystemHelp = state.mainButtons.some(button => button.key === 'systemHelp');

            if (!hasSystemHelp) {
                state.mainButtons.push({
                    key: 'systemHelp',
                    useMouseOverBackground: false,
                })
            }
        }

        if (e['sideBar'] !== undefined) {
            console.error('####### initialize({ sideBar }) has been deprecated. initialize() 함수에서 sideBar 항목은 더이상 사용되지 않습니다.');
        }

        if (e.useSideBar !== undefined) {
            state.useSideBar = e.useSideBar;
        }

        if (e.useInformationSideBar !== undefined) {
            state.useInformationSideBar = e.useInformationSideBar;
        }

        if (e.useOnlineManual !== undefined) {
            state.useOnlineManual = e.useOnlineManual;
        }

        if (e.useGuideMessage !== undefined) {
            state.useGuideMessage = e.useGuideMessage;
        }

        if (e.isSideBarCollapsed !== undefined) {
            state.isSideBarCollapsed = e.isSideBarCollapsed;
        }

        if (e.useContentPadding !== undefined) {
            state.useContentPadding = e.useContentPadding;
        }

        if (e.isErpModule !== undefined) {
            state.isErpModule = e.isErpModule;
        }

        if (Util.safeAccessProperty(this.props, 'menuItem', 'pageCode')) {
            const pageSideBarCollapsedKey = Util.safeAccessProperty(this.props, 'menuItem', 'pageCode') + '_sidebar_collapsed'

            const collapsedString = localStorage.getItem(pageSideBarCollapsedKey);

            if (collapsedString) {
                state.isSideBarCollapsed = collapsedString === 'true' ? true : false;
            }
        }

        if (e.title) {
            state.title = e.title;
        }

        if (e.closeHandler !== undefined) {
            this.setCloseHandler(e.closeHandler);
        }

        if (e.printHandler !== undefined) {
            state.hasPrintHandler = true;
            this.setPrintHandler(e.printHandler);
        }

        if (e.deleteHandler !== undefined) {
            state.hasDeleteHandler = true;
            this.setDeleteHandler(e.deleteHandler);
        }

        if (e.activateHandler !== undefined) {
            this.setActivateHandler(e.activateHandler);
        }

        if (e.sideBarHandler !== undefined) {
            this.setSideBarHandler(e.sideBarHandler);
        }

        if (e.useFixedDrawer !== undefined) {
            state.useFixedDrawer = e.useFixedDrawer;
        }

        if (e.sideButtonElement !== undefined) {
            state.extraTitleElement = e.sideButtonElement;
        }

        if (e.hasErrorPage !== undefined) {
            state.hasErrorPage = e.hasErrorPage;
        }

        if (Object.keys(state).length > 0) {
            this.setState(state);
        }
    }

    /**
     * 페이지 컨테이너 내장 alert 컨트롤
     * @param e 
     * @param type 
     */
    private alert(e: IPageContainerAlert | string, type?: string) {
        return new Promise<void>(resolve => {
            setTimeout(() => {
                this.hideLoading().then(() => {
                    if (!this.myRefs.alert.current) {
                        resolve();
                        return;
                    }

                    if (typeof e === 'string') {
                        this.myRefs.alert.current.setAlert(
                            type ? type : undefined,
                            e,
                            undefined
                        ).then(() => {
                            resolve();
                        });
                    } else {
                        this.myRefs.alert.current.setAlert(
                            type ? type : e.type,
                            e.title,
                            e.labelText,
                            e.onClose
                        ).then(() => {
                            resolve();
                        });
                    }
                })
            }, 0);
        })
    }

    private closeAlert() {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                if (this.myRefs.alert.current) {
                    this.myRefs.alert.current.close()
                        .then(() => resolve());
                }
            }, 0);
        });
    }

    private error(error: any) {
        const labelText: string = typeof error === "string" ? error :
            error.name === "OrbitRuntimeError" ? error.showMessage :
                error.resultMsg ? error.resultMsg : "처리 중 문제가 발생하였습니다.";

        console.error(error);

        return this.alert({
            type: OBTAlert.Type.error,
            labelText
        });
    }

    private closeError() {
        return this.closeAlert();
    }

    private confirm(e: IPageContainerConfirm): Promise<void> {
        return new Promise<void>(resolve => {
            setTimeout(() => {
                this.hideLoading().then(() => {
                    if (this.myRefs.confirm.current) {
                        this.myRefs.confirm.current.setConfirm(e.type, e.cancelText, e.confirmText, e.labelText, e.onConfirm, e.onCancel).then(() => {
                            resolve();
                        })
                    } else {
                        resolve();
                    }
                })
            });
        })
    }

    private closeConfirm() {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                if (this.myRefs.confirm.current) {
                    this.myRefs.confirm.current.close()
                        .then(() => resolve());
                }
            });
        });
    }

    /**
     * 내장 스낵바 컨트롤
     * @param e 
     */
    private snackbar(e: { type?: string, labelText?: string } | string): Promise<void> {
        return new Promise<void>(resolve => {
            setTimeout(() => {
                if (this.myRefs.snackbar.current) {
                    this.myRefs.snackbar.current.setSnackbar(typeof e === 'string' ? undefined : e.type, typeof e === 'string' ? e : e.labelText).then(() => { resolve(); })
                } else {
                    resolve();
                }
            });
        })
    }

    /** 
     * 내장 로딩바 컨트롤
     * @param labelText
     */
    private showLoading(labelText?: string, option?: {
        key?: string,
        lock?: boolean,
        type?: Type
    }): Promise<void> {
        return new Promise<void>(resolve => {
            setTimeout(() => {
                if (!labelText) {
                    labelText = ''
                }

                if (this.myRefs.loading.current) {
                    this.myRefs.loading.current.showLoading(labelText, option).then(() => {
                        resolve();
                    })
                } else {
                    resolve();
                }
            })
        })
    }

    /** 
     * 내장 로딩바 컨트롤
     * @param labelText
     */
    private hideLoading(option?: {
        clearAll?: boolean,
        key?: string
    }) {
        return new Promise<void>(resolve => {
            setTimeout(() => {
                if (this.myRefs.loading.current) {
                    this.myRefs.loading.current.hideLoading(option).then(() => {
                        resolve()
                    });
                } else {
                    resolve();
                }
            });
        })
    }

    private setInformationSideBarData(data: any) {
        if (this.myRefs.sideBar.current) {
            this.myRefs.sideBar.current.setInformationSideBarData(data);
        }
    }

    private setInformationSideBarRender(callback: (data?: any) => JSX.Element) {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                if (this.myRefs.sideBar.current) {
                    this.myRefs.sideBar.current.setInformationSideBarRender(callback);
                }
                resolve();
            }, 0);
        });
    }

    private isOpenInformationSideBar(): boolean {
        return !this.state.isInformationSideBarCollapsed;
    }

    private showDataLoading(option?: {
        key?: string,
        lock?: boolean,
        type?: Type
    }) {
        return this.showLoading(OrbitInternalLangPack.getText('WE000022865', '데이터를 불러오고 있습니다.'), option);
    }

    private showDrawer(selection: number | any, ...drawerContents: IDrawerContent[]): void {
        (async () => {
            if (this.state.useFixedDrawer === false) {
                await new Promise<void>(resolve => this.setState({
                    drawerShown: true
                }, () => resolve()));
            }
            if (this.myRefs.drawer.current) {
                this.myRefs.drawer.current.showDrawer(selection, ...drawerContents);
            }
        })();
    }

    private hideDrawer(): void {
        (async () => {
            if (this.state.useFixedDrawer === false) {
                await new Promise<void>(resolve => this.setState({
                    drawerShown: false
                }, () => resolve()));
            }
            if (this.myRefs.drawer.current) {
                this.myRefs.drawer.current.hideDrawer();
            }
        })();
    }

    private focusedItem: {
        target: any,
        id?: string
    } | null = null;

    private _setSideBar(target: {
        target: any,
        id: string
    } | null, sideBarContents: ISideBarContent[]): void {
        if (this.handlers.sideBar) {
            let contentsArray: ISideBarContent[] = []
            this.handlers.sideBar(target, contentsArray);
            if (contentsArray && Array.isArray(contentsArray) && contentsArray.length > 0) {
                sideBarContents = sideBarContents.concat(contentsArray);
            }
        }

        if (this.myRefs.sideBar.current) {
            this.myRefs.sideBar.current.setSideBarContents(sideBarContents);
        }
    }

    /**
     * 사이드바 컨텐츠 등록
     * 사이드바 설정 context 함수
     * @param sideBarContents 
     */
    private setSideBar(sideBarContents: ISideBarContent[], target?: {
        target: any,
        id: string
    }): void {
        this.focusedItem = target ? target : null;
        this._setSideBar(target ? target : null, sideBarContents);
    }

    private clearSideBar(id?: string) {
        this.focusedItem = null;
    }

    private setDeleteButtonDisabled(disabled: boolean) {
        if (this.myRefs.mainButtons.current) {
            return this.myRefs.mainButtons.current.setDeleteButtonDisabled(disabled);
        } else {
            return Promise.resolve();
        }
    }

    private setPrintButtonDisabled(disabled: boolean) {
        if (this.myRefs.mainButtons.current) {
            return this.myRefs.mainButtons.current.setPrintButtonDisabled(disabled);
        } else {
            return Promise.resolve();
        }
    }

    private setDeleteButtonVisible(visible: boolean) {
        if (this.myRefs.mainButtons.current) {
            return this.myRefs.mainButtons.current.setDeleteButtonVisible(visible);
        } else {
            return Promise.resolve();
        }
    }

    private setPrintButtonVisible(visible: boolean) {
        if (this.myRefs.mainButtons.current) {
            return this.myRefs.mainButtons.current.setPrintButtonVisible(visible);
        } else {
            return Promise.resolve();
        }
    }

    /**
     * 
     * @param parameter 
     */
    private showUserProfile(parameter: UserProfilePopUpParameter) {
        this.setState({
            isShowUserProfile: true,
            userProfileParameter: parameter
        });
    }

    /**
     * @deprecated
     * 그리드와 사이드바 연결
     * @param grid 사이드바에 연결할 그리드
     * @param useCheckPen 그리드와 사이드바 체크펜을 연결할 것인지 여부
     * @param useMemo 그리드와 사이드바 메모를 연결할 것인지 여부
     * @param useInputLog 그리드와 사이드바 입력자, 수정자를 연결할 것인지 여부
     * @param useGridActionAlert 
     */
    private connectSideBar(grid: OBTDataGridInterface, option: {
        useGridGuide: boolean,
        useCheckPen: boolean,
        useMemo: boolean,
        useInputLog: boolean,
        useGridActionAlert: boolean,
        memoCategory?: string,
        guideList?: { name: string, guide: string | JSX.Element }[]
    }): void {
        console.error('####### connectSideBar() has been deprecated. 이 함수는 더 이상 사용되지 않습니다.');
        this.useSideBar(true);
        return;
    }

    /**
     * 
     * @param sideBarContents 
     */
    private updateSideBars(sideBarContents: ISideBarContent[]) {
        console.error('####### updateSideBars() has been deprecated. updateSideBars() 함수는 더 이상 사용되지 않습니다.');
    }

    /**
     * 
     * @param key 
     * @param content 
     */
    private setSideBarContent(key: string, content: ISideBarContent): void {
        console.error('####### setSideBarContent() has been deprecated. setSideBarContent() 함수는 더 이상 사용되지 않습니다.');
    }

    /**
     * 
     * @param key 
     * @param data 
     */
    private setSideBarData(key: string, data: any): void {
        console.error('####### setSideBarData() has been deprecated. setSideBarData() 함수는 더 이상 사용되지 않습니다.');
    }

    /**
     * 
     * @param key 
     * @param visible 
     */
    private setSideBarVisible(key: string, visible: boolean): void {
        console.error('####### setSideBarVisible() has been deprecated. setSideBarData() 함수는 더 이상 사용되지 않습니다.');
    }

    /**
     * 사이드바 접힘 여부 설정
     * @param collapse 
     */
    private setSideBarCollapse(collapse: boolean): void {
        if (!this.myRefs.sideBar.current) {
            return;
        }

        this.setState({
            isSideBarCollapsed: collapse
        })
    }

    /**
     * 사이드바 사용 여부 설정
     * @param use
     */
    private useSideBar(use: boolean): void {
        this.setState({
            useSideBar: use
        })
    }

    /**
     * 인포메이션 사이드바 사용 여부 설정
     * @param use
     */
    private useInformationSideBar(use: boolean): void {
        this.setState({
            useInformationSideBar: use
        })
    }

    /**
     * @default true
     * 온라인메뉴얼을 사용할지에 대한 여부
     * @param use 
     */
    private useOnlineManual(use: boolean): void {
        this.setState({
            useOnlineManual: use
        })
    }

    /**
     * 상단 버튼 등록
     * @param mainButtons 
     */
    private setMainButtons(mainButtons: IMainButton[]): void {
        if (OBTPageContainer.isERPModule(Util.safeAccessProperty(this.props, 'menuItem', 'moduleCode'))) {
            const hasSystemHelp = mainButtons.some(button => button.key === 'systemHelp');

            if (!hasSystemHelp) {
                mainButtons.push({
                    key: 'systemHelp',
                    useMouseOverBackground: false,
                })
            }
        }

        if (this.myRefs.mainButtons.current) {
            this.myRefs.mainButtons.current.setMainButtons(mainButtons);
        } else {
            this.setState({
                mainButtons: mainButtons
            });
        }
    }

    private hideFavoriteButton(hide: boolean): void {
        this.setState({
            hideFavoriteButton: hide
        });
    }

    private setTitle(title: string | JSX.Element) {
        this.setState({
            title: title
        });
    }

    private getTitle(): string | JSX.Element | null {
        return this.state.title || Util.safeAccessProperty(this.props, 'menuItem', 'label');
    }

    private isDrawerOpen(): boolean | undefined {
        return this.state.drawerShown
    }

    private isErpModule(): boolean | undefined {
        return this.state.isErpModule
    }

    /**
     * 닫기 이벤트 핸들러 등록
     * @param handler 
     */
    private setCloseHandler(handler: (e: { close: () => void }) => boolean): void {
        this.handlers.close = handler;
    }

    /**
     * 프린트 이벤트 핸들러 등록
     * @param handler 
     */
    private setPrintHandler(handler?: (e: {
        target: string
    }) => void): void {
        this.handlers.print = handler;
        const hasPrintHandler = handler ? true : false;
        if (this.state.hasPrintHandler !== hasPrintHandler) {
            this.setState({
                hasPrintHandler
            });
        }
    }

    /**
     * 삭제 이벤트 핸들러 등록
     * @param handler 
     */
    private setDeleteHandler(handler?: (e: {
        target: string
    }) => void): void {
        this.handlers.delete = handler;
        const hasDeleteHandler = handler ? true : false;
        if (this.state.hasDeleteHandler !== hasDeleteHandler) {
            this.setState({
                hasDeleteHandler
            });
        }
    }

    /**
     * 
     * @param handler 
     */
    private setActivateHandler(handler: () => void): void {
        this.handlers.activate = handler;
    }

    private setSideBarHandler(handler: (e: { target: any, content?: ISideBarContent[] | null }) => void): void {
        this.handlers.sideBar = handler;
    }

    private showDialog(e: IDialogContent) {
        if (e.component && React.isValidElement(e.component)) {
            const newProp = {
                close: () => {
                    this.setState({
                        dialogContent: undefined
                    });
                }
            };
            e.component = React.cloneElement(e.component, newProp);
            this.setState({
                dialogContent: e
            });
        }
    }

    /**
     * 닫기이벤트 핸들러
     */
    public onClose(type: any): boolean {
        if (typeof this.handlers.close === 'function') {
            return this.handlers.close({
                close: () => {
                    const { moduleManager, menuItem } = this.props;
                    if (moduleManager && menuItem) {
                        moduleManager.gnbMenuActions.close({ menuItem, history: moduleManager.history, force: true, type: type });
                    }
                }
            });
        }
        return true;
    }

    /**
     * 프린트 이벤트 핸들러
     * @param e 
     */
    private handlePrint(e): void {
        if (typeof this.handlers.print === 'function') {
            this.handlers.print({
                ...e,
                targetGrid: this.state.lastFocusedGrid
            });
        }
    }

    /**
     * 삭제 이벤트 핸들러
     * @param e 
     */
    private handleDelete(e): void {
        if (typeof this.handlers.delete === 'function') {
            this.handlers.delete({
                ...e,
                targetGrid: this.state.lastFocusedGrid
            });
        }
    }

    private handleMouseMove = (e) => {
        if (this.myRefs.mainButtons.current) {
            this.myRefs.mainButtons.current.handleFunctionPanelCollapse(e);
            this.myRefs.mainButtons.current.handleHelpPanelCollapse(e);
            this.myRefs.mainButtons.current.handleSystemPanelCollapse(e);
        }
    }

    private handleMouseOut = (e) => {
        if (this.myRefs.mainButtons.current) {
            this.myRefs.mainButtons.current.collapse();
        }
    }

    private handleShortcut = (e: React.KeyboardEvent) => {
        if (this.state.context && this.state.context.useShortCut) {
            const shortCut = Util.getShortCut(e);
            if (shortCut) {
                switch (shortCut) {
                    case 'Delete':
                        const isDeleteBtnDisabled = this.myRefs.mainButtons.current && this.myRefs.mainButtons.current.getDeleteButtonDisabled();
                        if (isDeleteBtnDisabled) return;

                        if (this.myRefs.drawer.current && this.myRefs.drawer.current.visible) {
                            const deleteDrawerButton = this.myRefs.drawer.current.getButton('delete');
                            if (deleteDrawerButton && deleteDrawerButton.visible !== false) {
                                this.handleDelete({ target: 'drawer' });
                            }
                        } else if (this.myRefs.mainButtons.current) {
                            const deleteMainButton = this.myRefs.mainButtons.current.getButton('delete');
                            if (deleteMainButton && deleteMainButton.visible !== false && deleteMainButton.disabled !== true) {
                                this.handleDelete({ target: 'mainButtons' });
                            }
                        }
                        break;
                    case 'Search':
                        if (this.myRefs.root.current) {
                            const conditionPanels = this.myRefs.root.current.querySelectorAll(`.${conditionPanelClassName}`);
                            if (conditionPanels) {
                                const conditionPanel = Array.from(conditionPanels).find(conditionPanel => conditionPanel.getClientRects().length > 0);
                                if (conditionPanel) {
                                    conditionPanel.dispatchEvent(new CustomEvent('search'));
                                }
                            }
                        }
                        break;
                    case 'Print':
                        const isPrintBtnDisabled = this.myRefs.mainButtons.current && this.myRefs.mainButtons.current.getPrintButtonDisabled();
                        if (isPrintBtnDisabled) return;

                        if (this.myRefs.drawer.current && this.myRefs.drawer.current.visible) {
                            const printDrawerButton = this.myRefs.drawer.current.getButton('print');
                            if (printDrawerButton && printDrawerButton.visible !== false) {
                                this.handlePrint({ target: 'drawer' });
                            }
                        } else if (this.myRefs.mainButtons.current) {
                            const printDrawerButton = this.myRefs.mainButtons.current.getButton('print');
                            if (printDrawerButton && printDrawerButton.visible !== false && printDrawerButton.disabled !== true) {
                                this.handlePrint({ target: 'mainButtons' });
                            }
                        }
                        break;
                    case 'GuideMessage':
                        this.setState({
                            showGuideMessageEditor: true
                        });
                        break;
                }
                e.preventDefault();
                e.stopPropagation();
            }
        }
    }

    public close(force: boolean = false) {
        const { moduleManager, menuItem } = this.props;
        if (moduleManager && menuItem) {
            moduleManager.gnbMenuActions.close({ menuItem, history: moduleManager.history, force: force });
        }
    }

    private fetchGuideMessage = () => {
        if (!Util.safeAccessProperty(this.props, 'menuItem', 'moduleCode')) {
            return;
        }

        if (!Util.safeAccessProperty(this.props, 'menuItem', 'menuCode')) {
            return;
        }

        if (!Util.safeAccessProperty(this.props, 'menuItem', 'pageCode')) {
            return;
        }

        if (!OBTPageContainer.isERPModule(Util.safeAccessProperty(this.props, 'menuItem', 'moduleCode'))) {
            return;
        }

        const path = '/static/guide-messages/' + this.props['menuItem']['moduleCode'] + '/' + this.props['menuItem']['menuCode'] + '/' + this.props['menuItem']['pageCode'] + '.html';

        if (this.state.useGuideMessage === true && path) {
            axios(path)
                .then(response => {
                    if (!response || response.statusText !== "OK") {
                        return null;
                    }

                    return response.data
                }).then(text => {
                    if (!text || text.length === 0) {
                        return;
                    }

                    const parsedHtml = parse(text);

                    const resultMap = new Map<string, any>();
                    parsedHtml.querySelectorAll('.guide-message-item').forEach((elementItem) => {
                        const contentsElement = elementItem.querySelector('.guide-message-item-content');
                        if (contentsElement) {
                            const key = contentsElement.getAttribute('data-guide-id');
                            if (key) {
                                resultMap.set(key, contentsElement);
                            }
                        }
                    });

                    this.setState({
                        context: {
                            ...this.state.context,
                            guideDocument: text,
                            guideDocumentMap: resultMap
                        }
                    });
                });
        }
    }

    private handleFocus = (e: React.FocusEvent) => {
        if (!this.focusedItem) {
            this._setSideBar(null, []);
        }
    }

    private getProcessIcon = () => {
        const planClickArea = [
            {
                style: {
                    left: '43px',
                    top: '88px'
                },
                openPage: {
                    module: 'HP',
                    middleModule: 'APB1010',
                    pageCode: 'APB1010',
                    parameters: undefined
                },
                tooltip: <div>구매/비용지출/회식등의 결재를<br />취득하기위해 사전 지출품의서를<br />작성하는 화면입니다.</div>
            },
            {
                style: {
                    left: '282px',
                    top: '88px'
                },
                openPage: {
                    module: 'HP',
                    middleModule: 'APB1020',
                    pageCode: 'APB1020',
                    parameters: undefined
                },
                tooltip: <div>품의서, 매입세금계산서 등을 적용<br />받아 사후 지출결의서를 작성하는<br />화면입니다.</div>
            },
            {
                style: {
                    left: '538px',
                    top: '88px'
                },
                openPage: {
                    module: 'A',
                    middleModule: 'ACA2010',
                    pageCode: 'ACA2010',
                    parameters: undefined
                },
                tooltip: <div>결재완료된 지출결의서 내역을 자동조회하여<br />분개내역을 확인 후 전표처리하는<br />화면입니다.</div>
            },
            {
                style: {
                    left: '778px',
                    top: '88px'
                },
                openPage: {
                    module: 'A',
                    middleModule: 'ACC1030',
                    pageCode: 'ACC1030',
                    parameters: undefined
                },
                tooltip: <div>전표처리된 미결전표를 조회 후 승인처리하여<br />장부 및 재무제표에 반영시키는<br />화면입니다.</div>
            },
            {
                style: {
                    left: '538px',
                    top: '323px'
                },
                openPage: {
                    module: 'A',
                    middleModule: 'ACC1030',
                    pageCode: 'ACC1030',
                    parameters: undefined
                },
                tooltip: <div>전표처리된 미결전표를 조회 후 승인처리하여<br />장부 및 재무제표에 반영시키는<br />화면입니다.</div>
            },
            {
                style: {
                    left: '779px',
                    top: '323px'
                },
                openPage: {
                    module: 'A',
                    middleModule: 'ACA0090',
                    pageCode: 'ACA0090',
                    parameters: {
                        DIV_CD: '2000',
                        DIV_NM: '솔루션사업부문',
                        DATE_FR: '20200601',
                        DATE_TO: '20200703',
                        CTR_CD: '2',
                        CTR_NM: '세목별',
                        ZERO_YN: '0',
                        ZERO_YN_NM: 'NO'
                    }
                },
                tooltip: <div>승인처리된 전표 기준으로 계정별원장을<br />조회하는 화면입니다.</div>
            },
            {
                style: {
                    left: '778px',
                    top: '323px'
                },
                tooltip: '본 메뉴는 아직 개발 전입니다.'
            },
            {
                style: {
                    left: '43px',
                    top: '558px'
                },
                openPage: {
                    module: 'UB',
                    middleModule: 'UBA',
                    pageCode: 'UBA1100',
                    parameters: undefined
                },
                tooltip: <div>최종결재 완료된 문서를<br />조회/열람하는 화면입니다.</div>
            },
            {
                style: {
                    left: '282px',
                    top: '558px'
                },
                openPage: {
                    module: 'A',
                    middleModule: 'ACA3040',
                    pageCode: 'ACA3040',
                    parameters: {
                        DEMAND_YM1: '202005'
                    }
                },
                tooltip: <div>회사 업무 관련 경비 사용내역을<br />최상위기준, 기간별추이기준으로 조회하고<br />레포팅하는 화면입니다.</div>
            }
        ]
        const { menuItem } = this.props;
        if (menuItem && menuItem.routePath && menuItem.routePath.length >= 3) {
            const plan = planClickArea.find(plan => plan.openPage && plan.openPage.module === menuItem.routePath[0] && plan.openPage.middleModule === menuItem.routePath[1] && plan.openPage.pageCode === menuItem.routePath[2]);
            if (plan) {
                return (
                    <div className={styles.planIcon} onClick={() => this.setState({ viewProcess: !this.state.viewProcess })}>
                        <OBTFloatingPanel position={OBTFloatingPanel.Position.bottom} align={OBTFloatingPanel.Align.center} value={this.state.viewProcess ? true : false}>
                            <div className={styles.planBox}>
                                <div className={styles.planContent}>
                                    <div className={styles.planTitle}>
                                        지출품의 프로세스
                                        <div style={{ position: 'absolute', top: '13px', right: '20px', display: 'flex', alignItems: 'center', height: '20px', fontSize: '11px', color: '#888888', cursor: 'pointer' }}
                                            onClick={() => {
                                                if (this.props.moduleManager) {
                                                    this.props.moduleManager.pageOpenModuleAndPage('A', 'ACN0010', 'ACN0010', { galleryId: '01' });
                                                }
                                            }}>
                                            바로가기 ▸
                                        </div>
                                    </div>
                                    <div className={styles.planView} ref={this.myRefs.processView}>
                                        <img src={Plan01BackgroundImage} alt={''} />
                                        {planClickArea.map((area, index) => (
                                            <OBTTooltip key={index} labelText={area.tooltip} position={OBTTooltip.Position.top} className={styles.planClickArea} style={area.style}>
                                                <div className={plan === area ? 'current' : ''} style={{ width: '185px', height: '52px' }} onClick={() => {
                                                    if (area.openPage) {
                                                        if (this.props.moduleManager) {
                                                            this.props.moduleManager.pageOpenModuleAndPage(area.openPage.module, area.openPage.middleModule, area.openPage.pageCode, area.openPage.parameters);
                                                        }
                                                    } else {
                                                        this.snackbar('본 메뉴는 아직 개발 전입니다.');
                                                    }
                                                }} />
                                            </OBTTooltip>))}
                                    </div>
                                </div>
                                <div className={styles.planArrow} />
                            </div>
                        </OBTFloatingPanel>
                    </div>)
            }
        }
        return undefined;
    }

    private printPopUpCancel = () => {
        this.setState({
            printPopUp: false
        })
    }

    private printPopUpConfirm = () => {
        this.setState({
            printPopUp: false
        }, () => {
            if (this.props.agentPopUpOpen) {
                this.props.agentPopUpOpen();
            }
        })
    }

    private showDownloadCenter() {
        this.setState({
            printPopUp: true
        });
    }

    private shouldRenderSessionTimeout = () => {
        const { userInfo } = this.props;

        const COMPANY_CODE_KEY = 'companyCode';
        const USER_NAME_KEY = 'userName';

        return !userInfo
            || !userInfo.erpUserInfo
            || !userInfo.erpUserInfo[COMPANY_CODE_KEY]
            || !userInfo.erpUserInfo[USER_NAME_KEY]
    }

    render() {
        const { style, component, ...otherProps } = this.props;
        const useFloatSideBar = this.state.containerWidth <= 1032 ? true : false;
        const moduleCode = Util.safeAccessProperty(this.props, 'menuItem', 'moduleCode');
        const isErpMenu = (moduleCode && OBTPageContainer.isERPModule(moduleCode));

        if (this.state.hasError === true) {
            if (this.state.hasErrorPage === false) {
                return (<></>);
            } else {
                return (
                    <div style={{
                        width: '100%',
                        height: '100%',
                        ...this.props.style
                    }}>
                        <div className={styles.errorPageRoot}>
                            <div className={styles.errorImg}>
                                <img src={ErrorImage} alt="렌더링 에러"></img>
                            </div>
                            <div className={styles.errorTitle}>
                                {OrbitInternalLangPack.getText("WE000022689", "Amaranth 10 업데이트 안내")}
                            </div>
                            <div className={styles.errorContents}>
                                {OrbitInternalLangPack.getText("WE000022693", "그룹웨어 개선과 서비스 안정화를 위한 업데이트가 진행되었습니다.")}
                                <br />
                                {
                                    window.navigator.userAgent.indexOf("Mac") !== -1 ?
                                        OrbitInternalLangPack.getText("WE000029123", "새로고침(command+R)을 눌러 페이지를 다시 불러올 수 있습니다.") :
                                        OrbitInternalLangPack.getText("WE000022694", "새로고침(F5)를 눌러 페이지를 다시 불러올 수 있습니다.")
                                }
                                <br />
                                {OrbitInternalLangPack.getText("WE000022695", "더욱 좋은 서비스 제공을 위해 노력하겠습니다. 감사합니다.")}
                            </div>
                        </div>
                    </div>
                );
            }
        }
        if (this.shouldRenderSessionTimeout() && this.state.isErpModule) { 
            return (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        ...this.props.style
                    }}>
                    <div className={styles.errorPageRoot}>
                        <div className={styles.errorImg}>
                            <img src={ReLogin} alt="재로그인"></img>
                        </div>
                        <div className={styles.errorTitle}>
                            {OrbitInternalLangPack.getText("WE000029863", "Amaranth 10 재로그인 안내")}
                        </div>
                        <div className={styles.errorContents}>
                            {OrbitInternalLangPack.getText("WE000029864", "시스템 안정화를 위한 업데이트 진행중으로 사용자 정보를 확인할 수 없습니다.")} <br />
                            {OrbitInternalLangPack.getText("WE000029865", "로그아웃 후 다시 로그인 해주시기 바랍니다.")} <br /><br />
                            {OrbitInternalLangPack.getText("WE000030616", "※ 시스템설정 > 조직관리 > 회사관리 메뉴에서 [erp연동활성화] 버튼을 클릭하여")} <br/>
                            {OrbitInternalLangPack.getText("WE000030617", "활성화하지 않은 경우에도 '재로그인 안내' 메시지가 표시될 수 있습니다.")} <br/><br />
                            {OrbitInternalLangPack.getText("WE000001036", "감사합니다.")}
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <OBTContext.Provider value={this.state.context}>
                <div ref={this.myRefs.root}
                    className={styles.root}
                    style={this.props.style}
                    tabIndex={-1}
                    // style={Object.assign({}, this.props.style,
                    //     this.getMinWidthStyleByMenuItem(this.props.menuItem))} // 모듈별 min-width 적용 
                    onMouseMove={this.handleMouseMove}
                    onMouseOut={this.handleMouseOut}
                    onKeyDown={this.handleShortcut}
                    onFocus={this.handleFocus}
                >
                    {this.renderOnlyNotPopup(otherProps, (
                        <div className={styles.header}>
                            <div className={styles.title}>
                                <div className={styles.titleButton} />
                                <div className={styles.titleText}>
                                    {/* {this.state.title} */}
                                    {this.state.title ? this.state.title : Util.safeAccessProperty(this.props, 'menuItem', 'label')}
                                </div>

                                {/* 온라인 메뉴얼 */}
                                {this.state.useOnlineManual !== false ? <OnlineManual menuItem={this.props.menuItem} /> : <></>}

                                {/* {processIcon} */}
                                <div className={styles.titleBlank} />
                            </div>
                            <div>
                                {this.state.extraTitleElement}
                            </div>
                            <div className={styles.mainButtons}>
                                <MainButtons
                                    ref={this.myRefs.mainButtons}
                                    owner={this}
                                    mainButtons={this.state.mainButtons}
                                    pageAuthority={this.state.context.pageAuthority}
                                    onBookmarkClick={this.props.onBookmarkClick}
                                    hideFavoriteButton={this.state.hideFavoriteButton}
                                    isErpModule={OBTPageContainer.isERPModule(Util.safeAccessProperty(this.props, 'menuItem', 'moduleCode'))}
                                    menuItem={this.props.menuItem}
                                    hasDeleteHandler={this.state.hasDeleteHandler}
                                    hasPrintHandler={this.state.hasPrintHandler}
                                />
                            </div>
                        </div>
                    ))}
                    <div className={Util.getClassNames(styles.section, useFloatSideBar ? styles.floatSideBar : undefined, this.state.drawerShown ? styles.drawerShown : undefined)}>
                        <div ref={this.myRefs.contentsWrapper} className={Util.getClassNames(styles.contentsWrapper, this.state.useContentPadding === false ? styles.noContentPadding : undefined)} tabIndex={-1}>
                            <div className={otherProps["isPopup"] === true ? styles.popupContents : styles.contents}>
                                {/* content component 영역 */}
                                <ContentsWrapper
                                    component={
                                        <this.props.component {...otherProps}
                                            pageContainer={this.pageContainerFunctions}
                                            userInfo={this.props['userInfo'] || {}}
                                            util={this.util}
                                            excel={this.excel}
                                        />
                                    }
                                    specialLnb={this.props.specialLnb}
                                    menuInfo={this.props.menuItem}
                                />
                            </div>
                        </div>
                        {this.renderOnlyNotPopup(otherProps, (
                            <SideBar
                                ref={this.myRefs.sideBar}
                                use={this.state.useSideBar === undefined ? false : this.state.useSideBar}
                                fetch={this.util.fetch}
                                collapse={this.state.isSideBarCollapsed}
                                informationCollapse={this.state.isInformationSideBarCollapsed}
                                userInfo={this.props['userInfo']}
                                menuItem={this.props['menuItem']}
                                UpDownloader={Util.safeAccessProperty(this.props, 'commonGwUtil', 'UAA0050AUTH', 'UpDownloader')}
                                userDetailDialog={Util.safeAccessProperty(this.props, 'commonGwUtil', 'UAAP012')}
                                pageContainerFunctions={this.pageContainerFunctions}
                                useFloatSideBar={useFloatSideBar}
                                useInformationSideBar={this.state.useInformationSideBar === undefined ? false : this.state.useInformationSideBar}
                                isRenderOnlyNotPop={this.isRenderOnlyNotPopup(otherProps)}
                                onToggleCollapseButtonClicked={this.handleSideBarCollapseToggleButtonClicked}
                                onToggleInformationCollapseButtonClicked={this.handleInfomationSideBarCollapseToggleButtonClicked}
                                onScheduleItemClicked={this.handleSideBarScheduleItemClicked}
                                onFocus={this.handleSideBarFocus}
                            />
                        ))}
                    </div>

                    <Drawer owner={this}
                        ref={this.myRefs.drawer}
                        containerWidth={this.state.containerWidth + "px"}
                        pageAuthority={this.state.context.pageAuthority}
                    />

                    <Alert ref={this.myRefs.alert} />
                    <Confirm ref={this.myRefs.confirm} />
                    <Snackbar ref={this.myRefs.snackbar} />
                    <Loading ref={this.myRefs.loading} />

                    {this.state.printPopUp ?
                        <OBTConfirm
                            labelText={<>Amaranth 10 필수 에이전트의 <br /> 설치 혹은 업데이트가 필요합니다.</>}
                            type={OBTConfirm.Type.warning}
                            onCancel={this.printPopUpCancel}
                            onConfirm={this.printPopUpConfirm}
                        />
                        : undefined}
                    <div className={styles.modalContainer} ref={this.myRefs.modalContainer}>
                    </div>
                </div>
                {this.state.showGuideMessageEditor ?
                    <React.Suspense fallback={''}>
                        <OBTGuideMessagePanel
                            style={this.props.style}
                            menuItem={this.props.menuItem}
                            targets={[this.myRefs.contentsWrapper, this.myRefs.modalContainer]}
                            onClose={() => this.setState({ showGuideMessageEditor: false })}
                            guideDocument={this.state.context.guideDocument}
                        />
                    </React.Suspense> : undefined}

                {
                    this.state.isShowUserProfile === true &&
                        this.state.userProfileParameter &&
                        Util.safeAccessProperty(this.props, 'commonGwUtil', 'UAAP012') ? (
                            <this.props.commonGwUtil.UAAP012
                                compSeq={this.state.userProfileParameter.compSeq}
                                deptSeq={this.state.userProfileParameter.deptSeq}
                                empSeq={this.state.userProfileParameter.empSeq}
                                onClickClose={(isShow: boolean) => {
                                    this.setState({
                                        isShowUserProfile: isShow,
                                    })
                                }}
                            />
                        ) : null
                }
            </OBTContext.Provider>
        );
    }
};

const withOBTPageContainer = (Component: React.Component, otherProps: any) => {
    return class extends React.Component {
        render() {
            return <OBTPageContainer component={Component} {...this.props} {...otherProps} />
        }
    }
}

// const withOBTContext = function <T>(Component: React.ComponentType<T>) {
//     return class extends React.Component<T> {
//         render() {
//             return (
//                 <OBTContext.Consumer>
//                     {obtContext => <Component {...this.props} obtContext={obtContext} />}
//                 </OBTContext.Consumer>
//             )
//         }
//     }
// }

export default OBTPageContainer;
export { withOBTPageContainer };

