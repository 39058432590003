/**
 * CodePickerController
 * 코드피커를 이루는 각 베이스 컴포넌트의 컨테이너
 * @version 0.1
 * @author 나윤수
 * @see common.js
 */
import React, { Component } from 'react';
import uuidv4 from 'uuid/v4';
import { CompositeProps, Events, Util } from '../../Common';
import { IFocusable, IRequired } from '../../Common/Functions';
import { Fetch } from '../../Common/Util';
import { ReadEventArgs } from '../../OBTDataGrid/OBTDataGridEvents';
import Pagination, { makePagination } from '../../OBTDataGrid/Pagination';
import OBTLoading from '../../OBTLoading';
import CodePickerUtil from '../CodePickerUtil';
import IBuiltInCodePicker, { IResponseData, ICodePickerGetDataEventArgs } from '../DataSource/IBuiltInCodePicker';
import { CodePickerDisplayItemEventArgs } from '../Events/CodePickerDisplayItemEventArgs';
import { CodePickerSearchEventArgs } from '../Events/CodePickerSearchEventArgs';
import { CodePickerValue } from '../OBTCodePicker';
import { CodePickerInput } from './CodePickerInput';
import DefaultDialog, { IDefaultDialog } from './DefaultDialog';
import { Type } from '../../OBTLoading/OBTLoading';

const styles = require('./CodePickerInput.module.scss');

interface ICodePickerController extends CompositeProps.CodePickerClass, Events.onMouseDown,
    Events.onMouseUp, Events.onMouseLeave, Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress, Events.onKeyUp, Events.onMouseMove, Events.onClick {
    /**
     * 
     */
    parameters: any,

    /**
     * 
     */
    codePicker: IBuiltInCodePicker;

    /**
     * 인풋에 입력될 수 있는 최대값
     */
    inputValueMaxLength?: number,

    /**
     * 한번 읽어온 데이터를 캐시할것인지 여부
     */
    isCacheDataSource: boolean,

    /**
     * 멀티선택 가능여부
     */
    canMultiSelect: boolean,

    /**
     * 
     */
    tooltip?: any,

    /**
     * 
     */
    fetch: any;

    useShortCut: boolean,

    /**
     * @default true
     * 코드피커 검색 시에 로딩을 띄울지에 대한 여부
     */
    useLoading?: boolean,

    /**
     * @default default
     * 코드피커 로딩 타입 정의
     */
    loadingType?: Type,

    /**
     *  default: false
     */
    useTotalToEmpty?: boolean,

    showUserProfileIcon: boolean,
    
    /**
     * @default true
     * 오직 다이얼로그로만 값을 검색해볼지에 대한 여부. false 시에 키보드 입력은 불가.
     */
    allowKeyboardInput?: boolean,

    /**
     * 인풋에 선택한 데이터가 표현되는 전략
     */
    onDisplaySelectedItem?: (evt: CodePickerDisplayItemEventArgs) => string,

    /**
    * 
    */
    onUserProfileClicked?: (item: any) => void,

    /**
     * 
     */
    dialogParameters?: (() => any)

    /**
     * 
     */
    onBeforeCallCodePicker?: ((e: {
        target: any,
        cancel: boolean,
        keyword: string | null
    }) => void),
}

interface IState {
    /**
     * 
     */
    dropDownListItems: any[],

    /**
     * 
     */
    dropDownListSelectedItem: any | null,

    /**
     * 
     */
    dropDownLoading: boolean,

    dialogGridDataItems: any[],

    dialogGridDataItemsKey: string,

    /**
     * 현재 데이터를 로딩중인지 여부
     */
    isDataItemLoading: boolean,

    /**
     * 다이얼로그가 보여지는지 여부
     */
    isShowDialog: boolean,

    /**
     * 인풋에 바인딩되는 문자열
     */
    inputValue: string,

    /**
     * true: 선택된 데이터가 있어 인풋에 값이 바인딩된 상태
     * false: 유저가 무언가 값을 입력해 인풋값을 직적조작하고 있는상태
     */
    isSelectedItemsDisplayed: boolean,

    tempSelectedItem: any[],

    /**
     * 
     */
    keyword: string | null,

    /**
     * 데이터 선택후 moveFocus
     */
    moveFocusContext?: {
        prevProps: any[] | CodePickerValue,
        nextProps: any[] | CodePickerValue
    }
}

export default class CodePickerController extends Component<ICodePickerController, IState> implements IFocusable, IRequired {
    private inputRef = React.createRef<CodePickerInput>();

    private dropDownListPagination: Pagination | null = null;

    private valueCache: any[] | CodePickerValue | null = null;

    public static defaultProps = {
        disabled: false,
        readonly: false,
        required: false,
        inputValueMaxLength: 30,
        isCacheDataSource: false,
        canMultiSelect: false,
        useTotalToEmpty: false,
        loadingType: Type.default,
        onDisplaySelectedItem: (evt: CodePickerDisplayItemEventArgs) => {
            let result = '';
            if (evt.selectedItems
                && evt.selectedItems.length === 0
                && evt.exceptValue
                && evt.useTotalToEmpty === true) {
                return '전체선택';
            }

            const mainItem = evt.selectedItems.length > 0 ? evt.selectedItems[0] : null;
            if (evt.selectedItems.length === 1) {
                result = [(evt.codeProperty ? mainItem![evt.codeProperty] : ''), (evt.textProperty ? mainItem![evt.textProperty] : '')].join('. ')
            } else if (evt.selectedItems.length >= 2) {
                result = [(evt.codeProperty ? mainItem![evt.codeProperty] : ''), (evt.textProperty ? mainItem![evt.textProperty] : '')].join('. ') + ' 외 ' + (evt.selectedItems.length - 1) + '건';
            } else {
                result = '';
            }

            if (result.trim() === '.') {
                result = '';
            }

            return result;
        }
    };

    state: IState = {
        isShowDialog: false,
        inputValue: '',
        isSelectedItemsDisplayed: true,
        keyword: null,
        dropDownListItems: [],
        dropDownLoading: false,
        isDataItemLoading: false,
        dialogGridDataItems: [],
        tempSelectedItem: [],
        dialogGridDataItemsKey: uuidv4(),
        dropDownListSelectedItem: null,
    }

    componentDidMount() {
        this.changeNotArrayValueToArray();
    }

    componentDidUpdate(prevProps: ICodePickerController, prevState: IState) {
        // 다이얼로그에서 데이터 선택시 바로 moveFocus
        if (this.state.moveFocusContext &&
            prevProps.value === this.state.moveFocusContext.prevProps &&
            this.props.value === this.state.moveFocusContext.nextProps) {
            this.setState({
                moveFocusContext: undefined
            }, () => {
                if (this.props.onMoveFocus) {
                    this.props.onMoveFocus(new Events.MoveFocusEventArgs(this, 'enter'));
                }
            });
        }

        this.changeNotArrayValueToArray();
    }

    /**
     * 벨류로 배열 이외의 값이 들어오면 배열형태로 onChange시킨다.
     */
    private changeNotArrayValueToArray = () => {
        let value: any[] | CodePickerValue | string | undefined | null = this.props.value;

        if (this.props.useTotalToEmpty === true && !value) {
            Util.invokeEvent<Events.CodePickerChangeEventArgs>(
                this.props.onChange,
                new Events.CodePickerChangeEventArgs(this, CodePickerValue.default(), undefined, true)
            );

        } else if (this.props.useTotalToEmpty === true && Array.isArray(this.props.value)) {
            Util.invokeEvent<Events.CodePickerChangeEventArgs>(
                this.props.onChange,
                new Events.CodePickerChangeEventArgs(this,
                    new CodePickerValue(false, this.props.value, null),
                    undefined,
                    true
                )
            );
        } else if (this.props.useTotalToEmpty === true && !(this.props.value instanceof CodePickerValue) && CodePickerValue.canConvert(this.props.value)) {
            Util.invokeEvent<Events.CodePickerChangeEventArgs>(
                this.props.onChange,
                new Events.CodePickerChangeEventArgs(this,
                    CodePickerValue.valueOf(this.props.value),
                    null,
                    this.props.useTotalToEmpty)
            );
        } else if (this.props.useTotalToEmpty !== true && !value) {
            Util.invokeEvent<Events.CodePickerChangeEventArgs>(
                this.props.onChange,
                new Events.CodePickerChangeEventArgs(this, [])
            );

        } else if (this.props.useTotalToEmpty !== true && !Array.isArray(value) && typeof value === 'object' && !(value instanceof CodePickerValue)) {
            Util.invokeEvent<Events.CodePickerChangeEventArgs>(
                this.props.onChange,
                new Events.CodePickerChangeEventArgs(this, [value])
            );
        } else if (typeof value === 'string' && String(value).length === 0) {
            const value = this.props.useTotalToEmpty === true ? CodePickerValue.default() : []
            Util.invokeEvent<Events.CodePickerChangeEventArgs>(
                this.props.onChange,
                new Events.CodePickerChangeEventArgs(this, value)
            );

        } else if (this.state.isDataItemLoading === false && typeof value === 'string' && this.props.codePicker.getData) {
            makePagination({
                initialRowCount: this.props.codePicker.initialRowCount ? this.props.codePicker.initialRowCount : this.props.codePicker.rowCountPerPage!,
                rowCountPerPage: this.props.codePicker.rowCountPerPage!,
                pagingDirection: 'scrollToBottom',
            }).then(pagingInfo => {
                const parameter = {
                    fetch: this.props.fetch,
                    parameters: this.props.parameters ? this.props.parameters : undefined,
                    keyword: value as string,
                    pagingInfo: pagingInfo
                };

                this.setState({
                    isDataItemLoading: true,
                }, () => {
                    this.props.codePicker.getData!(CodePickerUtil.trimObject(parameter)).then((item) => {
                        const filterd = item.data.filter((i) => i[this.props.codePicker.codeProperty] === value);

                        if (filterd && filterd.length >= 1) {
                            const value = this.props.useTotalToEmpty === true ?
                                new CodePickerValue(
                                    false,
                                    [filterd[0]],
                                    null
                                ) : [filterd[0]]

                            Util.invokeEvent<Events.CodePickerChangeEventArgs>(
                                this.props.onChange,
                                new Events.CodePickerChangeEventArgs(this, value)
                            );
                        } else {
                            const value = this.props.useTotalToEmpty === true ? CodePickerValue.default() : []
                            Util.invokeEvent<Events.CodePickerChangeEventArgs>(
                                this.props.onChange,
                                new Events.CodePickerChangeEventArgs(this, value)
                            );
                        }
                    }).finally(() => {
                        this.setState({
                            isDataItemLoading: false,
                        })
                    })
                })
            });
        }
    }

    // public method

    public focus(isLast?: boolean | undefined): void {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    public isEmpty(): boolean {
        if (this.props.value instanceof CodePickerValue) {
            return this.props.value.isEmpty();
        }

        if (Array.isArray(this.props.value)) {
            return (this.props.value.length === 0);
        }

        return true;
    }

    public validate(): boolean {
        return !(this.props.required && this.isEmpty());
    }

    private get canEdit(): boolean {
        return !(this.props.readonly || this.props.disabled || this.props.frozen);
    }

    /**
     * 페이징 전체선택인지 여부 리턴
     */
    public isSelectedTotalOnPaging = () => {
        return this.props.codePicker.paging === true &&
            this.props.canMultiSelect === true &&
            this.props.value instanceof CodePickerValue &&
            this.props.value.isTotalSelect === true;
    }

    /**
     * 인풋에 바인딩되는 텍스트를 생성한다.
     */
    private makeInputValue = () => {
        if (!this.props.onDisplaySelectedItem) {
            return ''
        } else if (this.state.isSelectedItemsDisplayed === false) {
            return this.state.inputValue;
        } else if (this.isEmpty()) {
            return ''
        } else {
            const value = this.props.value instanceof CodePickerValue ? this.props.value.value : this.props.value;
            const exceptValue = this.props.value instanceof CodePickerValue ? this.props.value.exceptValue : null;

            const displayText = this.props.onDisplaySelectedItem(
                new CodePickerDisplayItemEventArgs(
                    this,
                    this.props.codePicker.codeProperty,
                    this.props.codePicker.textProperty,
                    (value || []),
                    (value || []).length,
                    (value || []).length,
                    this.props.useTotalToEmpty === undefined ? false : this.props.useTotalToEmpty,
                    exceptValue ? exceptValue : null,
                )
            );

            return displayText;
        }
    }

    /**
     * 다이얼로그 오픈 버튼 클릭 이벤트 핸들러
     * @param evt 
     */
    private handleInputActionButtonClick = (evt: Events.EventArgs) => {
        this.openDialog();
    }

    private checkBeforeOpenCodePicker(keyword: string | null) {
        if (this.props.onBeforeCallCodePicker) {
            const eventArgs = {
                target: this,
                cancel: false,
                keyword: keyword
            }

            this.props.onBeforeCallCodePicker(eventArgs);
            if (eventArgs.cancel === true) {
                return false;
            }
        }

        return true;
    }

    /**
     * 공통이벤트 포커스
     */
    private handleFocus = (evt: Events.FocusEventArgs) => {
        if (this.props.onFocus) {
            this.props.onFocus(evt);
        }
    }

    /**
     * 
     * @param evt 
     */
    private handleInputMoveFocus = (evt: Events.MoveFocusEventArgs) => {
        if (evt.direction === 'enter') {
            if (this.state.isSelectedItemsDisplayed === false && this.state.inputValue.length > 0) {

                this.handleSearchEnter(new CodePickerSearchEventArgs(
                    this,
                    "input",
                    this.state.inputValue,
                    null,

                ));

                return;
            }
        }
        if (this.props.onMoveFocus) {
            this.props.onMoveFocus(evt);
        }
    }

    /**
     * 인풋에 검색어 입력후 엔터
     */
    handleSearchEnter = (e: CodePickerSearchEventArgs) => {
        if (!e.keyword || e.keyword.trim().length === 0) {
            // 빈값으로 검색시 선택 아이템 클리어
            this.setState({
                isSelectedItemsDisplayed: true,
            }, () => {
                Util.invokeEvent<Events.CodePickerChangeEventArgs>(
                    this.props.onChange,
                    new Events.CodePickerChangeEventArgs(this, [])
                );
            });
        } else if (e.keyword && e.keyword.trim().length > 0 && this.props.codePicker && this.props.codePicker.paging === true) {

            makePagination({
                initialRowCount: this.props.codePicker.initialRowCount ? this.props.codePicker.initialRowCount : this.props.codePicker.rowCountPerPage!,
                rowCountPerPage: this.props.codePicker.rowCountPerPage!,
                pagingDirection: 'scrollToBottom',

            }).then((pagination) => {
                const parameters = this.makeParameters(this.props.parameters, this.getFetch(), e.keyword) as ICodePickerGetDataEventArgs;

                this.props.codePicker.getData!(CodePickerUtil.trimObject({ ...parameters, pagingInfo: pagination })).then((response) => {
                    if (response.data && response.data.length === 1) {
                        this.setState({
                            isSelectedItemsDisplayed: true,
                        }, () => {
                            Util.invokeEvent<Events.CodePickerChangeEventArgs>(
                                this.props.onChange,
                                new Events.CodePickerChangeEventArgs(this, response.data)
                            );
                        })
                        return;
                    } else {
                        if (this.checkBeforeOpenCodePicker(e.keyword || null)) {
                            this.setState({
                                keyword: e.keyword || null,
                                isShowDialog: true,
                                dialogGridDataItemsKey: uuidv4(),
                            });
                        }
                    }
                }).finally(() => {
                    this.setState({
                        isDataItemLoading: false
                    });
                })
            });
        } else if (e.keyword && e.keyword.trim().length > 0 && !this.props.codePicker.getData) {
            if (this.checkBeforeOpenCodePicker(e.keyword)) {
                // getData가 정의되어 있지 않으면 다이얼로그 열고 리턴
                // 일반적인 그리드형태를 따르지 않는 커스텀 다이얼로그등
                this.setState({
                    keyword: e.keyword,
                    isShowDialog: true,
                });
            }
        } else {
            if (this.props.codePicker.getData && this.checkBeforeOpenCodePicker(e.keyword)) {
                const parameters = this.makeParameters(this.props.parameters, this.getFetch(), e.keyword);

                this.setState({
                    isDataItemLoading: true,
                }, () => {
                    this.props.codePicker.getData!(CodePickerUtil.trimObject(parameters)).then((response) => {
                        if (response.data && response.data.length === 1) {
                            this.setState({
                                isSelectedItemsDisplayed: true,
                            }, () => {
                                Util.invokeEvent<Events.CodePickerChangeEventArgs>(
                                    this.props.onChange,
                                    new Events.CodePickerChangeEventArgs(this, response.data)
                                );
                            })
                        } else if (response.data && response.data.length > 1 && e.keyword) {
                            this.setState({
                                keyword: e.keyword,
                                dialogGridDataItemsKey: uuidv4(),
                                dialogGridDataItems: response.data,
                                isShowDialog: true,
                            })
                        } else {
                            this.setState({
                                inputValue: this.makeInputValue(),
                                isSelectedItemsDisplayed: true
                            })
                        }
                    }).finally(() => {
                        this.setState({
                            isDataItemLoading: false
                        });
                    })
                })
            }
        }
    }

    /**
     * 인풋 컨트롤 Change이벤트 핸들러
     * @param evt
     */
    private handleInputTextChange = (e: Events.ChangeEventArgs<string>) => {
        if (e.value === '') {
            this.setState({
                isShowDialog: false,
                isSelectedItemsDisplayed: true,
                keyword: null
            }, () => {
                Util.invokeEvent<Events.CodePickerChangeEventArgs>(
                    this.props.onChange,
                    new Events.CodePickerChangeEventArgs(this, [])
                );

                this.focus();
            });
        } else {
            this.setState({
                inputValue: e.value,
                isSelectedItemsDisplayed: false,
            });
        }
    }

    /**
     * 코드피커 인풋 Blur 이벤트 핸들러
     * @param evt
     */
    private handleInputBlur = (evt: Events.EventArgs) => {
        if (this.props.onBlur) {
            this.props.onBlur(evt)
        }

        this.setState({
            inputValue: this.isEmpty() ? '' : this.makeInputValue(),
            isSelectedItemsDisplayed: true
        });
    }

    private handleClickDropDownItem = (item: any) => {
        if (this.props.useTotalToEmpty && this.props.value instanceof CodePickerValue) {
            const value = this.props.value as CodePickerValue;

            if (value.isTotalSelect === true) {
                this.valueCache = this.props.value;
            } else {
                if (value.value && value.value.length >= 2) {
                    this.valueCache = this.props.value;
                }
            }
        } else if (this.props.useTotalToEmpty !== true) {
            const value = this.props.value as any[];

            if (value.length >= 2) {
                this.valueCache = this.props.value;
            }
        }

        this.setState({
            dropDownListSelectedItem: item,
        })
        Util.invokeEvent<Events.CodePickerChangeEventArgs>(
            this.props.onChange,
            new Events.CodePickerChangeEventArgs(
                this,
                this.props.useTotalToEmpty ? new CodePickerValue(false, [item], null) : [item],
                undefined,
                this.props.useTotalToEmpty
            )
        );
    }

    private handleClickDropDownSelectAll = (items: any[]) => {
        this.setState({
            dropDownListSelectedItem: null,
        }, () => {
            Util.invokeEvent<Events.CodePickerChangeEventArgs>(
                this.props.onChange,
                new Events.CodePickerChangeEventArgs(this,
                    this.valueCache ? this.valueCache : this.props.useTotalToEmpty ? new CodePickerValue(false, [], null) : [],
                    undefined,
                    this.props.useTotalToEmpty)
            );
        })
    }

    private handleDropDownListRemoveAllClick = () => {
        this.setState({
            dropDownListSelectedItem: null,
        }, () => {
            Util.invokeEvent<Events.CodePickerChangeEventArgs>(
                this.props.onChange,
                new Events.CodePickerChangeEventArgs(this,
                    this.props.useTotalToEmpty ? new CodePickerValue(false, [], null) : [],
                    undefined,
                    this.props.useTotalToEmpty)
            );
        })
    }

    private handleDropDownListRemoveItemClick = (removedItem: any) => {
        this.setState({
            dropDownListItems: this.state.dropDownListItems.filter((item) => {
                return (removedItem[this.props.codePicker.codeProperty] !== item[this.props.codePicker.codeProperty])
            }),
        }, () => {
            const value = (this.props.value instanceof CodePickerValue ? this.props.value.value : this.props.value) || [];
            const valueWithoutRemoved = value.filter((item) => {
                return (removedItem[this.props.codePicker.codeProperty] !== item[this.props.codePicker.codeProperty])
            });

            Util.invokeEvent<Events.CodePickerChangeEventArgs>(
                this.props.onChange,
                new Events.CodePickerChangeEventArgs(this,
                    this.props.useTotalToEmpty === true && this.props.value instanceof CodePickerValue ? new CodePickerValue(
                        this.props.value.isTotalSelect,
                        this.props.value.isTotalSelect ? null : valueWithoutRemoved,
                        this.props.value.isTotalSelect ? Util.nullToEmpty(this.props.value.exceptValue).concat(removedItem) : null
                    ) : valueWithoutRemoved,
                    undefined,
                    this.props.useTotalToEmpty
                )
            );
        })
    }

    private handleShowDropDownList = () => {
        if (this.state.dropDownListSelectedItem) {
            return Promise.resolve([]);
        }

        if (this.props.value instanceof CodePickerValue && this.props.value.isTotalSelect) {
            if (!this.props.codePicker.getData) {
                throw new Error('코드피커의 getData가 설정되어있지 않습니다.')
            }

            // 전체선택인 상황에서 드랍다운을 열었을 때, Pagination을 생성후 getData를 호출해 바인딩한다.
            return new Promise<any[]>((resolve) => {
                this.setState({
                    dropDownLoading: true,
                }, () => {
                    makePagination({
                        initialRowCount: this.props.codePicker.initialRowCount ? this.props.codePicker.initialRowCount : this.props.codePicker.rowCountPerPage!,
                        rowCountPerPage: this.props.codePicker.rowCountPerPage!,
                        pagingDirection: 'scrollToBottom',
                    }).then((pagination) => {
                        this.dropDownListPagination = pagination;

                        return this.props.codePicker.getData!(CodePickerUtil.trimObject({
                            fetch: this.props.fetch,
                            keyword: undefined,
                            parameters: {
                                ...this.props.parameters,
                            },
                            pagingInfo: pagination
                        }))
                    }).then((e) => {
                        // 전체선택에서 예외값 제거하고 나머지 아이템 바인딩
                        const dropDownListItems = e.data.filter((dataItem, index) => {
                            return Util.nullToEmpty((this.props.value as CodePickerValue).exceptValue).some(selectedItem => {
                                return selectedItem[this.props.codePicker.codeProperty] === dataItem[this.props.codePicker.codeProperty];
                            }) === false;
                        })
                        this.setState({
                            dropDownListItems: dropDownListItems,
                            dropDownLoading: false
                        }, () => {
                            resolve(dropDownListItems)
                        })
                    })
                })
            })
        } else {
            // 전체 선택모드가 아닌 일반적인 모드
            return new Promise<any[]>((resolve) => {
                const dropDownValue = Util.nullToEmpty(CodePickerValue.asArrayValue(this.props.value));
                this.setState({
                    dropDownListItems: dropDownValue
                }, () => {
                    resolve(dropDownValue)
                })
            })
        }
    }

    private handleDropDownRequestNextPageData = () => {
        return new Promise<any[]>((resolve) => {
            this.setState({
                dropDownLoading: true
            }, () => {
                if (!this.dropDownListPagination) {
                    this.setState({
                        dropDownLoading: false
                    }, () => {
                        resolve([]);
                    });

                    return;
                }
                this.dropDownListPagination.nextPage();

                this.props.codePicker.getData!(CodePickerUtil.trimObject({
                    fetch: this.props.fetch,
                    keyword: undefined,
                    parameters: {
                        ...this.props.parameters,
                    },
                    pagingInfo: this.dropDownListPagination
                })).then((e) => {
                    const dropDownItems = this.state.dropDownListItems.concat(e.data.filter((dataItem) => {
                        return this.props.value instanceof CodePickerValue ? Util.nullToEmpty(this.props.value.exceptValue) : [].some(selectedItem => {
                            return selectedItem[this.props.codePicker.codeProperty] === dataItem[this.props.codePicker.codeProperty];
                        }) === false;
                    }));

                    this.setState({
                        dropDownListItems: dropDownItems,
                        dropDownLoading: false
                    }, () => {
                        resolve(dropDownItems);
                    })
                })
            })
        });
    }


    /**
     * 페이징은 OnReadData
     * @param e 
     */
    private handleDialogReadDataItems = (e: {
        target: any,
        event: ReadEventArgs,
        parameters?: any | null,
        keyword?: any
    }): Promise<any[] | null | undefined> => {
        if (!this.props.codePicker.getData) {
            return Promise.resolve([]);
        }

        return new Promise<any[]>((resolve) => {
            this.setState({
                isDataItemLoading: true
            }, () => {
                let args = this.makeParameters(
                    this.props.parameters,
                    this.props.fetch ? this.props.fetch : this.context.fetch,
                    e.keyword
                );

                args.parameters = {
                    ...args.parameters,
                    ...e.parameters
                };
                args.pagingInfo = { ...e.event.pagingInfo };

                this.props.codePicker.getData!(CodePickerUtil.trimObject(args)).then((response: IResponseData) => {
                    resolve(response.data);
                }).catch((e) => {
                    console.error(e);
                }).finally(() => {
                    this.setState({
                        isDataItemLoading: false
                    });
                });
            });
        });
    }

    /**
     * 다이얼로그내 조회 버튼 클릭 
     * @param e 
     */
    handleDialogSearchButtonClick = (e) => {
        if (this.props.codePicker.paging === true) {
            // TODO
        } else {

            this.setState({
                keyword: null,
                isDataItemLoading: true,
            }, () => {
                if (this.props.codePicker.getData) {
                    const parameters = this.makeParameters({
                        ...this.props.parameters,
                        ...e.searchParameter,
                    }, this.getFetch());

                    this.props.codePicker.getData(CodePickerUtil.trimObject(parameters)).then(response => {
                        this.setState({
                            dialogGridDataItemsKey: uuidv4(),
                            dialogGridDataItems: response.data,
                        })
                    }).finally(() => {
                        this.setState({
                            isDataItemLoading: false
                        })
                    })
                }
            })
        }
    }

    handleDialogCloseButtonClick = (e) => {
        this.setState({
            isShowDialog: false,
            keyword: null,
            isDataItemLoading: false,
            tempSelectedItem: []
        }, () => {
            if (this.inputRef.current) {
                this.inputRef.current.focus();
            }
        })
    }

    handleDialogGridRowCheckedChange = (items: any[]) => {
        this.setState({
            tempSelectedItem: items
        }, () => {
        });
    }

    /**
     * 다이얼로그 선택 버튼 클릭 콜백
     * @param totalSelect 
     * @param exceptItems 
     */
    handleDialogSelectButtonClick = (totalSelect?: boolean, exceptItems?: any[] | null,) => {
        const value = this.props.useTotalToEmpty === true ? new CodePickerValue(
            totalSelect === true ? true : false,
            totalSelect === true ? null : [...this.state.tempSelectedItem],
            exceptItems ? exceptItems : []
        ) : [...this.state.tempSelectedItem];

        this.setState({
            isShowDialog: false,
            keyword: null,
            isDataItemLoading: false,
            tempSelectedItem: [],
            dropDownListSelectedItem: null,
        }, () => {
            this.props.onChange(new Events.CodePickerChangeEventArgs(
                this,
                value,
                null,
                this.props.useTotalToEmpty
            ));

            if (this.inputRef.current) {
                this.inputRef.current.focus();
            }

            this.setState({
                moveFocusContext: {
                    prevProps: this.props.value,
                    nextProps: value
                }
            })
        });
    }

    handleDialogSelectAndConfirm = (items: any[], exceptItems?: any[] | null, totalSelect?: boolean) => {
        this.setState({
            isShowDialog: false,
            keyword: null,
            isDataItemLoading: false,
            tempSelectedItem: [],
            dropDownListSelectedItem: null,
        }, () => {
            const value = this.props.useTotalToEmpty === true ?
                new CodePickerValue(
                    totalSelect === true ? true : false,
                    totalSelect === true ? null : items,
                    exceptItems ? exceptItems : []
                ) : items;

            this.props.onChange(new Events.CodePickerChangeEventArgs(
                this,
                value,
                null,
                this.props.useTotalToEmpty
            ));

            if (this.inputRef.current) {
                this.inputRef.current.focus();
            }

            this.setState({
                moveFocusContext: {
                    prevProps: this.props.value,
                    nextProps: value
                }
            })
        })
    }

    handleUserProfileClicked = (item?: any) => {
        if (this.props.onUserProfileClicked) {
            this.props.onUserProfileClicked(item);
        }
    }

    private handleClick = (event: React.MouseEvent) => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onClick, new Events.MouseEventArgs(this, event));
    }

    private handleMouseDown = (event: React.MouseEvent) => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseDown, new Events.MouseEventArgs(this, event));
    }

    private handleMouseMove = (event: React.MouseEvent) => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseMove, new Events.MouseEventArgs(this, event));
    }

    private handleMouseUp = (event: React.MouseEvent) => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseUp, new Events.MouseEventArgs(this, event));
    }

    private handleMouseLeave = (event: React.MouseEvent) => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseLeave, new Events.MouseEventArgs(this, event));
    }

    private handleMouseEnter = (event: React.MouseEvent) => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseEnter, new Events.MouseEventArgs(this, event));
    }

    private handleKeyDown = (event: React.KeyboardEvent) => {
        Util.invokeEvent<Events.KeyEventArgs>(this.props.onKeyDown, new Events.KeyEventArgs(this, event));
        if (this.props.useShortCut) {
            const shortCut = Util.getShortCut(event);
            if (shortCut === 'CodePicker') {
                this.openDialog();
            }
        }
    }

    private handleKeyPress = (event: React.KeyboardEvent) => {
        Util.invokeEvent<Events.KeyEventArgs>(this.props.onKeyPress, new Events.KeyEventArgs(this, event));
    }

    openDialog = () => {
        if (!this.canEdit) return;

        if (!this.props.codePicker.getData) {
            if (this.checkBeforeOpenCodePicker(null)) {
                this.setState({
                    isShowDialog: true,
                    dialogGridDataItemsKey: uuidv4(),
                })
            }
        } else if (this.props.codePicker.paging === true) {
            if (this.checkBeforeOpenCodePicker(null)) {
                this.setState({
                    isShowDialog: true,
                    dialogGridDataItemsKey: uuidv4(),
                })
            }
        } else {
            if (this.checkBeforeOpenCodePicker(null)) {
                this.setState({
                    isDataItemLoading: true
                }, () => {
                    const parameters = this.makeParameters(this.props.parameters, this.getFetch());
                    this.props.codePicker.getData!(CodePickerUtil.trimObject(parameters)).then((response) => {
                        this.setState({
                            isShowDialog: true,
                            dialogGridDataItems: response.data,
                            dialogGridDataItemsKey: uuidv4(),
                            // TODO: 다이얼로그 열릴때 키워드
                            // keyword: this.state.isSelectedItemsDisplayed === false && this.state.inputValue.trim().length > 0 ? this.state.inputValue : null,
                            keyword: null
                        });
                    }).finally(() => {
                        this.setState({
                            isDataItemLoading: false,
                        })
                    })
                })
            }
        }
    }

    private getFetch = () => {
        let fetch = this.context.fetch;
        if (this.props.fetch) {
            if (this.props.fetch instanceof Fetch) {
                fetch = this.props.fetch;
            } else {
                fetch = new Fetch(this.props.fetch);
            }
        }

        return fetch;
    }

    private makeParameters = (parameter: any, fetch: any, keyword?: string | null) => {
        let result = {
            parameters: {}
        } as any;
        if (parameter && parameter.parameters !== undefined) {
            result.parameters = parameter.parameters;
        }
        else {
            result.parameters = parameter;
        }

        result.fetch = fetch;

        if (keyword) {
            result.keyword = keyword;
        }
        return result;
    }

    private renderInput() {
        return (
            <CodePickerInput
                ref={this.inputRef}
                className={this.props.className}
                disabled={this.props.disabled}
                readonly={this.props.readonly}
                frozen={this.props.frozen}
                required={this.props.required}
                width={this.props.width}
                height={this.props.height}
                value={this.makeInputValue()}
                showInfo={false}
                canMultiSelect={this.props.canMultiSelect}
                codePicker={this.props.codePicker}
                inputValueMaxLength={this.props.inputValueMaxLength}
                dropdownItems={this.state.dropDownListItems}
                selectedItem={this.state.dropDownListSelectedItem}
                dropDownLoading={this.state.dropDownLoading}
                showDropdownIcon={
                    (this.props.value instanceof CodePickerValue && this.props.value.isTotalSelect === true) ||
                    (this.props.canMultiSelect && Util.nullToEmpty(CodePickerValue.asArrayValue(this.props.value)).length > 0)
                }
                showUserProfileIcon={this.props.showUserProfileIcon}
                tooltip={this.props.tooltip}
                isShowSearchResult={false}
                allowKeyboardInput={this.props.allowKeyboardInput}
                onClickActionButton={this.handleInputActionButtonClick}
                onFocus={this.handleFocus}
                onChange={this.handleInputTextChange}
                onBlur={this.handleInputBlur}
                onMoveFocus={this.handleInputMoveFocus}
                onKeyUp={this.props.onKeyUp}
                onClickDropDownItem={this.handleClickDropDownItem}
                onClickSelectAll={this.handleClickDropDownSelectAll}
                onClickRemoveAll={this.handleDropDownListRemoveAllClick}
                onClickRemoveItem={this.handleDropDownListRemoveItemClick}
                onShowDropdDownList={this.handleShowDropDownList}
                onUserProfileClicked={this.handleUserProfileClicked}
                onRequestNextPageData={this.state.dropDownListSelectedItem || this.isSelectedTotalOnPaging() ? this.handleDropDownRequestNextPageData : undefined}
            />
        );
    }

    private renderDialog = () => {
        let Component: React.ComponentType<IDefaultDialog>;
        if (this.props.codePicker.customDialogComponent) {
            Component = this.props.codePicker.customDialogComponent;
        } else {
            Component = DefaultDialog;
        }

        return (
            <Component
                isShow={this.state.isShowDialog}
                title={this.props.codePicker.dialogTitle}
                dataItemsKey={this.state.dialogGridDataItemsKey}
                dataItems={this.state.dialogGridDataItems}
                subTitle={this.props.codePicker.dialogSubtitle}
                width={CodePickerUtil.getDialogSize('width', this.props.codePicker)}
                height={CodePickerUtil.getDialogSize('height', this.props.codePicker)}
                canMultiSelect={this.props.canMultiSelect}
                codeProperty={this.props.codePicker.codeProperty}
                gridColumns={this.props.codePicker.columns || []}
                value={Util.nullToEmpty(CodePickerValue.asArrayValue(this.props.value))}
                exceptValue={this.props.value instanceof CodePickerValue ? this.props.value.exceptValue : null}
                isTotalSelect={this.props.value instanceof CodePickerValue ? this.props.value.isTotalSelect : undefined}
                searchKeyword={this.state.keyword || undefined}
                customSearchConditionComponent={this.props.codePicker.customSearchConditionComponent}
                paging={this.props.codePicker.paging}
                rowCountPerPage={this.props.codePicker.rowCountPerPage}
                initialRowCount={this.props.codePicker.initialRowCount}
                useTotalToEmpty={this.props.useTotalToEmpty}
                fetch={this.props.fetch}
                isLoading={this.state.isDataItemLoading}
                parameters={this.props.parameters}
                dialogParameters={this.props.dialogParameters}
                checkedRows={this.state.tempSelectedItem}
                codePicker={this.props.codePicker}
                onClickClose={this.handleDialogCloseButtonClick}
                onClickSearch={this.handleDialogSearchButtonClick}
                onChangeSelectedItems={this.handleDialogGridRowCheckedChange}
                onConfirmSelectedItems={this.handleDialogSelectButtonClick}
                onSelectAndConfirmItems={this.handleDialogSelectAndConfirm}
                onReadDataItems={this.props.codePicker.paging === true ? this.handleDialogReadDataItems : undefined}
            />
        )
    }

    render() {
        return (
            <div
                className={Util.getClassNames(this.props.className)}
                style={Object.assign({}, Util.getWrapperStyle(this.props), { display: 'inline-block' })}
                onClick={this.handleClick}
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseMove}
                onMouseUp={this.handleMouseUp}
                onMouseLeave={this.handleMouseLeave}
                onMouseEnter={this.handleMouseEnter}
                onKeyDown={this.handleKeyDown}
                onKeyPress={this.handleKeyPress}
                id={this.props.id}
                data-orbit-component={'OBTCodePicker'}
            >
                {this.renderInput()}
                {this.state.isShowDialog ? this.renderDialog() : null}
                {this.props.useLoading ?
                    <OBTLoading
                        open={this.state.isDataItemLoading}
                        className={this.props.loadingType === OBTLoading.Type.small ? styles.smallLoading : null}
                        value="Loading"
                        type={this.props.loadingType ? this.props.loadingType : OBTLoading.Type.default}
                        fullScreen={true}>
                    </OBTLoading> : <></>
                }
            </div>
        );
    }
}
