/**
 * OBTRadioButtonGroup
 * @version 0.1
 * @author 안광진
 * @see LUXRadioButtonGroup
 */

import * as React from 'react';
import { LUXRadioButtonGroup } from 'luna-rocket/LUXRadioButton'
import { Events, CompositeProps, Util, CommonProps, Functions, createPropDefinitions, CommonDefinitions, CommonType } from '../Common';
import uuid from "uuid/v4";
import OBTRadioButton from './OBTRadioButton';
import OBTTooltip from '../OBTTooltip';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

interface IOBTRadioButtonGroup extends CompositeProps.ContainerClass, CommonProps.value<string>, Events.onChange<string>,
    Events.onFocus, Events.onBlur, Events.onClick, Events.onMouseDown, Events.onMouseMove, Events.onMouseUp, Events.onMouseLeave,
    Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress, Events.onKeyUp, CommonProps.required {
    /**
     * @default 20
     * 간격을 정의하는 속성입니다.
     */
    margin: string,
    /**
     * @default true
     * 가로정렬 여부를 정의하는 속성입니다.
     */
    horizontal: boolean,
    /**
     * 틀팁에 대한 설정 속성입니다.
     */
    tooltip?: any

}

interface State extends hasError {
}

export default class OBTRadioButtonGroup extends React.Component<IOBTRadioButtonGroup, State> implements Functions.IFocusable {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.ContainerClass(CommonType.string),
        CommonDefinitions.required(),
        CommonDefinitions.value({ type: CommonType.string, description: "RadioButtonGroup 콤포넌트에 속해있는 RadioButton 콤포넌트 중 RadioButton 콤포넌트의 value 속성값과 매핑되어 default로 선택되는 RadioButton 콤포넌트를 정의하는 속성입니다." }),
        CommonDefinitions.onChange({ type: CommonType.string }),
        { name: "margin", type: CommonType.string, description: "간격을 정의하는 속성입니다.", default: "20px", optional: true },
        { name: "horizontal", type: CommonType.boolean, description: "가로정렬 여부를 정의하는 속성입니다.", default: true, optional: true },
        CommonDefinitions.tooltip(),
        CommonDefinitions.InnerFunc(),
        CommonDefinitions.Event(),
    );

    public static defaultProps = {
        frozen: false,
        disabled: false,
        required: false,
        margin: '20px',
        horizontal: true
    };

    public state: State = {};

    public myRefs = {
        ref: React.createRef<LUXRadioButtonGroup>()
    };

    public focus(isLast: boolean = false): void {
        if (!this.props.frozen && !this.props.disabled) {
            this.myRefs.ref.current.valueRef.focus();
        }
    }

    private get canEdit(): boolean {
        return !this.props.disabled;
    }

    public isEmpty(): boolean {
        return (!this.props.value || this.props.value.length <= 0);
    }

    public validate(): boolean {
        return !(this.props.required === true && this.isEmpty());
    }

    private handleMoveFocus = (direction: string): void => {
        Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
    }

    private handleFocus = (): void => {
        Util.invokeEvent<Events.EventArgs>(this.props.onFocus, new Events.EventArgs(this));
    }

    private handleBlur = (): void => {
        Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
    }

    private handleChange = (e: any, value: string): void => {
        if (this.canEdit) {
            Util.invokeEvent<Events.ChangeEventArgs<string>>(this.props.onChange, new Events.ChangeEventArgs<string>(this, value));
        }
    }

    renderComponent = () => {
        const props = {
            // Value
            defaultSelected: this.props.value,
            name: uuid(),
            disabled: this.props.disabled,
            // Events
            onChange: this.handleChange,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            onMoveFocus: this.handleMoveFocus,
            style: Util.getWrapperStyle(this.props),
        };

        const childrenCount = React.Children.count(this.props.children);
        const children = Util.mapChildren(this.props.children, (child, idx) => {
            if (React.isValidElement(child) && idx !== childrenCount) {
                if (child.type === OBTRadioButton) {
                    return {
                        style: this.props.horizontal ? { marginRight: this.props.margin } : { marginBottom: this.props.margin, display: 'block' },
                        onKeyDown: this.props.onKeyDown,
                        onMoveFocus: this.props.onMoveFocus,
                        RadioButtonLabelStyle: {
                            fontFamily: 'inherit',
                        },
                        RadioButtonIconStyle: this.props.required || ((child.props as any) || {}).required ? {
                            backgroundColor: '#fef4f4',
                            borderRadius: '50%',
                            marginTop: '-7px'
                        } : {
                            marginTop: '-7px'
                        }
                    };
                }
            }
            return null;
        });

        return <OBTTooltip {...this.props.tooltip}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            onClick={this.props.onClick}
            onMouseDown={this.props.onMouseDown}
            onMouseMove={this.props.onMouseMove}
            onMouseUp={this.props.onMouseUp}
            onMouseLeave={this.props.onMouseLeave}
            onMouseEnter={this.props.onMouseEnter}
            onKeyUp={this.props.onKeyUp}
            onKeyDown={this.props.onKeyDown}
            onKeyPress={this.props.onKeyPress}
            className={Util.getClassNames(this.props.className)} style={Util.getWrapperStyle(this.props)}
            overrideSize={false}
            rootProps={{
                id: this.props.id,
                'data-orbit-component': 'OBTRadioButtonGroup'
            }}>
            <LUXRadioButtonGroup ref={this.myRefs.ref} {...props}>{children}</LUXRadioButtonGroup>
        </OBTTooltip>

    }

    render() {
        return <ErrorBoundary owner={this} render={this.renderComponent} />
    }
}
