import { GetPrivacyEventArgs } from "./Events";
import { IPageAuthority } from "../OBTPageContainer/OBTPageContainer";

export interface id { id?: string }
export interface frozen { frozen?: boolean }
export interface className { className?: string }
export interface width { width?: string }
export interface height { height?: string }
export interface required { required: boolean }
export interface disabled { disabled: boolean }
export interface readonly { readonly: boolean }
export interface value<T> { value: T }
export interface subValue<T> { subValue?: T }
export interface exceptValue<T> { exceptValue?: T | null }
export interface subId { subId?: string }
export interface useSubLang { useSubLang?: boolean }
export interface style { style?: any }

export interface labelText { labelText?: string }
export interface placeHolder { placeHolder?: string }
export enum PrivacyBehaviorEnum {
    viewByButton,
    viewByFocus,
    always
}
export interface usePrivacy {
    usePrivacy?: boolean,
    privacyBehavior?: PrivacyBehaviorEnum,
    onGetPrivacy?: (e: GetPrivacyEventArgs) => Promise<string>
}

/**
 * 로그인 유저 권한 
 * 적용되는 컴포넌트 OBTDataGrid, OBTFormPanel
 * 컨텍스트로 넘어오는 pageAuthority를 바라보는것이 디폴트
 */
export interface usePageAuthority {
    usePageAuthority?: boolean,
    pageAuthority?: IPageAuthority,
}