/**
 * OBTConditionPanel
 * Luna - Orbit 개발시 템플릿 으로 사용.
 * @version 0.1
 * @author 김철희
 * @see common.js
 */
import * as React from 'react';
import { Events, CompositeProps, Util, CommonProps, Functions, createPropDefinitions, CommonDefinitions, CommonType } from '../Common';
import { OBTButton, OBTConditionItem, OBTFloatingPanel } from '..';
import { ResizeSensor } from 'css-element-queries';
import debounce from 'lodash.debounce';
import iconBtnSearch from '../Images/icon-btn-search.png';
import iconBtnArrowDownNormal from '../Images/icon-btn-arrow-down-normal.png';
import iconBtnArrowTopNormal from '../Images/icon-btn-arrow-top-normal.png';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { OBTContext } from '../OBTPageContainer/OBTPageContainer';
import { OrbitInternalLangPack } from '../Common/Util';
const styles = require('./OBTConditionPanel.module.scss');

export class CollapseChangedEventArgs extends Events.EventArgs {
    constructor(public readonly target: any, public readonly collapsed) {
        super(target);
    }
}

export class SearchEventArgs extends Events.CancelableEventArgs {
    constructor(public readonly target: any, public readonly validated: boolean, public readonly invalidatedRefs) {
        super(target);
    }
}

export class AfterSelectChangeEventArgs extends Events.EventArgs {
    constructor(
        public readonly target: any,
        public readonly item: any) {
        super(target);
    }
}

export enum DisplayType {
    'default' = 'default',
    'small' = 'small'
}

interface IOBTConditionPanel extends CompositeProps.Default, CommonProps.disabled,
    Events.onFocus, Events.onBlur, Events.onMoveFocus {
    collapsed?: boolean,
    pageContainer?: any,
    children?: any,
    type?: DisplayType,
    useFloatingOptionalPanel?: boolean,
    requiredTooltip?: any,
    onCollapseChanged?: (e: CollapseChangedEventArgs) => void,
    onSearch?: (e: SearchEventArgs) => void,
    onChange?: (e: Events.ConditionPanelChangeEventArgs) => void,
    onAfterSelectChange?: (e: AfterSelectChangeEventArgs) => void,
}

/**
 * State 정의
 */
interface State extends hasError {
    collapsed: boolean,
    refs: [number, React.RefObject<any>][],
    primaryChildrenRefs: React.RefObject<any>[],
    primaryChildren: any | null,
    optionalChildrenRefs: React.RefObject<any>[],
    optionalChildren: any | null,
    etcChildren: any | null,
    children: any | null,
    generatedChildren: any | null,
    requiredIndex: number,
    handleMoveFocus: (e: Events.MoveFocusEventArgs, isOptional: boolean) => void,
    handleChildrenChange: (e: Events.ChangeEventArgs<any>) => void,
    handleChildrenFocus: (e: Events.EventArgs) => void,
    floatingPanelWidth: string,
    floatingPanelVisible: boolean,
    type?: DisplayType,
    optionalVisibility: boolean
}

export default class OBTConditionPanel extends React.Component<IOBTConditionPanel, State> implements Functions.IFocusable, Functions.IRequired {

    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        CommonDefinitions.disabled(),
        {
            name: 'collapsed', type: CommonType.boolean, description: "추가검색조건을 펼침/감춤 처리할 수 있다."
                + "\n값을 지정했을 경우 제어되는 컴포넌트로 설정되어 onCollapseChanged 를 반드시 지정해야 한다.", optional: true
        },
        { name: 'useFloatingOptionalPanel', type: CommonType.boolean, description: 'true시 선택입력 Panel에 FloatingPanel이 적용된다.', default: false, optional: true },
        CommonDefinitions.onFocus(),
        CommonDefinitions.onBlur(),
        CommonDefinitions.focus(),
        CommonDefinitions.isEmpty(),
        CommonDefinitions.onMoveFocus(),
        {
            name: 'onCollapseChange', type: CommonType.function, parameters: {
                name: "e",
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    collapsed: { type: CommonType.boolean, description: '추가 검색 조건 펼침/감춤' }
                }
            }, optional: true, description: '추가검색조건의 펼침/감추어짐 변경시 이벤트'
        },
        {
            name: 'onSearch', optional: true, type: CommonType.function, parameters: {
                name: "e",
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    validated: { type: CommonType.boolean, description: "필수항목 입력여부" },
                    invalidatedRefs: { type: ['ref'], description: "필수 미입력항목" }
                }
            }, description: "조회버튼 클릭시 이벤트\n"
                + "\n검색조건 중 필수항목의 입력여부와 관계없이 호출되며 파라메터의 e.validated 로 필수항목의 입력여부를 확인할 수 있다."
        },
        {
            name: 'onChange', optional: true, type: CommonType.function, parameters: {
                name: "e",
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                }
            }, description: "검색조건의 항목 변경시 호출"
                + "\n조회 된 내용을 clear 할때 사용한다."
        },
        {
            name: 'onAfterSelectChange', type: CommonType.function, parameters: {
                name: "e",
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    item: { type: CommonType.any, description: 'OBTConditionItem의 ref' }
                }
            }, optional: true, description: '항목이 변경되었을 때의 이벤트'
        },
    );

    public static Type = DisplayType;

    public static defaultProps = {
        frozen: false,
        disabled: false,
        optinalPanelFloating: false,
    }

    private resizeSensor: ResizeSensor | null = null;

    /**
     * Ref 정의
     */
    public myRefs = {
        root: React.createRef<HTMLDivElement>(),
        optionalRoot: React.createRef<HTMLDivElement>(),
        searchButton: React.createRef<OBTButton>(),
        searchButtonOptional: React.createRef<OBTButton>()
    }

    private useShortCut = false;

    ///////////////////////////////////////////////////////////////////////////// Life Cycle API
    static getDerivedStateFromProps(nextProps: IOBTConditionPanel, prevState: State): any {
        try {
            let { collapsed,
                // refs, 
                primaryChildrenRefs, primaryChildren, optionalChildrenRefs, optionalChildren, etcChildren, children, generatedChildren, optionalVisibility } = prevState;
            if (nextProps.collapsed !== undefined) {
                collapsed = nextProps.collapsed;
                optionalVisibility = !collapsed;
            }
            const refs: [number, React.RefObject<any>][] = [];
            if (nextProps.children !== children) {
                if (nextProps.children && React.Children.count(nextProps.children) > 0) {
                    generatedChildren = [];
                    primaryChildrenRefs = [];
                    primaryChildren = [];
                    optionalChildrenRefs = [];
                    optionalChildren = [];
                    etcChildren = [];
                    React.Children.toArray(nextProps.children).forEach((child, index) => {
                        if (React.isValidElement(child) && child.type === OBTConditionItem) {
                            const props: any = child.props;
                            const isOptional = props.hasOwnProperty('optional') && props['optional'] === true;
                            const newProps = {
                                key: index,
                                onChange: Util.overrideHandler(props['onChange'], prevState.handleChildrenChange),
                                onFocus: Util.overrideHandler(props['onFocus'], prevState.handleChildrenFocus),
                                onMoveFocus: Util.overrideHandler(props['onMoveFocus'],
                                    (e: Events.MoveFocusEventArgs) => { prevState.handleMoveFocus(e, isOptional) })
                            };
                            const ref = (child as any).ref ? (child as any).ref : React.createRef<any>();
                            newProps['ref'] = ref;
                            refs.push([index, ref]);
                            if (isOptional) {
                                optionalChildrenRefs.push(ref);
                            } else {
                                primaryChildrenRefs.push(ref);
                            }
                            if (prevState.requiredIndex === index) {
                                newProps['required'] = true;
                            }
                            if (nextProps.disabled === true) {
                                newProps['disabled'] = nextProps.disabled;
                            }
                            if (nextProps.requiredTooltip) {
                                newProps['requiredTooltip'] = nextProps.requiredTooltip
                            }
                            const clonedElement = React.cloneElement(child, newProps);
                            generatedChildren.push(clonedElement);
                            if (isOptional) {
                                optionalChildren.push(clonedElement);
                            } else {
                                primaryChildren.push(clonedElement);
                            }
                        } else {
                            etcChildren.push(child);
                        }
                    });
                }

                return {
                    collapsed,
                    optionalVisibility,
                    refs,
                    primaryChildrenRefs,
                    primaryChildren,
                    optionalChildrenRefs,
                    optionalChildren,
                    etcChildren,
                    children,
                    generatedChildren,
                    requiredIndex: prevState.requiredIndex
                };
            } else {
                return {
                    collapsed,
                    optionalVisibility,
                    requiredIndex: prevState.requiredIndex
                };
            }
        } catch (e) {
            return Util.getErrorState(e);
        }
    }

    renderComponent = () => {
        const hasOptional = this.state.optionalChildren && this.state.optionalChildren.length > 0 ? true : false;
        const searchButtonProps: any = {
            imageUrl: iconBtnSearch,
            onClick: this.handleSearchButtonClick,
            height: '27px',
            tooltip: {
                labelText: OrbitInternalLangPack.getText('WE000015921', '조회'),
                position: 'top',
                focusValue: false
            },
        };
        switch ((this.props.type || this.state.type || DisplayType.default)) {
            case DisplayType.default:
                searchButtonProps.width = '27px';
                break;
            case DisplayType.small:
                searchButtonProps.width = '27px';
                break;
        }

        // if (hasOptional === true && this.state.collapsed === false) {
        //     searchButtonProps.labelText = '조회';
        //     searchButtonProps.width = undefined;
        //     searchButtonProps.tooltip = undefined;
        // }

        const searchButton = <OBTButton ref={this.myRefs.searchButton} className={styles.searchButton} {...searchButtonProps} />;
        const collapseButton = hasOptional ?
            <OBTButton className={styles.collapseButton}
                imageUrl={this.state.collapsed ? iconBtnArrowDownNormal : iconBtnArrowTopNormal}
                onClick={this.handleCollapseButtonClick}
                width='27px'
                height='27px' /> : null;
        const optionalPanel = hasOptional ?
            <>
                <div className={styles.optional}>
                    {this.state.optionalChildren}
                </div>
                <div className={styles.optionalFunctions}>
                    <OBTButton
                        ref={this.myRefs.searchButtonOptional}
                        className={styles.searchButton}
                        {...{
                            ...searchButtonProps,
                            width: undefined,
                            tooltip: undefined,
                        }}
                        labelText={OrbitInternalLangPack.getText('WE000015921', '조회')}
                    />
                </div>
            </> : null;

        const optionalWrapper = this.props.useFloatingOptionalPanel === true ? (
            <OBTFloatingPanel
                className={Util.getClassNames(styles.optionalWrapper,
                    this.props.disabled ? styles.disabled : null,
                    this.state.collapsed ? styles.collapsed : null)}
                anchor={this.myRefs.root} width={this.state.floatingPanelWidth}
                value={!this.state.collapsed || this.state.floatingPanelVisible}
                position={OBTFloatingPanel.Position.bottom}>
                <div ref={this.myRefs.optionalRoot}
                    className={styles.optionalRoot}
                    style={{ visibility: this.state.optionalVisibility ? 'visible' : 'hidden' }}
                    onTransitionEnd={this.handleTransitionEnd}>
                    {optionalPanel}
                </div>
            </OBTFloatingPanel>
        ) : (
            <div className={Util.getClassNames(styles.optionalWrapper,
                this.props.disabled ? styles.disabled : null,
                this.state.collapsed ? styles.collapsed : null)}>
                <div ref={this.myRefs.optionalRoot}
                    className={styles.optionalRoot}
                    style={{ visibility: this.state.optionalVisibility ? 'visible' : 'hidden' }}
                    onTransitionEnd={this.handleTransitionEnd}>
                    {optionalPanel}
                </div>
            </div>
        );

        return (
            <div ref={this.myRefs.root}
                id={this.props.id}
                data-orbit-component={'OBTConditionPanel'}
                className={Util.getClassNames(styles.root,
                    this.props.disabled ? styles.disabled : null,
                    this.state.collapsed ? styles.collapsed : null,
                    hasOptional ? styles.expandable : null,
                    this.props.className)}
                style={Util.getWrapperStyle(this.props)}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                onKeyDown={this.handleKeyDown}>
                <div className={styles.primaryRoot}>
                    <div className={styles.primary}>
                        {this.state.primaryChildren}
                    </div>
                    <div className={styles.primaryFunctions}>
                        {this.state.collapsed ? searchButton : <></>}
                        {collapseButton}
                    </div>
                </div>

                {optionalWrapper}
                {this.state.etcChildren}
            </div>
        )
    }

    render() {
        return (
            <OBTContext.Consumer>
                {
                    value => {
                        this.useShortCut = value.useShortCut;
                        return <ErrorBoundary owner={this} render={this.renderComponent} />;
                    }
                }
            </OBTContext.Consumer>
        )
    }

    componentDidMount() {
        try {
            if (this.myRefs.root.current) {
                this.resizeSensor = new ResizeSensor(this.myRefs.root.current, this.resizeHandler);
                if (this.props.type === undefined && this.state.type === undefined) {
                    const findDialog = (parentElement) => {
                        if (parentElement) {
                            if (parentElement.className.indexOf('obtDialog') >= 0) return true;
                            else if (parentElement.parentElement) {
                                return findDialog(parentElement.parentElement);
                            }
                        }
                        return false;
                    }
                    if (findDialog(this.myRefs.root.current.parentElement)) {
                        this.setState({
                            type: DisplayType.small
                        });
                    }
                }

                this.myRefs.root.current.addEventListener('search', this.handleSearchButtonClick);
            }

            if (this.myRefs.optionalRoot.current && !this.state.collapsed) {
                const height = this.myRefs.optionalRoot.current.offsetHeight;
                this.myRefs.optionalRoot.current.style.setProperty('--height', `-${height}px`);
            }
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    componentDidUpdate() {
        try {
            if (this.myRefs.optionalRoot.current && !this.state.collapsed) {
                const height = this.myRefs.optionalRoot.current.offsetHeight;
                this.myRefs.optionalRoot.current.style.setProperty('--height', `-${height}px`);
            }

            this.setHeight();
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    componentWillUnmount(): void {
        if (this.resizeSensor) {
            this.resizeSensor.detach(this.resizeHandler);
        }
        if (this.myRefs.root.current) {
            this.myRefs.root.current.removeEventListener('search', this.handleSearchButtonClick);
        }
    }

    private resizeHandler = debounce((() => {
        this.setHeight();
    }), 10);

    private setHeight() {
        if (this.myRefs.root.current) {
            if (this.state.floatingPanelWidth !== `${this.myRefs.root.current.offsetWidth}px`) {
                this.setState({
                    floatingPanelWidth: `${this.myRefs.root.current.offsetWidth}px`
                });
            }
        }
    }
    ///////////////////////////////////////////////////////////////////////////// Logics
    public focus(isLast?: boolean): void {
        this.setFocus(false, isLast === true ? this.state.primaryChildrenRefs.length - 1 : 0, isLast);
    }

    public isEmpty(): boolean {
        if (((this.state.refs || []).filter((ref: [number, React.RefObject<any>]) => {
            if (ref[1].current && ref[1].current.isEmpty) {
                return ref[1].current.isEmpty();
            }
            return false;
        }) || []).length > 0) return true;
        return false;
    }

    public validate(): boolean {
        if (((this.state.refs || []).filter((ref: [number, React.RefObject<any>]) => {
            if (ref[1].current && ref[1].current.validate) {
                return !ref[1].current.validate();
            }
            return false;
        }) || []).length > 0) return false;
        return true;
    }

    private setFocus(isOptional: boolean, index: number, isLast?: boolean) {
        const refs = isOptional ? this.state.optionalChildrenRefs : this.state.primaryChildrenRefs;
        while (index >= 0 && index < refs.length) {
            const instance = refs[index].current;
            if (instance && instance.focus(isLast)) {
                return true;
            }
            if (isLast === true) {
                index--;
            } else {
                index++;
            }
        }
    }

    public search() {
        if (this.myRefs.searchButton.current) {
            this.myRefs.searchButton.current.click();
        } else if (this.myRefs.searchButtonOptional.current) {
            this.myRefs.searchButtonOptional.current.click();
        }
    }

    ///////////////////////////////////////////////////////////////////////////// Event Handlers
    private handleTransitionEnd = (e: React.TransitionEvent) => {
        if (e.target === this.myRefs.optionalRoot.current &&
            this.state.floatingPanelVisible) {
            this.setState({
                floatingPanelVisible: false,
                optionalVisibility: !this.state.collapsed
            });
        }
    }

    private handleCollapseButtonClick = (e: Events.EventArgs) => {
        this.setState({
            floatingPanelVisible: true,
            optionalVisibility: true
        }, () => {
            if (this.props.onCollapseChanged) {
                Util.invokeEvent<CollapseChangedEventArgs>(this.props.onCollapseChanged, new CollapseChangedEventArgs(this, !this.state.collapsed));
            } else {
                this.setState({
                    collapsed: !this.state.collapsed
                });
            }
        })
    }

    private handleSearchButtonClick = (e: Events.EventArgs) => {
        const invalidates = ((this.state.refs || []).filter((ref: [number, React.RefObject<any>]) => {
            if (ref[1].current && ref[1].current.validate) {
                return !ref[1].current.validate();
            }
            return false;
        }) || []);
        if (Util.invokeEvent<SearchEventArgs>(this.props.onSearch,
            new SearchEventArgs(this,
                invalidates && invalidates.length > 0 ? false : true,
                invalidates.map(ref => ref[1])))) {
            this.setState({
                requiredIndex: invalidates.length ? invalidates[0][0] : -1
            });
        }
    }

    private handleMoveFocus = (e: Events.MoveFocusEventArgs, isOptional: boolean): void => {
        const refs = isOptional ? this.state.optionalChildrenRefs : this.state.primaryChildrenRefs;
        const index = refs.findIndex((ref) => ref.current === e.target);

        const moveUp = (isLast: boolean) => {
            if (isOptional) {
                if (!this.setFocus(false, isLast ? this.state.primaryChildrenRefs.length - 1 : 0, isLast)) {
                    Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, e.direction));
                }
            } else {
                Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, e.direction));
            }
        };
        const moveDown = () => {
            if (!isOptional && !this.state.collapsed) {
                if (!this.setFocus(true, 0, false)) {
                    Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, e.direction));
                }
            } else {
                Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, e.direction));
            }
        };
        const leftRightEnterProcess = () => {
            if (!this.setFocus(
                isOptional,
                index + (e.direction === Events.MoveFocusDirection.left ? -1 : 1),
                e.direction === Events.MoveFocusDirection.left ? true : false)) {
                if (e.direction === Events.MoveFocusDirection.left) {
                    moveUp(true);
                } else if (e.direction === Events.MoveFocusDirection.enter) {
                    this.handleSearchButtonClick(new Events.EventArgs(this));
                } else {
                    moveDown();
                }
            }
        }
        switch (e.direction) {
            case Events.MoveFocusDirection.up:
                moveUp(false);
                break;
            case Events.MoveFocusDirection.down:
                moveDown();
                break;
            case Events.MoveFocusDirection.enter:
                if (index === refs.length - 1) {
                    this.handleSearchButtonClick(new Events.EventArgs(this));
                } else {
                    leftRightEnterProcess();
                }
                break;
            default:
                leftRightEnterProcess();
                break;
        }
    }

    private handleChildrenChange = (e: Events.ChangeEventArgs<any>): void => {
        if (this.state.requiredIndex !== -1) {
            this.setState({
                requiredIndex: -1
            }, () => {
                setTimeout(() => {
                    if (e.target && e.target.focus) {
                        e.target.focus();
                    }
                }, 0);
            });
        }
        Util.invokeEvent<Events.ConditionPanelChangeEventArgs>(this.props.onChange, new Events.ConditionPanelChangeEventArgs(this, e));
    }

    private handleChildrenFocus = (e: Events.EventArgs): void => {
        Util.invokeEvent<AfterSelectChangeEventArgs>(this.props.onAfterSelectChange, new AfterSelectChangeEventArgs(this, e.target));
    }

    private handleFocus = (): void => {
        Util.invokeEvent<Events.EventArgs>(this.props.onFocus, new Events.EventArgs(this));
    }

    private handleBlur = (): void => {
        if (!((this.myRefs.root.current && Util.containsFocus(this.myRefs.root)) ||
            (this.myRefs.optionalRoot.current && Util.containsFocus(this.myRefs.optionalRoot)))) {
            Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
        }
    }

    private handleKeyDown = (e: React.KeyboardEvent) => {
        if (this.useShortCut) {
            const shortCut = Util.getShortCut(e);
            if (shortCut === 'Search') {
                this.handleSearchButtonClick(new Events.EventArgs(this));
                e.stopPropagation();
                e.preventDefault();
            }
        }
    }

    /**
     * State 정의
     */
    public state: State = {
        collapsed: true,
        refs: [],
        primaryChildrenRefs: [],
        primaryChildren: [],
        optionalChildrenRefs: [],
        optionalChildren: [],
        etcChildren: [],
        children: null,
        generatedChildren: null,
        requiredIndex: -1,
        handleMoveFocus: this.handleMoveFocus,
        handleChildrenChange: this.handleChildrenChange,
        handleChildrenFocus: this.handleChildrenFocus,
        floatingPanelWidth: 'auto',
        floatingPanelVisible: false,
        optionalVisibility: false
    }
};

OBTConditionPanel.contextType = OBTContext;