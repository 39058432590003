/**
 * OBTGroupSelector2
 * @version 0.1
 * @author 김소현
 * @see common.js
 */
import * as React from 'react';
import { Events, CompositeProps, Util, CommonProps, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { hasError } from '../Common/CommonState';
/**
 * @internal
 * CSS Modules 사용방식
 * styles.[className]
 */
const styles = require('./OBTGroupSelector2.module.scss');

enum AlignType {
    /**
     * 수평 정렬 (가로 정렬)
     */
    'horizontal' = 'horizontal',
    /**
     * 수직 정렬 (세로 정렬)
     */
    'vertical' = 'vertical'
}

enum TemplateType {
    'default' = 'default'
}

export class MapItemEventArgs extends Events.EventArgs {
    constructor(public readonly list: any, public item?: any) {
        super(null);
    }
}

export class ItemEventArgs extends MapItemEventArgs {
    constructor(public readonly list: any, public item?: any, public component?: any, public value?: any, public mouseOver?: boolean, public isSelected?: boolean, public index?: number, public mouseOverKey?: string | null) {
        super(null);
    }
}

export class RenderItemEventArgs extends Events.EventArgs {
    constructor(public readonly list: any, public component?: any) {
        super(null);
    }
}

interface IOBTGroupSelector2 extends CompositeProps.Default, CommonProps.value<string>, Events.onChange<string>, CommonProps.disabled {
    /**
     * 그룹셀렉터 컴포넌트의 정렬 방향 지정
     */
    align?: AlignType,
    /**
     * 기본 그룹셀렉터 템플릿 사용
     */
    template?: TemplateType
    /**
     * 컴포넌트에 주입할 리스트
     */
    list: Array<any>,
    /**
     * 아이템의 키값을 나타내는 프로퍼티 지정
     */
    itemKeyProperty: string,
    /**
     * 아이템의 너비 지정
     */
    itemWidth: string,
    /**
     * 아이템의 높이 지정
     */
    itemHeight: string,
    /**
     * 템플릿 사용시 리스트를 템플릿 형식에 맞는 아이템으로 변환시키는 함수
     */
    onMapItem?: (e: MapItemEventArgs) => void,
    /**
     * 템플릿을 사용하지 않을 때, 리스트를 변환시켜 하나의 아이템 요소로 만드는 함수
     */
    onRenderItem?: (e: RenderItemEventArgs) => void,
    /**
     * 좌우 이동 버튼을 숨길지 말지 지정
     */
    autoHideButtons?: boolean,
}

interface IState extends hasError {
    value: any,
    list?: Array<any>,
    component?: any,
    defaultTemplate: any,
    mouseOverItemKey: string | null,
    justifyContent: string,
    firstItemIndex: number,
    isDisabledPrevButton: boolean,
    isDisabledNextButton: boolean,
    isMouseOverPrevButton: boolean,
    isMouseOverNextButton: boolean,
    isHideButton: boolean
}

export default class OBTGroupSelector2 extends React.Component<IOBTGroupSelector2, IState> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        CommonDefinitions.value(CommonType.string),
        CommonDefinitions.onChange(CommonType.string),
        CommonDefinitions.disabled(),
        { name: "align", type: toEnumType(AlignType), optional: true, default: "horizontal", description: "요소의 정렬 방향을 지정합니다." },
        { name: "template", type: toEnumType(TemplateType), default: "default", optional: true, description: "미리 만들어진 template 사용 여부입니다." },
        { name: "list", type: "Array<any>", description: "요소의 리스트 입니다." },
        { name: "itemWidth", type: CommonType.string, description: "요소안에 들어가는 item의 너비 입니다." },
        { name: "itemHeight", type: CommonType.string, description: "요소안에 들어가는 item의 높이 입니다." },
        { name: "itemKeyProperty", type: CommonType.string, optional: true, description: "list의 아이템에서 키값을 의미하는 프로퍼티 명입니다. (마우스오버, 선택아이템 처리시 사용)", default: "key" },
        {
            name: "onMapItem", type: CommonType.function, parameters: {
                name: "e",
                type: {
                    list: { type: CommonType.any, description: "원본 요소" },
                    item: { type: CommonType.any, description: "변환 요소" }
                }
            }, optional: true, description: "데이터를 template 형식에 맞게 변환해 주기 위한 함수입니다."
                + "\ntemplate를 사용할 경우 이 힘수를 사용하여 변환해 주시면 됩니다."
        },
        {
            name: "onRenderItem", type: CommonType.function, parameters: {
                name: "e",
                type: {
                    list: { type: CommonType.any, description: "원본 요소" },
                    component: { type: CommonType.any, description: "변환 요소" }
                }
            }, optional: true, description: "데이터를 가지고 컴포넌트를 만들기 위한 함수입니다. "
                + "\ntemplate를 사용하지 않을 경우 이 힘수를 사용하여 변환해 주시면 됩니다."
        },
        { name: "autoHideButtons", type: CommonType.boolean, description: "좌/우 페이징 버튼을 item 갯수에 따라 자동으로 표시/미표시 합니다.", optional: true }
    );

    public state: IState = {
        value: this.props.value,
        mouseOverItemKey: null,
        defaultTemplate: (e) => {
            const { item, index } = e;
            e.component = (
                <div key={item.key}
                    className={Util.getClassNames(styles.defaultTemplate,
                        e.value === item.key && styles.selectedItem,
                        item.key === this.state.mouseOverItemKey && styles.mouseOverItem
                    )}
                    onMouseEnter={(e) => {
                        if (this.props.list.length > index) {
                            this.setState({
                                mouseOverItemKey: item.key
                            })
                        }
                    }}
                    onMouseLeave={(e) => {
                        this.setState({
                            mouseOverItemKey: null
                        })
                    }} >
                    <div className={Util.getClassNames(styles.image, item.key === this.state.mouseOverItemKey || e.value === item.key ? styles.imageMouseOver : null)}>
                        {item.image}
                    </div>
                    <div className={styles.textArea}>
                        <div className={styles.textAreaSub}>
                            {item.sub}
                        </div>
                        <div className={styles.textAreaMain}>
                            {item.main}
                        </div>
                    </div>
                </div>
            )
        },
        justifyContent: 'space-around',
        firstItemIndex: 0,
        isDisabledPrevButton: false,
        isDisabledNextButton: false,
        isMouseOverPrevButton: false,
        isMouseOverNextButton: false,
        isHideButton: this.props.autoHideButtons!
    }

    public static defaultProps = {
        itemWidth: '183px',
        itemHeight: '70px',
        itemKeyProperty: 'key'
    }
    public static Align = AlignType
    public static Template = TemplateType

    private itemsContainerRef = React.createRef<HTMLDivElement>();
    private itemRef = React.createRef<HTMLDivElement>();

    static getDerivedStateFromProps(nextProps: IOBTGroupSelector2, prevState: IState): any {
        try {
            if (nextProps.list !== prevState.list || nextProps.value !== prevState.value) {
                let component = OBTGroupSelector2.renderItem(
                    nextProps.list,
                    nextProps.value,
                    nextProps.template,
                    prevState.defaultTemplate,
                    nextProps.onMapItem,
                    nextProps.onRenderItem,
                    prevState.mouseOverItemKey,
                    nextProps.itemKeyProperty,
                )
                return {
                    list: nextProps.list,
                    value: nextProps.value,
                    component: component,
                }
            }
            return null
        } catch (e) {
            console.log(e)
        }
    }

    componentDidMount = () => {
        try {
            const itemLength = this.props.list.length;
            const itemCount = this.getCountDisplayItem()!;

            if (this.props.autoHideButtons) {
                if (itemLength < itemCount) {
                    this.setState({
                        isHideButton: true,
                        justifyContent: 'flex-start'
                    })
                } else if (itemLength === itemCount) {
                    this.setState({
                        isHideButton: true,
                        justifyContent: 'space-around'
                    })
                } else {
                    this.setState({
                        isHideButton: false
                    })
                }
            } else {
                //리스트 전체 길이가 한화면에 보여줄수 있는 아이템 수보다 적으면 (즉, 아이템이 몇개 안되면) 왼쪽정렬 
                if (itemLength < itemCount) {
                    this.setState({
                        justifyContent: 'flex-start',
                        isDisabledPrevButton: true,
                        isDisabledNextButton: true
                    })
                } else if (itemLength >= itemCount) {//리스트 전체 길이가 한 화면에 보여지는 아이템 수보다 크면 자동 공간 정렬
                    this.setState({
                        justifyContent: 'space-around',
                    }, () => {
                        if (itemLength === itemCount) {
                            this.setState({
                                isDisabledNextButton: true
                            })
                        }
                    })
                }
            }
            window.addEventListener('resize', this.getItemsArrangement);
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    componentDidUpdate = (prevProps: IOBTGroupSelector2, prevState: IState) => {
        let needUpdate = false;
        let state = {};

        if (this.canEdit()) {
            if (this.props.autoHideButtons !== prevProps.autoHideButtons || this.state.isHideButton !== prevState.isHideButton) {
                const isHideButton = prevState.isHideButton
                this.setState({
                    isHideButton: !isHideButton
                }, () => {
                    this.getItemsArrangement();
                })
            }

            if (this.props.list !== prevProps.list ||
                this.state.justifyContent !== prevState.justifyContent ||
                (this.props.align !== AlignType.vertical && this.props.width !== prevProps.width) ||
                (this.props.align === AlignType.vertical && this.props.height !== prevProps.height)) {
                this.getItemsArrangement();
            }

            if (this.state.mouseOverItemKey !== prevState.mouseOverItemKey) {
                needUpdate = true;
                let component = OBTGroupSelector2.renderItem(
                    this.props.list,
                    this.props.value,
                    this.props.template,
                    prevState.defaultTemplate,
                    this.props.onMapItem,
                    this.props.onRenderItem,
                    this.state.mouseOverItemKey,
                    this.props.itemKeyProperty
                );
                state['component'] = component;
                needUpdate = true;
            }

            if (needUpdate) {
                this.setState(state)
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getItemsArrangement);
    }

    static renderItem = (list, value, template, defaultTemplate, onMapItem, onRenderItem, mouseOverKey: string | null, itemKeyProperty: string) => {
        let itemList: any = [];
        list.map((item, index) => {
            const e = new ItemEventArgs(item);
            e.value = value;
            e.index = index;
            e.mouseOver = mouseOverKey !== null && mouseOverKey === item[itemKeyProperty]
            e.isSelected = value === item[itemKeyProperty]
            e.mouseOverKey = mouseOverKey

            if (template === TemplateType.default) {
                if (onMapItem) {
                    onMapItem(e)
                }
                defaultTemplate(e)
                itemList.push(e.component)
            } else if (onRenderItem) {
                onRenderItem(e)
                itemList.push(e.component)
            }
            return item;
        })
        return itemList;
    }

    private canEdit = () => {
        return !this.props.frozen && !this.props.disabled;
    }

    private getCountDisplayItem = () => {
        if (this.itemsContainerRef.current && this.itemRef.current) {
            const itemContainerWidth = this.itemsContainerRef.current.getBoundingClientRect().width;
            const itemContainerHeight = this.itemsContainerRef.current.getBoundingClientRect().height;
            const itemWidth = this.itemRef.current.getBoundingClientRect().width;
            const itemHeight = this.itemRef.current.getBoundingClientRect().height;

            if (this.props.align === AlignType.vertical) {
                return Math.floor(itemContainerHeight / itemHeight);
            } else {
                return Math.floor(itemContainerWidth / itemWidth);
            }
        } else {
            if (this.props.autoHideButtons && this.state.firstItemIndex > 0) {
                this.setState({
                    firstItemIndex: 0
                }, () => {
                    this.setState({
                        isHideButton: true
                    })
                })
            }
        }
    }

    private getItemsArrangement = () => {
        const itemLength = this.props.list.length;
        const itemCount = this.getCountDisplayItem()!;

        //만약 전체 너비는 넓은데 아이템 갯수는 몇개되지 않지만, 첫번쨰로와야될 아이템의 인덱스가 0이 아니면 0으로 바꿈 (width갱신시 정상동작되게 하기위해)
        if (itemLength <= itemCount && this.state.firstItemIndex > 0) {
            this.setState({
                firstItemIndex: 0
            })
        }
        if (this.props.autoHideButtons) {
            if (itemLength < itemCount) {
                this.setState({
                    justifyContent: 'flex-start',
                    isHideButton: true
                })
            } else if (itemLength === itemCount) {
                this.setState({
                    justifyContent: 'space-around',
                    isHideButton: true
                })
            } else {
                this.setState({
                    isHideButton: false
                })
            }
        }

        if ((itemLength - this.state.firstItemIndex) > itemCount) {
            this.setState({
                justifyContent: 'space-around',
                isDisabledNextButton: false
            })
        } else if ((itemLength - this.state.firstItemIndex) < itemCount) {
            this.setState({
                justifyContent: 'flex-start',
                isDisabledPrevButton: false,
                isDisabledNextButton: true
            })
        } else if ((itemLength - this.state.firstItemIndex) === itemCount) {
            this.setState({
                justifyContent: 'space-around',
                isDisabledNextButton: true
            })
        }
    }

    private handlePrevButtonClick = () => {
        if (this.canEdit()) {
            const itemCount = this.getCountDisplayItem()!;

            if (this.state.firstItemIndex <= 0) {
                return;
            }

            if (this.props.list.length <= itemCount && this.props.autoHideButtons) {
                this.setState({
                    isHideButton: true,
                    justifyContent: 'flex-start'
                })
            }
            if (this.state.firstItemIndex > 0) {
                this.setState({
                    firstItemIndex: this.state.firstItemIndex - itemCount,
                    justifyContent: 'space-around',
                    isDisabledNextButton: false
                }, () => {
                    if (this.state.firstItemIndex < 0) {
                        this.setState({
                            firstItemIndex: 0
                        })
                    }
                })
            }
        }
    }

    private handleNextButtonClick = () => {
        if (this.canEdit()) {
            const itemCount = this.getCountDisplayItem()!;

            //만약 아이템 전체 갯수가 적을땐 버튼 클릭 안되도록 || 리스트 마지막까지 넘어갔을 때는 버튼 동작 막음
            if (this.props.list.length <= itemCount || (this.state.firstItemIndex + itemCount) >= this.props.list.length) {
                return;
            }

            if ((this.state.firstItemIndex + itemCount) <= this.props.list.length) {
                this.setState({
                    firstItemIndex: this.state.firstItemIndex + itemCount,
                }, () => {
                    //리스트 마지막까지 넘어왔는데 남아있는 아이템 갯수가 itemCount보다 작다면 왼쪽 정렬
                    if ((this.props.list.length - this.state.firstItemIndex) < itemCount) {
                        this.setState({
                            justifyContent: 'flex-start',
                            isDisabledNextButton: true
                        })
                    } else if ((this.props.list.length - this.state.firstItemIndex) === itemCount) {//리스트 마지막까지 넘어왔는데 남아있는 아이템갯수랑 itemCount랑 같다면 똑같이 space-around정렬
                        this.setState({
                            justifyContent: 'space-around',
                            isDisabledNextButton: true
                        })
                    }
                })
            }
        }
    }

    private handleItemClick = (value: string): void => {
        if (this.canEdit()) {
            Util.invokeEvent<Events.ChangeEventArgs<string>>(this.props.onChange, new Events.ChangeEventArgs<string>(this, value));
        }
    }

    renderComponent = () => {
        const { disabled, frozen, align, autoHideButtons, itemWidth, itemHeight } = this.props
        const verticalAlign = align === AlignType.vertical ? true : false

        const item: any[] = this.state.component.map((component, index) => {
            const onRenderItemMouseEvent = this.props.onRenderItem ? {
                onMouseEnter: (e) => {
                    if (this.props.list.length > index) {
                        this.setState({
                            mouseOverItemKey: this.props.list[index][this.props.itemKeyProperty]
                        })
                    }
                },
                onMouseLeave: (e) => {
                    this.setState({
                        mouseOverItemKey: null
                    })
                },
            } : {}

            const clone = React.cloneElement(component, {
                key: index,
                ref: index === this.state.firstItemIndex ? this.itemRef : null,
                style: {
                    ...(component && component.props) ? component.props.style : {},
                    display: index < this.state.firstItemIndex ? 'none' : 'inherit',
                    width: itemWidth.endsWith('%') ? 'calc(' + itemWidth + ' - 2px )' : itemWidth,
                    height: itemHeight.endsWith('%') ? 'calc(' + itemHeight + ' - 2px )' : itemHeight,
                    boxSizing: 'border-box',
                    marginLeft: verticalAlign || (index === this.state.firstItemIndex) ? '0' : '2px',
                    marginTop: verticalAlign === false || (index === this.state.firstItemIndex) ? '0' : '2px'
                },
                onClick: () => {
                    this.handleItemClick(component.key)
                },
                ...onRenderItemMouseEvent
            });
            return clone;
        });

        return (
            <div id={this.props.id}
                data-orbit-component={'OBTGroupSelector2'}
                className={Util.getClassNames(styles.root,
                    this.props.className,
                    disabled && styles.disabled)}
                style={{
                    width: verticalAlign ? itemWidth : this.props.width,
                    height: verticalAlign || (verticalAlign === false && this.props.height) ? this.props.height : itemHeight,
                    flexDirection: verticalAlign ? 'column' : 'row',
                }} >
                {/* 이전 버튼 영역 */}
                <div className={Util.getClassNames(styles.arrowButton,
                    (disabled || frozen || this.state.firstItemIndex <= 0 || this.state.isDisabledPrevButton) && styles.disabled,
                    verticalAlign ? styles.verticalPrevButton : styles.prevButton)}
                    style={{
                        width: verticalAlign ? itemWidth : '18px',
                        height: verticalAlign ? '18px' : itemHeight,
                        display: autoHideButtons && this.state.isHideButton ? 'none' : 'flex'
                    }}
                    onClick={this.handlePrevButtonClick}>
                </div>

                {/* 아이템 영역 */}
                <div ref={this.itemsContainerRef}
                    className={Util.getClassNames(styles.child,
                        disabled && styles.disabled,
                        frozen && styles.frozen
                    )}
                    style={{
                        margin: verticalAlign && !autoHideButtons ? '2px 0' : autoHideButtons && this.state.isHideButton ? '0 0' : '0 2px',
                        height: verticalAlign ? '100%' : itemHeight,
                        flexDirection: verticalAlign ? 'column' : 'row',
                        justifyContent: this.state.justifyContent
                    }}>
                    {item}
                </div>

                {/* 다음 버튼 영역 */}
                <div className={
                    Util.getClassNames(styles.arrowButton,
                        (disabled || frozen || this.state.isDisabledNextButton) && styles.disabled,
                        verticalAlign ? styles.verticalNextButton : styles.nextButton)}
                    style={{
                        width: verticalAlign ? itemWidth : '18px',
                        height: verticalAlign ? '18px' : itemHeight,
                        display: autoHideButtons && this.state.isHideButton ? 'none' : 'flex'
                    }}
                    onClick={this.handleNextButtonClick} >
                </div>
            </div>
        )
    }

    render() {
        return (<ErrorBoundary owner={this} render={this.renderComponent} />)
    }
};