import React, { Component } from 'react';
import keycode from "keycode";
import LUXButton from 'luna-rocket/LUXButton';
import { addMonths, addDays, getDaysInMonth, getFirstDayOfMonth, getFirstDayOfWeek } from 'luna-rocket/LUXDatePicker/LUXDateUtils';
import globalObj from 'luna-rocket/locale/intlGlobals';
import moment from 'moment'
import { OrbitInternalLangPack } from '../Common/Util';

function getStyles(state) {
    return {
        root: {
            padding: '8px 10px 10px',
            borderBottom: '1px solid #e0e0e0',
            outline: 'none',
        },
        innerDiv: {
            overflow: 'hidden',
        },
        innerDiv3: {
            position: 'relative',
            paddingLeft: '1px',
        },
        bar: {
            position: 'absolute',
            top: '100%',
            left: `calc(34px * ${state.checkedStartIndex})`,
            width: `calc(34px * ${state.checkedCount})`,
            height: '3px',
            marginTop: '-3px',
            background: '#1c90fb',
            content: '',
            zIndex: 10,
        },
        buttons: {
            default: {
                float: 'left',
                marginTop: '2px',
                padding: '1px 0 0',
            },
            first: {
                width: '12.0%',
                marginLeft: '2px',
            },
            second: {
                float: 'left',
                width: '12.0%',
                marginLeft: '2px',
            },
            third: {
                width: '35px',
                marginLeft: '-1px',
            }
        }
    }
}

class OBTDateControlButtons extends Component {
    state = {
        monthCheckedArr: [false, false, false, false, false, false, false, false, false, false, false, false],
        onButton: ''
    };

    componentWillMount() {
        const { selectedDateFrom, selectedDateTo } = this.props;
        this.getAllSelectedMonth(selectedDateFrom, selectedDateTo);
        let to = Number(moment(selectedDateTo).format('YYYY'));
        let from = Number(moment(selectedDateFrom).format('YYYY'));
        const thisYear = Number(moment().format('YYYY'))
        const lastYear = Number(moment().subtract(1, "year").format('YYYY'))
        const twoYearsAgo = Number(moment().subtract(2, "year").format('YYYY'))
        if (to - from === 0) {
            if (to === thisYear) {
                this.setState({ onButton: 'thisYear' })
            } else if (to === lastYear) {
                this.setState({ onButton: 'lastYear' })
            } else if (to === twoYearsAgo) {
                this.setState({ onButton: 'twoYearsAgo' })
            } else {
                this.setState({ onButton: "" })
            }
        } else {
            if (this.props.selectedDateFrom) {
                this.setState({ onButton: "" })
            } else {
                this.setState({ onButton: "thisYear" })
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedDateFrom !== this.props.selectedDateFrom || nextProps.selectedDateTo !== this.props.selectedDateTo) {
            this.setState({ checkedCount: 0 }, () => {
                this.getAllSelectedMonth(nextProps.selectedDateFrom, nextProps.selectedDateTo);
            })
            let to = Number(moment(nextProps.selectedDateTo).format('YYYY'));
            let from = Number(moment(nextProps.selectedDateFrom).format('YYYY'));
            const thisYear = Number(moment().format('YYYY'))
            const lastYear = Number(moment().subtract(1, "year").format('YYYY'))
            const twoYearsAgo = Number(moment().subtract(2, "year").format('YYYY'))
            if (to - from === 0) {
                if (to === thisYear) {
                    this.setState({ onButton: 'thisYear' })
                } else if (to === lastYear) {
                    this.setState({ onButton: 'lastYear' })
                } else if (to === twoYearsAgo) {
                    this.setState({ onButton: 'twoYearsAgo' })
                } else {
                    this.setState({ onButton: "" })
                }
            } else {
                this.setState({ onButton: "" })
            }

        }
    }

    getAllSelectedMonth(dateFrom, dateTo) {
        if (!dateFrom || !dateTo) return;

        let { monthCheckedArr } = this.state;
        for (let i = 0; i < monthCheckedArr.length; i++) {
            monthCheckedArr[i] = false;
        }

        let months = 0;

        // const today = new Date();
        // if (dateFrom.getFullYear() > today.getFullYear()) {
        //     months = -1;
        // }

        if (Number(moment(dateTo).format('YYYY')) - Number(moment(dateFrom).format('YYYY')) !== 0) {
            months = -1
        }

        if (months !== -1) {
            if (Number(moment(dateTo).format('YYYY')) - Number(moment(dateFrom).format('YYYY')) === 0) {
                months = Number(moment(dateTo).format('MM')) - Number(moment(dateFrom).format('MM'))
            } else {
                months = -1;
            }
        }

        const monthFrom = dateFrom.getMonth();
        const monthTo = dateTo.getMonth();
        let checkedCount = 0;
        let checkedStartIndex = 100;
        for (let i = months; i > 1; i--) {
            monthCheckedArr[monthFrom + i - 1] = true;
            checkedCount++;
            if (checkedStartIndex > monthFrom) checkedStartIndex = monthFrom + i - 1;
        }

        let cloneDateFrom = new Date(dateFrom);
        if (dateFrom.getDate() === 1 && Number(moment(dateTo).format('YYYY')) - Number(moment(dateFrom).format('YYYY')) === 0 && dateTo >= cloneDateFrom.setDate(getDaysInMonth(dateFrom))) {
            monthCheckedArr[monthFrom] = true;
            checkedCount++;
            if (checkedStartIndex > monthFrom) checkedStartIndex = monthFrom;
        }

        if (dateTo.getDate() === getDaysInMonth(dateTo) && Number(moment(dateTo).format('YYYY')) - Number(moment(dateFrom).format('YYYY')) === 0 && dateFrom <= getFirstDayOfMonth(dateTo)) {
            monthCheckedArr[monthTo] = true;
            if (monthFrom !== monthTo) {
                checkedCount++;
                if (checkedStartIndex > monthTo) checkedStartIndex = monthTo;
            }
        }

        this.setState({
            checkedCount: checkedCount,
            checkedStartIndex: checkedStartIndex,
            monthCheckedArr: monthCheckedArr,
        });
    }

    getBlankDate = () => {
        switch (this.state.onButton) {
            case 'thisYear':
                return new Date();
            case 'lastYear':
                let lastYearDate = new Date();
                return lastYearDate.setFullYear(lastYearDate.getFullYear() - 1);
            case 'twoYearsAgo':
                let twoYearsAgoDate = new Date();
                return twoYearsAgoDate.setFullYear(twoYearsAgoDate.getFullYear() - 2);
            default:
                return new Date();
        }
    }

    handleTouchTap = (label, event) => {
        const today = new Date();
        let dateFrom, dateTo;

        switch (label) {
            case globalObj.intl.messages["luna.rocket.today"]:
                dateFrom = today;
                dateTo = today;
                break;
            case globalObj.intl.messages["luna.rocket.theDaybefore"]:
                dateFrom = addDays(today, -1);
                dateTo = dateFrom;
                break;
            case globalObj.intl.messages["luna.rocket.weekly"]:
                dateFrom = getFirstDayOfWeek();
                dateTo = today;
                break;
            case globalObj.intl.messages["luna.rocket.lastWeek"]:
                dateFrom = addDays(getFirstDayOfWeek(), -7);
                dateTo = addDays(dateFrom, 6);
                break;
            case globalObj.intl.messages["luna.rocket.currentMonth"]:
                const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
                dateFrom = getFirstDayOfMonth(today);
                dateTo = new Date(today.getFullYear(), today.getMonth(), lastDay);
                break;
            case globalObj.intl.messages["luna.rocket.previousMonth"]:
                dateFrom = getFirstDayOfMonth(addMonths(today, -1));
                dateTo = new Date(dateFrom);
                dateTo.setDate(getDaysInMonth(dateFrom));
                break;
            case globalObj.intl.messages["luna.rocket.untilToday"]:
                dateFrom = new Date(today.getFullYear(), 0, 1);
                dateTo = today;
                break;
            case globalObj.intl.messages["luna.rocket.quarter1"]:
                dateFrom = new Date(moment(moment(this.props.selectedDateFrom ? this.props.selectedDateFrom : this.getBlankDate()).format('YYYY') + "/" + moment(new Date(today.getFullYear(), 0, 1)).format('MM/DD')).format("YYYY/MM/DD"));
                dateTo = addMonths(dateFrom, 2);
                dateTo.setDate(getDaysInMonth(dateTo));
                break;
            case globalObj.intl.messages["luna.rocket.quarter2"]:
                dateFrom = new Date(moment(moment(this.props.selectedDateFrom ? this.props.selectedDateFrom : this.getBlankDate()).format('YYYY') + "/" + moment(new Date(today.getFullYear(), 3, 1)).format('MM/DD')).format("YYYY/MM/DD"));
                dateTo = addMonths(dateFrom, 2);
                dateTo.setDate(getDaysInMonth(dateTo));
                break;
            case globalObj.intl.messages["luna.rocket.quarter3"]:
                dateFrom = new Date(moment(moment(this.props.selectedDateFrom ? this.props.selectedDateFrom : this.getBlankDate()).format('YYYY') + "/" + moment(new Date(today.getFullYear(), 6, 1)).format('MM/DD')).format("YYYY/MM/DD"));
                dateTo = addMonths(dateFrom, 2);
                dateTo.setDate(getDaysInMonth(dateTo));
                break;
            case globalObj.intl.messages["luna.rocket.quarter4"]:
                dateFrom = new Date(moment(moment(this.props.selectedDateFrom ? this.props.selectedDateFrom : this.getBlankDate()).format('YYYY') + "/" + moment(new Date(today.getFullYear(), 9, 1)).format('MM/DD')).format("YYYY/MM/DD"))
                dateTo = addMonths(dateFrom, 2);
                dateTo.setDate(getDaysInMonth(dateTo));
                break;
            case globalObj.intl.messages["luna.rocket.firstHalf"]:
                dateFrom = new Date(moment(moment(this.props.selectedDateFrom ? this.props.selectedDateFrom : this.getBlankDate()).format('YYYY') + "/" + moment(new Date(today.getFullYear(), 0, 1)).format('MM/DD')).format("YYYY/MM/DD"))
                dateTo = addMonths(dateFrom, 5);
                dateTo.setDate(getDaysInMonth(dateTo));
                break;
            case globalObj.intl.messages["luna.rocket.secondHalf"]:
                dateFrom = new Date(moment(moment(this.props.selectedDateFrom ? this.props.selectedDateFrom : this.getBlankDate()).format('YYYY') + "/" + moment(new Date(today.getFullYear(), 6, 1)).format('MM/DD')).format("YYYY/MM/DD"))
                dateTo = addMonths(dateFrom, 5);
                dateTo.setDate(getDaysInMonth(dateTo));
                break;
            case 'thisYear':
                this.setState({ onButton: 'thisYear' })
                dateFrom = new Date(today.getFullYear(), 0, 1);
                dateTo = new Date(today.getFullYear(), 11, 31);
                break;
            case 'lastYear':
                this.setState({ onButton: 'lastYear' })
                dateFrom = new Date(today.getFullYear() - 1, 0, 1);
                dateTo = new Date(today.getFullYear() - 1, 11, 31);
                break;
            case 'twoYearsAgo':
                this.setState({ onButton: 'twoYearsAgo' })
                dateFrom = new Date(today.getFullYear() - 2, 0, 1);
                dateTo = new Date(today.getFullYear() - 2, 11, 31);
                break;
            default: break;
        }
        this.props.onTouchTap(dateFrom, dateTo, event);
    }

    handleMouseDown = (month, event) => {
        this.setState({ monthFrom: month });
    }

    handleMouseUp = (month, event) => {
        const { monthFrom } = this.state;
        if (monthFrom === undefined) return;

        const today = new Date();
        if (monthFrom <= month) {
            let dateFrom = new Date(moment(moment(this.props.selectedDateFrom ? this.props.selectedDateFrom : this.getBlankDate()).format('YYYY') + "/" + moment(new Date(today.getFullYear(), monthFrom, 1)).format('MM/DD')).format("YYYY/MM/DD"));
            let dateTo = new Date(moment(moment(this.props.selectedDateFrom ? this.props.selectedDateFrom : this.getBlankDate()).format('YYYY') + "/" + moment(new Date(today.getFullYear(), month, 1)).format('MM/DD')).format("YYYY/MM/DD"));
            dateTo.setDate(getDaysInMonth(dateTo));
            this.props.onTouchTap(dateFrom, dateTo, event);
        }
        else {
            let dateFrom = new Date(moment(moment(this.props.selectedDateFrom ? this.props.selectedDateFrom : this.getBlankDate()).format('YYYY') + "/" + moment(new Date(today.getFullYear(), month, 1)).format('MM/DD')).format("YYYY/MM/DD"));
            let dateTo = new Date(moment(moment(this.props.selectedDateFrom ? this.props.selectedDateFrom : this.getBlankDate()).format('YYYY') + "/" + moment(new Date(today.getFullYear(), monthFrom, 1)).format('MM/DD')).format("YYYY/MM/DD"));
            dateTo.setDate(getDaysInMonth(dateTo));
            this.props.onTouchTap(dateFrom, dateTo, event);
        }

        this.setState({ monthFrom: undefined });
    }

    handleKeyDown = (event) => {
        event.stopPropagation();

        switch (keycode(event)) {
            case 'backspace':
                event.preventDefault();
                break;
            default: break;
        }
    }

    render() {
        const styles = getStyles(this.state);
        return (
            <div style={styles.root} tabIndex="0" onKeyDown={this.handleKeyDown}>
                <div style={styles.innerDiv}>
                    <LUXButton
                        label={OrbitInternalLangPack.getText('WE000022773', '올해')}
                        type="SAODefault"
                        style={Object.assign({}, styles.buttons.default, { width: '12.0%' })}
                        onTouchTap={this.handleTouchTap.bind(this, 'thisYear')}
                    />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.untilToday"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, { width: '12.5%', marginLeft: '2px' })} onTouchTap={this.handleTouchTap.bind(this, globalObj.intl.messages["luna.rocket.untilToday"])} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.today"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.first)} onTouchTap={this.handleTouchTap.bind(this, globalObj.intl.messages["luna.rocket.today"])} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.theDaybefore"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.first)} onTouchTap={this.handleTouchTap.bind(this, globalObj.intl.messages["luna.rocket.theDaybefore"])} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.weekly"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.first)} onTouchTap={this.handleTouchTap.bind(this, globalObj.intl.messages["luna.rocket.weekly"])} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.lastWeek"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.first)} onTouchTap={this.handleTouchTap.bind(this, globalObj.intl.messages["luna.rocket.lastWeek"])} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.currentMonth"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.first)} onTouchTap={this.handleTouchTap.bind(this, globalObj.intl.messages["luna.rocket.currentMonth"])} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.previousMonth"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.first)} onTouchTap={this.handleTouchTap.bind(this, globalObj.intl.messages["luna.rocket.previousMonth"])} />


                </div>
                <div style={styles.innerDiv}>
                    <LUXButton
                        label={OrbitInternalLangPack.getText('WE000028493', '전년도')}
                        type="SAODefault"
                        style={Object.assign({}, styles.buttons.default, { width: '12.0%' })}
                        onTouchTap={this.handleTouchTap.bind(this, 'lastYear')}
                    />
                    <LUXButton
                        label={OrbitInternalLangPack.getText('WE000028494', '전전년도')}
                        type="SAODefault"
                        style={Object.assign({}, styles.buttons.default, styles.buttons.second, { width: '12.5%' })}
                        onTouchTap={this.handleTouchTap.bind(this, 'twoYearsAgo')}
                    />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.quarter1"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.second)} onTouchTap={this.handleTouchTap.bind(this, globalObj.intl.messages["luna.rocket.quarter1"])} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.quarter2"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.second)} onTouchTap={this.handleTouchTap.bind(this, globalObj.intl.messages["luna.rocket.quarter2"])} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.quarter3"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.second)} onTouchTap={this.handleTouchTap.bind(this, globalObj.intl.messages["luna.rocket.quarter3"])} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.quarter4"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.second)} onTouchTap={this.handleTouchTap.bind(this, globalObj.intl.messages["luna.rocket.quarter4"])} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.firstHalf"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.second)} onTouchTap={this.handleTouchTap.bind(this, globalObj.intl.messages["luna.rocket.firstHalf"])} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.secondHalf"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.second)} onTouchTap={this.handleTouchTap.bind(this, globalObj.intl.messages["luna.rocket.secondHalf"])} />

                </div>
                <div style={Object.assign({}, styles.innerDiv, styles.innerDiv3)}>
                    <LUXButton label={globalObj.intl.messages["luna.rocket.january"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.third)} hoverStyle={{ zIndex: 1 }} onMouseDown={this.handleMouseDown.bind(this, 0)} onMouseUp={this.handleMouseUp.bind(this, 0)} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.february"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.third)} hoverStyle={{ zIndex: 1 }} onMouseDown={this.handleMouseDown.bind(this, 1)} onMouseUp={this.handleMouseUp.bind(this, 1)} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.march"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.third)} hoverStyle={{ zIndex: 1 }} onMouseDown={this.handleMouseDown.bind(this, 2)} onMouseUp={this.handleMouseUp.bind(this, 2)} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.april"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.third)} hoverStyle={{ zIndex: 1 }} onMouseDown={this.handleMouseDown.bind(this, 3)} onMouseUp={this.handleMouseUp.bind(this, 3)} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.may"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.third)} hoverStyle={{ zIndex: 1 }} onMouseDown={this.handleMouseDown.bind(this, 4)} onMouseUp={this.handleMouseUp.bind(this, 4)} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.june"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.third)} hoverStyle={{ zIndex: 1 }} onMouseDown={this.handleMouseDown.bind(this, 5)} onMouseUp={this.handleMouseUp.bind(this, 5)} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.july"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.third)} hoverStyle={{ zIndex: 1 }} onMouseDown={this.handleMouseDown.bind(this, 6)} onMouseUp={this.handleMouseUp.bind(this, 6)} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.august"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.third)} hoverStyle={{ zIndex: 1 }} onMouseDown={this.handleMouseDown.bind(this, 7)} onMouseUp={this.handleMouseUp.bind(this, 7)} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.september"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.third)} hoverStyle={{ zIndex: 1 }} onMouseDown={this.handleMouseDown.bind(this, 8)} onMouseUp={this.handleMouseUp.bind(this, 8)} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.october"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.third)} hoverStyle={{ zIndex: 1 }} onMouseDown={this.handleMouseDown.bind(this, 9)} onMouseUp={this.handleMouseUp.bind(this, 9)} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.november"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.third)} hoverStyle={{ zIndex: 1 }} onMouseDown={this.handleMouseDown.bind(this, 10)} onMouseUp={this.handleMouseUp.bind(this, 10)} />
                    <LUXButton label={globalObj.intl.messages["luna.rocket.december"]} type="SAODefault" style={Object.assign({}, styles.buttons.default, styles.buttons.third)} hoverStyle={{ zIndex: 1 }} onMouseDown={this.handleMouseDown.bind(this, 11)} onMouseUp={this.handleMouseUp.bind(this, 11)} />
                    <span style={styles.bar} />
                </div>
            </div>
        );
    }
}

export default OBTDateControlButtons;
