import React from 'react'

export default function mapChildren(children, callback) {
    return React.Children.map(children, (child, index) => {
        const newProps = callback(child, index);
        if (newProps) {
            return React.cloneElement(child, newProps);
        }
        return child;
    });
}
