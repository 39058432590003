import OBTListGridInterface, { ColumnAlignment } from './OBTListGridInterface';
import { IRealGridCellDynamicStyleOption } from '../OBTDataGrid/RealGridCellStyleOption';
// import IBuiltInCodePicker from '../OBTCodePicker/DataSource/IBuiltInCodePicker';

export enum DateFormat {
    'yyyyMMdd' = 'yyyyMMdd',
    'yyyyMM' = 'yyyyMM',
    'yyyy' = 'yyyy',
    'iso' = 'iso'
}

//TODO: 마스크인풋이랑 왠만하면 같은 타입쓰는게 좋을듯 
export enum MaskType {
    'custom' = 'custom',
    'biznumber' = 'biznumber',
    'resident' = 'resident',
    'foreign' = 'foreign',
    'passport' = 'passport',
    'driver' = 'driver',
    'credit' = 'credit',
    'account' = 'account',
    'tel' = 'tel',
    'email' = 'email'
}

/**
 * 텍스트 컬럼 작업시 
 */
export interface TextColumnEditorOption {
    allowSpecialChar: boolean,
    onlyNumber: boolean,
    preventCharList: string[],
}

// export enum CodePickerEditType {
//     'onlyDialog' = 'onlyDialog',
//     'notAllowEditorSearch' = 'notAllowEditorSearch'
// }

//TODO: readonly
//TODO: 헤더컬럼병합, 로우병합
//TODO: 다이나믹 스타일, 이미지,
export interface IColumn {
    name: string;
    /**
     * 데이터 프로바이더와 연결되는 필드명
     */
    fieldName?: string;
    /**
     * 그리드헤더 텍스트
     */
    header?: string;
    /**
     * 그리드헤더 이미지의 경로
     */
    headerImageUrl?: any;
    /**
     * 그리드헤더 이미지의 위치
     */
    headerImageLocation?: string;
    /**
     * 너비
     */
    width?: number;
    /**
     * 자동으로 컬럼너비가 조정되는지 여부
     * default = false
     */
    isAutoWidth?: boolean;
    /**
     * 컬럼 타입 
     */
    type?: ColumnType;
    /**
     * 텍스트 컬럼일경우 입력옵션
     */
    textColumnOption?: TextColumnEditorOption;
    /**
     * 
     */
    prefix?: string;
    /**
     * 
     */
    suffix?: string;
    /**
     * 
     */
    visible?: boolean;
    /**
     * 수정가능여부콜백
     * e = { columnName, rowIndex, values }
     * 
     */
    editable?: (e: any) => boolean | boolean;
    /**
     * 그룹일 경우 
     */
    columns?: IColumn[];
    /**
     * 셀 데이터의 정렬방식
     * 설정하지 않을경우 컬럼타입에 따라 자동으로 조정된다. (ex: 날짜는 가운데 정렬)
     */
    alignment?: ColumnAlignment;
    /**
     * 
     */
    trueValues?: string[]; // TODO: default, 
    /**
     * 
     */
    falseValues?: string[];
    /**
     * 숫자 컬럼에 대한 포메팅 문자열
     */
    numberColumnFormat?: string;
    /**
     * number컬럼일 경우 양수만 입력가능하게 할지 여부
     */
    positiveOnly?: boolean;
    /**
     * 액션 버튼을 가지는지 여부
     */
    hasActionButton?: boolean;
    /**
     * 
     */
    selectedItemDisplayCallBack?: (e: any) => string;
    /**
     * 
     */
    nanToZero?: boolean;
    /**
     * 
     */
    showZero?: boolean;
    /**
     * 필수값인지 여부
     */
    required?: boolean;
    /**
     * 읽기전용값이 여부
     */
    readonly?: boolean;
    /**
     * 날짜에 대한 포멧 
     * yyyyMMdd, yyyyMM, yyyy
     */
    dateFormat?: DateFormat;
    /**
     * 
     */
    useTooltip?: boolean;
    /**
     * 마스킹 타입
     */
    maskType?: MaskType;
    /**
     * 
     */
    editMask?: string;
    /**
     * 
     */
    customMaskCallback?: (e) => string;
    /**
     * 
     */
    dropDownDataItems?: any[];
    /**
     * 
     */
    dropDownCodeProperty?: string;
    /**
     * 
     */
    dropDownTextProperty?: string;
    /**
     * 
     */
    dropDownDataSourceCallBack?: Promise<any>;
    /**
     * 
     */
    footer?: {
        expression?: string,
        callback?: (e: any) => any
    };
    /**
     * 
     */
    footerValue?: string;
    /**
     * 
     */
    unmanaged?: any;
    /**
     * 
     */
    guideMessage?: string | React.Component;
    /**
     * 문자열 입력일 경우 최대길이 
     */
    maxLength?: number;
    /**
     * 아이콘 
     */
    useIcon?: boolean;
    /**
     * 아이콘 
     */
    onlyIcon?: boolean;
    /**
     * 
     */
    iconImageList?: string[];
    
    /**
     * 
     */
    style?: any;
    /**
     * “center” - 이미지 크기는 변경하지 않고 셀 중앙에 위치 시킵니다.
     * “both” - 이미지의 너비와 높이를 셀 크기에 맞춥니다. 이미지가 왜곡됩니다.
     * “width” - 이미지의 높이는 그냥 두고 너비만 셀 크기에 맞춥니다. 이미지가 왜곡됩니다.
     * “height” - 이미지의 너비는 그냥 두고 높이만 셀 크기에 맞춥니다. 이미지가 왜곡됩니다.
     * “auto” - 이미지의 비율을 유지한 채로 셀 크기에 맞춥니다.
     * “none”
     */
    fittingType?: 'center' | 'both' | 'width' | 'height' | 'auto' | 'none';
    /**
     * 다이나믹 스타일 콜백
     */
    dynamicStyles?: (grid: any, index: any, value: any) => IRealGridCellDynamicStyleOption;
    /**
     * 
     */
    calculateCallback?: (dataRow: any, fieldName: string, fieldNames: string[], values: any) => number;
    /**
     * 
     */
    afterCodeHelpCallback?: (e: any) => {};
    /**
     * 
     */
    renderer?: any;
    /**
     * type:button일경우 이미지 버튼 속성
     */
    imageButtons?: any;
    /**
     * type:checkImage일경우 trueValues값일경우 보여지는 이미지
     */
    trueImage?: any;
    /**
     * type:checkImage일경우 falseValues값일경우 보여지는 이미지
     */
    falseImage?: any;
    /**
     * type:checkImage일경우 빈값일경우 보여지는 이미지
     */
    emptyImage?: any;

    /**
     * 이미지 라벨을 사용할 것인지 여부.
     * useIcon 옵션과 함께 true일 수 없음.
     * type='check', 'progress'등 자체적인 렌더러를 사용하는 컬럼 타입에서는 사용할 수 없음
     */
    useImageLabel?: boolean,
    /**
     * useImageLabel = true일 경우 value에 따른 배경색 RGB 지정
     */
    imageLabelBackgroundColor?: ((value: string) => string) | string,
    /**
     * useImageLabel = true일 경우 사용자지정 이미지 라벨콜백함수.
     * 인자로 넘어온 canvas의 context를 조작하여 사용
     */
    customImageLabelCallback?: (value: string, backgroundColor?: ((value: string) => string) | string) => HTMLCanvasElement;

    /**
     * 
     */
    showDayOfWeek?: boolean | ((original: number) => string),

    /**
     * 링크 컬럼으로 렌더링할것인지 여부
     */
    linkable?: boolean,

    sortable?: boolean,

    /**
     * type = group 일 경우,
     * 자식들의 헤더를 보일 지에 대한 여부
     */
    hideChildHeaders?: boolean,

    /**
     * date, dropdown등 액션버튼을 가진 컬럼 타입의 경우 버튼이 보여지는 방법
     * "none" : 버튼을 보이지 않는다. 
     * "mouseOver" : 보이지 않다가 마우스가 올라간 순간 버튼이 보여진다. 
     * "always" : 항상 버튼이 보여진다.
     */
    buttonVisibility?: "none" | "mouseOver" | "always"

    showButtonOnlyEditable?: boolean,
}

export enum ColumnType {
    'text' = 'text',
    'number' = 'number',
    'dropDown' = 'dropDown',
    'date' = 'date',
    'dateTime' = 'dateTime',
    'check' = 'check',
    'button' = 'button',
    'data' = 'data',
    'mask' = 'mask',
    'group' = 'group',
    'image' = 'image',
    'visit' = 'visit',
    'checkImage' = 'checkImage'
}

// TODO: 구현
export class IColumnUtil {
    public validateIColumn(column: IColumn): any[] {
        if (column.type === undefined || column.type === ColumnType.text) {
            //column.
        }

        return [];
    }

    public normalizeColumn(column: IColumn): IColumn {

        return column;
    }

    //class ColumnValidate
}

/**
 * 버튼이 보여질지 여부를 결정하는 function을 리턴
 * @param grid 
 * @param column 
 */
export const buttonVisiblityFunction = (targtGrid: OBTListGridInterface, column: IColumn) => {
    return (grid, index, focused, mouseEntered) => {
        let isButtonVisible = false;
        if (column.buttonVisibility === "none") {
            isButtonVisible = false;
        } else if ((!column.buttonVisibility || column.buttonVisibility === "mouseOver") && (mouseEntered || focused)) {
            isButtonVisible = true;
        } else if (column.buttonVisibility === "always") {
            isButtonVisible = true;
        }

        if (isButtonVisible === true) {
            if (column.showButtonOnlyEditable === undefined || column.showButtonOnlyEditable === true) {
                isButtonVisible = targtGrid.isColumnEditable(column, index.itemIndex);
            }
        }
        return isButtonVisible;
    }
}