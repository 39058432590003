import React from 'react';
import OBTTooltip, { TooltipTheme } from '../OBTTooltip/OBTTooltip';

interface InputProps {
    className?: string,
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void,
    onChange: (e: { value: string, cancel: boolean, tooltip: any | null }) => void,
    style?: any,
    value?: string,
    editLabelTextRequired?: boolean,
    editLabelTextTooltip?: any
}

interface InputState {
    value?: string,
    showTooltip: boolean,
    tooltipLabelText: any | null,
    tooltipTheme: TooltipTheme | null,
}

export default class Input extends React.Component<InputProps, InputState> {
    state: InputState = {
        value: this.props.value,
        showTooltip: false,
        tooltipLabelText: null,
        tooltipTheme: null
    }

    input = React.createRef<HTMLInputElement>();

    render() {
        return (<OBTTooltip
            // theme={this.props.editLabelTextRequired ? OBTTooltip.Theme.required : this.props.editLabelTextTooltip.theme ? this.props.editLabelTextTooltip.theme : undefined}
            // value={this.props.editLabelTextRequired ? this.state.showTooltip : this.props.editLabelTextTooltip.value ? this.props.editLabelTextTooltip.value : undefined}
            theme={this.state.tooltipTheme ? this.state.tooltipTheme : undefined}
            value={this.state.showTooltip}
            labelText={this.state.tooltipLabelText}
            // value={this.state.tooltipValue}
            // {...this.props.editLabelTextTooltip}
        >
            <input
                className={this.props.className}
                ref={this.input}
                onKeyDown={this.props.onKeyDown}
                onChange={e => {
                    const eventArgs = {
                        target: this,
                        value: e.target.value,
                        cancel: false,
                        tooltip: null as any | null
                    };

                    this.props.onChange(eventArgs);

                    this.setState({
                        tooltipLabelText: eventArgs.tooltip && eventArgs.tooltip.labelText ? eventArgs.tooltip.labelText : null,
                        tooltipTheme: eventArgs.tooltip && eventArgs.tooltip.theme ? eventArgs.tooltip.theme : null,
                        showTooltip: eventArgs.tooltip ? true : false,
                    })

                    if (eventArgs.cancel !== true) {
                        this.setState({ value: e.target.value })
                    }
                }}
                value={this.state.value}
                style={this.props.style}
            />
        </OBTTooltip>);
    }

    componentDidMount() {
        if (this.input.current) {
            this.input.current.setSelectionRange(0, this.input.current.value.length);
            this.input.current.focus();
        }
    }

    save = async () => {
        if (this.input.current) {
            const value = this.input.current.value.trim();
            if (value.trim().length > 0) {
                if (this.state.showTooltip) {
                    await new Promise<void>(resolve => this.setState({
                        showTooltip: false
                    }, () => resolve));
                }
                return value;
            } else {
                if (!this.state.showTooltip) {
                    await new Promise<void>(resolve => this.setState({
                        showTooltip: true,
                        tooltipLabelText: this.props.editLabelTextTooltip.labelText,
                        tooltipTheme: OBTTooltip.Theme.required
                    }, () => resolve));
                }
                this.input.current.focus();
                return undefined;
            }
        }
    }

    cancel = async () => {
        if (this.state.showTooltip) {
            await new Promise<void>(resolve => this.setState({
                showTooltip: false
            }, () => resolve));
        }
        return true;
    }
}