import { Events } from '../../Common';

export class CodePickerDisplayItemEventArgs extends Events.EventArgs {
    public readonly target: any;
    public readonly codeProperty: string;
    public readonly textProperty: string;
    public readonly dataItemTotalCount: number;
    public readonly selectedItemLength: number;
    public readonly selectedItems: Array<Object>;
    public readonly exceptValue: Array<Object> | null = null;
    public readonly useTotalToEmpty: boolean = false;

    constructor(target: any,
        codeProperty: string,
        textProperty: string,
        selectedItems: Array<Object>,
        selectedItemLength: number,
        dataItemTotalCount: number,
        useTotalToEmpty: boolean,
        exceptValue?: Array<Object> | null,
        ) {
        super(target);
        this.target = target;
        this.codeProperty = codeProperty;
        this.textProperty = textProperty;
        this.dataItemTotalCount = dataItemTotalCount;
        this.selectedItemLength = selectedItemLength;
        this.selectedItems = selectedItems;
        this.useTotalToEmpty = useTotalToEmpty;
        this.exceptValue = exceptValue ? exceptValue : null;
    }

    getDisplayNameWithSeparator(separator: string) {
        return this.selectedItems.map(item => item[this.textProperty]).join(separator + ' ');
    }
}
