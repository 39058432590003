/**
 * OBTRadioButton
 * @version 0.1
 * @author 안광진
 * @see LUXRadioButton
 */

import * as React from 'react';
import LUXRadioButton from 'luna-rocket/LUXRadioButton';
import { Events, Util, CommonProps, Functions, createPropDefinitions, CommonDefinitions, CommonType } from '../Common';
//const styles = require('./OBTRadioButton.module.scss');

interface IOBTRadioButton extends CommonProps.id, CommonProps.className, CommonProps.width, CommonProps.height, CommonProps.disabled, CommonProps.required, Events.onFocus, Events.onBlur, Events.onMoveFocus,
    Events.onChange<string>, CommonProps.value<string>, CommonProps.labelText, Events.onKeyDown {
}

interface State {
}

export default class OBTRadioButton extends React.Component<IOBTRadioButton, State> implements Functions.IFocusable {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.ContainerClass(),
        CommonDefinitions.required(),
        CommonDefinitions.onChange({ type: CommonType.string, optional: true }),
        CommonDefinitions.value({ type: CommonType.string, description: "RadioButton 콤포넌트에 대한 고유한 식별값 정보가 정의되는 속성으로 RadioButtonGroup 콤포넌트의 value 값을 정의하는 속성입니다." }),
        CommonDefinitions.labelText(),
        CommonDefinitions.focus(),
        CommonDefinitions.onKeyDown()
    );

    public static defaultProps = {
        disabled: false,
        required: false
    };

    public state: State = {};

    public myRefs = {
        ref: React.createRef<LUXRadioButton>()
    };

    public focus(isLast: boolean = false): void {
        if (!this.props.disabled) {
            this.myRefs.ref.current.focus();
        }
    }

    private get canEdit(): boolean {
        return !this.props.disabled;
    }

    private handleMoveFocus = (direction: string): void => {
        Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
    }

    private handleFocus = (): void => {
        Util.invokeEvent<Events.EventArgs>(this.props.onFocus, new Events.EventArgs(this));
    }

    private handleBlur = (): void => {
        Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
    }

    private handleChange = (e: any, value: string): void => {
        if (this.canEdit) {
            Util.invokeEvent<Events.ChangeEventArgs<string>>(this.props.onChange, new Events.ChangeEventArgs<string>(this, value));
        }
    }

    private handleKeyDown = (event: React.KeyboardEvent): void => {
        Util.invokeEvent<Events.KeyEventArgs>(this.props.onKeyDown, new Events.KeyEventArgs(this, event));
    }

    render() {
        // const props = {
        //     // Value
        //     value: this.props.value,
        //     labelText: this.props.labelText,
        //     disabled: this.props.disabled,
        //     // Events
        //     onFocus: this.handleFocus,
        //     onBlur: this.handleBlur,
        //     onMoveFocus: this.handleMoveFocus,
        //     onChange: this.handleChange,
        //     style: Util.getWrapperStyle(this.props),
        //     onKeyDown: this.handleKeyDown,
        //     RadioButtonIconStyle: this.props.required ? {
        //         backgroundColor: '#fef4f4',
        //         borderRadius: '50%'
        //     } : undefined
        // }
        // const component = <LUXRadioButton ref={this.myRefs.ref} {...props} />;
        const component = undefined;
        return (<div id={this.props.id} data-orbit-component={'OBTRadioButton'} className={Util.getClassNames(this.props.className)}>{component}</div>);
    }
}
