/**
* OBTTextField
* @version 0.1
* @author 김철희
* @see LUXTextField
*/
import * as React from 'react';
import LUXTextField from 'luna-rocket/LUXTextField';
import { Events, CompositeProps, Util, CommonProps, Functions, Privacy, PrivacyBehaviorEnum, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import OBTTooltip, { IOBTTooltip } from '../OBTTooltip/OBTTooltip';
import * as Patterns from './Patterns';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import GuideMessageRenderer from '../Common/GuideMessageRenderer';
import { OBTContext } from '../OBTPageContainer/OBTPageContainer';
import searchPrivacyIcon from '../Images/ic-private-m-normal.png';
import searchPrivacyIconHover from '../Images/ic-open-m-over.png';
import detector from 'detector'

const styles = require('./OBTMaskedTextField.module.scss');

export enum MaskType {
    'custom' = 'custom',
    'biznumber' = 'biznumber',
    'conumber' = 'conumber',
    'resident' = 'resident',
    'foreign' = 'foreign',
    'passport' = 'passport',
    'driver' = 'driver',
    'credit' = 'credit',
    'account' = 'account',
    'tel' = 'tel',
    'email' = 'email',
    'cellular' = 'cellular',
    'landline' = 'landline'
}

enum AlignType {
    'left' = 'left',
    'center' = 'center',
    'right' = 'right'
}

interface IPattern {
    separator?: RegExp,
    rawExtractor?: (value?: string) => string,
    formatter?: (value?: string, options?: { withMasking?: boolean }) => string,
    validator?: (value?: string) => boolean
}

/**
 * style, disabled, readonly, required, value, placeHolder, type, onFocus, onBlur, onChange, onMoveFocus
 */
interface IOBTMaskedTextField extends CompositeProps.InputClass<string>, CommonProps.placeHolder, Events.onValidate<string>, Events.onClick, Events.onMouseDown,
    Events.onMouseMove, Events.onMouseUp, Events.onMouseLeave, Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress, Events.onKeyUp, CommonProps.usePrivacy {
    type: MaskType,
    pattern?: IPattern,
    align: AlignType,
    useMasking?: boolean,
    tooltip?: IOBTTooltip,
    checkDataClean?: boolean,
    informationText?: string //수정1
    useAutoComplete?: boolean
}

/**
* State 정의
*/
interface State extends hasError {
    type: MaskType,
    focused: boolean,
    pattern?: IPattern,
    selectionStart?: number,
    privacyButtonHovered?: boolean,
    isMobile: boolean,
}

/**
* OBTTextField
* Prop : { style, disabled, readonly, required, value, placeHolder, type, onFocus, onBlur, onChange, onMoveFocus }
*/
export default class OBTMaskedTextField extends React.Component<IOBTMaskedTextField, State> implements Functions.IFocusable {
    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.InputClass(CommonType.string),
        {
            name: "type", type: toEnumType(MaskType),
            default: "custom", description: "마스크 패턴 타입을 지정합니다."
                + "\n마스크 패턴은 입력패턴, 유효성체크, 개인정보보호 을 결정합니다."
                + "\ncustom : pattern 을 직접 지정합니다."
                + "\nbiznumber : 사업자등록번호 (___-__-_____)"
                + "\nconumber : 법인등록번호 (______-_______)"
                + "\nresident : 주민등록번호 (______-_______)"
                + "\nforeign : 외국인등록번호 (______-_______)"
                + "\npassport : 여권번호"
                + "\ndriver : 운전면허번호"
                + "\ncredit : 신용카드번호 (____-____-____-____)"
                + "\naccount : 계좌번호"
                + "\ntel : 전화번호(휴대폰, 유선전화 통합) (___-____ ...)"
                + "\ncellular : 휴대폰번호"
                + "\nlandline : 일반 유선전화"
                + "\nemail : 이메일 (______@____.___)", optional: true
        },
        {
            name: "pattern", type: {
                seperator: { type: "RegExp" },
                rawExtractor: {
                    type: CommonType.function, parameters: {
                        name: "value",
                        type: CommonType.string,
                        optional: true
                    }, result: CommonType.string, optional: true
                },
                formatter: {
                    type: CommonType.function, parameters: [
                        { name: "value", type: "string" },
                        { name: "options", type: "withMasking : boolean" }
                    ], optional: true
                },
                validator: {
                    type: CommonType.function, parameters: {
                        name: "value", type: CommonType.string
                    }, result: CommonType.boolean, optional: true
                }
            }, optional: true
        },
        { name: "align", type: toEnumType(AlignType), optional: true, default: "left", description: "텍스트 정렬 방법을 지정합니다." },
        { name: "useMasking", type: CommonType.boolean, optional: true, description: "마스킹(*표 표시) 기능의 사용여부를 지정합니다. false 로 지정시 강제로 마스킹 표시를 제거합니다." },
        { name: "useAutoComplete", type: CommonType.boolean, description: "자동완성 기능을 사용할지에 대한 여부를 설정합니다.", default: true },
        { name: "checkDataClean", type: CommonType.boolean, optional: true, description: "Validation에 대한 스타일을 초기화 시키는 속성입니다." },
        { name: "usePrivacy", type: CommonType.boolean, optional: true, description: "개인정보 암호화 사용 여부입니다.", default: false },
        {
            name: "privacyBehavior", type: toEnumType(PrivacyBehaviorEnum), optional: true, default: "viewByButton", description: "개인정보 암호화 조회 방식입니다."
                + "\nviewByButton : 개인정보 미조회 시, 조회버튼이 표시되며 조회버튼을 클릭할 경우 개인정보를 조회합니다. useMasking 옵션이 true 로 강제됩니다."
                + "\nviewByFocus : 컴포넌트에 포커스가 입력시 개인정보를 조회합니다. useMasking 옵션이 true 로 강제됩니다."
                + "\nalways : 컴포넌트에 값을 입력시 개인정보를 바로 조회합니다. useMasking 옵션이 false 로 강제됩니다."
        },
        {
            name: "onGetPrivacy", type: CommonType.function, parameters: {
                name: "e",
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    value: { type: CommonType.string },
                    privacykey: { type: CommonType.string },
                    cancel: { type: CommonType.boolean, description: "default = false" }
                }
            }, description: "개인정보를 표시해야 할때 호출되는 콜백함수입니다."
                + "\n개인정보를 API 를 통해 조회한 뒤 resolve 를 통해 리턴하십시오."
                + "\n리턴값은 개인정보값, 필드값 두 형태 모두 허용됩니다.", optional: true, result: ["Promise<any>"]
        },
        CommonDefinitions.tooltip(),
        CommonDefinitions.placeHolder(),
        CommonDefinitions.InnerFunc(),
        CommonDefinitions.onValidate(CommonType.string),
        CommonDefinitions.Event(),
    );

    ///////////////////////////////////////////////////////////////////////////// Initialize
    context!: React.ContextType<typeof OBTContext>;

    public static Type = MaskType
    public static Align = AlignType
    public static valueType = 'text';
    public static PrivacyBehavior = PrivacyBehaviorEnum;

    public static createPattern(
        separator: RegExp,
        rawExtractor: (value?: string) => string,
        formatter: (value?: string, options?: { withMasking?: boolean }) => string,
        validator: (value?: string) => boolean) {
        return new (class implements IPattern {
            separator = separator
            rawExtractor = rawExtractor
            formatter = formatter
            validator = validator
        })();
    }

    /**
     * Default Props 설정
     */
    public static defaultProps: Partial<IOBTMaskedTextField> = {
        disabled: false,
        readonly: false,
        required: false,
        type: MaskType.custom,
        align: AlignType.left,
        useAutoComplete: true
    }

    /**
     * State 정의
     */
    public state: State = {
        type: this.props.type,
        focused: false,
        pattern: OBTMaskedTextField.getPatterns(this.props.type, this.props.pattern),
        isMobile: detector.os.name === 'android' || window.navigator.userAgent.toLowerCase().indexOf('samsungbrowser') !== -1
    }

    /**
     * Ref 정의
     */
    public myRefs = {
        ref: React.createRef<LUXTextField>(),
        input: null
    } as {
        ref: React.RefObject<any>,
        input: any
    }

    ///////////////////////////////////////////////////////////////////////////// Life Cycle API
    private setImeMode = () => {
        if (this.myRefs.input) {
            const imeMode = this.myRefs.input.getAttribute('imeMode');
            if (imeMode === 'disabled' && this.props.type === MaskType.email) {
                this.myRefs.input.setAttribute('imeMode', 'auto');
            } else if (imeMode !== 'disabled') {
                this.myRefs.input.setAttribute('imeMode', 'disabled');
            }
        }
    }

    private setAutoComplete = () => {
        if (this.props.useAutoComplete) return;

        if (this.myRefs.ref.current && this.myRefs.ref.current.textfieldRef) {
            const textFieldRef = this.myRefs.ref.current.textfieldRef;
            const autoComplete = textFieldRef.getAttribute('autoComplete');
            if (autoComplete === 'pqdwef') {
                textFieldRef.setAttribute('autoComplete', 'new-password');
            }
        }
    }

    componentDidMount() {
        try {
            if (this.myRefs.ref.current && this.myRefs.ref.current.textfieldRef) {
                this.myRefs.input = this.myRefs.ref.current.textfieldRef;
                this.myRefs.input.addEventListener('keydown', this.handleKeyDown);
                this.myRefs.input.addEventListener('paste', this.handlePaste);
                this.myRefs.input.addEventListener('cut', this.handleCut);

                this.myRefs.input.addEventListener('keypress', this.handleKeyPress);
                // if (os === 'android') {
                //     this.myRefs.input.addEventListener('beforeinput', this.handleKeyPress);
                // } else {
                //     this.myRefs.input.addEventListener('keypress', this.handleKeyPress);
                // }
            }

            this.setAutoComplete();
            this.setImeMode();

            if (this.props.privacyBehavior === PrivacyBehaviorEnum.always) {
                this.getPrivacyValue();
            }
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    componentDidUpdate() {
        try {
            this.setAutoComplete();
            this.setImeMode();

            if (this.props.privacyBehavior === PrivacyBehaviorEnum.always) {
                this.getPrivacyValue();
            }
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    componentWillUnmount() {
        try {
            if (this.myRefs.input) {
                this.myRefs.input.removeEventListener('keydown', this.handleKeyDown);
                this.myRefs.input.removeEventListener('paste', this.handlePaste);
                this.myRefs.input.removeEventListener('cut', this.handleCut);

                this.myRefs.input.removeEventListener('keypress', this.handleKeyPress);
                // if (os === 'android') {
                //     this.myRefs.input.removeEventListener('beforeinput', this.handleKeyPress);

                // } else {
                //     this.myRefs.input.addEventListener('keypress', this.handleKeyPress);
                // }
            }
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    static getDerivedStateFromProps(nextProps: IOBTMaskedTextField, prevState: State): any {
        if (nextProps.type !== prevState.type || nextProps.type === MaskType.custom) {
            return {
                type: nextProps.type,
                pattern: OBTMaskedTextField.getPatterns(nextProps.type, nextProps.pattern)
            };
        }
        return null;
    }

    // shouldComponentUpdate(nextProps: IOBTMaskedTextField, nextState: State): boolean {
    //     try {
    //         const equals = (a: any, b: any): boolean => {
    //             try {
    //                 const aProps = Object.getOwnPropertyNames(a);
    //                 const bProps = Object.getOwnPropertyNames(b);
    //                 return aProps.length !== bProps.length || aProps.filter(name => name !== 'ref' && name !== 'selectionStart').findIndex((name) => bProps.includes(name) === false || a[name] !== b[name]) < 0;
    //             } catch {
    //                 return false;
    //             }
    //         }
    //         if (!equals(this.props, nextProps) &&
    //             !equals(this.state, nextState)) return false;
    //         return true;
    //     } catch (e) {
    //         Util.handleError(this, e);
    //         return false;
    //     }
    // }

    // component 가 render 될때 호출됨.
    render() {
        return (<ErrorBoundary owner={this} render={this.renderComponent} />)
    }

    renderComponent = () => {
        const pattern = this.state.pattern;
        const value = this.props.usePrivacy ? (this.value || '').replace(/[*]/g, '0') : this.value;
        const isMasked = this.isPrivacyMasked();
        const useMasking = this.props.usePrivacy ? true : this.props.useMasking;
        const withMasking = isMasked ? true :
            (this.props.usePrivacy && this.props.privacyBehavior === PrivacyBehaviorEnum.always) ? false :
                this.state.focused ? false :
                    useMasking === true ? true : false;
        const isSearchPrivacyIconShown = this.props.usePrivacy && (this.props.privacyBehavior || PrivacyBehaviorEnum.viewByButton) === PrivacyBehaviorEnum.viewByButton && isMasked ? true : false;
        const props = {
            // Value
            defaultValue: !this.state.focused && this.props.placeHolder && this.isEmpty() ? '' : pattern && pattern.formatter ? pattern.formatter(value, { withMasking: withMasking }) : value,
            valueOuterControl: true,

            // Events
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            onMoveFocus: this.handleMoveFocus,
            onChange: this.handleChange,

            // Etc
            inputElementType: 'text',
            focusOnSelect: false,
            disabled: this.props.disabled,
            hintText: this.props.placeHolder,
            fullWidth: true,
            checkData: isMasked || this.props.checkDataClean ? null : (!(value && value.length > 0) || !pattern || !pattern.validator) ? null : pattern.validator(value) ? 'Success' : 'Error',
            checkDataClean: isMasked || this.props.checkDataClean ? true : (!(value && value.length > 0) || !pattern || !pattern.validator) ? true : false,
            informationText: this.props.informationText,//수정2

            // Styling
            style: Object.assign({}, Util.getInputStateStyle(this.props), { width: '100%' }),
            inputBoxStyle: {
                padding: this.props.value !== '' ? '3px 24px 3px 6px' : '3px 6px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center', ...Util.getInputStateStyle(this.props)
            },
            infoStyle: { display: 'none' },
            inputStyle: {
                textAlign: this.props.align === AlignType.right ? 'right' : this.props.align === AlignType.center ? 'center' : 'left',
                fontFamily: 'inherit',
                // lineHeight: '13px'
            }
        };
        if (this.props.height && this.props.height.length > 0 && this.props.height !== 'auto') {
            props.inputBoxStyle['height'] = this.props.height;
            props.inputStyle['height'] = this.props.height;
            props['editingStyle'] = {
                height: this.props.height
            };
            props['iconStyle'] = {
                height: this.props.height
            }
        }
        const width = this.props.width ? this.props.width : (() => {
            switch (this.props.type) {
                case MaskType.biznumber:
                    return '104px';
                case MaskType.resident:
                    return '116px';
                case MaskType.foreign:
                    return '116px';
                case MaskType.credit:
                    return '146px';
                case MaskType.tel:
                    return '110px';
            }
            return undefined;
        })();

        return <OBTTooltip {...this.props.tooltip}
            className={Util.getClassNames(styles.obtMaskedTextField,
                isSearchPrivacyIconShown ? styles.searchPrivacyIconShown : undefined,
                this.props.className)}
            style={Util.getWrapperStyle(Object.assign({}, this.props, { width: width }))}
            overrideSize={false}
            onClick={this.props.onClick}
            onMouseDown={this.props.onMouseDown}
            onMouseMove={this.props.onMouseMove}
            onMouseUp={this.props.onMouseUp}
            onMouseLeave={this.props.onMouseLeave}
            onMouseEnter={this.props.onMouseEnter}
            onKeyUp={this.props.onKeyUp}
            onKeyDown={this.props.onKeyDown}
            onKeyPress={this.props.onKeyPress}
            rootProps={{
                id: this.props.id,
                'data-orbit-component': 'OBTMaskedTextField'
            }}>
            <LUXTextField ref={this.myRefs.ref} {...props} />
            {isSearchPrivacyIconShown ?
                <div className={styles.searchPrivacyIconRoot}>
                    <img className={styles.searchPrivacyIcon} src={this.state.privacyButtonHovered ? searchPrivacyIconHover : searchPrivacyIcon} alt={''} onClick={this.handleSearchPrivacy}
                        onMouseEnter={() => this.setState({
                            privacyButtonHovered: true
                        })}
                        onMouseLeave={() => this.setState({
                            privacyButtonHovered: false
                        })} />
                </div>
                : undefined}
        </OBTTooltip>;
    }

    ///////////////////////////////////////////////////////////////////////////// Logics
    private get value(): string {
        if (this.props.usePrivacy && this.props.onGetPrivacy) {
            const privacy = new Privacy(this.props.value);
            return (privacy.modifiedValue !== undefined && privacy.modifiedValue !== null ? privacy.modifiedValue : privacy.privacyValue) || '';
        }
        return this.props.value;
    }

    private isPrivacyMasked = () => {
        const value = this.value;
        if (value && value.includes('*')) return true;
        return false;
    }

    public focus(isLast: boolean = false): void {
        if (!this.props.frozen && !this.props.disabled) {
            this.myRefs.ref.current.focus();
        }
    }

    private get canEdit(): boolean {
        return !this.props.disabled && !this.props.readonly;
    }

    public isEmpty(): boolean {
        const value = this.value;
        return (!value || value.length <= 0);
    }

    public validate(): boolean {
        return !(this.props.required === true && this.isEmpty());
    }

    public isPatternValidate(): boolean {
        const value = this.value;
        return (!(value && value.length > 0) || !this.state.pattern || !this.state.pattern.validator) ? false : this.state.pattern.validator(value) ? true : false;
    }

    public static getPatterns(type: MaskType, pattern?: IPattern): IPattern {
        if (!pattern) {
            if (Patterns[type]) return new (Patterns[type])();
        }
        return pattern!;
    }

    private setSelection(oldFormattedValue: string, selection: number, nextSelection: boolean): void {
        if (this.myRefs.input && this.state.pattern) {
            const value = this.myRefs.input.value;
            if (this.state.pattern.separator) {
                const lastSeparatorCount = oldFormattedValue.length - oldFormattedValue.replace(new RegExp(this.state.pattern.separator, 'g'), '').length;
                const newSeparatorCount = value.length - value.replace(new RegExp(this.state.pattern.separator, 'g'), '').length;
                if (lastSeparatorCount !== newSeparatorCount) {
                    selection = value.length;
                } else {
                    for (; selection >= 0 && selection < value.length && this.state.pattern.separator.test(value[selection]); nextSelection ? selection++ : selection--) {
                    }
                }
            }

            this.myRefs.input.setSelectionRange(selection, selection);
        }
    }

    public async getPrivacyValue(): Promise<string | null> {
        if (this.value && this.props.usePrivacy && this.isPrivacyMasked() && this.props.onGetPrivacy) {
            const privacy = new Privacy(this.props.value);
            const eventArgs = new Events.GetPrivacyEventArgs(this, this.props.value, privacy.privacyKey || '');
            const value = await this.props.onGetPrivacy(eventArgs);
            if (eventArgs.cancel) return null;

            const privacyValue = new Privacy(value || '').privacyValue || '';
            const pattern = this.state.pattern;
            const withMasking = this.props.useMasking === false ? false : !this.state.focused;
            const source = {
                formatterValue: !this.state.focused && this.props.placeHolder && this.isEmpty() ? '' : pattern && pattern.formatter ? pattern.formatter(privacyValue, { withMasking: withMasking }) : privacyValue
            }
            privacy.privacyValue = privacyValue;
            Util.invokeEvent<Events.ChangeEventArgs<string>>(this.props.onChange, new Events.ChangeEventArgs(this, privacy.value || '', source));
            return privacyValue;
        }
        return null;
    }
    ///////////////////////////////////////////////////////////////////////////// Event Handlers
    private handleMoveFocus = (direction: string): void => {
        const value = this.value;
        if (this.props.required && (!value || value.length <= 0) && ['right', 'down', 'enter'].includes(direction)) return;
        Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
    }

    private handleSearchPrivacy = () => {
        this.getPrivacyValue().then(() => this.focus());
    }

    private handleFocus = (e): void => {
        new Promise<void>((resolve) => {
            if (this.props.privacyBehavior === PrivacyBehaviorEnum.viewByFocus) {
                this.getPrivacyValue().then(() => resolve());
            } else {
                resolve();
            }
        }).then(() => {
            this.setState({ focused: true }, () => {
                Util.invokeEvent<Events.EventArgs>(this.props.onFocus, new Events.EventArgs(this));

                setTimeout(() => {
                    if (this.myRefs.ref.current && this.myRefs.ref.current.textfieldRef) {
                        const ref = this.myRefs.ref.current.textfieldRef;
                        ref.setSelectionRange(0, ref.value.length);
                    }
                }, 0);
                GuideMessageRenderer.handleFocus(
                    this, this.props.id, this.context
                );
            });
        })
    }

    private setValue(value: string): Promise<void> {

        return new Promise((resolve, reject) => {
            if (Util.invokeEvent<Events.ValidateEventArgs<string>>(this.props.onValidate, new Events.ValidateEventArgs(this, this.value, value))) {
                const pattern = this.state.pattern;
                if (this.state.isMobile === true && pattern && pattern.rawExtractor) {
                    value = pattern.rawExtractor(value)
                }

                const withMasking = this.props.useMasking === false ? false : !this.state.focused;
                const source = {
                    formatterValue: !this.state.focused && this.props.placeHolder && this.isEmpty() ? '' : pattern && pattern.formatter ? pattern.formatter(value, { withMasking: withMasking }) : value
                }
                let fieldValue = value;
                if (this.props.usePrivacy) {
                    const privacy = new Privacy(this.props.value);
                    privacy.modifiedValue = fieldValue;
                    fieldValue = privacy.value || '';
                }

                Util.invokeEvent<Events.ChangeEventArgs<string>>(this.props.onChange, new Events.ChangeEventArgs(this, fieldValue, source));
                resolve();
            } else {
                reject();
            }
        })
    }

    private handleKeyDown = (e: KeyboardEvent): void => {
        if (!this.canEdit) return;
        const input = e.target as HTMLInputElement;
        const value: string = input.value;
        switch (e.key) {
            case 'Delete':
            case 'Backspace':
                if (this.state.pattern) {
                    let selectionStart = input && input.selectionStart ? input.selectionStart : 0;
                    let selectionEnd = input && input.selectionEnd ? input.selectionEnd : 0;
                    if (selectionStart === selectionEnd) {
                        if (e.key === 'Backspace') {
                            selectionStart = selectionStart - 1;
                        } else {
                            selectionEnd = selectionStart + 1;
                        }
                    }

                    let originalRaw = this.state.pattern.rawExtractor ? this.state.pattern.rawExtractor(value) : value;
                    if (this.props.usePrivacy && originalRaw.includes('*')) {
                        originalRaw = '';
                    }

                    while (selectionStart >= 0 && selectionStart < value.length &&
                        selectionEnd >= 0 && selectionEnd <= value.length) {
                        const newValue = value.split('').map(((selectionStart, selectionEnd) => (v, i) => i >= selectionStart && i < selectionEnd ? '' : v)(selectionStart, selectionEnd)).join('');
                        const raw = this.state.pattern.rawExtractor ? this.state.pattern.rawExtractor(newValue) : newValue;
                        if (raw !== originalRaw) {
                            this.setValue(raw)
                                .then(((selectionStart) => () => {
                                    this.setState({
                                        selectionStart: selectionStart
                                    }, () => {
                                        setTimeout(() => {
                                            this.setSelection(value, selectionStart, e.key === 'Backspace' ? false : true);
                                        }, 0);
                                    });
                                })(selectionStart));
                            break;
                        } else {
                            if (e.key === 'Backspace') {
                                selectionStart--;
                            } else {
                                selectionEnd++;
                            }
                        }
                    }
                    e.stopPropagation();
                    e.preventDefault();
                }
                break;
        }
    }

    private handleKeyPress = (e: KeyboardEvent): void => {
        // let os = detector.os.name;
        if (!this.canEdit) return;
        if (this.state.isMobile && this.state.pattern) {
            return;
        }
        if (this.props.type === MaskType.email) return;
        if (this.state.pattern) {
            const input = e.target as HTMLInputElement;
            const newChar = e.key || e.key.trim();
            if (newChar && newChar.length === 1) {
                let selectionStart = input && input.selectionStart ? input.selectionStart : 0;
                let selectionEnd = input && input.selectionEnd ? input.selectionEnd : 0;
                const value: string = input.value;
                let newValue = selectionStart !== selectionEnd ? value.split('').map((v, i) => i >= selectionStart && i < selectionEnd ? '' : v).join('') : value;
                newValue = newValue.slice(0, selectionStart) + newChar + newValue.slice(selectionStart);
                if (this.props.usePrivacy && value.includes('*')) {
                    newValue = newChar;
                }
                const originalRaw = this.state.pattern.rawExtractor ? this.state.pattern.rawExtractor(value) : value;
                const raw = this.state.pattern.rawExtractor ? this.state.pattern.rawExtractor(newValue) : newValue;
                if (originalRaw !== raw) {
                    this.setValue(raw).then(() => {
                        this.setState({
                            selectionStart: selectionStart + 1
                        }, () => {
                            setTimeout(() => {
                                this.setSelection(value, selectionStart + 1, true);
                            }, 0);
                        });
                    });
                }
            }
            e.stopPropagation();
            e.preventDefault();
        }
    }

    private handlePaste = (e: ClipboardEvent): void => {
        if (!this.canEdit) return;
        if (this.state.pattern) {
            const input = e.target as HTMLInputElement;
            const pasteValue = e.clipboardData ? e.clipboardData.getData('text') : null;
            if (pasteValue) {
                let selectionStart = input && input.selectionStart ? input.selectionStart : 0;
                let selectionEnd = input && input.selectionEnd ? input.selectionEnd : 0;
                const value: string = input.value;
                let newValue = selectionStart !== selectionEnd ? value.split('').map((v, i) => i >= selectionStart && i < selectionEnd ? '' : v).join('') : value;
                newValue = newValue.slice(0, selectionStart) + pasteValue + newValue.slice(selectionStart);
                const originalRaw = this.state.pattern.rawExtractor ? this.state.pattern.rawExtractor(value) : value;
                const raw = this.state.pattern.rawExtractor ? this.state.pattern.rawExtractor(newValue) : newValue;
                if (originalRaw !== raw) {
                    this.setValue(raw).then(() => {
                        if (this.state.pattern) {
                            const formatted = this.state.pattern.formatter ? this.state.pattern.formatter(raw) : raw;
                            let newSelection = formatted.length;
                            if (formatted.includes('_')) {
                                newSelection = formatted.indexOf('_');
                            }
                            this.setState({
                                selectionStart: newSelection
                            }, () => {
                                setTimeout(() => {
                                    this.setSelection(value, newSelection, true);
                                }, 0);
                            });
                        }
                    });
                }
                e.stopPropagation();
                e.preventDefault();
            }
        }
    }

    private handleCut = (e: ClipboardEvent): void => {
        if (!this.canEdit) return;
        if (this.state.pattern) {
            const input = e.target as HTMLInputElement;
            let selectionStart = input && input.selectionStart ? input.selectionStart : 0;
            let selectionEnd = input && input.selectionEnd ? input.selectionEnd : 0;
            if (selectionStart !== selectionEnd) {
                const value: string = input.value;
                const clipBoardValue = value.substr(selectionStart, selectionEnd - selectionStart);
                if (e.clipboardData) e.clipboardData.setData('text/plain', clipBoardValue);
                let newValue = value.split('').map((v, i) => i >= selectionStart && i < selectionEnd ? '' : v).join('');
                const raw = this.state.pattern.rawExtractor ? this.state.pattern.rawExtractor(newValue) : newValue;
                this.setValue(raw).then(() => {
                    this.setState({
                        selectionStart: selectionStart
                    }, () => {
                        setTimeout(() => {
                            this.setSelection(value, selectionStart, true);
                        }, 0);
                    });
                })
                e.stopPropagation();
                e.preventDefault();
            }
        }
    }

    private handleChange = (e: React.ChangeEvent, value: string) => {
        if (!this.canEdit) return;
        if (this.props.type === MaskType.email) {
            this.setValue(value);
        } else {
            if (this.state.isMobile) {
                this.setValue(value);
            }
        }
    }

    private handleBlur = (): void => {
        this.setState({ focused: false }, () => {
            Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
            GuideMessageRenderer.handleBlur(
                this.context,
                this.props.id,
            );
        });
    }
};

OBTMaskedTextField.contextType = OBTContext;