/**
 * @version 0.1
 * @author 하성준
 * @see common.js
 */
import * as React from 'react';
import { Events, CompositeProps, Functions, Util, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import { PositionType } from '../OBTFloatingPanel/OBTFloatingPanel';
import OBTDatePicker from '../OBTDatePicker';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import GuideMessageRenderer from '../Common/GuideMessageRenderer';
import { OBTContext } from '../OBTPageContainer/OBTPageContainer';

/**
 * @internal
 * CSS Modules 사용방식
 * styles.[className]
 * {@code <div className={styles.required}}
 */

interface Value {
    from: string,
    to: string
}

export enum AlignType {
    'near' = 'near',
    'center' = 'center',
    'far' = 'far'
}

interface IOBTDatePeriodPicker extends CompositeProps.InputClass<Value>, Events.onValidate<Value>, Events.onMouseDown,
    Events.onClick, Events.onMouseMove, Events.onMouseUp, Events.onMouseLeave, Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress, Events.onKeyUp {

    /**
     * 입력 가능한 날짜에 대한 범위의 최대(끝) 날짜를 지정하는 속성입니다.
     */
    max?: string,

    /**
     * 입력 가능한 날짜에 대한 범위의 최소(시작) 날짜를 지정하는 속성입니다.
     */
    min?: string

    /**
     *  툴팁 설정 기능입니다.
     */
    tooltip?: any,

    /**
    *  패널의 정렬 위치를 지정합니다.
    *  
    */
    align?: AlignType,

    /**
     *  패널의 위치를 지정합니다.
     *  
     */
    position?: PositionType,
    /**
     * 상태 ( disabled, readonly, required ) 를 가진 경우라도 기본 스타일을 유지합니다.
     */
    useStatelessStyle?: boolean,
    /**
     * period 에서 from, to 모두 입력되어야만 유효하다 판단합니다.
     */
    allowFullPeriodOnly?: boolean,

    showWeekday?: boolean
}

interface State extends hasError {
}

export default class OBTDatePeriodPicker extends React.Component<IOBTDatePeriodPicker, State> implements Functions.IFocusable {

    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.InputClass(['from(필수) : string, to(필수) : string']),
        { name: 'min', description: '입력 가능한 날짜에 대한 범위의 최소(시작) 날짜를 지정하는 속성입니다.', optional: true, type: CommonType.string, default: 'addYears(new Date(), 100)' },
        { name: 'max', description: '입력 가능한 날짜에 대한 범위의 최대(끝) 날짜를 지정하는 속성입니다.', optional: true, type: CommonType.string, default: 'addYears(new Date(), -100)' },
        { name: "align", type: toEnumType(AlignType), description: "dialog의 정렬을 지정하는 속성입니다.", default: "far", optional: true },
        { name: "position", type: toEnumType(PositionType), description: "dialog의 포지션을 지정하는 속성입니다.", default: "bottom", optional: true },
        { name: "useStatelessStyle", type: CommonType.boolean, default: false, optional: true, description: "상태(disabled, readonly, required, frozen)가 지정된 경우 useStatelessStyle 을 true 로 지정하면 css 스타일(배경색, 폰트컬러 등)을 기본으로 유지합니다." },
        { name: "allowFullPeriodOnly", type: CommonType.boolean, default: false, optional: true, description: "Period 타입일 경우 from, to 모두 입력되어야지만 유효하다고 판단합니다. \n1. required 인 경우 from, to 가 반드시 입력되어야 입력이 종결됩니다. 빈 값은 허용되지 않습니다. \n2. required 가 아닌 경우, from, to 가 각각 비어있거나 입력되어야 입력이 종결됩니다. from 만 입력 되거나, to 만 입력되어도 유효하다 판단합니다. \n3. required 가 아니며, allowFullPeriodOnly 인 경우 from, to 모두 비어있거나 모두 입력되어야만 유효하다 판단합니다." },
        { name: "showWeekday", type: CommonType.boolean, default: false, description: "true 로 지정할 경우 요일이 표시됩니다.", optional: true },
        CommonDefinitions.tooltip(),
        CommonDefinitions.focus(),
        CommonDefinitions.onValidate(CommonType.any),
        CommonDefinitions.Event(),
    );

    ///////////////////////////////////////////////////////////////////////////// Initialize
    context!: React.ContextType<typeof OBTContext>;

    /**
     * @internal
     * Default Props 설정
     */
    public static defaultProps = {
        disabled: false,
        readonly: false,
        required: false,
        frozen: false,
        align: AlignType.near,
        position: PositionType.bottom
    }

    public state: State = {
        hasError: false
    }

    public static Align = AlignType;
    public static Position = PositionType;

    /**
     * @internal
     * Ref 정의
     */
    public myRefs = {
        id: React.createRef<OBTDatePicker>()
    }

    ///////////////////////////////////////////////////////////////////////////// Life Cycle API

    renderComponent = () => {
        const { align, position, ...others } = this.props;
        return (
            <OBTDatePicker
                {...others}
                ref={this.myRefs.id}
                dataOrbitComponent={'OBTDatePeriodPicker'}
                format={OBTDatePicker.Format.YYYYMMDD}
                type={OBTDatePicker.Type.period}
                onMoveFocus={this.handleMoveFocus}
                dialogAlign={align}
                dialogPosition={position}
            />
        )
    }
    // component 가 render 될때 호출됨.
    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        )
    }


    ///////////////////////////////////////////////////////////////////////////// Logics
    public focus(isLast?: boolean): void {
        if (this.myRefs.id.current) {
            this.myRefs.id.current.focus();
        }
    }

    private isEmpty(): boolean {
        return (!this.props.value || !this.props.value.from || !this.props.value.to || this.props.value.from.length !== 8 || this.props.value.to.length !== 8);
    }

    private handleMoveFocus = (e: any): void => {
        if (this.props.required && this.isEmpty() && ['right', 'down', 'enter'].includes(e.direction)) return;
        Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, e.direction));
    }

    private handleFocus = (e): void => {
        Util.invokeEvent<Events.FocusEventArgs>(this.props.onFocus, e);
        GuideMessageRenderer.handleFocus(
            this, this.props.id, this.context
        );
    }

    private handleBlur = (): void => {
        Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
        GuideMessageRenderer.handleBlur(
            this.context,
            this.props.id,
        );
    }
};

OBTDatePeriodPicker.contextType = OBTContext;
