import React, { Component, Fragment } from 'react';

export default class MainIntroPlanetLogo extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {

        return (
            <Fragment>
                {/* 메인 로고 */}
                <div className="rocket">
                    <div className="rocket-rotate">
                        <div className="pod" />
                        <div className="fuselage" />
                    </div>
                </div>
                <div className="orbitPluto">
                    <div className="orbitPlanet animated1s jello infinite">
                        <div className="crater1" />
                        <div className="crater2" />
                        <div className="crater3" />
                    </div>
                    <div className="orbitObject">
                        <div className="luna" />
                    </div>
                    <div className="textBox animated1s fadeInUp delay1s">Luna-Orbit<b>Playground</b></div>
                </div>
            </Fragment>
        );
    }
}
