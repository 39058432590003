/**
 * OBTLinearProgress
 * @version 0.1
 * @author 박재성
 * @see LUXLinearProgress
 */
import * as React from 'react';
import LUXLinearProgress from 'luna-rocket/LUXProgress/LUXLinearProgress';
import { CommonDefinitions, CommonProps, CommonType, createPropDefinitions, toEnumType, Util } from '../Common';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const styles = require('./OBTLinearProgress.module.scss');

enum Type {
    'error' = 'error',
    'default' = 'default',
    'warning' = 'warning'
}

/**
 * PropType 정의
 * Events, CommonProps, CompositeProps 에 미리 지정된 Prop interface 를 충분히 활용한다.
 * extends 로 인터페이스 상속을 통해 사용된다.
 * 공용 Api 를 사용하려면 CommonProps.api 인터페이스를 확장한다.
 */
interface IOBTLinearProgress extends CommonProps.id, CommonProps.className, CommonProps.width, CommonProps.height {
    type: Type,
    value: number, // 진행률
    labelText?: any,
}

/**
 * State 정의
 */
interface State extends hasError {
}

export default class OBTLinearProgress extends React.Component<IOBTLinearProgress, State> {

    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.id(),
        CommonDefinitions.className(),
        CommonDefinitions.width(),
        CommonDefinitions.height(),
        { name: "type", type: toEnumType(Type), default: "default", optional: true, description: "type에 따라 progressbar의 색을 결정합니다." },
        { name: "value", type: CommonType.number, description: "type에 따라 progressbar의 색을 결정합니다." },
        { name: "labelText", type: CommonType.string, description: "progressbar 위 텍스트 영역을 정의합니다.", optional: true },
    );

    ///////////////////////////////////////////////////////////////////////////// Initialize
    public static Type = Type

    /**
     * Default Props 설정
     */
    public static defaultProps = {
        width: '384px',
        height: 'auto',
        value: 0,
        type: Type.default
    }

    /**
     * State 정의
     */
    public state: State = {}

    ///////////////////////////////////////////////////////////////////////////// Life Cycle API

    // component 가 render 될때 호출됨.
    render() {
        return (<ErrorBoundary owner={this} render={this.renderComponent} />)
    }

    renderComponent = () => {
        const props = {
            color: this.props.type === Type.default ? "#1C90FB" : (this.props.type === Type.error ? "#fc5356" : "#febc2c"),
            value: this.props.value,
            style: {
                height: '12px',
                borderRadius: 0
            }
        };

        const localStyles = {
            width: this.props.width,
            height: this.props.height
        }

        let labelTextElement = this.props.labelText ?
            <div className={styles.labelText}>{React.Children.toArray(this.props.labelText)}</div> : <div className={styles.empty}></div>;

        return (
            <div id={this.props.id} data-orbit-component={'OBTLinearProgress'} className={Util.getClassNames(this.props.className, styles.root)} style={localStyles}>
                {/* labelText 영역 */}
                {labelTextElement}

                {/* 프로그레스 영역 */}
                <div className={styles.progressDiv}>

                    {/* 프로그레스 바 */}
                    <LUXLinearProgress {...props} />

                    {/* 텍스트 */}
                    <div className={styles.innerText}>{this.props.value}%</div>
                </div>
            </div>
        );
    }
};
