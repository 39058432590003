/**
 * OBTAlert
 * @version 0.1
 * @author 박재성
 */
import * as React from 'react';
import { Events, Util, CommonProps, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import { OBTButton } from '..';

import IconAlertApproval from '../Images/icon-alert-approval.png';
import IconAlertError from '../Images/icon-alert-error.png';
import IconAlertQuest from '../Images/icon-alert-quest.png';
import IconAlertWarning from '../Images/icon-alert-warning.png';

import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';

import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { OBTContext } from '../OBTPageContainer/OBTPageContainer';
import OBTPortal from '../OBTPortal';
import { OrbitInternalLangPack } from '../Common/Util';

const styles = require('./OBTAlert.module.scss');

export enum Type {
    'default' = 'default',
    'success' = 'success',
    'warning' = 'warning',
    'error' = 'error',
    'question' = 'question'
}

interface IOBTAlert extends CommonProps.id, CommonProps.className {
    type: Type,
    title?: any,
    labelText?: any,
    buttonLabelText?: string,
    onClose?: (e: Events.EventArgs) => void,
}

interface State extends hasError {
    open: boolean
}

export default class OBTAlert extends React.Component<IOBTAlert, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.id(),
        CommonDefinitions.className(),
        { name: 'type', type: toEnumType(Type), default: 'default', optional: true, description: 'type에 따른 아이콘을 지정합니다.' },
        { name: 'title', type: CommonType.string, optional: true, description: '타이틀을 지정합니다.' },
        CommonDefinitions.labelText({ description: '메세지를 지정합니다.' }),
        {
            name: 'buttonLabelText', type: CommonType.string, optional: true, description: '버튼 텍스트를 지정할 수 있습니다. default값 확인'
        },
        {
            name: 'onClose', type: CommonType.function, parameters: { name: 'e', type: { target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' } } }, optional: true,
            description: '컴포넌트에서 확인 버튼 클릭, enter 입력, esc 입력, 배경클릭 이벤트 발생 시 발생하는 Callback 함수입니다.'
        }
    )

    public static Type = Type

    public static defaultProps = {
        type: OBTAlert.Type.default
    }

    public state: State = {
        open: true
    }

    public myRefs = {
        ref: React.createRef<HTMLDivElement>()
    }

    private isKeyDownEventTriggered = false;

    componentDidMount() {
        try {
            if (this.myRefs.ref.current) {
                this.myRefs.ref.current.focus();
            }
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    private onClose(): void {
        Util.invokeEvent<Events.EventArgs>(this.props.onClose, new Events.EventArgs(this));
    }

    handleClick = (event) => {
        this.setState({ open: false });
        this.onClose()
    }

    handleOnKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (this.isKeyDownEventTriggered === false) {
            return;
        }
        this.isKeyDownEventTriggered = false;

        if (this.state.open) {
            switch (event.keyCode) {
                case 9:  // tab
                    event.preventDefault();
                    break;
                case 13: // enter
                    this.setState({ open: false });
                    this.onClose();
                    break;
                case 27: // esc
                    this.setState({ open: false });
                    this.onClose();
                    break;
            }
            event.stopPropagation();
        }
    }

    renderComponent = () => {
        const {
            type,
            title,
            labelText
        } = this.props;

        let iconElement;
        if (type !== OBTAlert.Type.default) {
            if (type === OBTAlert.Type.success) {
                iconElement = <img className={styles.alertIconStyle} src={IconAlertApproval} alt="" />
            } else if (type === OBTAlert.Type.warning) {
                iconElement = <img className={styles.alertIconStyle} src={IconAlertWarning} alt="" />
            } else if (type === OBTAlert.Type.error) {
                iconElement = <img className={styles.alertIconStyle} src={IconAlertError} alt="" />
            } else if (type === OBTAlert.Type.question) {
                iconElement = <img className={styles.alertIconStyle} src={IconAlertQuest} alt="" />
            }
        }

        let titleElement = title ? <div className={styles.alertTitleStyle}>{title}</div> : undefined;

        let messageElement = labelText ? <div className={styles.alertMessageStyle}>{labelText}</div> : undefined;

        return (
            <div ref={this.myRefs.ref}
                className={Util.getClassNames(styles.root, this.state.open ? undefined : styles.closed, this.props.className)}
                tabIndex={-1}
                onKeyUp={this.handleOnKeyUp}
                onKeyDown={() => {
                    this.isKeyDownEventTriggered = true;
                }}
                id={this.props.id}
                data-orbit-component='OBTAlert'>
                {this.state.open ?
                    <Fade duration={300}><div className={styles.dimmed} /></Fade> : null}
                <div className={styles.wrapper}>
                    {this.state.open ? <Bounce duration={300}>
                        <div className={styles.alertBoxStyle}>
                            {iconElement}

                            {titleElement}

                            {messageElement}
                            <div className={styles.buttonsDiv}>
                                <OBTButton
                                    className={styles.button}
                                    labelText={this.props.buttonLabelText ? this.props.buttonLabelText :
                                        OrbitInternalLangPack.getText('WE000000054', '확인')}
                                    type={OBTButton.Type.big}
                                    theme={OBTButton.Theme.blue}
                                    onClick={this.handleClick}
                                />
                            </div>
                        </div>
                    </Bounce> : null}
                </div>
            </div>);
    }

    render() {
        return (
            <OBTContext.Consumer>
                {
                    value => {
                        return <OBTPortal><ErrorBoundary
                            owner={this}
                            render={this.renderComponent}
                        /></OBTPortal>;
                    }
                }
            </OBTContext.Consumer>
        )
    }
};
