/**
 * OBTAccordion2
 * @version 0.1
 * @author 신지유
 * @see common.js
 */
import * as React from 'react';
import { OBTAccordionSection } from '..';
import {
    CommonDefinitions,
    CommonProps,
    CommonType,
    CompositeProps,
    createPropDefinitions,
    Events,
    Util
} from '../Common';
import separatorImg from "./Images/ic_minus_m_disable_s.png"
const styles = require("./OBTAccordion2.module.scss");
enum AccordionType {
    'default' = 'default',
    'large' = 'large'
}
export interface IStateLabel {
    labelText?: string,
    color?: string
}

interface IOBTAccordion2 extends CompositeProps.Default, CommonProps.disabled, CommonProps.labelText {
    /**
     * 컴포넌트의 열리고 닫힘의 설정입니다. true시 오픈, false시 닫힘
     */
    value: boolean,
    /**
     * 아코디언 컴포넌트의 여닫이 여부가 변경될 때 발생하는 Callback 함수입니다.
     */
    onChange?: (e: Events.ChangeEventArgs<boolean>) => void,
    /**
     * 아코디언의 타이틀에 표시되는 문구입니다.
     */
    labelText?: string,
    /**
     * 아코디언의 타이틀 크기유형을 지정합니다.
     */
    type?: AccordionType,
    /**
     * @default true
     * 아코디언에 버튼 사용시 구분선 사용여부를 지정합니다.
     */
    useSeparator?: boolean,
    /**
     * @default false
     * children을 조건부 렌더링 할 것이냐에 대한 여부를 지정합니다. 
     * - 기존의 방법처럼(OBTAccordion) 아코디언이 접혀있을 때 children을 null로 지정
     * - 하지만, 기존 OBTAccordion 에서 해당 방식으로 인해 데이터그리드 이슈등이 발생하였고 이를 개선한 것이 OBTAccordion2 입니다.
     * (따라서, default는 false)
     */
    useChildrenConditionalRendering?: boolean,
    /**
     * 아코디언 타이틀 좌측 아이콘을 추가합니다.
     */
    imageUrl?: any,
    /**
     * 아코디언 타이틀 우측 버튼을 추가합니다.
     */
    button?: any,
    /**
     * 아코디언이 펼쳐진경우 imageUrl 을 지정합니다. (없을 경우 imageUrl을 사용합니다.)
     */
    expandedImageUrl?: any,
    /**
     * 아코디언이 닫힌경우 imageUrl 을 지정합니다. (없을 경우 imageUrl을 사용합니다.)
     */
    collapsedImageUrl?: any,
    /**
     * 상태를 우측에 표시하는 경우 이 항목을 지정합니다.
     */
    stateLabel?: IStateLabel,
    /**
     * key를 지정합니다.
     */
    key?: string | number
}

interface IState {
    value: boolean,
}

export default class OBTAccordion2 extends React.Component<IOBTAccordion2, IState> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        { name: 'key', type: ['number', 'string'], optional: true, description: 'key를 지정합니다.' },
        CommonDefinitions.disabled(),
        CommonDefinitions.value({ type: CommonType.boolean, optional: true, description: '컴포넌트의 열리고 닫힘의 설정입니다. true시 오픈, false시 닫힘.' }),
        CommonDefinitions.labelText({ description: '아코디언의 타이틀에 표시되는 문구입니다.' }),
        {
            name: 'useChildrenConditionalRendering', type: CommonType.boolean, default: false, optional: true, description: 'children을 조건부 렌더링 할 것이냐에 대한 여부를 지정합니다.'
                + '기존의 방법처럼(OBTAccordion) 아코디언이 접혀있을 때 children을 null로 지정. 하지만, 기존 OBTAccordion 에서 해당 방식으로 인해 데이터그리드 이슈등이 발생하였고 이를 개선한 것이 OBTAccordion2 입니다.'
                + '따라서 default는 false 입니다.'
        },
        { name: 'button', type: CommonType.any, optional: true, description: '아코디언 타이틀 우측 버튼을 추가합니다.' },
        { name: 'useSeparator', type: CommonType.boolean, default: true, optional: true, description: '아코디언에 버튼 사용시 구분선 사용여부를 지정합니다.' },
        { name: 'imageUrl', type: CommonType.image, optional: true, description: '아코디언 타이틀 좌측 아이콘을 추가합니다.' },
        { name: 'expandedImageUrl', type: CommonType.image, optional: true, description: '아코디언이 펼쳐진경우 imageUrl 을 지정합니다. (없을 경우 imageUrl을 사용합니다.)' },
        { name: 'collapsedImageUrl', type: CommonType.image, optional: true, description: '아코디언이 닫힌경우 imageUrl 을 지정합니다. (없을 경우 imageUrl을 사용합니다.)' },
        {
            name: 'stateLabel', type: {
                labelText: { type: CommonType.string, description: '상태표시 문구' },
                color: { type: CommonType.color, description: '상태컬러(HEX)' }
            }, optional: true, description: '컴포넌트 우측에 상태라벨을 표시할 수 있습니다.'
        },
        CommonDefinitions.onChange({ optional: true, description: '아코디언이 접히거나 펼쳐지는 경우 발생하는 Callback 함수입니다.' }),
        { name: 'type', type: ['default', 'large'], default: 'default', optional: true, description: '아코디언의 타이틀 크기유형' },
    )

    public static defaultProps: Partial<IOBTAccordion2> = {
        useSeparator: true,
        useChildrenConditionalRendering: false
    }

    public state: IState = {
        value: this.props.value === undefined ? false : this.props.value
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value
            })
        }
    }

    private isUsingImageUrl = (): boolean => {
        return this.props.imageUrl || (this.props.collapsedImageUrl && this.props.expandedImageUrl);
    }

    private isUsingSection = (): boolean => {
        return (
            this.props.children &&
                React.Children.toArray(this.props.children)
                    .find(child => React.isValidElement(child) && child.type === OBTAccordionSection) ? true : false
        )
    }

    private handleAccordionClick = (e) => {
        if (this.props.disabled) return;

        this.setState({
            value: !this.state.value
        }, () => {
            Util.invokeEvent<Events.ChangeEventArgs<boolean>>(
                this.props.onChange,
                new Events.ChangeEventArgs<boolean>(this, this.state.value)
            );
        });
    }

    private handleButtonClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
    }

    private renderButtonOrStateLabel = () => {
        const isShowButtonAndStateLabel = this.props.button && this.props.stateLabel;
        if (isShowButtonAndStateLabel) {
            return this.props.button;
        }
        else {
            if (this.props.stateLabel) {
                return (
                    <div className={styles.stateLabel} style={{ backgroundColor: this.props.stateLabel.color }}>
                        {this.props.stateLabel.labelText}
                    </div>
                )
            }
            else if (this.props.button) {
                return this.props.button;
            }
        }
    }

    render() {
        return (
            <div
                id={this.props.id}
                data-orbit-component={'OBTAccordion2'}
                className={Util.getClassNames(
                    styles.root,
                    this.props.className,
                    this.state.value ? 'Accordion2_opened' : 'Accordion2_closed'
                )}
                key={this.props.key}
            >
                <div className={styles.wrapper}>
                    <div
                        className={Util.getClassNames(styles.accordion,
                            this.props.disabled && styles.disabled,
                            this.props.type === AccordionType.large && this.isUsingImageUrl() ? styles.largeHavingImageUrl :
                                this.props.type === AccordionType.large ? styles.large :
                                    this.isUsingImageUrl() ? styles.defaultHavingImageUrl : null
                        )}
                        onClick={this.handleAccordionClick}
                    >
                        {/* imageUrl 영역 */}
                        <span className={styles.imageUrl}>
                            {this.isUsingImageUrl() &&
                                <img
                                    src={
                                        this.state.value && this.props.expandedImageUrl ? this.props.expandedImageUrl :
                                            !this.state.value && this.props.collapsedImageUrl ? this.props.collapsedImageUrl :
                                                this.props.imageUrl ? this.props.imageUrl : null
                                    }
                                    alt={this.props.imageUrl}
                                    className={Util.getClassNames(styles.imageUrl,
                                        this.props.type === AccordionType.large && styles.large
                                    )}
                                />
                            }
                        </span>
                        {/* button, stateLabel 영역 */}
                        <span
                            className={Util.getClassNames(styles.button,
                                this.props.useSeparator && styles.useSeparator)}
                            onClick={this.handleButtonClick}
                        >
                            {this.renderButtonOrStateLabel()}
                            {this.props.useSeparator && this.props.button && <img src={separatorImg} className={styles.separator} alt={''} />}
                        </span>

                        {/* ^ v 아이콘 영역 */}
                        <span className={Util.getClassNames(styles.arrowIcon,
                            this.state.value && styles.open)}
                        />
                        {/* 타이틀 영역 영역*/}
                        <span className={Util.getClassNames(styles.title,
                            this.props.disabled && styles.disabled,
                            this.state.value && styles.open
                        )}>
                            {this.props.labelText ? this.props.labelText : ""}
                        </span>
                    </div>
                </div>
                <div className={Util.getClassNames(styles.contents,
                    !this.state.value && styles.closed,
                    this.props.disabled && styles.closed,
                    this.isUsingSection() && styles.useSection
                )}>
                    {/* 안에 들어가는 컨텐츠 영역 */}
                    {this.props.useChildrenConditionalRendering && !this.state.value ? null : this.props.children}
                </div>
            </div>
        )
    }
}