import { IColumn } from "../../OBTDataGrid/IColumn";
import { IPagingOption } from "../../OBTDataGrid/IGridGlobalOption";
import Pagination from "../../OBTDataGrid/Pagination";
import { IDefaultDialog } from "../BaseComponent/DefaultDialog";
import { IDefaultDialogConditionPanel, CodePickerDialogConditionPanelFactoryItem } from "../BaseComponent/DefaultDialogConditionPanel";
import { OBTDataGridInterface } from "../../OBTDataGrid";
import * as GridEvents from '../../OBTDataGrid/OBTDataGridEvents';

export enum CodePickerSize {
    "small" = "small",
    "medium" = "medium",
    "default" = "default",
    "large" = "large",
    "largeBig" = "largeBig",
    "custom" = "custom"
}

/**
 * 코드피커 getData 이벤트 인자
 */
export interface ICodePickerGetDataEventArgs {
    /**
     * fetch 라이브러리 
     */
    fetch: any;
    /**
     * 코드피커의 인풋이나 그리드의 코드피커 컬럼에 사용자가 검색을 목적으로 입력한 값
     */
    keyword?: string;
    /**
     * 파라미터 (props로 넘긴 parameters + 코드피커 다이얼로그의 조회조건의 데이터)
     */
    parameters?: any;
    /**
     * 페이징 정보
     */
    pagingInfo?: Pagination;
}

/**
 * 코드피커 getData 이벤트 인자
 */
export interface ICodePickerGetTotalCountEventArgs {
    /**
     * fetch 라이브러리 
     */
    fetch: any;
    /**
     * 파라미터 (props로 넘긴 parameters)
     */
    parameters?: any;
}

/**
 * getData 함수의 리턴 타입
 */
export interface IResponseData {
    data: Array<any>;
}

/**
 * 
 */
export default interface IBuiltInCodePicker extends IPagingOption {
    /**
     * id
     */
    id: string,

    /**
     * 아무것도 입력되지 않은 인풋 컨트롤에 바인딩되는 값
     */
    placeHolder: string,

    /**
     * 다이얼로그의 타이틀
     */
    dialogTitle: string,

    /**
     * 다이얼로그 타이틀 오른쪽에 작게 들어가는 서브타이틀
     */
    dialogSubtitle?: string,

    /**
     * 다이얼로그의 사이즈
     * 
     * "small", "medium", "default", "large", "largeBig", "custom"
     * "custom"일 경우 dialogWitdh, dialogHeight를 수동으로 지정합니다.
     */
    size: CodePickerSize

    /**
     * 다이얼로그의 너비
     * ex) 300px 
     */
    dialogWidth?: string,

    /**
     * 다이얼로그의 높이
     * ex) 300px 
     */
    dialogHeight?: string,

    /**
     * 코드도움 다이얼로그
     */
    customDialogComponent?: React.ComponentType<IDefaultDialog> | null

    /**
     * 디폴트 코드도움의 커스텀 코드도움
     */
    customSearchConditionComponent?: React.ComponentClass<IDefaultDialogConditionPanel> | null

    /**
     * 
     */
    dialogConditionItems?: CodePickerDialogConditionPanelFactoryItem[]

    /**
     * 페이징을 사용할것인지 여부
     */
    paging?: boolean,

    /**
     * 데이터 소스에서 코드값에 해당하는 값을 가지는 프로퍼티명 
     */
    codeProperty: string;

    /**
     * 데이터 소스에서 codeProperty에 매칭되는 텍스트를 가지는 프로퍼티명 
     */
    textProperty: string;

    // /**
    //  * 데이터 소스에서 codeProperty에 매칭되는 텍스트를 가지는 프로퍼티명
    //  */
    // subTextProperty?: string;

    /**
     * OBTDataGrid에 바인딩되는 컬럼 종류
     */
    columns?: Array<IColumn>;

    /**
     * 다이얼로그 그리드에 바인딩할 이벤트 콜백
     */
    gridEvents?: any;

    onAfterOpen?: (e: { target: any, grid: OBTDataGridInterface }) => void;

    onAfterRead?: (e: { target: any, grid: OBTDataGridInterface, event: GridEvents.AfterReadEventArgs }) => void;

    /**
     * 
     * @param e 
     */
    getData?(e: ICodePickerGetDataEventArgs): Promise<IResponseData>;

    /**
     * 페이징, 전체선택 모드를 사용할 경우 전체 데이터아이템의 카운트 읽어오는 함수
     * @param e 
     */
    getTotalCount?(e: ICodePickerGetTotalCountEventArgs): Promise<number>;
}
