import React, { Component } from "react";
import PropTypes from "prop-types";
import ListBottom from "./ListBottom";
import Header from "./Header";
import SelectItem from "./SelectItem";
import LUXPopoverController from "luna-rocket/LUXPopoverController/LUXPopoverController";
import LUXPopoverAnimationFromTop from "luna-rocket/LUXPopoverController/LUXPopoverAnimationVertical";


/**
 * 검색한 리스트의 결과를 보여주는 팝오버 컨테이너 입니다.
 */
class PopoverContainer extends Component {
  static propTypes = {
    /**
     * 모든 아이템에 선택되어 있는지에 대한 여부입니다.
     */
    isCheckedAll: PropTypes.bool,

    /**
     * 헤더에 위치한 전체 체크박스를 클릭하면 발생하는 이벤트입니다.
     */
    onClickCheckBoxAll: PropTypes.func,

    /**
     * 넘겨받은 데이터리스트에 대한 정보값입니다
     */
    data: PropTypes.object,

    /**
     * 부모 div의 ref값입니다
     */
    anchorEl: PropTypes.object,

    /**
     * 현재 팝오버에 대한 상태입니다.
     */
    isPopoverOpen: PropTypes.bool,

    /**
     * 체크박스를 클릭 이벤트입니다
     */
    onClickCheckBoxItem: PropTypes.func,

    /**
     * 팝오버를 닫는 이벤트입니다
     */
    onClosePopOver: PropTypes.func,

    /**
     * 아이템을 더블클릭시 해당 아이템을 선택하고 팝오버를 닫습니다
     */
    onDoubleClick: PropTypes.func,

    /**
     * 바텀의 확인 버튼 클릭시 발생하는 이벤트이빈다
     */
    onOk: PropTypes.func,

    /**
     * 외부를 클릭할 경우 넘어오는 함수값을 오버라이드 함수입니다.
     */
    onRequestClose: PropTypes.func,

    /**
     * 팝오버 height
     */
    popOverHeigth: PropTypes.string,

    /**
     * 팝오버 width
     */
    popOverWidth: PropTypes.string
  };

  myRefs = {
    multiSelectField: React.createRef(),
    scollBody: React.createRef(),
    multiItem: React.createRef(),
    scoll: React.createRef()
  }

  componentDidUpdate = () => {
    if (this.myRefs.scoll.current !== null) {
      let scroll = this.myRefs.scoll.current;
      let index = this.props.data.position;
      let scrHeight = scroll.scrollHeight;
      let cliHeight = scroll.clientHeight;

      scroll.scrollTop = (scrHeight * (index / this.props.data.items.length) - (cliHeight - 10) * 0.5);
    }
  };


  /**
   * Render
   */
  render() {
    const {
      // props
      isCheckedAll,
      anchorEl,
      isPopoverOpen,
      data,
      popOverWidth,
      // popOverHeigth,
      styleUl,
      //func
      onClickCheckBoxAll,
      onDoubleClick,
      onClickCheckBoxItem,
      onOk,
      onClosePopOver,
      onRequestClose,
      listAutoHeight,
      useImage,
      image
    } = this.props;

    return (
      <LUXPopoverController
        ref={this.myRefs.multiSelectField}
        style={{ width: popOverWidth }}
        anchorEl={anchorEl}
        animation={LUXPopoverAnimationFromTop}
        open={isPopoverOpen}
        useLayerForClickAway={false}
        onRequestClose={onRequestClose}
      >
        <div
          className="LS_smartselect_result"
          ref={this.myRefs.scoll}
          style={{
            borderTop: '1px solid #a3a3a3',
            borderLeft: '1px solid #a3a3a3',
            borderRight: '1px solid #a3a3a3',
            background: '#fff',
            boxShadow: '1px 1px 1px #ececec',
            padding: 0,
            height: listAutoHeight ? '' : '200px',
            overflowY: 'auto'
          }}
        >
          <ul
            ref={this.myRefs.scollBody}
            className="result_list"
            style={Object.assign({}, styleUl)}
          >
            <Header
              isCheckedAll={isCheckedAll}
              onClickCheckBoxAll={onClickCheckBoxAll}
            />

            {data.items.map((element, index) => {
              return (
                <SelectItem
                  onDoubleClick={onDoubleClick}
                  ref={this.myRefs.multiItem}
                  key={index}
                  data={data.items.length}
                  itemName={element.name}
                  isChecked={element.isChecked}
                  onClickCheckBoxItem={onClickCheckBoxItem}
                  index={element.index}
                  position={data.position}
                  useImage={useImage}
                  image={useImage ? image[index].imageUrl : undefined}
                />
              );
            })}
          </ul>
        </div>
        <ListBottom onOk={onOk} onClosePopOver={onClosePopOver} />
      </LUXPopoverController>
    );
  }
}

export default PopoverContainer;
