/**
 * OBTTabs
 * @version 0.1
 * @author 전주빈
 * @see common.js
 */
import * as React from 'react';
import { Events, Util, CommonProps, CompositeProps, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import { LUXTabs } from 'luna-rocket/LUXTabs';
import OBTButton from '../OBTButton';
import OBTTab from './OBTTab';
import arrowLeft from '../Images/icon-arrow-left.png';
import arrowRight from '../Images/icon-arrow-right.png';
import arrowLeftHidden from '../Images/icon-arrow-left-hidden.png';
import arrowRightHidden from '../Images/icon-arrow-right-hidden.png';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { ResizeSensor } from 'css-element-queries';
import memoizeOne from 'memoize-one';
import { v1 as uuid } from 'uuid';
import debounce from 'lodash.debounce';

const styleTabs = require('./OBTTabs.module.scss');

/**
 * 요소의 하위 콤포넌트인 OBTTab의 정렬 위치를 지정합니다.
 */
enum Align {
    'left' = 'left',
    'center' = 'center',
    'right' = 'right'
}

/**
 * 자식 요소인 OBTTab 컴포넌트의 기본 탭, 미드 텝, 서브 탭으로 정해주는 속성입니다.
 */
enum TabType {
    'default' = 'default',
    'small' = 'small'
}

interface IOBTTabs extends CompositeProps.Default, CommonProps.disabled, Events.onChange<string>, CommonProps.value<string> {
    /**
     * 자식 요소인 OBTTab 콤포넌트의 선택된 상태가 변경되기 전 발생하는 Callback 함수입니다.
     */
    onBeforeChange?: (e: Events.ValidateEventArgs<string>) => void,
    /**
     * @default left
     * 요소의 하위 콤포넌트인 OBTTab의 정렬 위치를 지정합니다.
     */
    align?: Align,
    /**
     * @default default
     * 자식 요소인 OBTTab 컴포넌트의 기본 탭, 미드 텝, 서브 탭으로 정해주는 속성입니다.
     */
    type?: TabType,
    children?: any,
    selectedItemStyle?: {
        color: string,
        borderBottomColor: string
    }
    /**
     * 탭을 숨기거나 보일지에 대한 여부를 지정합니다.
     * default = false
     */
    hideTabs?: boolean
}

interface State extends hasError {
    value: string,
    expandButton: boolean,
    marginLeft: number,
    preMarginLeftArray: Array<any>,
    leftDisabled: boolean,
    rightDisabled: boolean,
    tabWidth?: number,
    fontChecked: boolean
}

class OBTTabTemplate extends React.Component<{
    children: JSX.Element,
    selected: boolean
}> {
    render() {
        return (
            <div className={Util.getClassNames(styleTabs.template, this.props.selected ? styleTabs.templateSelected : undefined)}>
                {this.props.children}
            </div>
        )
    }
}

class FontChecker extends React.Component<{ onFontLoaded: (recalculateTabWidth: boolean) => void }, { fontChecked: boolean }> {
    state = {
        fontChecked: false
    };

    fontChecker = React.createRef<HTMLDivElement>();

    fontCheckerRoot: React.CSSProperties = {
        fontFamily: 'inherit',
        display: 'inline-block',
        position: 'fixed',
        zIndex: -100,
        visibility: 'hidden',
        left: '0px',
        top: '0px',
        width: '0px',
        height: '0px',
        pointerEvents: 'none',
        userSelect: 'none'
    };

    fontCheckerStyle: React.CSSProperties = {
        display: 'inline-block',
        fontFamily: 'sans-serif',
        fontVariant: 'normal',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: 'normal',
        fontSize: '300px',
        color: 'transparent'
    };

    useFontAPI = (document as any).fonts ? true : false;
    resizeSensor: ResizeSensor | null = null;

    componentWillUnmount() {
        if (this.resizeSensor) {
            this.resizeSensor.detach(this.handleFontLoaded);
            this.resizeSensor = null;
        }
    }

    public setupFontChecker() {
        if (this.useFontAPI) {
            (document as any).fonts.ready.then(() => {
                this.handleFontLoaded();
            })
        } else {
            if (this.fontChecker.current) {
                this.resizeSensor = new ResizeSensor(this.fontChecker.current, this.handleFontLoaded);
                this.fontChecker.current.style.fontFamily = 'inherit';
            }
        }
    }

    handleFontLoaded = () => {
        if (this.props.onFontLoaded) {
            this.props.onFontLoaded(true);
        }
    }

    render() {
        return !this.useFontAPI && !this.state.fontChecked ? <div style={{ ...this.fontCheckerRoot }}>
            <div ref={this.fontChecker} style={this.fontCheckerStyle}>FontChecker[giItT1WQy@!-/#]</div>
        </div> : <></>;
    }
}

export default class OBTTabs extends React.Component<IOBTTabs, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        CommonDefinitions.disabled(),
        CommonDefinitions.value({ type: CommonType.string, description: "자식 요소인 OBTTab 콤포넌트를 제어할 수 있도록 도와주는 속성입니다." }),
        {
            name: "onChange", type: CommonType.function, description: "자식 요소인 OBTTab 콤포넌트의 선택된 상태가 변경될 때 발생하는 Callback 함수입니다.", parameters: {
                name: "e",
                type: {
                    target: { type: CommonType.any, description: "이벤트가 발생한 컴포넌트의 instance" },
                    value: { type: CommonType.string, description: "값" }
                }
            }
        },
        {
            name: "onBeforeChange", type: CommonType.function, description: "자식 요소인 OBTTab 콤포넌트의 선택된 상태가 변경되기 전 발생하는 Callback 함수입니다.", parameters: {
                name: "e",
                type: {
                    target: { type: CommonType.any, description: "이벤트가 발생한 컴포넌트의 instance" },
                    oldValue: { type: CommonType.string, description: "변경전 값" },
                    value: { type: CommonType.string, description: "변경된 값" },
                    cancel: { type: CommonType.boolean, description: "true 로 지정시 입력이 취소됩니다.", optional: true }
                }
            }, optional: true
        },
        { name: "align", type: toEnumType(Align), description: "요소의 하위 콤포넌트인 OBTTab의 정렬 위치를 지정합니다.", default: "left", optional: true },
        { name: "type", type: toEnumType(TabType), default: "default", description: "자식 요소인 OBTTab 컴포넌트의 기본 탭과 서브 탭으로 정해주는 속성입니다.", optional: true }
    )

    public static Align = Align;
    public static TabType = TabType;

    public static defaultProps = {
        disabled: false,
        width: '100%',
        height: '100%',
        frozen: false,
        align: Align.left,
        type: TabType.default,
        hideTabs: false
    }

    public state: State = {
        value: '',
        expandButton: false,
        marginLeft: 0,
        preMarginLeftArray: [],
        leftDisabled: true,
        rightDisabled: false,
        fontChecked: false
    }

    public myRefs = {
        root: React.createRef<HTMLDivElement>(),
        ref: React.createRef<LUXTabs>(),
        fontChecker: React.createRef<FontChecker>()
    }

    private uniqueId = uuid();
    private resizeSensor: ResizeSensor | null = null;

    componentDidMount() {
        if (this.myRefs.fontChecker.current) {
            this.myRefs.fontChecker.current.setupFontChecker();
        }
        if (this.myRefs.root.current) {
            this.resizeSensor = new ResizeSensor(this.myRefs.root.current, this.updateExpandButton);
        }
    }

    componentDidUpdate(prevProps: IOBTTabs): void {
        // 1. div 상태 변할시 버튼 생성할지 말지 
        // if (this.myRefs.ref.current.tabButtonsRef.firstElementChild.childElementCount !== this.state.marginLeft) {
        //     this.expendButtons();
        // }
        // 2. 상태값이 다를경우 화면 그리기
        try {
            if (this.state.expandButton) {
                //화면 바뀌는 로직
                if (this.state.value === 'right' || this.state.value === 'left') {
                    let { visibleWidth } = this.expendTabsWidth();
                    if (this.state.marginLeft !== visibleWidth && this.state.value === 'right') {
                        this.setState({
                            marginLeft: visibleWidth,
                            value: ''
                        })
                    } else if (this.state.marginLeft !== visibleWidth && this.state.value === 'left') {
                        if (this.myRefs.root.current) {
                            // const tabs = Array.from(this.myRefs.root.current.querySelectorAll(`*[data-obttab-parent-id="${this.uniqueId}"]`));
                            const tabWidth = this.state.tabWidth || 95;
                            const availableVisibleTabCount = Math.floor((this.myRefs.root.current.clientWidth - 48) / tabWidth);
                            const nextMarginLeft = this.state.marginLeft - (availableVisibleTabCount * tabWidth);
                            this.setState({
                                marginLeft: nextMarginLeft <= 0 ? 0 : nextMarginLeft,
                                value: ''
                            });
                        }
                    }
                }
                //버튼 diabled 기능
                if (this.state.value === '') {
                    let { leftExpendDisabled, rightExpendDisabled } = this.expendTabsWidth();
                    if (this.state.leftDisabled !== leftExpendDisabled) {
                        this.setState({
                            leftDisabled: leftExpendDisabled
                        })
                    }
                    if (this.state.rightDisabled !== rightExpendDisabled) {
                        this.setState({
                            rightDisabled: rightExpendDisabled,
                        })
                    }
                }
            }
            if (this.props.children !== prevProps.children) {
                this.updateTabWidth();
            }
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    componentWillUnmount() {
        if (this.resizeSensor) {
            this.resizeSensor.detach(this.updateExpandButton);
            this.resizeSensor = null;
        }
    }

    /**
     * @internal
     */
    private updateTabWidth = debounce(() => {
        if (this.myRefs.root.current) {
            const canvas = document.createElement('canvas');
            canvas.style.fontFamily = 'inherit';
            canvas.style.fontSize = '15px';
            canvas.style.visibility = 'hidden';
            canvas.style.pointerEvents = 'none';
            canvas.style.display = 'fixed';
            canvas.style.left = '0px';
            canvas.style.top = '0px';
            const context = canvas.getContext('2d');
            if (context) {
                context.font = 'bold 15px NSKR, Dotum, Helvetica, Apple SD Gothic Neo, sans-serif, 돋움';
                const tabWidth = React.Children.map(this.props.children, (child) => {
                    if (child && child.type === OBTTab) {
                        return context.measureText(child.props.labelText).width + (child.props.imageUrl ? 21 : 0) + 15;
                    }
                    return 0;
                }).reduce((accu, curr) => accu < curr ? curr : accu, 95);
                if (this.state.tabWidth !== tabWidth) {
                    this.setState({
                        tabWidth: tabWidth
                    }, () => {
                        this.updateExpandButton();
                    });
                }
            }
        }
    }, 300);

    /**
     * @internal
     */
    private updateExpandButton = debounce(() => {
        if (this.myRefs.root.current) {
            const tabs = Array.from(this.myRefs.root.current.querySelectorAll(`*[data-obttab-parent-id="${this.uniqueId}"]`));
            const expandButton = this.state.marginLeft || tabs.find(el => (el as HTMLElement).offsetTop > 0) ? true : false;
            if (this.state.expandButton !== expandButton) {
                this.setState({
                    expandButton: expandButton
                });
            }
        }
    }, 300);

    /**
     * @internal
     * 버튼클릭시 tab보여지는 함수
     */
    private expendTabsWidth(): any {
        if (this.state.expandButton && this.myRefs.root.current) {
            const tabs = Array.from(this.myRefs.root.current.querySelectorAll(`*[data-obttab-parent-id="${this.uniqueId}"]`));
            const widths = tabs.reduce((accu, curr) => {
                const width = curr.getBoundingClientRect().width;
                return {
                    totalWidth: accu.totalWidth + width,
                    visibleWidth: accu.visibleWidth + ((curr as HTMLElement).offsetTop <= 0 ? width : 0)
                }
            }, {
                totalWidth: 0,
                visibleWidth: 0
            });

            return {
                leftExpendDisabled: this.state.marginLeft ? false : true,
                rightExpendDisabled: widths.totalWidth === widths.visibleWidth,
                visibleWidth: widths.visibleWidth
            };
        }

        return { leftExpendDisabled: false, rightExpendDisabled: false, visibleWidth: 0 }
    }

    /**
     * @internal
     * 이전 값에 대한 cancel 이벤트
     * e.cancel = true 주면
     * return 시켜서 onchange안타게 한다.
     */
    private handleChange = (value: string) => {
        if (this.props.value !== value) {
            const oldValue: string = this.props.value
            if (!Util.invokeEvent<Events.ValidateEventArgs<string>>(this.props.onBeforeChange, new Events.ValidateEventArgs<string>(this, oldValue, value))) {
                return;
            }
            Util.invokeEvent<Events.ChangeEventArgs<string>>(this.props.onChange, new Events.ChangeEventArgs(this, value));
        }
    }

    private getChildren = memoizeOne((children: any, type: TabType, disabled: boolean, tabWidth?: number) => {
        const tabHeight = type === TabType.small ? '25px' : '32px';

        const defaultStyle = {
            minWidth: '92px',
            fontWeight: 'bold',
            fontSize: '15px',
            height: tabHeight,
            padding: '0px 6px',
            lineHeight: '0.93px',
            width: tabWidth ? `${tabWidth}px` : undefined
        }
        const smallStyle = {
            fontWeight: 'bold',
            fontSize: '13px',
            height: tabHeight,
            padding: '0px 18px',
            lineHeight: '0.93px'
        };

        return React.Children.map(children, child => {
            if (React.isValidElement(child) && child.type === OBTTab) {
                const customSeletedStyle = this.props.selectedItemStyle
                const selectedStyle = {
                    color: customSeletedStyle !== undefined && customSeletedStyle.color ? customSeletedStyle.color : '#1c90fb',
                    borderBottomColor: customSeletedStyle !== undefined && customSeletedStyle.borderBottomColor ? customSeletedStyle.borderBottomColor : '#1c90fb'
                }
                const hoverStyle = {
                    borderBottomColor: customSeletedStyle !== undefined && customSeletedStyle.borderBottomColor !== undefined ? customSeletedStyle.borderBottomColor : '#1c90fb'
                }
                let childrenProp = disabled === true ? {
                    itemStyle: type === TabType.small ? smallStyle : defaultStyle,
                    disabled: true,
                    parentId: this.uniqueId,
                    selectedStyle: selectedStyle,
                    hoverStyle: hoverStyle
                } : {
                    itemStyle: type === TabType.small ? smallStyle : defaultStyle,
                    parentId: this.uniqueId,
                    selectedStyle: selectedStyle,
                    hoverStyle: hoverStyle
                };

                return React.cloneElement(child, childrenProp)
            }
            return child;
        });
    })

    renderComponent = () => {
        const maxHeight = this.props.type === TabType.small ? 'calc(100% - 36px)' : 'calc(100% - 43px)';
        const tabHeight = this.props.type === TabType.small ? '25px' : '32px';
        const children = this.getChildren(this.props.children, this.props.type || TabType.default, this.props.disabled, this.state.tabWidth);

        //margintRight ( 확장 버튼시 필요 )
        const marginRight = this.state.expandButton ? '50px' : '0px';
        //marginLeft   ( 텝 추가 밀리기 )
        const marginLeft = this.state.marginLeft !== 0 ? `-${this.state.marginLeft}px` : '0px'

        const Tabs = <LUXTabs
            style={{ height: '100%', width: '100%' }}
            ref={this.myRefs.ref}
            onChange={this.handleChange}
            value={this.props.value}
            theme={'border'}
            fullItem={false}
            align={this.props.align}
            contentContainerStyle={{ paddingTop: '10px', maxHeight: maxHeight }}
            tabItemContainerStyle={{ lineHeight: '0px', marginLeft: marginLeft, marginRight: marginRight, overflow: 'hidden', height: tabHeight }}  //tab div
            tabTemplate={OBTTabTemplate}
        >
            {children}
        </LUXTabs>

        return (
            <div
                id={this.props.id}
                data-orbit-component={'OBTTabs'}
                className={Util.getClassNames(styleTabs.display,
                    this.props.className,
                    this.props.hideTabs && styleTabs.hideTabs
                )}
                style={Util.getWrapperStyle(this.props)}
            >
                <div ref={this.myRefs.root} style={{ display: 'flex', height: '100%', overflow: 'hidden' }}>
                    {Tabs}
                    {this.state.expandButton ?
                        <div
                            className={Util.getClassNames(styleTabs.buttonGroup,
                            this.props.hideTabs && styleTabs.hideTabs
                            )}
                        >
                            <OBTButton
                                className={styleTabs.btnLeft}
                                imageUrl={{ normal: this.state.leftDisabled ? arrowLeftHidden : arrowLeft }}
                                disabled={this.state.leftDisabled}
                                onClick={() => {
                                    this.setState({
                                        value: 'left'
                                    })
                                }}
                            />
                            <OBTButton
                                imageUrl={{ normal: this.state.rightDisabled ? arrowRightHidden : arrowRight }}
                                disabled={this.state.rightDisabled}
                                onClick={() => {
                                    this.setState({
                                        value: 'right'
                                    })
                                }}
                            />
                        </div> : null
                    }
                </div>
                <FontChecker ref={this.myRefs.fontChecker} onFontLoaded={this.updateTabWidth} />
            </div >
        )
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        );
    }
};

