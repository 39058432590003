import React, { Component } from 'react';
import { OBTLoading } from './components';
import styles from './index.module.scss';

class Loader extends Component {
    state = {
        Example: null
    }

    render() {
        const { Example } = this.state;
        return (Example ? <Example /> : <></>);
    }

    componentDidMount() {
        setTimeout(() =>
            this.props.getComponent()
                .then((Example) => this.setState({ Example: Example.default }, () => this.props.onLoaded())), 0);
    }
}

class Loading extends Component {
    state = {
        loading: true
    }

    render() {
        return (this.state.loading ? <OBTLoading
            className={styles.loading}
            open={this.state.loading}
            fullScreen={false}
            value={'Loading...'}
            labelText={'예제를 불러오고 있습니다.'}
            type={OBTLoading.Type.large}>
            <div style={{ width: '100%', height: '100%' }} />
        </OBTLoading> : <></>);
    }

    hideLoading = () => { this.setState({ loading: false }) };
}

const withSplitting = (getComponent, className, displayName) => {
    // 여기서 getComponent 는 () => import('./SplitMe') 의 형태로 함수가 전달되야합니다.
    class WithSplitting extends Component {
        loadingRef = React.createRef();
        render() {
            return (
                <>
                    <Loading ref={this.loadingRef} />
                    <Loader getComponent={getComponent} onLoaded={() => this.loadingRef.current.hideLoading()} />
                </>);
        }
    }
    WithSplitting.prototype.info = {
        class: className,
        displayName: displayName
    };
    return WithSplitting;
};

export default withSplitting;