/**
 * @version 0.1
 * @author 김철희
 * @see common.js
 */
import * as React from 'react';
import { Util } from '../Common';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const styles = require('./OBTSingleYearPicker.module.scss');

interface IOBTYearCalendar {
    max?: string,
    min?: string,
    value: string,
    onChange: (event: any, value: number) => void, //닫는 함수
}

interface State extends hasError {
    selectedYear: string,
    startYear: string,
    endYear: string
}

export default class OBTYearCalendar extends React.Component<IOBTYearCalendar, State> {
    public static defaultProps = {
        min: String(new Date().getFullYear() - 100),
        max: String(new Date().getFullYear() + 100)
    }

    public state: State = {
        selectedYear: this.props.value,
        startYear: this.props.value === '' ? String(Math.floor(new Date().getFullYear() * 0.1) * 10) : String(Math.floor(Number(this.props.value) * 0.1) * 10),
        endYear: this.props.value === '' ? String(Math.floor(new Date().getFullYear() * 0.1) * 10 + 9) : String(Math.floor(Number(this.props.value) * 0.1) * 10 + 9),
        hasError: false
    }

    static getDerivedStateFromProps(nextProps: IOBTYearCalendar, prevState: State): any {
        try {
            if (nextProps.value !== prevState.selectedYear) {
                return {
                    selectedYear: nextProps.value,
                    startYear: nextProps.value === '' ? String(Math.floor(new Date().getFullYear() * 0.1) * 10) : String(Math.floor(Number(nextProps.value) * 0.1) * 10),
                    endYear: nextProps.value === '' ? String(Math.floor(new Date().getFullYear() * 0.1) * 10 + 9) : String(Math.floor(Number(nextProps.value) * 0.1) * 10 + 9)
                }
            }
            return null
        } catch (e) {
            return Util.getErrorState(e);
        }
    }

    addYear = (add: number) => {
        const { max, min } = this.props;
        let { startYear, endYear } = this.state;

        let newStartYear = Number(startYear) + add;
        let newEndYear = Number(endYear) + add;

        if (max || min) {
            if (newStartYear > Number(max) || newEndYear < Number(min)) return;
        }

        this.setState({ startYear: String(newStartYear), endYear: String(newEndYear) });
    }

    getTableElement(props, state) {
        let { value, max, min } = props;
        let { selectedYear, startYear } = state;
        let yearButton = Number(startYear) - 2;
        let tr: JSX.Element[] = [];
        let td: JSX.Element[] = [];
        let tdClassName = '';
        let buttonDisabled = false;

        for (let i = 0; i < 3; i++) {
            td = [];
            for (let j = 1; j < 5; j++) {
                yearButton = yearButton + 1;
                tdClassName = 'date_day';

                // 선택된 월에 파란색
                if (value !== '' && Number(value) === Number(selectedYear) && Number(selectedYear) === yearButton) {
                    tdClassName = 'date_day date_day_selected';
                }
                buttonDisabled = false;
                // maxDate, minDate 의 범위를 벗어나면 disabled
                if (max || min) {
                    if ((Number(min) > yearButton) || (Number(max) < yearButton)) {
                        buttonDisabled = true;
                    }
                }
                td.push(
                    <td key={'td' + String(i) + String(j)} className={tdClassName}>
                        <button
                            type="button"
                            onClick={(event) => this.onClickYear(event, (4 * i) + j)}
                            style={Object.assign({}, { width: '43px', height: '36px' }, buttonDisabled ? { cursor: 'not-allowed' } : {})}
                        >
                            <span style={(i === 0 && j === 1) || (i === 2 && j === 4) ? { color: '#CCCCCC' } : {}}>{yearButton}</span>
                        </button>
                    </td>

                )
            }

            tr.push(
                <tr key={i}>{td}</tr>
            )
        }

        return tr;
    }

    /**
     * 년 버튼이 눌렸을 때 호출됩니다.
     */
    onClickYear = (event: any, selectYear: number) => {
        let { startYear } = this.state;
        let { max, min } = this.props;
        let disabled = false;

        // maxDate, minDate 의 범위를 벗어나면 disabled
        if (max || min) {
            if ((Number(min) > Number(startYear) + selectYear - 2) || Number(max) < Number(startYear) + selectYear - 2) {
                disabled = true;
            }
        }

        if (disabled) return;

        if (this.props.onChange) {
            this.props.onChange(event, Number(startYear) + selectYear - 2);
        }

    };

    renderComponent = () => {
        return (
            <>
                <div className={styles.date_select}>
                    <button type="button" className={Util.getClassNames(styles.btn, styles.btn_prev_year)} onClick={() => this.addYear(-10)}>
                        <span className={styles.sp_selene}>이전년</span>
                    </button>
                    <strong className={styles.date_day_title}>{this.state.startYear + '-' + this.state.endYear}</strong>
                    <button type="button" className={Util.getClassNames(styles.btn, styles.btn_next_year)} onClick={() => this.addYear(10)}>
                        <span className={styles.sp_selene}>다음년</span>
                    </button>
                </div>
                <div className="date_tbl">
                    <table>
                        <caption></caption>
                        <tbody>
                            {this.getTableElement(this.props, this.state)}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        )
    }
};


