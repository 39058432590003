/**
 * OBTBasicColorPicker.
 * @version 0.1
 * @author 하성준
 * @see common.js
 */
import * as React from 'react';
import { CustomPicker } from 'react-color';
import { Events, CompositeProps, Util, CommonProps } from '../Common';
import selectImage from '../Images/icon_color_select.png'
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { OrbitInternalLangPack } from '../Common/Util';

const styles = require('./OBTBasicColorPicker.module.scss');

interface IOBTBasicColorPicker extends CompositeProps.Default, CommonProps.disabled {
    color: any,
    hex?: string,
    rgb?: IRGB,
    type?: MainType,
    onChange: (e: any) => void,
    onButtonClick?: (e: Events.EventArgs) => void
}

interface IRGB {
    r: number,
    g: number,
    b: number,
    a: number
}

enum MainType {
    'basic' = 'basic',
    'popUp' = 'popUp'
}

interface State extends hasError {
    color: any,
    recentValue: Array<any>
}
class OBTBasicColorPicker extends React.Component<IOBTBasicColorPicker, State> {

    public static defaultProps = {
        disabled: false
    }

    public state: State = {
        color: this.props.color,
        recentValue: [],
        hasError: false
    }

    public myRefs = {
        firstColor: React.createRef<HTMLDivElement>()
    }

    componentDidMount() {
        try {
            let storedColor;
            let storedColorArray: any = []
            storedColor = (localStorage.getItem("colors"));

            if (JSON.parse(storedColor)) {
                storedColorArray = JSON.parse(storedColor);
                storedColorArray = storedColorArray.filter(color => {
                    if (this.props.hex && (color === this.props.hex.toUpperCase() || color === null || color === undefined)) {
                        return false;
                    }
                    return true;
                })
            } else {
                storedColorArray.push('#000000', '#FFFFFF', '#F50C00', '#F57300', '#FAD300', '#298B8C', '#000CFB', '#B0B0B0')
            }

            storedColorArray = storedColorArray.filter(color => {
                if (this.props.hex && (color === this.props.hex.toUpperCase() || color === null || color === undefined)) {
                    return false;
                }
                return true;
            })
            if (this.props.hex)
                storedColorArray.splice(0, 0, this.props.hex.toUpperCase())

            if (storedColorArray && storedColorArray.length > 8) {
                storedColorArray.splice(8, storedColorArray.length - 8)
            }

            localStorage.setItem("colors", JSON.stringify(storedColorArray));

            this.setState({
                recentValue: storedColorArray
            })
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    static getDerivedStateFromProps(nextProps: IOBTBasicColorPicker, prevState: State): any {
        try {
            if ((nextProps.color !== prevState.color)) {
                let storedColor;
                let storedColorArray: any = []
                storedColor = (localStorage.getItem("colors"));

                if (JSON.parse(storedColor)) {
                    storedColorArray = JSON.parse(storedColor);

                    storedColorArray = storedColorArray.filter(color => {
                        if (nextProps.hex && (color === nextProps.hex.toUpperCase() || color === null || color === undefined)) {
                            return false;
                        }
                        return true;
                    })
                } else {
                    storedColorArray.push('#000000', '#FFFFFF', '#F50C00', '#F57300', '#FAD300', '#298B8C', '#000CFB', '#B0B0B0')
                }

                storedColorArray = storedColorArray.filter(color => {
                    if (nextProps.hex && (color === nextProps.hex.toUpperCase() || color === null || color === undefined)) {
                        return false;
                    }
                    return true;
                })

                if (nextProps.hex)
                    storedColorArray.splice(0, 0, nextProps.hex.toUpperCase())

                if (storedColorArray && storedColorArray.length > 8) {
                    storedColorArray.splice(8, storedColorArray.length - 8)
                }

                localStorage.setItem("colors", JSON.stringify(storedColorArray));

                return {
                    color: nextProps.color,
                    recentValue: storedColorArray
                }
            }
            return null;
        } catch (e) {
            return Util.getErrorState(e);
        }
    }

    renderComponent = () => {
        const headerColor = ['#FFFFFF', '#000000', '#EAEAEA', "#4E5E76", "#6CA5DE", "#E98927", "#AFAFAF", "#FCC800", "#517CCF", "#83B644"]
        const bottomColor = [
            '#F4F4F4', "#8A8A8A", "#D6D4D4", "#DCE1E9", '#E4EEF9', '#FBE9DA', '#F0F0F0', '#FFF5D0', '#E0E7F6', '#E7F3DD',
            '#DEDEDE', '#636463', '#B8B4B4', '#B7C1D2', '#C7DCF2', '#F6D2B4', '#E0E0E0', '#FEEA9D', '#BECEEC', '#CFE4BA',
            '#C7C7C7', '#494949', '#817C7C', '#90A1BA', '#AACAEC', '#F2BA8A', '#D0D0D0', '#FEDF64', '#9BB3E4', '#B7D794',
            '#B0B0B0', '#2A2A2A', '#3F3B3B', '#3B475B', '#3D80C2', '#C66609', '#878787', '#C49B00', '#385FA5', '#648D32',
            '#8A8A8A', '#090909', '#171717', '#262F3D', '#285787', '#8B4506', '#5C5C5C', '#886B00', '#243E70', '#446222'
        ]
        const headerColorDiv = headerColor.map((color, index) => {
            return <div key={'headerColor' + String(index)} className={styles.smallColor} title={color} tabIndex={0} onClick={e => this.handleChange(color)} style={{ background: color, boxShadow: color.toUpperCase() === '#FFFFFF' ? 'rgb(221, 221, 221) 0px 0px 0px 1px inset' : '' }}></div>
        })
        const bottomColorDiv = bottomColor.map((color, index) => {
            return <div key={'bottomColor' + String(index)} className={styles.smallColor} title={color} tabIndex={0} onClick={e => this.handleChange(color)} style={{ background: color, boxShadow: color.toUpperCase() === '#FFFFFF' ? 'rgb(221, 221, 221) 0px 0px 0px 1px inset' : '' }}></div>
        })

        const standardColor = [
            '#F50C00', '#F45300', '#F57300', '#FAD300', '#DAD961', '#D8FF5F', '#93D400', '#57C000', '#36A300', '#298B8C',
            '#F50972', '#E10681', '#7C0062', '#85008E', '#5100A9', '#00008B', '#0341A8', '#000CFB', '#2F9CA2', '#207172'
        ]

        const standardColorDiv = standardColor.map((color, index) => {
            return <div key={'standardColor' + String(index)} className={styles.smallColor} title={color} tabIndex={0} onClick={e => this.handleChange(color)} style={{ background: color, boxShadow: color.toUpperCase() === '#FFFFFF' ? 'rgb(221, 221, 221) 0px 0px 0px 1px inset' : '' }}></div>
        })

        const recentColorDiv = this.state.recentValue.map((color, index) => {
            if (color)
                return <div ref={index === 0 ? this.myRefs.firstColor : undefined} key={'recentColor' + String(index)} className={styles.smallColor} title={String(color)} onClick={e => this.recentHandleChange(color)} tabIndex={0} style={{ background: String(color), boxShadow: String(color).toUpperCase() === '#FFFFFF' ? 'rgb(221, 221, 221) 0px 0px 0px 1px inset' : '' }}></div>

            return null
        })

        return (
            <div className={styles.root}>
                <div className={styles.title}>{OrbitInternalLangPack.getText('WE000017859', '테마 색')}</div>
                <div className={styles.headerColor}>
                    {headerColorDiv}
                </div>
                <div className={styles.bottomColor}>
                    {bottomColorDiv}
                </div>
                <div className={styles.title}>{OrbitInternalLangPack.getText('WE000017858', '표준 색')}</div>
                <div className={styles.standardColor}>
                    {standardColorDiv}
                </div>
                <div className={styles.title}>{OrbitInternalLangPack.getText('WE000001022', '현재')} ㅣ {OrbitInternalLangPack.getText('WE000019234', '최근 사용한 색')}</div>
                <div className={styles.recentColor}>
                    <div className={styles.smallColor} title={String(this.props.hex).toUpperCase()} onClick={e => this.handleChange(this.props.hex)} tabIndex={0} style={{ background: this.props.hex, boxShadow: this.props.hex ? this.props.hex.toUpperCase() === '#FFFFFF' ? 'rgb(221, 221, 221) 0px 0px 0px 1px inset' : '' : '' }}></div>
                    <div className={styles.line} ></div>
                    {recentColorDiv}
                </div>
                <span className={styles.another} onClick={this.handleButtonClick.bind(this)}><img src={selectImage} alt='' /> <span className={styles.text}>{OrbitInternalLangPack.getText('WE000025332', '다른 색 선택하기')}</span></span>
            </div >
        )
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        )
    }

    private handleChange(color: any): void {
        this.props.onChange(color);
    }

    private recentHandleChange(color: any): void {
        this.props.onChange(color);
        if (this.myRefs.firstColor.current && this.props.type === MainType.basic) {
            this.myRefs.firstColor.current.focus();
        }
    }

    private handleButtonClick(): void {
        Util.invokeEvent<Events.EventArgs>(this.props.onButtonClick, new Events.EventArgs(this));
    }

};

export default CustomPicker(OBTBasicColorPicker)