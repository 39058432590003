import { v4 as uuid } from 'uuid';
import unCheckImg from '../Images/checkbox.png';
import checkImg from '../Images/checkbox_cehcked.png';
import disabledCheckImg from '../Images/checkbox_checked_disable.png';
import disabledUnCheckImg from '../Images/checkbox_disable.png';
import favoriteOffImg from '../Images/icon-func-favorite-off.png';
import favoriteOnImg from '../Images/icon-func-favorite-on.png';
import GridImageLabelProcessor, { ImageLabelCanvasTemplate } from '../OBTDataGrid/GridBase/GridImageLabelProcessor';
import GridUtil from '../OBTDataGrid/GridBase/GridUtil';
import ICell from '../OBTDataGrid/ICell';
import { account, biznumber, credit, driver, email, foreign, passport, resident, tel } from '../OBTMaskedTextField/Patterns';
import { ChaningEvent } from './ChaningEvent';
import { GridGlobalOption } from './GridGlobalOption';
import GridStyle from './GridStyle';
import GridStyleForExcel from './GridStyleForExcel';
import { IActionButton } from './IActionButton';
import { ColumnType, DateFormat, IColumn, MaskType, buttonVisiblityFunction } from './IColumn';
import * as Events from './OBTListGridEvents';
import moveRowNormalIcon from '../Images/ic_view_list_02_m_normal.png'
import moveRowOverIcon from '../Images/ic_view_list_02_m_over.png'
import { IGridDisplayOption } from '../OBTDataGrid/IGridGlobalOption';
import { OrbitInternalLangPack } from '../Common/Util';

const RealGridJS: any = require('../OBTDataGrid/RealGridJS').default

/**
 * 그리드의 행이 가지는 데이터 변경에 대한 상태값을 표현하는 열거형
 */
export enum GridState {
    'empty' = 'empty',
    'none' = 'none',
    'added' = 'added',
    'modified' = 'modified',
    'deleted' = 'deleted'
}

interface IField {
    fieldName: string,
    dataType: string
    calculateCallback: (dataRow, fieldName: string, fieldNames: string[], values) => number;
}

/**
 * 그리드의 셀에서 데이터의 정렬방식에 대한 열거형
 */
export enum ColumnAlignment {
    'center' = 'center',
    'near' = 'near',
    'far' = 'far',
    'left' = 'left',
    'right' = 'right'
}

type Read = (e: Events.ReadEventArgs) => Promise<any[] | null | undefined>;
type ReadPage = (e: Events.ReadPageEventArgs) => Promise<any[] | null | undefined>;

interface IDataProvider {
    read: Read,
    readPage: ReadPage,
    fields?: IField[],
    _provider?: any,
}

interface IReadDataOptions {
    currentPage?: number,
    rowCountPerPage?: number,
    readCallback?: Read,
    readPageCallback?: ReadPage,
    preventChangeSelection?: boolean
}

/**
 * 그리드 인터페이스
 */
export default class OBTListGridInterface {
    public static readonly ImageLabelTemplate = ImageLabelCanvasTemplate;
    // static fields

    // public static GridType = GridType
    public static ColumnType = ColumnType;

    public static readonly ROW_MOVE_COLUMN_NAME = '__rowMove';

    /** @internal */
    public readonly _gridElementId: string = `grid_${uuid()}`;

    // private fields
    private _id: string = ''
    private _gridOption: GridGlobalOption = {};
    private _columns: IColumn[] | null = [];
    private _unmanagedGridOptions: any | null = null;
    private _gridView: any | null = null;
    private _provider: IDataProvider | null = null;
    private _isRowChanged: boolean = false;
    private _hasFocus: boolean = false
    private _rowDeleting: boolean = false;
    private _pageCount: number = 0;
    private _currentPage: number = 1;
    private _rowCountPerPage: number = 10;
    private _totalCount: number = 0;
    private _actionButtons: IActionButton[] | null = [];
    private _owner: any = null;
    private _dataSortType: Events.DataSortType = {
        columnName: null,
        direction: null,
    }
    /** 
     * @internal
     * 이미지 라벨 컬럼의 경우, 컬럼의 값에 매핑되는 이미지 정보를 캐싱하는 필드 
     *  */
    // private _imageDataByTextValue: IImageDataByTextValue[] = [];

    // Accessors

    /**
     * 생성자에서 주입받은 아이디
     */
    public get id() {
        return this._id;
    }

    /**
     * 생성자에서 주입받은 그리드옵션
     */
    public get gridOption() {
        return this._gridOption;
    }

    /**
     * 세팅된 컬럼리스트
     */
    public get columns() {
        return this._columns;
    }

    /**
     * 관리되지않는 그리드옵션
     */
    public get unmanagedGridOptions() {
        return this._unmanagedGridOptions;
    }

    /**
     * 리얼그리드의 gridview
     */
    public get gridView(): any {
        return this._gridView;
    }

    /**
     * provider
     */
    public get provider() {
        return this._provider;
    }

    /**
     * 리얼그리드의 프로바이더
     */
    public get realGridProvider() {
        return this._provider && this._provider._provider ? this._provider._provider : null;
    }

    public set currentPage(currentPage: number) {
        this._currentPage = (currentPage <= 0 ? 1 : currentPage);
    }

    /**
     * 현재페이지
     */
    public get currentPage() {
        return this._currentPage <= 0 ? 1 : this._currentPage;
    }

    /**
     * 페이지갯수
     */
    public get pageCount() {
        return this._pageCount;
    }

    /**
     * 그리드의 보여줄 로우 갯수
     */
    public get rowCountPerPage() {
        return this._rowCountPerPage;
    }

    /**
    * 페이징의 총 데이터 갯수
    */
    public get totalCount() {
        return this._totalCount;
    }

    /**
     * 퀵아이콘 리스트
     */
    public get actionButtons() {
        return this._actionButtons;
    }

    // Private Accessors

    /**
     * @internal
     */
    private get _realGridProvider() {
        return this._provider && this._provider._provider ? this._provider._provider : null;
    }

    /**
     * @internal
     */
    private get isReady() {
        return this._gridView && this._realGridProvider ? true : false;
    }

    /**
     * before
     * checkable 그리드에서 행이 체크상태가 변경된 이전에 발생하는 이벤트
     * [BeforeCheckEventArgs]
     */
    public readonly onBeforeCheck = new ChaningEvent<((e: Events.BeforeCheckEventArgs) => void)>();

    /**
     * before
     * checkable 그리드에서 행이 체크상태가 변경된 이후에 발생하는 이벤트
     * [AfterCheckEventArgs]
     */
    public readonly onAfterCheck = new ChaningEvent<((e: Events.AfterCheckEventArgs) => void)>();

    /**
     * checkable 그리드에서 헤더의 전체체크, 해제 여부가 바뀐후 발생하는 이벤트
     * [AfterHeaderCheckEventArgs]
     */
    public readonly onAfterHeaderCheck = new ChaningEvent<((e: Events.AfterHeaderCheckEventArgs) => void)>();

    /**
     * 셀 선택이 바뀌기 전에 발생하는 이벤트 
     * [BeforeSelectChangeEventArgs]
     */
    public readonly onBeforeSelectChange = new ChaningEvent<((e: Events.BeforeSelectChangeEventArgs) => void)>();

    /**
     * 셀선택이 바뀐 이후에 발생하는 이벤트
     * [AfterSelectChangeEventArgs]
     */
    public readonly onAfterSelectChange = new ChaningEvent<((e: Events.AfterSelectChangeEventArgs) => void)>();

    /**
     * 셀 선택이 바뀌기 전에 발생하는 이벤트 
     * [BeforeSelectChangeEventArgs]
     */
    public readonly onBeforeChangeRowSelection = new ChaningEvent<((e: Events.BeforeSelectChangeEventArgs) => void)>();

    /**
     * 셀선택이 바뀐 이후에 발생하는 이벤트
     * [AfterSelectChangeEventArgs]
     */
    public readonly onAfterChangeRowSelection = new ChaningEvent<((e: Events.AfterSelectChangeEventArgs) => void)>();

    /**
     * 데이터를 읽은 후에 호출되는 이벤트
     * [AfterReadEventArgs]
     */
    public readonly onAfterRead = new ChaningEvent<((e: Events.AfterReadEventArgs) => void)>();

    /**
     * 데이터 셀을 더블클릭했을때 발생하는 이벤트
     * [DoubleClickedEventArgs]
     */
    public readonly onDoublelClicked = new ChaningEvent<((e: Events.DoubleClickedEventArgs) => void)>();

    /**
     * 데이터 셀을 클릭했을때 발생하는 이벤트
     * [DataCellDblClickedEventArgs]
     */
    public readonly onClicked = new ChaningEvent<((e: Events.ClickedEventArgs) => void)>();

    /**
     * 헤더의 컬럼 너비가 바뀌고나서 호출됨
     */
    public readonly onColumnWidthChanged = new ChaningEvent<((e: Events.ColumnWidthChangeEventArgs) => void)>();

    /**
     * 마우스 오버시 툴팁을 보여주기전 발생
     * [DataCellDblClickedEventArgs]
     */
    // public readonly onShowTooltip = new ChaningEvent<((e: Events.ShowTooltipEventArgs) => string)>();

    /**
     * useTooltip 사용시 마우스 오버 이벤트 발생
     */
    public readonly onMouseHover = new ChaningEvent<((e: Events.MouseHoverEventArgs) => string)>();

    /**
     * 데이터, 체크등 내부의 데이터가 변경될때 호출
     * [AfterDataChangeEventArgs]
     */
    public readonly onAfterDataChanged = new ChaningEvent<((e: Events.AfterDataChangeEventArgs) => void)>();

    /**
     * PageContainer에서 Drawer를 보여줘야 되는 상황에서 호출되는 이벤트
     * [DrawerEventArgs]
     */
    public readonly onDrawer = new ChaningEvent<((e: Events.DrawerEventArgs) => void)>();

    /**
     * rowSelectMode에서 엔터 입력이 들어왔을때 호출되는 이벤트
     * [SelectByEnterEventArgs]
     */
    public readonly onSelectByEnter = new ChaningEvent<((e: Events.SelectByEnterEventArgs) => void)>();

    /**
     * 드래그를 시작하면 발생하는 이벤트
     * cancle = true drag가 취소된다. 
     * [InnerDragStartEventArgs]
     */
    public readonly onInnerDragStart = new ChaningEvent<((e: Events.InnerDragStartEventArgs) => boolean)>();

    /**
     * 드래그 상태에서 마우스버튼을 놓으면 발생하는 이벤트
     * cancle = true drag가 취소된다. 
     * [InnerDropEventArgs]
     */
    public readonly onInnerDrop = new ChaningEvent<((e: Events.InnerDropEventArgs) => void)>();

    /**
     * 컬럼 이미지버튼을 클릭했을 때 호출된다.
     */
    public readonly onImageButtonClicked = new ChaningEvent<((e: Events.ImageButtonClickedEventArgs) => void)>();

    /**
     * 스크롤이 발생할 경우 호출된다.
     */
    public readonly onScroll = new ChaningEvent<((e: Events.ScrollEventArgs) => void)>();

    /**
     * 소팅
     */
    public readonly onSorting = new ChaningEvent<((e: Events.SortingEventArgs) => void)>();

    /**
     * link타입 컬럼 클릭 이벤트
     */
    public readonly onLinkableCellClicked = new ChaningEvent<((e: Events.LinkColumnClickedEventArgs) => void)>();

    /**
     * link타입 컬럼 클릭 이벤트
     */
    public readonly onEditCommit = new ChaningEvent<((e: Events.EditCommitEventArgs) => void)>();


    private gridImageLabelProcessor = new GridImageLabelProcessor(this);

    /**
     * 
     * @param id 그리드의 아이디 한 페이지 내에서는 유일해야한다.
     * @param options 그리드의 전역옵션
     */
    constructor(id: string, options?: GridGlobalOption) {
        if (id.length <= 0) {
            throw new Error('OBTListGridInterface.constructor: parameter id required.')
        }

        this._id = id;

        if (options) {
            if (options.useEmptySet !== false) {
                // 초기 데이터 없는 경우 안내 문구 설정
                if (!options.emptyDataMsg) {
                    options.emptyDataMsg = '데이터가 존재하지 않습니다.';
                }
                if (!options.emptySearchMsg) {
                    options.emptySearchMsg = '검색 결과가 없습니다.';
                }
            }
            this._gridOption = options;
        }
    }

    /**
     * @internal
     * 할당된 필드값을 기반으로
     * 그리드 옵션을 리턴한다. 
     * indicator.visible, sorting.enabled, fixed.colCount
     * @returns 그리드옵션
     */
    private getRealGridOption(): any {
        if (!this._gridView) {
            return;
        }

        const gridOption = {
            softDeleting: true,
            currentChangingFirst: true,
            display: {
                fitStyle: 'fill', // none, even
                showEmptyMessage: true,
                emptyShowTooltip: this.gridOption.emptyShowTooltip === true ? true : false,
                emptyMessage: '',
                focusColor: "#00000000",
                focusBorderWidth: 0,
                focusBackground: "#001c90fb",
                focusActiveColor: "#ff1c90fb",
                rowHeight: 32,
                columnResizable: this.gridOption.columnResizable === false ? false : true,
                rowChangeDelay: 100,
                showInnerFocus: true,
                editItemMerging: true,
                eachRowResizable: this.gridOption.eachRowResizable ? this.gridOption.eachRowResizable : false
            },
            filtering: {
                enabled: false
            },
            panel: { visible: false },
            // 그리드 앞에 로우별로 변경된 데이터와 행번호를 알수 있는 인디케이터를 노출하는 옵션
            indicator: { visible: this._gridOption.indicator },
            // 데이터의 변경여부를 표시해주는 맨앞의 컬럼 표시여부
            stateBar: { visible: false },
            selection: { style: 'singleRow' },
            // 삭제된 행은 그리드에서 보일것인지 여부
            hideDeletedRows: true,
            sortMode: 'explicit',
            sorting: {
                enabled: this._gridOption.preventSort === true ? false : true
            },
            //filterMode: 'explicit', //TODO: tree에서 동작안함 확인필요
            editor: {
                useCssStyleDropDownList: true, // 드랍다운에 css 스타일 적용
                // 그리드 내부에 에디터 엘리먼트를 포함( 포커스 검사를 위해 추가 )
                viewGridInside: true,
                applyCellFont: true, // TODO: 얘를 true로 설정하면 number타입 컬럼의 dynamicStyle.editable이 먹지 않는다.
                validateOnEdited: false
            },
            fixed: {
                colCount: this.gridOption.fixedColumnCount
            }
        }

        return gridOption;
    }

    /**
     * @internal
     * 디폴트로 사용할 그리드 체크바 옵션 리턴
     * @see http://help.realgrid.com/api/types/CheckBar/
     * @returns 체크바옵션 
     */
    private getDefaultCheckBarOption(): any {
        return {
            visible: false,
            disabled: false,
            visibleOnly: false,
            checkableOnly: false,
            showAll: true,
            width: 30,
            exclusive: false,
            syncHeadCheck: true,
            drawCheckBox: true,
            headCheckImageUrl: checkImg,
            headUnCheckImageUrl: unCheckImg,
            checkImageUrl: checkImg,
            unCheckImageUrl: unCheckImg,
            disableCheckImageUrl: disabledCheckImg,
            disableUnCheckImageUrl: disabledUnCheckImg,
            // checkImageUrl: checkImg,
            // unCheckImageUrl: unCheckImg,
            // dynamicStyles: [{
            //     criteria: "not checkable",
            //     styles: {
            //         background: "#eceded",
            //     }
            // }]
        }
    }

    /**
     * @internal
     * 그리드의 기본적인 edit option
     * @returns edit option
     */
    private getDefaultGridEditOption(): any {
        return {
            deletable: false,
            readOnly: false,
            editable: false,
            updatable: false,
            appendable: false,
            // deleteRowsConfirm: false,
            // // 탭키로 셀간 포커스 이동
            // useTabKey: true,
            // // 엔터키를 탭키와 동일하게 사용한다.
            // enterToTab: true,
            // enterToNextRow: false,
            // validateOnEdited: true,
            // validateOnExit: true,
            // // 셀에 포커스가 있을때 엔터입력시 에디트 모드로 전환
            // enterToEdit: false,
            // checkable: true,
            // checkDiff: true,
            // checkCellDiff: true,
            // //skipReadOnly: true,
            // displayEmptyEditRow: false,
            enterToTab: true,
            checkDiff: true,
            checkCellDiff: true,
            //skipReadOnly: this._editable,
            //skipReadOnlyCell: this._editable, // 테스트 해볼것 : true이면 한 컬럼에서 행간(Vertical 컬럼 그룹 행을 포함) 이동시 readOnly 셀은 건너뛰고 다음 행의 컬럼 셀로 이동한다.
            crossWhenExitLast: true,
            maxLengthToNextCell: true,
        }
    }

    /**
     * 그리드 초기화
     * @ignore
     */
    public _initialize(owner: any): void {
        const id = this._gridElementId;
        this._owner = owner;

        // 그리드 인스턴스 생성
        this._gridView = new RealGridJS.GridView(id);

        // 그리드 바인딩
        this._gridView.setDataProvider(this._realGridProvider ? this._realGridProvider : null);

        this.setGridOption(this.gridOption, true);

        //TODO: 왜인지 setOptions에서 처리하면 안먹는다
        this._gridView.setEditOptions({
            showInvalidFormatMessage: false,
            editable: this.gridOption.editable === true ? true : false,
        });

        // 컬럼 세팅, 컬럼세팅은 editable, appendable 설정 이후에
        this._setColumns(false);

        // 서버 정렬
        if (this.columns && this.gridOption.useServerSort === true && this.gridOption.preventSort !== true) {
            this.columns.forEach((columnItem) => {
                this.realGridProvider.setDataComparer(columnItem.name, (field: number, row1: any, row2: any) => {
                    return this._dataSortType.direction === 'descending' ? -1 : 1;
                })
            })
        }

        // 푸터 세팅 푸터설정된 컬럼이 없으면 기본적으로 보이지 않게
        if (this.columns) {
            const hasFooterColumn = this.columns.filter(column => (column.footer || column.footerValue));

            this.setFooter(
                hasFooterColumn.length > 0,
                (this.gridOption.footerCount ? this.gridOption.footerCount : 1)
            );
        }

        this._gridView.setStyles(GridStyle.styles);

        // 이벤트등록
        // check
        this._gridView.onItemChecked = this.handleItemChecked.bind(this);
        this._gridView.onItemsChecked = this.handleItemsChecked.bind(this);

        this._gridView.onItemAllChecked = this.handleItemAllChecked.bind(this);
        this._gridView.onColumnCheckedChanged = this.handleColumnCheckedChanged.bind(this);

        // click
        this._gridView.onDataCellDblClicked = this.handleDoublelClicked.bind(this);
        this._gridView.onDataCellClicked = this.handleClicked.bind(this);

        // row change
        this._gridView.onCurrentChanging = this.handleCurrentChanging.bind(this);
        this._gridView.onCurrentChanged = this.handleCurrentChanged.bind(this);

        // tooltip
        // this._gridView.onShowTooltip = this.handleOnShowTooltip.bind(this);
        // mouseHover
        this._gridView.onShowTooltip = this.handleMouseHover.bind(this);

        // row drag
        this._gridView.onInnerDragStart = this.handleInnerDragStart.bind(this);
        this._gridView.onInnerDrop = this.handleInnerDrop.bind(this);
        this._gridView.onImageButtonClicked = this.handleImageButtonClicked.bind(this);

        // etc
        this._gridView.onTopItemIndexChanged = this.handleTopItemIndexChanged.bind(this);
        this._gridView.onColumnPropertyChanged = this.handleColumnPropertyChanged.bind(this);

        // sort
        this._gridView.onSorting = this.handleSorting.bind(this);

        // link
        this._gridView.onLinkableCellClicked = this.handleLinkableCellClicked.bind(this);

        // 수정
        this._gridView.onEditCommit = this.handleEditCommit.bind(this);

        this._gridView.onCellEdited = this.handleCellEdited.bind(this);

        this._gridView.onShowEditor = this.handleShowEditor.bind(this);
    }


    /**
     * 그리드 옵션의 디스플레이 옵션
     * @param option 
     */
    public setDisplayOptions(option: IGridDisplayOption): OBTListGridInterface {
        this.gridOption.eachRowResizable = option.eachRowResizable;
        this.gridOption.columnMovable = option.columnMovable;
        this.gridOption.emptyShowTooltip = option.emptyShowTooltip;

        if (this._gridView) {
            this._gridView.setDisplayOptions({
                eachRowResizable: option.eachRowResizable,
                columnMovable: option.columnMovable,
            });
        }
        return this;
    }

    // region private method


    /**
     * @internal
     * 컬럼정보를 데이터 프로바이더의 필드로 매핑한다.
     */
    private mapIColumnToProviderFields(columns: IColumn[] | undefined): any[] {
        if (!columns) {
            return [];
        }

        const resultArray: any[] = [];
        columns
            .forEach(column => {
                if (column.type === ColumnType.number) {
                    resultArray.push({
                        fieldName: column.fieldName ? column.fieldName : column.name,
                        dataType: "number",
                        calculateCallback: column.calculateCallback
                    });
                } else if (column.type === ColumnType.date) {
                    resultArray.push({
                        fieldName: column.fieldName ? column.fieldName : column.name,
                        dataType: "text",
                        datetimeFormat: this.resolveDateFormatByColunmProperty(column.dateFormat, 'datetimeFormat')
                    });
                } else if (column.type === ColumnType.dateTime) {
                    resultArray.push({
                        fieldName: column.fieldName ? column.fieldName : column.name,
                        dataType: "datetime",
                        datetimeFormat: "iso",
                        amText: "AM",
                        pmText: "PM"
                    });
                } else if (column.type === ColumnType.mask) {
                    resultArray.push({
                        fieldName: column.fieldName ? column.fieldName : column.name,
                        dataType: "text",
                    });
                } else if (column.type === ColumnType.group) {
                    resultArray.push(...this.mapIColumnToProviderFields(column.columns));
                } else if (column.type === ColumnType.image) {
                    resultArray.push({
                        fieldName: column.fieldName ? column.fieldName : column.name,
                        dataType: "text",
                    });
                } else {
                    resultArray.push({
                        fieldName: column.fieldName ? column.fieldName : column.name,
                        dataType: "text",
                        calculateCallback: column.calculateCallback
                    });
                }
            });

        return resultArray;
    }

    /**
     * @internal
     * IColumn 타입을 실제 리얼그리드의 컬럼정보 오브젝트로 매핑한다.
     */
    private mapIColumnToRealGridColumn(column: IColumn): any {
        let visit = this._columns!.filter(column => column.type === ColumnType.visit).map(column => column)[0];

        let realGridColumn: any = {
            name: column.name,
            fieldName: column.fieldName ? column.fieldName : column.name,
            header: typeof column.header === 'string' ? {
                text: column.header,
                imageLocation: column.headerImageLocation,
                imageUrl: column.headerImageUrl,
            } : column.header,
            editable: false,
            width: column.width,
            hideChildHeaders: ColumnType.group && column.hideChildHeaders ? true : false,
            fillWidth: column.isAutoWidth !== undefined ? 1 : 0,
            require: column.required !== undefined ? column.required : false,
            native: column.unmanaged,
            visible: column.visible !== undefined ? column.visible : true,
            renderer: this.mergeRenderer(column.renderer, {
                "showTooltip": true
            }),
            styles: column.style ? column.style.textWrap ? column.style : Object.assign(column.style, { "textWrap": "normal" }) : { "textWrap": "normal" },
            dynamicStyles: visit ? GridUtil.mergeDynamicStyles(column.dynamicStyles, (grid, index, value) => {
                if (visit.trueValues && grid.getValues(index.itemIndex)[visit.name] && grid.getValues(index.itemIndex)[visit.name] === visit.trueValues.filter(item => item === grid.getValues(index.itemIndex)[visit.name])[0]) {
                    return {
                        fontBold: true
                    };
                }
            }) : column.dynamicStyles
        }

        // 버튼
        if (column.hasActionButton === true) {
            realGridColumn = Object.assign(realGridColumn, {
                button: 'action',
            });
        }

        // 아이콘
        if (column.useIcon !== false && column.iconImageList) {
            this.registerColumnImageList(column.name, column.iconImageList);
            realGridColumn.imageList = column.name + '_image';
            realGridColumn.renderer = Object.assign(realGridColumn.renderer, {
                type: "icon",
                textVisible: column.onlyIcon === true ? false : true,
            });
        }

        // 컬럼 타입별 리얼그리드 컬럼 매핑
        if (column.type === undefined || column.type === ColumnType.text) {
            realGridColumn = this.makeTextColumn(realGridColumn, column);
        } else if (column.type === ColumnType.number) {
            realGridColumn = this.makeNumberColumn(realGridColumn, column);
        } else if (column.type === ColumnType.date) {
            realGridColumn = this.makeDateColumn(realGridColumn, column);
        } else if (column.type === ColumnType.dateTime) {
            realGridColumn = this.makeDateTimeColumn(realGridColumn, column);
        } else if (column.type === ColumnType.check) {
            realGridColumn = this.makeCheckColumn(realGridColumn, column);
        } else if (column.type === ColumnType.dropDown) {
            realGridColumn = this.makeDropdownColumn(realGridColumn, column);
        } else if (column.type === ColumnType.mask) {
            realGridColumn = this.makeMaskColumn(realGridColumn, column);
        } else if (column.type === ColumnType.group) {
            realGridColumn = this.makeGroupColumn(realGridColumn, column);
        } else if (column.type === ColumnType.image) {
            realGridColumn = this.makeImageColumn(realGridColumn, column);
        } else if (column.type === ColumnType.button) {
            //TODO: 버튼 과 라벨
            realGridColumn = this.makeButtonColumn(realGridColumn, column);
        } else if (column.type === ColumnType.checkImage) {
            //TODO: 버튼 과 라벨
            realGridColumn = this.makecheckImageColumn(realGridColumn, column);
        }

        // 정렬 설정
        realGridColumn = this.setAlignmentProperty(realGridColumn, column);

        // 동적수정 여부 세팅
        // realGridColumn = this.setEditableColumnProperty(realGridColumn, column);

        // 푸터 설정
        if (column.footer) {
            // 푸터의 정렬과 포메팅은 컬럼 설정 따라간다. 
            realGridColumn = Object.assign(realGridColumn, {
                footer: Object.assign(column.footer, {
                    styles: {
                        textAlignment: realGridColumn.styles.textAlignment,
                        numberFormat: column.numberColumnFormat ? column.numberColumnFormat : '#,000',
                    }
                })
            });
        }

        // 푸터 고정값
        if (column.footerValue) {
            if (column.footer === undefined && column.footerValue) {
                realGridColumn = Object.assign(realGridColumn, {
                    footer: {
                        callback: (index, footerIndex) => {
                            if (footerIndex === 0) {
                                return column.footerValue
                            }
                        }
                    }
                });
            }
        }

        // 컬럼 백그라운드 스타일 지정
        const requiredColumnBackgroundColor = '#ffe6e7';
        const readOnlyColumnBackgroundColor = '#eceded';
        if (column.required === true) {
            realGridColumn.styles = Object.assign(realGridColumn.styles, {
                background: requiredColumnBackgroundColor
            });
        } else if (column.readonly === true) {
            realGridColumn.styles = Object.assign(realGridColumn.styles, {
                background: readOnlyColumnBackgroundColor
            });
        }

        //prefix, suffix 세팅
        if (column.prefix) {
            realGridColumn.styles = Object.assign(realGridColumn.styles, {
                prefix: column.prefix
            });
        }
        if (column.suffix) {
            realGridColumn.styles = Object.assign(realGridColumn.styles, {
                suffix: column.suffix
            });
        }

        if (column.linkable === true) {
            realGridColumn = {
                ...realGridColumn,
                renderer: {
                    ...realGridColumn.renderer,
                    type: "link",
                    url: "http://www.douzone.com",
                    showUrl: true,
                    cursor: 'pointer'
                },
                styles: {
                    ...realGridColumn.styles,
                }
            }
        }

        // 이미지버튼 정렬때문에 추가
        if (column.imageButtons && column.imageButtons.alignment) {
            realGridColumn.renderer = {
                type: "imageButtons",
                alignment: column.imageButtons.alignment, // 버튼 정렬 near, far
                ...column.imageButtons
            }
        }

        if (column.sortable !== undefined) {
            realGridColumn.sortable = column.sortable;
        }

        realGridColumn = this.gridImageLabelProcessor.buildColumn(realGridColumn, column);

        // unmanaged TODO: 깊은 레벨까지 동작해야함
        if (column.unmanaged !== undefined && column.unmanaged) {
            const native = column.unmanaged;
            realGridColumn = Object.assign(realGridColumn, native);
        }

        // 필요없는 속성제거
        delete realGridColumn.require;
        delete realGridColumn.native;
        delete column.unmanaged;

        return realGridColumn;
    }

    /**
     * @internal
     * 리얼그리드 컬럼세팅 editable,
     * dynamicStyles을 사용함에 유의
     */
    // private setEditableColumnProperty(realGridColumn: any, column: IColumn) {
    //     if (this.gridOption.editable === true) {
    //         if (column.editable !== undefined && typeof column.editable === 'function') {
    //             realGridColumn = Object.assign(realGridColumn, {
    //                 editable: true,
    //                 dynamicStyles: this.mergeDynamicStyle(realGridColumn.dynamicStyles, (grid, index, value) => {
    //                     let editable: boolean = false;
    //                     if (index.column === column.name) {
    //                         editable = this.isColumnEditable(column, index.itemIndex, index.column);
    //                         return {
    //                             editable: editable
    //                         };
    //                     }
    //                 })
    //             });
    //         } else {
    //             if (column.readonly === true) {
    //                 realGridColumn = Object.assign(realGridColumn, {
    //                     editable: false,
    //                 });
    //             } else if (typeof column.editable === 'boolean') {
    //                 realGridColumn = Object.assign(realGridColumn, {
    //                     editable: column.editable,
    //                 });
    //             } else {
    //                 realGridColumn = Object.assign(realGridColumn, {
    //                     editable: this.gridOption.editable
    //                 });
    //             }
    //         }
    //     } else {
    //         // 특별한 수정가능여부 설정을 하지 않았을시
    //         realGridColumn = Object.assign(realGridColumn, {
    //             editable: this.gridOption.editable
    //         });
    //     }
    //     return realGridColumn;
    // }

    /**
     * @internal
     * @param param 
     * @param param2 
     */
    private mergeRenderer(param, param2): any {
        if (param === undefined && param2 === undefined) {
            return (e) => {
                return {}
            }
        }

        if (!param && param2) {
            return param2;
        }

        if (param && !param2) {
            return param;
        }
        const result = Object.assign(
            param, param2
        )
        return result;

    }

    /**
     * @internal
     * 리얼그리드 컬럼 매핑 정렬
     */
    private setAlignmentProperty(realGridColumn: any, column: IColumn) {
        let alignment = column.alignment;

        // 디폴트 값 처리
        if (!alignment) {
            if (column.type === ColumnType.visit
                || column.type === ColumnType.dropDown
                || column.type === ColumnType.mask
                || column.type === ColumnType.date) {
                alignment = ColumnAlignment.center;
            } else if (column.type === ColumnType.number) {
                alignment = ColumnAlignment.far;
            } else {
                alignment = ColumnAlignment.near;
            }
        }

        const alignmentString = this.mapAlignmentToRealGridAlignment(alignment);

        if (realGridColumn.editor) {
            realGridColumn.editor = Object.assign(realGridColumn.editor, {
                textAlignment: alignmentString
            });
        }
        if (realGridColumn.styles) {
            realGridColumn.styles = Object.assign(realGridColumn.styles, {
                textAlignment: alignmentString
            });
        } else {
            realGridColumn = Object.assign(realGridColumn, {
                styles: {
                    textAlignment: alignmentString
                }
            });
        }

        return realGridColumn;
    }

    /**
     * @internal
     * 이미지 컬럼 만들기
     * 데이터를 이미지 url로 해석한다.
     */
    makeImageColumn(defaultRealGridColumn: any, column: IColumn): any {
        defaultRealGridColumn = Object.assign(defaultRealGridColumn, {
            styles: {
                ...defaultRealGridColumn.styles,
                contentFit: column.fittingType ? column.fittingType : "auto"
            },
            renderer: {
                ...defaultRealGridColumn.renderer,
                type: "image",
                sommthing: true,
            }
        });

        return defaultRealGridColumn;
    }

    /**
     * @internal
     * 이미지버튼 컬럼 만들기
     */
    makeButtonColumn(defaultRealGridColumn: any, column: IColumn): any {
        defaultRealGridColumn = Object.assign(defaultRealGridColumn, {
            button: "image",
            styles: defaultRealGridColumn.styles,
            imageButtons: column.imageButtons,
            buttonVisibility: column.buttonVisibility ? column.buttonVisibility : "always"
        });

        return defaultRealGridColumn;
    }

    /**
     * @internal
     * checkImage 컬럼 만들기
     */
    makecheckImageColumn(defaultRealGridColumn: any, column: IColumn): any {
        defaultRealGridColumn = Object.assign(defaultRealGridColumn, {
            renderer: {
                ...defaultRealGridColumn.renderer,
                type: "check",
                trueValues: column.trueValues ? column.trueValues.join(',') : "True",
                falseValues: column.falseValues ? column.falseValues.join(',') : "False",
                labelPosition: "hidden",
                trueImage: column.trueImage ? column.trueImage : favoriteOnImg,
                falseImage: column.falseImage ? column.falseImage : favoriteOffImg,
                emptyImage: column.emptyImage ? column.emptyImage : favoriteOffImg
            },
        });

        return defaultRealGridColumn;
    }

    /**
     * @internal
     */
    private makeGroupColumn(defaultRealGridColumn: any, column: IColumn): any {
        defaultRealGridColumn = Object.assign(defaultRealGridColumn, {
            columns: column.columns ? column.columns
                .filter(col => (col.type !== ColumnType.data && col.type !== ColumnType.visit))
                .map(col => this.mapIColumnToRealGridColumn(col)) : []
        });
        return defaultRealGridColumn;
    }

    /**
     * @internal
     */
    private makeTextColumn(defaultRealGridColumn: any, column: IColumn): any {
        defaultRealGridColumn = Object.assign(defaultRealGridColumn, {
            editor: {
                type: "text",
                maxLength: column.maxLength ? column.maxLength : 100,
                // TODO: 키허용, 숫자만 입력가능하다던지
            }
        });
        return defaultRealGridColumn;
    }

    /**
     * @internal
     */
    private makeCheckColumn(defaultRealGridColumn: any, column: IColumn): any {
        defaultRealGridColumn = Object.assign(defaultRealGridColumn, {
            editable: false,
            renderer: {
                ...defaultRealGridColumn.renderer,
                type: "check",
                editable: true,
                startEditOnClick: true,
                trueValues: column.trueValues ? column.trueValues.join(',') : "True",
                falseValues: column.falseValues ? column.falseValues.join(',') : "False",
                labelPosition: "hidden",
            },
        });

        // TODO: 이미지 바꾸기
        return defaultRealGridColumn;
    }

    /**
     * @internal
     * 숫자컬럼 리얼그리드 컬럼으로 매핑
     */
    private makeNumberColumn(defaultRealGridColumn: any, column: IColumn): any {
        const defaultFormat = '#,000';

        let resultMaxIntegerLength = 0;
        let resultFormat = defaultFormat;

        if (column.numberColumnFormat) {
            resultMaxIntegerLength = 0;
            resultFormat = column.numberColumnFormat;
        } else if (column.maxLength) {
            const resolveResult = this.resolveNumberFormat(column.maxLength ? column.maxLength : null);
            resultMaxIntegerLength = resolveResult.integerLength;
            resultFormat = resolveResult.format;
        }

        defaultRealGridColumn = Object.assign(defaultRealGridColumn, {
            displayMinusZero: false,
            dataType: 'number',
            editor: {
                type: "number",
                multipleChar: "+",
                positiveOnly: column.positiveOnly === true ? true : false,
                maxIntegerLength: resultMaxIntegerLength,
                maxLengthExceptComma: true,
                editFormat: resultFormat
            },
            styles: {
                ...defaultRealGridColumn.styles,
                numberFormat: resultFormat
            },
        });

        if (column.nanToZero === true) {
            defaultRealGridColumn = Object.assign(defaultRealGridColumn, {
                nanText: "0"
            });
        }

        if (column.showZero === true) {
            defaultRealGridColumn = Object.assign(defaultRealGridColumn, {
                zeroText: "0"
            });
        } else {
            defaultRealGridColumn = Object.assign(defaultRealGridColumn, {
                zeroText: ""
            });
        }

        return defaultRealGridColumn;
    }

    /**
     * @internal
     * @param decimalLength 
     * @param as 
     */
    private resolveNumberFormat(decimalLength: number | null): { integerLength: number, format: string } {
        if (!decimalLength) {
            return {
                integerLength: 0,
                format: '#,##0,###'
            }
        }

        const decimalLengthToString = decimalLength.toString();

        // format 일때 뒤의 포메팅스트링을 붙여서 리턴해야함
        const defaultFormat = "#,##0."

        if (decimalLengthToString.indexOf('.') > -1) {
            const split: string[] = decimalLength.toString().split('.');

            if (split.length === 2) {
                const totalLength = split[0];
                const underZeroLength = split[1];

                return {
                    integerLength: (Number(totalLength) - Number(underZeroLength)),
                    format: defaultFormat + "#".repeat(Number(underZeroLength))
                }
            } else {
                return {
                    integerLength: decimalLength,
                    format: defaultFormat
                }
            }
        } else {
            return {
                integerLength: decimalLength,
                format: '#,##0'
            }
        }
    }

    /**
     * @internal
     * 문자열로 관리되는 날짜데이터 컬럼
     */
    private makeDateColumn(defaultRealGridColumn: any, column: IColumn): any {
        const dateFormat = column.dateFormat || DateFormat.yyyyMMdd;

        defaultRealGridColumn = Object.assign(defaultRealGridColumn, {
            editor: {
                type: "date",
                editFormat: this.resolveDateFormatByColunmProperty(dateFormat, 'datetimeFormat'),
                mask: {
                    editMask: this.resolveDateFormatByColunmProperty(dateFormat, 'editMask'),
                    includedFormat: false,
                },
                commitOnSelect: true
            },
            displayCallback: (grid, index, value) => {
                let result = this.formatDateString(value, column.dateFormat ? column.dateFormat : DateFormat.yyyyMMdd);
                if (result === 'Invalid date') {
                    return '';
                }

                if (result && column.showDayOfWeek === true) {
                    if (column.dateFormat === DateFormat.yyyyMMdd && value.length === 8) {
                        var week = ['일', '월', '화', '수', '목', '금', '토']
                        let toDate = new Date(
                            Number(value.slice(0, 4)),
                            Number(value.slice(4, 6)) - 1,
                            Number(value.slice(6, 8))
                        );
                        result = result + '(' + week[toDate.getDay()] + ')';
                    }
                } else if (result && typeof column.showDayOfWeek === 'function') {
                    let toDate = new Date(
                        Number(value.slice(0, 4)),
                        Number(value.slice(4, 6)) - 1,
                        Number(value.slice(6, 8))
                    );

                    result = result + ' ' + column.showDayOfWeek(toDate.getDay());

                }

                return result;
            }
        });

        // TODO: 달력 스타일, OBT랑 맞추기
        // TODO: 휴일처리
        // TODO: 년, 년월 년월일 달력도움창 변경
        return defaultRealGridColumn;
    }

    /**
     * @internal
     */
    private makeDateTimeColumn(defaultRealGridColumn: any, column: IColumn): any {
        const dateFormat = column.dateFormat || DateFormat.yyyyMMdd;

        defaultRealGridColumn = Object.assign(defaultRealGridColumn, {
            editor: {
                type: "date",
                editFormat: this.resolveDateFormatByColunmProperty(dateFormat, 'datetimeFormat'),
                mask: {
                    editMask: this.resolveDateFormatByColunmProperty(dateFormat, 'editMask'),
                    includedFormat: false,
                },
                commitOnSelect: true
            },
            styles: {
                ...defaultRealGridColumn.styles,
                datetimeFormat: this.resolveDateFormatByColunmProperty(dateFormat, 'displayStyle'),
            },
        });

        return defaultRealGridColumn;
    }

    /**
     * @internal
     */
    private makeDropdownColumn(defaultRealGridColumn: any, column: IColumn): any {
        // if (column.dropDownDataItems && column.dropDownCodeProperty && column.dropDownTextProperty) {
        //     defaultRealGridColumn = Object.assign(defaultRealGridColumn, {
        //         type: "data",
        //         lookupDisplay: true,
        //         values: column.dropDownDataItems.map((item) => item[column.dropDownCodeProperty ? column.dropDownCodeProperty : 'code']),
        //         labels: column.dropDownDataItems.map((item) => item[column.dropDownTextProperty ? column.dropDownTextProperty : 'text']),
        //         editable: true,
        //         editor: {
        //             type: "dropDown",
        //             dropDownCount: 4,
        //             domainOnly: true,
        //             editable: false,
        //             textReadOnly: false,
        //             values: column.dropDownDataItems.map((item) => item[column.dropDownCodeProperty ? column.dropDownCodeProperty : 'code']),
        //             labels: column.dropDownDataItems.map((item) => item[column.dropDownTextProperty ? column.dropDownTextProperty : 'text']),
        //         },
        //     });
        // }

        // // TODO: 키입력
        // // TODO: API로 받기, 필터기능까지

        // return defaultRealGridColumn;
        let values: any[] = [];
        let labels: any[] = [];
        if (column.dropDownDataItems && column.dropDownCodeProperty && column.dropDownTextProperty) {
            values = column.dropDownDataItems.map((item) => item[column.dropDownCodeProperty ? column.dropDownCodeProperty : 'code']);
            labels = column.dropDownDataItems.map((item) => item[column.dropDownTextProperty ? column.dropDownTextProperty : 'text']);
        }

        defaultRealGridColumn = {
            ...defaultRealGridColumn,
            lookupDisplay: true,
            values: values,
            labels: labels,
            editButtonVisibility: buttonVisiblityFunction(this, column),
            editable: true,
            editor: {
                ...defaultRealGridColumn.editor,
                type: "dropDown",
                dropDownCount: 4,//column.dropDownCount ? column.dropDownCount : 4,
                // 목록에 있는 값만 입력 받을 것인지 여부 
                domainOnly: true, //column.allowEditorValue === true ? false : true,
                // 드롭다운 목록에서 label값으로 보여질 것인지 value값으로 보여질 것인지 또는 label value, value label로 보여질것인지의 여부를 지정
                //displayLabels: column.dropDownLabelType ? column.dropDownLabelType : undefined,
                // displayLabels 속성이 “valueLabel”, “labelValue”인 경우 두 값들사이의 여백을 자동으로 조정하여 정렬 표시
                itemColumned: true,
                // 키보드 입력 막을 것인지 여부
                textReadOnly: true,//column.textReadOnly !== undefined ? column.textReadOnly : false,
                // true 인 경우 한글 초성만 입력해도 해당하는 라벨 위치로 바로 이동
                partialMatch: true,
                commitOnSelect: true,
                values: values,
                labels: labels,
            },
        }
        return defaultRealGridColumn;
    }

    /**
     * @internal
     */
    private makeMaskColumn(defaultRealGridColumn: any, column: IColumn): any {
        defaultRealGridColumn = Object.assign(defaultRealGridColumn, {
            editor: {
                type: "text",
                mask: {
                    editMask: (column.maskType === MaskType.custom) ?
                        column.editMask : this.resolveMask(column.maskType, "editMask"),
                    allowEmpty: true,
                },
            }
        });

        if (column.maskType === MaskType.custom && column.customMaskCallback) {
            // 커스텀 마스크를 사용하면 editMask와 customMaskCallback을 지정했다고 가정
            defaultRealGridColumn = Object.assign(defaultRealGridColumn, {
                displayCallback: (grid, index, value) => {
                    if (column.maskType === MaskType.custom) {
                        if (column.customMaskCallback) {
                            return column.customMaskCallback(value);
                        }
                    }
                    return this.resolveMask(column.maskType, 'callback').formatter(value, {});
                }
            });
        } else {
            // 커스텀이 아니면 마스크타입에 따라 정규표현식을 지정해준다.
            defaultRealGridColumn = Object.assign(defaultRealGridColumn, {
                displayRegExp: this.resolveMask(column.maskType, "displayRegExp"),
                displayReplace: this.resolveMask(column.maskType, "displayReplace"),
            });
        }

        //TODO: validation. 포메팅이랑 상관없이,
        //TODO:     

        return defaultRealGridColumn;
    }

    /**
     * @internal
     */
    private getMaskResolveInfo(maskType: MaskType | undefined): any {
        if (!maskType) {
            return null;
        }

        const resolve = {
            'resident': {
                editMask: '000000-0000000',
                displayRegExp: '([0-9]{6})([0-9]{7})',
                displayReplace: "$1-$2",
                callback: new resident()
            },
            'biznumber': {
                editMask: '000-00-00000',
                displayRegExp: '([0-9]{3})([0-9]{2})([0-9]{5})',
                displayReplace: "$1-$2-$3",
                callback: new biznumber()
            },
            'foreign': {
                editMask: '000000-000000',
                displayRegExp: '([0-9]{6})([0-9]{6})',
                displayReplace: "$1-$2",
                callback: new foreign()
            },
            'passport': {
                callback: new passport()
            },
            'driver': {
                callback: new driver()
            },
            'credit': {
                editMask: '0000-0000-0000-0000',
                displayRegExp: '([0-9]{4})([0-9]{4})([0-9]{4})([0-9]{4})',
                displayReplace: "$1-$2-$3-$4",
                callback: new credit()
            },
            'account': {
                callback: new account()
            },
            'tel': {
                editMask: '000-0000-0000',
                callback: new tel()
            },
            'email': {
                callback: new email()
            },
        }

        return resolve[maskType];
    }

    /**
     * @internal
     * TODO: 포메팅 콜백을 넘겨주는식은 오버헤드가 클수도 있으니 체크할것
     */
    private resolveMask(maskType: MaskType | undefined, as: "editMask" | 'displayRegExp' | 'displayReplace' | "callback"): any {
        if (!maskType) {
            return "";
        }

        const resolve = this.getMaskResolveInfo(maskType);

        if (!resolve[as]) {
            return "";
        }

        return resolve[as];
    }

    /**
     * @internal
     * 문자열 스트링 포메팅
     * @param value 
     * @param dateFormat 
     */
    private formatDateString(value: string, dateFormat: DateFormat | undefined) {
        if (value && dateFormat) {
            let result;
            if (dateFormat === DateFormat.yyyyMMdd && value.length === 8) {
                result = [value.slice(0, 4), "-", value.slice(4, 6), "-", value.slice(6, 8)].join('');
            } else if (dateFormat === DateFormat.yyyyMM && value.length === 6) {
                result = [value.slice(0, 4), "-", value.slice(4, 6)].join('');
            } else if (dateFormat === DateFormat.yyyy && value.length === 4) {
                result = value.slice(0, 4);
            }

            return result;
        }
        return value;
    }

    /**
     * @internal
     * @param alignment 
     */
    private mapAlignmentToRealGridAlignment(alignment: ColumnAlignment): string {
        switch (alignment) {
            case ColumnAlignment.left: return 'near';
            case ColumnAlignment.right: return 'far';
            default: return alignment.toString();
        }
    }

    /**
     * @internal
     */
    private resolveDateFormatByColunmProperty(dateFormat: DateFormat | undefined, property: "datetimeFormat" | "editMask" | "displayStyle" | "textMask") {
        const data = {
            'yyyy': {
                datetimeFormat: 'yyyy',
                editMask: '9999',
                displayStyle: 'yyyy',
            },
            'yyyyMM': {
                datetimeFormat: 'yyyyMM',
                editMask: '9999-99',
                displayStyle: 'yyyy-MM',
            },
            'yyyyMMdd': {
                datetimeFormat: 'yyyyMMdd',
                editMask: '9999-99-99',
                displayStyle: 'yyyy-MM-dd',
            },
            'iso': {
                datetimeFormat: 'yyyy.MM.dd HH:mm;2000;AM,PM',
                editMask: '9999-99-99',
                displayStyle: 'yyyy년 M월 d일 a h시m분',
            }
        }

        if (!dateFormat) {
            return data['yyyyMMdd'][property];
        }

        return data[dateFormat][property];
    }

    /**
     * @internal
     * 데이터를 리턴하는 Promise로 그리드에 드랍다운 컬럼을 세팅하낟
     */
    private setDropDownColumn(
        column: IColumn,
        dropDownCodeProperty: string | undefined,
        dropDOwnTextProperty: string | undefined,
        dropDownDataSourceCallBack: Promise<any>): void {
        if (!dropDownCodeProperty || !dropDOwnTextProperty) {
            throw new Error('property setting missed');
        }

        dropDownDataSourceCallBack.then((response) => {
            column.dropDownDataItems = response.map((obj) => {
                return {
                    [dropDownCodeProperty]: obj[dropDownCodeProperty],
                    [dropDOwnTextProperty]: obj[dropDOwnTextProperty]
                }
            });
            this.setColumn(column);
        });
    }

    /**
     * @internal
     * 세팅된 IColumn을 리얼그리드 gridView와 dataProvider에 세팅한다.
     */
    private _setColumns(changed: boolean): void {
        if (!this._gridView) {
            return;
        }

        if (!this._columns) {
            throw new Error("OBTListGridInterface._setColumns: column can't be null");
        }

        if (changed && this._provider && this._provider.fields === undefined && this._realGridProvider) {
            this._realGridProvider.setFields(this.mapIColumnToProviderFields(this._columns));
        }

        const realGridColumns = this._columns
            .filter(column => (column.type !== ColumnType.data && column.type !== ColumnType.visit))
            .map(column => this.mapIColumnToRealGridColumn(column))
            .filter(column => column);

        this._gridView.setColumns(realGridColumns);

        if (this._columns) {
            this._columns.forEach((column) => {
                if (column.dropDownDataSourceCallBack) {
                    this.setDropDownColumn(
                        column,
                        column.dropDownCodeProperty,
                        column.dropDownTextProperty,
                        column.dropDownDataSourceCallBack)
                }
            })
        }
    }

    /**
     * @internal
     * 이벤트 발행 편의 메소드
     */
    private invokeEvent<T>(event: ChaningEvent<(e: T) => void>, eventArgs: T, containData?: boolean, changedData?: boolean) {
        let invokeResult = {
            isCancel: false,
            returnData: null,
        };

        for (let i = 0; i < event.events.length; i++) {
            try {
                event.events[i](eventArgs);
                if (eventArgs['cancel'] === true) {
                    invokeResult.isCancel = true;
                    break;
                } else {
                    if (containData === true && eventArgs['data']) {
                        invokeResult.returnData = eventArgs['data'];
                    }
                }
            } catch (error) {
                console.error(error);
                invokeResult.isCancel = true;
                break;
            }
        }

        return invokeResult; //false;
    }

    /**
     * @internal
     * 로우단위 유효성체크
     */
    private checkRowValidation(rowIndex: number): boolean {
        const dataItem = this.getRow(rowIndex);

        const hasRequiredColumnEmpty = Object.keys(dataItem).find((propertyName) => {
            const column = this.getColumnByName(propertyName);

            if (column) {
                if (column.required) {
                    return GridUtil.isEmpty(dataItem[propertyName]);
                }
            }
            return false;
        }) ? true : false;

        return !hasRequiredColumnEmpty;
    }

    /**
    * 특정 인덱스의 특정 컬럼이 수정 가능한지 여부를 리턴한다.
    * 컬럼세팅에 수정가능 여부가 지정되어있지 않으면 그리드 레벨의 수정가능 여부를 본다. 
     * @param targetColumn 
     * @param rowIndex 
     * @param columnName 
     */
    public isColumnEditable(targetColumn: IColumn, rowIndex: number): boolean {
        if (targetColumn.readonly === true) {
            return false;
        }

        if (targetColumn.type !== ColumnType.dropDown) {
            return false;
        }

        let editable: boolean;

        if (targetColumn.editable !== undefined) {
            if (typeof targetColumn.editable === 'boolean') {
                editable = targetColumn.editable;
            } else if (typeof targetColumn.editable === 'function') {
                editable = targetColumn.editable({
                    values: this.getRow(rowIndex),
                    rowIndex: rowIndex,
                    columnName: targetColumn.name
                });
            } else {
                throw new Error('OBTListGridInterface.isColumnEditable: ' + targetColumn.name + '.editable is not a function or boolean');
            }
        } else {
            editable = this.gridOption.editable !== undefined ? this.gridOption.editable : false;
        }

        return editable;
    }

    /**
     * @internal
     * after read
     */
    private _invokeOnAfterRead(data: any): void {
        this._owner.setState({
            displayMessageType: this.getRowCount() > 0 ? 'data' : 'noData'
        });
        this.invokeEvent<Events.AfterReadEventArgs>(this.onAfterRead,
            new Events.AfterReadEventArgs(this, data || []));
    }

    /**
     * @internal
     * FocusStyle
     */
    private _setFocusStyle(use: boolean) {
        if (!this.isReady) {
            return;
        }

        if (use === true) {
            if (this._hasFocus) {
                this._gridView.setDisplayOptions({
                    focusColor: "#ff1c90fb",
                    focusBorderWidth: 2,
                    focusBackground: "#330094ff",
                    columnMovable: this.gridOption.columnMovable
                });
            } else {
                this._gridView.setDisplayOptions({
                    focusColor: "#00000000",
                    focusBorderWidth: 0,
                    focusBackground: "#001c90fb",
                    columnMovable: this.gridOption.columnMovable
                });
            }
        }
    }

    //region public method

    /**
     * 포커스 스타일
     * @param use 포커스스타일 사용여부
     */
    public setFocusStyle(use: boolean): OBTListGridInterface {
        this._gridOption.useFocusStyle = use;
        this._setFocusStyle(use);
        return this;
    }

    /**
     * GlobalGridOption을 리턴한다.
     * 복사본을 리턴하기 때문에 리턴된 오브젝트의 값을 변경해도 그리드에는 영향이 없다.
     */
    public getGridOption() {
        const copy = Object.assign({}, this._gridOption);
        return copy;
    }

    /**
     * 그리드옵션을 동적으로 조정한다.
     * 옵션중 할당되지 않은 값에는 디폴트값이 할당된다.
     * @param options 
     */
    public setGridOption(options: GridGlobalOption, initialize: boolean = false) {
        // 디폴트 옵션 가져오기
        if (options.paging === undefined) {
            options.paging = false;
        }
        if (options.rowCountPerPage === undefined) {
            options.rowCountPerPage = 10;
        }
        if (options.columnMovable === undefined) {
            options.columnMovable = true;
        }
        if (options.rowMovable === undefined) {
            options.rowMovable = true;
        }
        if (options.isPageTotalCountText === undefined) {
            options.isPageTotalCountText = true;
        }
        if (options.pagingBlock === undefined) {
            options.pagingBlock = 10;
        }

        // 포커스 스타일제거
        if (options.useFocusStyle === undefined) {
            options.useFocusStyle = false;
        }


        if (options.editable === undefined) {
            options.editable = false;
        }

        if (options.appendable === undefined) {
            options.appendable = false;
        }

        if (options.checkable === undefined) {
            options.checkable = false;
        }

        if (options.radioable === undefined) {
            options.radioable = false;
        }

        if (options.indicator === undefined) {
            options.indicator = false;
        }

        if (options.rowSelectMode === undefined) {
            options.rowSelectMode = false;
        }

        if (options.preventSort === undefined) {
            options.preventSort = false;
        }

        if (options.headerCheckVisible === undefined) {
            options.headerCheckVisible = options.checkable;
        }

        if (options.eachRowResizable === undefined) {
            options.eachRowResizable = false;
        }

        this._rowCountPerPage = options.rowCountPerPage; // 초기화
        this._gridOption = options;

        const gridOptions = this.getRealGridOption();
        this._gridView.setOptions(gridOptions);


        // 옵션처리 이후의 세팅 

        this.setCheckable(
            this._gridOption.checkable !== undefined ? this._gridOption.checkable : false,
            this._gridOption.headerCheckVisible !== undefined ? this._gridOption.headerCheckVisible : false
        );

        if (this._gridOption.radioable) {
            this._gridView.setCheckBar({
                exclusive: true,
                visible: true
            });
        }

        this.gridView.setPasteOptions({
            numberSeparator: '.',
            numberChars: ',',
            applyMaxLength: true,
            applyEditMask: true,
            checkReadOnly: true
        });

        this.gridView.setCopyOptions({
            singleMode: true,
        });

        // 로우 드래그드롭 설정
        this._gridView.setEditOptions({
            innerDraggable: options.rowMovable,
            // editable: false,
        });

        // //컬럼이동설정 여기서 설정하니 모바일에서 오류나네..
        // let displayOptions = this.gridView.getDisplayOptions();
        // displayOptions.columnMovable = options.columnMovable;
        // this.gridView.setDisplayOptions(displayOptions);
        if (options.columnMovable === false) {
            this.gridView.setDisplayOptions({ columnMovable: options.columnMovable });
        }

        this._gridView.setSelectOptions({
            style: 'singleRow',
            mobileStyles: {
                "background": "#220793f9",
                "selectedBackground": "#ff696969",
                "inactiveBackground": "#ffd3d3d3",
                "border": "#00ffffff,0",
                "foreground": "#ff000000",
                "selectedForeground": "#ffffffff",
                "inactiveForeground": "#ff808080",
                "paddingLeft": 0,
                "paddingRight": 0,
                "paddingTop": 0,
                "paddingBottom": 0,
                "iconIndex": "0",
                "iconLocation": "left",
                "iconAlignment": "center",
                "iconOffset": 0,
                "iconPadding": 0,
                "contentFit": "auto",
                "selectionDisplay": "mask",
                "hoveredMaskBackground": "#1f5292f7",
                "hoveredMaskBorder": "#335292f7,1px",
                "figureInactiveBackground": "#ffd3d3d3",
                "figureBorder": "#00000000,1px",
                "figureBackground": "#00000000"
            }
        });

        this._gridView.setMobileOptions({
            longTapDuration: 500, // 롱탭이 발생하기까지의 누르고 있는 시간을 지정한다.
            doubleTapInterval: 300, // 더블탭이 발생하기까지의 탭간 시간을 지정한다.
            tapThreshold: 4, // 탭간 동일 탭으로 인지하는 영역범위
            showTooltip: true, // 툴팁기능
        });

        // 로우오버시 스타일적용
        // this._gridView.setDisplayOptions({
        //     rowHoverMask: {
        //         visible: true,
        //         hoverMask: "row",
        //         styles: {
        //             background: '#00000000',
        //             border: "#1c90fb,1",
        //         }
        //     }
        // });

        if (initialize !== true) {
            // this.setRowMovableColumnIfAbailable();
            this.setColumns(this.columns);
        }
    }

    /**
     * 
     */
    private setRowMovableColumnIfAbailable() {
        if (!this.columns) {
            return;
        }

        if (this.gridOption.rowMovable === true) {
            if (this.columns.some(i => i.name === OBTListGridInterface.ROW_MOVE_COLUMN_NAME) === false) {
                this._columns = [{
                    name: OBTListGridInterface.ROW_MOVE_COLUMN_NAME,
                    header: OrbitInternalLangPack.getText('WE000002019', '이동'),
                    type: ColumnType.button,
                    alignment: ColumnAlignment.center,
                    onlyIcon: true,
                    width: this.gridOption.rowMovableColumnWidth ? this.gridOption.rowMovableColumnWidth : 30,
                    imageButtons: {
                        alignment: 'center',
                        images: [
                            {
                                name: "이동버튼",
                                up: moveRowNormalIcon,
                                hover: moveRowOverIcon,
                                down: moveRowNormalIcon,
                                cursor: "pointer",
                            }
                        ]
                    }
                } as IColumn].concat(this.columns);
            }
        } else {
            this._columns = this._columns!.filter(column => column.name !== OBTListGridInterface.ROW_MOVE_COLUMN_NAME);
        }
    }

    public setEmptySet(emptySet: {
        emptyDataImage?: JSX.Element,
        emptyDataMsg?: string,
        emptySearchImage?: JSX.Element,
        emptySearchMsg?: string,
    }) {
        if (emptySet.emptyDataImage) {
            this.gridOption.emptyDataImage = emptySet.emptyDataImage;
        }

        if (emptySet.emptyDataMsg) {
            this.gridOption.emptyDataMsg = emptySet.emptyDataMsg;
        }

        if (emptySet.emptySearchImage) {
            this.gridOption.emptySearchImage = emptySet.emptySearchImage;
        }

        if (emptySet.emptySearchMsg) {
            this.gridOption.emptySearchMsg = emptySet.emptySearchMsg;
        }
    }

    /**
     * 그리드 좌측에 인덱스여부 설정
     * @param visible 
     */
    public setIndicator(visible: boolean): OBTListGridInterface {
        this._gridOption.indicator = visible;
        if (this._gridView) {
            this._gridView.setIndicator({
                visible: visible
            });
        }
        return this;
    }

    /**
     * 그리드 옵션 푸터 설정
     * @param visible 
     * @param footerCount  
     */
    public setFooter(visible: boolean, footerCount?: number) {
        if (footerCount !== undefined) {
            this.gridOption.footerCount = footerCount;
        }

        let option = {
            visible: visible
        }

        option = Object.assign(option, footerCount !== undefined ? {
            height: 20 * (footerCount),
            count: footerCount
        } : {});

        if (this._gridView) {
            this.gridView.setFooter(option)
        }
    }

    /**
     * 체크모드 설정
     * @param checkable 
     */
    public setCheckable(checkable: boolean, showHeaderCheck: boolean): OBTListGridInterface {
        this._gridOption.checkable = checkable;

        if (this._gridView) {
            this._gridView.setCheckBar(Object.assign(this.getDefaultCheckBarOption(), {
                visible: checkable,
                showAll: showHeaderCheck,
                //checkbox의 외곽라인을 여부를 지정한다.
                drawCheckBox: true,
            }));
        }

        return this;
    }

    /**
     * 특정행 체크박스 비활성화 설정
     * @param checkableExpression 
     */
    public setCheckableExpression(checkableExpression: string): OBTListGridInterface {
        if (this._gridView) {
            this._gridView.setCheckableExpression(checkableExpression, true)
        }

        return this;
    }

    /**
     * IColumn배열을 받아 필드에 할당하고 그리드뷰와 데이터프로바이더에 세팅한다.
     * fluent api
     */
    public setColumns(columns: IColumn[] | null): OBTListGridInterface {
        this._columns = columns;

        this.setRowMovableColumnIfAbailable();
        this._setColumns(true);

        return this;
    }

    /**
     * index를 기반으로 컬럼을 제거한다.
     * fluent api
     * @param index 
     */
    public removeColumn(index: number): OBTListGridInterface {
        if (this._columns && index >= 0 && index < this._columns.length) {
            this._columns = this._columns.splice(index, 1);
            this._setColumns(true);
        }
        return this;
    }

    /**
     * 컬럼을 추가한다.
     * @param index 
     * @param columns 
     */
    public addColumn(index: number, ...columns: IColumn[]): OBTListGridInterface {
        if (this._columns) {
            if (index >= 0 && index < this._columns.length) {
                this._columns = this._columns.slice(0, index).concat(columns, this._columns.slice(index));
            } else {
                this._columns = this._columns.concat(columns);
            }
        } else {
            this._columns = columns;
        }

        this._setColumns(true);

        return this;
    }

    /**
     * 선택한 행의 로우를 삭제한다.
     * 여러개의 로우를 한번에 삭제할때는 removeRows를 사용
     * @param rowIndex 
     */
    public async removeRow(rowIndex: number) {
        return this.removeRows([rowIndex]);
    }

    /**
     * 여러건의 로우를 한번에 삭제한다. 
     * @param rowIndexes 
     */
    public async removeRows(rowIndexes: number[]): Promise<void> {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.removeRows: 그리드가 준비되지 않았습니다.');
        }

        let dataRows = rowIndexes.map(index => { return this._gridView.getDataRow(index) });

        // 이벤트에서 cancle되지 않은 건들 소프트 삭제처리
        this._realGridProvider.removeRows(
            dataRows
        );

        this.refresh();
    }

    /**
     * 프로바이더 세팅
     * @param provider 
     */
    public setProvider(provider: IDataProvider | null): OBTListGridInterface {
        try {
            if (provider) {
                if (provider._provider === undefined) {
                    provider._provider = new RealGridJS.LocalDataProvider();

                    provider._provider.setOptions({
                        checkStates: true,
                        softDeleting: true,
                        deleteCreated: true,
                        restoreMode: 'explicit'
                    });

                    if (this._columns) {
                        provider._provider.setFields(provider.fields ?
                            provider.fields : this.mapIColumnToProviderFields(this._columns));
                    }
                }
            }
            this._provider = provider;
            if (this._gridView) {
                this._gridView.setDataProvider(this._realGridProvider ? this._realGridProvider : null);
            }
        } catch (e) {
            console.error(e);
            throw e;
        }

        return this;
    }

    /**
     * 컬럼의 폭을 데이터에 따라 자동으로 조절한다.
     * @param columnName? 적용할 컬럼이름
     * @param maxWidth?
     * @param minWidth?
     */
    public fitColumnWidth(option?: { columnName?: string, maxWidth?: number, minWidth?: number, visibleOnly?: boolean }) {
        if (!option) {
            this.gridView.fitColumnWidth(null, 0, 0, true);
            return;
        }

        this.gridView.fitColumnWidth(
            option.columnName ? option.columnName : null,
            option.maxWidth ? option.maxWidth : 0,
            option.minWidth ? option.minWidth : 0,
            option.visibleOnly ? option.visibleOnly : true,
        );
    }

    /**
     * 그리드에 바인딩된 데이터의 길이를 리턴한다.
     * @returns 데이터의 길이(count)
     */
    public getRowCount(): number {
        if (!this.isReady) {
            return -1; // 초기그리드 이미지 세팅위해 필요
            // throw new Error('OBTListGridInterface.getRowCount: grid is not ready');
        }

        return this._gridView.getItemCount();
    }

    /**
     * 체크된 것이나 특정 GridState인 행의 인덱스 배열을 가져온다. 
     * [GridState]
     * @param options 가져오는 기준이되는 옵션
     */
    public getRowIndexes(options?: { checkedOnly?: boolean }): number[] {
        let rowIndexes: number[] = [];
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.getRowIndexes: grid is not ready');
        }

        if (options && Object.keys(options).length > 0) {
            if (options.checkedOnly === true) {
                rowIndexes = (this._gridView.getCheckedItems(true) || []);
            }
        } else {
            rowIndexes = (() => {
                let indexes: number[] = [];
                const rowCount = this.getRowCount();
                for (let i = 0; i < rowCount; i++) {
                    indexes.push(i);
                }
                return indexes;
            })()
        }

        return rowIndexes;
    }

    /**
     * 체크된 것이나 특정 GridState인 행의 데이터 배열을 가져온다.
     * @param options 가져오는 기준이 되는 옵션
     */
    public getRows(options?: { checkedOnly?: boolean }): any[] {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.getRows: grid is not ready');
        }

        let rows: any[] = [];

        if (options && Object.keys(options).length > 0) {
            const rowIndexes = this.getRowIndexes(options);
            return rowIndexes.map((rowIndex) => this._gridView.getValues(rowIndex));
        } else {
            rows = this._realGridProvider.getJsonRows(0, -1);
        }

        return rows;
    }

    /**
     * 파라미터로 준 인덱스의 행 데이터를 가져온다.
     * @param rowIndex 행의 인덱스
     * @returns 데이터
     */
    public getRow(rowIndex: number): any {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.getRow: grid is not ready');
        }

        return this.getValues(rowIndex);
    }

    /**
     * 파라미터로 준 인덱스의 행 데이터를 가져온다.
     * @param rowIndex 행의 인덱스
     * @returns 데이터
     */
    public getValues(rowIndex: number): any {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.getValues: grid is not ready');
        }

        let resultObject: any = {};

        let rawValues = this._gridView.getValues(rowIndex);

        Object.keys(rawValues).forEach(col => {


            if (col === '__rowId') {
                resultObject[col] = rawValues[col];
            } else {
                resultObject[col] = this.getValue(rowIndex, col);
            }
        });

        return resultObject;
    }

    /**
     * 특정 셀의 데이터를 가져온다 
     * @param rowIndex 가져올 데이터의 행
     * @param columnName 가져올 데이터의 컬럼이름 IColumn.name 속성과 매핑
     */
    public getValue(rowIndex: number, columnName: string): any {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.getValue: grid is not ready');
        }

        const column = this.getColumnByName(columnName);
        if (!column) {
            throw new Error("OBTListGridInterface.handleCellButtonClick: can't find column - " + columnName);
        }

        let rawValue = this._gridView.getValue(rowIndex, columnName);
        if (column.type === ColumnType.number && column.nanToZero === true) {
            if (isNaN(rawValue)) {
                rawValue = 0;
            }
        }

        return rawValue;
    }

    /**
     * 그리드에 세팅된 IColumn을 컬럼이름으로 검색해 가져온다.
     * @param columnName 가져올 데이터의 컬럼이름 IColumn.name 속성과 매핑
     * @returns 이름으로 찾은 IColumn이나 undefined
     */
    public getColumnByName(columnName: string): IColumn | undefined {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.getColumnByName: grid is not ready');
        }

        if (!this.columns) {
            throw new Error("OBTListGridInterface.getColumnByName: columns can't be null ");
        }

        let column: IColumn | undefined;
        this.columns.forEach((col) => {
            if (col.name === columnName) {
                column = col;
                return;
            }

            if (col.type === ColumnType.group && col.columns && !column) {
                column = col.columns.find((col) => {
                    return (col.name === columnName);
                });
            }
        });

        return column
    }

    /**
     * unmanaged result 리얼그리드의 컬럼정보를 이름으로 가져온다.
     * @param columnName 가져올 데이터의 컬럼이름 IColumn.name 속성과 매핑
     * @returns 리얼그리드에서 관리되는 컬럼 속성
     */
    public getRealGridColumnByName(columnName: string): any | null {
        return this._gridView.columnByName(columnName)
    }

    /**
     * IColumn을 기반으로 그리드에 컬럼을 세팅한다.
     * @param column 
     */
    public setColumn(column: IColumn): void {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.getValue: grid is not ready');
        }

        //TODO: 변경된것에 따라 _columns도 바꿔줘야함

        try {
            const realgridColumn = this._gridView.columnByName(column.name);

            let result: any;
            if (realgridColumn) {
                result = Object.assign(
                    realgridColumn,
                    this.mapIColumnToRealGridColumn(column)
                );
            } else {
                result = this.mapIColumnToRealGridColumn(column);
            }

            if (column.type === ColumnType.data || column.type === ColumnType.visit) {
                this._gridView.removeColumn(column.name);
                if (this._columns) {
                    this._realGridProvider.setFields(this.mapIColumnToProviderFields(this._columns));
                }
            } else {
                // setValue는 기본적으로 GridView에서 얻어온 컬럼에서 필요한것만 변경해주는 방식으로 써야한다.
                this._gridView.setColumn(result);
            }
        } catch (e) {
            console.error(e)
            throw e;
        }
    }

    /**
     * 리얼그리드 내부에 아이콘 식으로 사용할 이미지 리스트를 등록한다. 
     * @param columnName
     * @param iconPathList
     */
    public registerColumnImageList(columnName: string, iconPathList: string[]): void {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.getValue: grid is not ready');
        }

        var imgs = new RealGridJS.ImageList(columnName + '_image', '');
        imgs.addUrls(iconPathList);

        this.gridView.registerImageList(imgs);
    }

    /**
     * 특정 인덱스 체크처리
     * @param rowIndex 
     * @param checked 
     */
    public setCheck(rowIndex: number, checked: boolean): void {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.setCheck: grid is not ready');

        }
        let checkbar = this._gridView.getCheckBar()
        this._setCheck(rowIndex, checked, checkbar.exclusive, true);

        //TODO: 체크컬럼에 대한 컬럼배열을 뭘 넘겨줄까
        this.invokeEvent<Events.AfterDataChangeEventArgs>(
            this.onAfterDataChanged,
            new Events.AfterDataChangeEventArgs(this, [], Events.GridDataChangeAction.CHECK_CHANGED));
    }

    /**
     * @internal
     * 특정 인덱스 체크처리
     * @param rowIndex 
     * @param checked 
     */
    private _setCheck(rowIndex: number, checked: boolean, exclusive: boolean, checkEvent: boolean): void {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.setCheck: grid is not ready');
        }

        this._gridView.checkItem(rowIndex, checked, exclusive, checkEvent);
    }

    /**
     * 특정 인덱스의 행이 체크되어있는지 여부를 리턴한다.
     * @param rowIndex 
     */
    public isCheckedRow(rowIndex: number): boolean {
        if (this.isReady === false) {
            throw new Error('OBTListGridInterface.isCheckedRow: grid is not ready');
        }

        return this._gridView.isCheckedItem(rowIndex);
    }

    /**
     * 특정 인덱스의 행이 체크되어있는지 여부를 리턴한다.
     * @param rowIndex 
     */
    public getCheck(rowIndex: number): boolean {
        return this.isCheckedRow(rowIndex);
    }

    /**
     * 특정 인덱스의 행이 체크가능 여부를 세팅한다.
     * @param rowIndex 
     * @param checkable 
     */
    public setRowCheckable(rowIndex: number, checkable: boolean) {
        if (this.isReady === false) {
            throw new Error('OBTListGridInterface.setRowCheckable: grid is not ready');
        }

        this._gridView.setCheckable(rowIndex, checkable);
    }

    /**
     * 특정 인덱스의 행이 체크가능한지 여부를 리턴한다.
     * @param rowIndex 
     */
    public isRowCheckable(rowIndex: number): boolean {
        if (this.isReady === false) {
            throw new Error('OBTListGridInterface.isRowCheckable: grid is not ready');
        }

        return this._gridView.isCheckable(rowIndex);
    }

    /**
     * 그리드의 데이터행을 모두 체크한다.
     * @param checked 
     */
    public checkAll(checked?: boolean) {
        if (this.isReady === false) {
            throw new Error('OBTListGridInterface.checkAll: grid is not ready');
        }

        // checkAll(checked, visibleOnly, checkableOnly, checkEvent)
        this._gridView.checkAll(checked === undefined ? true : checked, false, false, true);
    }

    /**
     * 그리드에서 체크된 로우를 가져온다.
     */
    public getCheckedRows(): any[] {
        return this.getRows({
            checkedOnly: true
        });
    }

    /**
     * 체크한 인덱스 가져오기
     */
    public getCheckedIndexes(): number[] {
        return this.getRowIndexes({
            checkedOnly: true
        });
    }

    /**
     * @deprecated
     * 선택된 로우의 인덱스를 가져온다.
     */
    public getFocusedIndex(): number {
        console.warn('getFocusedIndex 폐기 예정인 API입니다. getSelectedIndex를 대신 사용해주세요');

        if (!this.isReady) {
            throw new Error('OBTListGridInterface.getFocusedIndex: grid is not ready');
        }

        return this._gridView.getCurrent().itemIndex;
    }

    /**
     * 선택된 로우의 인덱스를 가져온다.
     */
    public getSelectedIndex(): number {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.getSelectedIndex: grid is not ready');
        }

        return this._gridView.getCurrent().itemIndex;
    }

    /**
     * @deprecated
     * '선택된 컬럼의 이름을 가져온다.'
     */
    public getFocusedColumnName(): string {
        console.warn('getFocusedColumnName 폐기 예정인 API입니다. getSelectedColumnName를 대신 사용해주세요');

        if (!this.isReady) {
            throw new Error('OBTListGridInterface.getFocusedColumnName: grid is not ready');
        }

        return this._gridView.getCurrent().column;
    }

    /**
     * '선택된 컬럼의 이름을 가져온다.'
     */
    public getSelectedColumnName(): string {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.getFocusedIndex: grid is not ready');
        }

        return this._gridView.getCurrent().column;
    }

    /**
     * @deprecated
     */
    public getFocusedRow(): any {
        console.warn('getFocusedRow 폐기 예정인 API입니다. getSelectedRow를 대신 사용해주세요');

        if (this.isReady === false) {
            throw new Error('OBTListGridInterface.getFocusedRow: grid is not ready');
        }

        return this.getRow(this._gridView.getCurrent().itemIndex);
    }

    /**
     * 선택되어있는 행의 데이터를 가져온다.
     */
    public getSelectedRow(): any {
        if (this.isReady === false) {
            throw new Error('OBTListGridInterface.getSelectedRow: grid is not ready');
        }

        return this.getRow(this._gridView.getCurrent().itemIndex);
    }

    /**
     * 특정 셀 선택.
     * 포커스까지 옮기지는 않는다.
     * TODO: 인덱스 단위로는 움직이는데, 컬럼단위로는 이동이 안된다.
     * @param rowIndex 
     * @param columnName 
     */
    public setSelection(rowIndex: number, columnName?: string): void {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.setSelection: grid is not ready');
        }

        // managed event는 리얼그리드 이벤트 콜백에서 처리함
        this._gridView.setCurrent({
            itemIndex: rowIndex,
            column: columnName
        });
    }

    /**
     * 현재 선택된 데이터
     * @returns { rowIndex: 선택되어있는 인덱스, columnName: 선택되어있는 컬럼이름}
     */
    public getSelection(): any {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.getSelection: grid is not ready');
        }

        return {
            rowIndex: this.getSelectedIndex(),
            columnName: this.getSelectedColumnName()
        }
    }

    /**
     * 그리드뷰에 포커스를 준다.
     */
    public focus(): void {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.setFocus: grid is not ready');
        }

        this.gridView.setFocus();
    }

    /**
     * 데이터 검색
     * @param startIndex 검색시 시작 인덱스
     * @param columnNamesForSearch 
     * @param value 
     * @param partialMatch 
     */
    public searchData(startIndex: number, columnNamesForSearch: string[] | null, value: string, selectSearchedCell: boolean, partialMatch: boolean): ICell | null {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.searchData: 그리드가 준비되지 않았습니다.');
        }

        var options = {
            fields: columnNamesForSearch,
            value: value,
            select: selectSearchedCell,
            startItemIndex: startIndex,
            partialMatch: partialMatch,
        }

        var cell = this.gridView.searchCell(options);
        if (!cell) {
            return null;
        }

        return {
            rowIndex: cell.itemIndex,
            columnName: cell.fieldName
        };
    }

    /**
     *  actionButtons 세팅
     * @param actionButtons 
     */
    public setActionButtons(actionButtons: IActionButton[] | null): OBTListGridInterface {
        this._actionButtons = actionButtons;

        return this;
    }

    /**
     * 데이터를 읽어 그리드에 바인딩한다.
     * @param currentPage
     * @param rowCountPerPage
     * @param readCallback 
     * @param readPageCallback
     */
    public readData(currentPageOrOptions?: number | IReadDataOptions, rowCountPerPage?: number, readCallback?: Read, readPageCallback?: ReadPage): Promise<any> {
        let currentPage = typeof currentPageOrOptions === 'number' ? currentPageOrOptions as number : undefined;
        const options = typeof currentPageOrOptions === 'object' ? currentPageOrOptions as IReadDataOptions : {};
        currentPage = currentPage || options.currentPage;
        rowCountPerPage = rowCountPerPage || options.rowCountPerPage;
        readCallback = readCallback || options.readCallback;
        readPageCallback = readPageCallback || options.readPageCallback;
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.readData: grid is not ready');
        }

        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const readFunction = readCallback ?
                        readCallback : this._provider ?
                            this._provider.read : null;

                    if (!readFunction) {
                        throw new Error('OBTListGridInterface.readData: errors occurred at onAfterRead event handler.');
                    }

                    const data = await readFunction(new Events.ReadEventArgs(this));
                    this._totalCount = 0;
                    this._rowCountPerPage = rowCountPerPage ? rowCountPerPage : this._rowCountPerPage;
                    if (data && data.length > 0) {
                        this._totalCount = data[0].totalCount ? data[0].totalCount : 0;
                        this._pageCount = data[0].totalCount ? Math.ceil(data[0].totalCount / this._rowCountPerPage) : 0;
                        this.currentPage = currentPage ? currentPage > this._pageCount ? this._pageCount : currentPage : data[0].currentPage ? data[0].currentPage : 1;
                    }
                    await this.readPageData(readPageCallback);

                    this._owner._updateHover();
                    // 재조회 후 셀 선택이동
                    if (this._gridView) {
                        const changeSelection = options.preventChangeSelection !== undefined && this.gridOption.preventChangeSelection !== undefined ? options.preventChangeSelection !== true :
                            options.preventChangeSelection !== true && this.gridOption.preventChangeSelection !== true
                        if (changeSelection) {
                            this.setSelection(0);
                            // this._gridView.setTopItem(0);
                        }
                    }
                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            })();
        });
    }

    /**
     * @internal
     * 데이터를 읽어 그리드에 바인딩한다.
     * @param readCallback 
     */
    private readPageData(readCallback?: ReadPage): Promise<any> {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.readPageData: grid is not ready');
        }

        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const readFunction = readCallback ?
                        readCallback : this._provider ?
                            this._provider.readPage : null;

                    if (!readFunction) {
                        reject('OBTListGridInterface.readPageData: errors occurred at onAfterRead event handler.');
                        return;
                    }

                    let startRowIndex = (this.currentPage - 1) * this._rowCountPerPage + 1;
                    let endRowIndex = this.currentPage * this._rowCountPerPage >= this._totalCount ? this._totalCount : this.currentPage * this._rowCountPerPage;

                    const data = await readFunction(new Events.ReadPageEventArgs(this, this.rowCountPerPage, this.currentPage, this._totalCount, startRowIndex, endRowIndex, this._dataSortType));
                    this._realGridProvider.setRows(data || []);
                    this.gridView.checkAll(false, false, false, false);
                    this._invokeOnAfterRead(data);
                    this.invokeEvent<Events.DrawerEventArgs>(
                        this.onDrawer,
                        new Events.DrawerEventArgs(this, []));
                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            })();
        });
    }

    /**
     * 그룹컬럼을 제외하고 순수 컬럼만을 가져온다.
     */
    public getFlatColumns(visibleOnly: boolean): IColumn[] {
        if (!this.columns) {
            return [];
        }

        return this._getFlatColumns(this.columns, visibleOnly);
    }

    /**
     * @internal
     * 그룹컬럼을 제외하고 순수 컬럼만을 가져온다.
     */
    private _getFlatColumns(targetColumnList: IColumn[], visibleOnly: boolean): IColumn[] {
        let result: IColumn[] = [];

        (targetColumnList || []).forEach((col) => {
            if (visibleOnly === true) {
                if (col.type !== ColumnType.data && col.type !== ColumnType.group) {
                    if (col.visible !== false) {
                        result.push(col);
                    }
                }
            } else {
                if (col.type !== ColumnType.group) {
                    result.push(col);
                }
            }

            if (col.type === ColumnType.group && col.columns) {
                result = result.concat(this._getFlatColumns(col.columns, visibleOnly));
            }
        });

        return result;
    }

    /**
     * 엑셀 익스포트
     * @link http://help.realgrid.com/api/types/GridExportOptions/
     * @param exportOption 리얼그리드의 GridView.exportGrid 함수의 옵션
     */
    public exportExcel(exportOption?: any) {
        try {
            this._gridView.setStyles(GridStyleForExcel.styles);

            const defaultExportOption = {
                type: "excel",
                target: "local",
                fileName: this.id + '_excel_' + new Date().toISOString() + '.xlsx',
                indicator: 'default', // 'visible' | 'hidden'
                header: 'default', // 'visible' | 'hidden'
                footer: 'default', // 'visible' | 'hidden'
            };

            this.gridView.exportGrid(exportOption ? exportOption : defaultExportOption);

        } catch (e) {
            throw e;
        } finally {
            this._gridView.setStyles(GridStyle.styles);
        }
    }

    /**
     * 특정 컬럼을 보여준다. visible이 false로 지정된 컬럼에 대해서만 영향이 있다.
     * 보여지지 않는다고해서 required, unique의 유효성 체크가 무시되는것은 아님
     * @param columnName 
     */
    public showColumn(columnName: string) {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.showColumn: grid is not ready');
        }

        const column = this.getColumnByName(columnName);

        if (!column) {
            throw new Error("column can't found : " + columnName);
        }
        column.visible = true;

        this.gridView.setColumnProperty(columnName, 'visible', true);
    }

    /**
     * 특정 컬럼을 숨간다. 
     * 보여지지 않는다고해서 required, unique의 유효성 체크가 무시되는것은 아님
     * @param columnName 
     */
    public hideColumn(columnName: string) {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.hideColumn: grid is not ready');
        }

        const column = this.getColumnByName(columnName);

        if (!column) {
            throw new Error("column can't found : " + columnName);
        }
        column.visible = false;

        this.gridView.setColumnProperty(columnName, 'visible', false);
    }

    /**
     * 특정 컬럼 보여지는지 여부 지정.
     * 보여지지 않는다고해서 required, unique의 유효성 체크가 무시되는것은 아님
     * @param columnName 
     * @param isVisible 
     */
    public setColumnVisible(columnName: string, isVisible: boolean) {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.hideColumn: grid is not ready');
        }

        const column = this.getColumnByName(columnName);

        if (!column) {
            throw new Error("column can't found : " + columnName);
        }
        column.visible = isVisible;
        this.gridView.setColumnProperty(columnName, 'visible', isVisible);
    }

    /**
     * 그리드에 설정되어 있는 컬럼의 특정 속성 정보를 변경한다.
     * @param columnName 
     * @param property 
     * @param value 
     */
    public setColumnProperty(columnName: string, property: string, value: any) {
        const targetColumn = this.getColumnByName(columnName);

        if (!targetColumn) {
            return;
        }

        if (property === 'visible') {
            targetColumn.visible = value;
        }

        // TODO: 프로퍼티에 맞춰 컬럼 설정 동기화

        this._gridView.setColumnProperty(columnName, property, value);
    }

    /**
     * 
     * @param rowIndex 
     * @param height 
     * @param refresh 
     */
    public setRowHeight(rowIndex: number, height: number, refresh: boolean = true) {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.setRowHeight: grid is not ready');
        }

        if (this.gridOption.eachRowResizable === false) {
            throw new Error('OBTListGridInterface.setRowHeight: setRowHeight는 그리드 옵션 eachRowResizable = true 에서만 동작합니다.');
        }

        this._gridView.setRowHeight(rowIndex, height, refresh);
    }

    /**
     * 지정한 행 높이를 표시되는 데이터에 맞게 변경한다.
     * (* displayOptions.eachRowResizable: true로 지정되어 있어야 한다. multiLine인 경우 textWrap: “explicit”로 지정 )
     * @param itemIndex 
     * @param maxHeight 
     * @param textOnly 
     * @param refresh 
     */
    public fitRowHeight(itemIndex: number, maxHeight: number, textOnly: boolean = true, refresh: boolean = true) {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.fitRowHeight: grid is not ready');
        }

        if (this.gridOption.eachRowResizable === false) {
            throw new Error('OBTListGridInterface.setRowHeight: setRowHeight는 그리드 옵션 eachRowResizable = true 에서만 동작합니다.');
        }

        this._gridView.fitRowHeight(itemIndex, maxHeight, textOnly, refresh);
    }

    /**
     * 모든 행의 높이를 표시되는 데이터에 맞게 변경한다.
     * (* displayOptions.eachRowResizable: true로 지정되어 있어야 한다. multiLine인 경우 textWrap: “explicit”로 지정 )
     * * 데이터 행의 수가 많은 경우 브라우져에서 응답없음이 발생할 수 있으므로 주의해서 사용한다.* 
     * @param maxHeight 
     * @param textOnly 
     */
    public fitRowHeightAll(maxHeight: number, textOnly?: boolean) {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.fitRowHeightAll: grid is not ready');
        }

        if (this.gridOption.eachRowResizable === false) {
            throw new Error('OBTListGridInterface.setRowHeight: setRowHeight는 그리드 옵션 eachRowResizable = true 에서만 동작합니다.');
        }

        this._gridView.fitRowHeightAll(maxHeight, textOnly);
    }

    public addCellRenderers(value: any) {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.addCellRenderers: grid is not ready');
        }

        if (!this.columns) {
            throw new Error("OBTListGridInterface.addCellRenderers: columns can't be null ");
        }

        if (!value) {
            return;
        }
        this._gridView.addCellRenderers(value);
    }

    /**
     * 그리드에 설정되어 있는 행의 특정 속성 정보를 변경한다.
     * @param property 
     * @param value 
     */
    public setRowProperty(property: string, value: any) {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.getColumnByName: grid is not ready');
        }

        if (!this.columns) {
            throw new Error("OBTListGridInterface.getColumnByName: columns can't be null ");
        }

        this.columns.forEach((col) => {
            if (property === 'visible') {
                col.visible = value;
            }
            // TODO: 프로퍼티에 맞춰 컬럼 설정 동기화
            this._gridView.setColumnProperty(col.name, property, value);
        });

    }

    /**
     * 특정 컬럼의 데이터에 대한 집계값을 리턴한다.
     * @param columnName 컬럼이름
     * @param expression 지정되어있는 표현식중 하나
     */
    public getSummary(columnName: string, expression: 'sum' | 'avg' | 'min' | 'max' | 'count'): number {
        return this.gridView.getSummary(columnName, expression);
    }

    /**
     * 그리드 클리어
     */
    public clearData(): void {
        if (!this.isReady) {
            throw new Error('OBTListGridInterface.clearData: grid is not ready');
        }

        const rowIndex = this.getSelectedIndex();
        this._realGridProvider.clearSavePoints();
        this._realGridProvider.clearRows();

        this._gridView.refresh();

        this._owner.setState({
            displayMessageType: 'initial'
        });
        this._pageCount = 0;
        this._currentPage = 1;

        if (rowIndex >= 0) {
            this.invokeEvent<Events.AfterSelectChangeEventArgs>(
                this.onAfterSelectChange,
                new Events.AfterSelectChangeEventArgs(this, -1, null, true));

            this.invokeEvent<Events.AfterSelectChangeEventArgs>(
                this.onAfterChangeRowSelection,
                new Events.AfterSelectChangeEventArgs(this, -1, null, true));
        }

        this.invokeEvent<Events.AfterDataChangeEventArgs>(
            this.onAfterDataChanged,
            new Events.AfterDataChangeEventArgs(this, [], Events.GridDataChangeAction.DATA_CLEARED));

        this.invokeEvent<Events.DrawerEventArgs>(
            this.onDrawer,
            new Events.DrawerEventArgs(this, [])
        );
    }

    /**
     * 그리드뷰 refresh
     */
    public refresh(refresh: boolean = true): void {
        if (this._gridView) {
            this._gridView.resetSize();
            if (refresh) {
                this._gridView.refresh();
            }
        }
    }

    /**
     * 행을 변경하는 인터페이스
     * @param oldIndex 변경 전 행
     * @param newIndex 옮긴 후 행
     */
    public moveRow(oldIndex: number, newIndex: number) {
        if (this._provider &&
            this._provider._provider &&
            (oldIndex >= 0 && oldIndex < this._provider._provider._dp._rowIds.length) &&
            (newIndex >= 0 && newIndex < this._provider._provider._dp._rowIds.length)
        ) {
            this._provider._provider.moveRow(oldIndex, newIndex);
        }
    }

    /**
     * @internal
     * 리얼그리드 이벤트핸들러 onItemChecked 
     */
    private handleItemChecked(grid: any, rowIndex: number, checked: boolean): void {
        const invokeResult = this.invokeEvent<Events.BeforeCheckEventArgs>(
            this.onBeforeCheck,
            new Events.BeforeCheckEventArgs(this, rowIndex, checked)
        );

        if (invokeResult.isCancel === false) {
            this.invokeEvent<Events.AfterCheckEventArgs>(
                this.onAfterCheck,
                new Events.AfterCheckEventArgs(this, rowIndex, checked)
            );

            this.invokeEvent<Events.DrawerEventArgs>(
                this.onDrawer,
                new Events.DrawerEventArgs(this, this.getCheckedRows())
            );

            this.invokeEvent<Events.AfterDataChangeEventArgs>(
                this.onAfterDataChanged,
                new Events.AfterDataChangeEventArgs(this, [], Events.GridDataChangeAction.CHECK_CHANGED));
        } else {
            // 캔슬처리
            this._setCheck(rowIndex, !checked, false, false);
        }
    }

    /**
     * @internal
     * shift 클릭으로 여러개의 행을 한번에 체크할때 발생
     * @param grid 
     * @param rowIndexes 
     * @param checked 
     */
    private handleItemsChecked(grid: any, rowIndexes: number[], checked: boolean): void {
        rowIndexes.forEach((rowIndex) => {
            const invokeResult = this.invokeEvent<Events.BeforeCheckEventArgs>(
                this.onBeforeCheck,
                new Events.BeforeCheckEventArgs(this, rowIndex, checked)
            );

            if (invokeResult.isCancel === false) {
                this.invokeEvent<Events.AfterCheckEventArgs>(
                    this.onAfterCheck,
                    new Events.AfterCheckEventArgs(this, rowIndex, checked)
                );
            } else {
                // 캔슬처리
                this._setCheck(rowIndex, !checked, false, false);
            }
        })

        this.invokeEvent<Events.DrawerEventArgs>(
            this.onDrawer,
            new Events.DrawerEventArgs(this, this.getCheckedRows())
        );

        this.invokeEvent<Events.AfterDataChangeEventArgs>(
            this.onAfterDataChanged,
            new Events.AfterDataChangeEventArgs(this, [], Events.GridDataChangeAction.CHECK_CHANGED));
    }

    /**
     * @internal
     * @param grid 
     * @param column 
     * @param checked 
     */
    private handleColumnCheckedChanged(grid: any, column: any, checked: boolean) {
    }

    /**
     * @internal
     * onItemAllChecked 이벤트콜백
     */
    private handleItemAllChecked(grid: any, checked: boolean) {
        if (checked === true) {
            for (let i = 0; i < this.getRowCount(); i++) {
                if (!this.isRowCheckable(i)) {
                    this._setCheck(i, false, false, false);
                }
            }
        }

        this.invokeEvent<Events.AfterHeaderCheckEventArgs>(
            this.onAfterHeaderCheck,
            new Events.AfterHeaderCheckEventArgs(this, checked)
        );

        this.invokeEvent<Events.DrawerEventArgs>(
            this.onDrawer,
            new Events.DrawerEventArgs(this, this.getCheckedRows())
        );

        this.invokeEvent<Events.AfterDataChangeEventArgs>(
            this.onAfterDataChanged,
            new Events.AfterDataChangeEventArgs(this, [], Events.GridDataChangeAction.CHECK_CHANGED));
    }

    /**
     * @internal
     * 리얼그리드 onCurrentChanging 핸들러 
     * 행이 바뀌기 전에 호출된다. 
     * false를 리턴하면 행이 바뀌지 않는다. 
     */
    private handleCurrentChanging(grid: any, oldIndex: any, newIndex: any): boolean {
        // 삭제중일경우 이벤트를 동작시키지 않는다.
        if (this._rowDeleting === true) {
            this._rowDeleting = false;

            return true;
        }

        const oldTargetColumn = this.getColumnByName(oldIndex.column);
        if (!oldTargetColumn) {
            throw new Error('OBTListGridInterface.handleCurrentChanging: column not found(' + oldIndex.column + ')');
        }

        const newTargetColumn = this.getColumnByName(newIndex.column);
        if (!newTargetColumn) {
            throw new Error('OBTListGridInterface.handleCurrentChanging: column not found(' + newIndex.column + ')');
        }

        // 현재 로우가 변했는지 여부
        this._isRowChanged = oldIndex.itemIndex !== newIndex.itemIndex;

        // onBeforeSelectChange 이벤트 태우기
        let invokeResult = this.invokeEvent<Events.BeforeSelectChangeEventArgs>(
            this.onBeforeSelectChange,
            new Events.BeforeSelectChangeEventArgs(this, oldIndex.itemIndex, newIndex.itemIndex, oldIndex.column, newIndex.column, this._isRowChanged));

        // TODO: 이거 왜있더라;;;;
        if (invokeResult.isCancel === false && this._isRowChanged) {
            invokeResult = this.invokeEvent<Events.BeforeSelectChangeEventArgs>(
                this.onBeforeChangeRowSelection,
                new Events.BeforeSelectChangeEventArgs(this, oldIndex.itemIndex, newIndex.itemIndex, oldIndex.column, newIndex.column, this._isRowChanged));
        }

        // 빈값으로 
        // if (invokeResult.isCancel === false) {
        // const oldValue = this.getValue(oldIndex.itemIndex, oldIndex.column);
        // if (this.isEmpty(oldValue)) {
        //     // empty 컬럼처리
        //     const eventArgs = new Events.SkipEmptyCellEventArgs(this, oldIndex.column, oldIndex.itemIndex, oldTargetColumn.required, "");
        //     this.invokeEvent<Events.SkipEmptyCellEventArgs>(
        //         this.onSkipEmptyCell,
        //         eventArgs
        //     );
        // }
        // }

        // 셀이동이 변경될 때 조건을 체크해 storeData 처리
        if (oldIndex.itemIndex >= 0
            && this._isRowChanged === true
            && this.gridOption.editable === true
            && invokeResult.isCancel === false
            && oldIndex.itemIndex < newIndex.itemIndex) {
            if (this.checkRowValidation(oldIndex.itemIndex)) {
                // this.storeData(oldIndex.itemIndex)
            } else {
                return false;
            }
        }

        return !invokeResult.isCancel;
    }

    /**
     * @internal
     * 리얼그리드 onCurrentChanged 핸들러
     */
    private handleCurrentChanged(grid: any, newIndex: any): void {
        this.invokeEvent<Events.AfterSelectChangeEventArgs>(
            this.onAfterSelectChange,
            new Events.AfterSelectChangeEventArgs(this, newIndex.itemIndex, newIndex.column, this._isRowChanged));

        if (this._isRowChanged === true) {
            this.invokeEvent<Events.AfterSelectChangeEventArgs>(
                this.onAfterChangeRowSelection,
                new Events.AfterSelectChangeEventArgs(this, newIndex.itemIndex, newIndex.column, this._isRowChanged));
        }

        this.invokeEvent<Events.AfterDataChangeEventArgs>(
            this.onAfterDataChanged,
            new Events.AfterDataChangeEventArgs(this, [newIndex.column], Events.GridDataChangeAction.SELECTION_CHANGED));
    }

    /**
     * 리얼그리드 onShowTooltip 이벤트 콜백
     */
    // private handleOnShowTooltip(grid: any, index: any, value: string): string {
    //     if (!this.columns) {
    //         throw new Error("OBTListGridInterface.handleOnShowTooltip: columns can't be null");
    //     }

    //     const targetColumn = this.getColumnByName(index.column);

    //     let resultTooltipText: string = "";
    //     if (targetColumn) {
    //         const eventArgs = new Events.ShowTooltipEventArgs(
    //             this,
    //             index.column,
    //             index.itemIndex,
    //             this.getRow(index.itemIndex),
    //             ""
    //         );

    //         this.invokeEvent<Events.ShowTooltipEventArgs>(
    //             this.onShowTooltip,
    //             eventArgs);

    //         resultTooltipText = eventArgs.tooltipText;
    //     }

    //     return resultTooltipText;
    // }

    /**
     * @internal
     * 리얼그리드 onShowTooltip 이벤트 콜백
     */
    public handleMouseHover(grid: any, index: any, value: string): string {
        if (!this.columns) {
            throw new Error("OBTListGridInterface.handleMouseHover: columns can't be null");
        }

        const targetColumn = this.getColumnByName(index.column);

        let resultTooltipText: string = "";
        const eventArgs = new Events.MouseHoverEventArgs(
            this,
            index.column,
            index.itemIndex,
            this.getRow(index.itemIndex),
            "",
            targetColumn && targetColumn.style && targetColumn.style.textWrap === 'ellipse' ? true : false,
            300
        );

        this.invokeEvent<Events.MouseHoverEventArgs>(
            this.onMouseHover,
            eventArgs);

        resultTooltipText = eventArgs.tooltipText;

        return resultTooltipText ? `<div style="max-width: ${eventArgs.maxTooltipWidth || 300}px; white-space: normal; word-wrap: break-word; box-sizing: border-box; padding: 4px 13px;">${resultTooltipText}</div>` : resultTooltipText;
    }

    /**
     * @internal
     * 리얼그리드 onInnerDragStart 이벤트 콜백
     * false를 지정하면 드래그가 취소된다.
     */
    public handleInnerDragStart(grid: any, dragCells: any): boolean {
        // endColumn: "cnt" endItem: 5 endRow: 5 startColumn: "title_nm" startItem: 5 startRow: 5
        const eventArgs = new Events.InnerDragStartEventArgs(
            this,
            dragCells.startRow,
            this.getRow(dragCells.startRow),
        );

        this.invokeEvent<Events.InnerDragStartEventArgs>(
            this.onInnerDragStart,
            eventArgs);

        return !eventArgs.cancel;
    }

    /**
     * @internal
     * 리얼그리드 onInnerDrop 이벤트 콜백
     */
    public handleInnerDrop(grid: any, dropIndex: any, dragCells: any): void {
        const eventArgs = new Events.InnerDropEventArgs(
            this,
            dragCells.startRow,
            this.getRow(dragCells.startRow),
            dropIndex.dataRow,
            this.getRow(dropIndex.dataRow),
        );

        this.invokeEvent<Events.InnerDropEventArgs>(
            this.onInnerDrop,
            eventArgs);


        if (!eventArgs.cancel && dragCells.startRow >= 0 && dropIndex.dataRow >= 0) {
            if (this._provider && this._provider._provider) {
                this._provider._provider.moveRow(dragCells.startRow, dropIndex.dataRow);
            }
            this.setSelection(dropIndex.dataRow);
        }
    }

    /**
     * @internal
     * @param grid 
     * @param itemIndex 
     * @param column 
     * @param buttonindex 
     * @param name 
     */
    private handleImageButtonClicked(grid: any, itemIndex: number, column: any, buttonindex: number, name: string) {
        this.onImageButtonClicked.events.forEach(event => {
            event(new Events.ImageButtonClickedEventArgs(this,
                itemIndex,
                column,
                buttonindex,
                name,
                this.getRow(itemIndex)));
        });
    }


    /**
     * @internal
     * 더블클릭 이벤트 핸들러
     */
    private handleDoublelClicked(grid: any, index: any) {
        this.onDoublelClicked.events.forEach(event => {
            event(new Events.DoubleClickedEventArgs(this,
                index.itemIndex,
                index.column,
                this.getRow(index.itemIndex)));
        });
    }

    /**
     * @internal
     * 클릭 이벤트 핻늘러
     * @param grid 
     * @param index 
     */
    private handleClicked(grid: any, index: any) {
        // if(this._gridView) {
        //     this._gridView.commit(false);
        // }

        this.onClicked.events.forEach(event => {
            event(new Events.ClickedEventArgs(this,
                index.itemIndex,
                index.column,
                this.getRow(index.itemIndex)));
        });
    }

    /**
     * @internal
     * invoke onColumnWidthChange
     * @param grid 
     * @param column 
     * @param property 
     * @param value 
     */
    private handleColumnPropertyChanged(grid: any, column: any, property: string, value: any): void {
        if (property === 'width') {
            // 컬럼의 너비가 변할때 이벤트 발행
            this.invokeEvent<Events.ColumnWidthChangeEventArgs>(
                this.onColumnWidthChanged,
                new Events.ColumnWidthChangeEventArgs(this, column.name, value)
            );
        } else if (property === 'visible') {
            // 컬럼의 너비가 변할때 이벤트 발행
            this.invokeEvent<Events.ColumnWidthChangeEventArgs>(
                this.onColumnWidthChanged,
                new Events.ColumnWidthChangeEventArgs(this, column.name, 0)
            );
        }
    }

    public _invokeColumnWidthChangedWhenResize() {
        this.invokeEvent<Events.ColumnWidthChangeEventArgs>(
            this.onColumnWidthChanged,
            new Events.ColumnWidthChangeEventArgs(this, null, null, true)
        );
    }

    private handleTopItemIndexChanged(grid: any, itemIndex: number) {
        this.onScroll.events.forEach(event => {
            event(new Events.ScrollEventArgs(this, { grid, itemIndex }));
        });
    }

    /**
     * 컬럼에서 정렬이 발생할 때
     * @param grid 
     * @param fields 
     * @param directions 
     */
    private handleSorting(grid: any, fields: number[], directions: string[]) {
        if (!this.provider || !this.provider._provider) {
            return false;
        }

        const columnNames = fields.map(item => this.provider!._provider.getFieldName(item));
        const parameters = {
            target: this,
            columnName: columnNames.length === 0 ? null : columnNames[0],
            direction: directions.length === 0 ? null : directions[0],
            cancel: false,
        } as Events.SortingEventArgs;

        // 필드 세팅후 이벤트 호출
        this._dataSortType = parameters as Events.DataSortType;

        this.invokeEvent<Events.SortingEventArgs>(this.onSorting, parameters);

        return !parameters.cancel;
    }

    private handleLinkableCellClicked(grid: any, index: any, url: string) {
        this.invokeEvent<Events.LinkColumnClickedEventArgs>(
            this.onLinkableCellClicked,
            new Events.LinkColumnClickedEventArgs(this, index.itemIndex, index.column)
        );
    };

    private handleEditCommit(grid: any, index: any, oldValue: any, newValue: any) {

        this.invokeEvent<Events.EditCommitEventArgs>(
            this.onEditCommit,
            new Events.EditCommitEventArgs(this, index.itemIndex, index.column, oldValue, newValue)
        );
    }

    private handleCellEdited(grid, itemIndex, dataRow, field) {
        if (this._gridView) {
            this._gridView.commit(true);
        }
    }

    /**
 * @internal
 * @param grid 
 * @param index 
 */
    private handleShowEditor(grid: any, index: any): boolean {
        try {
            const targetColumn = this.getColumnByName(index.column);
            if (!targetColumn) {
                throw new Error("컬럼을 찾을수 없습니다. : " + index.column);
            }

            if (this.isColumnEditable(targetColumn, index.itemIndex) === false) {
                return false;
            }

            return true;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    // internal public 내부적으로 쓰이는 목적의 public 메소드들

    /**
     * @ignore
     */
    public _handleFocus() {
        if (this._hasFocus === false) {
            this._hasFocus = true;
            this._handleFocusChanged();
        }
    }

    /**
     * @ignore
     */
    public _handleBlur(wrapperRef: any) {
        const isParentEqualsThis = (parent: Element | null): boolean => {
            if (parent) {
                if (parent === wrapperRef.current) return true;
                else if (parent.parentElement) return isParentEqualsThis(parent.parentElement);
            }
            return false;
        }
        if (!isParentEqualsThis(document.activeElement)) {
            this._hasFocus = false;
            this._handleFocusChanged();
        }
    }

    /**
     * @internal
     * @ignore
     */
    private _handleFocusChanged() {
        if (this.isReady) {
            // 리스트그리드에선 선택포커스 제거
            this._setFocusStyle(this._gridOption.useFocusStyle === false ? false : true);
            if (!this._hasFocus) {
                if (this._gridView.isItemEditing()) {
                    this._gridView.cancelEditor();
                }
            }
        }
    }

    /**
     * @internal
     * @param merges 
     */
    private static deepMerge(...merges: any[]): any {
        let result: any = {};
        merges.forEach(merge => {
            if (merge) {
                Object.keys(merge).forEach(key => {
                    if (merge[key] && result[key] && (Object.keys(merge[key] || {}).length > 0 || Object.keys(result[key] || {}).length > 0)) {
                        result[key] = this.deepMerge(result[key], merge[key])
                    } else {
                        result[key] = merge[key];
                    }
                });
            }
        });
        return result;
    }
}
