import React, { Component, Fragment } from 'react';
import { v4 as uuid } from 'uuid';
import memoizeOne from 'memoize-one';

export default class MainIntroBg extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stars: [...new Array(300)].map(i => uuid())
        }
    }

    getStars = memoizeOne((stars) => {
        return stars.map(key => <div className="star" key={key} />);
    });

    render() {
        const stars = this.getStars(this.state.stars);
        return (
            <Fragment>
                {/* 메인 배경 */}
                <div className="orbitGalaxyBg">
                    <div className="orbitCloud">
                        <div className="orbitCluster">
                            {stars}
                            {/* <div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" />
                            <div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" />
                            <div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" />
                            <div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" />
                            <div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" />
                            <div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" />
                            <div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" />
                            <div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" />
                            <div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" />
                            <div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" />
                            <div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" />
                            <div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" />
                            <div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" />
                            <div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" />
                            <div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" />
                            <div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /><div className="star" /> */}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
