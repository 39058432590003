import IBuiltInCodePicker, { CodePickerSize } from "./DataSource/IBuiltInCodePicker";

/**
 * CodePicker관련 유틸 클래스
 */
export default class CodePickerUtil {

    /**
     * IBuiltInCodePicker를 기반으로 그리드의 실제적인 너비나 길이를 분석해 리턴한다.
     * @param widthOrHeight 
     * @param codePicker 
     * @returns 실제적인 width나 height값
     */
    public static getDialogSize(widthOrHeight: "width" | "height", codePicker: IBuiltInCodePicker): string {
        const dialogSize = {
            small:
                { width: "560px", height: '560px' },
            medium:
                { width: "560px", height: '600px' },
            default:
                { width: "560px", height: '600px' },
            large:
                { width: "700px", height: '850px' },
            largeBig:
                { width: "850px", height: '850px' },
        }

        if (codePicker.size === CodePickerSize.custom) {
            if (!codePicker.dialogWidth || !codePicker.dialogHeight) {
                return dialogSize[CodePickerSize.default][widthOrHeight];
            }

            if (widthOrHeight === "width") {
                return codePicker.dialogWidth;
            }
            if (widthOrHeight === "height") {
                return codePicker.dialogHeight;
            }
        }

        return dialogSize[codePicker.size][widthOrHeight];
    }

    public static trimObject(object) {
        if (!object) {
            return object;
        }

        const isObjectParameterProperty = typeof object === 'object' && object['parameters'] && typeof object['parameters'] === 'object';

        if (isObjectParameterProperty) {
            const parameters = object['parameters'];
            Object.keys(parameters).forEach(key => {
                if (typeof parameters[key] === 'string') {
                    parameters[key] = String(parameters[key]).trim()
                }
            })
        }

        const hasKeywordProperty = typeof object === 'object' && object['keyword'] && typeof object['keyword'] === 'string';
        if (hasKeywordProperty) {
            object['keyword'] = String(object['keyword']).trim()
        }

        return object;
    }
}
