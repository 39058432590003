/**
 * OBTReferencePanel
 * @version 0.1
 * @author 안광진
 * @see common.js
 */

import * as React from 'react';
import { Events, CompositeProps, Util, CommonProps, createPropDefinitions, CommonDefinitions, CommonType } from '../Common';
import infoImg from '../Images/icon-panel-info.png';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
const styles = require('./OBTReferencePanel.module.scss');

interface IOBTReferencePanel extends CompositeProps.Default, CommonProps.value<boolean>, Events.onChange<boolean> {
    /**
     * 열림 / 닫힘 버튼 사용 여부를 정의하는 속성입니다.
     */
    button: boolean,
    /**
     * 제목을 정의하는 속성입니다.
     */
    title: string,
}

/**
 * State 정의
 */
interface State extends hasError {
}

export default class OBTReferencePanel extends React.Component<IOBTReferencePanel, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        { name: 'title', type: CommonType.string, description: "제목을 정의하는 속성입니다." },
        { name: 'value', type: CommonType.boolean, description: '콤포넌트 내용의 열림 / 닫힘 여부를 정의하는 속성입니다.', default: false, optional: true },
        CommonDefinitions.onChange({ optional: true }),
        { name: 'button', type: CommonType.boolean, description: '우측에 화살표 모양의 버튼을 사용할 여부를 정의하는 속성입니다.\n(열림 / 닫힘 버튼 사용 여부를 정의하는 속성)', default: false, optional: true },
    );

    public static defaultProps = {
        frozen: false,
        button: false,
        value: false,
    };

    public state: State = {};

    private handleChange = (value: boolean, e: any): void => {
        Util.invokeEvent<Events.ChangeEventArgs<boolean>>(this.props.onChange, new Events.ChangeEventArgs<boolean>(this, !value));

    }

    renderComponent = () => {
        const props = {
            id: this.props.id,
            'data-orbit-component': 'OBTReferencePanel',
            style: Util.getWrapperStyle(this.props),
        }
        const title = this.props.title ?
            <>
                <span className={styles.icon}><img src={infoImg} alt='info'></img></span>
                <span className={styles.title}>{this.props.title}</span>
            </>
            : <div className={styles.notitle}> {this.props.children} </div>
        const button = <button onClick={this.handleChange.bind(this, this.props.value)}></button>
        // const button = <button onClick={(e) => this.handleChange(this.props.value)}></button>
        const component = <div className={Util.getClassNames(styles.referboxWrapper, this.props.className, this.props.value ? null : styles.closed)} {...props}>
            <div style={{
                borderRadius: this.props.title && this.props.value ? '3px 3px 0px 0px' : '3px',
                borderBottom: this.props.title && this.props.value ? 'none' : '1px solid #d4dfe7'
            }}
                className={this.props.title && !this.props.children ? styles.nochildreferbox : this.props.title ? styles.referbox : styles.noreferbox}>
                {title}
                {this.props.button ? button : <></>}
                {this.props.title && this.props.children ? <div className={styles.content}>{this.props.children}</div> : <></>}
            </div>
        </div>
        return <>{component}</>
    }

    render() {
        return <ErrorBoundary owner={this} render={this.renderComponent} />
    }
}
