/**
 * @version 0.1
 * @author 하성준
 * @see common.js
 */
import * as React from 'react';
import { CompositeProps, Util, CommonProps, Events, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import saveImage from './img/icon-con-save.png'
import cancelImage from './img/icon-con-cancel.png'
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import emptyDataImgSmall from '../Images/img_empty_data_s.png'
import OBTTreeViewItem from './OBTTreeViewItem';
import Input from './Input';
import { OrbitInternalLangPack } from '../Common/Util';

const styles = require('./OBTTreeView.module.scss');

export enum Type {
    'default' = 'default',
    'folder' = 'folder',
    'directory' = 'directory'
}

enum CheckBoxOption {
    'default' = 'default',
    'asymmetric' = 'asymmetric'
}

export interface IList {
    key: string,
    index?: number[],
    labelText: string | JSX.Element,
    editorValue: string | any,
    parentKey?: string,
    imageUrl?: string | IimageUrl,
    icon?: any | Iicon,
    collapsed?: boolean,
    checked?: boolean,
    visible?: boolean,
    checkBoxVisible?: boolean,
    disabled?: boolean,
    checkBoxDisabled?: boolean,
    visibleCollapsedImage?: boolean,
    children?: IList[],
    childCount?: boolean,
    originalItem?: any,
    tooltip?: any,
    style?: any,
    _childrenCount?: number,
    isHovered?: boolean
}

export interface IimageUrl {
    normal: { open?: string, close: string },
    disabled: { open?: string, close?: string }
}

export interface Iicon {
    normal: { open?: any, close: any },
    disabled: { open?: any, close?: any }
}

class MapItemEventArgs extends Events.EventArgs {
    constructor(public readonly list: any, public item?: IList) {
        super(null);
    }
}

class CollapseEventArgs extends Events.EventArgs {
    constructor(target: any, public item: any, public collapsed: boolean, public event?: React.MouseEvent) {
        super(target)
    }
}

class CheckedEventArgs extends Events.EventArgs {
    constructor(target: any, public items: any, public checked: boolean) {
        super(target)
    }
}

class AfterSelectEventArgs extends Events.EventArgs {
    constructor(target: any, public item: any) {
        super(target)
    }
}

class EditLabelTextArgs extends Events.EventArgs {
    constructor(target: any, public item: any, public labelText: string) {
        super(target)
    }
}

export class ChangeLabelTextArgs extends Events.EventArgs {
    constructor(target: any, public value: string, public cancel: boolean, public tooltip: any | null) {
        super(target)
    }
}

class EditSortArgs extends Events.EventArgs {
    constructor(target: any, public selectedItem: any, public changedItem: any) {
        super(target)
    }
}

class OnMouseEnterArgs extends Events.EventArgs {
    constructor(target: any, public item: any) {
        super(target)
    }
}

class OnMouseDownArgs extends Events.EventArgs {
    constructor(target: any, public item: any) {
        super(target)
    }
}

interface IOBTTreeView extends CompositeProps.Default, CommonProps.labelText, CommonProps.disabled {
    /**
     * @default default
     * 펼침 닫힘을 표시하는 이미지입니다.
     */
    type?: Type,

    /**
     * 컴포넌트에 들어가는 값 입니다.
     */
    list: any[],

    /**
     * @default default
     * 전체 요소들의 체크박스 표시 여부입니다.
     */
    checkBox?: boolean,

    /**
     * 맨 하위 요소들의 개수 표시 여부입니다.
     */
    childCount?: boolean,

    /**
     * 현재 선택된 아이템 입니다.
     */
    selectedItem?: any,

    /**
     * 	true일 때 textField(내용수정)가 활성화 됩니다.
     */
    editLabelText?: boolean,

    /**
     *  내용을 수정할 때 오른쪽 확인, 취소 버튼 표시여부 입니다.
     */
    editLabelTextButtonsVisible?: boolean

    /**
     * 수정 모드 일 때 required 여부입니다.
     */
    editLabelTextRequired?: boolean,

    /**
     * textField(내용수정)의 툴팁 설정입니다.
     */
    editLabelTextTooltip?: any,

    /**
     * true일 때 drag를 통한 자리이동(정렬)이 활성화 됩니다.
     */
    editSort?: boolean,

    /**
     * 각 요소의 오른쪽에 나올 항목들을 지정할 수 있습니다.
     * 항목들은 선택된 상태에서 over될 시에 나타납니다.
     */
    images?: Array<any>,

    /**
     *  부모 자식간의 체크상태 연관성에 관한 옵션입니다.
     *  - asymmetric: 자식 체크박스가 true가 아니여도 부모 체크박스를 true로 할 수 있습니다.
     */
    checkBoxOption?: CheckBoxOption,

    /**
     *  '데이터가 없을때 텍스트 커스텀',
     */
    emptyDataMsg?: string,

    /**
     * @default 데이터가존재하지않습니다.
     *  '데이터가 없을때 이미지 커스텀',
     */
    emptyDataImage?: string | JSX.Element,

    /**
     * 텍스트가 길어져서 말줄임 처리 될 때 툴팁을 띄우는 옵션
     */
    useOverflowTooltip?: boolean

    /**
     * 요소가 선택 될 때 발생하는 callback 함수 입니다.
     */
    onAfterSelectChange?: (e: AfterSelectEventArgs) => void,

    /**
     * 해당 요소의 체크박스를 클릭할 때 발생하는 callback 함수입니다.
     */
    onCheckChanged?: (e: CheckedEventArgs) => void,

    /**
     * 해당 요소가 접히거나 펼쳐질 때 발생하는 callback 함수 입니다.
     */
    onCollapseChanged?: (e: CollapseEventArgs) => void,

    /**
     * 데이터를 treeView 형식에 맞게 변환해 주기 위한 함수입니다.
     */
    onMapItem?: (e: MapItemEventArgs) => void,

    /**
     * editLabelText의 상태가 true 일 때 수정된 사항을 반환해주는 함수입니다.
     */
    onEditLabelText?: (e: EditLabelTextArgs) => void,

    /**
     * editLabelText의 상태가 true 일 때 수정된 사항을 반환해주는 함수입니다.
     */
    onChangeLabelText?: (e: ChangeLabelTextArgs) => void,

    /**
     * editLabelText의 상태가 true 일 때 textField에서 포커스가 Blur 될 때 발생하는 함수입니다.
     */
    onEditLabelTextBlur?: () => void,

    /**
     * editSort가 true 일 때 자리 이동(정렬)이 일어날 경우 발생하는 함수입니다.
     */
    onEditSort?: (e: EditSortArgs) => void,

    /**
     * 요소에 마우스가 Enter 될때 호출되는 함수입니다.
     */
    onMouseEnter?: (e: OnMouseEnterArgs) => void,

    /**
     * 요소를 마우스가 더블 클릭 할때 호출되는 함수입니다.
     */
    onDoubleClick?: (e: OnMouseEnterArgs) => void,

    /**
      * 요소를 마우스가 클릭 할때 호출되는 함수입니다.
      */
    onMouseDown?: (e: OnMouseDownArgs) => void,
    /**
     *  드래그가 끝낫을 때 호출되는 함수입니다.
     */
    onDragEnd?: (end: boolean) => void,
}

interface State extends hasError {
    list?: IList[], // 내부에서 쓸수 있도록 변형된 list
    propList?: any[], // 원본 list
    propSelectedItem?: string, // prop의 selectedItem의 원본 (key값만 존재)
    selectedItem?: IList // prop의 selectedItem로 선택된 요소의 list의 정보를 담는다. (key, index, labelText...등등)
    dragging?: boolean,
    draggingKey?: string,
    rootWidth?: string,
    imagesWidth: string,
    getImagesWidth: boolean
}

export default class OBTTreeView extends React.Component<IOBTTreeView, State> {
    public static IListDefinitions = createPropDefinitions(
        { name: "key", type: CommonType.string, description: "키" },
        { name: "labelText", type: ["string", "JSX.Element"], description: "항목 내용" },
        { name: "parentKey", type: CommonType.string, description: "부모키", optional: true },
        { name: "editorValue", type: CommonType.any, description: "편집모드로 진입시 에디터에 표시될 내용(lableText로 JSX 지정시 편집모드를 사용한다면 필수 설정)", optional: true },
        { name: "imageUrl", type: [CommonType.image, "{normal:{open:imageUrl, close:imageUrl}, disabled:{open:imageUrl, close:imageUrl}}"], description: "이미지, 혹은 상황에 맞는 아이콘(이미지)를 지정할 수 있습니다.", optional: true },
        { name: "icon", type: ["node", "{normal:{open:icon, close:icon}, disabled:{open:icon, close:icon}}"], description: "아이콘, 혹은 상황에 맞는 아이콘(이미지)를 지정할 수 있습니다.", optional: true },
        { name: "collapsed", type: CommonType.boolean, description: "펼침 접힘 선택", optional: true },
        { name: "checked", type: CommonType.boolean, description: "체크 여부 선택", optional: true },
        { name: "visible", type: CommonType.boolean, description: "해당 요소 표시 여부", optional: true },
        { name: "checkBoxVisible", type: CommonType.boolean, description: "체크박스 표시 여부", optional: true },
        { name: "disabled", type: CommonType.boolean, description: "해당 요소 disabled", optional: true },
        { name: "checkBoxDisabled", type: CommonType.boolean, description: "체크 박스 disabled", optional: true },
        { name: "visibleCollapsedImage", type: CommonType.boolean, description: "기본으로 제공되는 이미지 표시 여부", optional: true },
        { name: "children", type: "list", description: "하위 요소", optional: true },
        { name: "childCount", type: CommonType.boolean, description: "하위 요소 카운트 표시 여부", optional: true },
        CommonDefinitions.tooltip(),
        CommonDefinitions.style()
    );

    public static PropDefinitions = createPropDefinitions(
        { name: "list", type: ["IList"], description: "컴포넌트에 들어가는 값 입니다." },
        CommonDefinitions.Default(),
        CommonDefinitions.labelText(),
        CommonDefinitions.disabled(),
        {
            name: "type", type: toEnumType(Type), default: "default", description: "펼침 닫힘을 표시하는 이미지입니다. \n"
                + "default: 화살표, folder: 폴더, directory: 폴더와 같으나 최하위 항목도 디렉토리 아이콘으로 표시됨", optional: true
        },
        { name: "checkbox", type: CommonType.boolean, description: "선택된 요소 입니다.", optional: true },
        { name: "childCount", type: CommonType.boolean, description: "맨 하위 요소들의 개수 표시 여부 입니다.", optional: true },
        { name: "selectedItem", type: CommonType.string, description: "현재 선택된 아이템입니다.", optional: true },
        { name: "editLabelText", type: CommonType.boolean, description: "true일 때 textField(내용수정)가 활성화 됩니다.", optional: true },
        { name: "editLabelTextButtonsVisible", type: CommonType.boolean, description: "내용을 수정할 때 오른쪽 확인, 취소 버튼 표시여부 입니다.", optional: true },
        { name: "editLabelTextRequired", type: CommonType.boolean, description: "수정 모드 일 때 required 여부입니다.", optional: true },
        { name: "editLabelTextTooltip", type: CommonType.any, description: "textField(내용수정)의 툴팁 설정입니다.", optional: true },
        { name: "editSort", type: CommonType.boolean, description: "true일 때 drag를 통한 자리이동(정렬)이 활성화 됩니다.", optional: true },
        { name: "images", type: "Array<any>", description: "각 요소의 오른쪽에 나올 항목들을 지정할 수 있습니다. \n항목들은 선택된 상태에서 over될 시에 나타납니다.", optional: true },
        { name: "checkBoxOption", type: ["default", "asymmetric"], description: "부모 자식간의 체크상태 연관성에 관한 옵션입니다. \nasymmetric: 자식 체크박스가 true가 아니여도 부모 체크박스를 true로 할 수 있습니다.", optional: true },
        { name: "emptyDataMsg", type: CommonType.string, description: "데이터가 없을때 텍스트 커스텀", optional: true },
        { name: "emptyDataImage", type: ["string", "JSX.Element"], description: "데이터가 없을때 이미지 커스텀", optional: true },
        {
            name: "onAfterSelectChange", type: CommonType.function, parameters: {
                name: "e",
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    item: { type: CommonType.any, description: "해당요소" }
                }
            }, description: "요소가 선택 될 때 발생하는 callback 함수 입니다.", optional: true
        },
        {
            name: "onCheckChanged", type: CommonType.function, parameters: {
                name: "e",
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    items: { type: CommonType.any, description: "해당요소" },
                    checked: { type: CommonType.boolean, description: "값" }
                }
            }, description: "해당 요소의 체크박스를 클릭할 때 발생하는 callback 함수입니다.", optional: true
        },
        {
            name: "onCollapseChanged", type: CommonType.function, parameters: {
                name: "e",
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    item: { type: CommonType.any, description: "해당요소" },
                    collapsed: { type: CommonType.boolean, description: "값" }
                }
            }, description: "해당 요소가 접히거나 펼쳐질 때 발생하는 callback 함수 입니다.", optional: true
        },
        {
            name: "onMapItem", type: CommonType.function, parameters: {
                name: "e",
                type: {
                    list: { type: CommonType.any, description: '원본요소' },
                    item: { type: CommonType.any, description: "변환요소" },
                }
            }, description: "데이터를 treeView 형식에 맞게 변환해 주기 위한 함수입니다.", optional: true
        },
        {
            name: "onEditLabelText", type: CommonType.function, parameters: {
                name: "e",
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    item: { type: CommonType.any, description: "해당요소" },
                    labelText: { type: CommonType.string, description: "변환값" }
                }
            }, description: "editLabelText의 상태가 true 일 때 수정된 사항을 반환해주는 함수입니다.", optional: true
        },
        { name: "onEditLabelTextBlur", type: CommonType.function, description: "editLabelText의 상태가 true 일 때 textField에서 포커스가 Blur 될 때 발생하는 함수입니다.", optional: true },
        {
            name: "onChangeLabelText", type: CommonType.function, parameters: {
                name: "e",
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    cancel: { type: CommonType.boolean, description: '입력 취소 여부' },
                    tooltip: { type: CommonType.any, description: "labelText, theme등 툴팁 지정가능" },
                }
            }, description: "editSort가 true 일 때 자리 이동(정렬)이 일어날 경우 발생하는 함수입니다.", optional: true
        },
        {
            name: "onEditSort", type: CommonType.function, parameters: {
                name: "e",
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    selectedItem: { type: CommonType.any, description: '선택요소' },
                    changedItem: { type: CommonType.any, description: "변환요소" },
                }
            }, description: "editSort가 true 일 때 자리 이동(정렬)이 일어날 경우 발생하는 함수입니다.", optional: true
        },
        CommonDefinitions.onMouseEnter(),
        CommonDefinitions.onMouseDown(),
        CommonDefinitions.onDblClick(),
        {
            name: "onDragEnd", type: CommonType.function, parameters: {
                name: "end",
                type: CommonType.boolean
            }, description: "드래그가 끝났을 때 호출되는 함수입니다.", optional: true
        },
        {
            name: "collapse", type: CommonType.function, parameters: [
                { name: "key", type: CommonType.string, description: "collapse 명령을 수행할 대상. undefined 라면 전체" },
                { name: "collapse", type: CommonType.boolean, description: "접기 여부(false라면 expand) → default: true" }
            ], optional: true
        },
        {
            name: "expand", type: CommonType.function, parameters: [
                { name: "key", type: CommonType.string, description: "expand 명령을 수행할 대상. undefined 라면 전체" },
                { name: "expand", type: CommonType.boolean, description: "펼치기 여부(false라면 collapse) → default: true" }
            ], optional: true
        },
    );

    public static defaultProps = {
        type: Type.default,
        checkBoxOption: CheckBoxOption.default,
        frozen: false,
        disabled: false,
        emptyDataMsg: '데이터가 존재하지 않습니다.',
        emptyDataImage: emptyDataImgSmall

    }

    public state: State = {
        hasError: false,
        getImagesWidth: false,
        imagesWidth: '0'
    }

    public myRefs = {
        editLabelTextRef: React.createRef<Input>(),
        root: React.createRef<HTMLDivElement>(),
        images: React.createRef<HTMLSpanElement>()
    }

    public static Type = Type;
    public static CheckBoxOption = CheckBoxOption;

    static getDerivedStateFromProps(nextProps: IOBTTreeView, prevState: State): any {
        const setSelection = (list?: IList[], prevSelectedItem?: string, selectedItem?: string) => {
            return OBTTreeView.updateItem(prevSelectedItem ? OBTTreeView.updateItem(list, prevSelectedItem) : list, selectedItem, {}, { collapsed: false });
        };

        const state: any = {};

        if (nextProps.list !== prevState.propList) {
            const mappedList = setSelection(
                OBTTreeView.getMappedList(nextProps.list, prevState.list, nextProps.onMapItem),
                prevState.propSelectedItem, nextProps.selectedItem
            );
            state.list = mappedList;
            state.propList = nextProps.list;
            state.propSelectedItem = nextProps.selectedItem;
            state.selectedItem = OBTTreeView.getItem(mappedList, nextProps.selectedItem);
        }
        else if (nextProps.selectedItem !== prevState.propSelectedItem) {
            const newList = setSelection(prevState.list, prevState.propSelectedItem, nextProps.selectedItem);
            state.list = newList;
            state.propSelectedItem = nextProps.selectedItem;
            state.selectedItem = OBTTreeView.getItem(newList, nextProps.selectedItem);
        }

        if (Object.keys(state).length > 0) {
            return state;
        }
        return null;
    }

    componentDidMount() {
        (async () => {
            try {
                const state: any = {};
                if (this.myRefs.root.current) {
                    state.rootWidth = String(this.myRefs.root.current.getBoundingClientRect().width);
                }

                // if (this.myRefs.images.current && this.props.images && this.state.getImagesWidth === false) {

                //     state.getImagesWidth = true;
                //     if (this.myRefs.root.current && this.myRefs.root.current.clientHeight <= 0) {
                //         state.getImagesWidth = false;
                //     }

                //     state.imagesWidth = String(this.myRefs.images.current.getBoundingClientRect().width + 35);
                //     console.log('state.imagesWidth', state.imagesWidth)
                // }

                if (Object.keys(state).length > 0) {
                    await new Promise<void>((resolve) => this.setState({
                        ...state
                    }, () => resolve()));
                }

                if (this.myRefs.root.current && this.props.selectedItem) {
                    this.scrollPosition();
                }
            } catch (e) {
                Util.handleError(this, e);
            }
        })();
    }

    componentDidUpdate(prevProps: IOBTTreeView, prevState: State) {
        (async () => {
            try {

                if (this.props.editLabelText === true && prevProps.editLabelText === true && this.state.selectedItem !== prevState.selectedItem) {
                    await this.cancelEdit();
                }

                // if (this.myRefs.images.current && this.props.images && this.state.getImagesWidth === false) {
                // if (this.myRefs.images.current && this.props.images) {
                //     const iamgesWidth = String(this.myRefs.images.current.getBoundingClientRect().width + 35);

                //     if(this.state.imagesWidth !== iamgesWidth) {
                //         await new Promise<void>(resolve => this.setState({
                //             getImagesWidth: true,
                //             imagesWidth: iamgesWidth
                //         }, () => resolve()));
                //     }
                // }

                if (prevProps.images !== this.props.images) {
                    await new Promise<void>(resolve => this.setState({
                        getImagesWidth: false,
                    }, () => resolve()));
                } else if (this.myRefs.images.current && this.props.images && this.state.getImagesWidth === false) {
                    const iamgesWidth = String(this.myRefs.images.current.getBoundingClientRect().width + 35);

                    await new Promise<void>(resolve => this.setState({
                        getImagesWidth: true,
                        imagesWidth: iamgesWidth,
                    }, () => resolve()));
                }

                if (this.myRefs.root.current) {
                    const scrollTop = this.myRefs.root.current.scrollTop;
                    this.myRefs.root.current.scrollTop = scrollTop;

                    if (this.state.rootWidth !== String(this.myRefs.root.current.getBoundingClientRect().width)) {
                        const rootWidth = String(this.myRefs.root.current.getBoundingClientRect().width);
                        await new Promise<void>(resolve => this.setState({
                            rootWidth: rootWidth
                        }, () => resolve()));
                    }
                }

                if (this.props.selectedItem !== prevProps.selectedItem) {
                    this.scrollPosition();
                }
            } catch (e) {
                Util.handleError(this, e);
            }
        })();
    }

    private static getCachedList = (list?: IList[], oldList?: IList[]) => {
        const applyCache = (list: IList[], oldList?: IList[], apply?: { updated: number }) => {
            return list.map(item => {
                const found = oldList ? oldList.find(oldItem => oldItem.key === item.key) : null;
                if (found) {
                    let different = found ? (Object.keys(item)
                        .filter(key => !['index', 'children'].includes(key))
                        .find(key => item[key] !== found[key]) ? true : false) : true;
                    if ((item.children || []).length !== (found.children || []).length) {
                        different = true;
                    }

                    if (item.children && found.children) {
                        const childApply = { updated: 0 };
                        const children = applyCache(item.children, found.children, childApply);
                        if (childApply.updated > 0) {
                            if (childApply.updated === children.length && !different) {
                                if (apply) {
                                    apply.updated = apply.updated + 1;
                                }
                                return found;
                            }
                            return {
                                ...item,
                                children
                            };
                        }
                    } else {
                        if (!different) {
                            if (apply) {
                                apply.updated = apply.updated + 1;
                            }
                            return found;
                        }
                    }
                }

                return item;
            });
        };

        return list && oldList ? applyCache(list, oldList) : list;
    }

    private static getMappedList = (list?: any[], oldList?: IList[], onMapItem?: (e: MapItemEventArgs) => void) => {
        const calculationCount = (list?: IList[]) => {
            return list ? list.map(item => {
                if (item.children && item.children.length > 0) {
                    item.children = calculationCount(item.children);
                    item._childrenCount = item.children ? item.children
                        .map(child => child._childrenCount)
                        .reduce((accu, curr) => (accu || 0) + (curr || 0), 0) : 0;
                } else {
                    item._childrenCount = 1;
                }
                return item;
            }) : list;
        }
        let mappedList = (() => {
            if (list) {
                const getMappedList = (list: any[], hasChildren: { has: boolean }, parentKey?: string) => list.map(item => {
                    const e = new MapItemEventArgs(item);
                    const mappedItem = (() => {
                        if (onMapItem) {
                            onMapItem(e);
                            return e.item;
                        }
                        return item;
                    })();

                    return {
                        ...(mappedItem || {}),
                        parentKey: mappedItem.parentKey || parentKey,
                        originalItem: item,
                        children: (() => {
                            if (mappedItem && mappedItem.children && mappedItem.children.length > 0) {
                                hasChildren.has = true;
                                return getMappedList(mappedItem.children, hasChildren, mappedItem.key);
                            }
                            return mappedItem.children;
                        })()
                    } as IList;
                });

                const hasChildren = { has: false };
                const mappedList = getMappedList(list, hasChildren);

                const toList = (item: IList, ...parentIndex: number[]) => {
                    item.index = parentIndex;
                    if (item.children && item.children.length > 0) {
                        item.children = item.children.map((child, index) => toList(child, ...parentIndex, index));
                    }
                    return item;
                };

                if (!hasChildren.has) {
                    mappedList.forEach(item => {
                        const children = mappedList.filter(child => child.parentKey === item.key);
                        item.children = children && children.length > 0 ? children : undefined;
                    });
                    const rootNode = mappedList.filter(item => {
                        return !item.parentKey || !mappedList || !mappedList.find(parent => parent.key === item.parentKey);
                    });
                    return rootNode.map((item, index) => {
                        return toList(item, index);
                    });
                } else {
                    return mappedList.map((item, index) => {
                        return toList(item, index);
                    });
                }
            }
        })();

        if (mappedList && oldList) {
            const setOldState = (list: IList[], oldList?: IList[], updated?: { updated: boolean }) => {
                return list.map(item => {
                    const found = oldList ? oldList.find(oldItem => oldItem.key === item.key) : null;
                    if (found) {
                        if ((item.collapsed === undefined && item.collapsed !== found.collapsed) ||
                            (item.checked === undefined && item.checked !== found.checked)) {
                            if (updated) {
                                updated.updated = true;
                            }
                            item = {
                                ...item,
                                collapsed: item.collapsed === undefined ? found.collapsed : item.collapsed,
                                checked: item.checked === undefined ? found.checked : item.checked
                            };
                        }



                        if (item.children && found.children) {
                            const childUpdated = { updated: false };
                            const children = setOldState(item.children, found.children, childUpdated);
                            if (childUpdated.updated) {
                                if (updated) {
                                    updated.updated = true;
                                }
                                item = {
                                    ...item,
                                    children: children
                                }
                            }
                        }
                    }
                    return item;
                });
            };

            mappedList = setOldState(mappedList, oldList);
        }

        return OBTTreeView.getCachedList(calculationCount(mappedList), oldList);
    };

    private setList = (list?: IList[], selectedItem?: string) => {
        return new Promise<void>((resolve) => {
            const currentSelectedItem = this.state.selectedItem ? OBTTreeView.getItem(list, this.state.selectedItem.key) : undefined;
            const nextSelectedItem = (selectedItem ? OBTTreeView.getItem(list, selectedItem) : currentSelectedItem) || currentSelectedItem;
            this.setState({
                list: list,
                selectedItem: nextSelectedItem
            }, () => {
                if (nextSelectedItem) {
                    this.setSelectedItem(nextSelectedItem);
                }
                resolve();
            })
        });
    }

    private static getItem = (list: IList[] | undefined, itemKey: string) => {
        const getItem = (list: IList[]): IList[] => {
            return (list.flatMap(item => {
                if (item.key === itemKey) {
                    return item;
                } else if (item.children && item.children.length > 0) {
                    return getItem(item.children);
                }
                return null;
            }).filter(item => item) || []) as IList[];
        };
        const founds = list ? getItem(list) : undefined;
        return founds && founds.length > 0 ? founds[0] : undefined;
    }

    private static updateItem = (
        list: IList[] | undefined,
        updateItemKey?: string,
        update?: any,
        parentUpdate?: any,
        updated?: { updated: boolean }
    ) => {
        if (!list || !updateItemKey) return list;

        return list ? list.map(item => {
            if (item.key === updateItemKey) {
                if (updated) {
                    updated.updated = true;
                }
                return {
                    ...item,
                    ...(update || {})
                };
            }
            if (item.children && item.children.length > 0) {
                const childUpdated = { updated: false };
                const children = OBTTreeView.updateItem(item.children, updateItemKey, update, parentUpdate, childUpdated);
                if (childUpdated.updated) {
                    if (updated) {
                        updated.updated = true;
                    }
                    return {
                        ...item,
                        children: children,
                        ...(parentUpdate || {})
                    };
                }
            }
            return item;
        }) : list;
    }

    private static updateItems = (list: IList[] | undefined, update?: any) => {
        return list ? list.map(item => {
            return {
                ...item,
                ...(update || {}),
                children: item.children ? OBTTreeView.updateItems(item.children, update) : item.children
            };
        }) : list;
    };

    private static isParentCollapsed = (list: IList[] | undefined, itemKey: string) => {
        if (!list) return false;
        const findItem = (list: IList[], ...parentItem: IList[]) => {
            return list.find(item => {
                if (item.key === itemKey) {
                    if (parentItem.find(parent => OBTTreeView.isCollapsed(parent.collapsed)))
                        return true;
                }
                if (item.children && item.children.length > 0) {
                    return findItem(item.children, ...parentItem, item);
                }
                return false;
            });
        }
        return findItem(list) ? true : false;
    }

    private static getIndexList = (list: IList[] | undefined): IList[] => {
        return (list ? list.flatMap(item => {
            return [item.visible !== false && item.disabled !== true ? item : null].concat(
                item.collapsed === false && item.children && item.children.length > 0 ? OBTTreeView.getIndexList(item.children) : []
            );
        }).filter(item => item) : []) as IList[];
    }

    private static getItems = (list: IList[] | undefined, checker: (item: IList) => boolean) => {
        const getItems = (list: IList[]): IList[] => {
            return (list.flatMap(item => {
                return [checker(item) ? item : null].concat(item.children && item.children.length > 0 ? getItems(item.children) : []);
            }).filter(item => item) || []) as IList[];
        };
        return list ? getItems(list) : [] as IList[];
    }

    private getEditor = (item: IList) => {
        const visibleCollapsed_default = 14;
        const visibleCollapsed_folder = 21;
        const checkBoxVisible = 18;

        let maxWidth = this.state.rootWidth && item.index ?
            'calc(' + this.state.rootWidth + 'px - ' + String(
                (item.index.length) * 10 +
                (item.visibleCollapsedImage === false ? item.imageUrl || item.icon ? 0 : this.props.type === Type.default ? visibleCollapsed_default : visibleCollapsed_folder : 0) +
                (item.checkBoxVisible === true || this.props.checkBox ? checkBoxVisible : 0) +
                (item.children || this.props.type === Type.directory ? 0 : this.props.type === Type.default ? this.props.checkBox ? 0 : visibleCollapsed_default : (item.imageUrl || item.icon) ? 0 : this.props.checkBox ? 0 : visibleCollapsed_folder)
            ) + 'px - 100px)' : undefined

        if (this.state.rootWidth && item.index) {
            const rootWidth = Number(this.state.rootWidth);
            const left = Number(
                (item.index.length) * 10 +
                (item.visibleCollapsedImage === false ? item.imageUrl || item.icon ? 0 : this.props.type === Type.default ? visibleCollapsed_default : visibleCollapsed_folder : 0) +
                (item.checkBoxVisible === false && this.props.checkBox ? checkBoxVisible : 0) +
                (item.children || this.props.type === Type.directory ? 0 : this.props.type === Type.default ? this.props.checkBox ? 0 : visibleCollapsed_default : (item.imageUrl || item.icon) ? 0 : this.props.checkBox ? 0 : visibleCollapsed_folder)
            )
            const extra = 100

            if ((rootWidth - left - extra) <= 0) {
                maxWidth = '3px';
            }
        }

        return <>
            <Input
                className={this.props.editLabelTextRequired === true ? styles.required : null}
                ref={this.myRefs.editLabelTextRef}
                onChange={(e) => {
                    if (this.props.onChangeLabelText) {
                        const eventArgs = new ChangeLabelTextArgs(this, e.value, e.cancel, e.tooltip);

                        this.props.onChangeLabelText(eventArgs);

                        e.tooltip = eventArgs.tooltip;
                        e.cancel = eventArgs.cancel;
                    }
                }}
                onKeyDown={e => this.handleInputKeyDown(e, item)}
                style={{
                    maxWidth: maxWidth
                }}
                editLabelTextRequired={this.props.editLabelTextRequired}
                editLabelTextTooltip={this.props.editLabelTextTooltip}
                value={typeof item.editorValue === 'object' ? item.editorValue.props.children : item.editorValue}
            />
            <img
                className={Util.getClassNames(styles.saveImage, this.props.editLabelTextButtonsVisible === false ? styles.saveImageNone : null)}
                src={saveImage}
                alt=''
                onMouseDown={e => { e.stopPropagation(); e.preventDefault(); }}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (this.myRefs.editLabelTextRef.current) {
                        this.saveEdit(item);
                    }
                }}
            />
            <img
                className={Util.getClassNames(styles.cancelImage, this.props.editLabelTextButtonsVisible === false ? styles.cancelImageNone : null)}
                src={cancelImage}
                alt=''
                onMouseDown={e => { e.stopPropagation(); e.preventDefault(); }}
                onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.cancelEdit();
                }}
            />
        </>;
    }

    private getTreeNode = (list: IList[]) => {
        return list.map((item) => {
            return <OBTTreeViewItem
                key={item.key}
                item={item}
                selectedItem={this.state.selectedItem}
                editLabelText={this.props.editLabelText}
                editSort={this.props.editSort}
                disabled={this.props.disabled}
                checkBox={this.props.checkBox}
                type={this.props.type}
                useOverflowTooltip={this.props.useOverflowTooltip}
                rootWidth={this.state.rootWidth}
                imagesWidth={this.state.imagesWidth}
                childCount={this.props.childCount}
                images={this.props.images}
                dragging={this.state.dragging}
                draggingKey={this.state.draggingKey}
                onMouseEnter={this.handleMouseEnter}
                onDragStart={this.handleDragStart}
                onDragEnter={this.handleDragEnter}
                onDragOver={this.handleDragOver}
                onDragEnd={this.handleDragEnd}
                onMouseDown={this.handleMouseDown}
                onCheckBoxClicked={this.setCheck}
                onToggleCollapse={this.toggleCollapse}
                onDoubleClick={this.handleItemDoubleClicked}
                onTextFieldBlur={this.cancelEdit}
                onGetEditor={this.getEditor}
            />;
        });
    };

    private scrollPosition = () => {
        if (this.myRefs.root.current && this.props.selectedItem) {
            const element = this.myRefs.root.current.querySelector(`div[data-tree-node-key="${this.props.selectedItem}"`);
            if (element) {
                const rootBoundary = this.myRefs.root.current.getBoundingClientRect();
                const boundary = element.getBoundingClientRect();
                const top = boundary.top - rootBoundary.top;
                const bottom = boundary.top + boundary.height - rootBoundary.top;

                if (top < 0) {
                    element.scrollIntoView({
                        block: 'start'
                    });
                } else if (bottom > rootBoundary.height) {
                    element.scrollIntoView({
                        block: 'end'
                    });
                }
            }
        }
    }

    private saveEdit = async (item: IList) => {
        if (this.myRefs.editLabelTextRef.current) {
            const value = await this.myRefs.editLabelTextRef.current.save();
            if (value !== undefined) {
                if (this.myRefs.root.current) {
                    this.myRefs.root.current.focus();
                }
                Util.invokeEvent<EditLabelTextArgs>(this.props.onEditLabelText, new EditLabelTextArgs(this, item.originalItem, value));
                return true;
            } else {
                return false;
            }
        }
    }

    private cancelEdit = async () => {
        if (this.myRefs.editLabelTextRef.current) {
            await this.myRefs.editLabelTextRef.current.cancel();
            if (this.props.onEditLabelTextBlur) {
                this.props.onEditLabelTextBlur();
            }
            if (this.myRefs.root.current) {
                this.myRefs.root.current.focus();
            }
        }
    }

    /**
     * @internal
     * 새로운 요소를 선택할 때 호출
     */
    private setSelectedItem = (item: IList) => {
        if (this.props.disabled !== true && item.disabled !== true && item.visible !== false) {
            if (this.props.selectedItem !== item.key && this.props.onAfterSelectChange && !this.props.disabled && !item.disabled) {
                Util.invokeEvent<AfterSelectEventArgs>(this.props.onAfterSelectChange, new AfterSelectEventArgs(this, item.originalItem));
            }
            if (this.props.onMouseDown && !this.props.disabled && !item.disabled) {
                Util.invokeEvent<OnMouseDownArgs>(this.props.onMouseDown, new OnMouseDownArgs(this, item.originalItem));
            }
        }
    }

    private static isCollapsed(collapsed?: boolean) {
        return collapsed === false ? false : true;
    }

    private static isChecked(checked?: boolean) {
        return checked === true ? true : false;
    }

    private toggleCollapse = async (item: IList, e?: React.MouseEvent) => {
        const collapsed = OBTTreeView.isCollapsed(item.collapsed) ? false : true;
        if (this.props.disabled !== true) {
            await this.collapse(item.key, collapsed, e);
        }
    }

    private setCheck = (item: IList) => {
        const checked = OBTTreeView.isChecked(item.checked) ? false : true;
        if (this.props.disabled !== true && item.disabled !== true) {
            // 자식은 모두 체크변환, 현재 체크에 대해 부모 변경
            const checkedItems: IList[] = [];
            const setCheck = (list: IList[], updated?: { updated: boolean }, forceCheck?: boolean) => {
                return list.map(listItem => {
                    if (!listItem.disabled && (listItem.key === item.key || forceCheck !== undefined)) {
                        if (updated) {
                            updated.updated = true;
                        }
                        /* default 일때 */
                        if (this.props.checkBoxOption === CheckBoxOption.default) {
                            if (listItem.children && listItem.children.length > 0) {
                                //클릭한 값을 포함하여 children도 체크 포함
                                const children = setCheck(listItem.children, undefined, checked);
                                const newItem = {
                                    ...listItem,
                                    children: children,
                                    checked: checked
                                };
                                checkedItems.push(newItem);
                                return newItem;
                            } else { //자식이 없을 경우 children 체크 포함 X
                                const newItem = {
                                    ...listItem,
                                    checked: checked
                                };
                                checkedItems.push(newItem);
                                return newItem;
                            }
                            /* asymmetric 일때 */
                        } else if (this.props.checkBoxOption === CheckBoxOption.asymmetric) {
                            const newItem = {
                                ...listItem,
                                checked: checked
                            };
                            checkedItems.push(newItem);
                            return newItem;
                        }
                    }
                    if (listItem.children && listItem.children.length > 0) {
                        const childUpdated = { updated: false };
                        const children = setCheck(listItem.children, childUpdated);
                        if (childUpdated.updated) {
                            if (updated) {
                                updated.updated = true;
                            }
                            const currentCheck = children.filter(child => OBTTreeView.isChecked(child.checked) === true).length === children.length;

                            const itemAsymmetric = {
                                ...listItem,
                                children
                            };

                            const itemDefault = {
                                ...listItem,
                                checked: currentCheck,
                                children
                            }

                            return this.props.checkBoxOption === CheckBoxOption.asymmetric ? itemAsymmetric : itemDefault;
                        }
                    }
                    return listItem;
                });
            };
            const updatedList = this.state.list ? setCheck(this.state.list) : this.state.list;

            this.setList(updatedList, item.key).then(() => {
                Util.invokeEvent<CheckedEventArgs>(this.props.onCheckChanged, new CheckedEventArgs(this, checkedItems.map(item => item.originalItem), checked));
            });
        }
    }

    public collapse = async (key?: string, collapse: boolean = true, e?: React.MouseEvent) => {
        const updatedList = key ? OBTTreeView.updateItem(this.state.list, key, { collapsed: collapse }) :
            OBTTreeView.updateItems(this.state.list, { collapsed: collapse });
        const currentKey = key ? key : updatedList[0].key;
        await this.setList(updatedList, currentKey);
        const currentItem = OBTTreeView.getItem(this.state.list, currentKey);
        Util.invokeEvent<CollapseEventArgs>(this.props.onCollapseChanged, new CollapseEventArgs(this, currentItem ? currentItem.originalItem : undefined, collapse, e));
    }

    public expand = (key?: string, expand: boolean = true) => {
        this.collapse(key, !expand);
    }

    public getCheckedItems = () => {
        return OBTTreeView.getItems(this.state.list, (item) => item.checked === true);
    }

    private handleItemDoubleClicked = async (item: IList, e: React.MouseEvent<Element, MouseEvent>) => {
        await this.toggleCollapse(item, e);
        Util.invokeEvent<OnMouseEnterArgs>(this.props.onDoubleClick, new OnMouseEnterArgs(this, item.originalItem));
    }

    private handleMouseEnter = (item: IList, e: React.MouseEvent) => {
        if (!this.state.dragging) {
            if (this.props.onMouseEnter) {
                Util.invokeEvent<OnMouseEnterArgs>(this.props.onMouseEnter, new OnMouseEnterArgs(this, item.originalItem));
            }
        }
    }

    private handleDragStart = (item: IList, e: React.DragEvent) => {
        e.dataTransfer.setData("text", JSON.stringify(item.originalItem));
        if (e.dataTransfer.setDragImage) {
            const invisibleImage = new Image();
            invisibleImage.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
            invisibleImage.style.visibility = 'hidden'
            e.dataTransfer.setDragImage(invisibleImage, 0, 0);
        }
        this.setState({ dragging: true, draggingKey: item.key }, () => {
            const updateList = OBTTreeView.updateItem(this.state.list, item.key, { dragging: true });
        });
    }

    private handleDragEnter = (item: IList, e: React.DragEvent) => {
        if (this.props.editSort && this.state.dragging && this.state.draggingKey && this.state.draggingKey !== item.key) {
            const draggingItem = OBTTreeView.getItem(this.state.list, this.state.draggingKey);
            if (!draggingItem || !draggingItem.parentKey) return;
            const parentItem = OBTTreeView.getItem(this.state.list, draggingItem.parentKey);
            if (!parentItem || parentItem.key !== item.parentKey) return;
            const children = parentItem.children || [];
            const draggingItemIndex = children.findIndex(child => child.key === draggingItem.key);
            const itemIndex = children.findIndex(child => child.key === item.key);
            children[draggingItemIndex] = item;
            children[itemIndex] = draggingItem;
            const updatedList = OBTTreeView.updateItem(this.state.list, parentItem.key, {
                children
            });
            this.setList(updatedList, draggingItem.key)
                .then(() => {
                    if (this.props.onEditSort) {
                        Util.invokeEvent<EditSortArgs>(this.props.onEditSort, new EditSortArgs(this, draggingItem.originalItem, item.originalItem));
                    }
                });
        }
    }

    private handleDragOver = (item: IList, e: React.DragEvent) => {
        if (this.props.editSort && item.index) {
            if (this.state.selectedItem && this.state.selectedItem.index && item.index.length === this.state.selectedItem.index.length) {
                let count = this.state.selectedItem.index.filter((selectedItem, index) => {
                    if (this.state.selectedItem && this.state.selectedItem.index && this.state.selectedItem.index.length - 1 === index) {
                        return false
                    }
                    else if (item.index && selectedItem === item.index[index]) {
                        return true
                    }
                    return false
                })
                if (count.length === this.state.selectedItem.index.length - 1) {
                    e.preventDefault();
                }
            }
        }
    }

    private handleDragEnd = (item: IList, e: React.DragEvent) => {
        if (this.state.dragging) {
            this.setState({
                dragging: false,
                draggingKey: undefined
            }, () => {
                if (this.props.onDragEnd) {
                    this.props.onDragEnd(true);
                }
            })
        }
    }

    private handleMouseDown = (item: IList, e: React.MouseEvent) => {
        e.stopPropagation();
        this.setSelectedItem(item)
    }

    private handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, item: IList) => {
        e.stopPropagation();
        switch (e.key) {
            case 'Enter':
                e.stopPropagation();
                e.preventDefault();
                e.persist();
                this.saveEdit(item);
                break;
            case 'Esc':
            case 'Escape':
                e.preventDefault();
                e.preventDefault();
                e.persist();
                this.cancelEdit();
                break;
            default:
                break;
        }
    }

    private handleTreeViewKeyDown = (e: React.KeyboardEvent) => {
        e.stopPropagation();
        switch (e.key) {
            case 'Tab':
            case 'Down':
            case 'ArrowDown':
                e.preventDefault();
                if (this.state.selectedItem) {
                    const indexedList = OBTTreeView.getIndexList(this.state.list);
                    const currentIndex = indexedList.findIndex(item => item === this.state.selectedItem);
                    if (currentIndex + 1 < indexedList.length) {
                        this.setSelectedItem(indexedList[currentIndex + 1]);
                    }
                }
                break;
            case 'Up':
            case 'ArrowUp':
                e.preventDefault();
                if (this.state.selectedItem) {
                    const indexedList = OBTTreeView.getIndexList(this.state.list);
                    const currentIndex = indexedList.findIndex(item => item === this.state.selectedItem);
                    if (currentIndex - 1 >= 0) {
                        this.setSelectedItem(indexedList[currentIndex - 1]);
                    }
                }
                break;
            case 'Enter':
                e.preventDefault();
                if (this.state.selectedItem && this.props.checkBox)
                    this.setCheck(this.state.selectedItem)
                break;
            case 'Space':
            case ' ':
                e.preventDefault();
                if (this.state.selectedItem) {
                    this.toggleCollapse(this.state.selectedItem);
                }
                break;
            default:
                break;
        }
    }

    private renderEmptySet = () => {
        let emptyImageJsx: JSX.Element | null = null;
        if (typeof this.props.emptyDataImage === 'string') {
            emptyImageJsx = (<img src={this.props.emptyDataImage} alt={'empty set image'}></img>);
        } else {
            if (this.props.emptyDataImage) {
                emptyImageJsx = this.props.emptyDataImage;
            }
        }

        let emptyDataMsg = this.props.emptyDataMsg;
        if (this.props.emptyDataMsg === '데이터가 존재하지 않습니다.') {
            emptyDataMsg = OrbitInternalLangPack.getText('WE000009420', '데이터가 존재하지 않습니다.');
        }

        return (
            <div style={{
                width: "100%",
                height: "100%",
                pointerEvents: 'none'
            }}>
                <div className={styles.emptyData}>
                    {emptyImageJsx}
                    <div className={styles.emptyTextNormal}>
                        {this.props.emptyDataMsg}
                    </div>
                </div>
            </div>
        );
    }

    private renderComponent = () => {
        return (
            <>
                <div
                    id={this.props.id}
                    data-orbit-component={'OBTTreeView'}
                    className={Util.getClassNames(styles.root, this.props.className)}
                    style={Util.getWrapperStyle(this.props)}
                    onKeyDown={this.handleTreeViewKeyDown}
                    tabIndex={0}
                    ref={this.myRefs.root}
                >
                    {this.state.list && this.state.list.length > 0 ? this.getTreeNode(this.state.list) : this.renderEmptySet()}
                </div>

                {this.props.images ?
                    <span
                        style={{ display: this.state.getImagesWidth ? 'none' : 'inline-flex', opacity: 0, position: 'fixed', pointerEvents: 'none' }}
                        ref={this.myRefs.images}
                    >
                        {this.props.images}
                    </span>
                    : undefined}
            </>
        )
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        )
    }
};
