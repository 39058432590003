import { IColumn, ColumnType } from "../IColumn";
import { ignoreException } from "../../Common/OrbitInternalUtil";

export interface ChangedLayoutByColumn {
    [key: string]: CustomColumnProperty
}

export interface CustomColumnProperty {
    visible?: boolean,
    displayIndex?: number,
    width?: number,
}

export interface IUserSorting {
    direction: string,
    fieldName: string,
    orgFieldName: string,
}

export default class UserCustomGridLayoutProcessor {
    /**
     * columns를 changedLayoutByColumn를 기준으로 변경한다.
     * @param columns 
     * @param changedLayoutByColumn 
     */
    public convertColumns(columns: IColumn[], changedLayoutByColumn: ChangedLayoutByColumn): IColumn[] {
        let result = columns.map<IColumn>((column) => {
            if (column.type === ColumnType.group && column.columns) {
                column.columns = this.convertColumns(column.columns, changedLayoutByColumn);

                // 그룹 컬럼 자식의 width로 자신의 width를 재계산
                column.width = column.columns.reduce((column1, column2) => {
                    let width = 0
                    if (column1.visible !== false) {
                        width += column1.width ? Number(column1.width) : 0
                    }

                    if (column2.visible !== false) {
                        width += column2.width ? Number(column2.width) : 0
                    }

                    return {
                        name: 'no meaning',
                        width: width
                    };
                }).width;
            }

            if (changedLayoutByColumn[column.name]) {
                let customColumn = column;

                if (changedLayoutByColumn[column.name].width !== undefined) {
                    customColumn = {
                        ...customColumn,
                        width: Number(changedLayoutByColumn[column.name].width)
                    }
                }

                if (changedLayoutByColumn[column.name].visible !== undefined) {
                    customColumn = {
                        ...customColumn,
                        visible: changedLayoutByColumn[column.name].visible
                    }
                }

                return customColumn;
            }

            return column;
        });

        result = result.sort((column1, column2) => {
            let column1DisplayIndex = 0;
            let column2DisplayIndex = 0;

            if (changedLayoutByColumn[column1.name] && changedLayoutByColumn[column1.name].displayIndex) {
                column1DisplayIndex = changedLayoutByColumn[column1.name].displayIndex!;
            }

            if (changedLayoutByColumn[column2.name] && changedLayoutByColumn[column2.name].displayIndex) {
                column2DisplayIndex = changedLayoutByColumn[column2.name].displayIndex!;
            }

            if (column1DisplayIndex === undefined) {
                return 1;
            }

            if (column2DisplayIndex === undefined) {
                return -1;
            }

            return column1DisplayIndex > column2DisplayIndex ? 1 : 0;
        });

        return result;
    }

    public convertUserCustomColumns(clientKey: string, targetColumns: IColumn[]) {
        if (!targetColumns) {
            return [];
        }

        const storageDataString = localStorage.getItem(clientKey);
        if (!storageDataString) {
            return targetColumns;
        }

        const changedLayoutByColumn = JSON.parse(storageDataString);
        let result = this.convertColumns(targetColumns, changedLayoutByColumn);

        return result;
    }

    public hasUserLayout(clientKey: string) {
        const existItemString = localStorage.getItem(clientKey);
        if (!existItemString) {
            return false;
        }

        return true;
    }

    public removeUserCustomColumns(clientKey: string) {
        localStorage.removeItem(clientKey);
    }

    public removeUserCustomSorting(clientKey: string) {
        localStorage.removeItem(this.getSortingKey(clientKey));
    }

    public removeUserCustom(clientKey: string) {
        this.removeUserCustomColumns(clientKey);
        this.removeUserCustomSorting(clientKey);
    }

    public getUserColumnLayout(clientKey: string) {
        try {
            const existItemString = localStorage.getItem(clientKey);
            if (!existItemString) {
                return null;
            }

            const object = JSON.parse(existItemString);
            return object
        } catch (e) {
            console.error(e);
        }
    }

    public getUserSorting(clientKey: string): IUserSorting [] | null {
        let result: IUserSorting [] | null = null;

        ignoreException(() => {
            const jsonString = localStorage.getItem(this.getSortingKey(clientKey));
            if(jsonString) {
                const object = JSON.parse(jsonString);
                result = object;
            }
        })

        return result;
    }

    /**
     * 컬럼순서, 보여지는 여부 지정 
     * @param clientKey 
     * @param column 
     * @param value 
     */
    public saveUserCustomLayout(clientKey: string, column: IColumn, value: CustomColumnProperty) {
        ignoreException(() => {
            if ((value.displayIndex === undefined || value.displayIndex === null) && value.visible === undefined && value.width === undefined) {
                return;
            }

            const existItemString = localStorage.getItem(clientKey);
            if (existItemString) {
                const object = JSON.parse(existItemString);

                const updateItem = {
                    ...object,
                    [column.name]: {
                        ...object[column.name],
                        ...value
                    }
                }
                localStorage.setItem(clientKey, JSON.stringify(updateItem));
            } else {
                localStorage.setItem(clientKey, JSON.stringify({
                    [column.name]: value,
                }));
            }
        })
    }

    public saveUserSorting(clientKey: string, sortedFields: IUserSorting[]) {
        ignoreException(() => {
            const jsonString = JSON.stringify(sortedFields);
            localStorage.setItem(this.getSortingKey(clientKey), jsonString);
        })
    }

    public getSortingKey(clientKey: string) {
        return clientKey + '_SORT';
    }
}
