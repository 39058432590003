import React from "react";
import { OBTButton, OBTDialog, OBTFormPanel, OBTTextField } from "../../components";
import { setCookie } from "./cookie";

export default class LoginDialog extends React.Component {

    state = {
        id: "",
        password: "",
        idValidate: OBTTextField.ValidateState.error,
        passwordValidate: OBTTextField.ValidateState.error,
        message: "",
    }

    myRefs = {
        formPanelRef: React.createRef(),
        firstTextRef: React.createRef()
    }

    componentDidMount() {
        if (this.myRefs.firstTextRef.current) {
            this.myRefs.firstTextRef.current.focus();
        }
    }

    handleButton = (e) => {
        // 1. 닫기 버튼일 경우 닫아버리기
        if (e.type === "closeButton" || e.type === "esc") {
            this.props.onClose();
            this.setState({
                id: "",
                password: "",
                idValidate: OBTTextField.ValidateState.error,
                passwordValidate: OBTTextField.ValidateState.error,
                message: ""
            })
            return;
        }

        if (e.type === "button") {
            // 2. 아이디랑 비밀번호 둘다 입력할것
            if (this.state.idValidate === OBTTextField.ValidateState.error &&
                this.state.passwordValidate === OBTTextField.ValidateState.error) {
                this.setState({
                    message: "비밀번호와 아이디를 입력해주세요!"
                })
                return;
            }

            if (this.state.passwordValidate === OBTTextField.ValidateState.error) {
                this.setState({
                    message: "비밀번호를 입력해주세요."
                })
                return;
            }

            if (this.state.idValidate === OBTTextField.ValidateState.error) {
                this.setState({
                    message: "아이디를 입력해주세요."
                })
                return;
            }

            // 3. fetch
            fetch('http://10.82.6.190/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                },
                body: new URLSearchParams({
                    loginId: this.state.id,
                    password: this.state.password
                })
            }).then((response) => {
                if (response.ok) {
                    this.props.onClose();
                }
                else if (!response.ok) {
                    this.setState({
                        message: "아이디와 비밀번호가 맞지 않습니다!"
                    })
                }
                return response.json()
            }).then((result) => {
                const accessKey = result.accessKey;
                if (accessKey) {
                    setCookie('login', accessKey, {
                        path: "/",
                        // secure: true
                    })
                }

                window.location.reload();
            }).catch((e) => {
                console.error(e)
                throw e;
            })
        }
    }

    render() {
        return (
            <>
                <OBTDialog
                    open={this.props.dialog}
                    buttons={[
                        {
                            key: 'add',
                            onClick: this.handleButton,
                            labelText: 'login',
                            isClose: true,
                            theme: OBTButton.Theme.drawer
                        }
                    ]}
                    title='Admin Login'
                    subTitle='관리자로 로그인 합니다.'
                    width='430px'
                    height='250px'
                >
                    <OBTFormPanel ref={this.myRefs.formPanelRef}>
                        <colgroup>
                            <col type='label' />
                            <col width='200px' />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>관리자 아이디</th>
                                <td>
                                    <OBTTextField
                                        id="adminId"
                                        value={this.state.id}
                                        onChange={(e) => {
                                            this.setState({
                                                id: e.value
                                            })
                                        }}
                                        placeHolder={"아이디를 입력해주세요."}
                                        ref={this.myRefs.firstTextRef}
                                        validateState={this.state.idValidate}
                                        onValidate={(e) => {
                                            if (e.newValue === 'admin') {
                                                this.setState({
                                                    idValidate: OBTTextField.ValidateState.success
                                                })
                                            }
                                            else if (e.newValue.length < 1) {
                                                this.setState({
                                                    idValidate: OBTTextField.ValidateState.error
                                                })
                                            }
                                            else this.setState({
                                                idValidate: OBTTextField.ValidateState.none
                                            })
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>관리자 비밀번호</th>
                                <td>
                                    <OBTTextField
                                        id='adminPassword'
                                        value={this.state.password}
                                        onChange={(e) => {
                                            this.setState({
                                                password: e.value
                                            })
                                        }}
                                        type={OBTTextField.Type.password}
                                        placeHolder={"비밀번호를 입력해주세요."}
                                        validateState={this.state.passwordValidate}
                                        onValidate={(e) => {
                                            if (e.newValue === 'password') {
                                                this.setState({
                                                    passwordValidate: OBTTextField.ValidateState.success
                                                })
                                            }
                                            else if (e.newValue.length < 1) {
                                                this.setState({
                                                    passwordValidate: OBTTextField.ValidateState.error
                                                })
                                            }
                                            else this.setState({
                                                passwordValidate: OBTTextField.ValidateState.none
                                            })
                                        }}
                                        onKeyUp={(e) => {
                                            if (e.event.key === "Enter") {
                                                this.handleButton({ type: "button" })
                                            }
                                        }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </OBTFormPanel>
                    <div style={{ textAlign: "center", paddingTop: "9px" }}>
                        <p style={{ fontWeight: "bold" }}>{this.state.message}</p>
                    </div>
                </OBTDialog>
            </>
        )
    }
}