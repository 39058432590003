import * as Events from './OBTDataGridEvents';

export default class Pagination {
    /**
     * 현재 페이지 넘버
     */
    public currentPage: number;
    /**
     * 읽히지 않은 것의 합계 데이터
     */
    public unreadedFooterData: any | undefined;
    /**
     * 현재 페이지의 시작인덱스
     */
    public startRowIndex: number;
    /**
     * 총 페이지 길이 0부터 시작
     */
    public totalPageLength: number | undefined;

    public currentRowCount: number;

    constructor(
        /**
         * 페이징 방법 
         */
        public pagingDirection: "scrollToBottom" | "scrollToTop",
        /**
         * 총 로우 카운트
         */
        public totalCount: number | undefined,
        /**
         * 페이지당 로우 카운트
         */
        public rowCountPerPage: number,
        /**
         * 최초 조회시 페이지의 크기
         */
        public initialPageSize: number,
        /**
         * totalRowCount를 모르는 상황에서 isLastPage를 판단하기 위한 기준값
         */
        public markPreventRead: boolean) {
        if (totalCount) {
            this.totalPageLength = Pagination.calculateTotalPageLength(totalCount!, rowCountPerPage, initialPageSize);
        }

        this.currentPage = (pagingDirection === "scrollToTop") ? this.totalPageLength! : 0;

        this.startRowIndex = this.currentPage * this.initialPageSize;
        if (pagingDirection === "scrollToTop") {
            this.startRowIndex = this.totalCount! - this.initialPageSize;
        }

        this.currentRowCount = this.initialPageSize;

        markPreventRead = false;
    }

    public setFooterData(footerData: any) {
        this.unreadedFooterData = footerData;
    }

    /**
     * limit 카운트 계산
     */
    public getLimitCount(): number {
        if (this.pagingDirection === "scrollToTop") {
            if (!this.totalCount) {
                throw new Error('');
            }
            let firstPageCount =
                (this.totalCount - this.initialPageSize) % (this.rowCountPerPage);

            if (firstPageCount === 0) {
                firstPageCount = this.rowCountPerPage;
            }

            let previousPage = (this.currentPage - 1);
            if (previousPage === 0) {
                return firstPageCount;
            }
            if (previousPage === -1) {
                return 0;
            }

            let previousPageLastIndex = firstPageCount + ((previousPage) * this.rowCountPerPage);
            return previousPageLastIndex;
        } else {
            if (this.totalCount) {
                return this.totalCount;
            } else {
                return 300000
            }
        }
    }

    public nextPage(): void {
        if (this.isLastPage() === false) {
            if (this.pagingDirection === "scrollToBottom") {
                this.currentPage = this.currentPage + 1;
                this.startRowIndex = this.currentPage === 0 ? 0 : (this.currentPage * this.rowCountPerPage) + (this.initialPageSize - this.rowCountPerPage);
                this.currentRowCount = this.rowCountPerPage;
            } else {
                this.currentPage = this.currentPage - 1;
                this.startRowIndex = this.totalCount! - (this.rowCountPerPage * (this.totalPageLength! - (this.currentPage - 1)));
                if (this.currentPage <= 0) {
                    this.startRowIndex = 0;
                }
                if (this.currentPage <= 0) {
                    let firstPageCount =
                        (this.totalCount! - this.initialPageSize) % (this.rowCountPerPage);
                    this.currentRowCount = firstPageCount;
                } else {
                    this.currentRowCount = this.rowCountPerPage;
                }
            }
        }
    }

    public preventRead() {
        this.markPreventRead = true;
    }

    public previousPage(): void {
        if (this.isFirstPage() === false) {
            if (this.pagingDirection === "scrollToBottom") {
                this.currentPage = this.currentPage - 1;
            }
            else {
                this.currentPage = this.currentPage + 1;
            }
            this.startRowIndex = this.currentPage === 0 ? 0 : (this.currentPage * this.rowCountPerPage) + (this.initialPageSize - this.rowCountPerPage);
        }
    }

    /**
     * 현재 마지막 페이지인지 판별
     */
    public isLastPage(): boolean {
        if (this.markPreventRead === true) {
            return true;
        } else if (this.pagingDirection === "scrollToTop") {
            return this.currentPage === 0;
        } else {
            return this.currentPage === this.totalPageLength;
        }
    }

    /**
     * 현재 첫번째 페이지 인지 판별
     */
    public isFirstPage(): boolean | undefined {
        if (this.pagingDirection === "scrollToBottom") {
            return this.currentPage === 0;
        } else {
            return (this.currentPage === this.totalPageLength)
        }
    }

    static calculateTotalPageLength(totalCount: number, rowCountPerPage: number, initialPageSize: number) {
        if (totalCount <= initialPageSize) {
            return 0;
        }

        const exTotalCount = totalCount - initialPageSize;

        let totalPageLength = parseInt((exTotalCount / rowCountPerPage).toString()) - 1;
        if (exTotalCount % rowCountPerPage !== 0) {
            totalPageLength++;
        }
        return totalPageLength + 1;
    }
}

/**
 * 
 * @param option 
 */
export function makePagination(option: {
    rowCountPerPage: number,
    initialRowCount: number,
    pagingDirection?: "scrollToTop" | "scrollToBottom"
    readTotalCount?: (e: Events.ReadTotalCountEventsArgs) => Promise<any>
}) {
    return new Promise<Pagination>((resolve) => {
        let pagingInfo: Pagination;
        if (option.readTotalCount) {
            option.readTotalCount(new Events.ReadTotalCountEventsArgs({})).then((response) => {
                pagingInfo = new Pagination(
                    option.pagingDirection ? option.pagingDirection : 'scrollToBottom',
                    response,
                    option.rowCountPerPage,
                    option.initialRowCount,
                    false
                );
                resolve(pagingInfo)
            });
        } else {
            if (option.pagingDirection === 'scrollToTop') {
                throw Error('scrollToTop pagingDirection must implement readTotalCount');
            }

            pagingInfo = new Pagination(
                option.pagingDirection ? option.pagingDirection : 'scrollToBottom',
                undefined,
                option.rowCountPerPage!,
                option.initialRowCount!,
                false,
            );

            resolve(pagingInfo);
        }
    })
}
