import React from 'react'
import { OBTSnackbar } from '../index';
import uuid from 'uuid/v1';

interface IState {
    key?: string,
    type?: string,
    labelText?: string
}

export default class Snackbar extends React.Component<{}, IState> {
    state: IState = {}

    public setSnackbar(type?: string, labelText?: string) {
        return new Promise<void>(resolve => {
            this.setState({
                key: uuid(),
                type,
                labelText
            }, () => {
                resolve()
            });
        })
    }

    render() {
        return (
            this.state.key ? <OBTSnackbar key={this.state.key} type={this.state.type as any} labelText={this.state.labelText} /> : <></>
        );
    }
}
