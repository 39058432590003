/**
 * OBTListGrid
 * @version 0.1
 * @author 안광진
 * @see common.js
 */

import { ResizeSensor } from 'css-element-queries'
import debounce from 'lodash.debounce';
import * as React from 'react'
import uuid from 'uuid/v1'
import { CompositeProps, Events, Util, Functions, createPropDefinitions, CommonDefinitions, CommonType } from '../Common'
import OBTListGridInterface from './OBTListGridInterface'
import OBTPagination from '../OBTPagination';
import OBTButton from '../OBTButton';
import { IActionButton } from './IActionButton';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { Fragment } from 'react';
import './OBTListGridStyle.css'

/**
 * @ignore
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RealGridJS: any = require('../OBTDataGrid/RealGridJS').default

/**
 * @ignore
 */
const styles = require('./OBTListGrid.module.scss')

/**
 * OBTListGrid 컴포넌트 props
 */
interface IOBTListGrid extends CompositeProps.Default, Events.onChange<TypeList> {
    /**
     * 리얼그리드와의 연결을 담당하는 인터페이스
     */
    interface: OBTListGridInterface,
}

/**
 * @ignore
 */
interface TypeList {
    currentPage: number,
    rowCountPerPage: number,
}

/**
 * @ignore
 */
interface State extends hasError {
    interface: OBTListGridInterface,
    displayMessageType?: string,

    emptyBoundary?: {
        top: number,
        height: number
    },

    hover: {
        visible: boolean,
        rowIndex: number,
        boundary?: any,
        moveThumbBoundary?: any,
        actionButtons?: any,
        reorder?: boolean,
        reorderIndex?: number,
        reorderImage?: any,
        mousePosition?: { clientX: number, clientY: number }
    }
}

/**
 * @ignore
 */
export default class OBTListGrid extends React.Component<IOBTListGrid, State> implements Functions.IRefreshable {
    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        { name: 'key', type: CommonType.string, default: false, description: '각 컴포넌트를 구분하는 Unique Key 입니다.', optional: false },
        CommonDefinitions.Default(),
        { name: 'icon', type: ['node'], default: '', description: '버튼형태에 표시할 아이콘(SVG) 입니다. OBTButton.icon 참고', optional: true },
        { name: 'imageUrl', type: CommonType.string, default: '', description: '버튼형태에 표시할 이미지(PNG...) 입니다. OBTButton.imageUrl 참고', optional: true },
        { name: 'disabled', type: CommonType.boolean, default: false, description: '컴포넌트의 활성여부입니다.', optional: true },
        { name: 'tooltip', type: ['json'], default: false, description: '툴팁입니다. OBTButton.tooltip 참고', optional: true },
        { name: 'component', type: CommonType.any, default: false, description: '버튼형태가 아닌 다른 컴포넌트를 직접 등록하여 사용할 수 있습니다.', optional: true },
    );

    //////////////// GridGlobalOption
    public static GridGlobalOptionDefinitions = createPropDefinitions(
        { name: 'paging', type: CommonType.boolean, default: false, description: '그리드의 페이징 사용여부', optional: true },
        { name: 'rowCountPerPage', type: CommonType.number, description: '그리드의 보여줄 로우 갯수 10 | 20 | 30 | 40 | 50', optional: true, default: 10 },
        { name: 'columnMovable', type: CommonType.boolean, description: '그리드의 컬럼이동 사용여부', default: true, optional: true },
        { name: 'rowMovable', type: CommonType.boolean, description: '그리드의 행이동 사용여부 행의 포커스가 있을경우 행 라인을 클릭하여 이동', default: true, optional: true },
        { name: 'rowMovableColumnWidth', type: CommonType.number, default: 30, description: '행이동 컬럼의 너비', optional: true },
        { name: 'useEmptySet', type: CommonType.boolean, default: true, description: '초기 데이터 없는 경우, 조회 데이터 없는 경우 emptySet 표시 여부', optional: true },
        { name: 'emptyDataMsg', type: CommonType.string, optional: true, description: '초기 데이터 없는 경우 안내 문구 설정', default: '데이터가 존재하지 않습니다.' },
        {
            name: 'emptyDataImage', type: ['node'], description: `초기 데이터 없는 경우 안내 이미지 설정 emptyDataImage: <img src={...} alt={''} />`
            , optional: true
        },
        { name: 'emptySearchMsg', type: CommonType.string, optional: true, description: '조회 데이터 없는 경우 안내 문구 설정', default: '검색 결과가 없습니다.' },
        { name: 'emptySearchImage', type: ['node'], description: '조회된 데이터가 없는 경우 안내 이미지 설정', optional: true },
        { name: 'checkable', type: CommonType.boolean, default: false, description: '그리드 좌측 체크영역 표시여부 setCheckable()로 동적설정 가능 ', optional: true },
        { name: 'indicator', type: CommonType.boolean, default: false, description: '그리드 좌측에 인덱스, 편집여부등이 표시되는 indicator를 표기할지 여부를 지정', optional: true },
        { name: 'footerCount', type: CommonType.number, description: '푸터의 로우 카운트', optional: true },
        { name: 'preventSort', type: CommonType.function, description: '정렬기능막기', optional: true },
        { name: 'headerCheckVisible', type: CommonType.boolean, optional: true, description: 'checkable = true시 헤더에 전제체크를 보일지 여부' },
        { name: 'pagingBlock', type: CommonType.number, default: 10, optional: true, description: '페이지 단위를 지정합니다.' },
        { name: 'isPageTotalCountText', type: CommonType.boolean, optional: true, description: '페이지의 총 페이지 텍스트 표시 여부 설정', default: true },
        { name: 'useRowCountDropDownList', type: CommonType.boolean, optional: true, default: true, description: 'Pager 영역의 드랍다운리스트 영역을 보여줄지 여부입니다.' },
        { name: 'eachRowResizable', type: CommonType.boolean, description: '각각의 행의 높이를 조절할수 있는지 여부', default: false, optional: true },
        { name: 'useServerSort', type: CommonType.boolean, optional: true, description: 'true로 설정시 헤더를 클릭하여 정렬을 수행할 때 기본적으로 수행되는 정렬기능이 동작하지 않는다. (서버사이드에서 정렬 사용시 true로 사용)', default: false },
        { name: 'emptyShowTooltip', type: CommonType.boolean, optional: true, default: false, description: 'true 로 지정시 빈값에 대해서도 툴팁이 표시됩니다.' },
        { name: 'columnResizable', type: CommonType.boolean, optional: true, default: true, description: 'false 로 지정시 컬럼 너비 조정을 불가능하게 합니다.' },
    );

    //////////////// Interface
    public static InterfaceDefinitions = createPropDefinitions(
        {
            name: 'setGridOption', default: 'editable: false, appendable: false, checkable: false, indicator: false, preventSort: false, headerCheckVisible: true',
            type: CommonType.function, description: '그리드옵션을 동적으로 조정한다. 옵션중 할당되지 않은 값에는 디폴트값이 할당된다.', optional: true,
            parameters: {
                name: 'options',
                type: 'GridGlobalOption'
            }
        },
        {
            name: 'setColumn', optional: true, type: CommonType.function, parameters: [
                { name: 'column', type: 'IColumn' }
            ], description: '컬럼 정보를 바탕으로 컬럼을 동적으로 세팅한다.'
        },
        {
            name: 'setCheck', optional: true, type: CommonType.function, parameters: [
                { name: 'rowIndex', type: CommonType.number },
                { name: 'chekced', type: CommonType.boolean }
            ], description: '특정 인덱스의 체크처리를 제어한다.'
        },
        {
            name: 'setRowCheckable', optional: true, type: CommonType.function, parameters: [
                { name: 'rowIndex', type: CommonType.number },
                { name: 'checkable', type: CommonType.boolean }
            ], description: '특정 인덱스의 행이 체크가능 여부를 세팅한다.'
        },
        {
            name: 'setCheckable', type: CommonType.function, description: '그리드의 체크상태 변경', optional: true, parameters: [
                { name: 'checkable', type: CommonType.boolean },
                { name: 'showHeaderCheck', type: CommonType.boolean }
            ]
        },
        {
            name: 'setCheckableExpression', type: CommonType.function, description: '특정 행 체크박스 비활성화시킬 수 있는 기능', optional: true, parameters: [
                { name: 'checkableExpression', type: CommonType.string }
            ]
        },
        {
            name: 'setActionButtons', type: CommonType.function, optional: true,
            parameters: {
                name: 'actionButtons',
                type: ['IActionButton[]', 'null']
            },
            description: '마우스오버시 보여지는 우측에 항목별 단일 기능 아이콘 설정'
        },
        {
            name: 'setIndicator', type: CommonType.function, description: '그리드 좌측에 인덱스, 편집여부등이 표시되는 indicator를 표기할지 여부를 지정한다.', optional: true,
            parameters: {
                name: 'visible',
                type: CommonType.boolean
            }
        },
        {
            name: 'setColumn', type: CommonType.function, optional: true, parameters: [
                { name: 'column', type: 'IColumn' }
            ], description: '컬럼 정보를 바탕으로 컬럼을 동적으로 세팅한다.'
        },

        {
            name: 'setColumns', type: CommonType.function, optional: true, parameters: {
                name: 'columns',
                type: ['IColumn[]', 'null']
            }, result: ['OBTListGridInterface'], description: '컬럼 배열을 세팅한다.'
        },
        {
            name: 'setProvider', type: CommonType.function, optional: true, parameters: {
                name: 'provider',
                type: ['IDataProvider', 'null']
            }, result: ['OBTListGridInterface'], description: '데이터 설정에 관련된 함수'
        },
        {
            name: 'setSelection', type: CommonType.function, optional: true, parameters: [
                { name: 'rowIndex', type: CommonType.number },
                { name: 'columnName?', type: CommonType.string }
            ], result: CommonType.string, description: '특정 셀 선택. 포커스까지 옮기지는 않는다.'
        },
        {
            name: 'setColumnVisible', type: CommonType.function, optional: true, parameters: [
                { name: 'columnName', type: CommonType.string },
                { name: 'isVisible', type: CommonType.boolean }
            ], description: '특정 컬럼 보여지는지 여부 지정.'
        },
        {
            name: 'setRowHeight', type: CommonType.function, optional: true, parameters: [
                { name: 'rowIndex', type: CommonType.number },
                { name: 'height', type: CommonType.number },
                { name: 'refresh', type: CommonType.boolean, description: 'default = true' }
            ], description: '행의 height 값을 조정한다. 그리드옵션 eachRowResizable: true 에서만 동작'
        },
        {
            name: 'fitColumnWidth', type: CommonType.function, optional: true, parameters: {
                name: 'options?',
                type: {
                    columnName: { type: CommonType.string, optional: true },
                    maxWidth: { type: CommonType.number, optional: true },
                    minWidth: { type: CommonType.number, optional: true },
                    visibleOnlyt: { type: CommonType.boolean, optional: true }
                }
            }, description: "컬럼의 폭을 데이터에 따라 자동으로 조절한다."
                + '\ncolumnName을 지정하지 않으면 모든 컬럼에 일괄적용한다.'
                + '\nmaxWidth, minWidth는 지정하지 않으면 데이터의 길이에 따른다.'
        },
        {
            name: 'fitRowHeight', type: CommonType.function, optional: true, parameters: [
                { name: 'itemIndex', type: CommonType.number },
                { name: 'maxHeight', type: CommonType.number },
                { name: 'textOnlyt', type: CommonType.boolean, description: 'default = true' },
                { name: 'refresh', type: CommonType.boolean, description: 'default = true' }
            ], description: 'eachRowResizable = true 일 경우, 행의 높이를 데이터에 따라 자동조절한다. (multiline 컬럼일 경우) 최대 maxHeight까지 높이가 늘어난다.'
        },
        {
            name: 'fitRowHeightAll', type: CommonType.function, optional: true, parameters: [
                { name: 'maxHeight', type: CommonType.number },
                { name: 'textOnlyt', type: CommonType.boolean, description: 'default = true' }
            ], description: '모든 행의 높이를 데이터에 따라 자동조절한다. (multiline 컬럼일 경우) 최대 maxHeight까지 높이가 늘어난다. 그리드옵션 eachRowResizable: true 에서만 동작'
        },
        { name: 'getGridOption', type: CommonType.function, description: 'GlobalGridOption을 리턴한다. 복사본을 리턴하기 때문에 리턴된 오브젝트의 값을 변경해도 그리드에는 영향이 없다.', optional: true },
        {
            name: 'getSelection', type: CommonType.function, result: CommonType.any,
            description: '다음의 형태로 선택된 정보를 가져온다. { rowIndex: number, columnName: string }', optional: true
        },
        { name: 'getRowCount', type: CommonType.function, result: CommonType.number, optional: true, description: '그리드에 바인딩된 데이터의 갯수를 가져온다.' },
        {
            name: 'getRowIndexes', type: CommonType.function, optional: true, parameters: {
                name: 'options?',
                type: {
                    checkedOnly: { type: CommonType.boolean, optional: true },
                }
            }, description: '체크된 행이나 특정 스테이트의 인덱스만 가져온다.', result: ['number[]']
        },
        {
            name: 'getRows', type: CommonType.function, optional: true, parameters: {
                name: 'options',
                type: {
                    checkedOnly: { type: CommonType.boolean, optional: true },
                }
            }, description: '체크된 행이나 특정 스테이트의 데이터를 가져온다.', result: CommonType.any
        },
        {
            name: 'getRow', type: CommonType.function, optional: true, parameters: {
                name: 'rowIndex',
                type: CommonType.number
            }, result: CommonType.any, description: 'rowIndex행의 데이터를 가져온다.'
        },
        {
            name: 'getValues', type: CommonType.function, optional: true, parameters: {
                name: 'rowIndex',
                type: CommonType.number
            }, result: CommonType.any, description: 'rowIndex행의 데이터를 가져온다.'
        },
        {
            name: 'getColumnByName', type: CommonType.function, optional: true, parameters: {
                name: 'columnName',
                type: CommonType.string
            }, result: ['IColumn', 'undefined'], description: 'columnName으로 컬럼을 가져온다.'
        },
        {
            name: 'getRealGridColumnByName', type: CommonType.function, optional: true, parameters: {
                name: 'columnName', type: CommonType.string
            }, result: ['any', 'null'], description: '리얼그리드의 컬럼을 가져온다.'
        },
        {
            name: 'getCheck', optional: true, type: CommonType.function, parameters: {
                name: 'IColumn',
                type: CommonType.boolean
            }, result: CommonType.boolean, description: '특정 인덱스의 행이 체크되어있는지 여부를 리턴한다.'
        },
        { name: 'getCheckedRows', optional: true, type: CommonType.function, result: ['any[]'], description: '그리드에서 체크된 로우를 가져온다.' },
        { name: 'getCheckedIndexes', optional: true, type: CommonType.function, result: ['number[]'], description: '체크한 인덱스 가져오기' },
        { name: 'checkAll', optional: true, type: CommonType.function, result: CommonType.boolean, description: '모든 행 체크' },
        {
            name: 'getSelectedIndex', type: CommonType.function, optional: true, result: CommonType.number,
            description: '선택된 로우의 인덱스를 가져온다.'
        },
        {
            name: 'getSelectedColumnName', type: CommonType.function, result: CommonType.string, optional: true,
            description: '선택된 컬럼의 이름을 가져온다.'
        },
        { name: 'getSelectedRow', type: CommonType.function, optional: true, result: CommonType.any, description: '선택된 행의 데이터를 가져온다.' },
        {
            name: 'getSummary', type: CommonType.function, optional: true, parameters: [
                { name: 'columnName', type: CommonType.string },
                { name: 'expression', type: ['sum | avg | min | max'] }
            ], result: CommonType.number, description: '특정 컬럼의 집계값 리턴'
        },
        {
            name: 'isRowCheckable', optional: true, type: CommonType.function, parameters: {
                name: 'rowIndex',
                type: CommonType.number
            }, result: CommonType.boolean, description: '특정 인덱스의 행이 체크가능한지 여부를 리턴한다.'
        },
        { name: 'focus', type: CommonType.function, description: '그리드에 focus를 준다', optional: true },
        {
            name: 'searchData', type: CommonType.function, optional: true, parameters: [
                { name: 'startIndex', type: CommonType.number, description: '검색시 시작인덱스' },
                { name: 'columnNamesForSearch', type: ['string[]', 'null'] },
                { name: 'value', type: CommonType.string, description: '찾을 값' },
                { name: 'selectSearchedCell', type: CommonType.boolean },
                { name: 'partialMatch', type: CommonType.boolean, description: '부분만 일치해도 찾을 것인지' }
            ], result: ['ICell', 'null'], description: '데이터 검색'
        },
        {
            name: 'readData', type: CommonType.function, optional: true, parameters: {
                name: 'readCallback?',
                type: ['Read']
            }, result: ['Promise<any>,'], description: '데이터를 읽어 그리드에 바인딩한다.'
        },
        { name: 'clearData', type: CommonType.function, optional: true, description: '그리드의 데이터를 클리어한다.' },
        {
            name: 'showColumn', type: CommonType.function, optional: true, parameters: {
                name: 'columnName',
                type: CommonType.string
            }, description: '특정 컬럼을 보여준다. visible이 false로 지정된 컬럼에 대해서만 영향이 있다.'
        },
        {
            name: 'hideColumn', type: CommonType.function, optional: true, parameters: {
                name: 'columnName',
                type: CommonType.string
            }, description: '특정 컬럼을 숨긴다.'
        },
        {
            name: 'exportExcel', type: CommonType.function, optional: true, parameters: {
                name: 'exportOption?',
                type: CommonType.any
            }, description: '엑셀 익스포트, 엑셀 익스포트의 커스텀을 원할 경우 exportOption에 다음을 참고해서 옵션을 줄수 있음 \nhttp://help.realgrid.com/api/types/GridExportOptions/'
        },
    );

    //////////////// Event
    public static EventDefinitions = createPropDefinitions(
        { name: 'onBeforeCheck', type: CommonType.function, optional: true, description: '로우의 체크바의 체크상태가 변경되기전에 호출된다.' },
        { name: 'onAfterCheck', type: CommonType.function, optional: true, description: '로우의 체크바의 체크상태가 변경된 이후에 호출된다.' },
        { name: 'onAfterHeaderCheck', type: CommonType.function, optional: true, description: '헤더의 체크바의 체크상태가 변경된 이후에 호출된다.' },
        { name: 'onBeforeSelectChange', type: CommonType.function, optional: true, description: '셀 선택이 변경되기 전에 호출된다.' },
        { name: 'onAfterSelectChange', type: CommonType.function, optional: true, description: '셀 선택이 변경한 이후에 호출된다.' },
        { name: 'onAfterRead', type: CommonType.function, optional: true, description: 'readData함수로 데이터를 읽은 이후에 호출된다.' },
        { name: 'onDoublelClicked', type: CommonType.function, optional: true, description: '데이터 셀을 더블클릭했을때 발생하는 이벤트' },
        { name: 'onClicked', type: CommonType.function, optional: true, description: '데이터 셀을 클릭했을때 발생하는 이벤트' },
        { name: 'onColumnWidthChanged', type: CommonType.function, optional: true, description: '컬럼의 width값이 변경된 이후에 호출된다.' },
        { name: 'onMouseHover', type: CommonType.function, optional: true, description: 'useTooltip 사용시 마우스 오버 이벤트 발생' },
        { name: 'onAfterDataChanged', type: CommonType.function, optional: true, description: '데이터, 체크등 내부의 데이터가 변경될때 호출' },
        { name: 'onInnerDragStart', type: CommonType.function, optional: true, description: '드래그를 시작하면 발생하는 이벤트. cancel = true drag가 취소된다.' },
        { name: 'onInnerDrop', type: CommonType.function, optional: true, description: '드래그 상태에서 마우스버튼을 놓으면 발생하는 이벤트. cancel = true drag가 취소된다. ' },
        { name: 'onImageButtonClicked', type: CommonType.function, optional: true, description: '컬럼 이미지버튼을 클릭했을 때 호출된다.' },
        { name: 'onScroll', type: CommonType.function, optional: true, description: '스크롤이 발생할 경우 호출된다.' },
        { name: 'onSorting', type: CommonType.function, optional: true, description: '정렬시 발생' },
        { name: 'onLinkableCellClicked', type: CommonType.function, optional: true, description: 'link타입 컬럼 클릭 이벤트' },
        { name: 'onDrawer', type: CommonType.function, optional: true, description: 'PageContainer에서 Drawer를 보여줘야 되는 상황에서 호출되는 이벤트' },
        { name: 'onSelectByEnter', type: CommonType.function, optional: true, description: 'rowSelectMode에서 엔터 입력이 들어왔을때 호출되는 이벤트' },
    );

    //////////////// IColumn
    public static IColumnDefinitions = createPropDefinitions(
        { name: 'name', type: CommonType.string, description: '컬럼의 명칭' },
        {
            name: 'fieldName', type: CommonType.string, default: 'name에 지정한 값', description: "DataProvider의 데이터소스 필드와 연결되는 명칭을 지정."
                + "\n기본값으로 name 속성을 fieldName으로 사용", optional: true
        },
        {
            name: 'header', type: CommonType.any, description: '헤더에 표시되는 텍스트', optional: true
        },
        { name: 'headerImageUrl', type: CommonType.any, description: '헤더 이미지의 경로', optional: true },
        { name: 'headerImageLocation', type: CommonType.string, description: '헤더 이미지의 위치', optional: true },
        { name: 'imageButtons', type: CommonType.any, description: '이미지 버튼 설정', optional: true },
        { name: 'width', type: CommonType.number, description: '컬럼의 너비', optional: true },
        { name: 'isAutoWidth', type: CommonType.boolean, description: '컬럼의 너비가 자동 조정되는지 여부', default: false, optional: true },
        {
            name: 'type', type: ['text | multiline | number | date | dateTime | check | button | data | mask | group | image | visit'],
            optional: true, default: 'text', description: '컬럼의 타입. 지정하지 않을시 text'
        },
        { name: 'visible', optional: true, type: CommonType.boolean, description: '그리드 좌측에 인덱스, 편집여부등이 표시되는 indicator를 표기할지 여부를 지정한다.' },
        { name: 'editable', optional: true, type: ['boolean | func'], description: "컬럼의 수정 가능 여부이며 콜백으로도 지정가능" },
        {
            name: 'alignment', type: ['center', 'near', 'far', 'left', 'right'], default: 'left', description: '컬럼에 바인딩되는 데이터의 정렬 방식 이며 type에 따라 default가 자동 조정 됨'
            , optional: true
        },
        { name: 'required', optional: true, type: CommonType.boolean, description: "필수값인지 여부.", default: false },
        {
            name: 'hasActionButton', type: CommonType.boolean, optional: true, default: false, description: '셀 우측에 작은 버튼이 생기는지 여부, 버튼 클릭시 onClickCustomActionButton 이벤트가 발생한다.'
        },
        { name: 'prefix', type: CommonType.string, optional: true, description: '데이터 앞에 붙는 접두사' },
        { name: 'suffix', type: CommonType.string, optional: true, description: '데이터 뒤에 붙는 접미사' },
        { name: 'useTooltip', type: CommonType.boolean, optional: true, description: '마우스오버시 툴팁 사용여부' },
        { name: 'onlyIcon', type: CommonType.boolean, optional: true, description: '텍스트없이 아이콘만 디스플레이할것인지 여부' },
        { name: 'footer', type: ['object'], optional: true, description: '푸터설정' },
        { name: 'unmanaged', type: CommonType.any, optional: true, description: '컬럼 공통. 리얼그리드 속성' },
        { name: 'guideMessage', type: ['string', 'React.Component'], optional: true, description: '컬럼 공통. 가이드메세지. ' },
        { name: 'dynamicStyles', type: CommonType.any, description: '컬럼 공통, 다이나믹 스타일', optional: true },
        { name: 'renderer', type: CommonType.any, description: '다중 아이콘 속성', optional: true },
        {
            name: 'useImageLabel', type: CommonType.boolean, optional: true, default: false, description: '데이터를 이미지 라벨로 표현한다.'
        },
        { name: 'linkable', type: CommonType.boolean, optional: true, description: '텍스트 데이터를 링크형태로 사용할것인지 여부 클릭시 onLinkableCellClicked 이벤트 발생' },
        { name: 'sortable', type: CommonType.boolean, optional: true, description: '해당 컬럼의 상단헤더 클릭을 이용한 정렬을 사용할것인지 여부' },
        { name: 'maxLength', type: CommonType.number, optional: true, description: 'type = text, number 계열일 경우 입력가능한 최대길이 number일경우 (전체길이.소수점길이) 형식으로 작성' },
        { name: 'numberColumnFormat', type: CommonType.string, description: "type = number일 경우 숫자의 포메팅스트링", optional: true },
        { name: 'nanToZero', optional: true, type: CommonType.boolean, description: 'type = number일 경우. null등의 숫자가 아닌 값을 0으로 해석하게 한다.', default: false },
        { name: 'showZero', optional: true, type: CommonType.boolean, default: false, description: "type = number일 경우. 0을 그리드에 표기할지 여부'" },
        {
            name: 'calculateCallback', type: CommonType.function, optional: true, description: 'type = number일 경우 자동계산되는 값을 리턴하는 콜백'
        },
        { name: "positiveOnly", type: CommonType.boolean, optional: true, description: 'type = number일 경우 양수만 입력받을것인지 여부' },
        { name: 'showDayOfWeek', type: ['boolean', '(original:number) => string'], optional: true, description: 'type = date일 경우 요일을 표기할것인지 여부' },
        { name: "dateFormat", type: ['yyyyMMdd', 'yyyyMM', 'yyyy', 'iso'], optional: true, description: 'type = date일 경우 컬럼의 날짜 포메팅' },
        { name: 'columns', optional: true, type: ['IColumn[]'], description: 'type이 group일 경우 자식이 되는 컬럼들 목록' },
        { name: 'trueValues', optional: true, type: ['string[]'], description: 'type = check일 경우 체크 처리되는 데이터의 목록' },
        { name: 'falseValues', optional: true, type: ['string[]'], description: 'type = check일 경우 체크해제 처리되는 데이터의 목록' },
        {
            name: 'maskType', type: ['custom', 'biznumber', 'conumber', 'resident', 'foreign', 'passport', 'driver', 'credit', 'account', 'tel', 'email'],
            description: 'type = mask일 경우 마스킹 방식', optional: true
        },
        { name: 'editMask', type: CommonType.string, optional: true, description: 'type = mask, maskType = custom일 경우, 셀의 에디터의 editMask' },
        {
            name: 'customMaskCallback', type: CommonType.function, optional: true, parameters: [
                { name: 'value', type: CommonType.string }
            ], result: CommonType.string, description: 'type = mask, maskType = custom일 경우 디스플레이되는 방식을 지정하는 콜백, 파라미터로 순수데이터가 들어오고 표시될 텍스트를 리턴시킨다.'
        },
        { name: 'useIcon', type: CommonType.boolean, description: '아이콘 렌더링을 사용할 것인지 여부', optional: true },
        { name: 'iconImageList', type: ['string[]'], description: 'useIcon = true일 경우 컬럼에서 사용할 이미지리스트', optional: true },
        { name: 'imageLabelBackgroundColor', type: ['(value: string) => string', 'string'], optional: true, description: "useImageLabel = true일 경우, 라벨의 배경색을 지정한다. string이나 string리턴 콜백 지정가능", default: '#81afb4' },
        { name: 'customImageLabelCallback', type: ['(value, backgroundColor) => HTMLCanvasElement', 'string'], optional: true, description: 'useImageLabel = true일 경우, canvas 엘리먼트를 커스텀한다.' },
    );

    /**
     * 그리드의 루트 div에 할당되는 클래스명
     */
    private readonly wrapperClassName = 'obt_list_grid_root_class_name_hard';

    public static defaultProps = {
        width: '100%',
        height: '100%',
    }

    public state: State = {
        interface: this.props.interface,
        displayMessageType: 'initial',
        hover: {
            visible: false,
            rowIndex: -1
        }
    }

    public myRefs = {
        grid: React.createRef<HTMLDivElement>(),
        wrapper: React.createRef<HTMLDivElement>(),
        hover: React.createRef<HTMLDivElement>()
    }

    private wrapperId = `gridList_${uuid()}`;
    private resizeSensor: ResizeSensor | null = null;
    private resizeHandler = debounce((() => {
        this.state.interface.refresh(false);
        this.state.interface._invokeColumnWidthChangedWhenResize();
        this.setEmptyBoundary();
    }), 300, { leading: true });

    // handleReorderMouseDown = (e: React.MouseEvent) => {
    //     if (this.myRefs.wrapper.current) {
    //         e.preventDefault();
    //         e.stopPropagation();

    //         // onInnerDragStart 호출
    //         if (this.props.interface) {
    //             if (this.props.interface.handleInnerDragStart(this.props.interface.gridView, { startRow: this.state.hover.rowIndex }) === false) {
    //                 return;
    //             }
    //         }


    //         document.addEventListener('mouseup', this.handleReorderMouseUp, true);
    //         const image = this.myRefs.wrapper.current.querySelector<HTMLCanvasElement>(`#${this.state.interface._gridElementId} > div > canvas`)!.toDataURL('image/png', 1);
    //         this.setState({
    //             hover: {
    //                 ...this.state.hover,
    //                 reorder: true,
    //                 reorderIndex: this.state.hover.rowIndex,
    //                 reorderImage: <img className={styles.reorderImage} src={image} alt={''} style={{
    //                     top: `calc(${this.state.hover.boundary.top} * -1 - 1px)`,
    //                     left: `calc(${this.state.hover.moveThumbBoundary.left} * -1)`
    //                 }} />
    //             }
    //         });
    //     }
    // }

    handleReorderMouseUp = (e: MouseEvent) => {
        if (this.myRefs.wrapper.current && this.state.interface.getRowCount() > 0) {
            const event = {
                clientX: e.clientX,
                clientY: e.clientY
            };
            if (this.state.hover.rowIndex >= 0 && this.state.hover.reorderIndex !== this.state.hover.rowIndex) {
                this.state.interface.handleInnerDrop(this.state.interface.gridView, { dataRow: this.state.hover.rowIndex }, { startRow: this.state.hover.reorderIndex });
            }
            this.setState({
                hover: {
                    ...this.state.hover,
                    reorder: false,
                    reorderIndex: -1,
                    reorderImage: undefined
                }
            }, () => {
                setTimeout(() => {
                    this.setHover(false, true, event);
                }, 0);
            });
        }
        document.removeEventListener('mouseup', this.handleReorderMouseUp, true);
    }

    handleMouseDown = (e: React.MouseEvent) => {
        if (this.myRefs.wrapper.current) {
            const wrapperBoundary = this.myRefs.wrapper.current.getBoundingClientRect();
            const location = {
                x: Math.floor(e.clientX - wrapperBoundary.left),
                y: Math.floor(e.clientY - wrapperBoundary.top)
            };
            const index = this.state.interface.gridView.mouseToIndex(location.x, location.y);

            if (index.column === OBTListGridInterface.ROW_MOVE_COLUMN_NAME) {
                if (this.props.interface) {
                    if (this.props.interface.handleInnerDragStart(this.props.interface.gridView, { startRow: this.state.hover.rowIndex }) === false) {
                        return;
                    }
                }

                document.addEventListener('mouseup', this.handleReorderMouseUp, true);
                const image = this.myRefs.wrapper.current.querySelector<HTMLCanvasElement>(`#${this.state.interface._gridElementId} > div > canvas`)!.toDataURL('image/png', 1);
                this.setState({
                    hover: {
                        ...this.state.hover,
                        reorder: true,
                        reorderIndex: this.state.hover.rowIndex,
                        reorderImage: <img className={styles.reorderImage} src={image} alt={''} style={{
                            top: `calc(${this.state.hover.boundary.top} * -1 - 1px)`,
                            left: `calc(${this.state.hover.moveThumbBoundary.left} * -1)`
                        }} />
                    }
                });
            }
        }

    };


    handleMouseUp = (e: React.MouseEvent) => {
        if (this.myRefs.wrapper.current && this.state.interface.getRowCount() > 0) {
            const event = {
                clientX: e.clientX,
                clientY: e.clientY
            };
            if (this.state.hover.rowIndex >= 0 && this.state.hover.reorderIndex !== this.state.hover.rowIndex) {
                if (this.state.hover.reorderIndex && this.state.hover.reorderIndex >= 0) {
                    this.state.interface.handleInnerDrop(this.state.interface.gridView, { dataRow: this.state.hover.rowIndex }, { startRow: this.state.hover.reorderIndex });
                }
            }
            this.setState({
                hover: {
                    ...this.state.hover,
                    reorder: false,
                    reorderIndex: -1,
                    reorderImage: undefined
                }
            }, () => {
                setTimeout(() => {
                    this.setHover(false, true, event);
                }, 0);
            });
        }
        document.removeEventListener('mouseup', this.handleReorderMouseUp, true);
    };

    public _updateHover = () => {
        if (this.state.hover.visible && this.state.hover.mousePosition) {
            this.setHover(false, false, this.state.hover.mousePosition);
        }
    }

    private setHover = ((leaved: boolean, refresh: boolean, e: { clientX: number, clientY: number }) => {
        const { gridView } = this.state.interface;
        let currentColumn: string | null = null;
        const newHover = Object.assign({}, this.state.hover);
        let hoverBoundary: any = null;
        const scrollBarWidth = this.state.interface.gridOption.scrollBarWidth ? this.state.interface.gridOption.scrollBarWidth : 16;

        if (leaved === false) {
            if (this.myRefs.wrapper.current) {
                const wrapperBoundary = this.myRefs.wrapper.current.getBoundingClientRect();
                const location = {
                    x: Math.floor(wrapperBoundary.width / 2),
                    y: Math.floor(e.clientY - wrapperBoundary.top)
                };
                const index = gridView.mouseToIndex(location.x, location.y);
                newHover.rowIndex = index ? index.itemIndex : -1;
                const currentColumnIndex = gridView.mouseToIndex(Math.floor(e.clientX - wrapperBoundary.left), location.y);
                currentColumn = currentColumnIndex ? currentColumnIndex.column : null;
                const cellBoundary = index ? gridView.getCellBounds(newHover.rowIndex, index.column, false) : null;
                if (cellBoundary) {
                    const height = this.state.interface.gridOption.eachRowResizable ?
                        [...new Array(gridView.getItemCount())].map((dummy, index) => gridView.getRowHeight(index)).reduce((heights, height) => heights + height, 0) :
                        gridView.getItemCount() * cellBoundary.height;
                    const verticalScrollVisible = (wrapperBoundary.height - ((gridView.getHeader() || {}).height || 0) - ((gridView.getFooter() || {}).height || 0)) < height;
                    hoverBoundary = {
                        top: cellBoundary.y,
                        right: verticalScrollVisible ? scrollBarWidth : 0,
                        height: cellBoundary.height
                    }
                }
                newHover.visible = newHover.rowIndex >= 0 && hoverBoundary ? true : false;
            }
        } else {
            newHover.visible = false;
        }

        if (refresh || newHover.visible !== this.state.hover.visible || newHover.rowIndex !== this.state.hover.rowIndex) {
            if (newHover.visible && newHover.rowIndex >= 0) {
                if (hoverBoundary) {
                    newHover.boundary = {
                        left: `0px`,
                        top: `${hoverBoundary.top - 1}px`,
                        right: `${hoverBoundary.right}px`,
                        height: `${hoverBoundary.height + 1}px`
                    };
                }

                newHover.moveThumbBoundary = {
                    left: `${(this.state.interface.getGridOption().indicator ? 40 : 0) +
                        (this.state.interface.getGridOption().checkable || this.state.interface.getGridOption().radioable ? 30 : 0)}px`
                }

                this.state.interface.handleMouseHover(null, { itemIndex: newHover.rowIndex, column: currentColumn || '' }, '');

                let actionButtons = this.state.interface.actionButtons ? this.state.interface.actionButtons.filter(a => a.visible !== false) : null;
                if (actionButtons && actionButtons.length > 0) {
                    // 액션버튼 디폴트 처리
                    actionButtons = actionButtons.map((item) => {
                        if (item.imageUrl && typeof item.imageUrl !== 'string') {
                            return {
                                ...item,
                                imageUrl: {
                                    ...{
                                        disabled: item.imageUrl.normal,
                                        normal: item.imageUrl.normal,
                                        over: item.imageUrl.normal,
                                        press: item.imageUrl.over
                                    },
                                    ...item.imageUrl,
                                }
                            }
                        } else {
                            return item;
                        }
                    });

                    newHover.actionButtons = actionButtons.map((actionButton, index) => {
                        return actionButton.component ? <Fragment key={index}>{actionButton.component}</Fragment> :
                            <OBTButton
                                key={index}
                                useMouseOverBackground={actionButton.useMouseOverBackground}
                                type={OBTButton.Type.icon}
                                icon={actionButton.icon}
                                imageUrl={actionButton.imageUrl}
                                disabled={actionButton.disabled}
                                tooltip={actionButton.tooltip}
                                onClick={(e) => this.handleFunctionButtonClick(actionButton, e.event)} />;
                    });
                } else {
                    newHover.actionButtons = undefined;
                }
            }

            this.setState({
                hover: {
                    ...newHover,
                    mousePosition: {
                        clientX: e.clientX,
                        clientY: e.clientY
                    }
                }
            });
        }
    });

    handleMouseLeave = (e: React.MouseEvent) => {
        if (this.state.hover.visible) {
            this.setHover(true, false, e);
        }
    }

    handleMouseMove = (e: React.MouseEvent) => {
        if (this.myRefs.wrapper.current && this.state.interface.getRowCount() > 0) {
            this.setHover(false, false, e);
        }
    };

    componentDidMount(): void {
        try {
            this.state.interface._initialize(this);

            this.setEmptyBoundary().then(() => {
                // root 요소에 mousemove 설정
                if (this.myRefs.wrapper.current) {
                    this.resizeSensor = new ResizeSensor(this.myRefs.wrapper.current, this.resizeHandler);
                }

                this.state.interface.onScroll.addToFirst((e) => {
                    if (this.state.hover.mousePosition) {
                        this.setHover(false, true, this.state.hover.mousePosition);
                    }
                });

                this.state.interface.onAfterSelectChange.addToFirst((e) => {
                    if (e.rowIndex === -1) {
                        this.setState({
                            hover: {
                                visible: false,
                                rowIndex: -1
                            }
                        })
                    }
                });


                this.state.interface.onAfterRead.addToFirst((e) => {
                    if (e.data.length === 0) {
                        this.setState({
                            hover: {
                                visible: false,
                                rowIndex: -1
                            }
                        })
                    }
                });
            });

            if (this.myRefs.grid.current) {
                this.myRefs.grid.current['gridView'] = this.state.interface.gridView;
            }
        }
        catch (e) {
            Util.getErrorState(e);
        }
    }

    private setEmptyBoundary() {
        return new Promise<void>((resolve) => {
            const height = this.myRefs.wrapper.current ? this.myRefs.wrapper.current.clientHeight : 0;
            const emptyBoundaryTop = (() => {
                if (this.state.interface.gridView) {
                    return this.state.interface.gridView.getHeader().height + 2;
                }
                return 0;
            })();
            const emptyBoundaryBottom = (() => {
                if (this.state.interface.gridView) {
                    return this.state.interface.gridView.getFooter().height + 1;
                }
                return 0;
            })();
            this.setState({
                emptyBoundary: {
                    top: emptyBoundaryTop,
                    height: height - emptyBoundaryTop - emptyBoundaryBottom
                }
            }, () => resolve());
        })
    }

    // componentDidUpdate(): void {
    //     try {
    //         this.refreshOnVisibleChanged();
    //     }
    //     catch (e) {
    //         Util.getErrorState(e);
    //     }
    // }

    /**
     * @ignore
     */
    componentWillUnmount(): void {
        if (this.resizeSensor) {
            this.resizeSensor.detach(this.resizeHandler);
        }

        document.removeEventListener('mouseup', this.handleReorderMouseUp, true);
    }

    public focus(): void {
        if (this.myRefs.wrapper.current) {
            this.myRefs.wrapper.current.focus()
        }
    }

    public refresh(): void {
        if (this.state.interface) {
            this.state.interface.refresh();
        }
    }

    render() {
        return <ErrorBoundary owner={this} render={this.renderComponent} />
    }

    renderComponent = () => {
        const id = this.state.interface._gridElementId;
        const paging = this.state.interface.getGridOption().paging && this.state.interface.pageCount > 0 ?
            <OBTPagination
                currentPage={this.state.interface.currentPage}
                pageCount={this.state.interface.pageCount}
                rowCountPerPage={this.state.interface.rowCountPerPage}
                totalCount={this.state.interface.totalCount}
                pagingBlock={this.state.interface.getGridOption().pagingBlock}
                isPageTotalCountText={this.state.interface.getGridOption().isPageTotalCountText}
                useCountDropDownList={this.state.interface.getGridOption().useRowCountDropDownList}
                onChange={this.handleChange.bind(this)} /> : null;
        const emptyDataMsg = this.state.displayMessageType === 'initial' ? this.state.interface.getGridOption().emptyDataMsg :
            this.state.displayMessageType === 'noData' ? this.state.interface.getGridOption().emptySearchMsg :
                '';
        const emptyDataImage = this.state.displayMessageType === 'initial' ? this.state.interface.getGridOption().emptyDataImage :
            this.state.displayMessageType === 'noData' ? this.state.interface.getGridOption().emptySearchImage :
                undefined;
        const emptySizeStyle = (() => {
            if (this.state.emptyBoundary) {
                if (this.state.emptyBoundary.height < (42 + 10 + 24)) {
                    return styles.tiny;
                } else if (this.state.emptyBoundary.height < (100 + 10 + 24)) {
                    return styles.small;
                }
            }
            return undefined;
        })();
        const useEmptySet = this.state.interface.gridOption.useEmptySet === false ? false : true;
        return (
            <div className={styles.rootDiv}>
                <div id={this.wrapperId}
                    className={Util.getClassNames(this.wrapperClassName, styles.root, this.state.hover.reorder ? styles.dragging : undefined, this.props.className)}
                    style={Util.getWrapperStyle(this.props)}
                    ref={this.myRefs.wrapper}
                    onMouseMoveCapture={this.handleMouseMove}
                    onMouseLeave={this.handleMouseLeave}
                    onMouseDown={this.handleMouseDown}
                    onMouseUp={this.handleMouseUp}
                >
                    <div id={id}
                        ref={this.myRefs.grid}
                        data-orbit-id={this.state.interface.id}
                        data-orbit-component={'OBTListGrid'}
                        className={useEmptySet && (this.state.displayMessageType === 'initial' || this.state.displayMessageType === 'noData') ? styles.initGrid : styles.grid}
                        tabIndex={-1}
                        onFocus={this.handleFocus.bind(this)}
                        onBlur={this.handleBlur.bind(this)}
                    >
                    </div>
                    <div className={Util.getClassNames(styles.emptyDataRoot,
                        useEmptySet ? (this.state.displayMessageType === 'initial' ? styles.initial : this.state.displayMessageType === 'noData' ? styles.noData : undefined) : undefined,
                        this.state.interface.getRowCount() <= 0 ? styles.visible : undefined,
                        emptySizeStyle)}
                        style={{
                            top: `${this.state.emptyBoundary ? this.state.emptyBoundary.top : 0}px`
                        }}
                    >
                        {emptyDataImage ? emptyDataImage : <div className={styles.emptyDataImage} />}
                        <div className={styles.emptyDataText}>{emptyDataMsg}</div>
                    </div>
                    <div className={styles.hoverWrapper}>
                        <div className={Util.getClassNames(styles.hoverRoot, this.state.hover.visible ? styles.visible : undefined)} style={this.state.hover.boundary}>
                            {this.state.interface.getGridOption().rowMovable ?
                                <div className={styles.reorderRoot} style={this.state.hover.moveThumbBoundary}>
                                    <div className={Util.getClassNames(styles.reorderImageRoot, this.state.hover.reorder ? styles.on : undefined)}>
                                        {this.state.hover.reorderImage ? this.state.hover.reorderImage : undefined}
                                    </div>
                                    {
                                        this.state.hover.reorder === true ? (
                                            <div className={Util.getClassNames(styles.moveThumb, this.state.hover.reorder === true ? styles.on : undefined)} />
                                        ) : null
                                    }
                                    {/* <div className={Util.getClassNames(styles.moveThumb, this.state.hover.reorder === true ? styles.on : undefined)} onMouseDown={this.handleReorderMouseDown} /> */}
                                </div>
                                : undefined}
                            {!this.state.hover.reorder && this.state.hover.actionButtons ?
                                <div className={styles.hoverActionButtonRoot}>
                                    {this.state.hover.actionButtons}
                                </div> : undefined}
                        </div>
                    </div>
                </div>
                {paging}
            </div>
        )
    }

    handleFunctionButtonClick(functionButton: IActionButton, e: React.MouseEvent) {
        if (functionButton.onClick) {
            functionButton.onClick(new Events.MouseEventArgs(this, e));
        }
    }

    private handleChange(e: Events.ChangeEventArgs<TypeList>): void {
        this.state.interface.readData(e.value.currentPage, e.value.rowCountPerPage);
    }

    handleFocus() {
        if (this.state.interface)
            this.state.interface._handleFocus();
    }

    handleBlur() {
        setTimeout(() => {
            if (this.state.interface)
                this.state.interface._handleBlur(this.myRefs.wrapper);
        }, 0);
    }

    private refreshOnVisibleChanged() {
        if (this.myRefs.wrapper.current && this.state.interface) {
            const style = window.getComputedStyle(this.myRefs.wrapper.current);
            const visible = style.display !== 'none' && style.visibility !== 'hidden' && this.myRefs.wrapper.current.offsetParent !== null;
            const canvas = this.myRefs.wrapper.current.querySelector("canvas");
            const canvasSize = {
                height: canvas ? (canvas.getBoundingClientRect() || {}).height : 0,
                width: canvas ? (canvas.getBoundingClientRect() || {}).width : 0
            };
            if (visible && (canvasSize.height <= 0 || canvasSize.width <= 0)) {
                this.state.interface.refresh(false);
            }
        }
    }

}