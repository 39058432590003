/**
 * OBTLoadingView
 * @version 0.1
 * @author 박재성
 * @see LUXFCircularProgress
 */
import * as React from 'react';
import LUXFCircularProgress from 'luna-rocket/LUXProgress/LUXFCircularProgress';
import { CommonDefinitions, CommonProps, CommonType, createPropDefinitions, toEnumType, Util } from '../Common';
import { hasError } from '../Common/CommonState';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { OBTContext } from '../OBTPageContainer/OBTPageContainer';
import OBTPortal from '../OBTPortal';
import OBTButton from '../OBTButton';
import { OrbitInternalLangPack } from '../Common/Util';

const styles = require('./OBTProgress.module.scss');

enum Type {
    'small' = 'small',
    'default' = 'default',
    'large' = 'large'
}

enum States {
    'default' = 'default',
    'error' = 'error',
    'warning' = 'warning'
}

interface IOBTProgress extends CommonProps.id, CommonProps.className, CommonProps.value<number>, CommonProps.labelText, CommonProps.width, CommonProps.height {
    /**
     * @default default
     * 로딩뷰의 원의 크기
     */
    type: Type,

    /**
     * @default true
     * 풀 스크린 여부
     */
    fullScreen: boolean,

    /**
     * @default false
     * 여닫 여부
     */
    open: boolean,

    /**
     * @default default
     * 색상
     */
    states: States,

    /**
     * 취소 버튼 유무
     */
    hasCancelButton?: boolean

    /**
     * 취소 버튼 클릭시 발생하는 함수
     */
    onCancelButtonClick?: (e: any) => void
}

interface State extends hasError {
}

export default class OBTProgress extends React.Component<IOBTProgress, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.id(),
        CommonDefinitions.className(),
        { name: "value", type: CommonType.number, description: "원 안의 퍼센테이지 값을 지정합니다." },
        { name: "labelText", type: CommonType.string, description: "원 아래 텍스트 값을 지정합니다." },
        CommonDefinitions.width(),
        CommonDefinitions.height(),
        { name: "type", type: toEnumType(Type), description: "원의 크기를 지정합니다.", optional: true, default: "default" },
        { name: "fullScreen", type: CommonType.boolean, description: "OBTProgress Modal이 적용되는 범위를 화면가득 채울지 특정 영역만 채울지를 결정 설정합니다.", optional: true, default: true },
        { name: "open", type: CommonType.boolean, default: false, description: "OBTProgress이 열려있는지 닫혀있는지 설정합니다." },
        { name: "states", type: toEnumType(States), description: "원의 상태(색깔)를 지정합니다. \ndefault (기본) : 파란색 / warning (경고) : 노란색 / error (에러) : 빨간색", default: "default", optional: true }
    )

    public static Type = Type
    public static States = States

    /**
     * Default Props 설정
     */
    public static defaultProps = {
        type: Type.default,
        state: States.default,
        fullScreen: true, // 풀스크린 여부
        open: false
    }

    public state: State = {}

    // 키 입력 방지
    componentDidMount() {
        this.setEvent();
    }

    componentDidUpdate() {
        this.setEvent();
    }

    componentWillUnmount() {
        this.setEvent(true);
    }

    private setEvent = (remove?: boolean) => {
        document.removeEventListener('keydown', this.handleKeyEvents, true);
        if (!remove && this.props.fullScreen && this.props.open) {
            document.addEventListener('keydown', this.handleKeyEvents, true);
        }
    }

    private handleKeyEvents = (e: KeyboardEvent) => {
        e.stopPropagation();
        e.preventDefault();
    }

    renderComponent = () => {
        const defualtColor = this.props.states === OBTProgress.States.default ? "#1C90FB" : this.props.states === OBTProgress.States.error ? "#fc5356" : "#febc2c"
        const labelText = (this.props.type === OBTProgress.Type.default || this.props.type === OBTProgress.Type.small) ? <span className={styles.labelTextSpan}>{this.props.labelText}</span> : "";

        const props = {
            type: "small",
            color: defualtColor,
            size: this.props.type === Type.small ? 21 : this.props.type === Type.default ? 63 : 191,
            guideText: labelText,
            visible: this.props.open,
            value: this.props.value,
            dimmedStyle: { background: '#fff' }
        };

        const component = <LUXFCircularProgress {...props} />

        const windowBackground = (this.props.type === OBTProgress.Type.default || this.props.type === OBTProgress.Type.large) ? styles.windowBackground : undefined;

        const boxSize = this.props.type === OBTProgress.Type.small ? styles.small :
            this.props.type === OBTProgress.Type.default ? styles.default : styles.large

        const boxBackground = (this.props.type === OBTProgress.Type.default || this.props.type === OBTProgress.Type.small) ? styles.boxBackground : undefined;

        const baseComponent =
            <div id={this.props.id} data-orbit-component={'OBTProgress'} className={Util.getClassNames(styles.root, this.props.fullScreen ? styles.full : styles.notFull, this.props.open ? null : styles.closed, this.props.fullScreen ? this.props.className : null)}>
                <div className={Util.getClassNames(styles.wrapper)}>
                    {windowBackground && this.props.open ?
                        <Fade duration={3000}>
                            <div className={windowBackground}>
                            </div>
                        </Fade> : null}
                    {this.props.open ?
                        <Bounce duration={300}>
                            <div className={Util.getClassNames(styles.box, boxSize, boxBackground)}>
                                {component}
                                {this.props.hasCancelButton && this.props.type === OBTProgress.Type.default ?
                                    <OBTButton
                                        className={styles.button}
                                        width='50px'
                                        labelText={OrbitInternalLangPack.getText('WE000001945', '취소')}
                                        onClick={(e) => this.props.onCancelButtonClick && this.props.onCancelButtonClick(e)}
                                    /> : <></>}
                            </div>
                        </Bounce> : null}
                </div>
            </div>

        const fullComponent =
            <>
                {this.props.children}
                {baseComponent}
            </>

        const size = {
            width: this.props.width,
            height: this.props.height
        }

        const sectionComponent =
            <div id={this.props.id} data-orbit-component={'OBTProgress'} className={Util.getClassNames(styles.section, this.props.className)} style={size}>
                {this.props.children}
                {baseComponent}
            </div>

        return (this.props.fullScreen ? fullComponent : sectionComponent);
    }

    render() {
        return <OBTContext.Consumer>
            {
                value => {
                    const resultDOM = (
                        <ErrorBoundary
                            owner={this}
                            render={this.renderComponent}
                        />
                    )

                    if (this.props.fullScreen === true && React.Children.count(this.props.children) === 0) {
                        return <OBTPortal>{value.isPageVisible === true ? resultDOM : <></>}</OBTPortal>;
                    } else {
                        return (value.isPageVisible === true ? resultDOM : <></>);
                    }
                }
            }
        </OBTContext.Consumer>
    }
};
