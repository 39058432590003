/**
 * OBTCodePickerDialog
 * @version 0.1
 * @author 나윤수
 * @see common.js
 */
import React, { Component } from 'react';
import { Events } from '../../Common';
import { OrbitInternalLangPack } from '../../Common/Util';
import { OBTConditionItem, OBTConditionPanel } from '../../OBTConditionPanel';
import { SearchEventArgs } from '../../OBTConditionPanel/OBTConditionPanel';
import OBTTextField from '../../OBTTextField';
import { CodePickerSearchEventArgs } from '../Events/CodePickerSearchEventArgs';
import OBTDropDownList2 from '../../OBTDropDownList2';
import OBTDatePicker from '../../OBTDatePicker';
import OBTCheckBox from '../../OBTCheckBox';
import OBTMaskedTextField from '../../OBTMaskedTextField';

export interface IDefaultDialogConditionPanel {
    /**
     * 부모인 다이얼로그가 열렸는지 여부
     */
    isDialogOpen: boolean,

    keyword?: string

    parameters?: any,

    /**
     * 
     */
    isLoading?: boolean,

    /**
     * 조회조건의 검색버튼 클릭 콜백
     */
    onClickSearch: (e: CodePickerSearchEventArgs) => void,

    /**
     * 
     */
    dialogParameters?: (() => any)

    /**
     * fetch
     */
    fetch?: any
}

export interface IState {
    searchText: string,
    // useYn: "Y" | 'N',
}

export interface DefaultDialogConditionPanelRef {
    focus?: () => void,
    clear?: () => void,
}

export default class DefaultDialogConditionPanel extends Component<IDefaultDialogConditionPanel, IState> implements DefaultDialogConditionPanelRef {

    private inputRef = React.createRef<OBTTextField>();

    public state: IState = {
        searchText: this.props.keyword || '',
        // useYn: 'Y'
    }

    // constructor(props: IDefaultDialogConditionPanel) {
    //     super(props);
    // }

    clear = () => {
        this.setState({
            searchText: '',
            // useYn: 'Y'
        })
    }

    focus = () => {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    handleOnSearch = (evt: SearchEventArgs) => {
        const eventArgs = new CodePickerSearchEventArgs(
            this,
            'dialog',
            undefined,
            {
                searchText: this.state.searchText,
                // useYn: this.state.useYn
            },
            this.state.searchText.length === 0
        );
        this.props.onClickSearch(eventArgs);
    }

    render() {
        return (
            <OBTConditionPanel onSearch={this.handleOnSearch}>
                <OBTConditionItem labelText={OrbitInternalLangPack.getText('WE000000238', '검색어')} >
                    <OBTTextField
                        ref={this.inputRef}
                        onChange={(e: Events.ChangeEventArgs<string>) => {
                            this.setState({ searchText: e.value })
                        }}
                        value={this.state.searchText}
                    />
                </OBTConditionItem>
                {/* <OBTConditionItem labelText='사용여부' >
                    <OBTDropDownList
                        onChange={(e: Events.ChangeEventArgs<string>) => {
                        }}
                        value={'Y'}
                        list={[
                            { value: 'Y', labelText: '사용' },
                            { value: 'N', labelText: '미사용' },
                        ]}
                    />
                </OBTConditionItem> */}
            </OBTConditionPanel>
        )
    }
}

export interface CodePickerDialogConditionPanelFactoryItem {
    componentName: string,
    valueProperty: string,
    labelText: string,
    componentProps?: any
    defaultValue?: any,
    isSearchText?: boolean,
    isOptional?: boolean
}
interface IFactoryBaseDialogConditionPanel extends IDefaultDialogConditionPanel {
    factoryItems: CodePickerDialogConditionPanelFactoryItem[]
}

export class FactoryBaseDialogConditionPanel extends Component<IFactoryBaseDialogConditionPanel, any> implements DefaultDialogConditionPanelRef {
    private inputRef = React.createRef<any>();

    public state = {
        // useYn: 'Y'
        conditionItems: [],
        value: { searchText: '' }
    }

    // constructor(props: IFactoryBaseDialogConditionPanel) {
    //     super(props);
    // }

    componentDidMount() {
        const value = {};
        this.props.factoryItems.forEach((item) => {
            if (item.defaultValue) {
                value[item.valueProperty] = item.defaultValue;
            }

            if (item.isSearchText === true) {
                value[item.valueProperty] = this.props.keyword || '';
            }
        })

        if (Object.keys(value).length > 0) {
            this.setState({
                value: value
            })
        }
    }

    clear = () => {
        this.setState({
            value: {}
        })
    }

    focus = () => {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    handleOnSearch = (evt: SearchEventArgs) => {
        const eventArgs = new CodePickerSearchEventArgs(
            this,
            'dialog',
            undefined,
            this.state.value,
        );
        this.props.onClickSearch(eventArgs);
    }

    render() {
        const jsxList = this.props.factoryItems.map((item, index) => {
            if (item.componentName === 'OBTTextField') {
                return (
                    <OBTConditionItem labelText={item.labelText} key={index} optional={item.isOptional}>
                        <OBTTextField
                            {...item.componentProps}
                            ref={index === 0 ? this.inputRef : null}
                            value={this.state.value[item.valueProperty]}
                            onChange={(e) => {
                                this.setState({
                                    value: {
                                        ...this.state.value,
                                        [item.valueProperty]: e.value,
                                    }
                                }, () => {
                                    console.log(this.state.value)
                                })
                            }}
                        />
                    </OBTConditionItem>
                )
            } else if (item.componentName === 'OBTDropDownList' || item.componentName === 'OBTDropDownList2') {
                return (
                    <OBTConditionItem labelText={item.labelText} key={index} optional={item.isOptional}>
                        <OBTDropDownList2
                            {...item.componentProps}
                            ref={index === 0 ? this.inputRef : null}
                            value={this.state.value[item.valueProperty]}
                            onChange={(e) => {
                                this.setState({
                                    value: {
                                        ...this.state.value,
                                        [item.valueProperty]: e.value,
                                    }
                                }, () => {
                                    console.log(this.state.value)
                                })
                            }}
                        />
                    </OBTConditionItem>
                )
            } else if (item.componentName === 'OBTDatePicker') {
                let defaultValue = this.state.value[item.valueProperty];
                const isPeriodType = item.componentProps && item.componentProps.type && item.componentProps.type === OBTDatePicker.Type.period;
                if (isPeriodType) {
                    defaultValue = { from: '', to: '' };
                }

                return (
                    <OBTConditionItem labelText={item.labelText} key={index} optional={item.isOptional}>
                        <OBTDatePicker
                            {...item.componentProps}
                            ref={index === 0 ? this.inputRef : null}
                            value={this.state.value[item.valueProperty] || defaultValue}
                            onChange={(e) => {
                                this.setState({
                                    value: {
                                        ...this.state.value,
                                        [item.valueProperty]: e.value,
                                    }
                                }, () => {
                                    console.log(this.state.value)
                                })
                            }}
                        />
                    </OBTConditionItem>
                )
            } else if (item.componentName === 'OBTCheckBox') {
                return (
                    <OBTConditionItem labelText={item.labelText} key={index} optional={item.isOptional}>
                        <OBTCheckBox
                            {...item.componentProps}
                            ref={index === 0 ? this.inputRef : null}
                            value={this.state.value[item.valueProperty]}
                            onChange={(e) => {
                                this.setState({
                                    value: {
                                        ...this.state.value,
                                        [item.valueProperty]: e.value,
                                    }
                                }, () => {
                                    console.log(this.state.value)
                                })
                            }}
                        />
                    </OBTConditionItem>
                )
            } else if (item.componentName === 'OBTMaskedTextField') {
                return (
                    <OBTConditionItem labelText={item.labelText} key={index} optional={item.isOptional}>
                        <OBTMaskedTextField
                            {...item.componentProps}
                            ref={index === 0 ? this.inputRef : null}
                            value={this.state.value[item.valueProperty]}
                            onChange={(e) => {
                                this.setState({
                                    value: {
                                        ...this.state.value,
                                        [item.valueProperty]: e.value,
                                    }
                                }, () => {
                                    console.log(this.state.value)
                                })
                            }}
                        />
                    </OBTConditionItem>
                )
            } else {
                console.warn('FactoryBaseDialogConditionPanel 알수없는 컴포넌트명: ' + item.componentName);
            }

            return null;
        });

        return (
            <OBTConditionPanel onSearch={this.handleOnSearch}>
                {jsxList}
            </OBTConditionPanel>
        )
    }
}