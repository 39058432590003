import { ResizeSensor } from 'css-element-queries'
import keycode from "keycode"
import debounce from 'lodash.debounce'
import moment from 'moment'
import { parse } from 'node-html-parser'
import * as React from 'react'
import Draggable from 'react-draggable'
import uuid from 'uuid/v1'
import { OBTButton, OBTFormPanel, OBTTextField, OBTSnackbar } from '..'
import { CommonDefinitions, CommonType, CompositeProps, createPropDefinitions, Events, Util } from '../Common'
import { hasError } from '../Common/CommonState'
import { IRefreshable } from '../Common/Functions'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import emptyDataImg from '../Images/img_empty_data.png'
import emptyDataImgSmall from '../Images/img_empty_data_s.png'
import emptySearchImg from '../Images/img_empty_search.png'
import emptySearchImgSmall from '../Images/img_empty_search_s.png'
import { OBTCodePickerDialogController } from '../OBTCodePicker'
import DefaultDialog from '../OBTCodePicker/BaseComponent/DefaultDialog'
import IBuiltInCodePicker, { CodePickerSize } from '../OBTCodePicker/DataSource/IBuiltInCodePicker'
import SimpleJsonCodePickerDataSource from '../OBTCodePicker/DataSource/Impl/SimpleJsonCodePickerDataSource'
import { CodePickerValue } from '../OBTCodePicker/OBTCodePicker'
import { PositionType } from '../OBTFloatingPanel/OBTFloatingPanel'
import { DateFormat } from '../OBTListGrid/IColumn'
import { IOBTContext, OBTContext } from '../OBTPageContainer/OBTPageContainer'
import { ISideBarContent } from '../OBTPageContainer/SideBar'
import OBTYearFieldDialog, { AlignType } from '../OBTSingleYearPicker/OBTYearFieldDialog'
import UFODateFieldDialog from '../UFO/UFODateField/UFODateFieldDialog'
import UFOMonthFieldDialog from '../UFO/UFOMonthField/UFOMonthFieldDialog'
import ExcelExportDialog from './ExcelExportDialog'
import { ColumnType } from './IColumn'
import * as GridEvents from './OBTDataGridEvents'
import { GridCodePickerCellSearchEventArgs } from './OBTDataGridEvents'
import OBTDataGridInterface, { EmptySetSize, EmptySetType, GridState } from './OBTDataGridInterface'
import Slide from 'react-reveal/Slide';
import OBTLinearProgress from '../OBTLinearProgress';
import './OBTDataGridStyle.css'
import { OrbitInternalLangPack } from '../Common/Util'
import UserCustomColumnDialog from './UserCustomColumnDialog'
import OBTAlert from '../OBTAlert'
import OBTConfirm from '../OBTConfirm'
import { ignoreException } from '../Common/OrbitInternalUtil'
import GridUtil from './GridBase/GridUtil'
import OBTDropDownList2 from '../OBTDropDownList2'
import { DisplayType } from '../OBTDropDownList2/OBTDropDownList2'
import AutoCompleteDialog from './AutoCompleteDialog'
import { makePagination } from './Pagination'
import CodePickerUtil from '../OBTCodePicker/CodePickerUtil'
import { usePageAuthority } from '../Common/CommonProps'
import { onFocus } from '../Common/Events'
import detector from 'detector'

/**
 * @ignore
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RealGridJS: any = require('./RealGridJS').default

/**
 * @ignore
 */
const styles = require('./OBTDataGrid.module.scss')

/**
 * OBTDataGrid 컴포넌트의 props
 */
interface IOBTDataGrid extends CompositeProps.Default, usePageAuthority, onFocus {
    /**
     * 리얼그리드와의 연결을 담당하는 인터페이스
     */
    interface: OBTDataGridInterface,
}

/**
 * @ignore
 */
interface IState extends hasError {
    interface: OBTDataGridInterface,
    /**
     * 날짜 다이얼로그의 value
     */
    dateDialogValue: Date | string | number | null,
    currentCodePickerColumn?: {
        codePicker?: IBuiltInCodePicker,
        canMultiSelect: boolean,
        staticParameters: any,
        rowIndex: number,
        columnName: string,
        usePaging: boolean,
    } | null,
    // 코드피커 관련
    /**
     * 선택한 코드피커 아이템을 스트링으로 매핑하는 콜백
     */
    currentCodePickerSelectedValueDisplayCallback?: (e: any) => string,
    /**
     * 코드피커 입력을 취소했을때 호출되는 콜백
     */
    codePickerCancelCallback: () => void,
    /**
     * 
     */
    currentCodePickerKeyword: string | null,
    // codePickerSelectedItem: any[],
    isShowCodePickerDialog: boolean,
    currentDateColumn: {
        rowIndex: number,
        columnName: string,
        dateFormat: DateFormat,
        value: string,
        dateDialogAlign: AlignType,
        dateDialogPosition: PositionType,
    } | null,
    emptySetType: EmptySetType,
    emptySetSize: EmptySetSize,
    emptySetTopPotition: number,

    autoCompleteColumn: {
        width?: string,
        searchCallback?: any[],
        keyProperty: string,
        selectedIndex: number,
        correctKeyword?: string,
        isOpen: boolean,
        rowIndex: number,
        columnName: string,
        isSelectable: boolean,
        dialogAlign?: "left" | "center" | "right",
        useDirectValueMapping?: boolean,
        inputValue?: string
    },

    // 오른쪽 마우스 클릭 컨텍스트 박스 검색창 관련 searchBox
    searchBox: {
        isShowSearchBox: boolean,
        selectedSearchBoxDropdownValue: { value: string, labelText: string },
        keyword: string,
        // startIndex: number,
        // startFieldIndex: number
        //searchWhenPagingReachLast: boolean,
    }
    // export excel 관련
    isShowExportExcelDialog: boolean,
    isShowCustomColumnDialog: boolean,
    // dateColumnPointer: {
    //     x: number,
    //     y: number
    // } | null,
    alreadyRead: boolean,
    hover: {
        visible: boolean,
        rowIndex: number,
        boundary?: any,
        moveThumbBoundary?: any,
        actionButtons?: any,
        reorder?: boolean,
        reorderIndex?: number,
        reorderImage?: any,
        mousePosition?: { clientX: number, clientY: number }
    },
    progress?: {
        key?: string,
        visible?: boolean,
        labelText?: string,
        percent?: number
    },
    requiredSnackbar: {
        show: boolean,
        labelText?: string
    },
    noSearchSnackbar: {
        show: boolean,
        labelText?: string
    }
    // cellAlert: {
    //     show: boolean,
    //     labelText: string | null,
    //     targetCellBound: {
    //         x: number,
    //         y: number,
    //         width: number,
    //         height: number
    //     } | null
    // },
    confirmResetColumnSetting: boolean,
    alertHasDirtyData: boolean,
    wrapperSize: {
        width: number,
        height: number,
    } | null,
}

class OBTDataGrid extends React.Component<IOBTDataGrid, IState> implements IRefreshable {
    ///////////////////////////////////////////////////////////////////////////// PropDefinition

    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        { name: 'interface', type: 'OBTDataGridInterface', description: '리얼 그리드와의 연결을 담당하는 인터페이스' },
        CommonDefinitions.pageAuthority(),
    );

    //////////////// IColumn
    public static IColumnDefinitions = createPropDefinitions(
        { name: 'name', type: CommonType.string, description: '컬럼의 명칭' },
        { name: 'subName', type: CommonType.string, description: '다국어 컬럼의 명칭', optional: true },
        {
            name: 'type', type: ['text', 'multiline', 'number', 'dropDown', 'date', 'time', 'dateTime', 'check', 'data', 'mask', 'group', 'image', 'progress', 'link', 'button'],
            default: 'text', description: '컬럼의 타입'
        },
        {
            name: 'fieldName', type: CommonType.string, default: 'name에 지정한 값', description: "DataProvider의 데이터소스 필드와 연결되는 명칭을 지정."
                + "\n기본값으로 name 속성을 fieldName으로 사용", optional: true
        },
        { name: 'width', type: CommonType.number, description: '너비', optional: true },
        { name: 'style', type: 'IRealGridCellStyleOption', optional: true, description: "고정적인 컬럼의 스타일 http://demo.realgrid.com/GridStyle/StyleProperties/" },
        {
            name: 'dynamicStyles', type: CommonType.function, description: '다이나믹 스타일 콜백 http://demo.realgrid.com/GridStyle/StyleProperties/ 참고', parameters: [
                { name: 'grid', type: CommonType.any },
                { name: 'index', type: CommonType.any },
                { name: 'value', type: CommonType.any }
            ], result: 'IRealGridCellDynamicStyleOption', optional: true
        },
        { name: 'isAutoWidth', type: CommonType.boolean, description: '자동으로 컬럼너비가 조정되는지 여부', default: false, optional: true },
        { name: 'orientation', type: ['horizontal', 'vertical'], optional: true, default: 'horizontal', description: '컬럼이 보여지는 방식 지정' },
        { name: 'prefix', type: CommonType.string, optional: true, description: '데이터 앞에 붙는 접두사' },
        { name: 'suffix', type: CommonType.string, optional: true, description: '데이터 뒤에 붙는 접미사' },
        { name: 'visible', optional: true, type: CommonType.boolean, description: '컬럼이 보여질지 여부' },
        { name: 'editable', optional: true, type: ['boolean', '(e: { values: any, rowIndex: number, columnName: string} => boolean'], description: "컬럼의 수정 가능 여부이며 콜백으로도 지정가능" },
        { name: 'required', optional: true, type: ['boolean', '(e: { rowIndex: number, columnName: string} => boolean'], description: "필수값인지 여부. boolean 리턴 콜백으로 지정 가능", default: false },
        { name: 'readonly', optional: true, type: CommonType.boolean, description: "읽기전용값이 여부" },
        { name: 'sortable', type: CommonType.boolean, optional: true, description: '해당 컬럼의 상단헤더 클릭을 이용한 정렬을 사용할것인지 여부' },
        { name: 'hidable', type: CommonType.boolean, optional: true, description: '컬럼 표시 기능으로 컬럼을 숨길수 있는지 여부', default: true },
        {
            name: 'alignment', type: ['center', 'near', 'far', 'left', 'right'], default: 'left', description: '컬럼에 바인딩되는 데이터의 정렬 방식 이며 type에 따라 default가 자동 조정 됨'
                + '\n (ex: 날짜는 가운데, 숫자는 오른쪽 정렬)', optional: true
        },
        { name: 'useTooltip', type: CommonType.boolean, optional: true, description: "툴팁을 사용할것 인지 여부" },
        {
            name: 'header', type: CommonType.any, description: 'http://help.realgrid.com/api/types/ColumnHeader/'
                + '\n헤더에 표시되는 값을 정의하며 16가지의 속성이 존재한다. 해당 사이트 참고', optional: true
        },
        { name: 'headerCheckLocation', type: ['left', 'right'], optional: true, description: "헤더에 표시할 체크박스의 위치를 지정" },
        { name: 'headerHeight', type: CommonType.number, description: '헤더의 height', optional: true },
        { name: 'headerStyle', type: CommonType.any, description: '헤더 스타일 http://demo.realgrid.com/GridStyle/StyleProperties/', optional: true },
        {
            name: 'footer', type: [{
                expression: { type: ['count', 'sum', 'max', 'min', 'avg', 'var', 'varp', 'stdev', 'stdevp'], optional: true },
                callback: { type: CommonType.function, parameters: [{ name: 'index', type: CommonType.any }, { name: 'footerIndex', type: CommonType.number }], result: CommonType.any, optional: true },
                style: { type: ['IRealGridCellStyleOption'], optional: true },
                groupExpression: { type: CommonType.function, optional: true, parameters: [{ name: 'index', type: CommonType.any }, { name: 'footerIndex', type: CommonType.number }], result: CommonType.any },
                groupStyle: { type: ['IRealGridCellStyleOption'], optional: true }
            }], optional: true, description: "푸터설정 최하단 푸터와 그루핑 푸터를 설정한다."
                + '\n* ColumnSuammaryExpression'
                + '\n- count : 데이터행의 갯수'
                + '\n- sum : 컬럼에 포함된 모든 데이터셀의 값들을 더한 값'
                + '\n- max : 컬럼에 포함된 모든 데이터셀의 값들 중 가장 큰 값'
                + '\n- min : 컬럼에 포함된 모든 데이터셀의 값들 중 가장 작은 값'
                + '\n- avg : 컬럼에 포함된 모든 데이터셀의 값들의 평균'
                + '\n- var : 컬럼에 포함된 모든 데이터셀의 값들의 분산'
                + '\n- varp : 컬럼에 포함된 모든 데이터셀의 값들의 모집단 분산'
                + '\n- stdev : 컬럼에 포함된 모든 데이터셀의 값들의 표준편차'
                + '\n- stdevp : 컬럼에 포함된 모든 데이터셀의 값들의 모집단 표준'
                + '\n* IRealGridCellStyleOption'
                + '\n- http://demo.realgrid.com/GridStyle/StyleProperties/ 참고'
        },
        { name: 'footerValue', type: CommonType.string, optional: true, description: '단일행 footer를 사용할 경우 footer에 바인딩되는 값' },
        { name: 'groupFooterValue', type: CommonType.string, optional: true, description: '단일행 footer를 사용할 경우 footer에 바인딩되는 값' },
        { name: 'usePrivacy', type: CommonType.boolean, description: '개인정보 암호화 여부', optional: true },
        { name: 'privacyBehavior', type: ['viewByButton', 'viewByFocus', 'always'], description: '개인정보 암호화 조회 옵션', optional: true },
        {
            name: 'getProfileInfo', type: CommonType.function, parameters: {
                name: 'e',
                type: [{
                    rowIndex: { type: CommonType.number },
                    columnName: { type: CommonType.string },
                    values: { type: CommonType.any }
                }]
            }, result: 'Promise<{compSeq: string, deptSeq: string, empSeq: string}>', optional: true,
            description: '{ compSeq: string, deptSeq: string, empSeq: string }을 resolve하는 Promise 리턴 처리하는 함수 작성시,\n유저프로필 팝업을 불러오는 아이콘을 렌더링한다.'
        },
        { title: '아이콘/이미지/라벨 관련', name: '', type: '' },
        { name: 'onlyIcon', type: CommonType.boolean, optional: true, description: '아이콘만 디스플레이할것인지 여부' },
        { name: 'iconImageList', type: ['string[]'], description: '컬럼에 사용할 이미지 아이콘의 리스트', optional: true },
        {
            name: 'useImageLabel', type: CommonType.boolean, optional: true, default: false, description: '이미지 라벨을 사용할 것인지 여부'
                + '\nuseIcon 옵션과 함께 true일 수 없음.'
                + '\ntype=check, progress 등 자체적인 렌더러를 사용하는 컬럼 타입에서는 사용할 수 없음'
        },
        { name: 'imageLabelBackgroundColor', type: ['(value: string) => string', 'string'], optional: true, description: "useImageLabel = true일 경우 value에 따른 배경색 RGB 지정" },
        { name: 'customImageLabelCallback', type: ['(value, backgroundColor) => HTMLCanvasElement', 'string'], optional: true, description: 'useImageLabel = true일 경우, canvas 엘리먼트를 커스텀' },
        { name: 'useColorIcon', type: CommonType.boolean, default: false, description: '데이터가 RGB 값일 경우 색을 표현하는 아이콘을 셀 우측에 표시', optional: true },
        { name: 'imageButtonAlignment', type: ['center', 'near', 'far', 'left', 'right'], optional: true, description: '이미지 버튼의 정렬 방식' },
        {
            name: 'hasActionButton', type: CommonType.boolean, optional: true, default: false, description: '컬럼에 네모난 ... 버튼을 가지는지에 대한 여부'
                + '\n액션버튼을 click하면 onClickCustomActionButton 이벤트가 발행된다.'
        },
        {
            name: 'buttonVisibility', type: ['none', 'mouseOver', 'always'], optional: true, description: "액션버튼을 가진 컬럼 타입의 경우 버튼이 보여지는 방법"
                + "\n- none : 버튼을 보이지 않는다."
                + "\n- mouseOver : 보이지 않다가 마우스가 올라간 순간 버튼이 보여진다."
                + "\n- always : 항상 버튼이 보여진다."
        },
        { name: 'showButtonOnlyEditable', type: CommonType.boolean, optional: true, description: "hasActionButton = true 로 설정된 컬럼일 때, 수정 가능한 셀의 경우만 버튼을 표시할지 여부.", default: 'hasActionButton = true 이면 기본값 false' },
        {
            name: 'imageButtonList', type: ['ImageButton[]'], optional: true, description: '이미지가 들어간 버튼리스트. generateImageButton으로 이를 생성할 수 있음.'
                + '\n *ImageButton'
                + '\n {'
                + '\n    name: string,'
                + '\n    up?: string,'
                + '\n    hover?: string'
                + '\n    down?: string'
                + '\n    width?: number'
                + '\n    cursor?: string'
                + '\n }'
        },
        {
            name: 'imageButtonRenderers', type: ['IImageButtonRenderer[]'], optional: true, description: '이미지 버튼 렌더러'
                + '\n *IImageButtonRenderer'
                + '\n {'
                + '\n    id: string,'
                + '\n    buttonList: {'
                + '\n        id: string,'
                + '\n        labelText: string'
                + '\n        width: number'
                + '\n }[]'
        },
        // type = image
        { title: 'image', name: '', type: '', optional: true },
        { name: 'fittingType', type: ['center', 'both', 'width', 'height', 'auto', 'none'], optional: true, description: 'type = image일 경우 이미지의 디스플레이 방식', default: 'auto' },
        // type = time
        { title: 'time', name: '', type: '', optional: true },
        {
            name: 'mapTimeValueCallback', type: CommonType.function, optional: true, parameters: {
                name: 'value',
                type: ['string', 'null', 'undefiend']
            }, result: ['string', 'null', 'undefined'], description: 'type = time 컬럼의 값이 hh:mm 형태가 아닐 때, hh:mm 형태로 매핑하기위한 콜백'
        },
        { name: 'timePeriodType', type: ['hour', 'half'], description: 'type = time일 경우 드랍다운리스트의 시간 간격을 1시간단위 또는 30분단위로 설정', optional: true, default: 'hour' },
        // type = number
        { title: 'number', name: '', type: '', optional: true },
        { name: 'minimumValue', type: CommonType.number, default: 0, description: "type = progress일 경우 최소값", optional: true },
        { name: 'maximumValue', type: CommonType.number, default: 100, description: 'type = progress일 경우 최대값', optional: true },
        // type = mask
        { title: 'mask', name: '', type: '', optional: true },
        {
            name: 'maskType', type: ['custom', 'biznumber', 'conumber', 'resident', 'foreign', 'passport', 'driver', 'credit', 'account', 'tel', 'email'],
            description: 'type = mask일 경우 마스킹 방식', optional: true
        },
        { name: 'editMask', type: CommonType.string, optional: true, description: 'type = mask, maskType = custom일 경우, 셀의 에디터의 editMask' },
        {
            name: 'customMaskCallback', type: CommonType.function, optional: true, parameters: [
                { name: 'value', type: CommonType.any },
                { name: 'index', type: CommonType.number }
            ], result: CommonType.string, description: 'type = mask 이고 maskType = custom일 경우, 디스플레이되는 방식을 지정하는 콜백이다.\n파라미터로 순수 데이터가 들어오고 표시 될 텍스트를 리턴시킨다.'
        },
        {
            name: 'allowInvalidValue', type: CommonType.boolean, optional: true,
            description: 'type = mask일때 invalid한 value를 허용할것인지 여부, invalid한 value는 자동 스타일 적용, 길이까지 체크하지는 않으니 maxLength와 함께 사용해야한다.'
        },
        { name: 'useMasking', type: CommonType.boolean, optional: true, description: '이 옵션으로 개인정보 * 표시 마스킹 처리를 할 수 있습니다.' },
        // type = dropdown
        { title: 'dropdown', name: '', type: '', optional: true },
        { name: 'dropDownDataItems', type: ['any[]'], description: "type = dropdown일 경우 사용될 데이터 배열", optional: true },
        { name: 'dropDownCodeProperty', type: CommonType.string, optional: true, description: 'type = dropdown일 경우 dropDownDataItems에서 사용할 코드값의 프로퍼티' },
        { name: 'dropDownTextProperty', type: CommonType.string, optional: true, description: 'type = dropdown일 경우 dropDownDataItems에서 사용할 텍스트값의 프로퍼티' },
        { name: 'dropDownDataSourceCallBack', type: ['Promise<any>', "() => Promise<any>"], description: "type = dropdown일 경우 드랍다운 리스트에 들어갈 데이터를 백엔드에서 Promise로 조회하여 받아오는 콜백이다.", optional: true },
        { name: 'dropDownCount', type: CommonType.number, optional: true, description: 'type = dropdown일 경우 드랍다운 리스트의 아이템 갯수 지정', default: 4 },
        { name: 'allowEditorValue', type: CommonType.boolean, description: 'type = dropdown, time일 경우 드랍다운 리스트내 아이템 이외의 값 입력을 허용 할지 여부', optional: true },
        {
            name: 'dynamicDropDownItemFilter', type: CommonType.function, description: "드롭다운 리스트의 값을 동적으로 변경하기 위한 속성입니다. values: 컬럼 네임과 값 표출, item: dropDownDataItems의 code와 text 값 표출", optional: true,
            parameters: [
                { name: 'values', type: CommonType.any },
                { name: 'item', type: CommonType.any }
            ]
        },
        // type = date
        { title: 'date', name: '', type: '', optional: true },
        { name: 'showDayOfWeek', type: ['boolean', '(original:number) => string'], optional: true, description: 'type = date, dateTime일 경우 요일을 표기할 것인지 여부' },
        { name: "dateFormat", type: ['yyyyMMdd', 'yyyyMM', 'yyyy', 'iso'], optional: true, description: 'type = date일 경우 컬럼의 날짜 포메팅' },
        { name: 'dateDialogAlign', type: ['near', 'center', 'left'], optional: true, description: 'type = date일 경우 날짜 다이얼로그 정렬 방식' },
        { name: 'dateDialogPosition', type: ['top', 'left', 'right', 'bottom'], optional: true, description: 'type = date일 경우 날짜 다이얼로그 정렬 방식' },
        // type = number
        { title: 'number', name: '', type: '', optional: true },
        { name: 'nanToZero', optional: true, type: CommonType.boolean, description: 'type = number일 경우 nan을 0으로 보여줄것인지 여부', default: false },
        { name: 'showZero', optional: true, type: CommonType.boolean, default: false, description: "type = number일 경우 0을 보여줄것인지 여부" },
        { name: "positiveOnly", type: CommonType.boolean, optional: true, description: 'type = number일 경우 양수만 입력가능하게 할지 여부' },
        { name: 'numberColumnFormat', type: CommonType.string, description: "type = number일 경우 숫자 컬럼에 대한 포메팅 문자열", optional: true },
        {
            name: 'calculateCallback', type: CommonType.function, optional: true, description: 'type = number일 경우 해당 콜백에서 계산되어 리턴된 값이 표기된다.', parameters: [
                { name: 'dataRow', type: CommonType.any },
                { name: 'fieldName', type: CommonType.string },
                { name: 'fieldNames', type: ['string[]'] },
                { name: 'values', type: CommonType.any }
            ], result: CommonType.number
        },
        { name: 'maxLength', type: CommonType.number, optional: true, description: 'type = text, number 계열일 경우 입력가능한 최대길이 number일경우 (전체길이.소수점길이) 형식으로 작성' },
        { name: 'textCase', type: ['normal', 'upper', 'lower', 'default'], description: '입력되는 값을 모두 대문자로, 소문자로, 입력하는대로 변환 설정. 타자기에 영어로 설정되어있을 경우 upper 인 경우 대문자만 입력가능하며, lower는 소문자만 입력 가능', optional: true },
        // type = codePicker
        { title: 'codePicker', name: '', type: '', optional: true },
        { name: 'afterCodeHelpCallback', type: CommonType.function, optional: true, description: "type = codePicker일 경우 코드가 선택된 이후 호출되는 콜백" },
        {
            name: 'codePickerEditType', optional: true, type: ['onlyDialog', 'notAllowEditorSearch'], description: "type = codePicker일 경우."
                + "\n- onlyDialog: 키보드입력을 허용하지 않는다."
                + "\n- notAllowEditorSearch: 셀 검색대신 입력된 값을 code로 해석한다."
        },
        {
            name: 'selectedItemDisplayCallBack', type: CommonType.function, parameters: {
                name: 'e',
                type: CommonType.any
            }, result: CommonType.string, description: 'type = codePicker일 경우. 선택한 아이템의 셀에 보여지는 방식을 지정하는 콜백', optional: true
        },
        { name: 'canMultiSelect', optional: true, description: 'type = codePicker 코드피커 멀티 사용 여부', type: ['boolean', '(e: { columnName: string, rowIndex: number } => boolean'], default: false },
        { name: 'autoMoveNextCell', optional: true, description: '데이터의 수정이 이루어진후 다음셀로 이동할지 여부', type: CommonType.boolean, default: true },
        { name: 'parameters', type: CommonType.any, optional: true, description: 'type = codePicker일 경우 코드피커의 getData 함수로 보낼 파라미터, 오브젝트나 콜백으로 설정' },
        {
            name: 'customCodePicker', type: ['IBuiltInCodePicker | (e) => IBuiltInCodePicker'], optional: true, description: "type = codePicker일 경우 코드피커의 인스턴스, 콜백 지정가능"
                + "\n *IBuiltInCodePicker"
                + '\n {'
                + '\n    id: string,'
                + '\n    placeHolder: string,'
                + '\n    dialogTitle: string,'
                + '\n    dialogSubtitle?: string,'
                + '\n    size: CodePickerSize("small"|"medium"|"default"|"large"|"largeBig"|"custom"),'
                + '\n    dialogWidth?: string,'
                + '\n    dialogHeight?: string,'
                + '\n    customDialogComponent?: React.ComponentType<IDefaultDialog> (코드도움 다이얼로그),'
                + '\n    customSearchConditionComponent?: React.ComponentClass<IDefaultDialogConditionPanel> (디폴트 코드도움의 커스텀 코드도움),'
                + '\n    paging?: boolean,'
                + '\n    codeProperty: string (데이터 소스에서 코드값에 해당하는 값을 가지는 프로퍼티명),'
                + '\n    textProperty: string (데이터 소스에서 codeProperty에 매칭되는 텍스트를 가지는 프로퍼티명),'
                + '\n    columns: Array<IColumn> (OBTDataGrid에 바인딩되는 컬럼 종류),'
                + '\n    getTotalCount: (e: ICodePickerGetTotalCountEventArgs): Promise<number> (페이징, 전체선택 모드를 사용할 경우 전체 데이터아이템의 카운트 읽어오는 함수),'
                + '\n }'
        },
        // type = check
        { title: 'check', name: '', type: '', optional: true },
        { name: 'falseValues', optional: true, type: ['string[]'], description: 'type = check일 경우 false로 사용할 데이터의 리스트 ' },
        { name: 'trueValues', optional: true, type: ['string[]'], description: 'type = check일 경우 true으로 사용할 데이터의 리스트' },
        // type = group
        { title: 'group', name: '', type: '', optional: true },
        { name: 'columns', optional: true, type: ['IColumn[]'], description: 'type = group일 경우 자식 컬럼의 리스트' },
        { name: 'groupHeaderVisible', type: CommonType.boolean, optional: true, description: 'type = group일 경우 그루핑 컬럼일 경우 헤더를 보일지 여부', default: true },
        // type = autoComplete 
        { title: 'autoComplete', name: '', type: '', optional: true },
        { name: 'keyProperty', type: CommonType.string, description: "type = autoComplete일 경우 대표적으로 보여줄 키 프로퍼티를 설정합니다." },
        { name: 'searchCallback', type: CommonType.function, description: "type = autoComplete일 경우 자동완성 찾는 함수를 설정" },
        { name: 'dialogAlign', type: ['left', 'center', 'right'], optional: true, default: 'left', description: 'type = autoComplete일 경우 자동완성 다이얼로그 정렬' },
    );

    //////////////// GridGlobalOption
    public static GridGlobalOptionDefinitions = createPropDefinitions(
        { name: 'gridType', type: ['gridVeiw', 'treeView'], description: '그리드뷰 혹은 트리뷰 선택 가능', optional: true, default: 'gridView' },
        { name: 'appendable', type: CommonType.boolean, default: false, description: '그리드의 수정가능한 마지막행에서 enter입력시 입력가능한 새로운행이 추가되는지 여부, setAppendable()로 동적설정가능', optional: true },
        { name: 'editable', type: CommonType.boolean, default: false, optional: true, description: '수정 가능 여부 설정 컬럼에서 editable을 지정하지 않으면 이 옵션을 따라간다. setEditable()로 동적 설정 가능' },
        { name: 'checkable', type: CommonType.boolean, default: false, description: '체크 가능한 그리드 인지 여부 설정. setCheckable()로 동적 설정 가능', optional: true },
        {
            name: 'indicator', type: CommonType.boolean, default: false, description: '그리드 좌측에 인덱스, 편집여부등이 표시되는 indicator를 표기할지 여부를 지정 (넘버링 컬럼을 보일 것 인지 여부)'
                + '\nsetIndicator()로 동적 설정 가능', optional: true
        },
        { name: 'footerCount', type: CommonType.number, optional: true, description: '푸터의 로우 카운트를 지정할 수 있음' },
        { name: 'rowSelectMode', type: CommonType.boolean, optional: true, description: 'enter입력시 그리드 행추가 동작이 아닌 onSelectByEnter 이벤트를 발생시킨다. 코드피커등의 목적에서 사용', default: false },
        { name: 'preventSort', type: CommonType.boolean, optional: true, description: '정렬 기능을 막을 것 인지 여부 설정', default: false },
        { name: 'headerCheckVisible', type: CommonType.boolean, default: true, optional: true, description: 'checkable = true시 헤더에 전제체크를 보일지 여부' },
        { name: 'emptyDataMsg', type: CommonType.string, optional: true, description: '초기 데이터 없는 경우 안내 문구 설정', default: '데이터가 존재하지 않습니다.' },
        { name: 'emptySearchMsg', type: CommonType.string, optional: true, description: '조회된 데이터 없는 경우 안내 문구 설정', default: '검색 결과가 없습니다.' },
        { name: 'emptySearchImage', type: ['JSX.Element'], description: '조회된 데이터가 없는 경우 안내 이미지 설정', optional: true },
        { name: 'emptyDataImage', type: ['JSX.Element'], description: '초기 데이터가 없는 경우 안내 이미지 설정', optional: true },
        { name: 'paging', type: CommonType.boolean, default: false, description: '스크롤 페이징을 사용할것인지 여부', optional: true },
        { name: 'hideEmptyImage', type: CommonType.boolean, default: false, description: 'true로 설정시 데이터 없을때 그리드 바디영억에 이미지가 제거된다.', optional: true },
        { name: 'fixedColumnCount', optional: true, type: CommonType.number, description: '고정되는 컬럼의 갯수' },
        { name: 'reservedColumnNames', optional: true, type: CommonType.any, description: '체크펜등의 기능을 위한 예약된 컬럼이름' },
        { name: 'columnMovable', optional: true, type: CommonType.boolean, default: true, description: '그리드의 컬럼이동 사용여부' },
        { name: 'eachRowResizable', optional: true, type: CommonType.boolean, description: '각각의 행의 높이를 조절할수 있는지 여부', default: true },
        { name: 'columnResizable', type: CommonType.boolean, optional: true, default: true, description: 'false 로 지정시 컬럼 너비 조정을 불가능하게 합니다.' },
        CommonDefinitions.pageAuthority(),
        { name: 'privacyBehavior', type: ['viewByButton', 'viewByFocus', 'always'], description: '개인정보 암호화 조회 옵션', optional: true },
        { name: 'hideEmptyImage', optional: true, type: CommonType.boolean, default: false, description: 'true로 설정시 데이터 없을때 그리드 바디영억에 이미지가 제거된다.' },
        { name: 'useExcelExport', optional: true, type: CommonType.boolean, default: false, description: '권한에 상관없이 ExcelExport 기능을 사용할것인지 여부' },
        { name: 'uniqueColumns', optional: true, type: CommonType.boolean, default: false, description: '중복데이터 체크를 수행할 컬럼, 데이터가 수정될때 마다 중복 체크가 수행되며 데이터 수정후 설정된 컬럼기준으로 중복데이터가 존재하면 입력이 취소되고 onAlert 이벤트가 호출된다.' },
        { name: 'useCustomLayout', optional: true, type: CommonType.boolean, default: false, description: 'R-Click 컬럼 표시하기 기능, 컬럼 관련 세팅 클라이언트 저장 사용여부 (OBTPageContainer 이하에서 동작)' },
        { name: 'skipEmptyDataMsg', optional: true, type: CommonType.boolean, default: false, description: 'true로 주면 조회전 데이터가 없을 경우 뜨는 아이콘과 텍스트가 표출되지 않는다.' },
        { name: 'rowCountPerPage', type: CommonType.number, description: '페이징과 관련한 옵션) 페이지당 불러 올 아이템의 갯수', optional: true },
        { name: 'initialPageSize', type: CommonType.number, description: '페이징과 관련한 옵션) 최초 페이지 사이즈', default: 'rowCountPerPage', optional: true },
    );

    //////////////// Event
    public static EventDefinitions = createPropDefinitions(
        { name: 'onBeforeCheck', type: CommonType.function, optional: true, description: '로우의 체크바의 체크상태가 변경되기전에 호출된다.' },
        { name: 'onAfterCheck', type: CommonType.function, optional: true, description: '로우의 체크바의 체크상태가 변경된 이후에 호출된다.' },
        { name: 'onAfterHeaderCheck', type: CommonType.function, optional: true, description: '헤더의 체크바의 체크상태가 변경된 이후에 호출된다.' },
        { name: 'onDataCellDblClicked', type: CommonType.function, optional: true, description: '셀 하나를 더블클릭한 이후에 호출된다.' },
        { name: 'onDataCellClicked', type: CommonType.function, optional: true, description: '셀 하나를 클릭한 이후에 호출된다.' },
        { name: 'onBeforeSelectChange', type: CommonType.function, optional: true, description: '셀 선택이 변경되기 전에 호출된다.' },
        { name: 'onAfterSelectChange', type: CommonType.function, optional: true, description: '셀 선택이 변경한 이후에 호출된다.' },
        { name: 'onBeforeChangeRowSelection', type: CommonType.function, optional: true, description: '행 선택이 변경되기 전에 호출된다.' },
        {
            name: 'onBeforeChangeRowSelectionAsync', type: CommonType.function, optional: true, description: '행선택이 변경되기 전에 호출된다. resolve하는 불린값에 따라 행변경 취소가능 () => Promise<boolean>',
            result: ['Promise<boolean>']
        },
        { name: 'onAfterChangeRowSelection', type: CommonType.function, optional: true, description: '행선택이 변경된 이후에 호출된다.' },
        { name: 'onBeforeAddRow', type: CommonType.function, optional: true, description: '마지막 셀에서 엔터입력, addRow 함수로 새로운 행이 추가되기 전에 호출된다.' },
        { name: 'onAfterAddRow', type: CommonType.function, optional: true, description: '마지막 셀에서 엔터입력, addRow 함수로 새로운 행이 추가된 이후에 호출된다.' },
        { name: 'onBeforeChange', type: CommonType.function, optional: true, description: '셀의 데이터가 수정되기 전에 호출된다.' },
        { name: 'onValidateChange', type: CommonType.function, optional: true, description: '셀의 데이터가 변경되기전에 호출된다. 변경되기 전값과 변경된 값을 이용한 유효성검사를 할 수 있다.' },
        { name: 'onAfterChange', type: CommonType.function, optional: true, description: '셀의 데이터가 변경된 이후에 호출된다.' },
        {
            name: 'onAfterChangeAsync', type: CommonType.function, optional: true, description: '셀의 데이터가 변경된 이후에 호출된다. 비동기 처리 지원 () => Promise<boolean>',
            result: ['Promise<boolean>']
        },

        { name: 'onShowTooltip', type: CommonType.function, optional: true, description: 'useTooltip = true 로 설정된 컬럼의 셀에 마우스 오버될때 호출된다. 툴팁으로 표시할 문자열을 리턴하는 로직을 작성해야한다.' },
        { name: 'onClickCustomActionButton', type: CommonType.function, optional: true, description: 'hasActionButton = true 로 설정된 컬럼의 액션버튼을 클릭한 이후에 호출된다.' },
        { name: 'onAfterRead', type: CommonType.function, optional: true, description: 'readData함수로 데이터를 읽은 이후에 호출된다.' },
        { name: 'onBeforeRemoveRow', type: CommonType.function, optional: true, description: 'removeRow 함수로 데이터행을 삭제하기 전에 호출된다.' },
        { name: 'onAfterRemoveRow', type: CommonType.function, optional: true, description: 'removeRow 함수로 데이터행을 삭제한 후에 호출된다.' },
        { name: 'onSkipEmptyCell', type: CommonType.function, optional: true, description: 'require = true로 설정된 컬럼을 선택한 후 입력없이 다른 셀로 이동하기 전에 호출된다. 셀에 바인딩되는 데이터를 세팅할 수 있다.' },
        { name: 'onColumnWidthChanged', type: CommonType.function, optional: true, description: '컬럼의 width값이 변경된 이후에 호출된다.' },
        { name: 'onClickLinkColumn', type: CommonType.function, optional: true, description: '링크 타입컬럼을 클릭할때 발생한다.' },
        { name: 'onImageButtonClicked', type: CommonType.function, optional: true, description: 'imageButtonList가 등록된 컬럼의 이미지 버튼을 클릭했을때 발생한다.' },
        { name: 'onBeforeChangePageNumber', type: CommonType.function, optional: true, description: '페이징 모드에서 페이지 넘버 변경전 호출된다.' },
        { name: 'onAfterChangePageNumber', type: CommonType.function, optional: true, description: '페이징 모드에서 페이지 넘버 변경이후 호출된다.' },
        { name: 'onReachLastPage', type: CommonType.function, optional: true, description: '페이징 모드에서 마지막 페이지에 도달했을때 호출된다.' },
        { name: 'onAfterColumnHeaderCheck', type: CommonType.function, optional: true, description: 'type : "check"인 컬럼의 헤더 체크박스의 상태가 변경될 때 발생한다.' },
        { name: 'onBeforeCallCodePicker', type: CommonType.function, optional: true, description: '코드피커 다이얼로그가 열리기전 호출된다. cancel 가능' },
        { name: 'onContextMenuItemClicked', type: CommonType.function, optional: true, description: '오른쪽 마우스 클릭 메뉴 항목 클릭시 호출된다.' },
        { name: 'onContextMenuExportExcel', type: CommonType.function, optional: true, description: '오른쪽 마우스 클릭, 엑셀 내려받기 전 호출, e.option 으로 옵션 커스텀 가능, e.cancel로 다이얼로그 열기 동작 취소 가능' },
    );

    //////////////// Interface
    public static InterfaceDefinitions = createPropDefinitions(
        //get
        { title: 'get', name: '', type: '' },
        {
            name: 'getGridOption',
            type: CommonType.function, description: 'GlobalGridOption을 리턴한다. 복사본을 리턴하기 때문에 리턴된 오브젝트의 값을 변경해도 그리드에는 영향이 없다.',
            optional: true,
            resultExample: <p>
                appendable: true, <br />
                checkable: true, <br />
                columnMovable: false, <br />
                eachRowResizable: false, <br />
                등 적용된 GridOption
            </p>
        },
        { name: 'getRowCount', type: CommonType.function, result: CommonType.number, optional: true, description: '그리드에 바인딩된 데이터의 갯수를 가져온다.', resultExample: <p>5</p> },
        { name: 'getGridOption', type: CommonType.function, description: 'GlobalGridOption을 리턴한다. 복사본을 리턴하기 때문에 리턴된 오브젝트의 값을 변경해도 그리드에는 영향이 없다.', optional: true },
        { name: 'getRowCount', type: CommonType.function, result: CommonType.number, optional: true, description: '그리드에 바인딩된 데이터의 갯수를 가져온다.' },
        {
            name: 'getRowIndexes', type: CommonType.function, optional: true, parameters: {
                name: 'options',
                type: {
                    checkedOnly: { type: CommonType.boolean, optional: true, description: '체크된 행만 가져올 것인지에 대한 여부. 선택속성' },
                    states: { type: ['GridState[]'], optional: true, description: '해당 스테이트(empty | none | added | modified | deleted)의 행만 가져오게 됨. 선택속성' }
                }
            }, description: '체크된 행이나 특정 스테이트의 인덱스만 가져온다.', result: ['number[]'], tooltip: true, resultExample: <p>[1,3]</p>
        },
        {
            name: 'getRows', type: CommonType.function, optional: true, parameters: {
                name: 'options',
                type: {
                    checkedOnly: { type: CommonType.boolean, optional: true, description: '체크된 행만 가져올 것인지에 대한 여부. 선택속성' },
                    states: { type: ['GridState[]'], optional: true, description: '해당 스테이트(empty | none | added | modified | deleted)의 행만 가져오게 됨. 선택속성' }
                }
            }, description: '체크된 행이나 특정 스테이트의 데이터를 가져온다.', result: CommonType.any, tooltip: true,
            resultExample: <p>
                [&#123;"__rowId":0, <br />
                "deadline":"20201101",<br />
                "task":10001,<br />
                "require":"에러 사항 변경 요청",<br />
                "progress":"10",<br />
                "manager":"1020.최원석",<br />
                "check":"T",<br />
                "supervisor":"0001.루오빗"&#125;]
            </p>
        },
        {
            name: 'getDisplayRows', type: CommonType.function, optional: true, parameters: {
                name: 'options',
                type: {
                    checkedOnly: { type: CommonType.boolean, optional: true, description: '체크된 행만 가져올 것인지에 대한 여부. 선택속성' },
                    states: { type: ['GridState[]'], optional: true, description: '해당 스테이트(empty | none | added | modified | deleted)의 행만 가져오게 됨. 선택속성' }
                }
            }, description: '조건에 맞는 데이터의 행 배열을 가져온다. 그리드뷰의 데이터가 정렬이 되어있다면 그 순서를 그대로 따른다. 정렬된 데이터가 필요한게 아니면 getRows 사용 권장', result: CommonType.any, tooltip: true,
            resultExample: <p>
                &#123;"__rowId":0, <br />
                "deadline":"20201101",<br />
                "task":10001,<br />
                "require":"에러 사항 변경 요청",<br />
                "progress":"10",<br />
                "manager":"1020.최원석",<br />
                "check":"T",<br />
                "supervisor":"0001.루오빗" <br />
                "__rowIndex":0&#125;]
            </p>
        },
        {
            name: 'getRow', type: CommonType.function, optional: true, parameters: [
                { name: 'rowIndex', type: CommonType.number, description: '가져올 행' },
            ], result: CommonType.any, description: 'rowIndex행의 데이터를 가져온다.',
            resultExample: <p>
                &#123;"__rowId":0, <br />
                "deadline":"20201101",<br />
                "task":10001,<br />
                "require":"에러 사항 변경 요청",<br />
                "progress":"10",<br />
                "manager":"1020.최원석",<br />
                "check":"T",<br />
                "supervisor":"0001.루오빗"&#125;
            </p>
        },
        {
            name: 'getValue', type: CommonType.function, optional: true, parameters: [
                { name: 'rowIndex', type: CommonType.number, description: '가져올 데이터의 행' },
                { name: 'columnName', type: CommonType.string, description: '특정 셀의 데이터를 가져온다' }
            ], result: CommonType.any, description: '특정 셀의 데이터를 가져온다.', tooltip: true
        },
        {
            name: 'getValues', type: CommonType.function, optional: true, parameters: [
                { name: 'rowIndex', type: CommonType.number, description: '가져올 행' },
                {
                    name: 'GetValueOption', type: {
                        usePrivacyOriginField: { type: CommonType.boolean, optional: true, description: '개인정보암호화 원본 필드의 값을 읽을 것인지 여부' },
                        withFormat: { type: CommonType.boolean, optional: true, description: '포멧팅 된 값을 그대로 가져올 것인지 여부 (ex. type = mask일 경우 마스킹 포맷까지 적용된 값 그대로 가져오기.' },
                    }
                }
            ], result: CommonType.any, description: '파라미터로 준 인덱스의 행 데이터를 가져온다.', tooltip: true
        },
        {
            name: 'getNextActiveCell', type: CommonType.function, optional: true, parameters: [{
                name: 'rowIndex',
                type: CommonType.number
            },
            {
                name: 'columnName',
                type: CommonType.string
            }], result: ['ICell', 'null'], description: '인자로 넘긴 정보에 해당하는 셀의 다음 활성화된 셀 정보를 리턴'
        },
        {
            name: 'getColumnByName', type: CommonType.function, optional: true, parameters: {
                name: 'columnName',
                type: CommonType.string
            }, result: ['IColumn', 'undefined'], description: 'columnName으로 컬럼을 가져온다.'
        },
        {
            name: 'getRealGridColumnByName', type: CommonType.function, optional: true, parameters: {
                name: 'columnName',
                type: CommonType.string
            }, result: ['any', 'null'], description: '리얼그리드의 컬럼을 가져온다.'
        },
        { name: 'getAllPageRows', optional: true, type: CommonType.function, result: ['Prmoise<any[] | null | undefiend>'], description: '페이징 모드일때 모든 데이터를 가져온다.' },
        {
            name: 'getState', optional: true, type: CommonType.function, result: ['GridState'], parameters: {
                name: 'rowIndex',
                type: CommonType.number
            }, description: 'rowIndex의 스테이트를 가져온다.'
        },
        {
            name: 'getSelectedIndex', type: CommonType.function, optional: true, result: CommonType.number,
            description: '선택된 로우의 인덱스를 가져온다.'
        },
        {
            name: 'getSelectedColumnName', type: CommonType.function, result: CommonType.string, optional: true,
            description: '선택된 컬럼의 이름을 가져온다.'
        },
        { name: 'getSelectedRow', type: CommonType.function, optional: true, result: CommonType.any, description: '선택된 행의 데이터를 가져온다.' },
        {
            name: 'getSelection', type: CommonType.function, result: CommonType.any,
            description: '다음의 형태로 선택된 정보를 가져온다. { rowIndex: number, columnName: string }', optional: true
        },
        {
            name: 'getHeader', type: CommonType.function, optional: true, result: CommonType.any,
            description: '헤더영역의 세팅 정보를 가져온다. 다음 페이지에서 옵션 참고 \nhttp://help.realgrid.com/api/types/Header/'
        },
        {
            name: 'getSummary', type: CommonType.function, optional: true, parameters: [
                { name: 'columnName', type: CommonType.string },
                { name: 'expression', type: ['sum', 'avg', 'min', 'max', 'count'] }
            ], description: '특정 컬럼의 집계값 리턴', result: CommonType.number
        },
        //set
        { title: 'set', name: '', type: '' },
        {
            name: 'setGridOption', type: CommonType.function, description: '그리드옵션을 동적으로 조정한다. 옵션중 할당되지 않은 값에는 디폴트값이 할당된다.', optional: true, parameters: {
                name: 'options',
                type: ['GridGlobalOption']
            }
        },
        {
            name: 'setIndicator', type: CommonType.function, description: '그리드 좌측에 인덱스, 편집여부등이 표시되는 indicator를 표기할지 여부를 지정한다.', optional: true, parameters: {
                name: 'visible',
                type: CommonType.boolean
            }
        },
        {
            name: 'setEditable', type: CommonType.function, description: '수정 가능 여부를 지정', optional: true, parameters: {
                name: 'editable',
                type: CommonType.boolean
            }
        },
        {
            name: 'setAppendable', type: CommonType.function, description: '그리드의 편집가능한 마지막 행에서 엔터를 입력시 새로운 행을 추가할지 여부', optional: true, parameters: {
                name: 'appendable',
                type: CommonType.boolean
            }
        },
        {
            name: 'setCheckable', type: CommonType.function, description: '그리드의 체크상태 변경', optional: true, parameters: [
                { name: 'checkable', type: CommonType.boolean },
                { name: 'showHeaderCheck', type: CommonType.boolean }
            ]
        },
        {
            name: 'setColumn', type: CommonType.function, optional: true, parameters: [
                { name: 'column', type: ['IColumn'] },
                { name: 'merge?', type: CommonType.boolean }
            ], result: ['OBTDataGridInterface'], description: 'IColumn을 기반으로 그리드에 컬럼을 세팅한다.'
                + '\n- column : 세팅할 컬럼'
                + '\n- merge : column.name에 일치하는 기존의 컬럼을 column으로 할당한 값과 merge 한다.'
                + '\n( 변경할 값만 업데이트 )'
        },
        {
            name: 'setColumns', type: CommonType.function, optional: true, parameters: {
                name: 'columns',
                type: ['IColumn[]', 'null']
            }, result: ['OBTDataGridInterface'], description: '컬럼 배열을 세팅한다.'
        },
        {
            name: 'setProvider', type: CommonType.function, optional: true, parameters: {
                name: 'provider',
                type: ['IDataProvider', 'null']
            }, result: ['OBTDataGridInterface'], description: '데이터 설정에 관련된 함수'
                + '\nIDataProvider'
                + '\n- read : 데이터 조회 콜백'
                + '\n- readTotalCount : 페이징 모드에서 전체 데이터 카운트 조회'
                + '\n- readFooter : 페이징 모드에서 푸터 데이터 조회'
                + '\n- store : 저장 콜백'
                + '\n- storeMemo : 메모키 저장'
                + '\n- storeCheckPen : 체크펜 저장'
        },
        {
            name: 'setValues', optional: true, type: CommonType.function, parameters: [
                { name: 'rowIndex', type: CommonType.number },
                { name: 'values', type: CommonType.any },
                { name: 'moveRow', type: CommonType.boolean, description: 'default = true' }
            ], description: 'rowIndex의 행에 values 데이터를 바인딩한다.'
        },
        {
            name: 'setValue', optional: true, type: CommonType.function, parameters: [
                { name: 'rowIndex', type: CommonType.number },
                { name: 'columnName', type: CommonType.string },
                { name: 'values', type: CommonType.any },
                { name: 'moveRow', type: CommonType.boolean, description: 'default = true' }
            ], description: '특정 셀의 데이터를 세팅한다. BeforeChange와 ValidateChange에서 cancel되었는지 여부를 리턴한다. GridState를 변화시킨다.'
        },
        {
            name: 'setValueUnmanaged', type: CommonType.function, optional: true, parameters: [
                { name: 'rowIndex', type: CommonType.number },
                { name: 'columnName', type: CommonType.string },
                { name: 'value', type: CommonType.any }
            ], result: ['Promise<void>'], description: 'BeforeChange와 ValidateChange 이벤트를 무시한 setValue'
        },
        {
            name: 'setState', optional: true, type: CommonType.function, parameters: [
                { name: 'rowIndex', type: CommonType.number },
                { name: 'state', type: ['GridState'] }
            ], description: 'rowIndex의 스테이트를 state로 설정한다.'
        },
        {
            name: 'setColumnVisible', type: CommonType.function, optional: true, parameters: [
                { name: 'columnName', type: CommonType.string },
                { name: 'isVisible', type: CommonType.boolean }
            ], description: '특정 컬럼 보여지는지 여부 지정.'
        },
        {
            name: 'setSelection', type: CommonType.function, optional: true, parameters: [
                { name: 'rowIndex', type: CommonType.number },
                { name: 'columnName?', type: CommonType.string }
            ], result: CommonType.string, description: '특정 셀 선택. 포커스까지 옮기지는 않는다.'
        },
        {
            name: 'setHeader', type: CommonType.function, optional: true, parameters: {
                name: 'option',
                type: CommonType.any
            }, description: '헤더영역의 높이등 옵션을 설정한다. 기존의 세팅에서 설정된 항목만 변경한다. 다음 페이지에서 옵션 참고 \nhttp://help.realgrid.com/api/types/Header/'
        },
        {
            name: 'setRowGroup', type: CommonType.function, optional: true, parameters: {
                name: 'option',
                type: CommonType.any
            }, description: '그루핑 푸터, 헤더의 값등 그루핑에 관한 설정을 한다. 다음을 참고해서 옵션을 줄수 있음 \nhttp://help.realgrid.com/api/types/RowGroupOptions/'
        },
        {
            name: 'setContextMenu', type: CommonType.function, optional: true, parameters: {
                name: 'additionalMenuItems',
                type: CommonType.any
            }, description: '메뉴 정보 배열을 넘겨받아 오른쪽 마우스클릭 메뉴 항목을 세팅한다.\n http://help.realgrid.com/api/types/MenuItem/'
        },
        //check 관련
        { title: 'check 관련', name: '', type: '' },
        {
            name: 'getCheck', optional: true, type: CommonType.function, parameters: {
                name: 'rowIndex',
                type: CommonType.number
            }, result: CommonType.boolean, description: '특정 인덱스의 행이 체크되어있는지 여부를 리턴한다.'
        },
        { name: 'getCheckedRows', optional: true, type: CommonType.function, result: CommonType.any, description: '그리드에서 체크된 로우를 가져온다.' },
        { name: 'getCheckedIndexes', optional: true, type: CommonType.function, result: ['number[]'], description: '체크한 인덱스 가져오기' },
        {
            name: 'setCheck', optional: true, type: CommonType.function, parameters: [
                { name: 'rowIndex', type: CommonType.number, description: '체크가능 여부를 세팅할 rowIndex' },
                { name: 'checked', type: CommonType.boolean, description: '체크 가능 여부' }
            ], description: '특정 인덱스의 체크처리를 제어한다.', tooltip: true
        },
        {
            name: 'setRowCheckable', optional: true, type: CommonType.function, parameters: [
                { name: 'rowIndex', type: CommonType.number, description: '체크가능 여부를 세팅할 rowIndex' },
                { name: 'checkable', type: CommonType.boolean, description: '체크 가능 여부' }
            ], description: '특정 인덱스의 행이 체크가능 여부를 세팅한다.', tooltip: true
        },
        {
            name: 'setColumnHeaderCheck', type: CommonType.function, optional: true, parameters: [
                { name: 'columnName', type: CommonType.string, description: '헤더의 체크 여부를 설정할 columnName' },
                { name: 'checked', type: CommonType.boolean, description: '체크 여부의 설정' }
            ], description: '헤더의 체크 여부를 boolean으로 설정할 수 있다.', tooltip: true
        },
        {
            name: 'isRowCheckable', optional: true, type: CommonType.function, parameters: {
                name: 'rowIndex',
                type: CommonType.number,
                description: '특정 인덱스 선택'
            }, result: CommonType.boolean, description: '특정 인덱스의 행이 체크가능한지 여부를 리턴한다.', tooltip: true
        },
        { name: 'checkAll', optional: true, type: CommonType.function, result: CommonType.boolean, description: '모든 행 체크' },
        { name: 'unCheckAll', optional: true, type: CommonType.function, result: CommonType.boolean, description: '모든 행 체크 해제' },
        // 높이/너비/스타일 관련
        { title: 'height/width/style 관련', name: '', type: '' },
        {
            name: 'fitColumnWidth', type: CommonType.function, optional: true, parameters: {
                name: 'options?',
                type: {
                    columnName: { type: CommonType.string, optional: true, description: '폭을 지정할 컬럼의 이름을 지정한다. columnName을 지정하지 않으면 모든 컬럼에 일괄적용한다.' },
                    maxWidth: { type: CommonType.number, optional: true, description: '조절되는 최대 폭을 지정한다. 0을 입력하면 데이터의 길이에 따라 반영된다.' },
                    minWidth: { type: CommonType.number, optional: true, description: '조절되는 최소 폭을 지정한다. 0을 입력하면 데이터의 길이에 따라 반영된다.' },
                    visibleOnly: { type: CommonType.boolean, optional: true, description: '현재 그리드에 보이는 데이터만 가지고 폭을 결정한다.' }
                }
            }, description: "컬럼의 폭을 데이터에 따라 자동으로 조절한다.", tooltip: true
        },
        {
            name: 'fitRowHeight', type: CommonType.function, optional: true, parameters: [
                { name: 'itemIndex', type: CommonType.number, description: '높이 변경할 아이템의 index를 지정' },
                { name: 'maxHeight', type: CommonType.number, description: '0으로 지정시 데이터의 양에 따라 높이 제한없이 변경된다. 값 지정시 데이터의 양이 많아도 지정한 행 높이만큼만 반영 된다.' },
                { name: 'textOnly', type: CommonType.boolean, description: 'default = true, 텍스트 컬럼만을 대상으로 높이 계산을 수행할 것인지에 대한 여부' },
                { name: 'refresh', type: CommonType.boolean, description: 'default = true, 변경한 행 높이를 바로 화면에 반영할 것인지에 대한 여부를 지정' }
            ], description: 'eachRowResizable = true 일 경우, 행의 높이를 데이터에 따라 자동조절한다. (multiline 컬럼일 경우) 최대 maxHeight까지 높이가 늘어난다.', tooltip: true
        },
        {
            name: 'fitRowHeightAll', type: CommonType.function, optional: true, parameters: [
                { name: 'maxHeight', type: CommonType.number, description: '0으로 지정시 데이터의 양에 따라 높이 제한없이 변경된다. 값 지정시 데이터의 양이 많아도 지정한 행 높이만큼만 반영 된다.' },
                { name: 'textOnly', type: CommonType.boolean, description: 'default = true, 텍스트 컬럼만을 대상으로 높이 계산을 수행할 것인지에 대한 여부' }
            ], description: 'eachRowResizable = true 일 경우, 모든 행의 높이를 데이터에 따라 자동조절한다. (multiline 컬럼일 경우) 최대 maxHeight까지 높이가 늘어난다.', tooltip: true
        },
        {
            name: 'setRowStyle', type: CommonType.function, optional: true, parameters: [
                { name: 'rowIndex', type: CommonType.number, description: '스타일을 지정할 cell' },
                { name: 'styleId', type: CommonType.string, description: '지정할 style id (사용자가 미리 지정)' }
            ], description: '특정 셀에 addCellStyle로 지정한 styleId를 지정한다.', tooltip: true
        },
        {
            name: 'addCellStyle', type: CommonType.function, optional: true, parameters: [
                { name: 'styleId', type: CommonType.string, description: '적용할 style id (사용자가 미리 지정)' },
                { name: 'style?', type: ['IRealGridCellStyleOption'], description: 'http://demo.realgrid.com/GridStyle/StyleProperties/' }
            ], description: 'styleId를 추가한다. 스타일을 적용시킨다.', tooltip: true
        },
        {
            name: 'removeCellStyle', type: CommonType.function, parameters: {
                name: 'styleId',
                type: CommonType.string,
                description: '제거할 style id (사용자가 미리 지정)'
            }, optional: true, description: 'styleId를 제거한다. 적용된 스타일도 제거됨.', tooltip: true
        },
        {
            name: 'setRowHeight', type: CommonType.function, optional: true, parameters: [
                { name: 'rowIndex', type: CommonType.number, description: '높이 변경할 아이템의 index를 지정' },
                { name: 'height', type: CommonType.number, description: '값을 지정하여 지정한 행 높이로 변경' },
                { name: 'refresh', type: CommonType.boolean, description: 'default = true이며 변경한 행 높이를 바로 화면에 반영할 것인지에 대한 여부를 지정' }
            ], description: 'eachRowResizable = true 일 경우, 행의 height 값을 조정한다.', tooltip: true
        },
        //Provider에서 관리하는 데이터 관련
        { title: 'providerData 관련', name: '', type: '' },
        {
            name: 'readData', type: CommonType.function, optional: true, parameters: {
                name: 'readCallback?',
                type: ['Read']
            }, result: ['Promise<any>,'], description: '데이터를 읽어 그리드에 바인딩한다.'
        },
        { name: 'clearData', type: CommonType.function, optional: true, description: '그리드의 데이터를 클리어한다.' },
        {
            name: 'storeData', type: CommonType.function, result: ['Promise<any>'], parameters: [
                { name: 'rowIndex?', type: CommonType.number },
                { name: 'storeCallback?', type: ['Store'] }
            ], description: '더티 데이터를 추려내 존재한다면 provider의 store를 호출한다.', optional: true
        },
        {
            name: 'refresh', type: CommonType.function, optional: true, parameters: {
                name: 'refresh?',
                type: CommonType.boolean
            }, description: '그리드를 갱신한다. 콜백과 같이 state가 바뀌어도 변동되지 않는 곳 까지 갱신시켜준다.'
        },
        {
            name: 'searchData', type: CommonType.function, optional: true, parameters: [
                { name: 'startIndex', type: CommonType.number, description: '검색시 시작인덱스' },
                { name: 'columnNamesForSearch', type: ['string[]', 'null'], description: '검색 할 컬럼목록(컬럼네임으로 지정)' },
                { name: 'value', type: CommonType.string, description: '찾을 값' },
                { name: 'selectSearchedCell', type: CommonType.boolean, description: 'true로 지정하면 검색된 행이 있을 때 그 행을 선택하고, 현재 표시된 범위 밖이면 표시되도록 스크롤한다.' },
                { name: 'partialMatch', type: CommonType.boolean, description: 'true면 지정한 텍스트가 포함되기만 하면 일치하는 것으로 판단한다.' }
            ], result: ['ICell', 'null'], description: '데이터 검색', tooltip: true
        },
        //treeGrid
        { title: 'treeGrid 관련', name: '', type: '' },
        { name: 'expandAll', type: CommonType.function, optional: true, description: '트리뷰 전용. 모두 펼치기' },
        { name: 'collapseAll', type: CommonType.function, optional: true, description: '트리뷰 전용. 모두 접기' },
        {
            name: 'expand', type: CommonType.function, optional: true, description: '트리뷰 전용. 특정 인덱스의 자식영역을 확장한다.', parameters: [
                { name: 'rowIndex', type: CommonType.number },
                { name: 'recursive?', type: CommonType.boolean },
                { name: 'force?', type: CommonType.boolean }
            ]
        },
        {
            name: 'collapse', type: CommonType.function, optional: true, parameters: [
                { name: 'rowIndex', type: CommonType.number },
                { name: 'recursive?', type: CommonType.boolean }
            ], description: '트리뷰 전용. 특정 인덱스의 자식영역을 축소한다.'
        },
        {
            name: 'getChildrenIndexes', type: CommonType.function, optional: true, parameters: { name: 'rowIndex', type: CommonType.number },
            result: ['number[]'], description: '트리뷰 전용. 자식행의 아이템의 index배열을 가져온다.'
        },
        {
            name: 'getParentIndex', type: CommonType.function, optional: true, parameters: {
                name: 'rowIndex', type: CommonType.number
            }, result: ['number[]'], description: '트리뷰 전용. 부모행의 아이템의 index를 가져온다.'
        },
        {
            name: 'checkChildren', type: CommonType.function, optional: true, parameters: [
                { name: 'rowIndex', type: CommonType.number },
                { name: 'checked', type: CommonType.boolean },
                { name: 'recusive', type: CommonType.boolean }
            ], description: '트리뷰 전용. 입력된 행의 자식 행들을 체크하거나 해제한다.'
        },
        {
            name: 'hasChildren', type: CommonType.function, optional: true, parameters: {
                name: 'rowIndex',
                type: CommonType.number
            }, result: CommonType.boolean, description: '트리뷰 전용. 자식행을 가지고있는지 여부'
        },
        {
            name: 'getParentIndex', type: CommonType.function, optional: true, parameters: {
                name: 'rowIndex',
                type: CommonType.number
            }, result: ['number', 'null'], description: '트리뷰 전용. 부모의 인덱스를 가져온다. 부모가 없다면 null'
        },
        {
            name: 'addTreeRow', type: CommonType.function, optional: true, parameters: [
                { name: 'parentRowIndex?', type: CommonType.number },
                { name: 'childRowIndex?', type: CommonType.number }
            ], description: '트리뷰 전용. 행을 추가한다.'
        },
        //etc
        { title: 'etc', name: '', type: '' },
        {
            name: 'hasDirtyData', type: CommonType.function, optional: true, result: CommonType.boolean,
            description: '체크스테이트를 검사해 변경된 데이터가 있는지 여부를 리턴한다.'
        },
        {
            name: 'removeRow', type: CommonType.function, optional: true, parameters: {
                name: 'rowIndex',
                type: CommonType.number
            }, result: ['Promise'], description: '선택한 행의 로우를 삭제한다. Promise 리턴'
        },
        {
            name: 'removeRows', type: CommonType.function, optional: true, parameters: {
                name: 'rowIndexs',
                type: ['number[]']
            }, result: ['Promise'], description: '여러건의 로우를 한번에 삭제한다. Promise 리턴'
        },
        {
            name: 'addColumn', type: CommonType.function, optional: true, parameters: [
                { name: 'index', type: CommonType.number },
                { name: '...columns', type: ['IColumn[]'] },
            ], description: 'index를 기반으로 컬럼을 추가한다.'
        },
        {
            name: 'removeColumn', type: CommonType.function, optional: true, parameters: {
                name: 'indexOrName',
                type: ['number', 'string'],
                description: '제거할 인덱스나 컬럼네임'
            }, description: 'index나 컬럼명을 기반으로 컬럼을 제거한다. index를 기반으로 할 경우 그룹 컬럼의 자식을 삭제할 수 없다.', tooltip: true
        },
        {
            name: 'addRow', type: CommonType.function, optional: true, parameters: [
                { name: 'rowIndex?', type: CommonType.number },
                { name: 'data?', type: CommonType.any }
            ], description: 'rowIndex에 빈행을 추가한다. rowIndex를 지정하지 않으면 마지막행에 추가한다.'
        },
        {
            name: 'generateImageButton', type: CommonType.function, parameters: [
                { name: 'buttonName', type: CommonType.string },
                { name: 'width', type: CommonType.number },
                { name: 'labelText', type: CommonType.string }
            ], optional: true, description: 'labelText를 기반으로 리얼 그리드의 이미지 버튼으로 사용될 수 있는 버튼 오브젝트를 생성한다.'
                + '\n- buttonName : id로 각각의 버튼을 구분하는 용도'
                + '\n- width : 버튼의 width 값'
                + '\n- labelText : 버튼의 labelText 값'
        },
        { name: 'focus', type: CommonType.function, description: '그리드에 focus를 준다', optional: true },
        {
            name: 'showColumn', type: CommonType.function, optional: true, parameters: {
                name: 'columnName',
                type: CommonType.string
            }, description: '특정 컬럼을 보여준다. visible이 false로 지정된 컬럼에 대해서만 영향이 있다.'
        },
        {
            name: 'hideColumn', type: CommonType.function, optional: true, parameters: {
                name: 'columnName',
                type: CommonType.string
            }, description: '특정 컬럼을 숨긴다.'
        },
        {
            name: 'callCodePickerDialog', type: CommonType.function, optional: true, parameters: [
                { name: 'rowIndex', type: CommonType.number },
                { name: 'columnName', type: CommonType.string },
                { name: 'keyword', type: CommonType.string, description: '다이얼로그를 열때 사용할 검색 키워드 선택적 파라미터' }
            ], description: '코드피커 다이얼로그를 연다.'
        },
        {
            name: 'exportExcel', type: CommonType.function, optional: true, parameters: {
                name: 'exportOption?',
                type: CommonType.any
            }, description: '엑셀 익스포트, 엑셀 익스포트의 커스텀을 원할 경우 exportOption에 다음을 참고해서 옵션을 줄수 있음 \nhttp://help.realgrid.com/api/types/GridExportOptions/'
        },
        {
            name: 'groupBy', type: CommonType.function, optional: true, parameters: {
                name: 'columnNames',
                type: ['string[]']
            }, description: '컬럼명 배열을 넘겨 해당 컬럼으로 그루핑한다. 순수 조회모드에서만 사용가능'
        },
    );

    context!: React.ContextType<typeof OBTContext>;

    public static defaultProps = {
        width: '100%',
        height: '100%'
    }

    public state: IState = {
        interface: this.props.interface,
        dateDialogValue: null,
        currentCodePickerColumn: null,
        currentCodePickerSelectedValueDisplayCallback: undefined,
        currentCodePickerKeyword: null,
        currentDateColumn: null,
        isShowCodePickerDialog: false,
        codePickerCancelCallback: () => { },
        emptySetType: EmptySetType.beforeSearch,
        emptySetSize: "normal",
        emptySetTopPotition: 0,
        searchBox: {
            isShowSearchBox: false,
            keyword: '',
            selectedSearchBoxDropdownValue: { value: '0', labelText: '전체' },
            // startIndex: 0,
            // startFieldIndex: 0,
            //searchWhenPagingReachLast: false,
        },
        autoCompleteColumn: {
            width: '150px',
            keyProperty: "",
            selectedIndex: 0,
            isOpen: false,
            columnName: "",
            rowIndex: 0,
            isSelectable: true,
            useDirectValueMapping: true,
        },
        isShowExportExcelDialog: false,
        isShowCustomColumnDialog: false,
        // dateColumnPointer: null,
        alreadyRead: false,
        hover: {
            visible: false,
            rowIndex: -1
        },
        requiredSnackbar: {
            show: false,
            labelText: ""
        },
        noSearchSnackbar: {
            show: false,
            labelText: ""
        },
        // cellAlert: {
        //     show: false,
        //     labelText: null,
        //     targetCellBound: null
        // },
        confirmResetColumnSetting: false,
        alertHasDirtyData: false,
        wrapperSize: null
    }

    public myRefs = {
        grid: React.createRef<HTMLDivElement>(),
        wrapper: React.createRef<HTMLDivElement>(),
        searchBox: React.createRef<HTMLDivElement>(),
        searchBoxInput: React.createRef<OBTTextField>(),
        dateDialogYYYY: React.createRef<OBTYearFieldDialog>(),
        dateDialogYYYYMM: React.createRef<UFOMonthFieldDialog>(),
        dateDialogYYYYMMDD: React.createRef<UFODateFieldDialog>(),
        codePickerDialog: React.createRef<OBTCodePickerDialogController>(),
        autoCompleteDialog: React.createRef<AutoCompleteDialog>()
    }

    /**
     * 그리드의 루트 div에 할당되는 아이디
     */
    private wrapperId = `gridWrapper_${uuid()}`;

    /**
     * 그리드의 루트 div에 할당되는 클래스명
     */
    private readonly wrapperClassName = 'obt_grid_root_class_name_hard';

    /**
     * 
     */
    private resizeSensor: ResizeSensor | null = null;

    /**
     * 사이즈 재조정될때의 이벤트 핸들러
     */
    private resizeHandler = () => {
        this.changeEmptySetTypeDebounce(this.state.emptySetType);

        this.state.interface._invokeColumnWidthChangedWhenResize();
        this.state.interface.refresh(false);
    }

    /**
     * 정렬 및 소계
     * 
     * 컬럼 크기, 순서등의 정보를 localStorage에 저장시 사용될 키값을 생성한다.
     */
    makeClientId = () => {
        const id = this.state.interface.id;
        const erpEmpSeq = Util.safeAccessProperty(this.context, 'loginUserInfo', 'ucUserInfo', 'erpEmpSeq');
        const pageCode = Util.safeAccessProperty(this.context, 'menuItem', 'pageCode');

        if (!id || !erpEmpSeq || !pageCode) {
            return null;
        }

        return erpEmpSeq + '|' + pageCode + '|' + id;
    }

    componentDidMount(): void {
        try {
            // 컨텍스트에 따른 초기화
            const context = this.context as IOBTContext;

            // 세션 아이디 저장
            const clientId = this.makeClientId();

            // 숫자 포멧 환경설정 따라가기
            if (context && context.erpNumberFormat) {
                this.state.interface._setErpUserNumberFormat(context.erpNumberFormat!);
            }

            // 권한
            if (this.props.usePageAuthority === false) {
                this.state.interface._initialize(this, this.wrapperId, clientId);
            } else if (this.props.pageAuthority) {
                if (context && context.pageAuthority) {
                    this.state.interface._initialize(this, this.wrapperId, clientId, {
                        ...context.pageAuthority,
                        ...this.props.pageAuthority
                    });
                } else {
                    this.state.interface._initialize(this, this.wrapperId, clientId, this.props.pageAuthority);
                }
            } else if (context && context.pageAuthority) {
                this.state.interface._initialize(this, this.wrapperId, clientId, context.pageAuthority);
            } else {
                this.state.interface._initialize(this, this.wrapperId, clientId);
            }

            // wrapper에 이벤트 적용
            if (this.myRefs.wrapper.current) {
                this.resizeSensor = new ResizeSensor(this.myRefs.wrapper.current, this.resizeHandler);

                // TODO: 리얼그리드 asset을 위하고 쪽에서 관리해서 생기는 크로스도메인 문제때문에 테스트차 추가한 코드인데 지금은 필요하지 않아보임
                const canvasElement = this.myRefs.wrapper.current.querySelector<HTMLCanvasElement>(`#${this.state.interface._gridElementId} > div > canvas`);
                if (canvasElement) {
                    canvasElement.setAttribute('crossorigin', 'anonymous');
                }
            }

            // 사이즈에 따른 empty set 등록 
            this.changeEmptySetType(EmptySetType.beforeSearch);

            // 인터페이스 이벤트 핸들러 등록
            ((instance) => {
                instance.onCallDatePickerDialog = this.handleCallDatePickerDialog;
                instance.onCloseDatePickerDialog = this.handleCloseDatePickerDialog;

                instance.onCallCodePickerDialog = this.handleCallCodePickerDialog;
                instance.onCallCodePickerSearch = this.handleCallCodePickerSearch;

                instance.onCallAutoCompleteDialog = this.handleCallAutoCompleteDialog;
                instance.onShowEditorAutoCompleteDialog = this.handleShowEditorAutoCompleteDialog;
                instance.onCloseAutoCompleteDialog = this.handleCloseAutoCompleteDialog;
                instance.onKeyDownAutoComplete = this.handleAutoCompleteKeyDown;

                instance.onChangeEmptySetType = this.handleChangeEmptySetType;

                instance.onClickContextMenuShowSearchBox = this.handleClickContextMenuShowSearchBox;
                instance.onClickContextMenuExportExcel = this.handleClickContextMenuExportExcel;
                instance.onClickContextMenuCustomColumn = this.handleClickContextMenuCustomColumn;
                instance.onKeyDownInternal = this.handleKeyDownOnGrid;

                instance.onAlert.add(this.handleOnSnackbar);

                instance.onAfterChange.add(this.handleGridInterfaceAfterChange)
                instance.onAfterSelectChange.add(this.handleGridInterfaceAfterSelectChange);
                instance.onAfterRead.add(this.handleAfterRead);
                instance.onImageButtonClicked.add(this.handleImageButtonClicked)

            })(this.state.interface);

            if (this.myRefs.grid.current) {
                this.myRefs.grid.current['gridView'] = this.state.interface.gridView;

                const wrapperRect = this.myRefs.grid.current.getBoundingClientRect();
                this.setState({
                    wrapperSize: {
                        height: wrapperRect.height,
                        width: wrapperRect.width,
                    }
                })
            }

            // 데이터가 조회된 이후 unmount -> mount 시점에 스타일 재적용
            ignoreException(() => {
                if (this.state.interface.getRowCount() > 0) {
                    this.state.interface.refreshCellStyle()
                }
            })
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    componentDidUpdate(prevProps: Readonly<IOBTDataGrid>, prevState: Readonly<IState>) {
        if (this.state.interface) {

            if (this.myRefs.grid.current) {
                const wrapperRect = this.myRefs.grid.current.getBoundingClientRect();
                if (this.state.wrapperSize && (this.state.wrapperSize.height !== wrapperRect.height || this.state.wrapperSize.width !== wrapperRect.width)) {

                    if (this.state.wrapperSize.width === 0 || this.state.wrapperSize.height === 0) {
                        // this.state.interface.gridView.refresh();
                        this.state.interface.refresh();
                    }

                    this.changeEmptySetType(this.state.emptySetType);

                    this.setState({
                        wrapperSize: {
                            height: wrapperRect.height,
                            width: wrapperRect.width,
                        }
                    })
                }
            }
        }
    }

    componentWillUnmount(): void {
        if (this.resizeSensor) {
            this.resizeSensor.detach(this.resizeHandler);
        }
    }

    searchBoxStartFieldIndex: number = 0;
    searchBoxStartIndex: number = 0;
    /**
     * 그리드 셀 선택 변경 이벤트 핸들러
     */
    private handleGridInterfaceAfterSelectChange = (e: GridEvents.AfterSelectChangeEventArgs) => {
        if (!e.columnName || !this.state.interface) {
            return;
        }

        const targetColumn = this.state.interface.getColumnByName(e.columnName);
        if (!targetColumn) {
            throw new Error("handleGridInterfaceAfterSelectChange : 타겟 컬럼이 존재하지 않습니다." + e);
        }

        // this.setState({
        //     searchBox: {
        //         ...this.state.searchBox,
        //         startFieldIndex: this.state.interface.provider?._provider.getFieldIndex(OBTDataGridInterface.getFieldName(targetColumn)) + 1,
        //         startIndex: e.rowIndex
        //     }
        // })
        this.searchBoxStartFieldIndex = this.state.interface.provider?._provider.getFieldIndex(OBTDataGridInterface.getFieldName(targetColumn)) + 1;
        this.searchBoxStartIndex = e.rowIndex;

        if (targetColumn && targetColumn.type !== ColumnType.date) {
            this.handleCloseDatePickerDialog();
        } else if (this.state.currentDateColumn && this.state.currentDateColumn.rowIndex !== e.rowIndex) {
            this.handleCloseDatePickerDialog();
        }

        // 사이드바 세팅
        if (e.rowIndex >= 0 && e.invokedFromReadData === false) {
            this.setSideBar(e.rowIndex, e.columnName, e.isRowChanged);
        }
        this.handleCloseAutoCompleteDialog();
    }

    /**
     * AfterChange 이벤트 핸들러
     * 입력자정보 자동등록
     * @param e 
     */
    private handleGridInterfaceAfterChange = (e: GridEvents.AfterChangeEventArgs) => {
        try {
            if (!e.columnName) {
                return;
            }

            const context = (this.context) as IOBTContext;
            if (!context) {
                return;
            }

            const userInfo = context.loginUserInfo;
            const grid = this.state.interface;
            if (userInfo) {
                const columnNames = grid.getGridOption().reservedColumnNames!;

                if (userInfo) {
                    // 입력자 정보 자동 세팅
                    if (grid.getState(e.rowIndex) === GridState.added) {
                        if (grid.getColumnByName(columnNames.insertDateTime)) {
                            grid.setValueUnmanaged(e.rowIndex, columnNames.insertDateTime, new Date().toString());
                        }

                        if (grid.getColumnByName(columnNames.insertIPAddress)) {
                            grid.setValueUnmanaged(e.rowIndex, columnNames.insertIPAddress, userInfo.ucUserInfo ? userInfo.ucUserInfo.clientIp : '');
                        }

                        if (grid.getColumnByName(columnNames.insertUserId)) {
                            grid.setValueUnmanaged(e.rowIndex, columnNames.insertUserId, userInfo.ucUserInfo ? userInfo.ucUserInfo.erpEmpSeq : '');
                        }
                    }

                    // 수정자 정보 자동 세팅
                    if (grid.getColumnByName(columnNames.modifiedIPAddress)) {
                        grid.setValueUnmanaged(e.rowIndex, columnNames.modifiedIPAddress, userInfo.ucUserInfo ? userInfo.ucUserInfo.clientIp : '');
                    }

                    if (grid.getColumnByName(columnNames.modifyDateTime)) {
                        grid.setValueUnmanaged(e.rowIndex, columnNames.modifyDateTime, new Date().toString());
                    }

                    if (grid.getColumnByName(columnNames.modifyUserId)) {
                        grid.setValueUnmanaged(e.rowIndex, columnNames.modifyUserId, userInfo.ucUserInfo ? userInfo.ucUserInfo.erpEmpSeq : '');
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * 필수 값이 누락되거나 데이터그리드에서 찾는 값이 존재하지 않을 경우
     * @param e 
     */
    private handleOnSnackbar = (e: GridEvents.AlertEventArgs) => {
        //1. EMPTY_REQUIRED 인 경우
        if (e.alertReason === GridEvents.GridAlertReason.VALIDATION_FAIL) {
            if (!e.validationResult || !e.validationResult.invalidColumns) {
                return;
            }

            const emptyColumnHeaderText: string[] = e.validationResult.invalidColumns.filter(invalidColumn => {
                return invalidColumn.reason === GridEvents.GridValidationFailReason.EMPTY_REQUIRED
            }).map((emptyColumn) => {
                const columnName = emptyColumn.columnName;
                const headerText = this.state.interface.getHeaderText(columnName);
                return headerText;
            });

            if (emptyColumnHeaderText.length > 0) {
                const labelText = `[${emptyColumnHeaderText[0]}] ${OrbitInternalLangPack.getText("WE000009432", "입력해주세요.")}`;

                this.setState({
                    requiredSnackbar: {
                        show: true,
                        labelText: labelText
                    }
                });
            }
        }

        // //2. NO_SEARCH_CELL_RESULT 인 경우
        // else if (e.alertReason === GridEvents.GridAlertReason.NO_SEARCH_CELL_RESULT) {
        //     this.setState({
        //         noSearchSnackbar: {
        //             show: true,
        //             labelText: OrbitInternalLangPack.getText('WE000014711', '결과값이 존재하지 않습니다.')
        //         }
        //     })
        // }
    }

    /**
     * 사이드바 데이터 세팅
     * @param rowIndex 
     * @param columnName 
     */
    private setSideBar(rowIndex: number, columnName: string, isRowChanged: boolean) {
        if (!this.state.interface) {
            return;
        }

        if (!this.context.setSideBar) {
            return;
        }

        const reservedColumnNames = this.state.interface.getGridOption().reservedColumnNames!;
        const willUpdateSideBarContents: ISideBarContent[] = [];
        const targetColumn = this.state.interface.getColumnByName(columnName)!;

        // 체크펜
        const checkPenColumn = this.state.interface.getColumnByName(reservedColumnNames.checkPen);
        if (checkPenColumn) {
            const rowState = this.state.interface.getState(rowIndex);

            const sideBarCheckPen = {
                key: 'checkpen',
                visible: (rowState === GridState.modified || rowState === GridState.none)
                    && Util.safeAccessProperty(this.state.interface, 'provider', 'storeCheckPen') !== undefined,
                onApply: (selectedColorHex) => {
                    const targetGrid = this.state.interface;
                    if (!targetGrid) {
                        return;
                    }

                    let realGridColorHex = "#ff".concat(selectedColorHex.substr(1, selectedColorHex.length - 1));
                    if (!selectedColorHex || selectedColorHex.concat().length === 0) {
                        realGridColorHex = "";
                    }
                    targetGrid.storeCheckPen(targetGrid.getSelectedIndex(), realGridColorHex).then(() => {
                        targetGrid.applyInternalCellStyle(targetGrid.getSelectedIndex());

                        this.setSideBar(targetGrid.getSelectedIndex(), reservedColumnNames.checkPen, false);
                    });
                },
                changeApplyCheckPen: (use: boolean) => {
                    if (!this.state.interface || this.state.interface.getRows().length === 0) {
                        return;
                    }

                    this.state.interface.setGridOption(
                        {
                            ...this.state.interface.getGridOption(),
                            useCheckPen: use
                        }
                    );

                    this.state.interface.getRows().forEach((item, index) => {
                        this.state.interface.applyInternalCellStyle(index, {
                            selectedRow: false,
                            applyNothingStyle: false,
                        });
                    })

                    this.state.interface.applyInternalCellStyle(this.state.interface.getSelectedIndex(), {
                        selectedRow: true,
                    });
                },
                data: GridUtil.convertRGBToRealGridColorHex(this.state.interface.getValue(rowIndex, checkPenColumn.name))
            } as ISideBarContent;

            willUpdateSideBarContents.push(sideBarCheckPen);
        }

        // 입력자 정보
        if (this.state.interface.getColumnByName(reservedColumnNames.insertUserId) !== undefined) {
            const dataRow = this.state.interface.getRow(rowIndex);
            if (dataRow) {
                willUpdateSideBarContents.push({
                    key: 'inputlog',
                    data: {
                        insertId: dataRow[reservedColumnNames.insertUserId],
                        insertDt: dataRow[reservedColumnNames.insertDateTime],
                        insertIp: dataRow[reservedColumnNames.insertIPAddress],
                        modifyId: dataRow[reservedColumnNames.modifyUserId],
                        modifyDt: dataRow[reservedColumnNames.modifyDateTime],
                        modifyIp: dataRow[reservedColumnNames.modifiedIPAddress]
                    },
                    visible: true,
                    rowKey: rowIndex >= 0 ? this.state.interface.id + rowIndex.toString() : undefined
                });
            } else {
                willUpdateSideBarContents.push({
                    key: 'inputlog',
                    visible: false,
                });
            }
        }

        // 메모
        const memoColumn = this.state.interface.getColumnByName(reservedColumnNames.memoCode);
        if (memoColumn) {
            const rowState = this.state.interface.getState(rowIndex);
            const dataRow = this.state.interface.getRow(rowIndex);
            if (dataRow) {
                willUpdateSideBarContents.push({
                    key: 'memo',
                    data: {
                        memoKey: dataRow[reservedColumnNames.memoCode],
                    },
                    readonly: !this.state.interface.provider!.storeMemo,
                    memoCategory: this.state.interface.gridOption.memoCategory,
                    onApply: (payload) => {
                        if (payload.type === 'saveMemo') {
                            const targetGrid = this.state.interface;
                            if (!targetGrid) {
                                return;
                            }

                            const existMemoKey = targetGrid.getValue(targetGrid.getSelectedIndex(), memoColumn.name) as string;
                            if (!existMemoKey || existMemoKey.length === 0) {
                                targetGrid.storeMemo(targetGrid.getSelectedIndex(), payload.memoKey);
                                targetGrid.applyInternalCellStyle(targetGrid.getSelectedIndex());
                            }

                            // 메모 키를 사이드바에 전달해주기 위해
                            this.setSideBar(targetGrid.getSelectedIndex(), memoColumn.name, false);

                        } else if (payload.type === 'clearMemo') {
                            const targetGrid = this.state.interface;
                            if (!targetGrid) {
                                return;
                            }

                            targetGrid.storeMemo(targetGrid.getSelectedIndex(), null);
                            targetGrid.applyInternalCellStyle(targetGrid.getSelectedIndex());

                            this.setSideBar(targetGrid.getSelectedIndex(), memoColumn.name, false);
                        }

                    },
                    visible: (rowState === GridState.none || rowState === GridState.modified)
                });
            }
        }

        // 가이드 메세지
        if (this.context.guideDocument || (targetColumn!.guideMessage)) {
            let headerText: string | undefined
                = typeof targetColumn.header === 'string' ? targetColumn.header :
                    (targetColumn.header ? targetColumn.header.text : '');

            let guideFromDocument: string | undefined;
            let guideFromColumnOption: JSX.Element | undefined;

            if (this.context.guideDocument) {
                const parsed = parse(this.context.guideDocument);
                const pathAttribute = this.state.interface.id + '_' + columnName;
                const targetElement = parsed.querySelector('[data-guide-id="' + pathAttribute + '"]');

                if (targetElement) {
                    headerText = targetElement.getAttribute('data-guide-title');
                    guideFromDocument = targetElement.toString();
                }
            }

            if (targetColumn.guideMessage) {
                const toJSX = (value: string | JSX.Element | null | undefined) => {
                    if (!value) return;

                    if (value === 'string') {
                        guideFromColumnOption = (
                            <div dangerouslySetInnerHTML={{
                                __html: value
                            }}>
                            </div>
                        );
                    } else {
                        return (
                            <>
                                {value}
                            </>
                        )
                    }
                };

                if (typeof targetColumn.guideMessage === 'function') {
                    guideFromColumnOption = targetColumn.guideMessage({ rowIndex: rowIndex, columnName: columnName });
                } else {
                    guideFromColumnOption = toJSX(targetColumn.guideMessage);
                }
            }

            if (guideFromDocument) {
                willUpdateSideBarContents.push({
                    key: 'gridGuide',
                    component: (
                        <div style={{ width: '100%' }}>
                            <div style={{ backgroundColor: '#f5f5f5', padding: '5px 10px' }}>
                                {headerText}
                            </div>

                            <div style={{ padding: '10px' }}>
                                <div dangerouslySetInnerHTML={{
                                    __html: guideFromDocument
                                }} />

                                {
                                    guideFromColumnOption ? (
                                        <>
                                            <div style={{
                                                borderBottom: 'solid 1px #e6e6e6',
                                                marginTop: '9px',
                                                marginBottom: '9px',
                                            }} />
                                            <div>
                                                {guideFromColumnOption}
                                            </div>
                                        </>
                                    ) : null
                                }
                            </div>

                        </div>
                    ),
                    visible: true,
                })
            } else if (guideFromColumnOption) {
                willUpdateSideBarContents.push({
                    key: 'gridGuide',
                    component: (
                        <div style={{ width: '100%' }}>
                            <div style={{ backgroundColor: '#f5f5f5', padding: '5px 10px' }}>
                                {headerText}
                            </div>
                            <div style={{ padding: '10px' }}>
                                {guideFromColumnOption}
                            </div>
                        </div>
                    ),
                    visible: true,
                })
            }
        }

        this.context.setSideBar(willUpdateSideBarContents, {
            id: this.state.interface.id,
            target: this.state.interface
        });
    }

    /**
     * wrapper에 mousedown 이벤트
     */
    private handleMouseDownInWrapper = (e: React.MouseEvent) => {
        if (this.myRefs.wrapper.current) {
            const wrapperRect = this.myRefs.wrapper.current.getBoundingClientRect();
            /*const cellIndex = */this.state.interface.gridView.mouseToIndex(
                Math.floor(e.clientX - wrapperRect.left),
                Math.floor(e.clientY - wrapperRect.top)
            );
        }
    }

    /**
     * 그리드에 포커스
     */
    public focus(): void {
        if (this.myRefs.wrapper.current) {
            this.myRefs.wrapper.current.focus();
            this.state.interface.gridView.setFocus();
        }
    }

    /**
     * 그리드 refresh
     */
    public refresh(): void {
        if (this.state.interface) {
            this.state.interface.refresh();
        }
    }

    private getCodePicker(codePickerId: string) {
        let codePicker: IBuiltInCodePicker = this.findCodePickerById(codePickerId);

        if (this.state.currentCodePickerColumn
            && this.state.currentCodePickerColumn.codePicker
            && this.state.currentCodePickerColumn.codePicker.id === codePickerId) {
            codePicker = this.state.currentCodePickerColumn.codePicker
        }

        return codePicker;
    }

    /**
     * TODO: dummy data
     * @param id 
     */
    private findCodePickerById = (id: string): IBuiltInCodePicker => {
        const simpleSource: SimpleJsonCodePickerDataSource = new SimpleJsonCodePickerDataSource({
            data: [
                {
                    code: '01',
                    text: 'test'
                },
                {
                    code: '02',
                    text: 'test'
                },
                {
                    code: '03',
                    text: 'test'
                },
                {
                    code: '03_2',
                    text: 'test2'
                }
            ]
        },
            [
                {
                    header: '코드',
                    name: 'code',
                    fieldName: 'code',
                    width: 30,
                    type: ColumnType.text
                },
                {
                    header: '텍스트',
                    name: 'text',
                    fieldName: 'text',
                    width: 30,
                    type: ColumnType.text
                }
            ], 'code', 'text');

        let codePicker: IBuiltInCodePicker = Object.assign(simpleSource, {
            id: "Test",
            placeHolder: "기본적인 코드도움 사용",
            dialogTitle: "타이틀",
            dialogSubtitle: "서브타이틀",
            size: CodePickerSize.medium,
            ustomDialogComponent: DefaultDialog,
        });

        return codePicker;
    }

    /**
     * 그리드의 코드피커 컬럼 셀에서 검색이 발생했을떄 실행되는 콜백
     * @param e 
     */
    private handleCallCodePickerSearch = (e: GridCodePickerCellSearchEventArgs) => {
        if (e.rowIndex < 0) {
            return;
        }

        let codePicker: IBuiltInCodePicker | undefined;

        if (!e.customCodePicker) {
            throw new Error("codePickerId가 null또는 undefined 입니다.")
        }
        if (e.useCustomCodePicker === true) {
            if (typeof e.customCodePicker == 'function') {
                const callBackResult = e.customCodePicker({
                    rowIndex: e.rowIndex,
                    columnName: e.columnName,
                    parameters: e.parameters,
                    keyword: e.keyword
                })
                codePicker = Object.assign({ getData: (e) => Promise.resolve([]) }, callBackResult)
            } else {
                codePicker = Object.assign({ getData: (e) => Promise.resolve([]) }, e.customCodePicker)
            }
        } else {
            codePicker = this.getCodePicker(e.codePickerId!);
        }

        if (!codePicker) {
            throw new Error("[" + e.codePickerId + "] 코드피커를 찾을 수 없습니다.")
        }


        if (!e.keyword || e.keyword.length === 0) {
            // 검색어가 없으면 데이터 클리어로 본다.
            this.state.interface.setValue(e.rowIndex, e.columnName, '');
            this.state.interface._callAfterCodePickerCallback(e.rowIndex, e.columnName, [{
                [codePicker.codeProperty]: '',
                [codePicker.textProperty]: ''
            }]);

            this.state.interface._changeCodePickerState(e.columnName, "binded");
            return;
        }

        const beforeState = this.state.interface.getState(e.rowIndex);

        if (beforeState && e.keyword && e.keyword.length > 0 && codePicker.paging === true) {

            makePagination({
                initialRowCount: codePicker.initialRowCount ? codePicker.initialRowCount : codePicker.rowCountPerPage!,
                rowCountPerPage: codePicker.rowCountPerPage!,
                pagingDirection: 'scrollToBottom',

            }).then((pagination) => {
                let ajaxEbp = this.state.interface.codePickerAjaxEbp ? this.state.interface.codePickerAjaxEbp : this.context.fetch;//this.state.interface.codePickerAjaxEbp;
                if (!ajaxEbp) {
                    console.warn('OBTDataGridInterface.codePickerFetch 옵션을 이용해 페이지컨테이너의 fetch를 할당해 주세요');
                    ajaxEbp = Util.fetch;
                }
                const parameter = {
                    fetch: ajaxEbp,
                    keyword: e.keyword,
                    parameter: e.parameters,
                    parameters: e.parameters
                }

                codePicker!.getData!(CodePickerUtil.trimObject({ ...parameter, pagingInfo: pagination })).then((response) => {

                    this.setState({
                        codePickerCancelCallback: () => {
                            this.state.interface.setValueInternal(e.rowIndex, e.columnName, e.oldValue)
                            this.state.interface._changeCodePickerState(e.columnName, "binded");
                            this.state.interface.setState(e.rowIndex, beforeState);//GridState.none);
                        },
                        currentCodePickerSelectedValueDisplayCallback: e.selectedValueDisplayCallback,
                        currentCodePickerColumn: {
                            codePicker: codePicker,
                            staticParameters: e.parameters,
                            rowIndex: e.rowIndex,
                            columnName: e.columnName,
                            canMultiSelect: e.canMultiSelect,
                            usePaging: this.state.currentCodePickerColumn ? this.state.currentCodePickerColumn.usePaging : false
                        },
                        currentCodePickerKeyword: e.keyword
                    }, () => {
                        if (response.data && response.data.length === 1) {
                            this.handleClickDialogSelectButton({
                                target: this,
                                value: response.data,
                                Source: response.data,
                            })
                        } else {
                            this.setState({
                                isShowCodePickerDialog: true,
                            })
                        }
                    });

                })
            });

            return;
        }

        this.setState({
            currentCodePickerKeyword: e.keyword,
            codePickerCancelCallback: () => {
                if (beforeState) {
                    this.state.interface.setValueInternal(e.rowIndex, e.columnName, e.oldValue)
                    this.state.interface._changeCodePickerState(e.columnName, "binded");
                    this.state.interface.setState(e.rowIndex, beforeState);
                }
            },
            currentCodePickerSelectedValueDisplayCallback: e.selectedValueDisplayCallback,
            currentCodePickerColumn: {
                codePicker: codePicker,
                staticParameters: e.parameters,
                rowIndex: e.rowIndex,
                columnName: e.columnName,
                canMultiSelect: e.canMultiSelect,
                usePaging: this.state.currentCodePickerColumn ? this.state.currentCodePickerColumn.usePaging : false
            },
        }, () => {
            if (this.myRefs.codePickerDialog.current) {
                let ajaxEbp = this.state.interface.codePickerAjaxEbp ? this.state.interface.codePickerAjaxEbp : this.context.fetch;//this.state.interface.codePickerAjaxEbp;
                if (!ajaxEbp) {
                    console.warn('OBTDataGridInterface.codePickerFetch 옵션을 이용해 페이지컨테이너의 fetch를 할당해 주세요');
                    ajaxEbp = Util.fetch;
                }
                const parameter = {
                    fetch: ajaxEbp,
                    keyword: e.keyword,
                    parameter: e.parameters,
                    parameters: e.parameters
                }
                this.myRefs.codePickerDialog.current.fetchCodePickerData(parameter);
            }
        });
    }

    /**
     * 날짜타입 컬럼 다이얼로그 보이기 버튼 클릭시
     */
    private handleCallDatePickerDialog = (e: any) => {
        let initialDate: Date | string | number = this.getInitialDate({
            rowIndex: e.rowIndex,
            columnName: e.columnName,
            dateFormat: e.dateFormat,
            value: e.value,
        });

        if (e.dateFormat === DateFormat.yyyy) {
            // 연도 피커에서는 string   
            initialDate = initialDate.getFullYear().toString();
        } else if (e.dateFormat === DateFormat.yyyyMM) {
            // 연월피커는 number
            const resultMonthString = (initialDate.getMonth() + 1) < 10 ? '0' + (initialDate.getMonth() + 1).toString() : (initialDate.getMonth() + 1).toString()
            initialDate = Number(initialDate.getFullYear().toString() + resultMonthString)
        }

        this.setState({
            currentDateColumn: {
                rowIndex: e.rowIndex,
                columnName: e.columnName,
                dateFormat: e.dateFormat,
                value: e.value,
                dateDialogAlign: e.dateDialogAlign,
                dateDialogPosition: e.dateDialogPosition,
            },
            dateDialogValue: initialDate
        }, () => {
            const dateDialog = this.getCurrentDataDialogRef();
            if (dateDialog && dateDialog.current) {
                this.state.interface.gridView.showEditor();
                dateDialog.current.show(false);

                const isMobile =
                    detector.os.name === 'ios' ||
                    detector.os.name === 'android' ||
                    window.navigator.userAgent.toLowerCase().indexOf('samsungbrowser') !== -1

                if (isMobile === false && e.focusOnDialog) {
                    setTimeout(() => {
                        if (dateDialog && dateDialog.current) {
                            dateDialog.current.focus();
                        }
                    }, 0);
                }
            }
        })
    }

    public setDateColumnDefaultValue = (rowIndex: number, columnName: string) => {
        this.state.interface.setValue(
            rowIndex,
            columnName,
            this.state.dateDialogValue
        );
    }

    public getDateDialogValue = () => {
        return this.state.dateDialogValue;
    }

    private handleCloseDatePickerDialog = () => {
        const currentDateDialog = this.getCurrentDataDialogRef();
        if (currentDateDialog && currentDateDialog.current) {
            currentDateDialog.current.dismiss();
        }
    }

    /**
    * 자동완성 컴포넌트 - 다이얼로그 호출 함수
    */
    private handleCallAutoCompleteDialog = debounce((e: any) => {
        const inputValue = e.value;
        if (this.state.autoCompleteColumn.inputValue === inputValue) {
            return;
        }

        e.column.searchCallback(inputValue).then((result) => {
            if (result && result.length > 0 && inputValue.length > 0 && e.column.name === this.state.interface.getSelection().columnName) {
                let correctKeyword = "";
                let keyProperty = e.column.keyProperty;

                if (!keyProperty) {
                    throw new Error("handleCallAutoCompleteDialog - keyProperty가 존재하지 않습니다.");
                }

                result.forEach((data) => {
                    if (data[keyProperty].match(inputValue)) {
                        correctKeyword = inputValue;
                    }
                });

                this.setState({
                    autoCompleteColumn: {
                        ...this.state.autoCompleteColumn,
                        searchCallback: result,
                        correctKeyword: correctKeyword,
                        isOpen: true,
                        keyProperty: keyProperty,
                        inputValue: inputValue
                    }
                })
            }
            else {
                this.handleCloseAutoCompleteDialog();
            }
        })
    }, 50);

    /**
     * 자동완성 컴포넌트 - 다이얼로그 최초 세팅 함수
     */
    private handleShowEditorAutoCompleteDialog = (e: any) => {
        if (!e.column.keyProperty) {
            throw new Error("handleShowEditorAutoCompleteDialog - column에 keyProperty 속성이 존재하지 않습니다. keyProperty를 설정해주세요." + e);
        }

        this.setState({
            autoCompleteColumn: {
                ...this.state.autoCompleteColumn,
                width: e.width,
                rowIndex: e.rowIndex,
                columnName: e.column.name,
                dialogAlign: e.column.dialogAlign,
                keyProperty: e.column.keyProperty,
                selectedIndex: 0,
                useDirectValueMapping: e.column.useDirectValueMapping === undefined || e.column.useDirectValueMapping === true ? true : false
            }
        }, () => {
            if (this.myRefs.autoCompleteDialog.current) {
                this.myRefs.autoCompleteDialog.current.scrollTop();
            }
        });
    }

    /**
     * 자동완성 컴포넌트 - 다이얼로그 닫기 함수
     */
    private handleCloseAutoCompleteDialog = () => {
        if (this.state.autoCompleteColumn.isOpen) {
            this.setState({
                autoCompleteColumn: {
                    ...this.state.autoCompleteColumn,
                    isOpen: false,
                    searchCallback: [],
                    selectedIndex: 0
                }
            }, () => {
                if (this.myRefs.autoCompleteDialog.current) {
                    this.myRefs.autoCompleteDialog.current.scrollTop();
                }
            });
        }
    }

    /**
     * 자동완성 컴포넌트 - 리스트 클릭 이벤트
     */
    private handleClickAutoCompleteList = (item: any) => {
        if (!this.state.autoCompleteColumn.keyProperty) {
            throw new Error("handleClickAutoCompleteList - column에 keyProperty 속성이 존재하지 않습니다. keyProperty를 설정해주세요.");
        }

        if (!this.state.autoCompleteColumn.isSelectable) {
            return;
        }

        const eventArgs = new GridEvents.SearchedItemSelectEventArgs(
            this,
            this.state.autoCompleteColumn.columnName,
            this.state.autoCompleteColumn.rowIndex,
            item
        );

        if (!this.state.autoCompleteColumn.useDirectValueMapping) {
            this.state.interface.invokeSelectEvent(eventArgs);
            this.handleCloseAutoCompleteDialog();
            return;
        }

        this.state.interface.gridView.cancel();

        this.state.interface.setValue(
            this.state.autoCompleteColumn.rowIndex,
            this.state.autoCompleteColumn.columnName,
            item[this.state.autoCompleteColumn.keyProperty]
        );

        this.state.interface.commit();
        this.focus();
        this.state.interface.invokeSelectEvent(eventArgs);
        this.handleCloseAutoCompleteDialog();
    }

    /**
     * 자동완성 컴포넌트 - 리스트 마우스 무브 이벤트
     */
    private handleMouseMoveCompleteList = (index: number) => {
        this.setState({
            autoCompleteColumn: {
                ...this.state.autoCompleteColumn,
                selectedIndex: index
            }
        })
    }

    /**
    * @internal
    * @param keyCode 
    * 자동완성 컬럼에서 다이얼로그가 열렸을 경우 위/아래/엔터 키 이벤트 함수입니다.
    */
    private handleAutoCompleteKeyDown = (keyCode: number) => {
        if (this.state.autoCompleteColumn.isSelectable &&
            this.state.autoCompleteColumn["isOpen"] &&
            this.state.autoCompleteColumn.searchCallback &&
            this.state.autoCompleteColumn.searchCallback.length > 0
        ) {
            // 키보드 밑으로 누를때
            if (keycode(keyCode) === 'down') {
                if (this.state.autoCompleteColumn.selectedIndex < this.state.autoCompleteColumn.searchCallback.length) {
                    this.setState((state) => {
                        return {
                            autoCompleteColumn: {
                                ...this.state.autoCompleteColumn,
                                selectedIndex: state.autoCompleteColumn.selectedIndex + 1
                            }
                        }
                    }, () => {
                        if (this.state.autoCompleteColumn.selectedIndex === this.state.autoCompleteColumn.searchCallback!.length) {
                            this.setState({
                                autoCompleteColumn: {
                                    ...this.state.autoCompleteColumn,
                                    selectedIndex: 0
                                }
                            })
                        }
                    })
                    return false;
                }
            }
            // 키보드 위로 누를 때 
            else if (keycode(keyCode) === 'up') {
                if (this.state.autoCompleteColumn.selectedIndex > -1) {
                    this.setState((state) => {
                        return {
                            autoCompleteColumn: {
                                ...this.state.autoCompleteColumn,
                                selectedIndex: state.autoCompleteColumn.selectedIndex - 1
                            }
                        }
                    }, () => {
                        if (this.state.autoCompleteColumn.selectedIndex === -1) {
                            this.setState({
                                autoCompleteColumn: {
                                    ...this.state.autoCompleteColumn,
                                    selectedIndex: this.state.autoCompleteColumn.searchCallback!.length - 1
                                }
                            })
                        }
                    })
                    return false;
                }
            }
            // 엔터 누를 시에 
            else if (keycode(keyCode) === "enter") {
                if (this.myRefs.autoCompleteDialog.current) {
                    this.myRefs.autoCompleteDialog.current.scrollTop()
                }

                const targetObject = this.state.autoCompleteColumn.searchCallback[this.state.autoCompleteColumn.selectedIndex];

                if (!this.state.autoCompleteColumn.keyProperty) {
                    throw new Error("handleAutoCompleteKeyDown(enter) - column에 keyProperty 속성이 존재하지 않습니다. keyProperty를 설정해주세요.");
                }

                const eventArgs = new GridEvents.SearchedItemSelectEventArgs(
                    this,
                    this.state.autoCompleteColumn.columnName,
                    this.state.autoCompleteColumn.rowIndex,
                    targetObject
                );

                if (this.state.autoCompleteColumn.useDirectValueMapping === false) {
                    this.state.interface.invokeSelectEvent(eventArgs);
                    this.handleCloseAutoCompleteDialog();
                    return false;
                }

                this.setState({
                    autoCompleteColumn: {
                        ...this.state.autoCompleteColumn,
                        isSelectable: false
                    }
                }, () => {
                    if (this.state.autoCompleteColumn.searchCallback) {
                        const targetObject = this.state.autoCompleteColumn.searchCallback[this.state.autoCompleteColumn.selectedIndex];

                        if (!this.state.autoCompleteColumn.keyProperty) {
                            throw new Error("handleAutoCompleteKeyDown(enter) - column에 keyProperty 속성이 존재하지 않습니다. keyProperty를 설정해주세요.");
                        }

                        this.state.interface.gridView.cancel();

                        this.state.interface.setValue(
                            this.state.autoCompleteColumn.rowIndex,
                            this.state.autoCompleteColumn.columnName,
                            targetObject[this.state.autoCompleteColumn.keyProperty]
                        );

                        this.state.interface.commit();

                        const eventArgs = new GridEvents.SearchedItemSelectEventArgs(
                            this,
                            this.state.autoCompleteColumn.columnName,
                            this.state.autoCompleteColumn.rowIndex,
                            targetObject
                        );

                        this.state.interface.invokeSelectEvent(eventArgs);

                        this.setState({
                            autoCompleteColumn: {
                                ...this.state.autoCompleteColumn,
                                isSelectable: true,
                                isOpen: false,
                                searchCallback: [],
                                selectedIndex: 0,
                            }
                        });
                    }
                });
            }
        }
        return true
    }

    /**
     * 인터페이스에서 코드피커 다이얼로그를 열라는 요청이 왔을시 실행됨
     * @param e 
     */
    private handleCallCodePickerDialog = (e: any) => {
        let codePicker: IBuiltInCodePicker | undefined;
        if (e.useCustomCodePicker === true) {
            if (typeof e.customCodePicker == 'function') {
                const callBackResult = e.customCodePicker({
                    rowIndex: e.rowIndex,
                    columnName: e.columnName,
                    parameters: e.parameters,
                    keyword: e.keyword
                })
                codePicker = Object.assign({ getData: (e) => Promise.resolve([]) }, callBackResult)
            } else {
                codePicker = Object.assign({ getData: (e) => Promise.resolve([]) }, e.customCodePicker)
            }
        } else {
            codePicker = this.getCodePicker(e.codePickerId);
        }

        if (!codePicker) {
            throw new Error(e.codePickerId + "를 찾을 수 없습니다.");
        }

        let ajaxEbp = this.state.interface.codePickerAjaxEbp ? this.state.interface.codePickerAjaxEbp : this.context.fetch;
        if (!ajaxEbp) {
            console.warn('OBTDataGridInterface.codePickerFetch 이용해 페이지컨테이너의 fetch를 할당해 주세요');
            ajaxEbp = Util.fetch;
        }

        this.setState({
            currentCodePickerColumn: {
                codePicker: codePicker,
                staticParameters: e.parameters,
                rowIndex: e.rowIndex,
                columnName: e.columnName,
                canMultiSelect: e.canMultiSelect,
                usePaging: codePicker.paging ? codePicker.paging : false,

            },
            currentCodePickerKeyword: e.keyword ? e.keyword : null,
            isShowCodePickerDialog: true,
            alreadyRead: false,
            currentCodePickerSelectedValueDisplayCallback: e.selectedItemDisplayCallBack,
            codePickerCancelCallback: () => { }
        });
    }

    /**
     * 다이얼로그 닫기 콜백
     * @param evt 
     */
    handleClickCloseDialogButton = (evt?: Events.EventArgs) => {
        this.state.interface.gridView.commit();

        this.setState({
            isShowCodePickerDialog: false,
            currentCodePickerKeyword: null,
            alreadyRead: false
        }, () => {
            this.state.codePickerCancelCallback();

            this.state.interface.gridView.setFocus();
        });
    }

    /**
     * 다이얼로그에서 아이템 선택을 했을 경우,
     * 혹은 코드 검색으로 아이템이 바인딩 될 경우
     * 혹은 코드 검색시 결과가 없어 이전 데이터로 돌릴경우
     * @param item 
     */
    private handleClickDialogSelectButton = (evt: Events.ChangeEventArgs<any[] | CodePickerValue>) => {
        if (!this.state.currentCodePickerColumn || !this.state.currentCodePickerColumn.codePicker) {
            throw new Error('코드피커를 찾을수 없습니다.');
        }

        const selectedItems = CodePickerValue.asArrayValue(evt.value);

        if (!selectedItems || selectedItems.length === 0) {
            this.state.codePickerCancelCallback()
            return;
        }

        this.state.codePickerCancelCallback();

        this.setState(state => ({
            isShowCodePickerDialog: false,
        }), () => {
            const codePickerColumn = this.state.currentCodePickerColumn!;
            // 데이터 바인딩
            if (!codePickerColumn.canMultiSelect || selectedItems.length === 1) {
                const displayValue = this.state.currentCodePickerSelectedValueDisplayCallback ?
                    this.state.currentCodePickerSelectedValueDisplayCallback(
                        {
                            codeProperty: codePickerColumn.codePicker!.codeProperty,
                            textProperty: codePickerColumn.codePicker!.textProperty,
                            selectedItem: selectedItems[0],
                        }
                    ) : selectedItems[0][codePickerColumn.codePicker!.codeProperty];

                const isCancel: boolean = this.state.interface.setValue(
                    codePickerColumn.rowIndex,
                    codePickerColumn.columnName,
                    displayValue
                );

                if (isCancel === false) {
                    this.setState({
                        currentCodePickerKeyword: null,
                        codePickerCancelCallback: () => { },
                    }, () => {
                        this.state.interface._callAfterCodePickerCallback(
                            codePickerColumn.rowIndex,
                            codePickerColumn.columnName,
                            selectedItems
                        );

                        this.state.interface._changeCodePickerState(codePickerColumn.columnName, "binded");
                        this.state.interface.gridView.setFocus();

                        this.moveCellAfterCodeHelp();

                    })
                } else {
                    this.state.codePickerCancelCallback();
                    this.state.interface._changeCodePickerState(codePickerColumn.columnName, "binded");
                    this.state.interface.gridView.setFocus();
                }
            } else {
                this.state.interface._callAfterCodePickerCallback(
                    codePickerColumn.rowIndex,
                    codePickerColumn.columnName,
                    selectedItems
                );
                this.state.interface._changeCodePickerState(
                    codePickerColumn.columnName, "binded"
                );

                this.moveCellAfterCodeHelp();
            }
        });
    }

    private moveCellAfterCodeHelp() {
        const codePickerColumn = this.state.currentCodePickerColumn!;

        const codePickerGridColumn = this.state.interface.getColumnByName(codePickerColumn.columnName);
        if (codePickerGridColumn && codePickerGridColumn.autoMoveNextCell !== false) {
            const nextCell = this.state.interface.getNextActiveCellUsingIColumn(codePickerColumn.rowIndex, codePickerGridColumn);
            if (nextCell && nextCell.columnName) {
                this.state.interface.setSelection(nextCell.rowIndex, nextCell.columnName);
            }
        }
    }

    private handleClickCloseExcelExportDialog = () => {
        this.setState({
            isShowExportExcelDialog: false
        })
    }

    private handleFocus = (e: React.FocusEvent) => {
        if (this.state.interface) {
            this.state.interface._handleFocus();
            this.setSideBar(
                this.state.interface.getSelectedIndex(),
                this.state.interface.getSelectedColumnName(),
                true
            );
        }

        if (this.context && this.context.setLastFocusedGrid && this.state.interface.gridOption.isSystemGrid !== true) {
            this.context.setLastFocusedGrid(this.state.interface);
        }

        Util.invokeEvent<Events.FocusEventArgs>(this.props.onFocus, {
            ...e,
            target: this.state.interface
        });
    }

    private handleBlur = () => {
        if (this.context && this.context.clearSideBar) {
            this.context.clearSideBar();
        }

        setTimeout(() => {
            if (this.state.interface)
                this.state.interface._handleBlur(this.myRefs.wrapper);
        }, 0);
    }

    private refreshOnVisibleChanged() {
        if (this.myRefs.wrapper.current && this.state.interface) {
            const style = window.getComputedStyle(this.myRefs.wrapper.current);
            const visible = style.display !== 'none' && style.visibility !== 'hidden' && this.myRefs.wrapper.current.offsetParent !== null;
            const canvas = this.myRefs.wrapper.current.querySelector("canvas");
            const canvasSize = {
                height: canvas ? (canvas.getBoundingClientRect() || {}).height : 0,
                width: canvas ? (canvas.getBoundingClientRect() || {}).width : 0
            };
            if (visible && (canvasSize.height <= 0 || canvasSize.width <= 0)) {
                this.state.interface.refresh();
            }
        }
    }

    private changeEmptySetType = (emptySetType: EmptySetType) => {
        if (this.myRefs.wrapper.current) {
            const bound = this.myRefs.wrapper.current.getBoundingClientRect();
            const bodyHeight = this.state.interface.getBodyAreaHeight(bound.height);

            let emptySetSize: EmptySetSize = 'normal';
            if (bodyHeight < 100) {
                emptySetSize = 'tiny'
            } else if (bodyHeight < 121) {
                emptySetSize = 'small'
            }

            if (emptySetType !== EmptySetType.hasData && this.state.interface.getRowCount() > 0) {
                emptySetType = EmptySetType.hasData;
            }

            this.setState({
                emptySetType: emptySetType,
                emptySetSize: emptySetSize,
            });
        } else {
            if (emptySetType !== EmptySetType.hasData && this.state.interface.getRowCount() > 0) {
                emptySetType = EmptySetType.hasData;
            }

            this.setState({
                emptySetType: emptySetType,
            });
        }
    }

    private changeEmptySetTypeDebounce = debounce(this.changeEmptySetType, 300);

    private handleChangeEmptySetType = (emptySetType: EmptySetType) => {
        this.changeEmptySetType(emptySetType);
    }

    private handleClickContextMenuCustomColumn = () => {
        if (this.state.interface.hasDirtyData() === true) {
            this.setState({
                alertHasDirtyData: true
            })

            return;
        }

        this.setState({
            isShowCustomColumnDialog: true
        });
    }

    /**
     * 컨텍스트 메뉴 | 찾기
     */
    private handleClickContextMenuShowSearchBox = () => {
        if (this.state.interface.hasDirtyData() === true) {
            this.setState({
                alertHasDirtyData: true
            })

            return;
        }

        this.setState({
            searchBox: {
                ...this.state.searchBox,
                isShowSearchBox: true
            }
        }, () => {
            if (this.myRefs.searchBoxInput.current) {
                this.myRefs.searchBoxInput.current.focus();
            }
        });
    }

    /**
     * 컨텍스트 메뉴 | 엑셀 내리기
     * 더티데이터 체크로직 제거 요청으로 바로 다이얼로그 연다.
     */
    private handleClickContextMenuExportExcel = () => {

        this.setState({
            isShowExportExcelDialog: true,
        });
    }

    private getCurrentDataDialogRef = () => {
        if (!this.state.currentDateColumn) {
            return;
        }

        // TODO: 없애도 되는지 체크 
        if (!this.state.currentDateColumn.dateFormat) {
            return this.myRefs.dateDialogYYYYMMDD;
        } else if (this.state.currentDateColumn.dateFormat === DateFormat.yyyyMMdd) {
            return this.myRefs.dateDialogYYYYMMDD;
        } else if (this.state.currentDateColumn.dateFormat === DateFormat.yyyyMM) {
            return this.myRefs.dateDialogYYYYMM;
        } else if (this.state.currentDateColumn.dateFormat === DateFormat.yyyy) {
            return this.myRefs.dateDialogYYYY;
        }
    }

    private handleKeyDownOnGrid = (keyCode: number) => {
        if (this.state.currentDateColumn) {
            const currentRef = this.getCurrentDataDialogRef();

            if (currentRef && currentRef.current) {
                if (currentRef.current.state.open === true) {
                    if (keycode(keyCode) === 'down') {
                        currentRef.current.focus();

                        return false;
                    }
                }
            }
        }

        return true;
    }

    /**
     * 조회
     */
    private handleAfterRead = () => {
        this.setState({
            isShowCodePickerDialog: false,
            searchBox: {
                ...this.state.searchBox,
                isShowSearchBox: false,
                keyword: '',
                // startIndex: 0
            }
        }, () => {
            this.searchBoxStartFieldIndex = 0;
            this.searchBoxStartIndex = 0;
            this._updateHover();
        });
    }

    /**
     * 
     * @param evt 
     */
    private handleImageButtonClicked = (evt: GridEvents.ImageButtonClickedEventArgs) => {
        // user profile 처리
        if (evt.name !== 'userProfileIcon') {
            return;
        }

        const targetColumn = this.state.interface.getColumnByName(evt.columnName);
        if (targetColumn && targetColumn.getProfileInfo) {
            targetColumn.getProfileInfo({
                rowIndex: evt.rowIndex,
                columnName: evt.columnName,
                values: evt.values
            }).then(parameter => {
                const context = this.context as IOBTContext;
                if (context) {
                    context.showUserProfile!(parameter);

                }
            });
        }
    }

    /**
     * 데이터 검색버튼 클릭
     */
    private handleClickSearchBoxFindButton = () => {
        if (!this.state.searchBox.keyword || this.state.searchBox.keyword.trim().length === 0) {
            return;
        }

        let targetColumnNames: string[] | null;
        if (this.state.searchBox.selectedSearchBoxDropdownValue.value === '0') {
            targetColumnNames = this.state.interface.getFlatColumns(true).map(item => OBTDataGridInterface.getFieldName(item));
        } else {
            const selectedSearchBoxDropdownValue = this.state.searchBox.selectedSearchBoxDropdownValue;
            targetColumnNames = this.state.interface.getFlatColumns(true).filter(item => {
                return OBTDataGridInterface.getFieldName(item) === selectedSearchBoxDropdownValue.value
            }).map(item => item.name);
        }

        const option = {
            startIndex: this.searchBoxStartIndex, //this.state.searchBox.startIndex,
            startFieldIndex: this.searchBoxStartFieldIndex, //this.state.searchBox.startFieldIndex,
            columnNames: targetColumnNames,
            partialMatch: true,
            selectSearchedCell: false,
            value: this.state.searchBox.keyword
        }
        const cell = this.state.interface.searchCell(option);

        if (cell) {
            // 검색 데이터가 존재할 때
            if (cell.rowIndex < this.searchBoxStartIndex //this.state.searchBox.startIndex
                && this.state.interface.getGridOption().paging === true
                && this.state.interface.getPagingInfo()!.isLastPage() === false) {
                this.state.interface.gridView.setTopItem(
                    this.state.interface.getPagingInfo()!.pagingDirection === "scrollToBottom" ?
                        this.state.interface.getRowCount() - 1 : 0
                );
            } else {
                const startFieldIndex = targetColumnNames.map((item, index) => {
                    return { index: index, item: item }
                }).filter(item => {
                    return item.item === cell.columnName;
                })[0].index + 1;

                // this.setState({
                //     searchBox: {
                //         ...this.state.searchBox,
                //         startIndex: cell.rowIndex,
                //         startFieldIndex: startFieldIndex,
                //     }
                // }, () => {
                // });
                this.state.interface.setSelection(cell.rowIndex, cell.columnName ? cell.columnName : undefined);

            }
        } else {
            // 존재하지 않을 때
            if (this.state.interface.getGridOption().paging === true
                && this.state.interface.getPagingInfo()!.isLastPage() === false) {
                // 페이징 모드에서 마지막 페이지가 아니면 지속적인 검색을 위해 스크롤 이동을 통해서 페이지 이동처리
                this.state.interface.gridView.setTopItem(
                    this.state.interface.getPagingInfo()!.pagingDirection === "scrollToBottom" ?
                        this.state.interface.getRowCount() - 1 : 0
                );
            } else {
                const eventArgs = new GridEvents.AlertEventArgs(
                    this.state.interface,
                    GridEvents.GridAlertReason.NO_SEARCH_CELL_RESULT,
                    null
                );
                this.state.interface.invokeAlertEvent(eventArgs);

                // this.setState({
                //     searchBox: {
                //         ...this.state.searchBox,
                //         startIndex: 0,
                //         startFieldIndex: 0,
                //     }
                // })
                this.searchBoxStartFieldIndex = 0;
                this.searchBoxStartIndex = 0;
            }
        }
    }

    private getSearchCellTargetColumnNames() {
        let columnNames: string[] | null = null;
        if (this.state.searchBox.selectedSearchBoxDropdownValue.value === '0') {
            columnNames = this.state.interface.getFlatColumns(true).map(item => item.name);
        } else {
            columnNames = this.state.interface.getFlatColumns(true).filter(item => {
                return item.header === this.state.searchBox.selectedSearchBoxDropdownValue.labelText
            }).map(item => item.name);
        }

        return columnNames;
    }

    private handleClickSearchBoxCloseButton = () => {
        this.searchBoxStartFieldIndex = 0;
        this.searchBoxStartIndex = 0;

        this.setState({
            searchBox: {
                ...this.state.searchBox,
                keyword: '',
                isShowSearchBox: false,
                // startIndex: 0,
                // startFieldIndex: 0,
                selectedSearchBoxDropdownValue: { value: '0', labelText: OrbitInternalLangPack.getText('WE000000529', '전체') },
            }
        });
    }

    private handleChangeSearchBoxDropdown = (e: Events.ChangeEventArgs<string>) => {
        const selectedSearchBoxDropdownValue = { value: '0', labelText: OrbitInternalLangPack.getText('WE000000529', '전체'), }

        if (e.Source[0]) {
            selectedSearchBoxDropdownValue.value = e.Source[0].value;
            selectedSearchBoxDropdownValue.labelText = e.Source[0].labelText;
        }

        this.searchBoxStartFieldIndex = 0;
        this.searchBoxStartIndex = 0;

        this.setState({
            searchBox: {
                ...this.state.searchBox,
                selectedSearchBoxDropdownValue: selectedSearchBoxDropdownValue,
                // startIndex: 0,
                // startFieldIndex: 0
            }
        });
    }

    private handleChangeSearchBoxTextInput = (e: Events.ChangeWithSubEventArgs<string>) => {
        this.setState({
            searchBox: {
                ...this.state.searchBox,
                keyword: e.value
            }
        })
    }

    private renderEmptyMessage = () => {
        if (this.state.emptySetType === "hasData") {
            return <></>;
        } else if (this.state.emptySetType === 'noData') {
            return (
                <div style={{
                    position: 'absolute',
                    width: "100%",
                    height: "100%",
                    bottom: '0',
                }}>
                    <div className={styles.emptyData}>
                        {
                            this.state.interface.getGridOption().hideEmptyImage === true ?
                                (<></>) :
                                (<img src={emptySearchImg} alt={''}></img>)
                        }
                        <div className={styles.text}>{this.state.interface.getGridOption().emptySearchMsg}</div>
                    </div>
                </div>
            );
        } else if (this.state.emptySetType === 'beforeSearch') {
            return (
                <div style={{
                    position: 'absolute',
                    width: "100%",
                    height: "100%",
                    bottom: '0',
                }}>
                    <div className={styles.emptyData}>
                        {
                            this.state.interface.getGridOption().hideEmptyImage === true ?
                                (<></>) :
                                (<img src={emptyDataImg} alt={''}></img>)
                        }
                        <div className={styles.text}>{this.state.interface.getGridOption().emptyDataMsg}</div>
                    </div>
                </div>
            );
        }
    }

    renderSearchBox() {
        if (this.state.interface.isReady === false) {
            return;
        }

        if (this.state.searchBox.isShowSearchBox === false) {
            return;
        }

        if (this.state.interface.hasDirtyData() === true) {
            return;
        }

        const columnList = [
            {
                value: '0',
                labelText: OrbitInternalLangPack.getText('WE000000529', '전체'),
            },
        ];

        if (this.state.interface.columns) {
            this.state.interface.getFlatColumns(false).filter(column => {
                const headerText = this.state.interface.getHeaderText(column.name);
                return headerText && headerText !== '';
            }).map(column => {
                return {
                    ...column,
                    header: this.state.interface.getHeaderText(column.name)
                }
            }).forEach((item, index) => {
                columnList.push({
                    value: item.name ? item.name : '',
                    labelText: this.state.interface.getHeaderText(item.name),
                })
            });
        }

        return (
            <Draggable
                bounds={"parent"}
                offsetParent={this.myRefs.wrapper.current ? this.myRefs.wrapper.current : undefined}
            >
                <div ref={this.myRefs.searchBox}
                    tabIndex={-1}
                    onKeyUp={
                        (e) => {
                            if (e && e.keyCode === 13) {
                                this.handleClickSearchBoxFindButton();
                            } else if (e && e.keyCode === 27) {
                                this.handleClickSearchBoxCloseButton();
                                this.state.interface.focus();
                            }
                        }
                    }
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        width: '200px',
                        backgroundColor: 'white',
                        outline: 'none',
                        border: '1px solid black', //rgb(231, 231, 231)'
                        overflow: 'hidden'
                    }}>
                    <OBTFormPanel
                        disabled={false}
                        width={'100%'}
                        usePageAuthority={false}
                    >
                        <colgroup>
                            <col />
                            <col />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th style={{ width: '55px' }}>{OrbitInternalLangPack.getText('WE000002282', '컬럼')}</th>
                                <td>
                                    <OBTDropDownList2
                                        list={columnList}
                                        value={this.state.searchBox.selectedSearchBoxDropdownValue.value}
                                        onChange={this.handleChangeSearchBoxDropdown}
                                        onMouseDown={e => e.event.stopPropagation()}
                                        displayType={DisplayType.text}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th style={{ width: '55px' }}>{OrbitInternalLangPack.getText('WE000000099', '내용')}</th>
                                <td>
                                    <OBTTextField ref={this.myRefs.searchBoxInput}
                                        placeHolder={OrbitInternalLangPack.getText('WE000000238', '검색어')}
                                        value={this.state.searchBox.keyword}
                                        onMouseDown={e => e.event.stopPropagation()}
                                        onChange={this.handleChangeSearchBoxTextInput}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </OBTFormPanel>
                    <div style={{
                        padding: '7px 0px',
                        textAlign: 'center',
                        backgroundColor: '#f2f2f2'
                    }}>
                        <OBTButton labelText={OrbitInternalLangPack.getText('WE000001970', '닫기')}
                            onMouseDown={e => e.event.stopPropagation()}
                            onClick={this.handleClickSearchBoxCloseButton}
                        />
                        {'\u00A0'}
                        <OBTButton
                            labelText={OrbitInternalLangPack.getText('WE000000999', '찾기')}
                            onMouseDown={e => e.event.stopPropagation()}
                            onClick={this.handleClickSearchBoxFindButton}
                            onKeyDown={(e) => {
                                e.event.preventDefault();
                            }}
                        />
                    </div>
                </div>
            </Draggable>
        );
    }

    renderCodePickerDialog() {
        if (!this.state.currentCodePickerColumn || !this.state.currentCodePickerColumn.codePicker) {
            return;
        }

        const codePickerColumn = this.state.currentCodePickerColumn;
        const targetColumn = this.state.interface.getColumnByName(codePickerColumn.columnName);
        return (
            <OBTCodePickerDialogController
                ref={this.myRefs.codePickerDialog}
                canMultiSelect={codePickerColumn.canMultiSelect}
                codePicker={codePickerColumn.codePicker!}
                isShowDialog={this.state.isShowCodePickerDialog}
                parameters={codePickerColumn.staticParameters}
                value={[]}
                keyword={this.state.currentCodePickerKeyword}
                paging={codePickerColumn.usePaging ? codePickerColumn.usePaging : false}
                alreadyRead={this.state.alreadyRead}
                fetch={this.state.interface.codePickerAjaxEbp ? this.state.interface.codePickerAjaxEbp : this.context.fetch}
                dialogParameters={targetColumn && targetColumn.codePickerDialogParameters ?
                    () => {
                        return targetColumn.codePickerDialogParameters!({
                            rowIndex: codePickerColumn.rowIndex,
                            columnName: codePickerColumn.columnName
                        });
                    } : undefined
                }
                onChange={this.handleClickDialogSelectButton}
                onShow={() => {
                    this.setState({
                        isShowCodePickerDialog: true,
                        alreadyRead: true
                    })
                }}
                onClose={this.handleClickCloseDialogButton}
                onChangeKeyword={(keyword) => {
                    this.setState({
                        currentCodePickerKeyword: keyword
                    })
                }}
            />
        )
    }

    handleReorderMouseDown = (e: React.MouseEvent) => {
        try {
            if (this.myRefs.wrapper.current) {
                e.preventDefault();
                e.stopPropagation();

                // onInnerDragStart 호출
                if (this.props.interface) {
                    if (this.props.interface.handleInnerDragStart(this.props.interface.gridView, { startRow: this.state.hover.rowIndex }) === false) {
                        return;
                    }
                }

                this.state.interface.setSelection(this.state.hover.rowIndex);

                document.addEventListener('mouseup', this.handleReorderMouseUp, true);
                const canvasElement = this.myRefs.wrapper.current.querySelector<HTMLCanvasElement>(`#${this.state.interface._gridElementId} > div > canvas`);
                if (canvasElement) {

                    const image = canvasElement.toDataURL('image/png', 1);
                    this.setState({
                        hover: {
                            ...this.state.hover,
                            reorder: true,
                            reorderIndex: this.state.hover.rowIndex,
                            reorderImage: <img className={styles.reorderImage} src={image} alt={''} crossOrigin={"anonymous"}
                                style={{
                                    top: `calc(${this.state.hover.boundary.top} * -1 - 1px)`,
                                    left: `calc(${this.state.hover.moveThumbBoundary.left} * -1)`
                                }} />
                        }
                    });
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    handleReorderMouseUp = (e: MouseEvent) => {
        if (this.myRefs.wrapper.current && this.state.interface.getRowCount() > 0) {
            const event = {
                clientX: e.clientX,
                clientY: e.clientY
            };
            if (this.state.hover.rowIndex >= 0 && this.state.hover.reorderIndex !== this.state.hover.rowIndex) {
                this.state.interface.handleInnerDrop(this.state.interface.gridView, { dataRow: this.state.hover.rowIndex }, { startRow: this.state.hover.reorderIndex });
            }
            this.setState({
                hover: {
                    ...this.state.hover,
                    reorder: false,
                    reorderIndex: -1,
                    reorderImage: undefined
                }
            }, () => {
                setTimeout(() => {
                    this.setHover(false, true, event);
                }, 0);
            });
        }
        document.removeEventListener('mouseup', this.handleReorderMouseUp, true);
    }

    public _updateHover = () => {
        if (this.state.hover.visible && this.state.hover.mousePosition) {
            this.setHover(false, false, this.state.hover.mousePosition);
        }
    }

    private setHover = ((leaved: boolean, refresh: boolean, e: { clientX: number, clientY: number }) => {
        const { gridView } = this.state.interface;
        // let currentColumn: string | null = null;
        const newHover = Object.assign({}, this.state.hover);
        let hoverBoundary: any = null;

        if (leaved === false) {
            if (this.myRefs.wrapper.current) {
                const wrapperBoundary = this.myRefs.wrapper.current.getBoundingClientRect();
                const location = {
                    x: Math.floor(wrapperBoundary.width / 2),
                    y: Math.floor(e.clientY - wrapperBoundary.top)
                };
                const index = gridView.mouseToIndex(location.x, location.y);
                newHover.rowIndex = index ? index.itemIndex : -1;
                // const currentColumnIndex = gridView.mouseToIndex(Math.floor(e.clientX - wrapperBoundary.left), location.y);
                // currentColumn = currentColumnIndex ? currentColumnIndex.column : null;
                const cellBoundary = index ? gridView.getCellBounds(newHover.rowIndex, index.column, false) : null;
                if (cellBoundary) {
                    const height = this.state.interface.gridOption.eachRowResizable ?
                        [...new Array(gridView.getItemCount())].map((dummy, index) => gridView.getRowHeight(index)).reduce((heights, height) => heights + height, 0) :
                        gridView.getItemCount() * cellBoundary.height;
                    const verticalScrollVisible = (wrapperBoundary.height - ((gridView.getHeader() || {}).height || 0) - ((gridView.getFooter() || {}).height || 0)) < height;
                    hoverBoundary = {
                        top: cellBoundary.y,
                        right: verticalScrollVisible ? 16 : 0,
                        height: cellBoundary.height
                    }
                }
                newHover.visible = newHover.rowIndex >= 0 && hoverBoundary ? true : false;
            }
        } else {
            newHover.visible = false;
        }

        if (refresh || newHover.visible !== this.state.hover.visible || newHover.rowIndex !== this.state.hover.rowIndex) {
            if (newHover.visible && newHover.rowIndex >= 0) {
                if (hoverBoundary) {
                    newHover.boundary = {
                        left: `0px`,
                        top: `${hoverBoundary.top - 1}px`,
                        right: `${hoverBoundary.right}px`,
                        height: `${hoverBoundary.height + 1}px`
                    };
                }

                newHover.moveThumbBoundary = {
                    left: `${(this.state.interface.getGridOption().indicator ? 40 : 0) +
                        (this.state.interface.getGridOption().checkable ? 30 : 0)}px`
                }

                newHover.actionButtons = undefined;
            }

            this.setState({
                hover: {
                    ...newHover,
                    mousePosition: {
                        clientX: e.clientX,
                        clientY: e.clientY
                    }
                }
            });
        }
    });

    handleMouseLeave = (e: React.MouseEvent) => {
        if (!this.state.interface || this.state.interface.gridOption.rowMovable !== true) {
            return;
        }

        if (this.state.hover.visible) {
            this.setHover(true, false, e);
        }
    }

    handleMouseMove = (e: React.MouseEvent) => {
        if (!this.state.interface || this.state.interface.gridOption.rowMovable !== true) {
            return;
        }

        if (this.myRefs.wrapper.current && this.state.interface.getRowCount() > 0) {
            this.setHover(false, false, e);
        }
    };

    /**
     * 엑셀 내려받기 다이얼로그 렌더링
     * 요청으로 더티 데이터 체크로직 제거
     */
    renderExcelExportDialog() {
        if (this.state.isShowExportExcelDialog === false) {
            return;
        }

        if (this.state.interface.isReady === false) {
            return;
        }

        if (this.state.interface.getGridOption().preventRClick === true) {
            return;
        }

        return (
            this.state.isShowExportExcelDialog ? <ExcelExportDialog
                isShow={this.state.isShowExportExcelDialog}
                columnList={this.state.interface.getFlatColumns(false).filter(column => {
                    const headerText = this.state.interface.getHeaderText(column.name);
                    return headerText && headerText !== '' && column.type !== ColumnType.data;
                }).map(column => {
                    return {
                        ...column,
                        header: this.state.interface.getHeaderText(column.name)
                    }
                })}
                showExportAllPageCheck={this.state.interface.getGridOption().paging === true}
                onClickTransform={this.handleClickTransformExcelExportDialog}
                onClickClose={this.handleClickCloseExcelExportDialog}
            /> : undefined
        )
    }

    renderCustomColumnDialog() {
        if (this.state.isShowCustomColumnDialog === false) {
            return;
        }

        if (this.state.interface.isReady === false) {
            return;
        }

        if (this.state.interface.hasDirtyData() === true) {
            return;
        }

        if (this.state.interface.getGridOption().preventRClick === true) {
            return;
        }

        const fixedColumnCount = this.state.interface.getGridOption().fixedColumnCount || 0;

        // 그룹컬럼을 제외한 순수 컬럼을 가져온다.
        let columnList = this.state.interface.getFlatColumns(false).filter(column => {
            const headerText = this.state.interface.getHeaderText(column.name);
            return headerText && headerText !== '' && column.type !== ColumnType.data;
        }).map(column => {
            return {
                ...column,
                header: this.state.interface.getHeaderText(column.name)
            }
        });

        const fixedColumnList = this.state.interface.getColumns().filter((column, index) => {
            return column.visible !== false && column.type !== ColumnType.data
        }).filter((column, index) => {
            return fixedColumnCount > index
        });

        const fixedColumnListFlat = this.state.interface._getFlatColumns(fixedColumnList, false);

        columnList = columnList.map(item => {
            if (fixedColumnListFlat.some(itemFix => item.name === itemFix.name)) {
                return {
                    ...item,
                    hidable: false
                }
            }

            return item;
        });

        return (
            this.state.isShowCustomColumnDialog ? <UserCustomColumnDialog
                isShow={this.state.isShowCustomColumnDialog}
                columnList={columnList}
                onChangeColumn={(columnChangeInfoList) => {
                    if (this.state.interface.columns) {
                        columnChangeInfoList.forEach(item => {
                            this.state.interface.setColumnVisible(item.columnName, item.isVisibleColumn === 'T' ? true : false);
                        })
                    }
                }}
                onResetButtonClick={() => {
                    this.setState({
                        confirmResetColumnSetting: true
                    })
                }}
                onClickClose={() => {
                    this.setState({
                        isShowCustomColumnDialog: false
                    })
                }}
            /> : undefined
        )
    }

    private handleClickTransformExcelExportDialog = (selectedList: { columnName: string, transform: 'T' | 'F', isVisibleColumn: 'T' | 'F', withoutMasking: 'T' | 'F', maskable: 'T' | 'F' }[], exportAllPage: boolean) => {
        this.setState({
            isShowExportExcelDialog: false,
        }, () => {
            if (exportAllPage) {
                this.state.interface.getAllPageRows()
                    .then(dataItems => {
                        return this.state.interface.readData((e) => Promise.resolve(dataItems));
                    })
                    .then(() => {
                        this.state.interface.getPagingInfo()!.preventRead();
                        this.exportExcelBindedData(selectedList);
                    });
            } else {
                this.exportExcelBindedData(selectedList);
            }
        })
    }

    private async exportExcelBindedData(selectedList: { columnName: string, transform: 'T' | 'F', isVisibleColumn: 'T' | 'F', withoutMasking: 'T' | 'F', maskable: 'T' | 'F' }[]) {
        const hideColumns = selectedList.filter(item => {
            return item.isVisibleColumn === 'T' && item.transform === 'F'
        }).map(item => item.columnName);

        const showColumns = selectedList.filter(item => {
            return item.isVisibleColumn === 'F' && item.transform === 'T'
        }).map(item => item.columnName);

        // 개인정보 조회
        const withoutMaskingColumns = selectedList.filter(item => {
            return item.maskable === 'T' && item.withoutMasking === 'T';
        });

        const valueMap = withoutMaskingColumns && withoutMaskingColumns.length > 0 ?
            await (async () => {
                const columns = withoutMaskingColumns.map(item => item.columnName);
                const valueMap: any = {};
                await this.state.interface.getPrivacyValues({
                    rowIndexes: [...new Array(this.state.interface.getRowCount())].map((dummy, index) => index),
                    columns: this.state.interface.getFlatColumns(false).filter((column => columns.includes(column.name))),
                    accessType: 'download',
                    force: true,
                    readCallback: (list) => {
                        list.forEach(item => {
                            valueMap[`${item.rowIndex}-${item.column.name}`] = item.privacy.privacyValue;
                        })
                    }
                });
                return valueMap;
            })() : null;

        // 포매터
        const rows = [...new Array(this.state.interface.getRowCount())].map((dummy, index) => this.state.interface.gridView.getDisplayValues(index, true));
        const textCallback = (index: number, columnName: string, value: any) => {
            if (value) {
                const column = this.state.interface.getColumnByName(columnName);
                // 마스크 타입은 마스킹을 재처리한다.
                if (column && column.type === ColumnType.mask) {
                    // 개인정보 암호화 컬럼은 기본적으로 마스킹 처리한다.
                    if (column.usePrivacy) {
                        // 만일 valueMap 에 포함되어 있다면 개인정보 표시한 항목이다. 이런 항목은 마스킹 처리에서 제외한다.
                        if (valueMap && valueMap.hasOwnProperty(`${index}-${columnName}`)) {
                            value = valueMap[`${index}-${columnName}`];
                            return this.state.interface.getMaskedValue(index, column, value, false);
                        }
                        return this.state.interface.getMaskedValue(index, column, value, true);
                    }

                    return this.state.interface.getMaskedValue(index, column, value);
                }
                return rows[index][this.state.interface.gridView.columnByName(columnName).fieldName || columnName];
            }
            else {
                const value = rows[index][this.state.interface.gridView.columnByName(columnName).fieldName || columnName];
                if (value) return value;
            }
            return value;
        };

        const isEmpty = (value: any) => {
            return value === undefined || value === null || value === "" || Number.isNaN(value)
        }

        const numberCallback = (index: number, columnName: string, value: any) => {
            const column = this.state.interface.getColumnByName(columnName);
            if (column) {
                if (!column.showZero && value === 0) {
                    return ""
                } else if (!column.nanToZero && isEmpty(value)) {
                    return ""
                } else if (column.nanToZero && isEmpty(value)) {
                    return 0
                } else {
                    return value
                }
            }
        }

        const exportExcelOption = {
            type: "excel",
            target: "local",
            fileName: this.props.interface.id + '_excel_' + new Date().toISOString() + '.xlsx',
            indicator: 'default', // 'visible' | 'hidden'
            header: 'default', // 'visible' | 'hidden'
            footer: 'default', // 'visible' | 'hidden'
            allItems: true,
            lookupDisplay: true,
            applyFitStyle: true,
            showColumns: showColumns,
            hideColumns: hideColumns,
            textCallback: textCallback,
            numberCallback: numberCallback,
            // showProgress: true,
        }

        const invokeResult = this.state.interface.invokeEvent<GridEvents.ExportExcelRClick>(
            this.state.interface.onContextMenuExportExcel,
            new GridEvents.ExportExcelRClick(this.state.interface, exportExcelOption)
        );

        if (!invokeResult || invokeResult.isCancel !== true) {
            this.state.interface.exportExcel(exportExcelOption);
        }
    }

    private getEmptySet() {
        if (this.state.interface.isReady === false || this.state.emptySetType === EmptySetType.hasData) {
            return null;
        }

        const imageMap = {
            [EmptySetType.noData]: {
                tiny: emptySearchImgSmall,
                small: emptySearchImgSmall,
                normal: emptySearchImgSmall
            },
            [EmptySetType.beforeSearch]: {
                tiny: emptyDataImgSmall,
                small: emptyDataImgSmall,
                normal: emptyDataImgSmall
            }
        }

        const labelMap = {
            [EmptySetType.noData]: this.state.interface.gridOption.emptySearchMsg,
            [EmptySetType.beforeSearch]: this.state.interface.gridOption.emptyDataMsg
        }

        let emptyImage: JSX.Element = <></>;
        if (this.state.emptySetType === EmptySetType.beforeSearch) {
            if (this.state.interface.getGridOption().skipEmptyDataMsg) {
                return;
            }

            emptyImage = this.state.interface.gridOption.emptyDataImage ? this.state.interface.gridOption.emptyDataImage : (
                <img src={imageMap[this.state.emptySetType][this.state.emptySetSize]} alt={''}></img>
            )
        } else if (this.state.emptySetType === EmptySetType.noData) {
            emptyImage = this.state.interface.gridOption.emptySearchImage ? this.state.interface.gridOption.emptySearchImage : (
                <img src={imageMap[this.state.emptySetType][this.state.emptySetSize]} alt={''}></img>
            )
        }

        const otherHeight = this.state.interface.getHeaderHeight() + this.state.interface.getFooterHeight();
        const bottom = this.state.interface.getFooterHeight();
        const hasSetColumnData = this.state.interface.getColumns().length > 0 ? true : false


        if (this.state.emptySetSize === 'normal' || this.state.emptySetSize === 'small') {
            return (
                <div style={{
                    position: 'absolute',
                    width: "100%",
                    height: "calc(100% - " + otherHeight + "px)",
                    bottom: bottom,
                    pointerEvents: 'none',
                    transition: 'opacity 0.3s ease',
                    opacity: hasSetColumnData && otherHeight === 0 ? '0' : '1'
                }}>
                    <div className={styles.emptyData}>
                        {
                            this.state.interface.getGridOption().hideEmptyImage === true ?
                                (<></>) :
                                (emptyImage)
                        }
                        <div className={this.state.emptySetSize === 'small' ? styles.emptyTextSmall : styles.emptyTextNormal}>
                            {labelMap[this.state.emptySetType]}
                        </div>
                    </div>
                </div>
            )
        } else if (this.state.emptySetSize === 'tiny') {
            return (
                <div style={{
                    position: 'absolute',
                    width: "100%",
                    height: "calc(100% - " + otherHeight + "px)",
                    bottom: bottom,
                    pointerEvents: 'none',
                    transition: 'opacity 0.3s ease',
                    opacity: hasSetColumnData && otherHeight === 0 ? '0' : '1'
                }}>
                    <div className={styles.emptyDataTiny}>
                        <span className={styles.emptyTextTiny}>
                            {labelMap[this.state.emptySetType]}
                        </span>
                    </div>
                </div>
            )
        }
    }

    handleYearMonthDayDateDialogAccept = (valueFrom: any, valueTo: any) => {
        if (!this.state.currentDateColumn) {
            return;
        }

        let dateFormat = this.state.currentDateColumn.dateFormat;
        if (!dateFormat) {
            dateFormat = DateFormat.yyyyMMdd
        }

        this.state.interface.setValue(
            this.state.currentDateColumn.rowIndex,
            this.state.currentDateColumn.columnName,
            valueFrom ? moment(valueFrom).format(dateFormat.toUpperCase()) : null
        );

        this.state.interface.commit();

        this.focus();
    }

    handleYearDateDialogAccept = (event: any, valueTo: any) => {
        if (!this.state.currentDateColumn) {
            return;
        }

        let dateFormat = this.state.currentDateColumn.dateFormat;
        if (!dateFormat) {
            dateFormat = DateFormat.yyyy
        }

        this.state.interface.setValue(
            this.state.currentDateColumn.rowIndex,
            this.state.currentDateColumn.columnName,
            valueTo && typeof valueTo === 'string' ? valueTo :
                valueTo && typeof valueTo === 'number' ? String(valueTo) : null
        );
        this.state.interface.commit();

        this.focus();
    }

    handleYearMonthDateDialogAccept = (event: any, valueTo: any) => {
        // valueTo: number
        if (!this.state.currentDateColumn) {
            return;
        }

        let dateFormat = this.state.currentDateColumn.dateFormat;
        if (!dateFormat) {
            dateFormat = DateFormat.yyyyMM
        }

        this.state.interface.setValue(
            this.state.currentDateColumn.rowIndex,
            this.state.currentDateColumn.columnName,
            valueTo && typeof valueTo === 'string' ? valueTo :
                valueTo && typeof valueTo === 'number' ? String(valueTo) : null
        );
        this.state.interface.commit();

        this.focus();
    }

    /**
     * 날짜 다이얼로그가 열린후 처음으로 선택되는 날짜를 가져온다.
     */
    getInitialDate = (dataColumn: {
        rowIndex: number,
        columnName: string,
        dateFormat: DateFormat,
        value: string,
    }) => {
        let initialDate = new Date();
        if (dataColumn && dataColumn.value && dataColumn.dateFormat) {
            const { value, dateFormat } = dataColumn;
            if (dateFormat === DateFormat.yyyy && value.length === 4) {
                initialDate = moment(value + '0101', dataColumn.dateFormat.toUpperCase()).toDate();
            } else if (dateFormat === DateFormat.yyyyMM && value.length === 6) {
                initialDate = moment(value + '01', dataColumn.dateFormat.toUpperCase()).toDate();
            } else if (dateFormat === DateFormat.yyyyMMdd && value.length === 8) {
                initialDate = moment(value, dataColumn.dateFormat.toUpperCase()).toDate();
            }
        }

        if (moment(initialDate).isBefore(this.state.interface.dateFieldMinDate)) {
            initialDate = this.state.interface.dateFieldMinDate;
        } else if (moment(initialDate).isAfter(this.state.interface.dateFieldMaxDate)) {
            initialDate = this.state.interface.dateFieldMaxDate;
        }

        if (moment(initialDate).isValid() === false) {
            initialDate = new Date();
        }


        return initialDate;
    }

    renderDateDialog = () => {
        let dateDialog: any | null = null;
        const anchorEl = () => {
            return this.myRefs.grid.current ? this.myRefs.grid.current.querySelector(`input#${this.state.interface._gridElementId}_line`) : null;
        };
        if (this.state.currentDateColumn) {
            if (this.state.currentDateColumn.dateFormat === DateFormat.yyyy) {
                dateDialog = (
                    <OBTYearFieldDialog
                        ref={this.myRefs.dateDialogYYYY}
                        value={
                            typeof this.state.dateDialogValue === "string" ? this.state.dateDialogValue : String(new Date().getUTCFullYear())
                        }
                        onAccept={this.handleYearDateDialogAccept}
                        min={this.state.interface.dateFieldMinDate.getFullYear().toString()}
                        max={this.state.interface.dateFieldMaxDate.getFullYear().toString()}
                        align={this.state.currentDateColumn.dateDialogAlign || AlignType.near}
                        position={this.state.currentDateColumn.dateDialogPosition || PositionType.bottom}
                        anchorEl={anchorEl}
                        usePortal={false}
                        onKeyDown={(e) => {
                            if (e.key === 'ArrowLeft') {
                                this.setState({
                                    dateDialogValue: moment(this.state.dateDialogValue || new Date()).add(-1, 'year').toDate().getFullYear().toString()
                                })
                            }

                            if (e.key === 'ArrowRight') {
                                this.setState({
                                    dateDialogValue: moment(this.state.dateDialogValue || new Date()).add(1, 'year').toDate().getFullYear().toString()
                                })
                            }

                            if (e.key === 'ArrowDown') {
                                this.setState({
                                    dateDialogValue: moment(this.state.dateDialogValue || new Date()).add(4, 'year').toDate().getFullYear().toString()
                                })
                            }

                            if (e.key === 'ArrowUp') {
                                this.setState({
                                    dateDialogValue: moment(this.state.dateDialogValue || new Date()).add(-4, 'year').toDate().getFullYear().toString()
                                })
                            }

                            if (e.key === 'Escape' || e.key === 'Backspace') {
                                if (this.state.dateDialogValue) {
                                    this.focus();
                                }
                            }

                            if (e.key === 'Enter') {
                                this.state.interface.commit();

                                // 날짜선택 이벤트 핸들러 강제호출 
                                this.handleYearDateDialogAccept({}, this.state.dateDialogValue);
                                this.focus();
                            }
                        }}
                    />
                )
            } else if (this.state.currentDateColumn.dateFormat === DateFormat.yyyyMMdd) {
                dateDialog = (
                    <UFODateFieldDialog
                        ref={this.myRefs.dateDialogYYYYMMDD}
                        initialDate={this.state.dateDialogValue} // date
                        onAccept={this.handleYearMonthDayDateDialogAccept}
                        onRequestClose={this.handleCloseDatePickerDialog}
                        minDate={this.state.interface.dateFieldMinDate}
                        maxDate={this.state.interface.dateFieldMaxDate}
                        align={this.state.currentDateColumn.dateDialogAlign ? this.state.currentDateColumn.dateDialogAlign.toString() : 'near'}
                        position={this.state.currentDateColumn.dateDialogPosition ? this.state.currentDateColumn.dateDialogPosition.toString() : 'bottom'}
                        anchorEl={anchorEl}
                        usePortal={false}
                        onKeyDown={(e) => {
                            if (e.key === 'ArrowLeft') {
                                this.setState({
                                    dateDialogValue: moment(this.state.dateDialogValue || new Date()).add(-1, 'day').toDate()
                                })
                            }

                            if (e.key === 'ArrowRight') {
                                this.setState({
                                    dateDialogValue: moment(this.state.dateDialogValue || new Date()).add(1, 'day').toDate()
                                })
                            }

                            if (e.key === 'ArrowDown') {
                                this.setState({
                                    dateDialogValue: moment(this.state.dateDialogValue || new Date()).add(1, 'week').toDate()
                                })
                            }

                            if (e.key === 'ArrowUp') {
                                this.setState({
                                    dateDialogValue: moment(this.state.dateDialogValue || new Date()).add(-1, 'week').toDate()
                                })
                            }

                            if (e.key === 'Escape' || e.key === 'Backspace') {
                                if (this.state.dateDialogValue) {
                                    this.focus();
                                }
                            }

                            if (e.key === 'Enter') {
                                this.state.interface.commit();
                                if (this.myRefs.dateDialogYYYYMMDD.current) {
                                    // 날짜선택 이벤트 핸들러 강제호출 
                                    this.handleYearMonthDayDateDialogAccept(this.state.dateDialogValue, this.state.dateDialogValue);
                                    this.focus();
                                }
                            }
                        }}
                    />
                )
            } else if (this.state.currentDateColumn.dateFormat === DateFormat.yyyyMM) {
                dateDialog = (
                    <UFOMonthFieldDialog
                        ref={this.myRefs.dateDialogYYYYMM}
                        initialDate={Number(this.state.dateDialogValue)} // string, number
                        onAccept={this.handleYearMonthDateDialogAccept}
                        // minValue={19000101}
                        // maxValue={20601130}
                        minValue={Number.parseInt(moment(this.state.interface.dateFieldMinDate).format('YYYYMMDD'))}
                        maxValue={Number.parseInt(moment(this.state.interface.dateFieldMaxDate).format('YYYYMMDD'))}
                        align={this.state.currentDateColumn.dateDialogAlign ? this.state.currentDateColumn.dateDialogAlign.toString() : 'near'}
                        position={this.state.currentDateColumn.dateDialogPosition ? this.state.currentDateColumn.dateDialogPosition.toString() : 'bottom'}
                        anchorEl={anchorEl}
                        usePortal={false}
                        onKeyDown={(e) => {
                            const setValue = (value: any, key: string) => {
                                // 문자열 YYYYMMDD로
                                let stringValue: string = value.toString();
                                if (!stringValue) {
                                    stringValue = moment(new Date()).format('YYYYMMDD');
                                }

                                if (stringValue && stringValue.length === 5) {
                                    stringValue = stringValue.substring(0, 4) + '0' + stringValue.substring(4, 5) + '01';
                                } else if (stringValue && stringValue.length === 6) {
                                    stringValue = stringValue + '01';
                                }

                                let resultDate = new Date();
                                if (stringValue) {
                                    if (key === 'ArrowLeft') {
                                        resultDate = moment(stringValue).add(-1, 'month').toDate()
                                    }
                                    if (key === 'ArrowRight') {
                                        resultDate = moment(stringValue).add(1, 'month').toDate()
                                    }
                                    if (key === 'ArrowDown') {
                                        resultDate = moment(stringValue).add(4, 'month').toDate()
                                    }
                                    if (key === 'ArrowUp') {
                                        resultDate = moment(stringValue).add(-4, 'month').toDate()
                                    }
                                }

                                const resultMonthString = (resultDate.getMonth() + 1) < 10 ? '0' + (resultDate.getMonth() + 1).toString() : (resultDate.getMonth() + 1).toString()
                                this.setState({
                                    dateDialogValue: Number(resultDate.getFullYear().toString() + resultMonthString)
                                })
                            }

                            if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                                setValue(this.state.dateDialogValue, e.key);
                            }

                            if (e.key === 'Escape' || e.key === 'Backspace') {
                                if (this.state.dateDialogValue) {
                                    this.focus();
                                }
                            }

                            if (e.key === 'Enter') {
                                this.state.interface.commit();
                                if (this.myRefs.dateDialogYYYYMM.current) {
                                    // 날짜선택 이벤트 핸들러 강제호출 
                                    this.handleYearMonthDateDialogAccept(this.state.dateDialogValue, this.state.dateDialogValue);
                                    this.focus();
                                }
                            }
                        }}
                    />
                );
            }
        }

        if (dateDialog) {
            return (
                <>
                    {dateDialog}
                </>
            )
        }
    }

    renderAutoComplete = () => {
        const anchorEl = () => {
            return this.myRefs.grid.current ? this.myRefs.grid.current.querySelector(`input#${this.state.interface._gridElementId}_line`) : null;
        };

        return (
            <>
                <AutoCompleteDialog
                    anchorEl={anchorEl()}
                    isOpen={this.state.autoCompleteColumn["isOpen"] ? true : false}
                    onClickItem={this.handleClickAutoCompleteList}
                    onMouseMove={this.handleMouseMoveCompleteList}
                    ref={this.myRefs.autoCompleteDialog}
                    width={this.state.autoCompleteColumn["width"]}
                    searchCallback={this.state.autoCompleteColumn["searchCallback"]}
                    keyProperty={this.state.autoCompleteColumn["keyProperty"]}
                    selectedIndex={this.state.autoCompleteColumn["selectedIndex"]}
                    correctKeyword={this.state.autoCompleteColumn["correctKeyword"]}
                    dialogAlign={this.state.autoCompleteColumn["dialogAlign"]}
                />
            </>
        )
    }

    public setProgress = (percent: number | undefined, visible: boolean | undefined, labelText: string | undefined): Promise<void> => {
        return new Promise<void>((resolve) => {
            this.setState({
                progress: {
                    key: this.state.progress && this.state.progress.visible === false && visible === true ? uuid() : (this.state.progress || {}).key,
                    percent: percent === undefined ? this.state.progress ? this.state.progress.percent : undefined : percent,
                    visible: visible === undefined ? this.state.progress ? this.state.progress.visible : undefined : visible,
                    labelText: labelText === undefined ? this.state.progress ? this.state.progress.labelText : undefined : labelText
                }
            }, () => resolve())
        });
    }

    renderComponent = () => {
        const id = this.state.interface._gridElementId;
        const emptyMessage = this.getEmptySet();
        const searchBox = this.renderSearchBox();

        return (
            <>
                <div id={this.wrapperId}
                    className={Util.getClassNames(this.wrapperClassName, styles.root, this.props.className)}
                    style={Util.getWrapperStyle(this.props)}
                    ref={this.myRefs.wrapper}
                    onMouseDown={this.handleMouseDownInWrapper}
                    onMouseMoveCapture={this.handleMouseMove}
                    onMouseLeave={this.handleMouseLeave}
                >
                    <div id={id}
                        data-orbit-id={this.state.interface.id}
                        data-orbit-component={'OBTDataGrid'}
                        ref={this.myRefs.grid}
                        className={styles.grid}
                        tabIndex={-1}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}>
                    </div>

                    {/* 행 마우스 오버 영역, 행 마우스 이동기능 */}
                    {
                        this.state.interface && this.state.interface.gridOption.rowMovable === true && (
                            <div className={styles.hoverWrapper}>
                                <div className={Util.getClassNames(styles.hoverRoot, this.state.hover.visible ? styles.visible : undefined)} style={this.state.hover.boundary}>
                                    {this.state.interface.getGridOption().rowMovable ?
                                        <div className={styles.reorderRoot} style={this.state.hover.moveThumbBoundary}>
                                            <div className={Util.getClassNames(styles.reorderImageRoot, this.state.hover.reorder ? styles.on : undefined)}>
                                                {this.state.hover.reorderImage ? this.state.hover.reorderImage : undefined}
                                            </div>
                                            <div className={Util.getClassNames(styles.moveThumb, this.state.hover.reorder ? styles.on : undefined)} onMouseDown={this.handleReorderMouseDown} />
                                        </div>
                                        : undefined}
                                    {!this.state.hover.reorder && this.state.hover.actionButtons ?
                                        <div className={styles.hoverActionButtonRoot}>
                                            {this.state.hover.actionButtons}
                                        </div> : undefined}
                                </div>
                            </div>
                        )
                    }
                    {emptyMessage}
                    {searchBox}

                    {/* cell alert */}
                    {/* {this.state.cellAlert.show === true && this.state.cellAlert.targetCellBound ? (
                        <div style={{
                            position: 'absolute',
                            display: 'none',
                            top: (this.state.cellAlert.targetCellBound.y - this.state.cellAlert.targetCellBound.height) + 'px',
                            left: this.state.cellAlert.targetCellBound.x + 'px',
                            width: '100px',
                            height: '32px',
                            backgroundColor: 'red'
                        }}>
                            {this.state.cellAlert.labelText}
                        </div>
                    ) : null} */}

                    {this.state.requiredSnackbar.show === true ?
                        <OBTSnackbar
                            key="requiredSnackbar"
                            open={this.state.requiredSnackbar.show}
                            labelText={this.state.requiredSnackbar.labelText}
                            type={OBTSnackbar.Type.warning}
                            onChange={(e) =>
                                this.setState({
                                    requiredSnackbar: {
                                        show: false
                                    }
                                })}
                        /> : <></>
                    }

                    {/* {this.state.noSearchSnackbar.show === true ?
                        <OBTSnackbar
                            key="noSearchSnackbar"
                            open={this.state.noSearchSnackbar.show}
                            labelText={this.state.noSearchSnackbar.labelText}
                            onChange={(e) =>
                                this.setState({
                                    noSearchSnackbar: {
                                        show: false
                                    }
                                })}
                        /> : <></>
                    } */}

                    <div className={Util.getClassNames(styles.privacyLoadingRoot)}>
                        <Slide right when={this.state.progress && this.state.progress.visible ? true : false} duration={300}>
                            <OBTLinearProgress
                                key={this.state.progress ? this.state.progress.key : undefined}
                                className={styles.privacyLoading}
                                labelText={(this.state.progress ? this.state.progress.labelText : '') || ''}
                                value={(this.state.progress ? this.state.progress.percent : 0) || 0}
                                width={'300px'}
                            />
                        </Slide>
                    </div>

                    {/* 달력 다이얼로그 */}
                    {this.renderDateDialog()}

                    {/* 자동완성 다이얼로그 */}
                    {this.renderAutoComplete()}

                </div>
                {/* 엑셀 익스포트 다이얼로그 */}
                {this.renderExcelExportDialog()}

                {/*  */}
                {this.renderCustomColumnDialog()}

                {/* 코드피커 다이얼로그 */}
                {this.renderCodePickerDialog()}

                {this.state.confirmResetColumnSetting === true ?
                    // TODO: 다국어 
                    <OBTConfirm
                        title='설정 초기화'
                        labelText='컬럼 설정을 초기화하시겠습니까?'
                        onCancel={() => this.setState({ confirmResetColumnSetting: false })}
                        onConfirm={() => {
                            const clientKey = this.state.interface._clientKey;
                            if (clientKey) {
                                this.state.interface.resetUserCustomColumn();
                            }

                            this.setState({
                                isShowCustomColumnDialog: false,
                                confirmResetColumnSetting: false,
                            });
                        }} /> : <></>
                }

                {this.state.alertHasDirtyData === true ?
                    // TODO: 다국어 
                    <OBTAlert
                        // title='Title 영역 입니다.'
                        labelText='수정된 데이터가 존재합니다.'
                        type={OBTAlert.Type.warning}
                        onClose={() => { this.setState({ alertHasDirtyData: false }) }} /> : <></>
                }
            </>
        )
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        )
    }
}

OBTDataGrid.contextType = OBTContext;
export default OBTDataGrid;