import React, { Component } from "react";
import PropTypes from "prop-types";
import LUXSpriteIcon from "luna-rocket/LUXSpriteIcon";
import LUXButton from "luna-rocket/LUXButton";
import { OrbitInternalLangPack } from '../../../Common/Util';
import { removeTrashElement } from "../../../Common/OrbitInternalUtil";

const getStyles = props => {
  const { isFocused, disabled, finish } = props;

  const styles = {
    // inputElement 의 전체를 감싸고 있는 div 의 Style
    style: {
      position: "relative",
      margin: 0,
      padding: 0,
      height: "100%"
    },
    // inputElement 의 span Style
    spanStyle: {
      display: 'block',
      overflow: 'hidden',
      height: '20px',
      padding: '4px 24px 1px 6px',
      //border: '1px solid rgb(219, 219, 219)',
      background: 'rgb(48, 20, 20)',
      lineHeight: '18px',
      color: 'rgb(166, 166, 166)',
      border: isFocused
        ? "1px solid #58adfc"
        : "1px solid rgb(219, 219, 219)",
      backgroundColor: isFocused
        ? "white"
        : disabled || finish ? "rgba(247,247,247,1)" : "white",
      // background: "#fff",
      cursor: disabled || finish ? "not-allowed" : "pointer",
    },
    // input Style
    inputStyle: {
      display: 'block',
      width: '100%',
      border: 0,
      background: 'transparent',
      outline: 'none',
      fontSize: '12px',
      fontFamily: 'inherit',
      color: '#7c7e80',
      cursor: props.disabled ? 'default' : 'pointer',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      wordBreak: 'break-all',
      textOverflow: 'ellipsis',
      paddingLeft: props.imagePosition === 'left' ? '30px' : '',
      lineHeight: '18px'
    },
    // 오른쪽 아이콘 스타일
    iconStyle: {
      position: "absolute",
      top: '50%',
      right: 0,
      cursor: disabled || finish ? "not-allowed" : "pointer",
      width: "27px",
      height: "27px",
      border: "0 none",
      background: "transparent",
      padding: 0,
      marginTop: '-14px'
    }
  };

  return styles;
};

class InputElement extends Component {
  static propTypes = {
    /**
     * inputElement 의 전체를 감싸고 있는 div 의 스타일
     */
    style: PropTypes.object
  };

  static defaultProps = {
    isFocused: false,
    disabled: false
  };

  myRefs = {
    input: React.createRef()
  }

  handleClick = () => {
    if (this.props.isPopoverOpen) {
      removeTrashElement();
    }
  }

  /**
   * input 생성
   */
  createInput = (styles, styleInput, inputProps) => {
    const { className, value, hintText, data } = this.props;
    let input = (
      <input
        className={className ? className : "LSinput"}
        ref={this.myRefs.input}
        type="text"
        style={Object.assign({}, styles.inputStyle, styleInput)}
        value={
          value.length
            ? value.length === 1
              ? value[0]
              : value.length === data.length
                ? OrbitInternalLangPack.getText('WE000000529', '전체') //"전체"
                : `${value[0]} ${OrbitInternalLangPack.getText('WE000004601', '외')} ${value.length - 1} ${OrbitInternalLangPack.getText('MO000001175', '건')}`
            : ""
        }
        placeholder={hintText}
        readOnly={true}
        {...inputProps}
      />
    );
    return input;
  };

  render() {
    const {
      styleInput,
      styleInputSpan,
      styleButton,
      isPopoverOpen,
      disabled,
      className,
    } = this.props;

    const inputProps = {
      disabled: disabled,
      className: className
    };

    const styles = getStyles(this.props);

    // input 생성
    const input = this.createInput(styles, styleInput, inputProps);

    const rightIconElement = (
      <LUXButton
        disabled={disabled}
        tabIndex="-1"
        icon={
          <LUXSpriteIcon
            sprite={
              isPopoverOpen ? "arrowSmallBlackUp1" : "arrowSmallBlackDown1"
            }
          />
        }
        innerStyle={{ width: "8px", height: "4px" }}
        style={Object.assign({}, styles.iconStyle, styleButton)}
      />
    );

    return (
      <div style={Object.assign({}, styles.style)} onClick={this.handleClick}>
        <span style={Object.assign({}, styles.spanStyle, styleInputSpan)}>
          {input}
        </span>
        {rightIconElement}
      </div>
    );
  }

  //endregion
}

export default InputElement;
