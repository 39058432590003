/**
 * @version 0.1
 * @author 박재성
 * @see common.js
 */
import * as React from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import { CommonDefinitions, createPropDefinitions, toEnumType, Util } from '../Common';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import { Calendar } from '@fullcalendar/core';
import OBTButton from '../OBTButton';
import iconBtnArrowLeft from '../Images/icon-arrow-left.png';
import iconBtnArrowRight from '../Images/icon-arrow-right.png';
import './OBTCalendar.scss';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { OrbitInternalLangPack, getLangCodeFromSession } from '../Common/Util';

enum CalendarTheme {
    'default' = 'default',
    'erp' = 'erp'
}

interface State extends hasError {
    open: boolean
}

/**
 * withApi() HOC 를 사용하면 Props 로 Api 를 사용할 수 있다.
 * api 가 Optional 로 선언되었기에 내부에서 ! 오퍼레이터를 사용해서 호출한다.
 * {@code this.props.api!.test();}
 */
export default class OBTCalendar extends React.Component<any, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        { name: 'theme', type: toEnumType(CalendarTheme), default: 'default', optional: true, description: '캘린더의 테마를 지정합니다.' },
        { name: '기타 속성(FullCalendar)', type: '', optional: true, description: 'https://fullcalendar.io/docs#toc 를 참고하시기 바랍니다.' }
    );

    public static defaultProps = {
        width: '100%',
        height: 'auto',
        frozen: false,
        theme: CalendarTheme.default
    }

    public myRefs = {
        ref: React.createRef<FullCalendar>()
    }

    public static Theme = CalendarTheme

    componentDidUpdate() {
        if (this.myRefs.ref.current) {
            this.myRefs.ref.current.getApi().updateSize()
        }
    }

    private getFullCalendarLocale = () => {
        const langCode = getLangCodeFromSession();
        if (!langCode) {
            return 'ko';
        }

        if (langCode.toUpperCase() === 'JP') {
            return 'jpn';
        }

        if (langCode.toUpperCase() === 'EN') {
            return 'en';
        }

        if (langCode.toUpperCase() === 'CH') {
            return 'zh';
        }

        return 'ko'
    }

    public getApi(): Calendar {
        let ret;
        if (this.myRefs.ref.current) {
            ret = this.myRefs.ref.current.getApi() as Calendar;
        }
        return ret;
    }

    private handlePrevNext(dir) {
        if (this.myRefs.ref.current) {
            if (dir === 'prev') {
                this.myRefs.ref.current.getApi().prev();
            } else if (dir === 'next') {
                this.myRefs.ref.current.getApi().next();
            }
        }
    }

    private handleThisMonth() {
        if (this.myRefs.ref.current) {
            this.myRefs.ref.current.getApi().today();
        }
    }

    render() {
        return (<ErrorBoundary owner={this} render={this.renderComponent} />)
    }

    renderComponent = () => {
        const wrapperStyleProp: { frozen?: boolean, width?: string, height?: string } = {
            frozen: this.props.frozen || false,
            width: this.props.width,
            height: this.props.height
        };

        let erpToolbar = this.props.theme === OBTCalendar.Theme.erp && (this.props.header !== false && this.props.header !== null) ? <div className='erpToolbar'>
            <OBTButton
                className={'previous-month-button'}
                tooltip={{ labelText: OrbitInternalLangPack.getText('WE000010082', '이전달'), focusValue: false }}
                imageUrl={iconBtnArrowLeft}
                width='27px'
                height='27px'
                onClick={this.handlePrevNext.bind(this, 'prev')}
            />
            <OBTButton
                className={'next-month-button'}
                tooltip={{ labelText: OrbitInternalLangPack.getText('WE000010081', '다음달'), focusValue: false }}
                imageUrl={iconBtnArrowRight}
                width='27px'
                height='27px'
                onClick={this.handlePrevNext.bind(this, 'next')}
            />
            <OBTButton
                className='btnThisMonth'
                labelText={OrbitInternalLangPack.getText('WE000022702', '이번달')}
                width='auto'
                height='27px'
                onClick={this.handleThisMonth.bind(this)}
            />
        </div> : null

        return (
            <div
                className={Util.getClassNames('demo-app-calendar', this.props.className, this.props.theme === OBTCalendar.Theme.erp ? 'obtCalendar-erp' : undefined)}
                style={Object.assign({}, Util.getWrapperStyle(wrapperStyleProp), { overflow: 'auto' })}
                id={this.props.id}
                data-orbit-component='OBTCalendar'>
                {erpToolbar}
                <FullCalendar
                    defaultView="dayGridMonth"
                    header={this.props.theme === OBTCalendar.Theme.erp ?
                        {
                            left: 'title',
                            center: '',
                            right: ''
                        } : {
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                        }}
                    plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                    ref={this.myRefs.ref}
                    locale={this.getFullCalendarLocale()}
                    {...(this.props)}
                    height={this.props.height === 'auto' ? 'auto' : 'parent'}
                    contentHeight={'auto'}
                />
            </div>
        )
    }
};
