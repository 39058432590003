/**
 * OBTNumberField
 * @version 0.1
 * @author 안광진
 * @see LUXPriceField
 */

import * as React from 'react';
import { Events, CompositeProps, Util, CommonProps, Functions, createPropDefinitions, CommonDefinitions, CommonType } from '../Common';
import { LUXPriceField } from 'luna-rocket/LUXTextField';
import OBTTooltip from '../OBTTooltip';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import GuideMessageRenderer from '../Common/GuideMessageRenderer';
import { OBTContext } from '../OBTPageContainer/OBTPageContainer';
const styles = require('./OBTNumberField.module.scss');

/**
 * value 값이 없을 때 화면에 보여지는 value값을 정의하는 속성입니다.
 * empty, zero
 */
enum NonValueDisplayType {
    'default' = 'default',
    'empty' = 'empty',
    'zero' = 'zero',
}

/**
 * 인풋 내 숫자 정렬에 관련된 속성입니다.
 */
enum AlignType {
    'left' = 'left',
    'center' = 'center',
    'right' = 'right'
}

/**
 * PropType 정의
 * Events, CommonProps, CompositeProps 에 미리 지정된 Prop interface 를 충분히 활용한다.
 * extends 로 인터페이스 상속을 통해 사용된다.
 * 공용 Api 를 사용하려면 CommonProps.api 인터페이스를 확장한다.
 */
interface IOBTNumberField extends CompositeProps.InputClass<string | number>, CommonProps.placeHolder, Events.onValidate<string | number>,
    Events.onMouseDown, Events.onClick, Events.onMouseMove, Events.onMouseUp, Events.onMouseLeave, Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress, Events.onKeyUp {
    /**
     * value 값이 없을 때 화면에 보여지는 value값을 정의하는 속성입니다.
     */
    allowEmpty: boolean,
    /**
     * 최대입력가능 길이를 정의하는 속성입니다.
     */
    maxLength?: number,
    /**
     * 틀팁에 대한 설정 속성입니다.
     */
    tooltip?: any,
    /**
     * 인풋 내 숫자 정렬에 관련된 속성입니다.
     */
    align?: AlignType,
    /**
     * 컴포넌트 입력필드에서 value 값이 없을 때 화면에 보여지는 value값을 정의하는 속성입니다. 해당옵션 사용시 allowEmpty보다 우선됩니다.
     */
    nonValueDisplayType?: NonValueDisplayType
}

/**
 * @internal
 * State 정의
 */
interface State extends hasError {
}

export default class OBTNumberField extends React.Component<IOBTNumberField, State> implements Functions.IFocusable, Functions.IRequired {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.InputClass(['string', 'number']),
        CommonDefinitions.placeHolder(),
        { name: "allowEmpty", type: CommonType.boolean, description: "숫자 입력필드에 값이 비어있는 것을 허용할지를 정의하는 속성입니다. 값이 true일 경우 입력 필드가 비어있는 상태이고, false일 경우 숫자 0으로 기본 설정되어 있습니다.", default: true, optional: true },
        { name: "maxLength", type: CommonType.number, description: "최대입력가능 길이를 정의하는 속성입니다.\nex)  maxLength={5} : 정수 다섯번째 자리까지 입력 가능 000000 \nex)  maxLength={3.2} : 정수 세번째 자리까지 입력 가능하고, 소수 2번째 자리까지 입력 가능 000.00", optional: true },
        CommonDefinitions.tooltip(),
        CommonDefinitions.onValidate(['string', 'number']),
        CommonDefinitions.InnerFunc(),
        CommonDefinitions.Event(),
    );

    private moveFocusInvoked: boolean = false;
    context!: React.ContextType<typeof OBTContext>;
    public static Align = AlignType;
    public static DisplayType = NonValueDisplayType;

    /**
     * Default Props 설정
     */
    public static defaultProps = {
        frozen: false,
        disabled: false,
        readonly: false,
        required: false,
        allowEmpty: true,
    }

    /**
     * State 정의
     */
    public state: State = {}

    /**
     * Ref 정의
     */
    public myRefs = {
        ref: React.createRef<LUXPriceField>(),
        input: null
    } as {
        ref: React.RefObject<any>,
        input: any
    }

    private setReadonly = () => {
        if (this.myRefs.input) {
            const elReadonly = this.myRefs.input.getAttribute('readonly');
            if (this.props.readonly && !elReadonly) {
                this.myRefs.input.setAttribute('readonly', 'readonly');
            } else if (!this.props.readonly && elReadonly) {
                this.myRefs.input.removeAttribute('readonly');
            }
        }
    }

    private getNonValueDisplayType = () => {
        if (this.props.nonValueDisplayType === undefined) {
            if (this.props.allowEmpty) {
                return NonValueDisplayType.empty
            }
            return NonValueDisplayType.zero
        }

        if (this.props.nonValueDisplayType === NonValueDisplayType.zero) {
            return NonValueDisplayType.zero
        } else if (this.props.nonValueDisplayType === NonValueDisplayType.empty) {
            return NonValueDisplayType.empty
        }
        return NonValueDisplayType.default
    }

    componentDidMount() {
        try {
            if (this.myRefs.ref.current && this.myRefs.ref.current.pricefieldRef) {
                this.myRefs.input = this.myRefs.ref.current.pricefieldRef;
                this.myRefs.input.setAttribute('imeMode', 'disabled');
                this.myRefs.input.addEventListener('keypress', this.handleKeyPress);
            }
            this.setReadonly();
        }
        catch (e) {
            Util.getErrorState(e);
        }
    }

    componentDidUpdate() {
        this.setReadonly();
    }

    // component 가 render 될때 호출됨.
    render() {
        return <ErrorBoundary owner={this} render={this.renderComponent} />
    }

    renderComponent = () => {
        const integerLength = parseInt(String(this.EmptyToZero(this.props.maxLength)).split('.')[0]);
        const decimalLength = parseInt(String(this.EmptyToZero(this.props.maxLength)).split('.')[1]);
        const props = {
            // Value
            defaultValue: this.props.value ? String(this.props.value) : '',
            componentType: 'default',
            nonValueDisplayType: this.getNonValueDisplayType(),
            hintText: this.props.placeHolder,

            // Events
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            onMoveFocus: this.handleMoveFocus,
            onChange: this.handleChange,
            onKeyDown: this.handleKeyDown,

            // Etc
            disabled: this.props.disabled,
            fullWidth: true,
            integerLength: integerLength ? integerLength : null,
            decimalLength: decimalLength ? decimalLength : null,
            decimal: decimalLength ? true : false,
            decimalUseZero: decimalLength ? true : false,
            focusOnSelect: true,
            // Styling
            style: Object.assign({}, Util.getInputStateStyle(this.props), { width: '100%' }),
            inputBoxStyle: { padding: '3px 6px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', ...Util.getInputStateStyle(this.props) },
            inputStyle: {
                fontFamily: 'inherit',
                // lineHeight: '13px'
                textAlign: this.props.align !== undefined && this.props.align === AlignType.center ? 'center' :
                    this.props.align !== undefined && this.props.align === AlignType.left ? 'left' : 'right'
            }
        };
        return <OBTTooltip {...this.props.tooltip}
            className={Util.getClassNames(styles.obtNumberField, this.props.className)} style={Util.getWrapperStyle(this.props)}
            overrideSize={false}
            onClick={this.props.onClick}
            onMouseDown={this.props.onMouseDown}
            onMouseMove={this.props.onMouseMove}
            onMouseUp={this.props.onMouseUp}
            onMouseLeave={this.props.onMouseLeave}
            onMouseEnter={this.props.onMouseEnter}
            onKeyDown={this.props.onKeyDown}
            onKeyPress={this.props.onKeyPress}
            onKeyUp={this.props.onKeyUp}
            rootProps={{
                id: this.props.id,
                'data-orbit-component': 'OBTNumberField'
            }}>
            <LUXPriceField ref={this.myRefs.ref} {...props} />
        </OBTTooltip>
    }

    ///////////////////////////////////////////////////////////////////////////// Logics
    public focus(isLast: boolean = false): void {
        if (!this.props.frozen && !this.props.disabled) {
            this.myRefs.ref.current.focus();
        }
    }

    private get canEdit(): boolean {
        return !this.props.disabled && !this.props.readonly;
    }

    public isEmpty(): boolean {
        return (!this.props.value || this.props.value.toString().length <= 0);
    }

    public validate(): boolean {
        return !(this.props.required === true && this.isEmpty());
    }

    public getValue(): any {
        return this.myRefs.ref.current.state.value
    }

    /**
     * @internal
     * @param value 
     */
    private EmptyToZero(value: any): number {
        return value ? value : 0;
    }

    ///////////////////////////////////////////////////////////////////////////// Event Handlers
    private handleMoveFocus = (direction: string): void => {
        // MoveFocus 호출됨 toggle
        this.moveFocusInvoked = true;

        if (!this.validate() && ['right', 'down', 'enter'].includes(direction)) {
            return;
        }
        if (this.myRefs.ref.current && this.myRefs.ref.current.pricefieldRef && ['left', 'right'].includes(direction)) {
            const inputRef = this.myRefs.ref.current.pricefieldRef as HTMLInputElement

            // 텍스트의 영역이 선택되어 있다면 onMoveFocus 호출하지 않고 커서의 위치만 바꿈
            if (inputRef.selectionStart !== inputRef.selectionEnd) {
                this.moveFocusInvoked = false;
                return;
            }
        }
        Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
    }

    private handleFocus = (e): void => {
        Util.invokeEvent<Events.EventArgs>(this.props.onFocus, new Events.EventArgs(this));

        GuideMessageRenderer.handleFocus(
            this, this.props.id, this.context
        );
    }

    private handleBlur = (): void => {
        Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));

        GuideMessageRenderer.handleBlur(
            this.context,
            this.props.id,
        );
    }

    private handleChange = (e: any, value: string | number): void => {
        if (this.canEdit) {
            const rawValue = typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value;
            if (Util.invokeEvent<Events.ValidateEventArgs<string | number>>(this.props.onValidate, new Events.ValidateEventArgs(this, this.props.value, rawValue))) {
                Util.invokeEvent<Events.ChangeEventArgs<string | number>>(this.props.onChange, new Events.ChangeEventArgs<string | number>(this, rawValue));
            }
        }
    }

    private handleKeyPress = (e: KeyboardEvent): void => {
        if (!this.canEdit || this.props.value === undefined) {
            e.stopPropagation();
            e.preventDefault();
            return;
        }

        const input = e.target as HTMLInputElement;
        const newChar = String.fromCharCode(e.keyCode);
        const selectionStart = input && input.selectionStart ? input.selectionStart : 0;
        const selectionEnd = input && input.selectionEnd ? input.selectionEnd : 0;
        const value: string = input.value;
        let newValue = selectionStart !== selectionEnd ? value.split('').map((v, i) => i >= selectionStart && i < selectionEnd ? '' : v).join('') : value;
        newValue = newValue.slice(0, selectionStart) + newChar + newValue.slice(selectionStart);

        if (!Util.invokeEvent<Events.ValidateEventArgs<string | number>>(this.props.onValidate,
            new Events.ValidateEventArgs(this, this.props.value, parseFloat(newValue.replace(/,/g, ''))))) {
            e.stopPropagation();
            e.preventDefault();
            return;
        }

        if (!parseInt(String(this.EmptyToZero(this.props.maxLength)).split('.')[1]))
            return;

        if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
            const oldFloatValue = value.split('.')[1];
            const newFloatValue = newValue.split('.')[1];
            if (newFloatValue && oldFloatValue !== newFloatValue) {
                if (String(this.EmptyToZero(this.props.maxLength)).split('.')[1] != null && newFloatValue.length > parseInt(String(this.EmptyToZero(this.props.maxLength)).split('.')[1])) {
                    e.stopPropagation();
                    e.preventDefault();
                }
            }
        }
    }

    private handleKeyDown = (e: React.KeyboardEvent): void => {
        if (this.moveFocusInvoked) {
            this.moveFocusInvoked = false;
            e.preventDefault();
        }
    }
}

OBTNumberField.contextType = OBTContext;