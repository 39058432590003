/**
 * OBTPreviousNextSelector
 * @version 0.1
 * @author 박재성
 * @see LUXPageableBox
 */
import * as React from 'react';
import LUXPageableBox from 'luna-rocket/LUXPageableBox';
import { Events, CompositeProps, Util, CommonProps, createPropDefinitions, CommonDefinitions, CommonType } from '../Common';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import GuideMessageRenderer from '../Common/GuideMessageRenderer';
import { OBTContext } from '../OBTPageContainer/OBTPageContainer';
import { removeTrashElement } from '../Common/OrbitInternalUtil';

const styles = require('./OBTPreviousNextSelector.module.scss');

interface TypeList {
    /**
     * value : 값
     */
    value: string,
    /**
     * labelText : 텍스트
     */
    labelText: string,
    /**
     * labelText : 텍스트
     */
    labelSubText?: string
}

interface IOBTPreviousNextSelector extends CompositeProps.Default, CommonProps.disabled, CommonProps.required, CommonProps.value<string>, Events.onValidate<string>, Events.onFocus, Events.onBlur, Events.onChange<string>,
    CommonProps.useSubLang, Events.onClick, Events.onMouseDown, Events.onMouseMove, Events.onMouseUp, Events.onMouseLeave, Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress, Events.onKeyUp {
    list: Array<any>,
    /**
    * 컴포넌트에서 데이터소스를 이용하여 매핑 정의하는 속성입니다.
    */
    fieldName?: TypeList,
}

interface State extends hasError { }

export default class OBTPreviousNextSelector extends React.Component<IOBTPreviousNextSelector, State> {
    context!: React.ContextType<typeof OBTContext>;

    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        {
            name: 'fieldName', type: [{
                value: { type: CommonType.string, description: "값" },
                labelText: { type: CommonType.string, description: "텍스트" },
                labelSubText: { type: CommonType.string, description: "서브 텍스트", optional: true }
            }], description: "컴포넌트에서 데이터소스를 이용하여 매핑 정의하는 속성입니다.", optional: true
        },
        { name: 'list', type: 'Array<any>', description: "previousNextSelector에 사용될 list" },
        CommonDefinitions.disabled(),
        CommonDefinitions.required(),
        CommonDefinitions.value({ type: CommonType.string, description: "previousNextSelector에 의 selector에 표시될 값" }),
        CommonDefinitions.onValidate({ type: CommonType.string }),
        CommonDefinitions.onBlur(),
        CommonDefinitions.onFocus(),
        CommonDefinitions.onChange({ type: CommonType.string }),
        CommonDefinitions.focus(),
        CommonDefinitions.useSubLang(),
        CommonDefinitions.Event(),
    );

    public static defaultProps = {
        frozen: false,
        disabled: false,
        required: false,
    }

    public state: State = {}

    public myRefs = {
        ref: React.createRef<LUXPageableBox>()
    }

    private typeListChange(): any {
        let typeList: Array<any> = [];
        typeList = (this.props.list || []).map((list) => {
            const fieldName = Object.assign({
                value: 'value',
                labelText: 'labelText',
                labelSubText: 'labelSubText',
            }, this.props.fieldName || {});
            return {
                value: list[fieldName.value],
                labelText: list[fieldName.labelText] || list.text,
                labelSubText: list[fieldName.labelSubText] || list.text,
            };
        });
        if (typeList.length <= 0) {
            typeList = [{
                value: '', labelText: '', labelSubText: ''
            }];
        }
        return typeList
    }

    private getDisplayData(data: Array<any>): Array<any> {
        const useSubLang = this.props.useSubLang;
        return (data || []).map((list) => ({
            value: list.value,
            text: Util.cl(list.labelText || list.text, list.labelSubText, useSubLang)
        }));
        // let tempList: Array<any> = [];

        // (data || []).forEach((list, index) => {
        //     let listElement = {
        //         value: list.value,
        //         text: Util.cl(list.labelText || list.text, list.labelSubText, useSubLang)
        //     }
        //     tempList.push(listElement)
        // });
        // return tempList;
    }

    public focus(): void {
        if (!this.props.frozen && !this.props.disabled) {
            this.myRefs.ref.current.focus();
        }
    }

    private handleFocus = (): void => {
        Util.invokeEvent<Events.EventArgs>(this.props.onFocus, new Events.EventArgs(this));
        GuideMessageRenderer.handleFocus(
            this, this.props.id, this.context
        );
    }

    private handleChange = (e: any, value: string): void => {
        if (this.props.value !== value) {
            const oldValue: string = this.props.value
            const item: Array<any> = [];
            const oldItem: Array<any> = [];
            if (this.props.list) {
                this.props.list.forEach(originList => {
                    if (originList.value === oldValue) oldItem.push(originList);
                    if (originList.value === value) item.push(originList);
                });
            }

            if (!Util.invokeEvent<Events.ValidateEventArgs<string>>(this.props.onValidate, new Events.ValidateEventArgs<string>(this, oldValue, value, oldItem, item))) {
                return;
            }
            if (!this.props.frozen && !this.props.disabled) {
                Util.invokeEvent<Events.ChangeEventArgs<string>>(this.props.onChange, new Events.ChangeEventArgs<string>(this, value, item));
            }
        }
    }

    private handleBlur = (): void => {
        Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
        GuideMessageRenderer.handleBlur(
            this.context,
            this.props.id,
        );
    }

    private handleClick = (event: React.MouseEvent): void => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onClick, new Events.MouseEventArgs(this, event));
    }

    private handleMouseDown = (event: React.MouseEvent): void => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseDown, new Events.MouseEventArgs(this, event));
    }

    private handleMouseMove = (event: React.MouseEvent): void => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseMove, new Events.MouseEventArgs(this, event));
    }

    private handleMouseUp = (event: React.MouseEvent): void => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseUp, new Events.MouseEventArgs(this, event));
    }

    private handleMouseLeave = (event: React.MouseEvent): void => {
        removeTrashElement();
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseLeave, new Events.MouseEventArgs(this, event));
    }

    private handleMouseEnter = (event: React.MouseEvent): void => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseEnter, new Events.MouseEventArgs(this, event));
    }

    private handleKeyDown = (event: React.KeyboardEvent): void => {
        Util.invokeEvent<Events.KeyEventArgs>(this.props.onKeyDown, new Events.KeyEventArgs(this, event));
    }

    private handleKeyPress = (event: React.KeyboardEvent): void => {
        Util.invokeEvent<Events.KeyEventArgs>(this.props.onKeyPress, new Events.KeyEventArgs(this, event));
    }

    private handleKeyUp = (event: React.KeyboardEvent): void => {
        Util.invokeEvent<Events.KeyEventArgs>(this.props.onKeyUp, new Events.KeyEventArgs(this, event));
    }

    renderComponent = () => {
        let listData = this.props.list

        if (this.props.fieldName) {
            listData = this.typeListChange();
        }

        listData = this.getDisplayData(listData);

        const props = {
            // Value
            onChange: this.handleChange.bind(this),
            value: this.props.value,

            // Events
            onFocus: this.handleFocus.bind(this),
            onBlur: this.handleBlur.bind(this),

            // Etc
            list: listData,
            disabled: this.props.disabled,

            // Styling
            style: Object.assign({}, Util.getWrapperStyle(this.props),
                Util.getInputStateStyle({ ...this.props, required: undefined, disabled: undefined })),
            selectBoxStyle: Object.assign({}, Util.getWrapperStyle(this.props), Util.getInputStateStyle(this.props)),
            selectStyle: Object.assign({}, {
                fontFamily: 'inherit', lineHeight: '13px',
            }),
        };

        const component =
            <div className={Util.getClassNames(styles.previousNextSelector, this.props.className)}>
                <LUXPageableBox ref={this.myRefs.ref} {...props} />
            </div>

        return (
            <div
                id={this.props.id}
                data-orbit-component={'OBTPreviousNextSelector'}
                onClick={this.handleClick}
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseMove}
                onMouseUp={this.handleMouseUp}
                onMouseLeave={this.handleMouseLeave}
                onMouseEnter={this.handleMouseEnter}
                onKeyDown={this.handleKeyDown}
                onKeyPress={this.handleKeyPress}
                onKeyUp={this.handleKeyUp}
            >
                {component}
            </div >);
    }

    render() {
        return (<ErrorBoundary owner={this} render={this.renderComponent} />)
    }
};

OBTPreviousNextSelector.contextType = OBTContext;