/**
 * @version 0.1
 * @author 하성준
 * @see common.js
 */
import * as React from 'react';
import { Events, CompositeProps, Util, CommonProps, Functions, createPropDefinitions, CommonDefinitions, CommonType } from '../Common';
import UFOCheckBox from '../UFO/UFOCheckBox';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import OBTTooltip, { IOBTTooltip } from '../OBTTooltip/OBTTooltip';

/**
 * @internal
 * CSS Modules 사용방식
 * styles.[className]
 * {@code <div className={styles.required}}
 */
const styles = require('./OBTCheckBox.module.scss');

class ChangeEventArgs extends Events.ChangeEventArgs<boolean> {
    constructor(target: any, public readonly value: boolean, public readonly event: React.MouseEvent) {
        super(target, value);
    }
}

interface IOBTCheckBox extends CompositeProps.Default, CommonProps.disabled, CommonProps.readonly, CommonProps.required, CommonProps.value<boolean>,
    Events.onFocus, Events.onBlur, Events.onMoveFocus, CommonProps.labelText, Events.onValidate<boolean>, Events.onMouseDown, Events.onMouseMove, Events.onMouseUp, Events.onMouseLeave, Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress,
    Events.onKeyUp {
    onChange: (e: ChangeEventArgs) => void,
    useRadioStyle?: boolean,
    /**
    *  버튼 tooltip 설정 입니다.
    */
    tooltip?: IOBTTooltip,
}

interface State extends hasError {
}

export default class OBTCheckBox extends React.Component<IOBTCheckBox, State> implements Functions.IFocusable, Functions.IRequired {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        CommonDefinitions.disabled(),
        CommonDefinitions.readonly(),
        CommonDefinitions.required(),
        CommonDefinitions.value({ type: CommonType.boolean, description: '체크여부' }),
        CommonDefinitions.labelText(),
        {
            name: 'useRadioStyle',
            type: CommonType.boolean,
            default: false,
            optional: true,
            description: '체크박스 형태를 라디오버튼 형태로 만들어주는 속성입니다.'
        },
        {
            name: 'onChange', type: CommonType.function, parameters: {
                name: 'e',
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    value: { type: CommonType.boolean, description: '체크여부' },
                    event: { type: 'MouseEvent', description: '이벤트 객체' }
                }
            },
            description: '입력된 값이 변경될 때 발생하는 Callback 함수입니다.'
        },
        CommonDefinitions.onClick(),
        CommonDefinitions.onFocus(),
        CommonDefinitions.onBlur(),
        CommonDefinitions.onMouseDown(),
        CommonDefinitions.onMouseMove(),
        CommonDefinitions.onMouseUp(),
        CommonDefinitions.onMouseLeave(),
        CommonDefinitions.onMouseEnter(),
        CommonDefinitions.onKeyDown(),
        CommonDefinitions.onKeyPress(),
        CommonDefinitions.onKeyUp()
    );

    public static defaultProps = {
        disabled: false,
        frozen: false,
        readonly: false,
        required: false,
        labelText: ''
    }

    public state: State = {
        hasError: false
    }

    public myRefs = {
        root: React.createRef<HTMLDivElement>(),
        id: React.createRef<UFOCheckBox>(),
        tooltip: React.createRef<OBTTooltip>()
    }

    componentDidMount() {
        try {
            let root = (this.myRefs.root.current || (this.myRefs.id.current ? this.myRefs.id.current.myRefs.wrapper.current : undefined)) as HTMLDivElement;

            if (!this.props.tooltip && root) {
                if (this.props.id) {
                    root.id = this.props.id;
                }
                root.setAttribute('data-orbit-component', 'OBTCheckBox');
            }
        } catch (error) {
            console.error(error);
        }
    }

    public focus(isLast?: boolean): void {
        if (this.myRefs.id.current) {
            this.myRefs.id.current.focus(undefined);
        }
    }

    public isEmpty(): boolean {
        return (!this.props.value);
    }

    public validate() {
        return !(this.props.required === true && this.isEmpty());
    }

    get canEdit(): boolean {
        return !this.props.disabled;
    }

    /**
     * Luna - Rocket 의 onMoveFocus 이벤트 핸들러를 받아 재호출
     * @param {string} direction
     */
    private handleMoveFocus = (direction: string): void => {
        if (this.props.required && this.isEmpty() && ['right', 'down', 'enter'].includes(direction)) return;
        Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
    }

    private handleFocus = (): void => {
        Util.invokeEvent<Events.EventArgs>(this.props.onFocus, new Events.EventArgs(this));
    }

    private handleChange = (event: React.MouseEvent, value: boolean): void => {
        if (Util.invokeEvent<Events.ValidateEventArgs<boolean>>(this.props.onValidate, new Events.ValidateEventArgs(this, this.props.value, value))) {
            Util.invokeEvent<ChangeEventArgs>(this.props.onChange, new ChangeEventArgs(this, value, event));
        }
    }

    private handleBlur = (): void => {
        Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
    }


    private handleMouseDown = (event: React.MouseEvent): void => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseDown, new Events.MouseEventArgs(this, event));
    }

    private handleMouseMove = (event: React.MouseEvent): void => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseMove, new Events.MouseEventArgs(this, event));
    }

    private handleMouseUp = (event: React.MouseEvent): void => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseUp, new Events.MouseEventArgs(this, event));
    }

    private handleMouseOut = (event: React.MouseEvent): void => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseLeave, new Events.MouseEventArgs(this, event));
    }

    private handleMouseOver = (event: React.MouseEvent): void => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseEnter, new Events.MouseEventArgs(this, event));
    }

    private handleKeyDown = (event: React.KeyboardEvent): void => {
        Util.invokeEvent<Events.KeyEventArgs>(this.props.onKeyDown, new Events.KeyEventArgs(this, event));
    }

    private handleKeyPress = (event: React.KeyboardEvent): void => {
        Util.invokeEvent<Events.KeyEventArgs>(this.props.onKeyPress, new Events.KeyEventArgs(this, event));
    }

    private handleKeyUp = (event: React.KeyboardEvent): void => {
        Util.invokeEvent<Events.KeyEventArgs>(this.props.onKeyUp, new Events.KeyEventArgs(this, event));
    }
    renderComponent = () => {
        const component =
            <UFOCheckBox
                ref={this.myRefs.id}
                className={styles.checkBox}
                labelText={this.props.labelText || ''}
                checked={this.props.value}
                iconCheckBoxStyle={{
                    pointerEvents: 'none',
                    ...(() => this.props.required ? { backgroundColor: '#fef4f4' } : {})()
                }}
                style={Util.getWrapperStyle(this.props)}
                disabled={this.props.disabled}
                frozen={this.props.frozen}
                radioIcon={this.props.useRadioStyle ? true : false}
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseMove}
                onMouseUp={this.handleMouseUp}
                onMouseOut={this.handleMouseOut}
                onMouseOver={this.handleMouseOver}
                onKeyDown={this.handleKeyDown}
                onKeyPress={this.handleKeyPress}
                onKeyUp={this.handleKeyUp}
                onChange={this.handleChange}
                onMoveFocus={this.handleMoveFocus}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
            />

        const wrappedClassNameComponent = ((this.props.className && this.props.className.length > 0) || this.props.tooltip) ?
            <div ref={this.myRefs.root}
                className={Util.getClassNames(styles.root, this.props.className)}
                style={{
                    lineHeight: this.props.tooltip ? '18px' : undefined,
                    ...(!this.props.tooltip) && Util.getWrapperStyle(this.props)
                }}
            >
                {component}
            </div> : component;

        const wrappedTooltipComponent = this.props.tooltip ? (
            <OBTTooltip
                ref={this.myRefs.tooltip}
                style={Util.getWrapperStyle(this.props)}
                {...this.props.tooltip}
                rootProps={{
                    id: this.props.id,
                    'data-orbit-component': 'OBTCheckBox'
                }}
            >
                {wrappedClassNameComponent}
            </OBTTooltip>

        ) : wrappedClassNameComponent;
        return (
            <>
                {wrappedTooltipComponent}
            </>
        )
    }

    renderComponentOld = () => {
        const component =
            <UFOCheckBox
                ref={this.myRefs.id}
                className={styles.checkBox}
                labelText={this.props.labelText || ''}
                checked={this.props.value}
                iconCheckBoxStyle={{
                    pointerEvents: 'none',
                    ...(() => this.props.required ? { backgroundColor: '#fef4f4' } : {})()
                }}
                style={Util.getWrapperStyle(this.props)}
                disabled={this.props.disabled}
                frozen={this.props.frozen}
                radioIcon={this.props.useRadioStyle ? true : false}
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseMove}
                onMouseUp={this.handleMouseUp}
                onMouseOut={this.handleMouseOut}
                onMouseOver={this.handleMouseOver}
                onKeyDown={this.handleKeyDown}
                onKeyPress={this.handleKeyPress}
                onKeyUp={this.handleKeyUp}
                onChange={this.handleChange}
                onMoveFocus={this.handleMoveFocus}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
            />
        return (
            <>
                {this.props.className && this.props.className.length > 0 ?
                    <div ref={this.myRefs.root}
                        className={Util.getClassNames(styles.root, this.props.className)}
                        style={Util.getWrapperStyle(this.props)}>{component}</div> : component}

            </>
        )
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        )
    }
};
