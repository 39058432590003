export default class Privacy {
    private static readonly PRIVACY_KEY_SEPARATOR = "@!1!@";
    private static readonly MODIFIED_VALUE_SEPARATOR = "@!0!@";

    private _privacyKey?: string | null;

    private _privacyValue?: string | null;

    private _modifiedValue?: string | null;

    public set value(fieldValue: string | undefined | null) {
        let privacyKey: string | undefined,
            privacyValue: string | undefined,
            modifiedValue: string | undefined;

        if (fieldValue) {
            const privacyKeyIndex = fieldValue.indexOf(Privacy.PRIVACY_KEY_SEPARATOR);
            const modifiedValueIndex = fieldValue.indexOf(Privacy.MODIFIED_VALUE_SEPARATOR);
            const firstIndex = ((privacyKeyIndex, modifiedValueIndex) => {
                return privacyKeyIndex > modifiedValueIndex ? modifiedValueIndex : privacyKeyIndex;
            })(privacyKeyIndex === -1 ? fieldValue.length : privacyKeyIndex, modifiedValueIndex === -1 ? fieldValue.length : modifiedValueIndex);

            if (privacyKeyIndex >= 0) {
                if (modifiedValueIndex > privacyKeyIndex) {
                    privacyKey = fieldValue.substring(privacyKeyIndex + Privacy.PRIVACY_KEY_SEPARATOR.length, modifiedValueIndex);
                } else {
                    privacyKey = fieldValue.substring(privacyKeyIndex + Privacy.PRIVACY_KEY_SEPARATOR.length);
                }
            }

            if (modifiedValueIndex >= 0) {
                if (privacyKeyIndex > modifiedValueIndex) {
                    modifiedValue = fieldValue.substring(
                        modifiedValueIndex + Privacy.MODIFIED_VALUE_SEPARATOR.length, privacyKeyIndex);
                } else {
                    modifiedValue = fieldValue.substring(modifiedValueIndex + Privacy.MODIFIED_VALUE_SEPARATOR.length);
                }
            }

            if (firstIndex >= 0) {
                privacyValue = fieldValue.substring(0, firstIndex);
            } else {
                privacyValue = fieldValue;
            }
        }
        this._privacyKey = privacyKey;
        this._privacyValue = privacyValue;
        this._modifiedValue = modifiedValue;
    }

    public get value() {
        // let privacyValue = this._privacyValue;
        let privacyKey = this._privacyKey;
        let modifiedValue = this._modifiedValue;
        if (!privacyKey && modifiedValue) {
            // privacyValue = modifiedValue;
            modifiedValue = undefined;
        }
        return (this._privacyValue || '') +
            (this._privacyKey ? Privacy.PRIVACY_KEY_SEPARATOR + this._privacyKey : '') +
            (this._modifiedValue !== undefined && this._modifiedValue !== null ? Privacy.MODIFIED_VALUE_SEPARATOR + this._modifiedValue : '');
    }

    constructor(value: string | undefined | null) {
        this.value = value;
    }

    public get privacyKey(): string | undefined | null { return this._privacyKey; }
    public set privacyKey(privacyKey: string | undefined | null) { this._privacyKey = privacyKey; }
    public get privacyValue() { return this._privacyValue; }
    public set privacyValue(privacyValue: string | undefined | null) { this._privacyValue = privacyValue; }
    public get modifiedValue() { return this._modifiedValue; }
    public set modifiedValue(modifiedValue: string | undefined | null) { this._modifiedValue = modifiedValue; }
}