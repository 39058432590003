/**
 * OBTColorPickerMain.
 * @version 0.1
 * @author 하성준
 * @see common.js
 */
import * as React from 'react';
import { Events, CompositeProps, Util, CommonProps } from '../Common';
import OBTCustomColorPicker from './OBTCustomColorPicker';
import { CustomPicker } from 'react-color';
import OBTDialog2 from '../OBTDialog2';
import OBTBasicColorPicker from './OBTBasicColorPicker';
import { ColorWrapChangeHandler } from 'react-color/lib/components/common/ColorWrap'
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { OrbitInternalLangPack } from '../Common/Util';

const styles = require('./OBTColorPickerMain.module.scss');

interface IOBTColorPickerMain extends CompositeProps.Default, Events.onBlur, CommonProps.disabled, CommonProps.value<any> {
    type?: MainType,
    onButtonClick?: (e: Events.EventArgs) => void,
    onChange: ColorWrapChangeHandler,
    onDialogClosed?: () => void
}

enum MainType {
    'basic' = 'basic',
    'popUp' = 'popUp'
}

interface State extends hasError {
    newColor: any,
    open: boolean
}


class OBTColorPickerMain extends React.Component<IOBTColorPickerMain, State> {

    public static defaultProps = {
        disabled: false,
        type: MainType.basic,
    }

    public state: State = {
        newColor: this.props.value,
        open: false,
        hasError: false
    }

    onSave = () => {
        this.setState({
            open: false
        }, () => {
            this.handleChange(this.state.newColor)
            if (this.props.onDialogClosed) {
                this.props.onDialogClosed();
            }
        });
    }

    onClose = () => {
        this.setState({
            open: false,
            newColor: this.props.value
        }, () => {
            if (this.props.onDialogClosed) {
                this.props.onDialogClosed();
            }
        })
    }

    private handleChange(value: any): void {
        this.props.onChange(value)
    }

    private handleButtonClick = (): void => {
        this.setState({ open: true, newColor: this.props.value }, () => {
            Util.invokeEvent<Events.EventArgs>(this.props.onButtonClick, new Events.EventArgs(this));
        })
    }

    renderComponent = () => {
        return (
            <>
                <div className={Util.getClassNames(styles.main, this.props.disabled === true ? styles.disabled : null)}>
                    {this.props.type === MainType.popUp ?

                        this.state.open ?
                            <OBTCustomColorPicker onButtonClick={e => this.handleButtonClick()} color={this.state.newColor} onChange={e => { this.setState({ newColor: e }, () => { this.handleChange(this.state.newColor) }) }} />
                            :
                            <OBTBasicColorPicker type={this.props.type} disabled={this.props.disabled} color={this.props.value} onChange={e => this.handleChange(e)} onButtonClick={e => this.handleButtonClick()} />
                        :
                        <>
                            <OBTBasicColorPicker type={this.props.type} disabled={this.props.disabled} color={this.props.value} onChange={e => this.handleChange(e)} onButtonClick={e => this.handleButtonClick()} />
                            <OBTDialog2
                                key={this.state.open ? 'open' : 'close'}
                                title={OrbitInternalLangPack.getText('WE000017437', '사용자 지정')}
                                width="280px"
                                height="435px"
                                type={OBTDialog2.Type.small}
                                open={this.state.open}
                                buttons={[OBTDialog2.Button.Save(this.onSave), { key: 'close', onClick: this.onClose, labelText: OrbitInternalLangPack.getText('WE000001970', '닫기'), visible: false, isClose: true }]}>
                                <div className={styles.dialog}>
                                    <OBTCustomColorPicker onButtonClick={e => this.handleButtonClick()} color={this.state.newColor} onChange={e => { this.setState({ newColor: e }) }} />
                                </div>
                            </OBTDialog2>
                        </>
                    }
                </div>
            </>
        )
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        )
    }
};

export default CustomPicker(OBTColorPickerMain)