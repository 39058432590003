import * as React from 'react';
import { CommonProps, Util, Events, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import IconSnackApproval from '../Images/icon-snack-approval.png';
import IconSnackInfor from '../Images/icon-snack-infor.png';
import IconSnackError from '../Images/ic_snack_error.png';
import IconSnackWarning from '../Images/ic_snack_warning.png';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import OBTPortal from '../OBTPortal';

const styles = require('./OBTSnackbar.module.scss');

enum Type {
    'success' = 'success',
    'warning' = 'warning',
    'error' = 'error',
    'info' = 'info'
}

class ChangeEventArgs extends Events.EventArgs {
    constructor(
        public readonly target: any,
        public readonly open: boolean
    ) {
        super(target);
    }
}

interface IOBTSnackbar extends CommonProps.id, CommonProps.className, CommonProps.labelText {
    /**
     * @default info
     * Snackbar의 타입을 지정합니다.
     * : success | warning | error | info
     */
    type: Type,
    /**
     * OBTSnackbar가 열려있는지 닫혀있는지 설정합니다.
     */
    open?: boolean,
    /**
     * @default true
     * OBTSnackbar가 적용되는 범위를 화면가득 채울지 특정영역만 채울지를 설정합니다.
     */
    fullScreen?: boolean,
    /**
     OBTSnackbar 가 특정영역에 열릴때, Wrapper 의 스타일을 지정할 수 있습니다.
     */
    wrapperStyle?: React.CSSProperties,
    /**
     * 입력된 값이 변경될 때 발생하는 Callback 함수입니다.
     */
    onChange?: (e: ChangeEventArgs) => void
}

interface State extends hasError {
    open: boolean,
    visible: boolean,
    nextOpen: boolean
}

class OBTSnackbarDOM extends React.Component<any> {
    render() {
        const {
            className,
            labelText,
            type,
            fullScreen,
            id,
            open,
            visible,
            onTransitionEnd,
            children
        } = this.props;

        // 이미지
        let iconElement;
        let bgColor;
        if (type === OBTSnackbar.Type.success) {
            iconElement = <img src={IconSnackApproval} alt="Approval Icon" />
            bgColor = styles.success
        } else if (type === OBTSnackbar.Type.warning) {
            iconElement = <img src={IconSnackWarning} alt="Warning Icon" />
            bgColor = styles.warning
        } else if (type === OBTSnackbar.Type.info) {
            iconElement = <img src={IconSnackInfor} alt="Info Icon" />
            bgColor = styles.info
        } else if (type === OBTSnackbar.Type.error) {
            iconElement = <img src={IconSnackError} alt="Error Icon" />
            bgColor = styles.error
        }

        // 스낵바
        const snackbar =
            <div className={Util.getClassNames(className, styles.snackRoot, bgColor)}>
                <span>
                    {iconElement}
                    <span>
                        {labelText}
                    </span>
                </span>
            </div>

        return fullScreen ? (visible || open ? (<OBTPortal>
            <div
                id={id}
                data-orbit-component={'OBTSnackbar'}
                className={Util.getClassNames(styles.fullFixed,
                    open ? styles.open : styles.closed,
                    visible || open ? undefined : styles.invisible)}>
                <div className={styles.relative}>
                    <div className={Util.getClassNames(styles.absolute, open ? styles.open : styles.closed)}
                        onTransitionEnd={onTransitionEnd}>
                        {snackbar}
                    </div>
                </div>
            </div>
        </OBTPortal>) : <></>) : (<div
            id={id} data-orbit-component={'OBTSnackbar'} className={styles.sectionRoot} style={this.props.wrapperStyle}>
            {children}
            {visible || open ?
                <div className={Util.getClassNames(styles.sectionWrapper, visible || open ? undefined : styles.invisible)}>
                    <div className={Util.getClassNames(styles.sectionContents, visible || open ? undefined : styles.invisible)}>
                        <div className={Util.getClassNames(open ? styles.open : styles.closed, styles.absolute,
                            visible || open ? undefined : styles.invisible)}
                            onTransitionEnd={onTransitionEnd}>
                            {snackbar}
                        </div>
                    </div>
                </div> : undefined}
        </div>);
    }
}

export default class OBTSnackbar extends React.Component<IOBTSnackbar, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.id(),
        CommonDefinitions.className(),
        { name: "labelText", type: CommonType.string, description: "Snackbar의 라벨에 표시될 문구를 지정합니다." },
        { name: "type", type: toEnumType(Type), description: "Snackbar의 타입을 지정합니다.", default: "info", optional: true },
        { name: "open", type: CommonType.boolean, description: "OBTSnackbar가 열려있는지 닫혀있는지 설정합니다.", optional: true },
        { name: "fullScreen", type: CommonType.boolean, description: "OBTSnackbar가 적용되는 범위를 화면가득 채울지 특정영역만 채울지를 설정합니다.", default: true, optional: true },
        { name: "wrapperStyle", type: "React.CSSProperties", description: "OBTSnackbar 가 특정영역에 열릴때, Wrapper 의 스타일을 지정할 수 있습니다.", optional: true },
        CommonDefinitions.onChange()
    )

    public static Type = Type

    public static defaultProps = {
        type: OBTSnackbar.Type.info,
        fullScreen: true
    }

    public state: State = {
        open: false,
        visible: false,
        nextOpen: (this.props.open === undefined ? true : this.props.open)
    }

    public myRefs = {
        root: React.createRef<HTMLDivElement>()
    }

    private autoHideDuration = 1000
    private timerAutoHideId: any = null;
    private transitionWatchId: any = null;

    componentDidMount() {
        try {
            this.setSnackbar();
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    componentDidUpdate(prevProps) {
        try {
            if (prevProps.open !== this.props.open) {
                this.setState({
                    nextOpen: (this.props.open === undefined ? true : this.props.open)
                }, () => {
                    this.setSnackbar();
                });
            } else {
                this.setSnackbar();
            }
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    componentWillUnmount() {
        try {
            clearTimeout(this.timerAutoHideId);
            clearTimeout(this.transitionWatchId);
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    private setSnackbar() {
        if (this.state.visible === false) {
            if (this.state.open !== this.state.nextOpen) {
                const nextOpen = this.state.nextOpen;
                this.setState({
                    visible: true
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            open: this.state.nextOpen
                        });
                        this.transitionWatchId = setTimeout(() => {
                            if (this.state.visible === true &&
                                this.state.open === nextOpen &&
                                this.state.nextOpen === nextOpen) {
                                this.handleTransitionEnd();
                            }
                        }, 300);
                    }, 0);
                });
            }
        }
    }

    private setAutoHideTimer() {
        if (this.timerAutoHideId) {
            clearTimeout(this.timerAutoHideId);
            this.timerAutoHideId = null;
        }
        this.timerAutoHideId = setTimeout(() => {
            this.setState({ nextOpen: false });
        }, this.autoHideDuration);
    }

    private handleTransitionEnd = () => {
        if (this.transitionWatchId) {
            clearTimeout(this.transitionWatchId);
            this.transitionWatchId = null;
        }
        this.setState({
            visible: false
        });
        if (this.state.open) {
            this.setAutoHideTimer();
        } else {
            clearTimeout(this.timerAutoHideId);
            if (this.props.onChange) {
                Util.invokeEvent<ChangeEventArgs>(this.props.onChange, new ChangeEventArgs(this, false));
            }
        }
    }

    renderComponent = () => {
        const component = <OBTSnackbarDOM
            className={this.props.className}
            labelText={this.props.labelText}
            type={this.props.type}
            fullScreen={this.props.fullScreen}
            id={this.props.id}
            open={this.state.open}
            visible={this.state.visible}
            onTransitionEnd={this.handleTransitionEnd}
            children={this.props.children}
            wrapperStyle={this.props.wrapperStyle} />

        return this.props.fullScreen ?
            <>
                {this.props.children}
                {component}
            </> : component;
    }

    render() {
        return <ErrorBoundary owner={this} render={this.renderComponent} />;
    }
};