import React from 'react';
import { Util } from '../Common';
import { OBTDataGridInterface } from '../OBTDataGrid';
import SBCCheckPen from './SBCCheckPen';
import SBCEditTracker from './SBCEditTracker';
import SBCGridGuide from './SBCGridGuide';
import SBCMemo from './SBCMemo';
import SBCMessage from './SBCMessage';
import SBCSchedule, { IScheduleItem } from './SBCSchedule';
import SideBarComponent, { SideBarContentType } from './SideBarContent';

import iconInputLog from './Images/ico_sb_user_normal.png';
import iconInputLogFocused from './Images/ico_sb_user_select.png';
import iconCheckpen from './Images/ico_sb_pen_normal.png';
import iconCheckpenFocused from './Images/ico_sb_pen_select.png';
import iconGridGuide from './Images/ico_sb_guide_normal.png';
import iconGridGuideFocused from './Images/ico_sb_guide_select.png';
import iconSchedule from './Images/ico_sb_cal_normal.png';
import iconScheduleFocused from './Images/ico_sb_cal_select.png';
import iconMemo from './Images/ico_sb_memo_normal.png';
import iconMemoFocused from './Images/ico_sb_memo_select.png';
import { OBTScrollbar } from '..';
import { IPageContainerFunctions } from './OBTPageContainer';
import InformationSideBar from './InformationSideBar';

const styles = require('./OBTPageContainer.module.scss');

interface ISideBarButton {
    icon?: Node,
    imageUrl?: string,
    onClick?: (e: any) => void,
}

export interface ISideBarContent {
    rowKey?: string;

    memoCategory?: string;

    priority?: number;
    /**
     * 사이드바 아이템의 키
     */
    key: string,

    /**
     * 
     */
    readonly?: boolean,
    /**
     * 사이드바 아이템의 형태
     * ex) 아코디언으로 표기
     */
    type?: SideBarContentType,
    /**
     * 사이드바 아이템 아코디언에 표기되는 아이콘
     */
    icon?: JSX.Element,//Node,
    /**
     * 포커스 된 경우사이드바 아이템 아코디언에 표기되는 아이콘
     */
    iconFocused?: JSX.Element,//Node,
    /**
     * 사이드바 아이템 아코디언에 표기되는 이미지
     */
    imageUrl?: string,
    /**
     * 포커스 된 경우 아이템 아코디언에 표기되는 이미지
     */
    imageUrlFocused?: string,
    /**
     * 
     */
    title?: string,
    /**
     * 
     */
    titleButtons?: ISideBarButton[]
    /**
     * 컨텐츠가 되는 컴포넌트
     */
    component?: any,
    /**
     * any타입 데이터
     */
    data?: any,
    /**
     * 보여질지 여부
     */
    visible?: boolean,
    /**
     * 체크펜, 작성자 정보 사용시 연관된 그리드
     */
    grid?: OBTDataGridInterface,

    /**
     * 
     */
    onApply?: (payload: any) => void
}

interface ISideBar {
    use: boolean,
    collapse: boolean,
    informationCollapse: boolean,
    /**
     * 메모에 사용되는 파일 업다운로더
     * this.props.commonGwUtil.UAA0050.UpDownloader     
     */
    UpDownloader?: any;
    userDetailDialog?: any;
    userInfo?: any;
    menuItem?: any;
    fetch?: any,
    useFloatSideBar: boolean,
    pageContainerFunctions: IPageContainerFunctions,
    useInformationSideBar?: boolean,
    isRenderOnlyNotPop: boolean,
    onScheduleItemClicked: (item: IScheduleItem) => void;
    onToggleCollapseButtonClicked: () => void,
    onToggleInformationCollapseButtonClicked: () => void,
    onFocus: () => void,
}

interface IState {
    sideBarContents: ISideBarContent[] | null,
    hasError: boolean,
}

class SideBar extends React.Component<ISideBar, IState> {

    public myRefs = {
        InformationSideBar: React.createRef<InformationSideBar>()
    }

    state: IState = {
        sideBarContents: this.getSideBarContents([]),
        hasError: false,
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.error('SideBar did catch', error, errorInfo);

        this.setState({
            hasError: true,
        });
    }

    public updateSideBarContents(sideBarContents: ISideBarContent[]) { console.warn('폐기된 메소드입니다.') }
    public replaceSideBarContents(targetList: ISideBarContent[] | null | undefined, content: ISideBarContent) { console.warn('폐기된 메소드입니다.') }
    public removeSideBarByKey(key: string) { console.warn('폐기된 메소드입니다.'); }
    public setSideBarData(key: string, data: any): void { console.warn('폐기된 메소드입니다.'); }
    public setSideBarContent(key: string, content: ISideBarContent): void { console.warn('폐기된 메소드입니다.'); }

    private getSideBarContents(sideBarContents: ISideBarContent[]) {
        const defaultSideBar: ISideBarContent[] = [{
            key: 'schedule',
            icon: <img src={iconSchedule} alt={'side bar icon - schedule'} />,
            iconFocused: <img src={iconScheduleFocused} alt={'side bar icon - schedule'} />
        }];
        return defaultSideBar.concat(sideBarContents.filter(item => {
            switch (item.key.toLowerCase()) {
                case 'schedule': return false;
                case 'inputlog': return item.data ? true : false;
                case 'guidemessage':
                case 'gridguide': return item.component ? true : false;
                default:
                    return true;
            }
        }).map(item => {
            switch (item.key.toLowerCase()) {
                case 'inputlog':
                    return {
                        ...item,
                        icon: <img src={iconInputLog} alt={''} />,
                        iconFocused: <img src={iconInputLogFocused} alt={''} />
                    };
                case 'checkpen':
                    return {
                        ...item,
                        icon: <img src={iconCheckpen} alt={''} />,
                        iconFocused: <img src={iconCheckpenFocused} alt={''} />
                    };
                case 'guidemessage':
                case 'gridguide':
                    return {
                        ...item,
                        icon: <img src={iconGridGuide} alt={''} />,
                        iconFocused: <img src={iconGridGuideFocused} alt={''} />
                    };
                case 'memo':
                    return {
                        ...item,
                        icon: <img src={iconMemo} alt={''} />,
                        iconFocused: <img src={iconMemoFocused} alt={''} />
                    };
                default:
                    break;
            }
            return item;
        }));
    }

    public setInformationSideBarRender(callback: (data?: any) => JSX.Element) {
        if (this.myRefs.InformationSideBar.current) {
            this.myRefs.InformationSideBar.current.setInformationSideBarRender(callback);
        }
    }

    public setInformationSideBarData(data: any) {
        if (this.myRefs.InformationSideBar.current) {
            this.myRefs.InformationSideBar.current.setInformationSideBarData(data);
        }
    }

    public setSideBarContents(sideBarContents: ISideBarContent[]) {
        if (this.props.use) {
            this.setState({
                sideBarContents: this.getSideBarContents(sideBarContents)
            })
        }
    }

    /**
     * 
     * @param key 
     * @param visible 
     */
    public setSideBarVisible(key: string, visible: boolean): void {
        if (this.state.sideBarContents) {
            if (this.hasSideBarByKey(key)) {
                this.setState({
                    sideBarContents: this.state.sideBarContents.map(item => {
                        if (item.key === key) {
                            return Object.assign(item, { visible: visible });
                        }
                        return item;
                    })
                });
            } else {
                this.setState({
                    sideBarContents: this.state.sideBarContents.concat({
                        key: key,
                        visible: visible
                    })
                });
            }
        }
    }

    /**
     * 키에 해당하는 사이드가 존재하는가
     * @param key 
     */
    public hasSideBarByKey(key: string): boolean {
        if (!this.state.sideBarContents) {
            return false;
        }

        return this.state.sideBarContents.some(content => content.key === key);
    }

    static getPriorityByKey(key?: string) {
        if (!key) {
            return 0;
        }
        if (key.toUpperCase() === 'MESSAGE') {
            return 100;
        } else if (key.toUpperCase() === 'GRIDGUIDE') {
            return 90;
        } else if (key.toUpperCase() === 'SCHEDULE') {
            return 80;
        } else if (key.toUpperCase() === 'INPUTLOG') {
            return 70;
        } else if (key.toUpperCase() === 'MEMO') {
            return 60;
        } else if (key.toUpperCase() === 'CHECKPEN') {
            return 50;
        } else {
            return 0;
        }
    }

    private handleClickToggleCollapseButton = () => {
        this.props.onToggleCollapseButtonClicked();
    }

    private handleScheduleItemClicked = (item: IScheduleItem) => {
        this.props.onScheduleItemClicked(item);
    };

    private handleFocus = (e: React.FocusEvent) => {
        this.props.onFocus();
    }

    render() {
        if (!this.props.use) {
            return (<></>)
        }

        if (this.state.hasError === true) {
            return <div></div>
        }

        const sideBarContents = this.state.sideBarContents ? this.state.sideBarContents.filter(item => item.visible !== false) : [];
        if (!sideBarContents || sideBarContents.length === 0) {
            return (<></>)
        }

        return (
            <div className={Util.getClassNames(styles.sideBar, this.props.collapse ? styles.collapsed : null)} tabIndex={-1} onFocus={this.handleFocus}>
                <div className={styles.sideBarButtonsWrapper}>
                    {this.props.useFloatSideBar &&
                        <button
                            tabIndex={-1}
                            className={Util.getClassNames(styles.sideBarCollapseButton,
                                this.props.collapse ? styles.closed : styles.open
                            )}
                            
                            onClick={this.handleClickToggleCollapseButton}
                        />
                    }
                    {!this.props.useFloatSideBar && this.props.use && this.props.isRenderOnlyNotPop &&
                        <button
                            tabIndex={-1}
                            className={Util.getClassNames(styles.sideBarCollapseButton,
                                this.props.collapse ? styles.closed : styles.open
                            )}
                            onClick={this.handleClickToggleCollapseButton}
                        />
                    }
                    {this.props.useInformationSideBar &&
                        <>
                            <button
                                tabIndex={-1}
                                className={Util.getClassNames(
                                    styles.informationBarCollapseButton,
                                    this.props.informationCollapse ? styles.closed : styles.open 
                                )}
                                onClick={(e) => {
                                    this.props.onToggleInformationCollapseButtonClicked();
                                }}
                            />
                        </>
                    }
                </div>
                {this.props.useInformationSideBar &&
                    <InformationSideBar
                        ref={this.myRefs.InformationSideBar}
                        isOpen={!this.props.informationCollapse}
                    />
                }
                <div className={styles.sideBarWrapper}>
                    <OBTScrollbar className={styles.sideBarContents} >
                        {sideBarContents.sort((item1, item2) => {
                            let item1Priority = item1.priority ? item1.priority : SideBar.getPriorityByKey(item1.key);
                            let item2Priority = item2.priority ? item2.priority : SideBar.getPriorityByKey(item2.key);

                            return item1Priority > item2Priority ? -1 : item1Priority < item2Priority ? 1 : 0;
                        }).map(item => {
                            switch (item.key) {
                                case 'message':
                                    return <SBCMessage key={item.key} item={item} />;
                                case 'gridGuide':
                                    return <SBCGridGuide key={item.key} item={item} />;
                                case 'schedule':
                                    return (
                                        <SBCSchedule
                                            key={item.key}
                                            fetch={this.props.fetch}
                                            userInfo={this.props.userInfo}
                                            item={item}
                                            onScheduleItemClicked={this.handleScheduleItemClicked}
                                        />
                                    );
                                case 'inputlog':
                                    return (
                                        <SBCEditTracker
                                            key={item.key}
                                            rowKey={item.rowKey}
                                            fetch={this.props.fetch}
                                            item={Object.assign({}, item)}
                                            userInfo={this.props.userInfo}
                                            userDetailDialog={this.props.userDetailDialog}
                                        />
                                    );
                                case 'memo':
                                    if (!Util.safeAccessProperty(item, 'data')) {
                                        Util.warning('data가 할당되지 않아 SBCMemo를 렌더링하지 않습니다.')
                                        return null;
                                    }
                                    return (
                                        <SBCMemo
                                            key={item.key}
                                            userInfo={this.props.userInfo}
                                            menuItem={this.props.menuItem}
                                            category={item.memoCategory}
                                            item={Object.assign({}, item)}
                                            fetch={this.props.fetch}
                                            UpDownloader={this.props.UpDownloader}
                                            pageContainerFunctions={this.props.pageContainerFunctions}
                                        />
                                    );
                                case 'checkpen':
                                    return (
                                        <SBCCheckPen key={item.key}
                                            item={Object.assign({}, item)}
                                            pageContainerFunctions={this.props.pageContainerFunctions}
                                        />
                                    );
                                default:
                                    return (
                                        <SideBarComponent key={item.key} item={item}>
                                            {item.component}
                                        </SideBarComponent>
                                    )
                            }
                        })}
                    </OBTScrollbar>
                </div>

            </div>
        );
    }
}

export default SideBar;

