import { CodePickerValue } from "../OBTCodePicker/OBTCodePicker";

export class EventArgs {
    /**
     * 기본 EventArgs.
     * 모든 EventArgs 는 이 class 를 super class 로 사용해야 한다.
     * @param {React.Component} target 이벤트 생성자
     * {@code Util.invokeEvent(this.props.onFocus, new Event.EventArgs(this))}
     * @see Util.invokeEvent
     */
    constructor(public readonly target: any) {
    }
    toString(): string { return JSON.stringify({ target: this.target ? 'object' : this.target }); }
}

export class ConditionPanelChangeEventArgs extends EventArgs {
    constructor(target: any, public readonly originalEvent: EventArgs) {
        super(target);
    }
}

export class CancelableEventArgs extends EventArgs {
    /**
     * Cancel 을 지원하는 EventArgs
     * @param {React.Component} target 이벤트 생성자
     * {@code if (!Util.invokeEvent(this.props.onCustomEvent, new Event.Cancelable(this))) return;}
     * @see EventArgs
     * @see Util.invokeEvent
     */
    constructor(target: any, public cancel: boolean = false) {
        super(target);
    }
    toString(): string { return JSON.stringify({ target: this.target ? 'object' : this.target, cancel: this.cancel }); }
}

export class ChangeEventArgs<T> extends EventArgs {
    /**
     * Value 속성을 제공하는 EventArgs
     * @param {React.Component} target 이벤트 생성자
     * @param {*} value 값
     * {@code Util.invokeEvent(this.props.onChange, new Event.ChangeEventArgs(this, value))}
     * @see EventArgs
     * @see Util.invokeEvent
     */
    constructor(target: any, public readonly value: T, public readonly Source: any = null) {
        super(target);
    }
    toString(): string { return JSON.stringify({ target: this.target ? 'object' : this.target, value: this.value, Source: this.Source }); }
}

export class CodePickerChangeEventArgs extends ChangeEventArgs<any[] | CodePickerValue> {
    constructor(
        public readonly target: any,
        public readonly value: any[] | CodePickerValue,
        public readonly Source: any = null,
        public readonly useTotalToEmpty: boolean = false,
    ) {
        super(target, value, Source);
    }
    toString(): string { return JSON.stringify({ target: this.target ? 'object' : this.target, value: this.value, Source: this.Source }); }
}

export class ChangeWithSubEventArgs<T> extends EventArgs {
    /**
     * Value 속성을 제공하는 EventArgs
     * @param {React.Component} target 이벤트 생성자
     * @param {*} value 값
     * @param {*} subValue 값
     * {@code Util.invokeEvent(this.props.onChange, new Event.ChangeEventArgs(this, value))}
     * @see EventArgs
     * @see Util.invokeEvent
     */
    constructor(target: any, public readonly value: T, public readonly subValue: T, public readonly Source: any = null) {
        super(target);
    }
    toString(): string { return JSON.stringify({ target: this.target ? 'object' : this.target, value: this.value, subValue: this.subValue, Source: this.Source }); }
}

export class ValidateEventArgs<T> extends CancelableEventArgs {
    /**
     * Cancel 을 지원하는 oldValue, newValue 를 제공하는 EventArgs
     * oldValue 와 newValue 를 비교하여 cancel 을 true 로 부여하면 추후 이벤트 동작 중지와 같은 코드에서 사용함.
     * @param {React.Component} target 이벤트 생성자
     * @param {*} oldValue 이전 값
     * @param {*} newValue 이후 값
     * {@code if (!Util.invokeEvent(this.props.onBeforeChange, new Event.ValidateEventArgs(this, oldValue, newValue))) return;}
     * @see CancelableEventArgs
     * @see Util.invokeEvent
     */
    constructor(target: any, public readonly oldValue: T, public readonly newValue: T, public readonly oldSource: any = null, public readonly newSource: any = null) {
        super(target);
    }
    toString(): string { return JSON.stringify({ target: this.target ? 'object' : this.target, oldValue: this.oldValue, newValue: this.newValue, oldSource: this.oldSource, newSource: this.newSource }); }
}

export class ValidateWithSubEventArgs<T> extends CancelableEventArgs {
    /**
     * Cancel 을 지원하는 oldValue, newValue 를 제공하는 EventArgs
     * oldValue 와 newValue 를 비교하여 cancel 을 true 로 부여하면 추후 이벤트 동작 중지와 같은 코드에서 사용함.
     * @param {React.Component} target 이벤트 생성자
     * @param {*} oldValue 이전 값
     * @param {*} newValue 이후 값
     * @param {*} oldSubValue 이전 값
     * @param {*} newSubValue 이후 값
     * {@code if (!Util.invokeEvent(this.props.onBeforeChange, new Event.ValidateEventArgs(this, oldValue, newValue))) return;}
     * @see CancelableEventArgs
     * @see Util.invokeEvent
     */
    constructor(target: any, public readonly oldValue: T, public readonly newValue: T, public readonly oldSubValue: T, public readonly newSubValue: T, public readonly oldSource: any = null, public readonly newSource: any = null) {
        super(target);
    }
    toString(): string { return JSON.stringify({ target: this.target ? 'object' : this.target, oldValue: this.oldValue, newValue: this.newValue, oldSubValue: this.oldSubValue, newSubValue: this.newSubValue, oldSource: this.oldSource, newSource: this.newSource }); }
}

export enum MoveFocusDirection {
    left = 'left',
    up = 'up',
    right = 'right',
    down = 'down',
    enter = 'enter'
}

export class MoveFocusEventArgs extends EventArgs {
    /**
     * onMoveFocus 이벤트의 EventArgs
     * @param {React.Component} target 이벤트 생성자
     * @param {*} direction Event.MoveFocusDirection 사용 ( left, up, right, down )
     * {@code onMoveFocus(direction) { Util.invokeEvent(this.props.onMoveFocus, new Event.MoveFocusEventArgs(this, this.state.value, direction)) }}
     */
    constructor(target: any, public readonly direction: string) {
        super(target);
    }
    toString(): string { return JSON.stringify({ target: this.target ? 'object' : this.target, direction: this.direction }); }
}

export class MouseEventArgs extends EventArgs {
    /**
     * 마우스 이벤트의 EventArgs
     * @param {React.Component} target 이벤트 생성자
     * @param {React.MouseEvent} event 마우스 이벤트
     * {@code onClick(e) { Util.invokeEvent(this.props.onClick, new Event.MouseEventArgs(this, e)) }}
     */
    constructor(target: any, public readonly event: React.MouseEvent | any) {
        super(target);
    }
    toString(): string { return JSON.stringify({ target: this.target ? 'object' : this.target, event: this.event.toString() }); }
}

export class KeyEventArgs extends EventArgs {
    /**
     * 키 이벤트의 EventArgs
     * @param {React.Component} target 이벤트 생성자
     * @param {React.KeyboardEvent} event 키 이벤트
     * {@code onClick(e) { Util.invokeEvent(this.props.onKeyPress, new Event.MouseEventArgs(this, e)) }}
     */
    constructor(target: any, public readonly event: React.KeyboardEvent | any) {
        super(target);
    }
    toString(): string { return JSON.stringify({ target: this.target ? 'object' : this.target, event: this.event.toString() }); }
}

export class FocusEventArgs extends EventArgs {
    constructor(target: any, public readonly event?: React.FocusEvent) {
        super(target);
    }
}

export class GetPrivacyEventArgs extends CancelableEventArgs {
    constructor(target: any, public readonly value: string, public readonly privacyKey: string, public cancel: boolean = false) {
        super(target);
    }
}

export interface onFocus { onFocus?: (e: FocusEventArgs) => void }
export interface onBlur { onBlur?: (e: EventArgs) => void }
export interface onChange<T> { onChange: (e: ChangeEventArgs<T>) => void }
export interface onChangeWithSub<T> { onChange: (e: ChangeWithSubEventArgs<T>) => void }
export interface onChangeCodePicker { onChange: (e: CodePickerChangeEventArgs) => void }
export interface onChangePartial<T> extends Partial<onChange<T>> { }
export interface onValidate<T> { onValidate?: (e: ValidateEventArgs<T>) => void }
export interface onValidateWithSub<T> { onValidate?: (e: ValidateWithSubEventArgs<T>) => void }
export interface onActivate { onActivate?: (e: EventArgs) => void }
export interface onDeactivate { onDeactivate?: (e: EventArgs) => void }
export interface onClick { onClick?: (e: MouseEventArgs) => void }
export interface onDblClick { onDblClick?: (e: MouseEventArgs) => void }
export interface onMouseDown { onMouseDown?: (e: MouseEventArgs) => void }
export interface onMouseMove { onMouseMove?: (e: MouseEventArgs) => void }
export interface onMouseUp { onMouseUp?: (e: MouseEventArgs) => void }
export interface onMouseLeave { onMouseLeave?: (e: MouseEventArgs) => void }
export interface onMouseEnter { onMouseEnter?: (e: MouseEventArgs) => void }
export interface onKeyDown { onKeyDown?: (e: KeyEventArgs) => void }
export interface onKeyPress { onKeyPress?: (e: KeyEventArgs) => void }
export interface onKeyUp { onKeyUp?: (e: KeyEventArgs) => void }
export interface onMoveFocus { onMoveFocus?: (e: MoveFocusEventArgs) => void }