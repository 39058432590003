import React from 'react'
import reactCSS from 'reactcss'

export const Pointer = ({ direction }) => {
    const styles = reactCSS({
        'default': {
            picker: {
                width: '14px',
                height: '14px',
                borderRadius: '50%',
                transform: 'translate(-9px, -1px)',
                backgroundColor: 'rgb(248, 248, 248)',
                boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.37)',
            },
        },
        'vertical': {
            picker: {
                transform: 'translate(-2px, -9px)',
            },
        },
    }, { vertical: direction === 'vertical' })

    return (
        <div style={styles.picker} />
    )
}

export default Pointer