/**
 * LinearProgress
 * @version 0.1
 * @author 신지유
 */
import * as React from 'react';
import { Util } from '..';
import { Type } from './OBTProgressDialog';

const styles = require('./OBTProgressDialog.module.scss');

interface ILinearProgress {
    value: number,
    type?: Type,
    isEntireLoading?: boolean,
    disabled?: boolean
}

interface IState {

}

export default class LinearProgress extends React.Component<ILinearProgress, IState> {

    render() {
        return (
            <div className={styles.linearProgress}>
                <div className={styles.linearWrapper}>
                    <div
                        className={Util.getClassNames(
                            styles.linearLoading,
                            this.props.isEntireLoading && styles.isEntireLoading,
                            this.props.type === Type.error && styles.error,
                            this.props.type === Type.warning && styles.warning,
                            this.props.type === Type.success && styles.success
                        )}
                        style={{
                            width: `${this.props.value}%`,
                            backgroundColor: this.props.disabled ? 'rgb(198,198,198)' : undefined}}
                    />
                </div>
            </div>
        )
    }
}
