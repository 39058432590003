/**
 * OBTTreeView
 * @version 0.1
 * @author 박재성
 * @see 
 */
import * as React from 'react';
import { Util } from '../Common';
import falseArrow from './img/icon-tree-arrow-close-normal.png'
import trueArrow from './img/icon-tree-arrow-open-normal.png'
import disabledFalseArrow from './img/icon-tree-arrow-close-disable.png'
import disabledTrueArrow from './img/icon-tree-arrow-open-disable.png'
import falseFolder from './img/icon-tree-folder-close-normal.png'
import trueFolder from './img/icon-tree-folder-open-normal.png'
import disabledFalseFolder from './img/icon-tree-folder-close-disable.png'
import disabledTrueFolder from './img/icon-tree-folder-open-disable.png'
import { IList, Type, IimageUrl, Iicon } from './OBTTreeView';
import OBTCheckBox from '../OBTCheckBox';
import OBTTooltip from '../OBTTooltip';

const styles = require('./OBTTreeView.module.scss');

interface IOBTTreeViewItem {
    item: IList,
    selectedItem?: IList,
    editLabelText?: boolean,
    editSort?: boolean,
    disabled?: boolean,
    checkBox?: boolean,
    type?: Type,
    rootWidth?: string,
    imagesWidth: string,
    childCount?: boolean,
    images?: Array<any>,
    dragging?: boolean,
    draggingKey?: string,
    useOverflowTooltip?: boolean,

    onMouseEnter: (item: IList, e: React.MouseEvent) => void,
    onDragStart: (item: IList, e: React.DragEvent) => void,
    onDragEnter: (item: IList, e: React.DragEvent) => void,
    onDragOver: (item: IList, e: React.DragEvent) => void,
    onDragEnd: (item: IList, e: React.DragEvent) => void,
    onMouseDown: (item: IList, e: React.MouseEvent) => void,
    onCheckBoxClicked: (item: IList) => void,
    onToggleCollapse: (item: IList, e: React.MouseEvent) => void,
    onDoubleClick: (item: IList, e: React.MouseEvent) => void,
    onTextFieldBlur: () => void,
    onGetEditor: (item: IList) => any
}

const expanderImage = {
    default: {
        normal: {
            open: trueArrow,
            close: falseArrow
        },
        disabled: {
            open: disabledTrueArrow,
            close: disabledFalseArrow
        }
    },
    folder: {
        normal: {
            open: trueFolder,
            close: falseFolder
        },
        disabled: {
            open: disabledTrueFolder,
            close: disabledFalseFolder
        }
    },
    directory: {
        normal: {
            open: trueFolder,
            close: falseFolder
        },
        disabled: {
            open: disabledTrueFolder,
            close: disabledFalseFolder
        }
    }
}

interface State {
    isHovered?: boolean,
    isShowOverflowTooltip: boolean
}

export default class OBTTreeViewItem extends React.Component<IOBTTreeViewItem, State> {
    visibleCollapsed_default = 10;

    visibleCollapsed_folder = 24;

    checkBoxVisible = 18;

    state: State = {
        isShowOverflowTooltip: false
    };

    private labelTextButtonRef = React.createRef<HTMLButtonElement>();

    shouldComponentUpdate(nextProps: IOBTTreeViewItem, nextState: State) {
        return nextProps.item !== this.props.item ||
            nextProps.editLabelText !== this.props.editLabelText ||
            nextProps.editSort !== this.props.editSort ||
            nextProps.disabled !== this.props.disabled ||
            nextProps.checkBox !== this.props.checkBox ||
            nextProps.type !== this.props.type ||
            nextProps.rootWidth !== this.props.rootWidth ||
            nextProps.imagesWidth !== this.props.imagesWidth ||
            nextProps.childCount !== this.props.childCount ||
            nextProps.images !== this.props.images ||
            nextProps.dragging !== this.props.dragging ||
            nextProps.draggingKey !== this.props.draggingKey ||
            nextProps.useOverflowTooltip !== this.props.useOverflowTooltip ||
            nextState.isHovered !== this.state.isHovered ||
            nextState.isShowOverflowTooltip !== this.state.isShowOverflowTooltip;
    }

    isCollapsed(collapsed?: boolean) {
        return collapsed === false ? false : true;
    }

    getButton = (onChange: (e) => void, checked?: boolean, disabled?: boolean, visible?: boolean) => {
        return (
            <OBTCheckBox className={Util.getClassNames(styles.checkBox,
                visible === false ? styles.visible : null,
                this.props.disabled || disabled ? styles.disabled : null)}
                value={checked ? checked : false}
                onChange={onChange}
                disabled={disabled}
            />
        );
    }

    getExpander = (collapsed?: boolean, disabled?: boolean, onClick?: (e: React.MouseEvent) => void) => {
        let imageUrl = expanderImage[this.props.type ? this.props.type : Type.default][
            this.props.disabled ? 'disabled' : disabled ? 'disabled' : 'normal'
        ][this.isCollapsed(collapsed) ? 'close' : 'open'];
        return (
            <img
                className={Util.getClassNames(styles.expander, this.props.disabled ? styles.disabled : null)}
                src={imageUrl}
                onMouseDown={onClick}
                alt=''
            />
        )
    }

    imageUrl = (image, normal, open) => {
        if (normal === 'normal') {
            if (open === 'close') {
                return image.normal.close;
            } else {
                return image.normal.open ? image.normal.open : image.normal.close;
            }
        } else {
            if (open === 'close') {
                return image.disabled ? image.disabled.close ? image.disabled.close : image.normal.close : image.normal.close
            } else {
                return image.disabled ? image.disabled.open ? image.disabled.open : image.disabled.close ? image.disabled.close : image.normal.close : image.normal.close
            }
        }
    }

    getImageUrl = (image: string | IimageUrl, collapsed?: boolean, disabled?: boolean, onClick?: (e: React.MouseEvent) => void) => {
        let imageUrl = this.imageUrl(image, this.props.disabled ? 'disabled' : disabled ? 'disabled' : 'normal', this.isCollapsed(collapsed) ? 'close' : 'open');
        let img = new Image()
        img.src = imageUrl;

        return (
            <img
                className={Util.getClassNames(styles.imageUrl, this.props.disabled ? styles.disabled : null)}
                src={imageUrl}
                onClick={onClick}
                alt=''
            />
        )
    }

    getImageUrlState = (imageUrl: any) => {
        if (imageUrl !== undefined) {
            if (typeof imageUrl === 'string') {
                return { normal: { close: imageUrl } };
            } else {
                return imageUrl
            }
        }
    }

    icon = (icon, normal, open) => {
        if (normal === 'normal') {
            if (open === 'close') {
                return icon.normal.close;
            } else {
                return icon.normal.open ? icon.normal.open : icon.normal.close;
            }
        } else {
            if (open === 'close') {
                return icon.disabled ? icon.disabled.close ? icon.disabled.close : icon.normal.close : icon.normal.close
            } else {
                return icon.disabled ? icon.disabled.open ? icon.disabled.open : icon.disabled.close ? icon.disabled.close : icon.normal.close : icon.normal.close
            }
        }
    }

    getIcon = (icons: any | Iicon, collapsed?: boolean, disabled?: boolean, onClick?: (e: React.MouseEvent) => void) => {
        let icon = this.icon(icons, this.props.disabled ? 'disabled' : disabled ? 'disabled' : 'normal', this.isCollapsed(collapsed) ? 'close' : 'open');

        return (
            <span
                className={Util.getClassNames(styles.icon, this.props.disabled ? styles.disabled : null)}
                onClick={onClick}
            >
                {icon}
            </span>
        )
    }

    getIconState = (icon: any) => {
        if (icon !== undefined) {
            if (typeof icon === 'object' && icon.hasOwnProperty('normal')) {
                return icon;
            } else {
                return {
                    normal: { close: icon }
                };
            }
        }
    }

    private calculateContentsMaxWidth(item: IList): string | null {
        const rootWidth = this.props.rootWidth;
        const itemMarginLeft = item.index ? (item.index.length * 10) : 0;
        const collapseButtonWidth = (item.visibleCollapsedImage === false ? item.imageUrl || item.icon ? 0 : this.props.type === Type.default ? this.visibleCollapsed_default : this.visibleCollapsed_folder : 0);
        const checkButtonWidth = item.checkBoxVisible === true || this.props.checkBox ? this.checkBoxVisible : 0;
        const imagesWidth = Number(this.props.disabled === true || this.props.item.disabled === true ? 0 : this.props.imagesWidth)

        if (rootWidth) {
            const root = Number(rootWidth)
            const left = Number(itemMarginLeft) + Number(collapseButtonWidth) + Number(checkButtonWidth) + this.visibleCollapsed_folder + 15 + 4;

            if (this.state.isHovered === true) {
                return `calc(${root - left - imagesWidth}px)`
            } else {
                return `calc(${root - left}px)`
            }
        }

        return null;
    }

    private getTooltipValue = (item: IList) => {
        if (item.tooltip && !(this.props.disabled || item.disabled)) {
            if (item.tooltip.value) {
                return item.tooltip.value
            }

            return undefined
        }

        if (this.props.useOverflowTooltip && !(this.props.disabled || item.disabled)) {
            return this.state.isShowOverflowTooltip
        }

        return false
    }

    private handleMouseEnter = (e: React.MouseEvent) => {
        if (!this.props.dragging) {
            this.setState({
                isHovered: true
            }, () => {
                this.props.onMouseEnter(this.props.item, e);
            });
        }
    }

    private handleMouseLeave = () => {
        if (this.state.isHovered) {
            this.setState({
                isHovered: false
            });
        }
    }

    private handleDragStart = (e: React.DragEvent) => {
        this.props.onDragStart(this.props.item, e)
    }

    private handleDragEnter = (e: React.DragEvent) => {
        this.props.onDragEnter(this.props.item, e)
    }

    private handleDragOver = (e: React.DragEvent) => {
        this.props.onDragOver(this.props.item, e)
    }

    private handleDragEnd = (e: React.DragEvent) => {
        this.setState({
            isHovered: true
        }, () => {
            this.props.onDragEnd(this.props.item, e)
        });
    }

    private handleMouseDown = (e: React.MouseEvent) => {
        this.props.onMouseDown(this.props.item, e)
    }

    private handleLabelTextMouseEnter = (e: React.MouseEvent, item: IList) => {
        //리스트에 툴팁 설정이 있으면 useOverflowTooltip은 무시
        if (!this.props.useOverflowTooltip || item.tooltip !== undefined) {
            return;
        }

        if (this.props.useOverflowTooltip && this.labelTextButtonRef.current) {
            const clientWidth = this.labelTextButtonRef.current.clientWidth
            const scrollWidth = this.labelTextButtonRef.current.scrollWidth;
            if (clientWidth < scrollWidth) {
                this.setState({
                    isShowOverflowTooltip: true
                })
            }
        }

        return;
    }

    private handleLabelTextMouseLeave = () => {
        this.setState({
            isShowOverflowTooltip: false
        })
    }

    render() {
        const { item, ...others } = this.props;
        const childrenNode = item.children && item.children.length > 0 ? item.children.map((child) =>
            <OBTTreeViewItem key={child.key} item={child} {...others} />) : undefined;
        const children = childrenNode != null ?
            <div className={Util.getClassNames(styles.treeNodeChildren)}>
                {childrenNode}
            </div> : null;
        const displayCount = item._childrenCount || 0;
        const selected = this.props.selectedItem === item;
        const isHovered = this.props.dragging ? this.props.draggingKey === item.key : this.state.isHovered;
        const draggable = this.props.editLabelText === true && selected === true ? false : true;
        return (
            <div
                className={Util.getClassNames(styles.treeNode,
                    item.visibleCollapsedImage ? null : item.imageUrl || item.icon ? null : styles.visibleCollapsed,
                    this.isCollapsed(item.collapsed) ? styles.collapsed : null,
                    item.visible === false ? styles.visible : null)}
                style={{ width: '100%' }}>
                <div
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    draggable={draggable}
                    onDragStart={this.handleDragStart}
                    onDragEnter={this.handleDragEnter}
                    onDragOver={this.handleDragOver}
                    onDragEnd={this.handleDragEnd}
                    onMouseDown={this.handleMouseDown}
                    className={Util.getClassNames(styles.treeNodeContents,
                        isHovered && this.props.editSort ? styles.outLine : null,
                        this.props.editSort ? null : styles.notEditSort,
                        this.props.editLabelText === true && selected === true ? styles.edit : null,
                        this.props.disabled || item.disabled ? styles.disabled : null)}
                    style={{
                        paddingRight: '10px',
                        paddingLeft: item.index ? String((item.index.length) * 10 +
                            (item.visibleCollapsedImage === false ? item.imageUrl || item.icon ? 0 : this.props.type === Type.default ? this.visibleCollapsed_default : this.visibleCollapsed_folder : 0) +
                            (item.checkBoxVisible === false && this.props.checkBox ? this.checkBoxVisible : 0) +
                            ((item.children && item.children.length > 0) || this.props.type === Type.directory ? 0 : this.props.type === Type.default ? this.props.checkBox ? 0 : this.visibleCollapsed_default : (item.imageUrl || item.icon) ? 0 : this.props.checkBox ? 0 : this.visibleCollapsed_folder)) + 'px' : undefined
                    }}
                    data-tree-node-key={item.key}>
                    {this.props.checkBox ? this.getButton((e) => { this.props.onCheckBoxClicked(item) }, item.checked, item.disabled || item.checkBoxDisabled || item.checkBoxVisible === false || item.visible === false, item.checkBoxVisible) : null}
                    {(item.children || []).length > 0 && item.visibleCollapsedImage !== false ? this.getExpander(item.collapsed, item.disabled, (e) => {
                        this.props.onToggleCollapse(item, e);
                    }) : this.props.type === Type.directory ? this.getImageUrl(expanderImage.directory, true, item.disabled, (e) => {
                    }) : <span style={{ paddingLeft: this.props.checkBox ? item.children ? '0px' : this.props.type === Type.default ? '14px' : '0px' : '0px' }}></span>}
                    {item.imageUrl ? this.getImageUrl(this.getImageUrlState(item.imageUrl), item.collapsed, item.disabled, (e) => {
                        this.props.onToggleCollapse(item, e);
                    }) : null}
                    {item.icon ? this.getIcon(this.getIconState(item.icon), item.collapsed, item.disabled, (e) => {
                        this.props.onToggleCollapse(item, e);
                    }) : null}
                    <OBTTooltip
                        // labelText={item.tooltip ? item.tooltip.labelText ? item.tooltip.labelText : '' : ''}
                        // value={item.tooltip && !(this.props.disabled || item.disabled) ? item.tooltip.value ? item.tooltip.value : undefined : false}
                        labelText={
                            item.tooltip && item.tooltip.labelText ? item.tooltip.labelText :
                                this.props.useOverflowTooltip && this.state.isShowOverflowTooltip ? item.labelText : ''
                        }
                        value={this.getTooltipValue(item)}
                        focusValue={false}
                        {...item.tooltip}
                    >
                        <button
                            ref={this.labelTextButtonRef}
                            className={Util.getClassNames(styles.treeLabelText,
                                isHovered && this.props.editSort ? styles.selectedOver : null,
                                this.props.editLabelText === true && selected === true ? styles.edit : null,
                                this.props.disabled || item.disabled ? styles.disabled : null,
                                selected ? styles.focused : styles.noFocused)
                            }
                            onMouseEnter={(e) => this.handleLabelTextMouseEnter(e, item)}
                            onMouseLeave={this.handleLabelTextMouseLeave}
                            onClick={e => { e.stopPropagation(); e.preventDefault() }}
                            onDoubleClick={(e) => item.disabled ? null : this.props.onDoubleClick(item, e)}
                            style={Object.assign(item.disabled !== true ? { ...item.style } : {},
                                {
                                    maxWidth:
                                        this.calculateContentsMaxWidth(item)
                                    // this.props.rootWidth && item.index ? 'calc(' + this.props.rootWidth + 'px - ' + String((item.index.length) * 10 +
                                    // (item.visibleCollapsedImage === false ? item.imageUrl || item.icon ? 0 : this.props.type === Type.default ? this.visibleCollapsed_default : this.visibleCollapsed_folder : 0) +
                                    // (item.checkBoxVisible === false && this.props.checkBox ? this.checkBoxVisible : 0) +
                                    // // (item.icon ? item.icon) +
                                    // (item.children || this.props.type === Type.directory ? 0 : this.props.type === Type.default ? this.props.checkBox ? 0 : this.visibleCollapsed_default : (item.imageUrl || item.icon) ? 0 : this.props.checkBox ? 0 : this.visibleCollapsed_folder)) + 'px - ' + this.props.imagesWidth + 'px)' : undefined
                                })}>

                            {item.labelText} {this.props.childCount === true && item.childCount !== false && item.children && item.children.length > 0 ? '(' + displayCount + ')' : null}
                        </button>
                    </OBTTooltip>
                    <span className={styles.textField} onBlur={e => this.props.onTextFieldBlur()}>
                        {this.props.editLabelText === true && selected === true ? this.props.onGetEditor(item) : undefined}
                    </span>
                    <span className={Util.getClassNames(isHovered && this.props.images && !(this.props.editLabelText === true && selected === true) && !(this.props.disabled || item.disabled) ? styles.editDeleteImage : styles.notEdit)}>
                        {this.props.images ? this.props.images : null}
                    </span>
                </div>
                {children}
            </div>
        );
    }
};