export class ChaningEvent<T> {
    private _events: T[] = [];
    public get events() { return this._events; }

    public addToFirst(event: T) {
        this._events = [event].concat(this._events)   
    }

    public add(event: T) {
        if (!this._events.includes(event)) {
            this._events.push(event);
        }
    }

    public remove(event: T) {
        const index = this._events.indexOf(event);
        if (index >= 0) {
            this._events.splice(index, 0);
        }
    }
    
    public set(event: T | null) {
        this._events = event ? [event] : [];
    }
}
