/**
 * Component Develment Template
 * Luna - Orbit 개발시 템플릿 으로 사용.
 * @version 0.1
 * @author 전주빈
 * @see common.js
 */
import * as React from 'react';
import { CompositeProps, Util, createPropDefinitions, CommonDefinitions } from '../Common';
import { OBTCardListInterface, EmptySetBody } from './OBTCardListInterface';
import { RenderItemEventArgs } from './OBTCardListEvents'
import LUXVirtualBox from 'luna-rocket/LUXVirtualBox';
import OBTCheckBox from '../OBTCheckBox';
import OBTDropDownList from '../OBTDropDownList';
import OBTPagination from '../OBTPagination';
import keycode from 'keycode';
import debounce from 'lodash.debounce';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import plusImage from './Images/plus.png';
import focusedPlusImage from './Images/focusedPlus.png';
import emptyDataSmallImg from '../Images/img_empty_data_s.png';
import emptySearchSmallImg from '../Images/img_empty_search_s.png';
import emptyAddDataSmallImg from '../Images/img_empty_write_s.png';
import { OBTContext, IPageAuthority, IOBTContext } from '../OBTPageContainer/OBTPageContainer';
import { OrbitInternalLangPack } from '../Common/Util';
import { ISideBarContent } from '../OBTPageContainer/SideBar';

/**
 * CSS Modules 사용방식
 * styles.[className]
 * {@code <div className={styles.required}}
 */
const styles = require('./OBTCardList.module.scss');

/**
 * 카드리스 기본 템플릿을 직접 제공해줍니다. (listHeight 고정, onRenderItem 사용 x)
 */
interface ICardListTemplate {
    /**
     * 카드리스트에서 보여지는 main값을 정의하는 속성입니다.
     */
    main: string,
    /**
     * 카드리스트에서 아래쪽 왼쪽에 보여지는 값을 정의하는 속성입니다.
     */
    subLeft?: string,
    /**
     * 카드리스트에서 아래쪽 오른쪽에 보여지는 값을 정의하는 속성입니다.
     */
    subRight?: string,
    /**
     * 카드리스트에서 가장 오른쪽에 보여지는 값을 정의하는 속성입니다.
     */
    right?: string,
    /**
     * 카드리스트 템플릿의 스타일을 설정하는 속성입니다.
     */
    className?: string
}

/**
 * 카드리스 기본 이미지 템플릿을 직접 제공해줍니다. (listHeight 고정, onRenderItem 사용 x)
 */
interface ICardListImageTemplate {
    /**
     * 카드리스트에서 이미지 값을 정의하는 속성입니다.
     */
    imageUrl: string,
    /**
     * 카드리스트에서 보여지는 main값을 정의하는 속성입니다.
     */
    main: string,
    /**
     * 카드리스트에서 아래쪽 왼쪽에 보여지는 값을 정의하는 속성입니다.
     */
    subLeft?: string,
    /**
     * 카드리스트에서 아래쪽 오른쪽에 보여지는 값을 정의하는 속성입니다.
     */
    subRight?: string,
    /**
     * 카드리스트 템플릿의 스타일을 설정하는 속성입니다.
     */
    right?: string,
    /**
     * 카드리스트 템플릿의 스타일을 설정하는 속성입니다.
     */
    className?: string
}

interface ICardListStateTemplate {
    /**
     * 카드리스트에서 보여지는 main값을 정의하는 속성입니다.
     */
    main: string,
    /**
     * 카드리스트에서 아래쪽 왼쪽에 보여지는 값을 정의하는 속성입니다.
     */
    subLeft?: string,
    /**
     * 카드리스트에서 아래쪽 오른쪽에 보여지는 값을 정의하는 속성입니다.
     */
    subRight?: string,
    /**
     * 카드리스트에서 우측 상태 텍스트 값을 정의하는 속성입니다.
     */
    stateLabelText?: string,
    /**
     * 카드리스트에서 우측 상태 배경색을 정의하는 속성입니다.
     */
    stateColor: string,
    /**
     * 카드리스트에서 우측 상태 아이콘을 정의하는 속성입니다.
     */
    stateIcon?: string,
    /**
     * 카드리스트 템플릿의 스타일을 설정하는 속성입니다.
     */
    className?: string
}

interface ICardListCustomTemplate {
    getComponent: (e?: { data: any }) => JSX.Element
}

/**
 * @internal
 * 카드리스 이미지 사이즈 
 */
enum ImageSize {
    'small' = 'small',
    'normal' = 'normal'
}

interface IOBTCardList extends CompositeProps.Default {
    interface: OBTCardListInterface
    pageAuthority?: IPageAuthority,
}

interface State extends hasError {
    interface: OBTCardListInterface,
    renderNotifier: boolean,
    focusedIndex: number,
    keyDownPreFocusedIndex: number,
    headCheked: boolean,
    dropDownValue: string,
    imgSize: ImageSize
}

export default class OBTCardList extends React.Component<IOBTCardList, State> {
    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.frozen(),
        CommonDefinitions.className(),
        CommonDefinitions.height(),
        { name: 'interface', type: 'OBTCardListInterface', description: 'OBTCardListInterface 의 인스턴스' }
    )

    public static defaultProps = {
        frozen: false,
        height: '100%'
    }

    context!: React.ContextType<typeof OBTContext>;

    public state: State = {
        interface: this.props.interface,
        renderNotifier: false,
        focusedIndex: -1,
        keyDownPreFocusedIndex: 0,
        headCheked: false,
        dropDownValue: this.props.interface.dropDownValue === null ? '0' : this.props.interface.dropDownValue,
        imgSize: ImageSize.normal
    }

    public myRefs = {
        wrapper: React.createRef<HTMLDivElement>(),
        virtualBoxWrapper: React.createRef<HTMLDivElement>(),
        virtualBox: React.createRef<LUXVirtualBox>(),
        ul: React.createRef<HTMLUListElement>()
    }

    /**
     * 보여줄 data
     * added x, empty x
     */
    private _data: any[] = [];

    constructor(props) {
        super(props);
        const context = this.context as IOBTContext;

        if (this.props.pageAuthority) {
            if ((context && context.pageAuthority)) {
                this.state.interface._initialize(this, {
                    ...context.pageAuthority,
                    ...this.props.pageAuthority
                });
            } else {
                this.state.interface._initialize(this, this.props.pageAuthority);
            }
        } else if (context && context.pageAuthority) {
            this.state.interface._initialize(this, context.pageAuthority);
        } else {
            this.state.interface._initialize(this);
        }

        this.state.interface.onAfterChangeRowSelection.add((e) => {
            const rowCount = this.state.interface.getRowCount()
            if (rowCount > 0) {
                this.setSideBar(e.rowIndex);
            }
        })
    }

    componentDidMount(): void {
        try {
            if (this.myRefs.wrapper.current !== null) {
                const bound = this.myRefs.wrapper.current.getBoundingClientRect();
                const width = bound.width;
                const height = bound.height;
                let imgSize = this.state.imgSize;
                if (width < 310 || height < 300) {
                    imgSize = ImageSize.small;
                }
                this.setState({
                    imgSize: imgSize
                })
            }
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    componentDidUpdate() {
        //display : none일시 버츄얼박스 높이 못 잡아서 UL 안그려져서 랜더링 하기......
        try {
            if (this.state.interface.data && this.state.interface.data.length > 0) {
                if (this.myRefs.ul.current !== null) {
                    const bound = this.myRefs.ul.current.getBoundingClientRect();
                    const element = document.elementFromPoint(bound.left, bound.top);
                    const isChild = (el) => {
                        if (el) {
                            if (el === this.myRefs.ul.current) return true;
                            if (el.parentElement) {
                                isChild(el.parentElement);
                            }
                        }
                        return false;
                    };
                    if (isChild(element) && this.myRefs.ul.current.childElementCount <= 0) {
                        this.setState(this.state);
                    }
                }
            }
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    private setSideBar(rowIndex: number) {
        const cardListInterface = this.state.interface;
        const willUpdateSideBarContents: ISideBarContent[] = [];
        const selectedCardData = this.state.interface.getRow(rowIndex);
        const reservedColumnNames = this.state.interface.reservedColumnNames;

        if (!cardListInterface) {
            return;
        }

        if (!this.context.setSideBar) {
            return;
        }

        const checkPenValue = this.state.interface.getValue(rowIndex, reservedColumnNames.checkPen);
        if (checkPenValue !== undefined) {
            const sideBarCheckPen = {
                key: 'checkpen',
                visible: this.state.interface.dataAdapter && this.state.interface.dataAdapter.storeCheckPen !== undefined,
                onApply: (selectedColorHex) => {
                    if (!cardListInterface) {
                        return;
                    }

                    if (!selectedColorHex || selectedColorHex.concat().length === 0) {
                        selectedColorHex = "";
                    }
                    cardListInterface.storeCheckPen(cardListInterface.getSelection(), selectedColorHex).then(() => {
                        this.setSideBar(cardListInterface.getSelection());
                    });
                },
                changeApplyCheckPen: (use: boolean) => {
                    if (!cardListInterface || cardListInterface.getRows().length === 0) {
                        return;
                    }
                    cardListInterface.useCheckPen = use;
                    cardListInterface.refresh();
                },
                data: cardListInterface.getValue(rowIndex, reservedColumnNames.checkPen)
            } as ISideBarContent;

            willUpdateSideBarContents.push(sideBarCheckPen);
        }

        const insertIdValue = cardListInterface.getValue(rowIndex, reservedColumnNames.insertUserId)
        if (insertIdValue !== undefined) {
            if (selectedCardData) {
                willUpdateSideBarContents.push({
                    key: 'inputlog',
                    data: {
                        insertId: cardListInterface.getValue(rowIndex, reservedColumnNames.insertUserId),
                        insertDt: cardListInterface.getValue(rowIndex, reservedColumnNames.insertDateTime),
                        insertIp: cardListInterface.getValue(rowIndex, reservedColumnNames.insertIPAddress),
                        modifyId: cardListInterface.getValue(rowIndex, reservedColumnNames.modifyUserId),
                        modifyDt: cardListInterface.getValue(rowIndex, reservedColumnNames.modifyDateTime),
                        modifyIp: cardListInterface.getValue(rowIndex, reservedColumnNames.modifiedIPAddress)
                    },
                    visible: true,
                    rowKey: rowIndex >= 0 ? rowIndex.toString() : undefined
                });
            } else {
                willUpdateSideBarContents.push({
                    key: 'inputlog',
                    visible: false,
                });
            }
        }

        const memoCdValue = cardListInterface.getValue(rowIndex, reservedColumnNames.memoCode);
        if (memoCdValue !== undefined) {
            const sideBarMemo = {
                key: 'memo',
                data: {
                    memoKey: memoCdValue
                },
                memoCategory: this.state.interface.memoCategory,
                readonly: !cardListInterface.dataAdapter!.storeMemo,
                onApply: (payload) => {
                    const targetGrid = cardListInterface;
                    if (!targetGrid) {
                        return;
                    }
                    if (payload.type === 'saveMemo') {
                        const existMemoKey = memoCdValue as string;
                        if (!existMemoKey || existMemoKey.length === 0) {
                            targetGrid.storeMemo(rowIndex, payload.memoKey).then(() => {
                                this.setSideBar(rowIndex);
                            });
                        }
                    } else if (payload.type === 'clearMemo') {
                        targetGrid.storeMemo(rowIndex, null).then(() => {
                            this.setSideBar(rowIndex);
                        });
                    }
                },
                visible: true
            } as ISideBarContent;

            willUpdateSideBarContents.push(sideBarMemo);
        }

        this.context.setSideBar(willUpdateSideBarContents, {
            id: rowIndex.toString(),
            target: cardListInterface
        });
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        );
    }

    renderComponent = () => {
        const _interface = this.state.interface;
        const checkBoxYn = _interface.checkable;
        const headCheckBoxYn = _interface.headerCheckVisible;
        const dropDownList = _interface.sortList;
        const displayType = _interface.displayType;
        const headerClassName = _interface.headerClassName;
        const headerComponent = typeof _interface.headerComponent === 'function' ? _interface.headerComponent() : _interface.headerComponent;
        const appendable = _interface.checkPossibleGridAction('appendable');
        const addButton = _interface.addButton;
        this._data = _interface.data !== null ? _interface.data : [];

        if (_interface.data !== null) {
            this._data = this.getVisibleData(_interface.data);
        }

        _interface._setEmptyBodyImgText(_interface.emptySet!);

        /**
         * 1단계
         * <요소>
         * Item
         * List 안 CheckBox 
         * 1. CheckBox (List에 추가)
         */
        const Item = class Item extends React.Component<{
            dataIndex: number,
            data: any,
            component: any,
            itemHeight: number,
            globalObject: any,
            getLastChecked: () => { index: number, checked: boolean } | undefined | null,
            setLastChecked: (payload: { index: number, checked: boolean }) => void
        }, { checked: SVGAnimatedBoolean }> {
            state = {
                checked: _interface.data![this.props.dataIndex]._checked || false
            }
            static getDerivedStateFromProps(nextProps: {
                dataIndex: number,
                component: any,
                itemHeight: number,
                globalObject: any
            }, prevState: { checked: boolean }): any {
                const checked = _interface.data![nextProps.dataIndex]._checked || false;
                if (checked !== prevState.checked) {
                    return {
                        checked
                    };
                }
                return null;
            }

            private handleItemChecked = (e: any) => {
                const shift = e.event.shiftKey;
                const lastChecked = this.props.getLastChecked();
                if (shift && lastChecked) {
                    const from = lastChecked.index < this.props.dataIndex ? lastChecked.index : this.props.dataIndex;
                    const to = lastChecked.index < this.props.dataIndex ? this.props.dataIndex : lastChecked.index;
                    for (let i = from; i <= to; i++) {
                        _interface._setCheck(i, lastChecked.checked, i === to ? true : false);
                    }
                } else {
                    this.setState({ checked: e.value });
                    _interface._setCheck(this.props.dataIndex, e.value, false);
                    this.props.setLastChecked({ index: this.props.dataIndex, checked: e.value });
                }
            }

            render() {
                const { component, globalObject, dataIndex, itemHeight } = this.props;
                const borderRadius = _interface.borderRadius;
                const itemPadding = _interface.itemPadding;
                const existMemoData = this.props.data[_interface.reservedColumnNames.memoCode]
                const existCheckPenData = this.props.data[_interface.reservedColumnNames.checkPen]
                const disabledCheckProperty = this.props.data[_interface.disabledCheckProperty]

                const propsLiStyle: any = {
                    height: itemHeight,
                    borderBottom: '1px solid #e0e0e0'
                };
                const propsDivStyle: any = {
                    width: '100%',
                    height: '100%',
                    background: globalObject.focusedIndex === dataIndex ? '#e6f5ff' : 'white'
                };
                const propsRadiusStyle: any = {
                    top: '0px',
                    bottom: existCheckPenData ? '-3px' : '0px',
                    right: '0px',
                    left: '0px'
                };

                //템플릿을 사용할 경우 default 카드 리스트 (입체 주기)
                if (_interface.cardListTemplate !== null) {
                    propsDivStyle.height = '';
                    propsDivStyle.width = '';
                    propsLiStyle.borderBottom = '';

                    //입체주기
                    propsLiStyle.padding = '3px 12px';
                    propsDivStyle.top = '3px';
                    propsDivStyle.right = '12px';
                    propsDivStyle.left = '12px';
                    propsDivStyle.bottom = '3px';
                    propsDivStyle.border = '1px solid #e0e0e0';

                    propsRadiusStyle.borderRadius = '3px';
                    propsDivStyle.borderRadius = '3px';

                } else {
                    //사용자 옵션 (카드리스트 템플릿 사용 x), 직접 입체주기
                    if (itemPadding !== null) {
                        propsDivStyle.height = '';
                        propsDivStyle.width = '';
                        if (itemPadding.top !== undefined) {
                            propsDivStyle.top = itemPadding.top;
                            propsDivStyle.borderTop = '1px solid #e0e0e0';
                        }
                        if (itemPadding.right !== undefined) {
                            propsDivStyle.right = itemPadding.right;
                            propsDivStyle.borderRight = '1px solid #e0e0e0'
                        }
                        if (itemPadding.bottom !== undefined) {
                            //페딩 바텀있을경우 지워줘야함...... (default는 있으므로)
                            propsLiStyle.borderBottom = '';
                            propsDivStyle.bottom = itemPadding.bottom;
                            propsDivStyle.borderBottom = '1px solid #e0e0e0'
                        }
                        if (itemPadding.left !== undefined) {
                            propsDivStyle.left = itemPadding.left;
                            propsDivStyle.borderLeft = '1px solid #e0e0e0';
                        }
                    }
                    if (borderRadius !== null) {
                        propsRadiusStyle.borderRadius = borderRadius;
                        propsDivStyle.borderRadius = borderRadius;
                    }
                }

                if (_interface.useCheckPen === true && this.props.data[_interface.reservedColumnNames.checkPen]) {
                    propsDivStyle.borderBottom = `3px solid ${this.props.data[_interface.reservedColumnNames.checkPen]}`
                }

                //div select된 div 스타일 제공
                const listStyle: any = {
                    style: propsDivStyle
                }
                if (globalObject.focusedIndex === dataIndex) listStyle.className = 'selectedList'
                return <li
                    className={styles.li_CardList}
                    onFocus={(event) => globalObject.onFocusItem(event, dataIndex)}
                    onKeyDown={(event) => globalObject.onKeyDown(event)}
                    onMouseDown={(event) => {
                        globalObject.onItemClick(event, dataIndex)
                    }}
                    onDoubleClick={(event) => globalObject.onItemDoubleClick(event, dataIndex)}
                    tabIndex={-1}
                    id={'virtual' + (dataIndex)}
                    style={{ ...propsLiStyle }}
                >
                    <div {...listStyle}>
                        {existMemoData ?
                            <svg viewBox="0 0 50 50" height="10px" className={styles.memo}>
                                <path d="M1 50 V10 Q1 1 10 1 H50z" fill="#2400f0" />
                            </svg>
                            : null}
                        {globalObject.focusedIndex === dataIndex ?
                            <div className={Util.getClassNames(styles.selectedCardList, 'selectedBorder')} style={{ ...propsRadiusStyle }}></div>
                            : null}
                        {checkBoxYn ?
                            <div className={styles.checkstyle} tabIndex={-1} onFocus={(e) => { e.stopPropagation(); }}>
                                <OBTCheckBox
                                    value={this.state.checked}
                                    onChange={this.handleItemChecked}
                                    disabled={disabledCheckProperty ? true : false} />
                            </div>
                            : null}
                        {component}
                    </div>
                </li >
            }
        }

        /**
         * 2단계
         * <요쇼>
         * listComponent
         * template 및 render List Component
         * 1. CardListTamlete 템플릿 설정 
         * 2. Template설정 및 render 선택 
         */
        const listComponent = class extends React.Component<{
            virtual: any,
            itemHeight: number,
            globalObject: any,
        }, {}> {
            /** 
             * @internal
             * CardListTemlate 템플릿 설정 
             * CardListTemlate : default
             * CardListImageTemlate : image사용
             * div Tamplete 함수 (default, image)            
             */
            private cardListTemlate(data: ICardListTemplate): any {
                const templateDefault = <>
                    <div className={styles.template1}>
                        <div className={styles.template2}>
                            <div>
                                <div className={styles.template3}>{data.main}</div>
                                {data.subLeft !== undefined ? <div className={styles.template4}>
                                    {[data.subLeft || '', data.subRight || ''].filter(item => item.length > 0).join('/')}
                                </div> : null}
                            </div>
                        </div>
                        {data.right !== undefined ? <div className={styles.template5}>{data.right}</div> : null}
                    </div>
                </>
                const template = data.className !== undefined ? <div className={data.className}>{templateDefault}</div> : templateDefault
                return template;
            };

            /**
             * @internal
             * @param data 
             * @param align 
             * @param className 
             */
            private cardListImageTemlate(data: ICardListImageTemplate, align: string, className?: string): any {
                const templateDefault = <>
                    <div className={styles.template1}>
                        {align === 'imageLeft' ? <div className={styles.imageLeft}> <img src={data.imageUrl} alt={''}></img>
                        </div> : null}
                        <div className={styles.template2}>
                            <div>
                                <div className={styles.template3}>{data.main}</div>
                                {data.subLeft !== undefined ? <div className={styles.template4}>
                                    {[data.subLeft || '', data.subRight || ''].filter(item => item.length > 0).join('/')}
                                </div> : null}
                            </div>
                        </div>
                        {align === 'imageRight' ? <div className={styles.imageRight}> <img src={data.imageUrl} alt={''}></img>
                        </div> : data.right !== undefined ? <div className={styles.template5}>{data.right}</div> : null}
                    </div>
                </>
                const template = className !== undefined ? <div className={className}>{templateDefault}</div> : templateDefault
                return template;
            };

            /**
             * @internal
             * @param data 
             * @param align 
             * @param className 
             */
            private cardListStateTemlate(data: ICardListStateTemplate, templateName: string): any {
                const templateDefault = <>
                    <div className={styles.template1}>
                        <div className={styles.template2}>
                            <div>
                                <div className={styles.template3}>{data.main}</div>
                                {data.subLeft !== undefined ? <div className={styles.template4}>
                                    {[data.subLeft || '', data.subRight || ''].filter(item => item.length > 0).join('/')}
                                </div> : null}
                            </div>
                        </div>
                        <div className={styles.template6}>
                            {templateName === OBTCardListInterface.Template.stateIcon ?
                                <>
                                    <img src={data.stateIcon} alt={''} />
                                    <span className={styles.template8} style={{ color: data.stateColor }}>{data.stateLabelText}</span>
                                </> :
                                <>
                                    <div className={styles.template7} style={{ backgroundColor: data.stateColor }}>
                                        {data.stateLabelText}
                                    </div>
                                </>}
                        </div>
                    </div>
                </>
                const template = data.className !== undefined ? <div className={data.className}>{templateDefault}</div> : templateDefault
                return template;
            };

            /**
             * @internal
             */
            private cardListCustomTemplate(template: ICardListCustomTemplate, data: any): JSX.Element {
                return template.getComponent({
                    data: data
                });
            }

            /**
             * @internal
             * @param template 
             * @param data 
             */
            private cardList(template: any, data: any): any {
                const getValue = (fieldName?: (string) | ((data: any) => string)) => {
                    if (fieldName) {
                        if (typeof fieldName === 'string')
                            return data[fieldName];
                        else return fieldName(data);
                    }
                    return undefined;
                }
                let component: any;
                if (template.template === 'default') {
                    component = this.cardListTemlate({
                        main: getValue(template.main),
                        subLeft: getValue(template.subLeft),
                        subRight: getValue(template.subRight),
                        right: getValue(template.right),
                        className: getValue(template.className)
                    });
                } else if (template.template === 'imageLeft' || template.template === 'imageRight') {
                    component = this.cardListImageTemlate({
                        imageUrl: getValue(template.imageUrl),
                        main: getValue(template.main),
                        subLeft: getValue(template.subLeft),
                        subRight: getValue(template.subRight),
                        right: getValue(template.right),
                        className: getValue(template.className)
                    }, template.template);
                } else if (template.template === OBTCardListInterface.Template.stateIcon || template.template === OBTCardListInterface.Template.stateLabel) {
                    const state = typeof template.state === 'function' ? template.state(data) : undefined;
                    component = this.cardListStateTemlate({
                        main: getValue(template.main),
                        subLeft: getValue(template.subLeft),
                        subRight: getValue(template.subRight),
                        stateIcon: OBTCardListInterface.Template.stateIcon ? getValue(template.stateIcon) : undefined,
                        stateColor: getValue(template.stateColor),
                        stateLabelText: getValue(template.stateLabelText),
                        className: getValue(template.className),
                        ...state
                    }, template.template);
                } else if (template.template === OBTCardListInterface.Template.custom) {
                    component = this.cardListCustomTemplate(template as ICardListCustomTemplate, data);
                }
                return component;
            }

            private lastChecked?: {
                index: number,
                checked: boolean
            };

            render() {
                const { virtual, itemHeight, globalObject } = this.props;
                return (
                    <ul ref={globalObject.ul}
                        style={Object.assign({}, virtual.style, { zIndex: '9999' })}>
                        {virtual.items.map((data, index) => {
                            const dataIndex = virtual.firstItemIndex + index;
                            //템플릿 있으면 콜백처리 x
                            //텔플릿 없으면 render 콜백
                            let component: any;
                            if (_interface.cardListTemplate !== null) {
                                component = this.cardList(_interface.cardListTemplate, data!);
                            } else {
                                const e = new RenderItemEventArgs(this, dataIndex, data);
                                if (_interface.onRenderItem) _interface.onRenderItem(e);  //콜백
                                component = e.component;
                            }
                            return <Item
                                key={dataIndex}
                                data={data}
                                dataIndex={dataIndex}
                                component={component}
                                itemHeight={itemHeight}
                                globalObject={globalObject}
                                getLastChecked={() => this.lastChecked}
                                setLastChecked={(payload) => this.lastChecked = payload}
                            />
                        })}
                    </ul>
                )
            }
        }

        /**
         * 3단계
         * <요쇼>
         * virtualCardList
         * 버츄얼 카드리스트 Component
         * listHeight: list 높이
         */
        const listHeight = this.state.interface.listHeight;
        const virtualCardList =
            <div ref={this.myRefs.virtualBoxWrapper} className={styles.virtualCardList} style={{ borderBottom: appendable === true ? '1px solid #ccc' : '' }}>
                <div>
                    <LUXVirtualBox
                        ref={this.myRefs.virtualBox}
                        rootStyle={{ width: '100%', height: '100%', overflow: 'hidden' }}
                        listComponent={listComponent}
                        itemCount={this._data ? this._data.length : 0}
                        items={this._data || []}
                        itemHeight={listHeight}
                        globalObject={{
                            focusedIndex: this.state.focusedIndex,
                            onFocusItem: this.handleFocusItem,
                            onKeyDown: this.handleKeyDown,
                            onItemClick: this.handleItemClick,
                            onItemDoubleClick: this.handleItemDoubleClick,
                            ul: this.myRefs.ul
                        }}
                    >
                    </LUXVirtualBox >
                </div>
            </div>

        /**
         * 4단계
         * <요쇼>
         * appendComponent
         * appendComponent 추가 Component
         */
        const appendProps: any = {
            id: 'obtAppendButton',
            className: Util.getClassNames(styles.addRow),
            style: {
                height: 64,
                border: this.state.focusedIndex === this._data.length ? '1px solid #1c90fb' : '#ccc',
                background: this.state.focusedIndex === this._data.length ? '#e6f5ff' : '',
                color: this.state.focusedIndex === this._data.length ? '#1c90fb' : '#8d8d8d',
                boxSizing: 'border-box',
            },
            onClick: this.handleClickButton
        };

        let appendComponent: any;
        if (addButton !== null) {
            if (addButton.appendComponent !== undefined) {
                //사용자 제공
                appendComponent = <div {...appendProps}>
                    {addButton.appendComponent}
                </div>;
            } else {
                appendComponent = <div {...appendProps}>
                    <img src={addButton.imageUrl !== undefined ? addButton.imageUrl : plusImage} alt={''}></img>
                    <span>{addButton.labelText !== undefined ? addButton.labelText : OrbitInternalLangPack.getText('WE000000263', '추가')}</span>
                </div>
            }
        } else {
            appendComponent = <div {...appendProps}>
                <img src={this.state.focusedIndex === this._data.length ? focusedPlusImage : plusImage} alt={''}></img>
                <span>{OrbitInternalLangPack.getText('WE000000263', '추가')}</span>
            </div>
        }

        /**
         * 마지막단계
         * <종합>
         * cardList
         * 카드리스트 Component 
         */
        const headHeight = headCheckBoxYn === true || dropDownList !== null || this.props.interface.paging === true ? '27px' : '9px';
        const headBorderBottom = _interface.cardListTemplate !== null || _interface.itemPadding !== null ? '' : '1px solid #e9e9e9'
        const isOnlyHeaderComponent = headerComponent && !_interface.sortList && !_interface.headerCheckVisible && !_interface.paging;
        const headLineStyle: any = {
            left: '13px',
            width: 'calc(100% - 26px)'
        }
        const headLine = headCheckBoxYn === true || dropDownList !== null ?
            <div className={styles.headLine}>
                <div style={headLineStyle}></div>
            </div> : <div className={'headLine'}>
                <div style={headLineStyle}></div>
            </div>;

        const cardList = <div ref={this.myRefs.wrapper} className={styles.root}>
            <div
                className={Util.getClassNames(styles.sortstyle, headerClassName, isOnlyHeaderComponent && styles.isOnlyHeaderComponent)}
                style={{ height: headHeight, borderBottom: headBorderBottom }}
            >
                {headCheckBoxYn ? <OBTCheckBox
                    className={styles.headerCheckBox}
                    value={this.state.headCheked}
                    onChange={this.handleItemAllChecked}
                /> : null}
                <div className={styles.sortSpace}>
                    {headerComponent}
                </div>
                {dropDownList ?
                    <OBTDropDownList
                        boxStyle={{ border: '1px solid #cccccc', boxShadow: 'none' }}
                        className={styles.dropdownlist}
                        list={dropDownList!}
                        value={this.state.dropDownValue}
                        displayType={displayType!}
                        onChange={this.handleDropListChange}
                    >
                    </OBTDropDownList> : null}
            </div>
            {headLine}
            {this._data.length <= 0 ? this.renderEmptyMessage() : virtualCardList}
            {appendable === true ? appendComponent : null}
        </div >

        /**
         * 마지막단계
         * <종합>
         * paging
         * 페이징 처리 Component
         */
        let pabingClassName = styles.pagination;
        if (_interface.headerCheckVisible === true) {
            pabingClassName = styles.paginationCheck;
        }
        const paging = _interface.paging && _interface.pageCount > 0 ?
            <OBTPagination
                className={Util.getClassNames(pabingClassName, this.state.interface.headerComponent ? styles.noHeader : undefined)}
                currentPage={this.state.interface.currentPage}
                pageCount={this.state.interface.pageCount}
                rowCountPerPage={this.state.interface.rowCountPerPage!}
                totalCount={this.state.interface.totalCount}
                onChange={this.handlePageChange}
                pagingBlock={5} /> : null;

        return (
            <div id={this.props.id}
                className={Util.getClassNames(styles.head, this.props.className)}
                style={Util.getWrapperStyle(this.props)} data-orbit-component='OBTCardList'
                onFocus={() => {
                    if (this.context && this.context.setLastFocusedGrid) {
                        this.context.setLastFocusedGrid(this.state.interface);
                    }
                }}
            >
                {cardList}
                {paging}
            </div >
        )
    }

    /**
     * @internal
     * 데이터 없을시 
     * 1. 데이터 존재 x 
     * 2. 추가버튼시 데이터 존재x
     * 3. 검색시 데이터 존재x
     * message  
     */
    private renderEmptyMessage() {
        const imgSize = this.state.imgSize;
        const emptyDataSet = this.state.interface.emptySet;
        const userImgText = this.state.interface.emptyImgText;
        let img = emptyDataSet === EmptySetBody.emptyData ? emptyDataSmallImg :
            emptyDataSet === EmptySetBody.emptyWrite ? emptyAddDataSmallImg :
                emptySearchSmallImg;
        let text = emptyDataSet === EmptySetBody.emptyData ? OrbitInternalLangPack.getText('WE000015777', '데이터가 존재하지 않습니다.') :
            emptyDataSet === EmptySetBody.emptyWrite ? OrbitInternalLangPack.getText('WE000022704', '[추가]버튼을 눌러 상세 내용을 입력해 주세요.') :
                OrbitInternalLangPack.getText('WE000006451', '검색 결과가 없습니다.');
        let emptyImgText = {
            imgClassName: styles.emptyImg,
            textClassName: styles.emptyText,
            img: img,
            text: text
        }

        if (userImgText !== null) {
            emptyImgText.img = userImgText.image;
            emptyImgText.text = userImgText.msg;
        } else {
            if (imgSize === ImageSize.small) {
                emptyImgText.img = emptyDataSet === EmptySetBody.emptyData ? emptyDataSmallImg :
                    emptyDataSet === EmptySetBody.emptyWrite ? emptyAddDataSmallImg :
                        emptySearchSmallImg;
            }
        }

        if (imgSize === ImageSize.small) {
            emptyImgText.imgClassName = styles.emptySmallImg;
            emptyImgText.textClassName = styles.emptySmallText;
        }

        return (
            <div style={{ position: 'relative', width: "100%", height: "100%" }}>
                <div className={styles.emptyData}>
                    <img className={emptyImgText.imgClassName} src={emptyImgText.img} alt={''}></img>
                    <div className={emptyImgText.textClassName}> {emptyImgText.text}</div>
                </div>
            </div>
        );
    }
    /**
     * @internal
     * 추가버튼생성되서 added, empty 화면안보이게 함수
     */
    private getVisibleData(originData: any[]): any[] {
        let changeData: any[] = [];
        if (originData !== null) {
            changeData = originData!.filter(function (data) {
                return !(data._state === 'empty' || data._state === 'added');
            })
        }
        return changeData
    }

    /**
     * @internal
     * Luna - Rocket 의 handleFoucusItem 이벤트 핸들러를 받아 재호출
     * @param {number} dataIndex
     * setSeletcion 
     */
    private handleFocusItem = (event, dataIndex: number) => {
        const preIndex = this.state.focusedIndex; //이전값
        this.state.interface._handleSelection(dataIndex, preIndex);
    }

    /**
     * @internal
     * Luna - Rocket 의 handleKeyDown 이벤트 핸들러를 받아 재호출
     * @param {number} dataIndex
     * 키업다운 핸들러 
     * 계속 누루고있을시 1.index 2.스크롤 3.포커스 관리
     */
    private handleKeyDown = e => {
        switch (keycode(e)) {
            case 'down':
                this.handleKeyDown_Down();
                e.preventDefault();
                e.stopPropagation();
                break;
            case 'up':
                this.handleKeyDown_Up();
                e.preventDefault();
                e.stopPropagation();
                break;
            case 'page down':
                this.handleKeyDown_PageDown();
                e.preventDefault();
                e.stopPropagation();
                break;
            case 'page up':
                this.handleKeyDown_PageUp();
                e.preventDefault();
                e.stopPropagation();
                break;
            default:
                break;
        }
    }

    private handleKeyDown_Down = debounce(() => {
        const getFocusIndex = this.state.interface.getSelection();
        const total = this._data;
        if (total) {
            if (getFocusIndex! < total!.length - 1) {
                this.setState({ focusedIndex: getFocusIndex }, () => {
                    this.state.interface._handleSelection(getFocusIndex! + 1, getFocusIndex, true);
                });
            }
        }
    }, 10);
    
    private handleKeyDown_Up = debounce(() => {
        const getFocusIndex = this.state.interface.getSelection();
        const total = this._data;
        if (total) {
            if (getFocusIndex! > 0) {
                this.setState({ focusedIndex: getFocusIndex }, () => {
                    this.state.interface._handleSelection(getFocusIndex! - 1, getFocusIndex, true);
                });
            }
        }
    }, 10);

    private handleKeyDown_PageDown = debounce(() => {
        if (this.myRefs.virtualBoxWrapper.current) {
            const itemHeight = this.state.interface.listHeight ? this.state.interface.listHeight : 70;
            const wrapperHeight = this.myRefs.virtualBoxWrapper.current.getBoundingClientRect().height;
            const virtualItemCount = Math.floor(wrapperHeight / itemHeight) - 1;
            const getFocusIndex = this.state.interface.getSelection();
            const total = this._data;

            if (total) {
                const lastIndex = total.length - 1;
                if (getFocusIndex + virtualItemCount < lastIndex) {
                    this.setState({ focusedIndex: getFocusIndex }, () => {
                        this.state.interface._handleSelection(getFocusIndex + virtualItemCount, getFocusIndex, true);
                    });
                }
                else {
                    this.setState({ focusedIndex: lastIndex }, () => {
                        this.state.interface._handleSelection(lastIndex, getFocusIndex, true);
                    })
                }
            }
        }
    }, 10);
    
    private handleKeyDown_PageUp = debounce(() => {
        if (this.myRefs.virtualBoxWrapper.current) {
            const itemHeight = this.state.interface.listHeight ? this.state.interface.listHeight : 70;
            const wrapperHeight = this.myRefs.virtualBoxWrapper.current.getBoundingClientRect().height;
            const virtualItemCount = Math.floor(wrapperHeight / itemHeight) - 1;
            const getFocusIndex = this.state.interface.getSelection();
            const total = this._data;
            
            if (total) {
                if (getFocusIndex - virtualItemCount < 0) {
                    this.setState({ focusedIndex: 0 }, () => {
                        this.state.interface._handleSelection(0, getFocusIndex, true);
                    });
                }
                else {
                    this.setState({ focusedIndex: getFocusIndex }, () => {
                        this.state.interface._handleSelection(getFocusIndex - virtualItemCount, getFocusIndex, true);
                    });
                }
            }
        }
    }, 10);

    private handleItemClick = (e: any, rowIndex: number) => {
        this.state.interface._handleItemClick(rowIndex);
    }

    private handleItemDoubleClick = (e: any, rowIndex: number) => {
        this.state.interface._handleItemDoubleClick(rowIndex);
    }

    /**
    * @internal
     * Luna - Rocket 의 onMoveFocus 이벤트 핸들러를 받아 재호출
     * @param {string} value
     * DropDownList 정렬 헨들러
     */
    private handleDropListChange = e => {
        this.state.interface._setSort(e.value);
    }

    /**
     *  @internal
     * Luna - Rocket 의 onMoveFocus 이벤트 핸들러를 받아 재호출
     * @param {boolean} value
     * Header Check 헨들러
     */
    private handleItemAllChecked = e => {
        this.state.interface._setHeadCheck(e.value);
    }

    /**
     * @internal
     * page 체인지 이벤트
     */
    private handlePageChange = e => {
        this.state.interface.readData(undefined, e.value.currentPage, e.value.rowCountPerPage, false)
    }

    /**
     * @internal
     * addButton 추가 이벤트
     */
    private handleClickButton = () => {
        const data = this._data;
        this.state.interface._handleSelection(data.length, this.state.focusedIndex);
    }
};

OBTCardList.contextType = OBTContext;