import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";

class MainIntroMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menuActive: false,
        };
    }

    // 메뉴 토글
    handleMenuActiveToggle = () => {
        this.state.menuActive ? this.setState({ menuActive: false }) : this.setState({ menuActive: true });
    };

    handleClose = () => {
        this.setState({ menuActive: false });
    };

    render() {

        return (
            <Fragment>

                <div className={`nav home ${this.state.menuActive ? 'active' : ''}`}>
                    <div className="dim"></div>

                    {/* 전체메뉴 아이콘 */}
                    <span className="menu-trigger" onClick={this.handleMenuActiveToggle}>
                        <span></span><span></span><span></span>
                    </span>

                    {/* nav_in_wrap */}
                    <div className="nav_in_wrap animated1s fadeIn delay05s">
                        {/* 고정메뉴 */}
                        <div className="nav_hold">
                            <ul>
                                <Link to='/Documents/UpdateDocuments'><li><span onClick={this.handleClose} className="menuLink">Update History</span></li></Link>
                                <li><span className="menuLink" onClick={() => {
                                    window.open('http://wiki.duzon.com:8080/pages/viewpage.action?pageId=40726978', 'blank');
                                }}>WIKI(문의/요청)</span></li>
                            </ul>
                        </div>
                        {/* 스크롤메뉴 */}
                        <div className="nav_scroll">
                            <div className="box">
                                <p>Component</p>
                                <div className="unitWrap">
                                    <ul>
                                        <li><b>CONTAINER</b></li>
                                        <Link to='/Documents/OBTAutoValueBinder'> <li><span onClick={this.handleClose} className="menuLink">OBTAutoValueBinder</span></li></Link>
                                        <Link to='/Documents/OBTPageContainer'><li><span onClick={this.handleClose} className="menuLink">OBTPageContainer</span></li></Link>
                                        <Link to='/Documents/OBTDockPanel'><li><span onClick={this.handleClose} className="menuLink">OBTDockPanel</span></li></Link>
                                        <Link to='/Documents/OBTFormPanel'><li><span onClick={this.handleClose} className="menuLink">OBTFormPanel</span></li></Link>
                                        <Link to='/Documents/OBTConditionPanel'><li><span onClick={this.handleClose} className="menuLink">OBTConditionPanel</span></li></Link>
                                        <Link to='/Documents/OBTFloatingPanel'> <li><span onClick={this.handleClose} className="menuLink">OBTFloatingPanel</span></li></Link>
                                        <Link to='/Documents/OBTReferencePanel'> <li><span onClick={this.handleClose} className="menuLink">OBTReferencePanel</span></li></Link>
                                        <Link to='/Documents/OBTPopupPanel'> <li><span onClick={this.handleClose} className="menuLink">OBTPopupPanel</span></li></Link>
                                        <Link to='/Documents/OBTDialog'> <li><span onClick={this.handleClose} className="menuLink">OBTDialog</span></li></Link>
                                        <Link to='/Documents/OBTDialog2'> <li><span onClick={this.handleClose} className="menuLink">OBTDialog2</span></li></Link>
                                        <Link to='/Documents/OBTExcelFormDialog'> <li><span onClick={this.handleClose} className="menuLink">OBTExcelFormDialog</span></li></Link>
                                        <Link to='/Documents/OBTAccordion'> <li><span onClick={this.handleClose} className="menuLink">OBTAccordion</span></li></Link>
                                        <Link to='/Documents/OBTAccordion2'> <li><span onClick={this.handleClose} className="menuLink">OBTAccordion2</span></li></Link>
                                        <Link to='/Documents/OBTTab'> <li><span onClick={this.handleClose} className="menuLink">OBTTab</span></li></Link>
                                    </ul>
                                </div>
                                <div className="unitWrap">
                                    <ul>
                                        <li><b>INPUT</b></li>
                                        <Link to='/Documents/OBTTextField'>  <li><span onClick={this.handleClose} className="menuLink">OBTTextField</span></li></Link>
                                        <Link to='/Documents/OBTMultiLineTextField'>  <li><span onClick={this.handleClose} className="menuLink">OBTMultiLineTextField</span></li></Link>
                                        <Link to='/Documents/OBTNumberField'>  <li><span onClick={this.handleClose} className="menuLink">OBTNumberField</span></li></Link>
                                        <Link to='/Documents/OBTMaskedTextField'>  <li><span onClick={this.handleClose} className="menuLink">OBTMaskedTextField</span></li></Link>
                                        <Link to='/Documents/OBTComplete'>  <li><span onClick={this.handleClose} className="menuLink">OBTComplete</span></li></Link>
                                        <Link to='/Documents/OBTComplete2'>  <li><span onClick={this.handleClose} className="menuLink">OBTComplete2</span></li></Link>
                                        <Link to='/Documents/OBTAutoCompleteChips'>  <li><span onClick={this.handleClose} className="menuLink">OBTAutoCompleteChips</span></li></Link>
                                        <Link to='/Documents/OBTDropDownList'>  <li><span onClick={this.handleClose} className="menuLink">OBTDropDownList</span></li></Link>
                                        <Link to='/Documents/OBTDropDownList2'>  <li><span onClick={this.handleClose} className="menuLink">OBTDropDownList2</span></li></Link>
                                        <Link to='/Documents/OBTMultiDropDownList'>  <li><span onClick={this.handleClose} className="menuLink">OBTMultiDropDownList</span></li></Link>
                                        <Link to='/Documents/OBTButton'>  <li><span onClick={this.handleClose} className="menuLink">OBTButton</span></li></Link>
                                        <Link to='/Documents/OBTButtonGroup'>  <li><span onClick={this.handleClose} className="menuLink">OBTButtonGroup</span></li></Link>
                                        <Link to='/Documents/OBTSplitButton'>  <li><span onClick={this.handleClose} className="menuLink">OBTSplitButton</span></li></Link>
                                        <Link to='/Documents/OBTRadioButton'>  <li><span onClick={this.handleClose} className="menuLink">OBTRadioButton</span></li></Link>
                                        <Link to='/Documents/OBTCheckBox'>  <li><span onClick={this.handleClose} className="menuLink">OBTCheckBox</span></li></Link>
                                        <Link to='/Documents/OBTCheckBox2'>  <li><span onClick={this.handleClose} className="menuLink">OBTCheckBox2</span></li></Link>
                                        <Link to='/Documents/OBTPreviousNextSelector'>  <li><span onClick={this.handleClose} className="menuLink">OBTPreviousNextSelector</span></li></Link>
                                        <Link to='/Documents/OBTGroupSelector'>  <li><span onClick={this.handleClose} className="menuLink">OBTGroupSelector</span></li></Link>
                                        <Link to='/Documents/OBTGroupSelector2'>  <li><span onClick={this.handleClose} className="menuLink">OBTGroupSelector2</span></li></Link>
                                        <Link to='/Documents/OBTCodePicker'>  <li><span onClick={this.handleClose} className="menuLink">OBTCodePicker</span></li></Link>
                                        <Link to='/Documents/OBTTimePicker'>  <li><span onClick={this.handleClose} className="menuLink">OBTTimePicker</span></li></Link>
                                        <Link to='/Documents/OBTDatePicker'>  <li><span onClick={this.handleClose} className="menuLink">OBTDatePicker</span></li></Link>
                                        <Link to='/Documents/OBTSingleDatePicker'>  <li><span onClick={this.handleClose} className="menuLink">OBTSingleDatePicker</span></li></Link>
                                        <Link to='/Documents/OBTSingleYearPicker'>  <li><span onClick={this.handleClose} className="menuLink">OBTSingleYearPicker</span></li></Link>
                                        <Link to='/Documents/OBTSingleYearMonthPicker'>  <li><span onClick={this.handleClose} className="menuLink">OBTSingleYearMonthPicker</span></li></Link>
                                        <Link to='/Documents/OBTDatePeriodPicker'>  <li><span onClick={this.handleClose} className="menuLink">OBTDatePeriodPicker</span></li></Link>
                                        <Link to='/Documents/OBTYearMonthPeriodPicker'>  <li><span onClick={this.handleClose} className="menuLink">OBTYearMonthPeriodPicker</span></li></Link>
                                    </ul>
                                </div>
                                <div className="unitWrap">
                                    <ul>
                                        <li><b>DATA</b></li>
                                        <Link to='/Documents/OBTDataGrid'>   <li><span onClick={this.handleClose} className="menuLink">OBTDataGrid</span></li></Link>
                                        <Link to='/Documents/OBTDataGrid2'>   <li><span onClick={this.handleClose} className="menuLink">OBTDataGrid2</span></li></Link>
                                        <Link to='/Documents/OBTListGrid'>   <li><span onClick={this.handleClose} className="menuLink">OBTListGrid</span></li></Link>
                                        <Link to='/Documents/OBTCardList'>   <li><span onClick={this.handleClose} className="menuLink">OBTCardList</span></li></Link>
                                        <Link to='/Documents/OBTChip'>   <li><span onClick={this.handleClose} className="menuLink">OBTChip</span></li></Link>
                                        <Link to='/Documents/OBTTreeView'>   <li><span onClick={this.handleClose} className="menuLink">OBTTreeView</span></li></Link>
                                    </ul>
                                    <ul>
                                        <li><b>ETC</b></li>
                                        <Link to='/Documents/OBTTitle'>  <li><span onClick={this.handleClose} className="menuLink">OBTTitle</span></li></Link>
                                        <Link to='/Documents/OBTToolTip'> <li><span onClick={this.handleClose} className="menuLink">OBTToolTip</span></li></Link>
                                        <Link to='/Documents/OBTSnackbar'> <li><span onClick={this.handleClose} className="menuLink">OBTSnackbar</span></li></Link>
                                        <Link to='/Documents/OBTCalendar'> <li><span onClick={this.handleClose} className="menuLink">OBTCalendar</span></li></Link>
                                        <Link to='/Documents/OBTScrollbar'> <li><span onClick={this.handleClose} className="menuLink">OBTScrollbar</span></li></Link>
                                        <Link to='/Documents/OBTFileView'> <li><span onClick={this.handleClose} className="menuLink">OBTFileView</span></li></Link>
                                        <Link to='/Documents/OBTColorPicker'> <li><span onClick={this.handleClose} className="menuLink">OBTColorPicker</span></li></Link>
                                        <Link to='/Documents/OBTLoading'> <li><span onClick={this.handleClose} className="menuLink">OBTLoading</span></li></Link>
                                        <Link to='/Documents/OBTProgress'> <li><span onClick={this.handleClose} className="menuLink">OBTProgress</span></li></Link>
                                        <Link to='/Documents/OBTLinearProgress'> <li><span onClick={this.handleClose} className="menuLink">OBTLinearProgress</span></li></Link>
                                        <Link to='/Documents/OBTProgressDialog'> <li><span onClick={this.handleClose} className="menuLink">OBTProgressDialog</span></li></Link>
                                        <Link to='/Documents/OBTAlert'> <li><span onClick={this.handleClose} className="menuLink">OBTAlert</span></li></Link>
                                        <Link to='/Documents/OBTConfirm'> <li><span onClick={this.handleClose} className="menuLink">OBTConfirm</span></li></Link>
                                    </ul>
                                    <ul>
                                        <li><b>CHART</b></li>
                                        <Link to='/Documents/ECharts'><li><span onClick={this.handleClose} className="menuLink">ECharts</span></li></Link>
                                    </ul>
                                </div>
                                <div className="unitWrap">
                                    <ul>
                                        <li><b>Example</b></li>
                                        <Link to='/Documents/ExampleCardListSideBar'><li><span onClick={this.handleClose} className="menuLink">카드리스트 사이드바 예제</span></li></Link>
                                    </ul>
                                    <ul>
                                        <li><b>TEMPLATE</b></li>
                                        <Link to='/Documents/TemplateDefault'>  <li><span onClick={this.handleClose} className="menuLink">TemplateDefault</span></li></Link>
                                        <Link to='/Documents/TemplateCardListForm'>  <li><span onClick={this.handleClose} className="menuLink">TemplateCardListForm</span></li></Link>
                                        <Link to='/Documents/TemplateTab'>  <li><span onClick={this.handleClose} className="menuLink">TemplateTab</span></li></Link>
                                        <Link to='/Documents/TemplateCardList'>  <li><span onClick={this.handleClose} className="menuLink">TemplateCardList</span></li></Link>
                                        <Link to='/Documents/TemplateHeaderDetail'>  <li><span onClick={this.handleClose} className="menuLink">TemplateHeaderDetail</span></li></Link>
                                    </ul>

                                    <ul>
                                        <li><b>MULTILINGUAL</b></li>
                                        <Link to='/Documents/MultilingualGridForm'> <li><span onClick={this.handleClose} className="menuLink">MultilingualGridForm</span></li></Link>
                                        <Link to='/Documents/MultilingualCardlistForm'> <li><span onClick={this.handleClose} className="menuLink">MultilingualCardlistForm</span></li></Link>
                                    </ul>
                                </div>
                                <div className="unitWrap">
                                    <ul>
                                        <li><b>UC TEMPLATE</b></li>
                                        <Link to='/Documents/TemplateFM01'>  <li><span onClick={this.handleClose} className="menuLink">TemplateFM01</span></li></Link>
                                        <Link to='/Documents/TemplateGR01'>  <li><span onClick={this.handleClose} className="menuLink">TemplateGR01</span></li></Link>
                                        <Link to='/Documents/TemplateGR02'>  <li><span onClick={this.handleClose} className="menuLink">TemplateGR02</span></li></Link>
                                        <Link to='/Documents/TemplateGR03'>  <li><span onClick={this.handleClose} className="menuLink">TemplateGR03</span></li></Link>
                                        <Link to='/Documents/TemplateGR04'>  <li><span onClick={this.handleClose} className="menuLink">TemplateGR04</span></li></Link>
                                        <Link to='/Documents/TemplateGR05'>  <li><span onClick={this.handleClose} className="menuLink">TemplateGR05</span></li></Link>
                                        <Link to='/Documents/TemplateTR02'>  <li><span onClick={this.handleClose} className="menuLink">TemplateTR02</span></li></Link>
                                        <Link to='/Documents/TemplatePOPUP'>  <li><span onClick={this.handleClose} className="menuLink">TemplatePOPUP</span></li></Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default MainIntroMenu;