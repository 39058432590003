// 스플리팅
import withSplitting from '../withSplitting';
// Container / Input / Data / Template / Chart / Multilingual / Etc / KLAGOUC

// Container
export const OBTAutoValueBinder = withSplitting(() => import('./OBTAutoValueBinder'), 'Container');
export const OBTPageContainer = withSplitting(() => import('./OBTPageContainer'), 'Container');
export const OBTDockPanel = withSplitting(() => import('./OBTDockPanel'), 'Container');
export const OBTFormPanel = withSplitting(() => import('./OBTFormPanel'), 'Container');
export const OBTConditionPanel = withSplitting(() => import('./OBTConditionPanel'), 'Container');
export const OBTFloatingPanel = withSplitting(() => import('./OBTFloatingPanel'), 'Container');
export const OBTReferencePanel = withSplitting(() => import('./OBTReferencePanel'), 'Container');
export const OBTPopupPanel = withSplitting(() => import('./OBTPopupPanel'), 'Container');
export const OBTDialog = withSplitting(() => import('./OBTDialog'), 'Container');
export const OBTDialog2 = withSplitting(() => import('./OBTDialog2'), 'Container');
export const OBTExcelFormDialog = withSplitting(() => import('./OBTExcelFormDialog'), 'Container');
export const OBTAccordion = withSplitting(() => import('./OBTAccordion'), 'Container');
export const OBTAccordion2 = withSplitting(() => import('./OBTAccordion2'), 'Container');
export const OBTTab = withSplitting(() => import('./OBTTab'), 'Container');

// Input
export const OBTTextField = withSplitting(() => import('./OBTTextField'), 'Input');
export const OBTMultiLineTextField = withSplitting(() => import('./OBTMultiLineTextField'), 'Input');
export const OBTNumberField = withSplitting(() => import('./OBTNumberField'), 'Input');
export const OBTMaskedTextField = withSplitting(() => import('./OBTMaskedTextField'), 'Input');
export const OBTComplete = withSplitting(() => import('./OBTComplete'), 'Input');
export const OBTComplete2 = withSplitting(() => import('./OBTComplete2'), 'Input');
export const OBTAutoCompleteChips = withSplitting(() => import('./OBTAutoCompleteChips'), 'Input');
export const OBTDropDownList = withSplitting(() => import('./OBTDropDownList'), 'Input');
export const OBTDropDownList2 = withSplitting(() => import('./OBTDropDownList2'), 'Input');
export const OBTMultiDropDownList = withSplitting(() => import('./OBTMultiDropDownList'), 'Input');
export const OBTButton = withSplitting(() => import('./OBTButton'), 'Input');
export const OBTButtonGroup = withSplitting(() => import('./OBTButtonGroup'), 'Input');
export const OBTSplitButton = withSplitting(() => import('./OBTSplitButton'), 'Input');
export const OBTRadioButton = withSplitting(() => import('./OBTRadioButton'), 'Input');
export const OBTCheckBox = withSplitting(() => import('./OBTCheckBox'), 'Input');
export const OBTCheckBox2 = withSplitting(() => import('./OBTCheckBox2'), 'Input');
export const OBTPreviousNextSelector = withSplitting(() => import('./OBTPreviousNextSelector'), 'Input');
export const OBTGroupSelector = withSplitting(() => import('./OBTGroupSelector'), 'Input');
export const OBTGroupSelector2 = withSplitting(() => import('./OBTGroupSelector2'), 'Input');
export const OBTCodePicker = withSplitting(() => import('./OBTCodePicker'), 'Input');
export const OBTTimePicker = withSplitting(() => import('./OBTTimePicker'), 'Input');
export const OBTDatePicker = withSplitting(() => import('./OBTDatePicker'), 'Input');
export const OBTSingleDatePicker = withSplitting(() => import('./OBTSingleDatePicker'), 'Input');
export const OBTSingleYearPicker = withSplitting(() => import('./OBTSingleYearPicker'), 'Input');
export const OBTSingleYearMonthPicker = withSplitting(() => import('./OBTSingleYearMonthPicker'), 'Input');
export const OBTDatePeriodPicker = withSplitting(() => import('./OBTDatePeriodPicker'), 'Input');
export const OBTYearMonthPeriodPicker = withSplitting(() => import('./OBTYearMonthPeriodPicker'), 'Input');

//Data
export const OBTDataGrid = withSplitting(() => import('./OBTDataGrid'), 'Data');
export const OBTDataGrid2 = withSplitting(() => import('./OBTDataGrid2'), 'Data');
export const OBTListGrid = withSplitting(() => import('./OBTListGrid'), 'Data');
export const OBTCardList = withSplitting(() => import('./OBTCardList'), 'Data');
export const OBTChip = withSplitting(() => import('./OBTChip'), 'Data');
export const OBTTreeView = withSplitting(() => import('./OBTTreeView'), 'Data');

//Etc
export const OBTTitle = withSplitting(() => import('./OBTTitle'), 'Etc');
export const OBTTooltip = withSplitting(() => import('./OBTTooltip'), 'Etc');
export const OBTSnackbar = withSplitting(() => import('./OBTSnackbar'), 'Etc');
export const OBTAlert = withSplitting(() => import('./OBTAlert'), 'Etc');
export const OBTConfirm = withSplitting(() => import('./OBTConfirm'), 'Etc');
export const OBTLoading = withSplitting(() => import('./OBTLoading'), 'Etc');
export const OBTProgress = withSplitting(() => import('./OBTProgress'), 'Etc');
export const OBTLinearProgress = withSplitting(() => import('./OBTLinearProgress'), 'Etc');
export const OBTProgressDialog = withSplitting(() => import("./OBTProgressDialog"), 'Etc');
export const OBTCalendar = withSplitting(() => import('./OBTCalendar'), 'Etc');
export const OBTScrollbar = withSplitting(() => import('./OBTScrollbar'), 'Etc');
export const OBTFileView = withSplitting(() => import('./OBTFileView'), 'Etc');
export const OBTColorPicker = withSplitting(() => import('./OBTColorPicker'), 'Etc');

//Chart
export const ECharts = withSplitting(() => import('./ECharts'), 'Chart');

//Example
export const ExampleCardListSideBar = withSplitting(() => import('./ExampleCardListSideBar'), 'Example', '카드리스트 사이드바 예제');

//Templete
export const TemplateDefault = withSplitting(() => import('./TemplateDefault'), 'Template');
export const TemplateCardListForm = withSplitting(() => import('./TemplateCardListForm'), 'Template');
export const TemplateTab = withSplitting(() => import('./TemplateTab'), 'Template');
export const TemplateCardList = withSplitting(() => import('./TemplateCardList'), 'Template');
export const TemplateHeaderDetail = withSplitting(() => import('./TemplateHeaderDetail'), 'Template');

//Multilingual
export const MultilingualGridForm = withSplitting(() => import('./MultilingualGridForm'), 'Multilingual');
export const MultilingualCardListForm = withSplitting(() => import('./MultilingualCardListForm'), 'Multilingual');

//KLAGOUC
export const TemplateFM01 = withSplitting(() => import('./TemplateKlagoUC/FM01'), 'KLAGOUC');
export const TemplateGR01 = withSplitting(() => import('./TemplateKlagoUC/GR01'), 'KLAGOUC');
export const TemplateGR02 = withSplitting(() => import('./TemplateKlagoUC/GR02'), 'KLAGOUC');
export const TemplateGR03 = withSplitting(() => import('./TemplateKlagoUC/GR03'), 'KLAGOUC');
export const TemplateGR04 = withSplitting(() => import('./TemplateKlagoUC/GR04'), 'KLAGOUC');
export const TemplateGR05 = withSplitting(() => import('./TemplateKlagoUC/GR05'), 'KLAGOUC');
export const TemplateTR02 = withSplitting(() => import('./TemplateKlagoUC/TR02'), 'KLAGOUC');
export const TemplatePOPUP = withSplitting(() => import('./TemplateKlagoUC/POPUP'), 'KLAGOUC');