/**
 * OBTPagination
 * @version 0.1
 * @author 안광진
 * @see LUXPagination
 */
import * as React from 'react';
import { Events, CompositeProps, Util } from '../Common';
import OBTPageButtons from './OBTPageButtons'
const styles = require('./OBTPagination.module.scss');
/**
 * PropType 정의
 * Events, CommonProps, CompositeProps 에 미리 지정된 Prop interface 를 충분히 활용한다.
 * extends 로 인터페이스 상속을 통해 사용된다.
 * 공용 Api 를 사용하려면 CommonProps.api 인터페이스를 확장한다.
 */
interface IOBTPagination extends CompositeProps.Default, Events.onChange<TypeList> {
    /**
     * 현재페이지
     */
    currentPage: number,
    /**
     * 페이지갯수
     */
    pageCount: number,
    /**
     * 페이지단위
     */
    pagingBlock: number,
    /**
     * row갯수
     */
    rowCountPerPage: number,
    /**
     * 총 row갯수
     */
    totalCount: number,
    /**
     * 총갯수 visible 여부
     */
    isPageTotalCountText: boolean,
    /**
     * 
     */
    useCountDropDownList?: boolean,
}

interface TypeList {
    currentPage: number,
    rowCountPerPage: number,
}

/**
 * State 정의
 */
interface State {
}

export default class OBTPagination extends React.Component<IOBTPagination, State> {
    ///////////////////////////////////////////////////////////////////////////// Initialize

    /**
     * Default Props 설정
     */
    public static defaultProps = {
        frozen: false,
        pagingBlock: 10,
        isPageTotalCountText: true,
        useCountDropDownList: true,
    }

    /**
     * State 정의
     */
    public state: State = {
    }

    ///////////////////////////////////////////////////////////////////////////// Life Cycle API

    // component 가 render 될때 호출됨.
    render() {

        const props = {
            // Value
            onChange: this.handleChange,
            currentPage: this.props.currentPage,
            pageCount: this.props.pageCount,
            totalCount: this.props.totalCount,
            rowCountPerPage: this.props.rowCountPerPage,
            pagingBlock: this.props.pagingBlock,
            isPageTotalCountText: this.props.isPageTotalCountText,
            useCountDropDownList: this.props.useCountDropDownList
        };

        const component = <OBTPageButtons {...props} />

        return (
            <div id={this.props.id} data-orbit-component={'OBTPagination'} className={Util.getClassNames(styles.root, this.props.className)}>
                {component}
            </div>
        );
    }

    ///////////////////////////////////////////////////////////////////////////// Logics


    ///////////////////////////////////////////////////////////////////////////// Event Handlers
    private handleChange = (e: any): void => {
        this.props.onChange(e);
    }

};
