/**
 *  검색시 팝 오버에 뿌려질 리스트 컨트롤 입니다.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import LineItem from './LineItem';
import ButtonHeader from './ButtonHeader';

const getStyles = (props) => {
    const styles = {
        // inputElement 의 전체를 감싸고 있는 div 의 Style
        style: {
            position: 'relative',
            margin: 0,
            padding: 0,
        },
        // ul Style
        ulStyle: {
            listStyle: 'none',
            margin: 0,
            padding: 0,
            // overflow: "auto",
            //borderTop: "1px solid #a3a3a3"
            borderTop: props.index === 0 ? "" : "1px solid #a3a3a3"
        },
        // li Style
        liStyle: {
            margin: 0,
            padding: 0,
            position: 'relative',
            clear: 'both',
            overflow: 'hidden',
        },
        titleDivStyle: {
            margin: "0 10px",
            padding: 0,
            lineHeight: "28px",
            borderBottom: "1px solid #dbdbdb",
            textAlign: "right"

        },
        titleSpanStyle: {
            margin: 0,
            padding: 0,
            fontSize: '12px',
            fontFamily: 'inherit',
            // lineHeight: '13px',
            lineHeight: "20px",
            paddingRight: "8px",
            cursor: "default",
        }
    };

    return styles;
};

class ListElement extends Component {

    // region React Base

    static propTypes = {
        /**
         * 항목삭제 가능여부입니다.
         */
        canDelete: PropTypes.bool,
        /**
         * 팝오버의 높이 입니다. 지정하지 않은 경우 높이는 자동으로 계산됩니다.
         */
        maxPopOverHeight: PropTypes.number,
        /**
         * 검색한 키워드
         */
        keyWord: PropTypes.string.isRequired,
        /**
         * 아이템을 삭제버튼이 클릭되면 발생하는 콜백함수입니다.
         */
        onDelete: PropTypes.func,
        /**
         * 툴팁의 float 속성입니다.
         */
        toolTipFloat: PropTypes.string,
        /**
         * null(공란) 값을 툴팁에서 skip  할지 정하는 속성입니다.
         */
        toolTipNullSkip: PropTypes.bool,
        /**
         * 툴팁을 무조건 생성할지 여부
         */
        toolTipAlwaysShow: PropTypes.bool,
        /**
         * 툴팁을 생성 여부
         */
        isShowTooltip: PropTypes.bool
    }

    // constructor() {
    //     super();
    // }

    myRefs = {
        list: React.createRef()
    }

    componentDidUpdate(prevProps, prevState) {
        // 아이템의 이동에 따라 스크롤을 컨트롤 한다.
        if (this.props.focusedInfo.index !== prevProps.focusedInfo.index) {
            const hasAddButton = this.props.data.data[0] === "addButton";
            if (this.myRefs.list.current) {
                const currentIndex = hasAddButton ? this.props.focusedInfo.index - 1 : this.props.focusedInfo.index;

                const boxTopHeight = this.myRefs.list.current.getScrollTop();
                const boxBottomHeight = boxTopHeight + this.myRefs.list.current.getClientHeight();
                const itemHeight = this.myRefs.list.current.view.children[0].clientHeight;
                const itemTopHeight = itemHeight * (currentIndex);
                const itemBottomHeight = itemTopHeight + itemHeight;

                if (boxBottomHeight < itemBottomHeight) {
                    this.myRefs.list.current.scrollTop(itemBottomHeight - this.myRefs.list.current.getClientHeight());
                } else if (boxTopHeight > itemTopHeight) {
                    this.myRefs.list.current.scrollTop(itemTopHeight);
                }
            }
        }
    }

    // endregion

    // region LineItem

    /**
     * 팝오버의 리스트를 만든다.
     */
    createLineItem = (styles, ulStyle) => {
        let headerItems = [];
        let lineItems = [];
        let data = this.props.data;
        let dataCount = data.data.length;

        if (data.data.length > 0) {
            // 추가버튼이 있으면 버튼 영역을 추가
            let hasAddButton = false;
            if (data.data[0] === "addButton") {
                dataCount -= 1;
                hasAddButton = true;
                headerItems.push(
                    <ButtonHeader
                        key={-2}
                        keyWord={this.props.keyWord}
                        focusedInfo={this.props.focusedInfo}
                        onSelect={this.handleSelect}
                    />
                )
            }

            if (data.title) {
                // 타이틀이 있으면 타이틀 li를 추가한다.
                headerItems.push(
                    <li key={-1} style={styles.liStyle}>
                        <div style={styles.titleDivStyle}>
                            <span style={styles.titleSpanStyle}>
                                {data.title + " : " + dataCount + "건"}
                            </span>
                        </div>
                    </li>
                );
            }
            // 데이터 하나 마다 하나의 라인을 만든다.
            data.data.forEach((item, index) => {
                if (item !== "addButton") {
                    lineItems.push(
                        <LineItem
                            index={index - (hasAddButton ? 1 : 0)}
                            toolTipNullSkip={this.props.toolTipNullSkip}
                            canDelete={this.props.canDelete}
                            key={index}
                            dataType={data.dataType}
                            data={item}
                            dataInfo={this.props.dataInfo}
                            keyWord={this.props.keyWord}
                            focusedInfo={this.props.focusedInfo}
                            onSelect={this.handleSelect}
                            onClickDelete={this.handleDelete}
                            stylePopoverATag={this.props.stylePopoverATag}
                            type={this.props.type}
                            toolTipFloat={this.props.toolTipFloat}
                            toolTipAlwaysShow={this.props.toolTipAlwaysShow}
                            isShowTooltip={this.props.isShowTooltip}
                        />);
                }
            })
        }

        let list = (
            <ul style={ulStyle}>
                <Scrollbars
                    ref={this.myRefs.list}
                    autoHeight
                    autoHeightMax={this.props.maxPopOverHeight ? this.props.maxPopOverHeight : 640}
                >
                    {lineItems}
                </Scrollbars>
            </ul>
        );

        return (
            <div style={styles.style}>
                <ul style={ulStyle}>
                    {headerItems}
                </ul>
                {list}
            </div>
        );
    };



    /**
     * 마우스로 아이템을 클릭해서 선택하면 호출된다.
     */
    handleSelect = (event, data, dataType = null) => {
        if (this.props.onSelect) {
            this.props.onSelect(event, data, dataType);
        }
    }

    /**
     * 아이템을 삭제하면 발생하는 이벤트 입니다.
     */
    handleDelete = (event, data, index) => {
        if (this.props.onDelete) {
            this.props.onDelete(event, data, index);
        }
    }

    // endregion

    // region render

    render() {
        const styles = getStyles(this.props);

        let ulStyle = styles.ulStyle;
        if (this.props.maxPopOverHeight) {
            ulStyle = Object.assign({}, ulStyle, { maxHeight: this.props.maxPopOverHeight + "px" });
        }

        // 타입에 따라서 리스트 엘리멘트를 생성한다.
        let createLiElement = this.createLineItem(styles, ulStyle);

        return (
            <div>
                {createLiElement}
            </div>
        )
    }

    // endregion
}

export default ListElement;