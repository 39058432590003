import React from "react";
import uuidv4 from 'uuid/v4';
import OBTDataGrid, { OBTDataGridInterface } from ".";
import { GridType } from "./OBTDataGridInterface";
import { ColumnType, IColumn } from "./IColumn";
import OBTDialog2 from '../OBTDialog2';
import OBTCheckBox from '../OBTCheckBox';
import OBTDockPanel from '../OBTDockPanel';
import { Util } from "../Common";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import { hasError } from "../Common/CommonState";
import { OrbitInternalLangPack } from "../Common/Util";

interface IExcelExportDialog {
    isShow: boolean,
    columnList: IColumn[],
    showExportAllPageCheck: boolean,
    onClickClose: () => void,
    onClickTransform: (selectedList: { columnName: string, transform: 'T' | 'F', isVisibleColumn: 'T' | 'F', withoutMasking: 'T' | 'F', maskable: 'T' | 'F' }[], exportAllPage: boolean) => void,
}

interface IState extends hasError {
    grid: OBTDataGridInterface,
    onlyDisplayExportChecked: boolean,
    exportAllPageChecked: boolean,
}

export default class ExcelExportDialog extends React.Component<IExcelExportDialog, IState> {
    state: IState = {
        grid: new OBTDataGridInterface(uuidv4(), {
            gridType: GridType.gridView,
            editable: true,
            appendable: false,
            indicator: true,
            preventRClick: true,
            isSystemGrid: true,
        }).setColumns([
            {
                name: 'columnHeaderName',
                header: OrbitInternalLangPack.getText('WE000002498', '항목명'),
                type: ColumnType.text,
                isAutoWidth: true,
                editable: false,
            },
            {
                name: 'transform',
                header: OrbitInternalLangPack.getText('WE000002319', '변환'), // TODO Change로 변역됨
                type: ColumnType.check,
                trueValues: ["T"],
                falseValues: ["F"],
                width: 70,
                editable: true,
                headerCheckLocation: 'left',
                isHeaderCheck: false
            },
            {
                name: 'withoutMasking',
                header: OrbitInternalLangPack.getText('WE000026859', '개인정보\n포함'),
                type: ColumnType.check,
                trueValues: ["T"],
                falseValues: ["F"],
                width: 70,
                editable: (e) => {
                    return e.values['maskable'] === 'T' ? true : false;
                },
                dynamicStyles: (grid, index, value) => {
                    const maskable = this.state.grid.getValue(index.itemIndex, 'maskable');
                    return {
                        background: maskable === 'T' ? undefined : '#eceded'
                    }
                },
                visible: false
            },
            {
                name: 'isVisibleColumn',
                type: ColumnType.data,
            },
            {
                name: 'maskable',
                type: ColumnType.data,
            },
            {
                name: 'columnName',
                type: ColumnType.data,
            }
        ]).setProvider({
            read: (e) => {
                return new Promise((resolve) => {
                    const dataItems = this.props.columnList.map(item => {
                        return {
                            columnName: item.name,
                            columnHeaderName: item.header,
                            transform: (item.type !== ColumnType.data && item.visible !== false) ? 'T' : 'F',
                            withoutMasking: 'F',
                            isVisibleColumn: (item.type !== ColumnType.data && item.visible !== false) ? 'T' : 'F',
                            maskable: (item.type === ColumnType.mask && (item.usePrivacy)) ? 'T' : 'F',
                        }
                    });

                    resolve(dataItems);
                })
            },
            store: (e) => {
                return new Promise<void>((resolve) => {
                    resolve();
                })
            }
        }),
        onlyDisplayExportChecked: true,
        exportAllPageChecked: true,
    }

    private hasPrivacy(): boolean {
        return this.props.columnList.find(column => column.usePrivacy === true) ? true : false;
    }

    componentDidMount() {
        try {
            this.state.grid.onAfterChange.add((e) => {
                if (this.state.onlyDisplayExportChecked === true) {
                    this.setState({
                        onlyDisplayExportChecked: false
                    })
                }
                if (e.columnName === 'transform') {
                    let trueValues: Array<any> = [];
                    let falseValues: Array<any> = [];

                    this.state.grid.getRows().forEach((item, index) => {
                        if (item.transform === 'T') {
                            trueValues.push(item);
                        }
                        if (item.transform === 'F') {
                            falseValues.push(item);
                        }
                    });

                    if (trueValues.length === this.state.grid.getRows().length) {
                        this.state.grid.setColumnHeaderCheck('transform', true);
                    } 
                    else if(falseValues.length === 1){
                        this.state.grid.setColumnHeaderCheck('transform', false);
                    }
                }
            });

            this.state.grid.onAfterColumnHeaderCheck.add((e) => {
                const trueValues = this.state.grid.getRows().filter(item => item.transform === 'T');

                if (e.checked) { 
                    if (trueValues.length === this.state.grid.getRows().length) { // 헤더 버튼 클릭 없이 모든 데이터가 선택 될 경우
                        return;
                    }
                    else {
                        this.state.grid.getRows().forEach((item, index) => { // 헤더 버튼 클릭으로 전체 선택 될 경우
                            this.state.grid.setValue(index, 'transform', 'T', false);
                        });
                    }
                } else { 
                    if (trueValues.length === this.state.grid.getRows().length -1) { // 헤더 버튼 클릭 없이 헤더 버튼 해제 될 경우
                        return;
                    }
                    else {
                        this.state.grid.getRows().forEach((item, index) => { // 헤더 버튼 클릭으로 전체 해제 될 경우
                            this.state.grid.setValue(index, 'transform', 'F', false);
                        });
                    }
                }
            });

            this.state.grid.readData()
                .then(() => {
                    this.state.grid.setColumnVisible('withoutMasking', this.hasPrivacy() ? true : false);
                })
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    componentDidUpdate(prevProps: IExcelExportDialog) {
        try {
            if (this.props.isShow !== prevProps.isShow) {
                if (this.props.isShow) {
                    this.setState({
                        onlyDisplayExportChecked: true,
                        exportAllPageChecked: false,
                    }, () => {
                        this.state.grid.readData();

                        this.state.grid.refresh();
                    })
                }
            }
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    private handleClickOk = () => {
        this.props.onClickTransform(this.state.grid.getRows(), this.state.exportAllPageChecked && this.props.showExportAllPageCheck);
    }

    private handleClickClose = () => {
        this.props.onClickClose();
    }

    private handleChangeOnlyDisplayExport = (e) => {
        if (e.value === true) {
            this.state.grid.getDisplayRows().forEach((item, index) => {
                if (item['isVisibleColumn'] === 'T') {
                    this.state.grid.setValue(index, 'transform', 'T', false);
                } else {
                    this.state.grid.setValue(index, 'transform', 'F', false);
                }
            })
        }

        this.setState({
            onlyDisplayExportChecked: e.value
        });
    }

    private handleChangeExportAllPage = (e) => {
        this.setState({
            exportAllPageChecked: e.value
        });
    }

    renderComponent = () => {
        return (
            <OBTDialog2
                title={OrbitInternalLangPack.getText('WE000026853', '엑셀 변환하기')}
                open={this.props.isShow}
                width={"400px"}
                height={"650px"}
                buttons={OBTDialog2.Buttons.ConfirmAndCancel(
                    this.handleClickOk,
                    this.handleClickClose
                )}
            >
                <OBTDockPanel>
                    <OBTDataGrid
                        interface={this.state.grid}
                    />
                    <div {...{ dock: OBTDockPanel.Dock.bottom }}>
                        <div>
                            <OBTCheckBox
                                value={this.state.onlyDisplayExportChecked}
                                labelText={OrbitInternalLangPack.getText('WE000026854', '화면데이터만 내려받기')}
                                onChange={this.handleChangeOnlyDisplayExport}
                            />
                        </div>
                        {
                            this.props.showExportAllPageCheck === true ? (
                                <div>
                                    <OBTCheckBox
                                        value={this.state.exportAllPageChecked}
                                        labelText={OrbitInternalLangPack.getText('WE000026857', '모든 페이지 데이터 변환')}
                                        onChange={this.handleChangeExportAllPage}
                                    />
                                </div>
                            ) : null
                        }
                    </div>
                </OBTDockPanel>
            </OBTDialog2>
        )
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        )
    }
}