import React, { Component } from "react";
import PropTypes from 'prop-types';
import LUXButton from "luna-rocket/LUXButton";
import { UFOMonthCalendar } from "../UFOMonthField";
import OBTControlButtons from "../../OBTYearMonthPeriodPicker/OBTControlButtons";
import moment from 'moment'
import OBTFloatingPanel from "../../OBTFloatingPanel";
import { Util } from "../../Common";
import { OrbitInternalLangPack } from '../../Common/Util';
const moduleStyles = require('./UFOPeriodMonthFieldDialog.module.scss');

const styles = {
    button: {
        // background: '#fff',
        border: '1px solid #e0e0e0',
        boxSizing: 'border-box',
        color: '#4a4a4a',
        display: 'inline-block',
        fontSize: '11px',
        fontFamily: '돋움, Dotum, Helvetica, Apple SD Gothic Neo, sans-serif',
        height: '21px',
        margin: '-1px 0 0 -1px',
        outline: 'none',
        padding: '1px 6px 0',
        position: 'relative',
        textAlign: 'center',
        verticalAlign: 'top',
    },
    dialogContent: {
        width: 310,
    },
    dialogBodyContent: {
        padding: 0,
        minHeight: 209,
        width: 388,
    }
};

class UFOPeriodMonthFieldDialog extends Component {
    static propTypes = {
        anchorEl: PropTypes.object,
        displayYear: PropTypes.string,
        fixedYear: PropTypes.oneOf(["from", "to"]),
        initialDateFrom: PropTypes.number,
        initialDateTo: PropTypes.number,
        maxValue: PropTypes.number,
        minValue: PropTypes.number,
        onAccept: PropTypes.func,
        onDismiss: PropTypes.func,
        onShow: PropTypes.func,
        open: PropTypes.bool,
        shouldDisableDate: PropTypes.func,
        useControlButton: PropTypes.bool,
        customLabel: PropTypes.func,
        required: PropTypes.bool
    };

    static defaultProps = {
        selectedDateFrom: undefined,
        selectedDateTo: undefined,
        initialDateFrom: null,
        initialDateTo: null,
    };

    state = {
        open: false,
        periodDays: 0,
        transition: false
    };

    myRefs = {
        root: React.createRef(),
        periodDialog: React.createRef(),
        okButton: React.createRef(),
        dateFrom: React.createRef(),
        dateTo: React.createRef()

    }

    componentWillMount() {
        this.setState({
            selectedDateFrom: this.props.initialDateFrom,
            selectedDateTo: this.props.initialDateTo,
            periodDays: this.getPeriodDays(this.props.initialDateFrom, this.props.initialDateTo),
        });
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.initialDateFrom !== this.props.initialDateFrom) {
            const date = nextProps.initialDateFrom;
            this.setState({
                selectedDateFrom: date,
                periodDays: this.getPeriodDays(date, nextProps.initialDateTo),
            });
        }

        if (nextProps.initialDateTo !== this.props.initialDateTo) {
            const date = nextProps.initialDateTo;
            this.setState({
                selectedDateTo: date,
                periodDays: this.getPeriodDays(nextProps.initialDateFrom, date),
            });
        }
    };

    show = () => {
        if (this.props.onShow && !this.state.open) this.props.onShow();

        this.setState({
            open: true,
            periodDays: this.getPeriodDays(this.state.selectedDateFrom, this.state.selectedDateTo),
        }, () => {
            this.setState({
                transition: true
            })
        });
    };

    dismiss = () => {
        if (this.props.onDismiss) this.props.onDismiss();

        this.setState({
            open: false, transition: false,
            selectedDateFrom: this.props.initialDateFrom,
            selectedDateTo: this.props.initialDateTo,
        });
    };

    getPeriodDays(dateFrom, dateTo) {
        return !dateFrom || !dateTo ? 0 : this.monthDiff(dateTo, dateFrom) + 1;
    }

    monthDiff(to, from) {
        let diff = to - from;
        return (diff / 100) * 12 + diff % 100;
    }

    handleTouchTapDayFrom = (event, dateFrom) => {

        this.setState({
            selectedDateFrom: isNaN(dateFrom) ? null : dateFrom,
            periodDays: this.getPeriodDays(dateFrom, this.state.selectedDateTo),
        });

        this.focusOkButton();
    };

    onChangeDisplayDateFrom = (dateFrom) => {
        if (this.myRefs.dateFrom.current) {
            this.myRefs.dateFrom.current.handleTouchTapMonth(undefined, dateFrom.month, dateFrom.year)
        }
    }

    handleTouchTapDayTo = (event, dateTo) => {
        this.setState({
            selectedDateTo: isNaN(dateTo) ? null : dateTo,
            periodDays: this.getPeriodDays(this.state.selectedDateFrom, dateTo),
        });

        this.focusOkButton();
    };

    onChangeDisplayDateTo = (dateTo) => {
        if (this.myRefs.dateTo.current) {
            this.myRefs.dateTo.current.handleTouchTapMonth(undefined, 12, dateTo.year)
        }
    }

    handleTouchTapButtons = (dateFrom, dateTo, event) => {

        const selectedDateFrom = Number(moment(dateFrom).format('YYYYMM'));
        const selectedDateTo = Number(moment(dateTo).format('YYYYMM'));

        if (this.props.maxValue >= selectedDateTo && this.props.minValue <= selectedDateFrom) {

            this.setState({
                selectedDateFrom: selectedDateFrom,
                selectedDateTo: selectedDateTo,
            });
            this.focusOkButton();
        }
    }

    handleRequestClose = () => {

        this.setState({
            selectedDateFrom: this.props.initialDateFrom,
            selectedDateTo: this.props.initialDateTo,
        });

        if (this.props.onRequestClose) this.props.onRequestClose('cancel');
    };

    handleTouchTapOk = (e) => {

        this.setState({
            selectedDateFrom: this.props.initialDateFrom,
            selectedDateTo: this.props.initialDateTo,
        });
        if (this.props.onAccept) {
            this.props.onAccept(this.state.selectedDateFrom, this.state.selectedDateTo);
        }
        this.dismiss();
    };

    focusOkButton = () => {
        if (this.myRefs.okButton.current) this.myRefs.okButton.current.focus();
        this.setState({ focusOkButton: true });
    }

    blurOkButton = () => {
        if (this.myRefs.okButton.current) this.myRefs.okButton.current.blur();
        this.setState({ focusOkButton: false });
    }

    handleResetFrom = () => {
        this.setState({
            selectedDateFrom: null
        })
    }

    handleResetTo = () => {
        this.setState({
            selectedDateTo: null
        })
    }

    nullChecked = (date) => {
        if (date) {
            return String(date)
        } else {
            return ''
        }
    }

    getAvailable = () => {
        let available = false;
        if (this.state.periodDays > 0) available = true;
        if (!this.props.allowFullPeriodOnly &&
            (this.state.selectedDateFrom || this.state.selectedDateTo) &&
            this.state.selectedDateFrom !== 0 && this.state.selectedDateTo !== 0) {
            available = true;
        }
        return available;
    }

    render() {
        const {
            // cancelLabel,
            displayYear,
            // firstDayOfWeek,
            fixedYear,
            // initialDateFrom,
            // initialDateTo,
            // locale,
            maxValue,
            minValue,
            // onAccept,
            // onClick,
            // onRequestClose,
            // okLabel,
            // periodNumber,
            // periodType,
            // popoverStyle,
            // position,
            // shouldDisableDate,
            // ...other
        } = this.props;

        const { open, selectedDateFrom, selectedDateTo, focusOkButton } = this.state;
        const available = this.getAvailable();

        return (
            <OBTFloatingPanel
                key={open ? 'open' : 'close'}
                value={open}
                position={this.props.position}
                align={this.props.align}
                className={"LS_calendar"}
                width="388px"
                autoPosition={true}
            >
                <div ref={this.myRefs.root} tabIndex={0}
                    className={Util.getClassNames(moduleStyles.calendar, this.state.transition === true ? moduleStyles.visible : null)}>
                    {this.props.useControlButton ?
                        <OBTControlButtons
                            selectedDateFrom={this.state.selectedDateFrom ? new Date(String(this.state.selectedDateFrom).slice(0, 4) + '-' + String(this.state.selectedDateFrom).slice(4, 6)) : undefined}
                            selectedDateTo={this.state.selectedDateTo ? new Date(String(this.state.selectedDateTo).slice(0, 4) + '-' + String(this.state.selectedDateTo).slice(4, 6)) : undefined}
                            onTouchTap={this.handleTouchTapButtons}
                        />
                        : <></>}
                    <UFOMonthCalendar
                        ref={this.myRefs.dateFrom}
                        displayYear={displayYear}
                        fixedYearFrom={fixedYear === "from"}
                        initialDate={selectedDateFrom}
                        selectedDateTo={selectedDateTo}
                        maxValue={maxValue}
                        minValue={minValue}
                        onChange={this.handleTouchTapDayFrom}
                        style={{ float: 'left', width: '50%' }}
                        onChangeDisplayDate={this.onChangeDisplayDateFrom}
                    />
                    <UFOMonthCalendar
                        ref={this.myRefs.dateTo}
                        displayYear={displayYear}
                        fixedYearTo={fixedYear === "to"}
                        selectedDateFrom={selectedDateFrom}
                        initialDate={selectedDateTo}
                        maxValue={maxValue}
                        minValue={minValue}
                        onChange={this.handleTouchTapDayTo}
                        onChangeDisplayDate={this.onChangeDisplayDateTo}
                        style={{ float: 'left', width: '50%', position: 'relative' }}
                    >
                        <span style={{ position: 'absolute', top: 0, left: 0, bottom: 0, zIndex: 10, width: '1px', background: '#e0e0e0' }} />
                    </UFOMonthCalendar>
                    <div className="select_area" style={{ height: '17px' }}>
                        <div className="btn_group2">
                            <LUXButton
                                label={OrbitInternalLangPack.getText('WE000001945', '취소')}
                                onClick={this.handleRequestClose}
                                style={styles.button}
                            />
                            <LUXButton
                                ref={this.myRefs.okButton}
                                label={OrbitInternalLangPack.getText('WE000019193', '확인')}
                                onBlur={this.blurOkButton}
                                onClick={this.handleTouchTapOk}
                                keyboardFocused={focusOkButton}
                                style={Object.assign({}, styles.button, { marginLeft: '3px' })}
                                disabled={!available}
                            />

                        </div>
                        {this.props.customLabel ? this.props.customLabel(this.nullChecked(this.state.selectedDateFrom), this.nullChecked(this.state.selectedDateTo), this.handleResetFrom, this.handleResetTo) :
                            !this.props.required ? <LUXButton
                                label={OrbitInternalLangPack.getText('WE000001958', '초기화')}
                                onClick={() => {
                                    if (this.props.onAccept) {
                                        this.props.onAccept('', '');
                                    }
                                    this.dismiss();
                                }}
                                style={styles.button}
                            /> : undefined}
                    </div>
                </div>
            </OBTFloatingPanel>
        );
    }

    containsFocus = () => {
        return Util.containsFocus(this.myRefs.root);
    }
}

export default UFOPeriodMonthFieldDialog;
