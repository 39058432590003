import * as React from 'react';
import { IOBTContext } from "../OBTPageContainer/OBTPageContainer";

export default {
    handleFocus: (
        target: any,
        id?: string,
        context?: IOBTContext,
    ) => {
        if (!id) return;
        if (!context) return;
        if (!context.setSideBar) return;
        if (!context.guideDocumentMap) return;

        const targetElement = context.guideDocumentMap.get(id);
        if (targetElement) {
            let headerText = targetElement.getAttribute('data-guide-title') as string;

            const headerElement = headerText && headerText.length > 0 ? (
                <div style={{ backgroundColor: '#f5f5f5', padding: '5px 10px' }}>
                    {headerText}
                </div>
            ) : null;

            context.setSideBar([{
                key: 'gridGuide',
                component: (
                    <div style={{ width: '100%' }}>
                        {headerElement}
                        <div style={{ padding: '10px' }} dangerouslySetInnerHTML={{
                            __html: targetElement.toString()
                        }}>
                        </div>
                    </div>
                ),
                visible: true,
            }], {
                id: id,
                target: target
            });
        }
    },
    handleBlur: (context: any, id?: string) => {
        if (context && context.clearSideBar) {
            context.clearSideBar(id);
        }
    }
}

