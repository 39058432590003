import * as Events from './OBTCardListEvents';
import { Util, Privacy } from '../Common';
import { DisplayType } from '../OBTDropDownList/OBTDropDownList'
import Pagination from './Pagination';
import { IPageAuthority } from '../OBTPageContainer/OBTPageContainer';
import ICell from '../OBTDataGrid/ICell';
import PropDefinitions from './PropDefinitions';

////////// enum //////////

/**
 * @internal
 * 그리드의 상태 속성입니다.
 */
enum GridState {
    'none' = 'none',
    'empty' = 'empty',
    'added' = 'added',
    'modified' = 'modified',
    'deleted' = 'deleted'
}

/**
 * 카드리스트 기본 템플릿에서 보여주는 형식 속성입니다.
 */
enum Template {
    /**
     * default : 기본
     * imageLeft : 이미지 왼쪽
     * imageRight : 이미지 오른쪽
     */
    'default' = 'default',
    'imageLeft' = 'imageLeft',
    'imageRight' = 'imageRight',
    'stateIcon' = 'stateIcon',
    'stateLabel' = 'stateLabel',
    'custom' = 'custom'
}

/**
 * @internal
 * 카드리스트 기본 바디 템플릿에서 데이터 없을 경우 보여주는 이미지 형식 속성입니다.
 */
export enum EmptySetBody {
    /**
     * emptyData : 기본 초기 데이터가 없을 경우
     * onReadData : read 후 검색 결과가 없을 경우
     * onAppendableReadData : 추가 버튼 있을 경우 
     */
    'emptyData' = 'emptyData',
    'emptySearch' = 'emptySearch',
    'emptyWrite' = 'emptyWrite'
}

/**
 * 특정 셀의 스타일을 파악하기 위한 정보
 */
export type ICardStyleType = {
    checkPen: {
        has: boolean,
        valueIfHas: string
    },
    memo: {
        has: boolean,
    }
}


////////// type //////////

export type StoreCheckPen = (e: Events.StoreMemoCheckPenEventArgs) => Promise<any>;
export type StoreMemo = (e: Events.StoreMemoCheckPenEventArgs) => Promise<any>;

/**
 * @internal
 * 내부 타입 설정입니다.
 */
type Read = (e: Events.ReadEventArgs) => Promise<any[] | null | undefined>;
type ReadTotalCount = (e: Events.ReadTotalCountEventsArgs) => Promise<number | null | undefined>;
type Store = (e: Events.StoreEventArgs) => Promise<any>;
type onRenderItem = (e: Events.RenderItemEventArgs) => void;  //콜백함수

////////// interface //////////

/**
 * 카드리스트의 기본 템플릿 속성입니다.
 */
interface ICardListTemplate {
    template: Template,
    /**
     * 카드리스트에서 보여지는 main값을 정의하는 속성입니다.
     */
    main: string,
    /**
     * 카드리스트에서 아래쪽 왼쪽에 보여지는 값을 정의하는 속성입니다.
     */
    subLeft?: string,
    /**
     * 카드리스트에서 아래쪽 오른쪽에 보여지는 값을 정의하는 속성입니다.
     */
    subRight?: string,
    /**
     * 카드리스트 템플릿의 스타일을 설정하는 속성입니다.
     */
    right?: string,
    /**
     * 카드리스트 템플릿의 스타일을 설정하는 속성입니다.
     */
    className?: string,
    /**
    * 카드리스트에서 이미지 값을 정의하는 속성입니다.
    */
    imageUrl?: string
}

/**
 * 카드리스트의 append 속성입니다.
 */
interface IAddButton {
    /**
     * 추가 버튼에 들어갈 사용자가 직접 만든 Component 입니다. 
     */
    appendComponent?: any,
    /**
    * 추가 버튼에서 text 값을 정의하는 속성입니다.
    */
    labelText?: string,
    /**
     * 추가 버튼의 스타일을 설정하는 속성입니다.
     */
    className?: string,
    /**
     * 추가 버튼에서 이미지 값을 정의하는 속성입니다.
     */
    imageUrl?: string
}

/**
 * 카드리스트의 read, store 사용하는 속성입니다.
 */
interface IDataAdapter {
    read: Read,
    readTotalCount: ReadTotalCount,
    store?: Store,
    storeCheckPen?: StoreCheckPen,
    storeMemo?: StoreMemo
}

/**
 * 카드리스트의 itemPadding 
 */
interface IItemPadding {
    top?: string,
    right?: string,
    bottom?: string,
    left?: string
}

/**
 * 카드리스트의 empty 셋 이미지, 텍스트
 * img : 사용자 이미지
 * text : 사용자 텍스트
 */
export interface ICardListEmptySet {
    image: string,
    msg: string
}

/////////// Chanin 클레스 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * @internal
 * 카드리스트 내부의 changing 이벤트입니다.
 */
class ChaningEvent<T> {
    private _events: T[] = [];
    public get events() { return this._events; }

    public addToFirst(event: T) {
        this._events = [event].concat(this._events)
    }

    public add(event: T) {
        if (!this._events.includes(event)) {
            this._events.push(event);
        }
    }

    public remove(event: T) {
        const index = this._events.indexOf(event);
        if (index >= 0) {
            this._events.splice(index, 0);
        }
    }

    public set(event: T | null) {
        this._events = event ? [event] : [];
    }
}

////////// OBTCardListInterface //////////

/**
 * 카드리스트의 인터페이스입니다.
 */
export class OBTCardListInterface {
    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = PropDefinitions;

    ////////// 템플릿 타입 //////////

    /**
     * 카드리스트의 템플릿 속성입니다.
     */
    public static Template = Template;

    ///////////////////////////////////////////////// Events //////////////////////////////////////////////////////

    /**
     * 셀 선택이 바뀌기 전에 발생하는 이벤트 
     * [BeforeSelectChangeEventArgs]
     */
    public readonly onBeforeChangeRowSelection = new ChaningEvent<((e: Events.BeforeChangeSelection) => void)>();

    /**
     * 셀선택이 바뀐 이후에 발생하는 이벤트
     * [AfterSelectChangeEventArgs]
     */
    public readonly onAfterChangeRowSelection = new ChaningEvent<((e: Events.AfterChangeSelection) => void)>();

    /**
     * 새로운행이 추가되기전에 발생되는 이벤트, cancel 가능
     * [Events.BeforeAddRowEventArgs]
     * 이벤트파리미터 클래스
     */
    public readonly onBeforeAddRow = new ChaningEvent<((e: Events.BeforeAddRowEventArgs) => void)>();

    /**
     * 새로운행이 추가되기된 이후에 발생하는 이벤트
     * [AfterAddRowEventArgs]
     */
    public readonly onAfterAddRow = new ChaningEvent<((e: Events.AfterAddRowEventArgs) => void)>();

    /**
     * 행 삭제전에 발생하는 이벤트
     * cancel가능
     * [BeforeRemoveRowEventArgs]
     */
    public readonly onBeforeRemoveRow = new ChaningEvent<((e: Events.BeforeRemoveRowEventArgs) => void)>();

    /**
     * 행이 삭제된 이후에 발생하는 이벤트
     * [AfterRemoveRowEventArgs]
     */
    public readonly onAfterRemoveRow = new ChaningEvent<((e: Events.AfterRemoveRowEventArgs) => void)>();

    /**
     * 셀의 데이터가 변경된 이후에 발생하는 이벤트
     * cancel가능 
     * [BeforeChangeEventArgs]
     */
    public readonly onBeforeChange = new ChaningEvent<((e: Events.BeforeChangeEventArgs) => void)>();

    /**
     * 셀의 데이터가 변경되기전에 변경되기 발생하는 이벤트
     * 변경되기 전값과 변경된 값을 이용한 유효성검사를 할 수 있다.
     * cancel가능 
     * [ValidateChangeEventArgs]
     */
    public readonly onValidateChange = new ChaningEvent<((e: Events.ValidateChangeEventArgs) => void)>();

    /**
     * @event
     * 셀의 데이터가 변경된 이후에 발생하는 이벤트
     * [AfterChangeEventArgs]
     */
    public readonly onAfterChange = new ChaningEvent<((e: Events.AfterChangeEventArgs) => void)>();

    /**
     * before
     * checkable 그리드에서 행이 체크상태가 변경된 이후에 발생하는 이벤트
     * [AfterCheckEventArgs]
     */
    public readonly onAfterCheck = new ChaningEvent<((e: Events.AfterCheckEventArgs) => void)>();

    /**
     * checkable 그리드에서 헤더의 전체체크, 해제 여부가 바뀐후 발생하는 이벤트
     * [AfterHeaderCheckEventArgs]
     */
    public readonly onAfterHeaderCheck = new ChaningEvent<((e: Events.AfterHeaderCheckEventArgs) => void)>();

    /**
     * 데이터를 읽은 후에 호출되는 이벤트
     * [AfterReadEventArgs]
     */
    public readonly onAfterRead = new ChaningEvent<((e: Events.AfterReadEventArgs) => void)>();

    /**
     * 데이터, 체크등 내부의 데이터가 변경될때 호출
     * [AfterDataChangeEventArgs]
     */
    public readonly onAfterDataChanged = new ChaningEvent<((e: Events.AfterDataChangeEventArgs) => void)>();

    /**
     * PageContainer에서 Drawer를 보여줘야 되는 상황에서 호출되는 이벤트
     * [DrawerEventArgs]
     */
    public readonly onDrawer = new ChaningEvent<((e: Events.DrawerEventArgs) => void)>();

    /**
     * 데이터 state가 change 되었을 때 호출되는 이벤트
     * [AfterStateChangeEventArgs]
     */
    public readonly onAfterStateChange = new ChaningEvent<((e: Events.AfterStateChangeEventArgs) => void)>();

    /**
     * 데이터 sort가 change 되었을 때 전에 호출되는 이벤트
     * [BeforeSortChangeEventArgs]
     */
    public readonly onBeforeSortChange = new ChaningEvent<((e: Events.BeforeSortChangeEventArgs) => void)>();

    /**
     * 데이터 sort가 change 된 후에 호출되는 이벤트
     * [AfterSortChangeEventArgs]
     */
    public readonly onAfterSortChange = new ChaningEvent<((e: Events.AfterSortChangeEventArgs) => void)>();

    /**
     * 추가버튼 클릭했을 때 호출되는 이벤트
     * [AddButtonClickEventArgs]
     */
    public readonly onAddButtonClicked = new ChaningEvent<((e: Events.AddButtonClickEventArgs) => void)>();

    /**
     * readData시 data가 없을 때 호출되는 이벤트
     * [emptyDataEventArgs]
     */
    public readonly onDisplayEmptySet = new ChaningEvent<((e: Events.EmptySetEventArgs) => void)>();

    /**
     * readData시 data가 없을 때 호출되는 이벤트
     * [emptyDataEventArgs]
     */
    public readonly onItemClick = new ChaningEvent<((e: Events.ItemClickEventArgs) => void)>();

    /**
     * 항목이 더블 클릭 되었을 떄 호출되는 이벤트
     */
    public readonly onItemDoubleClick = new ChaningEvent<((e: Events.ItemDoubleClickEventArgs) => void)>();

    /**
     * 개인정보 암호화 - 개인정보 조회 이벤트
     */
    public readonly onGetPrivacy = new ChaningEvent<(e: Events.PrivacyEventArgs) => Promise<{ privacyKey: string, privacyValue: string }[]>>();

    /**
     * 개인정보 암호화 - 개인정보 조회 완료 이벤트
     */
    public readonly onPrivacyRetrieved = new ChaningEvent<(e: Events.PrivacyRetrievedEventArgs) => void>();
    ////////// 초기 constructor 선언 ////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /**
     * @internal
     * _dataAdapter
     * OBTCardListInterface 내부 사용
     */
    private _dataAdapter: IDataAdapter | null = null;
    /**
     * @internal
     * dataAdapter()
     * OBTCardList - 사용할 get함수
     */
    public get dataAdapter() { return this._dataAdapter; }
    /**
     * @internal
     * dataAdapter()
     * OBTCardList - 사용할 set함수
     */
    public set dataAdapter(dataAdapter) { this._dataAdapter = dataAdapter; }

    /**
     * @internal
     * _cardListTemplate
     * OBTCardListInterface 내부 사용
     */
    private _cardListTemplate: ICardListTemplate | null = null;
    /**
     * @internal
     * cardListTemplate()
     * OBTCardList - 사용할 get함수
     */
    public get cardListTemplate() { return this._cardListTemplate; }

    /**
     * @internal
     * _appendButton
     * OBTCardListInterface 내부 사용
     */
    private _addButton: IAddButton | null = null;

    /**
     * @internal
     * appendButton()
     * OBTCardList - 사용할 get함수
     */
    public get addButton() { return this._addButton; }

    /**
     * @internal
     * _listHeight
     * OBTCardListInterface 내부 사용
     */
    private _listHeight: number | null = null;
    /**
     * @internal
     * listHeight()
     * OBTCardList - 사용할 get함수
     */
    public get listHeight() { return this._listHeight; }

    /**
     * @internal
     * _headerCheckVisible
     * OBTCardListInterface 내부 사용
     */
    private _headerCheckVisible: boolean | null = null;
    /**
     * @internal
     * headerCheckVisible()
     * OBTCardList - 사용할 get함수
     */
    public get headerCheckVisible() { return this._headerCheckVisible; }

    /**
     * @internal
     * _checkable
     * OBTCardListInterface 내부 사용
     */
    private _checkable: boolean | null = null;
    /**
     * @internal
     * checkable()
     * OBTCardList - 사용할 get함수
     */
    public get checkable() { return this._checkable; }

    /**
     * @internal
     * _disabledCheckProperty
     * OBTCardListInterface 내부 사용
     */
    public _disabledCheckProperty: string = 'disabledCheck';
    /**
     * @internal
     * disabledCheckProperty()
     * OBTCardList - 사용할 get함수
     */
    public get disabledCheckProperty() { return this._disabledCheckProperty }

    /**
     * @internal
     * _headerClassName
     * OBTCardListInterface 내부 사용
     */
    private _headerClassName: string | null = null;
    /**
     * @internal
     * headerClassName()
     * OBTCardList - 사용할 get함수
     */
    public get headerClassName() { return this._headerClassName; }

    /**
     * @internal
     * _headerComponent
     * OBTCardListInterface 내부 사용
     */
    private _headerComponent: React.ReactNode | (() => React.ReactNode) | null = null;
    /**
     * @internal
     * headerComponent()
     * OBTCardList - 사용할 get함수
     */
    public get headerComponent() { return this._headerComponent; }

    /**
     * @internal
     * _onRenderItem
     * OBTCardListInterface 내부 사용
     */
    private _onRenderItem: onRenderItem | null = null;
    /**
     * @internal
     * onRenderItem()
     * OBTCardList - 사용할 get함수
     */
    public get onRenderItem() { return this._onRenderItem; }
    /**
     * @internal
     * onRenderItem()
     * OBTCardList - 사용할 set함수
     */
    public set onRenderItem(onRenderItem) { this._onRenderItem = onRenderItem; }

    /**
    * @internal
    * _appendable
    * OBTCardListInterface 내부 사용
    */
    private _appendable: boolean | false = false;

    /**
    * @internal
    * _editable
    * OBTCardListInterface 내부 사용
    */
    private _editable: boolean | false = false;
    /**
    * @internal
    * _removable
    * OBTCardListInterface 내부 사용
    */
    private _removable: boolean | false = false;
    /**
    * @internal
    * _paging
    * OBTCardListInterface 내부 사용
    */
    private _paging: boolean | false = false;
    /**
    * @internal
    * _rowCountPerPage
    * OBTCardListInterface 내부 사용
    */
    private _rowCountPerPage: number | null = null;
    /**
     *  @internal
     * 페이징 정보 
     * 1. totalRowCount
     * 2. pageCount
     * 3. currentPage
     * 4. startRowIndex
     * 5. endRowIndex
     */
    private _pagingInfo: Pagination | undefined;
    /**
     * @internal
     * append 빈row data
     */
    private _appendEmptyData: any[] | null = null;
    /**
     * @internal
     * radius 
     */
    private _borderRadius: string | null = null;
    /**
     * @internal
     * padding
     */
    private _itemPadding: IItemPadding | null = null;
    /**
     * @internal
     * img, text
     */
    private _emptyImgText: ICardListEmptySet | null = null;

    /**
     * body 이미지 세팅
     */
    /**
      * @internal
      * enum 으로 시점
      * 1. 기본 초기 데이터가 없을 경우
      * 2. read 후 검색 결과가 없을 경우
      * 3. 추가 버튼 있을 경우 
      */
    private _emptySet: EmptySetBody | null = null;

    private _privacyColumns: string[] | null = null;

    private _useCheckPen: boolean = true;

    private _memoCategory: string | null = null;

    private _reservedColumnNames: {
        memoCode: string;
        checkPen: string;
        insertDateTime: string;
        insertIPAddress: string;
        insertUserId: string;
        modifiedIPAddress: string;
        modifyDateTime: string;
        modifyUserId: string;
    } = {
            memoCode: 'memoCd',
            checkPen: 'checkPen',
            insertDateTime: 'insertDt',
            insertIPAddress: 'insertIp',
            insertUserId: 'insertId',
            modifiedIPAddress: 'modifyIp',
            modifyDateTime: 'modifyDt',
            modifyUserId: 'modifyId'
        };
    ////////// initialize 선언 ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /**
     * @internal
     * owner
     * OBTCardListInterface 내부 사용
     */
    private owner: any | null = null;
    /**
     * @internal
     * _data
     * OBTCardListInterface 내부 사용
     */
    private _data: any[] | null = null;
    /**
     * @internal
     * data()
     * OBTCardList - 사용할 get함수
     */
    public get data() { return this._data; }
    /**
     * @internal
     * _sortList
     * OBTCardListInterface 내부 사용
     */
    private _sortList: Array<Events.IDropList> | null = null;
    /**
     * @internal
     * sortList()
     * OBTCardList - 사용할 get함수
     */
    public get sortList() { return this._sortList; }
    /**
     * @internal
     * _dropDownValue
     * OBTCardListInterface 내부 사용
     */
    private _dropDownValue: string | null = null;
    /**
     * @internal
     * data()
     * OBTCardList - 사용할 get함수
     */
    public get dropDownValue() { return this._dropDownValue; }
    /**
     * @internal
     * _displayType
     * OBTCardListInterface 내부 사용
     */
    private _displayType: DisplayType | null = null;
    /**
     * @internal
     * displayType()
     * OBTCardList - 사용할 get함수
     */
    public get displayType() { return this._displayType; }

    public get useCheckPen() {
        return this._useCheckPen;
    }

    public set useCheckPen(useCheckPen: boolean) {
        this._useCheckPen = useCheckPen;
    }

    private _comparer

    /**
     * @internal
     * page
     * true, false 일지
     */
    public get paging() {
        return this._paging;
    }
    /**
     * @internal
     * 현재페이지
     */
    public get currentPage() {
        return this._currentPage;
    }
    /**
     * @internal
     * 페이지갯수
     */
    public get pageCount() {
        return this._pageCount;
    }
    /**
     * @internal
     * 그리드의 보여줄 로우 갯수
     */
    public get rowCountPerPage() {
        return this._rowCountPerPage;
    }
    /**
     * @internal
    * 페이징의 총 데이터 갯수
    */
    public get totalCount() {
        return this._totalCount;
    }
    /**
     * @internal
     * radius
     */
    public get borderRadius() {
        return this._borderRadius;
    }
    /**
     * @internal
     * itemPadding
     */
    public get itemPadding() {
        return this._itemPadding;
    }
    /**
     * @internal
     * emptySet 속성
     */
    public get emptySet() {
        return this._emptySet;
    }
    /**
     * @internal
     * emptyImgText 속성
     */
    public get emptyImgText() {
        return this._emptyImgText;
    }

    public get privacyColumns() {
        return this._privacyColumns;
    }

    public get memoCategory() {
        return this._memoCategory;
    }

    public get reservedColumnNames() {
        return this._reservedColumnNames
    }

    ////////// 기타 선언 ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /**
     * @internal
     * 삭제된값들 저장 (data관리이므로 삭제되면 delete에서 못 찾아서)
     */
    private _deleteStateRows: any[] = [];
    /**
     * @internal
     * page카운터
     */
    private _pageCount: number = 0;
    /**
     * @internal
     * 현재 페이지
     */
    private _currentPage: number = 1;
    /**
     * @internal
     * 총 페이지
     */
    private _totalCount: number = 0;
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 

    private _pageAuthority: IPageAuthority = {
        modifyAuthYn: "Y",
        printAuthYn: "Y",
        selectAuthType: "NO",
        deleteAuthYn: "Y",
    }

    /**
     * 카드리스트의 생성자 입니다.
     */
    constructor(options: {
        /**
         * Read(), Store() 사용할 속성입니다. 
         */
        dataAdapter: IDataAdapter,
        /**
         * 행 추가 설정입니다. 
         * ( default : false )
         */
        appendable?: boolean,
        /**
         * Tamplete 기본 형식으로 사용합니다. 
         * (단. listHeight 무시, onRenderItem 무시)
         */
        cardListTemplate?: ICardListTemplate,
        /**
         * 사용자가 직접 Tamplete 만들시 각 List의 높이 설정 
         * (단. cardListTemplate 사용 시 listHeight : 50 고정 )
         */
        listHeight?: number,
        /**
         * 사용자가 직접 Tamplete을 만들시 
         * div스타일 및 데이터 설정 
         * (단. cardListTemplate 있을 시 무시 )
         */
        onRenderItem?: onRenderItem,
        /**
         * 헤더 체크박스 설정
         * ( default : false )
         */
        headerCheckVisible?: boolean,
        /**
         * list 체크박스 설정
         * ( default : false )
         */
        checkable?: boolean,
        /**
         * 아이템의 체크상태를 비활성화 시킬 수 있는 키값으로 사용할 string지정
         */
        disabledCheckProperty?: string,
        /**
         * 헤더 엘리먼드 들어갈 콤퍼넌트 속성입니다.
         */
        headerComponent?: React.ReactElement | (() => React.ReactElement),
        /**
         * 헤더 부분 스타일 수정할 수 있는 속성입니다.
         */
        headerClassName?: string,
        /**
         * 카드리스트의 페이징 사용여부
         */
        paging?: boolean,
        /**
         * 카드리스트의 보여줄 로우 갯수 10 | 20 | 30 | 40 | 50
         */
        rowCountPerPage?: number,
        /**
         * Add 카드리스트 템플릿 (추가)
         */
        appendButton?: IAddButton,
        /**
         * border-radius 카드리스트 스타일
         */
        borderRadius?: string,
        /**
         * Item-padding 카드리스트 padding (입체효과)
         */
        itemPadding?: IItemPadding,
        /**
         * 개인정보 암호화 적용 컬럼목록
         */
        privacyColumns?: string[],
        /**
         * 메모의 카테고리
         */
        memoCategory?: string,
        /**
         * 
         */
        reservedColumnNames?: {
            memoCode: string;
            checkPen: string;
            insertDateTime: string;
            insertIPAddress: string;
            insertUserId: string;
            modifiedIPAddress: string;
            modifyDateTime: string;
            modifyUserId: string;
        }
    }) {
        /**
         * @internal 
         * CardList 기초 생성자
         * 1. dataAdapter : Read()나 Store() 필수
         * 2. appendable : 마지막 행에서 엔터입력시 새로운 로우를 추가할 것인지 여부
         * 3. cardListtemplate : Tamplete 형식으로 사용할 경우 (단. listHeight 무시, onRenderItem 무시)
         * 4. listHeight : 사용자가 직접 Tamplete 만들시 각 List의 높이 설정        (단. cardListtemplate 있을시 listHeight : 70 고정 : 원래는 64인데 padding top, bottom 3p )
         * 5. onRenderItem : 사용자가 직접 Tamplete 만들시 div스타일 및 데이터 설정  (단. cardListtemplate 있을시 무시 )
         * 6. headerCheckVisible: 헤더 체크박스 설정
         * 7. chekable : list 체크박스 설정
         * 8. disabledCheckProperty : list 체크박스를 비활성화 시킬 수 있는 키값 변경
         * 9. headerComponent : 헤더 엘리먼드 들어갈 콤퍼넌트  
         * 10. headerClassName : 헤더 부분 스타일 수정할 수 있는 속성
         * 11. paging : 페이징 사용여부
         * 12. rowCountPerPage : 카드 리스트의 보여줄 로우 갯수 10 | 20 | 30 | 40 | 50 (default : 10)
         * 13. appendButton : 카드 리스트 하단의 append 스타일
         * 14. borderRadius : 리스트 테두리 스타일 
         * 15. itemPadding : 리스트 padding 스타일
         * 16. emptySet : 데이터 없을 시 Body에 보여줄 이미지, 텍스트
         */
        this._dataAdapter = options.dataAdapter;
        this._appendable = options.appendable === undefined ? false : options.appendable;
        this._cardListTemplate = options.cardListTemplate === undefined ? null : options.cardListTemplate;
        this._listHeight = (this._cardListTemplate !== null || options.listHeight === undefined) ? 70 : options.listHeight;
        this._onRenderItem = options.onRenderItem !== undefined ? options.onRenderItem : null;
        this._headerCheckVisible = options.headerCheckVisible === undefined ? false : options.headerCheckVisible;
        this._checkable = options.checkable === undefined ? false : options.checkable;
        this._disabledCheckProperty = options.disabledCheckProperty !== undefined ? options.disabledCheckProperty : 'disabledCheck';
        this._headerComponent = options.headerComponent !== undefined ? options.headerComponent : null;
        this._headerClassName = options.headerClassName !== undefined ? options.headerClassName : null;
        this._paging = options.paging === undefined ? false : options.paging;
        this._rowCountPerPage = options.rowCountPerPage !== undefined ? options.rowCountPerPage : 10;
        this._addButton = options.appendButton !== undefined ? options.appendButton : null;
        this._borderRadius = options.borderRadius !== undefined ? options.borderRadius : null;
        this._itemPadding = options.itemPadding !== undefined ? options.itemPadding : null;
        this._emptySet = this._appendable === true ? EmptySetBody.emptyWrite : EmptySetBody.emptyData;
        this._privacyColumns = options.privacyColumns ? options.privacyColumns : null;
        this._memoCategory = options.memoCategory === undefined ? null : options.memoCategory;
        this._reservedColumnNames = {
            ...(options.reservedColumnNames || {}),
            memoCode: 'memoCd',
            checkPen: 'checkPen',
            insertDateTime: 'insertDt',
            insertIPAddress: 'insertIp',
            insertUserId: 'insertId',
            modifiedIPAddress: 'modifyIp',
            modifyDateTime: 'modifyDt',
            modifyUserId: 'modifyId'
        };
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Functions
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////초기 함수////////////////////////////////////////////////////////////////////////////

    /**
     * CardList 초기값 세팅
     * setHeaderCheckVisible(visible) : 카드리스트에 체크리스트 보여주기 여부 속성입니다.
     * @param {boolean} visible
     */
    public setHeaderCheckVisible(visible: boolean): OBTCardListInterface {
        this._headerCheckVisible = visible;
        this._checkable = visible;
        return this;
    }

    /**
     * CardList 초기값 세팅
     * setSort(value, sortList) : 카드리스트에 SortList (상단 드랍다운리스트) 정렬 여부 속성입니다.
     * @param {string} index
     * @param {Array<IDropList>} sortList
     * @param {json} options (선택) { displayType?: DisplayType }
     */
    public setSort(index: string, sortList: Array<Events.IDropList>, options?: { displayType?: DisplayType, comparer?: (sortItem: Events.IDropList, a: any, b: any) => number }): OBTCardListInterface {
        this._sortList = sortList;
        this._dropDownValue = index;
        this._comparer = options ? options.comparer : undefined;
        this._displayType = (options ? options.displayType : undefined) || DisplayType.valueText;
        return this;
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////권한 설정 함수////////////////////////////////////////////////////////////////////////////////

    /**
     *  @internal
     * CardList 권한값 설정
     * 1. appendable() : 사용자설정에 따라 addRow()
     */
    public get appendable(): boolean {
        return this._appendable;
    }

    /**
     *  @internal
     * CardList 권한값 설정
     * 2. editable() : 사용자설정에 따라 setValue()
     */
    private get editable(): boolean {
        return this._editable;
    }

    /**
     *  @internal
     * CardList 권한값 설정
     * 3. removable() : 사용자설정에 따라 removeRow()
     */
    private get removable(): boolean {
        return this._removable;
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////// 사용자 제공함수///////////////////////////////////////////////////////////////////////
    /**
    * Function() : Row
    * getRowCount() : 카드 리스트에서 전체 Row 수를 가지오는 함수입니다.
    */
    public getRowCount(): number {
        // 데이터 및 rowindex 존재 체크 여부
        if (!this._isDataExists()) return -1;

        return this._data !== null ? this._data.length : 0;
    }

    /**
   * Function() : Row
   * getRowIndexes() : 카드 리스트에서 Row에 해당하는 index들을 가지오는 함수입니다.  
   * @param {json} options (선택) 
   * 1. checkedOnly : 체크된 index 가져오기
   * 2. states : ( GridState.none, GridState.added, GridState.modified, GridState.deleted )  
   */
    public getRowIndexes(options?: {
        checkedOnly?: boolean,
        states?: GridState[]
    }): number[] {
        // 데이터 및 rowindex 존재 체크 여부
        if (!this._isDataExists()) return [];

        let rowIndexes: number[] | null = null;
        if (this._data) {
            if (options && Object.keys(options).length > 0) {
                if (options.checkedOnly === true) {
                    rowIndexes = this._data.map((data, rowIndex) => ({ data, rowIndex })).filter(item => item.data._checked).map(item => item.rowIndex);
                }
                if (options.states && options.states.length > 0) {
                    rowIndexes = (rowIndexes ? rowIndexes : this._data.map((data, rowIndex) => rowIndex))
                        .filter(rowIndex => options.states ? options.states.includes(this.getState(rowIndex)) : false);
                }
            } else {
                rowIndexes = this._data.map((data, rowIndex) => rowIndex)
            }
        }
        return rowIndexes!;
    }

    public setGridOption(options: {
        dataAdapter: IDataAdapter,
        appendable?: boolean,
        cardListTemplate?: ICardListTemplate,
        listHeight?: number,
        onRenderItem?: onRenderItem,
        headerCheckVisible?: boolean,
        checkable?: boolean,
        disabledCheckProperty?: string,
        headerComponent?: React.ReactElement | (() => React.ReactElement),
        headerClassName?: string,
        paging?: boolean,
        rowCountPerPage?: number,
        appendButton?: IAddButton,
        borderRadius?: string,
        itemPadding?: IItemPadding,
        privacyColumns?: string[],
        memoCategory?: string,
        reservedColumnNames?: {
            memoCode: string;
            checkPen: string;
            insertDateTime: string;
            insertIPAddress: string;
            insertUserId: string;
            modifiedIPAddress: string;
            modifyDateTime: string;
            modifyUserId: string;
        }
    }) {
        this._dataAdapter = options.dataAdapter ? options.dataAdapter : this._dataAdapter;
        this._appendable = options.appendable !== undefined ? options.appendable : this._appendable;
        this._cardListTemplate = options.cardListTemplate ? options.cardListTemplate : this._cardListTemplate;
        this._listHeight = options.listHeight ? options.listHeight : this._listHeight;
        this._onRenderItem = options.onRenderItem ? options.onRenderItem : this._onRenderItem;
        this._headerCheckVisible = options.headerCheckVisible !== undefined ? options.headerCheckVisible : this._headerCheckVisible;
        this._checkable = options.checkable !== undefined ? options.checkable : this._checkable;
        this._disabledCheckProperty = options.disabledCheckProperty !== undefined ? options.disabledCheckProperty : 'disabledCheck';
        this._headerComponent = options.headerComponent ? options.headerComponent : this._headerComponent;
        this._headerClassName = options.headerClassName ? options.headerClassName : this._headerClassName;
        this._paging = options.paging !== undefined ? options.paging : this._paging;
        this._rowCountPerPage = options.rowCountPerPage ? options.rowCountPerPage : this._rowCountPerPage;
        this._addButton = options.appendButton !== undefined ? options.appendButton : this._addButton;
        this._borderRadius = options.borderRadius ? options.borderRadius : this._borderRadius;
        this._itemPadding = options.itemPadding ? options.itemPadding : this._itemPadding;
        this._privacyColumns = options.privacyColumns ? options.privacyColumns : this._privacyColumns;
        this._memoCategory = options.memoCategory === undefined ? null : options.memoCategory;
        this._reservedColumnNames = {
            ...(options.reservedColumnNames || {}),
            memoCode: 'memoCd',
            checkPen: 'checkPen',
            insertDateTime: 'insertDt',
            insertIPAddress: 'insertIp',
            insertUserId: 'insertId',
            modifiedIPAddress: 'modifyIp',
            modifyDateTime: 'modifyDt',
            modifyUserId: 'modifyId'
        };
        return new Promise<void>(resolve => {
            this._setState({
                renderNotifier: !this.state.renderNotifier
            }, () => {
                resolve()
            })
        })
    }

    public refresh() {
        return new Promise<void>(resolve => {
            this._setState({
                renderNotifier: !this.state.renderNotifier
            }, () => {
                resolve()
            })
        })
    }

    /**
   * Function() : Row
   * getRows() : 카드 리스트에서 Row에 해당하는 Row데이터를 가지오는 함수입니다.
   * @param {json} options (선택) 
   * 1. checkedOnly : 체크된 rows 가져오기
   * 2. states :( GridState.none, GridState.added, GridState.modified, GridState.deleted )
   */
    public getRows(options?: {
        checkedOnly?: boolean,
        states?: GridState[]
    }): any[] {
        // 데이터 및 rowindex 존재 체크 여부
        if (!this._isDataExists()) return [];

        let rows: any[] = [];
        if (options && Object.keys(options).length > 0) {
            const rowIndexes = this.getRowIndexes(options);
            return rowIndexes.map((rowIndex) => this.getValues(rowIndex));
        } else {
            rows = this._data!;
        }
        return rows;
    }

    /**
   * Function() : Row
   * gerRow(rowIndex) : 카드 리스트에서 해당하는 Row를 가지오는 함수입니다.
   * @param {number} rowIndex
   */
    public getRow(rowIndex: number): any {
        // 데이터 및 rowindex 존재 체크 여부
        if (!this._isDataExists(rowIndex)) return;

        return this._data![rowIndex];
    }

    /**
   * Function() : Row
   * addRow() : 카드 리스트에서 해당하는 Row를 추가하는 함수입니다.
   * @param {number} rowIndex (선택)
   */
    public addRow(rowIndex?: number): void {
        if (this.checkPossibleGridAction('appendable') === false) {
            return;
        }

        if (this._data!.length - 1 < rowIndex!) {
            return;
        }

        ////////////appendable true 시만 addrow사용
        if (!this._appendable) return;

        let values: any = {};
        const invokeResult = this._invokeEvent<Events.BeforeAddRowEventArgs>(
            this.onBeforeAddRow,
            new Events.BeforeAddRowEventArgs(this, values), true); //true시 (콜백 데이터 체인지)

        if (invokeResult.isCancel !== true) {
            if (rowIndex !== undefined && rowIndex >= 0) {
                this._data!.splice(rowIndex, 0, values)
            } else {
                rowIndex = this.getRowCount()
                this._data!.splice(rowIndex, 0, values)
            }
            //this._scroll(rowIndex!);
            // //row가 0개에서 1개 될때 index설정 (유효, 무효성검사)
            // if (!this._data || this._data.length === 1) {
            //     //안에서 랜더링
            //     this._renderVaildateChange(rowIndex);
            // } else {
            //     this._setState({
            //         renderNotifier: !this.state.renderNotifier
            //     }, () => { this._scroll(rowIndex!) });
            // }
            this._invokeEvent<Events.AfterAddRowEventArgs>(
                this.onAfterAddRow,
                new Events.AfterAddRowEventArgs(this, rowIndex!, this._data![rowIndex!]));
            //상태설정
            this.setState(rowIndex, GridState.empty);
        }
    }

    /**
   * Function() : Row
   * removeRow(rowIndex) : 카드 리스트에서 해당하는 Row를 제거하는 함수입니다.
   * @param {number} rowIndex
   */
    public removeRow(rowIndex: number): void {
        // 데이터 및 rowindex 존재 체크 여부
        if (!this._isDataExists(rowIndex)) return;

        // //권한체크
        // if (!this.removable) return;
        const values = this.getValues(rowIndex);

        const invokeResult = this._invokeEvent<Events.BeforeRemoveRowEventArgs>(
            this.onBeforeRemoveRow,
            new Events.BeforeRemoveRowEventArgs(this, rowIndex, values));

        if (!invokeResult.isCancel) {
            //삭제변수(배열따로 넣어줌)
            let state = this.getState(rowIndex);
            //none이거나 modified일때 삭제 데이터
            if (state === (GridState.none || GridState.modified)) {
                this.setState(rowIndex, GridState.deleted);

                //state 변경시 이벤트 호출 
                this._invokeEvent<Events.AfterStateChangeEventArgs>(
                    this.onAfterStateChange,
                    new Events.AfterStateChangeEventArgs(this, this.getState(rowIndex)))

                this._deleteStateRows!.push(this._data![rowIndex])
            }
            this.data!.splice(rowIndex, 1);

            //StoreData()호출
            if (this._dataAdapter && this._dataAdapter.store) this.storeData(undefined, undefined, true);

            //index 잃어버리므로 (유효, 무효성검사)
            if (!this._data || this._data.length <= 0) {
                //안에서 랜더링
                this._renderInVaildateChange();
            } else {
                this._setState({
                    renderNotifier: !this.state.renderNotifier
                });
            }
            this._invokeEvent<Events.AfterRemoveRowEventArgs>(
                this.onAfterRemoveRow,
                new Events.AfterRemoveRowEventArgs(this, rowIndex, values));

            this._invokeEvent<Events.AfterDataChangeEventArgs>(
                this.onAfterDataChanged,
                new Events.AfterDataChangeEventArgs(this));

            this._invokeEvent<Events.DrawerEventArgs>(
                this.onDrawer,
                new Events.DrawerEventArgs(this, this.getCheckedRows()));

            //행이 모두 삭제 되었을 때 자동 행 생성
            if (this.appendable === true && (!this._data || this._data.length <= 0)) this.addRow();
        }
    }

    /**
   * Function() : Row
   * removeRows(rowIndexes) : 카드 리스트에서 원하는 Rows를 제거하는 함수입니다.
   * @param {number[]} rowIndexes 
   */
    public removeRows(rowIndexes: number[]) {
        // 데이터 및 rowindex 존재 체크 여부
        if (!this._isDataExists()) return;

        // 아이템 인덱스를 로우아이디등 필요한 데이터를 가지고있는 오브젝트로 매핑한다. 
        const dataIds: any[] = rowIndexes.map(rowIndex => {
            const values = this.getValues(rowIndex);
            const invokeResult = this._invokeEvent<Events.BeforeRemoveRowEventArgs>(
                this.onBeforeRemoveRow,
                new Events.BeforeRemoveRowEventArgs(this, rowIndex, values));
            return {
                itemIndex: rowIndex,
                value: values,
                isCancel: invokeResult.isCancel
            }
        });

        //거꾸로삭제
        for (let rowIndex = dataIds.length - 1; rowIndex >= 0; rowIndex--) {
            // Cancel 아닐경우만 
            if (dataIds[rowIndex].isCancel === false) {
                //삭제변수(배열따로 넣어줌)
                let state = this.getState(dataIds[rowIndex].itemIndex);
                //none이거나 modified일때 삭제 데이터
                if (state === (GridState.none || GridState.modified)) {
                    this.setState(rowIndex, GridState.deleted);

                    //state 변경시 이벤트 호출 
                    this._invokeEvent<Events.AfterStateChangeEventArgs>(
                        this.onAfterStateChange,
                        new Events.AfterStateChangeEventArgs(this, this.getState(rowIndex)));

                    this._deleteStateRows!.push(this._data![dataIds[rowIndex].itemIndex])
                }
                this.data!.splice(dataIds[rowIndex].itemIndex, 1);
            }
        }

        ///StoreData()호출
        if (this._dataAdapter && this._dataAdapter.store) this.storeData(undefined, undefined, true);

        //index 잃어버리므로 (유효, 무효성검사)
        if (!this._data || this._data.length <= 0) {
            //안에서 랜더링
            this._renderInVaildateChange();
        } else {
            this._setState({
                renderNotifier: !this.state.renderNotifier
            });
        }

        dataIds.filter(i => i.isCancel === false).forEach(i => {
            this._invokeEvent<Events.AfterRemoveRowEventArgs>(
                this.onAfterRemoveRow,
                new Events.AfterRemoveRowEventArgs(this, i.itemIndex, i.value));

            this._invokeEvent<Events.AfterDataChangeEventArgs>(
                this.onAfterDataChanged,
                new Events.AfterDataChangeEventArgs(this));
        });

        this._invokeEvent<Events.DrawerEventArgs>(
            this.onDrawer,
            new Events.DrawerEventArgs(this, this.getCheckedRows()));

        //행이 모두 삭제 되었을 때 자동 행 생성
        if (this.appendable === true && (!this._data || this._data.length <= 0)) this.addRow();
    }

    /**
     * Function() : Value
     * getValues(rowIndex) : 카드 리스트에서 해당하는 Row의 values의 값을 가지오는 함수입니다.
     * @param {number} rowIndex
     */
    public getValues(rowIndex: number): any {
        // 데이터 및 rowindex 존재 체크 여부
        if (!this._isDataExists(rowIndex)) return;

        return this._data![rowIndex];
    }

    /**
     * Function() : Value
     * getValue(rowIndex, columnName) : 카드 리스트에서 해당하는 Row의 column에 맞는 value 값을 가지오는 함수입니다.
     * @param {number} rowIndex
     * @param {string} columnName
     */
    public getValue(rowIndex: number, columnName: string): any {
        // 데이터 및 rowindex 존재 체크 여부
        if (!this._isDataExists(rowIndex)) return;
        return this._data![rowIndex][columnName];
    }

    /**
     * Function() : Value
     * setValue(rowIndex, columnName, value) : 카드 리스트에서 해당하는 Row의 column에 맞는 value 값을 설정하는 함수입니다.
     * @param {number} rowIndex
     * @param {string} columnName
     * @param {any} value
     */
    public setValue(rowIndex: number, columnName: string, value: any): void {
        if (this.checkPossibleGridAction('editable') === false) {
            return;
        }

        this._setValue(rowIndex, columnName, value);
    }

    /**
     * Function() : Value
     * setValues(rowIndex, values) : 카드 리스트에서 해당하는 Row의 values의 값을 설정하는 함수입니다.
     * @param {number} rowIndex
     * @param {any} values
     */
    public setValues(rowIndex: number, values: any): void {
        if (this.checkPossibleGridAction('editable') === false) {
            return;
        }

        this._setValues(rowIndex, values);
    }

    /**
     * Function() : State
     * getState(rowIndex) : 카드 리스트에서 해당하는 state의 값을 가지오는 함수입니다.
     * @param {number} rowIndex
     */
    public getState(rowIndex: number): GridState {
        // 데이터 및 rowindex 존재 체크 여부
        if (!this._isDataExists(rowIndex)) return GridState.none;

        //_state가 undefined이면 모두 none으로 처리
        if (this._data![rowIndex]._state === undefined)
            this._data![rowIndex]._state = GridState.none;
        return this._data![rowIndex]._state
    }

    /**
     * Function() : State
     * setState(rowIndex, state) : 카드 리스트에서 해당하는 state의 값을 설정하 함수입니다.
     * @param {number} rowIndex
     * @param {GridState} state
     */
    public setState(rowIndex: number, state: GridState): void {
        // 데이터 및 rowindex 존재 체크 여부
        if (!this._isDataExists(rowIndex)) return;

        this._data![rowIndex]._state = state;
    }

    /**
     * Function() : Read, Store (Promise)
     * readData() : 카드 리스트에서 Data를 Read에서 설정하는 Callback 함수입니다.
     * @param {Read} readCallback (선택)
     * @param {number} currentPage (선택)
     * @param {number} rowCountPerPage (선택)
     */
    public readData(readCallback?: Read, currentPage?: number, rowCountPerPage?: number, clear?: boolean): Promise<any> {
        // 추가항목 있을경우는 appendable, 없을경우는 read 상태
        this._emptySet = this.appendable === true ? EmptySetBody.emptyWrite : EmptySetBody.emptySearch;
        this._pagingInfo = undefined;
        return new Promise((resolve, reject) => {
            const _readCallback = readCallback ? readCallback : this.dataAdapter ? this.dataAdapter.read : null;
            if (_readCallback) {
                const callReadFunctionAndBind = (readCallback: Read, callback: (value?: any[]) => void) => {
                    readCallback(new Events.ReadEventArgs(
                        this,
                        this._pagingInfo
                    )).then((data) => {
                        //paging처리에서 appendable 시 
                        //paging에서 added열은 계속 empty에 유지시켜야하므로
                        if (!(clear === undefined ? true : clear) && this._data !== null && this._appendable === true) {
                            this._appendEmptyData = this._data.filter(function (data) {
                                return data._state === GridState.added;
                            })
                        }

                        //초기화 
                        this._data = [];
                        this._data = data ? data : null;

                        this.owner.setState({ headCheked: false });

                        //정렬옵션 따라가기
                        if (this._data) {
                            if (this._sortList && this._dropDownValue) {
                                const sortType = this._sortList[this._dropDownValue];
                                if (sortType) {
                                    const sortColumn = sortType.columnName;
                                    if (this._comparer && sortType) {
                                        this._data.sort((a, b) => {
                                            const compared = this._comparer(sortType, a, b);
                                            return compared === undefined || compared === null || isNaN(compared) ? a[sortColumn] < b[sortColumn] ? -1 : a[sortColumn] > b[sortColumn] ? 1 : 0 : compared;
                                        });
                                    } else {
                                        this._data.sort(function (a, b) {
                                            return a[sortColumn] < b[sortColumn] ? -1 : a[sortColumn] > b[sortColumn] ? 1 : 0;
                                        });
                                    }
                                }
                            }
                        }
                        //appendable 추가 기능
                        if (this.checkPossibleGridAction('appendable') === true) {//this._appendable === true) {
                            if (this._data !== null) {
                                //paging에서 appendable 데이터 가지고 있으면 addRow()대신 넣어준다.
                                if (this._appendEmptyData !== null && this._appendEmptyData.length !== 0) {
                                    this._data.push(this._appendEmptyData[0]);
                                    //초기화
                                    this._appendEmptyData = null;
                                }
                                this.addRow();
                            }
                        }
                        //data 무효성 검사
                        if (!this._data || this._data.length < 0) { //index 무효 
                            this._renderInVaildateChange();
                        }
                        else {
                            /**
                             * 1. appendable 사용 X 시 paging x 시 0번째 index  
                             * 2. appendable 사용 X 시 paging O 시 처음 조회시는 0번째 index, paging read시 (1. 이전 index가 데이터보다 더 클경우 마지막으로)
                             * 3. appendable 사용 O 시 paging O 시 처음 조회시는 마지막번째 index, paging read시 (1. add된경우가 있으므로 데이터하나 증가된거 빼주기, 2. 이전 index)  
                             * 4. appendable 사용 O 시 paging x 시 마지막번째 index 
                             * */
                            let rowIndex = this.owner.state.focusedIndex;
                            if (rowIndex === -1) rowIndex = 0;
                            this._renderVaildateChange(rowIndex); //index ReadData만 0으로 유효
                        }

                        if (!this.onAfterRead.events.find((event) => {
                            try {
                                event(new Events.AfterReadEventArgs(this, data || []));
                                return false;
                            } catch (error) {
                                console.error(error);
                                return true;
                            }
                        })) {
                            setTimeout(() => {
                                if (data) {
                                    callback(data);
                                }
                            }, 0);
                        } else {
                            setTimeout(() => {
                                reject('OBTCardListInterface.readData: errors occurred at onAfterRead event handler.');
                            }, 0);
                        }
                    })
                        .catch((error) => reject(error));
                }

                //페이징 함수
                if (this._paging === true) {
                    if (this.dataAdapter && this.dataAdapter.readTotalCount) {
                        this.dataAdapter.readTotalCount(new Events.ReadTotalCountEventsArgs(this)).then((toTotalCount) => {
                            if (toTotalCount !== null && toTotalCount !== undefined) {
                                this._rowCountPerPage = rowCountPerPage ? rowCountPerPage : this._rowCountPerPage;
                                this._pagingInfo = new Pagination(
                                    //totalCount 0 아닐경우 기존 늘어난 totalCount
                                    toTotalCount,
                                    this._rowCountPerPage,
                                    currentPage ? currentPage : undefined
                                );
                            }
                            if (this._pagingInfo) {
                                if ((this._pagingInfo.currentPage || 1) > this._pagingInfo.pageCount) {
                                    this._pagingInfo.currentPage = 1;
                                }
                                this._totalCount = this._pagingInfo.toTotalCount!;
                                this._pageCount = this._pagingInfo.pageCount;
                                this._currentPage = this._pagingInfo.currentPage || 1;
                            }

                            callReadFunctionAndBind(_readCallback, resolve);
                        });
                    } else {
                        // readPage를 정의하지 않고, page 쓸경우
                        const pagingInfo = new Pagination(
                            //totalCount 0 아닐경우 기존 늘어난 totalCount
                            undefined,
                            this._rowCountPerPage,
                            undefined
                        );
                        this._pagingInfo = pagingInfo;
                        this._totalCount = pagingInfo.toTotalCount || 0;
                        this._pageCount = pagingInfo.pageCount;
                        this._currentPage = pagingInfo.currentPage || 1;

                        callReadFunctionAndBind(_readCallback, resolve);
                    }
                } else {
                    callReadFunctionAndBind(_readCallback, resolve);
                }
            } else {
                reject(new Error('dataAdapter or readCallback is required.'));
            }
        })
    }

    /**
     * Function() : Read, Store (Promise)
     * storeData() : 카드 리스트에서 Data의 결과를 DML처리 하려는 Callback 함수입니다.
     * @param {number} rowIndex (선택)
     * @param {Store} storeCallback (선택)
     */
    public storeData(rowIndex?: number, storeCallback?: Store, removeRowsMode?: boolean): Promise<void> {
        return new Promise((resolve, reject) => {
            const _store = storeCallback ? storeCallback : this.dataAdapter && this.dataAdapter.store ? this.dataAdapter.store : null;
            if (_store) {
                const indexes: Events.IStoreIndexes = {
                    added: [],
                    modified: []
                };
                const data: Events.IStoreData = {
                    added: [],
                    modified: [],
                    deleted: []
                };

                if (rowIndex !== undefined) {
                    const state = this.getState(rowIndex);
                    if (indexes[state]) {
                        indexes[state].push(rowIndex);
                    }
                    if (data[state]) {
                        data[state].push(this.getValues(rowIndex))
                    }
                } else {
                    if (this._data) {
                        //index
                        this._data.forEach((data, index) => { if (data._state === GridState.added) indexes['added'].push(index) });
                        this._data.forEach((data, index) => { if (data._state === GridState.modified) indexes['modified'].push(index) });
                        // //data
                        this._data.forEach((list, index) => { if (list._state === GridState.added) data['added'].push(this.getValues(index)) });
                        this._data.forEach((list, index) => { if (list._state === GridState.modified) data['modified'].push(this.getValues(index)) });
                    }
                    if (this._deleteStateRows) {
                        this._deleteStateRows.forEach((list, index) => data['deleted'].push(this._deleteStateRows![index]));
                    }
                }

                // TODO: 급해서 땜빵코딩 해놨는데, removeRows에 storeData 호출시
                // dirtyData에 deleted만 들어가는게 아니라 added랑 modifyed 들어가는 문제가 있어 추가함.
                // 만약 체크한 로우를 삭제하는 처리를 readData하지 않고 여러번하면 deleted에 계속해서 누적될것임
                // 만약 체크한 로우를 삭제하는 처리를 readData하지 않고 여러번하면 deleted에 계속해서 누적될것임

                if (removeRowsMode !== undefined) {
                    indexes.added = [];
                    data.added = [];
                    indexes.modified = [];
                    data.modified = [];
                }

                if (data.added.length > 0 || data.modified.length > 0 || data.deleted.length > 0) {
                    const e = new Events.StoreEventArgs(this, indexes, data);
                    _store(e)
                        .then(() => {
                            if (!e.cancel) {
                                setTimeout(() => {
                                    if (e.changeState) {
                                        //초기화
                                        this._deleteStateRows = [];
                                        if (rowIndex !== undefined) {
                                            this.setState(rowIndex, GridState.none);
                                            //랜더링
                                            this.setCheck(rowIndex, false);
                                        } else {
                                            //Add추가버튼 생성시 empty는 그대로 유지시켜주기
                                            //totalcount는 저장됬으므로 1 증가
                                            let rowIndex = this._data!.length - 1;
                                            if (this._data) {
                                                this._data.forEach(dataList => {
                                                    if (this._appendable === true && dataList['_state'] === GridState.empty) {
                                                        dataList['_state'] = GridState.empty;
                                                        rowIndex = rowIndex - 1;
                                                        this._totalCount = Number(this._totalCount) + 1;
                                                    } else {
                                                        dataList['_state'] = GridState.none;
                                                    }
                                                })
                                                this._data.forEach(dataList => { dataList['_checked'] = false })
                                            }
                                            //랜더링
                                            this._setState({
                                                renderNotifier: !this.state.renderNotifier
                                            }, () => { this._scroll(rowIndex); });
                                        }
                                    }
                                    resolve();
                                }, 0);
                            }
                        });
                } else {
                    resolve();
                }
            } else {
                reject('OBTCardListInterface.storeData: storeCallback or dataProvider.store is required.');
            }
        });
    }

    /**
     * Function() : Read, Store
     * claerData() : 카드 리스트에서 전체 Row 수를 가지오는 함수입니다.
     */
    public clearData(): void {
        this._data = [];
        //따로 뺌 <handleSelection에 포커스기능 있어서 clear시 포커스 필요없음>
        //포커스함수 못쓴다. ... index를 -1설정이여서 focusElement null이므로
        this.owner.setState({ focusedIndex: -1 }, () => {
            let oldIndex = -1;
            let dataIndex = this.owner.state.focusedIndex;
            this._invokeEvent<Events.AfterChangeSelection>(
                this.onAfterChangeRowSelection,
                new Events.AfterChangeSelection(this, oldIndex, dataIndex));
            this._invokeEvent<Events.AfterDataChangeEventArgs>(
                this.onAfterDataChanged,
                new Events.AfterDataChangeEventArgs(this));
            this._invokeEvent<Events.DrawerEventArgs>(
                this.onDrawer,
                new Events.DrawerEventArgs(this, this.getCheckedRows()));
        })
    }

    /**
     * Function() : Selection
     * getSelection() : 카드 리스트에서 해당하는 Row의 index를 가지오는 함수입니다.
     */
    public getSelection(): number {
        return this.owner.state.focusedIndex;
    }

    /**
     * Function() : Selection
     * setSelection(rowIndex) : 카드 리스트에서 해당하는 Row의 index를 설정하는 함수입니다.
     * @param {number} rowIndex
     */
    public setSelection(rowIndex: number): void {
        let oldIndex = this.owner.state.focusedIndex;
        this._handleSelection(rowIndex, oldIndex);
    }

    // public getValue(rowIndex: number, columnName: string): any {
    //     // 데이터 및 rowindex 존재 체크 여부
    //     if (!this._isDataExists(rowIndex)) return;
    //     return this._data![rowIndex][columnName];
    // }

    public searchData(startIndex: number, columnName: string, value: any, selectSearchedRow: boolean = true): ICell | null {
        if (!this.data || this.data.length === 0) {
            return null;
        }

        const findedIndex = this.data.findIndex((item, index) => {
            return (index >= startIndex && item[columnName] === value);
        });

        if (findedIndex >= 0) {
            if (selectSearchedRow === true) {
                this.setSelection(findedIndex);
            }

            return {
                columnName: columnName,
                rowIndex: findedIndex,
            };
        }

        return null;
    }

    /**
    * Function() : Check
    * setCheck(rowIndex, checked) : 카드 리스트에서 해당하는 Row를 check 설정하는 함수입니다.
    * @param {number} rowIndex
    * @param {boolean} checked
    */
    public setCheck(rowIndex: number, checked: boolean): void {
        this._setCheck(rowIndex, checked, true);
    }

    /**
    * Function() : Check
    * getCheck(rowIndex) : 카드 리스트에서 해당하는 check된 Row를 가지오는 함수입니다.
    * @param {number} rowIndex
    */
    public getCheck(rowIndex: number): boolean {
        // 데이터 및 rowindex 존재 체크 여부
        if (!this._isDataExists()) return false;

        return this.data![rowIndex]._checked;
    }

    /**
    * Function() : Check
    * getHeadCheck() : 카드 리스트에서 Header에 해당하는 check된 boolean 상태를 가지오는 함수입니다.
    */
    public getHeadCheck(): boolean {
        return this.owner.state.headCheked;
    }

    /**
    * Function() : Check
    * getCheckedRows() : 카드 리스트에서 check된 Row들을 가지오는 함수입니다.
    */
    public getCheckedRows(): any[] {
        return this.getRows({
            checkedOnly: true
        });
    }

    /**
    * Function() : Check
    * getCheckedIndexes : 카드 리스트에서 check된 index들을 가지오는 함수입니다.
    */
    public getCheckedIndexes(): number[] {
        return this.getRowIndexes({
            checkedOnly: true
        });
    }

    /**
    * Function() : 포커스가 카드 리스트에 있는 지 확인여부 합수입니다
    * hasFocus() : boolean 
    */
    public hasFocus(): boolean {
        return Util.containsFocus(this.owner.myRefs.wrapper);
    }

    /**
     * @internal
     * rowIndex에 checkPenRGB 세팅하고 저장
     */
    public storeCheckPen(rowIndex: number, checkPenRGB: string) {
        const beforeState = this.getState(rowIndex);
        const selectedCardData = this.getRow(rowIndex);
        const selectedCardCheckPen = this.reservedColumnNames.checkPen;

        if (beforeState) {
            this.setValue(rowIndex, selectedCardCheckPen, checkPenRGB);
            if (this._dataAdapter && this._dataAdapter.storeCheckPen) {
                return this._dataAdapter.storeCheckPen(
                    new Events.StoreMemoCheckPenEventArgs(this,
                        rowIndex,
                        selectedCardData,
                        null,
                        selectedCardData[selectedCardCheckPen]

                    )
                ).then(() => {
                    this.setState(rowIndex, beforeState);
                });
            } else {
                return this.storeData(rowIndex);
            }
        }

        return Promise.resolve();
    }

    /**
     * @internal
     */
    public storeMemo(rowIndex: number, memoCode: string | null) {
        const beforeState = this.getState(rowIndex);
        if (beforeState) {
            this.setValue(rowIndex, this.reservedColumnNames.memoCode, memoCode);

            if (this._dataAdapter && this._dataAdapter.storeMemo) {
                return this._dataAdapter.storeMemo(
                    new Events.StoreMemoCheckPenEventArgs(this,
                        rowIndex,
                        this.getRow(rowIndex),
                        memoCode,
                        null
                    )).then((e) => {
                        this.setState(rowIndex, beforeState)
                    });
            } else {
                return this.storeData(rowIndex)
            }
        }

        return Promise.resolve();
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////내부 함수////////////////////////////////////////////////////////////////////////////
    /**
     * Function() : Change
     * 1. _invokeBeforeChange() : 값변경시 beforeChange 내부에서 관리 함수
     * 2. _invokeValidateChange() :값변경시 validateChange 내부에서 관리 함수
     * 3. _invokeAfterChange() : 값변경시 afterChange 내부에서 관리 함수
     * 4. _invokeEvent(): 값변경시 Chain으로 바뀐 Data를 가지오기 위한 함수
     * 5. _setValues() : 값 설정 내부에서 관리 함수
     * 6. _setValue() : 값 설정 내부에서 관리 함수
     * 7. _handleSelection : 해당 index 설정 내부에서 관리 함수
     * 8. _setCheck : 체크 설정 내부에서 관리 함수
     * 9. _setheadCheck : 헤더 체크 설정 내부에서 관리 함수
     * 10. _setSort : 오름차순 정렬 내부에서 관리 함수
     */

    /**
     * @internal
     * _invokeBeforeChange() : 값변경시 beforeChange 내부에서 관리 함수
     * @param {number} rowIndex
     * @param {any} values
     */
    private _invokeBeforeChange(rowIndex: number, values: any): boolean {
        return Object.keys(values).find(columnName => {
            return this.onBeforeChange.events.find(event => {
                const e = new Events.BeforeChangeEventArgs(this, rowIndex, columnName, values);
                try {
                    event(e);
                } catch (error) {
                    console.error(error);
                    return true;
                }
                return e.cancel;
            });
        }) ? false : true;
    }

    /**
     * @internal
     * _invokeBeforeChange() : 값변경시 beforeChange 내부에서 관리 함수
     * @param {number} rowIndex 
     * @param {any} oldValues 
     * @param {any} values 
     */
    private _invokeValidateChange(rowIndex: number, oldValues: any, values: any): boolean {
        return Object.keys(values).find(columnName => {
            const value = values[columnName];
            return this.onValidateChange.events.find(event => {
                const e = new Events.ValidateChangeEventArgs(this, rowIndex, columnName, oldValues[columnName], value, oldValues);
                try {
                    event(e);
                } catch (error) {
                    console.error(error);
                    return true;
                }
                return e.cancel;
            });
        }) ? false : true;
    }

    /**
     * @internal
     * _invokeAfterChange() : 값변경시 afterChange 내부에서 관리 함수
     * @param {number} rowIndex 
     * @param {any} oldValues 
     * @param {any} values 
     */
    private _invokeAfterChange(rowIndex: number, oldValues: any, values: any): void {
        Object.keys(values).find(columnName => {
            const value = values[columnName];
            return this.onAfterChange.events.find(event => {
                const e = new Events.AfterChangeEventArgs(this, rowIndex, columnName, oldValues[columnName], value, oldValues);
                try {
                    event(e);
                    return false;
                } catch (error) {
                    console.error(error);
                    return true;
                }
            }) ? true : false;
        });
        //event
        this._invokeEvent<Events.AfterDataChangeEventArgs>(
            this.onAfterDataChanged,
            new Events.AfterDataChangeEventArgs(this));
    }

    /**
     * @internal
     * _invokeEvent(): 값변경시 Chain으로 바뀐 Data를 가지오기 위한 함수
     * @param {ChaningEvent<(e: T) => void>} event 
     * @param {T} eventArgs 
     * @param {boolean} containData 
     * @param {boolean} changedData 
     */
    private _invokeEvent<T>(event: ChaningEvent<(e: T) => void>, eventArgs: T, containData?: boolean, changedData?: boolean) {
        let invokeResult = {
            handled: false,
            isCancel: false,
            returnData: null,
            emptySet: null
        };
        for (let i = 0; i < event.events.length; i++) {
            try {
                event.events[i](eventArgs);
                if (eventArgs['cancel'] === true) {
                    invokeResult.isCancel = true;
                    break;
                } else if (eventArgs['handled'] === true) {
                    invokeResult.handled = true;
                    break;
                } else if (eventArgs['emptySet'] !== null) {
                    invokeResult.emptySet = eventArgs['emptySet'];
                }
                else {
                    if (containData === true && eventArgs['data']) {
                        invokeResult.returnData = eventArgs['data'];
                    }
                }
            } catch (error) {
                console.error(error);
                invokeResult.isCancel = true;
                break;
            }
        }
        return invokeResult; //false;
    }

    /** @internal */
    private async invokePromiseEvent<T, K>(event: ChaningEvent<(e: T) => Promise<K>>, eventArgs: T) {
        let invokeResult: {
            isCancel: boolean,
            returnData: K | undefined | null
        } = {
            isCancel: false,
            returnData: null,
        };

        for (let i = 0; i < event.events.length; i++) {
            try {
                const callbackReturnValue = await event.events[i](eventArgs);
                if (eventArgs['cancel'] === true) {
                    invokeResult.isCancel = true;
                    break;
                } else if (callbackReturnValue) {
                    invokeResult.returnData = callbackReturnValue;
                }
            } catch (error) {
                console.error(error);
                invokeResult.isCancel = true;
                break;
            }
        }

        return invokeResult; //false;
    }

    /**
     * @internal
     * _setValues() : 값 설정 내부에서 관리 함수
     * @param {number} rowIndex 
     * @param {any} values 
     */
    private _setValues(rowIndex: number, values: any): void {
        // 데이터 및 rowindex 존재 체크 여부
        if (!this._isDataExists(rowIndex)) return;

        // //권한체크
        // if (!this.editable) return;

        const oldValues = this.getValues(rowIndex);
        let canceled = this._invokeBeforeChange(rowIndex, values) && this._invokeValidateChange(rowIndex, oldValues, values) ? false : true;
        if (!canceled) {
            this._data![rowIndex] = values;
            this._setState({
                renderNotifier: !this.state.renderNotifier
            });
            //상태가 setValue에서 CardList는 입력과 수정을 구분을 못함.....
            //addRow()에서 미리 added되있을경우는 added, 아닐경우는 modified처리
            let state = this.getState(rowIndex);
            if (state === GridState.empty && this._appendable) this.addRow();
            if (state === GridState.none) {
                state = GridState.modified;
            } else if (state === GridState.empty) {
                state = GridState.added;
            }
            this.setState(rowIndex, state);

            //state 변경시 이벤트 호출 
            this._invokeEvent<Events.AfterStateChangeEventArgs>(
                this.onAfterStateChange,
                new Events.AfterStateChangeEventArgs(this, this.getState(rowIndex)))

            this._invokeAfterChange(rowIndex, oldValues, values);
            this.setState(rowIndex, state);
        }
    }

    /**
     * @internal
     * _setValue() : 값 설정 내부에서 관리 함수
     * @param {number} rowIndex 
     * @param {string} columnName 
     * @param {any} value 
     */
    private _setValue(rowIndex: number, columnName: string, value: any): void {
        // 데이터 및 rowindex 존재 체크 여부
        if (!this._isDataExists(rowIndex)) return;

        // //권한체크
        // if (!this.editable) return;

        //따로 쓴 이유: _state: 값이 들어와서....
        const oldValue = this.getValue(rowIndex, columnName)
        const oldValues = { [columnName]: oldValue };
        const values = { [columnName]: value };
        let canceled = this._invokeBeforeChange(rowIndex, values) && this._invokeValidateChange(rowIndex, oldValues, values) ? false : true;
        if (!canceled) {
            this._data![rowIndex][columnName] = value;
            this._setState({
                renderNotifier: !this.state.renderNotifier
            }, () => {
                //상태가 setValue에서 CardList는 입력과 수정을 구분을 못함.....
                //addRow()에서 미리 added되있을경우는 added, 아닐경우는 modified처리
                let state = this.getState(rowIndex);
                if (state === GridState.empty && this._appendable) this.addRow();
                if (state === GridState.none) {
                    state = GridState.modified;
                } else if (state === GridState.empty) {
                    state = GridState.added
                }
                this.setState(rowIndex, state);

                //state 변경시 이벤트 호출 
                this._invokeEvent<Events.AfterStateChangeEventArgs>(
                    this.onAfterStateChange,
                    new Events.AfterStateChangeEventArgs(this, this.getState(rowIndex)))

                this._invokeAfterChange(rowIndex, oldValues, values);
            });
        }
    }

    /**
     * @internal
     * _handleSelection : 해당 index 설정 내부에서 관리 함수
     * @param {number} dataIndex 
     * @param {number} oldIndex 
     */
    public _handleSelection(dataIndex: number, oldIndex: number, keyMove: boolean = false) {
        //필수 (selection은 행이 바꼈을 때 타는것이기때문)
        if (oldIndex !== dataIndex) {
            const invokeResult = this._invokeEvent<Events.BeforeChangeSelection>(
                this.onBeforeChangeRowSelection,
                new Events.BeforeChangeSelection(this, oldIndex, dataIndex));

            if (invokeResult.isCancel === true) {
                this.owner.setState({ focusedIndex: oldIndex })
            } else {
                //StoreData()호출 추가버튼 생성되서
                if (this._dataAdapter && this._dataAdapter.store) {
                    if (!(this.getState(oldIndex) === GridState.added || this.getState(oldIndex) === GridState.empty)) this.storeData(oldIndex);
                }
                //1. keydown에서 oldIndex 관리
                //2. click에서 oldIndex 관리 
                //3. oldIndex가 3가지임( 1. key로 관리, 2. click으로 관리, 3. handleSelection 직접 관리)
                oldIndex = oldIndex === undefined ? keyMove ? this.owner.state.keyDownPreFocusedIndex : this.owner.state.focusedIndex : oldIndex;

                // 추가항목일경우는 스크롤 x
                if (!(this.getState(dataIndex) === GridState.empty || this.getState(dataIndex) === GridState.added)) this._scroll(dataIndex);
                this.owner.setState({ 
                    focusedIndex: dataIndex,
                    keyDownPreFocusedIndex: keyMove ? oldIndex : dataIndex
                }, () => {
                    if (dataIndex >= 0) {
                        let focusElement = this.owner.myRefs.wrapper.current.querySelector(`#virtual${dataIndex}`);
                        if (focusElement) {
                            focusElement.focus();
                        }
                        this._invokeEvent<Events.AfterChangeSelection>(
                            this.onAfterChangeRowSelection,
                            new Events.AfterChangeSelection(this, oldIndex!, dataIndex));

                        this._invokeEvent<Events.AfterDataChangeEventArgs>(
                            this.onAfterDataChanged,
                            new Events.AfterDataChangeEventArgs(this));

                        //추가버튼 클릭 이벤트
                        if (dataIndex > 0) {
                            this._handleAddButonClicked(dataIndex);
                        }
                    }
                })
            }
        } else {
            this._handleAddButonClicked(dataIndex);
        }
    }

    public _handleItemClick(dataIndex: number) {
        this._invokeEvent<Events.ItemClickEventArgs>(
            this.onItemClick,
            new Events.ItemClickEventArgs(this, dataIndex));
    }

    public _handleItemDoubleClick(dataIndex: number) {
        this._invokeEvent<Events.ItemDoubleClickEventArgs>(
            this.onItemDoubleClick,
            new Events.ItemDoubleClickEventArgs(this, dataIndex));
    }

    /**
     * @internal
     * 추가버튼 클릭 이벤트
     */
    private _handleAddButonClicked(dataIndex: number) {
        if (this._appendable === true) {
            if (this.getState(dataIndex) === GridState.empty) {
                this._invokeEvent<Events.AddButtonClickEventArgs>(
                    this.onAddButtonClicked,
                    new Events.AddButtonClickEventArgs(this));
            }
        }
    }

    /**
     * @internal
     * _setCheck : 체크 설정 내부에서 관리 함수
     * @param {number} rowIndex 
     * @param {boolean} checked 
     * @param {boolean} onRender 
     */
    public _setCheck(rowIndex: number, checked: boolean, onRender: boolean): void {
        // 데이터 및 rowindex 존재 체크 여부
        if (!this._isDataExists(rowIndex)) return;
        // 예외처리 (empty 일 경우 checked x)
        // if (this.data![rowIndex]._checked === GridState.empty) return;

        this.data![rowIndex]._checked = checked;

        // //체크박스가 모두 체크되었을 시 헤더체크 빼는거
        // //체크박스가 모두 하나라도 빠젔을 시 헤더체크 빼기
        const checkedData: any[] = [];
        let checkedCount = 0;
        let checkBoxDisabledCount = 0;

        if (this.data) {
            this.data.forEach(data => {
                if (data._state !== GridState.empty) {
                    checkedData.push(data);
                }
            })
        }
        checkedData.forEach(data => {
            if (data[this._disabledCheckProperty] === true) {
                checkBoxDisabledCount++;
            }
            if (data._checked === true) {
                checkedCount++;
            }
        })

        ///헤더에서 랜더링 
        if (checkedCount + checkBoxDisabledCount === checkedData.length) {
            this.owner.setState({ headCheked: true });
            onRender = false;
        }
        if (checkedCount + checkBoxDisabledCount === checkedData.length - 1) {
            this.owner.setState({ headCheked: false });
            onRender = false;
        }
        // //////////////////////////////////////////////

        //랜더링
        if (onRender) {
            this._setState({
                renderNotifier: !this.state.renderNotifier
            });
        }
        //event
        this._invokeEvent<Events.AfterCheckEventArgs>(
            this.onAfterCheck,
            new Events.AfterCheckEventArgs(this, rowIndex, checked));

        this._invokeEvent<Events.AfterDataChangeEventArgs>(
            this.onAfterDataChanged,
            new Events.AfterDataChangeEventArgs(this));

        this._invokeEvent<Events.DrawerEventArgs>(
            this.onDrawer,
            new Events.DrawerEventArgs(this, this.getCheckedRows()));
    }

    /**
     * @internal
     * _setheadCheck : 헤더 체크 설정 내부에서 관리 함수
     * @param {boolean} checked 
     */
    public _setHeadCheck(checked: boolean): void {
        // 데이터 및 rowindex 존재 체크 여부
        if (!this._isDataExists()) return;
        const disabledCheckProperty = this._disabledCheckProperty;


        if (this.owner.state.headCheked) {

            for (let index = 0; index < this._data!.length; index++) {
                if (!this._data![index][disabledCheckProperty]) {
                    this._data![index]._checked = false
                }
            }
        } else {
            for (let index = 0; index < this._data!.length; index++) {
                if (this._data![index]._state !== GridState.empty && !this._data![index][disabledCheckProperty])
                    this._data![index]._checked = true;
            }
        }
        this.owner.setState({ headCheked: checked });

        //event
        this._invokeEvent<Events.AfterHeaderCheckEventArgs>(
            this.onAfterHeaderCheck,
            new Events.AfterHeaderCheckEventArgs(this, checked));

        this._invokeEvent<Events.AfterDataChangeEventArgs>(
            this.onAfterDataChanged,
            new Events.AfterDataChangeEventArgs(this));

        this._invokeEvent<Events.DrawerEventArgs>(
            this.onDrawer,
            new Events.DrawerEventArgs(this, this.getCheckedRows()));
    }

    /**
     * @internal
     * _setSort : 오름차순 정렬 내부에서 관리 함수
     * @param {string} sortIndex 
     */
    public _setSort(sortIndex: string): void {
        if (!this._data) return;

        const values: Events.IDropList = {
            value: sortIndex,
            columnName: this._sortList![sortIndex].columnName,
            text: this._sortList![sortIndex].text
        };

        //StoreData()호출
        if (this._dataAdapter && this._dataAdapter.store) {
            if (!(this.getState(this.owner.state.focusedIndex) === GridState.added || this.getState(this.owner.state.focusedIndex) === GridState.empty))
                this.storeData();
        }

        this._dropDownValue = sortIndex;
        const invokeResult = this._invokeEvent<Events.BeforeSortChangeEventArgs>(
            this.onBeforeSortChange,
            new Events.BeforeSortChangeEventArgs(this, values));
        if (invokeResult.isCancel === true) {
            this._dropDownValue = this.owner.state.dropDownValue;
            this.owner.setState({ dropDownValue: this.owner.state.dropDownValue })
        } if (invokeResult.handled === true) {
            if (this.owner.state.dropDownValue !== sortIndex) {
                this.owner.setState({ dropDownValue: sortIndex });
            }
        } else {
            this._dropDownValue = sortIndex;
            if (this._data) {
                if (this._sortList && this._dropDownValue) {
                    const sortType = this._sortList[this._dropDownValue];
                    if (sortType) {
                        const sortColumn = sortType.columnName;
                        if (this._comparer && sortType) {
                            this._data.sort((a, b) => {
                                const compared = this._comparer(sortType, a, b);
                                return compared === undefined || compared === null || isNaN(compared) ? a[sortColumn] < b[sortColumn] ? -1 : a[sortColumn] > b[sortColumn] ? 1 : 0 : compared;
                            });
                        } else {
                            this._data.sort(function (a, b) {
                                return a[sortColumn] < b[sortColumn] ? -1 : a[sortColumn] > b[sortColumn] ? 1 : 0;
                            });
                        }
                    }
                }
            }

            //event
            this.owner.setState({ dropDownValue: sortIndex }, () => {
                this._invokeEvent<Events.AfterChangeSelection>(
                    this.onAfterChangeRowSelection,
                    new Events.AfterChangeSelection(this, this.owner.state.focusedIndex, this.owner.state.focusedIndex));

                this._invokeEvent<Events.AfterSortChangeEventArgs>(
                    this.onAfterSortChange,
                    new Events.AfterSortChangeEventArgs(this, values));

                this._invokeEvent<Events.AfterDataChangeEventArgs>(
                    this.onAfterDataChanged,
                    new Events.AfterDataChangeEventArgs(this));
            })
        }
    }

    /**
    * 기타 함수 
    * 1. index가 문제 있을시(유효) (데이터 맨끝으로) onBeforeChangeRowSelection 필요 X
    * 2. index가 문제 있을시(무효) (데이터 없을시 -1로) onBeforeChangeRowSelection 필요 X
    * 3. data나 rowIndex 존재 여부체크(방호코드)
    * 4. focus 포커스 함수(이후 이벤트 AfterChangeSelection, AfterDataChangeEventArgs)
    * 5. scroll 함수 
    * 6. Body empty 세팅 함수
    */

    /**
     * @internal
     * _renderVaildateChange() : index가 문제 있을시(유효) 
     * (데이터 맨끝으로) onBeforeChangeRowSelection 필요 X
     * @param {number} dataIndex 
     */
    private _renderVaildateChange(dataIndex: number): void {
        if (this._data && dataIndex >= this._data.length - (this.appendable ? 1 : 0)) { //유효한데 
            dataIndex = 0;
        }
        if (this.appendable === true) { 
            if (this.getState(dataIndex) === GridState.added || this.getState(dataIndex) === GridState.empty) {
                if (dataIndex !== 0) dataIndex = this._rowCountPerPage!;
            }
        }
        //포커스함수(AfterChangeSelection, AfterDataChangeEventArgs)
        this._handleSelection(dataIndex, -1);
    }

    /**
     * @internal
     * _renderInVaildateChange() : index가 문제 있을시(무효) 
     * (데이터 없을시 -1로) onBeforeChangeRowSelection 필요 X
     */
    private _renderInVaildateChange(): void {
        //포커스함수 못쓴다. ... index를 -1설정이여서 focusElement null이므로
        this.clearData();
    }

    /**
     * @internal
     * _isDataExists() : data나 rowIndex 존재 여부체크(방호코드)
     * @param {number} rowIndex 
     */
    private _isDataExists(rowIndex?: number): boolean {
        let existsYn: boolean = true;
        if (!this._data) {
            existsYn = false;
            return existsYn;
        }

        if (rowIndex !== undefined) {
            if (!this._data || rowIndex <= -1) {
                existsYn = false;
            }
            if (this._data!.length - 1 < rowIndex) {
                existsYn = false;
            }
        }

        return existsYn;
    }

    /**
     * @deprecated
     * _focus() : focus 포커스 함수 
     * (이후 이벤트 AfterChangeSelection, AfterDataChangeEventArgs)
     * (포커스 시간차 문제로 안에 같이 사용)
     * @param {number} dataIndex 
     * @param {number} oldIndex 
     * @param {boolean} keyMove 
     */
    public _focus(dataIndex: number, oldIndex?: number, keyMove: boolean = false): void {
        //1. keydown에서 oldIndex 관리
        //2. click에서 oldIndex 관리 
        //3. oldIndex가 3가지임( 1. key로 관리, 2. click으로 관리, 3. handleSelection 직접 관리)
        oldIndex = oldIndex === undefined ? keyMove ? this.owner.state.keyDownPreFocusedIndex : this.owner.state.focusedIndex : oldIndex;

        const invokeResult = this._invokeEvent<Events.BeforeChangeSelection>(
            this.onBeforeChangeRowSelection,
            new Events.BeforeChangeSelection(this, oldIndex!, dataIndex));

        // 추가항목일경우는 스크롤 x
        if (!(this.getState(dataIndex) === GridState.empty || this.getState(dataIndex) === GridState.added)) this._scroll(dataIndex);
        this.owner.setState({
            focusedIndex: invokeResult.isCancel === true ? oldIndex : dataIndex,
            keyDownPreFocusedIndex: keyMove ? oldIndex : dataIndex
        }, () => {
            if (dataIndex >= 0) {
                let focusElement = this.owner.myRefs.wrapper.current.querySelector(`#virtual${dataIndex}`);
                if (focusElement) {
                    focusElement.focus();
                }
                //무조건 여기서 해야함 ..... <setState 시간차 문제로 this.owner.state.focusedIndex를 바로 바뀐 값으로 안가지옴>
                if (!keyMove) {
                    this._invokeEvent<Events.AfterChangeSelection>(
                        this.onAfterChangeRowSelection,
                        new Events.AfterChangeSelection(this, oldIndex!, dataIndex));

                    this._invokeEvent<Events.AfterDataChangeEventArgs>(
                        this.onAfterDataChanged,
                        new Events.AfterDataChangeEventArgs(this));

                    //추가버튼 클릭 이벤트
                    if (dataIndex > 0) {
                        this._handleAddButonClicked(dataIndex);
                    }
                }
            }
        })
    }
    
    /**
     * @internal
     * scroll() : virtualBox 스크롤 계산 함수 
     * @param {number} dataIndex
     */
    public _scroll(dataIndex: number): void {
        if (this.owner.myRefs.virtualBox.current &&
            this.owner.myRefs.virtualBoxWrapper.current &&
            this.listHeight) {
            const virtualBox = this.owner.myRefs.virtualBox.current;
            const wrapper = this.owner.myRefs.virtualBoxWrapper.current;
            const scrollTop = virtualBox.getScrollTop();
            const scrollBottom = scrollTop + wrapper.offsetHeight;
            let itemTop = dataIndex * this.listHeight;
            const itemBottom = itemTop + this.listHeight;
            //padding 값 
            const itemPadding = this._cardListTemplate !== null ? 3 : 0;

            if (wrapper < itemTop) {
                itemTop = itemTop + (itemTop - wrapper)
            }
            if (itemTop < scrollTop) {
                virtualBox.setScrollTop(itemTop);
            } else if (itemBottom >= scrollBottom) {
                virtualBox.setScrollTop(itemBottom - wrapper.offsetHeight + itemPadding);
            }
        }
    }

    /**
     * @internal
     * setEmptyBodyImgText() : 카드리스트 emptyBody 설정 함수
     * @param {string} data
     */
    public _setEmptyBodyImgText(data: EmptySetBody): void {
        const invokeResult = this._invokeEvent<Events.EmptySetEventArgs>(
            this.onDisplayEmptySet,
            new Events.EmptySetEventArgs(this, data));

        if (invokeResult.emptySet !== null) {
            this._emptyImgText = invokeResult.emptySet;
        }
    }

    /**
    * Function() : 초기 함수 
    * 1. _initialize() :CardList interface 상태 내부에서 관리 함수
    * 2. _setState() : CardList interface 상태 내부에서 관리 함수
    * 3. state() : CardList interface 상태 내부에서 관리 함수
    */

    /**
     * @internal
     * _initialize() :CardList interface 상태 내부에서 관리 함수
     * @param {any} owner 
     */
    public _initialize(owner: any, pageAuthority?: IPageAuthority): void {
        this.owner = owner;

        if (pageAuthority) {
            this._pageAuthority = pageAuthority;
        }
    }

    /**
     * @internal
     * _setState() : CardList interface 상태 내부에서 관리 함수
     * @param {any} state 
     * @param {callback?: () => void} callback 
     */
    private _setState(state: any, callback?: () => void): void {
        if (this.owner) {
            this.owner.setState(state, callback);
        }
    }

    /**
     * @internal
     * state() : CardList interface 상태 내부에서 관리 함수
     */
    private get state(): any {
        if (this.owner) {
            return this.owner.state;
        }
        return null;
    }


    /**
     * @internal
    */
    public checkPossibleGridAction(behavior: 'appendable' | 'editable' | 'removable' | 'printable', invokedPublicMethodCall?: boolean): boolean {
        if (!this._pageAuthority) {
            return true;
        }

        if (invokedPublicMethodCall === true) {
            if (behavior === 'appendable') {
                return (this._appendable === true && this._pageAuthority.modifyAuthYn === "Y");
            } else if (behavior === 'editable') {
                return (this._pageAuthority.modifyAuthYn === "Y");
            } else if (behavior === 'removable') {
                return (this._pageAuthority.deleteAuthYn === "Y");
            } else if (behavior === 'printable') {
                return (this._pageAuthority.printAuthYn === "Y");
            }
        } else {
            if (behavior === 'appendable') {
                return (this._appendable === true && this._pageAuthority.modifyAuthYn === "Y");
            } else if (behavior === 'editable') {
                return (this._pageAuthority.modifyAuthYn === "Y");
            } else if (behavior === 'removable') {
                return (this._pageAuthority.deleteAuthYn === "Y");
            } else if (behavior === 'printable') {
                return (this._pageAuthority.printAuthYn === "Y");
            }
        }

        return false;
    }

    public isUsePrivacy = (columnName: string): boolean => {
        return this._privacyColumns && this._privacyColumns.includes(columnName) ? true : false;
    }

    /**
     * 카드리스트에서는 개인정보 암호화 동작 정의를 하지 않는다.
     */
    public getPrivacyBehavior = (columnName: string) => {
        return undefined;
    }

    public getPrivacyComponentHandler = async (columnName: string): Promise<void> => {
        if (this.isUsePrivacy(columnName) && this.onGetPrivacy) {
            const rowIndex = this.getSelection();
            const privacy = new Privacy(this.getValue(rowIndex, columnName));
            const result = await this.invokePromiseEvent<Events.PrivacyEventArgs, { privacyKey: string, privacyValue: string }[]>(this.onGetPrivacy,
                new Events.PrivacyEventArgs(this, 'read', [columnName], [this.getValues(rowIndex)]));
            if (!result.isCancel && result.returnData) {
                const found = result.returnData.find(item => item.privacyKey === privacy.privacyKey);
                if (found && this._data) {
                    privacy.privacyValue = found.privacyValue;
                    this._data[rowIndex][columnName] = privacy.value;
                    this._setState({
                        renderNotifier: !this.state.renderNotifier
                    });
                    this._invokeEvent<Events.PrivacyRetrievedEventArgs>(this.onPrivacyRetrieved, new Events.PrivacyRetrievedEventArgs(this, [columnName]));
                }
            }
        }
    }

    public updatePrivacy(rowIndex: number, columnName: string, updatePrivacyValue: string) {
        const rowState = this.getState(rowIndex);
        const updatePrivacy = new Privacy(updatePrivacyValue);
        const privacy = new Privacy(this.getValue(rowIndex, columnName));
        if (privacy && updatePrivacy) {
            privacy.privacyValue = privacy.modifiedValue;
            privacy.modifiedValue = null;
            privacy.privacyKey = updatePrivacy.privacyKey;
            this._setValue(rowIndex, columnName, privacy.value);
            this.setState(rowIndex, rowState);
        }
    }
}