import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UFOMonthCalendar from './UFOMonthCalendar';
import OBTFloatingPanel from '../../OBTFloatingPanel';
import { Util } from '../../Common';
import LUXButton from 'luna-rocket/LUXButton';
import { OrbitInternalLangPack } from '../../Common/Util';
const moduleStyles = require('./UFOMonthFieldDialog.module.scss');

const styles = {
    label: {
        clear: 'both',
        padding: '10px',
        borderTop: '1px solid #e6e6e6',
        whiteSpace: 'nowrap',
        outline: 'none',
    },
    button: {
        background: '#fff',
        border: '1px solid #e0e0e0',
        boxSizing: 'border-box',
        color: '#4a4a4a',
        display: 'inline-block',
        fontSize: '11px',
        fontFamily: '돋움, Dotum, Helvetica, Apple SD Gothic Neo, sans-serif',
        height: '21px',
        margin: '-1px 3px 0 -1px',
        outline: 'none',
        padding: '1px 6px 0',
        position: 'relative',
        textAlign: 'center',
        verticalAlign: 'top',
    }
};

class UFOMonthFieldDialog extends Component {
    static propTypes = {
        displayYear: PropTypes.string,
        initialDate: PropTypes.number,
        maxValue: PropTypes.number,
        minValue: PropTypes.number,
        onAccept: PropTypes.func,
        onDismiss: PropTypes.func,
        onShow: PropTypes.func,
        open: PropTypes.bool,
        customLabel: PropTypes.func,
        required: PropTypes.bool,
        anchorEl: PropTypes.any,
        usePortal: PropTypes.bool
    };

    static defaultProps = {
        usePortal: true
    };

    state = {
        open: false,
        transition: false
    };

    myRefs = {
        dialog: React.createRef(),
        root: React.createRef()
    }

    show = (focus = true) => {
        if (this.props.onShow && !this.state.open) this.props.onShow();
        this.setState({
            open: true,
            isChangeDisplayDate: false,
            isCalendarFocused: false,
        }, () => {
            this.setState({
                transition: true
            })
            if (focus === true && this.myRefs.root.current) {
                this.myRefs.root.current.focus();
            }
        });
    };

    dismiss = () => {
        if (this.props.onDismiss) this.props.onDismiss();
        this.setState({ open: false, transition: false });
    };

    focus = () => {
        if (this.myRefs.root.current) {
            this.myRefs.root.current.focus();
        }
    }

    resetDate = () => {
        this.props.onAccept(null, null)
    }

    handleRootKeyDown = (e) => {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(e);
        }
    }

    render() {
        const {
            displayYear,
            initialDate,
            maxValue,
            minValue,
            onAccept,
        } = this.props;

        const { open } = this.state;

        return (
            <OBTFloatingPanel
                key={open ? 'open' : 'close'}
                value={open}
                align={this.props.align}
                position={this.props.position}
                className={"LS_calendar"}
                width='194px'
                autoPosition={true}
                anchorEl={this.props.anchorEl}
                usePortal={this.props.usePortal}
            >
                <div ref={this.myRefs.root}
                    tabIndex={-1}
                    onKeyDown={this.handleRootKeyDown}
                    className={Util.getClassNames(moduleStyles.calendar, this.state.transition === true ? moduleStyles.visible : null)}>
                    <UFOMonthCalendar
                        displayYear={displayYear}
                        initialDate={initialDate}
                        maxValue={maxValue}
                        minValue={minValue}
                        onChange={onAccept}
                    />
                    <div style={styles.label} tabIndex={0}>
                        {this.props.customLabel ?
                            this.props.customLabel(initialDate === 0 ? '' : String(initialDate), '', this.resetDate) :
                            <>
                                <LUXButton
                                    key={'today'}
                                    label={OrbitInternalLangPack.getText('WE000022702', '이번달')}
                                    onTouchTap={() => {
                                        const today = new Date();
                                        const todayString = `${today.getFullYear()}${('00' + (today.getMonth() + 1)).split('').reverse().filter((ch, i) => i < 2).reverse().join('')}`
                                        if (this.props.onAccept) {
                                            this.props.onAccept(null, todayString);
                                        }
                                        this.dismiss();
                                    }}
                                    style={styles.button}
                                />
                                {!this.props.required ? <LUXButton
                                    key={'clear'}
                                    label={OrbitInternalLangPack.getText('WE000001958', '초기화')}
                                    onTouchTap={() => {
                                        if (this.props.onAccept) {
                                            this.props.onAccept(null, null);
                                        }
                                        this.dismiss();
                                    }}
                                    style={styles.button}
                                /> : undefined}
                            </>}
                    </div>
                </div>
            </OBTFloatingPanel>
        );
    }

    containsFocus = () => {
        return Util.containsFocus(this.myRefs.root);
    }
}

export default UFOMonthFieldDialog;
