/**
 * Component Develment Template
 * Luna - Orbit 개발시 템플릿 으로 사용.
 * @version 0.1
 * @author 김철희
 * @see common.js
 */
import * as React from 'react';
import { CompositeProps, Util, CommonProps } from '../Common';
import Immutable from 'immutable';
import {
    Editor, EditorState, AtomicBlockUtils, ContentBlock, SelectionState,
    DraftHandleValue, CompositeDecorator, ContentState, Modifier,
    DefaultDraftBlockRenderMap,
    getDefaultKeyBinding,
    RichUtils,
    convertToRaw
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import memoizeOne from 'memoize-one';
import { OBTFloatingPanel } from '../index';
import 'draft-js/dist/Draft.css';
/**
 * CSS Modules 사용방식
 * styles.[className]
 * {@code <div className={styles.required}}
 */
const classNames = require('./OBTRichEditor.module.scss');

enum ValueTypeEnum {
    'text' = 'text',
    'html' = 'html'
}

enum ExtendEnum {
    'block' = 'block',
    'decorator' = 'decorator',
    'atomic' = 'atomic',
    'entity' = 'entity'
}

enum EmbedExtendEnum {
    'unordered-list-item' = 'unordered-list-item',
    'ordered-list-item' = 'ordered-list-item',
    'hr' = 'hr',
    'variable' = 'variable',
    'link' = 'LINK',
    'image' = 'IMAGE'
}

enum StyleTypeEnum {
    'bold' = 'bold',
    'underline' = 'underline',
    'italic' = 'italic',
    'align' = 'align',
    'color' = 'color',
    'backgroundColor' = 'backgroundColor'
}

interface IExtendType {
    extend: ExtendEnum,
    type: string,
    component?: any,
    decorator?: boolean,
    regex?: RegExp,
    props?: any,
    strategy?: (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) => void,
    mutable?: boolean
}

interface IVariable {
    value: string,
    description?: string
}

interface IStyle {
    type: StyleTypeEnum,
    value?: string
}

/**
 * PropType 정의
 * Events, CommonProps, CompositeProps 에 미리 지정된 Prop interface 를 충분히 활용한다.
 * extends 로 인터페이스 상속을 통해 사용된다.
 * 공용 Api 를 사용하려면 CommonProps.api 인터페이스를 확장한다.
 */
interface IOBTRichEditor extends CompositeProps.Default, CommonProps.value<string>, CommonProps.required, CommonProps.readonly, CommonProps.disabled,
    CommonProps.placeHolder {
    valueType?: ValueTypeEnum,
    extendTypes?: IExtendType[],
    variables?: (string | IVariable)[],
    colors?: (string)[]
}

/**
 * State 정의
 */
interface State extends hasError {
    editorState: EditorState
}

function Atomic(props: any) {
    const { block, contentState, blockProps: { extendTypes } } = props;
    const entity = block.getLength() > 0 ? contentState.getEntity(block.getEntityAt(0)) : undefined;
    if (entity) {
        const data = entity.getData();
        const type = entity.getType();
        const extendType = ((extendTypes || []) as IExtendType[]).find(item => item.extend === ExtendEnum.atomic && item.type === type);
        if (extendType) {
            return <extendType.component {...data} {...extendType.props} />
        }
    }
    return <></>;
}

interface IVariableProps {
    contentState: ContentState,
    decoratedText: string,
    blockKey: string,
    entityKey?: string,
    offsetKey: string,
    start: number,
    end: number,
    children: any,
    className?: string,
    variables: (string | IVariable)[],
    onUpdate?: (e: { blockKey: string, start: number, end: number, newText: string }) => void,
    [x: string]: any
}

class Variable extends React.Component<IVariableProps, any> {
    state = {
        showList: 'false'
    }

    private myRefs = {
        root: React.createRef<HTMLSpanElement>()
    }

    render() {
        const { contentState, decoratedText, blockKey, entityKey, offsetKey, start, end, children, className, variables, onUpdate, ...otherProps } = this.props;
        return (<span className={Util.getClassNames(classNames.variable, className)} {...otherProps}
            tabIndex={-1}
            onClick={this.handleClick}
            ref={this.myRefs.root}
            onBlur={this.handleBlur}>
            {children}
            {this.state.showList === 'false' ? undefined :
                <OBTFloatingPanel anchor={this.myRefs.root} autoPosition={true} position={OBTFloatingPanel.Position.bottom} align={OBTFloatingPanel.Align.near} value={true}>
                    <div className={Util.getClassNames(classNames.variableList,
                        ['setTrue', 'setFalse'].includes(this.state.showList) ? classNames.hide : undefined)}
                        onTransitionEnd={this.handleTransitionEnd}>
                        {variables.map((variable: string | IVariable, index: number) => {
                            const value = typeof variable === 'string' ? variable : variable.value;
                            const description = typeof variable === 'object' ? variable.description : null;
                            return (<div key={index}
                                id={value}
                                className={Util.getClassNames(classNames.variableListItem, `@${value}` === decoratedText ? classNames.selected : undefined)}
                                onMouseDown={(e) => this.handleItemClick(value, e)}>
                                <span>{`@${value}`}</span>
                                {description ? <span className={classNames.description}>{description}</span> : undefined}
                            </div>);
                        })}
                    </div>
                </OBTFloatingPanel>
            }
        </span>);
    }

    private handleClick = (e: React.MouseEvent) => {
        if (['true', 'false'].includes(this.state.showList)) {
            this.setState({
                showList: this.state.showList === 'true' ? 'setFalse' : 'setTrue'
            }, () => {
                if (this.state.showList === 'setTrue') {
                    if (this.myRefs.root.current) {
                        this.myRefs.root.current.focus();
                    }
                    this.setState({
                        showList: 'true'
                    });
                }
            });
        }
    }

    private handleBlur = (e: React.FocusEvent) => {
        setTimeout(() => {
            if (this.state.showList === 'true' && !Util.containsFocus(this.myRefs.root)) {
                this.setState({
                    showList: 'setFalse'
                });
            }
        }, 0);
    }

    private handleTransitionEnd = (e: React.TransitionEvent) => {
        if (this.state.showList === 'setFalse') {
            this.setState({
                showList: 'false'
            });
        }
    }

    private handleItemClick(variable: string, e: React.MouseEvent) {
        const { blockKey, start, end } = this.props;
        this.setState({
            showList: 'setFalse'
        }, () => {
            if (this.props.onUpdate) {
                this.props.onUpdate({
                    blockKey,
                    start,
                    end,
                    newText: `@${variable}`
                })
            }
        });
        e.preventDefault();
        e.stopPropagation();
    }
}

function Link(props: any) {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return <a href={url} className={classNames.link}>{props.children}</a>
}

function Image(props: any) {
    const { url, alt } = props;
    return <img src={url} alt={alt} className={classNames.image} />
}

/**
 * withApi() HOC 를 사용하면 Props 로 Api 를 사용할 수 있다.
 * api 가 Optional 로 선언되었기에 내부에서 ! 오퍼레이터를 사용해서 호출한다.
 * {@code this.props.api!.test();}
 */
export default class OBTRichEditor extends React.Component<IOBTRichEditor, State> {
    public static StyleType = StyleTypeEnum;
    public static ValueType = ValueTypeEnum;

    ///////////////////////////////////////////////////////////////////////////// Initialize
    /**
     * Default Props 설정
     */
    public static defaultProps = {
        disabled: false,
        readonly: false,
        required: false
    }

    private getEmbedExtends = memoizeOne((variables?: (string | IVariable)[]) => {
        const extendTypes: IExtendType[] = [
            {
                extend: ExtendEnum.block,
                type: EmbedExtendEnum["unordered-list-item"],
                regex: /^\s*[-]\s+/g
            },
            {
                extend: ExtendEnum.block,
                type: EmbedExtendEnum["ordered-list-item"],
                regex: /^\s*[0-9]+\.\s+/g
            },
            {
                extend: ExtendEnum.atomic,
                type: EmbedExtendEnum.hr,
                regex: /^--$/g,
                component: function hr() {
                    return <div className={classNames.hr} />
                }
            },
            {
                extend: ExtendEnum.entity,
                type: EmbedExtendEnum.link,
                mutable: true,
                strategy: (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) => {
                    contentBlock.findEntityRanges((character) => {
                        const entityKey = character.getEntity();
                        return entityKey && contentState.getEntity(entityKey).getType() === 'LINK' ? true : false;
                    }, callback);
                },
                component: Link
            },
            {
                extend: ExtendEnum.atomic,
                type: EmbedExtendEnum.image,
                // mutable: false,
                // strategy: (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) => {
                //     contentBlock.findEntityRanges((character) => {
                //         const entityKey = character.getEntity();
                //         return entityKey && contentState.getEntity(entityKey).getType() === 'IMAGE' ? true : false;
                //     }, callback);
                // },
                component: Image
            }
        ];
        if (variables && variables.length > 0) {
            const regex = new RegExp(`@(${variables.map(variable => typeof variable === 'string' ? variable : variable.value).filter(item => item && item.length > 0).join('|')})`, 'g');
            extendTypes.push({
                extend: ExtendEnum.decorator,
                type: EmbedExtendEnum.variable,
                strategy: (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) => {
                    const text = contentBlock.getText();
                    let match: (RegExpExecArray | null) = null, start = 0;
                    while (regex && (match = regex.exec(text)) !== null) {
                        start = match.index;
                        callback(start, start + match[0].length);
                    }
                },
                component: Variable,
                props: {
                    variables: variables,
                    onUpdate: this.handleVariableUpdate.bind(this)
                }
            })
        }
        return extendTypes;
    })

    private getExtendTypes = memoizeOne((embedExtendTypes: IExtendType[], extendTypes?: IExtendType[]): IExtendType[] =>
        extendTypes ? extendTypes.concat(embedExtendTypes) : embedExtendTypes);

    private getBlockRenderMap = memoizeOne((extendTypes: IExtendType[]) => DefaultDraftBlockRenderMap.merge(Immutable.Map((() => {
        return {
            'align(left)': {
                element: 'div',
                aliasedElements: ['p']
            },
            'align(center)': {
                element: 'div',
                aliasedElements: ['p']
            },
            'align(right)': {
                element: 'div',
                aliasedElements: ['p']
            },
            'align(justify)': {
                element: 'div',
                aliasedElements: ['p']
            }
        };
    })())));

    private getCompositeDecorator = memoizeOne((extendTypes: IExtendType[]) => {
        return !extendTypes ? undefined :
            (() => {
                const decorators = (extendTypes || []).filter(item => (item.extend === ExtendEnum.decorator || item.extend === ExtendEnum.entity) && item.strategy);
                if (decorators) {
                    return new CompositeDecorator(decorators.map(item => ({
                        strategy: item.strategy ? item.strategy : () => { },
                        component: item.component,
                        props: item.props
                    })));
                }
                return undefined;
            })();
    });

    private get ExtendTypes() { return this.getExtendTypes(this.getEmbedExtends(this.props.variables), this.props.extendTypes); }

    private getStyleMap = memoizeOne((colors?: string[]): any => {
        return {
            [StyleTypeEnum.bold]: { fontWeight: 'bold' },
            [StyleTypeEnum.italic]: { fontStyle: 'italic' },
            [StyleTypeEnum.underline]: { textDecoration: 'underline' },
            // [`${StyleTypeEnum.align}(left)`]: { textAlign: 'left' },
            // [`${StyleTypeEnum.align}(right)`]: { textAlign: 'right' },
            // [`${StyleTypeEnum.align}(center)`]: { textAlign: 'center' },
            // [`${StyleTypeEnum.align}(justify)`]: { textAlign: 'justify' },
            ...(() => {
                return colors ? colors.reduce((map, color) => {
                    map[`color(${color})`] = { color: color };
                    map[`bgColor(${color})`] = { backgroundColor: color };
                    return map;
                }, {}) : {}
            })()
        };
    });

    /**
     * State 정의
     */
    public state: State = {
        editorState: EditorState.createEmpty(this.getCompositeDecorator(this.ExtendTypes))
    }

    /**
     * Ref 정의
     */
    public myRefs = {
        editor: React.createRef<Editor>()
    }
    ///////////////////////////////////////////////////////////////////////////// Life Cycle API
    render() {
        return (<ErrorBoundary owner={this} render={this.renderComponent} />)
    }

    // component 가 render 될때 호출됨.
    renderComponent = () => {
        return (
            <div className={Util.getClassNames(classNames.root, 'obtRichEditor-root', this.props.className)}
                style={Object.assign({}, Util.getWrapperStyle(this.props), Util.getInputStateStyle(this.props))}>
                <Editor
                    ref={this.myRefs.editor}
                    editorState={this.state.editorState}
                    handleBeforeInput={this.handleBeforeInput}
                    onChange={this.handleChange}
                    blockStyleFn={this.handleBlockStyle}
                    blockRendererFn={this.handleBlockRenderer}
                    blockRenderMap={this.getBlockRenderMap(this.ExtendTypes)}
                    keyBindingFn={this.handleKeyBinding}
                    handleKeyCommand={this.handleKeyCommand}
                    customStyleMap={this.getStyleMap(this.props.colors)}
                    placeholder={this.props.placeHolder}
                    readOnly={this.props.readonly}
                    handlePastedText={this.handlePastedText}
                    handlePastedFiles={this.handlePastedFiles}
                    handleDroppedFiles={this.handleDroppedFiles}
                />
            </div>
        )
    }

    componentDidMount() {
        this.setValue();
    }

    componentDidUpdate(prevProps: IOBTRichEditor) {
        if (this.props.value !== prevProps.value ||
            this.props.valueType !== prevProps.valueType) {
            this.setValue();
        }
    }

    ///////////////////////////////////////////////////////////////////////////// Logics
    public focus(): void {
        if (this.myRefs.editor.current) {
            this.myRefs.editor.current.focus();
        }
    }

    get canEdit(): boolean {
        return !this.props.disabled && !this.props.readonly;
    }

    public setValue = (value?: string, valueType?: ValueTypeEnum) => {
        const editorValue = value === undefined ? this.props.value : value;
        const editorValueType = valueType === undefined ? this.props.valueType : valueType;
        let editorState = EditorState.createEmpty(this.getCompositeDecorator(this.ExtendTypes));
        if (editorValue) {
            if (editorValueType === ValueTypeEnum.html) {
                const contentBlock = htmlToDraft(editorValue, (nodeName, node) => {
                    switch (nodeName) {
                        case 'hr':
                            return {
                                type: 'hr',
                                mutability: 'IMMUTABLE',
                                data: {}
                            }
                        case 'img':
                            return {
                                type: 'IMAGE',
                                mutability: 'IMMUTABLE',
                                data: {
                                    url: node.src,
                                    alt: node.alt
                                }
                            }
                    }
                    if (nodeName === 'hr') {
                    }
                });
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    editorState = EditorState.createWithContent(contentState, this.getCompositeDecorator(this.ExtendTypes));
                }
            } else {
                editorState = EditorState.set(editorState, {
                    currentContent: Modifier.insertText(editorState.getCurrentContent(), editorState.getSelection(), editorValue)
                });
            }
        }

        this.setState({
            editorState: editorState
        })
    }

    public getValue = (valueType?: ValueTypeEnum) => {
        if ((valueType || this.props.valueType) === ValueTypeEnum.html) {
            const raw = convertToRaw(this.state.editorState.getCurrentContent());
            const value = draftToHtml(raw,
                undefined,
                undefined,
                (entity: any, text: string) => {
                    switch (entity.type) {
                        case 'hr':
                            return '<hr/>';
                        case 'IMAGE':
                            return `<img src="${(entity.data || {}).url}" alt="${(entity.data || {}).alt}" />`;
                    }
                    return undefined;
                });
            return value;
        } else {
            return convertToRaw(this.state.editorState.getCurrentContent()).blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
        }
    }

    public insertLink = (url: string, urlText?: string) => {
        return new Promise<void>(resolve => {
            const { editorState } = this.state;
            const contentState = editorState.getCurrentContent();
            {
                const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: url });
                const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                const editorStateWithEntity = EditorState.set(editorState, { currentContent: contentStateWithEntity });
                this.setState({
                    editorState: EditorState.push(
                        editorStateWithEntity,
                        Modifier.insertText(editorStateWithEntity.getCurrentContent(), editorStateWithEntity.getSelection(), urlText || url, undefined, entityKey),
                        'insert-characters')
                }, () => resolve());
            }
        });
    }

    public insertImage = (url: string) => {
        return new Promise<void>(resolve => {
            const { editorState } = this.state;
            const contentState = editorState.getCurrentContent();
            {
                const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', { url: url });
                const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                const editorStateWithEntity = EditorState.set(editorState, { currentContent: contentStateWithEntity });
                this.setState({
                    editorState: AtomicBlockUtils.insertAtomicBlock(editorStateWithEntity, entityKey, ' ')
                }, () => resolve());
            }
        });
    }

    public insertDivider = () => {
        return new Promise<void>(resolve => {
            const { editorState } = this.state;
            const contentState = editorState.getCurrentContent();
            {
                const contentStateWithEntity = contentState.createEntity('hr', 'IMMUTABLE');
                const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                const editorStateWithEntity = EditorState.set(editorState, { currentContent: contentStateWithEntity });
                this.setState({
                    editorState: AtomicBlockUtils.insertAtomicBlock(editorStateWithEntity, entityKey, ' ')
                }, () => resolve());
            }
        });
    }

    public setStyle = (style: {
        type: StyleTypeEnum,
        value?: any
    }) => {
        return new Promise<void>(resolve => {
            const { editorState } = this.state;
            const contentState = editorState.getCurrentContent();
            const selectionState = editorState.getSelection();
            switch (style.type) {
                case StyleTypeEnum.color:
                case StyleTypeEnum.backgroundColor:
                    const getKey = (color: any) => style.type === StyleTypeEnum.color ? `color(${color})` : `bgColor(${color})`;
                    if (this.props.colors && this.props.colors.length > 0) {
                        this.setState({
                            editorState: EditorState.push(editorState, (() => {
                                const nextContentState = this.props.colors.reduce((nextContentState, color) => {
                                    return Modifier.removeInlineStyle(nextContentState, selectionState, getKey(color));
                                }, contentState);
                                return style.value ? Modifier.applyInlineStyle(nextContentState, selectionState, getKey(style.value)) : nextContentState;
                            })(), 'change-inline-style')
                        }, () => resolve())
                    } else {
                        resolve();
                    }
                    break;
                case StyleTypeEnum.align:
                    const block = contentState.getBlockForKey(selectionState.getAnchorKey());
                    let data = block.getData();
                    if (data) {
                        const dataJs = data.toJS();
                        dataJs['text-align'] = style.value;
                        data = Immutable.Map(dataJs);
                    } else {
                        data = Immutable.Map({ 'text-align': style.value })
                    }
                    this.setState({
                        editorState: EditorState.push(editorState, Modifier.setBlockData(contentState, selectionState, data), 'change-block-data')
                    })
                    break;
                default:
                    if (style.value !== undefined) {
                        if (style.value) {
                            this.setState({
                                editorState: EditorState.push(editorState, Modifier.applyInlineStyle(contentState, selectionState, style.type), 'change-inline-style')
                            }, () => resolve());
                        } else {
                            this.setState({
                                editorState: EditorState.push(editorState, Modifier.removeInlineStyle(contentState, selectionState, style.type), 'change-inline-style')
                            }, () => resolve());
                        }
                    } else {
                        this.setState({
                            editorState: RichUtils.toggleInlineStyle(editorState, style.type)
                        }, () => resolve());
                    }
                    break;
            }
        });
    }
    ///////////////////////////////////////////////////////////////////////////// Event Handlers
    private handleBeforeInput = (chars: string, editorState: EditorState, eventTimeStamp: number): DraftHandleValue => {
        let nextState = editorState;
        let contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
        const block = contentState.getBlockForKey(selectionState.getAnchorKey());
        const extendTypes = this.ExtendTypes.filter(item => (item.extend === ExtendEnum.atomic || item.extend === ExtendEnum.block) && item.regex);

        if (extendTypes && extendTypes.length > 0 && block && ['unstyled', 'align(left)', 'align(center)', 'align(right)', 'align(justify)'].includes(block.getType())) {
            const blockText = (() => {
                const state = Modifier.insertText(contentState, selectionState, chars);
                return state.getBlockForKey(block.getKey()).getText();
            })();
            if (blockText) {
                const found = extendTypes.map(extendType => {
                    if (extendType.regex) {
                        return {
                            extendType,
                            match: extendType.regex.exec(blockText)
                        };
                    }
                    return undefined;
                }).find(item => item && item.match !== null);

                if (found) {
                    const { extendType, match } = found;
                    const blockKey = block.getKey();
                    contentState = Modifier.removeRange(contentState, new SelectionState({
                        anchorKey: blockKey,
                        anchorOffset: 0,
                        focusKey: blockKey,
                        focusOffset: match ? match.index + match[0].length - (chars.length) : 0
                    }), 'backward');
                    if (extendType.extend === ExtendEnum.atomic) {
                        contentState = contentState.createEntity(extendType.type, 'IMMUTABLE', {});
                        const entityKey = contentState.getLastCreatedEntityKey();
                        nextState = EditorState.set(nextState, { currentContent: contentState });
                        nextState = AtomicBlockUtils.insertAtomicBlock(nextState, entityKey, '<br/>');
                    } else {
                        contentState = Modifier.setBlockType(contentState, SelectionState.createEmpty(blockKey), extendType.type);
                        nextState = EditorState.push(nextState, contentState, 'change-block-type');
                        nextState = EditorState.forceSelection(nextState, SelectionState.createEmpty(blockKey));
                    }
                    this.setState({
                        editorState: nextState
                    });
                    return 'handled';
                }
            }
        }

        return 'not-handled';
    }

    private handleChange = (editorState: EditorState): void => {
        this.setState({
            editorState: editorState
        });
    }

    private handleBlockStyle = (contentBlock: ContentBlock): string => {
        const blockClassNames = [classNames.block];
        const blockData = contentBlock.getData();
        if (blockData) {
            const blockJs = blockData.toJS();
            if (blockJs.hasOwnProperty('text-align')) {
                switch (blockJs['text-align']) {
                    case 'left':
                        blockClassNames.push(classNames.alignLeft);
                        break;
                    case 'right':
                        blockClassNames.push(classNames.alignRight);
                        break;
                    case 'center':
                        blockClassNames.push(classNames.alignCenter);
                        break;
                    case 'justify':
                        blockClassNames.push(classNames.alignJustify);
                        break;
                }
            }
        }
        return Util.getClassNames(...blockClassNames);
    }

    private handleBlockRenderer = (contentBlock: ContentBlock): (void | any) => {
        const type = contentBlock.getType();
        if (type === 'atomic') {
            return {
                component: Atomic,
                editable: false,
                props: {
                    extendTypes: this.ExtendTypes
                }
            }
        }
    }

    private handlePastedText = (text: string, html: string | undefined, editorState: EditorState): DraftHandleValue => {
        if (/^(http|https):\/\/.*/.test(text)) {
            this.insertLink(text, text);
            return 'handled';
        }
        return 'not-handled';
    }

    private handlePastedFiles = (files: Array<Blob>): DraftHandleValue => {
        return this.handleFile(files);
    }

    private handleDroppedFiles = (selection: SelectionState, files: Array<Blob>): DraftHandleValue => {
        return this.handleFile(files, selection);
    }

    private handleFile = (files: Array<Blob>, selection?: SelectionState): DraftHandleValue => {
        const self = this;
        const regex = /.*\.(png|jpg|jpeg|gif|bmp)$/;
        files.map(file => file as File)
            .filter(file => regex.test(file.name))
            .reduce((p, file) => {
                return p.then(() => {
                    return new Promise((resolve) => {
                        const fileReader = new FileReader();
                        fileReader.onload = () => {
                            const url = fileReader.result as string;
                            self.insertImage(url).then(() => resolve());
                        }
                        fileReader.readAsDataURL(file);
                    });
                })
            }, Promise.resolve());

        return 'not-handled';
    }

    private handleKeyBinding = (e: /* React.KeyboardEvent */ any): string | null => {
        if (e.keyCode === 9) {
            const contentState = this.state.editorState.getCurrentContent();
            const selectionState = this.state.editorState.getSelection();
            const block = contentState.getBlockForKey(selectionState.getAnchorKey());
            if (block && ["unordered-list-item", "ordered-list-item"].includes(block.getType())) {
                this.setState({
                    editorState: RichUtils.onTab(e, this.state.editorState, 2)
                });
                return 'handled';
            }
        }
        return getDefaultKeyBinding(e);
    }

    private handleKeyCommand = (command: string): DraftHandleValue => {
        if (command === 'handled') return 'handled';
        return 'not-handled';
    }

    private handleVariableUpdate(e: { blockKey: string, start: number, end: number, newText: string }) {
        const contentState = this.state.editorState.getCurrentContent();
        const selectionState = this.state.editorState.getSelection().merge({
            anchorKey: e.blockKey,
            anchorOffset: e.start,
            focusKey: e.blockKey,
            focusOffset: e.end
        });
        const nextContentState = Modifier.replaceText(contentState, selectionState, e.newText);
        this.setState({
            editorState: EditorState.push(this.state.editorState, nextContentState, 'change-block-data')
        });
    }
};
