import OBTDataGridInterface, { ColumnAlignment } from "./OBTDataGridInterface";
import IBuiltInCodePicker from '../OBTCodePicker/DataSource/IBuiltInCodePicker';
import { MaskType } from '../OBTMaskedTextField/OBTMaskedTextField';
import IRealGridCellStyleOption, { IRealGridCellDynamicStyleOption } from './RealGridCellStyleOption';
import { UserProfilePopUpParameter } from "../OBTPageContainer/OBTPageContainer";
import { PrivacyBehaviorEnum } from '../Common';
import { AlignType, PositionType } from "../OBTFloatingPanel/OBTFloatingPanel";

/**
 * type = image일 경우 이미지의 디스플레이 방식
 */
export enum ColumnImageFittingType {
    /**
     * 이미지 크기는 변경하지 않고 셀 중앙에 위치 시킵니다. 
     */
    'center' = 'center',
    /**
     * 이미지의 너비와 높이를 셀 크기에 맞춥니다. 이미지가 왜곡됩니다.
     */
    'both' = 'both',
    /**
     * 이미지의 높이는 그냥 두고 너비만 셀 크기에 맞춥니다. 이미지가 왜곡됩니다.
     */
    'width' = 'width',
    /**
     * 이미지의 너비는 그냥 두고 높이만 셀 크기에 맞춥니다. 이미지가 왜곡됩니다.
     */
    'height' = 'height',
    /**
     * 이미지의 비율을 유지한 채로 셀 크기에 맞춥니다.
     */
    'auto' = 'auto',
    /**
     * 
     */
    'none' = 'none'
}

export enum ERPNumberFormatType {
    /**
     * 수량소수점자리수
     */
    'sFormat02' = 'sFormat02',
    /**
    * 원화단가소수점자리수
    */
    'sFormat03' = 'sFormat03',
    /**
     * 외화단가소수점자리수
     */
    'sFormat04' = 'sFormat04',
    /**
     * 비율소숫점자리수
     */
    'sFormat05' = 'sFormat05',
    /**
     * 금액소숫점자리수
     */
    'sFormat06' = 'sFormat06',
    /**
    * 외화소숫점자리수
    */
    'sFormat07' = 'sFormat07',
    /**
     * 환율소숫점자리수
     */
    'sFormat08' = 'sFormat08'
}

/**
 * 컬럼의 타입
 */
export enum ColumnType {
    'text' = 'text',
    'multiline' = 'multiline',
    'number' = 'number',
    'dropDown' = 'dropDown',
    'date' = 'date',
    //'date2' = 'date2',
    'time' = 'time',
    'dateTime' = 'dateTime',
    'codePicker' = 'codePicker',
    'check' = 'check',
    'data' = 'data',
    'mask' = 'mask',
    'group' = 'group',
    'image' = 'image',
    'progress' = 'progress',
    'link' = 'link',
    'button' = 'button',
    'autoComplete' = 'autoComplete'
}

/**
 * 날짜 Format
 */
export enum DateFormat {
    'yyyyMMdd' = 'yyyyMMdd',
    'yyyyMM' = 'yyyyMM',
    'yyyy' = 'yyyy',
    'iso' = 'iso'
}

export { MaskType };

/**
 * 텍스트 컬럼 작업
 */
export interface TextColumnEditorOption {
    allowSpecialChar: boolean,
    onlyNumber: boolean,
    preventCharList: string[],
}

/**
 * 코드피커의 에디트 타입
 */
export enum CodePickerEditType {
    'onlyDialog' = 'onlyDialog',
    'notAllowEditorSearch' = 'notAllowEditorSearch'
}

/**
 * IColumn.footer.expression에 사용하는 표현식
 */
export enum ColumnSummaryExpression {
    /**
     * 데이터행의 갯수.
     */
    'count' = 'count',
    /**
     * 컬럼에 포함된 모든 데이터셀의 값들을 더한 값.
     */
    'sum' = 'sum',
    /**
     * 컬럼에 포함된 모든 데이터셀의 값들 중 가장 큰 값.
     */
    'max' = 'max',
    /**
     * 컬럼에 포함된 모든 데이터셀의 값들 중 가장 작은 값.
     */
    'min' = 'min',
    /**
     * 컬럼에 포함된 모든 데이터셀의 값들의 평균.
     */
    'avg' = 'avg',
    /**
     * 컬럼에 포함된 모든 데이터셀의 값들의 분산.
     */
    'var' = 'var',
    /**
     * 컬럼에 포함된 모든 데이터셀의 값들의 모집단 분산.
     */
    'varp' = 'varp',
    /**
     * 컬럼에 포함된 모든 데이터셀의 값들의 표준편차.
     */
    'stdev' = 'stdev',
    /**
     * 컬럼에 포함된 모든 데이터셀의 값들의 모집단 표준편차.
     */
    'stdevp' = 'stdevp'
}

/**
 * IColumn.dropDownLabelType
 */
export enum DropDownColumnLableType {
    /**
     * value만 표시된다.
     */
    "value" = "value",
    /**
     * label만 표시된다.
     */
    "label" = "label",
    /**
     * value와 label의 순서로 둘 다 표시된다.
     */
    "valueLabel" = "valueLabel",
    /**
     * label과 value의 순서로 둘 다 표시된다.
     */
    "labelValue" = "labelValue"
}

export interface ImageButton {
    name: string;
    up?: string;
    hover?: string;
    down?: string;
    width?: number;
    cursor?: string;
}

// export const makeTextColumn = (column: {} extends IInputBaseColumnProperty) : IColumn => {


// }

/**
 * 데이터 기반의 컬럼
 */
interface IInputBaseColumnProperty {
    /**
     * 컬럼공통. 데이터 앞에 붙는 접두사
     */
    prefix?: string;
    /**
     * 컬럼공통. 데이터 뒤에 붙는 접미사
     */
    suffix?: string;
    /**
     * 컬럼공통. 셀 데이터의 정렬방식
     * 설정하지 않을경우 컬럼타입에 따라 자동으로 조정된다. (ex: 날짜는 가운데 정렬)
     */
    alignment?: ColumnAlignment;

    /**
     * 컬럼공통. 컬럼이 보여지는 방식 지정
     */
    orientation?: 'horizontal' | 'vertical';
}

interface IImageButtonOptionProperty {
    /**
     * 이미지 버튼의 정렬 방식 
     */
    imageButtonAlignment?: ColumnAlignment;

    /**
     * 이미지버튼 리스트
     */
    imageButtonList?: ImageButton[];
    /**
     * 이미지버튼 렌더러
     */
    imageButtonRenderers?: IImageButtonRenderer[];
}

interface IProfileInfoOptionProperty {
    /**
    * 
    */
    getProfileInfo?: ((e: { rowIndex: number, columnName: string, values: any }) => Promise<UserProfilePopUpParameter>);
}

interface ICheckColumnProperty {
    /**
     * type = check일 경우 true으로 사용할 데이터의 리스트
     */
    trueValues?: string[];
    /**
     * type = check일 경우 false로 사용할 데이터의 리스트 
     */
    falseValues?: string[];
}

interface IAutoCompleteProperty extends IInputBaseColumnProperty {
    /**
     * 키 프로퍼티 설정
     */
    keyProperty?: string,
    /**
     * 찾는 함수
     */
    searchCallback?: (e: string) => Promise<any>,
    /**
     * 다이얼로그 정렬
     */
    dialogAlign?: "left" | "center" | "right",
    /**
     * 클릭 혹은 엔터시 바로 값을 매핑할것인지에 대한 여부를 지정
     */
    useDirectValueMapping?: boolean,
}

interface INumberColumnProperty extends IInputBaseColumnProperty {
    /**
     * type = number일 경우 숫자 컬럼에 대한 포메팅 문자열
     * sFormat02: 수량소수점자리수
     * sFormat03: 원화단가소수점자리수
     * sFormat04: 외화단가소수점자리수
     * sFormat05: 비율소숫점자리수
     * sFormat06: 금액소숫점자리수
     * sFormat07: 외화소숫점자리수
     * sFormat08: 환율소숫점자리수
     */
    numberColumnFormat?: string | ERPNumberFormatType;
    /**
     * type = number일 경우 양수만 입력가능하게 할지 여부
     */
    positiveOnly?: boolean;
    /**
     * type = number일 경우 nan을 0으로 보여줄것인지 여부
     */
    nanToZero?: boolean,
    /**
     * type = number일 경우 0을 보여줄것인지 여부
     */
    showZero?: boolean,
    /**
     * type = text, number 계열일 경우 입력가능한 최대길이 number일경우 (전체길이.소수점길이) 형식으로 작성
     */
    maxLength?: number,
    /**
     *  type = number일 경우 해당 콜백에서 계산되어 리턴된 값이 표기된다. 
     */
    calculateCallback?: (dataRow: any, fieldName: string, fieldNames: string[], values: any) => number,
}

interface ITextColumnProperty extends IInputBaseColumnProperty {
    /**
     * type = text, number 계열일 경우 입력가능한 최대길이 number일경우 (전체길이.소수점길이) 형식으로 작성
     */
    maxLength?: number,

    /**
     * type = text 일 경우, 입력되는 값을 모두 대문자로, 소문자로, 입력하는대로 변환 설정할 수 있습니다.
     * - normal : 입력되는대로 표시한다.
     * - upper : 모두 대문자로 표시한다.
     * - lower : 모두 소문자로 표시한다.
     * - default : editor가 이 값이면 컬럼의 textInputCase 값을 따른다.
     */
    textCase?: 'upper' | 'default' | 'lower' | 'normal',
}

interface IMultiLineColumnProperty extends IInputBaseColumnProperty {
    /**
     * type = text, number 계열일 경우 입력가능한 최대길이 number일경우 (전체길이.소수점길이) 형식으로 작성
     */
    maxLength?: number,
}

interface IButtonOptionProperty {
    /**
     * date, dropdown등 액션버튼을 가진 컬럼 타입의 경우 버튼이 보여지는 방법
     * "none" : 버튼을 보이지 않는다. 
     * "mouseOver" : 보이지 않다가 마우스가 올라간 순간 버튼이 보여진다. 
     * "always" : 항상 버튼이 보여진다.
     */
    buttonVisibility?: "none" | "mouseOver" | "always" //| () =>  boolean,
    /**
     * 액션버튼을 가진 컬럼 타입일때 수정 가능한 셀의 경우만 버튼을 표시할지 여부.
     * hasActionButton = true 이면 기본값 false
     * type = (codePicker, dropDown, date) 일 경우 기본값 true
     */
    showButtonOnlyEditable?: boolean,
}

interface ICodePickerColumnProperty extends IButtonOptionProperty, IInputBaseColumnProperty, ISelectableColumnOptionProperty {
    /**
     * @internal
     * @deprecated
     * type = codePicker일 경우 코드피커의 id
     */
    codePickerId?: string;
    /**
     * type = codePicker일 경우 코드피커의 인스턴스, 아이디가 있을 경우 아이디 우선
     */
    customCodePicker?: IBuiltInCodePicker | ((e: any) => IBuiltInCodePicker);
    /**
     * type = codePicker 코드피커 멀티 사용 여부
     */
    canMultiSelect?: boolean | ((e: { columnName: string, rowIndex: number }) => boolean);
    /**
     * type = codePicker일 경우 코드피커의 getData 함수로 보낼 파라미터, 오브젝트나 콜백으로 설정
     */
    parameters?: any;
    /**
     * type = codePicker일 경우. 
     * onlyDialog: 키보드입력을 허용하지 않는다. 
     * notAllowEditorSearch: 셀 검색대신 입력된 값을 code로 해석한다.
     */
    codePickerEditType?: CodePickerEditType,

    /**
     * 코드선택이 이루어진후 자동으로 다음컬럼으로 이동할지 여부
     * @default true
     */
    autoMoveNextCell?: boolean,

    /**
     * type = codePicker일 경우. 선택한 아이템의 셀에 보여지는 방식을 지정하는 콜백
     */
    selectedItemDisplayCallBack?: (e: any) => string;
    /**
     * type = codePicker일 경우 코드가 선택된 이후 호출되는 콜백
     */
    afterCodeHelpCallback?: (e: any) => {},
}

interface IDateColumnProperty extends IInputBaseColumnProperty {
    /**
     * type = date일 경우 컬럼의 날짜포메팅, 날짜에 대한 포멧 
     * yyyyMMdd, yyyyMM, yyyy
     */
    dateFormat?: DateFormat; //| ((value: any) => string);

    /**
     * 날짜 다이얼로그 정렬 방식 
     */
    dateDialogAlign?: AlignType;

    /**
     * 날짜 다이얼로그 정렬 방식 
     */
    dateDialogPosition?: PositionType;
    /**
     * type = (date, dateTime)일 경우
     * 요일을 함께 표기 할것인지 여부 
     */
    showDayOfWeek?: boolean | ((original: number) => string),
}

/**
 * 코드피커, 드랍다운등 선택이 가능한 옵션에 대한 처리
 */
interface ISelectableColumnOptionProperty {
    /**
     * 텍스트 에디트를 사용할것인지 여부
     * default: false
     */
    textReadOnly?: boolean,
}

interface ITimeColumnProperty extends IInputBaseColumnProperty {
    /**
     * type = 'time' 컬럼의 값이 hh:mm 형태가 아닐 때, hh:mm 형태로 매핑하기위한 콜백
     */
    mapTimeValueCallback?: ((value: string | null | undefined) => string | null | undefined),
    /**
     * type = 'time' 컬럼
     * list의 시간 간격을 1시간단위 또는 30분단위로 설정
     */
    timePeriodType?: 'hour' | 'half',

    /**
     * 인풋값에 입력되는 값을 허용할것인지 여부
     */
    allowEditorValue?: boolean,
}

interface IMaskColumnProperty extends IInputBaseColumnProperty {
    /**
     * type = mask일 경우 마스킹 방식
     */
    maskType?: MaskType | ((rowIndex: number, target: OBTDataGridInterface) => MaskType | undefined);
    /**
     *  type = mask, maskType = custom일 경우, 셀의 에디터의 editMask
     */
    editMask?: string,
    /**
     * 개인정보 마스킹 사용어부
     */
    useMasking?: boolean,
    /**
     *  type = mask일때 invalid한 value를 허용할것인지 여부, invalid한 value는 자동 스타일 적용
     *  길이까지 체크하지는 않으니 maxLength와 함께 사용할것
     */
    allowInvalidValue?: boolean,
    /**
     * type = mask, maskType = custom일 경우 디스플레이되는 방식을 지정하는 콜백, 
     * 파라미터로 순수데이터가 들어오고 표시될 텍스트를 리턴시킨다.
     */
    customMaskCallback?: (value: any, index: number) => string;
}

interface IDropdownColumnProperty extends IInputBaseColumnProperty, ISelectableColumnOptionProperty {
    /**
     * type = dropDown일 경우 
     */
    dropDownDataItems?: any[];
    /**
     * type = dropdown일 경우 dropDownDataItems에서 사용할 코드값의 프로퍼티
     */
    dropDownCodeProperty?: string;
    /**
     * type = dropdown일 경우 dropDownDataItems에서 사용할 텍스트값의 프로퍼티
     */
    dropDownTextProperty?: string;
    /**
     * 
     */
    dropDownDataSourceCallBack?: Promise<any> | (() => Promise<any>);
    /**
     * 
     */
    dropDownLabelType?: DropDownColumnLableType;
    /**
     * default: 4
     */
    dropDownCount?: number,
    /**
     * 인풋값에 입력되는 값을 허용할것인지 여부
     */
    allowEditorValue?: boolean,
    /**
     * 동적으로 드롭다운 리스트를 변경할 경우 초반에 들어갈 리스트입니다.
     */
    originalDropDownList?: any[],
    /**
     * 동적으로 드롭다운 리스트를 변경할 때 사용합니다.
     */
    dynamicDropDownItemFilter?: (values: any, item: any) => boolean,
}

interface IGroupColumnProperty {
    /**
     * type = group일 경우 자식 컬럼의 리스트 
     */
    columns?: IColumn[];
}

interface INewGroupColumnProperty {
    /**
     * type = group일 경우 자식 컬럼의 리스트 
     */
    columns?: INewColumn[];
}


interface IProgressColumnProperty {
    /**
     * 
     */
    minimumValue?: number,
    /**
     * 
     */
    maximumValue?: number,
}

interface IIConOptionProperty {
    /**
     * @deprecated
     * 아이콘을 사용할 것이지 여부
     */
    useIcon?: boolean,
    /**
     * 아이콘만 디스플레이할것인지 여부
     */
    onlyIcon?: boolean,
    /**
     * 컬럼에 사용할 이미지 아이콘의 리스트,
     * dynamicStyles와 함께 사용 (iconIndex 속성)
     */
    iconImageList?: string[],
}

interface IImageColumnProperty {
    /**
     * type = image일 경우 이미지의 디스플레이 방식
     */
    fittingType?: ColumnImageFittingType,
}

interface IPrivacyOptionProperty {
    /**
     * 개인정보 암호화 여부
     */
    usePrivacy?: boolean,

    /**
     * 개인정보 암호화 조회 옵션
     */
    privacyBehavior?: PrivacyBehaviorEnum,
}

interface IImageLabelOption {
    /**
     * 이미지 라벨을 사용할 것인지 여부.
     * useIcon 옵션과 함께 true일 수 없음.
     * type='check', 'progress'등 자체적인 렌더러를 사용하는 컬럼 타입에서는 사용할 수 없음
     */
    useImageLabel?: boolean,
    /**
     * useImageLabel = true일 경우 value에 따른 배경색 RGB 지정
     */
    imageLabelBackgroundColor?: ((value: string) => string) | string,
    /**
     * useImageLabel = true일 경우 사용자지정 이미지 라벨콜백함수.
     * 인자로 넘어온 canvas의 context를 조작하여 사용
     */
    customImageLabelCallback?: (value: string, backgroundColor?: ((value: string) => string) | string) => HTMLCanvasElement;

    useColorIcon?: boolean,
}

interface IFooterOptionProperty {
    /**
     * 컬럼공통. 푸터 설정
     */
    footer?: {
        expression?: ColumnSummaryExpression,
        callback?: (index: any, footerIndex: number) => any,
        style?: IRealGridCellStyleOption
        groupExpression?: ColumnSummaryExpression,
        groupCallback?: (index: any, footerIndex: number) => any,
        groupStyle?: IRealGridCellStyleOption
    };
    /**
     * 단일행 footer를 사용할 경우 footer에 바인딩되는 값
     */
    footerValue?: string,
    /**
     * 단일행 footer를 사용할 경우 footer에 바인딩되는 값
     */
    groupFooterValue?: string,
}

interface IHeader {
    text?: string,
    subText?: string,
    subTextGap?: number,
    SubTextLocation?: string,
    itemOffset?: number,
    itemGap?: number,
    CheckLocation?: string,
    imageLocation?: string,
    imageUrl?: string,
    checked?: boolean,
    styles?: IRealGridCellStyleOption,
    subStyles?: IRealGridCellStyleOption,
    showTooltip?: boolean,
    tooltip?: string,
    fixedHeight?: number,
    popupMenu?: []
}

interface IHeaderProperty {
    /**
     * 컬럼공통.그리드헤더 텍스트
     */
    header?: string | IHeader;
    /**
     * 컬럼공통. 헤더의 Height
     */
    headerHeight?: number;
    /**
     * 헤더 스타일
     */
    headerStyle?: IRealGridCellStyleOption,
}

interface IColumnIdentifierProperty {
    /**
     * 컬럼공통. 필수. 컬럼의 명칭
     */
    name: string;
    /**
    * 다국어 컬럼의 명칭
    */
    subName?: string;
    /**
     * 컬럼공통. 데이터 프로바이더와 연결되는 필드명
     * 설정하지 않으면 name속성을 fieldName을 사용
     */
    fieldName?: string;
}

interface IColumnCommonProperty {

    /**
     * 컬럼공통. 너비
     */
    width?: number;
    /**
     * 컬럼공통. 자동으로 컬럼너비가 조정되는지 여부
     * @default false
     */
    isAutoWidth?: boolean;

    /**
     * 
     */
    fillWidth?: number;

    /**
     * 컬럼공통. 컬럼 타입 
     * @default text
     */
    type?: ColumnType;

    /**
     * 컬럼공통. 컬럼이 보여질지 여부
     * @default true
     */
    visible?: boolean;

    /**
     * 컬럼공통. 필수값인지 여부
     * @default false
     */
    required?: boolean | ((e: { rowIndex: number, columnName: string }) => boolean);

    /**
     * 컬럼공통. 수정가능한값인지 여부
     */
    editable?: boolean | ((e: {
        values: any,
        rowIndex: number,
        columnName: string
    }) => boolean);

    /**
     * 컬럼공통.읽기전용값이 여부
     * @default false
     */
    readonly?: boolean;
    /**
     * 툴팁을 사용할것 인지 여부
     * 사용할 경우 데이터가 셀의 영역을 넘어서면 툴팁 자동 생성
     * @default false
     */
    useTooltip?: boolean;
    /**
     * 고정적인 컬럼의 스타일
     */
    style?: IRealGridCellStyleOption,
    /**
     * 다이나믹 스타일 콜백
     * @example (grid: any, index: any, value: any) => { return { ... } }
     */
    dynamicStyles?: (grid: any, index: any, value: any) => IRealGridCellDynamicStyleOption,
    /**
     * 헤더를 통한 정렬을 사용할것인지 여부
     * @default true
     */
    sortable?: boolean;

    /**
     * 컬럼 표시기능에서 숨길수 있는지 여부
     * @default true
     */
    hidable?: boolean,

    /**
     * 컬럼 셀 우측에 액션버튼을 표시할지 여부, 클릭시 onClickCustomActionEventArgs 이벤트가 발생한다.
     * @default false
     */
    hasActionButton?: boolean;
    /**
     * @internal
     */
    guideMessage?: string | JSX.Element | ((e: { rowIndex: number, columnName: string }) => JSX.Element),
}

/**
 * 컬럼 설정
 */
export interface IColumn {
    /**
     * 컬럼공통. 필수. 컬럼의 명칭
     */
    name: string;
    /**
    * 다국어 컬럼의 명칭
    */
    subName?: string;
    /**
     * 컬럼공통. 데이터 프로바이더와 연결되는 필드명
     * 설정하지 않으면 name속성을 fieldName을 사용
     */
    fieldName?: string;
    /**
     * 컬럼공통.그리드헤더 텍스트
     */
    header?: string | IHeader;
    /**
     * type = group일 경우 그루핑 컬럼일 경우 헤더를 보일지 여부
     */
    groupHeaderVisible?: boolean;
    /**
     * 컬럼공통. 헤더의 Height
     */
    headerHeight?: number;
    /**
     * 헤더 스타일
     */
    headerStyle?: IRealGridCellStyleOption,

    /**
     * 
     */
    headerCheckLocation?: string,

    /**
     * 타입이 체크일경우, 헤더에 체크가 되어있는지에 대한 여부를 설정합니다.
     */
    isHeaderCheck?: boolean,

    /**
     * 컬럼공통. 너비
     */
    width?: number;
    /**
     * 컬럼공통. 자동으로 컬럼너비가 조정되는지 여부
     * default = false
     */
    isAutoWidth?: boolean;

    /**
     * 
     */
    fillWidth?: number;
    /**
     * 컬럼공통. 컬럼 타입 
     * default = text
     */
    type?: ColumnType;
    /**
     * @internal
     * type = text일경우 입력옵션
     */
    textColumnOption?: TextColumnEditorOption;
    /**
     * 컬럼공통. 컬럼이 보여지는 방식 지정
     */
    orientation?: 'horizontal' | 'vertical'
    /**
     * 컬럼공통. 데이터 앞에 붙는 접두사
     */
    prefix?: string;
    /**
     * 컬럼공통. 데이터 뒤에 붙는 접미사
     */
    suffix?: string;
    /**
     * 컬럼공통. 컬럼이 보여질지 여부
     */
    visible?: boolean;
    /**
     * 컬럼공통. 동적 수정가능여부 콜백
     * e = { columnName, rowIndex, values }
     * 
     */
    editable?: boolean | ((e: {
        values: any,
        rowIndex: number,
        columnName: string
    }) => boolean);
    /**
     * type = group일 경우 자식 컬럼의 리스트 
     */
    columns?: IColumn[];
    /**
     * 컬럼공통. 셀 데이터의 정렬방식
     * 설정하지 않을경우 컬럼타입에 따라 자동으로 조정된다. (ex: 날짜는 가운데 정렬)
     */
    alignment?: ColumnAlignment;
    /**
     * 이미지 버튼의 정렬 방식 
     */
    imageButtonAlignment?: ColumnAlignment;

    /**
     * type = check일 경우 true으로 사용할 데이터의 리스트
     */
    trueValues?: string[];
    /**
     * type = check일 경우 false로 사용할 데이터의 리스트 
     */
    falseValues?: string[];
    /**
     * type = number일 경우 숫자 컬럼에 대한 포메팅 문자열  
     * sFormat02: 수량소수점자리수  
     * sFormat03: 원화단가소수점자리수  
     * sFormat04: 외화단가소수점자리수  
     * sFormat05: 비율소숫점자리수  
     * sFormat06: 금액소숫점자리수  
     * sFormat07: 외화소숫점자리수  
     * sFormat08: 환율소숫점자리수  
     */
    numberColumnFormat?: string | ERPNumberFormatType;

    /**
     * (0 반올림, 1 절사, 2 절상)
     */
    lastCutType?: "0" | "1" | "2" | 0 | 1 | 2,
    /**
     * type = number일 경우 양수만 입력가능하게 할지 여부
     */
    positiveOnly?: boolean;
    /**
     * 컬럼공통. 액션 버튼을 가지는지 여부
     * 액션버튼을 click하면 onClickCustomActionButton 이벤트가 발행된다.
     */
    hasActionButton?: boolean;
    /**
     * 이미지버튼 리스트
     */
    imageButtonList?: ImageButton[];
    /**
     * 이미지버튼 렌더러
     */
    imageButtonRenderers?: IImageButtonRenderer[];
    /**
     * 
     */
    renderer?: string | ((rowIndex: number, columnName: string) => string);
    /**
     * 
     */
    getProfileInfo?: ((e: { rowIndex: number, columnName: string, values: any }) => Promise<UserProfilePopUpParameter>);
    /**
     * @internal
     * @deprecated
     * type = codePicker일 경우 코드피커의 id
     */
    codePickerId?: string;
    /**
     * type = codePicker일 경우 코드피커의 인스턴스, 아이디가 있을 경우 아이디 우선
     */
    customCodePicker?: IBuiltInCodePicker | ((e: any) => IBuiltInCodePicker);

    // /**
    //  * 코드피커에서 페이징을 사용할것인지 여부
    //  */
    // codePickerPaging?: boolean;
    /**
     * type = codePicker 코드피커 멀티 사용 여부
     */
    canMultiSelect?: boolean | ((e: { columnName: string, rowIndex: number }) => boolean);
    /**
     * 코드선택이 이루어진후 자동으로 다음컬럼으로 이동할지 여부
     * @default true
     */
    autoMoveNextCell?: boolean;
    /**
     * type = codePicker일 경우 코드피커의 getData 함수로 보낼 파라미터, 오브젝트나 콜백으로 설정
     */
    parameters?: any;
    /**
     * type = codePicker일 경우. 
     * onlyDialog: 키보드입력을 허용하지 않는다. 
     * notAllowEditorSearch: 셀 검색대신 입력된 값을 code로 해석한다.
     */
    codePickerEditType?: CodePickerEditType,
    /**
     * type = codePicker일 경우. 선택한 아이템의 셀에 보여지는 방식을 지정하는 콜백
     */
    selectedItemDisplayCallBack?: (e: any) => string
    /**
     * type = number일 경우 nan을 0으로 보여줄것인지 여부
     */
    nanToZero?: boolean,
    /**
     * type = number일 경우 0을 보여줄것인지 여부
     */
    showZero?: boolean,

    /**
     * 컬럼공통. 필수값인지 여부
     */
    required?: boolean | ((e: { rowIndex: number, columnName: string }) => boolean);
    /**
     * 컬럼공통.읽기전용값이 여부
     */
    readonly?: boolean;
    /**
     * type = date일 경우 컬럼의 날짜포메팅, 날짜에 대한 포멧 
     * yyyyMMdd, yyyyMM, yyyy
     */
    dateFormat?: DateFormat; //| ((value: any) => string);

    /**
     * 날짜 다이얼로그 정렬 방식 
     */
    dateDialogAlign?: AlignType;

    /**
     * 날짜 다이얼로그 정렬 방식 
     */
    dateDialogPosition?: PositionType;

    /**
     * 툴팁을 사용할것 인지 여부
     */
    useTooltip?: boolean;
    /**
     * type = mask일 경우 마스킹 방식
     */
    maskType?: MaskType | ((rowIndex: number, target: OBTDataGridInterface) => MaskType | undefined);
    /**
     *  type = mask, maskType = custom일 경우, 셀의 에디터의 editMask
     */
    editMask?: string,

    /**
     *  type = mask일때 invalid한 value를 허용할것인지 여부, invalid한 value는 자동 스타일 적용
     *  길이까지 체크하지는 않으니 maxLength와 함께 사용할것
     */
    allowInvalidValue?: boolean,

    /**
     * type = text 일 경우, 입력되는 값을 모두 대문자로, 소문자로, 입력하는대로 변환 설정할 수 있습니다.
     * - normal : 입력되는대로 표시한다.
     * - upper : 모두 대문자로 표시한다.
     * - lower : 모두 소문자로 표시한다.
     * - default : editor가 이 값이면 컬럼의 textInputCase 값을 따른다.
     */
    textCase?: 'upper' | 'default' | 'lower' | 'normal',

    /**
     * 
     */
    useMasking?: boolean,
    /**
     * type = mask, maskType = custom일 경우 디스플레이되는 방식을 지정하는 콜백, 
     * 파라미터로 순수데이터가 들어오고 표시될 텍스트를 리턴시킨다.
     */
    customMaskCallback?: (value: any, index: number) => string;
    /**
     * type = dropdown일 경우 사용될 데이터 배열
     */
    dropDownDataItems?: any[];
    /**
     * type = dropdown일 경우 dropDownDataItems에서 사용할 코드값의 프로퍼티
     */
    dropDownCodeProperty?: string;
    /**
     * type = dropdown일 경우 dropDownDataItems에서 사용할 텍스트값의 프로퍼티
     */
    dropDownTextProperty?: string;
    /**
     * type = dropdown일 경우
     * 드랍다운 리스트에 들어갈 데이터를 백엔드에서 Promise로 조회하여 받아오는 콜백이다.
     */
    dropDownDataSourceCallBack?: Promise<any> | (() => Promise<any>);
    /**
     * 
     */
    dropDownLabelType?: DropDownColumnLableType;
    /**
     * type = dropdown일 경우 드랍다운 리스트의 아이템 갯수 지정
     * default: 4
     */
    dropDownCount?: number,
    /**
     * 컬럼공통. 푸터 설정
     */
    footer?: {
        expression?: ColumnSummaryExpression,
        callback?: (index: any, footerIndex: number) => any,
        style?: IRealGridCellStyleOption
        groupExpression?: ColumnSummaryExpression,
        groupCallback?: (index: any, footerIndex: number) => any,
        groupStyle?: IRealGridCellStyleOption
    };
    /**
     * 단일행 footer를 사용할 경우 footer에 바인딩되는 값
     */
    footerValue?: string,
    /**
     * 단일행 footer를 사용할 경우 footer에 바인딩되는 값
     */
    groupFooterValue?: string,
    /**
     * type = text, number 계열일 경우 입력가능한 최대길이 number일경우 (전체길이.소수점길이) 형식으로 작성
     */
    maxLength?: number,
    /**
     * @deprecated
     * 아이콘을 사용할 것이지 여부
     */
    useIcon?: boolean,
    /**
     * 아이콘만 디스플레이할것인지 여부
     */
    onlyIcon?: boolean,
    /**
     * 컬럼에 사용할 이미지 아이콘의 리스트
     */
    iconImageList?: string[],

    /**
     * 이미지 라벨을 사용할 것인지 여부.
     * useIcon 옵션과 함께 true일 수 없음.
     * type='check', 'progress'등 자체적인 렌더러를 사용하는 컬럼 타입에서는 사용할 수 없음
     */
    useImageLabel?: boolean,
    /**
     * useImageLabel = true일 경우 value에 따른 배경색 RGB 지정
     */
    imageLabelBackgroundColor?: ((value: string) => string) | string,
    /**
     * useImageLabel = true일 경우 사용자지정 이미지 라벨콜백함수.
     * 인자로 넘어온 canvas의 context를 조작하여 사용
     */
    customImageLabelCallback?: (value: string, backgroundColor?: ((value: string) => string) | string) => HTMLCanvasElement;

    /**
     * 
     */
    useColorIcon?: boolean,

    /**
     * 
     */
    minimumValue?: number,
    /**
     * 
     */
    maximumValue?: number,
    /**
     * 고정적인 컬럼의 스타일
     */
    style?: IRealGridCellStyleOption,

    /**
     * type = image일 경우 이미지의 디스플레이 방식
     */
    fittingType?: ColumnImageFittingType,
    /**
     * 다이나믹 스타일 콜백
     */
    dynamicStyles?: (grid: any, index: any, value: any) => IRealGridCellDynamicStyleOption,
    /**
     *  type = number일 경우 해당 콜백에서 계산되어 리턴된 값이 표기된다. 
     */
    calculateCallback?: (dataRow: any, fieldName: string, fieldNames: string[], values: any) => number,
    /**
     * type = codePicker일 경우 코드가 선택된 이후 호출되는 콜백
     */
    afterCodeHelpCallback?: (e: any) => {},

    codePickerDialogParameters?: (e: { rowIndex: number, columnName: string }) => any,

    /**
     * @deprecated
     * 오타 buttonVisibility을 사용하세요
     */
    buttonVisiblity?: "none" | "mouseOver" | "always" //| () =>  boolean,

    /**
     * date, dropdown등 액션버튼을 가진 컬럼 타입의 경우 버튼이 보여지는 방법
     * "none" : 버튼을 보이지 않는다. 
     * "mouseOver" : 보이지 않다가 마우스가 올라간 순간 버튼이 보여진다. 
     * "always" : 항상 버튼이 보여진다.
     * 콜백으로 선언시 showButtonOnlyEditable 속성과 함께 사용할 수 없다.
     */
    buttonVisibility?: "none" | "mouseOver" | "always" | ((e: { target: OBTDataGridInterface, rowIndex: number, columnName: string, focused: boolean, mouseEntered: boolean }) => boolean),

    /**
     * 액션버튼을 가진 컬럼 타입일때 수정 가능한 셀의 경우만 버튼을 표시할지 여부.
     * hasActionButton = true 이면 기본값 false
     * type = (codePicker, dropDown, date) 일 경우 기본값 true
     */
    showButtonOnlyEditable?: boolean,
    /**
     * type = (date, dateTime)일 경우
     * 요일을 함께 표기 할것인지 여부 
     */
    showDayOfWeek?: boolean | ((original: number) => string),

    /**
     * type = 'time' 컬럼의 값이 hh:mm 형태가 아닐 때, hh:mm 형태로 매핑하기위한 콜백
     */
    mapTimeValueCallback?: ((value: string | null | undefined) => string | null | undefined),
    /**
     * 동적으로 드롭다운 리스트를 변경할 경우 초반에 들어갈 리스트입니다.
     */
    originalDropDownList?: any[],
    /**
     * 동적으로 드롭다운 리스트를 변경할 때 사용합니다.
     */
    dynamicDropDownItemFilter?: (values: any, item: any) => boolean,
    /**
     * type = 'time' 컬럼
     * list의 시간 간격을 1시간단위 또는 30분단위로 설정
     */
    timePeriodType?: 'hour' | 'half',
    /**
     * type = 'autoComplete' 컬럼
     * 키 프로퍼티 설정
     */
    keyProperty?: string
    /** 
     * type = 'autoComplete' 컬럼
     * 찾는 함수
     */
    searchCallback?: (e: string) => Promise<any>,
    /**
     * type = 'autoComplete' 컬럼
     * 다이얼로그 정렬
     */
    dialogAlign?: "left" | "center" | "right",
    /**
     * type = 'autoComplete' 컬럼
     * 클릭 혹은 엔터시 바로 값을 매핑할것인지에 대한 여부를 지정
     */
    useDirectValueMapping?: boolean,
    /**
     * 
     */
    allowEditorValue?: boolean,

    /**
     * 개인정보 암호화 여부
     */
    usePrivacy?: boolean,

    /**
     * 개인정보 암호화 조회 옵션
     */
    privacyBehavior?: PrivacyBehaviorEnum,

    /**
     * @internal
     */
    guideMessage?: string | JSX.Element | ((e: { rowIndex: number, columnName: string }) => JSX.Element),
    /**
     * @internal
     * type = codePicker code 에 해당하는 컬럼명
     */
    codeColumnName?: string;
    /**
     * @internal
     * type = codePicker name 에 해당하는 컬럼명
     */
    textColumnName?: string;
    /**
     * @internal
     */
    internalCodePickerState?: "search" | "unbinded" | "binded";
    /**
     * 
     */
    sortable?: boolean;
    // /**
    //  * @internal
    //  */
    // lookupSourceId?: string;

    // /**
    //  * @internal
    //  */
    // lookupKeyFields?: string[];

    /**
     * 텍스트 에디트를 사용할것인지 여부
     * default: false
     */
    textReadOnly?: boolean,

    /**
     * hidable: false 일경우 컬럼표시 기능에서 표시여부를 지정할수 없다.
     */
    hidable?: boolean,

    /**
     * 
     */
    ignoreEditing?: boolean,

    /**
     * @internal
     */
    unmanaged?: any;
}

interface IImageButtonRenderer {
    id: string,
    buttonList: {
        id: string,
        labelText: string
        width: number
    }[]
}

/**
 * 버튼이 보여질지 여부를 결정하는 function을 리턴
 * @param grid 
 * @param column 
 */
export const buttonVisiblityFunction = (targetGrid: OBTDataGridInterface, column: IColumn) => {
    return (grid, index, focused, mouseEntered) => {
        if (typeof column.buttonVisibility === 'function') {
            return column.buttonVisibility({
                columnName: index.column,
                focused: focused,
                mouseEntered: mouseEntered,
                rowIndex: index.itemIndex,
                target: targetGrid
            })
        }

        let isButtonVisible = false;
        if (column.buttonVisibility === "none") {
            isButtonVisible = false;
        } else if ((!column.buttonVisibility || column.buttonVisibility === "mouseOver") && (mouseEntered || focused)) {
            isButtonVisible = true;
        } else if (column.buttonVisibility === "always") {
            isButtonVisible = true;
        }

        if (isButtonVisible === true) {
            if (column.showButtonOnlyEditable === undefined || column.showButtonOnlyEditable === true) {
                isButtonVisible = targetGrid.isColumnEditable(column, index.itemIndex);
            }
        }
        return isButtonVisible;
    }
}

export interface INewColumn extends IColumnIdentifierProperty, IColumnCommonProperty {

    /**
     * @example header: '헤더이름'
     */
    header?: string | IHeaderProperty,

    footer?: IFooterOptionProperty,

    typeText?: ITextColumnProperty,

    typeMultiLine?: IMultiLineColumnProperty,

    typeNumber?: INumberColumnProperty,

    typeCodePicker?: Omit<Omit<ICodePickerColumnProperty, 'customCodePicker'>, 'codePickerId'> & { codePicker: IBuiltInCodePicker },

    typeDropDown?: IDropdownColumnProperty,

    typeTime?: ITimeColumnProperty,

    typeDate?: IDateColumnProperty,

    typeProgress?: IProgressColumnProperty,

    typeImage?: IImageColumnProperty,

    typeMask?: IMaskColumnProperty,

    typeGroup?: INewGroupColumnProperty,

    typeCheck?: ICheckColumnProperty,

    typeAutoComplete?: IAutoCompleteProperty,

    icon?: IIConOptionProperty,

    imageLabel?: IImageLabelOption,

    // button?: IButtonOptionProperty,

    privacy?: IPrivacyOptionProperty,
}

export function toLegacyColumn(column: INewColumn): IColumn {
    const type =
        column.typeCodePicker ? ColumnType.codePicker :
            column.typeText ? ColumnType.text :
                column.typeMultiLine ? ColumnType.multiline :
                    column.typeDate ? ColumnType.date :
                        column.typeDropDown ? ColumnType.dropDown :
                            column.typeImage ? ColumnType.image :
                                column.typeMask ? ColumnType.mask :
                                    column.typeProgress ? ColumnType.progress :
                                        column.typeTime ? ColumnType.time :
                                            column.typeCheck ? ColumnType.check :
                                                column.typeNumber ? ColumnType.number :
                                                    column.typeGroup ? ColumnType.group :
                                                        column.typeAutoComplete ? ColumnType.autoComplete :
                                                            ColumnType.data;



    const header = typeof column.header === 'string' ? {
        header: column.header,
    } as IHeaderProperty : column.header;

    let childColumns: IColumn[] | null = null;
    if (column.typeGroup && column.typeGroup.columns) {
        childColumns = column.typeGroup.columns.map(toLegacyColumn);
    }

    const icon = {
        useIcon: column.icon ? true : false,
        ...column.icon
    } as IIConOptionProperty;

    const codePicker = {
        customCodePicker: column.typeCodePicker ? column.typeCodePicker.codePicker : undefined,
        ...column.typeCodePicker
    } as ICodePickerColumnProperty;

    return {
        name: column.name,
        subName: column.subName,
        fieldName: column.fieldName,
        type: type,
        columns: childColumns ? childColumns : undefined,
        ...column as IColumnCommonProperty,
        ...header,
        ...column.icon ? icon : {},
        ...column.footer,
        ...column.typeText,
        ...column.typeNumber,
        ...column.typeCodePicker ? codePicker : {},
        ...column.typeDropDown,
        ...column.typeTime,
        ...column.typeDate,
        ...column.typeProgress,
        ...column.typeImage,
        ...column.typeMask,
        ...column.icon,
        ...column.imageLabel,
        ...column.privacy,
        ...column.typeAutoComplete
    }
}

// export class ColumnsBuilder {
//     private columns: IColumn[] = [];
//     private columnCache: IColumn | null = null;

//     constructor(columns?: IColumn[]) {
//         if (columns) {
//             this.columns = columns;
//         }
//     }

//     public and() {
//         if (this.columnCache) {
//             this.columns.push(this.columnCache);
//         }

//         return new ColumnsBuilder(this.columns);
//     }

//     public get() {
//         return this.columnCache;
//     }

//     public build() {
//         if (this.columnCache) {
//             const isCacheItemAlreadyAdded = this.columns.some((column) => {
//                 return column.name === this.columnCache!.name
//             });

//             if (!isCacheItemAlreadyAdded) {
//                 this.columns.push(this.columnCache);
//             }
//         }

//         return this.columns;
//     }

//     public text(option: IBaseColumnProperty & ITextColumnProperty) {
//         this.columnCache = {
//             type: ColumnType.text,
//             ...option,
//         }

//         return this;
//     }

//     public number(option: IBaseColumnProperty & INumberColumnProperty) {
//         this.columnCache = {
//             type: ColumnType.number,
//             ...option,
//         }

//         return this;
//     }

//     public dropDown(option: IBaseColumnProperty & IDropdownColumnProperty) {
//         this.columnCache = {
//             type: ColumnType.dropDown,
//             ...option,
//         }

//         return this;
//     }

//     public time(option: IBaseColumnProperty & ITimeColumnProperty) {
//         this.columnCache = {
//             type: ColumnType.time,
//             ...option,
//         }

//         return this;
//     }

//     public mask(option: IBaseColumnProperty & IMaskColumnProperty) {
//         this.columnCache = {
//             type: ColumnType.mask,
//             ...option,
//         }

//         return this;
//     }

//     public progress(option: IBaseColumnProperty & IProgressColumnProperty) {
//         this.columnCache = {
//             type: ColumnType.progress,
//             ...option,
//         }

//         return this;
//     }

//     public codePicker(option: IBaseColumnProperty & ICodePickerColumnProperty) {
//         this.columnCache = {
//             type: ColumnType.codePicker,
//             ...option,
//         }

//         return this;
//     }

//     public imageLabel(option: IBaseColumnProperty & IImageLabelOption) {
//         this.columnCache = {
//             useImageLabel: true,
//             ...option,
//         }

//         return this;
//     }

//     public withHeader(option: IHeaderProperty) {
//         return this.with<IHeaderProperty>(option);
//     }

//     public withFooter(option: IFooterOptionProperty) {
//         return this.with<IFooterOptionProperty>(option);
//     }

//     public withIcon(option: IIConOptionProperty) {
//         return this.with<IIConOptionProperty>(option);
//     }

//     public withPrivacy(option: IPrivacyOptionProperty) {
//         return this.with<IPrivacyOptionProperty>(option);
//     }

//     public withImageLabel(option: IImageLabelOption) {
//         return this.with<IImageLabelOption>(option);
//     }

//     public withImageButton(option: IImageButtonOptionProperty) {
//         return this.with<IImageButtonOptionProperty>(option);
//     }

//     public withUnmanaged(option: any) {
//         return this.with<any>(option);
//     }

//     private with<T>(option: T) {
//         if (!this.columnCache) {
//             throw Error("column can't be null");
//         }

//         this.columnCache = {
//             ...this.columnCache,
//             ...option
//         }
//         return this;
//     }

//     public custom(callback: (column: IColumn | null) => IColumn) {
//         this.columnCache = callback(this.columnCache);

//         return this;
//     }

//     public column(option: IColumn) {
//         this.columnCache = option;

//         return this;
//     }
// }

// function ColumnsBuilderTest() {
//     const column = new ColumnsBuilder()
//         .text({
//             name: 'text',
//         })
//         .withHeader({
//             header: '헤더',
//             headerStyle: {
//             }
//         })
//         .withFooter({
//             footer: {

//             }
//         })
//         .and()
//         .time({
//             name: 'test',
//         })
//         .and()
//         .build()

//     const column2 = {
//         name: 'dd',
//         codePicker: {
//             canMultiSelect: true,
//             prefix: 'd',
//             textReadOnly: true,
//             alignment: 'center',

//         },
//     } as INewColumn


// }

// ColumnsBuilderTest();
