import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CheckboxChecked from 'luna-rocket/LUXSVGIcon/Duzon/FullSize/CheckboxChecked'
import Checkbox from 'luna-rocket/LUXSVGIcon/Duzon/FullSize/Checkbox'
import CheckboxDisabled from 'luna-rocket/LUXSVGIcon/Duzon/FullSize/CheckboxDisabled'
import CheckboxDisabledChecked from 'luna-rocket/LUXSVGIcon/Duzon/FullSize/CheckboxDisabledChecked'
import Radio from 'luna-rocket/LUXSVGIcon/Duzon/FullSize/Radio'
import RadioChecked from 'luna-rocket/LUXSVGIcon/Duzon/FullSize/RadioChecked'
import RadioDisabled from 'luna-rocket/LUXSVGIcon/Duzon/FullSize/RadioDisabled'
import RadioDisabledChecked from 'luna-rocket/LUXSVGIcon/Duzon/FullSize/RadioDisabledChecked'
import LUXSpriteIcon from 'luna-rocket/LUXSpriteIcon'
import keycode from 'keycode'
//OBT 수정 import focusStyle from './UFOCheckBox.module.scss' 추가
import focusStyle from './UFOCheckBox.module.scss'
import { Util } from '../../Common';

function getStyles(props) {
    return {
        iconCheckBoxStyle: {
            position: 'absolute',
            left: 0,
            top: '50%',
            marginTop: '-7px',
            cursor: props.disabled || props.finish ? 'not-allowed' : 'pointer',
            width: '14px',
            height: '14px'
        },
    };
}

class UFOCheckBox extends Component {
    static propTypes = {
        /**
         *  CheckBox의 오른쪽에 보여지는 텍스트입니다.
         */
        labelText: PropTypes.string,
        /**
         *  CheckBox가 체크 여부를 결정하는 속성입니다
         */
        checked: PropTypes.bool,
        /**
         *  CheckBox 최상단 div element에 적용되는 스타일 속성입니다.
         */
        style: PropTypes.object,
        /**
         *  CheckBox 아이콘 영역에 적용되는 스타일 속성입니다.
         */
        iconCheckBoxStyle: PropTypes.object,
        /**
         *  CheckBox 오른쪽 텍스트 span element에 적용되는 스타일 속성입니다.
         */
        labelCheckBoxStyle: PropTypes.object,
        /**
         *  CheckBox의 input element에 적용되는 스타일 속성입니다.
         */
        inputCheckBoxStyle: PropTypes.object,
        /**
         *  CheckBox의 아이콘 이미지를 라디오 아이콘과 같이 보여주기 위해 사용되는 속성입니다.
         */
        radioIcon: PropTypes.bool,
        /**
         *  CheckBox를 비활성화 상태로 지정할 수 있는 속성입니다.
         */
        disabled: PropTypes.bool,
        /**
         *  마감상태(readOnly)에 대한 속성으로 true일 때 동작됩니다.
         */
        finish: PropTypes.bool,
        /**
         *  가로정렬과 세로정렬에 대한 속성입니다.
         */
        horizontal: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        checked: false,
        radioIcon: false,
        finish: false,
        horizontal: true,
    };

    myRefs = {
        wrapper: React.createRef(),
        root: React.createRef(),
        checkbox: React.createRef()
    }

    constructor(props) {
        super(props);
        this.state = {
            switch: false,
            isTabPressed: true,
            isFocused: false,
        }
    }

    componentDidMount() {
        this.setState({ switch: this.props.checked });
    };

    componentWillReceiveProps(nextProps) {
        this.setState({ switch: nextProps.checked });
    };

    handleFocus = (event) => {
        if (event.target.nodeName === 'INPUT') {
            return false;
        }
        this.setState({
            isFocused: true,
        });

        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    };

    handleBlur = (event) => {
        if (event.target.nodeName === 'INPUT') {
            return false;
        }
        this.setState({
            isTabPressed: true,
            isFocused: false,
        });
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    };

    handleMoveFocus = (move = 'right') => {
        if (this.props.onMoveFocus) {
            this.props.onMoveFocus(move);
        }
    };

    handleKeyDown = (event) => {
        event.stopPropagation();

        switch (keycode(event)) {
            case 'space':
                event.preventDefault();
                //this.handleOnCheck();
                this.handleChange();
                break;
            case 'down':
            case 'left':
            case 'up':
            case 'right':
            case 'enter':
                event.preventDefault();
                this.handleMoveFocus(keycode(event));
                break;
            default:
                break;
        }

        if (this.props.onKeyDown) this.props.onKeyDown(event);
    };

    handleClick = (event) => {
        this.setState({
            isTabPressed: false,
        });
    };

    focus = (move) => {
        if (this.props.disabled || this.props.finish) { //추가해봄
            if (this.props.onMoveFocus) {
                this.props.onMoveFocus(move);
                return;
            }
        }
        if (this.myRefs.root.current) this.myRefs.root.current.focus();
        this.setState({
            isFocused: true,
            isTabPressed: true,
        });
    };

    handleChange = (event) => {
        if (this.props.disabled) return;
        if (this.props.onChange && (!this.props.disabled || !this.props.finish)) {
            this.props.onChange(event, !this.props.checked);
        }
    }

    handleMouseDown = (event) => {
        if (this.props.onMouseDown && (!this.props.disabled || !this.props.finish)) {
            this.props.onMouseDown(event)
        }
    }

    handleMouseMove = (event) => {
        if (this.props.onMouseMove && (!this.props.disabled || !this.props.finish)) {
            this.props.onMouseMove(event)
        }
    }

    handleMouseUp = (event) => {
        if (this.props.onMouseUp && (!this.props.disabled || !this.props.finish)) {
            this.props.onMouseUp(event)
        }
    }

    handleMouseOut = (event) => {
        if (this.props.onMouseOut && (!this.props.disabled || !this.props.finish)) {
            this.props.onMouseOut(event)
        }
    }

    handleMouseOver = (event) => {
        if (this.props.onMouseOver && (!this.props.disabled || !this.props.finish)) {
            this.props.onMouseOver(event)
        }
    }

    handleKeyPress = (event) => {
        if (this.props.onKeyPress && (!this.props.disabled || !this.props.finish)) {
            this.props.onKeyPress(event)
        }
    }

    handleKeyUp = (event) => {
        if (this.props.onKeyUp && (!this.props.disabled || !this.props.finish)) {
            this.props.onKeyUp(event)
        }
    }

    handleKeyUp = (event) => {
        if (this.props.onKeyUp && (!this.props.disabled || !this.props.finish)) {
            this.props.onKeyUp(event)
        }
    }


    render() {
        const {
            labelText,
            style,
            labelCheckBoxStyle,
            iconCheckBoxStyle,
            disabled,
            finish,
            radioIcon,
            horizontal
        } = this.props;

        const inputProps = {
            onKeyDown: this.handleKeyDown,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            //OBT 수정 onMouseDown -> onClick 으로 수정
            onClick: this.handleClick,

            //OBT 수정 아래 8개 항목 추가
            onMouseDown: this.handleMouseDown,
            onMouseMove: this.handleMouseMove,
            onMouseUp: this.handleMouseUp,
            onMouseOut: this.handleMouseOut,
            onMouseOver: this.handleMouseOver,
            onKeyPress: this.handleKeyPress,
            onKeyUp: this.handleKeyUp,
            // ...other,
        };

        const styles = getStyles(this.props);

        let spriteIconElement;

        if (radioIcon) {
            if (this.state.switch) {
                if (disabled || finish) {
                    spriteIconElement = <RadioDisabledChecked style={Object.assign({}, styles.iconCheckBoxStyle, iconCheckBoxStyle)} />
                } else {
                    if (this.state.isFocused && this.state.isTabPressed) {
                        spriteIconElement = <LUXSpriteIcon sprite="radioCheckedFocus" style={Object.assign({}, styles.iconCheckBoxStyle, iconCheckBoxStyle)} />
                    } else {
                        spriteIconElement = <RadioChecked style={Object.assign({}, styles.iconCheckBoxStyle, iconCheckBoxStyle)} />
                    }
                }
            } else {
                if (disabled || finish) {
                    spriteIconElement = <RadioDisabled style={Object.assign({}, styles.iconCheckBoxStyle, iconCheckBoxStyle)} />
                } else {
                    if (this.state.isFocused && this.state.isTabPressed) {
                        spriteIconElement = <LUXSpriteIcon sprite="radioDefaultFocus" style={Object.assign({}, styles.iconCheckBoxStyle, iconCheckBoxStyle)} />
                    } else {
                        spriteIconElement = <Radio style={Object.assign({}, styles.iconCheckBoxStyle, iconCheckBoxStyle)} />
                    }
                }
            }
        } else {
            if (this.state.switch) {
                if (disabled || finish) {
                    spriteIconElement = <CheckboxDisabledChecked
                        style={Object.assign({}, styles.iconCheckBoxStyle, iconCheckBoxStyle)} />
                } else {
                    if (this.state.isFocused && this.state.isTabPressed) {
                        //OBT 수정 <LUXSpriteIcon sprite="checkboxFocused" style={Object.assign({}, styles.iconCheckBoxStyle, iconCheckBoxStyle)} /> 을 <CheckboxChecked className={focusStyle.focusCheckBox} style={Object.assign({}, styles.iconCheckBoxStyle, iconCheckBoxStyle, { background: '#EFF7FF' })} />로 변경
                        spriteIconElement = <CheckboxChecked className={focusStyle.focusCheckBox} style={Object.assign({}, styles.iconCheckBoxStyle, iconCheckBoxStyle, { background: '#EFF7FF' })} />
                    } else {
                        spriteIconElement = <CheckboxChecked className={focusStyle.focusCheckBox} style={Object.assign({}, styles.iconCheckBoxStyle, iconCheckBoxStyle)} />
                    }
                }
            } else {
                if (disabled || finish) {
                    spriteIconElement =
                        <CheckboxDisabled style={Object.assign({}, styles.iconCheckBoxStyle, iconCheckBoxStyle)} />
                } else {
                    if (this.state.isFocused && this.state.isTabPressed) {
                        //OBT 수정 <LUXSpriteIcon sprite="Checkbox" style={Object.assign({}, styles.iconCheckBoxStyle, iconCheckBoxStyle)} /> 을 <Checkbox className={focusStyle.focusCheckBox} style={Object.assign({}, styles.iconCheckBoxStyle, iconCheckBoxStyle, { background: '#EFF7FF' })} />로 변경
                        spriteIconElement = <Checkbox className={focusStyle.focusCheckBox} style={Object.assign({}, styles.iconCheckBoxStyle, iconCheckBoxStyle, { background: '#EFF7FF' })} />
                    } else {
                        spriteIconElement = <Checkbox style={Object.assign({}, styles.iconCheckBoxStyle, iconCheckBoxStyle)} />
                    }
                }
            }
        }

        return (
            <div
                ref={this.myRefs.wrapper}
                className={horizontal ? focusStyle.LS_form : Util.getClassNames(focusStyle.LS_form, focusStyle.vertical_align)}
                style={
                    { verticalAlign: 'top', ...style }
                }
                onClickCapture={this.handleChange}
            >
                <label
                    ref={this.myRefs.root}
                    tabIndex={disabled || finish || this.props.frozen ? -1 : 0}
                    style={Object.assign({}, styles.root, { outline: 'none', userSelect: 'none' })}
                    {...inputProps}
                >
                    <span>{spriteIconElement}</span>
                    {/* OBT 수정 style={labelText} -> style={Object.assign({ labelCheckBoxStyle }, disabled ? { cursor: 'not-allowed' } : { cursor: 'pointer' })} 변경, onClick={this.handleChange} 추가 */}
                    <span className={Util.getClassNames(focusStyle.label_text, focusStyle.a)} style={Object.assign({ labelCheckBoxStyle }, disabled ? { cursor: 'not-allowed' } : { cursor: 'pointer' })}>{labelText}</span>
                </label>
            </div>
        );
    }
}

export default UFOCheckBox;
