import React from 'react';
import SideBarContent, { SideBarContentType } from './SideBarContent';
import { ISideBarContent } from './SideBar';
import { OrbitInternalLangPack } from '../Common/Util';

interface ISBCMessage {
    item: ISideBarContent
}

export default class SBCMessage extends React.Component<ISBCMessage, {}> {
    render() {
        const data = this.props.item.data || {};
        const message = (data.message && (typeof data.message !== 'string' || data.message.length > 0)) ? data.message :
            <span style={{ color: 'rgb(177, 177, 177)' }}>{OrbitInternalLangPack.getText('WE000028503', '안내메시지 영역입니다.')}</span>;
        return (
            <SideBarContent item={Object.assign({}, this.props.item, {
                type: SideBarContentType.default
            })}>
                {message}
            </SideBarContent>
        )
    }
}
