interface JsonType {
    [name: string]: {
        type: PropType | PropType[],
        optional?: boolean,
        parameters?: { name: string, type: PropType | PropType[] } | { name: string, type: PropType | PropType[] }[],
        result?: PropType,
        description?: string
    } | JsonType
}

type EnumType = {
    enumName: string
}[]

export enum CommonType {
    string = '@string',
    number = '@number',
    boolean = '@boolean',
    function = '@function',
    image = '@image',
    color = '@color',
    any = '@any'
};

declare type PropType = CommonType | EnumType | JsonType | string;

export interface IPropDefinition {
    name: string,
    type: PropType | PropType[],
    optional?: boolean,
    default?: any,
    parameters?: { name: string, type: PropType | PropType[], description?: string } | { name: string, type: PropType | PropType[], description?: string }[],
    result?: PropType | PropType[],
    description?: string,
    title?: string,
    tooltip?: any,
    resultExample?: any
}

export const CommonDefinitions: {
    [name: string]: (option?: any) => (IPropDefinition | IPropDefinition[])
} =
    (() => {
        const def = (_definition: IPropDefinition) => {
            return (definition?: Partial<IPropDefinition>) => ({ ..._definition, ...definition });
        };

        const target = { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' };
        const focusEvent = { type: 'FocusEvent', description: '이벤트 객체' };
        const mouseEvent = { type: 'MouseEvent', description: '이벤트 객체' };
        const keyboardEvent = { type: 'MouseEvent', description: '이벤트 객체' };

        const PropDefinitions = {
            id: def({ name: 'id', type: CommonType.string, optional: true, description: 'AutoValueBinder 와 연결키를 지정합니다.' }),
            frozen: def({ name: 'frozen', type: CommonType.boolean, optional: true, default: false, description: '아무런 동작을 할 수 없도록 설정합니다.' }),
            className: def({ name: 'className', type: CommonType.string, optional: true, description: '최상단 Element 의 Class 를 지정합니다.' }),
            width: def({ name: 'width', type: CommonType.string, optional: true, description: '컴포넌트 넓이(width)를 지정합니다.' }),
            height: def({ name: 'height', type: CommonType.string, optional: true, description: '컴포넌트 높이(height)를 지정합니다.' }),
            required: def({ name: 'required', type: CommonType.boolean, optional: true, default: false, description: '필수입력 여부를 지정합니다.' }),
            disabled: def({ name: 'disabled', type: CommonType.boolean, optional: true, default: false, description: '활성화여부를 지정합니다.' }),
            readonly: def({ name: 'readonly', type: CommonType.boolean, optional: true, default: false, description: '읽기전용(선택은 가능하지만 값은 변경불가) 여부를 지정합니다.' }),
            value: def({ name: 'value', type: CommonType.any, description: '값을 지정합니다.' }),
            subValue: def({ name: 'subValue', type: CommonType.any, description: '보조언어 값을 지정합니다.', optional: true }),
            exceptValue: def({ name: 'exceptValue', type: 'any[]', description: '전체선택에서 제외되는 값을 지정합니다.' }),
            subId: def({ name: 'subId', type: CommonType.string, optional: true, description: 'AutoValueBinder 와 보조언어 연결키를 지정합니다.' }),
            useSubLang: def({ name: 'useSubLang', type: CommonType.boolean, optional: true, default: false, description: '보조언어 사용여부를 지정합니다.' }),
            style: def({ name: 'style', type: CommonType.any, optional: true, description: '최상단 Element 의 Style 을 지정합니다.' }),
            labelText: def({ name: 'labelText', type: CommonType.string, optional: true, description: '라벨에 표시될 문구를 지정합니다.' }),
            placeHolder: def({ name: 'placeHolder', type: CommonType.string, optional: true, description: '데이터 미 입력시 기본표시되는 문구를 지정합니다.' }),
            focus: def({ name: 'focus', type: CommonType.function, optional: true, description: '컴포넌트의 포커스를 줄때 사용하는 내장함수 입니다.' }),
            isEmpty: def({ name: 'isEmpty', type: CommonType.function, optional: true, description: '컴포넌트의 값이 빈값인지 확인하는 내장함수 입니다.' }),
            validate: def({ name: 'validate', type: CommonType.function, optional: true, description: '컴포넌트가 필수입력이면서 값이 빈값인지 확인하는 내장함수 입니다.' }),
            onFocus: def({
                name: 'onFocus', type: CommonType.function, parameters: {
                    name: 'e',
                    type: {
                        target,
                        event: focusEvent
                    }
                }, optional: true, description: 'Focus 가 입력된 경우 발생하는 Callback 함수입니다.'
            }),
            onBlur: def({
                name: 'onBlur', type: CommonType.function, parameters: {
                    name: 'e',
                    type: {
                        target
                    }
                }, optional: true, description: 'Focus 를 잃은 경우 발생하는 Callback 함수입니다.'
            }),
            onChange: (definition?: Partial<IPropDefinition>) => {
                const { type, ...others } = definition || {};
                return {
                    name: 'onChange', type: CommonType.function, parameters: {
                        name: 'e',
                        type: {
                            target,
                            value: { type: type || CommonType.any, description: '변경된 값' },
                            Source: { type: CommonType.any, optional: true, description: '변경된 값과 연관된 데이터' }
                        }
                    },
                    description: '입력된 값이 변경될 때 발생하는 Callback 함수입니다.',
                    ...others
                };
            },
            onChangeWithSub: (definition?: Partial<IPropDefinition>) => {
                const { type, ...others } = definition || {};
                return {
                    name: 'onChange', type: CommonType.function, parameters: {
                        name: 'e',
                        type: {
                            target,
                            value: { type: type || CommonType.any, description: '변경된 값' },
                            subValue: { type: type || CommonType.any, description: '변경된 보조언어 값' },
                            Source: { type: CommonType.any, description: '변경된 값과 연관된 데이터' }
                        }
                    }, description: '보조언어 사용시 입력된 값이 변경될 때 발생하는 Callback 함수입니다.',
                    ...others
                };
            },
            onChangeCodePicker: def({
                name: 'onChange', type: CommonType.function, parameters: {
                    name: 'e',
                    type: {
                        target,
                        value: { type: 'any[] | CodePickerValue', description: '변경된 값(Array형태) 혹은 CodePickerValue' },
                        Source: { type: CommonType.any, description: '변경된 값과 연관된 데이터' },
                        useTotalToEmpty: { type: CommonType.boolean, optional: true, description: '전체선택시 value 를 빈값으로 리턴하는 옵션' }
                    }
                }, description: '코드피커의 값이 변경되었을 경우 발생하는 callback 입니다. value 의 형태와는 달리 array 형태로만 리턴됩니다.'
            }),
            onValidate: def({
                name: 'onValidate', type: CommonType.function, parameters: {
                    name: 'e',
                    type: {
                        target,
                        oldValue: { type: CommonType.any, description: '변경전 값' },
                        newValue: { type: CommonType.any, description: '변경된 값' },
                        cancel: { type: CommonType.boolean, optional: true, description: 'true 로 지정시 입력이 취소됩니다.' }
                    }
                }, optional: true, description: '유효성 체크 이벤트로, e.cancel = true 로 지정하면 입력이 취소됩니다.'
            }),
            onValidateWithSub: def({
                name: 'onValidate', type: CommonType.function, parameters: {
                    name: 'e',
                    type: {
                        target,
                        oldValue: { type: CommonType.any, description: '변경전 값' },
                        newValue: { type: CommonType.any, description: '변경된 값' },
                        oldSubValue: { type: CommonType.any, description: '변경전 보조언어 값' },
                        newSubValue: { type: CommonType.any, description: '변경된 보조언어 값' },
                        cancel: { type: CommonType.boolean, optional: true, description: 'true 로 지정시 입력이 취소됩니다.' }
                    }
                }, description: '유효성 체크 이벤트로, e.cancel = true 로 지정하면 입력이 취소됩니다.'
            }),
            onActivate: def({
                name: 'onActivate', type: CommonType.function, parameters: {
                    name: 'e',
                    type: {
                        target
                    }
                }, optional: true, description: '컴포넌트가 활성화된경우 발생하는 Callback 함수입니다.'
            }),
            onDeactivate: def({
                name: 'onDeactivate', type: CommonType.function, parameters: {
                    name: 'e',
                    type: {
                        target
                    }
                }, optional: true, description: '컴포넌트가 활성상태를 잃은경우 발생하는 Callback 함수입니다.'
            }),
            onClick: def({
                name: 'onClick', type: CommonType.function, parameters: {
                    name: 'e',
                    type: {
                        target,
                        event: mouseEvent
                    }
                }, optional: true, description: 'Click 시 발생하는 Callback 함수입니다.'
            }),
            onDblClick: def({
                name: 'onDblClick', type: CommonType.function, parameters: {
                    name: 'e',
                    type: {
                        target,
                        event: mouseEvent
                    }
                }, optional: true, description: 'Double Click 시 발생하는 Callback 함수입니다.'
            }),
            onMouseDown: def({
                name: 'onMouseDown', type: CommonType.function, parameters: {
                    name: 'e',
                    type: {
                        target,
                        event: mouseEvent
                    }
                }, optional: true, description: 'MouseDown 시 발생하는 Callback 함수입니다.'
            }),
            onMouseMove: def({
                name: 'onMouseMove', type: CommonType.function, parameters: {
                    name: 'e',
                    type: {
                        target,
                        event: mouseEvent
                    }
                }, optional: true, description: 'MouseMove 시 발생하는 Callback 함수입니다.'
            }),
            onMouseUp: def({
                name: 'onMouseUp', type: CommonType.function, parameters: {
                    name: 'e',
                    type: {
                        target,
                        event: mouseEvent
                    }
                }, optional: true, description: 'MouseUp 시 발생하는 Callback 함수입니다.'
            }),
            onMouseLeave: def({
                name: 'onMouseLeave', type: CommonType.function, parameters: {
                    name: 'e',
                    type: {
                        target,
                        event: mouseEvent
                    }
                }, optional: true, description: 'MouseLeave 시 발생하는 Callback 함수입니다.'
            }),
            onMouseEnter: def({
                name: 'onMouseEnter', type: CommonType.function, parameters: {
                    name: 'e',
                    type: {
                        target,
                        event: mouseEvent
                    }
                }, optional: true, description: 'MouseEnter 시 발생하는 Callback 함수입니다.'
            }),
            onKeyDown: def({
                name: 'onKeyDown', type: CommonType.function, parameters: {
                    name: 'e',
                    type: {
                        target,
                        event: keyboardEvent
                    }
                }, optional: true, description: 'KeyDown 시 발생하는 Callback 함수입니다.'
            }),
            onKeyPress: def({
                name: 'onKeyPress', type: CommonType.function, parameters: {
                    name: 'e',
                    type: {
                        target,
                        event: keyboardEvent
                    }
                }, optional: true, description: 'KeyPress 시 발생하는 Callback 함수입니다.'
            }),
            onKeyUp: def({
                name: 'onKeyUp', type: CommonType.function, parameters: {
                    name: 'e',
                    type: {
                        target,
                        event: keyboardEvent
                    }
                }, optional: true, description: 'KeyUp 시 발생하는 Callback 함수입니다.'
            }),
            onMoveFocus: def({
                name: 'onMoveFocus', type: CommonType.function, parameters: {
                    name: 'e',
                    type: {
                        target,
                        direction: { type: ['left', 'up', 'right', 'down', 'enter'], description: '이동방향' }
                    }
                }, optional: true, description: '컴포넌트에서 다른항목으로 Focus 이동이 필요한 경우 발생하는 이벤트입니다.'
            }),
            tooltip: def({
                name: "tooltip", type: ['JSON'], optional: true,
                description: "1. labelText(필수) : string | node // 툴팁에 들어갈 내용 입니다."
                    + "\n 2. value : boolean // 값이 true 이면, tooltip이 띄워집니다. 기본으로 부모 요소에 마우스 오버했을 때나 포커스가 잡혔을 때 tooltip이 열리게 됩니다."
                    + "\n 3. theme : 'default' | 'black' | 'red' | 'orange' | 'green' | 'blue' | 'required' // 툴팁의 배경색에 대한 테마를 지정합니다."
                    + "\n 4. position : 'top' | 'bottom' | 'left' | 'right' // 컴포넌트를 띄울 위치를 지정합니다. → default: top"
                    + "\n 5. align : 'center' | 'near' | 'far' // 컴포넌트의 정렬 상태를 지정합니다. (position 내에서 anchor 와 툴팁의 정렬을 지정) \n→ default: center"
                    + "\n 6. focusValue : boolean // 컴포넌트에 focus 됐을 때 tooltip을 유지 시킬지 지정 합니다."
            }),
            pageAuthority: def({
                name: "pageAuthority", type: {
                    selectAuthType: {
                        type: ['No', 'G', 'C', 'B', 'D', 'U'],
                    },
                    modifyAuthYn: {
                        type: ["Y", "N"]
                    },
                    deleteAuthYn: {
                        type: ["Y", "N"]
                    },
                    printAuthYn: {
                        type: ["Y", "N"]
                    },
                }, optional: true, description: "권한에 따른 컴포넌트의 동작을 커스텀하고 싶을때 사용"
                    + "\n* UC"
                    + "\n-'G'일경우, 그룹 이하 모두 조회 가능"
                    + "\n-'C'일경우, 해당 회사 조회 가능"
                    + "\n* ERP"
                    + "\n-'C'일경우, 회사 이하 조회 가능"
                    + "\n-'B'일경우, 사업장 이하 조회 가능"
                    + "\n-'D'일경우, 부서 이하 조회 가능"
                    + "\n-'U'일경우, 사용자 이하 조회 가능"
            })
        };
        const _default = () => [
            PropDefinitions.id(),
            PropDefinitions.frozen(),
            PropDefinitions.className(),
            PropDefinitions.width(),
            PropDefinitions.height()
        ];

        const _event = () => [
            PropDefinitions.onMouseDown(),
            PropDefinitions.onMouseMove(),
            PropDefinitions.onMouseUp(),
            PropDefinitions.onMouseLeave(),
            PropDefinitions.onMouseEnter(),
            PropDefinitions.onKeyDown(),
            PropDefinitions.onKeyPress(),
            PropDefinitions.onKeyUp(),
            PropDefinitions.onClick(),
        ]

        const _innerFunc = () => [
            PropDefinitions.focus(),
            PropDefinitions.isEmpty(),
            PropDefinitions.validate(),
        ]

        return {
            ...PropDefinitions,
            Default: _default,
            Event: _event,
            InnerFunc: _innerFunc,
            ContainerClass: () => [
                ..._default(),
                PropDefinitions.disabled(),
                PropDefinitions.onFocus(),
                PropDefinitions.onBlur(),
                PropDefinitions.onMoveFocus()
            ],
            InputClass: (type?: PropType | PropType[]) => [
                ..._default(),
                PropDefinitions.disabled(),
                PropDefinitions.readonly(),
                PropDefinitions.required(),
                PropDefinitions.value(type ? { type } : undefined),
                PropDefinitions.onFocus(),
                PropDefinitions.onBlur(),
                PropDefinitions.onChange({ type }),
                PropDefinitions.onMoveFocus()
            ],
            SubInputClass: (type?: PropType | PropType[]) => [
                ..._default(),
                PropDefinitions.disabled(),
                PropDefinitions.readonly(),
                PropDefinitions.required(),
                PropDefinitions.value(type ? { type } : undefined),
                PropDefinitions.subId(),
                PropDefinitions.subValue(type ? { type } : undefined),
                PropDefinitions.useSubLang(),
                PropDefinitions.onFocus(),
                PropDefinitions.onBlur(),
                PropDefinitions.onChangeWithSub({ type }),
                PropDefinitions.onMoveFocus()
            ],
            CodePickerClass: () => [
                ..._default(),
                PropDefinitions.disabled(),
                PropDefinitions.readonly(),
                PropDefinitions.required(),
                PropDefinitions.value({ type: 'any[] | CodePickerValue' }),
                PropDefinitions.onFocus(),
                PropDefinitions.onBlur(),
                PropDefinitions.onChangeCodePicker(),
                PropDefinitions.onMoveFocus()
            ]
        };
    })();

export const createPropDefinitions = (...definitions: (IPropDefinition | IPropDefinition[])[]): IPropDefinition[] => {
    return definitions.flatMap(item => item);
}
export const toEnumType = <T extends { [name: string]: any }>(enumObject: T): EnumType => {
    return Object.keys(enumObject).map(key => ({ enumName: key }));
}