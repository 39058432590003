import * as React from 'react';
import { Events } from '../Common';
import { Scrollbars } from 'react-custom-scrollbars';
import OBTFloatingPanel from '../OBTFloatingPanel';

const styles = require('./AutoCompleteDialog.module.scss');

interface IAutoCompleteDialog extends Events.onKeyDown, Events.onKeyUp {
    /**
     * 다이얼로그가 열려있는지에 대한 여부를 지정합니다.
     */
    isOpen: boolean,
    /**
     * 다이얼로그 너비를 지정합니다. 
     */
    width: string,
    /**
     * 보여줄 텍스트를 keyProperty로 지정합니다.
     */
    keyProperty: string,
    /**
     * 사용자가 설정한 검색조건에 맞는 리스트들입니다.
     */
    searchCallback?: any[],
    /**
     * 다이얼로그 alignment를 지정합니다.
     */
    dialogAlign?: "left" | "center" | "right",
    /**
     * 플로팅 패널을 붙일 앵커를 지정합니다. 
     */
    anchorEl?: any,
    /**
     * 현재 선택되고 있는 index 입니다.
     */
    selectedIndex?: number,
    /**
     * 사용자가 검색하는 키워드와 일치하는 키워드입니다. 
     */
    correctKeyword?: string,
    /**
     * 자동완성 다이얼로그에 아이템을 클릭했을 때의 콜백함수 입니다.
     */
    onClickItem: (item: any) => void,
    /**
     * 자동완성 다이얼로그 아이템 위에 마우스를 움직일때 호출되는 콜백함수 입니다. 
     */
    onMouseMove: (index: number) => void,
}

interface IState {
    selectedDropDownIndex: number,
    index?: number,
    flag?: boolean
}

export default class AutoCompleteDialog extends React.Component<IAutoCompleteDialog, IState> {
    public static defaultProps: Partial<IAutoCompleteDialog> = {
        width: '150px',
    }

    public state: IState = {
        selectedDropDownIndex: 0,
        flag: false
    }

    myRefs = {
        wrapper: React.createRef<OBTFloatingPanel>(),
        scrollBar: React.createRef<Scrollbars>()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedIndex !== this.props.selectedIndex) {
            this.updateDropdownScrollTop()
        }
    }

    private updateDropdownScrollTop = () => {
        if (this.props.searchCallback &&
            this.props.selectedIndex &&
            this.myRefs.scrollBar.current) {
            // 1. 가장 위에 닿아 맨 뒤로 갈때
            if (this.props.selectedIndex === -1) {
                this.myRefs.scrollBar.current.scrollTop = 22 * this.props.searchCallback.length - 1;
                // 가장 밑으로 이동되었을 때, 첫 번째 스크롤이 비정상작동하여 flag로 추가 및 관리
                // -> 가장 밑일 경우 flag는 true
                this.setState({
                    flag: true
                })
            }
            // 2. 가장 밑에 닿아 맨 위로 갈때
            else if (this.props.selectedIndex === this.props.searchCallback.length) {
                this.myRefs.scrollBar.current.scrollTop = 0;
            }
            // 3. 그 밖에 
            else {
                const scrollTop = Math.floor(this.myRefs.scrollBar.current.scrollTop);
                const dropDownWrapperHeight = 110;
                const itemHeight = 22;
                const currentItemHeight = itemHeight && itemHeight * this.props.selectedIndex;

                if (currentItemHeight) {
                    // 1. 키보드 방향키 아래일 경우
                    if (Math.abs(scrollTop - currentItemHeight) >= 110 &&
                        scrollTop + dropDownWrapperHeight <= currentItemHeight) {
                        this.myRefs.scrollBar.current.scrollTop = scrollTop + dropDownWrapperHeight;
                        // 2. 키보드 방향키 위일 경우
                    } else if (this.state.flag && scrollTop - 22 > currentItemHeight) {
                        // 가장 밑으로 이동되었을 때, 첫 번째 스크롤이 비정상작동하여 flag로 추가 및 관리
                        // -> 첫 번째 스크롤의 경우 다른 스크롤과 약간 상이하게 작동 후 flag 는 false
                        this.myRefs.scrollBar.current.scrollTop = scrollTop - dropDownWrapperHeight;
                        this.setState({
                            flag: false
                        })
                    } else if (!this.state.flag && currentItemHeight && scrollTop > currentItemHeight) {
                        // 가장 밑이 아닐 경우(flag=false), 원래 스크롤 
                        this.myRefs.scrollBar.current.scrollTop = scrollTop - dropDownWrapperHeight;
                    }
                }
            }
        }
    }

    public scrollTop = () => {
        if (this.myRefs.scrollBar.current) {
            this.myRefs.scrollBar.current.scrollTop = 0;
        }
    }

    public focus(): void {
        if (this.myRefs.wrapper.current) {
            this.myRefs.wrapper.current.containsFocus();
        }
    }

    renderLi = (text: string) => {
        let dropDownList: any;

        if (this.props.correctKeyword) {
            dropDownList = text.split(this.props.correctKeyword).map((str, i, arr) => {
                return (
                    <React.Fragment key={i}>
                        <span>{str}</span>
                        {i < arr.length - 1 ?
                            <span style={{ color: 'rgb(22, 129, 251)' }}>
                                {this.props.correctKeyword}
                            </span> : undefined
                        }
                    </React.Fragment>
                )
            })
        } else {
            dropDownList = (
                <span>{text}</span>
            )
        }
        return dropDownList;
    }

    render() {
        return (
            <OBTFloatingPanel
                ref={this.myRefs.wrapper}
                value={this.props.isOpen}
                anchorEl={this.props.anchorEl}
                position={OBTFloatingPanel.Position.bottom}
                align={OBTFloatingPanel.Align.near}
            >
                <div
                    style={{
                        height: this.props.searchCallback && this.props.searchCallback.length < 6 ?
                            `${this.props.searchCallback.length * 22}px` : "110px",
                        width: this.props.width ? this.props.width : '150px',
                    }}
                    className={styles.wrapper}
                    ref={this.myRefs.scrollBar}
                >
                    <ul>
                        {this.props.searchCallback && this.props.searchCallback.length > 0 ?
                            this.props.searchCallback.map((item, index) => {
                                return (
                                    <li
                                        key={index}
                                        style={{
                                            textAlign: this.props.dialogAlign ? this.props.dialogAlign : "left",
                                            backgroundColor: this.props.selectedIndex === index ? '#eff7ff' : undefined,
                                        }}
                                        className={styles.dialogList}
                                        onMouseOver={(e) => this.props.onMouseMove(index)}
                                        onMouseUp={(e) => this.props.onClickItem(item)}
                                        onMouseDown={(e) => e.preventDefault()}
                                    >
                                        {this.renderLi(item[this.props.keyProperty])}
                                    </li>
                                )
                            })

                            : <></>
                        }
                    </ul>
                </div>
            </OBTFloatingPanel>
        )
    }
}
