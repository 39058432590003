/**
 * OBTAutoValueBinder
 * @version 0.1
 * @author 안광진
 * @see common.js
 */
import * as React from 'react';
import { Events, CommonType, Util, createPropDefinitions, CommonDefinitions } from '../Common';
import OBTCodePicker from '../OBTCodePicker';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import OBTMaskedTextField from '../OBTMaskedTextField';
import { IColumn } from '../OBTDataGrid/IColumn';
import { OBTRadioButtonGroup } from '../OBTRadioButton';

interface IGrid {
    getValues: any,
    setValue: any,
    onAfterChangeRowSelection: any,
    onBeforeChangeRowSelection: any,
    onAfterChange: any,
    getSelection: any,
    getRowCount: any,
}

interface IOBTAutoValueBinder extends Events.onValidate<any> {
    owner: any,
    onChange?: (e: Events.ChangeEventArgs<any>) => void
    children?: any
}

interface State extends hasError {
    children?: any,
    mappedChildren?: any,
    fields?: string[],
    values?: any,
    rowIndex?: number,
    handleChange: any,
    handleChangeWithSub: any,
    handleValidate: any,
    handleValidateWithSub: any,
    handleBlur: any,
    suspandRender: boolean,
    handleGetPrivacy: any,
    handleIsPrivacyMasked: any
}

export default class OBTAutoValueBinder extends React.Component<IOBTAutoValueBinder, State> {
    static PropDefinitions = createPropDefinitions(
        { name: 'owner', type: CommonType.any, description: 'state 의 소유자의 reference, 혹은 OBTCardListInterface, OBTDataGridInterface 의 instance 를 지정합니다. (=this)' },
        CommonDefinitions.onChange({ optional: true, description: 'OBTAutoValueBinder가 감싸고 있는 컴포넌트에서 입력된 값이 변경될 때 발생하는 Callback 함수입니다.' }),
        CommonDefinitions.onValidate()
    );

    public static defaultProps = {
    }

    public state: State = {
        handleChange: this.handleChange.bind(this),
        handleChangeWithSub: this.handleChangeWithSub.bind(this),
        handleValidate: this.handleValidate.bind(this),
        handleValidateWithSub: this.handleValidateWithSub.bind(this),
        handleBlur: this.handleBlur.bind(this),
        suspandRender: false,
        handleGetPrivacy: this.handleGetPrivacy.bind(this),
        handleIsPrivacyMasked: this.handleIsPrivacyMasked.bind(this)
    }

    constructor(props: IOBTAutoValueBinder) {
        super(props);
        if (OBTAutoValueBinder.isGrid(props.owner)) {
            this.props.owner['onAfterChangeRowSelection'].addToFirst(this.handleAfterChangeRowSelection.bind(this));
            this.props.owner['onBeforeChangeRowSelection'].addToFirst(this.handleBeforeChangeRowSelection.bind(this));
            this.props.owner['onAfterChange'].addToFirst(this.handleAfterChange.bind(this));
            if (OBTAutoValueBinder.isPrivacyOwner(props.owner)) {
                this.props.owner['onPrivacyRetrieved'].addToFirst(this.handlePrivacyRetrieved.bind(this));
            }
        }
    }

    static getDerivedStateFromProps(nextProps: IOBTAutoValueBinder, prevState: State): any {
        try {
            if (prevState.suspandRender) return null;
            if (nextProps.children !== prevState.children) {
                const owner = nextProps.owner;
                const isGrid = OBTAutoValueBinder.isGrid(owner);
                const isDataGrid = OBTAutoValueBinder.isDataGrid(owner);
                const fields: string[] = [];
                const assignChildren = (children) => {
                    let mappedChildren = React.Children.map(children, (child) => {
                        if (React.isValidElement(child) && child.type !== OBTAutoValueBinder) {
                            let newProps: any = {};
                            const props = child.props as any;
                            if (props.children && React.Children.count(props.children) > 0) {
                                newProps.children = assignChildren(props.children);
                            }
                            if (Util.isOrbitComponent(child.type)) {
                                const id = props['id'];
                                if (id && id.length > 0 && !props.hasOwnProperty('value') && !props.hasOwnProperty('onChange')) {
                                    let assign = false;
                                    if (isGrid) {
                                        assign = true;
                                    } else {
                                        const ownerState = owner['state'] as any;
                                        if (ownerState.hasOwnProperty(id)) {
                                            assign = true;
                                        }
                                    }

                                    if (assign) {
                                        const value = OBTAutoValueBinder.getValue(owner, prevState, id, child);

                                        if (isGrid && child.type === OBTCodePicker) {
                                            const columnInfo = OBTAutoValueBinder.getCodePickerColumnInfo(owner, child, id);
                                            if (columnInfo) {
                                                if (!fields.includes(columnInfo.codeColumnName)) fields.push(columnInfo.codeColumnName);
                                                if (!fields.includes(columnInfo.textColumnName)) fields.push(columnInfo.textColumnName);
                                            } else if (!fields.includes(id)) {
                                                fields.push(id);
                                            }
                                        } else if (!fields.includes(id)) {
                                            fields.push(id);
                                        }
                                        newProps['value'] = value;
                                        const useSubLang = props['useSubLang'];
                                        let subId = props['subId'];
                                        if (isDataGrid) {
                                            const column = owner['columns'].filter(column => column.name === id).map(column => column)[0];
                                            subId = column ? column.subName : null;
                                        }

                                        if (child.type === OBTMaskedTextField && OBTAutoValueBinder.isUsePrivacy(owner, id)) {
                                            newProps['usePrivacy'] = true;
                                            newProps['privacyBehavior'] = props['privacyBehavior'] || OBTAutoValueBinder.getPrivacyBehavior(owner, id);
                                            newProps['onGetPrivacy'] = Util.overrideHandler(props['onGetPrivacy'], (e: Events.GetPrivacyEventArgs) => {
                                                e.cancel = true;
                                                return prevState.handleGetPrivacy(id, e);
                                            });
                                        }

                                        if (useSubLang !== false && subId) {
                                            if (!fields.includes(subId)) {
                                                fields.push(subId);
                                            }
                                            const subValue = OBTAutoValueBinder.getValue(owner, prevState, subId, child);
                                            newProps['subValue'] = subValue;
                                            newProps['onChange'] = Util.overrideHandler(props['onChange'], (e: Events.ChangeWithSubEventArgs<any>) => {
                                                prevState.handleChangeWithSub(id, subId, e, child)
                                            });

                                            if (!props.hasOwnProperty('onValidate')) {
                                                newProps['onValidate'] = Util.overrideHandler(props['onValidate'], (e: Events.ValidateWithSubEventArgs<any>) => prevState.handleValidateWithSub(id, e));
                                            }

                                            if (isGrid) {
                                                newProps['onBlur'] = Util.overrideHandler(props['onBlur'], (e: Events.EventArgs) => prevState.handleBlur(id, e));
                                            }
                                        }
                                        else {
                                            newProps['onChange'] = Util.overrideHandler(props['onChange'], (e: Events.ChangeEventArgs<any>) => {
                                                prevState.handleChange(id, e, child)
                                            });
                                            if (!props.hasOwnProperty('onValidate')) {
                                                newProps['onValidate'] = Util.overrideHandler(props['onValidate'], (e: Events.ValidateEventArgs<any>) => prevState.handleValidate(id, e));
                                            }
                                            if (isGrid) {
                                                newProps['onBlur'] = Util.overrideHandler(props['onBlur'], (e: Events.EventArgs) => prevState.handleBlur(id, e));
                                            }
                                        }
                                    }
                                }
                            }
                            return React.cloneElement(child, newProps);
                        }
                        return child;
                    });
                    if (mappedChildren && mappedChildren.length === 1) {
                        return mappedChildren[0];
                    }
                    else return mappedChildren;
                };

                return {
                    children: nextProps.children,
                    mappedChildren: owner ? assignChildren(nextProps.children) : nextProps.children,
                    fields: fields
                }
            }
            return null;
        } catch (e) {
            return Util.getErrorState(e);
        }
    }

    private static isGrid(owner: any): boolean {
        if (owner) {
            if (typeof owner['getValues'] === 'function' &&
                typeof owner['setValue'] === 'function' &&
                typeof owner['getSelection'] === 'function' &&
                owner['onAfterChangeRowSelection'] !== undefined &&
                owner['onBeforeChangeRowSelection'] !== undefined &&
                owner['onAfterChange'] !== undefined) {
                return true;
            }
        }
        return false;
    }

    private static isPrivacyOwner(owner: any): boolean {
        if (owner) {
            if (typeof owner['isUsePrivacy'] === 'function' &&
                typeof owner['getPrivacyBehavior'] === 'function' &&
                typeof owner['getPrivacyComponentHandler'] === 'function' &&
                owner['onPrivacyRetrieved'] !== undefined) {
                return true;
            }
        }
        return false;
    }

    private static isDataGrid(owner: any): boolean {
        if (owner) {
            if (typeof owner['getValues'] === 'function' &&
                typeof owner['setValue'] === 'function' &&
                typeof owner['getSelection'] === 'function' &&
                typeof owner['columns'] === 'object' &&
                owner['columns'] !== undefined &&
                owner['onAfterChangeRowSelection'] !== undefined &&
                owner['onBeforeChangeRowSelection'] !== undefined &&
                owner['onAfterChange'] !== undefined) {
                return true;
            }
        }
        return false;
    }

    private static isUsePrivacy(owner: any, id: string): boolean {
        if (OBTAutoValueBinder.isPrivacyOwner(owner)) {
            return owner['isUsePrivacy'] ? owner['isUsePrivacy'](id) : false;
        }
        return false;
    }

    private static getPrivacyBehavior(owner: any, id: string): boolean {
        if (OBTAutoValueBinder.isPrivacyOwner(owner)) {
            return owner['getPrivacyBehavior'] ? owner['getPrivacyBehavior'](id) : false;
        }
        return false;
    }

    private static getColumn(owner: any, id: string): IColumn | null {
        if (OBTAutoValueBinder.isGrid(owner)) {
            return owner['getColumnByName'] ? owner['getColumnByName'](id) : null;
        }
        return null;
    }

    private static getCodePickerColumnInfo(owner: any, child: any, id: string): { codeColumnName: string, textColumnName: string } | null {
        if (OBTAutoValueBinder.isGrid(owner) && child.type === OBTCodePicker) {
            const column = this.getColumn(owner, id);
            const codeColumnName = child.props.bindingCodeColumnName ? child.props.bindingCodeColumnName :
                column && column.codeColumnName ? column.codeColumnName :
                    null;
            const textColumnName = child.props.bindingTextColumnName ? child.props.bindingTextColumnName :
                column && column.textColumnName ? column.textColumnName :
                    null;
            return codeColumnName && textColumnName ? {
                codeColumnName, textColumnName
            } : null;
        }
        return null;
    }

    private static getValue(owner: any, state: State, id: string, child: any): any {
        if (OBTAutoValueBinder.isGrid(owner)) {
            const columnInfo = OBTAutoValueBinder.getCodePickerColumnInfo(owner, child, id);
            const codePicker = child.props.codePicker;
            if (columnInfo && codePicker) {
                const code = (state.values || {})[columnInfo.codeColumnName];
                const text = (state.values || {})[columnInfo.textColumnName]
                return code && code.length > 0 ? [{
                    [codePicker.codeProperty]: code,
                    [codePicker.textProperty]: text
                }] : [];
            }
            let value = (state.values || {})[id]
            if (child.type === OBTRadioButtonGroup && (value === undefined || value === null)) {
                value = '';
            }
            return value;
        } else {
            return owner.state[id];
        }
    }

    private setValue(id: string, value: any, child: any, e: Events.EventArgs): void {
        if (OBTAutoValueBinder.isGrid(this.props.owner)) {
            const columnInfo = OBTAutoValueBinder.getCodePickerColumnInfo(this.props.owner, child, id);
            const codePicker = child.props.codePicker;
            if (columnInfo && codePicker) {
                this.setState({
                    ...this.state,
                    children: null,
                    values: {
                        ...this.state.values,
                        [columnInfo.codeColumnName]: value.length > 0 ? value[0][codePicker.codeProperty] : '',
                        [columnInfo.textColumnName]: value.length > 0 ? value[0][codePicker.textProperty] : ''
                    }
                }, () => {
                    this.setGridValues(e);
                });
                return;
            }
            this.setState({
                ...this.state,
                children: null,
                values: {
                    ...this.state.values,
                    [id]: value
                }
            }, () => {
                if (child.type && child.type.valueType !== 'text') {
                    this.setGridValues(e);
                }
            })
        } else {
            const setState = this.props.owner['setState'] as any;
            if (setState) {
                setState.call(this.props.owner, {
                    [id]: value
                });
            }
        }
    }

    private setSubValue(id: string, value: any, subId: string, subValue: any, child: any, e: Events.EventArgs): void {
        if (OBTAutoValueBinder.isGrid(this.props.owner)) {
            const columnInfo = OBTAutoValueBinder.getCodePickerColumnInfo(this.props.owner, child, id);
            const codePicker = child.props.codePicker;
            if (columnInfo && codePicker) {
                this.setState({
                    ...this.state,
                    children: null,
                    values: {
                        ...this.state.values,
                        [columnInfo.codeColumnName]: value.length > 0 ? value[0][codePicker.codeProperty] : '',
                        [columnInfo.textColumnName]: value.length > 0 ? value[0][codePicker.textProperty] : ''
                    }
                }, () => {
                    this.setGridValues(e);
                });
                return;
            }
            this.setState({
                ...this.state,
                children: null,
                values: {
                    ...this.state.values,
                    [id]: value,
                    [subId]: subValue,

                }
            }, () => {
                if (child.type && child.type.valueType !== 'text') {
                    this.setGridValues(e);
                }
            })
        } else {
            const setState = this.props.owner['setState'] as any;
            if (setState) {
                setState.call(this.props.owner, {
                    [id]: value,
                    [subId]: subValue,
                });
            }
        }
    }

    private static getRowIndex(owner: any): number {
        try {
            const selection = owner['getSelection'].call(owner);
            const rowIndex = typeof selection === 'object' && selection.hasOwnProperty('rowIndex') ? selection['rowIndex'] : selection;
            return Number.isNaN(rowIndex) || rowIndex === undefined ? -1 : rowIndex;
        } catch (error) {
            return -1;
        }
    }

    private static getGridValues(owner: any, rowIndex: number): any {
        if (rowIndex >= 0) {
            return owner['getValues'].call(owner, rowIndex, { usePrivacyOriginField: true });
        }
        return undefined;
    }

    private setGridValues(e: Events.EventArgs) {
        if (this.state.rowIndex !== undefined &&
            this.state.values &&
            this.state.fields) {
            if (this.state.rowIndex >= 0 && this.state.rowIndex < (this.props.owner as IGrid).getRowCount()) {
                this.setState({
                    suspandRender: true
                }, () => {
                    const oldValues = OBTAutoValueBinder.getGridValues(this.props.owner, this.state.rowIndex!);
                    if (oldValues === undefined) {
                        return;
                    }

                    this.state.fields!.forEach((field) => {
                        if (this.state.values[field] !== oldValues[field]) {
                            (this.props.owner as IGrid).setValue(this.state.rowIndex, field, this.state.values[field], false, { usePrivacyOriginField: true });
                            Util.invokeEvent<Events.ChangeEventArgs<any>>(this.props.onChange, new Events.ChangeEventArgs<string>(e.target, this.state.values[field]));
                        }
                    });
                    this.setState({
                        suspandRender: false
                    }, () => {
                        this.refresh();
                    });
                })
            }
        }
    }

    public refresh() {
        if (OBTAutoValueBinder.isGrid(this.props.owner)) {
            const rowIndex = OBTAutoValueBinder.getRowIndex(this.props.owner);
            const values = OBTAutoValueBinder.getGridValues(this.props.owner, rowIndex);

            this.setState({
                children: null,
                rowIndex: rowIndex,
                values: values
            });

        } else {
            this.setState({
                children: null
            });
        }
    }

    private handleChange(id: string, e: Events.ChangeEventArgs<any>, child: any): void {
        this.setValue(id, e.value, child, e);
        Util.invokeEvent<Events.ChangeEventArgs<any>>(this.props.onChange, new Events.ChangeEventArgs<string>(e.target, e.value));
    }

    private handleChangeWithSub(id: string, subId: string, e: Events.ChangeWithSubEventArgs<any>, child: any): void {
        this.setSubValue(id, e.value, subId, e.subValue, child, e);
        Util.invokeEvent<Events.ChangeWithSubEventArgs<any>>(this.props.onChange, new Events.ChangeWithSubEventArgs<string>(e.target, e.value, e.subValue));
    }

    private handleValidate(id: string, e: Events.ValidateEventArgs<any>): void {
        Util.invokeEvent<Events.ValidateEventArgs<any>>(this.props.onValidate, new Events.ValidateEventArgs<string>(e.target, e.oldValue, e.newValue));
    }

    private handleValidateWithSub(id: string, e: Events.ValidateWithSubEventArgs<any>): void {
        Util.invokeEvent<Events.ValidateWithSubEventArgs<any>>(this.props.onValidate, new Events.ValidateWithSubEventArgs<string>(e.target, e.oldValue, e.newValue, e.oldSubValue, e.newSubValue));
    }

    private handleBlur(id: string, e: Events.EventArgs): void {
        if (OBTAutoValueBinder.isGrid(this.props.owner)) {
            this.setGridValues(e);
        }
    }

    private handleBeforeChangeRowSelection(e: Events.EventArgs): void {
        this.setGridValues(e);
    }

    private handleAfterChangeRowSelection(e: Events.EventArgs): void {
        this.refresh();
    }

    private handleAfterChange(e: any): void {
        if (this.state.suspandRender === true) return;

        if (e.rowIndex === OBTAutoValueBinder.getRowIndex(this.props.owner)) {
            const values = OBTAutoValueBinder.getGridValues(this.props.owner, e.rowIndex);

            if (this.state.values && this.state.fields) {
                if (this.state.fields.findIndex((name) => values[name] !== this.state.values[name]) >= 0) {
                    this.refresh();
                }
            }
        }
    }

    private async handleGetPrivacy(id: string, e: Events.GetPrivacyEventArgs): Promise<string> {
        if (OBTAutoValueBinder.isGrid(this.props.owner) && OBTAutoValueBinder.isPrivacyOwner(this.props.owner)) {
            const getPrivacyComponentHandler = this.props.owner['getPrivacyComponentHandler'] as any;
            return getPrivacyComponentHandler(id);
        }
        return '';
    }

    private handleIsPrivacyMasked(id: string): boolean {
        if (OBTAutoValueBinder.isGrid(this.props.owner) && OBTAutoValueBinder.isPrivacyOwner(this.props.owner)) {
            const isPrivacyMaskedComponentHandler = this.props.owner['isPrivacyMaskedComponentHandler'] as any;
            return isPrivacyMaskedComponentHandler.call(null, this.state.rowIndex, id);
        }
        return false;
    }

    private handlePrivacyRetrieved(e: { target: any, columnNames: string[] }) {
        this.refresh();
    }

    render() {
        return <ErrorBoundary owner={this} render={this.renderComponent} />
    }

    renderComponent = () => {
        return (
            <>
                {this.state.mappedChildren}
            </>
        )
    }
}