/**
 * @version 0.1
 * @author 하성준
 * @see common.js
 */
import * as React from 'react';
import { Events, Util, CommonProps, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import LUXSplitButton from 'luna-rocket/LUXSplitButton'
import './OBTSplitButton.scss';
import OBTTooltip from '../OBTTooltip';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const styles = require('./OBTSplitButton.module.scss');

export enum ButtonType {
    'big' = 'big',
    'default' = 'default',
    'small' = 'small'
}

export enum ButtonTheme {
    'default' = 'default',

    /**
     * 파란색(Emphasized) 테마 입니다.
     */
    'blue' = 'blue',

    /**
     * 하늘색(Primary) 테마 입니다.
     */
    'skyBlue' = 'skyBlue',

    /**
     *  Drawer
     */
    'drawer' = 'drawer',

    /**
     * Drawer Important
     */
    'drawerImportant' = 'drawerImportant'
}

enum ButtonPosition {
    'left' = 'left',
    'right' = 'right'
}

enum ButtonMotionType {
    /**
     * 메인 버튼을 클릭 하위 리스트가 열립니다.
     */
    'dropDown' = 'dropDown',

    /**
     * 메인 버튼을 클릭 하여도 리스트가 열리지 않습니다.
     */
    'split' = 'split'
}

class MouseEventArgs extends Events.MouseEventArgs {
    constructor(target: any, event: React.MouseEvent, public readonly key: number | string) {
        super(target, event);
    }
    toString(): string { return JSON.stringify({ target: this.target ? 'object' : this.target, event: this.event!.toString(), key: this.key }); }
}

interface IValue {
    key: number | string,
    labelText: any,
    disabled?: boolean,
}

interface State extends hasError {
    open: boolean,
    width: string,
}

interface IOBTSplitButton extends CommonProps.id, CommonProps.frozen, CommonProps.className, CommonProps.value<IValue[]>, CommonProps.disabled,
    Events.onFocus, Events.onBlur, Events.onMouseDown, Events.onMouseMove, Events.onMouseUp, Events.onMouseLeave, Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress, Events.onKeyUp {
    /**
     * @default left
     * 더보기 위치를 지정합니다.
     */
    position?: ButtonPosition,

    /**
     * @default default
     * 컴포넌트의 크기를 지정합니다.
     */
    type?: ButtonType,

    /**
     * @default default
     * 값이 blue 이면, 파란색 테마, skyBlue면 하늘색 테마를 적용시킵니다.
     */
    theme?: ButtonTheme,

    /**
     * 툴팁에 대한 설정입니다.
     */
    tooltip?: any,

    /**
     * @default dropDown
     * 메인버튼 클릭시에 발생하는 모션의 타입을 지정합니다.
     */
    motionType?: ButtonMotionType,

    /**
     * 컴포넌트에서 onClick 동작이 있을 때 발생하는 Callback 함수입니다.
     */
    onClick?: (e: MouseEventArgs) => void,

    /**
     *  dropDown 메뉴의 width 입니다.
     */
    dropDownWidth?: string
}

export default class OBTSplitButton extends React.Component<IOBTSplitButton, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.id(),
        CommonDefinitions.frozen(),
        CommonDefinitions.className(),
        {
            name: "value", type: [{
                key: { type: ["string", "number"], description: "키 값" },
                labelText: { type: ["string", "node"], description: "버튼 라벨 텍스트" }
            }], description: "split버튼의 값을 지정합니다. 객체의 배열 형식이어야 하며, 배열의 첫 번째 요소가 메인 버튼이 됩니다. labelText에 버튼명을 지정할 수 있습니다."
        },
        { name: "position", type: toEnumType(ButtonPosition), description: "더보기 위치를 지정합니다.", default: "left", optional: true },
        {
            name: "type",
            type: toEnumType(ButtonType),
            description: "Type속성을 사용하여 버튼의 크기를 정할 수 있습니다.\nbig : 큰버튼 / default : 기본버튼 / small : 작은버튼",
            optional: true,
            default: "default"
        },
        {
            name: "theme",
            type: toEnumType(ButtonTheme),
            description: "Theme속성을 사용하여 버튼의 색 테마를 지정할 수 있습니다. \ndefault : 기본 / skyblue : 하늘색 / blue : 파란색 / drawer : 예제 참고 / drawerImportant : 예제 참고",
            optional: true,
            default: "default"
        },
        {
            name: "motionType",
            type: toEnumType(ButtonMotionType),
            description: "메인버튼 클릭시에 발생하는 모션의 타입을 지정합니다.\n(dropDown : 메인 버튼 클릭시 요소가 열림, split : 메인 버튼 클릭시 요소 열림 x)",
            optional: true,
            default: "dropDown"
        },
        { name: "dropDownWidth", type: CommonType.string, description: "dropDown 메뉴의 width 입니다.", optional: true },
        CommonDefinitions.tooltip(),
        CommonDefinitions.disabled(),
        CommonDefinitions.onFocus(),
        CommonDefinitions.onBlur(),
        CommonDefinitions.Event(),
    )

    public static defaultProps = {
        disabled: false,
        frozen: false,
        theme: ButtonTheme.default,
        type: ButtonType.default,
        position: ButtonPosition.left,
        motionType: ButtonMotionType.dropDown
    }

    public myRefs = {
        id: React.createRef<HTMLDivElement>()
    }

    public state: State = {
        open: false,
        width: '',
        hasError: false
    }

    public static Type = ButtonType
    public static Theme = ButtonTheme
    public static Position = ButtonPosition
    public static MotionType = ButtonMotionType

    componentDidMount(): void {
        try {
            if (this.state.open === true && document.querySelector('body > div > .LS_splitbtn') && !this.props.dropDownWidth) {
                let buttonWidth = this.myRefs.id.current!.clientWidth;
                let listWidth = document.querySelector('body > div > .LS_splitbtn')!.clientWidth;
                if (Math.round(buttonWidth) > Math.round(listWidth)) {
                    setTimeout(() => {
                        this.setState({
                            width: String(buttonWidth) + 'px',
                        })
                    }, 0);
                }
            }
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    componentDidUpdate(prevProps: IOBTSplitButton, prevState: State, snapshot: any): void {
        try {
            if (this.state.open === true && document.querySelector('body > div > .LS_splitbtn') && !this.props.dropDownWidth) {
                let buttonWidth = this.myRefs.id.current!.clientWidth;
                let listWidth = document.querySelector('body > div > .LS_splitbtn')!.clientWidth;
                if (Math.round(buttonWidth) > Math.round(listWidth)) {
                    setTimeout(() => {
                        this.setState({
                            width: String(buttonWidth) + 'px',
                        })
                    }, 0);
                }
            }
            this.removeGarbage();
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    componentWillUnmount() {
        this.removeGarbage();
    }

    private removeGarbage() {
        setTimeout(() => {
            document.querySelectorAll('body > div[style*="position: absolute;"][style*="z-index: 2000;"]')
                .forEach(el => {
                    if (el.children.length <= 0) {
                        if (el.parentNode)
                            el.parentNode.removeChild(el);
                    }
                });
        }, 0);
    }

    public open = (): void => {
        setTimeout(() => {
            this.setState({
                open: !this.state.open
            })
        }, 0);

    }

    public onBlur = (e: Events.EventArgs): void => {
        setTimeout(() => {
            this.setState({
                open: false
            }, () => { if (this.props.onBlur) this.props.onBlur(e) })
        }, 0);
        this.removeGarbage()
    }

    private handleClick = (event: React.MouseEvent, key: number | string): void => {
        if (this.props.motionType === ButtonMotionType.dropDown && key === this.props.value[0].key)
            this.open()
        Util.invokeEvent<MouseEventArgs>(this.props.onClick, new MouseEventArgs(this, event, key));

    }

    private handleOnKeyDown = (event: React.KeyboardEvent): void => {
        Util.invokeEvent<Events.KeyEventArgs>(this.props.onKeyDown, new Events.KeyEventArgs(this, event));
    }

    renderComponent = () => {
        let values = (this.props.value || []).map((value, index) => {
            if (index === 0) {
                return ({ key: value.key, value: <div><span>{value.labelText}</span></div>, disabled: value.disabled })
            }
            else {
                return ({ key: value.key, value: value.labelText, disabled: value.disabled })
            }
        });
        if (values.length <= 0) {
            values = [{ key: '', value: '', disabled: true }];
        }

        const component =
            <div className={Util.getClassNames(styles.root, 'OBTSeleneSplitButton',
                this.props.type === ButtonType.small ? styles.small : null,
                this.props.theme ? styles[this.props.theme] : styles.default, this.props.disabled ? styles.disabled : null)} ref={this.myRefs.id}
            >
                <LUXSplitButton
                    disabled={this.props.disabled}
                    value={values}
                    onTouchTap={this.handleClick}
                    position={this.props.position}
                    size={this.props.type === ButtonType.big ? 'l' :
                        this.props.type === ButtonType.default ? 'm' :
                            this.props.type === ButtonType.small ? 's' : null}
                    blue={this.props.theme === ButtonTheme.blue ||
                        this.props.theme === ButtonTheme.drawer ||
                        this.props.theme === ButtonTheme.drawerImportant ? true : false}
                    moreButtonOpen={this.state.open}
                    onTouchTapMore={this.open}
                    onBlur={this.onBlur}
                    ulStyle={{ width: this.props.dropDownWidth ? this.props.dropDownWidth : this.state.width, left: '-1px', maxHeight: 'max-content' }}
                    onKeyDown={this.handleOnKeyDown}

                />
            </div>

        const wrappedComponent = this.props.className && this.props.className.length > 0 ?
            <div
                className={Util.getClassNames(styles.root, this.props.className)}
                style={Util.getWrapperStyle(this.props)}
            >
                {component}
            </div> : component

        return (
            <OBTTooltip {...this.props.tooltip}
                style={
                    Object.assign(this.props.frozen === true ?
                        Util.getStyle('frozen') : {},
                        { boxSizing: 'content-box', display: "inline-block", position: 'relative', verticalAlign: 'top' })
                }
                overrideSize={false}
                onFocus={this.props.onFocus}
                onMouseDown={this.props.onMouseDown}
                onMouseMove={this.props.onMouseMove}
                onMouseUp={this.props.onMouseUp}
                onMouseLeave={this.props.onMouseLeave}
                onMouseEnter={this.props.onMouseEnter}
                onKeyPress={this.props.onKeyPress}
                onKeyUp={this.props.onKeyUp}
                rootProps={{
                    id: this.props.id,
                    'data-orbit-component': 'OBTSplitButton'
                }}
            >
                {wrappedComponent}
            </OBTTooltip>
        )
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        )
    }
};
