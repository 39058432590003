/**
 * Component OBTAccordionGroup
 * Luna - Orbit 개발시 템플릿 으로 사용.
 * @version 0.1
 * @author 전주빈
 * @see common.js
 */
import * as React from 'react';
import { CompositeProps, Util, Events, createPropDefinitions, CommonDefinitions, CommonType } from '../Common';
import OBTAccordion from './OBTAccordion';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import memoizeOne from 'memoize-one';
import { OBTAccordion2 } from "../OBTAccordion2";
const accordion2Styles = require("../OBTAccordion2/OBTAccordionGroup2.module.scss");

const styles = require('./OBTAccordionGroup.module.scss');

interface IOBTAccordionGroup extends CompositeProps.Default {
    /**
     * 컴포넌트의 처음 열리는 value값 설정합니다.
     */
    value?: number | number[],
    /**
     * 컴포넌트에서 입력된 값이 변경될 때 발생하는 Callback 함수입니다.
     */
    onChange?: (e: Events.ChangeEventArgs<number>) => void,
    /**
     * 복수펼침 기능을 사용한다.
     */
    useMultipleExpand?: boolean
}

interface State extends hasError {
    oldValue?: number | number[],
    selectedIndexes: number[],
    isUsingOldVersion: boolean[]
}

/**
 * withApi() HOC 를 사용하면 Props 로 Api 를 사용할 수 있다.
 * api 가 Optional 로 선언되었기에 내부에서 ! 오퍼레이터를 사용해서 호출한다.
 * {@code this.props.api!.test();}
 */
export default class OBTAccordionGroup extends React.Component<IOBTAccordionGroup, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        CommonDefinitions.value({ type: CommonType.number, optional: true, description: '펼쳐질 아코디언의 index 를 지정합니다.' }),
        CommonDefinitions.onChange({ optional: true, type: CommonType.number, description: '펼쳐진 아코디언의 index 가 변경될 경우 호출되는 Callback 입니다.' }),
        {
            name: 'useMultipleExpand',
            type: CommonType.boolean,
            optional: true,
            default: false,
            description: '아코디언 그룹을 사용할 경우에도 다수 아코디언을 각기 다르게 펼칠 수 있습니다.\n( 단 uncontrolled 형태만 지원합니다. )'
        },
    )

    public static defaultProps: Partial<IOBTAccordionGroup> = {
        frozen: false,
        useMultipleExpand: false
    }

    public myRefs = {
        OBTAccordionGroup: React.createRef<HTMLDivElement>()
    }

    public state: State = {
        oldValue: this.props.value,
        isUsingOldVersion: React.Children.toArray(this.props.children).map((child, index) => {
            if ((React.isValidElement(child) &&
                child.type === OBTAccordion &&
                child.props.hasOwnProperty('useOldVersion') &&
                child.props['useOldVersion'] === false) ||
                (React.isValidElement(child) && child.type === OBTAccordion2)) {
                return false;
            }
            return true;
        }),
        selectedIndexes: this.props.value === undefined ? (React.Children.toArray(this.props.children).map((child, index) => {
            if (React.isValidElement(child) && (child.type === OBTAccordion || child.type === OBTAccordion2)) {
                if (child.props.hasOwnProperty('value') && child.props['value'] === true) {
                    return index;
                }
            }
            return -1;
        }).filter(item => item >= 0) as number[]) || [] : typeof (this.props.value) === 'number' ? [this.props.value] :
            typeof (this.props.value) === 'object' && this.props.useMultipleExpand ? this.props.value :
                typeof (this.props.value) === 'object' && !this.props.useMultipleExpand ? [this.props.value[0]] : []
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedIndexes !== this.state.selectedIndexes) {
            if (!this.props.onChange) return;

            const newIndex = (selectedIndexes: number) => {
                return isNaN(selectedIndexes) ? -1 : selectedIndexes
            }

            if (typeof (this.props.value) === 'object') {
                if (!this.props.useMultipleExpand) {
                    Util.invokeEvent<Events.ChangeEventArgs<number>>(this.props.onChange, new Events.ChangeEventArgs<number>(this, newIndex(this.state.selectedIndexes[0])));
                }
                else {
                    Util.invokeEvent<Events.ChangeEventArgs<any>>(this.props.onChange, new Events.ChangeEventArgs<any>(this, this.state.selectedIndexes));
                }
            }
            else {
                Util.invokeEvent<Events.ChangeEventArgs<number>>(this.props.onChange, new Events.ChangeEventArgs<number>(this, newIndex(this.state.selectedIndexes[0])));
            }
        }
    }

    public getOpenedAccordionKeys = (): any[] => {
        const openedItems: any[] = [];
        const key: any[] = [];
        React.Children.map(this.props.children, (child, index) => {
            if (React.isValidElement(child) && (child.type === OBTAccordion2 || child.type === OBTAccordion)) {
                key.push(child.key);
            }
        });
        if (this.myRefs.OBTAccordionGroup.current) {
            const children = this.myRefs.OBTAccordionGroup.current.children;
            for (let i = 0; i < children.length; i++) {
                const componentName = children[i].attributes["data-orbit-component"].nodeValue;
                if (componentName === "OBTAccordion" && children[i] && children[i].lastChild) {
                    const isOpended = children[i].lastChild!.childNodes
                    if (isOpended.length === 0) {
                        const openedItem = children[i].id ? children[i].id : key[i] ? key[i] : i
                        openedItems.push(openedItem);
                    }
                }
                else if (componentName === "OBTAccordion2") {
                    this.state.selectedIndexes.forEach((selectedIndex) => {
                        if (selectedIndex === i && children[i]) {
                            const openedItem = children[i].id ? children[i].id : key[i] ? key[i] : i
                            openedItems.push(openedItem);
                        }
                    })
                }
            }
        }
        return openedItems;
    }

    static getDerivedStateFromProps(nextProps: IOBTAccordionGroup, prevState: State): any {
        try {
            if (nextProps.value !== prevState.oldValue) {
                return {
                    oldValue: nextProps.value,
                    selectedIndexes: typeof (nextProps.value) === 'number' ? [nextProps.value] :
                        typeof (nextProps.value) === 'object' && nextProps.useMultipleExpand ? nextProps.value :
                            typeof (nextProps.value) === 'object' && !nextProps.useMultipleExpand ? [nextProps.value[0]] : []
                }
            }
            return null;
        } catch (e) {
            return Util.getErrorState(e);
        }
    }

    private isRenderingAccordion2 = this.state.isUsingOldVersion.some(isUsing => isUsing === false);

    private getChildren = memoizeOne(
        (children: React.ReactNode,
            selectedIndexes: number[],
            onChange: ((e: Events.ChangeEventArgs<number>) => void) | undefined,
            useMultipleExpand: boolean) => {
            return React.Children.map(children, (child, index: number) => {
                if (React.isValidElement(child)) {
                    if (child.type === OBTAccordion || child.type === OBTAccordion2) {
                        return React.cloneElement(child, {
                            className: !this.isRenderingAccordion2 ? Util.getClassNames(styles.child, selectedIndexes.includes(index) ? styles.selected : undefined, (child.props || {}).className) :
                                (child.props || {}).className,
                            value: selectedIndexes.includes(index),
                            onChange: Util.overrideHandler(child.props['onChange'], (e: Events.ChangeEventArgs<boolean>) => {
                                if (useMultipleExpand) {
                                    this.setState({
                                        selectedIndexes: (() => {
                                            if (e.value) {
                                                return [index].concat(selectedIndexes.filter(idx => idx !== index))
                                            } else {
                                                return selectedIndexes.filter(idx => idx !== index)
                                            }
                                        })()
                                    });
                                    return;
                                }
                                const newIndex = e.value ? index : -1;
                                this.setState({ selectedIndexes: newIndex >= 0 ? [newIndex] : [] });
                            })
                        });
                    }
                }
                return child;
            });
        });

    renderComponent = () => {
        const children = this.getChildren(this.props.children, this.state.selectedIndexes, this.props.onChange, this.props.useMultipleExpand ? true : false);
        return (
            <div
                id={this.props.id}
                className={!this.isRenderingAccordion2 ? Util.getClassNames(styles.root, this.props.className) :
                    Util.getClassNames(accordion2Styles.wrapper, this.props.className)
                }
                style={Util.getWrapperStyle(this.props)}
                data-orbit-component='OBTAccordionGroup'
                ref={this.myRefs.OBTAccordionGroup}
            >
                {children}
            </div>
        )
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        );
    }
};