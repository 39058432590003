import { ERPNumberFormatType } from "../OBTDataGrid/IColumn";

export function isDevServer(hostName: string): boolean {
    if (hostName === 'localhost' ||
        hostName === '127.0.0.1' ||
        hostName === 'dev.amaranth10.co.kr')
        return true;
    else return false;
}

/**
 * @internal
 * 예외처리 콜백 템플릿
 * @param func 
 * @param funcWhenThrow 
 * @param funcWhenFinally 
 */
export function ignoreException(func: () => void, funcWhenThrow?: (exception: any) => void, funcWhenFinally?: () => void) {
    try {
        func();
    } catch (e) {
        if (funcWhenThrow) {
            funcWhenThrow(e)
        }
    } finally {
        if (funcWhenFinally) {
            funcWhenFinally()
        }
    }
}

/**
 * @internal
 * 예외처리 콜백 템플릿
 * @param func 
 * @param funcWhenThrow 
 * @param funcWhenFinally 
 */
export function ignoreExceptionTemplate(func: () => void, funcWhenThrow?: (exception: any) => void, funcWhenFinally?: () => void) {
    return () => {
        try {
            func();
        } catch (e) {
            if (funcWhenThrow) {
                funcWhenThrow(e)
            }
        } finally {
            if (funcWhenFinally) {
                funcWhenFinally()
            }
        }
    }
}

/**
 * 
 */
export function removeTrashElement() {
    try {
        const trash = Array.from(document.body.children).filter((element) => {
            return (
                element.tagName === 'DIV' &&
                (element.getAttribute('style') === 'position: relative; inset: 0px; z-index: 2000;' ||
                    element.getAttribute('style') === 'position: absolute; top: 0px; left: 0px; min-width: 168px; z-index: 2000;')
            )
        })

        trash.forEach((element) => {
            document.body.removeChild(element)
        })
    } catch (e) { }
}

/**
 * userInfo에서 공통 환경설정 숫자타입 자릿수, 끝전단수처리 정보가져오기
 * @param userInfo 
 */
export function extractNumberFormatTypeFromUserInfo(userInfo: any): IERPNumberFormatType | null {
    if (userInfo && userInfo.erpUserInfo) {
        const formatType = {
            sFormat02: userInfo.erpUserInfo.sFormat02,
            sFormat03: userInfo.erpUserInfo.sFormat03,
            sFormat04: userInfo.erpUserInfo.sFormat04,
            sFormat05: userInfo.erpUserInfo.sFormat05,
            sFormat06: userInfo.erpUserInfo.sFormat06,
            sFormat07: userInfo.erpUserInfo.sFormat07,
            sFormat08: userInfo.erpUserInfo.sFormat08,
            sFormat10: userInfo.erpUserInfo.sFormat10,
        } as IERPNumberFormatType;

        return formatType;
    }

    return null;
}

/**
 * 시스템환경설정 기반 리얼그리드 숫자포멧 문자열 가져오기
 * @param format 
 * @param formatType 
 */
export function makeNumberFormatString(format: ERPNumberFormatType, formatType: IERPNumberFormatType | null, lastCutType?: "0" | "1" | "2" | 0 | 1 | 2) {
    if (!formatType) {
        return null;
    }

    const decimalLength = Number(formatType[format]);
    if (!isNotNull(decimalLength)) {
        return null;
    }

    if (isNotNull(formatType.sFormat10) || isNotNull(lastCutType)) {
        const sFormat10 = isNotNull(lastCutType) ? Number(lastCutType) : formatType.sFormat10;
        let decimalFormatString = '';
        for (let index = 0; index < decimalLength! - 1; index++) {
            decimalFormatString += '0'
        }

        if (decimalLength) {
            decimalFormatString += sFormat10 === 0 ? '#' : '0'
        }

        // 수소점 존재
        if (decimalLength && sFormat10 === 1) {
            // 절사
            decimalFormatString += ';.;,;f';
        } else if (decimalLength && sFormat10 === 2) {
            // 절상
            decimalFormatString += ';.;,;c';
        }

        // 정수만 존재
        if (!decimalLength && sFormat10 === 0) {
            return '#,###'
        } else if (!decimalLength && sFormat10 === 1) {
            return '#,##0;;;f'
        } else if (!decimalLength && sFormat10 === 2) {
            return '#,##0;;;c'
        }

        return '#,##0.' + decimalFormatString;
    }

    return null;
}

export function makeNumberExcelFormatString(format: ERPNumberFormatType, formatType: IERPNumberFormatType | null, lastCutType?: "0" | "1" | "2" | 0 | 1 | 2) {
    if (!formatType) {
        return null;
    }

    const decimalLength = Number(formatType[format]) || 0;

    if (isNotNull(formatType.sFormat10) || isNotNull(lastCutType)) {
        const sFormat10 = isNotNull(lastCutType) ? Number(lastCutType) : formatType.sFormat10;
        let decimalFormatString = '';
        for (let index = 0; index < decimalLength; index++) {
            decimalFormatString += '#'
        }

        if (decimalLength === 0) {
            decimalFormatString = "#,###";
        } else if (sFormat10 === 0) {
            decimalFormatString = "#,###." + decimalFormatString;
        } else if (sFormat10 === 1) {
            decimalFormatString = "#,###." + decimalFormatString;
        } else if (sFormat10 === 2) {
            decimalFormatString = "#,###." + decimalFormatString;
        }

        return decimalFormatString;
    }

    return null;
}

export type IERPNumberFormatTypeKeys = keyof IERPNumberFormatType;

export interface IERPNumberFormatType {
    /**
     * 수량소수점자리수
     */
    sFormat02?: number,
    /**
     * 원화단가소수점자리수
     */
    sFormat03?: number,
    /**
     * 외화단가소수점자리수
     */
    sFormat04?: number,
    /**
     * 비율소숫점자리수
     */
    sFormat05?: number,
    /**
     * 금액소숫점자리수
     */
    sFormat06?: number,
    /**
     * 외화소숫점자리수
     */
    sFormat07?: number,
    /**
     * 환율소숫점자리수
     */
    sFormat08?: number,
    /**
     * 끝전 단수처리 유형 (0, 반올림, 1, 절사, 2, 절상)
     */
    sFormat10?: number,
}

export function isNotNull(value: any) {
    return (value !== null && value !== undefined)
}

// sFormat02 수량소수점자리수 2자리
// sFormat03 원화단가소수점자리수 2자리 
// sFormat04 외화단가소수점자리수 2자리
// sFormat05 비율소숫점자리수 3자리
// sFormat06 금액소숫점자리수 0자리
// sFormat07 외화소숫점자리수 2자리
// sFormat08 환율소숫점자리수 3자리

// 끝전 단수처리 유형 (반올림, 절사, 절상)
// 비율%표시여부 부

// sFormat02: 2
// sFormat03: 2
// sFormat04: 2
// sFormat05: 3
// sFormat06: 0
// sFormat07: 2
// sFormat08: 3

// interface INumberFormatType {
//     sFormat02: number,
//     sFormat03: number,
//     sFormat04: number,
//     sFormat05: number,
//     sFormat06: number,
//     sFormat07: number,
//     sFormat08: number,
// }

// function resolveNumberFormatTypeByColumnName(columnName: string): null | keyof INumberFormatType {
//     if (columnName.toUpperCase().endsWith('AM') || columnName.toUpperCase().endsWith('AMT')) {
//         return "sFormat06";
//     } else if (columnName.toUpperCase().endsWith('RT')) {
//         return "sFormat05";
//     } else if (columnName.toUpperCase().endsWith('CNT')) {
//         return "sFormat02";
//     }

//     return null;
// }

// /**
//  * 
//  * @param columnName 
//  * @param decimalLength 
//  * @param lastNumberType 
//  */
// function formatNumber(source: number, decimalLength: number, lastNumberType: 'roundOff' | 'cutOff' | 'cutUp') {
//     source.toFixed()

//     source.toFixed()
// }