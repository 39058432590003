/**
 * OBTButton.
 * @version 0.1
 * @author 하성준
 * @see common.js
 */
import * as React from 'react';
import { Events, CompositeProps, Util, CommonProps, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import OBTTooltip from '../OBTTooltip';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const styles = require('./OBTButton.module.scss');

export interface ImageUrl {
    normal: string,
    over?: string,
    press?: string,
    disabled?: string
}

export interface Icon {
    normal: any,
    over?: any,
    press?: any,
    disabled?: any
}

interface IOBTButton extends CompositeProps.Default, CommonProps.labelText, CommonProps.disabled, Events.onClick, Events.onMouseLeave,
    Events.onFocus, Events.onBlur, Events.onMouseDown, Events.onClick, Events.onMouseMove, Events.onMouseUp, Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress, Events.onKeyUp {
    /**
     * 값이 blue 이면, 파란색 테마, skyBlue면 하늘색 테마를 적용시킵니다.
     */
    theme?: ButtonTheme,

    /**
     *  컴포넌트에 아이콘을 넣을 때 사용하는 속성입니다. 
     */
    icon?: Icon | any,

    /**
     *  컴포넌트에 이미지 URL을 넣을 때 사용하는 속성입니다. 
     */
    imageUrl?: ImageUrl | string,

    /**
     *  사용할 버튼의 타입 입니다.
     */
    type?: ButtonType,

    /**
     *  버튼 tooltip 설정 입니다.
     */
    tooltip?: any,

    /**
     * imageUrl 지정한 버튼에서 마우스 오버했을때 백그라운드 처리를 할것인지 여부입니다.
     */
    useMouseOverBackground?: boolean,

    /**
     * imageUrl 을 사용할 때 들어갈 이미지에 클래스 네임을 부여할 수 있는 속성입니다.
     */
    imageTagClassName?: string
}

interface State extends hasError {
    behavior: string
}

export enum ButtonType {
    'big' = 'big',
    'default' = 'default',
    'small' = 'small',
    'icon' = 'icon'
}

export enum ButtonTheme {
    'default' = 'default',
    'blue' = 'blue',
    'skyBlue' = 'skyBlue',
    'drawer' = 'drawer',
    'drawerImportant' = 'drawerImportant'
}

export default class OBTButton extends React.Component<IOBTButton, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        { name: 'type', type: toEnumType(ButtonType), default: 'default', optional: true, description: 'Type속성을 사용하여 버튼의 크기를 정할 수 있습니다.\n(big : 큰버튼 / default : 기본버튼 / small : 작은버튼 / icon : 아이콘버튼(svg))' },
        CommonDefinitions.labelText({ description: '버튼 텍스트입니다.' }),
        { name: 'theme', type: toEnumType(ButtonTheme), default: 'default', optional: true, description: 'Theme속성을 사용하여 버튼의 색 테마를 지정할 수 있습니다.\n(default : 기본 / skyblue : 하늘색 / blue : 파란색 / drawer : 예제 참고 / drawerImportant : 예제 참고)' },
        {
            name: 'imageUrl', type: [CommonType.image, {
                normal: { type: CommonType.image, description: '기본상태 이미지 Url' },
                over: { type: CommonType.image, optional: true, description: 'over상태 이미지 Url' },
                press: { type: CommonType.image, optional: true, description: 'press상태 이미지 Url' },
                disabled: { type: CommonType.image, optional: true, description: '비활성상태 이미지 Url' }
            }], optional: true, description: '버튼에 imageUrl을 넣을 때 사용하는 속성입니다.\nnormal에 해당하는 이미지 Url 혹은 Json 객체를 통해 이미지를 지정할 수 있습니다.'
        },
        {
            name: 'icon', type: ['SVG 컴포넌트', {
                normal: { type: 'SVG 컴포넌트', description: '기본상태 SVG 컴포넌트' },
                over: { type: 'SVG 컴포넌트', optional: true, description: 'over상태 SVG 컴포넌트' },
                press: { type: 'SVG 컴포넌트', optional: true, description: 'press상태 SVG 컴포넌트' },
                disabled: { type: 'SVG 컴포넌트', optional: true, description: '비활성상태 SVG 컴포넌트' }
            }], optional: true, description: '버튼에 SVG 컴포넌트을 넣을 때 사용하는 속성입니다.\nnormal에 해당하는 SVG 컴포넌트 혹은 Json 객체를 통해 이미지를 지정할 수 있습니다.'
        },
        { name: 'useMouseOverBackground', type: CommonType.boolean, optional: true, default: false, description: 'imageUrl 지정한 버튼에서 마우스 오버했을때 백그라운드 처리를 할것인지 여부' },
        { name: 'imageTagClassName', type: CommonType.string, optional: true, description: 'imageUrl 을 사용할 때 들어갈 이미지에 클래스 네임을 부여할 수 있는 속성입니다.' },
        { name: 'value', type: CommonType.string, optional: true, description: '버튼의 값을 지정하는 속성입니다. OBTButtonGroup 컴포넌트의 value 속성과 매핑되며, 버튼을 그룹지어 단일항목 선택에 사용할 수 있습니다.' },
        CommonDefinitions.disabled(),
        CommonDefinitions.tooltip(),
        CommonDefinitions.onClick(),
        CommonDefinitions.onFocus(),
        CommonDefinitions.onBlur(),
        CommonDefinitions.onMouseDown(),
        CommonDefinitions.onMouseMove(),
        CommonDefinitions.onMouseUp(),
        CommonDefinitions.onMouseLeave(),
        CommonDefinitions.onMouseEnter(),
        CommonDefinitions.onKeyDown(),
        CommonDefinitions.onKeyPress(),
        CommonDefinitions.onKeyUp()
    );


    public static defaultProps = {
        disabled: false,
        frozen: false,
        labelText: '',
        type: ButtonType.default,
        theme: ButtonTheme.default,
        useOverBackground: false,
    }

    public state: State = {
        behavior: this.props.disabled ? 'disabled' : 'normal',
        hasError: false
    }

    private myRefs = {
        root: React.createRef<HTMLButtonElement>()
    }

    private isOver: boolean = false;

    public static Type = ButtonType
    public static Theme = ButtonTheme

    componentDidUpdate(prevProps) {
        if (this.props.disabled !== prevProps.disabled) {
            this.setState({
                behavior: this.props.disabled ? 'disabled' : 'normal',
            })
        }
    }

    private handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.isOver = true;
        if (this.props.disabled !== true)
            this.setState({
                behavior: 'over'
            });
    }

    private handleMouseLeave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.isOver = false;
        if (this.props.disabled !== true)
            this.setState({
                behavior: 'normal'
            });
    }

    private handleMouseDown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (this.props.disabled !== true)
            this.setState({
                behavior: 'press'
            });
        if (this.props.onMouseDown)
            Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseDown, new Events.MouseEventArgs(this, e));
    }

    private handleMouseUp = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (this.props.disabled !== true)
            this.setState({
                behavior: this.isOver ? 'over' : 'normal'
            });
        if (this.props.onMouseUp)
            Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseUp, new Events.MouseEventArgs(this, e));
    }

    private handleClick = (event: React.MouseEvent) => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onClick, new Events.MouseEventArgs(this, event));
    }

    public click() {
        if(this.myRefs.root.current) {
            this.myRefs.root.current.click();
        }
    }

    renderComponent = () => {
        const getItem = ((value: any, isObject: boolean) => {
            const obj = {
                normal: isObject ? value.normal : value,
                over: isObject ? value.over : undefined,
                press: isObject ? value.press : undefined,
                disabled: isObject ? value.disabled : undefined
            }
            return obj[this.state.behavior] || (this.state.behavior === 'press' ? obj.press : undefined) || obj.normal;
        });
        const icon =
            this.props.icon
            ? getItem(this.props.icon, this.props.icon.hasOwnProperty('normal'))
            : undefined;
        const imageUrl =
            this.props.imageUrl
            ? getItem(this.props.imageUrl, typeof this.props.imageUrl !== 'string')
            : undefined;
        const component = <button
            ref={this.myRefs.root}
            className={Util.getClassNames(styles.root,
                styles[`type${this.props.type}`],
                styles[`theme${this.props.theme}`],
                this.props.disabled ? styles.disabled : null,
                this.props.type === ButtonType.small ? styles.small : null,
                this.props.useMouseOverBackground === true ? styles.overBackground : null,
                this.props.className)}
            style={Util.getWrapperStyle(this.props)}
            disabled={this.props.disabled}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            onMouseDown={this.handleMouseDown}
            onMouseUp={this.handleMouseUp}
            onClick={this.handleClick}
            tabIndex={0}
        >
            <div className={styles.wrapper}>
                {icon ? <span className={styles.icon}>{icon}</span> : null}
                {imageUrl ?
                    <img
                        alt=''
                        src={imageUrl}
                        className={this.props.imageTagClassName && this.props.imageTagClassName}
                    /> : null}
                {this.props.labelText ? <span className={styles.labelText}>{this.props.labelText}</span> : null}
            </div>
        </button>;

        return <OBTTooltip focusValue={false} {...this.props.tooltip}
            overrideSize={false}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            onMouseMove={this.props.onMouseMove}
            onMouseLeave={this.props.onMouseLeave}
            onMouseEnter={this.props.onMouseEnter}
            onKeyDown={this.props.onKeyDown}
            onKeyPress={this.props.onKeyPress}
            onKeyUp={this.props.onKeyUp}
            rootProps={{
                id: this.props.id,
                'data-orbit-component': 'OBTButton'
            }}
        >
            {component}
        </OBTTooltip>
    }

    render() {
        return <ErrorBoundary owner={this} render={this.renderComponent} />
    }
};
