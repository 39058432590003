import { Events } from '../../Common';

export class ColumnWidthChangeEventArgs extends Events.EventArgs {
    constructor(
        public readonly target: any,
        public readonly columnName: string | null,
        public readonly size: number | null,
        public readonly isRearranged: boolean = false) {
        super(target);
    }
}