/* eslint-disable eqeqeq */
/**
 * Ajax with promise
 * @see reference from http://www.w3schools.com/ajax/ajax_xmlhttprequest_send.asp
 * 설치 사항
  1. jquery : authToken과 hashKey 를 쿠키에 저장할때 jquery를 사용하므로 설치가 필요합니다
    yarn add jquery
  2. jquery.cookie : 쿠키에 저장하기위해 사용
    yarn add jquery.cookie
  3. crypto-js : wehago-sign을 암호화시 사용합니다
    npm install crypto-js
  4. detector : IE 캐시 이슈를 해결하기위해 사용합니다
    npm install detector
 */


import $ from "jquery";
import "jquery.cookie";

let CryptoJS = require("crypto-js");
let detector = require("detector");

//개발용
let apiUrl = "http://14.41.2.189";
// let apiUrl = "http://localhost:9090";
//배포용
// let apiUrl = "";
export default {
    /*
     각 http메소드에 대한 메소드
     url : 요청을 보낼 서버 url
     options : signature 등 헤더에 저장되어야하는값을 전달할때 이용
     args : 요청 시 body에 추가할 값을 전달
    */
    get: function (url, options) {
        if (options === undefined) {
            options = { async: true };
        } else if (typeof options === "boolean") {
            options = { async: options };
        }
        return this.call(apiUrl + url, "GET", options);
    },
    post: function (url, args, options) {
        if (options === undefined) {
            options = { async: true };
        } else if (typeof options === "boolean") {
            options = { async: options };
        }

        return this.call(apiUrl + url, "POST", options, args);
    },
    put: function (url, args, options) {
        if (options === undefined) {
            options = { async: true };
        } else if (typeof options === "boolean") {
            options = { async: options };
        }
        return this.call(url, "PUT", options, args);
    },
    delete: function (url, args, options) {
        if (options === undefined) {
            options = { async: true };
        } else if (typeof options === "boolean") {
            options = { async: options };
        }
        return this.call(url, "DELETE", options, args);
    },
    patch: function (url, args, options) {
        if (options === undefined) {
            options = { async: true };
        } else if (typeof options === "boolean") {
            options = { async: options };
        }
        return this.call(url, "PATCH", options, args);
    },
    // 서버에 요청을 보냅니다 (Header에 로그인에 필요한값들을 채워줍니다.)
    call: function (url, method, options, args) {
        console.log('---!!!' + url)
        /*
 
         url : 요청을 보낼 서버 url
         method : http 메소드
         options : 받아온 signature 값 등
         args : 요청 시 body에 추가할 값을 전달
        */
        console.log(options);
        let target = this;
        let xmlReq;

        try {
            xmlReq = new Promise(function (resolve, reject) {
                let defaults = {
                    contextType: "application/x-www-form-urlencoded",
                    async: true,
                    filedownload: false
                };

                // authToken을 쿠키에서 가져옵니다
                let oAuthToken = $.cookie("oAuthToken"); // oauth 토큰 입니다. 사용하는 저장소의 값으로 셋팅해주세요.
                console.log(oAuthToken);
                // hashKey를 쿠키에서 가져옵니다.
                let signKey = $.cookie("signKey"); // hash key 입니다. 사용하는 저장소의 값으로 셋팅해주세요.
                console.log(signKey);
                if (options || options === undefined) {
                    options = $.extend(defaults, options);
                }

                let req = new XMLHttpRequest();
                if (method === null || method === undefined) method = "GET";
                // timestamp를 생성합니다.
                let ts = Math.floor(Date.now() / 1000);

                //IE 에서만 파라미터에 timestamp 포함 - IE 캐시 이슈
                if (
                    detector.browser.name === "ie" ||
                    detector.browser.name === "edge"
                ) {
                    if (
                        signKey !== undefined &&
                        options.signature == undefined
                    ) {
                        if (method.toUpperCase() === "GET") {
                            if (url.indexOf("?") > -1) url += "&";
                            else url += "?";
                            url += "timestamp=" + ts;
                        } else if (method.toUpperCase() === "DELETE") {
                            if (url.indexOf("?") > -1) url += "&";
                            else url += "?";
                            url += "timestamp=" + ts;
                            args.timestamp = ts;
                        } else {
                            args.timestamp = ts;
                        }
                    }
                }

                let param = "";
                // body에 넣어보낼 값이 있다면 key, value로 나누어 저장
                if (
                    args &&
                    (method.toUpperCase() === "POST" ||
                        method.toUpperCase() === "PUT" ||
                        method.toUpperCase() === "PATCH" ||
                        method.toUpperCase() === "DELETE")
                ) {
                    let argcount = 0;
                    for (let key in args) {
                        // args의 key를 가져와서
                        if (args.hasOwnProperty(key)) {
                            // key가 특정 프로퍼티값을 가지고있으면
                            if (argcount++) {
                                param += "&"; // &을 붙여 구분합니다.
                            }
                            // key, value값으로 설정합니다
                            param += encodeURIComponent(key) + "=" + args[key];
                        }
                    }
                }

                if (method.toUpperCase() == "GET") {
                    if (
                        options.encodeUrl != undefined &&
                        options.encodeUrl == false
                    ) {
                    } else {
                        url = target.setEncodeUrl(url);
                    }
                }

                // 요청객체 설정
                req.open(method, url, options.async);

                // transaction-id 생성
                let transactionId = target.getTransactionId();

                if (options.filedownload == true)
                    req.responseType = "arraybuffer";

                // 비인증시에는 signature를 넣어서 call함수를 싫행시키므로 if부분이 실행됩니다.
                if (options.signature != undefined) {
                    req.setRequestHeader("signature", options.signature);
                    req.setRequestHeader("transaction-id", options.tid);

                    // undefined를 넣는이유는 필터에서 Header의 Authorization의 값의 길이를 보고 판단하기때문에
                    // req.setRequestHeader("Authorization", "Bearer " + oAuthToken);
                    // 위코드가 실행되면 Bearer undefined로 길이기 0 이상이기때문에 필터가 비인증으로 확인
                    oAuthToken = undefined;
                } else {
                    // 인증시에는 signature를 파라미터에 넣지않으므로 else부분이 실행됩니다.
                    req.setRequestHeader("transaction-id", transactionId);
                }

                req.setRequestHeader("Content-type", options.contextType);
                req.withCredentials = false;
                // 비인증시에는 Bearer undefined
                // 인증시에는 Bearer token값이 설정됩니다.
                req.setRequestHeader("Authorization", "Bearer " + oAuthToken);
                req.setRequestHeader("timestamp", ts);

                // 인증시에는 signature가 없으므로 실행됩니다.
                if (signKey !== undefined && options.signature === undefined) {
                    let hash_key = signKey;
                    // authToken + transaction-id + timestamp + url을 hash_key로 암호화 후 인코딩하여 wehago-sign을 만듭니다.
                    // wehago-sign의 값으로 비인증인지 인증인지 구분합니다.

                    let wehagoSign = CryptoJS.enc.Base64.stringify(
                        CryptoJS.HmacSHA256(
                            oAuthToken +
                            transactionId +
                            ts +
                            target.getLocation(url).pathname,
                            hash_key
                        )
                    );
                    //let wehagoSign = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(oAuthToken + transactionId + ts + url , hash_key));

                    req.setRequestHeader("wehago-sign", wehagoSign);
                }

                req.onload = function () {
                    if (req.status >= 200 && req.status < 300) {
                        resolve(JSON.parse(req.response));
                    } else if (req.status === 401) {
                        let response = JSON.parse(req.response);
                        // 세션만료 일 경우
                        if (response.errors !== undefined) {
                            let gatewayResponse = response.errors;
                            // 세션 만료
                            if (
                                gatewayResponse.code === "E002" &&
                                (gatewayResponse.message.indexOf(
                                    "Token is not exists"
                                ) > -1 ||
                                    gatewayResponse.message.indexOf(
                                        "Session is not exists"
                                    ) > -1)
                            ) {
                                console.log("세션이 만료되었습니다.");
                            }
                        }
                        reject(req.response);
                    } else {
                        reject(req.response);
                    }
                };
                req.onerror = function (e) {
                    reject(
                        JSON.stringify({
                            status: req.status,
                            errorCode: 500,
                            errorMsg: "Network Error.",
                            errors: {
                                code: 500,
                                message: "Network Error."
                            }
                        })
                    );
                };

                // 각 컨텐츠타입에 따라 분기됩니다.
                if (options.contextType.toLowerCase() === "application/json")
                    req.send(JSON.stringify(args));
                else if (
                    options.contextType.toLowerCase() === "multipart/form-data"
                ) {
                    req.send(args);
                } else {
                    req.send(param);
                }
            });
        } catch (err) {
            xmlReq = new Promise(function (resolve, reject) {
                reject(
                    JSON.stringify({
                        errorCode: 500,
                        errorMsg: "API 요청중 오류가 발생했습니다.",
                        errors: {
                            code: 500,
                            message: "API 요청중 오류가 발생했습니다."
                        }
                    })
                );
                console.log("tc : API 요청중 오류가 발생했습니다.");
            });
            console.log(err)
        }
        return xmlReq;
    },
    getLocation: function (url) {
        let match = url.match(
            /^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/
        );

        if (match != null) {
            return (
                match && {
                    protocol: match[1],
                    host: match[2],
                    hostname: match[3],
                    port: match[4],
                    pathname: match[5],
                    search: match[6],
                    hash: match[7]
                }
            );
        } else {
            return {
                pathname: url
            };
        }
    },
    // transaction-id 생성
    // getTransactionId: function() {
    //     let chars =
    //         "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    //     let string_length = 16;
    //     let randomstring = "";
    //     for (let i = 0; i < string_length; i++) {
    //         let rnum = Math.floor(Math.random() * chars.length);
    //         randomstring += chars.substring(rnum, rnum + 1);
    //     }
    //     return randomstring;
    // },
    getTransactionId: function guid() {
        function makeUUID() {
            return (((1 + Math.random()) * 0x10000) | 0)
                .toString(16)
                .substring(1);
        }
        return (
            makeUUID() +
            makeUUID() +
            makeUUID() +
            makeUUID() +
            makeUUID() +
            makeUUID() +
            makeUUID() +
            makeUUID()
        );
    },
    // 인코딩 함수
    setEncodeUrl: function (url) {
        if (url.search("\\?") == -1) return url;
        let match,
            // pl = /\+/g,
            search = /([^&=]+)=?(.*?(?=[&?]\w+=|$))/g,
            //decode = function (s) { return encodeURIComponent(s.replace(pl, " ")); },
            decode = function (s) {
                return encodeURIComponent(s.replace(/[!'()*]/g, escape));
            },
            query = this.getLocation(url).search;
        if (query.search("\\?") == 0) query = query.substring(1, query.length);

        let urlstr = url.substring(0, url.search("\\?") + 1);
        while ((match = search.exec(query))) {
            urlstr += match[1] + "=" + decode(match[2]) + "&";
        }
        if (urlstr.substring(urlstr.length - 1, urlstr.length) == "&")
            urlstr = urlstr.substring(0, urlstr.length - 1);
        return urlstr;
    },
    //get_token 요청함수
    getUncertToken: function (url, method, callback) {
        let target = this;
        let transactionId = target.getTransactionId();
        //개발용
        let apiUncertUrl = "http://14.41.2.189"; // 비인증 api 셋팅, 개발서버(인증)의 ip를 설정합니다.
        //배포용
        //let apiUncertUrl = "";
        $.ajax({
            url: apiUncertUrl + "/get_token/?url=" + url,
            type: "GET",
            data: {},
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            crossDomain: true,
            crossOrigin: false,
            async: true,
            beforeSend: function (req) {
                // transaction-id를 헤더에 설정합니다
                req.setRequestHeader("transaction-id", transactionId); // 단계별 로그 transaction_id
            },
            success: function (data, textStatus, jqXHR) {
                console.log("data.token : " + data.token);
                console.log("data.cur_date : " + data.cur_date);
                console.log("transactionId : " + transactionId);
                console.log("url : " + url);

                let encText = data.token + data.cur_date + transactionId + url;
                // 리턴받은 token, curDate과 요청시 보냈던 url, transaction-id를 이용하여 임시시그니처 생성
                let hashText = CryptoJS.SHA256(encText);
                callback(
                    CryptoJS.enc.Base64.stringify(hashText),
                    transactionId,
                    jqXHR.status
                );
            },
            error: function (jqXHR, textStatus, errorThrown) {
                callback(null, jqXHR.status);
            }
        });
    },
    //로그인 함수 ( 위의 함수들을 신경쓰지않아도 이 함수만 실행하면됩니다.)
    devLogin: function (loginId, groupSeq, menuCode, callback) {
        // 파라미터 로그인 아이디와 그룹시퀀스를 객체에 저장합니다.
        let loginIdAndGroupSeq = {
            loginId: loginId,
            groupSeq: groupSeq
        };

        return new Promise((resolve, reject) => {
            //get_token을 수행합니다. http://서버주소/gw/devLogin
            this.getUncertToken("/gw/devLogin", "get", function (
                signature,
                tid
            ) {
                // tid : transaction-id
                let signatureAndTid = [signature, tid];
                resolve(signatureAndTid);
            });
        }).then(signatureAndTid => {
            return new Promise((resolve, reject) => {

                let url = "/gw/devLogin";
                // 실제 로그인 요청을 보냅니다.  signature와 transaction-id를 파라미터에 담아 보냅니다.
                this.post(url, loginIdAndGroupSeq, {
                    signature: signatureAndTid[0],
                    tid: signatureAndTid[1],
                    contextType: "application/json"
                }).then(
                    function (response) {
                        //response로 token과 hashKey를 받습니다.

                        // 로직 구현
                        if (
                            response.resultCode === 200
                        ) {
                            let sessionInfo = response.resultData.sessionInfo;

                            // 세션정보 쿠키 적재
                            $.cookie("oAuthToken", sessionInfo.auth_a_token);
                            $.cookie("signKey", sessionInfo.hash_key);
                            $.cookie("BIZCUBE_AT", sessionInfo.auth_a_token);
                            $.cookie("BIZCUBE_HK", sessionInfo.hash_key);
                            $.cookie("BIZCUBE_TYPE", "WEB");

                            // 로그인후 바로 로컬 api를 호출해야한다면 등록된 콜백함수를 실행
                            if (callback != undefined) {
                                callback();
                            }
                            resolve(response);
                        } else {
                            console.log('에러');
                            reject({});
                        }
                    },
                    function (error) {
                        console.error("Failed!", error);
                        reject({});
                    }
                );
            })
        });
    }

};