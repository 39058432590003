import React from 'react';
import { Switch, withRouter, Link, Route } from 'react-router-dom';
import memoizeOne from 'memoize-one';
import styles from './Documents.module.scss';
import { OBTTextField, OBTScrollbar } from '../components';
import debounce from 'lodash.debounce';
import * as Examples from '../example';
import OrbitHeader from './app/orbitUnit/OrbitHeader';
import { UpdateDocuments } from './documentsPage/updateDocuments';
import { GridAttributesPage } from '../example/OBTDataGrid/GridAttributesPage';


const getClassNames = (...classNames) => {
    return classNames.filter(name => name && name.length > 0).join(' ');
}

const examples = Object.keys(Examples).map((key) => {
    if (key.startsWith('OBT') || key.startsWith('Template') || key.startsWith('ECharts') || key.startsWith('Multilingual') || key.startsWith('Example')) {
        return {
            name: key,
            class: (Examples[key].prototype.info || {}).class || 'Etc',
            displayName: (Examples[key].prototype.info || {}).displayName,
            component: Examples[key]
        }
    } else return null;
}).filter(notNull => notNull);

class Group extends React.Component {
    state = {
        collapsed: false
    }

    groupChildrenRef = React.createRef();

    render() {
        return (
            <div className={styles.groupRoot}>
                <div className={styles.groupTitle} onClick={this.handleCollapse}>
                    <span>{`${this.props.group} (${React.Children.count(this.props.children)})`}</span>
                    <div className={getClassNames(styles.collapseButton, this.state.collapsed ? styles.collapsed : undefined)} />
                </div>
                <div ref={this.groupChildrenRef} className={getClassNames(styles.groupChildren, this.state.collapsed ? styles.collapsed : undefined)}>
                    {this.props.children}
                </div>
            </div>);
    }

    handleCollapse = () => {
        if (!this.state.collapsed) {
            if (this.groupChildrenRef.current) {
                this.groupChildrenRef.current.style.setProperty('--height', `${this.groupChildrenRef.current.offsetHeight}px`);
            }
        }
        setTimeout(() => {
            this.setState({
                collapsed: !this.state.collapsed
            });
        }, 0);
    }
}

class SearchText extends React.Component {
    state = {
        searchText: ''
    }

    render() {
        return <OBTTextField
            width={'100%'}
            placeHolder={'검색어를 입력하십시오.'}
            value={this.state.searchText}
            onChange={this.handleChange} />;
    }

    handleChange = (e) => {
        this.setState({
            searchText: e.value
        }, () => this.props.onChange(this.state.searchText));
    };
}

class Documents extends React.Component {
    getGroups = memoizeOne((searchText, pathname) => {
        const searchedExamples = searchText && searchText.length > 0 ?
            examples.filter(example =>
                example.name.toLowerCase().replace(' ', '').indexOf(searchText.toLowerCase().replace(' ', '')) >= 0) :
            examples;
        const groups = [];
        searchedExamples.forEach(example => {
            if (!groups.includes(example.class)) groups.push(example.class);
        });

        return groups.map(group => {
            const groupItems = searchedExamples.filter(example => example.class === group);
            return (
                <Group key={group} group={group}>
                    {groupItems.map(example => {
                        const selected = pathname.endsWith(example.name);
                        return (
                            <Link
                                key={example.name}
                                className={getClassNames(styles.example, selected ? styles.selected : undefined)}
                                to={`/Documents/${example.name}`}>{example.displayName || example.name}</Link>

                        )
                    })}
                </Group>
            )
        })
    });

    getExamples = memoizeOne(() => examples.map(
        (example, index) => (
            <Route key={example.name} exact path={'/Documents/' + example.name} component={example.component} />
        )
    ));

    state = {
        searchingText: '',

    }

    render() {
        const { pathname } = this.props.location;
        const groups = this.getGroups(this.state.searchText, pathname);
        const examples = this.getExamples();


        return (
            <>
                <OrbitHeader />
                <div className={styles.rootContainer}>
                    <div className={styles.root}>
                        <div className={styles.navRoot}>
                            <div className={styles.searchRoot}>
                                <SearchText onChange={this.handleSearch} />
                                <div className={styles.searchIcon} />
                            </div>
                            <div className={styles.listContainer}>
                                <div className={styles.list}>
                                    <OBTScrollbar width={'100%'} height={'100%'}>
                                        {groups}
                                    </OBTScrollbar>
                                </div>
                            </div>
                        </div>
                        <div className={styles.contentsWrap}>
                            <div className={styles.contents}>
                                <Switch>
                                    {examples}
                                    <Route exact path={'/Documents/UpdateDocuments'} component={UpdateDocuments} />
                                    <Route path={'/Documents/GridAttributesPage'} component={GridAttributesPage} />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    handleSearch = debounce((searchText) => {
        this.setState({
            searchText: searchText
        })
    }, 100);
};

export default withRouter(props => <Documents {...props} />);