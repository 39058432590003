/**
* CodePickerDialogController
* @version 0.1
 * @author 나윤수
 * @see common.js
 */
import React, { Component } from 'react';
import uuidv4 from 'uuid/v4';
import { OBTLoading } from '../..';
import { CommonProps, Events, Util } from '../../Common';
import { hasError } from '../../Common/CommonState';
import { Fetch } from '../../Common/Util';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { ReadEventArgs } from '../../OBTDataGrid/OBTDataGridEvents';
import { OBTContext } from '../../OBTPageContainer/OBTPageContainer';
import CodePickerUtil from '../CodePickerUtil';
import IBuiltInCodePicker, { IResponseData } from '../DataSource/IBuiltInCodePicker';
import { CodePickerSearchEventArgs } from '../Events/CodePickerSearchEventArgs';
import DefaultDialog, { IDefaultDialog } from './DefaultDialog';
import { CodePickerValue } from '../OBTCodePicker';

interface ICodePickerDialogController extends CommonProps.value<any[] | CodePickerValue>, Events.onChangeCodePicker {
    /**
     * 코드피커의 기본적인 속성및 데이터를 담고있는 오브젝트
     */
    codePicker: IBuiltInCodePicker,

    /**
     * 다이얼로그가 보여지는지 여부
     */
    isShowDialog: boolean,

    /**
     * 멀티선택이 가능한지 여부
     */
    canMultiSelect: boolean,

    /**
     * 코드피커의 getData에 넘기는 정적 파라미터
     */
    parameters: any,

    /**
     * 검색을 위한 string keyword
     */
    keyword?: string | null,

    /**
     * fetch api
     */
    fetch?: any,

    /**
     * @deprecated
     * 데이터소스를 캐시할것인지 여부
     * 사용안함 
     */
    isCacheDataSource: boolean,

    /**
     * 데이터 소스를 이미 읽은 상태로 다이얼로그를 열때 readData를 하지 않는다.
     */
    alreadyRead?: boolean,

    /**
     * 전체선택을 사용할것인지 여부
     */
    useTotalToEmpty?: boolean,

    /**
     * 닫기 콜백
     */
    onClose: () => void,

    /**
     * 열기 콜백
     */
    onShow?: () => void,

    /**
     * 
     */
    dialogParameters?: (() => any)

    /**
     * 
     */
    onChangeKeyword?: ((keyword: string | null) => void)
}

interface IState extends hasError {
    dataItemsKey: string,
    dataItems: any[],
    selectedItems: any[],
    /**
     * 조회조건의 파라미터
     */
    searchParameter: any | null,
    isLoading: boolean,
}

export default class OBTCodePickerDialogController extends Component<ICodePickerDialogController, IState> {
    context!: React.ContextType<typeof OBTContext>;

    /** 
     * state 초기화
     */
    state: Readonly<IState> = {
        dataItemsKey: '',
        isLoading: false,
        dataItems: [],
        selectedItems: [],
        searchParameter: null,
        hasError: false,
    };

    /**
     * default props 설정 
     */
    static defaultProps = {
        isShowDialog: false,
        parameters: {},
        isCacheDataSource: false,
        paging: false,
        alreadyRead: false,
        useTotalToEmpty: false,
    }

    componentDidMount() {
        try {
            if (this.props.alreadyRead === false && this.props.isShowDialog === true && this.props.codePicker.paging !== true) {
                this.readData()
            }
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    componentDidUpdate(prevProps: ICodePickerDialogController) {
        try {
            if (this.props.alreadyRead === false && this.props.isShowDialog !== prevProps.isShowDialog) {
                this.readData()
            }
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    /**
     * @internal
     * @param parameter
     */
    public fetchCodePickerData(parameter: any) {
        if (!this.props.codePicker.getData) {
            return;
        }

        this.setState({
            isLoading: true
        }, () => {
            this.props.codePicker.getData!(parameter).then((response: IResponseData) => {
                if (!response.data) {
                    this.setState({
                        dataItemsKey: uuidv4()
                    }, () => {
                        if (this.props.onShow) {
                            this.props.onShow();
                        }
                    })
                } else if (response.data.length === 1) {
                    const resultItem = response.data[0];
                    this.props.onChange(
                        new Events.CodePickerChangeEventArgs(this, [resultItem])
                    );
                } else if (response.data.length > 1) {
                    this.setState({
                        dataItems: response.data,
                        dataItemsKey: uuidv4()
                    }, () => {
                        if (this.props.onShow) {
                            this.props.onShow();
                        }
                    })
                } else {
                    // FIXME: 이전 value로 onChange 하는게 맞나
                    this.props.onChange(new Events.CodePickerChangeEventArgs(this, this.props.value))
                }
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                if (this.state.isLoading === true) {
                    this.setState({
                        isLoading: false
                    });
                }
            });
        });
    }

    /**
     * 
     * @param args 
     */
    private readDataItemsAndSetState = (args: any) => {
        if (!this.props.codePicker.getData) {
            return;
        }

        this.setState({
            isLoading: true
        }, () => {
            this.props.codePicker.getData!(args).then((response: IResponseData) => {
                this.setState({
                    dataItemsKey: uuidv4(),
                    dataItems: response.data,
                    isLoading: false
                })
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                if (this.state.isLoading === true) {
                    this.setState({
                        isLoading: false
                    });
                }
            });
        });
    }

    private getFetch = () => {
        let fetch = this.context.fetch;
        if (this.props.fetch) {
            if (this.props.fetch instanceof Fetch) {
                fetch = this.props.fetch;
            } else {
                fetch = new Fetch(this.props.fetch);
            }
        }

        return fetch;
    }

    private readData = () => {
        if (this.props.isShowDialog === true && this.props.codePicker.paging !== true) {
            let getDataArgs = this.makeParameters(
                this.props.parameters,
                this.getFetch(),
                this.props.keyword
            );

            this.readDataItemsAndSetState(
                getDataArgs
            );
        }
    }

    private makeParameters = (parameter: any, fetch: any, keyword?: string | null) => {
        let result = {
            parameters: {}
        } as any;
        if (parameter.parameters !== undefined) {
            result.parameters = parameter.parameters;
        }
        else {
            result.parameters = parameter;
        }

        result.fetch = fetch;

        if (keyword) {
            result.keyword = keyword;
        }
        return result;
    }

    private handleClickCloseDialogButton = () => {
        this.setState({
            searchParameter: null,
            dataItems: [],
            isLoading: false,
        }, () => {
            this.props.onClose();
        })
    }

    /**
     * 다이얼로그 선택 클릭
     * @param totalSelect 
     */
    private handleClickDialogSelectButton = (totalSelect?: boolean, excpetItems?: any[] | null) => {
        const value = this.props.useTotalToEmpty === true ? new CodePickerValue(
            totalSelect === true ? true : false,
            totalSelect === true ? null : this.state.selectedItems,
            excpetItems ? excpetItems : []
        ) : this.state.selectedItems;

        this.props.onChange(new Events.CodePickerChangeEventArgs(
            this,
            value,
            null,
            this.props.useTotalToEmpty
        ));
    }

    /**
     * 다이얼로그 체크 변경
     * @param items 
     */
    private handleChangeRowChecked = (items: any[]) => {
        this.setState({
            selectedItems: items,
        });
    }

    /**
     * value 선택 콜백
     * @param selectedItem 
     * @param exceptItems 
     * @param totalSelect 
     */
    private handleSelectAndConfirm = (selectedItem: any[], exceptItems?: any[] | null, totalSelect?: boolean) => {
        this.setState({
            selectedItems: totalSelect === true ? [] : selectedItem,
        }, () => {
            this.props.onChange(new Events.CodePickerChangeEventArgs(
                this,
                this.props.useTotalToEmpty === true ?
                    new CodePickerValue(
                        totalSelect === true ? true : false,
                        totalSelect === true ? null : selectedItem,
                        exceptItems ? exceptItems : []
                    ) :
                    this.state.selectedItems,
                null,
                this.props.useTotalToEmpty
            ));
        });
    }

    private handleDialogSearchButtonClicked = (evt: CodePickerSearchEventArgs) => {
        const getDataArgs = this.makeParameters(
            this.props.parameters,
            this.getFetch(),
            null
        );

        getDataArgs.parameters = {
            ...getDataArgs.parameters,
            ...evt.searchParameter
        }

        this.setState({
            searchParameter: evt.searchParameter,
        }, () => {
            if (this.props.codePicker.paging !== true) {
                this.readDataItemsAndSetState(getDataArgs);
            }
        })
    }

    /**
     * 페이징은 OnReadData
     * @param e 
     */
    private handleDialogReadDataItems = (e: {
        target: any,
        event: ReadEventArgs,
        parameters?: any | null,
    }): Promise<any[] | null | undefined> => {
        if (!this.props.codePicker.getData) {
            return Promise.resolve([]);
        }

        return new Promise<any[]>((resolve) => {
            this.setState({
                isLoading: true
            }, () => {
                let args = this.makeParameters(
                    this.props.parameters,
                    this.props.fetch ? this.props.fetch : this.context.fetch,
                    this.props.keyword
                );

                args.parameters = {
                    ...args.parameters,
                    ...e.parameters
                };
                args.pagingInfo = e.event.pagingInfo;

                this.props.codePicker.getData!(args).then((response: IResponseData) => {
                    resolve(response.data);
                }).catch((e) => {
                    console.error(e);
                }).finally(() => {
                    this.setState({
                        isLoading: false
                    });
                });
            });
        });
    }

    private renderDialog = () => {
        let Component: React.ComponentType<IDefaultDialog>;
        if (this.props.codePicker.customDialogComponent) {
            Component = this.props.codePicker.customDialogComponent;
        } else {
            Component = DefaultDialog;
        }

        return (
            <Component
                isShow={this.props.isShowDialog}
                title={this.props.codePicker.dialogTitle}
                dataItemsKey={this.state.dataItemsKey}
                dataItems={this.state.dataItems}
                subTitle={this.props.codePicker.dialogSubtitle}
                width={CodePickerUtil.getDialogSize('width', this.props.codePicker)}
                height={CodePickerUtil.getDialogSize('height', this.props.codePicker)}
                canMultiSelect={this.props.canMultiSelect}
                codeProperty={this.props.codePicker.codeProperty}
                gridColumns={this.props.codePicker.columns || []}
                value={Util.nullToEmpty(CodePickerValue.asArrayValue(this.props.value))}
                exceptValue={this.props.value instanceof CodePickerValue ? this.props.value.exceptValue : null}
                searchKeyword={this.props.keyword || undefined}
                customSearchConditionComponent={this.props.codePicker.customSearchConditionComponent}
                paging={this.props.codePicker.paging}
                rowCountPerPage={this.props.codePicker.rowCountPerPage}
                initialRowCount={this.props.codePicker.initialRowCount}
                useTotalToEmpty={this.props.useTotalToEmpty}
                fetch={this.props.fetch}
                isLoading={this.state.isLoading}
                parameters={this.props.parameters}
                dialogParameters={this.props.dialogParameters}
                onClickClose={this.handleClickCloseDialogButton}
                onClickSearch={this.handleDialogSearchButtonClicked}
                onChangeSelectedItems={this.handleChangeRowChecked}
                onConfirmSelectedItems={this.handleClickDialogSelectButton}
                onSelectAndConfirmItems={this.handleSelectAndConfirm}
                onReadDataItems={this.props.codePicker.paging === true ? this.handleDialogReadDataItems : undefined}
                onChangeKeyword={(keyword) => {
                    this.props.onChangeKeyword && this.props.onChangeKeyword(keyword)
                }}
            />
        )
    }

    renderComponent = () => {
        return (
            <>
                <OBTLoading
                    open={this.state.isLoading}
                    value="Loading"
                    type={OBTLoading.Type.default}
                    fullScreen={true}>

                </OBTLoading>
                {this.props.isShowDialog ? this.renderDialog() : null}
            </>

        );
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        )
    }
}

OBTCodePickerDialogController.contextType = OBTContext;