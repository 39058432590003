/**
 * OBTDropDownList2
 * @version 0.1
 * @author 김소현
 * @see common.js
 */
import * as React from 'react';
import { Events, CompositeProps, Util, CommonProps } from '../Common';
import OBTTooltip, { IOBTTooltip } from '../OBTTooltip/OBTTooltip';
import keycode from "keycode";
import downArrowImg from '../Images/ic_arrow_up_01_s_Up.png';
import upArrowImg from '../Images/ic_arrow_up_01_s_over.png';
import { ChangeEventArgs } from '../Common/Events';
import { Scrollbars } from 'react-custom-scrollbars';
import OBTFloatingPanel from '../OBTFloatingPanel';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { hasError } from '../Common/CommonState';
/**
 * @internal
 * CSS Modules 사용방식
 * styles.[className]
 */
const styles = require('./OBTDropDownList2.module.scss');

export enum DisplayType {
    /**
     * numbertext, numberText : 숫자. 텍스트
     * text : 텍스트
     * valueText : 값. 텍스트 (default)
     */
    'numbertext' = 'numbertext',
    'numberText' = 'numberText',
    'text' = 'text',
    'valueText' = 'valueText'
}

interface TypeList {
    /**
     * value : 값
     */
    value: string,
    /**
     * labelText : 텍스트
     */
    labelText: any,
    /**
     * labelSubText : 서브텍스트
     */
    labelSubText?: string,
    /**
     * imageUrl : 이미지
     */
    imageUrl?: string,
    /**
     * itemTooltip : 리스트 아이템에 보여질 툴팁
     */
    itemTooltip?: any
}

interface IOBTDropDownList2 extends CompositeProps.InputClass<string>, CommonProps.useSubLang, Events.onValidate<string>, Events.onMouseDown, Events.onMouseMove,
    Events.onMouseUp, Events.onMouseLeave, Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress, Events.onKeyUp {
    /**
    * 컴포넌트 입력필드에서 보여지는 리스트를 정의하는 속성입니다.
    */
    list: Array<any>,
    /**
    * 텍스트 디스플레이 타입을 지정하는 속성입니다.
    */
    displayType?: DisplayType,
    /**
    * 틀팁에 대한 설정 속성입니다.
    */
    tooltip?: IOBTTooltip,
    /**
    * 컴포넌트에서 데이터소스를 이용하여 매핑 정의하는 속성입니다.
    */
    fieldName?: TypeList,
}

/**
 * @internal
 * State 정의
 */
interface IState extends hasError {
    list: Array<any>,
    isShowDropDown: boolean,
    dropDownWidth: number,
    dropDownSelectedIndex: any | null,
    searchedValue: string,
    isShowTooltip: boolean,
    inputOverflowTooltip: boolean,
    itemTooltip: {
        value: string,
        labelText: string,
        theme?: string,
        position?: string,
        align?: string
    } | null,
    moveFocusContext?: {
        prevProps: string,
        nextProps: string
    }
    animateDropDown: any | undefined
}

export default class OBTDropDownList2 extends React.Component<IOBTDropDownList2, IState> {
    public static DisplayType = DisplayType;

    public static defaultProps = {
        disabled: false,
        frozen: false,
        required: false,
        readonly: false,
        displayType: DisplayType.valueText
    }

    public state: IState = {
        list: [],
        isShowDropDown: false,
        dropDownWidth: 0,
        dropDownSelectedIndex: null,
        searchedValue: '',
        isShowTooltip: false,
        inputOverflowTooltip: false,
        itemTooltip: null,
        animateDropDown: undefined
    }

    private rootRef = React.createRef<HTMLDivElement>();
    private inputWrapperRef = React.createRef<HTMLDivElement>();
    private inputTextRef = React.createRef<HTMLDivElement>();
    private dropDownRootRef = React.createRef<HTMLDivElement>();
    private dropDownListWrapperRef = React.createRef<HTMLDivElement>();
    private scrollBarRef = React.createRef<Scrollbars>()

    componentDidMount() {
        if (this.dropDownRootRef.current) {
            const rootWidth = this.dropDownRootRef.current.clientWidth;

            this.setState({
                dropDownWidth: rootWidth
            });
        }
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentDidUpdate(prevProps: IOBTDropDownList2, prevState: IState) {
        if (this.props.value !== prevProps.value) {
            this.setState({
                dropDownSelectedIndex: this.getCurrentValueIndex()
            })
        }

        //dropdown 영역 width 갱신
        if (this.dropDownRootRef.current) {
            const rootWidth = this.dropDownRootRef.current.clientWidth;
            if ((this.props.width !== prevProps.width) || (this.state.dropDownWidth !== rootWidth)) {
                this.setState({
                    dropDownWidth: rootWidth
                });
            }
        }

        if (this.state.moveFocusContext &&
            prevProps.value === this.state.moveFocusContext.prevProps &&
            this.props.value === this.state.moveFocusContext.nextProps) {
            this.setState({
                moveFocusContext: undefined
            }, () => {
                this.handleMoveFocus('enter')
            })
        }

        if (this.state.isShowDropDown !== prevState.isShowDropDown) {
            // 선택된 아이템의 스크롤 탑 위치조정
            if (this.state.isShowDropDown === true && this.scrollBarRef.current && this.state.dropDownSelectedIndex > 6) {

                const scroll = this.scrollBarRef.current
                const itemHeight = 27;
                const currentItemHeight = itemHeight * this.state.dropDownSelectedIndex;

                scroll.scrollTop = Math.floor(currentItemHeight - 54)
            }
            // 드랍다운 열릴때 애니메이션 클래스 
            if (this.state.isShowDropDown === true) {
                this.setState({
                    animateDropDown: 'animate'
                })
            } else {
                this.setState({
                    animateDropDown: undefined,
                    isShowTooltip: false
                })
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    public isEmpty(): boolean {
        return this.props.value === '' ? true : false;
    }

    public validate() {
        return !(this.props.required === true && this.isEmpty());
    }

    public focus(): void {
        if (this.canEdit() && this.inputWrapperRef.current) {
            this.inputWrapperRef.current.focus();
        }
    }

    public blur(): void {
        if (this.inputWrapperRef.current) {
            this.inputWrapperRef.current.blur();
        }
    }

    private canEdit = () => {
        return !this.props.frozen && !this.props.disabled;
    }

    private getLabelText = (item) => {
        let labelTextKey = this.props.fieldName && this.props.fieldName.labelText ? this.props.fieldName.labelText : 'labelText';
        let textKey = this.props.fieldName && item.text ? item.text : 'text';
        return item[labelTextKey] || item[textKey]
    }

    private getValueKey = () => {
        return this.props.fieldName && this.props.fieldName.value ? this.props.fieldName.value : 'value';
    }

    private getImageUrlKey = () => {
        return this.props.fieldName && this.props.fieldName.imageUrl ? this.props.fieldName.imageUrl : 'imageUrl';
    }

    private updateDropDownScrollTop = () => {
        if (this.state.isShowDropDown === true && this.state.dropDownSelectedIndex && this.scrollBarRef.current) {
            const scrollTop = this.scrollBarRef.current.scrollTop;

            const dropDownWrapperHeight = 189;
            const itemHeight = 27;
            const currentItemHeight = itemHeight * this.state.dropDownSelectedIndex;

            if (Math.abs(scrollTop - currentItemHeight) >= 189 && scrollTop + dropDownWrapperHeight <= currentItemHeight) {
                this.scrollBarRef.current.scrollTop = Math.floor(scrollTop + dropDownWrapperHeight);
            } else if (scrollTop >= currentItemHeight) {
                this.scrollBarRef.current.scrollTop = Math.floor(scrollTop + itemHeight - dropDownWrapperHeight);
            }
        }
    }

    private getCurrentValueIndex = () => {
        const index = this.props.list.findIndex(item => {
            return (item[this.getValueKey()] === this.props.value)
        })

        if (index === -1) {
            return null;
        }
        return index;
    }

    /*
     * 키보드로 value 입력시 해당 value 찾아 input value onChange
     */
    private searchedValueChange(value: string, displayType: DisplayType) {
        if (this.state.isShowDropDown === true) {
            const keyPressValue = this.state.searchedValue + String(value);
            const searchedByValue = this.props.list.filter((item, index) => {
                if (this.props.displayType === DisplayType.numbertext || this.props.displayType === DisplayType.numberText) {
                    return String(index).startsWith(keyPressValue)
                }
                return item[this.getValueKey()].startsWith(keyPressValue)
            });

            if (searchedByValue.length > 1) {
                this.setState({
                    searchedValue: keyPressValue
                });
                setTimeout(() => {
                    this.props.onChange(new ChangeEventArgs<string>(this, searchedByValue[0][this.getValueKey()], [searchedByValue[0]]));
                }, 0)
            } else if (searchedByValue.length === 1) {
                this.setState({
                    isShowDropDown: false,
                    searchedValue: '',
                    moveFocusContext: {
                        prevProps: this.props.value,
                        nextProps: searchedByValue[0][this.getValueKey()]
                    }
                })
                setTimeout(() => {
                    this.props.onChange(new ChangeEventArgs<string>(this, searchedByValue[0][this.getValueKey()], searchedByValue));
                }, 0)
            } else {
                this.setState({ searchedValue: '' })
            }
        }
    }

    getDisplayValue = () => {
        if (!this.props.list || this.props.list.length <= 0) {
            return '';
        }

        const currentValueIndex = this.getCurrentValueIndex();

        if (currentValueIndex === null) {
            return ''
        }

        const labelTextList = this.props.list.map((item) => {
            return this.getLabelText(item)
        });

        if (this.props.value === '') {
            return labelTextList[currentValueIndex]
        }

        if (this.props.displayType === DisplayType.numbertext || this.props.displayType === DisplayType.numberText) {
            return `${this.getCurrentValueIndex()}. ${labelTextList[currentValueIndex]}`
        } else if (this.props.displayType === DisplayType.valueText) {
            return `${this.props.value}. ${labelTextList[currentValueIndex]}`
        }
        return `${labelTextList[currentValueIndex]}`
    }

    private handleSearchedValueByKey = (e: Events.KeyEventArgs) => {
        Util.invokeEvent<Events.KeyEventArgs>(this.props.onKeyPress, e);
        const value = String.fromCharCode(e.event.charCode);

        if (value) {
            const displayType = this.props.displayType === undefined ? DisplayType.valueText : this.props.displayType;
            switch (displayType) {
                case DisplayType.numbertext:
                    this.searchedValueChange(value, displayType);
                    break;
                case DisplayType.numberText:
                    this.searchedValueChange(value, displayType);
                    break;
                case DisplayType.text:
                    this.searchedValueChange(value, displayType);
                    break;
                case DisplayType.valueText:
                    this.searchedValueChange(value, displayType);
                    break;
                default:
                    break;
            }
        }
    }

    private handleOutsideClick = (e) => {
        if (this.state.isShowDropDown === true && this.dropDownRootRef.current && !this.dropDownRootRef.current.contains(e.target)) {
            if (this.dropDownListWrapperRef.current && !this.dropDownListWrapperRef.current.contains(e.target)) {
                this.setState({
                    isShowDropDown: false
                })
            }
        }
    }

    private handleInputMouseDown = () => {
        if (this.canEdit()) {
            this.setState({
                isShowDropDown: !this.state.isShowDropDown,
                dropDownSelectedIndex: !this.state.isShowDropDown ? this.getCurrentValueIndex() : null
            })
        }
    }

    private handleInputFocus = () => {
        if (this.canEdit()) {
            this.setState({
                isShowDropDown: true,
                dropDownSelectedIndex: this.getCurrentValueIndex()
            });
            Util.invokeEvent<Events.FocusEventArgs>(this.props.onFocus, new Events.FocusEventArgs(this));
        }
    }

    private handleInputBlur = () => {
        Util.invokeEvent<Events.FocusEventArgs>(this.props.onBlur, new Events.FocusEventArgs(this));
    }

    private handleListItemClick = (item) => {
        if (this.props.value !== item[this.getValueKey()]) {
            const oldValue = this.props.value
            const oldItem = this.state.list
            if (!Util.invokeEvent<Events.ValidateEventArgs<string>>(this.props.onValidate, new Events.ValidateEventArgs<string>(this, oldValue, item[this.getValueKey()], oldItem, [item]))) {
                if (this.inputWrapperRef.current) {
                    this.inputWrapperRef.current.focus()
                }
                this.setState({
                    isShowDropDown: false
                })
                return;
            }
            if (!this.props.readonly) {
                this.props.onChange(new ChangeEventArgs<string>(this, item[this.getValueKey()], [item]));
            }
        }
        this.setState({
            isShowDropDown: false,
            moveFocusContext: {
                prevProps: this.props.value,
                nextProps: item[this.getValueKey()]
            }
        })
    }

    private handleMoveFocus = (direction: string) => {
        if (this.props.required && this.isEmpty() && ['right', 'down', 'enter'].includes(direction)) {
            return;
        }
        Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
    }

    private handleKeyDown = (event: any) => {
        if (this.props.onKeyDown) {
            Util.invokeEvent<Events.KeyEventArgs>(this.props.onKeyDown, new Events.KeyEventArgs(this, event));
        }
        const dropDownIsOpen = this.state.isShowDropDown === true
        switch (keycode(event)) {
            case 'tab':
                event.preventDefault();
                break;
            case 'esc':
                event.preventDefault();
                if (dropDownIsOpen) {
                    this.setState({
                        isShowDropDown: false
                    })
                    if (this.inputWrapperRef.current) {
                        this.inputWrapperRef.current.blur();
                    }
                }
                break;
            case 'down':
                event.preventDefault()
                if (dropDownIsOpen) {
                    if (this.state.dropDownSelectedIndex === null) {
                        this.setState({
                            dropDownSelectedIndex: 0
                        });
                    } else if (this.state.dropDownSelectedIndex < this.props.list.length - 1) {
                        this.setState({
                            dropDownSelectedIndex: this.state.dropDownSelectedIndex + 1
                        }, () => {
                            this.updateDropDownScrollTop();
                        })
                    }
                } else {
                    return false
                }
                break;
            case 'up':
                event.preventDefault()
                if (dropDownIsOpen) {
                    if (this.state.dropDownSelectedIndex === null || this.state.dropDownSelectedIndex === 0) {
                        this.setState({
                            dropDownSelectedIndex: 0
                        })
                    } else {
                        this.setState({
                            dropDownSelectedIndex: this.state.dropDownSelectedIndex - 1
                        }, () => {
                            this.updateDropDownScrollTop();
                        })
                    }
                } else {
                    return false
                }
                break;
            case 'enter':
                event.preventDefault()
                if (dropDownIsOpen) {
                    let currentFocusData = this.props.list[this.state.dropDownSelectedIndex];

                    if (this.props.value !== currentFocusData[this.getValueKey()]) {
                        const oldValue = this.props.value
                        const oldItem = this.state.list

                        if (!Util.invokeEvent<Events.ValidateEventArgs<string>>(this.props.onValidate, new Events.ValidateEventArgs<string>(this, oldValue, currentFocusData[this.getValueKey()], oldItem, [currentFocusData]))) {
                            this.setState({
                                isShowDropDown: false
                            })
                            return;
                        }
                    }
                    if (!this.props.readonly) {
                        this.props.onChange(new ChangeEventArgs<string>(this, currentFocusData[this.getValueKey()], [currentFocusData]));
                    }
                    this.setState({
                        isShowDropDown: false,
                        moveFocusContext: {
                            prevProps: this.props.value,
                            nextProps: currentFocusData[this.getValueKey()]
                        }
                    })
                }
                break;
            case 'right':
                event.preventDefault()
                this.setState({
                    isShowDropDown: false
                })
                this.handleMoveFocus(keycode(event));
                break;
            case 'left':
                event.preventDefault()
                this.setState({
                    isShowDropDown: false
                })
                this.handleMoveFocus(keycode(event));
                break;
            default:
        }
    }

    /**
     * @param e 
     * @param item
     * 텍스트가 영역을 넘어갈 때 툴팁처리 
     */
    private handleListItemMouseEnter = (e: React.MouseEvent, item: any) => {
        const scrollWidth = e.currentTarget.scrollWidth
        const clientWidth = e.currentTarget.clientWidth
        let tooltipKey = this.props.fieldName && this.props.fieldName.itemTooltip ? this.props.fieldName.itemTooltip : 'itemTooltip';
        if (this.state.isShowDropDown === true && e.currentTarget) {
            const customTooltip = item[tooltipKey]

            if (item[tooltipKey]) {
                this.setState({
                    isShowTooltip: true,
                    itemTooltip: {
                        value: item[this.getValueKey()],
                        labelText: customTooltip.labelText,
                        align: customTooltip.align,
                        position: customTooltip.position
                    }
                })
            } else if (clientWidth < scrollWidth) {
                this.setState({
                    isShowTooltip: true,
                    itemTooltip: {
                        value: item[this.getValueKey()],
                        labelText: this.getLabelText(item)
                    }
                })
            } else {
                this.setState({
                    isShowTooltip: false,
                    itemTooltip: null
                })
            }
        }
    }

    private handleInputMouseEnter = (e: React.MouseEvent) => {
        if (this.inputTextRef.current) {
            const inputClientWidth = this.inputTextRef.current.clientWidth;
            const inputScrollWidth = this.inputTextRef.current.scrollWidth;

            if (inputClientWidth < inputScrollWidth) {
                this.setState({
                    inputOverflowTooltip: true
                })
            }
        }
    }

    private handleMouseLeave = (e: React.MouseEvent) => {
        this.setState({
            isShowTooltip: false,
            inputOverflowTooltip: false
        })
    }

    /**
     * input 영역
     */
    renderInputField = () => {
        let list = this.props.list;

        const input = (
            <div
                tabIndex={!this.canEdit() ? undefined : -1}
                className={Util.getClassNames(
                    styles.inputWrapper,
                    this.props.disabled && styles.disabled,
                    this.props.required && styles.require,
                    this.props.readonly && styles.readonly,
                    this.props.frozen && styles.frozen
                )}
                ref={this.inputWrapperRef}
                onMouseDown={this.handleInputMouseDown}
                onMouseEnter={this.handleInputMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onFocus={this.handleInputFocus}
                onBlur={this.handleInputBlur}
                onKeyDown={this.handleKeyDown}
            >
                {
                    list.map((item, index) => {
                        if (item[this.getImageUrlKey()]) {
                            return <span key={index} className={styles.useImage}>
                                <img src={item[this.getImageUrlKey()]} alt={'Item Icon'} />
                            </span>
                        }
                        return undefined;
                    }).filter((item, index) => {
                        return item && (this.getCurrentValueIndex() === index)
                    })
                }
                <div className={styles.inputText} ref={this.inputTextRef} >
                    {this.getDisplayValue()}
                </div>
                <div className={styles.arrowBtn} >
                    <img src={this.state.isShowDropDown === true ? upArrowImg : downArrowImg}
                        className={Util.getClassNames(this.state.isShowDropDown === false ? styles.arrowDown : null)} alt="arrow button" />
                </div>
            </div>
        )

        const currentValueIndex = this.getCurrentValueIndex();
        if (currentValueIndex === null) {
            return input
        }
        const labelTextList = this.props.list.map((item) => {
            return this.getLabelText(item)
        });

        return (
            <OBTTooltip
                value={this.state.inputOverflowTooltip === true ? true : false}
                className={styles.tooltipDefault}
                focusValue={false}
                labelText={
                    this.props.displayType === DisplayType.numbertext || this.props.displayType === DisplayType.numberText ? `${currentValueIndex}. ${labelTextList[currentValueIndex]}` :
                        this.props.displayType === DisplayType.text ? labelTextList[currentValueIndex] :
                            `${this.props.value}. ${labelTextList[currentValueIndex]}`
                }
            >
                {input}
            </OBTTooltip>
        )
    }

    /**
     * dropDown 영역
     */
    renderDropDownField = () => {
        let listData = this.props.list

        if (this.state.isShowDropDown === false) {
            return;
        }

        return (
            <OBTFloatingPanel
                value={this.state.isShowDropDown}
                anchor={this.inputWrapperRef}
                position={OBTFloatingPanel.Position.bottom}
                align={OBTFloatingPanel.Align.near}>
                <div
                    className={this.state.animateDropDown === 'animate' ? Util.getClassNames(styles.dropDownWrapperAnimate, styles.dropDownWrapper) : styles.dropDownWrapper}
                    style={{
                        width: this.state.dropDownWidth,
                        height: this.scrollBarRef.current && this.scrollBarRef.current.getBoundingClientRect().height
                    }}
                    ref={this.dropDownListWrapperRef}>
                    <div className={styles.dropDownList} ref={this.scrollBarRef} >
                        {
                            listData.map((item, index) => {
                                let itemTooltipKey = this.props.fieldName && this.props.fieldName.itemTooltip ? this.props.fieldName.itemTooltip : 'itemTooltip';

                                let listItem = (
                                    <div
                                        key={index}
                                        className={
                                            Util.getClassNames(
                                                styles.dropDownItem,
                                                this.state.dropDownSelectedIndex === index && styles.focusedItem,
                                            )}
                                        style={{
                                            padding: item[this.getImageUrlKey()] ? ' 0 7px 0 29px' : '0 7px 0 7px'
                                        }}
                                        onClick={() => { this.handleListItemClick(item) }}
                                        onMouseEnter={(e) => { this.handleListItemMouseEnter(e, item) }}
                                        onMouseLeave={this.handleMouseLeave}
                                    >
                                        {
                                            item[this.getImageUrlKey()] ? (
                                                <span key={index} className={styles.useImage}>
                                                    <img src={item[this.getImageUrlKey()]} alt={'Item Icon'} />
                                                </span>
                                            ) : null
                                        }
                                        <span >
                                            {
                                                this.props.displayType === DisplayType.numbertext || this.props.displayType === DisplayType.numberText ? `${index}.  ${this.getLabelText(item)}` :
                                                    this.props.displayType === DisplayType.valueText && item[this.getValueKey()] !== '' ? `${item[this.getValueKey()]}. ${this.getLabelText(item)}` : `${this.getLabelText(item)}`
                                            }
                                        </span>
                                    </div>
                                )
                                if (this.state.itemTooltip && this.state.itemTooltip.value === item[this.getValueKey()]) {
                                    const useCustomTooltip = item[itemTooltipKey]
                                    return (
                                        <OBTTooltip
                                            key={index}
                                            value={this.state.isShowTooltip}
                                            className={styles.itemOverFlowTooltip}
                                            labelText={
                                                useCustomTooltip ? useCustomTooltip.labelText :
                                                    this.props.displayType === DisplayType.numbertext || this.props.displayType === DisplayType.numberText ? `${index}. ${this.state.itemTooltip.labelText}` :
                                                        this.props.displayType === DisplayType.text ? this.state.itemTooltip.labelText : `${this.state.itemTooltip.value}. ${this.state.itemTooltip.labelText}`
                                            }
                                            theme={useCustomTooltip ? useCustomTooltip.theme : OBTTooltip.Theme.default}
                                            align={useCustomTooltip ? useCustomTooltip.align : OBTTooltip.Align.center}
                                            position={useCustomTooltip ? useCustomTooltip.position : OBTTooltip.Position.top}
                                            focusValue={false}
                                            overrideSize={false}
                                        >
                                            {listItem}
                                        </OBTTooltip>
                                    )
                                } else {
                                    return listItem;
                                }
                            })
                        }
                    </div>
                </div>
            </OBTFloatingPanel>
        )
    }

    renderComponent = () => {
        return (
            <div
                style={{
                    width: this.props.width ? this.props.width : '100%',
                    height: this.props.height,
                }}
                ref={this.rootRef}>
                <OBTTooltip
                    {...this.props.tooltip}
                    className={Util.getClassNames(styles.tooltipDefault, this.props.className)}
                    focusValue={false}
                    overrideSize={false}
                    onMouseDown={this.props.onMouseDown}
                    onMouseMove={this.props.onMouseMove}
                    onMouseUp={this.props.onMouseUp}
                    onMouseLeave={this.props.onMouseLeave}
                    onMouseEnter={this.props.onMouseEnter}
                    onKeyUp={this.props.onKeyUp}
                    onKeyPress={this.handleSearchedValueByKey}
                    onMoveFocus={e => this.handleMoveFocus(e.direction)}
                    rootProps={{
                        id: this.props.id,
                        'data-orbit-component': 'OBTDropDownList2'
                    }} >
                    <div ref={this.dropDownRootRef}>
                        {this.renderInputField()}
                        {this.renderDropDownField()}
                    </div>
                </OBTTooltip>
            </div>
        )
    }

    render() {
        return (<ErrorBoundary owner={this} render={this.renderComponent} />)
    }
};
