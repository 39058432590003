import React from "react";
import uuidv4 from 'uuid/v4';
import OBTDataGrid, { OBTDataGridInterface } from ".";
import { Util } from "../Common";
import { hasError } from "../Common/CommonState";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import OBTButton from "../OBTButton";
import OBTDialog2 from '../OBTDialog2';
import { ColumnType, IColumn } from "./IColumn";
import { GridType, StandardDesign } from "./OBTDataGridInterface";
import OBTAlert from "../OBTAlert";

interface IUserCustomColumnDialogProps {
    isShow: boolean,
    columnList: IColumn[],
    onClickClose: () => void,
    onChangeColumn: ((columns: any[]) => void),
    onResetButtonClick: () => void,
}

interface IState extends hasError {
    grid: OBTDataGridInterface,
    showAlertNoColumn: boolean,
}

export default class UserCustomColumnDialog extends React.Component<IUserCustomColumnDialogProps, IState> {
    state: IState = {
        grid: new OBTDataGridInterface(uuidv4(), {
            gridType: GridType.gridView,
            editable: true,
            appendable: false,
            indicator: true,
            preventRClick: true,
            isSystemGrid: true,
        }).setColumns([
            {
                name: 'columnHeaderName',
                header: '컬럼명',  //TODO: 다국어
                type: ColumnType.text,
                isAutoWidth: true,
                editable: false,
            },
            {
                name: 'isVisibleColumn',
                header: '화면표시', //TODO: 다국어
                type: ColumnType.check,
                trueValues: ["T"],
                falseValues: ["F"],
                width: 70,
                editable: (e) => {
                    return this.state.grid.getValue(e.rowIndex, 'isRequired') === 'F'
                },
                dynamicStyles: (grid, index, value) => {
                    if (this.state.grid.getValue(index.itemIndex, 'isRequired') === 'T') {
                        return {
                            background: StandardDesign.GridColorRGB.columnReadonlyBackground
                        }
                    }

                    return {
                    }
                }
            },
            {
                name: 'columnName',
                type: ColumnType.data,
            },
            {
                name: 'isRequired',
                type: ColumnType.data,
            }
        ]).setProvider({
            read: (e) => {
                return new Promise((resolve) => {
                    const dataItems = this.props.columnList.map(item => {
                        return {
                            columnName: item.name,
                            columnHeaderName: item.header,
                            isVisibleColumn: (item.type !== ColumnType.data && item.visible !== false) ? 'T' : 'F',
                            isRequired: item.hidable === false || (item.required === true || typeof item.required === 'function') ? 'T' : 'F'
                        }
                    });
                    resolve(dataItems);
                })
            },
            store: (e) => {
                return new Promise<void>((resolve) => {
                    resolve();
                })
            }
        }),
        showAlertNoColumn: false,
    }

    componentDidMount() {
        try {
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    private handleClickOk = () => {
        if (this.state.grid.getRows().filter((row) => row.isVisibleColumn === "T").length === 0) {
            this.setState({
                showAlertNoColumn: true
            })
            return;
        }

        if (this.state.grid.hasDirtyData() === true) {
            this.props.onChangeColumn(this.state.grid.getRows());
        }
        this.props.onClickClose();
    }

    private handleClickClose = () => {
        this.props.onClickClose();
    }

    renderComponent = () => {
        return (
            <OBTDialog2
                title={'컬럼 표시'} //TODO: 다국어
                open={this.props.isShow}
                width={"400px"}
                height={"650px"}
                buttons={OBTDialog2.Buttons.ConfirmAndCancel(
                    this.handleClickOk,
                    this.handleClickClose
                )}
                onAfterOpen={() => {
                    this.state.grid.readData();
                }}
            >
                <div>
                    <div style={{
                        marginBottom: '4px',
                        display: 'flex',
                        flexDirection: 'row-reverse'
                    }}>
                        <div style={{
                        }}>
                            {/* TODO: 다국어 */}
                            <OBTButton labelText="초기화" onClick={() => {
                                this.props.onResetButtonClick()
                            }} />
                        </div>
                    </div>
                    <div style={{
                        height: '480px'
                    }}>
                        <OBTDataGrid
                            interface={this.state.grid}
                        />
                    </div>
                </div>
                {/* TODO : 다국어 */}
                {
                    this.state.showAlertNoColumn ? (
                        <OBTAlert
                            labelText={'최소 1개의 컬럼은 포함해야 합니다.'}
                            type={OBTAlert.Type.warning}
                            onClose={() => {
                                this.setState({
                                    showAlertNoColumn: false
                                })
                            }}
                        />
                    ) : null
                }

            </OBTDialog2>
        )
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        )
    }
}