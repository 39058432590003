import React, { Component } from "react";
import PropTypes from 'prop-types';
import shallowEqual from "recompose/shallowEqual";
import keycode from "keycode";

class UFOMaskField extends Component {
    static propTypes = {
        /**
         * 루트 요소의 클래스명을 지정합니다.
         */
        className: PropTypes.string,
        /**
         * 값이 true 이면, 요소가 비활성화 됩니다.
         */
        disabled: PropTypes.bool,
        /**
         * Input Element에 대한 Type를 정의하는 속성입니다.
         */
        inputType: PropTypes.oneOf(['text', 'password']),
        /**
         * backspace 키 입력을 허용할지 결정하는 함수입니다.
         *
         * @param caretPosition 입력을 받는 위치입니다.
         */
        shouldDisableBackspace: PropTypes.func,
        /**
         * 특정 숫자 키 입력을 허용할지 결정하는 함수입니다.
         *
         * @param value 입력을 받기 전의 값입니다.
         * @param keycodeStr 입력을 받은 숫자 값입니다.
         * @param caretPosition 입력을 받는 위치입니다.
         */
        shouldDisableValue: PropTypes.func,
        /**
         * 마스킹 되는 글자를 지정합니다.
         */
        maskChar: PropTypes.string,
        /**
         * 마스킹 되는 글자의 길이를 배열로 지정합니다.
         */
        maskCharLengthArray: PropTypes.arrayOf(PropTypes.number),
        /**
         * 마스킹을 구분짓는 글자를 지정합니다.
         */
        maskDivisionChar: PropTypes.string,
        /**
         * 요소에 초점(Focus)이 사라질 때 발생하는 Callback 함수입니다.
         *
         * @param {object} event 요소에서 발생한 이벤트 객체입니다.
         */
        onBlur: PropTypes.func,
        /**
         * 요소의 값이 바뀔 때 발생하는 Callback 함수입니다.
         *
         * @param {object} event 요소에서 발생한 이벤트 객체입니다.
         * @param {string} value 요소의 변경된 값입니다.
         */
        onChange: PropTypes.func,
        /**
         * 요소에 Click 이벤트 동작이 있을 때 발생하는 Callback 함수입니다.
         *
         * @param {object} event 요소에 발생하는 `click` 이벤트입니다.
         */
        onClick: PropTypes.func,
        /**
         * 요소에 Key Down 이벤트 동작이 있을 때 발생하는 Callback 함수입니다.
         *
         * @param {object} event 요소에 발생하는 `keydown` 이벤트입니다.
         */
        onKeyDown: PropTypes.func,
        /**
         * 요소에서 포커스가 이동되어야 할 시점에 발생하는 Callback 함수입니다.
         *
         * @param {string} move 포커스가 이동되어야 하는 방향입니다.
         */
        onMoveFocus: PropTypes.func,
        /**
         * 루트 요소의 inline-styles 값을 오버라이드 합니다.
         */
        style: PropTypes.object,
        /**
         * 요소의 값을 지정합니다.
         */
        value: PropTypes.string,
        /**
         * 월의 시작일 또는 종료일을 자동완성합니다.(기본: 'default', 시작일: 'first', 종료일: 'last')
         */
        autoCompleteDay: PropTypes.string,
        /**
         * 요소에 숫자 값만 입력 받도록 설정합니다.(기본: 'true')
         */
        onlyNumber: PropTypes.bool,
    };
    //OBT 수정 maskDivisionChar: '-' -> maskDivisionChar: '-'
    static defaultProps = {
        className: 'LS_ngh_input',
        inputType: 'text',
        shouldDisableBackspace: () => { },
        shouldDisableValue: () => { },
        maskChar: '_',
        maskCharLengthArray: [],
        maskDivisionChar: '-',
        value: '',
        autoCompleteDay: 'default',  // ['first', 'last', 'default']
        onlyNumber: true,
    };

    //OBT 수정 ref = createRef 로 변경
    myRefs = {
        input: React.createRef()
    }

    // region Init

    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
            maskValue: this.getMaskValue(props, props.value),
            maxLength: this.getMaxLength(props),
            maskDivisionStartIndexArray: this.getMaskDivisionStartIndexArray(props),
            maskDivisionEndIndexArray: this.getMaskDivisionEndIndexArray(props),
            isFocused: false,
            valueLength: this.getMaxLength(props) - props.maskCharLengthArray.length + 1,
            maskCharLengthArray: props.maskCharLengthArray,
        };
        this.keyCode = null; // 입력키 temp변수
        this._handleUpdateCallaback = () => { } // 커스텀 콜백함수
        // this.maskCharLengthArray;
    }

    componentDidMount() {
        // this.focus();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            const regNumber = /^[0-9/_]*$/;
            if (!regNumber.test(nextProps.value)) this.maskCharLengthArray = [13];
            else this.maskCharLengthArray = nextProps.maskCharLengthArray;

            this.setState({
                value: nextProps.value,
                maskValue: this.getMaskValue(nextProps, nextProps.value),
                maxLength: this.getMaxLength(nextProps),
                maskDivisionStartIndexArray: this.getMaskDivisionStartIndexArray(nextProps),
                maskDivisionEndIndexArray: this.getMaskDivisionEndIndexArray(nextProps),
                // valueLength: this.getMaxLength(nextProps) - nextProps.maskCharLengthArray.length + 1,
                valueLength: this.getMaxLength(nextProps) - this.maskCharLengthArray.length + 1,
            }, () => {
                // 키보드 입력시에만 일 자동입력처리
                this._handleUpdateCallaback();
            });

            this.focus();
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        return !shallowEqual(this.props, nextProps) || !shallowEqual(this.state, nextState);
    }

    // endregion

    // region get/set Func

    /**
     * 입력 가능한 최대 글자수를 얻는 함수입니다.
     */
    getMaxLength(props) {
        const maskCharLengthArray = this.maskCharLengthArray || props.maskCharLengthArray;
        let maxLength = maskCharLengthArray.length - 1;
        maskCharLengthArray.forEach((length) => {
            maxLength += length;
        });

        return maxLength;
    }

    /**
     * 값에 들어간 마스크를 제외한 스트링을 반환합니다.
     */
    getNoMaskValue(value) {
        let { maskChar } = this.props;
        let pureValue = '';

        for (let i = 0; i < value.length; i++) {
            if (value[i] === maskChar) continue;
            pureValue += value[i];
        }

        return pureValue;
    }

    /**
     * 값에 들어간 마스크를 구분하는 값을 제외한 스트링을 반환합니다.
     */
    getNoMaskDivisionValue(value) {
        let { maskDivisionChar } = this.props;
        let pureValue = '';

        for (let i = 0; i < value.length; i++) {
            if (value[i] === maskDivisionChar) continue;
            pureValue += value[i];
        }

        return pureValue;
    }

    /**
     * mask 된 값을 얻는 함수입니다.
     */
    getMaskValue(props, value) {
        let { maskChar, maskDivisionChar } = props;
        let maskValue = '';
        let charIndex = 0;
        const maskCharLengthArray = this.maskCharLengthArray || props.maskCharLengthArray;

        maskCharLengthArray.forEach((length) => {
            for (let i = 0; i < length; i++) {
                if (value && value[charIndex]) {
                    // 숫자만인지 체크하는 정규식
                    // const regNumber = /^[0-9]*$/;
                    // if ( !regNumber.test(value[charIndex]) ) continue;
                    maskValue += value[charIndex];
                } else {
                    maskValue += maskChar;
                }
                charIndex++;
            }
            maskValue += maskDivisionChar;
        });

        return maskValue ? maskValue.slice(0, - 1) : '';
    }

    /**
     * mask 의 구분 값이 시작되는 위치의 배열을 반환합니다.
     */
    getMaskDivisionStartIndexArray = (props) => {
        // let {maskCharLengthArray} = props;
        let maskDivisionIndexArray = [];
        let index = 0;
        const maskCharLengthArray = this.maskCharLengthArray || props.maskCharLengthArray;

        const regNumber = /[a-zA-Z]/;
        if (regNumber.test(props.value)) maskDivisionIndexArray = []; // a-zA-Z 일 경우 mask 구분 값을 적용하지 않는다.
        else {
            maskCharLengthArray.forEach((length, i) => {
                if (i === maskCharLengthArray.length - 1) return;

                index += length;
                maskDivisionIndexArray.push(index);
                index++;
            });
        }

        return maskDivisionIndexArray;
    }

    /**
     * mask 의 구분 값이 끝나는 위치의 배열을 반환합니다.
     */
    getMaskDivisionEndIndexArray = (props) => {
        // let {maskCharLengthArray} = props;
        let maskDivisionIndexArray = [];
        let index = 0;
        const maskCharLengthArray = this.maskCharLengthArray || props.maskCharLengthArray;

        const regNumber = /[a-zA-Z]/;
        if (regNumber.test(props.value)) maskDivisionIndexArray = []; // a-zA-Z 일 경우 mask 구분 값을 적용하지 않는다.
        else {
            maskCharLengthArray.forEach((length, i) => {
                if (i === maskCharLengthArray.length - 1) return;

                index += length;
                index++;
                maskDivisionIndexArray.push(index);
            });
        }

        return maskDivisionIndexArray;
    }

    /**
     * 커서의 위치를 반환합니다.
     */
    getCaretPosition = () => {
        let targetElement = this.myRefs.input.current;

        if (document.selection) { // IE < 9
            if (targetElement) targetElement.focus();
            let range = document.selection.createRange();
            let rangeLength = range.text.length;
            range.moveStart('character', -targetElement.value.length);

            let start = range.text.length - rangeLength;
            return { 'start': start, 'end': start + rangeLength };
        } else { // IE >= 9 and Other Browsers
            return { 'start': targetElement.selectionStart, 'end': targetElement.selectionEnd };
        }
    };

    /**
     * 월 말일 계산
     */
    _handleAutoCompleteDay = (maskValue, dayType = 'first', displayType = 'default') => {
        /**
            maskValue
            dayType : ['first', 'last']
            displayType : ['mask', 'row', 'default']
        */
        const value = maskValue.split('-')
        // 월입력 시  말일 자동입력 *추가예정
        const year = value[0];
        const month = value[1];
        const lastDay = new Date(Number(year), Number(month), (dayType === 'first' ? 1 : 0))
        const lastDayReturn = lastDay.getDate() < 10 ? `0${lastDay.getDate()}` : lastDay.getDate();

        if (displayType === 'mask') { // yyyy-mm-dd
            return `${year}-${month}-${lastDayReturn}`;

        } else if (displayType === 'raw') { // yyyymmdd
            return `${year}${month}${lastDayReturn}`;

        } else { // default dd
            return lastDayReturn;
        }

    }

    /**
     * 
     */
    cr2 = () => {
        const input = this.myRefs.input.current;
        const inputLength = input.value.length

        if ('selectionStart' in input) {
            input.selectionStart = inputLength - 2;
            input.selectionEnd = inputLength;
            input.focus();
        }
        else {  // Internet Explorer before version 9
            const inputRange = input.createTextRange();
            inputRange.moveStart("character", inputLength - 2);
            inputRange.collapse();
            inputRange.moveEnd("character", inputLength);
            inputRange.select();
            // input.focus ();
        }
        this.keyCode = null
        this._handleUpdateCallaback = () => { }
    }

    /**
     * 커서의 위치를 지정합니다.
     */
    setCaretPosition = (start, end) => {
        let targetElement = this.myRefs.input.current;

        if (targetElement.setSelectionRange) { // IE >= 9 and Other Browsers
            if (targetElement) targetElement.focus();
            targetElement.setSelectionRange(start, end);
        } else if (targetElement.createTextRange) { // IE < 9
            let range = targetElement.createTextRange();
            range.collapse(true);
            range.moveEnd('character', end);
            range.moveStart('character', start);
            range.select();
        }
    };

    /**
     * 입력된 글자에 문자가 포함되어있는지 확인하여 mask 설정을 변경합니다.
     */
    setHasString = (inputValue, keyCode = undefined) => {
        const regNumber = /[a-zA-Z]/;
        const preValue = regNumber.test(inputValue); // true: 문자, false: 숫자
        const keycode = keyCode >= 65 && keyCode <= 90; // true: 문자, false: 숫자

        // input value : 문자 / keyCode : 문자 - 1
        // input value : 문자 / keyCode : 숫자 - 2
        // input value : 숫자 / keyCode : 숫자 - 3
        // input value : 숫자 / keyCode : 문자 - 4

        if (preValue && keycode) {
            return 1;
        } else if (preValue && !keycode) {
            this.maskCharLengthArray = this.props.maskCharLengthArray;
            return 2;
        } else if (!preValue && !keycode) {
            // this.maskCharLengthArray = this.props.maskCharLengthArray;
            return 3;
        } else if (!preValue && keycode) {
            this.maskCharLengthArray = [13];
            return 4;
        }
    }

    /**
     * 커서의 위치를 변경합니다.
     */
    moveCaretPosition = () => {
        // 빈 값이면 커서를 맨 앞으로 이동
        if (!this.state.value) {
            this.setCaretPosition(0, 0);
            return;
        }

        // 빈 값이 아니면 커서를 값이 있는 위치의 마지막으로 이동
        // let {maskCharLengthArray} = this.props;
        let { maskDivisionStartIndexArray } = this.state;
        const maskCharLengthArray = this.maskCharLengthArray || this.props.maskCharLengthArray;
        let arrayLength = maskCharLengthArray.length;
        let maskLength = 0;
        let valueLength = this.getNoMaskValue(this.state.value).length;
        let caretPosition = 0;

        for (let i = 0; i < arrayLength; i++) {
            maskLength += maskCharLengthArray[i];

            if (maskLength === valueLength) {
                caretPosition = valueLength + i + 1;
                break;
            } else if (maskLength > valueLength) {
                if (i === 0) {
                    caretPosition = valueLength;
                } else {
                    if (maskDivisionStartIndexArray.indexOf(valueLength + 1) < 0) {
                        caretPosition = valueLength + i;
                    } else {
                        caretPosition = maskLength + 1;
                    }
                }
                break;
            }

            maskLength++;
        }

        if (maskLength < valueLength) {
            caretPosition = maskLength + maskCharLengthArray[arrayLength - 1];
        }

        this.setCaretPosition(caretPosition, caretPosition);
    }

    // endregion

    // region Events

    focus() {
        if (this.myRefs.input.current) this.myRefs.input.current.focus();
        this.moveCaretPosition();
    }

    handleBlur = (event) => {
        this.setState({ isFocused: false });

        if (this.props.onBlur) this.props.onBlur(event);
    }

    handleChange = (event) => {
        let { maskValue } = this.state;

        if (this.props.onChange) this.props.onChange(event, this.getNoMaskDivisionValue(maskValue));
    }

    handleClick = (event) => {
        // if (this.state.isFocused) return;
        let { maskChar, maskDivisionChar } = this.props;
        let { maskValue } = this.state;

        let caretPosition = this.getCaretPosition();
        if (maskValue[caretPosition.start - 1] === maskChar || maskValue[caretPosition.start - 1] === maskDivisionChar) {
            this.moveCaretPosition();
            return;
        }

        this.setState({ isFocused: true });

        if (this.props.onClick) this.props.onClick(event);
    }

    handleFocus = (event) => {
        if (this.props.onFocus) this.props.onFocus(event);
    }


    handleKeyDown = (event) => {

        let caretPosition = this.getCaretPosition();
        let inputValue = this.myRefs.input.current.value;
        let { maskChar, maskDivisionChar, shouldDisableValue, shouldDisableBackspace, maskCharLengthArray } = this.props;
        let { maskValue, maskDivisionStartIndexArray, maskDivisionEndIndexArray } = this.state;

        if (this.props.onKeyDown) this.props.onKeyDown(event);

        if (!keycode(event)) return;
        this.key = event.key;
        this.keycode = keycode(event);
        switch (keycode(event)) {
            case 'enter':
            case 'down':
            case 'up':
                event.preventDefault();
                this.handleMoveFocus(keycode(event));
                break;
            case 'left':
                // 커서의 시작과 끝이 같을 때
                if (caretPosition.start === caretPosition.end && caretPosition.start === 0) {
                    this.handleMoveFocus(keycode(event));
                }
                // 입력이 안된 곳에서는 방향키 이동이 안되게 막음
                if (maskValue[caretPosition.start - 1] === maskChar) {
                    event.preventDefault();
                }
                break;
            case 'right':
                // 커서의 시작과 끝이 같을 때
                if (caretPosition.start === caretPosition.end && caretPosition.start === maskValue.length) {
                    this.handleMoveFocus(keycode(event));
                }
                // 입력이 안된 곳에서는 방향키 이동이 안되게 막음
                if (maskValue[caretPosition.start] === maskChar) {
                    event.preventDefault();
                }
                break;
            case 'delete':
            case 'backspace':
                event.preventDefault();
                if (caretPosition.start < 0) break;
                if (shouldDisableBackspace(caretPosition)) break; // 전체 지우기 비허용

                const preHasString = this.setHasString(this.getNoMaskDivisionValue(maskValue)); // 입력값 바뀌기 이전 타입

                // 커서의 시작과 끝이 같을 때
                if (caretPosition.start === caretPosition.end) {
                    if (caretPosition.start === 0) break;

                    if (maskDivisionEndIndexArray.indexOf(caretPosition.start) >= 0) {
                        caretPosition.start -= 1;
                        caretPosition.end -= 1;
                    }

                    maskValue = inputValue.slice(0, caretPosition.start - 1) + maskChar + inputValue.slice(caretPosition.end);

                    caretPosition.start -= 1;
                    caretPosition.end -= 1;
                } else { // 커서의 시작과 끝이 다를 때
                    if (caretPosition.start === 0) {
                        maskValue = "";
                    } else {
                        maskValue = inputValue.slice(0, caretPosition.start);
                    }

                    for (let i = caretPosition.start; i < caretPosition.end; i++) {
                        if (maskDivisionStartIndexArray.indexOf(i) >= 0) {
                            maskValue += maskDivisionChar;
                        } else {
                            maskValue += maskChar;
                        }
                    }

                    maskValue += inputValue.slice(caretPosition.end);
                    caretPosition.end = caretPosition.start;
                }

                const nextHasString = this.setHasString(this.getNoMaskDivisionValue(maskValue)); // 입력값 바뀐 이후 타입
                if (preHasString !== nextHasString) { // 입력값의 타입이 바뀌었을 경우
                    if (6 <= caretPosition.start) { // 하이픈(-) 포함 뒤에서 발생
                        caretPosition.start += 1;
                        caretPosition.end += 1;
                    }
                }
                this.setState({
                    caretPosition: caretPosition,
                    // maskValue: maskValue,
                }, () => {
                    this.setCaretPosition(caretPosition.start, caretPosition.end);
                });

                if (this.props.onChange) this.props.onChange(event, this.getNoMaskDivisionValue(maskValue), caretPosition);
                break;
            default:
                // 키보드, 키패드 숫자, 문자가 아닐 때
                if (!(event.keyCode >= 48 && event.keyCode <= 57) &&
                    !(event.keyCode >= 96 && event.keyCode <= 109) &&
                    !(event.keyCode >= 65 && event.keyCode <= 90)) break;

                if ((event.ctrlKey || event.metaKey) && event.keyCode === 86) break; // 붙여넣기

                let keycodeStr = keycode(event).slice(-1);

                const preInputValue = this.getNoMaskDivisionValue(inputValue);
                const hasString = this.setHasString(preInputValue, event.keyCode);

                // 영문자, 숫자만인지 체크하는 정규식
                const regNumber = this.props.onlyNumber ? /^[0-9]*$/ : /^[0-9a-zA-Z]*$/;
                if (!regNumber.test(this.key)) break; // shift 키 판별
                if (!regNumber.test(keycodeStr)) break;

                if (caretPosition.start >= maskValue.length) break;
                if (shouldDisableValue(this.getNoMaskDivisionValue(maskValue), keycodeStr, caretPosition)) break;

                // 커서의 시작과 끝이 같을 때
                if (caretPosition.start === caretPosition.end) {
                    if (hasString === 4) { // 숫자에서 문자 입력 시 동작
                        // 하이픈 아주 앞에서 발생 -> 글자 붙이기 / 커서 +1
                        // 하이픈 바로 앞에서 발생 -> 커서 +1 / 글자 붙이기
                        // 하이픈 바로 뒤에서 발생 -> 글자 붙이기 / 커서 +0
                        // 하이픈 아주 뒤에서 발생 -> 글자 붙이기 / 커서 +0
                        if (maskDivisionStartIndexArray.indexOf(caretPosition.start) >= 0) { // 하이픈(-) 바로 앞에서 입력 발생 시
                            caretPosition.start += 1;
                            caretPosition.end += 1;
                            maskValue = inputValue.slice(0, caretPosition.start) + this.key + inputValue.slice(caretPosition.end + 1);
                        } else {
                            if (maskDivisionStartIndexArray[0] > caretPosition.start) { // 하이픈(-) 아주 앞에서 발생
                                maskValue = inputValue.slice(0, caretPosition.start) + this.key + inputValue.slice(caretPosition.end + 1);
                                caretPosition.start += 1;
                                caretPosition.end += 1;
                            } else if (maskDivisionStartIndexArray[0] < caretPosition.start) { // 하이픈(-) 뒤에서 발생
                                maskValue = inputValue.slice(0, caretPosition.start) + this.key + inputValue.slice(caretPosition.end + 1);
                            } else { // 맨 앞에서 발생
                                maskValue = inputValue.slice(0, caretPosition.start) + this.key + inputValue.slice(caretPosition.end + 1);
                                caretPosition.start += 1;
                                caretPosition.end += 1;
                            }
                        }
                    } else if (hasString === 2) { // 문자에서 숫자 입력 시 동작
                        maskValue = inputValue.slice(0, caretPosition.start) + this.key + inputValue.slice(caretPosition.end + 1);

                        caretPosition.start += 1;
                        caretPosition.end += 1;

                        if (maskCharLengthArray[0] <= caretPosition.start) { // 하이픈(-) 뒤에서 입력 발생 시
                            const regNumber = /^[0-9]*$/;
                            if (regNumber.test(this.getNoMaskDivisionValue(maskValue))) {
                                caretPosition.start += 1;
                                caretPosition.end += 1;
                            }
                        }
                    } else {
                        if (maskDivisionStartIndexArray.indexOf(caretPosition.start) >= 0) { // 하이픈(-) 바로 앞에서 입력 발생 시
                            caretPosition.start += 1;
                            caretPosition.end += 1;
                        }

                        maskValue = inputValue.slice(0, caretPosition.start) + this.key + inputValue.slice(caretPosition.end + 1);

                        caretPosition.start += 1;
                        caretPosition.end += 1;

                        if (maskDivisionStartIndexArray.indexOf(caretPosition.start) >= 0) { // 하이픈(-) 바로 앞에서 입력 발생 시
                            caretPosition.start += 1;
                            caretPosition.end += 1;
                        }
                    }
                } else { // 커서의 시작과 끝이 다를 때 -> 드래그해서 값을 바꾸는 경우
                    if (hasString === 4) { // 숫자에서 문자로 바뀔 때
                        if (caretPosition.start === 0) {
                            maskValue = this.key;
                        } else {
                            maskValue = inputValue.slice(0, caretPosition.start) + this.key;
                        }

                        caretPosition.start += 1;

                        if (maskDivisionStartIndexArray[0] >= caretPosition.start) { // 하이픈 이전
                            if (maskDivisionStartIndexArray[0] < caretPosition.end) {
                                for (let i = caretPosition.start; i < caretPosition.end - 1; i++) {
                                    maskValue += maskChar;
                                }
                            } else {
                                for (let i = caretPosition.start; i < caretPosition.end; i++) {
                                    maskValue += maskChar;
                                }
                            }

                            maskValue += inputValue.slice(caretPosition.end);
                            caretPosition.end = caretPosition.start;
                        } else { // 하이픈 이후
                            for (let i = caretPosition.start; i < caretPosition.end; i++) {
                                maskValue += maskChar;
                            }

                            caretPosition.start -= 1;
                            maskValue += inputValue.slice(caretPosition.end);
                            if (hasString === 2 && maskDivisionStartIndexArray[0] < caretPosition.start) {
                                // caretPosition.start -= 1;
                            }
                            caretPosition.end = caretPosition.start;
                        }
                    } else {
                        if (caretPosition.start === 0) {
                            maskValue = keycodeStr;
                        } else {
                            maskValue = inputValue.slice(0, caretPosition.start) + keycodeStr;
                        }

                        caretPosition.start += 1;

                        for (let i = caretPosition.start; i < caretPosition.end; i++) {
                            if (maskDivisionStartIndexArray.indexOf(i) >= 0) {
                                maskValue += maskDivisionChar;
                            } else {
                                maskValue += maskChar;
                            }
                        }

                        maskValue += inputValue.slice(caretPosition.end);
                        if (hasString === 2) { // 문자에서 숫자로 바뀔 때
                            if (maskCharLengthArray[0] < caretPosition.start && maskCharLengthArray[0] < caretPosition.end) {
                                caretPosition.start += 1;
                            }
                        }
                        caretPosition.end = caretPosition.start;
                    }
                }



                let newMaskValue = maskValue;
                if (
                    this.props.autoCompleteDay !== 'default'
                    && caretPosition.start === 8
                    && keycode(event) !== 'backspace'
                    && keycode(event) !== 'delete'
                ) {
                    newMaskValue = this._handleAutoCompleteDay(maskValue, this.props.autoCompleteDay, 'mask');
                    this._handleUpdateCallaback = () => setTimeout(this.cr2, 0)
                }

                this.setState({
                    caretPosition: caretPosition,
                    // maskValue: maskValue,
                }, () => {
                    this.setCaretPosition(caretPosition.start, caretPosition.end);
                });

                if (this.props.onChange) {
                    this.props.onChange(event, this.getNoMaskDivisionValue(newMaskValue), caretPosition);
                }
        }
    }

    handleMoveFocus = (move = 'right') => {
        if (this.props.onMoveFocus) this.props.onMoveFocus(move);
    }

    // endregion

    render() {
        const {
            className,
            disabled,
            inputType,
            shouldDisableBackspace,
            shouldDisableValue,
            maskChar,
            maskCharLengthArray,
            maskDivisionChar,
            onMoveFocus,
            onFocus,
            onBlur,
            onChange,
            onClick,
            onKeyDown,
            style,
            value,
            ...other
        } = this.props;

        const inputProps = {
            onBlur: this.handleBlur,
            onChange: this.handleChange,
            onClick: this.handleClick,
            onFocus: this.handleFocus,
            onKeyDown: this.handleKeyDown,
            ...other,
        };

        // console.log("value : ", value);
        // console.log("this.state.maskValue : ", this.state.maskValue);

        let inputPropsFiltered = Object.keys(inputProps).reduce((accu, curr) => {
            if (curr === 'autoCompleteDay') return accu;
            if (curr === 'onlyNumber') return accu;
            accu[curr] = inputProps[curr];
            return accu;
        }, {})

        return (
            <input
                ref={this.myRefs.input}
                type={inputType}
                className='LSinput'
                value={this.state.maskValue}
                disabled={disabled}
                maxLength={this.state.maxLength}
                autoComplete='pdsde'
                style={style}
                {...inputPropsFiltered}
            />
        );
    }
}

export default UFOMaskField;
