import React, { Component } from 'react';
import { Link } from "react-router-dom";
import '../../www/css/common.scss';
import '../../www/css/animate.scss';
import '../../www/css/orbit.scss';
import '../../www/css/orbitMenu.scss';
import MainIntroBg from '../orbitUnit/OrbitBg';
import MainIntroMenu from '../orbitUnit/OrbitMenu';

class OrbitHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }
    render() {
        return (
            <>
                <div className="headerWrap">
                    {/* 로고 */}
                    <Link to="/"><div className="textLogo">Luna-Orbit<b>Playground</b></div></Link>
                    {/* 링크메뉴 */}
                    <div className="textLink">
                        <Link to="/Documents/UpdateDocuments" className="link" style={{ textDecoration: 'none', color: '#ad8afd' }} >Update History</Link>
                        <span className="apart">/</span>
                        <span className="link" onClick={() => {
                            window.open('http://wiki.duzon.com:8080/pages/viewpage.action?pageId=40726978', 'blank');
                        }}>WIKI<b>(문의/요청)</b></span>
                    </div>

                    {/* 배경 */}
                    <MainIntroBg />
                    {/* 메뉴 */}
                    <MainIntroMenu handleViewType={this.props.handleViewType} />
                </div>
            </>
        );
    }
}

export default OrbitHeader

