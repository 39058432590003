/**
 * @version 0.1
 * @author 하성준
 * @see common.js
 */
import * as React from 'react';
import { Events, CompositeProps, Util, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import OBTFloatingPanel, { PositionType, AlignType } from '../OBTFloatingPanel/OBTFloatingPanel';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
const styles = require('./OBTTooltip.module.scss');

export interface IOBTTooltip extends CompositeProps.Default, Events.onFocus, Events.onBlur, Events.onActivate, Events.onDeactivate, Events.onClick,
    Events.onDblClick, Events.onMouseDown, Events.onMouseMove, Events.onMouseUp, Events.onMouseLeave, Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress,
    Events.onKeyUp, Events.onMoveFocus {

    /**
     *  값이 true 이면, tooltip이 띄워집니다. 기본으로 부모 요소에 마우스 오버했을 때나 포커스가 잡혔을 때 tooltip이 열리게 됩니다.
     */
    value?: boolean,

    /**
     * tooltip에 들어갈 내용 입니다.
     */
    labelText?: any | string,

    /**
     * @default top
     * 컴포넌트를 띄울 위치를 지정합니다.
     */
    position?: PositionType,

    /**
     * 컴포넌트의 정렬상태를 정합니다.
     */
    align?: AlignType

    /**
     * @default default
     * 컴포넌트의 색에 대한 테마를 지정합니다.
     */
    theme?: TooltipTheme,

    /**
     *  컴포넌트에 focus 되었을 때 tooltip 표시의 여부
     */
    focusValue?: boolean,

    style?: any,
    overrideSize: boolean,
    rootProps?: any
}

interface State extends hasError {
    value?: boolean,
    mouseEnter: boolean,
    focused: boolean,
    transition: boolean,
    position: PositionType,
}

export enum TooltipTheme {
    'blue' = 'blue',
    'black' = 'black',
    'red' = 'red',
    'orange' = 'orange',
    'green' = 'green',
    'default' = 'default',
    'required' = 'required'
}

export default class OBTTooltip extends React.Component<IOBTTooltip, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        CommonDefinitions.value({ type: CommonType.boolean, optional: true, description: '값이 true 이면, tooltip이 띄워집니다. 기본으로 부모 요소에 마우스 오버했을 때나 포커스가 잡혔을 때 tooltip이 열리게 됩니다.' }),
        { name: "labelText", type: ['string', 'node'], description: "라벨에 표시될 문구를 지정합니다." },
        { name: "theme", type: toEnumType(TooltipTheme), description: "툴팁의 배경색에 대한 테마를 지정합니다", optional: true, default: "default" },
        { name: "position", type: toEnumType(PositionType), description: "컴포넌트를 띄울 위치를 지정합니다", optional: true, default: "top" },
        { name: "align", type: toEnumType(AlignType), description: "컴포넌트의 정렬 상태를 지정합니다. (position 내에서 anchor 와 툴팁의 정렬을 지정)", optional: true, default: "center" },
        { name: "focusValue", type: CommonType.boolean, description: "컴포넌트에 focus 됐을 때 tooltip을 유지 시킬지 지정 합니다", optional: true },
        CommonDefinitions.Event(),
        CommonDefinitions.onMoveFocus(),
        CommonDefinitions.onFocus(),
        CommonDefinitions.onBlur(),
        CommonDefinitions.onActivate(),
        CommonDefinitions.onDeactivate(),
        CommonDefinitions.onDblClick(),
    );

    public static defaultProps = {
        frozen: false,
        className: '',
        position: PositionType.top,
        theme: TooltipTheme.default,
        overrideSize: true
    }

    state: State = {
        mouseEnter: false,
        focused: false,
        transition: false,
        position: this.props.position ? this.props.position : PositionType.top,
        hasError: false,
    }

    public static Position = PositionType;
    public static Align = AlignType;
    public static Theme = TooltipTheme;

    public myRefs = {
        root: React.createRef<HTMLDivElement>(),
        floatingPanel: React.createRef<OBTFloatingPanel>()
    }

    static getDerivedStateFromProps(nextProps: IOBTTooltip, prevState: State): any {
        try {
            if (prevState.transition === false && nextProps.value !== prevState.value) {
                if (prevState.position !== nextProps.position) {
                    return {
                        transition: true,
                        position: nextProps.position
                    }
                } else {
                    return {
                        transition: true
                    }
                }
            }

            return null;
        } catch (e) {
            return Util.getErrorState(e);
        }
    }

    componentDidMount() {
        try {
            if (this.state.transition === true && this.props.value !== this.state.value) {
                this.setState({
                    value: this.props.value,
                    transition: this.props.value === false ? false : this.state.transition
                });
            }
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    componentDidUpdate() {
        try {
            if (this.state.transition === true) {
                if (this.props.value !== this.state.value) {
                    this.setState({
                        value: this.props.value
                    });
                } else {
                    // this.setState({
                    //     transition: false
                    // })
                }
            }
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    private autoPosition = () => {
        setTimeout(() => {
            const floatingPanelValue = this.state.transition || (this.state.value === undefined ? this.state.mouseEnter || this.state.focused : this.state.value);
            if (floatingPanelValue === true && (this.state.mouseEnter || this.state.focused)) {
                if (this.myRefs.floatingPanel.current && this.myRefs.floatingPanel.current.myRefs.root.current) {
                    if (this.state.position !== this.props.position) {
                        this.setState({
                            position: this.props.position ? this.props.position : PositionType.top
                        })
                    }
                    const left = this.myRefs.floatingPanel.current.myRefs.root.current.getBoundingClientRect().left;
                    const top = this.myRefs.floatingPanel.current.myRefs.root.current.getBoundingClientRect().top;
                    const right = this.myRefs.floatingPanel.current.myRefs.root.current.getBoundingClientRect().right;
                    const bottom = this.myRefs.floatingPanel.current.myRefs.root.current.getBoundingClientRect().bottom;
                    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
                    const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

                    if (this.props.position === PositionType.top || this.props.position === PositionType.bottom) {
                        if (this.props.position === PositionType.top && this.state.position !== PositionType.bottom && top < 0) {
                            this.setState({
                                position: PositionType.bottom
                            })
                        } else if (this.props.position === PositionType.bottom && this.state.position !== PositionType.top && bottom > vh) {
                            this.setState({
                                position: PositionType.top
                            })
                        }
                    } else {
                        if (this.props.position === PositionType.right && this.state.position !== PositionType.left && right > vw) {
                            this.setState({
                                position: PositionType.left
                            })
                        } else if (this.props.position === PositionType.left && this.state.position !== PositionType.right && left < 0) {
                            this.setState({
                                position: PositionType.right
                            })
                        }
                    }
                }
            }
        }, 0);
    }

    private handleMouseEnter = (event: React.MouseEvent) => {
        if (this.props.value === undefined && this.props.labelText) {
            this.setState({
                transition: true
            }, () => {
                this.setState({
                    mouseEnter: true
                })
                this.autoPosition();
            });
        }
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseEnter, new Events.MouseEventArgs(this, event));
    }

    private handleMouseLeave = (event: React.MouseEvent) => {
        if (this.props.value === undefined && this.props.labelText) {
            this.setState({
                transition: true
            }, () => {
                this.setState({
                    mouseEnter: false
                })
            });
        }
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseLeave, new Events.MouseEventArgs(this, event));
    }

    private handleFocus = () => {
        if (this.props.value === undefined && this.props.labelText && this.state.focused === false && this.props.focusValue !== false) {
            this.setState({
                transition: true
            }, () => {
                this.setState({
                    focused: true
                })
                this.autoPosition();
            });
        }

        Util.invokeEvent<Events.EventArgs>(this.props.onFocus, new Events.EventArgs(this));
    }

    private handleBlur = () => {
        setTimeout(() => {
            const isParentEqualsThis = (parent) => {
                if (parent === this.myRefs.root.current) {
                    return true;
                }
                else if (parent.parentElement) {
                    return isParentEqualsThis(parent.parentElement);
                }
                return false;
            }
            if (!isParentEqualsThis(document.activeElement)) {
                if (this.props.value === undefined && this.props.labelText && !Util.containsFocus(this.myRefs.root)) {
                    this.setState({
                        transition: true
                    }, () => {
                        this.setState({
                            focused: false,
                            mouseEnter: false
                        })
                    });
                }
            }
        }, 0);

        Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
    }

    private handleTransitionEnd = () => {
        this.setState({
            transition: false
        });
    }

    private handleClick = (event: React.MouseEvent): void => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onClick, new Events.MouseEventArgs(this, event));
    }

    private handleMouseDown = (event: React.MouseEvent): void => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseDown, new Events.MouseEventArgs(this, event));
    }

    private handleMouseMove = (event: React.MouseEvent): void => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseMove, new Events.MouseEventArgs(this, event));
    }

    private handleMouseUp = (event: React.MouseEvent): void => {
        Util.invokeEvent<Events.MouseEventArgs>(this.props.onMouseUp, new Events.MouseEventArgs(this, event));
    }

    private handleKeyDown = (event: React.KeyboardEvent): void => {
        Util.invokeEvent<Events.KeyEventArgs>(this.props.onKeyDown, new Events.KeyEventArgs(this, event));
    }

    private handleKeyPress = (event: React.KeyboardEvent): void => {
        Util.invokeEvent<Events.KeyEventArgs>(this.props.onKeyPress, new Events.KeyEventArgs(this, event));
    }

    private handleKeyUp = (event: React.KeyboardEvent): void => {
        Util.invokeEvent<Events.KeyEventArgs>(this.props.onKeyUp, new Events.KeyEventArgs(this, event));
    }

    renderComponent = () => {
        const tooltipVisible = this.state.value === undefined ? this.state.mouseEnter || this.state.focused : this.state.value;
        let floatingPanelValue = this.state.transition || (this.state.value === undefined ? this.state.mouseEnter || this.state.focused : this.state.value);
        const rootStyle: any = {};

        // disabled 체크
        let childDisabled = false;
        if (React.Children.count(this.props.children) === 1) {
            const child = React.Children.only(this.props.children);

            if (React.isValidElement(child)) {
                if (child.props['disabled'] !== undefined) {
                    childDisabled = child.props['disabled'];
                    // disabled 이면 플로팅 패널자체를 보이지 않는다.
                    floatingPanelValue = !child.props['disabled'];
                }
            }
        }

        let children = this.props.children;
        if (this.props.overrideSize && React.Children.count(this.props.children) === 1) {
            const child = React.Children.only(this.props.children);
            const newProps: any = {};
            if (React.isValidElement(child)) {
                const width = child.props.width ? child.props.width : child.props.style && child.props.style.width ? child.props.style.width : undefined;
                const height = child.props.height ? child.props.height : child.props.style && child.props.style.height ? child.props.style.height : undefined;
                if (width && width.indexOf('%') >= 0) {
                    if (child.props.width) {
                        newProps.width = '100%';
                    } else if (child.props.style && child.props.style.width) {
                        newProps.style = {
                            ...child.props.style,
                            ...newProps.style,
                            width: '100%'
                        };
                    }
                    rootStyle.width = width;
                }
                if (height && height.indexOf('%') >= 0) {
                    if (child.props.height) {
                        newProps.height = '100%';
                    } else if (child.props.style && child.props.style.height) {
                        newProps.style = {
                            ...child.props.style,
                            ...newProps.style,
                            height: '100%'
                        };
                    }
                    rootStyle.height = height;
                }

                if (Object.keys(newProps).length > 0) {
                    children = React.cloneElement(child, newProps);
                }
            }
        }

        return (
            <div
                id={this.props.id}
                className={Util.getClassNames(styles.root, this.props.className)}
                ref={this.myRefs.root}
                onMouseEnter={childDisabled ? null : this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                onClick={this.handleClick}
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseMove}
                onMouseUp={this.handleMouseUp}
                onKeyDown={this.handleKeyDown}
                onKeyPress={this.handleKeyPress}
                onKeyUp={this.handleKeyUp}
                style={Object.assign(rootStyle, this.props.style)}
                data-orbit-component={'OBTTooltip'}
                {...(this.props.rootProps || {})}>
                {children}
                <OBTFloatingPanel
                    className={styles.tooltipFloatingPanel}
                    ref={this.myRefs.floatingPanel}
                    value={floatingPanelValue}
                    position={this.state.position}
                    align={this.props.align}
                    autoPosition={false}
                    usePortal={false}>
                    <div className={Util.getClassNames(styles.tooltip,
                        styles[this.state.position],
                        this.props.align ? styles[this.props.align] : null,
                        tooltipVisible ? styles.visible : null,
                        this.state.transition ? styles.transition : null,
                        this.props.theme ? styles[this.props.theme] : null)}
                        onTransitionEnd={this.handleTransitionEnd}>
                        {/* 라벨텍스트 콘텐츠 */}
                        <div className={Util.getClassNames(styles.contents, typeof this.props.labelText === 'object' ? '' : styles.maxContent)}>
                            {this.props.labelText}
                        </div>
                        {/* 화살표 */}
                        <div className={styles.arrowWrapper}>
                            <div className={styles.arrow} />
                            <div className={styles.arrowBackground} />
                        </div>
                    </div>
                </OBTFloatingPanel>
            </div>
        )
    }

    render() {
        return <ErrorBoundary owner={this} render={this.renderComponent} />
    }
};
