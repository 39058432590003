import React from 'react';
import { OBTButton } from '..';
import { Util } from '../Common';
import OBTMultiLineTextField from '../OBTMultiLineTextField';
import avartarImage from './Images/avartar.gif';
// import icoSave from './Images/save.png';
import icoAttach from './Images/ico_attach_normal.png';
import icoDelete from './Images/ico_delete_normal.png';
import icoSave from './Images/ico_save_normal.png';
import { ISideBarContent } from './SideBar';
import SideBarContent, { SideBarContentType } from './SideBarContent';
import moment from 'moment';
import OBTConfirm from '../OBTConfirm';
import { IPageContainerFunctions } from './OBTPageContainer';
import { OrbitInternalLangPack } from '../Common/Util';

const styles = require('./SideBarContent.module.scss');

export interface IMemoItem {
    category?: string, // 메뉴 아이디
    coCd?: string, // 회사코드
    memoKey?: string, // 한 로우에 매핑되는 키값
    memoSeq?: number, // 한 로우에 여러 메모가 존재할때 
    memoContent?: string, // 내용
    memoType?: string, // 1 ?
    menuCd?: string, // 메뉴 코드 
    dataDc?: string,
    columnCd?: string, // memo_cd
    insertId?: string,
    insertDt?: string,
    InsertLoginUserName?: string,
    memoState?: 'modified' | 'added' | 'editMode' | null
}

interface ISBCMemo {
    item: ISideBarContent,
    category?: string,
    UpDownloader?: any,
    userInfo?: any,
    menuItem?: any,
    fetch: any,
    pageContainerFunctions: IPageContainerFunctions,
}

interface IState {
    isLoading: boolean,
    memoList: IMemoItem[],
    memo?: string,
    selectedMemo: IMemoItem | null,
    isAttachFilePopupOpen: boolean,
    testShowFileList: boolean,
    // mode: 'add' | 'list' | 'update' | 'item',
    showEmptyCard: boolean,
    showUpdownloader: boolean,
    showAlert: boolean,
    showDeleteConfirm: boolean,
    authKeyMap: any,
    saveButtonDisabled: boolean,
}

export default class SBCMemo extends React.Component<ISBCMemo, IState> {
    private textBox = React.createRef<OBTMultiLineTextField>();
    private upDownloaderRef = React.createRef<any>();
    private _isMounted: boolean = false;

    state: IState = {
        isLoading: false,
        memoList: [],
        selectedMemo: null,
        testShowFileList: true,
        isAttachFilePopupOpen: false,
        showEmptyCard: false,
        showUpdownloader: false,
        showAlert: false,
        showDeleteConfirm: false,
        authKeyMap: { fileKey: "0" },
        saveButtonDisabled: false,
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchMemoList(this.props.item.data['memoKey'], true);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps: ISBCMemo, prevState: IState) {
        if (this.props.item.data['memoKey'] !== prevProps.item.data['memoKey']) {
            if (!this.props.item.data || !this.props.item.data['memoKey']) {
                this.setState({
                    memoList: [],
                    memo: '',
                    selectedMemo: null,
                    showEmptyCard: true,
                    showUpdownloader: false,
                });
            } else {
                this.fetchMemoList(this.props.item.data['memoKey'], true);
            }
        }
    }

    fetchMemoList = async (key: string, changeState: boolean) => {
        const fetchedMemos = await this.getMemoList(key);

        const uniqueLoginIdUserInfo = Array.from(new Set<string | null | undefined>(fetchedMemos.map(item => {
            return item.insertId;
        }))).filter((itemInsertId) => {
            return itemInsertId !== null && itemInsertId !== undefined && itemInsertId.length > 0;
        }).map(async (itemInsertIdNotEmpty) => {
            try {
                const data = await this.getUserInfo((itemInsertIdNotEmpty!));
                return {
                    erpEmpSeq: itemInsertIdNotEmpty,
                    data: data
                };
            } catch (e) {
                throw e;
            }
        });

        const userInfoList = await Promise.all(uniqueLoginIdUserInfo);
        const dataItems = fetchedMemos.map(memo => {
            const userInfo = userInfoList.filter((item_3) => item_3.erpEmpSeq === memo.insertId);
            const insertLoginUserName = userInfo.length > 0 ? userInfo[0].data['data']['empName'] : memo.insertId;

            return {
                ...memo,
                InsertLoginUserName: insertLoginUserName
            } as IMemoItem;
        });

        if (changeState === true) {
            return new Promise<IMemoItem[]>(resolve => {
                if (this._isMounted === true) {
                    this.setState({
                        memo: '',
                        memoList: dataItems,
                        selectedMemo: null,
                        showEmptyCard: dataItems.length === 0 ? true : false
                    }, () => {
                        resolve(dataItems);
                    });
                }
            });
        }
        else {
            return Promise.resolve(dataItems);
        }
    }

    private getMemoList(memoKey?: string): Promise<IMemoItem[]> {
        if (!memoKey ||
            !Util.safeAccessProperty(this.props.menuItem, 'menuCode') ||
            !Util.safeAccessProperty(this.props.userInfo, 'erpUserInfo', 'companyCode')) {
            return Promise.resolve([]);
        }

        return new Promise<IMemoItem[]>((resolve) => {
            return this.props.fetch.post('/system/common/getmemodata',
                {
                    'coCd': this.props.userInfo['erpUserInfo']['companyCode'],
                    'category': this.props.category ? this.props.category : this.props.menuItem['menuCode'],
                    'memoKey': memoKey
                },
                {
                    contextType: "application/json"
                }
            ).then((response: any) => {
                const responseJson = (response);
                if (responseJson && responseJson['resultCode'] === 0) {
                    resolve(responseJson['resultData'])
                }
            }).catch((error) => {
                console.error(error);
            });
        })

    }

    private getUserInfo = (erpEmpSeq: string) => {
        return new Promise<any>((resolve) => {
            return this.props.fetch.post('/gw/gw066A54',
                {
                    'erpEmpSeq': erpEmpSeq
                },
                {
                    contextType: "application/json"
                }
            ).then(response => {
                const responseJson = (response);
                if (responseJson && responseJson['resultCode'] === 0 && responseJson['resultMsg'] === 'SUCCESS') {
                    resolve({
                        erpEmpSeq: erpEmpSeq,
                        data: responseJson['resultData']
                    })
                } else {
                    resolve({
                        erpEmpSeq: erpEmpSeq,
                        data: {
                            data: {
                                empName: erpEmpSeq
                            }
                        }
                    })
                }
            }).catch((error) => {
                console.error(error);
            });
        })
    }

    /**
     * 메모 저장
     * TODO: 기존 메모에 파일추가하는 동작이 처리안됨
     */
    handleClickSave = async () => {
        if (this.state.saveButtonDisabled === true) {
            return;
        }

        if (!this.state.memo || this.state.memo.length === 0) {
            if (this.textBox.current) {
                this.textBox.current.focus();
                return;
            }
        }

        this.setState({
            saveButtonDisabled: true,
        }, async () => {
            try {
                await this.props.pageContainerFunctions.showLoading("저장중입니다.");

                let fileGroupSeq = null;
                if (this.upDownloaderRef.current) {
                    let uploadFileList = await this.upDownloaderRef.current.fnAttFileUpload();
                    for (var index in uploadFileList) {
                        uploadFileList[index].useDownView = 'N'
                    }

                    let fileIds = uploadFileList.map(item => {
                        return {
                            fileId: item.fileId,
                            fileNm: (item.originalFileName + '.' + item.fileExtsn) || ''
                        }
                    });
                    const insertErpResult = await this.props.fetch.post('/system/common/insertSfileH', {
                        insertId: this.props.userInfo['ucUserInfo']['erpEmpSeq'],
                        fileGroup: (0), // this.state.selectedMemo !== null && this.state.selectedMemo.dataDc ? this.state.selectedMemo.dataDc : 0,
                        fileId: fileIds,
                        menuCode: this.props.menuItem['menuCode']
                    }, {
                        contextType: "application/json"
                    });

                    if (insertErpResult && insertErpResult['resultCode'] === 0) {
                        fileGroupSeq = insertErpResult.resultData;
                    }
                }

                if (this.state.showEmptyCard) {
                    const memoItem = await this.addMemo(fileGroupSeq);
                    if (memoItem && memoItem.memoKey) {
                        await this.saveFileList(memoItem.memoKey, memoItem.memoSeq);

                        if (this.props.item.onApply) {
                            this.props.item.onApply(
                                {
                                    type: 'saveMemo',
                                    memoKey: memoItem.memoKey
                                }
                            )
                        }
                        const memoList = await this.fetchMemoList(memoItem.memoKey, true);
                        if (memoList) {
                            const matchedMemos = memoList.filter((item) => item.memoKey === memoItem.memoKey)

                            if (matchedMemos.length > 0) {
                                this.handleClickMemoItem(matchedMemos[0])
                            }
                        }
                    }
                } else if (this.state.selectedMemo && this.state.selectedMemo.memoKey && this.state.selectedMemo.memoState === 'editMode') {
                    await this.updateMemo(this.state.selectedMemo, fileGroupSeq);
                    await this.fetchMemoList(this.state.selectedMemo.memoKey, true);

                    if (this.props.pageContainerFunctions) {
                        this.props.pageContainerFunctions.snackbar({
                            labelText: OrbitInternalLangPack.getText('WE000028501', '메모가 수정되었습니다.'),
                            type: 'success'
                        });
                    }
                }
            } catch (e) {
                if (this.props.pageContainerFunctions) {
                    this.props.pageContainerFunctions.snackbar({
                        labelText: OrbitInternalLangPack.getText('WE000001114', '저장하는 중 에러가 발생하였습니다. 다시 시도해 주십시오'),
                        type: 'error'
                    });
                }
            } finally {
                this.setState({
                    saveButtonDisabled: false
                }, () => {
                    this.props.pageContainerFunctions.hideLoading();
                })
            }
        })
    }

    /**
     * 메모 저장
     */
    addMemo = (fileGroup: any): Promise<any> | null => {
        if (!this.state.memo || this.state.memo.length <= 0) {
            if (this.textBox.current) {
                this.textBox.current.focus();
            }
            return null;
        }

        if (this.state.selectedMemo !== null) {
            return null;
        }

        if (!this.props.menuItem || !this.props.menuItem['menuCode']) {
            return null;
        }

        if (!this.props.userInfo || !this.props.userInfo['erpUserInfo'] || !this.props.userInfo['erpUserInfo']['companyCode']) {
            return null;
        }

        const memoItem = {
            category: this.props.category ? this.props.category : this.props.menuItem['menuCode'],
            menuCd: this.props.menuItem['menuCode'],
            memoContent: this.state.memo,
            coCd: this.props.userInfo['erpUserInfo']['companyCode'],
            memoType: 'M',
            memoKey: this.props.item.data['memoKey'] ?
                this.props.item.data['memoKey'] : Util.getDateString(Util.DateOption.yyyyMMddHHMMSS, new Date()),
            insertId: this.props.userInfo['ucUserInfo']['erpEmpSeq'],
            dataDc: fileGroup
        }

        return this.props.fetch.post('/system/common/addmemodata',
            memoItem,
            {
                contextType: "application/json"
            }
        ).then((response: any) => {
            const responseJson = (response);
            if (responseJson && responseJson['resultCode'] === 0) {

                return new Promise<any>((resolve) => {
                    if (this._isMounted === true) {
                        this.setState({
                            memo: '',
                        }, () => {
                            resolve(responseJson['resultData']);
                        });
                    }
                })
            } else {
                throw new Error('메모 저장 실패');
            }
        }).catch((error: any) => {
            console.error(error);

            throw error;
        });
    }

    updateMemo = (memoItem: IMemoItem, fileGroupSeq: any) => {
        return this.props.fetch.post('/system/common/updatememodata',
            {
                memoContent: this.state.memo,
                memoType: 'M',
                category: memoItem.category,
                coCd: memoItem.coCd,
                memoKey: memoItem.memoKey,
                memoSeq: Number(memoItem.memoSeq),
                insertId: this.props.userInfo['ucUserInfo']['erpEmpSeq'],
                dataDc: fileGroupSeq
            } as IMemoItem,
            {
                contextType: "application/json"
            }
        ).then(response => {
            const responseJson = (response)
            if (responseJson && responseJson['resultCode'] === 0) {
                return Promise.resolve(responseJson['resultData'])
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    // 이벤트 핸들러

    handleClickMemoItem = (memo: IMemoItem) => {
        let memoList = this.state.memoList;
        if (this.state.selectedMemo && this.state.selectedMemo.memoState === 'editMode') {
            memoList = this.state.memoList.map((item) => {
                if (this.state.selectedMemo && item.memoSeq === this.state.selectedMemo.memoSeq) {
                    return {
                        ...item,
                        memoState: null
                    }
                }
                return item;
            })
        }

        this.setState({
            memoList: memoList,
            selectedMemo: memo,
            memo: memo.memoContent,
            showEmptyCard: false,
            showUpdownloader: false
        });
    }

    handleClickDelete = () => {
        this.setState({
            showDeleteConfirm: true
        })
    }

    handleClickAttachFile(memo: IMemoItem) {
        const isShow = !this.state.showUpdownloader;
        this.setState({
            showUpdownloader: isShow
        }, () => {
            if (isShow) {
                this.fetchAndBindFileList(memo);
            }
        })
    }

    processDeleteMemo = async () => {
        if (this.state.selectedMemo === null) {
            if (this.props.pageContainerFunctions) {
                this.props.pageContainerFunctions.snackbar({
                    labelText: OrbitInternalLangPack.getText('WE000028502', '선택한 메모가 없습니다.'),
                    type: 'error'
                })
            }
            return;
        }

        // 등록자 체크
        if (this.state.selectedMemo.insertId !== this.props.userInfo['ucUserInfo']['erpEmpSeq']) {
            if (this.props.pageContainerFunctions) {
                this.props.pageContainerFunctions.snackbar({
                    labelText: OrbitInternalLangPack.getText('WE000005148', '삭제할 권한이 없습니다.'),
                    type: 'error'
                })
            }
            return;
        }

        const response = await this.props.fetch.post('/system/common/deletememodata', {
            coCd: this.state.selectedMemo.coCd,
            category: this.state.selectedMemo.category,
            memoKey: this.state.selectedMemo.memoKey,
            memoSeq: this.state.selectedMemo.memoSeq,
            insertId: this.state.selectedMemo.insertId,
        }, {
            contextType: "application/json"
        });

        if (response && response['resultCode'] === 0) {
            const memoList = await this.fetchMemoList(this.state.selectedMemo.memoKey!, false);

            this.setState({
                memo: '',
                memoList: memoList,
                // mode: 'list',
                selectedMemo: null
            }, () => {
                if (memoList && memoList.length <= 0) {
                    if (this.props.item.onApply) {
                        this.props.item.onApply({
                            type: 'clearMemo'
                        })
                    }
                }
            })
        }
    }

    handleMemoContentDoubleClick(memo: IMemoItem) {
        this.setState({
            selectedMemo: {
                ...memo,
                memoState: "editMode"
            },
            memoList: this.state.memoList.map(item => {
                if (item.memoSeq === memo.memoSeq) {
                    return {
                        ...item,
                        memoState: "editMode"
                    }
                }
                return item;
            })
        }, () => {
            if (this.textBox.current) {
                this.textBox.current.focus();
            }
        });
    }

    formatDate = (value?: string) => {
        if (!value) {
            return null;
        }

        const momentValue = moment(value);
        if (!momentValue.isValid()) {
            return ''
        }

        return momentValue.format('YY.MM.DD');
    }

    /**
     * dataDc -> FileGroup
     * @param memoContents 
     */
    fetchAndBindFileList = async (memoContents: IMemoItem) => {
        if (!memoContents.coCd || !memoContents.memoSeq) {//|| !memoContents.dataDc) {
            return;
        }
        // memoContents.dataDc = '1254';
        const result = await this.props.fetch.post('/system/common/getSelectSfileD',
            { fileGroup: memoContents.dataDc },
            {
                contextType: "application/json"
            }
        );

        if (result && result.resultCode === 0) {
            const fileList = result.resultData;

            if (fileList.length > 0) {
                const fileSnList = fileList.map(item => item.fileSn);
                const fileKey = fileList[0].fileKey;

                this.setState({
                    authKeyMap: { fileKey: fileKey }
                }, () => {
                    let fileUpDownloaderParameters = {
                        fileSnList: [...fileSnList],
                        authKeyMap: JSON.stringify({ fileKey: fileKey }),
                        moduleGbn: 'SYSTEM'
                    }

                    if (this.upDownloaderRef.current) {
                        const uploader = this.upDownloaderRef.current;
                        uploader.getFileInfoList(fileUpDownloaderParameters).then((files) => {
                            uploader.fnFileListBinding(files);
                            return files
                        })
                    }
                });
            }
        }


        // return new Promise<any[]>((resolve) => {
        //     this.props.fetch.post('/system/common/getmemofiledata',
        //         {
        //             'coCd': memoContents.coCd,
        //             'category': memoContents.category,
        //             'memoKey': memoContents.memoKey,
        //             'memoSeq': memoContents.memoSeq
        //         },
        //         {
        //             contextType: "application/json"
        //         }
        //     ).then(response => {
        //         const responseJson = (response);
        //         if (responseJson && responseJson['resultCode'] === 0) {
        //             if (this.upDownloaderRef.current) {

        //                 let params = {
        //                     fileSnList: [],
        //                     authKeyMap: JSON.stringify({ fileKey: "" }),
        //                     moduleGbn: 'FINANCIAL'
        //                 }

        //                 const uploader = this.upDownloaderRef.current;
        //                 uploader.getFileInfoList(params).then((files) => {
        //                     uploader.fnFileListBinding(files);

        //                     resolve(files)
        //                 })
        //             }
        //         }
        //     }).catch((error) => {
        //         console.error(error);
        //     })
        // })
    }

    saveFileList = (memoKey: string, memoSeq: string | number) => {
        if (!this.props.UpDownloader || !this.upDownloaderRef.current) {
            return Promise.resolve();
        }

        return this.upDownloaderRef.current.fnAttFileUpload().then((uidList: string[]) => {
            return this.props.fetch.post('/system/common/setmemofiledata',
                {
                    coCd: this.props.userInfo['erpUserInfo']['companyCode'],
                    category: this.props.category ? this.props.category : this.props.menuItem['menuCode'],//this.props.item.data['menuItem']['menuCode'],
                    memoKey: memoKey,//this.props.item.data['memoKey'],
                    // this.props.item.data['memoKey'] : Util.getDateString(Util.DateOption.yyyyMMddHHMMSS, new Date()),
                    memoSeq: Number(memoSeq),//String(memoSeq),
                    memoFilenames: uidList
                },
                {
                    contextType: "application/json"
                }
            ).then(response => {
                const responseJson = (response)
                if (responseJson && responseJson['resultCode'] === 0) {
                    return Promise.resolve(responseJson['resultData'])
                }
            })
        }).catch((error) => {
            console.error(error);
        });
    }


    private renderMemoItem(memo: IMemoItem, isLastItem: boolean, isEditMode: boolean) {
        const isSelectedMemoWriter = memo.insertId === this.props.userInfo.ucUserInfo.erpEmpSeq

        return (
            <div key={memo.memoSeq}
                style={{
                    width: '100%',
                    border: '1px solid #cccccc',
                    borderRadius: '4px',
                    paddingTop: '10px',
                    paddingBottom: this.state.showUpdownloader ? '0px' : '10px',
                    overflow: 'hidden',
                    boxSizing: 'border-box',
                    marginBottom: isLastItem ? '0px' : '5px'
                }}
            >
                {/* 사용자 정보 */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    alignContent: 'center',
                    marginLeft: '8px',
                    marginRight: '8px',
                    cursor: 'pointer',
                }} onClick={() => {

                }}>
                    {/* 프로필이미지 */}
                    <img src={avartarImage} alt={''}
                        style={{
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            width: '26px',
                            height: '26px',
                            borderRadius: '16px',
                            marginRight: '6px'
                        }}
                    />

                    {/* 이름, 날짜 */}
                    <div style={{
                        color: '#a6a6a6',
                        lineHeight: 1.18,
                        fontSize: '11px',
                        width: '74px'
                    }}>
                        <div>{memo.InsertLoginUserName}</div>
                        <div>{this.formatDate(memo.insertDt)}</div>
                    </div>

                    {/* 아이콘 영역 */}
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        alignItems: 'center',
                        alignContent: 'center',
                    }}>
                        <OBTButton
                            type={OBTButton.Type.icon}
                            imageUrl={{
                                normal: icoAttach
                            }}
                            width={"21px"}
                            onClick={this.handleClickAttachFile.bind(this, memo)}
                        />
                        {
                            this.props.item.readonly === true || this.state.showEmptyCard ? null : (
                                <OBTButton
                                    type={OBTButton.Type.icon}
                                    imageUrl={{
                                        normal: icoDelete
                                    }}
                                    width={"21px"}
                                    onClick={this.handleClickDelete}
                                />
                            )
                        }

                        {
                            this.props.item.readonly !== true && isEditMode ? (
                                <OBTButton
                                    type={OBTButton.Type.icon}
                                    imageUrl={{
                                        normal: icoSave
                                    }}
                                    disabled={this.state.saveButtonDisabled}
                                    width={"21px"}
                                    onClick={this.handleClickSave}
                                />
                            ) : null
                        }

                    </div>
                </div>

                {/* 구분선 */}
                <div style={{
                    borderBottom: '1px solid #cccccc',
                    marginTop: '6px',
                    marginBottom: '8px'
                }}></div>

                {/* 입력부 */}
                <div style={{
                    marginLeft: '8px',
                    marginRight: '8px',
                }}>
                    {
                        isEditMode ? (
                            <OBTMultiLineTextField
                                ref={this.textBox}
                                className={styles.multiLineText}
                                maxLength={200}
                                fixed={true}
                                value={this.state.memo || ''}
                                placeHolder={OrbitInternalLangPack.getText('WE000004018', '메모를 입력하세요.')}
                                onChange={(e) => {
                                    this.setState({
                                        memo: e.value
                                    })
                                }}
                            />
                        ) : (
                            <div onDoubleClick={this.handleMemoContentDoubleClick.bind(this, memo)} style={{
                                padding: '5px 8px'
                            }}>
                                {this.state.memo}
                            </div>
                        )
                    }
                    <div style={{
                        fontSize: '11px',
                        lineHeight: 1.18,
                        color: '#8c8c8c',
                        textAlign: 'right'
                    }}>
                        {`${(this.state.memo || '').length}자 / 200자`}
                    </div>
                </div>
                {/* 업다운로더 */}
                {
                    this.state.showUpdownloader && this.props.UpDownloader && (
                        <div style={{
                            marginTop: '8px'
                        }}>
                            <this.props.UpDownloader
                                ref={this.upDownloaderRef}
                                // ref={upDownloaderRef => (this.upDownloaderRef = upDownloaderRef)}
                                pathSeq={'2200'}
                                groupSeq={this.props['userInfo']['ucUserInfo']['groupSeq']}//{'klagoDev'}
                                // uploadMode={isEditMode ? 'U' : 'D'}
                                uploadMode={isEditMode && isSelectedMemoWriter ? 'U' : 'D'}
                                type={'M'}
                                moduleGbn={'SYSTEM'}
                                authKeyMap={this.state.authKeyMap}
                                useFileInfo={'Y'}
                            // pathSeq='2200'
                            // groupSeq='klagoDev'
                            // uploadMode='U'
                            // type='M'
                            // moduleGbn='COMMENT'
                            // authKeyMap={{"moduleSeq":"1435","commentSeq":"0000000003912000","moduleGbn":"report"}}
                            // useFileInfo='Y'
                            />
                        </div>
                    )
                }
            </div >
        )
    }

    renderListItem(memo: IMemoItem, isLastItem: boolean) {
        return (
            <div
                key={memo.memoSeq}
                style={{
                    width: '100%',
                    height: '38px',
                    border: '1px solid #cccccc',
                    borderRadius: '4px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    overflow: 'hidden',
                    boxSizing: 'border-box',
                    cursor: 'pointer',
                    marginBottom: isLastItem ? '0px' : '5px',
                }}
                onClick={() => {
                    this.handleClickMemoItem(memo);
                }}
            >
                <div style={{
                    display: 'inline-block',
                    width: '50%',
                    fontSize: '12px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    lineHeight: 1.08
                }}>
                    {memo.memoContent}

                </div>
                <div style={{
                    display: 'inline-block',
                    width: '50%',
                    color: '#a6a6a6',
                    fontSize: '11px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}>
                    <div style={{
                        display: 'inline',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        lineHeight: 1.08
                    }}>
                        {memo.InsertLoginUserName}
                    </div>

                    <div style={{
                        display: 'inline',
                        marginLeft: '2px',
                        marginRight: '2px',
                    }}>
                        <div style={{
                            display: 'inline-block',
                            height: '8px',
                            borderRight: '1px solid #cccccc',
                        }}>
                        </div>
                    </div>

                    <div style={{
                        display: 'inline',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        lineHeight: 1.08
                    }}>
                        {this.formatDate(memo.insertDt)}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <SideBarContent
                item={Object.assign({}, this.props.item, {
                    title: OrbitInternalLangPack.getText('WE000000087', '메모'),
                    type: SideBarContentType.accordion,

                })}
                onClickAddButton={this.props.item.readonly === true ? undefined : () => {
                    this.setState({
                        showEmptyCard: true,
                        selectedMemo: null,
                        showUpdownloader: false,
                        memo: '',
                    }, () => {
                        if (this.textBox.current) {
                            this.textBox.current.focus();
                        }
                    })
                }}
            >
                <div className={styles.memo}
                    style={{
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        paddingLeft: '5px',
                        paddingRight: '5px'
                    }}>
                    <div>
                        {
                            this.props.item.readonly !== true && this.state.showEmptyCard ? (
                                this.renderMemoItem({
                                    category: this.props.category ? this.props.category : this.props.menuItem['menuCode'],
                                    menuCd: this.props.menuItem['menuCode'],
                                    memoContent: this.state.memo,
                                    coCd: this.props.userInfo['erpUserInfo']['companyCode'],
                                    memoType: 'M',
                                    memoKey: this.props.item.data['memoKey'] ?
                                        this.props.item.data['memoKey'] : Util.getDateString(Util.DateOption.yyyyMMddHHMMSS, new Date()),
                                    insertId: this.props.userInfo['ucUserInfo']['erpEmpSeq'],
                                    InsertLoginUserName: this.props.userInfo['ucUserInfo']['empName'],
                                    memoState: 'added',
                                }, false, true)
                            ) : null
                        }

                        {
                            this.props.item.readonly === true && this.state.memoList && this.state.memoList.length === 0 ? (
                                <div>
                                    {OrbitInternalLangPack.getText('WE000028506', '등록된 메모가 없습니다.')}
                                </div>
                            ) : null
                        }

                        {
                            this.state.memoList.map((memo, index) => {
                                return this.state.selectedMemo && this.state.selectedMemo.memoSeq === memo.memoSeq ? (
                                    this.renderMemoItem(memo,
                                        this.state.memoList.length === index + 1,
                                        memo.memoState === "editMode"
                                    )
                                ) : (
                                    this.renderListItem(
                                        memo,
                                        this.state.memoList.length === index + 1
                                    )

                                )
                            })
                        }
                    </div>
                </div>
                {
                    this.state.showDeleteConfirm && (
                        <OBTConfirm title={OrbitInternalLangPack.getText('WE000026229', '등록된 메모를 삭제하시겠습니까?')}
                            type={OBTConfirm.Type.question}
                            cancelText={OrbitInternalLangPack.getText('WE000001945', '취소')}
                            confirmText={OrbitInternalLangPack.getText('WE000000054', '확인')}
                            onCancel={() => {
                                this.setState({
                                    showDeleteConfirm: false
                                })
                            }}
                            // onConfirm={() => {
                            //     this.setState({
                            //         showDeleteConfirm: false
                            //     }, async () => {
                            //         if (this.props.pageContainerFunctions) {
                            //             try {
                            //                 await this.props.pageContainerFunctions.showLoading(OrbitInternalLangPack.getText('WE000026849', '삭제중입니다.'));
                            //                 await this.processDeleteMemo();

                            //                 await this.props.pageContainerFunctions.snackbar({
                            //                     labelText: OrbitInternalLangPack.getText('WE000028505', '메모가 삭제되었습니다.'),
                            //                     type: 'info'
                            //                 })
                            //             } catch (e) {
                            //                 await this.props.pageContainerFunctions.snackbar({
                            //                     labelText: OrbitInternalLangPack.getText('WE000010411', '삭제 중 오류가 발생했습니다'),
                            //                     type: 'error'
                            //                 })
                            //             } finally {
                            //                 await this.props.pageContainerFunctions.hideLoading();
                            //             }
                            //         }
                            //     })
                            // }}
                            onConfirm={() => {
                                this.setState({
                                    showDeleteConfirm: false
                                }, () => {
                                    if (this.props.pageContainerFunctions) {
                                        this.props.pageContainerFunctions.showLoading(OrbitInternalLangPack.getText('WE000026849', '삭제중입니다.')).then(() => {
                                            this.processDeleteMemo().then(() => {

                                                this.props.pageContainerFunctions.snackbar({
                                                    labelText: OrbitInternalLangPack.getText('WE000028505', '메모가 삭제되었습니다.'),
                                                    type: 'info'
                                                });

                                            }).finally(() => {
                                                this.props.pageContainerFunctions.hideLoading();
                                            })
                                        });
                                    }
                                })
                            }}
                        ></OBTConfirm>
                    )
                }

            </SideBarContent>
        );
    }
}
