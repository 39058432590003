import React, { Component } from 'react';
import PropTypes from 'prop-types';
import keycode from "keycode";
import LUXButton from 'luna-rocket/LUXButton';
import { OrbitInternalLangPack } from '../../Common/Util';

const styles = {
    buttons: {
        float: 'right',
        zoom: 1,
    },
    button: {
        // background: '#fff',
        border: '1px solid #e0e0e0',
        boxSizing: 'border-box',
        color: '#4a4a4a',
        display: 'inline-block',
        fontSize: '11px',
        fontFamily: '돋움, Dotum, Helvetica, Apple SD Gothic Neo, sans-serif',
        height: '21px',
        margin: '-1px 0 0 -1px',
        outline: 'none',
        padding: '1px 6px 0',
        position: 'relative',
        textAlign: 'center',
        verticalAlign: 'top',
    },
    period: {
        display: 'inline-block',
        zoom: 1,
        fontSize: '12px',
        fontFamily: '돋움, Dotum, Helvetica, Apple SD Gothic Neo, sans-serif',
        color: '#999',
        padding: 0,
        margin: 0,
    },
    root: {
        clear: 'both',
        padding: '10px',
        borderTop: '1px solid #e6e6e6',
        whiteSpace: 'nowrap',
        outline: 'none',
    },
    span: {
        margin: '0 3px 0 0',
    }
};

class CalendarActionButton extends Component {
    static propTypes = {
        cancelLabel: PropTypes.string,
        okLabel: PropTypes.string,
        onTouchTapCancel: PropTypes.func,
        onTouchTapOk: PropTypes.func,
        periodDays: PropTypes.number,
        customLabel: PropTypes.func,
        onResetFrom: PropTypes.func,
        onResetTo: PropTypes.func,
        available: PropTypes.bool
    };

    myRefs = {
        okButton: React.createRef(),
    }

    constructor(props) {
        super(props);

        this.state = { focus: false };
    }

    focusOkButton = () => {
        if (this.myRefs.okButton.current) this.myRefs.okButton.current.focus();
        this.setState({ focus: true });
    }

    handleBlur = () => {
        if (this.myRefs.okButton.current) this.myRefs.okButton.current.blur();
        this.setState({ focus: false });
    }

    handleKeyDown = (event) => {
        event.stopPropagation();

        switch (keycode(event)) {
            case 'backspace':
                event.preventDefault();
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <div style={styles.root} tabIndex="0" onKeyDown={this.handleKeyDown}>
                <div style={styles.buttons}>
                    <LUXButton
                        label={this.props.cancelLabel}
                        onTouchTap={this.props.onTouchTapCancel}
                        style={styles.button}
                    />
                    <LUXButton
                        ref={this.myRefs.okButton}
                        label={this.props.okLabel}
                        onBlur={this.handleBlur}
                        onTouchTap={this.props.onTouchTapOk}
                        keyboardFocused={this.state.focus}
                        style={Object.assign({}, styles.button, { marginLeft: '3px' })}
                        disabled={!this.props.available}
                    />

                </div>
                {this.props.customLabel ? this.props.customLabel(this.props.selectedDateFrom, this.props.selectedDateTo, this.props.onResetFrom, this.props.onResetTo) :
                    <p style={styles.period}>
                        <span style={styles.span}>{OrbitInternalLangPack.getText('option0211|d', '선택기간')}:</span>&nbsp;
                        <span style={styles.span}>{this.props.periodDays}{OrbitInternalLangPack.getText('WE000000255', '일')}</span>
                    </p>}

            </div>
        );
    }
}

export default CalendarActionButton;
