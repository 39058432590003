import React from 'react';
import { Util } from '../Common';
import OBTScrollbar from '../OBTScrollbar';

interface IInformtaionBar {
    isOpen?: boolean,
}

interface IState {
    renderCallBack?: (data?: any) => JSX.Element,
    renderData?:any
}

const styles = require('./OBTPageContainer.module.scss');

class informationSideBar extends React.Component<IInformtaionBar, IState> {
    public state: IState = {
        renderCallBack: () => <></>,
        renderData: []
    }

    public setInformationSideBarRender(callback:(data?:any) => JSX.Element) {
        this.setState({
            renderCallBack: callback
        })
    }
    
    public setInformationSideBarData(data: any) {
        this.setState({
            renderData: data
        })
    }

    render() {
        return (
            <>
                <div className={Util.getClassNames(
                    styles.informationBarWrapper,
                    !this.props.isOpen ? styles.collapsed : null)}
                >
                    <OBTScrollbar className={styles.informationBarContents} >
                        <div>
                            {this.state.renderCallBack && this.state.renderCallBack(this.state.renderData)}
                        </div>
                    </OBTScrollbar>
                </div>
            </>
        );
    }
}
export default informationSideBar;

