import React, { Component } from "react";
import LUXButton from "luna-rocket/LUXButton";
import { ACTION_TYPE } from "../enums";
import { OrbitInternalLangPack } from '../../../Common/Util';

class ListBottom extends Component {
  /**
   * 취소 버튼 클릭시 현재 팝오버를 닫습니다.
   */
  handleCancel = (e) => {
    const { onClosePopOver } = this.props;
    if (onClosePopOver) {
      onClosePopOver(ACTION_TYPE.CLICK_CANCEL_BUTTON);
      e.stopPropagation();
    }
  };

  /**
   * 확인 버튼 클릭시 현재 팝오버내용을 저장하고 팝오버를 닫습니다 
   */
  handleOK = (e) => {
    const { onOk } = this.props;
    if (onOk) {
      onOk(ACTION_TYPE.SAVE_AND_MOVE_POP_OVER);
      e.stopPropagation();
    }
  };

  render() {
    const divStyle = {
      borderRight: '1px solid #a3a3a3',
      borderLeft: '1px solid #a3a3a3',
      borderBottom: '1px solid #a3a3a3',
      padding: "5px 0",
      textAlign: "center",
      background: "#f8f8f8",
      display: 'flex',
      alignItems: 'center',
      flexGrow: '1',
      justifyContent: 'center',

      //position: "absolute",
      //bottom: "0px",
      //width: "100%",
    };

    const buttonStyle = {
      margin: "0 4px",
      height: '20px',
      width: '45px !important'
    };

    return (
      <div className="btnbx" style={divStyle} onClick={this.handleCancel}>
        <LUXButton
          style={buttonStyle}
          label={OrbitInternalLangPack.getText('WE000001945', '취소')}
          onClick={this.handleCancel}
        />
        <LUXButton
          style={buttonStyle}
          label={OrbitInternalLangPack.getText('WE000000054', '확인')}
          onClick={this.handleOK} />
      </div>
    );
  }
}

export default ListBottom;
