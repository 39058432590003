import React from "react";
import LUXCheckBox from "luna-rocket/LUXCheckBox";
import LUXTooltip from "luna-rocket/LUXTooltip";


class SelecteItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOverflow: false,
    }
  }
  myRefs = {
    text: React.createRef()
  }

  componentDidMount = () => {
    let isOverflow = false;
    if (this.myRefs.text.current) {
      if (this.myRefs.text.current.scrollWidth !== this.myRefs.text.current.clientWidth) isOverflow = true;
      this.setState({ isOverflow: isOverflow });
    }
  };

  handleClickhceckBoxItem = e => {
    e.stopPropagation();
    e.preventDefault();
    const { onClickCheckBoxItem, index } = this.props;
    onClickCheckBoxItem(index);
  };

  handleDoubleClick = () => {
    const { onDoubleClick, index } = this.props;
    onDoubleClick(index);
  };

  getLiClassName = () => {
    const { isChecked, index, position } = this.props;
    let className = "";
    if (isChecked) {
      className = "selected";
    }
    if (index === position) {
      className = "focus";
    }
    return className;
  };

  render() {
    const { isChecked, itemName, useImage, image } = this.props;
    const tooltipProps = {
      float: 'middle',
      tooltipInnerDivStyle: {
        borderRadius: '3px',
        backgroundColor: '#fff',
        borderColor: '#a6a6a6',
        color: '#000'
      },
      arrowStyle: {
        backgroundColor: '#fff', color: '#000',
        transition: 'transform 0.1s ease-out',
        boxShadow: '1px 1px 0 0 #a6a6a6'
      }
    }
    const imageStyle = {
      border: '1px solid #ddd',
      marginTop: '6px',
      marginRight: '6px'
    }
    const liClassName = this.getLiClassName();

    const selectItems = <li
      className={liClassName}
      style={{
        display: 'flex', paddingLeft: '8px',
        cursor: "pointer", border: "none", height: '25px', lineHeight: '24px',
        background: isChecked ? '#e9f5ff' : this.props.index === this.props.position ? '#e9f5ff' : 'white',
        color: isChecked ? '#1c90fb' : 'black'
      }}
      onClick={this.handleClickhceckBoxItem}
      onDoubleClick={this.handleDoubleClick}
    >
      <div>
        <LUXCheckBox
          onClick={this.handleClickhceckBoxItem}
          style={{ height: '25px', width: '20px' }}
          checked={isChecked}
        />
      </div>
      <div>{useImage ? <img src={image} width={18} height={12} style={imageStyle} alt={''} /> : null}</div>
      <div ref={this.myRefs.text} style={{
        whiteSpace: 'nowrap',
        wordBreak: 'break-all',
        textOverflow: 'ellipsis',
        fontSize: '12px',
        fontFamily: 'inherit'
      }}>
        {itemName}</div>
    </li >;

    const totalSelectItmes = this.state.isOverflow ? <LUXTooltip label={itemName} float="left" style={{ display: 'block' }}  {...tooltipProps}>
      {selectItems}
    </LUXTooltip> : selectItems;
    return (
      totalSelectItmes
    );
  }
}




export default SelecteItem;
