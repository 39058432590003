/**
 * OBTPageButtons
 * @version 0.1
 * @author 안광진
 * @see LUXPageButtons
 */
import * as React from 'react';
import { Events, CompositeProps, Util } from '../Common';
import LUXButton from 'luna-rocket/LUXButton';
import OBTDropDownList from '../OBTDropDownList';
import firstImg from '../Images/icon-arrow-first.png';
import lastImg from '../Images/icon-arrow-last.png';
import nextImg from '../Images/icon-arrow-next.png';
import preImg from '../Images/icon-arrow-pre.png';
import { OrbitInternalLangPack } from '../Common/Util';
const styles2 = require('./OBTPagination.module.scss');
/**
 * PropType 정의
 * Events, CommonProps, CompositeProps 에 미리 지정된 Prop interface 를 충분히 활용한다.
 * extends 로 인터페이스 상속을 통해 사용된다.
 * 공용 Api 를 사용하려면 CommonProps.api 인터페이스를 확장한다.
 */
interface IOBTPageButtons extends CompositeProps.Default, Events.onChange<TypeList> {
    /**
     * 현재페이지
     */
    currentPage: number,
    /**
     * 페이지갯수
     */
    pageCount: number,
    /**
     * 페이지단위
     */
    pagingBlock: number,
    /**
     * row갯수
     */
    rowCountPerPage: number,
    /**
     * 총 row갯수
     */
    totalCount: number,

    isPageTotalCountText: boolean,

    useCountDropDownList?: boolean,
}

interface TypeList {
    currentPage: number,
    rowCountPerPage: number,
}

/**
 * State 정의
 */
interface State {
    rowIndex: number,
}

export default class OBTPageButtons extends React.Component<IOBTPageButtons, State> {
    ///////////////////////////////////////////////////////////////////////////// Initialize

    /**
     * Default Props 설정
     */
    public static defaultProps = {
        frozen: false,
        pagingBlock: 10,
        isPageTotalCountText: true,
        useCountDropDownList: true
    }

    /**
     * State 정의
     */
    public state: State = {
        rowIndex: 0,

    }

    ///////////////////////////////////////////////////////////////////////////// Life Cycle API

    // component 가 render 될때 호출됨.
    render() {
        const list = [
            { value: '10', labelText: '10' },
            { value: '20', labelText: '20' },
            { value: '30', labelText: '30' },
            { value: '40', labelText: '40' },
            { value: '50', labelText: '50' },

        ]
        let buttonElements: any[] = [];
        const styles = this.getStyles();
        const pageCount = this.props.pageCount;
        const pagingBlock = this.props.pagingBlock;
        let row = 0, col = 0;
        let selectedStyle = {};

        buttonElements[0] = new Array(pagingBlock);

        for (let j = 1; j <= pageCount; j++) {
            if (j === this.props.currentPage) {
                selectedStyle = { background: 'transparent', color: '#1c90fb' };
            }
            else {
                selectedStyle = {};
            }

            buttonElements[row][col] = (
                <LUXButton
                    key={j}
                    hoverStyle={Object.assign({}, styles.hover, selectedStyle)}
                    label={j.toString()}
                    onTouchTap={this.handleTouchTapPage.bind(this, j)}
                    style={Object.assign({}, styles.pageButton, selectedStyle)}
                />
            );

            col++;

            if (j < pageCount && (j % pagingBlock) === 0) {
                row++;
                col = 0;
                buttonElements[row] = new Array(pagingBlock);
            }

        }

        let rowIndex = Math.max(0, Math.ceil(this.props.currentPage / pagingBlock) - 1);
        return (
            <div className={Util.getClassNames(styles2.total, this.props.className)}>
                {this.props.isPageTotalCountText ?
                    <div className={styles2.pageLeft}>
                        <div className={styles2.pagetext}>{this.props.currentPage}-{this.props.pageCount}{OrbitInternalLangPack.getText('WE000001026', '페이지')}</div>
                        <div className={styles2.totalpagetext}>/{OrbitInternalLangPack.getText('WE000007967', '총')}{this.props.totalCount}{OrbitInternalLangPack.getText('WE000000943', '개')}</div>
                    </div>
                    : <></>}
                <div className={styles2.pageCenter}>
                    <button className={styles2.buttonFirst} onClick={this.handleClick.bind(this, 'first')}><img className={styles2.img} src={firstImg} alt=''></img></button>
                    <button className={styles2.button} onClick={this.handleClick.bind(this, 'pre')}><img className={styles2.img} src={preImg} alt=''></img></button>
                    <div className={styles2.numberButton} style={styles.root}>{buttonElements[rowIndex]}</div>
                    <button className={styles2.buttonFirst} onClick={this.handleClick.bind(this, 'next')}><img className={styles2.img} src={nextImg} alt=''></img></button>
                    <button className={styles2.button} onClick={this.handleClick.bind(this, 'last')}><img className={styles2.img} src={lastImg} alt=''></img></button>
                    {this.props.useCountDropDownList ? (
                        <OBTDropDownList
                            width='50px'
                            height='21px'
                            className={styles2.drop}
                            displayType={OBTDropDownList.DisplayType.text}
                            list={list}
                            value={this.props.rowCountPerPage.toString()}
                            onChange={this.handleChange}
                        />
                    ) : null}
                </div>
                {this.props.isPageTotalCountText ?
                    <div className={styles2.pageRight}>
                        <div className={styles2.pagetext}>{this.props.currentPage}-{this.props.pageCount}{OrbitInternalLangPack.getText('WE000001026', '페이지')}</div>
                        <div className={styles2.totalpagetext}>/{OrbitInternalLangPack.getText('WE000007967', '총')}{this.props.totalCount}{OrbitInternalLangPack.getText('WE000000943', '개')}</div>
                    </div>
                    : <></>}

            </div>
        );
    }

    ///////////////////////////////////////////////////////////////////////////// Logics
    private getStyles() {
        return {
            hover: {
                background: '#e9f5ff',
                color: '#1c90fb',
                border: 'solid 1px #1c90fb',
            },
            pageButton: {
                background: 'transparent',
                border: 'none',
                color: '#4a4a4a',
                display: 'inline-block',
                float: 'left',
                width: '21px',
                height: '21px',
                letterSpacing: 0,
                padding: '0 3px',
                textAlign: 'center',
            },
            root: {
                display: 'inline-block',
                zoom: 1,
                padding: '0px 8px'
            }
        }
    }

    ///////////////////////////////////////////////////////////////////////////// Event Handlers

    private handleClick = (type) => {
        let currentPage = 1;
        if (type === 'first') {
            currentPage = 1;
        }
        else if (type === 'pre') {
            currentPage = this.props.currentPage <= this.props.pagingBlock ? 1 : Math.floor(this.props.currentPage / this.props.pagingBlock - 1) * this.props.pagingBlock + 1;
        }
        else if (type === 'next') {
            currentPage = Math.ceil(this.props.currentPage / this.props.pagingBlock) * this.props.pagingBlock + 1 >= this.props.pageCount ? this.props.pageCount : Math.ceil(this.props.currentPage / this.props.pagingBlock) * this.props.pagingBlock + 1;
        }
        else if (type === 'last') {
            currentPage = this.props.pageCount;
        }

        let typeList = { currentPage: currentPage, rowCountPerPage: this.props.rowCountPerPage };
        Util.invokeEvent<Events.ChangeEventArgs<TypeList>>(this.props.onChange, new Events.ChangeEventArgs<TypeList>(this, typeList));
    };

    private handleTouchTapPage = (currentPage) => {
        let typeList = { currentPage: currentPage, rowCountPerPage: this.props.rowCountPerPage };
        Util.invokeEvent<Events.ChangeEventArgs<TypeList>>(this.props.onChange, new Events.ChangeEventArgs<TypeList>(this, typeList));

    };

    private handleChange = (e: any): void => {
        const rowCountPerPage = parseInt(e.value);
        let typeList = { currentPage: this.props.currentPage, rowCountPerPage: rowCountPerPage };
        Util.invokeEvent<Events.ChangeEventArgs<TypeList>>(this.props.onChange, new Events.ChangeEventArgs<TypeList>(this, typeList));

    }

};
