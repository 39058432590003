/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import LUXButton from 'luna-rocket/LUXButton';
import LUXTimePickerList from './LUXTimePickerList';
import { convertTimeType, convertTimeHour, convertTimeMinute, convertOutputData, convertListIndex, getIndexHours } from './LUXTimePickerUtils';
import Clock from 'luna-rocket/LUXSVGIcon/Duzon/FullSize/Clock';
import LUXPopoverController from 'luna-rocket/LUXPopoverController/LUXPopoverController';
import LUXPopoverAnimationFromTop from 'luna-rocket/LUXPopoverController/LUXPopoverAnimationVertical';
import ClickAwayListener from 'luna-rocket/internal/ClickAwayListener'
import globalObj from 'luna-rocket/locale/intlGlobals';
import detector from 'detector';

const browser = detector.browser.name;
const isChrome = browser === 'chrome';
const isMobile = detector.os.name === 'ios' || detector.os.name === 'android';

function getStyles(state, props, popoverWidth) {
    return {
        root: {
            position: 'relative'
        },
        timePickerBoxStyle: {
            position: 'relative',
            width: props.fullWidth ? '100%' : '100px',
        },
        timePickerDivStyle: {
            position: 'absolute',
            top: 4,
            left: 3,
            // zIndex: 10,
            // fontSize: '15px',
            lineHeight: '19px',
            fontFamily: 'inherit',
        },
        timePickerStyle: {
            display: 'block',
            height: '22px',
            padding: '3px 24px 0 3px',
            border: state.isFocused ? '1px solid #2196F3' : '1px solid #dbdbdb',
            background: props.disabled ? 'rgb(240,240,240)' : '#fff',
            lineHeight: '19px',
            outline: 'none'
        },
        timePickerTypeStyle: {
            display: 'inline-block',
            margin: '0 3px',
            fontSize: '12px',
            fontFamily: 'inherit',
            lineHeight: '13px',
            color: props.disabled ? '#999' : '#000',
            background: state.typeFocus ? '#b1d7fe' : '',
            outline: 'none',
            cursor: props.disabled ? 'not-allowed' : 'default',
            userSelect: 'none',
        },
        timePickerHourStyle: {
            display: 'inline-block',
            margin: '0 3px',
            fontSize: '12px',
            fontFamily: 'inherit',
            lineHeight: '13px',
            color: props.disabled ? '#999' : '#000',
            background: state.hourFocus ? '#b1d7fe' : '',
            outline: 'none',
            cursor: props.disabled ? 'not-allowed' : 'default',
            userSelect: 'none',
        },
        timePickerCloneStyle: {
            display: 'inline-block',
            margin: '0 -1px',
            fontSize: '12px',
            fontFamily: 'inherit',
            lineHeight: '13px',
            color: props.disabled ? '#999' : '#000',
            cursor: props.disabled ? 'not-allowed' : 'default',
            userSelect: 'none',
        },
        timePickerMinuteStyle: {
            display: 'inline-block',
            margin: '0 3px',
            fontSize: '12px',
            fontFamily: 'inherit',
            lineHeight: '13px',
            color: props.disabled ? '#999' : '#000',
            background: state.minuteFocus ? '#b1d7fe' : '',
            outline: 'none',
            cursor: props.disabled ? 'not-allowed' : 'default',
            userSelect: 'none',
        },
        timePickerIconStyle: {
            position: 'absolute',
            top: 0,
            right: 0,
            padding: 0,
            width: '27px',
            height: '27px',
            border: '0 none',
            background: 'transparent'
        },
        timePickerResultBoxStyle: {
            width: popoverWidth,
            overflow: 'auto',
            border: '1px solid #a3a3a3',
            background: '#fff',
            boxShadow: '1px 1px 2px #ececec',
            maxHeight: '110px'
        }
    }
}

const date = new Date();

class LUXTimePicker extends Component {
    static propTypes = {
        /**
         * 값이 true 이면, 요소가 비활성화 됩니다.
         */
        disabled: PropTypes.bool,
        /**
         * TimePicker 콤포넌트를 감싸고 있는 외부 영역에 100%로 width값을 정의할 때 사용하는 속성입니다.
         */
        fullWidth: PropTypes.bool,
        /**
         * TimePicker 콤포넌트에서 이전/다음 날로 넘어가는 시점에 발생하는 Callback 함수입니다.
         */
        onAddDays: PropTypes.func,
        /**
         * 요소에 초점(Focus)이 사라질 때 발생하는 Callback 함수입니다.
         *
         * @param {object} event 요소에서 발생한 이벤트 객체입니다.
         */
        onBlur: PropTypes.func,
        /**
         * TimePicker 콤포넌트의 값이 변경할 때 발생하는 Callback 함수입니다.
         *
         * @param {string} value 요소의 변경된 값입니다.
         */
        onChange: PropTypes.func,
        /**
         * @ignore
         * TimePicker 콤포넌트의 리스트를 클릭하여 선택할 때 발생하는 Callback 함수입니다.
         */
        onClickTimeList: PropTypes.func,
        /**
         * 요소에 초점(Focus)이 잡힐 때 호출되는 Callback 함수입니다.
         *
         * @param {object} event 요소에서 발생한 이벤트 객체입니다.
         */
        onFocus: PropTypes.func,
        /**
         * TimePicker 콤포넌트에서 Key Down 동작이 있을 때 발생하는 Callback 함수입니다.
         *
         * @param {object} event 요소에서 발생한 Key down 이벤트 객체입니다.
         * @param {string} position Key down 이벤트 동작이 발생한 위치입니다.
         */
        onKeyDown: PropTypes.func,
        /**
         * TimePicker 콤포넌트에서 Key Up 동작이 있을 때 발생하는 Callback 함수입니다.
         *
         * @param {object} event 요소에서 발생한 Key up 이벤트 객체입니다.
         * @param {string} position Key up 이벤트 동작이 발생한 위치입니다.
         */
        onKeyUp: PropTypes.func,
        /**
         * @ignore
         * TimePicker 콤포넌트에서 KeyBoard의 Arrow Up, Arrow Down 동작을 통한 Type, Hour, Minute의 변화가 있을 때 발생하는 Callback 함수입니다.
         */
        onKeyDownArrowEvent: PropTypes.func,
        /**
         * @ignore
         * TimePicker 콤포넌트에서 다음 포커스로 이동해야 할 때 발생하는 Callback 함수입니다.
         */
        onNextFocus: PropTypes.func,
        /**
         * @ignore
         * TimePicker 콤포넌트에서 이전 포커스로 이동해야 할 때 발생하는 Callback 함수입니다.
         */
        onPrevFocus: PropTypes.func,
        /**
         * @ignore
         * TimePicker 콤포넌트의 리스트가 닫힐 때 발생하는 Callback 함수입니다.
         */
        onRequestClose: PropTypes.func,
        /**
         * 특정 시간에 대한 비활성화 기능을 넣기 위해 사용되는 Callback 함수입니다.
         *
         * @param {string} time 비활성화를 결정 할 시간입니다.
         * @returns {boolean} 값이 true 이면, 해당 time 은 비활성화가 됩니다.
         */
        shouldDisableTime: PropTypes.func,
        /**
         * TimePicker 콤포넌트에 대한 최상위 div element에 적용되는 스타일 속성입니다.
         */
        style: PropTypes.object,
        /**
         * TimePicker 콤포넌트에 보여질 시간을 정의하는 속성으로, 값이 정의되지 않으면 현재시간을 기준으로 정의됩니다.
         * 형식 -> ex) 14:30 -> '1430'
         */
        time: PropTypes.string,
        /**
         * TimePicker 콤포넌트의 span영역과 button영역을 감싸고 있는 box영역인 div element에 적용되는 스타일 속성입니다.
         */
        timePickerBoxStyle: PropTypes.object,
        /**
         * TimePicker 콤포넌트에 대한 SelectBox 영역의 스크롤 및 최대 높이값을 정의하는 영역의 스타일 속성입니다.
         */
        timePickerResultBoxStyle: PropTypes.object,
        /**
         * TimePicker 콤포넌트에 대한 오전/오후, 시간, 분에 대한 span element를 감싸고 있는 div element 영역에 적용되는 스타일 속성입니다.
         */
        timePickerStyle: PropTypes.object,

        viewmode: PropTypes.string,

        readonly: PropTypes.bool,

        onMoveFocus: PropTypes.func
    };

    static defaultProps = {
        fullWidth: false,
        onFocus: () => { },
    };

    constructor(props) {
        super(props);

        this.state = {
            time: null,
            type: null,
            hours: null,
            minutes: null,
            open: false,
            typeFocus: false,
            hourFocus: false,
            minuteFocus: false,
            isFocused: false,
            listIndex: null,
            shift: false,
            inputOne: false,
            inputFive: false,
            inputTimeOver: true,
            isButtonClick: false,
            isKeyDown: false,
            addDays: 0,
        }
    }

    myRefs = {
        root: React.createRef(),
        selectField: React.createRef(),
        spanBox: React.createRef(),
        timePickerType: React.createRef(),
        timePickerHour: React.createRef(),
        timePickerMinute: React.createRef(),
        timeButton: React.createRef(),
        scrollBox: React.createRef(),
    }

    componentWillMount = () => {
        const { time } = this.props;
        if (time === undefined || time === '') {
            this.setState({
                type: globalObj.intl.messages["luna.rocket.morning"],
                hours: undefined,
                minutes: undefined,
                listIndex: 0
            });
        } else {
            let inputHours = time.substring(0, 2);
            let inputMinutes = time.substring(2, 4);

            if (inputHours.substring(0, 1) === '0') {
                inputHours = Number(inputHours.substring(1, 3));
            } else {
                if (Number(inputHours) >= 24) {
                    // Hours가 24 이상으로 넘어올 때 대비
                    inputHours = 0;
                } else {
                    inputHours = Number(inputHours);
                }
            }

            if (inputMinutes.substring(0, 1) === '0') {
                inputMinutes = Number(inputMinutes.substring(1, 3));
            } else {
                if (Number(inputMinutes) >= 60) {
                    // Minutes가 60 이상으로 넘어올 때 대비
                    inputMinutes = 0;
                } else {
                    inputMinutes = Number(inputMinutes);
                }
            }

            let convertType = convertTimeType(inputHours);
            let convertHour = convertTimeHour(inputHours);
            let convertMinute = convertTimeMinute(inputMinutes);
            let convertIndex = convertListIndex(convertType, convertHour, convertMinute);

            this.prevType = convertType;
            this.prevHour = convertHour;
            this.prevMinute = convertMinute;

            this.setState({
                type: convertType,
                hours: convertHour,
                minutes: convertMinute,
                listIndex: convertIndex
            });
        }
    };

    componentWillReceiveProps = (nextProps) => {
        // if (nextProps.time === this.props.time) return;

        if (nextProps.time === undefined || nextProps.time === '') {
            let convertType = globalObj.intl.messages["luna.rocket.morning"]
            let convertHour = undefined
            let convertMinute = undefined
            let convertIndex = 0

            this.setState({
                type: convertType,
                hours: convertHour,
                minutes: convertMinute,
                listIndex: convertIndex
            });

        } else {
            let inputHours = nextProps.time.substring(0, 2);
            let inputMinutes = nextProps.time.substring(2, 4);

            if (inputHours.substring(0, 1) === '0') {
                inputHours = Number(inputHours.substring(1, 3));
            } else {
                if (Number(inputHours) >= 24) {
                    // Hours가 24 이상으로 넘어올 때 대비
                    inputHours = 0;
                } else {
                    inputHours = Number(inputHours);
                }
            }

            if (inputMinutes.substring(0, 1) === '0') {
                inputMinutes = Number(inputMinutes.substring(1, 3));
            } else {
                if (Number(inputMinutes) >= 60) {
                    // Minutes가 60 이상으로 넘어올 때 대비
                    inputMinutes = 0;
                } else {
                    inputMinutes = Number(inputMinutes);
                }
            }

            let convertType = convertTimeType(inputHours);
            let convertHour = convertTimeHour(inputHours);
            let convertMinute = convertTimeMinute(inputMinutes);
            let convertIndex = convertListIndex(convertType, convertHour, convertMinute);

            this.prevType = convertType;
            this.prevHour = convertHour;
            this.prevMinute = convertMinute;

            this.setState({
                type: convertType,
                hours: convertHour,
                minutes: convertMinute,
                listIndex: convertIndex
            });
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (!this.state.open) return;

        let scroll;
        let index = this.state.listIndex;
        if (!this.myRefs.scrollBox.current) {
            scroll = document.getElementById("scrollBox");
        } else {
            scroll = this.myRefs.scrollBox.current;
        }

        let scrHeight = scroll.scrollHeight;
        let cliHeight = scroll.clientHeight;

        //Top Arrows
        //scroll.scrollTop = (scrHeight * (this.state.listIndex / 48) - (cliHeight + 66) * 0.5);
        //Bottom Arrows
        //scroll.scrollTop = ((scrHeight * (this.state.listIndex / 48) - (cliHeight - 22) * 0.5));
        scroll.scrollTop = ((scrHeight * (index / 48) - (cliHeight + 18) * 0.5));
    };

    shouldComponentUpdate(nextProps, nextState) {
        return (JSON.stringify(this.props) !== JSON.stringify(nextProps) || JSON.stringify(this.state) !== JSON.stringify(nextState));
        // return (!shallowEqual(this.props, nextProps) || !shallowEqual(this.state, nextState));
    }

    componentClickAway = () => {
        //this.handleOnKeyboardPress();
        setTimeout(this.isComponentClickAway, 100);
        // setTimeout(this.handleBlur, 100);
    };

    componentClickAway2 = () => {
        this.setState({
            isListFocused: false,
            isScrolled: false,
        });
    }

    isComponentClickAway = () => {
        if (this.state.isListFocused) return;

        this.handleBlur();
    }

    handleScroll = () => {
        if (this.state.isKeyDown) {
            this.setState({ isKeyDown: false });
        } else if (!this.state.isScrolled) {
            this.setState({ isScrolled: true });
        } else if (!this.state.isListFocused) {
            this.setState({ isListFocused: true });
        }

        // if (!this.state.isListFocused && this.state.isScrolled) {
        //     this.setState({
        //         isListFocused: true,
        //     });
        // }
    }

    handleListElement = () => {
        let listElement = [];
        let listType = globalObj.intl.messages["luna.rocket.morning"];
        let listHour1 = '0';
        let listHour2 = '0';
        let listMinute = '0';

        for (let i = 0; i <= 48; i++) {
            // Type 계산
            if (i > 24) {
                listType = globalObj.intl.messages["luna.rocket.afternoon"];
            }

            if (i !== 0 && (i % 2 === 0 && this.props.viewmode === 'hour')) {
                continue;
            }

            // Hour 계산
            if (i === 1 || i === 2 || i === 25 || i === 26) {
                listHour1 = '1';
                listHour2 = '2';
            } else if (i === 3 || i === 4 || i === 27 || i === 28) {
                listHour1 = '0';
                listHour2 = '1';
            } else if (i === 5 || i === 6 || i === 29 || i === 30) {
                listHour1 = '0';
                listHour2 = '2';
            } else if (i === 7 || i === 8 || i === 31 || i === 32) {
                listHour1 = '0';
                listHour2 = '3';
            } else if (i === 9 || i === 10 || i === 33 || i === 34) {
                listHour1 = '0';
                listHour2 = '4';
            } else if (i === 11 || i === 12 || i === 35 || i === 36) {
                listHour1 = '0';
                listHour2 = '5';
            } else if (i === 13 || i === 14 || i === 37 || i === 38) {
                listHour1 = '0';
                listHour2 = '6';
            } else if (i === 15 || i === 16 || i === 39 || i === 40) {
                listHour1 = '0';
                listHour2 = '7';
            } else if (i === 17 || i === 18 || i === 41 || i === 42) {
                listHour1 = '0';
                listHour2 = '8';
            } else if (i === 19 || i === 20 || i === 43 || i === 44) {
                listHour1 = '0';
                listHour2 = '9';
            } else if (i === 21 || i === 22 || i === 45 || i === 46) {
                listHour1 = '1';
                listHour2 = '0';
            } else if (i === 23 || i === 24 || i === 47 || i === 48) {
                listHour1 = '1';
                listHour2 = '1';
            }

            // Minute 계산
            if (i % 2 === 0) {
                listMinute = '3';
            } else {
                listMinute = '0';
            }

            // listIndex 계산
            let focused = false;
            if (this.state.listIndex === i) {
                focused = true;
            }

            // 특정 시간 비활성화
            let outputData = convertOutputData(listType, listHour1 + listHour2, listMinute + '0');
            let isDisabled = this.props.shouldDisableTime && this.props.shouldDisableTime(outputData);

            if (i === 0) {
                listType = globalObj.intl.messages["luna.rocket.morning"];
                listHour1 = '_';
                listHour2 = '_';
                listMinute = '_';
            }

            listElement.push(
                <LUXTimePickerList
                    key={i}
                    listType={listType}
                    listHour1={listHour1}
                    listHour2={listHour2}
                    listMinute={listMinute}
                    isFocused={focused}
                    isDisabled={isDisabled}
                    index={i}
                    choiceTimeListData={this.handleSelectList}
                />
            );
        }

        return listElement;
    };

    handleSelectList = (type, hour, minute, index) => {
        let outputData = convertOutputData(type, hour, minute);
        // 비활성화 된 시간은 다시 이전 시간으로 변경
        if (this.props.shouldDisableTime && this.props.shouldDisableTime(outputData)) return;

        this.setState({
            type: type,
            hours: hour === '__' ? '' : hour,
            minutes: minute === '__' ? '' : minute,
            listIndex: index,
        });

        if (this.props.onClickTimeList) this.props.onClickTimeList(outputData);
        if (this.props.onChange) this.props.onChange(isNaN(outputData) ? '' : outputData);

        this.handleNextFocus();
    };

    handleSetListFocusIndex = () => {
        this.setState({ listIndex: this.props.time === undefined || this.props.time === '' ? 0 : convertListIndex(this.state.type, this.state.hours, this.state.minutes) });
    };

    // region Events
    handleBlur = (event) => {
        if (!this.state.isFocused) return;

        this.timePickerFocusOut();

        if (this.props.onBlur) this.props.onBlur(event);
        if (this.props.onRequestClose) this.props.onRequestClose();
    }

    handleFocus = (event) => {
        if (this.props.disabled || this.state.isFocused || this.props.readonly) return;

        this.handleSetListFocusIndex();
        this.setState({ isFocused: true, open: true });

        if (this.props.onFocus) this.props.onFocus(event);
    };

    handlePrevFocus = (target) => {
        this.handleMoveFocus('left');
        if (this.props.onPrevFocus) this.props.onPrevFocus(target);
    }

    handleNextFocus = (target) => {
        this.handleMoveFocus();
        if (this.props.onNextFocus) this.props.onNextFocus(target);
    }

    handleMoveFocus = (move = 'right', subPosition) => {
        this.handleBlur();
        if (this.myRefs.spanBox.current) this.myRefs.spanBox.current.blur();
        if (this.props.onMoveFocus) this.props.onMoveFocus(move, subPosition);
    }

    handleTouchTapButton = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (this.state.isFocused) {
            if (this.myRefs.spanBox.current) this.myRefs.spanBox.current.blur();
        } else {
            if (this.myRefs.spanBox.current) this.myRefs.spanBox.current.focus();
        }

        if (this.props.onTouchTap) this.props.onTouchTap(event);
    };
    // endregion

    /**
     * 시간 선택 창이 열렸을 때 발생하는 keydown 이벤트
     */
    handleKeyDown = (event) => {
        event.stopPropagation();
        event.preventDefault();

        switch (keycode(event)) {
            case 'backspace':
                break;
            case 'shift':
                this.setState({ shift: true });
                break;
            case 'enter':
                this.handleEnterKeyEvent();
                break;
            case 'tab':
                this.handleBlur(event);
                break;
            case 'up':
                this.setState({ isKeyDown: true });
                this.handleTopArrowsEvent();
                break;
            case 'down':
                this.setState({ isKeyDown: true });
                this.handleBottomArrowsEvent();
                break;
            case 'left':
                this.handlePrevFocus();
                break;
            case 'right':
                this.handleNextFocus();
                break;
            default:
                break;
        }

        if (this.props.onKeyDown) this.props.onKeyDown(event);
    };

    /**
     * 시간 선택 창이 열렸을 때 발생하는 keyup 이벤트
     */
    handleKeyUp = (event) => {
        event.preventDefault();

        this.setState({ shift: false });

        if (this.props.onKeyUp) this.props.onKeyUp(event);
    };

    handleEnterKeyEvent = () => {
        if (!this.state.open) return;
        // type
        let type = globalObj.intl.messages["luna.rocket.morning"];
        if (this.state.listIndex >= 26) type = globalObj.intl.messages["luna.rocket.afternoon"];
        // hours
        let hour = getIndexHours(this.state.listIndex);
        // minutes
        let minute = '00';
        if (this.state.listIndex % 2 === 0) minute = '30';
        // index
        let index = this.state.listIndex;

        this.handleSelectList(type, hour, minute, index);
    };

    handleTopArrowsEvent = () => {
        if (this.state.open) {
            if (this.state.listIndex === 0) {
                this.setState({ listIndex: 0 });
            } else {
                this.setState({ listIndex: this.state.listIndex - 1 });
            }
        } else {
            return false;
        }
    };

    handleBottomArrowsEvent = () => {
        if (this.state.open) {
            if (this.state.listIndex < 48) {
                this.setState({ listIndex: this.state.listIndex + 1 });
            } else if (this.state.listIndex === 48) {
                this.setState({ listIndex: 48 });
            }
        } else {
            return false;
        }
    };

    /**
     * span 태그 중 어느 것이 클릭되었는지를 리턴
     */
    getClickPosition(target) {
        if (target === this.myRefs.timePickerType.current) {
            return "type";
        } else if (target === this.myRefs.timePickerHour.current) {
            return "hour";
        } else if (target === this.myRefs.timePickerMinute.current) {
            return "minute";
        } else return undefined;
    }

    handleSpanClick = (event) => {
        event.stopPropagation();

        if (this.props.disabled) return;

        this.handleFocus();
        // this.setState({open: false});

        let elementType = this.getClickPosition(event.target);
        switch (elementType) {
            case "type":
                this.handleTypeClick();
                break;
            case "hour":
                this.handleHourClick();
                break;
            case "minute":
                this.handleMinuteClick();
                break;
            default:
                this.handleBlur(event);
        }
    }

    handleSpanKeyDown = (event) => {
        event.stopPropagation();

        if (this.props.disabled) return;

        let elementType = this.getClickPosition(event.target);
        switch (elementType) {
            case "type":
                this.handleTypeKeyDown(event);
                break;
            case "hour":
                this.handleHourKeyDown(event);
                break;
            case "minute":
                this.handleMinuteKeyDown(event);
                break;
            default:
                this.handleBlur(event);
        }
    }

    handleSpanKeyUp = (event) => {
        event.stopPropagation();

        if (this.props.disabled) return;

        let elementType = this.getClickPosition(event.target);
        switch (elementType) {
            case "type":
                this.handleTypeKeyUp(event);
                break;
            case "hour":
                this.handleHourKeyUp(event);
                break;
            case "minute":
                this.handleMinuteKeyUp(event);
                break;
            default:
                this.handleBlur(event);
        }
    }

    /**
     * 오전/오후 타입에 click 이벤트 발생
     */
    handleTypeClick = () => {
        this.setState({
            typeFocus: true,
            hourFocus: false,
            minuteFocus: false
        });

        if (this.myRefs.timePickerType.current) this.myRefs.timePickerType.current.focus();
    };

    /**
     * 오전/오후 타입에 keydown 이벤트 발생
     */
    handleTypeKeyDown = (event) => {
        switch (keycode(event)) {
            case 'backspace':
                event.preventDefault();
                break;
            case 'enter':
                this.handleNextFocus();
                break;
            case 'shift':
                this.setState({ shift: true });
                break;
            case 'up':
            case 'down':
                event.preventDefault();
                this.handleChangeType();
                break;
            case 'tab':
                if (this.state.shift) {
                    this.setState({ typeFocus: false });
                }
                break;
            case 'right':
                event.preventDefault();
                this.handleHourClick();
                break;
            case 'left':
                event.preventDefault();
                this.handleMinuteClick();
                break;
        }

        if (this.props.onKeyDown) this.props.onKeyDown(event, 'type');
    };

    /**
     * 오전/오후 타입에 keyup 이벤트 발생
     */
    handleTypeKeyUp = (event) => {
        this.setState({ shift: false });
        if (this.props.onKeyUp) this.props.onKeyUp(event, 'type');
    };

    handleChangeType = () => {
        this.setState({
            type: this.state.type === globalObj.intl.messages["luna.rocket.morning"] ? globalObj.intl.messages["luna.rocket.afternoon"] : globalObj.intl.messages["luna.rocket.morning"],
        }, this.handleOnKeyboardPress);
    };

    /**
     * 시간에 click 이벤트 발생
     */
    handleHourClick = () => {
        this.setState({
            typeFocus: false,
            hourFocus: true,
            minuteFocus: false
        });

        if (this.myRefs.timePickerHour.current) this.myRefs.timePickerHour.current.focus();
    };

    /**
     * 시간에 keydown 이벤트 발생
     */
    handleHourKeyDown = (event) => {
        // 숫자만인지 체크하는 정규식
        const regNumber = /^[0-9]*$/;
        if (!regNumber.test(keycode(event))) {
            this.setState({ inputOne: false });
        }

        switch (keycode(event)) {
            case 'backspace':
                event.preventDefault();
            case 'enter':
                this.handleOnKeyboardPress();
                this.handleNextFocus();
                break;
            case 'shift':
                this.setState({ shift: true });
                break;
            case 'up':
                event.preventDefault();
                this.handleAddHour(1);
                break;
            case 'down':
                event.preventDefault();
                this.handleAddHour(-1);
                break;
            case 'right':
                event.preventDefault();
                this.handleMinuteClick();
                break;
            case 'left':
                event.preventDefault();
                this.handleTypeClick();
                break;
            default:
                // 숫자만인지 체크하는 정규식
                const regNumber = /^[0-9]*$/;

                // 숫자가 아니면 아무 행동도 하지 않는다.
                let keycodeStr = keycode(event).slice(-1);
                if (!regNumber.test(keycodeStr)) break;

                let hour = parseInt(keycodeStr);

                if (hour < 0) break;
                else if (hour === 1) {
                    // 입력 시간 2초 체크
                    this.setState({ inputTimeOver: false });
                    setTimeout(() => {
                        this.setState({ inputTimeOver: true, inputOne: false });
                        this.handleOnKeyboardPress();  // 1을 입력했을 때는 2초 뒤에 onChange 함수 호출
                    }, 2000);
                }

                this.handleChangeHour('0' + hour);
        }

        if (this.props.onKeyDown) this.props.onKeyDown(event, 'hour');
    };

    /**
     * 시간에 keyup 이벤트 발생
     */
    handleHourKeyUp = (event) => {
        event.preventDefault();

        this.setState({ shift: false });
        if (this.props.onKeyUp) this.props.onKeyUp(event, 'hour');
    };

    /**
     * 현재 시간에서 파라미터의 시간 만큼을 더해줍니다.
     * @param addHour 더해질 시간을 숫자로 넘겨줍니다.
     */
    handleAddHour = (addHour) => {
        let hour = parseInt(this.state.hours);

        if (hour === 1 && addHour < 0) { // 오전 1시에서 -1 하면 오전 12시
            hour = 12;
        } else if (hour === 11 && addHour > 0) {
            hour = 12;

            if (this.state.type === globalObj.intl.messages["luna.rocket.morning"]) {     // 오전 11시 -> 오후 12시
                this.setState({ type: globalObj.intl.messages["luna.rocket.afternoon"] });
            } else {                              // 오후 11시 -> 오전 12시
                this.setState({ type: globalObj.intl.messages["luna.rocket.morning"], addDays: 1 });
            }
        } else if (hour === 12 && addHour < 0) {
            hour = 11;

            if (this.state.type === globalObj.intl.messages["luna.rocket.morning"]) {     // 오전 12시 -> 오후 11시
                this.setState({ type: globalObj.intl.messages["luna.rocket.afternoon"], addDays: -1 });
            } else {                              // 오후 12시 -> 오전 11시
                this.setState({ type: globalObj.intl.messages["luna.rocket.morning"] });
            }
        } else {
            hour = (hour + addHour) % 12;
        }

        if (hour < 10) {
            hour = '0' + hour;
        }

        this.setState({
            hours: hour.toString()
        }, this.handleOnKeyboardPress);
    };

    /**
     * 현재 시간을 입력된 값으로 바꿔줍니다.
     */
    handleChangeHour = (hour) => {
        let { inputOne, inputTimeOver, hours } = this.state;

        // 1 이 입력된 상태에서 2초 안에 0,1,2를 입력하면 10시,11시,12시로 입력된다.
        if (inputOne && !inputTimeOver && parseInt(hour) < 3) {
            hour = '1' + parseInt(hour);
        }

        this.setState({
            inputOne: hour === '01',
            hours: hour === '00' ? hours : hour,
        });

        // 2시부터 9시까지는 바로 onChange 함수 호출
        if (parseInt(hour) > 1 && parseInt(hour) < 10) {
            setTimeout(this.handleOnKeyboardPress, 100);
        }
    }

    /**
     * 분에 click 이벤트 발생
     */
    handleMinuteClick = () => {
        this.setState({
            typeFocus: false,
            hourFocus: false,
            minuteFocus: true
        });

        if (this.myRefs.timePickerMinute.current) this.myRefs.timePickerMinute.current.focus();
    };

    /**
     * 분에 keydown 이벤트 발생
     */
    handleMinuteKeyDown = (event) => {
        // 숫자만인지 체크하는 정규식
        const regNumber = /^[0-9]*$/;
        if (!regNumber.test(keycode(event))) {
            this.setState({ inputFive: false });
        }

        switch (keycode(event)) {
            case 'backspace':
                event.preventDefault();
            case 'enter':
                event.preventDefault();
                this.handleOnKeyboardPress();
                this.handleNextFocus();
                break;
            case 'shift':
                this.setState({ shift: true });
                break;
            case 'up':
                event.preventDefault();
                this.handleAddMinute(1);
                break;
            case 'down':
                event.preventDefault();
                this.handleAddMinute(-1);
                break;
            case 'tab':
                if (!this.state.shift) {
                    this.handleBlur(event);
                }
                break;
            case 'right':
                event.preventDefault();
                this.handleTypeClick();
                break;
            case 'left':
                event.preventDefault();
                this.handleHourClick();
                break;
            default:
                // 숫자만인지 체크하는 정규식
                const regNumber = /^[0-9]*$/;

                // 숫자가 아니면 아무 행동도 하지 않는다.
                let keycodeStr = keycode(event).slice(-1);
                if (!regNumber.test(keycodeStr)) break;

                let minute = parseInt(keycodeStr);
                if (minute < 0) break;
                else if (minute < 6) {
                    // 입력 시간 2초 체크
                    this.setState({ inputTimeOver: false });
                    setTimeout(() => {
                        this.setState({ inputTimeOver: true, inputFive: false });
                        this.handleOnKeyboardPress();  // 6보다 작은 숫자를 입력했을 때는 2초 뒤에 onChange 함수 호출
                    }, 2000);
                }

                this.handleChangeMinute('0' + minute);
        }

        if (this.props.onKeyDown) this.props.onKeyDown(event, 'minute');
    };

    /**
     * 분에 keyup 이벤트 발생
     */
    handleMinuteKeyUp = (event) => {
        event.preventDefault();

        this.setState({ shift: false });
        if (this.props.onKeyUp) this.props.onKeyUp(event, 'minute');
    };

    /**
     * 현재 분에서 파라미터의 분 만큼을 더해줍니다.
     * @param addMinute 더해질 분을 숫자로 넘겨줍니다.
     */
    handleAddMinute = (addMinute) => {
        let minute = parseInt(this.state.minutes);

        if (minute === 0 && addMinute < 0) {         // 00분에서 이전시간 59분으로 넘어감
            minute = 59;
            this.setState({ minutes: minute.toString() });
            this.handleAddHour(-1);
        } else if (minute === 59 && addMinute > 0) { // 59분에서 다음시간 00분으로 넘어감
            minute = 0;
            this.setState({ minutes: minute.toString() });
            this.handleAddHour(1);
        } else {
            minute = minute + addMinute;
        }

        if (minute < 10) {
            minute = '0' + minute;
        }

        this.setState({ minutes: minute.toString() });

        if (minute.toString() !== '59' && minute.toString() !== '00') {
            setTimeout(this.handleOnKeyboardPress, 100);
        }
    };

    /**
     * 현재 분을 입력된 값으로 바꿔줍니다.
     */
    handleChangeMinute = (minute) => {
        let { inputFive, inputTimeOver, minutes } = this.state;

        // 6보다 작은 숫자가 입력된 상태에서 2초 안에 숫자를 입력하면 두 자리 분으로 입력된다.
        if (inputFive && !inputTimeOver) {
            minute = parseInt(minutes).toString() + parseInt(minute).toString();
        }

        this.setState({
            inputFive: parseInt(minute) < 6,
            minutes: minute,
        });

        // 6,7,8,9 분은 바로 onChange 함수 호출
        if (parseInt(minute) >= 6) {
            setTimeout(this.handleOnKeyboardPress, 100);
        }
    }

    timePickerFocusOut = () => {
        this.setState({
            open: false,
            typeFocus: false,
            hourFocus: false,
            minuteFocus: false,
            isFocused: false,
            isScrolled: false,
            isListFocused: false,
        });

        if (this.myRefs.timePickerType.current) this.myRefs.timePickerType.current.blur();
        if (this.myRefs.timePickerHour.current) this.myRefs.timePickerHour.current.blur();
        if (this.myRefs.timePickerMinute.current) this.myRefs.timePickerMinute.current.blur();
    };

    handleOnKeyboardPress = () => {
        let outputData = convertOutputData(this.state.type, this.state.hours, this.state.minutes);
        if (this.props.onKeyDownArrowEvent) this.props.onKeyDownArrowEvent(outputData);

        let { addDays } = this.state;
        if (addDays !== 0) {
            if (this.props.onAddDays) this.props.onAddDays(addDays); // 다음/이전 날로 변경된 이벤트 발생
            this.setState({ addDays: 0 });
        }

        // 비활성화 된 시간은 다시 이전 시간으로 변경
        if (this.props.shouldDisableTime && this.props.shouldDisableTime(outputData, addDays)) {
            this.setState({
                type: this.prevType,
                hours: this.prevHour,
                minutes: this.prevMinute,
            });
            return;
        }

        this.prevType = this.state.type;
        this.prevHour = this.state.hours;
        this.prevMinute = this.state.minutes;

        if (this.props.onChange) {
            this.props.onChange(outputData, addDays)
        }
    };

    getTime = () => {
        return convertOutputData(this.state.type, this.state.hours, this.state.minutes);
    };

    focus = () => {
        if (this.props.disabled) return;

        if (this.myRefs.spanBox.current) this.myRefs.spanBox.current.focus();
    };

    getClientWidth() {
        if (this.myRefs.selectField.current) return this.myRefs.selectField.current.clientWidth;
    }

    render() {
        const {
            readonly,
            disabled,
            time,
            fullWidth,
            onAddDays,
            onBlur,
            onChange,
            onClickTimeList,         // eslint-disable-line no-unused-vars
            onRequestClose,          // eslint-disable-line no-unused-vars
            onKeyDownArrowEvent,     // eslint-disable-line no-unused-vars
            onFocus,                 // eslint-disable-line no-unused-vars
            onKeyDown,               // eslint-disable-line no-unused-vars
            onKeyUp,               // eslint-disable-line no-unused-vars
            onPrevFocus,
            onNextFocus,
            onMoveFocus,
            shouldDisableTime,
            style,
            timePickerBoxStyle,
            timePickerStyle,
            timePickerResultBoxStyle,
            ...other
        } = this.props;

        let popoverWidth = 0;
        if (this.myRefs.selectField.current) {
            popoverWidth = this.myRefs.selectField.current.clientWidth - 2;
        }

        const styles = getStyles(this.state, this.props, popoverWidth);

        const spanBoxProps = {
            disabled: disabled,
            onKeyDown: this.handleKeyDown,
            onKeyUp: this.handleKeyUp,
            onClick: (event) => { event.stopPropagation() },
            onFocus: this.handleFocus,
            ...other
        };
        const spanProps = {
            onClick: (event) => { event.stopPropagation() },
            onFocus: this.handleSpanClick,
            onKeyDown: this.handleSpanKeyDown,
            onKeyUp: this.handleSpanKeyUp,
        };

        return (
            <ClickAwayListener onClickAway={this.state.isFocused && this.componentClickAway}>
                <div ref={this.myRefs.root} style={Object.assign({}, styles.root, style)}>
                    <div ref={this.myRefs.selectField} style={Object.assign({}, styles.timePickerBoxStyle, timePickerBoxStyle)}>
                        <div ref={this.myRefs.spanBox} tabIndex={disabled ? -1 : 0} {...spanBoxProps} style={Object.assign({}, styles.timePickerStyle, timePickerStyle)}></div>
                        <div style={styles.timePickerDivStyle}>
                            <span ref={this.myRefs.timePickerType} tabIndex={disabled ? -1 : 0} {...spanProps} style={styles.timePickerTypeStyle}>{this.state.type === undefined || this.state.type === '' ? globalObj.intl.messages["luna.rocket.morning"] : this.state.type}</span>
                            <span ref={this.myRefs.timePickerHour} tabIndex={disabled ? -1 : 0} {...spanProps} style={styles.timePickerHourStyle}>{this.state.hours === undefined || this.state.hours === '' || isNaN(this.state.hours) ? `__` : this.state.hours}</span>
                            <span style={styles.timePickerCloneStyle}>:</span>
                            <span ref={this.myRefs.timePickerMinute} tabIndex={disabled ? -1 : 0} {...spanProps} style={styles.timePickerMinuteStyle}>{this.state.minutes === undefined || this.state.minutes === '' || isNaN(this.state.minutes) ? `__` : this.state.minutes}</span>
                        </div>
                        <LUXButton
                            ref={this.myRefs.timeButton}
                            tabIndex={-1}
                            icon={<Clock color="#4a4a4a" />}
                            innerStyle={{ width: '16px', height: '14px', marginTop: '-2px' }}
                            onTouchTap={this.handleTouchTapButton}
                            style={styles.timePickerIconStyle}
                            disabled={disabled || readonly}
                        />
                    </div>
                    <LUXPopoverController
                        anchorEl={this.myRefs.root.current}
                        animation={LUXPopoverAnimationFromTop}
                        open={this.state.open}
                        onRequestClose={this.handleBlur}
                        useLayerForClickAway={false}
                    >
                        <ClickAwayListener onClickAway={this.state.open && this.componentClickAway2}>
                            <div
                                id="scrollBox"
                                ref={this.myRefs.scrollBox}
                                onScroll={this.handleScroll}
                                onKeyDown={this.handleKeyDown}
                                style={Object.assign({}, styles.timePickerResultBoxStyle, timePickerResultBoxStyle)}
                            >
                                <div style={{ zoom: 1 }}>
                                    <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                                        {this.handleListElement()}
                                    </ul>
                                </div>
                            </div>
                        </ClickAwayListener>
                    </LUXPopoverController>
                </div>
            </ClickAwayListener>
        );
    }
}

export default LUXTimePicker
