import * as React from 'react';
import { CustomPicker } from 'react-color';
import Pointer from './Pointer'
import OBTColorPickerField from './OBTColorPickerField'
const styles = require('./OBTCustomColorPicker.module.scss');


class OBTCustomColorPicker extends React.Component {
    state = {
        nowColor: this.props.hex
    }
    render() {
        const { Saturation } = require('react-color/lib/components/common');
        const { Hue } = require('react-color/lib/components/common');
        return (
            <>
                <div className={styles.root}>
                    <div className={styles.top}>
                        <div className={styles.palette}>
                            <Saturation
                                {...this.props}
                                onChange={this.props.onChange} />
                        </div>
                        <div className={styles.selectColor} >
                            <Hue {...this.props} direction={'vertical'}
                                onChange={this.props.onChange} pointer={Pointer} />
                        </div>
                    </div>
                    <div className={styles.bottom} >
                        <OBTColorPickerField
                            onChange={this.props.onChange}
                            rgb={this.props.rgb}
                            hsv={this.props.hsv}
                            hex={this.props.hex}
                        />
                    </div>
                    <div className={styles.newNowColor}>
                        새 색 <div className={styles.newColor} style={{ backgroundColor: this.props.hex }}></div>
                        <div className={styles.nowColor} style={{ backgroundColor: this.state.nowColor }}></div> 현재 색
                    </div>
                </div>
            </>
        )
    }
};

export default CustomPicker(OBTCustomColorPicker)