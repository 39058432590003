import React, { Component, Fragment } from 'react';
import '../www/css/common.scss';
import '../www/css/animate.scss';
import '../www/css/orbit.scss';
import '../www/css/orbitMenu.scss';
import MainIntroBg from './orbitUnit/OrbitBg';
import MainIntroMenu from './orbitUnit/OrbitMenu';
import MainIntroPlanetLogo from './orbitUnit/MainIntroPlanetLogo';

export default class MainIntro extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <Fragment>
                {/* 메인배경 */}
                <MainIntroBg />
                {/* 메인 텍스트 로고 */}
                {/* <MainIntroTextLogo /> */}
                {/* 메인 로고 */}
                <MainIntroPlanetLogo />
                {/* 메인 메뉴 */}
                <MainIntroMenu />
                {/* copy */}
                <div className="copyright animated1s fadeInUp delay15s">&copy; 2020 by DOUZONE ICT GROUP. All rights reserved.</div>
            </Fragment>
        );
    }
}


