import React from 'react';
import { IconType, NodeType } from './Breadcrumb';

const styles = require("./Breadcrumb.module.scss");

interface IBreadcrumbItem {
    item: NodeType,
    icons: IconType,
    childNode?: NodeType[],
    hasNextNode?: boolean,
    dropdownTitle?: string,
    onItemClick: (e) => void,
}

interface IState {
    dropDownOpen: boolean,
}

export default class BreadcrumbItem extends React.Component<IBreadcrumbItem, IState>{
    state: IState = {
        dropDownOpen: false,
    };

    handleNodeClick = (e: React.MouseEvent) => {
        this.props.onItemClick(e);
    };

    toggleDropdownOpen = () => {
        const { dropDownOpen } = this.state;
        this.setState({ dropDownOpen: !dropDownOpen });
    };

    handleDropdownClick = () => {
        this.toggleDropdownOpen();
    };

    renderNode = () => {
        const { arrowRightIcon, listOpenIcon, listCloseIcon } = this.props.icons;
        const { dropDownOpen } = this.state;
        if (this.props.childNode) {
            return (
                <>
                    <span>{this.props.item.title}</span>
                    <img src={arrowRightIcon} />
                    <div
                        className={styles['node-item--end']}
                        onClick={this.handleDropdownClick}>
                        {this.props.dropdownTitle 
                            ? <span>{this.props.dropdownTitle}</span> 
                            : null}
                        <img src={dropDownOpen ? listCloseIcon : listOpenIcon} />
                        {dropDownOpen ? <div className={`${styles.dropdown} dropdown`}>
                            <ul>
                                {this.props.childNode.map((item, idx) =>
                                    <li data-id={item.id} 
                                        key={`${item.id}-${idx}`}
                                        onClick={this.handleNodeClick}>
                                        {item.title}
                                    </li>
                                )}
                            </ul>
                        </div> : null}
                    </div>
                </>
            );
        } else if (this.props.hasNextNode) {
            return <div className={styles['node-item']}>
                <span 
                    data-id={this.props.item.id} 
                    className={styles['node-item__prev']} 
                    onClick={this.handleNodeClick}>
                    {this.props.item.title}
                </span>
                <img src={arrowRightIcon} />
            </div>;
        } else {
            return <div className={styles['node-item']}>
                <span>{this.props.item.title}</span>
            </div>;
        }
    };

    render(): React.ReactNode {
        return (
            this.renderNode()
        );
    }
}