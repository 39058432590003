/**
 * OBTCodePickerDialog
 * @version 0.1
 * @author 나윤수
 * @see common.js
 */
import React, { Component } from 'react';
import uuidv4 from 'uuid/v4';
import { OBTDialog2 } from '../..';
import { CommonProps, Events } from '../../Common';
import OBTDataGrid, { OBTDataGridInterface } from '../../OBTDataGrid';
import { IColumn } from '../../OBTDataGrid/IColumn';
import * as GridEvents from '../../OBTDataGrid/OBTDataGridEvents';
import { GridType } from '../../OBTDataGrid/OBTDataGridInterface';
import { CodePickerSearchEventArgs } from '../Events/CodePickerSearchEventArgs';
import DefaultDialogConditionPanel, { IDefaultDialogConditionPanel, FactoryBaseDialogConditionPanel } from './DefaultDialogConditionPanel';
import { OrbitInternalLangPack } from '../../Common/Util';
import keycode from 'keycode'
import { ReadEventArgs } from '../../OBTDataGrid/OBTDataGridEvents';
import IBuiltInCodePicker from '../DataSource/IBuiltInCodePicker';

export interface IDefaultDialog extends CommonProps.width, CommonProps.height {
    /**
     * 다이얼로그가 보여지는지 여부
     */
    isShow: boolean,

    /**
     * 타이틀
     */
    title: string,

    /**
     * 서브타이틀
     */
    subTitle?: string,

    /**
     * 
     */
    dataItemsKey?: string,
    /**
     * 그리드에 바인딩되는 데이터,
     * onReadData, 콜백으로 지정가능
     */
    dataItems?: Array<Object>,

    /**
     * 선택한 아이템
     */
    value: Array<Object>,

    /**
     * 전체선택시 제외되는 아이템
     */
    exceptValue: Array<Object> | null,

    /**
     * 
     */
    isTotalSelect?: boolean,

    /**
     * 데이터 아이템의 코드프로퍼티
     */
    codeProperty: string,

    /**
     * 멀티선택 가능여부
     */
    canMultiSelect: boolean,

    /**
     * 그리드 컬럼 정보
     */
    gridColumns: IColumn[],

    /**
     * 
     */
    customSearchConditionComponent?: React.ComponentClass<IDefaultDialogConditionPanel> | null

    /** 
     * 
     */
    searchKeyword?: string,

    /**
     * 
     */
    paging?: boolean,

    /**
     * 
     */
    rowCountPerPage?: number,

    /**
     * 
     */
    initialRowCount?: number,

    /**
     * 
     */
    useTotalToEmpty?: boolean,

    /**
     * 
     */
    fetch?: any,

    /**
     * 
     */
    parameters?: any,

    /**
     * 
     */
    isLoading: boolean,

    /**
     * 
     */
    checkedRows?: any[],

    /**
     * 코드피커 인스턴스
     */
    codePicker?: IBuiltInCodePicker,

    /**
     * 다이얼로그 닫기버튼 클릭 콜백
     */
    onClickClose: (e: Events.EventArgs) => void,

    /**
     * 다이얼로그에서 검색버튼을 클랙했을때 검색파라미터를 넘기는 처리를 하면됨
     */
    onClickSearch: (evt: CodePickerSearchEventArgs) => void,

    /**
     * 선택한 아이템을 바꾼다.
     */
    onChangeSelectedItems: (items: any[]) => void,

    /**
     * onChangeSelectedItems로 선택한 아이템을 확정한다.
     */
    onConfirmSelectedItems: (totalSelect?: boolean, exceptItems?: any[] | null,) => void,

    /**
     * items을 선택한 아이템으로 확정한다.
     */
    onSelectAndConfirmItems?: (items: any[], exceptItems?: any[] | null, totalSelect?: boolean) => void,

    /**
     * 이 콜백을 사용할 시 props의 dataItems는 무시된다.
     * 페이징에서 사용
     * dataItems를 읽어온다.
     */
    onReadDataItems?: (e: {
        target: any,
        event: GridEvents.ReadEventArgs,
        parameters?: any | null,
        keyword?: any
    }) => Promise<any[] | null | undefined>,

    /**
     * 
     */
    dialogParameters?: (() => any),

    onChangeKeyword?: ((keyword: string | null) => void),
}

interface IState {
    grid: OBTDataGridInterface,
    selectLabelText: '확인' | '확인',
    conditionHeight: number,
    beforeCheckedRows: any[],
    /**
     * 페이징 모드에서만 사용
     */
    parametersForPaging: any | null,
    /**
     * 페이징 모드에서만 사용
     */
    searchKeywordForPaging: string | null
}

export default class DefaultDialog extends Component<IDefaultDialog, IState> {
    conditionRef = React.createRef<any>();
    conditionWrapperRef = React.createRef<HTMLDivElement>();

    state: IState = {
        selectLabelText: OrbitInternalLangPack.getText('WE000000054', '확인'),
        grid: new OBTDataGridInterface(uuidv4(), {
            gridType: GridType.gridView,
            editable: false,
            checkable: this.props.canMultiSelect,
            appendable: false,
            rowSelectMode: true,
            useInternalStyle: false,
            paging: this.props.paging ? this.props.paging : false,
            rowCountPerPage: this.props.rowCountPerPage,
            initialRowCount: this.props.initialRowCount,
            eachRowResizable: false,
            isSystemGrid: true,
        }).setColumns(this.props.gridColumns).setProvider({
            read: (gridEvent: ReadEventArgs) => {
                if (this.props.onReadDataItems) {
                    return this.props.onReadDataItems({
                        target: this,
                        event: gridEvent,
                        parameters: this.state.parametersForPaging,
                        keyword: this.state.searchKeywordForPaging,
                    }).then((data) => {
                        if (gridEvent.pagingInfo && gridEvent.pagingInfo.currentPage !== 0) {
                            return data;
                        }
                        return this.afterProcessData(data || []);
                    });
                } else {
                    return new Promise((resolve) => {
                        resolve(this.afterProcessData(this.props.dataItems || []));
                    });
                }
            }
        }),
        conditionHeight: 0,
        beforeCheckedRows: this.props.value,
        parametersForPaging: null,
        searchKeywordForPaging: null
    }

    public static defaultProps = {
        width: "350px",
        height: "400px",
        paging: false
    };

    componentDidMount() {
        if (this.isTotalToEmptyMode()) {
            this.state.grid.setCheckable(true, this.props.searchKeyword ? this.props.searchKeyword.length === 0 : true)
        }

        // 이벤트 콜백 등록
        this.state.grid.onAfterCheck.add(this.handleGridAfterCheck);
        this.state.grid.onAfterHeaderCheck.add(this.handleGridAfterHeaderCheck);
        this.state.grid.onAfterSelectChange.add(this.handleGridAfterSelectChange);
        this.state.grid.onDataCellDblClicked.add(this.handleDataCellDblClicked);
        this.state.grid.onSelectByEnter.add(this.handleSelectByEnter);
        this.state.grid.onAfterChangePageNumber.add(this.handleAfterChangePageNumber);
        this.state.grid.onKeyDown.add(this.handleGridKeyDown);
        this.state.grid.onAfterRead.add(this.handleAfterRead)

        // 사용자 정의 그리드이벤트 등록
        if (this.props.codePicker && this.props.codePicker.gridEvents) {
            Object.keys(this.props.codePicker.gridEvents).forEach((eventName: string) => {
                this.state.grid[eventName].add(this.props.codePicker!.gridEvents[eventName]);
            })
        }

        if (this.props.useTotalToEmpty === true) {
            // 전체선택 모드에서는 헤더체크가 유지된다.
            this.state.grid.setCheckBar({
                syncHeadCheck: false
            });
        }

        if(this.props.codePicker && this.props.codePicker.onAfterOpen) {
            this.props.codePicker.onAfterOpen({
                target: this,
                grid: this.state.grid
            })
        }

        if (this.props.isShow === true) {
            this.setState({
                searchKeywordForPaging: this.props.searchKeyword || null
            }, () => {
                this.state.grid.readData()
            })
        }
    }

    componentDidUpdate(prevProps: IDefaultDialog) {
        if (this.props.canMultiSelect !== prevProps.canMultiSelect) {
            this.state.grid.setCheckable(this.props.canMultiSelect, this.props.canMultiSelect);
        }

        if (this.props.paging !== true && this.props.dataItemsKey !== prevProps.dataItemsKey) {
            if (this.props.isShow === true) {
                this.state.grid.readData()
            }
        }

        if (this.conditionWrapperRef.current) {
            const conditionHeight = this.conditionWrapperRef.current.clientHeight;
            if (conditionHeight !== this.state.conditionHeight) {
                this.setState({
                    conditionHeight: conditionHeight
                })
            }
        }
    }

    /**
     * api조회된 데이터를 코드피커의 동작에 맞게 커스텀 
     * @param source 
     */
    afterProcessData(source: any[]) {
        if (this.props.canMultiSelect === true) {
            // TODO: check 아이템을 컬럼으로 관리한다면 아이템 순회를 최소화할수있음
            source = source.filter((dataItem) => {
                const exist = this.state.beforeCheckedRows.some(checkedRow => {
                    return checkedRow[this.props.codeProperty] === dataItem[this.props.codeProperty];
                });

                return !exist;
            });

            const withValueSource = (this.state.beforeCheckedRows || []).concat(source || []);
            return withValueSource;
        }

        return source;
    }

    /**
     * 조회된 데이터에서 value로 선택된 아이템을 체크한다.
     * 조회된 이후에 호출됨
     */
    private checkSelectedItems = () => {
        this.state.grid.setRowChangeAfterCheck(false);

        if (this.isTotalToEmptyMode() && this.props.isTotalSelect) {
            // useTotalSelect, 전체선택일때 헤더체크, 전체 아이템 체크, excepValue에 포함된 아이템은 체크해제

            this.state.grid.checkAll();
            this.state.grid.gridView.setAllCheck(true)

            this.state.grid.getRows().forEach((dataItem, index) => {
                const isExceptItem = this.props.exceptValue!.some(exceptItem => {
                    return exceptItem[this.props.codeProperty] === dataItem[this.props.codeProperty];
                });

                if (isExceptItem) {
                    this.state.grid.setCheck(index, false);
                }
            });
        } else {
            let checkCount = 0;
            this.state.grid.getRows().forEach((dataItem, index) => {
                const isSelectedItem = this.state.beforeCheckedRows.some(checkedRow => {
                    return checkedRow[this.props.codeProperty] === dataItem[this.props.codeProperty];
                });

                if (isSelectedItem) {
                    checkCount++;
                    this.state.grid.setCheck(index, true, false);
                }
            });

            if (checkCount === 0) {
                this.state.grid.unCheckAll();
                this.state.grid.gridView.setAllCheck(false)
            } else {
                const checkedRows = this.state.grid.getCheckedRows();
                this.setState({
                    beforeCheckedRows: checkedRows
                })
                this.props.onChangeSelectedItems(checkedRows);
            }
        }

        this.state.grid.setRowChangeAfterCheck(true);
    }

    private handleGridAfterCheck = (e: GridEvents.AfterCheckEventArgs) => {
        if (this.props.isShow === false) {
            return;
        }

        const checkedRows = this.state.grid.getCheckedRows();
        this.setState({
            beforeCheckedRows: checkedRows
        })
        this.props.onChangeSelectedItems(checkedRows);
    }

    private handleGridAfterHeaderCheck = (e: GridEvents.AfterHeaderCheckEventArgs) => {
        if (this.props.isShow === false) {
            return;
        }

        if (this.isTotalToEmptyMode() === false) {
            const checkedRows = this.state.grid.getCheckedRows();
            this.props.onChangeSelectedItems(checkedRows);
            this.setState({
                beforeCheckedRows: checkedRows
            })
        }
    }

    /**
     * 전체 선택 모드인지 체크
     */
    private isTotalToEmptyMode() {
        return (this.props.useTotalToEmpty === true && this.props.paging === true && this.props.canMultiSelect === true);
    }

    private handleGridAfterSelectChange = (e: GridEvents.AfterSelectChangeEventArgs) => {
        if (this.props.isShow === false) {
            return;
        }

        if (e.isRowChanged) {
            if (this.props.canMultiSelect === false && e.rowIndex >= 0) {
                this.props.onChangeSelectedItems([this.state.grid.getRow(e.rowIndex)])
            }
        }
    }

    /**
     * 그리드: 더블클릭
     * @param e 
     */
    private handleDataCellDblClicked = (e: GridEvents.DataCellDoubleClickedEventArgs) => {
        if (this.props.isShow === false) {
            return;
        }

        if (this.props.onSelectAndConfirmItems) {
            this.props.onSelectAndConfirmItems([e.values], null, false);
        } else {
            this.props.onChangeSelectedItems([e.values]);
            setTimeout(() => {
                this.props.onConfirmSelectedItems(false);
            }, 0)
        }
    }

    private handleSelectByEnter = (e: GridEvents.SelectByEnterEventArgs) => {
        if (!e.data) {
            return;
        }

        if (this.props.isShow === false) {
            return;
        }

        if (this.props.onSelectAndConfirmItems) {
            this.props.onSelectAndConfirmItems([e.data], null, false);

            if (this.props.canMultiSelect === true) {
                let checkedData = this.state.grid.getCheckedRows()
                if (checkedData.length === 0) {
                    checkedData = [e.data];
                }
                this.props.onSelectAndConfirmItems(checkedData, null, false);

                const isTotalSelect = this.isTotalToEmptyMode() && this.state.grid.gridView.isAllChecked() === true;
                if (isTotalSelect) {
                    this.props.onConfirmSelectedItems(
                        isTotalSelect,
                        isTotalSelect ? this.state.grid.getUnCheckedRows() : null
                    );
                }
            }
        } else {
            this.props.onChangeSelectedItems([e.data]);
            setTimeout(() => {
                this.props.onConfirmSelectedItems(false);
            }, 0)
        }
    }

    private handleAfterOpen = () => {
        if (this.conditionRef.current && this.conditionRef.current.focus) {
            this.conditionRef.current.focus();
        }
    }

    /**
     * 그리드: 페이징바뀔때
     * @param evt 
     */
    private handleAfterChangePageNumber = (evt: GridEvents.AfterPageNumberChangeArgs) => {
        this.state.grid.setRowChangeAfterCheck(false);

        if (this.isTotalToEmptyMode()) {
            if (this.state.grid.gridView.isAllChecked() === true) {
                for (let index = evt.pagingInfo.startRowIndex; index < this.state.grid.getRowCount(); index++) {

                    const codeValue = this.state.grid.getValue(index, this.props.codeProperty);

                    if ((this.props.exceptValue || []).some((item => item[this.props.codeProperty] === codeValue)) === false) {
                        this.state.grid.setCheck(index, true);
                    }
                }

            }
        }

        this.state.grid.setRowChangeAfterCheck(true);
    }

    private handleAfterRead = (evt: GridEvents.AfterReadEventArgs) => {
        if (this.conditionRef.current && this.conditionRef.current.focus) {
            this.conditionRef.current.focus();
        } else {
            this.state.grid.focus();
        }

        if (this.props.canMultiSelect === false && evt.data.length === 0) {
            // 조회된 상태에서 검색조건바꿔서 재조회 -> 검색결과없음 -> 확인 버튼 클릭시 이전 조회된 상태의 데이터가 선택되는 문제로 들어간 처리 
            this.props.onChangeSelectedItems([]);
        } else if (this.props.canMultiSelect === false) {
            // 최초 조회시 선택된 그리드의 행을 selectedItem으로 설정한다. 
            const item = this.state.grid.getRow(this.state.grid.getSelectedIndex());
            if (item) {
                this.props.onChangeSelectedItems([item]);
            }
        } else {
            this.checkSelectedItems();
        }

        if(this.props.codePicker && this.props.codePicker.onAfterRead) {
            this.props.codePicker.onAfterRead({
                target: this,
                grid: this.state.grid,
                event: evt
            })
        }
    }

    private handleGridKeyDown = (evt: GridEvents.KeyDownEventArgs) => {
        const key = keycode(evt.keyCode)

        if (key === 'up'
            && this.state.grid.getSelectedIndex() === 0
            && this.conditionRef.current
            && this.conditionRef.current.focus) {
            this.conditionRef.current.focus();
        }

        if (this.props.canMultiSelect === true) {
            if (key === 'space') {
                const selectedIndex = this.state.grid.getSelectedIndex()
                const getCheck = this.state.grid.getCheck(selectedIndex)

                if (getCheck === false) {
                    this.state.grid.setCheck(selectedIndex, true)
                } else {
                    this.state.grid.setCheck(selectedIndex, false)
                }
            }
        }
    }

    /**
     * 다이얼로그: 닫기 콜백
     * @param evt 
     */
    private handleClickCloseDialogButton = (evt: Events.EventArgs) => {
        if (this.props.isShow === false) {
            return;
        }

        this.props.onClickClose(evt);
    }

    /**
     * 조회조건: 검색 콜백
     * @param evt 
     */
    private handleClickSearch = (evt: CodePickerSearchEventArgs) => {
        if (this.props.isShow === false) {
            return;
        }

        this.props.onChangeKeyword && this.props.onChangeKeyword(null);

        if (this.isTotalToEmptyMode()) {
            this.state.grid.setCheckable(true, true);
        }

        if (this.props.paging === true) {
            // 페이징 모드이면 파리미터 세팅후 readData
            this.setState({
                parametersForPaging: evt.searchParameter,
                searchKeywordForPaging: null,
            }, () => {
                this.state.grid.readData();
            })
        } else {
            this.props.onClickSearch(evt);
        }
    }

    /**
     * 다이얼로그: 선택 버튼 콜백
     * @param evt 
     */
    private handleClickSelectDialogButton = (evt: Events.EventArgs) => {
        const isTotalSelect =
            this.isTotalToEmptyMode() && this.state.grid.gridView.isAllChecked() === true;

        this.props.onConfirmSelectedItems(
            isTotalSelect,
            isTotalSelect ? this.state.grid.getUnCheckedRows() : null
        );
    }

    dialogButtons = [
        {
            key: 'cancel',
            labelText: OrbitInternalLangPack.getText('WE000001945', '취소'),
            theme: OBTDialog2.ButtonTheme.default,
            onClick: this.handleClickCloseDialogButton,
            isClose: true,
        },
        {
            key: 'confirm',
            labelText: this.state.selectLabelText,
            theme: OBTDialog2.ButtonTheme.blue,
            onClick: this.handleClickSelectDialogButton,
        },
    ]

    /**
     * 렌더링: 조회조건
     */
    private renderConditionPanel = () => {
        if (this.props.codePicker && this.props.codePicker.dialogConditionItems) {
            return <FactoryBaseDialogConditionPanel
                ref={this.conditionRef}
                isDialogOpen={this.props.isShow}
                keyword={this.props.searchKeyword}
                parameters={this.props.parameters}
                isLoading={this.props.isLoading}
                onClickSearch={this.handleClickSearch}
                dialogParameters={this.props.dialogParameters}
                factoryItems={this.props.codePicker.dialogConditionItems}
                fetch={this.props.fetch}
            />
        }

        let Component = this.props.customSearchConditionComponent ?
            this.props.customSearchConditionComponent : DefaultDialogConditionPanel

        return (
            <Component
                ref={this.conditionRef}
                isDialogOpen={this.props.isShow}
                keyword={this.props.searchKeyword}
                parameters={this.props.parameters}
                isLoading={this.props.isLoading}
                onClickSearch={this.handleClickSearch}
                dialogParameters={this.props.dialogParameters}
                fetch={this.props.fetch}
            />
        );
    }

    render() {
        return (
            <OBTDialog2
                title={this.props.title}
                open={this.props.isShow}
                subTitle={this.props.subTitle}
                width={this.props.width}
                height={this.props.height}
                buttons={this.dialogButtons}
                onAfterOpen={this.handleAfterOpen}
            >
                <div style={{
                    width: '100%',
                    height: '100%',
                }}
                >
                    <div ref={this.conditionWrapperRef} style={{
                        marginBottom: 10
                    }}>
                        {this.renderConditionPanel()}
                    </div>
                    <div style={{
                        width: '100%',
                        height: 'calc(100% - ' + this.state.conditionHeight + 'px )',
                    }}>
                        <OBTDataGrid
                            interface={this.state.grid}
                        />
                    </div>
                </div>
            </OBTDialog2>
        )
    }
}
