/**
 * @version 0.2
 * @author 박지원
 * @see common.js
 */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { CompositeProps, Util, Events, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import OBTButton, { ButtonTheme, ButtonType } from '../OBTButton';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { OBTContext } from '../OBTPageContainer/OBTPageContainer';
import { OrbitInternalLangPack } from '../Common/Util';
import { ImageUrl } from '../OBTButton/OBTButton';
import { ResizeSensor } from 'css-element-queries';

const styles = require('./OBTDialog2.module.scss');

export class MouseEventArgs extends Events.MouseEventArgs {
    constructor(target: any, event: React.MouseEvent, public readonly type: string) {
        super(target, event);
    }
    toString(): string { return JSON.stringify({ target: this.target ? 'object' : this.target, event: this.event!.toString(), type: this.type }); }
}

interface IOBTDialog extends CompositeProps.Default {
    /**
     * true일때 Dialog가 열립니다.
     */
    open?: boolean,

    /**
     * Dialog의 제목을 설정합니다.
     */
    title: string,

    /**
     * Dialog의 부제목을 설정합니다.
     */
    subTitle?: string,

    /**
     * 우측 상단 X 버튼(닫기)을 제외한 채 타이틀을 지정할때 설정합니다.
     */
    hasTitleCloseButton?: boolean,

    /**
     * Dialog의 크기 타입을 설정합니다.
     */
    type?: DialogType,

    /**
     * Dialog의 하단의 버튼을 설정합니다.
     */
    buttons?: Array<IButton>,

    /**
     * Dialog가 open 되었을 때 실행되는 함수 입니다.
     */
    onAfterOpen?: (e) => void;
}

export interface ISampleButton {
    Confirm: (onConfirm) => IButton,
    Cancel: (onCancel) => IButton,
    Save: (onSave) => IButton,
    Close: (onClose) => IButton,
}

export interface IButton {
    key: string,
    onClick?: (e: any) => void,
    theme?: ButtonTheme,
    labelText?: string,
    type?: ButtonType,
    visible?: boolean,
    disabled?: boolean,
    isClose?: boolean,
    imageUrl?: ImageUrl,
    imageTagClassName?: string,
    buttonClassName?: string,
    width?: string,
    height?: string;
}

export interface ISampleButtons {
    ConfirmAndCancel: (onConfirm, onCancel) => [IButton, IButton],
    SaveAndClose: (onSave, onClose) => [IButton, IButton];
}

interface IRenderButton {
    items: any,
    propButtons: IButton[] | undefined,
}

export enum DialogType {
    /**
     *  1050px 720px
     */
    'big' = 'big',

    /**
     *  740px 620px
     */
    'default' = 'default',

    /**
     *  440px 520px
     */
    'small' = 'small',
}

class OBTDialog2 extends React.Component<IOBTDialog>{
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        { name: "open", type: CommonType.boolean, description: "true일때 Dialog가 열립니다.", optional: true },
        { name: "title", type: CommonType.string, description: "Dialog의 제목을 설정합니다." },
        { name: "subTitle", type: CommonType.string, description: "Dialog의 부제목을 설정합니다.", optional: true },
        {
            name: "hasTitleCloseButton", type: CommonType.boolean, optional: true, default: true,
            description: "우측 상단 X 버튼(닫기)을 제외한 채 타이틀을 지정할때 설정합니다. false로 주고 타이틀 없이 서브타이틀만 넣을 경우 우측 상단에 닫기 버튼 없는 다이얼로그가 생성됩니다."
        },
        {
            name: "type", type: toEnumType(DialogType), default: 'default', description: "Dialog의 크기 타입을 설정합니다."
                + "\n big : 1050px X 720px"
                + "\n default : 740px X 620px"
                + "\n small : 440px X 520px", optional: true
        },
        {
            name: "buttons", type: "Array<IButton>", optional: true, description: "Dialog의 하단의 버튼을 설정합니다.\n"
                + "\nOBTDialog.Button.Confirm | OBTDialog.Button.Cancel | OBTDialog.Button.Save | OBTDialog.Button.Close 배열로 감싸서 사용"
                + "\nex) buttons = {[OBTDialog.Button.Confirm(this.Confirm,OBTButton.Theme.blue)]}\n"

                + "\nOBTDialog.Buttons.ConfirmAndCancel | OBTDialog.Buttons.SaveAndClose 단일 사용 가능"
                + "\nex) buttons = {OBTDialog.Buttons.ConfirmAndCancel(this.Confirm,this.Cancel)}\n"

                + "\nCancel, Close는 기본적으로 isClose={true} 입니다."

                + "\nonClick의 funciont(target, event, type) 중 type은 "
                + "\nX버튼: closeButton, 하단버튼: button, 배경: background, 키보드 esc: esc\n"
        },
        {
            name: "onAfterOpen", type: CommonType.function, parameters: {
                name: "e",
                type: CommonType.any
            }, optional: true, description: "Dialog가 open 되었을 때 실행되는 함수 입니다."
        }
    );

    public static IButtonDefinitions = createPropDefinitions(
        { name: "key", description: "키", type: ['string', 'name'] },
        {
            name: "onClick", description: "Click 시 발생하는 Callback 함수입니다.", type: CommonType.function, parameters: {
                name: 'e',
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    event: { type: 'MouseEvent', description: '이벤트 객체' }
                }
            }
        },
        { name: "labelText", description: "버튼 이름", type: CommonType.boolean },
        { name: "isClose", description: "ESC, X버튼 클릭시 닫힘이벤트가 활성화되며 true인 요소의 onClick이 호출된다.", type: CommonType.boolean, optional: true },
        { name: "type", type: toEnumType(ButtonType), description: "버튼 타입", default: "default", optional: true },
        { name: "theme", type: toEnumType(ButtonTheme), default: 'default', description: '버튼 테마', optional: true },
        { name: 'visible', description: "false시 하단 버튼이 안보임", type: CommonType.boolean, optional: true },
        { name: "disabled", type: CommonType.boolean, description: "true시 해당 버튼은 비활성화된다.", optional: true },
        {
            name: 'imageUrl', type: [CommonType.image, {
                normal: { type: CommonType.image, description: '기본상태 이미지 Url' },
                over: { type: CommonType.image, optional: true, description: 'over상태 이미지 Url' },
                press: { type: CommonType.image, optional: true, description: 'press상태 이미지 Url' },
                disabled: { type: CommonType.image, optional: true, description: '비활성상태 이미지 Url' }
            }], optional: true, description: '버튼에 imageUrl을 넣을 때 사용하는 속성입니다.\nnormal에 해당하는 이미지 Url 혹은 Json 객체를 통해 이미지를 지정할 수 있습니다.'
        },
        {
            name: 'imageTagClassName',
            type: CommonType.string,
            description: '들어갈 이미지에 클래스 네임을 부여할 수 있습니다. 이미지 크기와 같은 조정을 할 수 있습니다. 사용하지 않을 시, 컴포넌트 내부적으로 사용하고 있는 클래스네임을 사용 (기본 이미지 크기 18 x 18)',
            optional: true
        },
        {
            name: 'buttonClassName',
            type: CommonType.string,
            description: '버튼 컴포넌트에 클래스 네임을 부여할 수 있습니다. 사용하지 않을 시, 컴포넌트 내부적으로 사용하고 있는 클래스네임을 사용',
            optional: true
        },
        {
            name: 'width',
            type: CommonType.string,
            description: '버튼 너비를 조정할 수 있습니다. 특히, 이미지가 있는 버튼과 이미지가 없는 버튼이 함께 있을 시에 너비가 맞지 않게 되는데, 이를 조정할 수 있습니다.',
            optional: true
        },
        {
            name: 'height',
            type: CommonType.string,
            description: '버튼 높이를 조정할 수 있습니다.',
            optional: true
        }
    );
    public static Button: ISampleButton = {
        Confirm: (onConfirm?: (e) => void, theme?: ButtonTheme) => {
            return {
                key: 'confirm',
                onClick: onConfirm,
                labelText: OrbitInternalLangPack.getText('WE000000054', '확인'),
                type: OBTButton.Type.big,
                theme: theme ? theme : ButtonTheme.default
            };
        },
        Cancel: (onCancel?: (e) => void, theme?: ButtonTheme) => {
            return {
                key: 'cancel',
                onClick: onCancel,
                isClose: true,
                labelText: OrbitInternalLangPack.getText('WE000001945', '취소'),
                type: OBTButton.Type.big,
                theme: theme ? theme : ButtonTheme.default
            };
        },
        Save: (onSave?: (e) => void, theme?: ButtonTheme) => {
            return {
                key: 'save',
                onClick: onSave,
                labelText: OrbitInternalLangPack.getText('WE000004653', '저장'),
                type: OBTButton.Type.big,
                theme: theme ? theme : ButtonTheme.default
            };
        },
        Close: (onClose?: (e) => void, theme?: ButtonTheme) => {
            return {
                key: 'close',
                onClick: onClose,
                isClose: true,
                labelText: OrbitInternalLangPack.getText('WE000001970', '닫기'),
                type: OBTButton.Type.big,
                theme: theme ? theme : ButtonTheme.default
            };
        },
    };

    public static Buttons: ISampleButtons = {
        ConfirmAndCancel: (onConfirm?: (e) => void, onCancel?: (e) => void) => {
            return [
                {
                    key: 'cancel',
                    onClick: onCancel,
                    isClose: true,
                    labelText: OrbitInternalLangPack.getText('WE000001945', '취소'),
                    type: OBTButton.Type.big
                },
                {
                    key: 'confirm',
                    onClick: onConfirm,
                    labelText: OrbitInternalLangPack.getText('WE000000054', '확인'),
                    type: OBTButton.Type.big,
                    theme: OBTButton.Theme.blue
                }
            ];
        },
        SaveAndClose: (onSave?: (e) => void, onClose?: (e) => void) => {
            return [
                {
                    key: 'close',
                    onClick: onClose,
                    isClose: true,
                    labelText: OrbitInternalLangPack.getText('WE000001970', '닫기'),
                    type: OBTButton.Type.big
                },
                {
                    key: 'save',
                    onClick: onSave,
                    labelText: OrbitInternalLangPack.getText('WE000004653', '저장'),
                    type: OBTButton.Type.big,
                    theme: OBTButton.Theme.blue
                }
            ];
        },
    };

    /**
     * Dialog의 타입을 지정합니다. 기본 값은 'default' 입니다.
     */
    static Type = DialogType;

    /**
     * Button의 테마를 설정합니다
     */
    static ButtonTheme = ButtonTheme;

    /**
     * 버튼의 타입을 지정합니다. big | small | default | icon
     */
    static ButtonType = ButtonType;

    /**
     * props의 기본 값을 세팅합니다.
     */
    static defaultProps = {
        frozen: false,
        hasTitleCloseButton: true,
        type: DialogType.default
    };

    /**
     * @internal
     */
    private invokeButtonClickEvent = (onClick: any, event: any, type: string): void => {
        Util.invokeEvent<MouseEventArgs>(onClick, new MouseEventArgs(this, event, type));
    };

    /**
     * @internal
     */
    private resizeSensor: ResizeSensor | null = null;

    /**
     * @internal
     */
    private myRefs = {
        root: React.createRef<HTMLDivElement>(),
        dialogBody: React.createRef<HTMLDivElement>(),
        dialogRoot: React.createRef<HTMLDivElement>(),
        dialogRef: React.createRef<HTMLDivElement>()
    };

    state = {
        hasError: false,
        posX: 0,
        posY: 0,
    };

    componentDidMount() {
        if (this.props.open) {
            if (this.myRefs.dialogBody.current)
                this.resizeSensor = new ResizeSensor(this.myRefs.dialogBody.current, this.handleResize);
            window.addEventListener('keyup', this.handleWindowKeyUp);
            this.afterOpen();
            this.setDialogPosition();
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.open !== prevProps.open) {
            if (this.props.open && this.myRefs.dialogBody.current) {
                this.resizeSensor = new ResizeSensor(this.myRefs.dialogBody.current, this.handleResize);
                window.addEventListener('keyup', this.handleWindowKeyUp);
                this.afterOpen();
                this.setDialogPosition();
            }

            if (!this.props.open && this.resizeSensor) {
                window.removeEventListener('keyup', this.handleWindowKeyUp);
                this.resizeSensor.detach(this.handleResize);
            }

        }
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.handleWindowKeyUp);
        if (this.resizeSensor) {
            this.resizeSensor.detach(this.handleResize);
        }
    }

    setDialogPosition = () => {
        if (this.myRefs.dialogRef.current) {
            const dialogHeight = this.myRefs.dialogRef.current.offsetHeight ?? 0;
            const dialogWidth = this.myRefs.dialogRef.current.offsetWidth ?? 0;

            const position = {
                top: (window.innerHeight - dialogHeight) / 2,
                left: (window.innerWidth - dialogWidth) / 2
            };

            if (dialogHeight > window.innerHeight) position.top = 0;
            if (dialogWidth > window.innerWidth) position.left = 0;

            this.myRefs.dialogRef.current.style.setProperty('--position-top', `${position.top}px`);
            this.myRefs.dialogRef.current.style.setProperty('--position-left', `${position.left}px`);
            this.setState({ posX: position.left });
            this.setState({ posY: position.top });
        }
    };

    handleResize = () => {
        const dialogRef = this.myRefs.dialogRef.current;
        if (this.props.open && dialogRef) {
            const position = {
                left: dialogRef.getBoundingClientRect().left,
                top: dialogRef.getBoundingClientRect().top,
                right: dialogRef.getBoundingClientRect().right,
                bottom: dialogRef.getBoundingClientRect().bottom
            };

            if (position.left === this.state.posX && position.top === this.state.posY) {
                position.left = (window.innerWidth - dialogRef.offsetWidth) / 2;
                position.top = (window.innerHeight - dialogRef.offsetHeight) / 2;
                this.setState({ posX: position.left, posY: position.top });
            }

            if (position.right > window.innerWidth && position.left > 0) {
                position.left = window.innerWidth - dialogRef.offsetWidth;
            } else if (position.left < -1) {
                position.left = 0;
            }

            if (position.bottom > window.innerHeight && position.top > 0) {
                position.top = window.innerHeight - dialogRef.offsetHeight;
            } else if (position.top < -1) {
                position.top = 0;
            }

            dialogRef.style.setProperty('--position-top', `${position.top}px`);
            dialogRef.style.setProperty('--position-left', `${position.left}px`);
        }
    };

    private afterOpen = () => {
        if (this.props.open) {
            if (this.myRefs.root.current) {
                this.myRefs.root.current.focus();
            }
            if (document.body &&
                this.myRefs.dialogRoot && this.myRefs.dialogRoot.current) {
                // IE 에서 scope 지원안함.
                // `${(document.body.querySelectorAll(':scope > .obtdialog.open') || []).length}`;
                this.myRefs.dialogRoot.current.style.zIndex = `${Array.from(document.body.children).filter(item =>
                    item.classList.contains('obtdialog') &&
                    item.classList.contains('open')).length}`;
            }
            Util.invokeEvent<Events.EventArgs>(this.props.onAfterOpen, new Events.EventArgs(this));
        }
        this.handleAfterOpen();
    };

    handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const context = {
            position: {
                x: e.clientX,
                y: e.clientY
            },
            current: {
                x: 0,
                y: 0
            }
        };

        const handleMouseMove = (e: MouseEvent) => {
            const dialogRef = this.myRefs.dialogRef.current;
            if (dialogRef) {
                context.current.x = e.clientX - context.position.x;
                context.current.y = e.clientY - context.position.y;
                context.position.x = e.clientX;
                context.position.y = e.clientY;

                let myPositionLeft = dialogRef.offsetLeft + context.current.x;
                let myPositionTop = dialogRef.offsetTop + context.current.y;

                dialogRef.style.setProperty('--position-top', `${myPositionTop}px`);
                dialogRef.style.setProperty('--position-left', `${myPositionLeft}px`);
            };
        };

        const clearDragEvent = () => {
            window.removeEventListener('mousemove', handleMouseMove, true);
            window.removeEventListener('mouseup', clearDragEvent, true);
        };

        window.addEventListener('mousemove', handleMouseMove, true);
        window.addEventListener('mouseup', clearDragEvent, true);

    };

    /**
     * @internal
     */
    private close = (event: any, type: string) => {

        if (!this.props.buttons) {
            return;
        }
        const closeButton = this.props.buttons.find(item => item.isClose === true && item.onClick);
        if (closeButton)
            this.invokeButtonClickEvent(closeButton.onClick, event, type);
    };

    /**
     * @internal
     */
    private handleDimClickerClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.close(e, 'background');
    };

    /**
     * @internal
     */
    private handleButtonClick = (button: IButton, e: Events.MouseEventArgs) => {
        if (button.onClick)
            this.invokeButtonClickEvent(button.onClick, e, 'button');
        this.handleButtonClicked(button.key);
    };

    /**
     * @internal
     */
    private handleWindowKeyUp = (e: KeyboardEvent) => {
        if (this.myRefs.dialogRoot.current && e.key === 'Escape') {
            if (document.body) {
                const openedCount = Array.from(document.body.children).filter(item =>
                    item.classList.contains('obtdialog') &&
                    item.classList.contains('open')).length;
                if (this.myRefs.dialogRoot.current.style.zIndex && Number(this.myRefs.dialogRoot.current.style.zIndex) === openedCount) {
                    e.stopImmediatePropagation();
                    this.close(e, 'esc');
                }
            }
        }
    };

    /**
     * @internal
     */
    private _handleAfterOpen: Array<Function> = [];

    /**
     * @internal
     */
    private _handleButtonClicked: Function | undefined = undefined;

    /**
     * @internal
     */
    private handleClose = (e) => {
        this.close(e, 'handleClose');
    };


    handleAfterOpen = () => {
        if (Array.isArray(this._handleAfterOpen) && this._handleAfterOpen.length) {
            this._handleAfterOpen.forEach((func: Function) => {
                return func();
            });
        }
    };

    handleButtonClicked = (key: string) => {
        if (this._handleButtonClicked) {
            this._handleButtonClicked(key);
        }
    };

    getDefaultHeight = () => this.props.height ? this.props.height
        : this.props.type === DialogType.big
            ? '720px'
            : this.props.type === DialogType.default
                ? '620px'
                : this.props.type === DialogType.small
                    ? '520px'
                    : undefined;

    getDefaultWidth = () => this.props.width ? this.props.width
        : this.props.type === DialogType.big
            ? '1050px'
            : this.props.type === DialogType.default
                ? '740px'
                : this.props.type === DialogType.small
                    ? '440px'
                    : undefined;

    renderPropButton = () => {
        const renderButton: IRenderButton = {
            items: [],
            propButtons: this.props.buttons,
        };

        if (renderButton.propButtons) {
            renderButton.items = renderButton.propButtons.map(button => {
                if (button.visible === false) return null;

                return <OBTButton
                    width={button.width}
                    height={button.height}
                    className={Util.getClassNames(button.imageUrl
                        ? styles.buttonWrapper
                        : null, button.buttonClassName)}
                    key={button.key}
                    labelText={button.labelText}
                    disabled={button.disabled === true ? true : false}
                    theme={button.theme}
                    type={button.type ? button.type : OBTButton.Type.big}
                    onClick={(e) => this.handleButtonClick(button, e)}
                    imageUrl={button.imageUrl && button.imageUrl}
                    imageTagClassName={
                        button.imageTagClassName
                            ? button.imageTagClassName
                            : styles.useImageUrl}
                />;
            });
        }
        return renderButton.items;
    };


    renderComponent = (isPageVisible: boolean) => {
        this._handleAfterOpen = [];
        const children = React.Children.map(this.props.children, (child) => {
            if (React.isValidElement(child) &&
                typeof child.type !== 'string'
                && !Util.isOrbitComponent(child.type)
            ) {
                return React.cloneElement(child, {
                    onAfterOpen: (func: Function) => {
                        if (typeof func === 'function')
                            this._handleAfterOpen.push(func);
                    },
                    onButtonClicked: (func: Function) => {
                        this._handleButtonClicked = func;
                    },
                    close: (e) => {
                        this.handleClose(e);
                    }
                });
            } else {
                return child;
            }
        });

        const dialogBoxStyle: React.CSSProperties = {
            height: this.getDefaultHeight(),
            width: this.getDefaultWidth(),
        };

        const dialogOpen: any = { '--dialog-display': this.props.open ? "block" : "none" };

        return (
            <div
                id={this.props.id}
                ref={this.myRefs.dialogRoot}
                data-orbit-component={'OBTDialog'}
                className={Util.getClassNames(
                    'obtdialog',
                    styles.dialogRoot,
                    isPageVisible ? this.props.open ? styles.dialogRootOpen : undefined : undefined,
                    'open',
                    this.props.className)}
                tabIndex={-1}>
                {/*  여기부터 component 영역 */}
                <div className={`${styles._isDialog} _isDialog`} ref={this.myRefs.dialogBody} style={dialogOpen} >
                    <div className={styles.dimmedStyle}>
                        <div className={styles._dimClicker} tabIndex={-1}
                            onClick={this.handleDimClickerClick}
                        ></div>
                    </div>
                    <div className={styles.dialogBoxStyle}
                        ref={this.myRefs.dialogRef}
                        style={Object.assign(Util.getWrapperStyle(this.props), dialogBoxStyle)} >
                        <div className={'dialog_content'}>
                            <div className={`dialog_data ${styles.dialog_data}`}
                                style={{
                                    height: this.getDefaultHeight(),
                                }}>
                                <div style={{ position: 'relative' }}>
                                    <div className={`dialog_title_area ${styles.dialog_title_area} `} onMouseDown={this.handleMouseDown} >
                                        {this.props.title
                                            ? <h1 className={`dialog_title ${styles.dialog_title} `}>{this.props.title}</h1>
                                            : null
                                        }
                                        <span className={!this.props.hasTitleCloseButton ? styles.notHaveTitleCLoseButton : styles.dialog_subTitle}>{this.props.subTitle}</span>
                                    </div>
                                    {this.props.hasTitleCloseButton
                                        ? <button tabIndex={-1} className={styles.dialog_button} >
                                            <span onClick={(e: any) => this.close(e, 'closeButton')} />
                                        </button>
                                        : null}
                                </div>
                                <div className={styles.data}
                                    ref={this.myRefs.root}
                                    tabIndex={-1}
                                >
                                    <div className={Util.getClassNames(styles.dataWrapper, 'obtDialog')}>
                                        {children}
                                    </div>
                                </div>
                                <div className={Util.getClassNames('bottomButton', styles.bottomButton)}>
                                    {this.renderPropButton()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );

    };

    render(): React.ReactNode {
        return (
            this.props.open ?
                <OBTContext.Consumer>
                    {
                        value => {
                            return ReactDOM.createPortal(
                                (
                                    <ErrorBoundary
                                        owner={this}
                                        render={() => { return this.renderComponent(value.isPageVisible); }}
                                    />
                                ),
                                document.body
                            );
                        }
                    }
                </OBTContext.Consumer>
                : <></>
        );
    }
}





export default OBTDialog2;


