/**
 * 일어난 액션 종류.
 */
const ACTION_TYPE = {
  /**
   * 현재 상태를 저장하고 팝오버를 닫고 포커스를 이동한다
   */
  SAVE_AND_MOVE_POP_OVER: "SAVE_AND_MOVE_POP_OVER",
  /**
   * 현재 상태를 저장하고 팝오버를 닫고 포커스를 이동하지 않는다.
   */
  SAVE_AND_POP_OVER: "SAVE_AND_POP_OVER",
  /**
   * 현재 상태를 저장하고 팝오버를 닫고 포커스를 이동하지 않는다.
   * 선택된 데이터들을 display Text에 노출시키지 않는다
   */
  CLICK_CANCEL_BUTTON: "CLICK_CANCEL_BUTTON",
  /**
   * 현재 상태를 저장하지 아니하고 팝오버를 닫으며 포커스를 유지한다.
   */
  PRESS_KEY_ESC: "PRESS_KEY_ESC"
};

/**
 * popover 스타일에 대한 기본 값
 */
const POPOVER_STYLE = {
  HEIGHT: "100%",
  WIDTH: "0px"
};

export { ACTION_TYPE, POPOVER_STYLE };
