/**
 * @version 0.1
 * @author 김철희
 * @see common.js
 */
import * as React from 'react';
import LUXPopoverController from 'luna-rocket/LUXPopoverController/LUXPopoverController';
import OBTYearCalendar from './OBTYearCalendar';
import './OBTYearFieldDialog.scss'
import OBTFloatingPanel from '../OBTFloatingPanel';
import { PositionType } from '../OBTFloatingPanel/OBTFloatingPanel';
import { Util } from '../Common';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import LUXButton from 'luna-rocket/LUXButton';
import { OrbitInternalLangPack } from "../Common/Util";

const moduleStyles = require('./OBTYearFieldDialog.module.scss');

export enum AlignType {
    'near' = 'near',
    'center' = 'center',
    'far' = 'far'
}

interface IOBTYearFieldDialog {
    max?: string,
    min?: string,
    value: string,
    align: AlignType,
    position: PositionType,
    required?: boolean,
    onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void,
    onAccept: (event, value) => void, //달력에서 날짜 클릭시 반영되게 하는 함수
    customLabel?: (from?: string, to?: string, onResetFrom?: () => void, onResetTo?: () => void) => any,
    anchorEl?: any,
    usePortal?: boolean,
}

interface State extends hasError {
    open: boolean,
    transition: boolean
}

const styles = {
    button: {
        background: '#fff',
        border: '1px solid #e0e0e0',
        boxSizing: 'border-box',
        color: '#4a4a4a',
        display: 'inline-block',
        fontSize: '11px',
        fontFamily: '돋움, Dotum, Helvetica, Apple SD Gothic Neo, sans-serif',
        height: '21px',
        margin: '-1px 3px 0 -1px',
        outline: 'none',
        padding: '1px 6px 0',
        position: 'relative',
        textAlign: 'center',
        verticalAlign: 'top',
    }
}

export default class OBTYearFieldDialog extends React.Component<IOBTYearFieldDialog, State> {
    public static defaultProps = {
        usePortal: true
    }

    public state: State = {
        open: false,
        transition: false,
        hasError: false
    }

    public myRefs = {
        dialog: React.createRef<LUXPopoverController>(),
        root: React.createRef<HTMLDivElement>()
    }

    private resetDate = () => {
        this.props.onAccept(null, null);
    }

    public show = (focus: boolean = true): void => {
        this.setState({
            open: true,
        }, () => {
            this.setState({
                transition: true
            })

            if(focus === true) {
                if (this.myRefs.root.current) {
                    this.myRefs.root.current.focus();
                }
            }
        });
    };

    public dismiss = (): void => {
        this.setState({ open: false, transition: false });
    };

    public focus = () => {
        if (this.myRefs.root.current) {
            this.myRefs.root.current.focus();
        }
    }

    containsFocus = () => {
        return Util.containsFocus(this.myRefs.root);
    }

    private handleRootKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(e);
        }
    }

    renderComponent = () => {
        return (
            <OBTFloatingPanel
                key={this.state.open ? 'open' : 'close'}
                value={this.state.open}
                position={this.props.position}
                align={this.props.align}
                className={"LS_calendar"}
                width="194px"
                autoPosition={true}
                anchorEl={this.props.anchorEl}
                usePortal={this.props.usePortal}
            >
                <div ref={this.myRefs.root}
                    tabIndex={-1}
                    onKeyDown={this.handleRootKeyDown}
                    className={Util.getClassNames(moduleStyles.calendar, this.state.transition === true ? moduleStyles.visible : null)}
                >
                    <OBTYearCalendar
                        value={this.props.value}
                        onChange={(event, value) => { this.props.onAccept(event, value); this.dismiss(); }}
                        max={this.props.max}
                        min={this.props.min}
                    />
                    <div className={moduleStyles.label}>
                        {this.props.customLabel ?
                            this.props.customLabel(this.props.value, '', this.resetDate)
                            :
                            <>
                                <LUXButton
                                    key={'today'}
                                    label={OrbitInternalLangPack.getText('WE000022773', '올해')}
                                    onTouchTap={() => {
                                        if (this.props.onAccept) {
                                            this.props.onAccept(null, String(new Date().getFullYear()));
                                        }
                                        this.setState({
                                            open: false
                                        });
                                    }}
                                    style={styles.button}
                                />
                                {!this.props.required ? <LUXButton
                                    key={'clear'}
                                    label={OrbitInternalLangPack.getText('WE000001958', '초기화')}
                                    onTouchTap={() => {
                                        if (this.props.onAccept) {
                                            this.props.onAccept(null, null);
                                        }
                                        this.setState({
                                            open: false
                                        });
                                    }}
                                    style={styles.button}
                                /> : undefined}
                            </>}
                    </div>
                </div>
            </OBTFloatingPanel>
        )
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        )
    }

};


