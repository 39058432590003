import IconFuncCmdHover from './icon-func-cmd-hover.png';
import IconFuncCmdNormal from './icon-func-cmd-normal.png';
import IconFuncFavoriteOff from './icon-func-favorite-off.png';
import IconFuncFavoriteOn from './icon-func-favorite-on.png';
import IconFuncFavoriteHover from './ic_favorite_m_over.png';

import IconFuncHelpHover from './icon-func-help-hover.png';
import IconFuncHelpNormal from './icon-func-help-normal.png';
import IconFuncPlayHover from './icon-func-play-hover.png';
import IconFuncPlayNormal from './icon-func-play-normal.png';
import IconFuncPrintHover from './icon-func-print-hover.png';
import IconFuncPrintNormal from './icon-func-print-normal.png';
import IconFuncDelHover from './icon-func-del-hover.png';
import IconFuncDelNormal from './icon-func-del-normal.png';


import IconHelpOnlineManual from './ic_onlinemanual_s_normal.png';
import IconHelpVideo from './ic_video_s_normal.png';
import IconHelpHappy from './ic_happy_s_normal.png';

import IconHelpOnlineManualClick from './btn_onlinemanual_click.png';
import IconHelpOnlineManualNormal from './btn_onlinemanual_normal.png'
import IconHelpOnlineManualOver from './btn_onlinemanual_over.png'

import IconHelpHappyClick from './btn_happy_click.png';
import IconHelpHappyNormal from './btn_happy_normal.png'
import IconHelpHappyOver from './btn_happy_over.png'

import IconHelpVideoClick from './btn_video_click.png';
import IconHelpVideoNormal from './btn_video_normal.png'
import IconHelpVideoOver from './btn_video_over.png'

import IconHelpRelateMenuClick from './btn_relatmenu_click.png';
import IconHelpRelateMenuNormal from './btn_relatmenu_normal.png'
import IconHelpRelateMenuOver from './btn_relatmenu_over.png'

import IconAprovalNormal from './ic-proval-m-normal.png';
import ImageOptionalArrow from './arrow.png';

import IconOneUrlNormal from './ic_oneffice_m_normal.png';

// import IconHelpOnlineManualRenewal from './ic_onlinemanual_normal.png';
// import IconHelpVideoRenewal from './ic_video_normal.png';
// import IconHelpHappyRenewal from './ic_happy_normal.png';
// import IconRelateMenuRenewal from './ic_relatmenu_normal.png';

const Images = {
    IconHelpOnlineManualClick,
    IconHelpOnlineManualNormal,
    IconHelpOnlineManualOver,

    IconHelpHappyClick,
    IconHelpHappyNormal,
    IconHelpHappyOver,

    IconHelpVideoClick,
    IconHelpVideoNormal,
    IconHelpVideoOver,

    IconHelpRelateMenuClick,
    IconHelpRelateMenuNormal,
    IconHelpRelateMenuOver,

    IconFuncCmdHover,
    IconFuncCmdNormal,
    IconFuncFavoriteOff,
    IconFuncFavoriteOn,
    IconFuncFavoriteHover,
    IconFuncHelpHover,
    IconFuncHelpNormal,
    IconFuncPlayHover,
    IconFuncPlayNormal,
    IconFuncPrintHover,
    IconFuncPrintNormal,
    IconFuncDelHover,
    IconFuncDelNormal,
    IconHelpOnlineManual,
    IconHelpVideo,
    IconHelpHappy,
    // IconHelpOnlineManualRenewal,
    // IconHelpVideoRenewal,
    // IconHelpHappyRenewal,
    // IconRelateMenuRenewal

    IconAprovalNormal,
    ImageOptionalArrow,

    IconOneUrlNormal
};

export default Images;