import React from 'react'
import { Util } from '../Common';
import { ISideBarContent } from './SideBar';
import icoNew from './Images/ico_new_normal.png';
import OBTButton from '../OBTButton';

const styles = require('./SideBarContent.module.scss');

export enum SideBarContentType {
    'default' = 'default',
    'accordion' = 'accordion'
}

interface ISideBarComponent {
    item: ISideBarContent,
    forwardRef?: React.RefObject<HTMLDivElement>,
    onClickAddButton?: (() => void)
    onChangeCheckPen?: ((value: boolean) => void),
}

interface IState {
    collapsed: boolean,
    focused: boolean,
    useCheckPen: boolean,
}

export default class SideBarComponent extends React.Component<ISideBarComponent, IState> {
    public static Type = SideBarContentType;

    static defaultProps = {
        type: SideBarContentType.default
    }

    state: IState = {
        collapsed: false,
        focused: false,
        useCheckPen: true,
    }

    myRefs = {
        root: React.createRef<HTMLDivElement>(),
        animationWrapper: React.createRef<HTMLDivElement>(),
        sideBarComponent: React.createRef<HTMLDivElement>()
    }

    renderCheckPenIcon = () => {
        return this.state.useCheckPen === true ? (
            <div style={{
                width: '38px',
                height: '16px',
                borderRadius: '11px',
                backgroundColor: 'white',
                border: '1px solid #1c90fb',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
            }} onClick={() => {
                this.setState({
                    useCheckPen: false,
                }, () => {
                    if (this.props.onChangeCheckPen) {
                        this.props.onChangeCheckPen(false);
                    }
                })
            }}>
                <div style={{
                    fontSize: '10px',
                    color: '#1c90fb'
                }}>
                    ON
                </div>

                <div style={{
                    marginLeft: '4px',
                    width: '10px',
                    height: '10px',
                    backgroundColor: '#1c90fb',
                    borderRadius: '11px',
                }}>
                </div>
            </div>
        ) : (
                <div style={{
                    width: '38px',
                    height: '16px',
                    borderRadius: '11px',
                    backgroundColor: '#ffffff',
                    border: '1px solid #c4c4c4',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                }} onClick={() => {
                    this.setState({
                        useCheckPen: true,
                    }, () => {
                        if (this.props.onChangeCheckPen) {
                            this.props.onChangeCheckPen(true);
                        }
                    })
                }}>
                    <div style={{
                        width: '10px',
                        height: '10px',
                        backgroundColor: '#c4c4c4',
                        borderRadius: '11px',
                    }}>
                    </div>
                    <div style={{
                        marginLeft: '2px',
                        fontSize: '10px',
                        color: '#c4c4c4'
                    }}>
                        OFF
                    </div>
                </div>
            )
    }

    render() {
        return (
            <div className={Util.getClassNames(styles.sideBarContent, styles[this.props.item.type!], this.state.collapsed ? styles.collapsed : null)}
                tabIndex={-1}
                ref={this.props.forwardRef ? this.props.forwardRef : this.myRefs.root}
                onFocusCapture={this.handleFocus}
                onBlur={this.handleBlur}>
                {this.props.item.type === SideBarContentType.accordion ?
                    <div className={styles.sideBarTitle}>
                        {this.state.focused && this.props.item.iconFocused ? <div className={styles.sideBarIcon}>{this.props.item.iconFocused}</div> :
                            this.props.item.icon ? <div className={styles.sideBarIcon}>{this.props.item.icon}</div> : <></>}
                        {this.props.item.imageUrl ? <div className={styles.sideBarImage}
                            style={{ backgroundImage: this.state.focused && this.props.item.imageUrlFocused ? this.props.item.imageUrlFocused : this.props.item.imageUrl }} /> : <></>}
                        <div className={Util.getClassNames(styles.sideBarTitleText, this.state.focused ? styles.focused : undefined)} onClick={this.handleCollapse}>{this.props.item.title}</div>
                        {this.props.item.titleButtons ? this.props.item.titleButtons.map((item) => <></>) : <></>}
                        {
                            this.props.onChangeCheckPen ? (
                                this.renderCheckPenIcon()
                            ) : null
                        }

                        {
                            this.props.onClickAddButton ? (
                                <OBTButton
                                    type={OBTButton.Type.small}
                                    imageUrl={{
                                        normal: icoNew
                                    }}
                                    width={"21px"}
                                    // onClick={this.handleClickAdd}
                                    onClick={this.props.onClickAddButton}
                                />
                            ) : null
                        }

                        {
                            this.props.onClickAddButton || this.props.onChangeCheckPen ? (
                                <div style={{
                                    height: '10px',
                                    borderLeft: '1px solid #cccccc',
                                    marginLeft: '8px',
                                    marginRight: '8px'
                                }}>
                                </div>
                            ) : null
                        }


                        <div tabIndex={-1} className={styles.sideBarTitleCollapse} onClick={this.handleCollapse}></div>
                    </div>
                    : <></>}
                <div ref={this.myRefs.animationWrapper} className={Util.getClassNames(styles.animationWrapper, this.state.collapsed ? styles.collapsed : undefined)}>
                    <div ref={this.myRefs.sideBarComponent} className={styles.sideBarComponent}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }

    handleCollapse = (e) => {
        const height = () => {
            if (this.myRefs.animationWrapper.current) {
                return Array.from(this.myRefs.animationWrapper.current.children).map(el => {
                    return (el as HTMLElement).offsetHeight;
                }).reduce((accu, curr) => accu + curr, 0);
            }
            return 0;
        };

        if (this.myRefs.animationWrapper.current) {
            this.myRefs.animationWrapper.current.style.setProperty('--height', `${height()}px`);
        }
        setTimeout(() => {
            this.setState({
                collapsed: !this.state.collapsed
            }, () => {
                setTimeout(() => {
                    if (!this.state.collapsed) {
                        if (this.myRefs.animationWrapper.current) {
                            this.myRefs.animationWrapper.current.style.removeProperty('--height');
                        }
                    }
                }, 350);
            });
        }, 0);

        e.stopPropagation();
        e.preventDefault();
    }

    handleFocus = () => {
        if (!this.state.focused) {
            this.setState({ focused: true });
        }
    }

    handleBlur = (e: React.FocusEvent) => {
        if (this.state.focused && !Util.containsFocus(this.props.forwardRef || this.myRefs.root)) {
            this.setState({ focused: false });
        }
    }

    public focus = () => {
        const current = (this.props.forwardRef || this.myRefs.root).current;
        if (current) {
            current.focus();
        }
    }
}
