import React, { Component } from 'react';

function getStyles(state, props) {
    return {
        timePickerResultLiStyle: {
            padding: 0,
            margin: 0,
            cursor: 'pointer',
            background: state.isHoverd || props.isFocused ? '#f5f5f5' : '',
        },
        timePickerResultRowsStyle: {
            display: 'block',
            padding: '2px 6px 0',
            textDecoration: 'none',
            fontSize: '12px',
            fontFamily: 'inherit',
            // lineHeight: '13px',
            color: '#4a4a4a',
            lineHeight: '20px',
            letterSpacing: '-0.5px',
        },
        timePickerResultEmStyle: {
            display: 'inline-block',
            minWidth: '8px',
            textAlign: 'center',
            fontStyle: 'normal',
            fontFamily: 'inherit'
        }
    }
}

class LUXTimePickerList extends Component {
    constructor(props) {
        super(props);

        this.state = { isHoverd: false }
    }

    handleListMouseEnter = () => {
        this.setState({ isHoverd: true });
    };

    handleListMouseLeave = () => {
        this.setState({ isHoverd: false });
    };

    handleRowsClick = (tmpType, tmpHour1, tmpHour2, tmpMinute, tmpIndex) => {
        if (this.props.isDisabled) return;

        let type = tmpType.listType;
        let hour = tmpHour1.listHour1 + tmpHour2.listHour2;
        let minute = tmpMinute.listMinute === '_' ? '__' : tmpMinute.listMinute + '0';
        let index = tmpIndex.index;
        this.props.choiceTimeListData(type, hour, minute, index);
    };

    render() {
        const styles = getStyles(this.state, this.props);

        const {
            listType,
            listHour1,
            listHour2,
            listMinute,
            // isFocused,
            isDisabled,
            index
        } = this.props;

        const liProps = {
            onMouseEnter: this.handleListMouseEnter,
            onMouseLeave: this.handleListMouseLeave
        };

        return (
            <li {...liProps} style={Object.assign({}, styles.timePickerResultLiStyle, isDisabled ? { cursor: "not-allowed", background: "rgb(240,240,240)" } : {})}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a style={styles.timePickerResultRowsStyle}>
                    <div onClick={this.handleRowsClick.bind(this, { listType }, { listHour1 }, { listHour2 }, { listMinute }, { index })}>{listType}&nbsp;
                        <em style={styles.timePickerResultEmStyle}>{listHour1}</em>
                        <em style={styles.timePickerResultEmStyle}>{listHour2}</em>
                        :
                        <em style={styles.timePickerResultEmStyle}>{listMinute}</em>
                        <em style={styles.timePickerResultEmStyle}>{listMinute === '_' ? '_' : '0'}</em>
                    </div>
                </a>
            </li>
        );
    }
}

export default LUXTimePickerList
