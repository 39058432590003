/**
 *  검색시 팝 오버에 뿌려질 리스트의 추가 버튼이 있는 헤더입니다.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import LUXSpriteIcon from "luna-rocket/LUXSpriteIcon";

const getStyles = (props, hover) => {
    const { focusedInfo } = props;

    let isSelect = focusedInfo.data === "addButton";

    const styles = {
        // inputElement 의 전체를 감싸고 있는 div 의 Style
        style: {
            borderBottom: "1px solid #dbdbdb",
            margin: 0,
            padding: 0,
            background: hover ? "#f5f5f5" : isSelect ? "#e9f5ff" : "#fff",
            cursor: "pointer"
        },
        iconStyle: {
            width: "16px",
            height: "16px",
            verticalAlign: "middle",
            marginRight: "5px"
        },
        divStyle: {
            display: "block",
            width: "100%",
            textAlign: "center",
            color: "#1c90fb",
            lineHeight: "32px"
        },
        spanStyle: {
            fontSize: "12px",
            lineHeight: "20px"
        }
    };
    return styles;
};

class ButtonHeader extends Component {
    // region React Base

    static propTypes = {
        /**
         * 검색한 키워드
         */
        keyWord: PropTypes.string.isRequired
    };

    constructor() {
        super();

        this.state = {
            hover: false
        };
    }

    // endregion

    /**
     * 데이터를 클릭하여 선택하면 발생
     */
    handleClick = event => {
        // 클릭된 데이터를 선택한다.
        event.stopPropagation();
        event.preventDefault();
        if (this.props.onSelect) {
            this.props.onSelect(event, "addButton");
        }
    };

    /**
     * 마우스 오버가 되면 hover state 설정
     */
    handleMouseEnter = () => {
        this.setState({ hover: true });
    };

    /**
     * 마우스 Leave 되면 hover state 설정
     */
    handleMouseLeave = () => {
        this.setState({ hover: false });
    };

    // region render

    render() {
        const styles = getStyles(this.props, this.state.hover);

        const divProps = {
            onClick: this.handleClick,
            onMouseEnter: this.handleMouseEnter,
            onMouseLeave: this.handleMouseLeave
        };

        return (
            <div style={styles.style} {...divProps}>
                <div style={styles.divStyle}>
                    <LUXSpriteIcon
                        sprite="buttonPlusBlue"
                        style={styles.iconStyle}
                    />
                    <span style={styles.spanStyle}>
                        {this.props.keyWord + "(으)로 등록"}
                    </span>
                </div>
            </div>
        );
    }

    // endregion
}

export default ButtonHeader;
