import React from 'react';

interface IContentsWrapper {
    component: JSX.Element,
    specialLnb: any,
    menuInfo: any
}

export default class ContentsWrapper extends React.Component<IContentsWrapper> {
    // shouldComponentUpdate(prevProps: IContentsWrapper) {
    //     // if(this.props.specialLnb !== prevProps.specialLnb) {
    //     //     return true;
    //     // }

    //     // if(this.props.menuInfo !== prevProps.menuInfo) {
    //     //     if(this.props.menuInfo && this.props.menuInfo['menuNo'] && prevProps.menuInfo && prevProps.menuInfo['menuNo']) {
    //     //         if (this.props.menuInfo['menuNo'] !== prevProps.menuInfo['menuNo']){
    //     //             return true;
    //     //         }
    //     //     }
    //     // }

    //     return false;
    // }

    render() {
        return (
            <>
                {this.props.component}
            </>
        )
    }
}
