import { IRealGridCellDynamicStyleOption } from "../RealGridCellStyleOption";

export default {

    /**
     * @internal
     * 두개의 다이나믹 스타일을 하나의 콜백으로 합치기
     */
    mergeDynamicStyles: (callBack1?: (grid: any, index: any, value: any) => IRealGridCellDynamicStyleOption,
        callBack2?: (grid: any, index: any, value: any) => any): (grid: any, index: any, value: any) => IRealGridCellDynamicStyleOption => {

        if (callBack1 === undefined && callBack2 === undefined) {
            return () => {
                return {}
            }
        }

        if (!callBack1 && callBack2) {
            return callBack2;
        }

        if (callBack1 && !callBack2) {
            return callBack1;
        }

        return (grid, index, value) => {
            const result1 = callBack1!(grid, index, value);
            const result2 = callBack2!(grid, index, value);

            const result = Object.assign(
                result1 ? result1 : {},
                result2 ? result2 : {},
            )
            return result;
        }
    },

    /**
     * @internal
     * 필수값 체크를 위해 비었는지 여부
     * @param object 
     */
    isEmpty: (object: any) => {
        if (object === null) {
            return true;
        }

        if (object === undefined) {
            return true;
        }

        if (typeof object === 'string' && object.trim() === '') {
            return true;
        }

        return false;
    },

    convertRGBToRealGridColorHex: (value?: string) => {
        if (!value) {
            return value;
        }

        function hex(x: string) {
            return ("0" + parseInt(x).toString(16)).slice(-2);
        }

        const splitByComma = value.split('.');
        if (splitByComma.length === 3) {
            return '#ff' + hex(splitByComma[0]) + hex(splitByComma[1]) + hex(splitByComma[2]);
        }

        const splitByCollon = value.split(',');
        if (splitByCollon.length === 3) {
            return '#ff' + hex(splitByCollon[0]) + hex(splitByCollon[1]) + hex(splitByCollon[2]);
        }
        return value;
    }
}