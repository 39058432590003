/**
 * OBTConditionItem
 * @version 0.1
 * @author 김철희
 * @see common.js
 */
import * as React from 'react';
import { Events, CompositeProps, Util, CommonProps, Functions, createPropDefinitions, CommonDefinitions, CommonType } from '../Common';
import OBTTooltip from '../OBTTooltip';
import { OBTContext } from '../OBTPageContainer/OBTPageContainer';
import { OrbitInternalLangPack } from '../Common/Util';
import { CancelableEventArgs } from '../Common/Events';
const styles = require('./OBTConditionItem.module.scss');

class BeforeMoveFocusEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly direction: string,
        public readonly cancel: boolean = false) {
        super(target);
    }
}

interface IOBTConditionItem extends CompositeProps.Default, CommonProps.disabled, Events.onMoveFocus, Events.onFocus {
    /**
     * 조회조건 컴포넌트의 label text
     */
    labelText?: React.ReactNode,
    labelTextVisible?: boolean,
    /**
     * 옵션값 여부
     */
    optional?: boolean,
    children?: any,
    /**
     * 필수값 여부
     */
    required?: boolean,
    onChange?: (e: Events.ChangeEventArgs<any>) => void,
    guideMessage?: any,
    requiredTooltip?: any,
    /**
     * 조회조건 컴포넌트가 부모의 영역에서 차지할 범위를 지정한다.
     * 1. 원본크기
     * 2. 두배 
     * 3. 세배
     */
    columnSpan?: 1 | 2 | 3,
    spaceBetweenChildren?: boolean,
    onBeforeMoveFocus?: (e: BeforeMoveFocusEventArgs) => void
}

/**
 * State 정의
 */
interface State {
    refs: React.RefObject<any>[]
}

class OBTConditionItem extends React.Component<IOBTConditionItem, State> implements Functions.IFocusable, Functions.IRequired {

    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        CommonDefinitions.disabled(),
        CommonDefinitions.onMoveFocus(),
        CommonDefinitions.onFocus(),
        { name: "labelText", type: CommonType.string, description: '라벨에 표시될 문구를 지정합니다.', optional: true },
        { name: 'labelTextVisible', type: CommonType.boolean, default: true, description: '라벨의 표시 여부를 지정합니다.', optional: true },
        { name: 'optional', type: CommonType.boolean, description: '옵션값 여부', optional: true, default: false },
        CommonDefinitions.onChange({
            optional: true, description: "컴포넌트에서 입력된 값이 변경될 때 발생하는 Callback 함수입니다."
        }),
        {
            name: 'onBeforeMoveFocus', type: CommonType.function, parameters: {
                name: 'e',
                type: [{
                    target: { type: CommonType.any, description: '타겟 인스턴스' },
                    direction: { type: 'left | up | right | down | enter', description: '이동방향' },
                    cancel: { type: CommonType.boolean, description: 'cancel 여부' }
                }]
            }, optional: true, description: 'onMoveFocus가 호출하기 전에 호출되며, e.cancel = true로 적용하여 onMoveFocus 호출을 제어할 수 있음'
        },
        {
            name: "columnSpan", type: CommonType.number, default: 1, description: '조회조건 컴포넌트가 부모의 영역에서 차지할 범위를 지정합니다.'
                + '\n 1. 원본크기 2. 두배 3. 세배', optional: true
        },
        {
            name: 'spaceBetweenChildren', type: CommonType.boolean, description: 'Item 내의 항목들 사이에 공간을 둡니다.', optional: true
        }
    );

    ///////////////////////////////////////////////////////////////////////////// Initialize
    /**
     * Default Props 설정
     */
    public static defaultProps = {
        frozen: false,
        disabled: false,
        height: '27px',
        columnSpan: 1
    }

    myRefs = {
        labelText: React.createRef<HTMLElement>()
    }

    /**
     * State 정의
     */
    public state: State = {
        refs: React.Children.toArray(this.props.children).map((child: any) => {
            if (React.isValidElement(child) && child['ref']) {
                return child['ref'] || React.createRef<any>();
            }
            return null;
        }).filter(ref => ref)
    }

    ///////////////////////////////////////////////////////////////////////////// Life Cycle API
    static getDerivedStateFromProps(nextProps: IOBTConditionItem, prevState: State): any {
        return {
            refs: React.Children.toArray(nextProps.children).map((child: any) => {
                if (React.isValidElement(child)) {
                    return child['ref'] || React.createRef<any>();
                }
                return null;
            }).filter(ref => ref)
        };
    }

    componentDidUpdate() {
        if (this.props.required === true) {
            if (this.state.refs && this.state.refs.length > 0) {
                const focusTarget = this.state.refs.find(ref => {
                    if (ref.current && ref.current.validate) {
                        return !ref.current.validate();
                    }
                    return false;
                });
                if (focusTarget && focusTarget.current && focusTarget.current.focus) {
                    focusTarget.current.focus();
                }
            }
        }
    }

    // component 가 render 될때 호출됨.
    render() {
        const childrenCount = React.Children.count(this.props.children);
        let refIndex = 0;
        const children = Util.mapChildren(this.props.children, (child) => {
            if (React.isValidElement(child)) {
                if (Util.isOrbitComponent(child.type)) {
                    let newProps = {};
                    const props: any = child.props;
                    if (childrenCount === 1) {
                        if (!props['width']) {
                            newProps['width'] = '100%';
                        }
                    }
                    if (this.props.disabled) {
                        newProps['disabled'] = true;
                    }
                    newProps['ref'] = this.state.refs[refIndex++];
                    newProps['onMoveFocus'] = Util.overrideHandler(props['onMoveFocus'], this.handleMoveFocus.bind(this));
                    newProps['onChange'] = Util.overrideHandler(props['onChange'], this.handleChange.bind(this));
                    return Object.keys(newProps).length > 0 ? newProps : null;
                }
            }
        });
        const tooltip = this.props.required === true ?
            <OBTTooltip
                className={styles.requiredTooltip}
                labelText={`${this.props.labelText} ${OrbitInternalLangPack.getText('WE000028458', '를(을) 반드시 입력해 주십시오.')}`}
                value={true}
                theme={OBTTooltip.Theme.required}
                {...this.props.requiredTooltip}
            >
                <div />
            </OBTTooltip> : <></>;

        const columnSize = (this.props.columnSpan ? this.props.columnSpan : 1);
        let itemWidth = (102 + 178 + 6) * columnSize; // label + component + margin    284 286

        // margin 사이즈 더하기
        itemWidth = itemWidth + ((columnSize - 1) * 8)

        let itemWrapperStyle = {
            width: this.props.width ? this.props.width : itemWidth
        }

        return (
            <div className={Util.getClassNames(styles.item, this.props.className)}
                style={Object.assign(Util.getWrapperStyle(this.props), itemWrapperStyle)}
                onFocus={this.handleFocus.bind(this)}
                id={this.props.id}
                data-orbit-component={'OBTConditionItem'}
            >
                {this.props.labelTextVisible !== false && this.props.labelText ? <span ref={this.myRefs.labelText} className={styles.label}>{this.props.labelText}</span> : <></>}
                <div className={Util.getClassNames(styles.tooltipWrapper, this.props.spaceBetweenChildren ? styles.spaceBetweenChildren : undefined)}>
                    {tooltip}
                    {children}
                </div>
            </div>
        )
    }

    ///////////////////////////////////////////////////////////////////////////// Logics
    public focus(isLast?: boolean): boolean {
        return this.setFocus((isLast === true ? this.state.refs.length - 1 : 0), isLast);
    }

    public isEmpty(): boolean {
        if (this.state.refs && this.state.refs.length > 0) {
            if (this.state.refs.find(ref => {
                if (ref.current && ref.current.isEmpty) {
                    return ref.current.isEmpty();
                }
                return false;
            })) return true;
        }
        return false;
    }

    public validate(): boolean {
        if (this.state.refs && this.state.refs.length > 0) {
            if (this.state.refs.find(ref => {
                if (ref.current && ref.current.validate) {
                    return !ref.current.validate();
                }
                return false;
            })) return false;
        }
        return true;
    }

    private setFocus(index: number, isLast?: boolean) {
        while (index >= 0 && index < this.state.refs.length) {
            const instance = this.state.refs[index].current;
            if (instance && instance.focus &&
                instance.props.disabled !== true &&
                instance.props.frozen !== true) {
                instance.focus(isLast);
                return true;
            }
            if (isLast === true) {
                index--;
            } else {
                index++;
            }
        }
        return false;
    }
    ///////////////////////////////////////////////////////////////////////////// Event Handlers
    private handleFocus(): void {
        Util.invokeEvent<Events.EventArgs>(this.props.onFocus, new Events.EventArgs(this));
    }

    private handleMoveFocus(e: BeforeMoveFocusEventArgs): void {
        const isMoveFocusable = Util.invokeEvent<BeforeMoveFocusEventArgs>(this.props.onBeforeMoveFocus, new BeforeMoveFocusEventArgs(this, e.direction, e.cancel));
        if (isMoveFocusable === false) {
            return;
        }

        const targetIndex = this.state.refs ? this.state.refs.findIndex((ref) => ref.current === e.target) : -1;
        if (targetIndex >= 0 && targetIndex < this.state.refs.length) {
            switch (e.direction) {
                case Events.MoveFocusDirection.left:
                    if (targetIndex === 0 || !this.setFocus(targetIndex - 1, true)) {
                        Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, e.direction));
                    }
                    break;
                case Events.MoveFocusDirection.right:
                case Events.MoveFocusDirection.enter:
                    if (targetIndex + 1 >= this.state.refs.length || !this.setFocus(targetIndex + 1, false)) {
                        Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, e.direction));
                    }
                    break;
                default:
                    Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, e.direction));
                    break;
            }
        }
    }

    private handleChange(e: Events.ChangeEventArgs<any>): void {
        Util.invokeEvent<Events.ChangeEventArgs<any>>(this.props.onChange, e);
    }
};

OBTConditionItem.contextType = OBTContext;
export default OBTConditionItem;