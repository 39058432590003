/**
 * 
 * @version 0.1
 * @author 하성준
 * @see common.js
 */
import * as React from 'react';
import { Events, CompositeProps, Util, CommonProps, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import OBTButton from '../OBTButton';
import OBTTooltip from '../OBTTooltip';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
const styles = require('./OBTButtonGroup.module.scss');


interface IOBTButtonGroup extends CompositeProps.Default, CommonProps.disabled,
    Events.onChange<string>, Events.onMouseEnter, Events.onMouseLeave,
    Events.onFocus, Events.onBlur, Events.onClick, Events.onMouseDown, Events.onMouseMove, Events.onMouseUp, Events.onMouseLeave,
    Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress, Events.onKeyUp {
    children: any,
    /**
     *  버튼 크기 타입
     */
    type?: ButtonType,
    /**
     *  OBTButton 콤포넌트의 key 속성값과 매핑되어 default로 선택되는 OBTButton 콤포넌트를 정의하는 속성입니다.
     */
    value: string,
    /**
     *  버튼 그룹에 대한 툴팁 설정 입니다.
     */
    tooltip?: any

}

interface State extends hasError {
}

enum ButtonType {
    'big' = 'big',
    'default' = 'default',
    'small' = 'small',
}

export default class OBTButtonGroup extends React.Component<IOBTButtonGroup, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        { name: 'type', type: toEnumType(ButtonType), default: 'default', optional: true, description: 'big : 큰버튼 / default : 기본버튼 / small : 작은버튼' },
        CommonDefinitions.value({ type: CommonType.string, description: 'OBTButton 컴포넌트의 value 속성값과 매핑되어 default로 선택되는 OBTButton 컴포넌트를 정의하는 속성입니다.' }),
        CommonDefinitions.onChange({ type: CommonType.string }),
        CommonDefinitions.disabled(),
        CommonDefinitions.tooltip(),
        CommonDefinitions.onClick(),
        CommonDefinitions.onFocus(),
        CommonDefinitions.onBlur(),
        CommonDefinitions.onMouseDown(),
        CommonDefinitions.onMouseMove(),
        CommonDefinitions.onMouseUp(),
        CommonDefinitions.onMouseLeave(),
        CommonDefinitions.onMouseEnter(),
        CommonDefinitions.onKeyDown(),
        CommonDefinitions.onKeyPress(),
        CommonDefinitions.onKeyUp()
    );

    public static defaultProps = {
        frozen: false,
        disabled: false,
        type: ButtonType.default,
    }
    public static Type = ButtonType

    public state: State = {
        hasError: false
    }

    private get canEdit(): boolean {
        return !this.props.disabled;
    }

    private handleChange(value: string): void {
        if (this.canEdit && this.props.onChange) {
            Util.invokeEvent<Events.ChangeEventArgs<string>>(this.props.onChange, new Events.ChangeEventArgs<string>(this, value));
        }
    }

    private isSelectedChild(childProps: any, childKey: any): boolean {
        if (!this.props.value) return false;
        const childValueOrKey = childProps || childKey;
        return childValueOrKey === this.props.value;
    }

    renderComponent = () => {
        const childrens = React.Children.map(this.props.children, (child, idx) => {
            const newProps: any = {
                className: Util.getClassNames(child.props.className ? child.props.className : null,
                    styles.button,
                    this.isSelectedChild(child.props.value, child.key) ? styles.focus : null,
                ),
                theme: OBTButton.Theme.default,
                type: this.props.type,
                imageUrl: child.props.imageUrl,
                icon: child.props.icon,
                tooltip: child.props.tooltip,
                disabled: child.props.disabled,
                onClick: (e) => {
                    if (child.props.value)
                        this.handleChange(child.props.value)
                    else if (child.key)
                        this.handleChange(child.key)
                    Util.invokeEvent<Events.MouseEventArgs>(child.props.onClick, new Events.MouseEventArgs(this, e));
                }
            };
            if (this.props.disabled === true) {
                newProps.disabled = true;
            }
            return React.cloneElement(child, newProps);
        });
        return <OBTTooltip {...this.props.tooltip}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            onClick={this.props.onClick}
            onMouseDown={this.props.onMouseDown}
            onMouseMove={this.props.onMouseMove}
            onMouseUp={this.props.onMouseUp}
            onMouseLeave={this.props.onMouseLeave}
            onMouseEnter={this.props.onMouseEnter}
            onKeyUp={this.props.onKeyUp}
            onKeyDown={this.props.onKeyDown}
            onKeyPress={this.props.onKeyPress}
            overrideSize={false}
            rootProps={{
                id: this.props.id,
                'data-orbit-component': 'OBTButtonGroup'
            }}>
            <div className={Util.getClassNames(styles.buttonGroup, this.props.className)} style={Util.getWrapperStyle(this.props)}>
                {childrens}
            </div>
        </OBTTooltip>
    }

    render() {
        return <ErrorBoundary owner={this} render={this.renderComponent} />
    }
};
