/**
 * OBTGroupSelector
 * @version 0.1
 * @author 하성준
 * @see common.js
 */
import * as React from 'react';
import { Events, CompositeProps, Util, CommonProps, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import leftImg from '../Images/icon-arrow-left.png'
import rightImg from '../Images/icon-arrow-right.png'
import upImg from '../Images/icon-arrow-up.png'
import downImg from '../Images/icon-arrow-down.png'

const styles = require('./OBTGroupSelector.module.scss');

export class MapItemEventArgs extends Events.EventArgs {
    constructor(public readonly list: any, public item?: any) {
        super(null);
    }
}

export class ItemEventArgs extends MapItemEventArgs {
    constructor(public readonly list: any, public item?: any, public component?: any, public value?: any, public mouseOver?: boolean, public isSelected?: boolean, public index?: number, public mouseOverKey?: string | null) {
        super(null);
    }
}

export class RenderItemEventArgs extends Events.EventArgs {
    constructor(public readonly list: any, public component?: any) {
        super(null);
    }
}

interface IOBTGroupSelector extends CompositeProps.Default, CommonProps.value<string>, Events.onChange<string>, CommonProps.disabled {
    /**
     * 요소들 정렬 방향
     */
    align?: AlignType,
    /**
     *  템플릿 사용
     */
    template?: TemplateType
    /**
     *  리스트
     */
    list: Array<any>,

    itemKeyProperty: string,
    /**
     *  아이템 너비
     */
    itemWidth: string,
    /**
     *  아이템 높이
     */
    itemHeight: string,
    /**
     *  리스트를 템플릿 형식에 맞는 아이템으로 변환시키는 함수
     */
    onMapItem?: (e: MapItemEventArgs) => void,
    /**
     *  요소에 들어갈 아이템을 지정하는 함수
     */
    onRenderItem?: (e: RenderItemEventArgs) => void,
    /**
     *  버튼 자동 숨김
     */
    autoHideButtons?: boolean
}

enum AlignType {
    /**
     * 수평
     */
    'horizontal' = 'horizontal',

    /**
     * 수직
     */
    'vertical' = 'vertical'
}

enum TemplateType {
    'default' = 'default'
}

interface State {
    value: any,
    list?: Array<any>,
    component?: any,
    count: number,
    hiddenItems: number,
    defaultTemplate: any,
    prevButtonWidth?: string,
    prevButtonHeight?: string,
    mouseOverItemKey: string | null
}

export default class OBTGroupSelector extends React.Component<IOBTGroupSelector, State> {
    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        CommonDefinitions.value(CommonType.string),
        CommonDefinitions.onChange(CommonType.string),
        CommonDefinitions.disabled(),
        { name: "align", type: toEnumType(AlignType), optional: true, default: "horizontal", description: "요소의 정렬 방향을 지정합니다." },
        { name: "template", type: toEnumType(TemplateType), default: "default", optional: true, description: "미리 만들어진 template 사용 여부입니다." },
        { name: "list", type: "Array<any>", description: "요소의 리스트 입니다." },
        { name: "itemWidth", type: CommonType.string, description: "요소안에 들어가는 item의 너비 입니다." },
        { name: "itemHeight", type: CommonType.string, description: "요소안에 들어가는 item의 높이 입니다." },
        { name: "itemKeyProperty", type: CommonType.string, optional: true, description: "list의 아이템에서 키값을 의미하는 프로퍼티 명입니다. (마우스오버, 선택아이템 처리시 사용)", default: "key" },
        {
            name: "onMapItem", type: CommonType.function, parameters: {
                name: "e",
                type: {
                    list: { type: CommonType.any, description: "원본 요소" },
                    item: { type: CommonType.any, description: "변환 요소" }
                }
            }, optional: true, description: "데이터를 template 형식에 맞게 변환해 주기 위한 함수입니다."
                + "\ntemplate를 사용할 경우 이 힘수를 사용하여 변환해 주시면 됩니다."
        },
        {
            name: "onRenderItem", type: CommonType.function, parameters: {
                name: "e",
                type: {
                    list: { type: CommonType.any, description: "원본 요소" },
                    component: { type: CommonType.any, description: "변환 요소" }
                }
            }, optional: true, description: "데이터를 가지고 컴포넌트를 만들기 위한 함수입니다. "
                + "\ntemplate를 사용하지 않을 경우 이 힘수를 사용하여 변환해 주시면 됩니다."
        },
        { name: "autoHideButtons", type: CommonType.boolean, description: "좌/우 페이징 버튼을 item 갯수에 따라 자동으로 표시/미표시 합니다.", optional: true }
    );

    ///////////////////////////////////////////////////////////////////////////// Initialize

    public static defaultProps = {
        align: AlignType.horizontal,
        itemWidth: '0px',
        itemHeight: '0px',
        itemKeyProperty: 'key'
    }

    public static Align = AlignType
    public static Template = TemplateType


    public state: State = {
        value: this.props.value,
        count: 0,
        hiddenItems: 0,
        mouseOverItemKey: null,
        defaultTemplate: (e) => {
            const { item, index } = e;
            e.component =
                <div className={Util.getClassNames(styles.defaultTemplate, e.value === item.key ? styles.selected : null, item.key === this.state.mouseOverItemKey ? styles.mouseOver : null)}
                    style={{
                        width: this.props.itemWidth,
                        height: this.props.itemHeight
                    }}
                    key={item.key}
                    onMouseEnter={(e) => {
                        if (this.props.list.length > index) {
                            this.setState({
                                mouseOverItemKey: item.key
                            })
                        }
                    }}
                    onMouseLeave={(e) => {
                        this.setState({
                            mouseOverItemKey: null
                        })
                    }}
                    onClick={e => this.handleChange(item.key)}
                >
                    <div className={item.key === this.state.mouseOverItemKey || e.value === item.key ? styles.imageMouseOver : styles.image}>
                        {item.image}
                    </div>
                    <div className={styles.textArea}>
                        <div className={styles.textAreaSub}>
                            {item.sub}
                        </div>
                        <div className={styles.textAreaMain}>
                            {item.main}
                        </div>
                    </div>
                </div>
        }
    }

    /**
     * Ref 정의
     */
    public myRefs = {
        centerWrap: React.createRef<HTMLDivElement>(),
        prevButton: React.createRef<HTMLDivElement>(),
        firstItem: React.createRef<HTMLElement>()
    }

    ///////////////////////////////////////////////////////////////////////////// Life Cycle API
    static getDerivedStateFromProps(nextProps: IOBTGroupSelector, prevState: State): any {
        if (nextProps.list !== prevState.list || nextProps.value !== prevState.value) {
            let component = OBTGroupSelector.onRenderItem(nextProps.list, nextProps.onRenderItem, nextProps.template, prevState.defaultTemplate, nextProps.onMapItem, nextProps.value, prevState.mouseOverItemKey, nextProps.itemKeyProperty);
            return {
                list: nextProps.list,
                component: component,
                value: nextProps.value
            }
        }
        return null
    }

    componentDidMount() {
        if (this.myRefs.firstItem.current) {
            this.setState({
                prevButtonWidth: String(this.myRefs.firstItem.current.getBoundingClientRect().width) + 'px',
                prevButtonHeight: String(this.myRefs.firstItem.current.getBoundingClientRect().height) + 'px',
            });
        } else if (this.myRefs.prevButton.current) {
            this.setState({
                prevButtonWidth: String(this.myRefs.prevButton.current.getBoundingClientRect().width) + 'px',
                prevButtonHeight: String(this.myRefs.prevButton.current.getBoundingClientRect().height) + 'px',
            });
        }

        this.setState({ count: this.countItems() })
    }

    componentDidUpdate(prevProps: IOBTGroupSelector, prevState: State) {
        let needUpdate = false;
        let state = {};
        const count = this.countItems();
        if (this.state.count !== count) {
            state['count'] = count;
            needUpdate = true;
        }

        const isChangeSizeProps = (((this.props.align !== prevProps.align)
            || (this.props.itemWidth !== prevProps.itemWidth)
            || (this.props.itemHeight !== prevProps.itemHeight)));

        if (this.myRefs.firstItem.current) {
            const prevButtonWidth = String(this.myRefs.firstItem.current.getBoundingClientRect().width) + 'px';
            const prevButtonHeight = String(this.myRefs.firstItem.current.getBoundingClientRect().height) + 'px';
            const isButtonSizeChanged = (this.state.prevButtonWidth !== prevButtonWidth || this.state.prevButtonHeight !== prevButtonHeight);
            if (isButtonSizeChanged || isChangeSizeProps) {
                state['prevButtonWidth'] = prevButtonWidth;
                state['prevButtonHeight'] = prevButtonHeight;
            }
        } else if (this.myRefs.prevButton.current) {
            const prevButtonWidth = String(this.myRefs.prevButton.current.getBoundingClientRect().width) + 'px';
            const prevButtonHeight = String(this.myRefs.prevButton.current.getBoundingClientRect().height) + 'px';
            const isButtonSizeChanged = (this.state.prevButtonWidth !== prevButtonWidth || this.state.prevButtonHeight !== prevButtonHeight);
            if (isButtonSizeChanged || isChangeSizeProps) {
                state['prevButtonWidth'] = prevButtonWidth;
                state['prevButtonHeight'] = prevButtonHeight;
                needUpdate = true;
            }
        }

        if (this.state.mouseOverItemKey !== prevState.mouseOverItemKey) {
            needUpdate = true;
            let component = OBTGroupSelector.onRenderItem(
                this.props.list,
                this.props.onRenderItem,
                this.props.template,
                prevState.defaultTemplate,
                this.props.onMapItem,
                this.props.value,
                this.state.mouseOverItemKey,
                this.props.itemKeyProperty
            );

            state['component'] = component;
            needUpdate = true;
        }

        if (needUpdate) {
            this.setState(state)
        }
    }

    static onRenderItem = (list, onRenderItem, template, onDefault, onMapItem, value, mouseOverKey: string | null, itemKeyProperty: string) => {
        let arrayComponent: any = [];
        list.map((item, index) => {
            const e = new ItemEventArgs(item);
            e.value = value;
            e.index = index;
            e.mouseOver = mouseOverKey !== null && mouseOverKey === item[itemKeyProperty]
            e.isSelected = value === item[itemKeyProperty]
            e.mouseOverKey = mouseOverKey

            if (template === TemplateType.default) {
                if (onMapItem) {
                    onMapItem(e)
                }
                delete e.mouseOver;
                delete e.isSelected;

                onDefault(e)
                arrayComponent.push(e.component)
            }
            else if (onRenderItem) {
                onRenderItem(e)
                arrayComponent.push(e.component);
            }

            return item
        })
        return arrayComponent;
    }

    /**
     * 이전, 다음 버튼을 가지고 있는지 여부
     */
    private hasPrevNextButton = () => {
        const hasPrev = this.state.hiddenItems <= 0 ? false : true;
        const hasNext = this.state.list && (this.state.hiddenItems + this.state.count) < this.state.list.length ? true : false;
        const autoHide = !hasPrev && !hasNext && this.props.autoHideButtons === true ? true : false;

        return !autoHide;
    }

    onClickPrev = () => {
        if (this.props.disabled !== true) {
            let count = this.countItems();

            this.setState({
                count: count,
                hiddenItems: this.state.hiddenItems - count < 0 ? 0 : this.state.hiddenItems - count
            })
        }
    }

    onClickNext = () => {
        if (this.props.disabled !== true) {
            let count = this.countItems();

            if (this.state.list)
                this.setState({
                    count: count,
                    hiddenItems: this.state.hiddenItems + count >= this.state.list.length ? this.state.hiddenItems : this.state.hiddenItems + count
                })
        }
    }

    countItems = () => {
        if (this.myRefs.centerWrap.current) {
            const minimumMargin = 0;
            const border = 2;

            let width;
            let height;
            let count;
            if ((this.props.itemWidth || this.props.template) && this.props.align === AlignType.horizontal) {
                width = Number(this.props.itemWidth.replace('px', '')) + minimumMargin + border;
                if (width !== 0)
                    count = Math.floor(this.myRefs.centerWrap.current.getBoundingClientRect().width / width);
                if (isNaN(width) && this.props.itemWidth) {
                    width = Number(this.props.itemWidth.replace('%', ''));
                    if (!isNaN(width) && width !== 0) {
                        width = (this.myRefs.centerWrap.current.getBoundingClientRect().width * width / 100);
                        count = Math.floor(this.myRefs.centerWrap.current.getBoundingClientRect().width / width);
                    }
                }
            } else if ((this.props.itemHeight || this.props.template) && this.props.align === AlignType.vertical) {
                height = Number(this.props.itemHeight.replace('px', '')) + minimumMargin + border;
                if (height !== 0)
                    count = Math.floor(this.myRefs.centerWrap.current.getBoundingClientRect().height / height);
                if (isNaN(height) && this.props.itemHeight) {
                    height = Number(this.props.itemHeight.replace('%', '')) + minimumMargin + border
                    if (!isNaN(height) && height !== 0) {
                        height = Math.floor(this.myRefs.centerWrap.current.getBoundingClientRect().height * height / 100);
                        count = Math.floor(this.myRefs.centerWrap.current.getBoundingClientRect().height / height);
                    }
                }
            }
            if (isNaN(count)) {
                count = 0;
            }
            return count;
        }
    }

    private handleChange = (value: string): void => {
        Util.invokeEvent<Events.ChangeEventArgs<string>>(this.props.onChange, new Events.ChangeEventArgs<string>(this, value));
    }

    render() {
        let firstVisibleItemAssignRef = false;
        const array: any[] = this.state.component.map((component, index) => {
            if (this.state.list && (this.state.hiddenItems) > index) {
                return <div key={index} style={{ display: 'none' }}>{component}</div>
            }

            const mouseEvent = this.props.onRenderItem ? {
                onMouseEnter: (e) => {
                    if (this.props.list.length > index) {
                        this.setState({
                            mouseOverItemKey: this.props.list[index][this.props.itemKeyProperty]
                        })
                    }
                },
                onMouseLeave: (e) => {
                    this.setState({
                        mouseOverItemKey: null
                    })
                },
                onClick: (e) => {
                    this.handleChange(component.key)
                }
            } : {}

            let width = "";
            if ((component && component.props && component.props.style && component.props.style.width)) {
                width = component.props.style.width;

                if (width.endsWith('%')) {
                    width = 'calc(' + width + ' - 4px )';
                }
            }

            const clone = React.cloneElement(component, {
                ref: firstVisibleItemAssignRef === false ? this.myRefs.firstItem : null,
                style: {
                    ...(component && component.props) ? component.props.style : {},
                    // margin: this.props.align === AlignType.horizontal ? '0px 4px' : '4px 0px',
                    width: width,
                },
                ...mouseEvent
            });

            return clone;
        });

        let cnt = this.state.list ? this.state.list.length % this.state.count : 0;
        if (this.state.component) {
            for (let i = 0; i < this.state.count - cnt; i++) {

                const targetComponent = this.state.component[0];
                let width = "";
                if ((targetComponent && targetComponent.props && targetComponent.props.style && targetComponent.props.style.width)) {
                    width = targetComponent.props.style.width;

                    if (width.endsWith('%')) {
                        width = 'calc(' + width + ' - 4px )';
                    }
                }
                array.push(React.cloneElement(targetComponent, {
                    key: 'noneBlock' + String(i),
                    style: {
                        ...(targetComponent && targetComponent.props) ? targetComponent.props.style : {},
                        width: width,
                        visibility: 'hidden',
                    },
                }))
            }
        }

        const hasPrev = this.state.hiddenItems <= 0 ? false : true;
        const hasNext = this.state.list && (this.state.hiddenItems + this.state.count) < this.state.list.length ? true : false;
        const autoHide = !hasPrev && !hasNext && this.props.autoHideButtons === true ? true : false;

        let childWidth = this.props.align === AlignType.horizontal ? '100%' : this.state.prevButtonWidth ? this.state.prevButtonWidth : this.state.prevButtonWidth ? this.state.prevButtonWidth : this.props.itemWidth;
        let childHeight = this.props.align === AlignType.horizontal ? this.state.prevButtonHeight ? this.state.prevButtonHeight : this.state.prevButtonHeight ? this.state.prevButtonHeight : this.props.itemHeight : '100%';

        if (childWidth === '0px') {
            childWidth = this.props.align === AlignType.horizontal ? '100%' : this.props.itemWidth;
        }

        if (childHeight === '0px') {
            childHeight = this.props.align === AlignType.horizontal ? this.props.itemHeight : '100%';
        }
        return (
            <div id={this.props.id}
                data-orbit-component={'OBTGroupSelector'}
                className={Util.getClassNames(this.props.className, styles.root, this.props.disabled === true ? styles.disabled : null)}
                style={{
                    ...Util.getWrapperStyle(this.props),
                    flexDirection: this.props.align === AlignType.horizontal ? 'row' : 'column'
                }}>
                <div
                    className={Util.getClassNames(styles.prevButton,
                        hasPrev ? null : styles.disabled,
                        this.props.disabled === true ? styles.disabled : null,
                        autoHide ? styles.hide : null)}
                    ref={this.myRefs.prevButton}
                    onClick={this.onClickPrev}
                    style={{
                        width: this.props.align === AlignType.horizontal ? '15px' : this.props.itemWidth,
                        height: this.props.align === AlignType.horizontal ? this.props.itemHeight : '15px',
                        // marginRight: this.props.align === AlignType.horizontal ? '4px' : undefined,//'10px 4px 10px 10px' : '10px 10px 4px 10px'
                        // marginBottom: this.props.align === AlignType.horizontal ? undefined : '4px'//'10px 4px 10px 10px' : '10px 10px 4px 10px'
                    }} >
                    <img className={styles.arrow} src={this.props.align === AlignType.horizontal ? leftImg : upImg} alt='' />
                </div>
                <div
                    className={Util.getClassNames(styles.child, this.props.disabled === true ? styles.disabled : null, this.props.align === AlignType.horizontal ? styles.horizontal : styles.vertical)}
                    ref={this.myRefs.centerWrap}
                    style={{
                        width: childWidth,
                        height: childHeight,
                    }}>
                    {array}
                </div>
                <div
                    className={Util.getClassNames(styles.nextButton,
                        hasNext ? null : styles.disabled,
                        this.props.disabled === true ? styles.disabled : null,
                        autoHide ? styles.hide : null)}
                    onClick={this.onClickNext}
                    style={{
                        width: this.props.align === AlignType.horizontal ? '15px' : this.props.itemWidth,
                        height: this.props.align === AlignType.horizontal ? this.props.itemHeight : '15px',
                        // margin: this.props.align === AlignType.horizontal ? '10px 10px 10px 4px' : '4px 10px 10px 10px'
                        // marginLeft: this.props.align === AlignType.horizontal ? '4px' : undefined,//'10px 4px 10px 10px' : '10px 10px 4px 10px'
                        // marginTop: this.props.align === AlignType.horizontal ? undefined : '4px'
                    }}>
                    <img className={styles.arrow} src={this.props.align === AlignType.horizontal ? rightImg : downImg} alt=''>
                    </img>
                </div>
            </div>
        )
    }
};
