import React from 'react';
import OBTButton from '../OBTButton';
import OBTColorPicker from '../OBTColorPicker';
import { ISideBarContent } from './SideBar';
import SideBarContent, { SideBarContentType } from './SideBarContent';
import iconColorSelect from '../Images/icon_color_select.png'
import OBTDialog2 from '../OBTDialog2';
import { IPageContainerFunctions } from './OBTPageContainer';
import { OrbitInternalLangPack } from '../Common/Util';

// const styles = require('./SideBarContent.module.scss');

interface ISBCCheckPen {
    item: ISideBarContent,
    pageContainerFunctions: IPageContainerFunctions,
}

interface IState {
    selectedColor: any,
    isOpenColorPicker: boolean,
    colorItems: {
        importantColorSet: string[],
        shouldCheckColorSet: string[]
        currentUsedColorSet: string[]
    },
    checkPenOn: boolean,
}

export default class SBCCheckPen extends React.Component<ISBCCheckPen, IState> {
    state: IState = {
        selectedColor: { hex: '' },
        isOpenColorPicker: false,
        colorItems: {
            currentUsedColorSet: [],
            importantColorSet: ['#bd3e41', '#fc5356', '#ff9987', '#ffa94d', '#ffe748'],
            shouldCheckColorSet: ['#156cbc', '#1c90fb', '#60bcff', '#8b8bff', '#bc8bff']
        },
        checkPenOn: true
    }

    private myRefs = {
        root: React.createRef<HTMLDivElement>()
    }

    private handleClickChangeColor = () => {
        let currentUsedColorSet = this.state.colorItems.currentUsedColorSet;

        // 유니크 체크
        if (currentUsedColorSet.some(color => color === this.state.selectedColor.hex) === false) {
            currentUsedColorSet = [this.state.selectedColor.hex].concat(currentUsedColorSet);
        }

        // 최대 아이템 갯수 제한
        currentUsedColorSet = currentUsedColorSet.slice(0, this.props.item.data ? 4 : 5);

        this.setState({
            isOpenColorPicker: false,
            colorItems: {
                ...this.state.colorItems,
                currentUsedColorSet: currentUsedColorSet
            }
        }, () => {
            if (this.props.item && this.props.item.onApply) {
                this.props.item.onApply(this.state.selectedColor.hex);

                if (this.props.pageContainerFunctions) {
                    this.props.pageContainerFunctions.snackbar({
                        labelText: OrbitInternalLangPack.getText('WE000025558', '체크펜이 적용되었습니다.'),
                        type: 'success'
                    });
                }
            }
        })

    }

    private handleClickClearColor = () => {
        if (this.props.item && this.props.item.onApply) {
            this.props.item.onApply("")

            if (this.props.pageContainerFunctions) {
                this.props.pageContainerFunctions.snackbar({
                    labelText: OrbitInternalLangPack.getText('WE000025557', '체크펜이 삭제되었습니다.'),
                    type: 'success'
                });
            }
        }
    }

    private renderColorBox = (color: string, isLastItem: boolean, rightBorder: boolean) => {
        const isSelected = this.state.selectedColor && this.state.selectedColor.hex === color;
        return (
            <React.Fragment key={color}>
                <div key={color}
                    style={{
                        display: 'inline-block',
                        backgroundColor: color,
                        marginRight: isLastItem ? '0px' : (rightBorder ? '5px' : '11px'),
                        border: isSelected ? '1px solid white' : '',
                        borderRadius: isSelected ? '4px' : '',
                        boxShadow: isSelected ? '0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 10px 0 rgba(0, 0, 0, 0.19)' : '',
                        width: '25px',
                        height: '25px',
                        boxSizing: 'border-box',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        this.setState({
                            selectedColor: {
                                hex: color
                            },
                        });
                    }}
                />
                {
                    rightBorder ? (
                        <div style={{
                            display: 'inline-block',
                            height: '17px',
                            borderRight: '1px solid #cccccc',
                            marginRight: isLastItem ? '0px' : '5px',
                        }}>
                        </div>
                    ) : null
                }
            </ React.Fragment>
        );
    }

    render() {
        return (
            <SideBarContent
                forwardRef={this.myRefs.root}
                item={Object.assign({}, this.props.item, {
                    title: OrbitInternalLangPack.getText('WE000025554', '체크펜'),
                    type: SideBarContentType.accordion
                })}
                onChangeCheckPen={(value) => {
                    this.setState({
                        checkPenOn: !this.state.checkPenOn
                    }, async () => {
                        if (this.props.pageContainerFunctions) {

                            await this.props.pageContainerFunctions.showLoading(OrbitInternalLangPack.getText('WE000025553', '체크펜 설정 변경중입니다.'));

                            if (value) {
                                this.props.pageContainerFunctions.snackbar(OrbitInternalLangPack.getText('WE000025555', '체크펜 ON'));
                            } else {
                                this.props.pageContainerFunctions.snackbar(OrbitInternalLangPack.getText('WE000025556', '체크펜 OFF'));
                            }

                            this.props.item['changeApplyCheckPen'](value);

                            await this.props.pageContainerFunctions.hideLoading();
                        }
                    })
                }}
            >
                <div>
                    <div>
                        {/* 색상 벅스 영역 1 */}
                        <div style={{
                            backgroundColor: '#f5f5f5',
                            padding: '5px 10px'
                        }}>
                            {OrbitInternalLangPack.getText('WE000022856', '중요한 항목입니다.')}
                        </div>
                        <div style={{
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '10px 16px'
                        }}>
                            {
                                this.state.colorItems.importantColorSet.map((color, index) => {
                                    return this.renderColorBox(color, this.state.colorItems.importantColorSet.length === index + 1, false);
                                })
                            }
                        </div>
                    </div>

                    {/* 색상 벅스 영역 2 */}
                    <div>
                        <div style={{
                            backgroundColor: '#f5f5f5',
                            padding: '5px 10px'
                        }}>
                            {OrbitInternalLangPack.getText('WE000022853', '검토가 필요합니다.')}
                        </div>
                        <div style={{
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '10px 16px'
                        }}>
                            {
                                this.state.colorItems.shouldCheckColorSet.map((color, index) => {
                                    return this.renderColorBox(color, this.state.colorItems.shouldCheckColorSet.length === index + 1, false);
                                })
                            }
                        </div>
                    </div>

                    {/* 색상 벅스 영역 3 */}
                    {
                        this.state.colorItems.currentUsedColorSet.length > 0 || this.props.item.data ? (
                            <div>
                                <div style={{
                                    backgroundColor: '#f5f5f5',
                                    padding: '5px 10px'
                                }}>
                                    {OrbitInternalLangPack.getText('WE000022850', '현재 | 최근 사용한 색')}
                                </div>
                                <div style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    // justifyContent: 'left',
                                    padding: '10px 16px'
                                }}>
                                    {/* 현재 선택한 색 */}
                                    {
                                        this.props.item.data ? this.renderColorBox(
                                            (this.props.item.data as string).length === 9 ? (this.props.item.data as string).replace('#ff', '#') : this.props.item.data as string,
                                            false,
                                            true
                                        ) : null
                                    }

                                    {/* 최근 선택한 색 */}
                                    {
                                        this.state.colorItems.currentUsedColorSet.slice(0, this.props.item.data ? 4 : 5).map((color, index) => {
                                            return this.renderColorBox(color, this.state.colorItems.currentUsedColorSet.slice(0, this.props.item.data ? 4 : 5).length === index + 1, false);
                                        })
                                    }
                                </div>
                            </div>
                        ) : null
                    }

                    {/* 다른색 선택하기 영역 */}
                    <div style={{
                        padding: '5px 10px',
                        cursor: 'pointer',
                        borderTop: '1px solid #e6e6e6'
                    }}>
                        <div style={{
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }} onClick={() => {
                            this.setState({
                                isOpenColorPicker: true,
                            })
                        }}>
                            <img src={iconColorSelect} style={{
                                paddingRight: '4px'
                            }} alt={''} />
                            <div>다른색 선택하기</div>
                        </div>
                    </div>

                    {/* 버튼영역 */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#f7f7f7',
                        padding: '9px 10px',
                    }}>
                        <div style={{
                            paddingRight: '4px'
                        }}>
                            <OBTButton
                                labelText={OrbitInternalLangPack.getText('WE000017441', '삭제')}
                                disabled={!this.state.checkPenOn}
                                type={OBTButton.Type.small}
                                onClick={this.handleClickClearColor}
                            />
                        </div>
                        <div>
                            <OBTButton labelText={OrbitInternalLangPack.getText('WE000005198', '적용')}
                                disabled={!this.state.checkPenOn}
                                type={OBTButton.Type.small}
                                theme={OBTButton.Theme.blue}
                                onClick={this.handleClickChangeColor}
                            />
                        </div>
                    </div>
                </div>

                {/* 다이얼로그 */}
                <OBTDialog2
                    key={this.state.isOpenColorPicker ? 'open' : 'close'}
                    title={OrbitInternalLangPack.getText('WE000022635', '다른색 선택하기')}
                    width="270px"
                    height="435px"
                    type={OBTDialog2.Type.small}
                    open={this.state.isOpenColorPicker}
                    buttons={[
                        {
                            key: 'close',
                            onClick: () => {
                                this.setState({
                                    isOpenColorPicker: false,
                                })
                            },
                            labelText: OrbitInternalLangPack.getText('WE000001970', '닫기'),
                            visible: false,
                            isClose: true
                        },
                        {
                            key: 'accept',
                            onClick: this.handleClickChangeColor,
                            labelText: OrbitInternalLangPack.getText('WE000005198', '적용'),
                            theme: OBTDialog2.ButtonTheme.blue,
                            disabled: !this.state.checkPenOn
                        },
                    ]}
                >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <OBTColorPicker
                            type={OBTColorPicker.Type.popUp}
                            onButtonClick={e => { }}
                            value={this.state.selectedColor}
                            onChange={(e) => {
                                this.setState({
                                    selectedColor: {
                                        hex: e.value.hex,
                                    },
                                });
                            }}
                        >
                        </OBTColorPicker>
                    </div>
                </OBTDialog2>
            </SideBarContent >
        )
    }
}
