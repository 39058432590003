/**
* OBTTextField
* @version 0.1
* @author 김철희
* @see LUXTextField
*/
import * as React from 'react';
import LUXTextField from 'luna-rocket/LUXTextField';
import { Events, CompositeProps, Util, CommonProps, Functions, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import OBTTooltip, { IOBTTooltip } from '../OBTTooltip/OBTTooltip';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { OBTContext, UserProfilePopUpParameter, IOBTContext } from '../OBTPageContainer/OBTPageContainer';
import userProfileIcon from '../Images/ic_profile.png';
import GuideMessageRenderer from '../Common/GuideMessageRenderer'

const styles = require('./OBTTextField.module.scss');

enum TextType {
    'default' = 'default',   // 기본타입
    'password' = 'password',  // 암호타입
    'codePicker' = 'codePicker' // 코드피커타입
}

enum AlignType {
    'left' = 'left',
    'center' = 'center',
    'right' = 'right'
}

enum ValidateStateEnum {
    'none' = 'none',
    'success' = 'success',
    'warning' = 'warning',
    'error' = 'error'
}

interface IOBTTextField extends CompositeProps.SubInputClass<string>, CommonProps.placeHolder, Events.onValidate<string>, Events.onClick, Events.onMouseDown,
    Events.onMouseMove, Events.onMouseUp, Events.onMouseLeave, Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress, Events.onKeyUp {
    type: TextType,
    maxLength?: number,
    maxByte?: number,
    align: AlignType,
    tooltip?: IOBTTooltip,
    codePickerIcon?: string | React.ReactSVGElement,
    onCodePickerClick?: (e: Events.EventArgs) => void,
    /**
     * 
     */
    getProfileInfo?: (e: {
        value: any
    }) => Promise<UserProfilePopUpParameter>,
    validateState?: ValidateStateEnum,
    useAutoComplete?: boolean
}

interface State extends hasError {
}

export default class OBTTextField extends React.Component<IOBTTextField, State> implements Functions.IFocusable, Functions.IRequired {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.SubInputClass(CommonType.string),
        CommonDefinitions.placeHolder(),
        { name: "type", type: toEnumType(TextType), description: "타입을 지정합니다.", default: "default", optional: true },
        { name: "maxLength", type: CommonType.number, description: "입력 최대길이를 지정할 수 있습니다.", optional: true },
        { name: "maxByte", type: CommonType.number, description: "입력 최대Byte를 지정할 수 있습니다.", optional: true },
        { name: "align", type: toEnumType(AlignType), description: "텍스트 정렬 방법을 지정합니다.", default: "left", optional: true },
        { name: "validateState", type: toEnumType(ValidateStateEnum), description: "입력필드의 유효성 상태를 표현할 수 있습니다.", optional: true },
        { name: "useAutoComplete", type: CommonType.boolean, description: "자동완성 기능을 사용할지에 대한 여부를 설정합니다.", default: true},
        { name: "codePickerIcon", type: [CommonType.image, "SVGComponent"], description: "type = codePicker 인 경우 코드피커 아이콘을 지정할 수 있습니다.", optional: true },
        {
            name: "onCodePickerClick", type: CommonType.function, description: "type: codePicker 로 설정시 버튼 클릭 이벤트", optional: true, parameters: {
                name: "e",
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                }
            }
        },
        CommonDefinitions.tooltip(),
        CommonDefinitions.InnerFunc(),
        CommonDefinitions.onValidate(),
        CommonDefinitions.Event(),
    )

    context!: React.ContextType<typeof OBTContext>;
    public static Type = TextType;
    public static Align = AlignType;
    public static ValidateState = ValidateStateEnum;
    public static valueType = 'text';
    private moveFocusInvoked: boolean = false;


    public static defaultProps: Partial<IOBTTextField> = {
        disabled: false,
        readonly: false,
        required: false,
        type: TextType.default,
        align: AlignType.left,
        useAutoComplete: true
    }


    public state: State = {}


    public myRefs = {
        ref: React.createRef<LUXTextField>()
    }

    render() {
        return (<ErrorBoundary owner={this} render={this.renderComponent} />)
    }

    renderComponent = () => {
        const value = this.props.value && this.props.maxLength !== undefined && this.props.value.length > this.props.maxLength ?
            this.props.value.substring(0, this.props.maxLength) : this.props.value && this.props.maxByte !== undefined && this.getByte(this.props.value) > this.props.maxByte ?
                this.getByteStr(this.props.value, this.props.maxByte) : this.props.value;
        const subValue = this.props.subValue && this.props.maxLength !== undefined && this.props.subValue.length > this.props.maxLength ?
            this.props.subValue.substring(0, this.props.maxLength) : this.props.subValue && this.props.maxByte !== undefined && this.getByte(this.props.subValue) > this.props.maxByte ?
                this.getByteStr(this.props.subValue, this.props.maxByte) : this.props.subValue;
        const props = {
            // Value
            defaultValue: value === undefined || value === null ? "" : Util.cl(value, subValue, this.props.useSubLang),
            // defaultValue: value,
            valueOuterControl: true,
            onChange: this.handleChange.bind(this),

            // Events
            onFocus: this.handleFocus.bind(this),
            onBlur: this.handleBlur.bind(this),
            onMoveFocus: this.handleMoveFocus.bind(this),
            onKeyDown: this.handleKeyDown.bind(this),
            onTouchTapButton: this.handleCodePickerClick,

            // Etc
            inputElementType: (this.props.type === TextType.password ? 'password' : 'text'),
            focusOnSelect: true,
            disabled: this.props.disabled,
            hintText: this.props.placeHolder,
            fullWidth: true,
            maxLength: this.props.maxLength,
            customIconType: this.props.type === TextType.codePicker ? 'help' : undefined,

            checkData: (() => {
                switch (this.props.validateState) {
                    case ValidateStateEnum.success: return 'Success';
                    case ValidateStateEnum.error: return 'Error';
                    case ValidateStateEnum.warning: return 'Warning';
                    default:
                        return undefined;
                }
            })(),
            checkDataClean: this.props.validateState && this.props.validateState !== ValidateStateEnum.none ? false : true,
            informationText: null,

            // Styling
            style: Object.assign({}, Util.getInputStateStyle(this.props), { width: '100%' }),
            inputBoxStyle: { padding: '3px 6px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', ...Util.getInputStateStyle(this.props) },
            inputStyle: {
                textAlign: this.props.align === AlignType.right ? 'right' : this.props.align === AlignType.center ? 'center' : 'left',
                color: this.props.readonly ? 'transparent' : undefined,
                textShadow: this.props.readonly ? '0 0 0 black' : undefined,
                fontFamily: 'inherit',
                // lineHeight: '13px'
            }
        };
        if (this.props.height && this.props.height.length > 0 && this.props.height !== 'auto') {
            props.inputBoxStyle['height'] = this.props.height;
            props.inputStyle['height'] = this.props.height;
            props['editingStyle'] = {
                height: this.props.height
            };
            props['iconStyle'] = {
                height: this.props.height
            }
        }

        return <OBTTooltip {...this.props.tooltip}
            className={Util.getClassNames(styles.obtTextField,
                this.props.type === TextType.codePicker && this.props.codePickerIcon ? styles.useCustomCodePickerIcon : undefined,
                this.props.validateState && this.props.validateState !== ValidateStateEnum.none ? styles.hideInformationText : undefined,
                this.props.className)}
            style={Util.getWrapperStyle(this.props)}
            overrideSize={false}
            onClick={this.props.onClick}
            onMouseDown={this.props.onMouseDown}
            onMouseMove={this.props.onMouseMove}
            onMouseUp={this.props.onMouseUp}
            onMouseLeave={this.props.onMouseLeave}
            onMouseEnter={this.props.onMouseEnter}
            onKeyUp={this.props.onKeyUp}
            onKeyDown={this.props.onKeyDown}
            onKeyPress={this.props.onKeyPress}
            rootProps={{
                id: this.props.id,
                'data-orbit-component': 'OBTTextField'
            }}>
            <LUXTextField ref={this.myRefs.ref} {...props} />
            {this.props.type === TextType.codePicker && this.props.codePickerIcon ?
                (typeof this.props.codePickerIcon === 'string' ? <img className={styles.customCodePickerIcon} src={this.props.codePickerIcon} alt={''} /> : <div className={styles.customCodePickerIcon}>{this.props.codePickerIcon}</div>) : undefined}
            {this.renderUserProfile()}
        </OBTTooltip>;
    }

    setReadonly = () => {
        if (this.myRefs.ref.current && this.myRefs.ref.current.textfieldRef) {
            const textFieldRef = this.myRefs.ref.current.textfieldRef;
            const elReadonly = textFieldRef.getAttribute('readonly');
            if (this.props.readonly && !elReadonly) {
                textFieldRef.setAttribute('readonly', 'readonly');
            } else if (!this.props.readonly && elReadonly) {
                textFieldRef.removeAttribute('readonly');
            }
        }
    }

    setAutoComplete = () => {
        if (this.props.useAutoComplete) return;

        if (this.myRefs.ref.current && this.myRefs.ref.current.textfieldRef) {
            const textFieldRef = this.myRefs.ref.current.textfieldRef;
            const autoComplete = textFieldRef.getAttribute('autoComplete');
            if (autoComplete === 'pqdwef') {
                textFieldRef.setAttribute('autoComplete', 'new-password');
            }
        }
    }

    componentDidMount() {
        this.setReadonly();
        this.setAutoComplete();
    }

    componentDidUpdate() {
        this.setReadonly();
        this.setAutoComplete();
    }

    public focus(): void {
        if (!this.props.frozen && !this.props.disabled && this.myRefs.ref.current) {
            this.myRefs.ref.current.focus();
        }
    }

    public blur(): void {
        if (this.myRefs.ref.current) {
            this.myRefs.ref.current.blur();
        }
    }

    private get canEdit(): boolean {
        return !this.props.disabled && !this.props.readonly && this.props.type !== TextType.codePicker;
    }

    public isEmpty(): boolean {
        return (!Util.cl(this.props.value, this.props.subValue, this.props.useSubLang) || Util.cl(this.props.value, this.props.subValue, this.props.useSubLang).length <= 0);
    }

    public validate(): boolean {
        return !(this.props.required === true && this.isEmpty());
    }

    private handleMoveFocus(direction: string): void {
        this.moveFocusInvoked = true;

        if (this.props.required && this.isEmpty() && ['right', 'down', 'enter'].includes(direction)) {
            return;
        }

        if (this.myRefs.ref.current && this.myRefs.ref.current.textfieldRef && ['left', 'right'].includes(direction)) {
            const inputRef = this.myRefs.ref.current.textfieldRef as HTMLInputElement

            // 텍스트의 영역이 선택되어 있다면 onMoveFocus 호출하지 않고 커서의 위치만 바꿈
            if (inputRef.selectionStart !== inputRef.selectionEnd) {
                this.moveFocusInvoked = false;
                return;
            }
        }

        Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
    }

    private handleFocus(e): void {
        Util.invokeEvent<Events.FocusEventArgs>(this.props.onFocus, new Events.FocusEventArgs(this, e));

        GuideMessageRenderer.handleFocus(
            this, this.props.id, this.context
        );
    }

    private handleChange(e: any, value: string): void {
        if (this.canEdit) {
            let oldValue = this.props.value;
            let newValue = Util.cl(value, this.props.value, this.props.useSubLang);
            let oldSubValue = this.props.subValue ? this.props.subValue : null;
            let newSubValue = Util.cl(this.props.subValue, value, this.props.useSubLang);
            if (Util.invokeEvent<Events.ValidateWithSubEventArgs<string>>(this.props.onValidate,
                new Events.ValidateWithSubEventArgs(this, oldValue, newValue, oldSubValue, newSubValue!))) {
                Util.invokeEvent<Events.ChangeWithSubEventArgs<string>>(this.props.onChange, new Events.ChangeWithSubEventArgs<string>(this, newValue, newSubValue));
            }
        }
    }

    private handleBlur(): void {
        Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));

        GuideMessageRenderer.handleBlur(
            this.context,
            this.props.id,
        );
    }

    private handleKeyDown(e: React.KeyboardEvent): void {
        if (this.moveFocusInvoked) {
            this.moveFocusInvoked = false;
            e.preventDefault();
        }
    }

    private handleCodePickerClick = () => {
        Util.invokeEvent<Events.EventArgs>(this.props.onCodePickerClick, new Events.EventArgs(this));
    }


    private renderUserProfile = () => {
        if (!this.props.getProfileInfo) {
            return;
        }

        if (!this.props.value || this.props.value.length === 0) {
            return;
        }

        const context = this.context as IOBTContext;
        if (!context || !context.showUserProfile) {
            return;
        }

        return (
            <>
                <img src={userProfileIcon}
                    onClick={() => {
                        if (this.props.getProfileInfo) {
                            this.props.getProfileInfo({ value: this.props.value }).then(parameters => {
                                if (context.showUserProfile) {
                                    context.showUserProfile(parameters);
                                }
                            }).catch(() => {

                            })
                        }
                    }}
                    // className={styles.userProfile}
                    style={{
                        position: 'absolute',
                        display: 'inline-block',
                        top: '5px',
                        right: '7px',
                        cursor: 'pointer',
                    }}
                    alt={"code picker userProfile Icon"}
                />
            </>
        )
    }


    private getByte = (value: string) => {
        return value.split('').map(s => s.charCodeAt(0)).reduce((prev, c) => (prev + ((c === 10) ? 2 : ((c >> 7) ? 2 : 1))), 0);
    }

    private getByteStr = (value: string, len: number) => {
        for (let i = 0; i < value.length; i++) {
            if (this.getByte(value.substring(0, i)) === len) {
                return value.substring(0, i);
            }
        }
        return value;
    }
};

OBTTextField.contextType = OBTContext;