import React from "react";
import DocumentView from "./documentsView";

export class UpdateDocuments extends React.Component {

    state = {
        documents: [],
        isEmptyData: false
    }

    componentDidMount(e) {
        fetch('http://10.82.6.190/history/findall', {
            cache: "no-cache"
        }).then((response) => {
            return response.json()
        }).then((result) => {
            // const minimumTwoMonthData = result.filter(item => {
            //     const setNowDate = new Date()
            //     const fromDate = new Date(setNowDate.setMonth(setNowDate.getMonth() - 2))
            //     const initDate = moment(item.updateDate).isBetween(fromDate, new Date(), null, '[]')
            //     return initDate
            // })
            this.setState({
                documents: result
            })
        })
    }

    handleSearch = (e) => {
        fetch(`http://10.82.6.190/history/search?updateType=${e.updateType}&warningLevel=${e.warningLevel}&searchText=${e.searchText}&dateTo=${e.date.to}&dateFrom=${e.date.from}`, {
            cache: "no-cache",
            method: 'GET',
        }).then((response) => {
            return response.json()
        }).then((result) => {
            if (result.length < 1) {
                this.setState({
                    isEmptyData: true
                })
            } else if (result.length > 0) {
                this.setState({
                    documents: result,
                    isEmptyData: false
                })
            }
        })
    }

    handleRefresh = () => {
        fetch('http://10.82.6.190/history/findall', { cache: "no-cache" }).then((response) => {
            return response.json()
        }).then((result) => {
            this.setState({
                documents: result,
                isEmptyData: false
            })
        })
    }

    render() {
        return (
            <>
                <DocumentView
                    documents={this.state.documents}
                    onSearch={this.handleSearch}
                    onRefresh={this.handleRefresh}
                    emptySet={this.state.isEmptyData}
                />
            </>
        );
    }
}
