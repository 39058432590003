/**
 * OBTProgressDialog
 * @version 0.1
 * @author 신지유
 */
import * as React from 'react';
import { hasError } from '../Common/CommonState';
import { CommonDefinitions, CommonProps, CommonType, createPropDefinitions, toEnumType, Util } from '../Common';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import { IButton, ISampleButton, ISampleButtons, MouseEventArgs } from '../OBTDialog2/OBTDialog2';
import OBTButton from '../OBTButton';
import OBTDialog2 from '../OBTDialog2';
import LinearProgress from './LinearProgress';

const styles = require('./OBTProgressDialog.module.scss');

export enum Type {
    'error' = 'error',
    'default' = 'default',
    'warning' = 'warning',
    'success' = 'success'
}

interface IProgressItem {
    labelText: string,
    value: number
}

interface IOBTProgressDialog extends CommonProps.id, CommonProps.className, CommonProps.width, CommonProps.height, CommonProps.disabled {
    open: boolean,
    buttons?: Array<IButton>,
    title?: string,
    subTitle?: JSX.Element,
    type?: Type,
    progressItems: Array<IProgressItem>,
    isShowTotalProgress?: boolean
}

interface State extends hasError {
    currentIndex: number,
    isShowPanel?: boolean,
    tooltipPositionTop?: number
}

export default class OBTProgressDialog extends React.Component<IOBTProgressDialog, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.id(),
        CommonDefinitions.className(),
        CommonDefinitions.width(),
        CommonDefinitions.height(),
        CommonDefinitions.disabled(),
        { name: "open", type: CommonType.boolean, description: "true일때 Dialog가 열립니다." },
        { name: "title", type: CommonType.string, description: "Dialog의 제목을 설정합니다.", optional: true },
        { name: "subTitle", type: ["JSX.Element"], description: "Dialog의 부제목을 설정합니다.", optional: true },
        {
            name: "buttons", type: "Array<IButton>", optional: true, description: "Dialog의 하단의 버튼을 설정합니다.\n"
            + "\nOBTDialog2.Button.Confirm | OBTDialog2.Button.Cancel | OBTDialog2.Button.Save | OBTDialog2.Button.Close 배열로 감싸서 사용"
            + "\nex) buttons = {[OBTDialog2.Button.Confirm(this.Confirm,OBTButton.Theme.blue)]}\n"

            + "\nOBTDialog2.Buttons.ConfirmAndCancel | OBTDialog2.Buttons.SaveAndClose 단일 사용 가능"
            + "\nex) buttons = {OBTDialog2.Buttons.ConfirmAndCancel(this.Confirm,this.Cancel)}\n"

            + "\nCancel, Close는 기본적으로 isClose={true} 입니다."

            + "\nonClick의 funciont(target, event, type) 중 type은 "
            + "\nX버튼: closeButton, 하단버튼: button, 배경: background, 키보드 esc: esc\n"
        },
        {
            name: "type", type: toEnumType(Type), default: 'default', optional: true,
            description: '로딩바의 타입을 지정합니다. 타입에 따라 색상이 변경됩니다.'
        },
        {
            name: "progressItems", type: 'Array<IProgressItem>', description: "로딩되고 있는 파일,자원등의 정보를 넣는 배열입니다\n"
            +"{labelText: string, value: number} 형태로 작성합니다."
        },
        {
            name: "isShowTotalProgress", type: CommonType.boolean, description: "프로그래스바를 2개로 보여줄 것인지에 대한 여부입니다.",
            optional: true
        }
    );

    public static defaultProps: Partial<IOBTProgressDialog> = {
        type: Type.default
    }

    public state: State = {
        currentIndex: 0,
        isShowPanel: false,
        tooltipPositionTop: 0
    }

    myRefs = {
        itemLabelTextWrapper: React.createRef<HTMLParagraphElement>(),
        itemLabelText: React.createRef<HTMLSpanElement>(),
    }

    public static Type = Type;
    public static Button: ISampleButton = OBTDialog2.Button;
    public static Buttons: ISampleButtons = OBTDialog2.Buttons;
    private _handleButtonClicked: Function | undefined = undefined;

    componentDidUpdate(prevProps, prevState) {
        const { progressItems } = this.props;

        if (progressItems[this.state.currentIndex]["value"] >= 100 &&
            this.state.currentIndex < progressItems.length - 1) {
            this.setState(current => ({
                currentIndex: current.currentIndex + 1
            }));
        }
    }

    private handleClick = (onClick: any, event: any, type: string): void => {
        Util.invokeEvent<MouseEventArgs>(onClick, new MouseEventArgs(this, event, type));
    };

    private handleButtonClicked = (key: string) => {
        if (this._handleButtonClicked) {
            this._handleButtonClicked(key);
        }
    };

    private handleLabelTextMouseEnter = (isEntireLoading?: boolean) => {
        const { itemLabelTextWrapper, itemLabelText } = this.myRefs;
        if (
            itemLabelTextWrapper.current &&
            itemLabelText.current &&
            itemLabelText.current.offsetWidth >= itemLabelTextWrapper.current.clientWidth &&
            !isEntireLoading
        ) {
            const tooltipPositionTop = Number(((itemLabelText.current && itemLabelText.current.offsetWidth) / (itemLabelTextWrapper.current && itemLabelTextWrapper.current.clientWidth)).toFixed(1));
            this.setState({
                isShowPanel: true,
                tooltipPositionTop: tooltipPositionTop
            });
        }
    }

    private handleLabelTextMouseOut = (isEntireLoading?: boolean) => {
        if (!isEntireLoading) {
            this.setState({
                isShowPanel: false,
                tooltipPositionTop: 0
            });
        }
    }

    private settingProgressColor = () => {
        return (
            this.props.type === Type.default ? "rgb(0,135,244)" :
                this.props.type === Type.error ? "#fc5356" :
                    this.props.type === Type.warning ? "#febc2c" : "#3fa684"
        );
    }
    
    renderButtons = () => {
        let propButtons = this.props.buttons;
        let renderButtons;
        if (propButtons) {
            renderButtons = propButtons.map(button => {
                if (button.visible === false) return null;

                return <OBTButton
                    width={button.width}
                    height={button.height}
                    className={
                        Util.getClassNames(button.imageUrl ? styles.buttonWrapper : null,
                        button.buttonClassName)
                    }
                    key={button.key}
                    labelText={button.labelText}
                    disabled={button.disabled ? true : false}
                    theme={button.theme}
                    type={button.type ? button.type : OBTButton.Type.default}
                    onClick={(e) => {
                        if (button.onClick) this.handleClick(button.onClick, e, 'button');
                        this.handleButtonClicked(button.key);
                    }}
                    imageUrl={button.imageUrl && button.imageUrl}
                    imageTagClassName={
                        button.imageTagClassName
                            ? button.imageTagClassName
                                : styles.useImageUrl}
                />;
            });
        }
        return renderButtons;
    }

    renderProgress = (isEntireLoading?: boolean) => {
        const { currentIndex } = this.state;
        const { progressItems } = this.props;

        return (
            <React.Fragment>
                {/* 퍼센테이지 숫자 */}
                {!isEntireLoading ?
                    <div
                        className={Util.getClassNames(
                            styles.percentageText,
                            this.props.disabled && styles.disabled
                        )}
                        style={{ color: this.settingProgressColor() }}
                    >{progressItems[currentIndex]["value"]} %</div> : null}
                {/* Linear Progress 로딩바 */}
                <LinearProgress
                    value={!isEntireLoading ? progressItems[currentIndex]["value"] :
                            progressItems[progressItems.length-1]["value"] >= 100 ? 100 :
                                Math.ceil(100 * ((currentIndex) / progressItems.length))}
                    type={this.props.type}
                    isEntireLoading={isEntireLoading}
                    disabled={this.props.disabled}
                />
                {/* 하단 파일 이름 및 전체파일 진행 텍스트 */}
                <div
                    className={Util.getClassNames(styles.itemText,
                        !isEntireLoading && styles.singleLoading)}
                >
                    <p
                        className={Util.getClassNames(styles.itemLabelText,
                            !isEntireLoading && styles.singleLoading
                        )}
                        ref={this.myRefs.itemLabelTextWrapper}
                        onMouseEnter={(e) => this.handleLabelTextMouseEnter(isEntireLoading)}
                        onMouseOut={(e) => this.handleLabelTextMouseOut(isEntireLoading)}
                    >
                        {!isEntireLoading ?
                            <span ref={this.myRefs.itemLabelText}>
                                {progressItems[currentIndex]["labelText"]}
                            </span> :
                            <span>
                                {progressItems[progressItems.length - 1]["value"] >= 100 ? `전체 파일(${progressItems.length}/${progressItems.length})`:
                                    `전체 파일(${currentIndex}/${progressItems.length})`}
                            </span>
                        }
                    </p>
                    {progressItems.length > 1 && !isEntireLoading && !this.props.isShowTotalProgress &&
                        <p className={styles.itemStatus}>
                            {progressItems[progressItems.length - 1]["value"] >= 100 ? `전체 파일(${progressItems.length}/${progressItems.length})`:
                                `전체 파일(${currentIndex}/${progressItems.length})`}
                        </p> 
                    }
                </div>
                {!isEntireLoading && 
                    <div
                        className={Util.getClassNames(styles.tooltip,
                            !this.state.isShowPanel && styles.hide)}
                        style={this.state.tooltipPositionTop &&
                        this.state.tooltipPositionTop >= 2.6 ? {top: '78px'} : undefined}
                    >
                        <div className={styles.contents}>
                            {progressItems[currentIndex]["labelText"]}
                        </div>
                        <div className={styles.arrowWrapper}>
                            <div className={styles.arrow}/>
                            <div className={styles.arrowBackground}/>
                        </div>
                    </div>
                } 
            </React.Fragment>
        )
    }

    renderComponent = () => {
        return (
            <div
                id={this.props.id}  
                className={Util.getClassNames(styles.root,
                    this.props.open ? undefined :
                        styles.closed, this.props.className)}
                tabIndex={-1}
                data-orbit-component='OBTProgressDialog'
            >
                {this.props.open ?
                    <Fade duration={300}>
                        <div className={styles.dimmed} />
                    </Fade> : null
                }
                
                <div className={styles.wrapper}>
                    {this.props.open ?
                        <Bounce duration={300}>
                            <div
                                className={styles.dialogWrapper}
                                style={Util.getWrapperStyle(this.props)}
                            >
                                {/* 타이틀 */}
                                <div className={styles.title}>
                                    {this.props.title && this.props.title}
                                </div>
                                <div className={styles.subTitle}>
                                    {this.props.subTitle && this.props.subTitle}
                                </div>
                                {/* 진행 상황 바 */}
                                    {this.renderProgress()}
                                    {this.props.isShowTotalProgress && this.renderProgress(true)}
                                {/* 버튼 */}
                                <div className={styles.buttons}>
                                    {this.renderButtons()}
                                </div>
                            </div>
                        </Bounce> : null
                    }
                </div>
            </div>
        )
    }

    render() {
        return (<ErrorBoundary owner={this} render={this.renderComponent} />)
    }
};
