// let detector = require('detector');
// let os = detector.device.name;

let gridFont = '돋움'
let normalFontSize = "12";

// const BORDER_COLOR = '#ffe1e1e1';
const FOOTER_BACKGROUND_COLOR = '#fffff1d6';

const GridStyle = {
    // Dotum,'돋움',AppleSDGothicNeo,sans-serif, Helvetica
    styles: {
        "grid": {
            "background": "#ffffffff",
            "iconLocation": "left",
            "border": "#ffe1e1e1,0",
            "iconAlignment": "center",
            "fontFamily": gridFont,
            "fontSize": normalFontSize,
            "iconPadding": "0",
            "selectedBackground": "#ff696969",
            "contentFit": "auto",
            "inactiveBackground": "#ffd3d3d3",
            "iconOffset": "0",
            "selectionDisplay": "mask",
            "hoveredMaskBackground": "#ffff7000",
            "hoveredMaskBorder": "#ffff0000,1",
            "paddingRight": "2",
            "paddingBottom": "2",
            "paddingTop": "2",
            "figureBackground": "#ff657082",
            "selectedForeground": "#ffffffff",
            "foreground": "#ff000000",
            "inactiveForeground": "#ff808080",
            "line": "#ffb7bdc7,1",
            "textAlignment": "center",
            "lineAlignment": "center",
            "iconIndex": "0"
        },
        "panel": {
            "background": "#ffa4a8b1",
            "paddingRight": "2",
            "iconLocation": "left",
            "border": "#ffff000,1",
            "selectedForeground": "#ffffffff",
            "iconAlignment": "center",
            "foreground": "#ffffffff",
            "inactiveForeground": "#ff808080",
            "borderBottom": "#ff5a5f6c,1",
            "textAlignment": "near",
            "selectedBackground": "#ff696969",
            "lineAlignment": "center",
            "inactiveBackground": "#ffd3d3d3",
            "iconOffset": "0",
            "selectionDisplay": "mask",
            "hoveredMaskBorder": "#335292f7,1",
            "iconPadding": "0",
            "hoveredMaskBackground": "#1f5292f7",
            "contentFit": "auto",
            "paddingTop": "4",
            "borderRight": "#ff777777,1",
            "figureBackground": "#ff008800",
            "paddingBottom": "5",
            "iconIndex": "0"
        },
        "body": {
            "background": "#ffffffff",
            "iconLocation": "left",
            "iconAlignment": "center",
            "iconOffset": "0",
            "iconPadding": "0",
            "iconIndex": "0",
            "border": "#ffffffff,0",
            "borderBottom": "#e6e6e6,1",
            "borderLeft": "#e6e6e6,0",
            "borderRight": "#e6e6e6,1",
            "fontFamily": gridFont,
            "fontSize": normalFontSize,
            "borderTop": "#ffffffff,0",
            "selectedBackground": "#ff696969",
            "contentFit": "auto",
            "inactiveBackground": "#ffd3d3d3",
            "selectionDisplay": "mask",
            "hoveredMaskBackground": "#1f5292f7",
            "hoveredMaskBorder": "#335292f7,1",
            "figureBackground": "#ff4995ff",
            "paddingLeft": "8",
            "paddingRight": "8",
            "paddingBottom": "2",
            "paddingTop": "2",
            "selectedForeground": "#ffffffff",
            "foreground": "#ff222530",
            "inactiveForeground": "#ff808080",
            "line": "#ff8a8686,0",
            "textAlignment": "center",
            "lineAlignment": "center",

            "empty": {
                "fontSize": 16,
                "foreground": "#8c8c8c",
                "textAlignment": "center",
                "lineAlignment": "center",
                // "background": "#ff00ffff"
            }
        },
        "fixed": {
            "background": "#ffffffff",
            "iconLocation": "left",
            "iconAlignment": "center",
            "iconOffset": "0",
            "iconPadding": "0",
            "iconIndex": "0",
            "border": "#ffffffff,0",
            "borderBottom": "#e6e6e6,1",
            "borderLeft": "#e6e6e6,0",
            "borderRight": "#e6e6e6,1",
            "fontFamily": gridFont,
            "fontSize": normalFontSize,
            "borderTop": "#ffffffff,0",
            "selectedBackground": "#ff696969",
            "contentFit": "auto",
            "inactiveBackground": "#ffd3d3d3",
            "selectionDisplay": "mask",
            "hoveredMaskBackground": "#1f5292f7",
            "hoveredMaskBorder": "#335292f7,1",
            "figureBackground": "#ff4995ff",
            "paddingLeft": "8",
            "paddingRight": "8",
            "paddingBottom": "2",
            "paddingTop": "2",
            "selectedForeground": "#ffffffff",
            "foreground": "#ff222530",
            "inactiveForeground": "#ff808080",
            "line": "#ff8a8686,0",
            "textAlignment": "center",
            "lineAlignment": "center",
            "colBar": {
                "background": "#ffffff",
                // "iconLocation": "left",
                "borderRight": "#e6e6e6,1",
                "borderLeft": "#e6e6e6,1",
                "padding": "0"
                // "iconAlignment": "center",
                // "borderBottom": "#ff808080,1",
                // "fontFamily": gridFont,
                // "fontSize": normalFontSize,
                // "iconPadding": "0",
                // "selectedBackground": "#ff696969",
                // "contentFit": "auto",
                // "inactiveBackground": "#ffd3d3d3",
                // "iconOffset": "0",
                // "selectionDisplay": "mask",
                // "hoveredMaskBackground": "#ffff7000",
                // "hoveredMaskBorder": "#ffff0000,1",
                // "paddingRight": "2",
                // "paddingBottom": "2",
                // "paddingTop": "2",
                // "figureBackground": "#ff008800",
                // "selectedForeground": "#ffffffff",
                // "foreground": "#ff000000",
                // "inactiveForeground": "#ff808080",
                // "textAlignment": "center",
                // "lineAlignment": "center",
                // "borderRight": "#ff808080,1",
                // "iconIndex": "0"
            },
            "rowBar": {
                "background": "#ffd3d3d3",
                "iconLocation": "left",
                "border": "#ffff0000,1",
                "iconAlignment": "center",
                "borderBottom": "#ff999999,1",
                "fontFamily": gridFont,
                "fontSize": normalFontSize,
                "iconPadding": "0",
                "selectedBackground": "#ff696969",
                "contentFit": "auto",
                "inactiveBackground": "#ffd3d3d3",
                "iconOffset": "0",
                "selectionDisplay": "mask",
                "hoveredMaskBackground": "#1f5292f7",
                "hoveredMaskBorder": "#335292f7,1",
                "paddingRight": "2",
                "paddingBottom": "2",
                "paddingTop": "2",
                "figureBackground": "#ff008800",
                "selectedForeground": "#ffffffff",
                "foreground": "#ff000000",
                "inactiveForeground": "#ff808080",
                "textAlignment": "center",
                "lineAlignment": "center",
                "borderRight": "#ff999999,1",
                "iconIndex": "0"
            }
        },
        "header": {
            "background": "#fffafafa",
            "iconLocation": "left",
            "iconAlignment": "center",
            "fontFamily": gridFont,
            "fontSize": normalFontSize,
            "selectedBackground": "#fffafafa",
            "contentFit": "auto",
            "inactiveBackground": "#ffd3d3d3",
            "iconOffset": "0",
            "selectionDisplay": "mask",
            "iconPadding": "0",
            "hoveredMaskBackground": "#22002b5b",
            "hoveredBackground": "#fffafafa",
            "paddingTop": "6",
            "fontBold": "true",
            "figureBackground": "#ff008800",
            "paddingBottom": "6",
            "hoveredMaskBorder": "#ffff0000,1",
            "paddingRight": "2",
            "selectedForeground": "#ff000000",
            "foreground": "#ff222530",
            "inactiveForeground": "#ff808080",
            "textAlignment": "center",
            "lineAlignment": "center",
            "border": "#ffffffff,0",
            "borderTop": "#ff000000, 0",
            "borderBottom": "#ffe1e1e1,1",
            "borderLeft": "#ffe1e1e1,0",
            "borderRight": "#ffe1e1e1,1",
            "iconIndex": "0",
            "group": {
                "background": "#fffafafa",
                "iconLocation": "left",
                "border": "#ffffffff,0",
                "iconAlignment": "center",
                "borderBottom": "#ffe1e1e1,1",
                "fontFamily": gridFont,
                "fontSize": normalFontSize,
                "borderTop": "#ffffffff,0",
                "selectedBackground": "linear,#ffcad0d7,#ffb7bec7,90",
                "contentFit": "auto",
                "inactiveBackground": "#ffd3d3d3",
                "iconOffset": "0",
                "selectionDisplay": "mask",
                "iconPadding": "0",
                "hoveredMaskBackground": "#22002b5b",
                "paddingTop": "6",
                "fontBold": "true",
                "figureBackground": "#ff008800",
                "paddingBottom": "6",
                "hoveredMaskBorder": "#ffff0000,1",
                "paddingRight": "2",
                "selectedForeground": "#ff000000",
                "foreground": "#ff222530",
                "inactiveForeground": "#ff808080",
                "textAlignment": "center",
                "lineAlignment": "center",
                "borderLeft": "#ffe1e1e1,0",
                "borderRight": "#ffe1e1e1,1",
                "iconIndex": "0",
            }
        },
        "footer": {
            "background": FOOTER_BACKGROUND_COLOR,
            "iconLocation": "left",
            "border": "#ffe1e1e1,0",
            "iconAlignment": "center",
            "borderBottom": "#ffe1e1e1,0",
            "fontFamily": gridFont,
            "fontBold": "true",
            "fontSize": normalFontSize,
            "borderTop": "#ffe1e1e1,1",
            "selectedBackground": "#ff696969",
            "contentFit": "auto",
            "inactiveBackground": "#ffd3d3d3",
            "iconOffset": "0",
            "selectionDisplay": "mask",
            "iconPadding": "0",
            "hoveredMaskBackground": "#1f5292f7",
            "hoveredMaskBorder": "#335292f7,1",
            "figureBackground": "#ff008800",
            "paddingBottom": "1",
            "paddingTop": "0",
            "paddingRight": "5",
            "paddingLeft": "5",
            "selectedForeground": "#ffffffff",
            "foreground": "#ff222530",
            "inactiveForeground": "#ff808080",
            "textAlignment": "near",
            "lineAlignment": "center",
            "borderLeft": "#ffe1e1e1,0",
            "borderRight": "#ffe1e1e1,1",
            "iconIndex": "0"
        },
        "rowGroup": {
            "header": {
                "background": "#ff464c58",
                "iconLocation": "left",
                "border": "#ffffffff,0",
                "iconAlignment": "center",
                "borderBottom": "#ff272d37,1",
                "borderTop": "#ffffffff,0",
                "selectedBackground": "#ff696969",
                "contentFit": "auto",
                "inactiveBackground": "#ffd3d3d3",
                "iconOffset": "0",
                "selectionDisplay": "mask",
                "iconPadding": "0",
                "hoveredMaskBackground": "#1f5292f7",
                "hoveredMaskBorder": "#335292f7,1",
                "figureBackground": "#ff008800",
                "paddingBottom": "2",
                "paddingTop": "2",
                "paddingRight": "2",
                "selectedForeground": "#ffffffff",
                "foreground": "#ffe2ecff",
                "inactiveForeground": "#ff808080",
                "textAlignment": "near",
                "lineAlignment": "center",
                "borderLeft": "#ff272d37,0",
                "borderRight": "#ff272d37,1",
                "iconIndex": "0"
            },
            "footer": {
                "background": "#fffffceb",
                "iconLocation": "left",
                "border": "#ffffffff,0",
                "iconAlignment": "center",
                "borderBottom": "#ffe6e6e6,1",
                "fontFamily": gridFont,
                "fontSize": normalFontSize,
                // "fontBold": true,
                "borderTop": "#ffe6e6e6,0",
                "selectedBackground": "#ff696969",
                "contentFit": "auto",
                "inactiveBackground": "#ffd3d3d3",
                "iconOffset": "0",
                "selectionDisplay": "mask",
                "iconPadding": "0",
                "hoveredMaskBackground": "#1f5292f7",
                "hoveredMaskBorder": "#335292f7,1",
                "figureBackground": "#ff008800",
                "paddingBottom": "2",
                "paddingTop": "2",
                "paddingRight": "4",
                "selectedForeground": "#ffffffff",
                "foreground": "#ff000000",
                "inactiveForeground": "#ff808080",
                "textAlignment": "far",
                "lineAlignment": "center",
                "borderLeft": "#ffe6e6e6,0",
                "borderRight": "#ffe6e6e6,1",
                "iconIndex": "0"
            },
            "head": {
                "background": "linear,#ffffffff,#ffe7e8eb,90",
                "iconLocation": "left",
                "border": "#ffffffff,0",
                "iconAlignment": "center",
                "borderBottom": "#ff767b87,1",
                "fontFamily": gridFont,
                "fontSize": normalFontSize,
                "borderTop": "#ffffffff,0",
                "selectedBackground": "#ff696969",
                "contentFit": "auto",
                "inactiveBackground": "#ffd3d3d3",
                "iconOffset": "0",
                "selectionDisplay": "mask",
                "iconPadding": "0",
                "hoveredMaskBackground": "#22002b5b",
                "hoveredMaskBorder": "#ffff0000,1",
                "figureBackground": "#ff008800",
                "paddingBottom": "2",
                "paddingTop": "2",
                "paddingRight": "2",
                "selectedForeground": "#ffffffff",
                "foreground": "#ff222530",
                "inactiveForeground": "#ff808080",
                "textAlignment": "center",
                "lineAlignment": "center",
                "borderLeft": "#ff979ca6,0",
                "borderRight": "#ff979ca6,1",
                "iconIndex": "0"
            },
            "foot": {
                "background": FOOTER_BACKGROUND_COLOR,
                "iconLocation": "left",
                "border": "#ffe1e1e1,0",
                "iconAlignment": "center",
                "borderBottom": "#ffe1e1e1,0",
                "fontFamily": gridFont,
                "fontBold": "true",
                "fontSize": normalFontSize,
                "borderTop": "#ffe1e1e1,1",
                "selectedBackground": "#ff696969",
                "contentFit": "auto",
                "inactiveBackground": "#ffd3d3d3",
                "iconOffset": "0",
                "selectionDisplay": "mask",
                "iconPadding": "0",
                "hoveredMaskBackground": "#1f5292f7",
                "hoveredMaskBorder": "#335292f7,1",
                "figureBackground": "#ff008800",
                "paddingBottom": "1",
                "paddingTop": "0",
                "paddingRight": "5",
                "paddingLeft": "5",
                "selectedForeground": "#ffffffff",
                "foreground": "#ff222530",
                "inactiveForeground": "#ff808080",
                "textAlignment": "near",
                "lineAlignment": "center",
                "borderLeft": "#ffe1e1e1,1",
                "borderRight": "#ffe1e1e1,1",
                "iconIndex": "0"
            },
            "headerBar": {
                "background": "#ff464c58",
                "iconLocation": "left",
                "border": "#ffffffff,0",
                "iconAlignment": "center",
                "borderBottom": "#ffffffff,0",
                "fontFamily": gridFont,
                "fontSize": normalFontSize,
                "borderTop": "#ffffffff,0",
                "selectedBackground": "#ff696969",
                "contentFit": "auto",
                "inactiveBackground": "#ffd3d3d3",
                "iconOffset": "0",
                "selectionDisplay": "mask",
                "iconPadding": "0",
                "hoveredMaskBackground": "#1f5292f7",
                "paddingTop": "2",
                "figureBackground": "#ffffffff",
                "paddingBottom": "2",
                "hoveredMaskBorder": "#335292f7,1",
                "paddingRight": "2",
                "selectedForeground": "#ffffffff",
                "foreground": "#ffe2ecff",
                "inactiveForeground": "#ff808080",
                "textAlignment": "near",
                "lineAlignment": "center",
                "borderLeft": "#ffffffff,0",
                "borderRight": "#ffffffff,0",
                "iconIndex": "0"
            },
            "footerBar": {
                "background": "#ffd8dee6",
                "iconLocation": "left",
                "border": "#ffffffff,0",
                "iconAlignment": "center",
                "borderBottom": "#ffffffff,0",
                "fontFamily": gridFont,
                "fontSize": normalFontSize,
                "borderTop": "#ff6e7b8c,1",
                "selectedBackground": "#ff696969",
                "contentFit": "auto",
                "inactiveBackground": "#ffd3d3d3",
                "iconOffset": "0",
                "selectionDisplay": "mask",
                "iconPadding": "0",
                "hoveredMaskBackground": "#1f5292f7",
                "hoveredMaskBorder": "#335292f7,1",
                "figureBackground": "#ff008800",
                "paddingBottom": "2",
                "paddingTop": "2",
                "paddingRight": "2",
                "selectedForeground": "#ffffffff",
                "foreground": "#ff000000",
                "inactiveForeground": "#ff808080",
                "textAlignment": "near",
                "lineAlignment": "center",
                "borderLeft": "#ff6e7b8c,0",
                "borderRight": "#ff6e7b8c,1",
                "iconIndex": "0"
            },
            "bar": {
                "background": "#ff464c58",
                "iconLocation": "left",
                "border": "#ffffffff,0",
                "iconAlignment": "center",
                "borderBottom": "#ff272d37,0",
                "fontFamily": gridFont,
                "fontSize": normalFontSize,
                "borderTop": "#ff464c58,0",
                "selectedBackground": "#ff696969",
                "contentFit": "auto",
                "inactiveBackground": "#ffd3d3d3",
                "iconOffset": "0",
                "selectionDisplay": "mask",
                "iconPadding": "0",
                "hoveredMaskBackground": "#1f5292f7",
                "paddingTop": "2",
                "figureBackground": "#ff008800",
                "paddingBottom": "2",
                "hoveredMaskBorder": "#335292f7,1",
                "paddingRight": "2",
                "selectedForeground": "#ffffffff",
                "foreground": "#ffe2ecff",
                "inactiveForeground": "#ff808080",
                "textAlignment": "near",
                "lineAlignment": "center",
                "borderLeft": "#ff272d37,0",
                "borderRight": "#ff272d37,1",
                "iconIndex": "0"
            },
            "panel": {
                "background": "#ff464c58",
                "iconLocation": "left",
                "border": "#ff393c42,1",
                "iconAlignment": "center",
                "borderBottom": "#ffffffff,0",
                "borderTop": "#ffffffff,0",
                "selectedBackground": "#ff696969",
                "contentFit": "auto",
                "inactiveBackground": "#ffd3d3d3",
                "iconOffset": "0",
                "selectionDisplay": "mask",
                "iconPadding": "0",
                "hoveredMaskBackground": "#ff393c42",
                "hoveredMaskBorder": "#335292f7,1",
                "figureBackground": "#ff008800",
                "paddingBottom": "1",
                "paddingTop": "1",
                "paddingRight": "1",
                "selectedForeground": "#ffffffff",
                "foreground": "#ffe2ecff",
                "inactiveForeground": "#ff808080",
                "line": "#ff464c58,1",
                "textAlignment": "center",
                "lineAlignment": "center",
                "borderLeft": "#ffffffff,0",
                "borderRight": "#ffffffff,0",
                "iconIndex": "0"
            }
        },
        "indicator": {
            "background": "#ffffffff",
            "paddingRight": "2",
            "iconLocation": "left",
            "border": "#ffe1e1e1,1",
            "selectedForeground": "#ffffffff",
            "iconAlignment": "center",
            "foreground": "#ff222530",
            "inactiveForeground": "#ff808080",
            "borderBottom": "#ffe1e1e1,1",
            "textAlignment": "center",
            "selectedBackground": "#ffbfc6ce",
            "lineAlignment": "center",
            "inactiveBackground": "#ffd3d3d3",
            "iconOffset": "0",
            "selectionDisplay": "mask",
            "hoveredMaskBorder": "#ff000000,1",
            "iconPadding": "0",
            "hoveredMaskBackground": "#ffd1d5db",
            "contentFit": "auto",
            "paddingTop": "2",
            "borderRight": "#ffe1e1e1,1",
            "figureBackground": "#ff2f363f",
            "paddingBottom": "2",
            "iconIndex": "0",
            "head": {
                "background": "#fffafafa",
                "iconLocation": "left",
                "border": "#ffff0000,1",
                "iconAlignment": "center",
                "borderBottom": "#ffe1e1e1,1",
                "fontFamily": gridFont,
                "fontSize": normalFontSize,
                "borderTop": "#ffffffff,0",
                "selectedBackground": "#ff696969",
                "contentFit": "auto",
                "inactiveBackground": "#ffd3d3d3",
                "iconOffset": "0",
                "selectionDisplay": "mask",
                "iconPadding": "0",
                "hoveredMaskBackground": "#1f5292f7",
                "hoveredMaskBorder": "#335292f7,1",
                "figureBackground": "#ff008800",
                "paddingBottom": "2",
                "paddingTop": "2",
                "paddingRight": "2",
                "selectedForeground": "#ffffffff",
                "foreground": "#ff000000",
                "inactiveForeground": "#ff808080",
                "textAlignment": "center",
                "lineAlignment": "center",
                "borderLeft": "#ffe1e1e1,0",
                "borderRight": "#ffe1e1e1,1",
                "iconIndex": "0"
            },
            "foot": {
                "background": FOOTER_BACKGROUND_COLOR,
                "iconLocation": "left",
                "border": "#ffe1e1e1,1",
                "iconAlignment": "center",
                "borderBottom": "#ffe1e1e1,0",
                "fontFamily": gridFont,
                "fontSize": normalFontSize,
                "borderTop": "#ffe1e1e1,1",
                "selectedBackground": "#ff696969",
                "contentFit": "auto",
                "inactiveBackground": "#ffd3d3d3",
                "iconOffset": "0",
                "selectionDisplay": "mask",
                "iconPadding": "0",
                "hoveredMaskBackground": "#1f5292f7",
                "hoveredMaskBorder": "#335292f7,1",
                "figureBackground": "#ff2f363f",
                "paddingBottom": "2",
                "paddingTop": "2",
                "paddingRight": "2",
                "selectedForeground": "#ffffffff",
                "foreground": "#ff000000",
                "inactiveForeground": "#ff808080",
                "textAlignment": "center",
                "lineAlignment": "center",
                "borderLeft": "#ffe1e1e1,0",
                "borderRight": "#ffe1e1e1,1",
                "iconIndex": "0"
            }
        },
        "checkBar": {
            "background": "#ffffffff",
            "iconLocation": "left",
            "border": "#ff464b58,1",
            "iconAlignment": "center",
            "borderBottom": "#ffe1e1e1,1",
            "fontFamily": gridFont,
            "fontSize": normalFontSize,
            "iconPadding": "0",
            "selectedBackground": "#ff696969",
            "contentFit": "auto",
            "inactiveBackground": "#ffd3d3d3",
            "iconOffset": "0",
            "selectionDisplay": "mask",
            "hoveredMaskBackground": "#1f5292f7",
            "hoveredMaskBorder": "#335292f7,1",
            "paddingRight": "2",
            "paddingBottom": "2",
            "paddingTop": "2",
            "figureBackground": "#ff4995ff",
            "selectedForeground": "#ffffffff",
            "foreground": "#ff555555",
            "inactiveForeground": "#ff808080",
            "textAlignment": "center",
            "lineAlignment": "center",
            "borderRight": "#ffe1e1e1,1",
            "iconIndex": "0",
            "head": {
                "background": "#fffafafa",
                "iconLocation": "left",
                "border": "#ffff0000,1",
                "iconAlignment": "center",
                "borderBottom": "#ffe1e1e1,1",
                "fontFamily": gridFont,
                "fontSize": normalFontSize,
                "borderTop": "#ffffffff,0",
                "selectedBackground": "#ff696969",
                "contentFit": "auto",
                "inactiveBackground": "#ffd3d3d3",
                "iconOffset": "0",
                "selectionDisplay": "mask",
                "iconPadding": "0",
                "hoveredMaskBackground": "#1f5292f7",
                "hoveredMaskBorder": "#335292f7,1",
                "figureBackground": "#ff008800",
                "paddingBottom": "2",
                "paddingTop": "2",
                "paddingRight": "2",
                "selectedForeground": "#ffffffff",
                "foreground": "#ff000000",
                "inactiveForeground": "#ff808080",
                "textAlignment": "center",
                "lineAlignment": "center",
                "borderLeft": "#ffe1e1e1,0",
                "borderRight": "#ffe1e1e1,1",
                "iconIndex": "0"
            },
            "foot": {
                "background": FOOTER_BACKGROUND_COLOR,
                "iconLocation": "left",
                "border": "#ffe1e1e1,1",
                "iconAlignment": "center",
                "borderBottom": "#ffe1e1e1,0",
                "fontFamily": gridFont,
                "fontSize": normalFontSize,
                "borderTop": "#ffe1e1e1,1",
                "selectedBackground": "#ff696969",
                "contentFit": "auto",
                "inactiveBackground": "#ffd3d3d3",
                "iconOffset": "0",
                "selectionDisplay": "mask",
                "iconPadding": "0",
                "hoveredMaskBackground": "#1f5292f7",
                "hoveredMaskBorder": "#335292f7,1",
                "figureBackground": "#ff008800",
                "paddingBottom": "2",
                "paddingTop": "2",
                "paddingRight": "2",
                "selectedForeground": "#ffffffff",
                "foreground": "#ff000000",
                "inactiveForeground": "#ff808080",
                "textAlignment": "center",
                "lineAlignment": "center",
                "borderLeft": "#ffe1e1e1,0",
                "borderRight": "#ffe1e1e1,1",
                "iconIndex": "0"
            }
        },
        "statusBar": {
            "background": "#fff9fef0",
            "iconLocation": "left",
            "border": "#ff464b58,1",
            "iconAlignment": "center",
            "borderBottom": "#ffe1e1e1,1",
            "fontFamily": gridFont,
            "fontSize": normalFontSize,
            "iconPadding": "0",
            "selectedBackground": "#ff696969",
            "contentFit": "auto",
            "inactiveBackground": "#ffd3d3d3",
            "iconOffset": "0",
            "selectionDisplay": "mask",
            "hoveredMaskBackground": "#1f5292f7",
            "hoveredMaskBorder": "#335292f7,1",
            "paddingRight": "2",
            "paddingBottom": "2",
            "paddingTop": "2",
            "figureBackground": "#ff008800",
            "selectedForeground": "#ffffffff",
            "foreground": "#ff000000",
            "inactiveForeground": "#ff808080",
            "textAlignment": "center",
            "lineAlignment": "center",
            "borderRight": "#ffe1e1e1,1",
            "iconIndex": "0",
            "head": {
                "background": "#fffafafa",
                "iconLocation": "left",
                "border": "#ffff0000,1",
                "iconAlignment": "center",
                "borderBottom": "#ffe1e1e1,1",
                "fontFamily": gridFont,
                "fontSize": normalFontSize,
                "borderTop": "#ffffffff,0",
                "selectedBackground": "#ff696969",
                "contentFit": "auto",
                "inactiveBackground": "#ffd3d3d3",
                "iconOffset": "0",
                "selectionDisplay": "mask",
                "iconPadding": "0",
                "hoveredMaskBackground": "#1f5292f7",
                "hoveredMaskBorder": "#335292f7,1",
                "figureBackground": "#ff008800",
                "paddingBottom": "2",
                "paddingTop": "2",
                "paddingRight": "2",
                "selectedForeground": "#ffffffff",
                "foreground": "#ff000000",
                "inactiveForeground": "#ff808080",
                "textAlignment": "center",
                "lineAlignment": "center",
                "borderLeft": "#ffe1e1e1,0",
                "borderRight": "#ffe1e1e1,1",
                "iconIndex": "0"
            },
            "foot": {
                "background": "#ffabccf8",
                "iconLocation": "left",
                "border": "#ff000000,1",
                "iconAlignment": "center",
                "borderBottom": "#ffe1e1e1,0",
                "fontFamily": gridFont,
                "fontSize": normalFontSize,
                "borderTop": "#ff96bbed,1",
                "selectedBackground": "#ff696969",
                "contentFit": "auto",
                "inactiveBackground": "#ffd3d3d3",
                "iconOffset": "0",
                "selectionDisplay": "mask",
                "iconPadding": "0",
                "hoveredMaskBackground": "#1f5292f7",
                "hoveredMaskBorder": "#335292f7,1",
                "figureBackground": "#ff008800",
                "paddingBottom": "2",
                "paddingTop": "2",
                "paddingRight": "2",
                "selectedForeground": "#ffffffff",
                "foreground": "#ff000000",
                "inactiveForeground": "#ff808080",
                "textAlignment": "center",
                "lineAlignment": "center",
                "borderLeft": "#ff96bbed,0",
                "borderRight": "#ff96bbed,1",
                "iconIndex": "0"
            }
        },
        "selection": {
            // "background": "#124995ff",
            "background": "#121C90FB", //
            "selectedBackground": "#ff696969",
            "inactiveBackground": "#ffd3d3d3",
            "border": "#00ffffff,0",
            "foreground": "#ff000000",
            "selectedForeground": "#ffffffff",
            "inactiveForeground": "#ff808080",
            "paddingLeft": 0,
            "paddingRight": 0,
            "paddingTop": 0,
            "paddingBottom": 0,
            "iconIndex": "0",
            "iconLocation": "left",
            "iconAlignment": "center",
            "iconOffset": 0,
            "iconPadding": 0,
            "contentFit": "auto",
            "selectionDisplay": "mask",
            "hoveredMaskBackground": "#1f5292f7",
            "hoveredMaskBorder": "#335292f7,1px",
            "figureBackground": "#ff008800",
            "figureInactiveBackground": "#ffd3d3d3"
        }
    }
};

export default GridStyle;