/**
 * Component FileView
 * Luna - Orbit 개발시 템플릿 으로 사용.
 * @version 0.1
 * @author 전주빈
 * @see common.js
 */
import * as React from 'react';
import { CompositeProps, Util, Events, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import { OBTButton, OBTScrollbar, OBTAlert, OBTDropDownList, OBTFloatingPanel, OBTTextField, OBTDialog2 } from '../index';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { OrbitInternalLangPack } from '../Common/Util';
import arrowUp from '../Images/icon-arrow-up.png';
import arrowDown from '../Images/icon-arrow-down.png';
import arrowUpHidden from '../Images/icon-arrow-up-hidden.png';
import arrowDownHidden from '../Images/icon-arrow-down-hidden.png';
import add from './img/btn-icon-add.png';
import delete1 from './img/btn-icon-delete.png';
import print from './img/icon_viewer_print_normal.png';
import printHover from './img/icon_viewer_print_hover.png';
// import horfit from './img/icon_viewer_hor_fit_normal.png';
// import fit from './img/icon_viewer_fit_normal.png';
// import originalFit from './img/icon_viewer_original_normal.png';
import imgHover from './img/icon_viewer_size_original_hover.png';
import imgNormal from './img/icon_viewer_size_original_normal.png';
import searchView from './img/icon_viewer_search.png';
import searchViewHover from './img/icon_viewer_search_hover.png';
import close from './img/icon_tabon_close_normal.png';
import imgIcon from './img/icon_board_img_small.png';
import pdfIcon from './img/icon_board_pdf_small.png';
import htmlIcon from './img/icon_board_html_small.png';

const styles = require('./OBTFileView.module.scss');

//pdf
const Document = React.lazy(() => import('./Document'));
const Page = React.lazy(() => import('./Page'));

/**
 * view type
 */
enum RendererType {
    'pdf' = 'pdf',
    'img' = 'img',
    'office' = 'office',
    'ect' = 'ect'
}

/**
 * 확장자 
 * 1. 랜더 타입
 * 2. 이미지 타입
 */
const extentions = {
    gif: { icon: imgIcon, rendererType: RendererType.img },
    png: { icon: imgIcon, rendererType: RendererType.img },
    jpg: { icon: imgIcon, rendererType: RendererType.img },
    jpeg: { icon: imgIcon, rendererType: RendererType.img },
    bmp: { icon: imgIcon, rendererType: RendererType.img },
    img: { icon: imgIcon, rendererType: RendererType.img },
    tif: { icon: imgIcon, rendererType: RendererType.img },
    pdf: { icon: pdfIcon, rendererType: RendererType.pdf },
    html: { icon: htmlIcon, rendererType: RendererType.office }
}

/**
 * Expand type
 */
enum ViewType {
    'full' = 'full',
    'width' = 'width',
    'origin' = 'origin'
}

/**
 * 파일 옵션
 * 1. 파일이름
 * 2. 파일확장자
 * 3. 파일콘텐트
 * (1. static(URL), 2. blob(파일) )
 */
interface IFile {
    name: string,
    extension: string,
    url?: string,
    blob?: Blob
}

/**
 * 기능 옵션
 * 1. 넓이기준, 높이기준 일지 선택
 * 2. 창 화면 뜨기
 */
interface Option {
    viewType?: ViewType,
    viewDialog?: boolean,
    viewDialogOption?: any,
    passwordPromptText?: string[], 
    onClick?: (e: Events.EventArgs) => void,
}


/**
 * PropType 정의
 * Events, CommonProps, CompositeProps 에 미리 지정된 Prop interface 를 충분히 활용한다.
 * extends 로 인터페이스 상속을 통해 사용된다.
 * 공용 Api 를 사용하려면 CommonProps.api 인터페이스를 확장한다.
 */
interface IOBTFileView extends CompositeProps.Default {
    file: IFile,
    option?: Option,
}

/**
 * @internal
 * State 정의
 */
interface State extends hasError {
    extentionInfo?: any,
    file?: IFile,
    contentSizeControl: any
    pdfPages?: any[],
    previewPages?: any[],
    viewType?: ViewType,
    numPages: number,
    pageNumber: number,
    zoom: number,
    url?: string,
    contentSize: string,
    searchText: string,
    oldSearchText: string,
    isSideBarCollapsed: boolean,
    imageExpendYn: boolean,
    searchOpen: boolean,
    officeSizeImage: number
}

/**
 * withApi() HOC 를 사용하면 Props 로 Api 를 사용할 수 있다.
 * api 가 Optional 로 선언되었기에 내부에서 ! 오퍼레이터를 사용해서 호출한다.
 * {@code this.props.api!.test();}
 */
export default class OBTFileView extends React.Component<IOBTFileView, State> {

    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        {
            name: 'file', type: {
                name: { type: CommonType.string },
                extension: { type: CommonType.string },
                url: { type: CommonType.string, optional: true },
                blob: { type: ["Blob"], optional: true }
            }, description: "*파일 옵션"
                + "\n1. name : 파일 이름"
                + "\n2. extension : 파일 확장자"
                + "\n3. 파일 콘텐츠(url | blob)"
        },
        {
            name: 'option', type: {
                viewType: {
                    type: toEnumType(ViewType), optional: true
                },
                viewDialog: { type: CommonType.boolean, optional: true },
                viewDialogOption: {
                    type: CommonType.any,
                    optional: true,
                },
                onClick: { type: CommonType.function, optional: true },
                passwordPromptText: { type: CommonType.any, optional: true, description: "비밀번호가 걸려있는 파일을 열때 사용자에게 물어볼 프롬프트창 문구를 지정합니다. [처음 물어볼 문구,사용자가 비밀번호를 틀리게 입력했을 경우 보여줄 문구]"}
            }, optional: true, description:
                "* viewType : full | width | origin"
                + "\n- full : 화면에 쪽 맞춤"
                + "\n- width : 화면에 너비 맞춤"
                + "\n- origin : 기본 화면 맞춤"
                + "\n* viewDialog : 다이얼로그를 띄울 지 여부를 정합니다."
                + "\n* viewDialogOption : 다이얼로그에 적용되는 추가 기능들 입니다."
                + "\n- title : 다이얼로그에 들어가는 타이틀 내용입니다."
                + "\n- subTitle : 다이얼로그에 들어가는 서브 타이틀 내용입니다."
                + "\n- width : 다이얼로그의 너비를 설정할 수 있습니다."
                + "\n- height : 다이얼로그의 높이를 설정할 수 있습니다."
                + "\n- type : 다이얼로그의 크기 타입을 정할 수 있습니다."
        }
    )

    ///////////////////////////////////////////////////////////////////////////// Initialize
    /**
     * Default Props 설정
     */
    public static defaultProps = {
        width: '100%',
        height: '100%'
    }
    /**  
     * State 정의
     */
    public state: State = {
        /**
         * 총 페이지
         */
        numPages: 0,
        /**
         * 보여질 페이지
         */
        pageNumber: 1,
        /**
         * 컨텐트 너비
         * 컨텐트 높이
         */
        contentSizeControl: {
            width: '',
            height: ''
        },
        /**
         * 페이지 꽉 채우기, 다시 돌아오기
         * 버튼하나로 2개 구분짓기 위해서 
         * true시 꽉채우기
         * false시 다시 원상복귀 
         */
        imageExpendYn: false,
        /**
         * zoom 
         * level
         * default 0
         * + 8, -8 
         */
        zoom: 0,
        /**
         * 1. 기본보기 
         * 2. 쪽맞춤
         * 3. 폭맞춤
         */
        viewType: (this.props.option || {}).viewType || ViewType.origin,
        /**
        * width, height
        * 누가 큰지 알기 ( 쪽, 폭맞춤 쓰기위해)
        */
        contentSize: '',
        /**
         * 현재 search Text
         */
        searchText: '',
        /**
         * 전 search Text
         */
        oldSearchText: '',
        /**
         * open 열리는 값
         */
        searchOpen: false,
        /**
         * pdf /사이드바 선택/
         */
        isSideBarCollapsed: false,
        /**
         * html 사이즈크기 state 이미지 적용
         */
        officeSizeImage: ((viewType) => {
            switch (viewType) {
                case ViewType.origin: return 1;
                case ViewType.full: return 2;
                default:
                    return 3;
            }
        })((this.props.option || {}).viewType || ViewType.origin)
    }

    public myRefs = {
        renderer: React.createRef<HTMLDivElement>(),
        previewScroll: React.createRef<OBTScrollbar>(),
        contentsScroll: React.createRef<OBTScrollbar>(),
        previewRoot: React.createRef<HTMLDivElement>(),
        contentsRoot: React.createRef<HTMLDivElement>()
    }

    // mark를 위한 index
    private searchIndex: number = 0;
    // contents original 크기
    private originalWidth: number = 0;
    // contents original 크기
    private originalHeight: number = 0;

    // private resizeSensor: ResizeSensor | null = null;
    // private resizeSensor1: ResizeSensor | null = null;

    /**
     * 초기값 세팅
     * @param nextProps 
     * numPages, pageNumber 초기화
     * url, extentionInfo, file 초기값 세팅
     * 1. blob파일형식 
     * 2. url형식
     * @param prevState 
     */
    static getDerivedStateFromProps(nextProps: IOBTFileView, prevState: State): any {
        try {
            if (nextProps.file !== prevState.file) {
                const nextState: State = {
                    ...prevState,
                    url: undefined,
                    extentionInfo: undefined,
                    file: nextProps.file,
                    numPages: 0,
                    pageNumber: 0
                }
                if (nextProps.file) {
                    if (nextProps.file.blob) {
                        nextState.url = URL.createObjectURL(nextProps.file.blob);
                    } else if (nextProps.file.url) {
                        nextState.url = nextProps.file.url;
                    }

                    if (nextProps.file.extension) {
                        if (extentions.hasOwnProperty(nextProps.file.extension.toLowerCase())) {
                            nextState.extentionInfo = extentions[nextProps.file.extension.toLowerCase()];
                            if (nextState.extentionInfo.rendererType === RendererType.pdf) {
                                import('react-pdf').then(pdf => { pdf.pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdf.pdfjs.version}/pdf.worker.js`; })
                            }
                        }
                    }
                }

                return nextState;
            }
            return null;
        } catch (e) {
            return Util.getErrorState(e);
        }
    }

    //dropDownList 세팅
    dropDownValue = [
        { value: '-7', labelText: '12.5%' },
        { value: '-6', labelText: '25%' },
        { value: '-5', labelText: '37.5%' },
        { value: '-4', labelText: '50%' },
        { value: '-3', labelText: '62.5%' },
        { value: '-2', labelText: '75%' },
        { value: '-1', labelText: '87.5%' },
        { value: '0', labelText: '100%' },
        { value: '1', labelText: '112.5%' },
        { value: '2', labelText: '125%' },
        { value: '3', labelText: '137.5%' },
        { value: '4', labelText: '150%' },
        { value: '5', labelText: '162.5%' },
        { value: '6', labelText: '175%' },
        { value: '7', labelText: '187.5%' },
        { value: '8', labelText: '200%' },
    ]

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        );
    }

    renderComponent = () => {
        //splitButton 세팅
        // const splitExpend = [
        //     {
        //         key: 0,
        //         labelText: <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        //             <img style={{ display: 'flex', marginLeft: '3px', marginRight: '6px', bottom: '0' }
        //             }
        //                 src={this.state.officeSizeImage === 1 ? originalFit : this.state.officeSizeImage === 2 ? fit : horfit} alt='' />
        //         </div >
        //     },
        //     {
        //         key: 1,
        //         labelText: <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        //             <img id={'fit'} style={{ display: 'flex', marginLeft: '3px', marginRight: '6px', bottom: '0' }} src={originalFit} alt='' />
        //             {OrbitInternalLangPack.getText('WE000028462', '기본 보기')}
        //         </div>
        //     },
        //     {
        //         key: 2,
        //         labelText: <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        //             <img style={{ display: 'flex', marginLeft: '3px', marginRight: '6px', bottom: '0' }} src={fit} alt='' />
        //             {OrbitInternalLangPack.getText('WE000028464', '쪽 맞춤')}
        //         </div>
        //     },
        //     {
        //         key: 3,
        //         labelText: <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        //             <img style={{ display: 'flex', marginLeft: '3px', marginRight: '6px', bottom: '0' }} src={horfit} alt='' />
        //             {OrbitInternalLangPack.getText('WE000028466', '폭 맞춤')}
        //         </div>
        //     }
        // ];

        //검색창 세팅
        const searchDialog = <OBTFloatingPanel className={styles.floatingPanel} value={this.state.searchOpen} position={OBTFloatingPanel.Position.bottom} align={OBTFloatingPanel.Align.far}>
            <div>
                <span>{OrbitInternalLangPack.getText('WE000000999', '찾기')}</span>
                <OBTButton className={styles.searchClose} onClick={this.handleSearchClose} imageUrl={{ normal: close }} />
            </div>
            <div style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
                <OBTTextField
                    className={styles.searchTextField}
                    value={this.state.searchText}
                    onChange={this.handleSearchPdf}
                    onMoveFocus={this.handleKeyDown} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', paddingLeft: '10px', paddingBottom: '10px' }}>
                <OBTButton className={styles.searchPreButton} onClick={this.handlePreButton} type={OBTButton.Type.small} labelText={OrbitInternalLangPack.getText('WE000002163', '이전')} />
                <OBTButton className={styles.searchNextButton} onClick={this.handleNextButton} type={OBTButton.Type.small} labelText={OrbitInternalLangPack.getText('WE000002162', '다음')} />
            </div>
        </OBTFloatingPanel>

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //시작
        const fileIcon = this.state.extentionInfo ? this.state.extentionInfo.icon : null;
        const rendererType = this.state.extentionInfo ? this.state.extentionInfo.rendererType : '';
        const name = this.props.file.name;
        const extension = this.props.file.extension;
        const renderer = this.getRenderer();
        const { viewDialog, viewDialogOption } = (this.props.option || {});

        //기본 뷰
        const defaultView =
            <div id={this.props.id} data-orbit-component={'OBTFileView'}
                className={Util.getClassNames(styles.default, this.props.className)}
                style={{
                    width: viewDialog === true ? 'calc(100% - 2px)' : this.props.width,
                    height: viewDialog === true ? '100%' : this.props.height
                }}
            >
                {/* 헤드 */}
                <div className={styles.head}>
                    <div className={styles.headLeft}>
                        <img style={{ paddingRight: '10px', height: '20px' }} src={fileIcon} alt={extension}></img>
                        <span>{name} {extension}</span>
                    </div>
                    <div className={styles.headRight}>
                        {rendererType === 'pdf' || rendererType === 'img' ? <OBTDropDownList width='75px' className={styles.dropDownPercent} displayType={OBTDropDownList.DisplayType.text} list={this.dropDownValue} value={`${this.state.zoom}`} onChange={this.handleDropDownList} /> : null}
                        {rendererType === 'pdf' || rendererType === 'img' ? <OBTButton className={styles.delete} onClick={this.handleReduce} imageUrl={{ normal: delete1 }} /> : null}
                        {rendererType === 'pdf' || rendererType === 'img' ? <OBTButton className={styles.add} onClick={this.handleExpend} imageUrl={{ normal: add }} /> : null}
                        {rendererType === 'pdf' ? <div className={styles.bar}></div> : null}
                        {rendererType === 'pdf' ? <div style={{ fontSize: '12px', alignItems: 'center', flex: '0 0 auto', paddingRight: '30px', whiteSpace: 'nowrap' }}>{this.state.pageNumber + 1} / {this.state.numPages}</div> : null}
                        {rendererType === 'img' ? <OBTButton className={styles.print} onClick={this.handleImageExpand} imageUrl={{ normal: imgNormal, over: imgHover }} /> : null}
                        {rendererType === 'pdf' ? <OBTButton className={styles.up} onClick={this.handleUpClick} imageUrl={{ normal: this.state.pageNumber === 0 ? arrowUpHidden : arrowUp }} disabled={this.state.pageNumber === 0} /> : null}
                        {rendererType === 'pdf' ? <OBTButton className={styles.down} onClick={this.handleDownClick} imageUrl={{ normal: this.state.pageNumber + 1 === this.state.numPages ? arrowDownHidden : arrowDown }} disabled={this.state.pageNumber + 1 === this.state.numPages} /> : null}
                        {rendererType === 'pdf' ? <div className={styles.bar}></div> : null}
                        <OBTButton className={styles.print} onClick={this.handlePrint} imageUrl={{ normal: print, over: printHover }} />
                        {rendererType === 'pdf' ? <OBTButton className={styles.pdfSearch} onClick={this.handlePdfSearch} imageUrl={{ normal: searchView, over: searchViewHover }} /> : null}
                        {/* {rendererType === 'office' ? <OBTSplitButton dropDownWidth={'138px'} className={styles.splitHtml} value={splitExpend} type={OBTSplitButton.Type.big} position={OBTSplitButton.Position.right} onClick={this.handleSplitClick} /> : null} */}
                        {rendererType === 'pdf' ? searchDialog : null}
                    </div>
                </div>
                {/* 미드 */}
                <div className={styles.renderer} ref={this.myRefs.renderer}>
                    {renderer}
                </div>
            </div>;

        // 다이아로그 뷰
        const dialogView = viewDialog === true ?
            <OBTDialog2
                title={'File View'}
                height={viewDialogOption && viewDialogOption.type ? undefined : '800px'}
                width={viewDialogOption && viewDialogOption.type ? undefined : '1300px'}
                open={true}
                {...viewDialogOption}
                buttons={[{ key: 'close', onClick: this.handleViewDiaLogClick, isClose: true, visible: false }]}
            >
                {defaultView}
            </OBTDialog2> : defaultView;

        return (
            dialogView
        )
    }

    //////////////////////////////////////////////////////////////////////////////////////함수
    private handleOnPassword = (tryPassword: (password: string) => void, hasFailed: string) => {
        const hasFailedCode = Number(hasFailed);
        switch (hasFailedCode) {
            case 1: {
                const promptText = this.props.option && this.props.option.passwordPromptText ?
                    this.props.option.passwordPromptText[0] : 'Enter the password to open this file.'
                const passwordPrompt = prompt(promptText);
                if (passwordPrompt === null) {
                    return;
                }
                else tryPassword(passwordPrompt);
                break;
            }
            case 2: {
                const promptText = this.props.option && this.props.option.passwordPromptText ?
                    this.props.option.passwordPromptText[1] : 'Invalid password. Please try again.'
                const passwordPrompt = prompt(promptText);
                if (passwordPrompt === null) {
                    return;
                }
                else tryPassword(passwordPrompt);
                break;
            }
            default:
        }
    }

    /**
     * @internal
     * pdf text찾는 함수
     * 
     */
    private getCustomTextRenderer(keyword) {
        return (textItem) => {
            if (keyword && keyword.length > 0) {
                return textItem.str
                    .split(keyword)
                    .reduce((strArray, currentValue, currentIndex) => (
                        currentIndex === 0
                            ? ([...strArray, currentValue])
                            : ([...strArray, (
                                // eslint-disable-next-line react/no-array-index-key
                                <mark key={currentIndex} className={styles.mark}>
                                    {keyword}
                                </mark>
                            ), currentValue])
                    ), [])
            } else {
                return textItem.str;
            }
        };
    }

    /**
     * @internal
     * 1. pdf
     * 로딩 속도 저하때문에
     * 한페이지씩 랜더링
     * Renderer함수
     * 2. img
     * 3. html
     */
    private getRenderer(): any {
        switch ((this.state.extentionInfo || {}).rendererType) {
            case RendererType.pdf:
                let page: any;
                const getPage = (options: any) => {
                    page = {
                        pdf: options.pdf,
                        key: options.index,
                        pageIndex: options.index,
                        onRenderSuccess: () => {
                            if (options.index + 1 < options.pdf.numPages) {
                                const pages = options.pageArrayGetter();
                                options.pageArraySetter(pages.concat([getPage({ ...options, index: options.index + 1 })]));
                            }
                        },
                        //text 찾기기능
                        customTextRenderer: this.getCustomTextRenderer(options),
                        width: options.width,
                        height: options.height,
                        renderAnnotationLayer: options.renderAnnotationLayer !== undefined ? options.renderAnnotationLayer : true,
                        renderTextLayer: options.renderTextLayer !== undefined ? options.renderTextLayer : true
                    }
                    return page;
                }
                return (
                    <React.Suspense fallback={<div>loading...</div>}>
                        <Document
                            className={styles.pdfDocument}
                            onPassword={this.handleOnPassword}
                            file={this.state.url}
                            onLoadSuccess={(pdf) => {
                                const nextState: State = {
                                    ...this.state,
                                    numPages: pdf.numPages,
                                    pdfPages: [],
                                    previewPages: [],
                                };
                                //초기 계산 사이즈 잡기위해서
                                const contentsRootSize = this.myRefs.contentsRoot.current ? this.myRefs.contentsRoot.current.getBoundingClientRect() : null;
                                const previewRootSize = this.myRefs.previewRoot.current ? this.myRefs.previewRoot.current.getBoundingClientRect() : null;

                                //넓이, 높이 알아오기 
                                if (pdf.numPages > 0) {
                                    nextState.pdfPages = [getPage({
                                        pdf: pdf,
                                        index: 0,
                                        pageArrayGetter: () => this.state.pdfPages,
                                        pageArraySetter: (pages) => this.setState({ pdfPages: pages }),
                                        width: this.state.viewType === ViewType.width ? contentsRootSize ? contentsRootSize.width : undefined : undefined,
                                        height: contentsRootSize ? contentsRootSize.height : undefined,
                                        keyword: this.state.searchText
                                    })];

                                    //side pdf는 넓이 고정
                                    nextState.previewPages = [getPage({
                                        pdf: pdf,
                                        index: 0,
                                        pageArrayGetter: () => this.state.previewPages,
                                        pageArraySetter: (pages) => this.setState({ previewPages: pages }),
                                        width: previewRootSize ? previewRootSize.width : undefined,
                                        keyword: this.state.searchText,
                                        renderAnnotationLayer: false,
                                        renderTextLayer: false
                                    })];
                                }
                                this.setState(nextState);
                            }}>
                            {/* 왼쪽 미리보기 view */}
                            <OBTScrollbar className={styles.previewScroll} ref={this.myRefs.previewScroll} height='100%' width={this.state.isSideBarCollapsed ? '0px' : '230px'}>
                                <div className={styles.preview} ref={this.myRefs.previewRoot} >
                                    {(this.state.previewPages || []).map((page, index) =>
                                        <div style={{ width: '230px', height: '100%' }} key={index} >
                                            <div id={`${index}`} key={index} tabIndex={-1} onClick={this.previewPageClick}>
                                                <Page {...page} />
                                            </div>
                                            <span style={{ fontSize: '12px', display: 'flex', justifyContent: 'center' }}>{index + 1}</span>
                                        </div >)}
                                </div>
                            </OBTScrollbar>
                            {/* 왼쪽 미리보기 버튼 */}
                            <div style={{ width: '20px', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', backgroundColor: '#999999' }}>
                                <button
                                    onClick={this.sideBarClick}
                                    style={{ cursor: 'pointer', border: '1px solid black', borderLeft: 'none', borderTopRightRadius: '5px', borderBottomRightRadius: '5px', backgroundColor: '#f7f7f7', height: '90px' }}>
                                    {OrbitInternalLangPack.getText('WE000002078', '미리보기')}
                                </button>
                            </div>
                            {/* pdf 메인 view */}
                            <OBTScrollbar className={styles.contentsScroll} ref={this.myRefs.contentsScroll} height='100%' width={this.state.isSideBarCollapsed ? 'calc(100% - 20px)' : 'calc(100% - 250px)'}>
                                <div className={styles.contents} ref={this.myRefs.contentsRoot}>
                                    {(this.state.pdfPages || []).map(page =>
                                        <Page {...page} />)
                                    }
                                </div>
                            </OBTScrollbar>
                        </Document>
                    </React.Suspense>);
            case RendererType.img:
                //중앙정렬
                const imgProp: any = {
                    justifyContent: 'center'
                }
                const imgProps: any = this.state.contentSizeControl;
                return (
                    <OBTScrollbar className={styles.contentsScroll} ref={this.myRefs.contentsScroll} height='100%'>
                        <div className={styles.contents} style={imgProp} ref={this.myRefs.contentsRoot} >
                            <img src={this.state.url} style={{ objectFit: 'cover', ...imgProps }} alt={''}></img>
                        </div>
                    </OBTScrollbar>);
            case RendererType.office:
                //중앙정렬
                const ofiiceContent: any = {
                    justifyContent: 'center'
                }
                // const html: any = undefined;//require(this.state.url!);
                // const htmlProps: any = this.state.contentSizeControl;
                return (
                    <OBTScrollbar ref={this.myRefs.contentsScroll} className={styles.contentsScroll} height='100%'>
                        <div className={styles.contents} style={ofiiceContent} ref={this.myRefs.contentsRoot} >
                            <iframe
                                style={{
                                    border: 'none',
                                    outline: 'none',
                                    padding: '0px',
                                    margin: '0px',
                                    background: '#ffffff',
                                    width: '100%',
                                    height: '100%'
                                }} src={this.state.url}
                                title={this.state.url} />
                            {/* <span style={htmlProps} dangerouslySetInnerHTML={this.createMarkup(html)} /> */}
                        </div>
                    </OBTScrollbar>);
            default:
                return (<OBTAlert
                    labelText={
                        <>
                            <div>{OrbitInternalLangPack.getText('WE000028456', '지원되지 않는 확장자입니다.')}</div>
                            <div>{OrbitInternalLangPack.getText('WE000028457', '[제공 : 이미지, pdf, hwp, gul, word, doc, docx, ppt, pptx, xis, xlsx]')}</div>
                        </>
                    }
                    type={OBTAlert.Type.warning}
                />);
        }
    }

    /**
     * @internal
     * static 
     * 사이즈 조절 함수 
     */
    private getCalcSizeWidthHeight(): any {
        let backgroundHeight: number = 0;
        let backgroundWidth: number = 0;
        let contentHeight: number = 0;
        let contentWidth: number = 0;

        // 누가 더 큰지 알려주는 사이즈 검사
        let contentBigSize: string = '';
        let ratioWidth: number = 0;
        let ratioHeight: number = 0;

        //expansion option에 때라
        //default 는 원래 pdf크기에서 큰 걸로 선택
        //비율 계산
        if (this.myRefs.renderer.current !== null) {
            backgroundHeight = this.myRefs.renderer.current.clientHeight;
            backgroundWidth = this.myRefs.renderer.current.clientWidth;
            if (this.myRefs.contentsRoot.current !== null) {
                if (this.myRefs.contentsRoot.current.firstElementChild !== null) {
                    contentHeight = this.myRefs.contentsRoot.current.firstElementChild.clientHeight;
                    contentWidth = this.myRefs.contentsRoot.current.firstElementChild.clientWidth;
                    /**
                     * 1. 원본 백그라운가 content보다 클때
                     * 2. 원본 백그라운드가 height만 클 때
                     * 3. 원본 백그라운드가 width만 클 때
                     * 4. 원본 백그라운드가 content보다 작을 때
                      */
                    if (backgroundHeight > this.myRefs.contentsRoot.current.firstElementChild.clientHeight && backgroundWidth > this.myRefs.contentsRoot.current.firstElementChild.clientWidth) {
                        if (ratioWidth < backgroundWidth && ratioHeight < backgroundHeight) {
                            if (backgroundWidth / this.myRefs.contentsRoot.current.firstElementChild.clientWidth * this.myRefs.contentsRoot.current.firstElementChild.clientHeight < backgroundHeight)
                                contentBigSize = 'width';
                            else {
                                contentBigSize = 'height';
                            }
                        }
                    } else if (backgroundHeight > this.myRefs.contentsRoot.current.firstElementChild.clientHeight && backgroundWidth < this.myRefs.contentsRoot.current.firstElementChild.clientWidth) {
                        contentBigSize = 'width';
                    } else if (backgroundHeight < this.myRefs.contentsRoot.current.firstElementChild.clientHeight && backgroundWidth > this.myRefs.contentsRoot.current.firstElementChild.clientWidth) {
                        contentBigSize = 'height';
                    } else {
                        //비율 맞춤 계산
                        ratioWidth = (backgroundWidth * this.myRefs.contentsRoot.current.firstElementChild.clientHeight) / backgroundHeight;
                        ratioHeight = (backgroundHeight * this.myRefs.contentsRoot.current.firstElementChild.clientWidth) / backgroundWidth;
                        if (ratioWidth < backgroundWidth) {
                            contentBigSize = 'height';
                        } else {
                            contentBigSize = 'width';
                        }
                    }
                }
            }
        }
        return { contentBigSize, contentHeight, contentWidth, backgroundHeight, backgroundWidth }
    }

    /**
     * @internal
     * HTML 변환
     */
    private createMarkup(fileURL: any) {
        return { __html: fileURL }
    };

    /**
     * @internal
     * zoom 화면 뿌리기
     * 1. pdf: 퍼센트 곱해서 나온 px
     * 2. html: scale 계산
     */
    private getZoomSize(zoom: number, size?: number): any {
        if (this.state.extentionInfo.rendererType === 'pdf') {
            let sizeDelta: number = size === undefined ? 0 : size;
            if (zoom < 0) {
                sizeDelta = sizeDelta * (1 - (-zoom * 0.125));
            } else if (zoom > 0) {
                sizeDelta = sizeDelta * (1 + (zoom * 0.5));
            } else {
                sizeDelta = this.state.viewType === ViewType.width ? this.myRefs.contentsRoot.current!.getBoundingClientRect().width : this.myRefs.contentsRoot.current!.getBoundingClientRect().height;
            }
            return sizeDelta;
        } else if (this.state.extentionInfo.rendererType === 'img') {
            let sizeNumber: number = size === undefined ? 0 : size;
            // this.dropDownValue.find(function (e) {
            //     if (e.value === `${zoom}`) {
            //         sizePercent = e.labelText
            //     }
            // })
            // return sizePercent;
            if (zoom < 0) {
                sizeNumber = sizeNumber * (1 - (-zoom * 0.125));
            } else if (zoom > 0) {
                sizeNumber = sizeNumber * (1 + (zoom * 0.5));
            } else {
                sizeNumber = 0
            }
            return sizeNumber;
        } else {
            //default
            let scale: number = 1;
            // zoom ( -7 ~ -1 ), ( 1 ~ 8 ), 0
            // 비율계산
            if (zoom < 0) {
                scale = 1 - (-zoom * 0.125);
            } else if (zoom > 0) {
                scale = 1 + (zoom * 0.5);
            }
            return scale;
        }
    }

    /**
     * @internal
     * 스크롤이동 함수
     */
    private moveScroll(mark: any, searchIndex: number, myRefsContent: any, myRefsScroll: any): void {
        const markTop = (mark[searchIndex].getBoundingClientRect() || {}).top || 0;
        const scrollElement = myRefsContent ? myRefsContent.parentElement : null;
        const scrollContainerTop = ((): any => (scrollElement ? scrollElement.getBoundingClientRect() : {}))().top || 0;
        const element: any = myRefsScroll!.element;
        element.scrollTop(markTop + (scrollElement ? scrollElement.scrollTop : 0) - scrollContainerTop);
    }

    //////////////////////////////////////////////////////////////////////////////////////이벤트 헨들러///////////////////////////////////////////////////////////
    //////////////////////////////img//////////////////////////////
    /**
     * @internal
     * img
     * 확장 기능
     */
    private handleImageExpand = e => {
        const imageExpendYn = !this.state.imageExpendYn;
        let contentSizeControl = this.state.contentSizeControl;

        const { contentBigSize, backgroundHeight, backgroundWidth } = this.getCalcSizeWidthHeight();

        //꽉채우기
        if (imageExpendYn) {
            if (contentBigSize === 'width') {
                contentSizeControl = {
                    width: backgroundWidth,
                    height: 'auto'
                }
            } else if (contentBigSize === 'height') {
                contentSizeControl = {
                    width: 'auto',
                    height: backgroundHeight
                }
            }
        } else {
            contentSizeControl = {
                width: 'auto',
                height: 'auto'
            }
        }
        this.setState({
            contentSizeControl: contentSizeControl,
            imageExpendYn: imageExpendYn
        })
    }

    //////////////////////////////////////////////////////////html ///////////////////////////////////////////////////
    /**
     * @internal
     * html  
     * 스플릿버튼 클릭시 이벤트
     */
    private handleSplitClick = e => {
        let contentSizeControl = this.state.contentSizeControl;
        let officeSize = this.state.officeSizeImage;
        const { contentBigSize } = this.getCalcSizeWidthHeight();

        if (e.key === 0) return;
        if (e.key === 1) {
            contentSizeControl = {
                width: 'auto',
                height: 'auto',
                transform: `scale(${this.getZoomSize(this.state.zoom)})`
            }
            officeSize = 1;
        } else if (e.key === 2) {
            if (contentBigSize === 'width') {
                contentSizeControl = {
                    width: '100%',
                    transform: `scale(${this.getZoomSize(this.state.zoom)})`
                }
            } else if (contentBigSize === 'height') {
                contentSizeControl = {
                    height: '100%',
                    transform: `scale(${this.getZoomSize(this.state.zoom)})`
                }
            }
            officeSize = 2;
        } else if (e.key === 3) {
            if (contentBigSize === 'width') {
                contentSizeControl = {
                    height: '100%',
                    transform: `scale(${this.getZoomSize(this.state.zoom)})`
                }
            } else if (contentBigSize === 'height') {
                contentSizeControl = {
                    width: '100%',
                    transform: `scale(${this.getZoomSize(this.state.zoom)})`
                }
            }
            officeSize = 3;
        }
        this.setState({
            zoom: 0,
            contentSizeControl: contentSizeControl,
            officeSizeImage: officeSize
        })
    }

    /////////////////////////////////////////////////////////////////////////////////pdf //////////////////////////////////////////////////////
    /**
     * @internal
     * pdf
     * Zoom + 
     */
    private handleExpend = e => {
        let zoom = this.state.zoom;
        if (this.state.extentionInfo.rendererType === 'pdf') {
            const contentsRootSize = this.myRefs.contentsRoot.current ? this.myRefs.contentsRoot.current.getBoundingClientRect() : null;
            if (contentsRootSize) {
                const widthHeight = this.state.viewType === ViewType.width ? contentsRootSize.width : contentsRootSize.height;
                const sizeDelta = this.getZoomSize(zoom + 1, widthHeight);
                //방호
                if (zoom === 8) return;
                const pdfPages = this.state.pdfPages;
                if (pdfPages && pdfPages.length > 0) {
                    const reRender = (index, count) => {
                        if (index < count) {
                            this.state.pdfPages![index]!.onRenderSuccess = () => {
                                reRender(index + 1, count);
                            };
                            this.state.pdfPages![index]!.width = this.state.viewType === ViewType.width ? sizeDelta : undefined;
                            this.state.pdfPages![index]!.height = sizeDelta;
                            this.setState({
                                pdfPages: this.state.pdfPages
                            });
                        }
                    }
                    pdfPages[0].onRenderSuccess = () => {
                        reRender(1, this.state.pdfPages!.length);
                    };
                    pdfPages[0].width = this.state.viewType === ViewType.width ? sizeDelta : undefined;
                    pdfPages[0].height = sizeDelta;
                    this.setState({
                        zoom: zoom + 1
                    });
                }
            }
        } else if (this.state.extentionInfo.rendererType === 'img') {
            const contentsRootSize = this.myRefs.contentsRoot.current ? this.myRefs.contentsRoot.current.getBoundingClientRect() : null;
            if (this.state.zoom === 0) {
                this.originalHeight = this.myRefs.contentsRoot.current!.firstElementChild!.clientHeight;
                this.originalWidth = this.myRefs.contentsRoot.current!.firstElementChild!.clientWidth;
            }
            if (contentsRootSize) {
                //방호
                if (zoom === 8) return;
                let contentSizeControl = this.state.contentSizeControl;
                contentSizeControl = {
                    width: this.getZoomSize(zoom + 1, this.originalWidth) === 0 ? 'auto' : this.getZoomSize(zoom + 1, this.originalWidth),
                    height: this.getZoomSize(zoom + 1, this.originalHeight) === 0 ? 'auto' : this.getZoomSize(zoom + 1, this.originalHeight)
                }
                this.setState({
                    contentSizeControl: contentSizeControl,
                    zoom: zoom + 1
                })
            }
        }
    }

    /**
     * @internal
     * pdf
     * Zoom - 
     */
    private handleReduce = e => {
        let zoom = this.state.zoom;
        if (this.state.extentionInfo.rendererType === 'pdf') {
            const contentsRootSize = this.myRefs.contentsRoot.current ? this.myRefs.contentsRoot.current.getBoundingClientRect() : null;
            if (contentsRootSize) {
                const widthHeight = this.state.viewType === ViewType.width ? contentsRootSize.width : contentsRootSize.height;
                const sizeDelta = this.getZoomSize(zoom - 1, widthHeight);
                //방호
                if (zoom === -7) return;
                const pdfPages = this.state.pdfPages;
                if (pdfPages && pdfPages.length > 0) {
                    const reRender = (index, count) => {
                        if (index < count) {
                            this.state.pdfPages![index]!.onRenderSuccess = () => {
                                reRender(index + 1, count);
                            };
                            this.state.pdfPages![index]!.width = this.state.viewType === ViewType.width ? sizeDelta : undefined;
                            this.state.pdfPages![index]!.height = sizeDelta;
                            this.setState({
                                pdfPages: this.state.pdfPages
                            });
                        }
                    }
                    pdfPages[0].onRenderSuccess = () => {
                        reRender(1, this.state.pdfPages!.length);
                    };
                    pdfPages[0].width = this.state.viewType === ViewType.width ? sizeDelta : undefined;
                    pdfPages[0].height = sizeDelta;
                    this.setState({
                        zoom: zoom - 1
                    });
                }
            }
        } else if (this.state.extentionInfo.rendererType === 'img') {
            const contentsRootSize = this.myRefs.contentsRoot.current ? this.myRefs.contentsRoot.current.getBoundingClientRect() : null;
            if (this.state.zoom === 0) {
                this.originalHeight = this.myRefs.contentsRoot.current!.firstElementChild!.clientHeight;
                this.originalWidth = this.myRefs.contentsRoot.current!.firstElementChild!.clientWidth;
            }
            if (contentsRootSize) {
                //방호
                if (zoom === -7) return;
                let contentSizeControl = this.state.contentSizeControl;
                contentSizeControl = {
                    width: this.getZoomSize(zoom - 1, this.originalWidth) === 0 ? 'auto' : this.getZoomSize(zoom - 1, this.originalWidth),
                    height: this.getZoomSize(zoom - 1, this.originalHeight) === 0 ? 'auto' : this.getZoomSize(zoom - 1, this.originalHeight)
                }
                this.setState({
                    contentSizeControl: contentSizeControl,
                    zoom: zoom - 1
                })
            }
        }
    }

    /**
     * @internal
     * pdf 
     * 미리보기에서 클릭 이벤트 
     */
    private previewPageClick = e => {
        const index: number = parseInt(e.currentTarget.id);
        const contentRoot = this.myRefs.contentsRoot.current!.children;

        if (this.myRefs.contentsRoot.current !== null) {
            this.moveScroll(contentRoot, index, this.myRefs.contentsRoot.current, this.myRefs.contentsScroll.current);
        }
        this.setState({
            pageNumber: index
        })
    }

    /**
     * @internal
     * pdf
     * 위로 올라가기 버튼
     */
    private handleUpClick = e => {
        let pageNumber = this.state.pageNumber;
        let previewTop: number = 0;
        const index: number = pageNumber - 1;
        const contentRoot = this.myRefs.contentsRoot.current!.children;

        if (this.myRefs.contentsRoot.current !== null) {
            this.moveScroll(contentRoot, pageNumber - 1, this.myRefs.contentsRoot.current, this.myRefs.contentsScroll.current);
        }

        if (this.myRefs.previewRoot.current !== null) {
            previewTop = (index) * this.myRefs.previewRoot.current.children[this.state.pageNumber - 1].getBoundingClientRect().height;
            if (this.myRefs.previewScroll.current !== null) {
                if (this.myRefs.previewScroll.current.element !== null) {
                    const element: any = this.myRefs.previewScroll.current.element;
                    element.scrollTop(previewTop);
                }
            }
        }
        this.setState({
            pageNumber: pageNumber - 1
        })
    }

    /**
     * @internal
     * pdf
     * 아래로 내려가기 버튼
     */
    private handleDownClick = e => {
        let pageNumber = this.state.pageNumber;
        let previewTop: number = 0;
        const index: number = pageNumber + 1;
        const contentRoot = this.myRefs.contentsRoot.current!.children;

        if (this.myRefs.contentsRoot.current !== null) {
            this.moveScroll(contentRoot, pageNumber + 1, this.myRefs.contentsRoot.current, this.myRefs.contentsScroll.current);
        }
        if (this.myRefs.previewRoot.current !== null) {
            previewTop = (index) * this.myRefs.previewRoot.current.children[this.state.pageNumber + 1].getBoundingClientRect().height + 17;
            if (this.myRefs.previewScroll.current !== null) {
                if (this.myRefs.previewScroll.current.element !== null) {
                    const element: any = this.myRefs.previewScroll.current.element;
                    element.scrollTop(previewTop);
                }
            }
        }
        this.setState({
            pageNumber: pageNumber + 1
        })
    }

    /**
     * @internal
     * pdf 
     * 찾기 기능
     */
    private handlePdfSearch = e => {
        const searchOpen: boolean = this.state.searchOpen;
        this.setState({
            searchOpen: !searchOpen
        })
    }

    /**
     *  @internal
     * pdf
     * 찾기 기능 (이전)
     */
    private handlePreButton = ({ target, event }) => {
        if (!this.myRefs.contentsRoot.current) return;
        const searchText = this.state.searchText;
        const oldSearchText = this.state.oldSearchText;
        let searchIndex = this.searchIndex;
        const mark = this.myRefs.contentsRoot.current.querySelectorAll('mark');
        const markLength = this.myRefs.contentsRoot.current.querySelectorAll('mark').length;
        let pageNumber = this.state.pageNumber;

        //다를 때 랜더
        if (searchText !== oldSearchText) {
            this.searchIndex = 0;
            this.setState({
                pdfPages: this.state.pdfPages ? this.state.pdfPages.map(page => {
                    return {
                        ...page,
                        customTextRenderer: this.getCustomTextRenderer(this.state.searchText)
                    };
                }) : this.state.pdfPages,
                oldSearchText: searchText
            })
            if (this.myRefs.contentsScroll.current !== null) {
                const element: any = this.myRefs.contentsScroll.current.element;
                element.scrollTop(0);
                pageNumber = 0;
            }
        } else {
            if (searchIndex === 0) {
                this.searchIndex = markLength - 1;
                searchIndex = markLength - 1;
            }
            if (this.myRefs.contentsScroll.current !== null) {
                this.moveScroll(mark, searchIndex, this.myRefs.contentsScroll.current, this.myRefs.contentsScroll.current);
                pageNumber = parseInt(mark[searchIndex].parentElement!.parentElement!.parentElement!.dataset.pageNumber!) - 1;
            }
            if (markLength > 0) {
                //맨처음일때
                if (searchIndex === 0) {
                    if (this.myRefs.contentsScroll.current !== null) {
                        this.moveScroll(mark, searchIndex, this.myRefs.contentsScroll.current, this.myRefs.contentsScroll.current);
                    }
                    this.searchIndex = markLength - 1;
                    pageNumber = parseInt(mark[searchIndex].parentElement!.parentElement!.parentElement!.dataset.pageNumber!) - 1;
                }
                else this.searchIndex = searchIndex - 1;
            }
        }
        this.setState({
            pageNumber: pageNumber
        })
    }

    /**
     * @internal
     * pdf 
     * 다음 버튼
     */
    private handleNextButton = ({ target, event }) => {
        if (!this.myRefs.contentsRoot.current) return;
        const searchText = this.state.searchText;
        const oldSearchText = this.state.oldSearchText;
        const searchIndex = this.searchIndex;
        const mark = this.myRefs.contentsRoot.current.querySelectorAll('mark');
        const markLength = this.myRefs.contentsRoot.current.querySelectorAll('mark').length;
        let pageNumber = this.state.pageNumber;

        //다를 때 랜더
        if (searchText !== oldSearchText) {
            this.searchIndex = 0;
            this.setState({
                pdfPages: this.state.pdfPages ? this.state.pdfPages.map(page => {
                    return {
                        ...page,
                        customTextRenderer: this.getCustomTextRenderer(this.state.searchText)
                    };
                }) : this.state.pdfPages,
                oldSearchText: searchText
            })
            if (this.myRefs.contentsScroll.current !== null) {
                const element: any = this.myRefs.contentsScroll.current.element;
                element.scrollTop(0);
                pageNumber = 0;
            }
        } else {
            if (this.myRefs.contentsScroll.current !== null) {
                this.moveScroll(mark, searchIndex, this.myRefs.contentsScroll.current, this.myRefs.contentsScroll.current);
                pageNumber = parseInt(mark[searchIndex].parentElement!.parentElement!.parentElement!.dataset.pageNumber!) - 1;
            }
            //마지막 갔을 때
            if (markLength - 1 === searchIndex) {
                if (this.myRefs.contentsScroll.current !== null) {
                    const element: any = this.myRefs.contentsScroll.current.element;
                    element.scrollTop(0);
                }
                this.searchIndex = 0;
                pageNumber = 0;
            }
            else this.searchIndex = searchIndex + 1;
        }
        this.setState({
            pageNumber: pageNumber
        })
    }

    /**
     * @internal
     * pdf 
     * 찾기 close 
     */
    private handleSearchClose = e => {
        this.setState({
            searchOpen: false
        })
    }

    /**
    * @internal
    * pdf 
    * 찾기 text
    */
    private handleSearchPdf = e => {
        const oldSearchText = this.state.searchText;
        this.setState({
            searchText: e.value,
            oldSearchText: oldSearchText,
        })
    }

    /**
    * @internal
    * pdf 
    * 찾기 버튼
    */
    private handleKeyDown = e => {
        switch (e.direction) {
            case 'enter':
                this.setState({
                    pdfPages: this.state.pdfPages ? this.state.pdfPages.map(page => {
                        return {
                            ...page,
                            customTextRenderer: this.getCustomTextRenderer(this.state.searchText)
                        };
                    }) : this.state.pdfPages
                })
                break;
            default:
                break;
        }
    }

    /**
     * @internal
     * pdf
     * dropDownList 퍼센트
     * 크기 
     */
    private handleDropDownList = e => {
        let zoom = parseInt(e.value);
        if (this.state.extentionInfo.rendererType === 'pdf') {
            const contentsRootSize = this.myRefs.contentsRoot.current ? this.myRefs.contentsRoot.current.getBoundingClientRect() : null;
            if (contentsRootSize) {
                const widthHeight = this.state.viewType === ViewType.width ? contentsRootSize.width : contentsRootSize.height;
                const sizeDelta = this.getZoomSize(zoom, widthHeight);
                const pdfPages = this.state.pdfPages;
                if (pdfPages && pdfPages.length > 0) {
                    const reRender = (index, count) => {
                        if (index < count) {
                            this.state.pdfPages![index]!.onRenderSuccess = () => {
                                reRender(index + 1, count);
                            };
                            this.state.pdfPages![index]!.width = this.state.viewType === ViewType.width ? sizeDelta : undefined;
                            this.state.pdfPages![index]!.height = sizeDelta;
                            this.setState({
                                pdfPages: this.state.pdfPages
                            });
                        }
                    }
                    pdfPages[0].onRenderSuccess = () => {
                        reRender(1, this.state.pdfPages!.length);
                    };
                    pdfPages[0].width = this.state.viewType === ViewType.width ? sizeDelta : undefined;
                    pdfPages[0].height = sizeDelta;
                    this.setState({
                        zoom: zoom
                    });
                }
            }
        }
        else if (this.state.extentionInfo.rendererType === 'img') {
            const contentsRootSize = this.myRefs.contentsRoot.current ? this.myRefs.contentsRoot.current.getBoundingClientRect() : null;
            if (this.state.zoom === 0) {
                this.originalHeight = this.myRefs.contentsRoot.current!.firstElementChild!.clientHeight;
                this.originalWidth = this.myRefs.contentsRoot.current!.firstElementChild!.clientWidth;
            }
            if (contentsRootSize) {
                let contentSizeControl = this.state.contentSizeControl;
                contentSizeControl = {
                    width: this.getZoomSize(zoom, this.originalWidth) === 0 ? 'auto' : this.getZoomSize(zoom, this.originalWidth),
                    height: this.getZoomSize(zoom, this.originalHeight) === 0 ? 'auto' : this.getZoomSize(zoom, this.originalHeight)
                }
                this.setState({
                    contentSizeControl: contentSizeControl,
                    zoom: zoom
                })
            }
        }
    }

    ///////////////////////////////////////////////////////////////////////////////기타 기능///////////////////////////////////////////////////
    /**
     * @internal
     *  다이아로그창 이벤트
     */
    private handleViewDiaLogClick = e => {
        if (this.props.option && this.props.option.onClick) {
            Util.invokeEvent<Events.EventArgs>(this.props.option.onClick, new Events.EventArgs(this));
        }
    }

    /**
     * @internal
    * 사이드바 클릭이벤트
    */
    private sideBarClick = e => {
        let isSideBarCollapsed = this.state.isSideBarCollapsed;
        this.setState({
            isSideBarCollapsed: !isSideBarCollapsed
        })
    }

    /**
     * @internal
     * 프린터 
     */
    private handlePrint = e => {
        if (this.myRefs.renderer.current) {
            const printBase = document.createElement('div');
            printBase.style.position = 'relative';
            let elements: any;
            //이미지 오리지날 
            let originalElements = this.myRefs.contentsRoot.current;
            if (this.state.extentionInfo.rendererType === 'pdf') {
                elements = this.myRefs.renderer.current.querySelectorAll('div.react-pdf__Page > canvas');
                for (let i = 0; i < elements.length; i++) {
                    const page: any = elements[i];
                    const div = document.createElement('div');
                    div.style.boxSizing = 'border-box';
                    div.style.display = 'inline-block';
                    div.style.width = '95%';
                    div.style.clear = 'both';
                    div.style.pageBreakBefore = 'always';
                    div.style.pageBreakInside = 'avoid';
                    div.style.pageBreakAfter = 'always';
                    const img = document.createElement('img');
                    img.src = page.toDataURL("image/png");
                    img.style.width = '100%';
                    div.appendChild(img);
                    printBase.appendChild(div);
                }
            } else if (this.state.extentionInfo.rendererType === 'img') {
                elements = this.myRefs.renderer.current.querySelector('img');
                printBase.appendChild(elements);
            } else if (this.state.extentionInfo.rendererType === 'office') {
                if (this.myRefs.contentsRoot.current) {
                    let iframeContents = this.myRefs.contentsRoot.current.querySelector('iframe');
                    if (iframeContents) {
                        let contents = iframeContents.contentWindow!.document.querySelector('html > body > center');
                        if (contents) {                     
                            let childrens = contents!.children;
                            for (let i = 0; i < childrens.length; i++) {
                                const page: any = childrens[i].cloneNode(true);
                                printBase.appendChild(page);
                            }
                        }
                    }
                }
            }

            const html = document.querySelector('html');
            if (html) {
                html.appendChild(printBase);
                const display = document.body.style.display;
                document.body.style.display = 'none';

                setTimeout(() => {
                    window.print();
                    html.removeChild(printBase);
                    document.body.style.display = display;
                    if (this.state.extentionInfo.rendererType === 'img') {
                        originalElements!.appendChild(elements);
                    }
                }, 0);
            }
        }
    }
};
