import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Util } from '../../Common';
import { OrbitInternalLangPack } from '../../Common/Util';
const moduleStyles = require('../../OBTSingleYearMonthPicker/OBTSingleYearMonthPicker.module.scss');

const styles = {
    monthButton: {
        width: '43px',
        height: '36px'
    }
};

class UFOMonthCalendar extends Component {
    static propTypes = {
        /**
         * @ignore
         * 시작연도를 고정할지의 여부 입니다.
         */
        fixedYearFrom: PropTypes.bool,
        /**
         * @ignore
         * 종료연도를 고정할지의 여부 입니다.
         */
        fixedYearTo: PropTypes.bool,
        /**
         * 기본으로 선택될 날짜 값을 지정합니다.
         */
        initialDate: PropTypes.number,
        /**
         * 입력 가능한 날짜에 대한 범위의 최대(끝) 날짜를 지정하는 속성입니다.(YYYYMM)
         * 기본으로 현재 연도의 12월로 지정되어 있습니다.
         */
        maxValue: PropTypes.number,
        /**
         * 입력 가능한 날짜에 대한 범위의 최소(시작) 날짜를 지정하는 속성입니다.(YYYYMM)
         * 기본으로 현재 연도의 1월로 지정되어 있습니다.
         */
        minValue: PropTypes.number,
        /**
         * 요소에서 선택된 날짜 값이 변경될 때 호출되는 Callback 함수입니다.
         *
         * @param {object} date `DateTime` 형식의 변경된 날짜 값입니다.
         */
        onChange: PropTypes.func,
        /**
         * 요소에서 미리보기 날짜 값이 변경될 때 호출되는 Callback 함수입니다.
         *
         * @param {object} date `DateTime` 형식의 미리보기 날짜 값입니다.
         */
        onChangeDisplayDate: PropTypes.func,
        /**
         * @ignore
         */
        onChangeDisplayDateAll: PropTypes.func,
        /**
         * @ignore
         */
        selectedDateFrom: PropTypes.number,
        /**
         * @ignore
         */
        selectedDateTo: PropTypes.number,
        /**
         * 루트 요소의 inline-styles 값을 오버라이드 합니다.
         */
        style: PropTypes.object,
    };

    static defaultProps = {
        initialDate: null,
        minValue: (new Date().getFullYear() - 100) * 100 + 1,
        maxValue: (new Date().getFullYear() - 100) * 100 + 12,
    };

    state = {
        displayMonthDay: true,
        selectedDate: undefined,
    };

    componentWillMount() {
        const {
            fixedYearFrom,
            fixedYearTo,
            initialDate,
            maxValue,
            minValue
        } = this.props;

        let maxDate = maxValue;
        let minDate = minValue;
        // 고정연도 속성이 있을 경우, 이동하지 않게 연도를 바꾸어 넣어준다.
        if (fixedYearFrom) {
            maxDate = parseInt((minDate + "").slice(0, 4) + "12");
        }
        if (fixedYearTo) {
            minDate = parseInt((maxDate + "").slice(0, 4) + "01");
        }

        this.setState({
            selectedDate: this.getYearMonth(initialDate),
            maxDate: this.getYearMonth(maxDate),
            minDate: this.getYearMonth(minDate)
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.initialDate !== this.props.initialDate || nextProps.selectedDateFrom !== this.props.selectedDateFrom) {
            const { initialDate, maxValue, minValue } = nextProps;

            this.setState({
                selectedDate: this.getYearMonth(initialDate),
                maxDate: maxValue ? this.getYearMonth(maxValue) : null,
                minDate: minValue ? this.getYearMonth(minValue) : null,
            });
        }
    }

    /**
     * 6자리 숫자로 된 날짜 값을 연도와 월로 구분하여 반환합니다.
     */
    getYearMonth(date) {
        let year;
        let month;
        let today = new Date().getFullYear() * 100 + new Date().getMonth() + 1;
        let { minValue, maxValue, displayYear } = this.props;

        if (!date) {
            year = new Date().getFullYear();

            if (minValue && maxValue) {
                if (minValue <= today && today <= maxValue) {
                    year = this.getYearMonth(today).year;
                } else {
                    year = this.getYearMonth(minValue).year;
                    if (this.props.fixedYearTo) {
                        year = this.getYearMonth(maxValue).year;
                    }
                }
                if (displayYear && this.getYearMonth(minValue).year <= displayYear && displayYear <= this.getYearMonth(maxValue).year) {
                    year = parseInt(displayYear);
                }
            } else if (displayYear) {
                year = parseInt(displayYear);
            }

        } else {
            date = date.toString();
            year = parseInt(date.slice(0, 4));
            month = parseInt(date.slice(4));
        }

        return {
            year: year,
            month: month,
        }
    }

    /**
     * 테이블에 들어가는 월에 대한 요소를 반환합니다.
     * @return {Array}
     */
    getTableElement(props, state) {
        let { initialDate, selectedDateFrom, selectedDateTo } = props;
        let { selectedDate, maxDate, minDate } = state;
        let initDate = this.getYearMonth(initialDate);
        let fromDate = this.getYearMonth(selectedDateFrom);
        let toDate = this.getYearMonth(selectedDateTo);
        let tr = [];
        let td = [];
        let tdClassName = '';
        let month = 1;
        let buttonDisabled = false;

        for (let i = 0; i < 3; i++) {
            td = [];
            for (let j = 1; j < 5; j++) {
                month = (4 * i) + j;
                tdClassName = 'date_day';

                // 선택된 기간에 하늘색
                if (initialDate && selectedDateFrom && selectedDateFrom < initialDate) { // to Calendar
                    if (fromDate.year === selectedDate.year && fromDate.month === month) {
                        // tdClassName = 'date_day date_day_selected';
                        tdClassName = 'date_day date_day_gap';
                    } else if (fromDate.year === initDate.year && initDate.year === selectedDate.year && (fromDate.month < month && month < initDate.month)) {
                        tdClassName = 'date_day date_day_gap';
                    } else if (fromDate.year < initDate.year) {
                        if (fromDate.year < selectedDate.year && selectedDate.year < initDate.year) {
                            tdClassName = 'date_day date_day_gap';
                        } else if ((initDate.year === selectedDate.year && month < initDate.month) || (selectedDate.year === fromDate.year && month > fromDate.month)) {
                            tdClassName = 'date_day date_day_gap';
                        }
                    }
                } else if (initialDate && selectedDateTo && selectedDateTo > initialDate) { // from Calendar
                    if (toDate.year === selectedDate.year && toDate.month === month) {
                        // tdClassName = 'date_day date_day_selected';
                        tdClassName = 'date_day date_day_gap';
                    } else if (toDate.year === initDate.year && initDate.year === selectedDate.year && (initDate.month < month && month < toDate.month)) {
                        tdClassName = 'date_day date_day_gap';
                    } else if (initDate.year < toDate.year) {
                        if (initDate.year < selectedDate.year && selectedDate.year < toDate.year) {
                            tdClassName = 'date_day date_day_gap';
                        } else if ((initDate.year === selectedDate.year && initDate.month < month) || (selectedDate.year === toDate.year && month < toDate.month)) {
                            tdClassName = 'date_day date_day_gap';
                        }
                    }
                }

                // 선택된 월에 파란색
                if (initialDate && initDate.year === selectedDate.year && month === selectedDate.month) {
                    tdClassName = 'date_day date_day_selected';
                }

                buttonDisabled = false;
                // maxDate, minDate 의 범위를 벗어나면 disabled
                if (maxDate && minDate) {
                    if ((minDate.year === selectedDate.year && minDate.month > month) ||
                        (maxDate.year === selectedDate.year && maxDate.month < month)) {
                        buttonDisabled = true;
                    }
                }

                td.push(
                    <td key={'td' + i + j} className={tdClassName}>
                        <button
                            type="button"
                            onClick={(event) => this.handleTouchTapMonth(event, (4 * i) + j)}
                            style={Object.assign({}, styles.monthButton, buttonDisabled ? { cursor: 'not-allowed' } : {})}
                        >
                            <span>{month}{OrbitInternalLangPack.getText('WE000000254', '월')}</span>
                        </button>
                    </td>
                );
            }

            tr.push(
                <tr key={i}>{td}</tr>
            );
        }

        return tr;
    }

    getSelectedDate() {
        return this.state.selectedDate;
    }

    isSelectedDateDisabled() {
        if (!this.state.displayMonthDay) return false;

        // OBT 구문이 이상해서 주석처리함
        // return this.refs.calendar.isSelectedDateDisabled();
    }

    /**
     * 월 버튼이 눌렸을 때 호출됩니다.
     */
    handleTouchTapMonth = (event, month, year = this.state.selectedDate.year) => {
        let { maxDate, minDate } = this.state;
        let disabled = false;

        // maxDate, minDate 의 범위를 벗어나면 disabled
        if (maxDate && minDate) {
            if ((minDate.year === year && minDate.month > month) ||
                (maxDate.year === year && maxDate.month < month)) {
                disabled = true;
            }
        }

        if (disabled) return;
        if (this.props.onChange) {
            this.props.onChange(event, year * 100 + month);
        }
    };

    /**
     * 이전년/다음년 버튼이 눌렸을 때 호출됩니다.
     */
    addYear = (add) => {
        const { maxDate, minDate } = this.state;
        let { selectedDate } = this.state;

        // 연도를 고정해야할때는 버튼 동작안하게
        if (this.props.fixedYearTo) return;

        let newYear = selectedDate.year + add;

        // maxYear, minYear 의 범위를 벗어나면 변경하지 않는다.
        if (maxDate && minDate) {
            if (newYear > maxDate.year || newYear < minDate.year) return;
        }

        selectedDate.year = newYear;

        this.setState({ selectedDate: selectedDate });
        if (this.props.onChangeDisplayDate) {
            this.props.onChangeDisplayDate(selectedDate)
        }
    }

    render() {
        const {
            children,
            displayYear,
            fixedYearFrom,
            fixedYearTo,
            initialDate,
            maxValue,
            minValue,
            selectedDateFrom,
            selectedDateTo,
            onChangeDisplayDate,
            ...other
        } = this.props;

        const { selectedDate } = this.state;

        return (
            <div className="LS_date_day_bx" {...other}>
                <div className={moduleStyles.date_select}>
                    <button type="button" className={Util.getClassNames(moduleStyles.btn, moduleStyles.btn_prev_mon)} onClick={() => this.addYear(-1)}>
                        <span className={moduleStyles.sp_selene}>이전년</span>
                    </button>
                    <strong className={moduleStyles.date_day_title}>{selectedDate.year}</strong>
                    <button type="button" className={Util.getClassNames(moduleStyles.btn, moduleStyles.btn_next_mon)} onClick={() => this.addYear(1)}>
                        <span className={moduleStyles.sp_selene}>다음년</span>
                    </button>
                </div>
                <div className="date_tbl">
                    <table>
                        <caption></caption>
                        <tbody>
                            {this.getTableElement(this.props, this.state)}
                        </tbody>
                    </table>
                </div>
                {children}
            </div>
        );
    }
}

export default UFOMonthCalendar;
