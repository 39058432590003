import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import classNames from './ExampleFunctionBar.module.scss';
import { OBTButton } from '../../components';

export default class ExampleFunctionBar extends React.Component {
    static propTypes = {
        functions: PropTypes.array.isRequired
    }

    getFunctions = memoizeOne((functions) => {
        return (functions || []).map((func, index) => {
            return (
                <OBTButton key={index} labelText={func.labelText} onClick={func.onClick} />
            )
        })
    });

    render() {
        const { functions, ...others } = this.props;

        return (
            <div className={classNames.root} {...others}>
                {this.getFunctions(functions)}
            </div>
        )
    }
}