/**
 * ICodePickerInput
 * @version 0.1
 * @author 나윤수
 * @see common.js
 */
import LUXTextField from 'luna-rocket/LUXTextField';
import React, { Component, FormEvent } from 'react';
import { CompositeProps, Events, Util } from '../../Common';
import { IFocusable, IRequired } from '../../Common/Functions';
import downArrowImg from '../../Images/ic_arrow_up_01_s_Up.png';
import upArrowImg from '../../Images/ic_arrow_up_01_s_over.png';
import deleteIconImg from '../../Images/icon-con-delete.png';
import userProfileIcon from '../../Images/ic_profile.png';
import OBTButton from '../../OBTButton';
import OBTLoading from '../../OBTLoading';
import OBTTooltip from '../../OBTTooltip';
import IBuiltInCodePicker from '../DataSource/IBuiltInCodePicker';
import OBTFloatingPanel from '../../OBTFloatingPanel';
import { PositionType, AlignType } from '../../OBTFloatingPanel/OBTFloatingPanel';
import { OrbitInternalLangPack } from '../../Common/Util';

const styles = require('./CodePickerInput.module.scss');

interface ICodePickerInput extends CompositeProps.InputClass<string>, Events.onKeyUp {
    /**
     * 검색결과가 보여지는지 여부
     */
    isShowSearchResult: boolean;

    /**
     * 
     */
    showInfo: boolean,

    /**
     * 
     */
    inputValueMaxLength: number,

    /**
     * 
     */
    showDropdownIcon: boolean,

    /**
     * 
     */
    canMultiSelect: boolean,

    /**
     * 
     */
    showUserProfileIcon: boolean,

    /**
     * 다이얼로그에서 선택한 데이터를 표시해주기 위한 용도
     */
    dropdownItems: Array<any>,

    /** 
     * 드랍다운 리스트에서 선택된 아이템 
     */
    selectedItem: any | null,

    /**
     * 
     */
    tooltip?: any,

    /**
     * 
     */
    codePicker: IBuiltInCodePicker;

    /**
     * 
     */
    dropDownLoading: boolean,

    /**
     * @default true
     * 오직 다이얼로그로만 값을 검색해볼지에 대한 여부. false 시에 키보드 입력은 불가.
     */
    allowKeyboardInput?: boolean,

    /**
    * 다이얼로그 버튼 클릭 이벤트
    */
    onClickActionButton: (e: Events.EventArgs) => void,

    /**
     * 인풋값 클리어, 인풋에서 백스페이스 입력시
     */
    // onKeyPressBackspace: (e: Events.EventArgs) => void,

    /**
     * 
     */
    onClickRemoveAll: () => void,

    /**
     * 
     */
    onClickRemoveItem: (item: any) => void,

    /**
     * 드랍다운리스트에서
     * 페이징이 발생해야하는 상황에서 요청 
     */
    onRequestNextPageData?: () => Promise<any[]>,

    /**
     * 
     */
    onShowDropdDownList: () => Promise<any[]>,

    /**
    * 
    */
    onUserProfileClicked?: (item?: any) => void,

    /**
    * 
    */
    onClickDropDownItem: (item?: any) => void

    /**
     * 
     */
    onClickSelectAll: (items: any[]) => void
}

interface IState {
    /**
     * dropDown이 보여질지 여부
     */
    isShowDropDown: boolean,
    /**
     * 인풋 컴포넌트의 width값을 계산해 드랍다운리스트의 width를 결정하기 위한 값.
     */
    dropDownWidth: number,
    /**
     * 드랍다운 영역 애니메이션 효과를 위한 클래스
     */
    dropDownAnimate: any | undefined,
    /**
     * 멀티선택일때 드롭다운 영역 텍스트가 길어질때 툴팁 보여짐 여부
     */
    isShowDropDownTooltip: boolean,
    /**
     * 인풋 영역의 텍스트가 길어질때 툴팁에 메세지 보여짐 여부
     */
    isShowInputTooltip: boolean,

    tooltipMessage: string | null,

    // selectedItem: any | null,
}

interface CodePickerInputPublicMethod extends IFocusable, IRequired  { }

export class CodePickerInput extends Component<ICodePickerInput, IState> implements CodePickerInputPublicMethod {
    private readonly DROPDOWN_ITEM_COUNT = 7;

    private moveFocusInvoked: boolean = false;

    public static defaultProps = {
        inputValueMaxLength: 30,
        dropdownItems: [],
        selectedItem: null
    };

    state: IState = {
        isShowDropDown: false,
        dropDownWidth: 0,
        dropDownAnimate: undefined,
        isShowDropDownTooltip: false,
        tooltipMessage: null,
        isShowInputTooltip: false,
        // selectedItem: null,
    }

    /**
     * root div의 ref
     */
    private rootRef = React.createRef<HTMLDivElement>();

    /**
     * LUXTextField의 ref
     */
    private inputRef = React.createRef<LUXTextField>();
    private dropDownWrapperRef = React.createRef<HTMLDivElement>();
    private dropDownListRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        if (this.rootRef.current) {
            const rootWidth = this.rootRef.current.clientWidth;
            this.setState({
                dropDownWidth: rootWidth
            });
        }
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentDidUpdate(prevProps: ICodePickerInput, prevState: IState) {
        // width 갱신
        if (this.rootRef.current) {
            const rootWidth = this.rootRef.current.clientWidth;
            if ((this.props.width !== prevProps.width) || (this.state.dropDownWidth !== rootWidth)) {
                this.setState({
                    dropDownWidth: rootWidth
                });

            }
        }

        if (this.state.isShowDropDown !== prevState.isShowDropDown) {
            // 드랍다운 열릴때 애니메이션 클래스 
            if (this.state.isShowDropDown === true) {
                this.setState({
                    dropDownAnimate: 'animate'
                })
            } else {
                this.setState({
                    dropDownAnimate: undefined,
                    isShowDropDownTooltip: false
                })
            }
        }

        if (this.props.dropdownItems !== prevProps.dropdownItems && this.props.dropdownItems.length === 0 && this.state.isShowDropDown === true) {
            this.setState({
                isShowDropDown: false
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    private handleDropDownListScroll = (e) => {
        if (!e.target || this.props.dropDownLoading === true) {
            return false;
        }

        // TODO: 퍼포먼스
        const SCROLL_BUFFER = 10;

        const isReachedBottom = Math.floor(e.target.scrollHeight - e.target.scrollTop) < Math.floor(e.target.clientHeight + SCROLL_BUFFER);
        if (isReachedBottom) {
            if (this.props.onRequestNextPageData) {
                this.props.onRequestNextPageData();
            }
        }
    }

    private handleInputKeyPress = (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (this.props.onKeyUp) {
            Util.invokeEvent<Events.KeyEventArgs>(this.props.onKeyUp, new Events.KeyEventArgs(this, evt));
        }

        if (evt.key === 'Enter') {
            if (this.props.readonly) {
                return;
            }

            this.setState({
                isShowDropDown: false
            });
        }
    }

    private handleOutsideClick = (e) => {
        if (this.state.isShowDropDown === true && this.rootRef.current && !this.rootRef.current.contains(e.target)) {
            if (this.dropDownWrapperRef.current && !this.dropDownWrapperRef.current.contains(e.target)) {
                this.setState({
                    isShowDropDown: false
                })
            }
        }
    }

    private handleInputDoubleClick = () => {
        if (this.canEdit === false) {
            return;
        }

        this.setState({
            isShowDropDown: false
        }, () => {
            this.props.onClickActionButton(new Events.EventArgs(this));
        });
    }

    private handleFocusInput = () => {
        Util.invokeEvent<Events.FocusEventArgs>(this.props.onFocus, new Events.FocusEventArgs(this));
    }

    private handleBlurInput = () => {
        Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
    }

    private handleMoveFocus = (direction: string) => {
        this.moveFocusInvoked = true;
        if (this.props.required && this.isEmpty() && ['right', 'down', 'enter'].includes(direction)) {
            return;
        }

        if (this.inputRef.current && this.inputRef.current.textfieldRef && ['left', 'right'].includes(direction)) {
            const inputRef = this.inputRef.current.textfieldRef as HTMLInputElement

            // 텍스트의 영역이 선택되어 있다면 onMoveFocus 호출하지 않고 커서의 위치만 바꿈
            if (inputRef.selectionStart !== inputRef.selectionEnd) {
                this.moveFocusInvoked = false;
                return;
            }
        }
        this.setState({
            isShowDropDown: false,
            isShowInputTooltip: false
        })

        Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
    }

    private handleClickArrowBtn = () => {
        this.setState({
            isShowDropDown: !this.state.isShowDropDown,
        }, () => {
            if (this.state.isShowDropDown === true) {
                if (this.inputRef.current) {
                    this.inputRef.current.focus();
                }
                this.props.onShowDropdDownList().then(items => {
                    if ((items.length - 1) < this.DROPDOWN_ITEM_COUNT && this.props.onRequestNextPageData) {
                        this.props.onRequestNextPageData()
                    }
                });
            }
        });
    }

    private handleClickDeleteIcon = (item: any, e: React.MouseEvent) => {
        e.stopPropagation();
        if (this.props.dropDownLoading === true) {
            return;
        }

        this.props.onClickRemoveItem(item);

        if ((this.props.dropdownItems.length - 1) < this.DROPDOWN_ITEM_COUNT && this.props.onRequestNextPageData) {
            this.props.onRequestNextPageData()
        }
    }

    private handleClickRemoveAll = () => {
        this.setState({
            isShowDropDown: false
        }, () => {
            this.props.onClickRemoveAll();
        });
    }

    private handleBlurDropDown = () => {
        setTimeout(() => {
            if (!Util.containsFocus(this.dropDownWrapperRef)) {
                this.setState({
                    isShowDropDown: false
                });
            }
        }, 0);
    }

    private handleTextFieldKeyDown = (e: React.KeyboardEvent) => {
        if (this.moveFocusInvoked) {
            this.moveFocusInvoked = false;
            e.preventDefault();
        }
    }

    private handleTextFieldChange = (e: FormEvent<HTMLInputElement>) => {
        if (this.canEdit === false || this.props.allowKeyboardInput === false) {
            return;
        }

        Util.invokeEvent<Events.ChangeEventArgs<string>>(this.props.onChange, new Events.ChangeEventArgs<string>(this, e.currentTarget.value));
    }

    private handleTextFieldShowDialogButtonClicked = () => {
        if (this.canEdit === false) {
            return;
        }

        this.setState({
            isShowDropDown: false
        }, () => {
            this.props.onClickActionButton(new Events.EventArgs(this));
        });

    }

    private handleDropDownItemMouseEnter = (e: React.MouseEvent, item: any) => {
        const scrollWidth = e.currentTarget.scrollWidth;
        const clientWidth = e.currentTarget.clientWidth;

        if (e.currentTarget && scrollWidth > clientWidth) {
            this.setState({
                isShowDropDownTooltip: true,
                tooltipMessage: item[this.props.codePicker.codeProperty]
            })
        } else if (scrollWidth <= clientWidth) {
            this.setState({
                isShowDropDownTooltip: false,
                tooltipMessage: null
            })
        }
    }

    private handleDropDownMouseLeave = () => {
        this.setState({
            isShowDropDownTooltip: false
        })
    }

    handleInputMouseEnter = (e: React.MouseEvent) => {
        const scrollWidth = e.currentTarget.scrollWidth;
        const clientWidth = e.currentTarget.clientWidth;

        if (e.currentTarget && scrollWidth > clientWidth) {
            this.setState({
                isShowInputTooltip: true,
            })
        } else if (scrollWidth <= clientWidth) {
            this.setState({
                isShowInputTooltip: false
            })
        }
    }

    private handleInputMouseLeave = () => {
        this.setState({
            isShowInputTooltip: false
        })
    }

    private get canEdit(): boolean {
        return !this.props.disabled && !this.props.readonly;
    }

    public focus(isLast: boolean = false): void {
        if (!this.props.frozen && !this.props.disabled) {
            this.inputRef.current.focus();
        }
    }

    public isEmpty(): boolean {
        return (!this.props.value || this.props.value.length <= 0);
    }

    public validate() {
        return !(this.props.required && this.isEmpty());
    }

    private renderTextField = () => {
        return (
            <LUXTextField
                ref={this.inputRef}
                className={styles.input}
                customIconType="help"
                inputBoxStyle={{
                    padding: this.props.disabled ? '3px 6px' : this.props.canMultiSelect === true && this.props.value.length > 1 ? '3px 47px 3px 6px' : '3px 27px 3px 6px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center', ...Util.getInputStateStyle(this.props)
                }}
                inputStyle={{
                    color: this.props.readonly ? 'transparent' : undefined,
                    textShadow: this.props.readonly ? '0 0 0 black' : undefined,
                    fontFamily: 'inherit',
                }}
                style={Object.assign({}, Util.getInputStateStyle(this.props), { width: '100%' })}
                disabled={this.props.disabled}
                defaultValue={this.props.value}
                hintText={this.props.codePicker.placeHolder}
                useInfo={this.props.showInfo}
                maxLength={this.props.inputValueMaxLength}
                valueOuterControl={true}
                fullWidth={true}
                focusOnSelect={true}
                onChange={this.handleTextFieldChange}
                onTouchTapButton={this.handleTextFieldShowDialogButtonClicked}
                onFocus={this.handleFocusInput}
                onBlur={this.handleBlurInput}
                onMoveFocus={this.handleMoveFocus}
                onDoubleClick={this.handleInputDoubleClick}
                onKeyUp={this.handleInputKeyPress}
                onKeyDown={this.handleTextFieldKeyDown}
                onMouseEnter={(e) => { this.handleInputMouseEnter(e) }}
                onMouseLeave={this.handleInputMouseLeave}
            />
        )
    }

    private maybeRenderDropDownList = () => {
        if (this.state.isShowDropDown === false) {
            return;
        }

        const editable = this.props.disabled !== true && this.props.readonly !== true && this.props.frozen !== true && this.props.selectedItem === null;

        return (
            <OBTFloatingPanel value={this.state.isShowDropDown}
                anchor={this.rootRef}
                position={PositionType.bottom}
                align={AlignType.near}
            >
                <div ref={this.dropDownWrapperRef}
                    style={{
                        position: 'fixed',
                        width: this.state.dropDownWidth,
                        zIndex: 2000,
                        outline: 'none'
                    }}
                    className={this.state.dropDownAnimate === 'animate' ? Util.getClassNames(styles.dropDownWrapperAnimate, styles.dropDownWrapper) : styles.dropDownWrapper}
                    tabIndex={-1}
                    onBlur={this.handleBlurDropDown.bind(this)}>
                    <OBTLoading
                        open={this.props.dropDownLoading}
                        fullScreen={false}
                        width={'100%'}
                        height={'100%'}
                        type={OBTLoading.Type.small}
                    >
                        <div className={styles.dropdown} ref={this.dropDownListRef} onScroll={this.handleDropDownListScroll}>
                            {
                                this.props.selectedItem ?
                                    <div
                                        className={styles.dropdownItem}
                                        style={{
                                            padding: this.props.canMultiSelect && this.props.showUserProfileIcon === true ? '0 36px 0 7px' : '0 26px 0 7px'
                                        }}
                                        onClick={() => {
                                            this.props.onClickSelectAll(this.props.dropdownItems)
                                        }}
                                    >
                                        <span>선택전체</span>
                                    </div> : null
                            }
                            {
                                this.props.dropdownItems.map((item, index) => {
                                    let dropDownItem = (
                                        <div key={item[this.props.codePicker.codeProperty]}
                                            style={{
                                                padding: this.props.canMultiSelect && this.props.showUserProfileIcon === true ? '0 36px 0 7px' : '0 26px 0 7px',
                                                cursor: this.canEdit ? 'pointer' : 'auto'
                                            }}
                                            className={styles.dropdownItem}
                                            onClick={(e) => {
                                                if (this.canEdit === false) {
                                                    return;
                                                }

                                                this.setState({
                                                    isShowDropDown: false,
                                                }, () => {
                                                    this.props.onClickDropDownItem(item)
                                                })
                                            }}
                                            onMouseEnter={(e) => { this.handleDropDownItemMouseEnter(e, item) }}
                                            onMouseLeave={this.handleDropDownMouseLeave}>
                                            <span>{item[this.props.codePicker.codeProperty]}. {item[this.props.codePicker.textProperty]}</span>

                                            {editable ? (
                                                <img src={deleteIconImg}
                                                    className={styles.deleteIcon}
                                                    alt={"code picker dropdown delete item"}
                                                    onClick={this.handleClickDeleteIcon.bind(this, item)}
                                                />
                                            ) : null}
                                            {
                                                this.props.canMultiSelect && this.props.showUserProfileIcon === true ? (
                                                    <img src={userProfileIcon}
                                                        className={styles.userProfileIcon}
                                                        alt={"code picker userProfileIcon"}
                                                        onClick={() => {
                                                            if (this.props.onUserProfileClicked) {
                                                                this.props.onUserProfileClicked(item)
                                                            }
                                                        }}
                                                    />
                                                ) : null
                                            }
                                        </div>
                                    );

                                    if (this.state.tooltipMessage && this.state.tooltipMessage === item[this.props.codePicker.codeProperty]) {
                                        return (
                                            <OBTTooltip
                                                key={index}
                                                value={this.state.isShowDropDownTooltip}
                                                className={styles.itemOverFlowTooltip}
                                                labelText={`${item[this.props.codePicker.codeProperty]}. ${item[this.props.codePicker.textProperty]}`}
                                                focusValue={false}
                                                overrideSize={false}
                                            >
                                                {dropDownItem}
                                            </OBTTooltip>
                                        )
                                    }
                                    return dropDownItem
                                })
                            }
                        </div>
                        <div className={styles.downDownBottom}>
                            <OBTButton
                                labelText={OrbitInternalLangPack.getText('WE000022008', '전체삭제')}
                                type={OBTButton.Type.small}
                                disabled={!editable}
                                onClick={this.handleClickRemoveAll.bind(this)}
                            />
                        </div>

                    </OBTLoading>
                </div>
            </OBTFloatingPanel>
        )
    }

    private maybeRenderDropDownIcon = () => {
        if (this.props.showDropdownIcon !== true) {
            return;
        }
        return (
            <div className={styles.arrowBtn}
                onClick={this.handleClickArrowBtn}>
                <img src={this.state.isShowDropDown === true ? upArrowImg : downArrowImg}
                    className={Util.getClassNames(this.state.isShowDropDown === false ? styles.arrow : null)}
                    alt={"code picker dropdown arrow"}
                />
            </div>
        )
    }

    /**
     * 프로필 아이콘 렌더링
     */
    private maybeRenderUserProfileIconIfHas = () => {
        if (this.props.canMultiSelect === true || this.props.showUserProfileIcon !== true) {
            return;
        }

        return (
            <>
                <img src={userProfileIcon}
                    onClick={() => {
                        if (this.props.onUserProfileClicked) {
                            this.props.onUserProfileClicked()
                        }
                    }}
                    className={styles.userProfile}
                    alt={"code picker userProfile Icon"}
                />
            </>
        )
    }

    render() {
        const renderCodePickerInput = (
            <div ref={this.rootRef}>
                {/* 인퓻영역 */}
                {this.renderTextField()}

                {/* 드랍다운 아이콘 렌더링 */}
                {this.maybeRenderDropDownIcon()}

                {/* 프로필 아이콘 렌더링 */}
                {this.maybeRenderUserProfileIconIfHas()}

                {/* 드랍다운리스트 렌더링 */}
                {this.maybeRenderDropDownList()}
            </div>
        )
        if (this.state.isShowInputTooltip) {
            return (
                <OBTTooltip
                    {...this.props.tooltip}
                    value={this.state.isShowInputTooltip}
                    labelText={this.props.value}
                    className={styles.obtTextField}
                    style={{ width: '100%' }}
                    overrideSize={false}
                    focusValue={false}
                >
                    {renderCodePickerInput}
                </OBTTooltip>
            )
        } else {
            return (
                <OBTTooltip
                    {...this.props.tooltip}
                    className={styles.obtTextField}
                    style={{ width: '100%' }}
                    focusValue={false}
                    overrideSize={false}
                >
                    {renderCodePickerInput}
                </OBTTooltip>
            );
        }
    }
}
