import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Error from 'luna-rocket/LUXSVGIcon/Duzon/FullSize/Error';
import Success from 'luna-rocket/LUXSVGIcon/Duzon/FullSize/Success';
import { LUXCircularProgress } from 'luna-rocket/LUXProgress';
import shallowEqual from 'recompose/shallowEqual';
import globalObj from 'luna-rocket/locale/intlGlobals';
import OBTButton from '../../../OBTButton';
import downArrowImg from '../../../Images/icon-btn-arrow-down-normal.png';
import topArrowImg from '../../../Images/icon-btn-arrow-top-normal.png';
import scssStyles from '../../OBTComplete.module.scss';

const getStyles = (props) => {
    const {
        isFocused,
        disabled,
        validationError,
        validationSuccess,
        progress,
        isDropDown
    } = props;

    const styles = {
        // inputElement 의 전체를 감싸고 있는 div 의 Style
        style: {
            position: 'relative',
            margin: 0,
            padding: 0,
            background: disabled ? '#f3f3f3' : '',
            cursor: disabled || progress ? 'not-allowed' : 'auto',
        },
        // inputElement 의 span Style
        spanStyle: {
            paddingRight: '6px',
            display: 'block',
            height: '19px',
            padding: validationSuccess || validationError ? '6px 24px 0 6px' : '6px 6px 0px 6px',
            border: (!disabled || !progress) && validationError ? '1px solid #fc5356' : (!disabled || !progress) && validationSuccess ? '1px solid #8fe1bd' : isFocused ? '1px solid #58adfc' : '1px solid #dbdbdb',
            background: disabled || progress ? '#f3f3f3' : '#fff',
            lineHeight: '19px',
            margin: 0,
        },
        // input Style
        inputStyle: {
            display: 'block',
            width: isDropDown ? 'calc(100% - 22px)' : '100%',
            border: 0,
            background: 'transparent',
            fontSize: '12px',
            fontFamily: 'inherit',
            lineHeight: '13px',
            color: '#000',
            outline: 'none',
            margin: 0,
            padding: 0,
            cursor: disabled || progress ? 'not-allowed' : 'auto',
        },
        rightIconStyle: {
            position: 'absolute',
            top: '6px',
            right: '6px',
            width: '14px',
            height: '14px',
            pointerEvents: 'none'
        },
        infoStyle: {
            fontFamily: 'inherit',
            lineHeight: '13px',
            margin: '5px 0 0 5px',
            fontSize: '12px',
            color: (!disabled || !progress) && validationError ? '#fc5356' : (!disabled || !progress) && validationSuccess ? '#8fe1bd' : '#8d8d8d'
        }
    };

    return styles;
};

class InputElement extends Component {

    // region React Base

    static propTypes = {
        /**
         * inputElement 의 전체를 감싸고 있는 div 의 스타일
         */
        style: PropTypes.object,
        /**
         * 값이 true 이면, 요소가 로딩 중인 상태로 변합니다.
         */
        progress: PropTypes.bool,
    }

    static defaultProps = {
        isFocused: false,
        disabled: false,
        hintText: globalObj.intl.messages["luna.rocket.enter"],
        value: ''
    };

    /**
     * constructor
     */
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    myRefs = {
        input: React.createRef(),
        root: React.createRef()
    }

    /**
     * shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return !shallowEqual(this.props, nextProps);
    }

    /**
     * componentDidUpdate
     */
    componentDidUpdate(prevProps, prevState) {
        // 포커스 상태가 변경되면 포커스를 주거나 잃는다.
        if (prevProps.isFocused !== this.props.isFocused) {
            if (this.props.isFocused) {
                if (this.myRefs.input.current) this.myRefs.input.current.focus();
            } else {
                if (this.myRefs.input.current) this.myRefs.input.current.blur();
                return false;
            }
        }
    }

    // region Input

    focus = () => {
        if (this.props.disabled || this.props.progress) return;
        if (this.myRefs.input.current) this.myRefs.input.current.focus();
    }

    /**
     * input 생성
     */
    createInput = (styles, styleInput, inputProps) => {
        let input = (
            <input
                ref={this.myRefs.input} type="text" style={Object.assign({}, styles.inputStyle, styleInput)}
                value={this.props.value}
                placeholder={this.props.hintText}
                autoComplete="pqdwef"
                {...inputProps}
            >
            </input>
        );
        return input;
    }


    /**
     * input Change
     */
    handleChange = (event) => {
        if (this.props.value !== event.target.value) {
            if (this.props.onChange) {
                this.props.onChange(event);
            }
        }
    }

    /**
     * input 이 포커스를 가지거나 가져야 할 경우 발생
     */
    handleFocus = (event) => {
        if (this.props.disabled || this.props.progress) {
            event.preventDefault();
            event.stopPropagation();
            this.handleBlur(event);
        }
        this.setState({
            isFocused: true,
        });

        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    };

    handleBlur = (event) => {
        this.setState({
            isFocused: false,
        });

        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    // endregion

    getInputWidth() {
        let width = 0;
        if (this.myRefs.root.current) {
            width = this.myRefs.root.current.clientWidth;
        }
        return width;
    }

    getCaretPosition = () => {
        let targetElement = this.myRefs.input.current;
        if (document.selection) { // IE < 9
            if (targetElement) targetElement.focus();
            let range = document.selection.createRange();
            let rangeLength = range.text.length;
            range.moveStart('character', -targetElement.value.length);

            let start = range.text.length - rangeLength;
            return { 'start': start, 'end': start + rangeLength };
        } else { // IE >= 9 and Other Browsers
            return { 'start': targetElement.selectionStart, 'end': targetElement.selectionEnd };
        }
    };

    handleInputPaste = (event) => {
        event.stopPropagation();

        let clipboardData = event.clipboardData.getData('Text');
        if (clipboardData) {
            // 붙여넣기 시, 데이터가 정규식 형태에 맞는지 확인
            if (this.props.value) {
                let subText1 = this.props.value.substring(0, this.getCaretPosition().start);
                let subText2 = this.props.value.substring(this.getCaretPosition().end, this.props.value.length);
                let convertData = subText1 + clipboardData + subText2;
                if (this.props.onChange) this.props.onChange(event, convertData);
            } else {
                if (this.props.onChange) this.props.onChange(event, clipboardData);
            }
        } else {
            let convertData = this.props.value + clipboardData;
            if (this.props.onChange) this.props.onChange(event, convertData);
        }
    }
    // region Render

    /**
     * Render
     */
    render() {
        let {
            style,
            styleInputDiv,
            styleInputSpan,
            styleInput,
            stylePlaceHolder,
            isFocused,
            disabled,
            hintText,
            value,
            onChange,
            onFocus,
            onBlur,
            onAdd,
            icon,
            validationError,
            validationSuccess,
            progress,
            isDropDown,
            onDropDownClick,
            isOpen,
            ...other
        } = this.props;

        disabled = progress || disabled;

        const inputProps = {
            onChange: this.handleChange,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            disabled: disabled,
            onPaste: this.handleInputPaste,
            ...other
        };

        const styles = getStyles(this.props);

        // input 생성
        let input = this.createInput(styles, styleInput, inputProps);

        // validation Error에 대한 우측 아이콘 생성
        let rightIconElement;
        if (!disabled && validationError) {
            rightIconElement = (<Error color="#fc5356" style={styles.rightIconStyle} />);
        } else if (!disabled && validationSuccess) {
            rightIconElement = (<Success color="#1fc47c" style={styles.rightIconStyle} />);
        }

        // progress
        const progressElement = progress ?
            <LUXCircularProgress type="small" size={20}
                style={{ position: 'absolute', left: '50%', top: '50%', margin: '-10px' }}
            /> : undefined;
        const dropDownBtn = isDropDown ?
            <OBTButton className={scssStyles.arrowBtn} imageUrl={isOpen ? topArrowImg : downArrowImg} width={'27px'} height={'27px'} style={styles.arrowBtnStyle} onClick={onDropDownClick} /> :
            undefined;

        return (
            <div ref={this.myRefs.root} style={Object.assign({}, styles.style, styleInputDiv)}>
                {icon}
                <span style={Object.assign({}, styles.spanStyle, styleInputSpan)}>
                    {input}
                    {dropDownBtn}
                    {rightIconElement}
                    {progressElement}
                </span>
            </div>
        )
    }
    //endregion
}

export default InputElement;
