import React, { Component } from 'react'
import { OBTAlert } from '../index';
import { Events } from '../Common';
import uuid from 'uuid/v1';

interface IState {
    key?: string,
    type?: string,
    title?: string,
    labelText?: string,
    onClose?: (e: Events.EventArgs) => void
}

export default class Alert extends Component<{}, IState> {
    state: IState = {}

    public setAlert(type?: string, title?: string, labelText?: string, onClose?: (e: Events.EventArgs) => void) {
        return new Promise<void>(resolve => {
            this.setState({
                key: uuid(),
                type,
                title,
                labelText,
                onClose
            }, () => {
                resolve();
            });
        })

    }

    public close() {
        return new Promise<void>(resolve => {
            this.setState({
                key: undefined
            }, () => resolve());
        });
    }

    render() {
        return (
            this.state.key ? <OBTAlert title={this.state.title} key={this.state.key} type={this.state.type as any} labelText={this.state.labelText} onClose={this.handleClose.bind(this)} /> : <></>
        );
    }

    handleClose() {
        const onClose = this.state.onClose;
        this.setState({
            key: undefined
        }, () => {
            if (onClose) {
                onClose(new Events.EventArgs(this));
            }
        });
    }
}
