import React, { Component } from 'react';
import PropTypes from 'prop-types';
import keycode from "keycode";
import LUXCalendarMonth from 'luna-rocket/LUXPeriodDatePicker/LUXCalendarMonth';
import LUXDateDisplay from 'luna-rocket/LUXDatePicker/LUXDateDisplay';
import LUXButton from 'luna-rocket/LUXButton';
import { isAfterDate, isBeforeDate, isBeforeMonth, isBetweenDates, getFirstDayOfMonth } from 'luna-rocket/LUXDatePicker/LUXDateUtils';
import moment from 'moment'
import { OrbitInternalLangPack } from '../Common/Util';

//all258 아래 import 추가
import OBTFloatingPanel from '../OBTFloatingPanel';
import { Util } from '../Common';

//all258 scss 추가 및 styles 수정
const moduleStyles = require('./WeekDateDialog.module.scss');
const styles = {
    root: {
        width: '216px',
        marginTop: '-1px',
        border: '1px solid rgb(163, 163, 163)',
        background: 'rgb(255, 255, 255)',
        boxSizing: 'border-box'
    },
    label: {
        clear: 'both',
        padding: '10px',
        borderTop: '1px solid #e6e6e6',
        whiteSpace: 'nowrap',
        outline: 'none',
    },
    button: {
        background: '#fff',
        border: '1px solid #e0e0e0',
        boxSizing: 'border-box',
        color: '#4a4a4a',
        display: 'inline-block',
        fontSize: '11px',
        fontFamily: '돋움, Dotum, Helvetica, Apple SD Gothic Neo, sans-serif',
        height: '21px',
        margin: '-1px 3px 0 -1px',
        outline: 'none',
        padding: '1px 6px 0',
        position: 'relative',
        textAlign: 'center',
        verticalAlign: 'top',
    },
};

class WeekDateDialog extends Component {
    static propTypes = {
        firstDayOfWeek: PropTypes.number,
        initialDate: PropTypes.object,
        locale: PropTypes.string.isRequired,
        maxDate: PropTypes.object,
        minDate: PropTypes.object,
        onTouchTapDay: PropTypes.func,
        open: PropTypes.bool,
        selectedDateTo: PropTypes.object,
        shouldDisableDate: PropTypes.func,
        useControlButton: PropTypes.bool,
        customLabel: PropTypes.func,
        required: PropTypes.bool
    };

    state = {
        displayDate: undefined,
        displayMonthDay: true,
        selectedDate: undefined,
        //all258 애니메이션 효과를 주기위해 추가
        transition: false
    };

    //all258 ref 추가
    myRef = {
        root: React.createRef(),
        dateDisplay: React.createRef(),
        calendarFrom: React.createRef()
    }

    componentWillMount() {
        const { initialDate, minDate, maxDate } = this.props;

        const date = initialDate ? initialDate : isBetweenDates(new Date(), minDate, maxDate) ? new Date() : minDate;

        this.setState({
            displayDate: getFirstDayOfMonth(date),
            selectedDate: initialDate,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.initialDate !== this.props.initialDate) {
            const { initialDate, minDate, maxDate } = nextProps;

            const date = initialDate ? initialDate : isBetweenDates(new Date(), minDate, maxDate) ? new Date() : minDate;

            this.setState({
                displayDate: getFirstDayOfMonth(date),
                selectedDate: initialDate,
            });
        }
    }

    getSelectedDate() {
        return this.state.selectedDate;
    }

    isSelectedDateDisabled() {
        if (!this.state.displayMonthDay) return false;

        return this.refs.calendarFrom.isSelectedDateDisabled();
    }

    setDisplayDate = (date) => {
        const newDisplayDate = getFirstDayOfMonth(date);
        const direction = newDisplayDate > this.state.displayDate ? 'left' : 'right';

        if (newDisplayDate !== this.state.displayDate) {
            this.setState({
                displayDate: newDisplayDate,
                transitionDirection: direction,
            });
        }
    }

    setSelectedDate = (date) => {
        let adjustedDate = date;

        if (this.props.minDate && isBeforeDate(date, this.props.minDate)) {
            adjustedDate = this.props.minDate;
        } else if (this.props.maxDate && isAfterDate(date, this.props.maxDate)) {
            adjustedDate = this.props.maxDate;
        }

        this.setDisplayDate(adjustedDate);

        this.setState({ selectedDate: adjustedDate });
    }

    handleTouchTapDay = (event, date) => {
        this.setSelectedDate(date);

        //all258 이벤트부분 삭제
        if (this.props.onTouchTapDay) this.props.onTouchTapDay(date);
    };

    handleChangeDateDisplay = (displayDate) => {
        const { maxDate, minDate } = this.props;

        if (isAfterDate(displayDate, maxDate) || isBeforeMonth(displayDate, minDate)) return;

        this.setDisplayDate(displayDate);

        if (this.props.onChangeDisplayDate) this.props.onChangeDisplayDate(displayDate, 'from');
    };

    handleKeyDown = (event) => {
        event.stopPropagation();

        switch (keycode(event)) {
            case 'backspace':
                event.preventDefault();
                break;
            default:
                break;
        }
    }
    //all258 className 합치기위해 추가
    getClassNames = (...classNames) => {
        return classNames.filter(className => className && className.length > 0).join(' ');
    }

    //all258 애니메니션 효과주기위해 추가
    componentDidMount() {
        if (this.props.open && this.state.transition !== true) {
            this.setState({
                transition: true
            })
        } else if (this.props.open === false) {
            if (this.state.transition !== false)
                this.setState({
                    transition: false
                })
        }
    }

    //all258 애니메니션 효과주기위해 추가
    componentDidUpdate() {
        if (this.props.open && this.state.transition !== true) {
            this.setState({
                transition: true
            })
        } else if (this.props.open === false) {
            if (this.state.transition !== false)
                this.setState({
                    transition: false
                })
        }
    }

    handleResetFrom = () => {
        this.setState({
            selectedDate: null
        })
        if (this.props.onTouchTapDay) this.props.onTouchTapDay(null);
    }

    invalidChecked = (date) => {
        if (String(date) === 'Invalid date' || String(date) === 'Invalid Date') {
            return null
        } else {
            return date
        }
    }

    render() {

        const {
            firstDayOfWeek,
            initialDate,
            locale,
            minDate,
            maxDate,
            onTouchTapToday, // eslint-disable-line no-unused-vars
            selectedDateTo,
            shouldDisableDate,
        } = this.props;

        return (
            //all258 패널추가
            <OBTFloatingPanel
                value={this.props.open}
                position={this.props.position}
                align={this.props.align}
                autoPosition={true}
            >
                {/* all258 애니메이션효과를 위한 className추가 */}
                <div ref={this.myRef.root}
                    tabIndex={0}
                    style={styles.root} onKeyDown={this.handleKeyDown}
                    className={this.getClassNames(moduleStyles.calendar, this.state.transition === true ? moduleStyles.visible : null)} >
                    {/* all258 ref 최신버젼으로 변경 */}
                    {this.props.useControlButton ?
                        <LUXDateDisplay
                            ref={this.myRef.dateDisplay}
                            locale={locale}
                            minDate={minDate}
                            maxDate={maxDate}
                            onChangeDateDisplay={this.handleChangeDateDisplay}
                            selectedDate={this.state.displayDate}
                        /> :
                        <></>}
                    {/* all258 ref 최신버젼으로 변경 type prop 삭제 */}
                    <div className={moduleStyles.content}>
                        <LUXCalendarMonth
                            ref={this.myRef.calendarFrom}
                            displayDate={this.state.displayDate}
                            firstDayOfWeek={firstDayOfWeek}
                            initialDate={initialDate}
                            key={this.state.displayDate.toDateString()}
                            minDate={minDate}
                            maxDate={maxDate}
                            onTouchTapDay={this.handleTouchTapDay}
                            selectedDateFrom={this.state.selectedDate}
                            selectedDateTo={selectedDateTo}
                            shouldDisableDate={shouldDisableDate}
                        />
                    </div>
                    {/* all258 week의 형식에 맞게 추가 */}
                    <div style={styles.label} tabIndex="0">
                        {this.props.customLabel ? this.props.customLabel(this.invalidChecked(moment(this.state.selectedDate).format('YYYYMMDD')), this.invalidChecked(moment(selectedDateTo).format('YYYYMMDD')), this.handleResetFrom) :
                            [<LUXButton
                                key={'today'}
                                label={OrbitInternalLangPack.getText('WE000005756', '이번주')}
                                onTouchTap={() => this.handleTouchTapDay(undefined, new Date())}
                                style={styles.button}
                            />,
                            !this.props.required ? <LUXButton
                                key={'clear'}
                                label={OrbitInternalLangPack.getText('WE000001958', '초기화')}
                                onTouchTap={() => this.props.onTouchTapDay('')}
                                style={styles.button}
                            /> : undefined
                            ].filter(component => component)}
                    </div>
                </div>
            </OBTFloatingPanel>
        );
    }

    containsFocus = () => {
        return Util.containsFocus(this.myRef.root);
    }

    show = () => {

    }
}

export default WeekDateDialog;
