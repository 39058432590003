import { createPropDefinitions, CommonType } from "../Common";
import { toEnumType } from "../Common/PropDefinition";
import { DisplayType } from '../OBTDropDownList/OBTDropDownList'

enum GridState {
    'none' = 'none',
    'empty' = 'empty',
    'added' = 'added',
    'modified' = 'modified',
    'deleted' = 'deleted'
}

export default createPropDefinitions(
    {
        name: 'constructor', type: CommonType.function, parameters: {
            name: 'options',
            type: {
                dataAdapter: {
                    read: {
                        type: '(e: { target, pagingInfo }) => Promise<any>',
                        description: '조회 Callback'
                    },
                    readTotalCount: {
                        type: '(e: { target }) => Promise<number>',
                        description: '페이징시 전체 RowCount 리턴 Callback',
                        optional: true
                    },
                    store: {
                        type: '(e: { target, indexes, data, changeState, cancel }) => Promise<void>',
                        description: '저장 Callback',
                        optional: true
                    }
                },
                cardListTemplate: {
                    template: {
                        type: ['default', 'imageLeft', 'imageRight', 'stateIcon', 'stateLabel', 'custom'],
                        description: '템플릿 종류'
                    },
                    main: {
                        type: CommonType.string,
                        description: '템플릿 기본 텍스트 필드명'
                    },
                    subLeft: {
                        type: CommonType.string,
                        optional: true,
                        description: '템플릿 보조 텍스트(좌) 필드명'
                    },
                    subRight: {
                        type: CommonType.string,
                        optional: true,
                        description: '템플릿 보조 텍스트(우) 필드명'
                    },
                    right: {
                        type: CommonType.string,
                        optional: true,
                        description: '템플릿 우측 텍스트 필드명'
                    },
                    className: {
                        type: CommonType.string,
                        optional: true,
                        description: '템플릿에 적용될 클래스명'
                    },
                    imageUrl: {
                        type: CommonType.image,
                        optional: true,
                        description: '템플릿 우측 이미지 필드명'
                    },
                    getComponent: {
                        type: '(e: { data: any }) => 컴포넌트',
                        optional: true,
                        description: '커스텀 템플릿의 Item 컴포넌트 리턴 Callback'
                    }
                },
                listHeight: {
                    type: CommonType.number,
                    optional: true,
                    description: '각 Item 높이 (템플릿 사용시 64px 고정)'
                },
                onRenderItem: {
                    type: '(e: { target, index, data, component }) => void',
                    optional: true,
                    description: '템플릿 미사용시, Item 컴포넌트 리턴(e.component지정) Callback'
                },
                headerCheckVisible: {
                    type: CommonType.boolean,
                    optional: true,
                    description: '헤더 체크박스 사용여부'
                },
                checkable: {
                    type: CommonType.boolean,
                    optional: true,
                    description: '체크박스 사용여부'
                },
                headerComponent: {
                    type: '컴포넌트',
                    optional: true,
                    description: '헤더에 특정 컴포넌트를 표시할 경우 지정합니다.'
                },
                headerClassName: {
                    type: CommonType.string,
                    optional: true,
                    description: '헤더에 class 를 지정할 경우 사용합니다.'
                },
                paging: {
                    type: CommonType.boolean,
                    optional: true,
                    description: '페이징 사용여부'
                },
                rowCountPerPage: {
                    type: CommonType.number,
                    optional: true,
                    description: '페이징 사용시 한 페이지당 Item 갯수(10, 20, 30, 40, 50 선택)'
                },
                appendButton: {
                    type: {
                        appendComponent: { type: CommonType.any, optional: true, description: '추가버튼에 표시될 컴포넌트' },
                        labelText: { type: CommonType.string, optional: true, description: '추가 버튼에 표시될 텍스트' },
                        className: { type: CommonType.string, optional: true, description: '추가 버튼에 지정될 class' },
                        imageUrl: { type: CommonType.image, optional: true, description: '추가 버튼에 표시될 이미지 Url' }
                    },
                    optional: true,
                    description: '추가버튼 속성'
                },
                borderRadius: {
                    type: CommonType.string,
                    optional: true,
                    description: 'Item 의 border-radius 속성'
                },
                itemPadding: {
                    type: {
                        top: { type: CommonType.string, optional: true },
                        left: { type: CommonType.string, optional: true },
                        right: { type: CommonType.string, optional: true },
                        bottom: { type: CommonType.string, optional: true }
                    }
                }
            }
        },
        description: `인터페이스 생성시 생성자로, options json 객체를 파라메터로 지정해야 합니다.
1. dataAdapter
조회, 저장 시 사용하는 callback 함수로 필수 지정되어야 합니다.
- read 조회 callback 으로 readData() 함수가 호출될때 호출되며 조회한 data 를 resolve 해야 합니다.
- 페이징을 사용하는 경우 read callback 의 pagingInfo 를 사용하고, readTotalCount 를 추가로 지정하여 전체 카운트를 조회할 수 있도록 해야 합니다.
- 카드리스트에서 저장기능이 필요한 경우 store 를 구현합니다.
2. cardListTemplate
카드리스트에 표시하는 Item 을 직접 구현하지 않고 (onRenderItem 구현) 템플릿을 사용하기 위해 설정하는 속성입니다.
- template 에 사용할 템플릿을 반드시 지정하십시오.
- main, subLeft, subRight, right, imageUrl 에 필드명을 주어 값을 표시하십시오.
- 템플릿 형태는 사용하되 내용을 따로 구성하고 싶다면 getComponent Callback 을 구현하여 내부에 표시되는 것을 대체할 수 있습니다.
3. listHeight, onRenderItem
템플릿을 사용하지 않고 직접 표시를 구현하는 경우 사용합니다.
4. appendable, appendButton
추가 버튼을 설정합니다.
- 추가 버튼은 카드리스트 하단에 항상 표시됩니다.
- appendButton 속성을 사용하여 추가버튼 내용을 지정할 수 있습니다.
5. headerCheckVisible, checkable
체크 기능 사용여부를 지정합니다.
6. headerComponent, headerClassName
카드리스트의 헤더 부분을 변경할때 사용합니다. 카드리스트와 통합된 형태로 추가 표시가 필요한 경우 사용합니다.
7. paging, rowCountPerPage
카드리스트의 페이징 기능 옵션을 지정할 떄 사용합니다.
8. borderRadius, itemPadding
카드리스트 Item 의 일부 스타일링을 변경하기 위해 사용합니다.`
    },
    {
        name: 'setHeaderCheckVisible',
        type: CommonType.function,
        parameters: {
            name: 'visible',
            type: CommonType.boolean
        },
        optional: true,
        description: '카드 리스트에서 체크박스 여부를 설정하는 함수입니다.'
    },
    {
        name: 'setSort',
        type: CommonType.function,
        parameters: [{
            name: 'index',
            type: CommonType.string,
            description: '정렬기준(sortList.value)'
        }, {
            name: 'sortList',
            type: {
                value: { type: CommonType.string },
                columnName: { type: CommonType.string, description: '정렬 대상 필드명' },
                text: { type: CommonType.string, description: 'DropDownList 표시 텍스트' }
            }
        }, {
            name: 'options',
            type: {
                displayType: {
                    type: toEnumType(DisplayType),
                    optional: true,
                    description: 'DropDownList 텍스트 표시 유형'
                },
                comparer: {
                    type: '(sortType: { value, columnName, text }, source: any, target: any) => number',
                    optional: true,
                    description: '정렬에 사용될 사용자정의 Comparer 함수'
                }
            }
        }],
        optional: true,
        description: '카드리스트 우측 상단에 정렬을 설정하는 함수입니다.'
    },
    {
        name: 'readData',
        type: CommonType.function,
        parameters: [{
            name: 'readCallback',
            type: '(e: { target, pagingInfo }) => Promise<any>',
            description: '기본 지정된 read callback 을 대체하여 조회해야 할 때 지정합니다.'
        }, {
            name: 'currentPage',
            type: CommonType.number,
            description: '페이징 시 현재 페이지 번호를 지정할 떄 사용합니다.'
        }, {
            name: 'rowCountPerPage',
            type: CommonType.number,
            description: '페이징 시 지정된 페이지 당 RowCount 를 대체할 때 사용합니다.'
        }, {
            name: 'clear',
            type: CommonType.boolean,
            description: '조회시 조회전 데이터를 clear 하지 않으려면 true 로 지정합니다.'
        }],
        result: 'Promise<any>',
        optional: true,
        description: '카드 리스트에서 Data를 Read에서 설정하는 Callback 함수입니다.'
    },
    {
        name: 'storeData',
        type: CommonType.function,
        parameters: [{
            name: 'rowIndex',
            type: CommonType.number,
            description: '저장할 rowIndex. 미 지정시 현재 rowIndex 가 지정됩니다.'
        }, {
            name: 'storeCallback',
            type: '(e: { target, indexes, data, changeState, cancel }) => Promise<void>',
            description: '저장 callback 을 대체할 때 지정합니다.'
        }],
        result: 'Promise<void>',
        optional: true,
        description: '카드리스트에서 데이터 변경이 일어났을경우, 명시적으로 저장하려 할떄 호출합니다. (기본적으로 row 변경시 호출됩니다.)'
    },
    {
        name: 'clearData',
        type: CommonType.function,
        optional: true,
        description: '카드리스트 데이터를 모두 제거할 때 사용합니다.'
    },
    {
        name: 'getRowCount',
        type: CommonType.function,
        result: CommonType.number,
        optional: true,
        description: '전체 Row 갯수를 리턴합니다.'
    },
    {
        name: 'getRowIndexes',
        type: CommonType.function,
        parameters: {
            name: 'options',
            type: {
                checkedOnly: {
                    type: CommonType.boolean,
                    optional: true,
                    description: '체크 된 Row만 가져옵니다.'
                },
                states: {
                    type: toEnumType(GridState),
                    optional: true,
                    description: '지정 상태인 Row 만 가져옵니다.'
                }
            }
        },
        result: 'number[]',
        optional: true,
        description: '카드리스트에 binding 된 Row 의 index 를 리턴합니다.'
    },
    {
        name: 'getRows',
        type: CommonType.function,
        parameters: {
            name: 'options',
            type: {
                checkedOnly: {
                    type: CommonType.boolean,
                    optional: true,
                    description: '체크 된 Row만 가져옵니다.'
                },
                states: {
                    type: toEnumType(GridState),
                    optional: true,
                    description: '지정 상태인 Row 만 가져옵니다.'
                }
            }
        },
        result: 'any[]',
        optional: true,
        description: '카드리스트에 binding 된 Row 의 데이터를 리턴합니다.'
    },
    {
        name: 'getRow',
        type: CommonType.function,
        parameters: {
            name: 'rowIndex',
            type: CommonType.number
        },
        result: 'any',
        optional: true,
        description: '지정된 rowIndex 에 해당하는 데이터를 리턴합니다.'
    },
    {
        name: 'getValues',
        type: CommonType.function,
        parameters: {
            name: 'rowIndex',
            type: CommonType.number
        },
        result: 'any',
        optional: true,
        description: 'getRow 와 동일한 함수입니다.'
    },
    {
        name: 'getValue',
        type: CommonType.function,
        parameters: [{
            name: 'rowIndex',
            type: CommonType.number
        }, {
            name: 'columnName',
            type: CommonType.string
        }],
        result: 'any',
        optional: true,
        description: '지정된 rowIndex 의 지정된 필드 데이터를 리턴합니다.'
    },
    {
        name: 'setValues',
        type: CommonType.function,
        parameters: [{
            name: 'rowIndex',
            type: CommonType.number
        }, {
            name: 'values',
            type: CommonType.any
        }],
        optional: true,
        description: '지정된 rowIndex 의 데이터 전체를 변경합니다.'
    },
    {
        name: 'setValue',
        type: CommonType.function,
        parameters: [{
            name: 'rowIndex',
            type: CommonType.number
        }, {
            name: 'columnName',
            type: CommonType.string
        }, {
            name: 'value',
            type: CommonType.any
        }],
        optional: true,
        description: '지정된 rowIndex 의 지정 필드 데이터를 변경합니다.'
    },
    {
        name: 'getState',
        type: CommonType.function,
        parameters: [{
            name: 'rowIndex',
            type: CommonType.number
        }],
        result: toEnumType(GridState),
        optional: true,
        description: '지정된 rowIndex 의 수정상태(GridState) 를 리턴합니다.'
    },
    {
        name: 'setState',
        type: CommonType.function,
        parameters: [{
            name: 'rowIndex',
            type: CommonType.number
        }, {
            name: 'state',
            type: toEnumType(GridState)
        }],
        optional: true,
        description: '지정된 rowIndex 의 수정상태(GridState) 를 변경합니다.'
    },
    {
        name: 'addRow',
        type: CommonType.function,
        parameters: [{
            name: 'rowIndex',
            type: CommonType.number,
            description: '지정하면 해당 rowIndex 에 insert 됩니다.'
        }, {
            name: 'state',
            type: toEnumType(GridState).filter(item => item.enumName !== 'deleted'),
            description: '지정하면 empty 가 아닌 다른 state 를 지정합니다.'
        }],
        optional: true,
        description: '새로운 Row 를 추가합니다.'
    },
    {
        name: 'removeRow',
        type: CommonType.function,
        parameters: [{
            name: 'rowIndex',
            type: CommonType.number
        }],
        optional: true,
        description: '지정한 rowIndex 의 Row 를 삭제합니다.'
    },
    {
        name: 'removeRows',
        type: CommonType.function,
        parameters: [{
            name: 'rowIndexes',
            type: 'number[]'
        }],
        optional: true,
        description: 'rowIndex 배열에 해당하는 Row 를 삭제합니다.'
    },
    {
        name: 'getSelection',
        type: CommonType.function,
        result: CommonType.number,
        optional: true,
        description: '현재 선택된 rowIndex 를 리턴합니다.'
    },
    {
        name: 'setSelection',
        type: CommonType.function,
        parameters: [{
            name: 'rowIndex',
            type: CommonType.number
        }],
        optional: true,
        description: '지정한 rowIndex 를 선택(Select) 합니다.'
    },
    {
        name: 'getCheck',
        type: CommonType.function,
        parameters: [{
            name: 'rowIndex',
            type: CommonType.number
        }],
        result: CommonType.boolean,
        optional: true,
        description: '지정한 rowIndex 가 체크되었는지 여부를 리턴합니다.'
    },
    {
        name: 'getHeadCheck',
        type: CommonType.function,
        result: CommonType.boolean,
        optional: true,
        description: '헤더가 체크되었는지 여부를 리턴합니다.'
    },
    {
        name: 'setCheck',
        type: CommonType.function,
        parameters: [{
            name: 'rowIndex',
            type: CommonType.number
        }, {
            name: 'checked',
            type: CommonType.boolean
        }],
        optional: true,
        description: '지정된 rowIndex 의 체크여부를 지정합니다.'
    },
    {
        name: 'getCheckedRows',
        type: CommonType.function,
        result: 'any[]',
        optional: true,
        description: '체크된 Row만 리턴합니다. getRows({checkedOnly: true}) 와 같습니다.'
    },
    {
        name: 'getCheckedIndexes',
        type: CommonType.function,
        result: 'number[]',
        optional: true,
        description: '체크된 Row의 index 만 리턴합니다. getRowIndexes({checkedOnly: true}) 와 같습니다.'
    },
    {
        name: 'hasFocus',
        type: CommonType.function,
        result: CommonType.boolean,
        optional: true,
        description: '카드리스트에 포커스입력 여부를 리턴합니다.'
    },
    {
        name: 'searchData',
        type: CommonType.function,
        parameters: [{
            name: 'startIndex',
            type: CommonType.number
        }, {
            name: 'columnName',
            type: CommonType.string
        }, {
            name: 'value',
            type: CommonType.any
        }, {
            name: 'selectSearchedRow',
            type: CommonType.boolean,
            description: '검색된 경우 해당 Row 를 선택할지 지정합니다. 기본은 true 입니다.'
        }],
        result: {
            columnName: { type: CommonType.string },
            rowIndex: { type: CommonType.number }
        },
        optional: true,
        description: 'startIndex 서부터 columnName 에 해당하는 필드값과 주어진 value 가 같은지 검색하여 결과를 리턴합니다. 검색된 항목이 없는 경우 null 을 리턴합니다.'
    },
    {
        name: 'setGridOption',
        type: CommonType.function,
        parameters: { name: 'options', type: 'constructor 의 options' },
        optional: true,
        description: '변경하고 싶은 그리드 옵션 값을 재설정합니다.'
    },
    {
        name: 'onBeforeChangeRowSelection',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: {
                target: { type: 'OBTCardListInterface' },
                oldRowIndex: { type: CommonType.number, description: '이전 선택 RowIndex' },
                rowIndex: { type: CommonType.number, description: '선택된 RowIndex' },
                cancel: { type: CommonType.boolean, description: 'true 로 지정시 Row 변경이 취소됩니다.' }
            }
        },
        optional: true,
        description: '선택된 RowIndex 가 변경되기 전 이벤트입니다. cancel = true 로 지정시 변경이 취소됩니다.'
    },
    {
        name: 'onBeforeAddRow',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: {
                target: { type: 'OBTCardListInterface' },
                data: { type: CommonType.any, description: '추가된 Row 에 기본 지정될 데이터' },
                cancel: { type: CommonType.boolean, description: 'true 로 지정시 추가가 취소됩니다.' }
            }
        },
        optional: true,
        description: '새로운 Row 를 추가하기 전 호출됩니다. data 에 기본값을 지정할 수 있으며 cancel = true 지정시 추가가 취소됩니다.'
    },
    {
        name: 'onAfterAddRow',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: {
                target: { type: 'OBTCardListInterface' },
                rowIndex: { type: CommonType.number, description: '추가된 RowIndex' },
                data: { type: CommonType.any, description: '추가된 Row 의 데이터' }
            }
        },
        optional: true,
        description: '새로운 Row 가 추가된 후 호출됩니다.'
    },
    {
        name: 'onBeforeRemoveRow',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: {
                target: { type: 'OBTCardListInterface' },
                rowIndex: { type: CommonType.number, description: '삭제될 RowIndex' },
                data: { type: CommonType.any, description: '삭제될 Row 의 데이터' },
                cancel: { type: CommonType.boolean, description: 'true 로 지정시 삭제가 취소됩니다.' }
            }
        },
        optional: true,
        description: 'Row 가 삭제되기 전 호출됩니다. cancel = true 로 지정시 삭제가 취소됩니다.'
    },
    {
        name: 'onAfterRemoveRow',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: {
                target: { type: 'OBTCardListInterface' },
                rowIndex: { type: CommonType.number, description: '삭제될 RowIndex' },
                data: { type: CommonType.any, description: '삭제될 Row 의 데이터' }
            }
        },
        optional: true,
        description: 'Row 가 삭제된 후 호출됩니다.'
    },
    {
        name: 'onBeforeChange',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: {
                target: { type: 'OBTCardListInterface' },
                rowIndex: { type: CommonType.number, description: '변경될 RowIndex' },
                columnName: { type: CommonType.string, description: '변경될 필드(컬럼)명' },
                values: { type: CommonType.any, description: '변경전 데이터' },
                cancel: { type: CommonType.boolean, description: 'true 로 지정시 변경이 취소됩니다.' }
            }
        },
        optional: true,
        description: 'Row 데이터가 변경되기 전 호출됩니다. cancel = true 로 지정시 변경이 취소됩니다.'
    },
    {
        name: 'onValidateChange',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: {
                target: { type: 'OBTCardListInterface' },
                rowIndex: { type: CommonType.number, description: '변경될 RowIndex' },
                columnName: { type: CommonType.string, description: '변경될 필드(컬럼)명' },
                oldValue: { type: CommonType.any, description: '변경전 데이터' },
                newValue: { type: CommonType.any, description: '변경후 데이터' },
                values: { type: CommonType.any, description: '변경전 데이터(Row전체)' },
                cancel: { type: CommonType.boolean, description: 'true 로 지정시 변경이 취소됩니다.' }
            }
        },
        optional: true,
        description: '데이터 변경시 유효성검사가 요구될 때 호출됩니다. cancel = true 로 지정시 변경이 취소됩니다.'
    },
    {
        name: 'onAfterChange',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: {
                target: { type: 'OBTCardListInterface' },
                rowIndex: { type: CommonType.number, description: '변경될 RowIndex' },
                columnName: { type: CommonType.string, description: '변경될 필드(컬럼)명' },
                oldValue: { type: CommonType.any, description: '변경전 데이터' },
                newValue: { type: CommonType.any, description: '변경후 데이터' },
                values: { type: CommonType.any, description: '변경후 데이터' }
            }
        },
        optional: true,
        description: 'Row 데이터가 변경된 후 호출됩니다.'
    },
    {
        name: 'onAfterCheck',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: {
                target: { type: 'OBTCardListInterface' },
                rowIndex: { type: CommonType.number, description: '변경될 RowIndex' },
                checked: { type: CommonType.any, description: '체크 여부' }
            }
        },
        optional: true,
        description: 'Row 의 체크여부가 변경되었을 떄 호출됩니다.'
    },
    {
        name: 'onAfterHeaderCheck',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: {
                target: { type: 'OBTCardListInterface' },
                checked: { type: CommonType.any, description: '체크 여부' }
            }
        },
        optional: true,
        description: '헤더의 체크여부가 변경되었을 떄 호출됩니다.'
    },
    {
        name: 'onAfterRead',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: {
                target: { type: 'OBTCardListInterface' },
                data: { type: 'any[]', description: '조회된 데이터' }
            }
        },
        optional: true,
        description: '카드리스트 조회 이후 호출됩니다.'
    },
    {
        name: 'onAfterDataChanged',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: {
                target: { type: 'OBTCardListInterface' }
            }
        },
        optional: true,
        description: '카드리스트에 바인딩 된 데이터가 변경(setValue, clearData 등) 된 경우 호출됩니다.'
    },
    {
        name: 'onDrawer',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: {
                target: { type: 'OBTCardListInterface' },
                checkedRows: { type: 'any[]', description: '체크된 Row 데이터 목록' }
            }
        },
        optional: true,
        description: '카드리스트에 연결된 Drawer(OBTPageContainer 참고)를 변경해야 할 경우 호출됩니다.'
    },
    {
        name: 'onAfterStateChange',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: {
                target: { type: 'OBTCardListInterface' },
                state: { type: ['none', 'empty', 'added', 'modified', 'deleted'], description: '수정상태' }
            }
        },
        optional: true,
        description: 'Row의 수정상태(GridState) 가 변경된 경우 호출됩니다.'
    },
    {
        name: 'onBeforeSortChange',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: {
                target: { type: 'OBTCardListInterface' },
                data: {
                    value: { type: CommonType.string },
                    columnName: { type: CommonType.string },
                    text: { type: CommonType.string }
                },
                cancel: { type: CommonType.boolean, description: 'true 로 지정시 변경이 취소됩니다.' },
                handled: { type: CommonType.boolean, description: 'true 로 지정시 사용자가 정렬작업을 수행하였으므로 따로 sort 처리하지 않습니다.' }
            }
        },
        optional: true,
        description: '정렬이 변경되어 정렬을 수행하기 전 호출됩니다. cancel = true 로 지정하면 정렬변경 자체가 취소되며, handled = true 로 지정하면 사용자가 직접 정렬을 수행하였으므로 내부 정렬기능을 수행하지 않습니다.'
    },
    {
        name: 'onAfterSortChange',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: {
                target: { type: 'OBTCardListInterface' },
                data: {
                    value: { type: CommonType.string },
                    columnName: { type: CommonType.string },
                    text: { type: CommonType.string }
                }
            }
        },
        optional: true,
        description: '정렬이 변경된 후 호출됩니다.'
    },
    {
        name: 'onAddButtonClicked',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: {
                target: { type: 'OBTCardListInterface' }
            }
        },
        optional: true,
        description: '추가버튼이 클릭된 경우 호출됩니다.'
    },
    {
        name: 'onItemClick',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: {
                target: { type: 'OBTCardListInterface' },
                rowIndex: { type: CommonType.number, description: '클릭된 RowIndex' }
            }
        },
        optional: true,
        description: 'Row 가 클릭 된 경우 호출됩니다. 선택(Select) 과 호출시점이 다르므로 혼용하지 않도록 주의가 필요합니다.'
    },
    {
        name: 'onItemDoubleClick',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: {
                target: { type: 'OBTCardListInterface' },
                rowIndex: { type: CommonType.number, description: '더블클릭된 RowIndex' }
            }
        },
        optional: true,
        description: 'Row 가 더블클릭 된 경우 호출됩니다.'
    },
    {
        name: 'onDisplayEmptySet',
        type: CommonType.function,
        parameters: {
            name: 'e',
            type: '{\n  type: emptyData | emptySearch | emptyWrite,\n  emptySet: { image: string, msg: string } }'
        },
        optional: true,
        description: 'EmptySet(데이터가 없는 경우 표시하는 아이콘, 문구) 이 표시되어야 할때 호출되며, emptySet 내의 값을 변경하면 표시되는 이미지나 문구를 변경할 수 있습니다.'
    },
);