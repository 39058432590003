import React, { Fragment } from 'react'
import { Events, Util } from '../Common';
import { OBTButton, OBTSplitButton } from '../index';
import Images from './Images';
import { IPageAuthority } from './OBTPageContainer';
import { ButtonTheme, Icon } from '../OBTButton/OBTButton'
import { OrbitInternalLangPack } from '../Common/Util';
const styles = require('./OBTPageContainer.module.scss');

export enum MainButtonType {
    'primary' = 'primary',
    'secondary' = 'secondary',
    'system' = 'system',
    'default' = 'default'
}

export interface IMainButton {
    key: string,
    type?: MainButtonType,
    labelText?: string,
    icon?: Node,
    imageUrl?: string | Icon,
    primary?: boolean,
    secondary?: boolean,
    disabled?: boolean,
    visible?: boolean,
    children?: IMainButton[],
    component?: any,
    theme?: ButtonTheme,
    useMouseOverBackground: boolean,
    onClick?: (e: Events.MouseEventArgs) => void,
    rootClassName?: string,
    rootStyle?: React.CSSProperties
}

interface IMainButtons {
    owner: any,
    isErpModule: boolean,
    onBookmarkClick?: any,
    menuItem?: any,
    mainButtons?: IMainButton[] | null,
    pageAuthority?: IPageAuthority,
    hasDeleteHandler?: boolean,
    hasPrintHandler?: boolean,
    hideFavoriteButton?: boolean
}

interface IState {
    oldProps: IMainButtons,
    optionPanelCollapsed: boolean,
    helpPanelCollapsed: boolean,
    systemPanelCollapsed: boolean,
    mainButtons?: IMainButton[] | null,
    imageUrls: {
        print: {
            normal: string,
            over: string,
        },
        delete: {
            normal: string,
            over: string,
        },
        favorite: {
            normal: string,
            over: string,
        } | string
    },
    deleteButtonDisabled: boolean,
    printButtonDisabled: boolean,
    deleteButtonVisible: boolean,
    printButtonVisible: boolean,
}

export default class MainButtons extends React.Component<IMainButtons, IState> {
    state: IState = {
        oldProps: this.props,
        optionPanelCollapsed: true,
        helpPanelCollapsed: true,
        systemPanelCollapsed: true,
        mainButtons: this.props.mainButtons,
        imageUrls: {
            print: {
                normal: Images.IconFuncPrintNormal,
                over: Images.IconFuncPrintHover,
            },
            delete: {
                normal: Images.IconFuncDelNormal,
                over: Images.IconFuncDelHover,
            },
            favorite: {
                normal: Images.IconFuncFavoriteOff,
                over: Images.IconFuncFavoriteHover,
            },
        },
        deleteButtonDisabled: false,
        printButtonDisabled: false,
        deleteButtonVisible: true,
        printButtonVisible: true,
    }

    myRefs = {
        functionButton: React.createRef<HTMLDivElement>(),
        functionPanel: React.createRef<HTMLDivElement>(),
        helpButton: React.createRef<HTMLDivElement>(),
        helpPanel: React.createRef<HTMLDivElement>(),
        systemButton: React.createRef<HTMLDivElement>(),
        systemPanel: React.createRef<HTMLDivElement>()
    }

    static getDerivedStateFromProps(nextProps: IMainButtons, prevState: IState): any {
        if (!prevState || nextProps.mainButtons !== prevState.oldProps.mainButtons) {
            return {
                oldProps: nextProps,
                mainButtons: nextProps.mainButtons
            };
        }
        return null;
    }

    static getMainButtons(mainButtons?: IMainButton[] | null): IMainButton[] | null | undefined {
        return mainButtons && mainButtons.length > 0 ?
            mainButtons.map((mainButton) => {
                switch (mainButton.key) {
                    case 'systemHelp':
                        return Object.assign({}, mainButton, {
                            type: MainButtonType.system,
                            imageUrl: {
                                normal: Images.IconFuncHelpNormal,
                                over: Images.IconFuncHelpHover
                            }  //iconFuncHelpNormal
                        });
                    default:
                        return mainButton;
                }
            }).filter((mainButton) => mainButton.visible !== false) : mainButtons;
    }

    public handleFunctionPanelCollapse(e: React.MouseEvent) {
        // if (!this.state.optionPanelCollapsed) {
        //     let boundaries: (ClientRect | DOMRect)[] = [];
        //     if (this.myRefs.functionButton.current) {
        //         boundaries.push(this.myRefs.functionButton.current.getBoundingClientRect());
        //     }
        //     if (this.myRefs.functionPanel.current) {
        //         boundaries.push(this.myRefs.functionPanel.current.getBoundingClientRect());
        //     }
        //     let collapsed = boundaries.find((boundary) => {
        //         if (boundary.left <= e.clientX && boundary.right > e.clientX &&
        //             boundary.top <= e.clientY && boundary.bottom > e.clientY) return true;
        //         return false;
        //     }) ? false : true;
        //     if (this.state.optionPanelCollapsed !== collapsed) {
        //         this.setState({
        //             optionPanelCollapsed: collapsed
        //         });
        //     }
        // }
    }

    public handleSystemPanelCollapse(e: React.MouseEvent) {
        if (!this.state.systemPanelCollapsed) {
            let boundaries: (ClientRect | DOMRect)[] = [];
            if (this.myRefs.systemButton.current) {
                boundaries.push(this.myRefs.systemButton.current.getBoundingClientRect());
            }
            if (this.myRefs.systemPanel.current) {
                boundaries.push(this.myRefs.systemPanel.current.getBoundingClientRect());
            }
            let collapsed = boundaries.find((boundary) => {
                if (boundary.left <= e.clientX && boundary.right > e.clientX &&
                    boundary.top <= e.clientY && boundary.bottom > e.clientY) return true;
                return false;
            }) ? false : true;
            if (this.state.systemPanelCollapsed !== collapsed) {
                this.setState({
                    systemPanelCollapsed: collapsed
                });
            }
        }
    }

    public handleHelpPanelCollapse(e: React.MouseEvent) {
        if (!this.state.helpPanelCollapsed) {
            let boundaries: (ClientRect | DOMRect)[] = [];
            if (this.myRefs.helpButton.current) {
                boundaries.push(this.myRefs.helpButton.current.getBoundingClientRect());
            }
            if (this.myRefs.helpPanel.current) {
                boundaries.push(this.myRefs.helpPanel.current.getBoundingClientRect());
            }
            let collapsed = boundaries.find((boundary) => {
                if (boundary.left <= e.clientX && boundary.right > e.clientX &&
                    boundary.top <= e.clientY && boundary.bottom > e.clientY) return true;
                return false;
            }) ? false : true;
            if (this.state.helpPanelCollapsed !== collapsed) {
                this.setState({
                    helpPanelCollapsed: collapsed
                });
            }
        }
    }

    public collapse() {
        // if (!this.state.optionPanelCollapsed) {
        //     this.setState({
        //         optionPanelCollapsed: true
        //     })
        // }

        if (!this.state.helpPanelCollapsed) {
            this.setState({
                helpPanelCollapsed: true
            })
        }

        if (!this.state.systemPanelCollapsed) {
            this.setState({
                systemPanelCollapsed: true
            })
        }
    }

    public getDeleteButtonDisabled() {
        return this.state.deleteButtonDisabled;
    }

    public setDeleteButtonDisabled(disabled: boolean) {
        if (disabled === false && this.props.pageAuthority && this.props.pageAuthority.deleteAuthYn === "N") {
            return Promise.resolve();
        }

        return new Promise<void>(resolve => {
            this.setState({
                deleteButtonDisabled: disabled,
            }, () => {
                resolve();
            });
        })
    }

    public getPrintButtonDisabled() {
        return this.state.printButtonDisabled;
    }

    public setPrintButtonDisabled(disabled: boolean) {
        if (disabled === false && this.props.pageAuthority && this.props.pageAuthority.printAuthYn === "N") {
            return Promise.resolve();
        }

        return new Promise<void>(resolve => {
            this.setState({
                printButtonDisabled: disabled,
            }, () => {
                resolve();
            });
        })
    }

    public setDeleteButtonVisible(visible: boolean) {
        if (visible === true && this.props.pageAuthority && this.props.pageAuthority.deleteAuthYn === "N") {
            return Promise.resolve();
        }

        return new Promise<void>(resolve => {
            this.setState({
                deleteButtonVisible: visible,
            }, () => {
                resolve();
            });
        })
    }

    public setPrintButtonVisible(visible: boolean) {
        if (visible === true && this.props.pageAuthority && this.props.pageAuthority.printAuthYn === "N") {
            return Promise.resolve();
        }

        return new Promise<void>(resolve => {
            this.setState({
                printButtonVisible: visible,
            }, () => {
                resolve();
            });
        })
    }

    handleFunctionButtonClick(functionButton: IMainButton, e: React.MouseEvent) {
        if (!this.state.optionPanelCollapsed) {
            this.setState({
                optionPanelCollapsed: true
            });
        }
        if (functionButton.onClick) {
            functionButton.onClick(new Events.MouseEventArgs(this, e));
        }
    }

    public setMainButtons(mainButtons: IMainButton[]) {
        this.setState({
            mainButtons: mainButtons
        });
    }

    public onClickPrintButton = () => {
        if (this.props.owner) {
            this.props.owner.handlePrint({ target: 'mainButtons' });
        }
    }

    public onClickDeleteButton = () => {
        if (this.props.owner) {
            this.props.owner.handleDelete({ target: 'mainButtons' });
        }
    }

    private get printButtonDisabled() {
        let printButtonDisabled = this.state.printButtonDisabled;
        if (printButtonDisabled === false && this.props.pageAuthority && this.props.pageAuthority.printAuthYn === "N") {
            printButtonDisabled = true;
        }
        return printButtonDisabled;
    }

    private get deleteButtonDisabled() {
        let deleteButtonDisabled = this.state.deleteButtonDisabled;
        if (deleteButtonDisabled === false && this.props.pageAuthority && this.props.pageAuthority.deleteAuthYn === "N") {
            deleteButtonDisabled = true;
        }
        return deleteButtonDisabled;
    }

    private get printButtonVisible() {
        if (!this.props.hasPrintHandler) return false;
        let printButtonVisible = this.state.printButtonVisible;
        if (printButtonVisible === true && this.props.pageAuthority && this.props.pageAuthority.printAuthYn === "N") {
            printButtonVisible = false;
        }

        return printButtonVisible;
    }

    private get deleteButtonVisible() {
        if (!this.props.hasDeleteHandler) return false;
        let deleteButtonVisible = this.state.deleteButtonVisible;
        if (deleteButtonVisible === true && this.props.pageAuthority && this.props.pageAuthority.deleteAuthYn === "N") {
            deleteButtonVisible = false;
        }
        return deleteButtonVisible;
    }

    private handleSecondaryOptionalOpenClick = () => {
        if (this.state.optionPanelCollapsed === true) {
            this.setState({ optionPanelCollapsed: false }, () => {
                const closeOptionPanel = (e: MouseEvent) => {
                    const contains = (element: HTMLElement) => {
                        if (element && element.parentElement === this.myRefs.functionButton.current) {
                            return true;
                        } else if (element && element.parentElement) {
                            return contains(element.parentElement);
                        } else if (element.parentElement === document.body) {
                            return false;
                        }
                        return false;
                    }
                    window.removeEventListener('click', closeOptionPanel, true);
                    if (!contains(e.target as HTMLElement)) {
                        this.setState({ optionPanelCollapsed: true });
                    }
                };
                window.addEventListener('click', closeOptionPanel, true);
            });
        } else {
            this.setState({
                optionPanelCollapsed: true
            });
        }
    }

    render() {
        let mainButtons: IMainButton[] = this.state.mainButtons || [];
        const getButtons = (buttons?: IMainButton[] | null, marginLeft?: number) => {
            return buttons && buttons.length > 0 ? <>
                {marginLeft ? <div /> : undefined}
                {buttons.map((item) => {
                    let component = item.component;
                    if (!component) {
                        const children = item.children ? item.children.filter(child => child.visible !== false) : item.children;
                        if (children && children.length > 0) {
                            const splitValues = [
                                {
                                    key: item.key,
                                    labelText: item.imageUrl && typeof item.imageUrl === 'string' ? <div className={styles.mainButtonSplit}>
                                        <img className={styles.mainButtonSplitImg} src={item.imageUrl} alt={''} />
                                        <span>{item.labelText || ''}</span>
                                    </div> : item.labelText || ''
                                }
                            ].concat(children.map(child => { return { key: child.key, labelText: child.labelText || '' }; }));

                            component = (
                                <div key={item.key} className={styles.primaryFunctionSplitButton}>
                                    <OBTSplitButton
                                        value={splitValues}
                                        type={OBTSplitButton.Type.default}
                                        motionType={OBTSplitButton.MotionType.dropDown}
                                        disabled={item.disabled}
                                        onClick={(e) => {
                                            const { key } = e;
                                            if (key) {
                                                const child = children.find(child => child.key === key) || item;
                                                this.handleFunctionButtonClick(child, e.event);
                                            }
                                        }}
                                    />
                                </div>
                            )
                        } else {
                            component = (
                                <OBTButton key={item.key}
                                    className={styles.primaryFunctionButton}
                                    type={OBTButton.Type.default}
                                    icon={item.icon}
                                    imageUrl={item.imageUrl}
                                    labelText={item.labelText}
                                    disabled={item.disabled}
                                    theme={item.theme}
                                    onClick={(e) => this.handleFunctionButtonClick(item, e.event)}
                                />
                            );
                        }
                    } else {
                        component = <div key={item.key} style={item.rootStyle} className={item.rootClassName}>{component}</div>
                    }
                    return component;
                })}
                <div className={styles.splitter} />
            </> : <></>;
        }

        const functionButtons = MainButtons.getMainButtons(mainButtons);
        const primary = functionButtons ? functionButtons.filter(item => {
            if (item.type !== undefined) {
                return item.type === MainButtonType.primary;
            } else {
                return item.primary === true;
            }
        }) : null;

        const primaryButtons = getButtons(primary);

        const optional = functionButtons ? functionButtons.filter(item => {
            if (item.type !== undefined) {
                return item.type === MainButtonType.default;
            } else {
                return item.primary !== true && item.secondary !== true
            }
        }) : null;

        const secondary = functionButtons ? functionButtons.filter(item => {
            if (item.type !== undefined) {
                return item.type === MainButtonType.secondary;
            } else {
                return item.secondary === true;
            }
        }) : null;

        const secondaryButtons = getButtons(
            [optional && optional.length > 0 ? {
                key: '',
                type: MainButtonType.secondary,
                component:
                    <div className={Util.getClassNames(styles.functionButton, this.state.optionPanelCollapsed ? styles.collapsed : styles.show)}
                        ref={this.myRefs.functionButton}>
                        <OBTButton labelText={<div className={styles.mainButtonSplit}>
                            <img className={styles.mainButtonSplitImg} src={Images.IconFuncCmdNormal} alt={''} />
                            <span>{OrbitInternalLangPack.getText("WE000029963", '기능모음')}</span>
                            <img className={styles.mainButtonSplitDrop} src={Images.ImageOptionalArrow} alt={''} />
                        </div> as any} onClick={this.handleSecondaryOptionalOpenClick} />
                        <div className={styles.functionPanelWrapper} ref={this.myRefs.functionPanel}>
                            <div className={styles.functionPanel}>
                                {optional.map(item => {
                                    let component = item.component;
                                    if (!component) {
                                        const children = item.children ? item.children.filter(child => child.visible !== false) : item.children;
                                        const props: any = {
                                            className: styles.function
                                        };
                                        if (item.onClick) {
                                            props.className = Util.getClassNames(props.className, styles.button, item.disabled === true ? styles.disabled : null);
                                            props.onClick = (e: React.MouseEvent) => {
                                                if (item.disabled !== true)
                                                    this.handleFunctionButtonClick(item, e)
                                            };
                                        }

                                        component = <Fragment key={item.key}>
                                            <div key={item.key} {...props}>{item.labelText}</div>
                                            {children && children.length > 0 ?
                                                children.map(child => {
                                                    const props: any = {
                                                        className: Util.getClassNames(styles.function, styles.child)
                                                    };
                                                    if (child.onClick) {
                                                        props.className = Util.getClassNames(props.className, styles.button, item.disabled === true || child.disabled ? styles.disabled : null);
                                                        props.onClick = (e: React.MouseEvent) => {
                                                            if (item.disabled !== true && child.disabled !== true)
                                                                this.handleFunctionButtonClick(child, e)
                                                        };
                                                    }
                                                    return <div key={child.key} {...props}>{child.labelText}</div>
                                                })
                                                : <></>}
                                        </Fragment>;
                                    } else {
                                        component = <div key={item.key}>{component}</div>
                                    }
                                    return component;
                                })}
                            </div>
                        </div>
                    </div>
            } as IMainButton : undefined].concat(secondary ? secondary.map(button => {
                switch (button.key) {
                    case 'approval':
                        return {
                            labelText: OrbitInternalLangPack.getText("WE000004709", '결재상신'),
                            imageUrl: Images.IconAprovalNormal,
                            ...button
                        };
                }
                return button;
            }) : []).filter(i => i) as IMainButton[], primaryButtons ? 1 : 0);

        const system = functionButtons ? functionButtons.filter(item => item.type === MainButtonType.system) : null;
        const systemButtons = system && system.length > 0 ? <>
            {system.map((item) => {
                let component = item.component;
                if (!component) {
                    if (item.key === 'systemHelp') {
                        component = (
                            <div
                                key={item.key}
                                className={Util.getClassNames(styles.functionButton, this.state.helpPanelCollapsed ? styles.collapsed : null)}
                                ref={this.myRefs.helpButton}
                                onMouseOver={() => {
                                    if (this.state.helpPanelCollapsed === true) {
                                        this.setState({ helpPanelCollapsed: false });
                                    }
                                }}
                            >
                                <OBTButton
                                    key={item.key}
                                    className={Util.getClassNames(styles.systemButton, styles.backgroundColorNone)}
                                    type={OBTButton.Type.icon}
                                    useMouseOverBackground={item.useMouseOverBackground}
                                    imageUrl={item.imageUrl}
                                    icon={item.icon}
                                    // disabled={item.disabled}
                                    width='25px' height='25px'
                                />
                                <div className={styles.functionPanelWrapper}
                                    style={{
                                        width: '116px',
                                        right: !this.deleteButtonVisible || !this.printButtonVisible ? '0px' : undefined,
                                    }}
                                    ref={this.myRefs.helpPanel}
                                >
                                    <div className={styles.functionPanel}>
                                        <div
                                            key={"officeManual"}
                                            className={Util.getClassNames(
                                                styles.function,
                                                styles.button,
                                                !this.props.menuItem["oneUrl"] && styles.disabled,
                                            )}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center'
                                            }}
                                            onClick={(e) => {
                                                if (!this.props.menuItem["oneUrl"]) return;
                                                window.open(this.props.menuItem["oneUrl"]);
                                            }}>
                                            <img
                                                src={Images.IconOneUrlNormal}
                                                style={{ width: '14px', height: '14px', marginRight: '5px' }}
                                                alt={''}
                                            />
                                            <div>{OrbitInternalLangPack.getText('ER000004187', '사내매뉴얼')}</div>
                                        </div>
                                        {
                                            item.children && item.children.length > 0 && item.children.map(buttonItem => {
                                                return <div key={buttonItem.key}
                                                    className={Util.getClassNames(styles.function, styles.button, buttonItem.disabled === true ? styles.disabled : null)}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center'
                                                    }}
                                                    onClick={(e) => {
                                                        if (buttonItem.onClick) {
                                                            buttonItem.onClick(new Events.MouseEventArgs(this, e));
                                                        }
                                                    }}>
                                                        {buttonItem.imageUrl &&
                                                            <img
                                                                src={typeof buttonItem.imageUrl === 'string' ? buttonItem.imageUrl : undefined}
                                                                style={{ width: '14px', height: '14px', marginRight: '5px', border: 'none' }}
                                                                alt={''}
                                                            />
                                                        }
                                                        <div>{buttonItem.labelText}</div>
                                                </div>
                                            }) 
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    else if (item.key !== 'systemHelp' && item.children) {
                        const children = item.children ? item.children.filter(child => child.visible !== false) : item.children;

                        component = (
                            <div key={item.key}
                                className={Util.getClassNames(styles.functionButton, this.state.systemPanelCollapsed ? styles.collapsed : null)}
                                ref={this.myRefs.systemButton}
                                onMouseOver={() => {
                                    if (this.state.systemPanelCollapsed === true) {
                                        this.setState({ systemPanelCollapsed: false });
                                    }
                                }}
                            >
                                <OBTButton
                                    key={item.key}
                                    className={Util.getClassNames(styles.systemButton, styles.backgroundColorNone)} type={OBTButton.Type.icon}
                                    useMouseOverBackground={item.useMouseOverBackground}
                                    imageUrl={item.imageUrl}
                                    icon={item.icon}
                                    disabled={item.disabled}
                                    width='25px' height='25px'
                                />
                                <div className={styles.functionPanelWrapper}
                                    style={{
                                        width: '216px'
                                    }}
                                    ref={this.myRefs.systemPanel}
                                >
                                    {/* children */}
                                    <div className={styles.functionPanel}>
                                        {
                                            children && children.length > 0 ? children.map((child) => {
                                                return <div
                                                    key={child.key}
                                                    className={Util.getClassNames(styles.function, styles.button, child.disabled === true ? styles.disabled : null)}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center'
                                                    }}
                                                    onClick={(e) => {
                                                        if (child.onClick) {
                                                            child.onClick(new Events.MouseEventArgs(this, e));
                                                        }
                                                    }}>
                                                    {/* {child.imageUrl ? 
                                                        <img src={child.imageUrl} style={{
                                                            width: '14px', height: '14px', marginRight: '5px'
                                                        }} />
                                                        : <></>
                                                    } */}
                                                    <div>
                                                        {
                                                            child.labelText
                                                        }
                                                    </div>
                                                </div>
                                            }) : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    else {
                        component = (
                            <OBTButton
                                tooltip={item.labelText ? {
                                    labelText: item.labelText,
                                    position: 'bottom',
                                    align: 'center'
                                } : undefined}
                                key={item.key}
                                className={Util.getClassNames(styles.systemButton)} type={OBTButton.Type.icon}
                                imageUrl={item.imageUrl} icon={item.icon} disabled={item.disabled}
                                useMouseOverBackground={item.useMouseOverBackground}
                                width='25px' height='25px'
                                onClick={item.onClick}
                            />
                        );
                    }
                }
                return component;
            })}
        </> : <></>;

        // 권한체크
        let printButtonDisabled = this.printButtonDisabled;
        const printButton = this.printButtonVisible ? (
            <OBTButton
                tooltip={{
                    labelText: OrbitInternalLangPack.getText('WE000000959', '인쇄'),
                    position: 'bottom',
                    align: 'center'
                }}
                className={Util.getClassNames(styles.systemButton, styles.backgroundColorNone)}
                type={OBTButton.Type.icon}
                imageUrl={this.state.imageUrls.print}
                width='25px' height='25px'
                disabled={printButtonDisabled}
                onClick={this.onClickPrintButton}
            />
        ) : null;

        // 권한체크
        let deleteButtonDisabled = this.deleteButtonDisabled;
        const deleteButton = this.deleteButtonVisible ? (
            <OBTButton
                tooltip={{
                    labelText: OrbitInternalLangPack.getText('WE000000249', '삭제'),
                    position: 'bottom',
                    align: 'center'
                }}
                className={Util.getClassNames(styles.systemButton, styles.backgroundColorNone)}
                type={OBTButton.Type.icon}
                imageUrl={this.state.imageUrls.delete}
                disabled={deleteButtonDisabled}
                width='25px' height='25px'
                onClick={this.onClickDeleteButton}
            />
        ) : null;

        // const functionButton =  optional && optional.length > 0 ?
        //     <div className={Util.getClassNames(styles.functionButton, this.state.optionPanelCollapsed ? styles.collapsed : null)}
        //         ref={this.myRefs.functionButton}
        //         onMouseOver={() => {
        //             if (this.state.optionPanelCollapsed === true) {
        //                 this.setState({ optionPanelCollapsed: false });
        //             }
        //         }}>
        //         <OBTButton className={styles.systemButton} type={OBTButton.Type.icon} imageUrl={this.state.imageUrls.function} width='25px' height='25px' />
        //         <div className={styles.functionPanelWrapper} ref={this.myRefs.functionPanel}>
        //             <div className={styles.functionPanel}>
        //                 {optional.map(item => {
        //                     let component = item.component;
        //                     if (!component) {
        //                         const children = item.children ? item.children.filter(child => child.visible !== false) : item.children;
        //                         const props: any = {
        //                             className: styles.function
        //                         };
        //                         if (item.onClick) {
        //                             props.className = Util.getClassNames(props.className, styles.button, item.disabled === true ? styles.disabled : null);
        //                             props.onClick = (e: React.MouseEvent) => {
        //                                 if (item.disabled !== true)
        //                                     this.handleFunctionButtonClick(item, e)
        //                             };
        //                         }

        //                         component = <Fragment key={item.key}>
        //                             <div key={item.key} {...props}>{item.labelText}</div>
        //                             {children && children.length > 0 ?
        //                                 children.map(child => {
        //                                     const props: any = {
        //                                         className: Util.getClassNames(styles.function, styles.child)
        //                                     };
        //                                     if (child.onClick) {
        //                                         props.className = Util.getClassNames(props.className, styles.button, item.disabled === true || child.disabled ? styles.disabled : null);
        //                                         props.onClick = (e: React.MouseEvent) => {
        //                                             if (item.disabled !== true && child.disabled !== true)
        //                                                 this.handleFunctionButtonClick(child, e)
        //                                         };
        //                                     }
        //                                     return <div key={child.key} {...props}>{child.labelText}</div>
        //                                 })
        //                                 : <></>}
        //                         </Fragment>;
        //                     } else {
        //                         component = <div key={item.key}>{component}</div>
        //                     }
        //                     return component;
        //                 })}
        //             </div>
        //         </div>
        //     </div>
        //     : <></>;

        const favoriteButton = (
            <OBTButton
                tooltip={{
                    labelText: OrbitInternalLangPack.getText('WE000010650', '즐겨찾기'),
                    position: 'bottom',
                    align: 'center'
                }}
                className={styles.systemButton}
                type={OBTButton.Type.icon}
                imageUrl={(this.props.menuItem || {}).bookmark ? Images.IconFuncFavoriteOn : { normal: Images.IconFuncFavoriteOff, over: Images.IconFuncFavoriteHover, press: Images.IconFuncFavoriteHover }}
                width='25px'
                height='25px'
                onClick={() => {
                    if (this.props.onBookmarkClick && typeof this.props.onBookmarkClick === 'function') {
                        this.props.onBookmarkClick(this.props.menuItem);
                    }
                }}
            />
        );

        return (
            <>
                {primaryButtons}
                {secondaryButtons}
                {systemButtons}
                {printButton}
                {deleteButton}
                {/* {functionButton} */}
                {this.props.hideFavoriteButton ? <></> : favoriteButton}
            </>
        );
    }

    public getButton(key: string) {
        switch (key) {
            case 'delete':
                return {
                    key: 'delete',
                    visible: true,
                    disabled: this.deleteButtonDisabled
                }
            case 'print':
                return {
                    key: 'print',
                    visible: true,
                    disabled: this.printButtonDisabled
                }
        }
        return undefined;
    }
}
