/**
 * OBTScrollbar
 * @version 0.1
 * @author 전주빈
 * @see common.js
 */
import * as React from 'react';
import { Util, Events, CommonProps, createPropDefinitions, CommonDefinitions, CommonType } from '../Common';
import { Scrollbars } from 'react-custom-scrollbars';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const styles = require('./OBTScrollbar.module.scss');

interface IOBScrollbar extends CommonProps.className, CommonProps.width, CommonProps.height {
    /**
     * 스크롤을 움직이면 발생하는 Callback 함수입니다.
     */
    onScroll?: (e: Events.EventArgs) => void,
    /**
     * 스크롤의 프레임의 설정값을 가지오는 Callback 함수입니다.
     */
    onScrollFrame?: (e: Events.ChangeEventArgs<object>) => void,
    /**
     * @default true
     * true (스크롤 보임) | false (스크롤 숨김)
     */
    visible?: boolean
}

interface State extends hasError {
}

export default class OBTScrollbar extends React.Component<IOBScrollbar, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.className(),
        CommonDefinitions.width(),
        CommonDefinitions.height(),
        { name: "visible", type: CommonType.boolean, description: "컴포넌트에서 스크롤을 숨기는 속성입니다.", optional: true, default: true },
        {
            name: "onScroll", type: CommonType.function, description: "스크롤을 움직이면 발생하는 Callback 함수입니다.", optional: true, parameters: {
                name: "e",
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' }
                }
            }
        },
        {
            name: "onScrollFrame", type: CommonType.function, description: "스크롤의 프레임의 설정값을 가져오는 Callback 함수입니다.", optional: true, parameters: {
                name: "e",
                type: {
                    value: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' }
                }
            }
        },
        { name: "element", type: CommonType.any, description: "element함수를 사용하여 HTMLDivElement에 내장되어있는 함수를 호출할 수 있습니다.", optional: true }
    );

    public static defaultProps = {
        visible: true
    }

    public state: State = {
    }

    public get element() { return this.myRefs.root.current; }

    public myRefs = {
        root: React.createRef<Scrollbars>()
    }

    /**
     * scrollTop 위치 값을 가져온다.
     */
    public get scrollTop() {
        return this.myRefs.root.current ? this.myRefs.root.current.getScrollTop() : undefined;
    }

    /**
     * top이 0일 경우 최상위 값으로 스크롤 한다.
     */
    public set scrollTop(top: number) {
        if (this.myRefs.root.current) {
            this.myRefs.root.current.scrollTop(top);
        }
    }

    /**
     * scrollLeft 위치 값을 가져온다.
     */
    public get scrollLeft() {
        return this.myRefs.root.current ? this.myRefs.root.current.getScrollLeft() : undefined;
    }

    /**
     * 왼쪽 값으로 스크롤 한다.
     */
    public set scrollLeft(left: number) {
        if (this.myRefs.root.current) {
            this.myRefs.root.current.scrollLeft(left);
        }
    }

    public get scrollWidth() {
        return this.myRefs.root.current ? this.myRefs.root.current.getScrollWidth() : undefined;
    }

    public get scrollHeight() {
        return this.myRefs.root.current ? this.myRefs.root.current.getScrollHeight() : undefined;
    }

    public get clientHeight() {
        return this.myRefs.root.current ? this.myRefs.root.current.getClientHeight() : undefined;
    }

    public get clientWidth() {
        return this.myRefs.root.current ? this.myRefs.root.current.getClientWidth() : undefined;
    }

    /**
     * 맨 위로 스크롤
     */
    public scrollToTop(): void {
        if (this.myRefs.root.current) {
            return this.myRefs.root.current.scrollToTop();
        }
    }

    /**
     * 맨 아래로 스크롤
     */
    public scrollToBottom(): void {
        if (this.myRefs.root.current) {
            return this.myRefs.root.current.scrollToBottom();
        }
    }

    /**
     * 왼쪽으로 스크롤
     */
    public scrollToLeft(): void {
        if (this.myRefs.root.current) {
            return this.myRefs.root.current.scrollToLeft();
        }
    }

    /**
     * 오른쪽으로 스크롤
     */
    public scrollToRight(): void {
        if (this.myRefs.root.current) {
            return this.myRefs.root.current.scrollToRight();
        }
    }

    private handleScroll = (event: any) => {
        Util.invokeEvent<Events.EventArgs>(this.props.onScroll, new Events.EventArgs(this));
    }

    private handleScrollFrame = (value: object) => {
        Util.invokeEvent<Events.ChangeEventArgs<object>>(this.props.onScrollFrame, new Events.ChangeEventArgs(this, value));
    }


    renderComponent = () => {
        //기본 수직, 수평 div스타일
        const defaultRenderThumbHorizontal = ({ style }) => {
            const thumbStyle = {
                borderRadius: '2px',
                width: '120px',
                height: '4px',
                backgroundColor: '#b1bec4'
            };
            return <div style={{ ...style, ...thumbStyle }} />;
        };

        const defaultRenderThumbVertical = ({ style }) => {
            const thumbStyle = {
                borderRadius: '2px',
                width: '4px',
                height: '120px',
                backgroundColor: '#b1bec4'
            };
            return <div style={{ ...style, ...thumbStyle }} />;
        };

        const props: any = {
            className: Util.getClassNames(this.props.visible === false ? styles.hidden : undefined, this.props.className),
            ref: this.myRefs.root,

            //style
            style: Object.assign({}, { width: this.props.width, height: this.props.height }),
            renderThumbHorizontal: defaultRenderThumbHorizontal,
            renderThumbVertical: defaultRenderThumbVertical,
            autoHide: true,

            //event
            onScroll: this.handleScroll,
            onScrollFrame: this.handleScrollFrame
        }

        const scrollbars =
            <Scrollbars
                {...props}>
                {this.props.children}
            </Scrollbars>

        return (
            scrollbars
        )
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        );
    }
};
