import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { daysDiff } from '../UFODateField/UFODateUtils';
import LUXCalendarFrom from 'luna-rocket/LUXPeriodDatePicker/LUXCalendarFrom';
import LUXCalendarTo from 'luna-rocket/LUXPeriodDatePicker/LUXCalendarTo';
import CalendarActionButtons from './CalendarActionButtons';
import OBTFloatingPanel from '../../OBTFloatingPanel';
import { Util } from '../../Common';
import OBTDateControlButtons from '../../OBTDatePicker/OBTDateControlButtons';
import moment from 'moment'
import LUXButton from 'luna-rocket/LUXButton';
import { OrbitInternalLangPack } from '../../Common/Util';

const moduleStyles = require('./UFOPeriodDateFieldDialog.module.scss');

const styles = {
    calendar: {
        background: '#fff',
        border: '1px solid #a3a3a3',
        boxShadow: '1px 1px 1px #ececec',
        display: 'block',
        width: '430px',
        zIndex: 99999,
    },
    dialogContent: {
        width: 310,
    },
    dialogBodyContent: {
        padding: 0,
        minHeight: 206,
        minWidth: 432,
    }
};

class UFOPeriodDateFieldDialog extends Component {
    static propTypes = {
        /**
         * Popover 콤포넌트의 위치를 설정하는데 사용되는 DOM Element 정보입니다.
         */
        anchorEl: PropTypes.object,
        /**
         * 취소버튼에 들어갈 텍스트 입니다.
         */
        cancelLabel: PropTypes.string,
        /**
         * @ignore
         * 달력에서 일주일 중 첫 번째 요일을 지정합니다.
         * 범위는 0(일요일) ~ 6(토요일)이며, Default로 0(일요일)이 지정되어 있습니다.
         */
        firstDayOfWeek: PropTypes.number,
        /**
         * 시작 날짜의 초기값 입니다.
         */
        initialDateFrom: PropTypes.object,
        /**
         * 끝 날짜의 초기값 입니다.
         */
        initialDateTo: PropTypes.object,
        /**
         * 원하는 날짜 표현형식의 지역을 지정합니다.
         * ex) en: 미국, fr: 프랑스, ko: 대한민국
         */
        locale: PropTypes.string,
        /**
         * 선택 가능한 날짜에 대한 범위의 최대(끝) 날짜를 지정하는 속성입니다.
         * 기본으로 현재 날짜 +100년으로 지정되어 있습니다.
         */
        maxDate: PropTypes.object,
        /**
         * 선택 가능한 날짜에 대한 범위의 최소(시작) 날짜를 지정하는 속성입니다.
         * 기본으로 현재 날짜 -100년으로 지정되어 있습니다.
         */
        minDate: PropTypes.object,
        /**
         * 확인버튼에 들어갈 텍스트 입니다.
         */
        okLabel: PropTypes.string,
        /**
         * 날짜 선택 값이 바뀔 때 발생하는 Callback 함수입니다.
         * 선택한 값인 Date 값을 파라미터로 넘겨줍니다.
         *
         * @param {object} date `Date` value that was changed.
         */
        onAccept: PropTypes.func,
        /**
         * 날짜 선택 창이 사라질 때 호출되는 Callback 함수입니다.
         */
        onDismiss: PropTypes.func,
        /**
         * 요소에서 Focus가 이동되어야 할 시점에 발생하는 Callback 함수입니다.
         */
        onMoveFocus: PropTypes.func,
        /**
         * 날짜 선택 창이 보여질 때 호출되는 Callback 함수입니다.
         */
        onShow: PropTypes.func,
        /**
         * @ignore
         */
        open: PropTypes.bool,
        /**
         * 날짜 선택 창에서 끝 날짜가 시작 날짜보다 작을경우, 시작 날짜에서 +n 일/달/년 후의 날짜로 자동 선택됩니다.
         */
        periodNumber: PropTypes.number,
        /**
         * 날짜 선택 창에서 끝 날짜가 시작 날짜보다 작을경우, 시작 날짜에서 +n 일/달/년 후의 날짜로 자동 선택됩니다.
         */
        periodType: PropTypes.oneOf(['day', 'month', 'year']),
        /**
         * 특정 날짜에 대한 비활성화 기능을 넣기 위해 사용되는 Callback 함수입니다.
         *
         * @param {object} day Date object of a day.
         * @returns {boolean} Indicates whether the day should be disabled.
         */
        shouldDisableDate: PropTypes.func,
        /**
         *  패널의 정렬 위치를 지정합니다.
         */
        align: PropTypes.string,
        /**
         *  패널의 위치를 지정합니다.
         */
        position: PropTypes.string,
        /**
         * 상단 버튼들의 사용여부를 지정하는 속성입니다.
         */
        useControlButton: PropTypes.bool,
        /**
        *  periodPicker의 왼쪽 하단의 라벨 부분을 custom element로 쓸 때 사용합니다.
        */
        customLabel: PropTypes.func,
        /**
         * 필수여부(초기화 기능 없음)
         */
        required: PropTypes.bool
    };

    static defaultProps = {
        firstDayOfWeek: 0,
        initialDateFrom: new Date(),
        initialDateTo: new Date(),
        locale: 'ko',
        cancelLabel: OrbitInternalLangPack.getText('WE000001945', '취소'),
        okLabel: OrbitInternalLangPack.getText('WE000000054', '확인'),
        periodNumber: 0,
        periodType: 'day',
        required: false
    };

    state = {
        open: false,
        periodDays: 0,
        transition: false,
        resetFrom: false,
        resetTo: false
    };

    myRefs = {
        root: React.createRef(),
        periodDialog: React.createRef(),
        calendarFrom: React.createRef(),
        calendarTo: React.createRef(),
        actionButtons: React.createRef()
    }

    componentWillMount() {
        this.setState({
            selectedDateFrom: this.props.initialDateFrom,
            selectedDateTo: this.props.initialDateTo,
            // OBT 수정 initialDateFrom: this.props.initialDateFrom,initialDateTo: this.props.initialDateTo 추가
            initialDateFrom: this.props.initialDateFrom,
            initialDateTo: this.props.initialDateTo,
            periodDays: this.getPeriodDays(this.props.initialDateFrom, this.props.initialDateTo),

        });
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.initialDateFrom !== this.props.initialDateFrom) {
            const date = nextProps.initialDateFrom;
            this.setState({
                selectedDateFrom: date,
                //OBT 수정 initialDateFrom: date,
                initialDateFrom: date,
                periodDays: this.getPeriodDays(date, nextProps.initialDateTo),
            });
        }

        if (nextProps.initialDateTo !== this.props.initialDateTo) {
            const date = nextProps.initialDateTo;
            this.setState({
                selectedDateTo: date,
                //OBT 수정 initialDateTo: date,
                initialDateTo: date,
                periodDays: this.getPeriodDays(nextProps.initialDateFrom, date),
            });
        }
    };

    show = () => {
        if (this.props.onShow && !this.state.open) this.props.onShow();

        this.setState({
            open: true,
            periodDays: this.getPeriodDays(this.state.selectedDateFrom, this.state.selectedDateTo),

        }, () => {
            this.setState({
                transition: true
            })
        });
    };

    dismiss = () => {
        //OBT 수정 && this.state.open 추가
        if (this.props.onDismiss && this.state.open) this.props.onDismiss();

        this.setState({
            // OBT 수정  initialDateFrom: this.props.initialDateFrom, initialDateTo: this.props.initialDateTo 추가
            open: false, transition: false,
            initialDateFrom: this.props.initialDateFrom,
            initialDateTo: this.props.initialDateTo,
            selectedDateFrom: this.props.initialDateFrom,
            selectedDateTo: this.props.initialDateTo,
        });

    };

    getPeriodDays(dateFrom, dateTo) {
        return !dateFrom || !dateTo ? 0 : daysDiff(dateTo, dateFrom) + 1;
    }

    handleTouchTapDayFrom = (event, date) => {
        this.setState({
            selectedDateFrom: date,
            periodDays: this.getPeriodDays(date, this.state.selectedDateTo),
        });

        if (this.myRefs.actionButtons.current) this.myRefs.actionButtons.current.focusOkButton();
    };

    onChangeDateDisplayFrom = (dateFrom) => {
        this.myRefs.calendarFrom.current.handleTouchTapDay(undefined, dateFrom)
    }

    handleTouchTapDayTo = (event, dateTo, dateFrom) => {
        this.setState({
            selectedDateTo: dateTo,
            // initialDateTo: dateTo,

            periodDays: this.getPeriodDays(dateFrom || this.state.selectedDateFrom, dateTo),
        });

        if (this.myRefs.actionButtons.current) this.myRefs.actionButtons.current.focusOkButton();
    };

    onChangeDateDisplayTo = (dateTo) => {
        this.myRefs.calendarTo.current.handleTouchTapDay(undefined, new Date(moment(dateTo).endOf('month').format('YYYY/MM/DD')))
    }

    handleRequestClose = () => {
        this.setState({
            // OBT 수정  initialDateFrom: this.props.initialDateFrom, initialDateTo: this.props.initialDateTo 추가
            initialDateFrom: this.props.initialDateFrom,
            initialDateTo: this.props.initialDateTo,
            selectedDateFrom: this.props.initialDateFrom,
            selectedDateTo: this.props.initialDateTo,
        });

        if (this.props.onRequestClose) this.props.onRequestClose('cancel');
        // this.dismiss();
    };

    handleTouchTapOk = () => {
        if (this.props.onAccept && !this.myRefs.calendarFrom.current.isSelectedDateDisabled() && !this.myRefs.calendarTo.current.isSelectedDateDisabled()) {
            this.props.onAccept(this.myRefs.calendarFrom.current.getSelectedDate(), this.myRefs.calendarTo.current.getSelectedDate());
        }
        this.dismiss();
    };

    handleTouchTapCancel = () => {
        this.handleRequestClose();
    };

    focusOkButton = () => {
        if (this.myRefs.actionButtons.current) this.myRefs.actionButtons.current.focusOkButton();
    }

    //OBT 수정 handleTouchTapControl 함수 추가
    handleTouchTapControl = (dateFrom, dateTo, event) => {
        const minDate = new Date(this.props.minDate);
        const maxDate = new Date(this.props.maxDate);

        if (minDate.getMonth() === dateFrom.getMonth() && maxDate.getMonth() === dateTo.getMonth()) {
            if (minDate > dateFrom && maxDate < dateTo) {
                this.setState({
                    initialDateFrom: minDate,
                    initialDateTo: maxDate,
                    selectedDateFrom: minDate,
                    selectedDateTo: maxDate,
                    periodDays: this.getPeriodDays(minDate, maxDate),
                })
            }
        }

        if (minDate.getMonth() === dateFrom.getMonth()) {
            if (maxDate < dateTo) {
                return;
            }
            if (minDate > dateFrom) {
                this.setState({
                    initialDateFrom: minDate,
                    initialDateTo: dateTo,
                    selectedDateFrom: minDate,
                    selectedDateTo: dateTo,
                    periodDays: this.getPeriodDays(minDate, dateTo),
                })
            }

        }
        if (maxDate.getMonth() === dateTo.getMonth()) {
            if (minDate > dateFrom) {
                return;
            }
            if (maxDate < dateTo) {
                this.setState({
                    initialDateFrom: dateFrom,
                    initialDateTo: maxDate,
                    selectedDateFrom: dateFrom,
                    selectedDateTo: maxDate,
                    periodDays: this.getPeriodDays(dateFrom, maxDate),
                })
            }
        }

        if (this.props.maxDate >= dateTo && this.props.minDate <= dateFrom) {
            this.setState({
                initialDateFrom: dateFrom,
                initialDateTo: dateTo,
                selectedDateFrom: dateFrom,
                selectedDateTo: dateTo,
                periodDays: this.getPeriodDays(dateFrom, dateTo),
            });
        }

        if (this.myRefs.actionButtons.current) this.myRefs.actionButtons.current.focusOkButton();
    }

    handleResetFrom = () => {
        this.setState({
            selectedDateFrom: null,
            initialDateFrom: null,
            resetFrom: true
        }, () => {
            this.setState({
                resetFrom: false
            })
        })
    }

    handleResetTo = () => {
        this.setState({
            selectedDateTo: null,
            initialDateTo: null,
            resetTo: true
        }, () => {
            this.setState({
                resetTo: false
            })
        })
    }

    invalidChecked = (date) => {
        if (String(date) === 'Invalid date' || String(date) === 'Invalid Date') {
            return null
        } else {
            return date
        }
    }

    getAvailable = () => {
        let available = false;
        if (this.state.periodDays > 0) available = true;
        if (!this.props.required && !this.props.allowFullPeriodOnly &&
            (this.state.selectedDateFrom || this.state.selectedDateTo)) {
            available = true;
        }
        return available;
    }

    defaultLabel = (required) => {
        const styles = {
            period: {
                display: 'inline-block',
                zoom: 1,
                fontSize: '12px',
                fontFamily: '돋움, Dotum, Helvetica, Apple SD Gothic Neo, sans-serif',
                color: '#999',
                padding: 0,
                margin: 0,
                lineHeight: '20px'
            },
            button: {
                background: '#fff',
                border: '1px solid #e0e0e0',
                boxSizing: 'border-box',
                color: '#4a4a4a',
                display: 'inline-block',
                fontSize: '11px',
                fontFamily: '돋움, Dotum, Helvetica, Apple SD Gothic Neo, sans-serif',
                height: '21px',
                margin: '-1px 3px 0px -1px',
                outline: 'none',
                padding: '1px 6px 0',
                position: 'relative',
                textAlign: 'center',
                verticalAlign: 'top',
            },
            span: {
                margin: '0 3px 0 0',
            },
        };
        return () => {
            const available = this.getAvailable();
            return (<>
                {!required ?
                    <LUXButton
                        label={OrbitInternalLangPack.getText('WE000001958', '초기화')}
                        style={styles.button}
                        onTouchTap={() => {
                            this.props.onAccept('', '');
                        }} /> : undefined}
                <p style={styles.period}>
                    <span style={styles.span}>{OrbitInternalLangPack.getText('option0211|d', '선택기간')}:</span>&nbsp;
                    <span style={{ ...styles.span, color: available ? undefined : '#FC5356' }}>{available ? `${this.state.periodDays}${OrbitInternalLangPack.getText('WE000000255', '일')}` : OrbitInternalLangPack.getText('WE000007742', '선택불가')}</span>
                </p>
            </>);
        }
    };

    render() {
        const {
            // cancelLabel,
            firstDayOfWeek,
            locale,
            maxDate,
            minDate,
            onClick,
            // okLabel,
            periodNumber,
            periodType,
            shouldDisableDate
        } = this.props;

        const { open } = this.state;
        const customLabel = this.props.customLabel ? this.props.customLabel : this.defaultLabel(this.props.required);
        const available = this.getAvailable();

        return (
            <OBTFloatingPanel
                key={open ? 'open' : 'close'}
                value={open}
                position={this.props.position}
                align={this.props.align}
                autoPosition={true}
            >
                <div tabIndex={0} className={Util.getClassNames(moduleStyles.calendar, this.state.transition === true ? moduleStyles.visible : null)} style={styles.calendar} onClick={onClick} ref={this.myRefs.root}>
                    {/* //OBT 수정 LUXControlButtons 추가 */}
                    {this.props.useControlButton ? <OBTDateControlButtons
                        selectedDateFrom={this.state.selectedDateFrom}
                        selectedDateTo={this.state.selectedDateTo}
                        onTouchTap={this.handleTouchTapControl}
                    /> :
                        <></>
                    }
                    <div className={moduleStyles.calendarTable} >
                        <LUXCalendarFrom
                            ref={this.myRefs.calendarFrom}
                            key={this.state.resetFrom ? 'resetFrom' : 'noneFrom'}
                            firstDayOfWeek={firstDayOfWeek}
                            //OBT 수정 initialDateFrom -> this.state.initialDateFrom
                            initialDate={this.state.initialDateFrom}
                            locale={locale}
                            onTouchTapDay={this.handleTouchTapDayFrom}
                            maxDate={maxDate}
                            minDate={minDate}
                            open={open}
                            selectedDateTo={this.state.selectedDateTo}
                            shouldDisableDate={shouldDisableDate}
                            onChangeDisplayDate={this.onChangeDateDisplayFrom}
                        />
                        <LUXCalendarTo
                            ref={this.myRefs.calendarTo}
                            key={this.state.resetTo ? 'resetTo' : 'noneTo'}
                            firstDayOfWeek={firstDayOfWeek}
                            //OBT 수정 initialDateTo -> this.state.initialDateTo
                            initialDate={this.state.initialDateTo}
                            locale={locale}
                            maxDate={maxDate}
                            minDate={minDate}
                            onTouchTapDay={this.handleTouchTapDayTo}
                            open={open}
                            periodNumber={periodNumber}
                            periodType={periodType}
                            selectedDateFrom={this.state.selectedDateFrom}
                            shouldDisableDate={shouldDisableDate}
                            onChangeDisplayDate={this.onChangeDateDisplayTo}
                        />
                    </div>
                    <CalendarActionButtons
                        ref={this.myRefs.actionButtons}
                        cancelLabel={OrbitInternalLangPack.getText('WE000001945', '취소')}
                        okLabel={OrbitInternalLangPack.getText('WE000000054', '확인')}
                        // cancelLabel={cancelLabel}
                        // okLabel={okLabel}
                        onTouchTapCancel={this.handleTouchTapCancel}
                        onTouchTapOk={this.handleTouchTapOk}
                        periodDays={this.state.periodDays}
                        customLabel={customLabel}
                        selectedDateFrom={this.invalidChecked(moment(this.state.selectedDateFrom).format('YYYYMMDD'))}
                        selectedDateTo={this.invalidChecked(moment(this.state.selectedDateTo).format('YYYYMMDD'))}
                        onResetFrom={this.handleResetFrom}
                        onResetTo={this.handleResetTo}
                        available={available}
                    />
                </div>
            </OBTFloatingPanel>
        );
    }

    containsFocus = () => {
        return Util.containsFocus(this.myRefs.root);
    }
}

export default UFOPeriodDateFieldDialog;
