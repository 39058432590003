import React from 'react';
import BreadcrumbItem from './BreadcrumbItem';
import defaultArrowRightIcon from '../Images/arrow-right.png';
import defaultListOpenIcon from '../Images/list-open-m.png';
import defaultListCloseIcon from '../Images/list-close-m.png';
import defaultHomeIcon from '../Images/home-m.png';

const styles = require("./Breadcrumb.module.scss");

export interface IBreadCrumb {
    /**
     * default로 위치할 노드의 id를 의미합니다.
     */
    nodeId: string,
    /**
     * Breadcrumb을 구성할 Json 데이터 입니다.
     */
    data: NodeType[],
    /**
     * 기본 아이콘을 변경합니다. 
     * @type {IconType}
     */
    icons?: IconType,
    /**
     * Breadcrum의 최상단 Element의 Class를 지정합니다.
     */
    className?: string,
    /**
     * dropdown 버튼 옆에 title을 지정합니다.
     */
    dropdownTitle?: string,
    /**
     * Breadcrumb의 node Item의 onClick 이벤트에 사용되는 callback 함수 입니다.
     * - e.target.dataset.id 을 통해 click이 발생한 item의 id 값을 얻을 수 있습니다.
     */
    onItemClick: (e) => void,
}

export interface NodeType {
    id: string;
    title: string;
    path: string;
    child: NodeType[] | null;
}
export interface IconType {
    homeIcon?: string,
    arrowRightIcon?: string;
    listOpenIcon?: string,
    listCloseIcon?: string,
}

/**
 * nodeItems에서 targetNodeId까지의 경로를 순서대로 배열에 담아 반환합니다.
 * @param nodeItems 
 * @param targetNodeId 
 * @returns {NodeType[] | []}
 * 
 */
export function getNodeList(nodeItems: NodeType[], targetNodeId: string): NodeType[] | [] {
    const nodeList: NodeType[] = [];
    const findTargetNode = (nodeItems: NodeType[], targetNodeId: string) => {
        for (var nodeItem of nodeItems) {
            if (nodeItem.id === targetNodeId) {
                nodeList.push(nodeItem);
                return nodeList;
            }
            if (nodeItem.child) {
                nodeList.push(nodeItem);
                if (!findTargetNode(nodeItem.child, targetNodeId)) nodeList.pop();
                else return nodeList;
            }
        }
        return null;
    };
    return findTargetNode(nodeItems, targetNodeId) ?? [];
}

interface IState {
    crumb: NodeType[],
    icons: IconType,
}

export default class Breadcrumb extends React.Component<IBreadCrumb, IState>{
    state: IState = {
        crumb: [],
        icons: {},
    };

    defaultIcons = {
        homeIcon: defaultHomeIcon,
        arrowRightIcon: defaultArrowRightIcon,
        listOpenIcon: defaultListOpenIcon,
        listCloseIcon: defaultListCloseIcon
    };

    componentDidMount() {
        const crumb = getNodeList(this.props.data, this.props.nodeId);

        this.setState({
            crumb: crumb,
            icons: {
                ...this.defaultIcons,
                ...this.props.icons,
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.nodeId !== prevProps.nodeId) {
            const crumb = getNodeList(this.props.data, this.props.nodeId);
            this.setState({
                crumb: crumb,
            });
        }
        if (this.props.icons !== prevProps.icons) {
            this.setState({
                icons: {
                    ...this.defaultIcons,
                    ...this.props.icons,
                }
            });
        }
    }

    isEndNode = idx => {
        return (this.state.crumb.length - 1 === idx);
    };

    render() {
        return (
            <>
                <div className={`${styles.breadcrumb} ${this.props.className ?? ''}`}>
                    <img src={this.state.icons.homeIcon} />
                    <img src={this.state.icons.arrowRightIcon} />
                    {this.state.crumb.map((item, idx) => {
                        if (this.isEndNode(idx)) {
                            if (item.child) {
                                return <BreadcrumbItem
                                    key={`${item.id}-${idx}`}
                                    item={item} icons={this.state.icons}
                                    childNode={item.child}
                                    onItemClick={this.props.onItemClick}
                                    dropdownTitle={this.props.dropdownTitle}
                                />;
                            } else {
                                return <BreadcrumbItem
                                    key={`${item.id}-${idx}`}
                                    icons={this.state.icons}
                                    item={item}
                                    onItemClick={this.props.onItemClick}
                                />;
                            }
                        } else {
                            return <BreadcrumbItem
                                key={`${item.id}-${idx}`}
                                icons={this.state.icons}
                                item={item}
                                onItemClick={this.props.onItemClick}
                                hasNextNode
                            />;
                        }
                    })}
                </div>
            </>
        );
    }
}
