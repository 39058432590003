import React from 'react';
import warning from 'warning';
import moment from 'moment';

const dayAbbreviation = ['일', '월', '화', '수', '목', '금', '토'];
const dayList = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const monthLongList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
    'October', 'November', 'December'];

export function dateTimeFormat(locale, options) {
    warning(locale === 'ko', `The ${locale} locale is not supported by the built-in DateTimeFormat.
    Use the \`DateTimeFormat\` prop to supply an alternative implementation.`);

    this.format = function (date) {
        if (options.month === 'short' && options.weekday === 'short' && options.day === '2-digit') {
            return `${dayList[date.getDay()]}, ${monthList[date.getMonth()]} ${date.getDate()}`;
        } else if (options.day === 'numeric' && options.month === 'numeric' && options.year === 'numeric') {
            return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
        } else if (options.month === 'long' && options.year === 'numeric') {
            return `${monthLongList[date.getMonth()]} ${date.getFullYear()}`;
        } else if (options.weekday === 'narrow') {
            return dayAbbreviation[date.getDay()];
        } else {
            warning(false, 'Wrong usage of DateTimeFormat');
        }
    };
}

export function addDays(d, days) {
    const newDate = cloneDate(d);
    newDate.setDate(d.getDate() + days);
    return newDate;
}

export function addMonths(d, months) {
    const newDate = cloneDate(d);
    newDate.setMonth(d.getMonth() + months);
    return newDate;
}

export function addLunarMonths(d, months) {
    // 한달씩 이동만 의미 있다. 한달씩 이동이 아니면 로직 무너짐
    let date = Object.assign({}, d);
    if (months === 1) {
        if (date.month === 12) {
            date.year += date.year + 1;
            date.month = 1;
        }
    }
    return date;
}

export function addYears(d, years) {
    const newDate = cloneDate(d);
    newDate.setFullYear(d.getFullYear() + years);
    return newDate;
}

export function cloneDate(d) {
    return new Date(d.getTime());
}

export function cloneAsDate(d) {
    const clonedDate = cloneDate(d);
    clonedDate.setHours(0, 0, 0, 0);
    return clonedDate;
}

export function getDayAbbreviation() {
    return dayAbbreviation;
}

export function getDaysInMonth(d) {
    const resultDate = getFirstDayOfMonth(d);

    resultDate.setMonth(resultDate.getMonth() + 1);
    resultDate.setDate(resultDate.getDate() - 1);

    return resultDate.getDate();
}

export function getFirstDayOfMonth(d) {
    return new Date(d.getFullYear(), d.getMonth(), 1);
}

export function getFirstDayOfWeek() {
    const now = new Date();
    return new Date(now.setDate(now.getDate() - now.getDay()));
}

export function getWeekArray(d, firstDayOfWeek) {
    const dayArray = [];
    const daysInMonth = getDaysInMonth(d);
    const weekArray = [];
    let week = [];

    for (let i = 1; i <= daysInMonth; i++) {
        dayArray.push(new Date(d.getFullYear(), d.getMonth(), i));
    }

    const addWeek = (week) => {
        const emptyDays = 7 - week.length;
        for (let i = 0; i < emptyDays; ++i) {
            week[weekArray.length ? 'push' : 'unshift'](null);
        }
        weekArray.push(week);
    };

    dayArray.forEach((day) => {
        if (week.length > 0 && day.getDay() === firstDayOfWeek) {
            addWeek(week);
            week = [];
        }
        week.push(day);
        if (dayArray.indexOf(day) === dayArray.length - 1) {
            addWeek(week);
        }
    });

    return weekArray;
}

export function localizedWeekday(DateTimeFormat, locale, day, firstDayOfWeek) {
    const weekdayFormatter = new DateTimeFormat(locale, { weekday: 'narrow' });
    const firstDayDate = getFirstDayOfWeek();

    return weekdayFormatter.format(addDays(firstDayDate, day + firstDayOfWeek));
}

// Convert date to ISO 8601 (YYYY-MM-DD) date string, accounting for current timezone
export function formatIso(date) {
    return (new Date(`${date.toDateString()} 12:00:00 +0000`)).toISOString().substring(0, 10);
}

// Convert date to Locale (YYYY.MM.DD) date string, accounting for current timezone
export function formatLocale(locale, date) {
    // Modify for moment format string not work on product env
    return [date.getFullYear(), ('00' + (date.getMonth() + 1)).slice(-2), ('00' + date.getDate()).slice(-2)].join('.');

    // moment.locale(locale);
    // return moment(date).format('L');
}

// Convert date to Locale (YYYYMMDD) date string, accounting for current timezone
export function dateToString(date) {
    return date.getFullYear() + ('00' + (date.getMonth() + 1)).slice(-2) + ('00' + date.getDate()).slice(-2);
}

// Convert Locale (YYYYMMDD) date string to date, accounting for current timezone
export function stringToDate(dateStr) {
    return new Date(dateStr.slice(0, 4), dateStr.slice(4, 6) - 1, dateStr.slice(6));
}

export function isDate(date) {
    const firstDayOfDate = new Date(date.slice(0, 4), date.slice(5, 7) - 1, 1);
    return (date.slice(5, 7) <= 12 && getDaysInMonth(firstDayOfDate) >= date.slice(8) && new Date(date) !== "Invalid Date");
}

export function isEqualDate(d1, d2) {
    return d1 && d2 &&
        (d1.getFullYear() === d2.getFullYear()) &&
        (d1.getMonth() === d2.getMonth()) &&
        (d1.getDate() === d2.getDate());
}

export function isBeforeTime(d1, d2) {
    if (!(d1 && d2)) return;

    let isBefore = false;

    if (d1.getHours() < d2.getHours()) {
        isBefore = true;
    } else if (d1.getHours() === d2.getHours()) {
        if (d1.getMinutes() < d2.getMinutes()) {
            isBefore = true;
        } else if (d1.getMinutes() === d2.getMinutes()) {
            if (d1.getSeconds() < d2.getSeconds()) {
                isBefore = true;
            }
        }
    }

    return isBefore;
}

export function isBeforeDate(d1, d2) {
    const date1 = cloneAsDate(d1);
    const date2 = cloneAsDate(d2);

    return (date1.getTime() < date2.getTime());
}

export function isAfterDate(d1, d2) {
    const date1 = cloneAsDate(d1);
    const date2 = cloneAsDate(d2);

    return (date1.getTime() > date2.getTime());
}

export function isSameDate(d1, d2) {
    const date1 = cloneAsDate(d1);
    const date2 = cloneAsDate(d2);

    return (date1.getTime() === date2.getTime());
}

export function isBetweenDates(dateToCheck, startDate, endDate) {
    return ((startDate && endDate) && !(isBeforeDate(dateToCheck, startDate)) && !(isAfterDate(dateToCheck, endDate)));
}

export function dayDiff(d1, d2) {
    let dateTo = moment([d1.getFullYear(), d1.getMonth(), d1.getDay()]);
    let dateFrom = moment([d2.getFullYear(), d2.getMonth(), d2.getDay()]);

    return dateTo.diff(dateFrom, 'days');
}

export function daysDiff(d1, d2) {
    if (!d1 || !d2) return 0;

    let msPerDay = 8.64e7;

    // Copy dates so don't mess them up
    let dateTo = new Date(d1);
    let dateFrom = new Date(d2);

    // Set to noon - avoid DST errors
    dateTo.setHours(12, 0, 0);
    dateFrom.setHours(12, 0, 0);

    // Round to remove daylight saving errors
    return Math.round((dateTo - dateFrom) / msPerDay);
}

export function monthDiff(d1, d2) {
    let m;

    m = (d1.getFullYear() - d2.getFullYear()) * 12;
    m += d1.getMonth();
    m -= d2.getMonth();

    return m;
}

export function yearDiff(d1, d2) {
    return ~~(monthDiff(d1, d2) / 12);
}

// 숫자를 자리수에 맞게 스트링으로 변형 ex) 1 -> 01
export function pad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
}


// 양력 1900년 1월 1일부터 2060년 12월 30일 까지의 음력 데이터
const solMonthDay = [31, 0, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const SOLAR_TO_LUNAR = 1;
const LUNAR_TO_SOLAR = 2;
const lunarMonthTable = [
    [2, 1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 5, 2, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],
    [2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2],
    [1, 2, 1, 2, 3, 2, 1, 1, 2, 2, 1, 2],
    [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1],
    [2, 2, 1, 2, 2, 1, 1, 2, 1, 2, 1, 2],
    [1, 2, 2, 4, 1, 2, 1, 2, 1, 2, 1, 2],
    [1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
    [2, 1, 1, 2, 2, 1, 2, 1, 2, 2, 1, 2],
    [1, 5, 1, 2, 1, 2, 1, 2, 2, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],
    [2, 1, 2, 1, 1, 5, 1, 2, 2, 1, 2, 2],
    [2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2],
    [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2],
    [2, 2, 1, 2, 5, 1, 2, 1, 2, 1, 1, 2],
    [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
    [1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
    [2, 3, 2, 1, 2, 2, 1, 2, 2, 1, 2, 1],
    [2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 5, 2, 2, 1, 2, 2],
    [1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2],
    [2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],
    [2, 1, 2, 2, 3, 2, 1, 1, 2, 1, 2, 2],
    [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 1, 2],
    [2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 1],
    [2, 1, 2, 5, 2, 1, 2, 2, 1, 2, 1, 2],
    [1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],
    [1, 5, 1, 2, 1, 1, 2, 2, 1, 2, 2, 2],
    [1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2],
    [1, 2, 2, 1, 1, 5, 1, 2, 1, 2, 2, 1],
    [2, 2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2],
    [1, 2, 2, 1, 6, 1, 2, 1, 2, 1, 1, 2],
    [1, 2, 1, 2, 2, 1, 2, 2, 1, 2, 1, 2],
    [1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 4, 1, 2, 1, 2, 1, 2, 2, 2, 1],
    [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1],
    [2, 2, 1, 1, 2, 1, 4, 1, 2, 2, 1, 2],
    [2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 1, 2],
    [2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 2, 1, 2, 2, 4, 1, 1, 2, 1, 2, 1],
    [2, 1, 2, 2, 1, 2, 2, 1, 2, 1, 1, 2],
    [1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2],
    [1, 1, 2, 4, 1, 2, 1, 2, 2, 1, 2, 2],
    [1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2],
    [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2],
    [2, 5, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
    [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
    [2, 2, 1, 2, 1, 2, 3, 2, 1, 2, 1, 2],
    [2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
    [1, 2, 1, 2, 4, 2, 1, 2, 1, 2, 1, 2],
    [1, 2, 1, 1, 2, 2, 1, 2, 2, 1, 2, 2],
    [1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2],
    [2, 1, 4, 1, 1, 2, 1, 2, 1, 2, 2, 2],
    [1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
    [2, 1, 2, 1, 2, 1, 1, 5, 2, 1, 2, 2],
    [1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
    [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1],
    [2, 1, 2, 1, 2, 5, 2, 1, 2, 1, 2, 1],
    [2, 1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 2, 3, 2, 1, 2, 1, 2, 2, 2, 1],
    [2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2],
    [1, 2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 2],
    [1, 2, 5, 2, 1, 1, 2, 1, 1, 2, 2, 1],
    [2, 2, 1, 2, 2, 1, 1, 2, 1, 2, 1, 2],
    [1, 2, 2, 1, 2, 1, 5, 2, 1, 2, 1, 2],
    [1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
    [2, 1, 1, 2, 2, 1, 2, 1, 2, 2, 1, 2],
    [1, 2, 1, 1, 5, 2, 1, 2, 2, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],
    [2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 2, 1],
    [2, 2, 1, 5, 1, 2, 1, 1, 2, 2, 1, 2],
    [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2],
    [2, 2, 1, 2, 1, 2, 1, 5, 2, 1, 1, 2],
    [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 1],
    [2, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
    [2, 1, 1, 2, 1, 6, 1, 2, 2, 1, 2, 1],
    [2, 1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],
    [1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2],
    [2, 1, 2, 3, 2, 1, 1, 2, 2, 1, 2, 2],
    [2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],
    [2, 1, 2, 2, 1, 1, 2, 1, 1, 5, 2, 2],
    [1, 2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2],
    [1, 2, 2, 1, 2, 2, 1, 2, 1, 2, 1, 1],
    [2, 1, 2, 2, 1, 5, 2, 2, 1, 2, 1, 2],
    [1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],
    [1, 2, 1, 1, 5, 1, 2, 2, 1, 2, 2, 2],
    [1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2],
    [1, 2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],
    [1, 2, 5, 2, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2],
    [1, 2, 2, 1, 2, 2, 1, 5, 2, 1, 1, 2],
    [1, 2, 1, 2, 2, 1, 2, 1, 2, 2, 1, 2],
    [1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 1, 2, 3, 2, 2, 1, 2, 2, 2, 1],
    [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1],
    [2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1],
    [2, 2, 2, 3, 2, 1, 1, 2, 1, 2, 1, 2],
    [2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2],
    [1, 5, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
    [1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1, 1],
    [2, 1, 2, 1, 2, 1, 5, 2, 2, 1, 2, 2],
    [1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2],
    [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2],
    [2, 2, 1, 1, 5, 1, 2, 1, 2, 1, 2, 2],
    [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
    [2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 1, 6, 2, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
    [1, 2, 1, 2, 1, 2, 1, 2, 5, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2, 1],
    [2, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2],
    [1, 2, 1, 2, 3, 2, 1, 2, 1, 2, 2, 2],
    [1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
    [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
    [2, 1, 2, 5, 2, 1, 1, 2, 1, 2, 1, 2],
    [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1],
    [2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2],
    [1, 5, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 2, 1, 1, 5, 2, 1, 2, 2, 2, 1],
    [2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2],
    [1, 2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 2],
    [1, 2, 2, 1, 5, 1, 2, 1, 1, 2, 2, 1],
    [2, 2, 1, 2, 2, 1, 1, 2, 1, 1, 2, 2],
    [1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1],
    [2, 1, 5, 2, 1, 2, 2, 1, 2, 1, 2, 1],
    [2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 5, 2],
    [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],
    [2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2],
    [2, 2, 1, 2, 1, 4, 1, 1, 2, 2, 1, 2],
    [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2],
    [2, 2, 1, 2, 1, 2, 1, 2, 1, 1, 2, 1],
    [2, 2, 1, 2, 5, 2, 1, 2, 1, 2, 1, 1],
    [2, 1, 2, 2, 1, 2, 2, 1, 2, 1, 2, 1],
    [2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2],
    [1, 5, 1, 2, 1, 2, 1, 2, 2, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2],
    [2, 1, 2, 1, 1, 2, 3, 2, 1, 2, 2, 2],
    [2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],
    [2, 1, 2, 2, 1, 1, 2, 1, 1, 2, 1, 2],
    [2, 1, 2, 2, 4, 1, 2, 1, 1, 2, 1, 2],
    [1, 2, 2, 1, 2, 2, 1, 2, 1, 2, 1, 1],
    [2, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2, 1],
    [2, 1, 4, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 1, 2, 1, 2, 1, 2, 1, 2, 2, 2],
    [1, 2, 1, 1, 2, 1, 1, 6, 1, 2, 2, 2],
    [1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2],
    [1, 2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],
    [1, 2, 2, 1, 2, 4, 1, 1, 2, 1, 2, 1],
    [2, 2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2],
    [1, 2, 2, 1, 2, 1, 2, 2, 1, 1, 2, 1],
    [2, 1, 2, 4, 2, 1, 2, 1, 2, 2, 1, 1],
    [2, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2, 2]
];

export function getLunarMonthTable() {
    return lunarMonthTable;
}

export class Lunar extends React.Component {
    constructor() {
        super();

        this.state = {
            lunarMaxDate: new Date(2060, 11, 30),
            lunarMinDate: new Date(1900, 1, 1),
        }
    }

    lunarToSolar(year, month, day, leapmonth) {
        return this.calculateDate(year, month, day, LUNAR_TO_SOLAR, leapmonth);
    };

    solarToLunar(year, month, day) {
        const date = new Date(year, month - 1, day);

        if (isAfterDate(date, this.state.lunarMaxDate) || isBeforeDate(date, this.state.lunarMinDate)) return;

        return this.calculateDate(year, month, day, SOLAR_TO_LUNAR, 0);
    };

    calculateDate(year, month, day, type, leapmonth) {
        let sMonthDay = solMonthDay;
        let lMonthTable = lunarMonthTable;
        var sYear, sMonth, sDay, lYear, lMonth, lDay;
        var lunLeapMonth, lunMonthDay;
        var lunIndex;

        if (!this.checkValidDate(year)) {
            return false;
        }
        //OBT 수정 sYear lYear , -> ; 바꿈
        if (year >= 2013) {
            /* 기준일자 양력 2013년 1월 1일 (음력 2012년 11월 20일) */
            sYear = 2013; sMonth = 1; sDay = 1;
            lYear = 2012; lMonth = 11; lDay = 20;
            lunLeapMonth = 0;
            sMonthDay[1] = 28; /* 2013 년 2월 28일 */
            lunMonthDay = 30; /* 2012년 11월 */
        } else if (year >= 2010) {
            /* 기준일자 양력 2010년 1월 1일 (음력 2009년 11월 17일) */
            sYear = 2010; sMonth = 1; sDay = 1;
            lYear = 2009; lMonth = 11; lDay = 17;
            lunLeapMonth = 0;
            sMonthDay[1] = 28; /* 2010 년 2월 28일 */
            lunMonthDay = 30; /* 2009년 11월 */
        } else if (year >= 2000) {
            /* 기준일자 양력 2000년 1월 1일 (음력 1999년 11월 25일) */
            sYear = 2000; sMonth = 1; sDay = 1;
            lYear = 1999; lMonth = 11; lDay = 25;
            lunLeapMonth = 0;
            sMonthDay[1] = 29;
            lunMonthDay = 30;
        } else if (year >= 1970) {
            /* 기준일자 양력 1970년 1월 1일 (음력 1969년 11월 24일) */
            sYear = 1970; sMonth = 1; sDay = 1;
            lYear = 1969; lMonth = 11; lDay = 24;
            lunLeapMonth = 0;
            sMonthDay[1] = 28; /* 1970 년 2월 28일 */
            lunMonthDay = 30; /* 1969년 11월 */
        } else if (year >= 1940) {
            /* 기준일자 양력 1940년 1월 1일 (음력 1939년 11월 22일) */
            sYear = 1940; sMonth = 1; sDay = 1;
            lYear = 1939; lMonth = 11; lDay = 22;
            lunLeapMonth = 0;
            sMonthDay[1] = 29; /* 1940 년 2월 29일 */
            lunMonthDay = 29; /* 1939년 11월 */
        } else {
            /* 기준일자 양력 1900년 1월 1일 (음력 1899년 12월 1일) */
            sYear = 1900; sMonth = 1; sDay = 1;
            lYear = 1899; lMonth = 12; lDay = 1;
            lunLeapMonth = 0;
            sMonthDay[1] = 28; /* 1900 년 2월 28일 */
            lunMonthDay = 30;
        }

        lunIndex = lYear - 1899;
        while (true) {
            if (type === SOLAR_TO_LUNAR && year === sYear && month === sMonth && day === sDay) {
                return {
                    "year": lYear,
                    "month": lMonth,
                    "day": lDay,
                    "leapmonth": lunLeapMonth,
                };
            } else if (type === LUNAR_TO_SOLAR && year === lYear && month === lMonth && day === lDay && leapmonth === lunLeapMonth) {
                return {
                    "year": sYear,
                    "month": sMonth,
                    "day": sDay,
                    "leapmonth": 0,
                };
            }

            if (sMonth === 12 && sDay === 31) {
                sYear++;
                sMonth = 1;
                sDay = 1;
                if (sYear % 400 === 0) {
                    sMonthDay[1] = 29;
                } else if (sYear % 100 === 0) {
                    sMonthDay[1] = 28;
                } else if (sYear % 4 === 0) {
                    sMonthDay[1] = 29;
                } else {
                    sMonthDay[1] = 28;
                }
            } else if (sMonthDay[sMonth - 1] === sDay) {
                sMonth++;
                sDay = 1;
            } else {
                sDay++;
            }

            if (lMonth === 12 && ((lMonthTable[lunIndex][lMonth - 1] === 1 && lDay === 29) || (lMonthTable[lunIndex][lMonth - 1] === 2 && lDay === 30))) {
                lYear++;
                lMonth = 1;
                lDay = 1;

                if (lYear > 2060) {
                    return false;
                }

                lunIndex = lYear - 1899;
                if (lMonthTable[lunIndex][lMonth - 1] === 1) {
                    lunMonthDay = 29;
                } else if (lMonthTable[lunIndex][lMonth - 1] === 2) {
                    lunMonthDay = 30;
                }
            } else if (lDay === lunMonthDay) {
                if (lMonthTable[lunIndex][lMonth - 1] >= 3 && lunLeapMonth === 0) {
                    lDay = 1;
                    lunLeapMonth = 1;
                } else {
                    lMonth++;
                    lDay = 1;
                    lunLeapMonth = 0;
                }
                if (lMonthTable[lunIndex][lMonth - 1] === 1)
                    lunMonthDay = 29;
                else if (lMonthTable[lunIndex][lMonth - 1] === 2)
                    lunMonthDay = 30;
                else if (lMonthTable[lunIndex][lMonth - 1] === 3)
                    lunMonthDay = 29;
                else if (lMonthTable[lunIndex][lMonth - 1] === 4 && lunLeapMonth === 0)
                    lunMonthDay = 29;
                else if (lMonthTable[lunIndex][lMonth - 1] === 4 && lunLeapMonth === 1)
                    lunMonthDay = 30;
                else if (lMonthTable[lunIndex][lMonth - 1] === 5 && lunLeapMonth === 0)
                    lunMonthDay = 30;
                else if (lMonthTable[lunIndex][lMonth - 1] === 5 && lunLeapMonth === 1)
                    lunMonthDay = 29;
                else if (lMonthTable[lunIndex][lMonth - 1] === 6)
                    lunMonthDay = 30;
            } else {
                lDay++;
            }
        }
    };

    checkValidDate(year) {
        return (year >= 1900 && year <= 2060);
    };
};
