/**
* OBTMultiLineTextField
* @version 0.1
* @author 박재성
* @see LUXTextArea
*/
import * as React from 'react';
import LUXTextArea from 'luna-rocket/LUXTextField/LUXTextArea';
import { Events, CompositeProps, Util, CommonProps, Functions, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import OBTTooltip, { IOBTTooltip } from '../OBTTooltip/OBTTooltip';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const styles = require('./OBTMultiLineTextField.module.scss');

enum AlignType {
    'left' = 'left',
    'center' = 'center',
    'right' = 'right'
}

/**
 * style, disabled, readonly, required, value, placeHolder, type, onFocus, onBlur, onChange
 */
interface IOBTMultiLineTextField extends CompositeProps.SubInputClass<string>, CommonProps.placeHolder, Events.onValidate<string>, Events.onClick, Events.onMouseDown,
    Events.onMouseMove, Events.onMouseUp, Events.onMouseLeave, Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress, Events.onKeyUp {
    maxLength?: number,
    maxByte?: number,
    fixed?: boolean,
    align: AlignType,
    tooltip?: IOBTTooltip
}

/**
* State 정의
*/
interface State extends hasError {
}

/**
* OBTMultiLineTextField
* Prop : { style, disabled, readonly, required, value, placeHolder, type, onFocus, onBlur, onChange }
*/
export default class OBTMultiLineTextField extends React.Component<IOBTMultiLineTextField, State> implements Functions.IFocusable, Functions.IRequired {
    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.SubInputClass(CommonType.string),
        { name: "maxLength", type: CommonType.number, optional: true, description: "입력 최대길이를 지정할 수 있습니다." },
        { name: "maxByte", type: CommonType.number, description: "입력 Byte를 지정할 수 있습니다.", optional: true },
        { name: "fixed", type: CommonType.boolean, optional: true, description: "멀티라인텍스트 크기를 고정시킵니다." },
        { name: "align", type: toEnumType(AlignType), optional: true, default: "left", description: "멀티라인텍스트 정렬 방법을 지정합니다." },
        CommonDefinitions.tooltip(),
        CommonDefinitions.placeHolder(),
        CommonDefinitions.InnerFunc(),
        CommonDefinitions.onValidate(CommonType.string),
        CommonDefinitions.Event()
    );
    ///////////////////////////////////////////////////////////////////////////// Initialize
    public static Align = AlignType;
    public static valueType = 'text';
    /**
     * Default Props 설정
     */
    public static defaultProps = {
        disabled: false,
        readonly: false,
        required: false,
        align: AlignType.left,
    }

    /**
     * State 정의
     */
    public state: State = {
    }

    /**
     * Ref 정의
     */
    public myRefs = {
        ref: React.createRef<LUXTextArea>()
    }

    ///////////////////////////////////////////////////////////////////////////// Life Cycle API

    // component 가 render 될때 호출됨.
    render() {
        return (<ErrorBoundary owner={this} render={this.renderComponent} />)
    }

    renderComponent = () => {
        const props = {
            // Value
            defaultValue: Util.cl(this.props.value, this.props.subValue, this.props.useSubLang),
            onChange: this.handleChange.bind(this),

            // Events
            onFocus: this.handleFocus.bind(this),
            onBlur: this.handleBlur.bind(this),

            // Etc
            disabled: this.props.disabled,
            hintText: this.props.placeHolder,
            fullWidth: true,
            maxLength: this.props.maxLength,
            maxByte: this.props.maxByte,

            // Styling
            rootStyle: {
                width: '100%',
                height: '100%',
            },
            style: Object.assign({}, Util.getInputStateStyle(this.props),
                {
                    width: '100%',
                    height: '100%',
                    textAlign: this.props.align === AlignType.right ? 'right' : this.props.align === AlignType.center ? 'center' : 'left',
                    fontFamily: 'inherit',
                    lineHeight: '13px'
                })
        };
        const component = <LUXTextArea ref={this.myRefs.ref} {...props}> </LUXTextArea>

        return (
            <OBTTooltip {...this.props.tooltip}
                className={Util.getClassNames(styles.obtMultiLineTextField, this.props.fixed ? styles.fixed : null, this.props.className)}
                style={Util.getWrapperStyle(this.props)}
                overrideSize={false}
                onClick={this.props.onClick}
                onMouseDown={this.props.onMouseDown}
                onMouseMove={this.props.onMouseMove}
                onMouseUp={this.props.onMouseUp}
                onMouseLeave={this.props.onMouseLeave}
                onMouseEnter={this.props.onMouseEnter}
                onKeyUp={this.props.onKeyUp}
                onKeyDown={this.props.onKeyDown}
                onKeyPress={this.props.onKeyPress}
                rootProps={{
                    id: this.props.id,
                    'data-orbit-component': 'OBTMultiLineTextField',
                    onKeyDownCapture: this.handleOnKeyDownCapture
                }}>
                {component}
            </OBTTooltip>
        );
    }

    ///////////////////////////////////////////////////////////////////////////// Logics
    public focus(isLast: boolean = false): void {
        if (!this.props.frozen && !this.props.disabled && this.myRefs.ref.current) {
            this.myRefs.ref.current.focus();
        }
    }

    private get canEdit(): boolean {
        return !this.props.disabled && !this.props.readonly;
    }

    public isEmpty(): boolean {
        return (!Util.cl(this.props.value, this.props.subValue, this.props.useSubLang) || Util.cl(this.props.value, this.props.subValue, this.props.useSubLang).length <= 0);
    }

    public validate(): boolean {
        return !(this.props.required === true && this.isEmpty());
    }

    public getCaretPosition() {
        if (this.myRefs.ref.current) {
            return this.myRefs.ref.current.getCaretPosition();
        }
        return null
    }

    public setCaretPosition(start, end) {
        if (this.myRefs.ref.current) {
            this.myRefs.ref.current.setCaretPosition(start, end);
        }
    }
    ///////////////////////////////////////////////////////////////////////////// Event Handlers

    private handleFocus(): void {
        Util.invokeEvent<Events.EventArgs>(this.props.onFocus, new Events.EventArgs(this));
    }

    private handleChange(e: any, value: string): void {
        if (this.canEdit) {
            let oldValue = this.props.value;
            let newValue = Util.cl(value, this.props.value, this.props.useSubLang);
            let oldSubValue = this.props.subValue ? this.props.subValue : null;
            let newSubValue = Util.cl(this.props.subValue, value, this.props.useSubLang);
            if (Util.invokeEvent<Events.ValidateWithSubEventArgs<string>>(this.props.onValidate,
                new Events.ValidateWithSubEventArgs(this, oldValue, newValue, oldSubValue, newSubValue!))) {
                Util.invokeEvent<Events.ChangeWithSubEventArgs<string>>(this.props.onChange, new Events.ChangeWithSubEventArgs<string>(this, newValue, newSubValue));
            }
        }
    }

    private handleBlur(): void {
        Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
    }

    private handleOnKeyDownCapture = (e: React.KeyboardEvent) => {
        if (!this.canEdit) {
            e.preventDefault()
        }
    }
};
