import React from 'react';
import PropTypes from 'prop-types';
import classNames from './ExampleConsole.module.scss';
import memoizeOne from 'memoize-one';

const getClassNames = (...classNames) => {
    return classNames.filter(name => name && name.length > 0).join(' ');
}

export default class ExampleConsole extends React.Component {
    static propTypes = {
        console: PropTypes.object,
        filters: PropTypes.array
    }

    static getHandler(event, consoleObject, callback) {
        return (e) => {
            consoleObject.log(`${event} : ` + e, event);
            if (callback) callback(e);
        }
    }

    state = {
        logs: [],
        ignoreFilters: []
    }

    logsRef = React.createRef();

    constructor(props) {
        super(props);
        const _console = props.console;
        _console.log = (log, filter) => {
            this.setState({
                logs: this.state.logs.concat({ log: log, type: 'log', filter: filter })
            }, this.scrollToBottom)
        }
        _console.error = (log, filter) => {
            this.setState({
                logs: this.state.logs.concat({ log: log, type: 'error', filter: filter })
            }, this.scrollToBottom)
        }
    }

    getFilters = memoizeOne((filters, ignoreFilters) => {
        return filters.map((filter, index) => {
            return (<div key={index}
                className={getClassNames(classNames.filter, !ignoreFilters.includes(filter) ? classNames.on : undefined)}
                onClick={() => this.handleIgnoreFilter(filter)}
            > {filter}</div >);
        })
    });
    getLogs = memoizeOne((logs, ignoreFilters) => {
        const filteredLogs = ignoreFilters && ignoreFilters.length > 0 ? logs.filter(log => !(log.filter && ignoreFilters.includes(log.filter))) : logs;
        return filteredLogs.map((log, index) => (<div key={index} className={log.type === 'error' ? classNames.error : classNames.log}>{log.log}</div>))
    });

    render() {
        const filters = this.getFilters(this.props.filters, this.state.ignoreFilters);
        const logs = this.getLogs(this.state.logs, this.state.ignoreFilters);
        return (
            <div className={classNames.root}>
                <div className={classNames.title}>
                    <span>Component</span>
                    <div className={classNames.filter}>
                        {this.props.children}
                    </div>
                </div>
                {this.props.filters ? <div className={classNames.filters}>
                    <span>Filters</span>
                    <div className={classNames.toggles}>
                        {filters}
                    </div>
                </div> : undefined}
                <div className={classNames.console}>
                    <div className={classNames.toolbox}>
                        <div className={classNames.clear} onClick={this.handleClearConsole}>Clear</div>
                        <div className={classNames.clearFilters} onClick={this.handleClearFilters}>Clear Filters</div>
                        <div className={classNames.allFilters} onClick={this.handleSetAllFilters}>Set All Filters</div>
                    </div>
                    <div ref={this.logsRef} className={classNames.logs}>
                        {logs}
                    </div>
                </div>
            </div>
        )
    }

    scrollToBottom = () => {
        if (this.logsRef.current) {
            this.logsRef.current.scrollTop = this.logsRef.current.scrollHeight;
        }
    }

    handleIgnoreFilter = (filter) => {
        if (this.state.ignoreFilters.includes(filter)) {
            this.setState({
                ignoreFilters: this.state.ignoreFilters.filter(item => item !== filter)
            }, this.scrollToBottom)
        } else {
            this.setState({
                ignoreFilters: this.state.ignoreFilters.concat([filter])
            }, this.scrollToBottom)
        }
    }

    handleClearConsole = () => {
        this.setState({
            logs: []
        }, this.scrollToBottom)
    }

    handleClearFilters = () => {
        this.setState({
            ignoreFilters: [].concat(this.props.filters)
        }, this.scrollToBottom)
    }

    handleSetAllFilters = () => {
        this.setState({
            ignoreFilters: []
        }, this.scrollToBottom)
    }
}