/**
 * OBTAutoCompleteChips
 * @version 0.1
 * @author 신지유
 * @see common.js
 */

import * as React from 'react';
import remove from './remove.png';
import edit from './edit.png';
import { Events, CompositeProps, Util, CommonProps, createPropDefinitions, CommonType, CommonDefinitions } from '../Common';
import debounce from 'lodash.debounce';
import { Scrollbars } from 'react-custom-scrollbars';
import OBTFloatingPanel from "../OBTFloatingPanel";
import OBTTooltip from '../OBTTooltip/OBTTooltip';

const styles = require("./OBTAutoCompleteChips.module.scss");

interface IOBTAutoCompleteChips extends CompositeProps.Default, CommonProps.disabled, CommonProps.readonly, Events.onFocus {
    /**
     * @interface IItem
     * text와 value의 객체형태를 띄고 있는 데이터입니다.
     * (하나의 아이템)
     */
    value: IItem[],
    /**
     * @default true
     * 유효성 검사 여부를 지정합니다.
     * onValidate 함수에서 작성한 대로 유효성 검사를 할지에 대한 여부입니다.
     * onValidate 함수에서 작성한 형식에 맞지 않은 값이 들어올 경우 - 빨간색
     * onValidate 함수에서 작성한 형식에 맞는 값이 들어올 경우 - 파란색 
     * false 시, 색깔 지정하지 않은 chips
     */
    useValidation?: boolean,
    /**
     * @default 300
     * 입력이 멈춘 후 부터 검색시까지의 지연시간 입니다. (단위 : ms)
     */
    delayTime?: number,
    /**
     * @default true
     * 수정 버튼의 유무를 지정합니다.
     */
    hasEditButton?: boolean,
    /**
     * @default true
     * 삭제 버튼의 유무를 지정합니다.
     */
    hasDeleteButton?: boolean,

    /**
     * 텍스트란이 비어있을 때 나타날 문구를 설정합니다.
     */
    placeHolder?: string,
    /**
     * 아이템과 아이템 사이의 구분자를 지정할 수 있습니다.
     */
    seperators?: Array<string>,
    /**
     * @default true
     * 말줄임표 되는 드랍다운 리스트에 툴팁을 띄울 지에 대한 여부를 지정할 수 있습니다.
     */
    useTooltipEllipsisItem?: boolean,
    /**
     * @default false
     * wrapper의 높이를 auto가 아니라 직접 높이 지정 후 스크롤 모드로 할 것인지에 대한 여부를 지정합니다.
     */
    useScrollMode?: boolean,
    /**
     * 드랍다운 리스트를 열고 닫을 수 있는 토글 버튼과 휴지통 모양의 버튼을 생성할지에 대한 여부입니다.
     */
    useDirectoryMode?: boolean,
    /**
     * 드랍다운의 너비를 지정할 수 있습니다. 기본값 540px
     */
    dropDownWidth?: string,
    /**
     * value와 text가 존재시에 value를 감쌀 접두사를 지정할 수 있습니다. 기본값 <
     */
    valuePrefix?: string,
    /**
     * value와 text가 존재시에 value를 감쌀 접미사를 지정할 수 있습니다. 기본값 >   
     */
    valueSuffix?: string,
    /**
     * @param IItem[]
     * 데이터가 변경될 때 호출되는 함수입니다. 
     */
    onChange?: (e: Events.ChangeEventArgs<IItem[]>) => void,
    /**
     * keyWord 로 검색하는 함수이며 외부에서 작성하여 사용합니다.
     */
    onSearch: (e: string) => Promise<any>,
    /**
     * 콤마 구분자 등을 사용하여 여러개의 배열이 들어왔을 경우 한번에 검색하게 하게끔하는 onSearchList함수입니다.
     */
    onSearchList?: (e: string[]) => Promise<any>,
    /**
     * 토글 버튼을 클릭했을 때 데이터를 넘겨주는 함수
     */
    onSearchWhenToggleClick?: () => Promise<any>,
    /**
     * chip을 누를 때 호출되는 Callback 함수입니다.
     */
    onClick?: (e: ClickChipEventArgs) => void,
    /**
     * value에 적용할 정규식 유효성 테스트 함수입니다.
     * 기본 값은 이메일 유효성 테스트 입니다.
     * (value만 사용시, 이름<이메일> 의 형태로 사용된다는 것을 가정)
     */
    onValidate?: (e: IItem) => boolean
    /**
     * 데이터 추가 시 호출되는 이벤트 함수입니다.
     */
    onAddItem?: (e: AddChangeEventArgs) => void,
    /**
     * 데이터 변경 시 호출되는 이벤트 함수입니다.
     */
    onEditItem?: (e: EditChangeEventArgs) => void,
    /**
     * 데이터 삭제 시 호출되는 이벤트 함수입니다.
     */
    onRemoveItem?: (e: RemoveChangeEventArgs) => void,
    /**
     * 드롭다운리스트에 존재하는 휴지통 버튼을 눌렀을때 호출되는 이벤트 함수입니다.
     */
    onClickTrashButton?: (e: ClickTrashButtonEventArgs) => void,
    /**
     * 토글 버튼을 눌렀을때 호출되는 이벤트 함수입니다.
     */
    onClickToggleButton?: (e: ClickToggleButtonEventArgs) => void
}

export class AddChangeEventArgs extends Events.EventArgs {
    constructor(target: any, public readonly value: IItem) {
        super(target);
    }
}

export class EditChangeEventArgs extends Events.EventArgs {
    constructor(
        target: any,
        public readonly value: IItem,
        public readonly oldValue: IItem,
        public readonly index: number,
    ) {
        super(target);
    }
}

export class RemoveChangeEventArgs extends Events.EventArgs {
    constructor(
        target: any,
        public readonly value: IItem,
        public readonly index: number
    ) {
        super(target);
    }
}

export class ClickTrashButtonEventArgs extends Events.EventArgs {
    constructor(
        target: any,
        public readonly value: IItem,
        public readonly index: number
    ) {
        super(target);
    }
}

export class ClickChipEventArgs extends Events.EventArgs {
    constructor(
        target: any,
        public readonly value: IItem,
        public readonly e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) {
        super(target);
    }
}

export class ClickToggleButtonEventArgs extends Events.EventArgs {
    constructor(
        target: any,
        public readonly searchData: any[],
        public readonly isOpen: boolean
    ) {
        super(target);
    }
}

interface IState {
    inputValue: string,
    editValue: string,
    dropdownOpen: boolean,
    searchData?: IItem[],
    selectedDropdownIndex: number | null,
    isInputPossible: boolean,
    correctKeyword?: string,
    editInputWidth?: number
    editIndex?: number | null,
    validationResult?: Map<string, boolean>,
    deletedDropDownList?: any[],
    isClickToggleButton?: boolean
}

interface IItem {
    value: string,
    text?: string,
}

export default class OBTAutoCompleteChips extends React.Component<IOBTAutoCompleteChips, IState> {
    public static PropDefinitions = createPropDefinitions(
        {
            name: 'value', type: [{
                value: { type: 'string', description: '값 (이메일)' },
                text: { type: 'string', description: '표시 텍스트 (이름)' }
            }], description: '표시될 값을 지정합니다.'
        },
        { name: 'useValidation', type: CommonType.boolean, default: true, optional: true, description: 'onValidate 함수에서 작성한 대로 유효성 검사를 할지에 대한 여부입니다.' },
        { name: 'delayTime', type: CommonType.number, default: 300, optional: true, description: '입력이 멈춘 후 부터 검색시까지의 지연시간 입니다. (단위 : ms)' },
        { name: 'hasEditButton', type: CommonType.boolean, default: true, optional: true, description: '수정 버튼의 유무를 지정합니다.' },
        { name: 'hasDeleteButton', type: CommonType.boolean, default: true, optional: true, description: '삭제 버튼의 유무를 지정합니다.' },
        CommonDefinitions.placeHolder(),
        { name: 'seperators', type: ['Array<string>'], default: [',', ';'], description: '아이템과 아이템 사이의 구분자를 지정할 수 있습니다.', optional: true },
        { name: 'useTooltipEllipsisItem', type: CommonType.boolean, default: true, description: '말줄임표 되는 드랍다운 리스트에 툴팁을 띄울 지에 대한 여부를 지정할 수 있습니다.', optional: true },
        { name: 'useScrollMode', type: CommonType.boolean, default: false, description: 'wrapper의 높이를 auto가 아니라 직접 높이 지정 후 스크롤 모드로 할 것인지에 대한 여부를 지정합니다.', optional: true },
        { name: 'dropDownWidth', type: CommonType.string, description: '드랍다운의 너비를 지정할 수 있습니다. 기본값 540px', optional: true },
        { name: 'valuePrefix', type: CommonType.string, description: 'value와 text가 존재시에 value를 감쌀 접두사를 지정할 수 있습니다. 기본값 <', optional: true },
        { name: 'valueSuffix', type: CommonType.string, description: 'value와 text가 존재시에 value를 감쌀 접미사를 지정할 수 있습니다. 기본값 > ', optional: true },
        CommonDefinitions.onChange({
            type: [{
                value: { type: 'string', description: '값 (이메일)' },
                text: { type: 'string', description: '표시 텍스트 (이름)' }
            }],
            optional: true
        }),
        {
            name: 'onValidate', type: CommonType.function, parameters: {
                name: 'e',
                type: ['IItem'],
            }, result: CommonType.boolean, description: 'value에 적용할 정규식 유효성 테스트 함수입니다. 기본 값은 이메일 유효성 테스트 입니다. (value만 사용시, 이름<이메일> 의 형태로 사용된다는 것을 가정)', optional: true
        },
        {
            name: 'onSearch', type: CommonType.function, parameters: {
                name: 'e',
                type: CommonType.string,
                description: '검색 문자열(Keyword)'
            }, result: 'Promise<[{ value: string, text: string }]>', description: 'Keyword 로 검색하는 함수이며 외부에서 작성하여 사용합니다.'
        },
        {
            name: 'onAddItem', type: CommonType.function, parameters: {
                name: 'e',
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    value: {
                        value: { type: 'string', description: '값 (이메일)' },
                        text: { type: 'string', description: '표시 텍스트 (이름)' }
                    }
                }
            }, optional: true, description: '데이터 추가 시 호출되는 Callback 함수입니다.'
        },
        {
            name: 'onEditItem', type: CommonType.function, parameters: {
                name: 'e',
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    value: {
                        value: { type: 'string', description: '값 (이메일)' },
                        text: { type: 'string', description: '표시 텍스트 (이름)' }
                    },
                    oldValue: {
                        type: {
                            value: { type: 'string', description: '값 (이메일)' },
                            text: { type: 'string', description: '표시 텍스트 (이름)' }
                        }, description: '수정전 값'
                    },
                    index: { type: CommonType.number, description: '수정된 항목 index' }
                }
            }, optional: true, description: '데이터 변경 시 호출되는 Callback 함수입니다.'
        },
        {
            name: 'onRemoveItem', type: CommonType.function, parameters: {
                name: 'e',
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    value: {
                        value: { type: 'string', description: '값 (이메일)' },
                        text: { type: 'string', description: '표시 텍스트 (이름)' }
                    },
                    index: { type: CommonType.number, description: '삭제된 항목 index' }
                }
            }, optional: true, description: '데이터 삭제 시 호출되는 Callback 함수입니다.'
        },
        {
            name: 'onClick', type: CommonType.function, parameters: {
                name: 'e',
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    value: {
                        value: { type: 'string', description: '값 (이메일)' },
                        text: { type: 'string', description: '표시 텍스트 (이름)' }
                    },
                    e: { type: CommonType.function, description: 'React.MouseEvent<HTMLDivElement, MouseEvent>' }
                }
            }, optional: true, description: 'chip 클릭 시 호출되는 Callback 함수입니다.'
        },
        CommonDefinitions.onFocus()
    );

    public static defaultProps: Partial<IOBTAutoCompleteChips> = {
        useValidation: true,
        hasEditButton: true,
        hasDeleteButton: true,
        delayTime: 300,
        useTooltipEllipsisItem: true,
        onValidate: (value) => {
            if (!value) return false;
            const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
            const seperatorEmailReg = /[^< ]+(?=>)/g;
            const result = value.value.match(seperatorEmailReg);

            if (result && reg.test(result[0])) {
                return true;
            }
            else if (!result && reg.test(value.value)) {
                return true;
            }
            else return false;
        },
        seperators: [',', ';'],
        useScrollMode: false
    }

    public state: IState = {
        inputValue: "",
        editValue: "",
        dropdownOpen: false,
        selectedDropdownIndex: null,
        isInputPossible: true,
        correctKeyword: "",
        editInputWidth: 100,
        editIndex: null,
        deletedDropDownList: []
    }

    myRefs = {
        wrapper: React.createRef<HTMLDivElement>(),
        input: React.createRef<HTMLInputElement>(),
        editInput: React.createRef<HTMLInputElement>(),
        scrollBar: React.createRef<Scrollbars>(),
        listWrapper: React.createRef<HTMLUListElement>(),
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleOutsideClick);

        const validationResult = new Map<string, boolean>();

        this.props.value.forEach((item) => {
            if (this.props.onValidate) {
                const isValid = this.props.onValidate && this.props.onValidate(item);
                validationResult.set(item.value, isValid);
            }
        });

        this.setState({
            validationResult: validationResult
        })
    }

    componentDidUpdate(prevProps: IOBTAutoCompleteChips, prevState: IState) {
        if (prevProps.delayTime !== this.props.delayTime) {
            this.showSearchedData = this.createShowSearchedData();
        }

        if (this.props.value !== prevProps.value) {
            const validationResult = new Map<string, boolean>();

            this.props.value.forEach((item) => {
                if (this.state.validationResult && this.state.validationResult.has(item.value)) {
                    validationResult.set(item.value, this.state.validationResult.get(item.value)!);
                } else if (this.props.onValidate) {
                    const isValid = this.props.onValidate && this.props.onValidate(item);
                    validationResult.set(item.value, isValid);
                }
            });

            this.setState({
                validationResult: validationResult
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    public focus(): void {
        if (!this.props.frozen && !this.props.disabled && !this.props.readonly && this.state.isInputPossible && this.myRefs.input.current) {
            this.myRefs.input.current.focus();
        }
    }

    private addChipItem = () => {
        if (!this.state.inputValue || !this.state.isInputPossible) {
            return;
        }

        if (!this.props.seperators) {
            throw new Error('seperators는 null또는 undefiend가 될 수 없습니다.');
        }

        const addedItems = splitBySeparators(this.state.inputValue, this.props.seperators, true)
            .filter((item) => {
                const notEmpty = item && item.length > 0;
                const hasSameValue = this.props.value.some(itemForUniqueCheck => itemForUniqueCheck.value === item);
                return notEmpty && !hasSameValue;
            });

        if (this.props.onSearchList && addedItems.length > 1) {
            let addedItemPromises = this.props.onSearchList && this.props.onSearchList(addedItems).then((searchedItems: any[]) => {
                const resultChipItems = addedItems.map((item) => {
                    let chipItem = {
                        value: item,
                        text: undefined
                    }
                    searchedItems.map((searchedItem) => {
                        if (searchedItem.value === chipItem.value) {
                            return chipItem = {
                                ...chipItem,
                                text: searchedItem.text
                            }
                        }
                        else {
                            return chipItem;
                        }
                    });
                    return chipItem
                })
                return resultChipItems;
            });

            this.setState({
                isInputPossible: false,
            }, () => {
                Promise.all([addedItemPromises]).then((resultList) => {
                    resultList.forEach((resultItems) => {
                        if (this.props.onAddItem) {
                            resultItems.forEach((resultItem) => {
                                this.props.onAddItem!(new AddChangeEventArgs(this, resultItem));
                            })
                        }

                        if (this.props.onChange) {
                            Util.invokeEvent<Events.ChangeEventArgs<IItem[]>>(
                                this.props.onChange,
                                new Events.ChangeEventArgs<IItem[]>(this, this.props.value.concat(resultItems)));
                        };
                    })
                }).finally(() => {
                    this.setState({
                        isInputPossible: true,
                        inputValue: "",
                        searchData: [],
                        dropdownOpen: false,
                        selectedDropdownIndex: null,
                        isClickToggleButton: false
                    });
                });
            });
        }

        else {
            let addedItemPromises = addedItems.map((item) => {
                const chipItem = {
                    value: item,
                    text: undefined
                }
                return this.props.onSearch(chipItem.value)
                    .then((searchResultList: any[]) => {
                        if (searchResultList.length > 0 && searchResultList[0].value === chipItem.value) {
                            return {
                                ...chipItem,
                                text: searchResultList[0].text
                            }
                        } else {
                            return chipItem;
                        }
                    });
            });

            this.setState({
                isInputPossible: false
            }, () => {
                Promise.all(addedItemPromises).then((resultList) => {
                    if (this.props.onAddItem) {
                        resultList.forEach((resultItem) => {
                            this.props.onAddItem!(new AddChangeEventArgs(this, resultItem));
                        })
                    }

                    if (this.props.onChange) {
                        Util.invokeEvent<Events.ChangeEventArgs<IItem[]>>(
                            this.props.onChange,
                            new Events.ChangeEventArgs<IItem[]>(this, this.props.value.concat(resultList)));
                    };
                }).finally(() => {
                    this.setState({
                        isInputPossible: true,
                        inputValue: "",
                        searchData: [],
                        dropdownOpen: false,
                        selectedDropdownIndex: null,
                    });
                });
            });
        }
    }

    private editChipItem = (editIndex: number) => {
        if (!this.state.isInputPossible) {
            return;
        }

        const hasSameValue = this.props.value.some(item => item.value === this.state.editValue);
        if (hasSameValue || !this.state.editValue) {
            this.setState({
                editIndex: null
            });

            return;
        }

        this.setState({
            isInputPossible: false
        }, () => {
            this.props.onSearch(this.state.editValue).then(searchResult => {
                const editedItem: IItem = {
                    value: this.state.editValue,
                    text: undefined
                }
                if (searchResult.length > 0 && searchResult[0].value === this.state.editValue) {
                    editedItem.text = searchResult[0].text;
                }

                this.props.onEditItem && this.props.onEditItem(new EditChangeEventArgs(this, editedItem, this.props.value[editIndex], editIndex));

                if (this.props.onChange) {
                    const newValue = this.props.value.map((propsValue, index) => {
                        if (index === editIndex) {
                            return editedItem
                        } else {
                            return propsValue
                        }
                    });

                    Util.invokeEvent<Events.ChangeEventArgs<IItem[]>>(
                        this.props.onChange,
                        new Events.ChangeEventArgs<IItem[]>(this, newValue));
                };
            }).finally(() => {
                this.setState({
                    isInputPossible: true,
                    editIndex: null
                }, () => {
                    if (this.myRefs.input.current && !this.props.useScrollMode) {
                        this.myRefs.input.current.focus();
                    }
                });
            })
        });
    }

    private selectDropdownItem = (selectedItem: IItem) => {
        if (!this.state.isInputPossible) {
            return;
        }

        let hasSameValue: boolean = this.props.value.some((item) => {
            return selectedItem.value === item.value
        });

        if (!hasSameValue) {
            this.props.onAddItem && this.props.onAddItem(new AddChangeEventArgs(this, selectedItem));

            if (this.props.onChange) {
                Util.invokeEvent<Events.ChangeEventArgs<IItem[]>>(
                    this.props.onChange,
                    new Events.ChangeEventArgs<IItem[]>(this, this.props.value.concat(selectedItem)));
            };
        }

        this.setState({
            inputValue: "",
            dropdownOpen: false,
            selectedDropdownIndex: null,
            searchData: [],
            isClickToggleButton: false
        }, () => {
            if (this.myRefs.input.current) {
                this.myRefs.input.current.focus();
            }
        });
    }

    private createShowSearchedData = () => {
        this.showSearchedData = debounce((value: string) => {
            this.props.onSearch(value).then((data) => {
                if (data && data.length > 0 && value && value.length > 1) {
                    this.setState({
                        dropdownOpen: true,
                        searchData: data,
                        selectedDropdownIndex: 0,
                        isClickToggleButton: false
                    }, () => {
                        if (this.state.searchData) {
                            this.state.searchData.forEach((data) => {
                                if (data["value"].match(value) || (data.text && data["text"].match(value))) {
                                    this.setState({
                                        correctKeyword: value
                                    });
                                }
                            });
                        }
                    });
                }
            })
        }, this.props.delayTime);
        return this.showSearchedData;
    }

    private showSearchedData = this.createShowSearchedData();

    private measureText = (value: string): number => {
        let metricsWidth;

        const canvas = document.createElement("canvas");
        const context = canvas.getContext('2d');

        if (context) {
            context.font = '12px NSKR, Dotum, Helvetica, Apple SD Gothic Neo, sans-serif, 돋움';
            const metrics = context.measureText(value);
            metricsWidth = metrics.width
        }

        return metricsWidth;
    }

    private updateDropdownScrollTop = () => {
        if (this.state.searchData && this.state.selectedDropdownIndex && this.state.searchData && this.myRefs.scrollBar.current) {

            const scrollTop = this.myRefs.scrollBar.current.scrollTop;
            const dropDownWrapperHeight = 270;
            const itemHeight = 27;
            const currentItemHeight = itemHeight * this.state.selectedDropdownIndex;

            if (Math.abs(scrollTop - currentItemHeight) >= 270 && scrollTop + dropDownWrapperHeight <= currentItemHeight) {
                this.myRefs.scrollBar.current.scrollTop = scrollTop + dropDownWrapperHeight;

            } else if (scrollTop > currentItemHeight) {
                this.myRefs.scrollBar.current.scrollTop = scrollTop - dropDownWrapperHeight;
            }
        }
    }

    private handleFilterDeletedItem = (data: any) => {
        return data.filter((item, index) => {
            if (this.state.deletedDropDownList && this.state.deletedDropDownList.length > 0) {
                return !this.state.deletedDropDownList.includes(item["value"])
            }
            else return data;
        });
    }

    private handleOutsideClick = (e) => {
        if ((this.myRefs.wrapper.current && this.myRefs.wrapper.current.contains(e.target)) ||
            (this.myRefs.listWrapper.current && this.myRefs.listWrapper.current.contains(e.target))) {
            return;
        }

        if (this.props.useDirectoryMode && this.state.isClickToggleButton) {
            this.props.onClickToggleButton && this.props.onClickToggleButton(
                new ClickToggleButtonEventArgs(
                    this,
                    [],
                    false
                )
            );

            this.setState({
                dropdownOpen: false,
                isClickToggleButton: false
            })
        }
        else {
            this.addChipItem();
        }
    }

    private handleInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (this.state.isInputPossible === false) {
            return;
        }

        if (e.nativeEvent.isComposing === false &&
            (e.key === "Enter" || (this.props.seperators && this.props.seperators.some((seperator) => seperator === e.key)))) {
            if (this.state.searchData && this.state.searchData.length > 0 && this.state.selectedDropdownIndex !== null) {
                const select = this.state.searchData[this.state.selectedDropdownIndex];
                this.selectDropdownItem(select)
            } else {
                this.addChipItem();
                if (this.myRefs.input.current && !this.myRefs.editInput.current) {
                    this.myRefs.input.current.focus();
                }
            }
        }
    }

    private handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (this.state.isInputPossible === false) {
            return;
        }

        if (e.key === "Backspace" && this.state.isInputPossible && !this.props.readonly) {
            if (this.state.inputValue === "" && this.props.value.length > 0) {
                const lastItem = this.props.value.pop();
                const items: IItem[] = [];

                this.props.value.forEach((item) => {
                    if (lastItem && lastItem.value !== item.value) {
                        items.push(item);
                    }
                });

                if (lastItem && this.props.onRemoveItem) {
                    this.props.onRemoveItem(new RemoveChangeEventArgs(this, lastItem, this.props.value.length));
                }

                if (this.props.onChange) {
                    Util.invokeEvent<Events.ChangeEventArgs<IItem[]>>(
                        this.props.onChange,
                        new Events.ChangeEventArgs<IItem[]>(this, items));
                }
            };
        } else if (e.nativeEvent.isComposing === false &&
            e.key === "ArrowDown" &&
            this.state.dropdownOpen && (this.state.searchData || []).length > 0) {
            e.preventDefault();

            if (this.state.selectedDropdownIndex !== null && this.state.searchData && this.state.selectedDropdownIndex < this.state.searchData.length) {
                if (this.state.selectedDropdownIndex === this.state.searchData.length - 1) {
                    this.setState({
                        selectedDropdownIndex: 0
                    }, () => {
                        if (this.state.selectedDropdownIndex === 0) {
                            this.myRefs.scrollBar.current.scrollTop = 0;
                        }
                    });
                } else {
                    this.setState({
                        selectedDropdownIndex: this.state.selectedDropdownIndex + 1
                    }, () => {
                        this.updateDropdownScrollTop();
                    });
                }
            }
        } else if (e.nativeEvent.isComposing === false &&
            e.key === "ArrowDown" &&
            this.props.useDirectoryMode &&
            !this.state.inputValue &&
            this.props.onSearchWhenToggleClick) {
            this.props.onSearchWhenToggleClick().then((data) => {
                if (data && data.length > 0) {
                    const searchData = this.handleFilterDeletedItem(data);
                    this.props.onClickToggleButton && this.props.onClickToggleButton(
                        new ClickToggleButtonEventArgs(
                            this,
                            data,
                            true
                        )
                    );

                    this.setState({
                        dropdownOpen: true,
                        isClickToggleButton: true,
                        searchData: searchData,
                        selectedDropdownIndex: 0
                    });
                }
            })
        } else if (e.nativeEvent.isComposing === false &&
            e.key === "ArrowUp" &&
            this.state.dropdownOpen &&
            this.state.searchData &&
            (this.state.searchData || []).length > 0 && this.state.selectedDropdownIndex !== null) {
            e.preventDefault();

            if (this.state.selectedDropdownIndex === 0) {
                this.setState({
                    selectedDropdownIndex: this.state.searchData.length - 1
                }, () => {
                    if (this.state.searchData && this.state.selectedDropdownIndex === this.state.searchData.length - 1) {
                        this.myRefs.scrollBar.current.scrollTop = 27 * this.state.searchData.length - 1;
                    }
                });
            }
            if (this.state.selectedDropdownIndex > 0) {
                this.setState({
                    selectedDropdownIndex: this.state.selectedDropdownIndex - 1
                }, () => {
                    this.updateDropdownScrollTop();
                });
            }
        }
    }

    private handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (this.state.isInputPossible === false) {
            return;
        }

        let isNormalInput = true;

        //seperator 남는 현상 제거를 위해 input에 seperator(ex. 콤마)가 남아있을 경우 제거하는 logic
        if (this.props.seperators) {
            this.props.seperators.forEach((seperator) => {
                if (seperator.includes(e.target.value)) {
                    isNormalInput = false
                }
            });
        }

        this.setState({
            inputValue: isNormalInput ? e.target.value : "",
            searchData: [],
            selectedDropdownIndex: null,
            correctKeyword: "",
        });

        this.showSearchedData(e.target.value);
    }

    private handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const actualTextSize = this.measureText(e.target.value) + 30;

        this.setState({
            editValue: e.target.value,
            editInputWidth: actualTextSize
        });
    }

    private handleEditInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select();
    }

    private handleEditInputKeyUp = (editIndex: number, e: React.KeyboardEvent<HTMLInputElement>) => {
        if (this.state.isInputPossible === false) {
            return;
        }

        if (e.key === "Enter") {
            this.editChipItem(editIndex);
        } else if (e.key === "Escape") {
            this.setState({
                editValue: '',
                editIndex: null
            })
        }
    }

    private handleEditButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, editValue: string, index: number): void => {
        e.stopPropagation();

        const actualTextSize = this.measureText(editValue) + 45;

        this.setState({
            editInputWidth: actualTextSize,
            editIndex: index,
            editValue: editValue
        }, () => {
            if (this.myRefs.editInput.current) {
                this.myRefs.editInput.current.focus();
            }
        });
    }

    private handleRemoveButtonClick = (removeValue: string, index: number, e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.stopPropagation();

        const items = this.props.value.filter((item) => {
            if (item.value !== removeValue) {
                return true;
            } else {
                this.props.onRemoveItem && this.props.onRemoveItem(new RemoveChangeEventArgs(this, item, index));
                return false;
            }
        });

        if (this.props.onChange) {
            Util.invokeEvent<Events.ChangeEventArgs<IItem[]>>(
                this.props.onChange,
                new Events.ChangeEventArgs<IItem[]>(this, items));
        }

        if (this.myRefs.input.current) {
            this.myRefs.input.current.focus();
        }
    }

    private handleWrapperClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();

        if (this.state.isInputPossible === false) {
            return;
        }

        if (this.myRefs.input.current && !this.myRefs.editInput.current) {
            this.myRefs.input.current.focus();
        }
    }

    private handleDropDownSelectItem = (item: IItem, index: number) => {
        this.selectDropdownItem(item);
    }

    private handleDropdownItemMouseEnter = (item: IItem, index: number) => {
        this.setState({
            selectedDropdownIndex: index
        });
    }

    private handleDropDownTrashClick = (item: IItem, index: number) => {
        if (this.state.deletedDropDownList && this.state.searchData) {
            const hasSameValue = this.state.deletedDropDownList.some(itemForUniqueCheck => itemForUniqueCheck === item.value);
            !hasSameValue && this.state.deletedDropDownList.push(item.value);

            this.props.onClickTrashButton && this.props.onClickTrashButton(
                new ClickTrashButtonEventArgs(
                    this,
                    item,
                    index
                )
            )

            if (!this.props.onSearchWhenToggleClick) {
                throw new Error("directoryMode 사용시 onSearchWhenToggleClick 를 꼭 사용해주세요.");
            }

            this.props.onSearchWhenToggleClick().then((data) => {
                const searchedData = this.handleFilterDeletedItem(data);

                this.setState({
                    searchData: searchedData,
                });
            });
        }
    }

    private handleToggleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (this.state.dropdownOpen) {
            this.setState({
                dropdownOpen: false,
                isClickToggleButton: false
            });

            this.props.onClickToggleButton && this.props.onClickToggleButton(
                new ClickToggleButtonEventArgs(
                    this,
                    [],
                    false
                )
            );
            return;
        }

        this.setState({
            correctKeyword: ""
        }, () => {
            if (!this.props.onSearchWhenToggleClick) {
                throw new Error("directoryMode 사용시 onSearchWhenToggleClick 를 꼭 사용해주세요.");
            }

            this.props.onSearchWhenToggleClick().then((data) => {
                const searchedData = this.handleFilterDeletedItem(data);

                const clickToggleButtonEvent = new ClickToggleButtonEventArgs(
                    this,
                    searchedData,
                    true
                );
                const isClickToggleButton = clickToggleButtonEvent && searchedData.length > 0 ? true : false;

                this.props.onClickToggleButton && this.props.onClickToggleButton(
                    new ClickToggleButtonEventArgs(
                        this,
                        searchedData,
                        isClickToggleButton
                    )
                )

                this.setState({
                    searchData: searchedData,
                    dropdownOpen: searchedData.length === 0 ? false : true,
                    selectedDropdownIndex: 0,
                    isClickToggleButton: isClickToggleButton
                })
            })
        })
    }

    private handleChipClick = (item: IItem, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();

        if (!this.props.onClick) {
            return;
        }

        const clickable =
            !this.props.disabled
            && !this.props.frozen
            && !this.state.inputValue
            && this.state.isInputPossible;

        if (clickable) {
            this.props.onClick(new ClickChipEventArgs(this, item, e));
        }
    }

    private renderChipItem = (item: IItem, itemIndex: number) => {
        const isItemValid = this.state.validationResult && this.state.validationResult.get(item.value);

        return (
            <div
                key={itemIndex}
                className={Util.getClassNames(styles.item,
                    !this.props.useValidation && styles.default,
                    this.props.useValidation && isItemValid && styles.correct,
                    this.props.useValidation && !isItemValid && styles.uncorrect,
                    this.props.disabled && styles.disabled)}
                style={{
                    cursor: this.props.onClick ? 'pointer' : 'default'
                }}
                onClick={(e) => this.handleChipClick(item, e)}
            >
                <div>
                    {item.text ? `${item.text} ${this.props.valuePrefix ? this.props.valuePrefix : '<'}${item.value}${this.props.valueSuffix ? this.props.valueSuffix : '>'}` : item.value}

                    {this.props.hasEditButton &&
                        <button
                            disabled={this.props.readonly || !this.state.isInputPossible ? true : false}
                            onClick={(e) => this.handleEditButtonClick(e, item.value, itemIndex)}
                        >
                            {!this.props.disabled && <img src={edit} alt="edit" />}
                        </button>
                    }

                    {this.props.hasDeleteButton &&
                        <button
                            disabled={this.props.readonly || !this.state.isInputPossible ? true : false}
                            onClick={(e) => this.handleRemoveButtonClick(item.value, itemIndex, e)}
                        >
                            {!this.props.disabled && <img src={remove} alt="remove" />}
                        </button>
                    }
                </div>
            </div>
        )
    }

    private renderChipItemEditMode = (item: IItem, itemIndex: number) => {
        const isItemValid = this.state.validationResult && this.state.validationResult.get(item.value);

        const inputElementStyle = {
            width: this.state.editInputWidth,
            backgroundColor: "inherit",
            maxWidth: "700px",
            fontSize: "12px"
        } as React.CSSProperties

        return (
            <div
                key={itemIndex}
                className={Util.getClassNames(
                    styles.editItem,
                    !this.props.useValidation && styles.default,
                    this.props.useValidation && isItemValid ? styles.correct : styles.uncorrect
                )}
            >
                <input
                    type="text"
                    ref={this.myRefs.editInput}
                    style={inputElementStyle}
                    value={this.state.editValue}
                    readOnly={!this.state.isInputPossible ? true : false}
                    onChange={this.handleEditInputChange}
                    onFocus={this.handleEditInputFocus}
                    onBlur={(e) => this.editChipItem(itemIndex)}
                    onKeyUp={(e) => this.handleEditInputKeyUp(itemIndex, e)}
                />
            </div>
        )
    }

    render() {
        const dropDownStyle = {
            height: this.state.searchData && this.state.searchData.length < 10 ? `${this.state.searchData.length * 27}px` : "270px",
            width: this.props.dropDownWidth && this.props.dropDownWidth
        }

        return (
            <div className={'OBTAutoCompleteChips_Wrapper'}>
                <div
                    id={this.props.id}
                    ref={this.myRefs.wrapper}
                    style={Util.getWrapperStyle(this.props)}
                    data-orbit-component={'OBTAutoCompleteChips'}
                    onClick={this.handleWrapperClick}
                    onFocus={this.props.onFocus}
                    className={Util.getClassNames(
                        styles.wrapper,
                        this.props.className,
                        this.props.disabled && styles.disabled,
                        this.props.readonly && styles.readOnly,
                        this.props.height && styles.height,
                        this.props.useScrollMode && styles.useScrollMode
                    )}
                >
                    {this.props.value.map((item, index) => {
                        if (index === this.state.editIndex) {
                            return this.renderChipItemEditMode(item, index)
                        }
                        return this.renderChipItem(item, index)
                    })}

                    {!this.props.disabled &&
                        <div className={styles.inputWrapper}>
                            <input
                                ref={this.myRefs.input}
                                type="text"
                                value={this.state.inputValue}
                                readOnly={this.props.readonly || !this.state.isInputPossible ? true : false}
                                placeholder={this.props.placeHolder && this.props.value.length === 0 ? this.props.placeHolder : undefined}
                                onChange={this.handleInputChange}
                                onKeyUp={this.handleInputKeyUp}
                                onKeyDown={this.handleInputKeyDown}
                            />

                            {this.props.useDirectoryMode &&
                                <button
                                    className={Util.getClassNames(
                                        styles.toggleButton,
                                        this.state.dropdownOpen ? styles.open : null
                                    )}
                                    onClick={this.handleToggleButtonClick}
                                />
                            }
                        </div>
                    }
                </div>

                {this.state.dropdownOpen && this.state.searchData && this.state.searchData.length > 0 &&
                    <>
                        <OBTFloatingPanel
                            value={true}
                            anchor={this.myRefs.input}
                            position={OBTFloatingPanel.Position.bottom}
                            align={OBTFloatingPanel.Align.near}
                        >
                            <div
                                ref={this.myRefs.scrollBar}
                                className={styles.dropDown}
                                style={dropDownStyle}
                            >
                                <ul ref={this.myRefs.listWrapper}>
                                    {this.state.searchData && this.state.searchData.map((item: IItem, index) => {
                                        return (
                                            <DropDownItem
                                                index={index}
                                                isSelected={this.state.selectedDropdownIndex === index}
                                                value={item}
                                                correctKeyword={this.state.correctKeyword}
                                                key={index}
                                                onSelectedItem={this.handleDropDownSelectItem}
                                                onMouseEnter={this.handleDropdownItemMouseEnter}
                                                onClickTrashButton={this.handleDropDownTrashClick}
                                                isShowTooltip={this.props.useTooltipEllipsisItem}
                                                dropDownWidth={this.props.dropDownWidth}
                                                useDirectoryMode={this.props.useDirectoryMode}
                                                isClickToggleButton={this.state.isClickToggleButton}
                                                valuePrefix={this.props.valuePrefix}
                                                valueSuffix={this.props.valueSuffix}
                                            />
                                        )
                                    })}
                                </ul>
                            </div>
                        </OBTFloatingPanel>
                    </>
                }
            </div>
        );
    }
}

interface IDropDownItem {
    value: IItem,
    index: number,
    isSelected: boolean,
    correctKeyword?: string,
    isShowTooltip?: boolean,
    valuePrefix?: string,
    valueSuffix?: string,
    dropDownWidth?: string,
    useDirectoryMode?: boolean,
    isClickToggleButton?: boolean
    onSelectedItem: (value: IItem, index: number) => void,
    onMouseEnter: (value: IItem, index: number) => void,
    onClickTrashButton: (value: IItem, index: number) => void,
}

class DropDownItem extends React.PureComponent<IDropDownItem> {

    itemRef = React.createRef<HTMLParagraphElement>();

    private handleDropdownListMouseMove = () => {
        this.props.onMouseEnter(this.props.value, this.props.index);
    }

    private handleDropdownListOnClick = () => {
        this.props.onSelectedItem(this.props.value, this.props.index);
    }

    private handleDropDownTrashClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        this.props.onClickTrashButton(this.props.value, this.props.index)
    }

    renderLiText = (searhData: IItem, key: number) => {
        let entireText: string = searhData.text ? `${searhData.text} ${this.props.valuePrefix ? this.props.valuePrefix : '<'}${searhData.value}${this.props.valueSuffix ? this.props.valueSuffix : '>'}` : searhData.value;
        let dropDownItem: any;

        if (this.props.correctKeyword && entireText.indexOf(this.props.correctKeyword) > -1) {
            dropDownItem = entireText.split(this.props.correctKeyword).map((str, i, arr) => {
                return (
                    <React.Fragment key={i}>
                        <span>
                            {str}
                        </span>
                        {i < arr.length - 1 ?
                            <span style={{ color: 'rgb(72,143,243)' }}>
                                {this.props.correctKeyword}
                            </span> : undefined}
                    </React.Fragment>
                )
            });
        }
        else {
            dropDownItem = (
                <span>{entireText}</span>
            )
        }

        const dropDownWidth = this.props.useDirectoryMode && this.props.dropDownWidth && this.props.isClickToggleButton ?
            Number(this.props.dropDownWidth.replace(/[^0-9]/g, '')) - 38 :
            this.props.useDirectoryMode && !this.props.dropDownWidth && this.props.isClickToggleButton ? 502 :
                !this.props.useDirectoryMode && this.props.dropDownWidth ? Number(this.props.dropDownWidth.replace(/[^0-9]/g, '')) - 20 :
                    520

        let dropDownItems = (
            <div className={styles.dropDownItemInner}>
                <p
                    key={key}
                    ref={this.itemRef}
                    style={{ width: `${dropDownWidth}px` }}
                    className={styles.dropDownItemInner}
                >
                    {dropDownItem}
                </p>
                {this.props.useDirectoryMode && this.props.isClickToggleButton &&
                    <button
                        className={styles.deleteButton}
                        onClick={this.handleDropDownTrashClick}
                    />
                }
            </div>
        );

        return (
            this.props.isShowTooltip && this.props.isSelected && this.itemRef.current && this.itemRef.current.scrollWidth > this.itemRef.current.clientWidth ?
                <OBTTooltip
                    value={true}
                    labelText={this.props.value.value}
                    className={styles.tooltip}
                    overrideSize={false}
                    focusValue={false}
                >
                    {dropDownItems}
                </OBTTooltip> : dropDownItems
        )
    }

    render() {
        const styleSheet = {
            backgroundColor: this.props.isSelected ? '#eff7ff' : undefined
        }

        return (
            <li
                className={styles.dropdownItem}
                style={styleSheet}
                onMouseMove={this.handleDropdownListMouseMove}
                onClick={this.handleDropdownListOnClick}
            >
                {this.renderLiText(this.props.value, this.props.index)}
            </li>
        )
    }
}

function splitBySeparators(value: string, separators: string[], removeDuplicate: boolean) {
    const propsSeperator = separators.map((item) => {
        return '[' + item + ']'
    }).join('|');

    let resultList: string[] = [];
    if (removeDuplicate) {
        new Set<string>(value.split(new RegExp(`${propsSeperator}`))).forEach(item => {
            resultList.push(item.trim());
        })
    } else {
        resultList = (value.split(new RegExp(`${propsSeperator}`)));
    }

    return resultList;
}