import React, { Component } from "react";
import PropTypes from "prop-types";
import LUXCheckBox from "luna-rocket/LUXCheckBox";
import { OrbitInternalLangPack } from '../../../Common/Util';

/**
 * 검색한 리스트의 결과의 헤더 부분입니다.
 */
class Header extends Component {
  static propTypes = {
    /**
     * 체크박스를 클릭하면 발생하는 콜백입니다.
     */
    onClickCheckBoxAll: PropTypes.func,
    /**
     * 선태 여부입니다.
     */
    isCheckedAll: PropTypes.bool
  };

  constructor() {
    super();
    this.state = {
      checked: false
    };
  }

  // 체크박스를 토글한다.
  handleToggle = event => {
    event.preventDefault();
    event.stopPropagation();
    const {
      // props
      isCheckedAll,
      //func
      onClickCheckBoxAll
    } = this.props;
    onClickCheckBoxAll(!isCheckedAll);
  };

  /**
   * Render
   */
  render() {
    const {
      // props
      isCheckedAll
    } = this.props;

    return (
      <li
        style={{
          cursor: "pointer",
          borderBottom: "1px solid #dbdbdb",
          backgroundColor: "white",
          height: '24px',
          lineHeight: '24px'
        }}
        onClick={this.handleToggle}
      >
        <div className="LS_form" style={{ paddingLeft: '8px', fontFamily: 'inherit' }}>
          <LUXCheckBox
            onClick={this.handleToggle}
            style={{ height: '24px' }}
            size={14}
            checked={isCheckedAll}
            labelText={OrbitInternalLangPack.getText('WE000000529', '전체')}
          />
        </div>
      </li >
    );
  }
}

export default Header;
