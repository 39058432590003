import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addYears } from './UFODateUtils';
import { LUXCalendar } from 'luna-rocket/LUXDatePicker';
import OBTFloatingPanel from '../../OBTFloatingPanel';
import { Util } from '../../Common';
import LUXButton from 'luna-rocket/LUXButton';
import { OrbitInternalLangPack } from '../../Common/Util';

const moduleStyles = require('./UFODateFieldDialog.module.scss');

const styles = {
    button: {
        background: '#fff',
        border: '1px solid #e0e0e0',
        boxSizing: 'border-box',
        color: '#4a4a4a',
        display: 'inline-block',
        fontSize: '11px',
        fontFamily: '돋움, Dotum, Helvetica, Apple SD Gothic Neo, sans-serif',
        height: '21px',
        margin: '-1px 3px 0 -1px',
        outline: 'none',
        padding: '1px 6px 0',
        position: 'relative',
        textAlign: 'center',
        verticalAlign: 'top',
    }
};

class UFODateFieldDialog extends Component {
    static propTypes = {
        /**
         * @ignore
         * 달력에서 일주일 중 첫 번째 요일을 지정합니다.
         * 범위는 0(일요일) ~ 6(토요일)이며, Default로 0(일요일)이 지정되어 있습니다.
         */
        firstDayOfWeek: PropTypes.number,
        /**
         * 달력에 보여질 라인 수를 지정합니다.
         */
        fixedLine: PropTypes.number,
        /**
         * 기본으로 선택될 날짜 값을 지정합니다.
         */
        initialDate: PropTypes.object,
        /**
         * 원하는 날짜 표현형식의 지역을 지정합니다.
         * ex) en: 미국, fr: 프랑스, ko: 대한민국
         */
        locale: PropTypes.string,
        /**
         * 선택 가능한 날짜에 대한 범위의 최대(끝) 날짜를 지정하는 속성입니다.
         * 기본으로 현재 날짜 +100년으로 지정되어 있습니다.
         */
        maxDate: PropTypes.object,
        /**
         * 선택 가능한 날짜에 대한 범위의 최소(시작) 날짜를 지정하는 속성입니다.
         * 기본으로 현재 날짜 -100년으로 지정되어 있습니다.
         */
        minDate: PropTypes.object,
        /**
         * 날짜 선택 값이 바뀔 때 발생하는 Callback 함수입니다.
         * 선택한 값인 Date 값을 파라미터로 넘겨줍니다.
         *
         * @param {object} date `Date` value that was changed.
         */
        onAccept: PropTypes.func,
        /**
         * 날짜 선택 창이 사라질 때 호출되는 Callback 함수입니다.
         */
        onDismiss: PropTypes.func,
        /**
         * 날짜 선택 창이 보여질 때 호출되는 Callback 함수입니다.
         */
        onShow: PropTypes.func,
        /**
         * 일정을 표시할 날짜를 지정합니다.
         */
        scheduleDate: PropTypes.array,
        /**
         * 특정 날짜에 대한 비활성화 기능을 넣기 위해 사용되는 Callback 함수입니다.
         *
         * @param {object} day Date object of a day.
         * @returns {boolean} Indicates whether the day should be disabled.
         */
        shouldDisableDate: PropTypes.func,
        /**
         * 오늘 버튼에 적용되는 텍스트 값을 지정합니다.
         */
        todayLabel: PropTypes.string,
        /**
         * @ignore
         */
        type: PropTypes.oneOf(['solar', 'lunar']),

        /**
         *  패널의 정렬 위치를 지정합니다.
         */
        align: PropTypes.string,
        /**
         *  패널의 위치를 지정합니다.
         */
        position: PropTypes.string,

        /**
         * 
         */
        onKeyDown: PropTypes.func,
        /**
         * 필수여부
         */
        required: PropTypes.bool,
        anchorEl: PropTypes.any,
        usePortal: PropTypes.bool,
    };

    static defaultProps = {
        initialDate: new Date(),
        firstDayOfWeek: 0,
        locale: 'ko',
        minDate: addYears(new Date(), -100),
        maxDate: addYears(new Date(), 100),
        todayLabel: OrbitInternalLangPack.getText('WE000002147', '오늘'),
        type: 'solar',
        usePortal: true
    };

    myRefs = {
        calendar: React.createRef(),
        root: React.createRef()
    }

    state = {
        open: undefined,
        transition: false
    };

    show = (focus = true) => {
        if (this.props.onShow && !this.state.open) this.props.onShow();
        this.setState({
            open: true,
            isChangeDisplayDate: false,
            isCalendarFocused: false,
        }, () => {
            this.setState({
                transition: true
            })
            if (focus === true && this.myRefs.root.current) {
                this.myRefs.root.current.focus();
            }
        });
    }

    dismiss = () => {
        if (this.props.onDismiss) this.props.onDismiss();

        this.setState({ open: false, transition: false });
    };

    componentClick = (event) => {
        this.setState({ isCalendarFocused: true });

        if (this.props.onClick) this.props.onClick(event);
    }

    getIsChangeDisplayDate() {
        return this.state.isChangeDisplayDate;
    }

    handleChangeDisplayDate = () => {
        this.setState({ isChangeDisplayDate: true });
    }

    handleTouchTapDay = (event, date, schedule) => {
        setTimeout(this.handleTouchTapOk, 0);
    };

    handleTouchTapOk = () => {
        if (this.props.onAccept && !this.myRefs.calendar.current.isSelectedDateDisabled()) {
            this.props.onAccept(this.myRefs.calendar.current.getSelectedDate());
        }
        this.dismiss();
    };

    handleTouchTapToday = () => {
        //this.handleChangeDisplayDate();
        this.props.onAccept(new Date());
        this.dismiss();
    };

    focus = () => {
        if (this.myRefs.root.current) {
            this.myRefs.root.current.focus();
        }

    }

    handleRootKeyDown = (e) => {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(e);
        }
    }

    render() {
        const {
            // anchorEl,
            firstDayOfWeek,
            fixedLine,
            initialDate,
            locale,
            maxDate,
            minDate,
            // onAccept,
            onBlur,
            scheduleDate,
            shouldDisableDate,
            // theme,
            todayLabel,
            type,
            // ...other
        } = this.props;

        const { open } = this.state;

        return (
            <OBTFloatingPanel
                key={open ? 'open' : 'close'}
                value={open}
                align={this.props.align}
                position={this.props.position}
                autoPosition={true}
                anchorEl={this.props.anchorEl}
                usePortal={this.props.usePortal}
            >
                <div
                    tabIndex={-1}
                    ref={this.myRefs.root}
                    className={Util.getClassNames(moduleStyles.calendar, this.state.transition === true ? moduleStyles.visible : null)}
                    onKeyDown={this.handleRootKeyDown}
                    onFocus={(e) => {
                        // OBTDataGrid에서 사용시 포커스되면 이벤트가 페이지컨테이너까지 전파되어 
                        // 사이드바 날아가는 현상 때문에 추가
                        e.stopPropagation();
                    }}
                >
                    <LUXCalendar
                        ref={this.myRefs.calendar}
                        firstDayOfWeek={firstDayOfWeek}
                        initialDate={initialDate}
                        locale={locale}
                        onChangeDisplayDate={this.handleChangeDisplayDate}
                        onChangeDisplayDateAll={this.handleChangeDisplayDate}
                        onTouchTapDay={this.handleTouchTapDay}
                        onTouchTapToday={this.handleTouchTapToday}
                        maxDate={maxDate}
                        minDate={minDate}
                        onBlur={onBlur}
                        onClick={this.componentClick}
                        open={open}
                        shouldDisableDate={shouldDisableDate}
                        scheduleDate={scheduleDate}
                        showActionButtons={false}
                        theme="basic"
                        todayLabel={todayLabel}
                        type={type}
                        fixedLine={fixedLine}
                        style={{
                            borderBottom: 'none',
                            minHeight: '207px'
                        }}
                    />
                    <div className={moduleStyles.actionBar}>
                        <LUXButton
                            key={'today'}
                            label={OrbitInternalLangPack.getText('WE000002147', '오늘')}
                            onTouchTap={() => {
                                if (this.props.onAccept) {
                                    this.props.onAccept(new Date());
                                }
                                this.dismiss();
                            }}
                            style={styles.button}
                        />
                        {!this.props.required ? <LUXButton
                            key={'clear'}
                            label={OrbitInternalLangPack.getText('WE000001958', '초기화')}
                            onTouchTap={() => {
                                if (this.props.onAccept) {
                                    this.props.onAccept(null);
                                }
                                this.dismiss();
                            }}
                            style={styles.button}
                        /> : undefined}
                    </div>
                </div>
            </OBTFloatingPanel>
        );
    }

    containsFocus = () => {
        return Util.containsFocus(this.myRefs.root);
    }
}

export default UFODateFieldDialog;
