import { EventArgs, CancelableEventArgs } from '../Common/Events';
import Pagination from "./Pagination";
import { GridState } from '../OBTListGrid/OBTListGridInterface';
import { ICardListEmptySet, EmptySetBody } from './OBTCardListInterface'
export class RenderItemEventArgs extends EventArgs {
    constructor(public readonly target: any, public readonly index: number, public readonly data: any, public component: any = null) {  //컴포넌트필요
        super(target);
    }
}

export class BeforeChangeSelection extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly oldRowIndex: number,
        public readonly rowIndex: number,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class AfterChangeSelection extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly oldRowIndex: number,
        public readonly rowIndex: number) {
        super(target);
    }
}

export class BeforeAddRowEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly data: any,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class AfterAddRowEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly data: any) {
        super(target);
    }
}

export class BeforeRemoveRowEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly data: any,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class AfterRemoveRowEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly data: any) {
        super(target);
    }
}

export class BeforeChangeEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
        public readonly values: any,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class ValidateChangeEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
        public readonly oldValue: any,
        public readonly newValue: any,
        public readonly values: any,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class AfterChangeEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
        public readonly oldValue: any,
        public readonly newValue: any,
        public readonly values: any) {
        super(target);
    }
}

export class AfterCheckEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly checked: boolean) {
        super(target);
    }
}

export class AfterHeaderCheckEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly checked: boolean) {
        super(target);
    }
}

export class AfterReadEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly data: any[]
    ) {
        super(target);
    }
}

export class BeforeStoreEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,

        public readonly cancel: boolean = false
    ) {
        super(target, cancel);
    }
}

export class AfterStoreEventArgs extends EventArgs {
    constructor(
        public readonly target: any
    ) {
        super(target);
    }
}

export class ReadEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly pagingInfo: Pagination | undefined
    ) {
        super(target);
    }
}

export class AfterDataChangeEventArgs extends EventArgs {
    constructor(
        public readonly target: any
    ) {
        super(target);
    }
}

export class DrawerEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly checkedRows: any[]
    ) {
        super(target);
    }
}

export interface IStoreIndexes {
    added: number[],
    modified: number[]
}

export interface IStoreData {
    added: any[],
    modified: any[],
    deleted: any[]
}

export interface IDropList {
    value: string,
    columnName: string,
    text: string
}

export class StoreEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly indexes: IStoreIndexes,
        public readonly data: IStoreData,
        public changeState: boolean = true,
        public cancel: boolean = false
    ) {
        super(target, cancel);
    }
}

export class ReadTotalCountEventsArgs extends EventArgs {
    constructor(
        public readonly target: any
    ) {
        super(target);
    }
}

export class AfterStateChangeEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly state: GridState
    ) {
        super(target);
    }
}

export class BeforeSortChangeEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly data: IDropList,
        public cancel: boolean = false,
        public handled: boolean = false
    ) {
        super(target, cancel);
    }
}

export class AfterSortChangeEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly data: IDropList,
    ) {
        super(target);
    }
}

export class AddButtonClickEventArgs extends EventArgs {
    constructor(
        public readonly target: any
    ) {
        super(target);
    }
}

export class EmptySetEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly type: EmptySetBody,
        public emptySet: ICardListEmptySet | null = null
    ) {
        super(target);
    }
}

export class ItemClickEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
    ) {
        super(target);
    }
}

export class ItemDoubleClickEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
    ) {
        super(target);
    }
}

export class PrivacyEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly accessType: string,
        public readonly columnNames: string[],
        public readonly rows: any[]
    ) {
        super(target);
    }
}

export class PrivacyRetrievedEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly columnNames: string[]
    ) {
        super(target);
    }
}

export class StoreMemoCheckPenEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly values: any,
        public readonly memoCode: string | null,
        public readonly checkPen: string | null,
        public cancel: boolean = false
    ) {
        super(target, cancel);
    }
}