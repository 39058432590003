/**
 * Component OBTChips
 * Luna - Orbit 개발시 템플릿 으로 사용.
 * @version 0.1
 * @author 전주빈
 * @see common.js
 */
import * as React from 'react';
import { CompositeProps, Util, Events, CommonProps, createPropDefinitions, CommonDefinitions, CommonType } from '../Common';
import { OBTScrollbar, OBTChip } from '../index'
import { IOBTTooltip } from '../OBTTooltip/OBTTooltip';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const styles = require('./OBTChip.module.scss');

export class RenderItemEventArgs extends Events.EventArgs {
    constructor(public readonly target: any, public readonly index: number, public readonly data: any, public item: Item) {
        super(target);
    }
}

export class ChangeEventArgs extends Events.EventArgs {
    /**
     * Value 속성을 제공하는 EventArgs
     * @param {React.Component} target 이벤트 생성자
     * @param {*} value 값
     * {@code Util.invokeEvent(this.props.onChange, new Event.ChangeEventArgs(this, value))}
     * @see EventArgs
     * @see Util.invokeEvent
     */
    constructor(target: any, public readonly value: any, public readonly index: number) {
        super(target);
    }
    toString(): string { return JSON.stringify({ target: this.target ? 'object' : this.target, value: this.value, index: this.index }); }
}

/**
 * 컴포넌트 Chip 요소 안에 들어가는 형식으로 Mapping 지정합니다.
 */
interface Item {
    /**
     * 키 (string)
     */
    key: string,
    /**
     * 텍스트 (Node)
     */
    labelText: Node,
    /**
     * 툴팁 (IOBTTooltip)
     */
    toolTip?: IOBTTooltip,
    /**
     * 이미지 (string)
     */
    imageUrl?: string,
    /**
     * 아이콘 (string)
     */
    icon?: string,
    /**
     * 사용불가 (boolean)
     */
    disabled?: boolean,
    /**
     * 클래스명
     */
    className?: string,
    /**
     * 라벨 스타일
     */
    labelStyle?: React.CSSProperties
}

interface IOBTChips extends CompositeProps.Default, CommonProps.disabled, CommonProps.required {
    /**
     * 컴포넌트의 기본 데이터 List를 지정합니다.
     */
    list: Array<any>,

    /**
     * 컴포넌트 Chip 요소 안에 들어가는 형식으로 Mapping 지정합니다.
     */
    onMapItem: (e: RenderItemEventArgs) => void,
    /**
     * 컴포넌트에서 버튼 클릭시 발생하는 Callback 함수입니다.
     */
    onRemoveItem?: (e: ChangeEventArgs) => void,
    /**
     * 컴포넌트에서 Drop 발생하는 Callback 함수입니다.
     */
    onDropItem?: (e: Events.ChangeEventArgs<object>) => void
}

interface State extends hasError {
}

export default class OBTChips extends React.Component<IOBTChips, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        CommonDefinitions.disabled(),
        CommonDefinitions.required(),
        {
            name: 'list',
            type: 'any[]',
            description: 'Chips 에 표시될 Chip 에 해당하는 데이터 Array. 사용자정의 형태 데이터.(onMapItem 을 통해 변환처리함)'
        },
        {
            name: 'onMapItem',
            type: CommonType.function,
            parameters: {
                name: 'e',
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    index: { type: CommonType.number, description: '데이터 index' },
                    data: { type: CommonType.any, description: '사용자정의 데이터' },
                    item: {
                        type: {
                            key: { type: CommonType.string, description: '유일키' },
                            labelText: { type: CommonType.string, description: 'Chip 에 표시될 문자열' },
                            toolTip: { type: 'Tooltip Props', optional: true, description: '설정시 툴팁이 표시됨' },
                            imageUrl: { type: CommonType.image, optional: true, description: 'labelText 앞에 표시될 이미지 Url' },
                            disabled: { type: CommonType.string, optional: true, description: '비활성 상태인 경우 이미지 Url' },
                            labelStyle: { type: CommonType.any, optional: true, description: 'labelText 의 스타일 지정' },
                            className: { type: CommonType.string, optional: true, description: 'Chip 의 className 지정' }
                        }
                    }
                }
            },
            description: 'list 의 데이터를 Chip 으로 변환하는 Callback 함수.\ne.item 요구되는 값을 반드시 지정해야 합니다.'
        },
        {
            name: 'onDropItem',
            type: CommonType.function,
            parameters: {
                name: 'e',
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    value: { type: CommonType.any, description: '추가된 데이터' }
                }
            },
            description: 'Drag & Drop 을 통해 Chips 로 데이터가 추가된 경우 호출되는 Callback.'
        },
        {
            name: 'onRemoveItem',
            type: CommonType.function,
            parameters: {
                name: 'e',
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    value: { type: CommonType.any, description: '삭제된 데이터' },
                    index: { type: CommonType.number, description: '삭제된 데이터 index' }
                }
            },
            description: 'Chip 의 삭제버튼을 클릭하여 삭제되어야 하는 경우 호출되는 Callback'
        }
    );

    public static defaultProps = {
        width: '100%',
        height: '100%'
    }
    public state: State = {
    }

    myRefs = {
        root: React.createRef<HTMLDivElement>()
    }

    private chips = (stateList: Array<any>,
        onMapItem: (e: RenderItemEventArgs) => void,
        disabled?: boolean,
        required?: boolean): any => {
        return stateList.length > 0 ? stateList.map((list, dataIndex) => {
            let item: any;
            const e = new RenderItemEventArgs(this, dataIndex, list, item);
            if (onMapItem) {
                onMapItem(e);
                item = e.item;
                return (<OBTChip
                    key={item.key}
                    item={item}
                    list={list}
                    disabled={disabled === true ? true : item.disabled}
                    required={required}
                    onChange={this.handleRemove}
                    labelStyle={item ? item.labelStyle : undefined}
                    className={item ? item.className : undefined}
                />);
            }
            return undefined;
        }).filter(item => item) : [];
    };

    private handleRemove = (e: any) => {
        if (this.props.disabled) return;

        const index = this.props.list.findIndex(item => {
            return item === e.value;
        });
        Util.invokeEvent<ChangeEventArgs>(this.props.onRemoveItem, new ChangeEventArgs(this, e.value, index));
    }

    private handleAdd = (e: any) => {
        try {
            const value = e.dataTransfer.getData('text');
            Util.invokeEvent<Events.ChangeEventArgs<object>>(this.props.onDropItem, new Events.ChangeEventArgs<object>(this, value));
        } catch (error) {
            console.error(error);
        }
    }

    private onDragOver = (e: any) => {
        if (this.props.disabled) return;
        e.preventDefault();
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        );
    }

    renderComponent = () => {
        const chips = this.chips(this.props.list, this.props.onMapItem, this.props.disabled, this.props.required);
        return (
            <div
                className={Util.getClassNames(styles.chipsDefault, this.props.className)}
                style={Util.getWrapperStyle(this.props)}
                onDrop={this.handleAdd}
                onDragOver={this.onDragOver}
                id={this.props.id}
                data-orbit-component='OBTChips'>
                <OBTScrollbar height={this.props.height} width={this.props.width}>
                    <div className={styles.chipsHead}>
                        {chips}
                    </div>
                </OBTScrollbar>
            </div>
        )
    }
};
