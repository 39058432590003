import React, { Component } from 'react'
import { Events, Util } from '../Common'
import { OBTButton, OBTSplitButton } from '../index';
import { IPageAuthority } from './OBTPageContainer';
import { OrbitInternalLangPack } from '../Common/Util';
const styles = require('./OBTPageContainer.module.scss');

export interface IDrawerContent {
    key: string,
    labelText?: string,
    icon?: Node,
    imageUrl?: string,
    children?: IDrawerContent[],
    component?: any,
    visible?: boolean,
    important?: boolean,
    onClick?: (e: Events.MouseEventArgs) => void
}

interface IState {
    isDrawerCollapsed?: boolean,
    drawerSelection?: any,
    drawerContents?: IDrawerContent[] | null
}

export default class Drawer extends Component<{ owner: any, containerWidth: string, pageAuthority?: IPageAuthority }, IState> {
    state: IState = {}

    showDrawer(selection: number | any, ...drawerContents: IDrawerContent[]) {
        if (typeof selection === 'number') {
            selection =
                (
                    <div className={styles.selection}>
                        <span className={styles.selectionCount}>{`${selection}`}{OrbitInternalLangPack.getText('MO000001175', '건')}</span>  
                        <span>{' '}{OrbitInternalLangPack.getText('WE000023190', '선택됨')}</span>
                    </div>
                );
        }
        this.setState({
            isDrawerCollapsed: false,
            drawerSelection: selection,
            drawerContents: drawerContents.map(item => {
                switch (item.key) {
                    case 'delete':
                        return {
                            ...item,
                            key: 'delete',
                            labelText: OrbitInternalLangPack.getText('WE000000249', '삭제'),
                            visible: this.props.pageAuthority && this.props.pageAuthority.deleteAuthYn === 'Y',
                            onClick: () => this.props.owner.handleDelete({ target: 'drawer' })
                        }
                    case 'print':
                        return {
                            ...item,
                            key: 'print',
                            labelText: OrbitInternalLangPack.getText('WE000000959', '인쇄'),
                            visible: this.props.pageAuthority && this.props.pageAuthority.printAuthYn === 'Y',
                            onClick: () => this.props.owner.handlePrint({ target: 'drawer' })
                        }
                }
                return item;
            })
        });
    };

    hideDrawer() {
        this.setState({
            isDrawerCollapsed: true
        });
    };

    render() {
        const drawerContents = this.state.drawerContents ? this.state.drawerContents.filter(item => item.visible !== false) : this.state.drawerContents;
        const collapsed = this.state.isDrawerCollapsed === false ? false : true;
        const contents = drawerContents && drawerContents.length > 0 ? <>{this.state.drawerSelection}
            <div className={styles.drawerContents}>
                {drawerContents.map(item => {
                    let component = item.component;
                    if (!component) {
                        const children = item.children ? item.children.filter(child => child.visible !== false) : item.children;
                        if (children && children.length > 0) {
                            const splitValues = [
                                { key: item.key, labelText: item.labelText || '' }
                            ].concat(children.map(child => { return { key: child.key, labelText: child.labelText || '' }; }));
                            component = <div key={item.key} className={styles.drawerSplitButton}>
                                <OBTSplitButton value={splitValues}
                                    type={OBTSplitButton.Type.big}
                                    theme={item.important ? OBTSplitButton.Theme.drawerImportant : OBTSplitButton.Theme.drawer}
                                    motionType={OBTSplitButton.MotionType.dropDown}
                                    onClick={(e) => {
                                        const { key } = e;
                                        const child = children.find(child => child.key === key) || item;
                                        this.handleDrawerButtonClick(child, e.event);
                                    }} />
                            </div>
                        } else {
                            component = <OBTButton key={item.key} className={styles.drawerButton} type={OBTButton.Type.big}
                                theme={item.important ? OBTSplitButton.Theme.drawerImportant : OBTSplitButton.Theme.drawer}
                                icon={item.icon} imageUrl={item.imageUrl} labelText={item.labelText}
                                onClick={(e) => this.handleDrawerButtonClick(item, e.event)} />
                        }
                    } else {
                        component = <div key={item.key}>{component}</div>
                    }
                    return component;
                })}
            </div></> : <></>
        return (
           drawerContents && drawerContents.length > 0 ?  
           <div style={{ width: this.props.containerWidth }}
           className={Util.getClassNames(styles.drawerWrapper, collapsed ? styles.collapsed : null)}>
                <div className={styles.drawer}>
                    {contents}
                </div>
            </div>: <></>
        );
    }

    handleDrawerButtonClick(drawerContent: IDrawerContent, e: React.MouseEvent) {
        if (drawerContent.onClick) {
            drawerContent.onClick(new Events.MouseEventArgs(this, e));
        }
    }

    public get visible() {
        return this.state.isDrawerCollapsed === false ? true : false;
    }

    public getButton(key: string) {
        return (this.state.drawerContents || []).find(item => item.key === key);
    }
}

