import React from 'react';
import { ISideBarContent } from './SideBar';
import SideBarContent, { SideBarContentType } from './SideBarContent';
import { OrbitInternalLangPack } from '../Common/Util';
// const styles = require('./SideBarContent.module.scss');

interface ISBCGridGuide {
    item: ISideBarContent
}

interface IState {
}

export default class SBCGridGuide extends React.Component<ISBCGridGuide, IState> {

    state: IState = {}

    render() {
        return (
            <SideBarContent item={Object.assign({}, this.props.item, {
                title: OrbitInternalLangPack.getText('WE000022643', '조작가이드'),
                type: SideBarContentType.accordion
            })}>
                <div style={{ overflow: 'auto' }}>
                    {this.props.item.component}
                </div>
            </SideBarContent>
        )
    }
}
