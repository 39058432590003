import React from 'react';
import ReactDOM from 'react-dom';
const styles = require('./OBTPortal.module.scss');

export default class OBTPortal extends React.Component {
    public getPortalRoot = () => {
        const root = Array.from(document.body.childNodes).find(el => {
            return (el as HTMLElement).className === styles.orbitPortalRoot;
        });
        if (!root) {
            const portalRoot = document.createElement('div');
            portalRoot.className = styles.orbitPortalRoot;
            document.body.appendChild(portalRoot);
            return portalRoot;
        }
        return root as HTMLElement;
    }

    render() {
        const portalRoot = this.getPortalRoot();
        return ReactDOM.createPortal(this.props.children, portalRoot);
    }
}