import { EventArgs, CancelableEventArgs } from '../Common/Events';
import { Events } from '../Common';
// import IBuiltInCodePicker from '../OBTCodePicker/DataSource/IBuiltInCodePicker';

export enum GridDataChangeAction {
    'SELECTION_CHANGED' = 'SELECTION_CHANGED',
    'DATA_CHANGED' = 'DATA_CHANGED',
    'ROW_ADDED' = 'ROW_ADDED',
    'CHECK_CHANGED' = 'CHECK_CHANGED',
    'ROW_DELETED' = 'ROW_DELETED',
    'DATA_CLEARED' = 'DATA_CLEARED',
}

export interface DataSortType {
    columnName: string | null,
    direction: string | null,
}

export class SortingEventArgs extends Events.CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly columnName: string | null,
        public readonly direction: string | null,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class ShowTooltipEventArgs extends Events.EventArgs {
    constructor(
        public readonly target: any,
        public readonly columnName: string,
        public readonly rowIndex: number,
        public readonly dataRow: any,
        public tooltipText: string,
    ) {
        super(target);
    }
}

export class EditCommitEventArgs extends Events.EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
        public readonly oldValue: any,
        public readonly newValue: any,
    ) {
        super(target);
    }
}

export class MouseHoverEventArgs extends Events.EventArgs {
    constructor(
        public readonly target: any,
        public readonly columnName: string,
        public readonly rowIndex: number,
        public readonly dataRow: any,
        public tooltipText: string,
        public readonly isEllipse: boolean,
        public maxTooltipWidth: number
    ) {
        super(target);
    }
}

export class BeforeSelectChangeEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly oldRowIndex: number,
        public readonly rowIndex: number,
        public readonly oldColumnName: string,
        public readonly columnName: string,
        public readonly isRowChanged: boolean,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class AfterSelectChangeEventArgs extends Events.EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string | null,
        public readonly isRowChanged: boolean) {
        super(target);
    }
}

export class StartEditEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class BeforeColumnChangeEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly oldRowIndex: number,
        public readonly rowIndex: number,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class BeforeRowChangeEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly oldRowIndex: number,
        public readonly rowIndex: number,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class AfterRowChangeEventArgs extends Events.EventArgs {
    constructor(
        public readonly target: any,
        public readonly oldRowIndex: number,
        public readonly rowIndex: number) {
        super(target);
    }
}

export class BeforeChangeSelection extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly oldRowIndex: number,
        public readonly oldColumnName: string,
        public readonly rowIndex: number,
        public readonly columnName: string,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class AfterChangeSelection extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly oldRowIndex: number,
        public readonly oldColumnName: string,
        public readonly rowIndex: number,
        public readonly columnName: string) {
        super(target);
    }
}

export class BeforeAddRowEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly data: any,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class AfterAddRowEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly data: any) {
        super(target);
    }
}

export class SelectByEnterEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly data: any) {
        super(target);
    }
}

export class SkipEmptyCellEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly columnName: string,
        public readonly rowIndex: number,
        public readonly isRequired: boolean | undefined,
        /**
         * 이 값이 셀에 바인딩된다.
         */
        public readonly defaultValue: any) {
        super(target);
    }
}

export class BeforeRemoveRowEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly data: any,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class AfterRemoveRowEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly data: any) {
        super(target);
    }
}

export class BeforeChangeEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
        public readonly values: any,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class ValidateChangeEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
        public readonly oldValue: any,
        public readonly newValue: any,
        public readonly values: any,
        public readonly source: any,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class AfterChangeEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
        public readonly oldValue: any,
        public readonly newValue: any,
        public readonly values: any,
        public readonly source: any) {
        super(target);
    }
}

export class BeforeCheckEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly checked: boolean,
        public readonly cancel: boolean = false) {
        super(target);
    }
}

export class AfterCheckEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly checked: boolean) {
        super(target);
    }
}

export class AfterHeaderCheckEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly checked: boolean) {
        super(target);
    }
}

export class AfterReadEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly data: any[]
    ) {
        super(target);
    }
}

export class AfterDataChangeEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly columns: string[],
        public readonly action: any
    ) {
        super(target);
    }
}

export class DrawerEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly checkedRows: any[]
    ) {
        super(target);
    }
}

export class ClickedEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
        public readonly values: any,
    ) {
        super(target);
    }
}

export class DoubleClickedEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
        public readonly values: any,
    ) {
        super(target);
    }
}

export class ReadEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
    ) {
        super(target);
    }
}

export class ReadPageEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowCountPerPage: number,
        public readonly currentPage: number,
        public readonly totalCount: number,
        public readonly startRowIndex: number,
        public readonly endRowIndex: number,
        public readonly sortType: DataSortType,

    ) {
        super(target);
    }
}

export class InnerDragStartEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly startRowIndex: number,
        public readonly values: any,
        public readonly cancel: boolean = false,
    ) {
        super(target);
    }
}

export class InnerDropEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly startRowIndex: number,
        public readonly startValues: any,
        public readonly endRowIndex: number,
        public readonly endValues: any,
        public readonly cancel: boolean = false,
    ) {
        super(target);
    }
}

export class ImageButtonClickedEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
        public readonly buttonIndex: number,
        public readonly name: string,
        public readonly values: any,
    ) {
        super(target);
    }
}

export class ScrollEventArgs extends Events.EventArgs {
    constructor(
        public readonly target: any,
        public readonly event: any
    ) {
        super(target);
    }
}

export class LinkColumnClickedEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
    ) {
        super(target);
    }
}

export * from '../OBTDataGrid/GridBase/GridBaseEventArgs'