/**
 * OBTCheckBox2
 * @version 0.1
 * @author 김소현
 * @see common.js
 */
import * as React from 'react';
import { CompositeProps, Util, Events, CommonProps, createPropDefinitions, CommonType, CommonDefinitions } from '../Common';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { hasError } from '../Common/CommonState';
import { ChangeEventArgs } from '../Common/Events';
import OBTTooltip, { IOBTTooltip } from '../OBTTooltip/OBTTooltip';

/**
 * @internal
 * CSS Modules 사용방식
 * styles.[className]
 */
const styles = require('./OBTCheckBox2.module.scss');

interface IOBTCheckBox2 extends CompositeProps.Default, CommonProps.disabled, CommonProps.required, CommonProps.value<boolean>, CommonProps.labelText,
    Events.onFocus, Events.onBlur, Events.onMoveFocus, Events.onValidate<boolean>, Events.onChange<boolean>, Events.onMouseDown, Events.onMouseMove, Events.onMouseUp, Events.onMouseLeave, Events.onMouseEnter,
    Events.onKeyDown, Events.onKeyPress, Events.onKeyUp {
    /**
     * 라디오버튼 형태의 체크박스를 사용할 수 있습니다.
     */
    useRadioStyle?: boolean,
    /**
    * 툴팁을 사용할 수 있습니다
    */
    tooltip?: IOBTTooltip
}

interface IState extends hasError {
    isChecked: boolean,
    isFocused: boolean
}

export default class OBTCheckBox2 extends React.Component<IOBTCheckBox2, IState> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.value({ type: CommonType.boolean, description: '체크여부' }),
        CommonDefinitions.labelText(),
        CommonDefinitions.Default(),
        CommonDefinitions.disabled(),
        CommonDefinitions.required(),
        {
            name: 'useRadioStyle',
            type: CommonType.boolean,
            default: false,
            optional: true,
            description: '체크박스 모양을 라디오버튼 형태로 만들어주는 속성입니다.'
        },
        {
            name: 'onChange', type: CommonType.function, parameters: {
                name: 'e',
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    value: { type: CommonType.boolean, description: '체크여부' },
                }
            },
            description: '입력된 값이 변경될 때 발생하는 Callback 함수입니다.'
        },
        CommonDefinitions.onClick(),
        CommonDefinitions.onFocus(),
        CommonDefinitions.onBlur(),
        CommonDefinitions.onMouseDown(),
        CommonDefinitions.onMouseMove(),
        CommonDefinitions.onMouseUp(),
        CommonDefinitions.onMouseLeave(),
        CommonDefinitions.onMouseEnter(),
        CommonDefinitions.onKeyDown(),
        CommonDefinitions.onKeyPress(),
        CommonDefinitions.onKeyUp()
    );

    public static defaultProps = {
        disabled: false,
        frozen: false,
        required: false,
    }

    public state: IState = {
        isChecked: this.props.value,
        isFocused: false
    }

    private wrapperRef = React.createRef<HTMLDivElement>();

    public isEmpty(): boolean {
        return this.props.value ? false : true;
    }

    public validate() {
        return !(this.props.required === true && this.isEmpty());
    }

    public focus(): void {
        if (this.canEdit() && this.wrapperRef.current) {
            this.wrapperRef.current.focus();
        }
    }

    public blur(): void {
        if (this.wrapperRef.current) {
            this.wrapperRef.current.blur();
        }
    }

    private canEdit = () => {
        return !this.props.frozen && !this.props.disabled;
    }

    private handleClick = () => {
        if (this.canEdit() && this.wrapperRef.current) {
            this.setState({
                isChecked: !this.state.isChecked,
                isFocused: false
            }, () => {
                if (this.props.onChange) {
                    if (Util.invokeEvent<Events.ValidateEventArgs<boolean>>(this.props.onValidate, new Events.ValidateEventArgs(this, this.props.value, this.state.isChecked))) {
                        this.props.onChange(new ChangeEventArgs<boolean>(this, this.state.isChecked));
                    }
                }
            })
        }
    }

    private handleFocus = () => {
        this.setState({
            isFocused: true,
        });
    }

    private handleBlur = () => {
        this.setState({
            isFocused: false,
        });
    }

    private handleKeyDown = (e: React.KeyboardEvent) => {
        e.stopPropagation();
        if (this.props.onKeyDown) {
            Util.invokeEvent<Events.KeyEventArgs>(this.props.onKeyDown, new Events.KeyEventArgs(this, e));
        }
        switch (e.key) {
            case 'ArrowUp':
                e.preventDefault();
                this.handleMoveFocus('up')
                break;
            case 'ArrowDown':
                e.preventDefault();
                this.handleMoveFocus('enter')
                break;
            case 'ArrowRight':
                e.preventDefault();
                this.handleMoveFocus('right')
                break;
            case 'ArrowLeft':
                e.preventDefault();
                this.handleMoveFocus('left')
                break;
            case 'Enter':
                e.preventDefault();
                this.handleMoveFocus('enter')
                break;
            case 'Tab':
                e.preventDefault();
                this.handleMoveFocus('right')
                break;
            case 'Space':
            case ' ':
                e.preventDefault();
                if (this.canEdit()) {
                    this.setState({
                        isChecked: !this.state.isChecked,
                    }, () => {
                        if (!this.props.onChange) {
                            return;
                        }
                        if (Util.invokeEvent<Events.ValidateEventArgs<boolean>>(this.props.onValidate, new Events.ValidateEventArgs(this, this.props.value, this.state.isChecked))) {
                            this.props.onChange(new ChangeEventArgs<boolean>(this, this.state.isChecked));
                        }
                    })
                }
                break;
            default:
        }
    }

    private handleMoveFocus = (direction: string) => {
        if (this.props.required && this.isEmpty() && ['right', 'down', 'enter'].includes(direction)) {
            return;
        }
        Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
    }


    renderComponent = () => {
        return (
            <OBTTooltip
                {...this.props.tooltip}
                className={Util.getClassNames(styles.root, this.props.className)}
                focusValue={false}
                overrideSize={false}
                onFocus={this.props.onFocus}
                onBlur={this.props.onBlur}
                onMouseDown={this.props.onMouseDown}
                onMouseMove={this.props.onMouseMove}
                onMouseUp={this.props.onMouseUp}
                onMouseLeave={this.props.onMouseLeave}
                onMouseEnter={this.props.onMouseEnter}
                onKeyDown={this.props.onKeyDown}
                onKeyUp={this.props.onKeyUp}
                onKeyPress={this.props.onKeyPress}
                onMoveFocus={(e) => { this.handleMoveFocus(e.direction) }}
                rootProps={{
                    id: this.props.id,
                    'data-orbit-component': 'OBTCheckBox2'
                }} >
                <div ref={this.wrapperRef}
                    style={{
                        width: this.props.width,
                        height: this.props.height,
                    }}
                    className={Util.getClassNames(styles.wrapper, this.props.disabled && styles.disabled)}
                    onClick={this.handleClick}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onKeyDown={this.handleKeyDown}
                    tabIndex={-1}
                >
                    <span className={Util.getClassNames(
                        styles.checkIcon,
                        !this.props.disabled && !this.props.required && this.props.value && styles.defaultChecked,
                        !this.props.disabled && this.props.required && styles.defaultRequired,
                        !this.props.disabled && this.props.required && this.props.value && styles.requiredChecked,
                        this.props.disabled && this.props.value && styles.disableChecked,
                        this.props.disabled && styles.defaultDisabled,

                        this.props.useRadioStyle && !this.props.disabled && !this.props.required && styles.radioDefaultUnchecked,
                        this.props.useRadioStyle && !this.props.disabled && !this.props.required && this.props.value && styles.radioDefaultChecked,
                        this.props.useRadioStyle && !this.props.disabled && this.props.required && styles.radioDefaultRequired,
                        this.props.useRadioStyle && !this.props.disabled && this.props.required && this.props.value && styles.radioRequiredChecked,
                        this.props.useRadioStyle && this.props.disabled && styles.radioDisabled,
                    )}>
                        <input type="checkbox" checked={this.props.value} readOnly
                            style={{
                                cursor: this.props.disabled ? 'not-allowed' : 'pointer'
                            }} />
                        {this.canEdit() && this.state.isFocused ? <span className={Util.getClassNames(styles.focused, this.props.useRadioStyle && styles.radioFocused)}></span> : null}
                    </span>
                    {this.props.labelText ? <span className={styles.labelText}>{this.props.labelText}</span> : null}
                </div>
            </OBTTooltip>
        )
    }

    render() {
        return (<ErrorBoundary owner={this} render={this.renderComponent} />)
    }
};