import { EventArgs, CancelableEventArgs } from '../Common/Events';
import { Events } from '../Common';
import IBuiltInCodePicker from '../OBTCodePicker/DataSource/IBuiltInCodePicker';
import Pagination from "./Pagination";
import { RowValidationAlert } from './OBTDataGridInterface';

/**
 * 
 */
export enum GridDataChangeAction {
    'SELECTION_CHANGED' = 'SELECTION_CHANGED',
    'DATA_CHANGED' = 'DATA_CHANGED',
    'ROW_ADDED' = 'ROW_ADDED',
    'CHECK_CHANGED' = 'CHECK_CHANGED',
    'ROW_DELETED' = 'ROW_DELETED',
    'DATA_CLEARED' = 'DATA_CLEARED',
}

export enum GridAlertReason {
    /** 행단위 유효성검사가 실패했을 경우 */
    "VALIDATION_FAIL" = "VALIDATION_FAIL",
    /**
     * 컨텍스트 메뉴 오른쪽버튼 찾기 결과가 없을 경우
     */
    "NO_SEARCH_CELL_RESULT" = "NO_SEARCH_CELL_RESULT",
}

export enum GridValidationFailReason {
    "EMPTY_REQUIRED" = "EMPTY_REQUIRED",
    "DUPLICATED_ROW" = "DUPLICATED_ROW"
}

export class GridCodePickerCellSearchEventArgs extends Events.EventArgs {
    constructor(
        public readonly target: any,
        public readonly codePickerId: string | undefined,
        public readonly useCustomCodePicker: boolean,
        public readonly customCodePicker: IBuiltInCodePicker | ((e: any) => IBuiltInCodePicker) | undefined,
        public readonly columnName: string,
        public readonly rowIndex: number,
        public readonly parameters: any,
        public readonly keyword: any,
        public readonly oldValue: any,
        public readonly canMultiSelect: boolean,
        public readonly selectedValueDisplayCallback?: (e: any) => string) {
        super(target);
    }
}

export class SearchedItemSelectEventArgs extends Events.EventArgs {
    constructor(
        public readonly target: any,
        public readonly columnName: string,
        public readonly rowIndex: number,
        public readonly item: any
    ) {
        super(target);
    }
}

export class ShowTooltipEventArgs extends Events.EventArgs {
    constructor(
        public readonly target: any,
        public readonly columnName: string,
        public readonly rowIndex: number,
        public readonly dataRow: any,
        public readonly isCodePicker: boolean,
        public tooltipText: string,
    ) {
        super(target);
    }
}

export class ContextMenuItemClickEventArgs extends Events.EventArgs {
    constructor(
        public readonly target: any,
        public readonly columnName: string,
        public readonly rowIndex: number,
        public readonly label: string,
        public readonly tag: string | undefined,
        public readonly menuItem: any,

    ) {
        super(target);
    }
}

export class BeforeSelectChangeEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly oldRowIndex: number,
        public readonly rowIndex: number,
        public readonly oldColumnName: string,
        public readonly columnName: string,
        public readonly isRowChanged: boolean,
        public readonly isLastCell: boolean,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}


export class BeforeSelectChangeAsyncEventArgs extends Events.EventArgs {
    constructor(
        public readonly target: any,
        public readonly oldRowIndex: number,
        public readonly rowIndex: number,
        public readonly oldColumnName: string,
        public readonly columnName: string,
        public readonly isRowChanged: boolean) {
        super(target);
    }
}
export class AfterSelectChangeEventArgs extends Events.EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string | null,
        public readonly isRowChanged: boolean,
        /**
         * 이벤트가 readData를 통해서 발행되었는지 여부
         */
        public readonly invokedFromReadData: boolean,
        public readonly isGroupItem: boolean) {
        super(target);
    }
}

export class ClickCustomActionEventArgs extends Events.EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
    ) {
        super(target);
    }
}

export class ImageButtonClickedEventArgs extends Events.EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
        public readonly buttonIndex: number,
        public readonly name: string,
        public readonly values: any,
    ) {
        super(target);
    }
}

export class StartEditEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class BeforeColumnChangeEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly oldRowIndex: number,
        public readonly rowIndex: number,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class BeforeRowChangeEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly oldRowIndex: number,
        public readonly rowIndex: number,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class AfterRowChangeEventArgs extends Events.EventArgs {
    constructor(
        public readonly target: any,
        public readonly oldRowIndex: number,
        public readonly rowIndex: number,
        public readonly isGroupItem: boolean) {
        super(target);
    }
}

export class BeforeChangeSelection extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly oldRowIndex: number,
        public readonly oldColumnName: string,
        public readonly rowIndex: number,
        public readonly columnName: string,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class AfterChangeSelection extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly oldRowIndex: number,
        public readonly oldColumnName: string,
        public readonly rowIndex: number,
        public readonly columnName: string) {
        super(target);
    }
}

export class BeforeAddRowEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly data: any,
        public readonly cancel: boolean = false,
        public readonly parentRowIndex: number = -1) {
        super(target, cancel);
    }
}

export class AfterAddRowEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly data: any,
        public readonly parentRowIndex: number = -1) {
        super(target);
    }
}

export class SelectByEnterEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly data: any) {
        super(target);
    }
}

export class SkipEmptyCellEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly columnName: string,
        public readonly rowIndex: number,
        public readonly isRequired: boolean | undefined,
        /**
         * 이 값이 셀에 바인딩된다.
         */
        public readonly defaultValue: any) {
        super(target);
    }
}

export class BeforeRemoveRowEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly data: any,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class AfterRemoveRowEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly data: any) {
        super(target);
    }
}

export class BeforeChangeEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
        /**
         * @deprecated value를 사용해주세요.
         */
        public readonly values: any,
        public readonly value: any,
        public cancel: boolean = false) {
        super(target, cancel);
    }
}

export class ValidateChangeEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
        public readonly oldValue: any,
        public readonly newValue: any,
        public readonly values: any,
        public readonly source: any,
        public readonly cancel: boolean = false) {
        super(target, cancel);
    }
}

export class AfterChangeEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
        public readonly oldValue: any,
        public readonly newValue: any,
        public readonly values: any,
        public readonly source: any) {
        super(target);
    }
}

export class BeforeCheckEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly checked: boolean,
        public readonly cancel: boolean = false) {
        super(target);
    }
}

export class AfterCheckEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly checked: boolean) {
        super(target);
    }
}

export class AfterHeaderCheckEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly checked: boolean) {
        super(target);
    }
}

export class AfterColumnHeaderCheckEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly columnName: string,
        public readonly checked: boolean) {
        super(target);
    }
}

export class AfterReadEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly data: any[]
    ) {
        super(target);
    }
}

export class AfterDataChangeEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly columns: string[],
        public readonly action: any
    ) {
        super(target);
    }
}

export class DrawerEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly checkedRows: any[]
    ) {
        super(target);
    }
}

/**
 * 그리드에서 페이지컨테이너의 alert을 띄워야할때 
 */
export class AlertEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly alertReason: GridAlertReason,
        public readonly validationResult: RowValidationAlert | null
    ) {
        super(target);
    }
}

export class DataCellClickedEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
        public readonly values: any,
    ) {
        super(target);
    }
}

export class DataCellDoubleClickedEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
        public readonly values: any,
    ) {
        super(target);
    }
}

export class LinkColumnClickedEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: string,
    ) {
        super(target);
    }
}

export class KeyDownEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly keyCode: number,
        public readonly ctrl: boolean,
        public readonly shift: boolean,
        public readonly alt: boolean) {
        super(target);
    }
}

export class KeyUpEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly keyCode: number,
        public readonly ctrl: boolean,
        public readonly shift: boolean,
        public readonly alt: boolean) {
        super(target);
    }
}

export class BeforeStoreEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,

        public readonly cancel: boolean = false
    ) {
        super(target, cancel);
    }
}

export class AfterStoreEventArgs extends EventArgs {
    constructor(
        public readonly target: any
    ) {
        super(target);
    }
}

export class ReadTotalCountEventsArgs extends EventArgs {
    constructor(
        public readonly target: any
    ) {
        super(target);
    }
}

export class ReadFooterEventsArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly startPage: number,
        public readonly endPage: number,
        public readonly startRowIndex: number,
        public readonly limitCount: number,
    ) {
        super(target);
    }
}

export class ReadEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly pagingInfo: Pagination | undefined,
    ) {
        super(target);
    }
}

export class BeforePageNumberChangeArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly oldPageNumber: number,
        public readonly pageNumber: number,
        public cancel: boolean = false
    ) {
        super(target, cancel);
    }
}

export class AfterPageNumberChangeArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly appendedData: any[],
        public readonly pagingInfo: Pagination
    ) {
        super(target);
    }
}

export class ReachEndOfPageArgs extends EventArgs {
    constructor(
        public readonly target: any,
    ) {
        super(target);
    }
}

export class InnerDragStartEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly startRowIndex: number,
        public readonly values: any,
        public readonly cancel: boolean = false,
    ) {
        super(target);
    }
}

export class InnerDropEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly startRowIndex: number,
        public readonly startValues: any,
        public readonly endRowIndex: number,
        public readonly endValues: any,
        public readonly cancel: boolean = false,
    ) {
        super(target);
    }
}

export class BeforeCallCodePickerEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly columnName: any,
        public readonly keyword: string | null = null,
        public readonly replaceValue: string | null = null,
        public readonly cancel: boolean = false,
    ) {
        super(target);
    }
}

export interface IStoreIndexes {
    added: number[],
    modified: number[]
}

export interface IStoreData {
    added: any[],
    modified: any[],
    deleted: any[]
}

export class StoreEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly indexes: IStoreIndexes,
        public readonly data: IStoreData,
        public changeState: boolean = true,
        public cancel: boolean = false
    ) {
        super(target, cancel);
    }
}

export class StoreMemoCheckPenEventArgs extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly rowIndex: number,
        public readonly values: any,
        public readonly memoCode: string | null,
        public readonly checkPen: string | null,
        public cancel: boolean = false
    ) {
        super(target, cancel);
    }
}

export class PrivacyEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly accessType: string,
        public readonly columnNames: string[],
        public readonly rows: any[]
    ) {
        super(target);
    }
}

export class PrivacyRetrievedEventArgs extends EventArgs {
    constructor(
        public readonly target: any,
        public readonly columnNames: string[]
    ) {
        super(target);
    }
}

export class ExportExcelRClick extends CancelableEventArgs {
    constructor(
        public readonly target: any,
        public readonly option: any,
    ) {
        super(target);
    }
}

export * from './GridBase/GridBaseEventArgs'