/**
 * OBTCodePickerBaseController
 * @version 0.1
 * @author 나윤수
 * @see common.js
 */
import React, { Component } from 'react';
import { CommonDefinitions, CommonProps, CommonType, CompositeProps, createPropDefinitions, Events, Util } from '../Common';
import { hasError } from '../Common/CommonState';
import { IFocusable, IRequired } from '../Common/Functions';
import CodePickerController from './BaseComponent/CodePickerController';
import IBuiltInCodePicker from './DataSource/IBuiltInCodePicker';
import { CodePickerDisplayItemEventArgs } from './Events/CodePickerDisplayItemEventArgs';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { OBTContext, UserProfilePopUpParameter, IOBTContext } from '../OBTPageContainer/OBTPageContainer';
import { Type } from '../OBTLoading/OBTLoading';
import GuideMessageRenderer from '../Common/GuideMessageRenderer';
import { Fetch, devLog } from '../Common/Util';

/**
 * 전체선택 기능 활성화시 value로 취급되는 클래스
 */
export class CodePickerValue {
    /**
     * 전체 선택을 했는지 여부
     */
    private _isTotalSelect: boolean;

    /**
     * 전체 선택을 하지 않았을때의 value
     */
    private _value: Object[] | null;

    /**
     * 전체 선택을 했을때 제외되는 value
     */
    private _exceptValue: Object[] | null;

    constructor(isTotalSelect: boolean, value: Object[] | null, exceptValue: Object[] | null) {
        this._isTotalSelect = isTotalSelect;
        this._value = value;
        this._exceptValue = exceptValue;
    }

    get isTotalSelect() {
        return this._isTotalSelect;
    }

    get value() {
        return this._value;
    }

    get exceptValue() {
        return this._exceptValue;
    }

    /**
     * 아무것도 선택되지않은 빈 value인지 여부를 리턴한다.
     */
    public isEmpty() {
        if (this.isTotalSelect === false && (!this._value || this._value.length === 0)) {
            return true;
        }

        return false;
    }

    /**
     * 타입이 명확하지 않은 object가 전체선택 value로 취급될수 있는지 여부를 리턴한다.
     * @param object 
     */
    static canConvert(object: Object) {
        let canConvert = false;
        if (object.hasOwnProperty('isTotalSelect') && typeof object['isTotalSelect'] === 'boolean') {
            canConvert = true;
        }

        if (object.hasOwnProperty('value')) {
            canConvert = true;
        }

        if (object.hasOwnProperty('exceptValue')) {
            canConvert = true;
        }

        return canConvert;
    }

    /**
     * 타입이 명확하지 않은 오브젝트를 CodePickerValue 타입으로 매핑한다.
     * 매핑될수 없는 오브젝트라면 Error를 throw 한다.
     * @param object 
     */
    static valueOf(object: Object) {
        if (!this.canConvert(object)) {
            throw new Error('can not convert')
        }

        return new CodePickerValue(
            object['isTotalSelect'],
            object['value'],
            object['exceptValue'] ? object['exceptValue'] : null,
        )
    }

    /**
     * 아무것도 선택되지 않은 빈 CodePickerValue객체를 생성해 리턴한다.
     */
    static default(): CodePickerValue {
        return new CodePickerValue(false, [], null);
    }

    static asArrayValue(value: any[] | CodePickerValue) {
        if (value instanceof CodePickerValue) {
            return value.value;
        } else {
            return value;
        }
    }
}

interface IOBTCodePicker extends CompositeProps.CodePickerClass, CommonProps.placeHolder, Events.onMouseDown,
    Events.onMouseMove, Events.onMouseUp, Events.onMouseLeave, Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress, Events.onKeyUp, Events.onClick {
    /**
     * 코드피커의 getData에 넘기는 파라미터
     */
    parameters: any,

    /**
     * 코드피커의 기본적인 속성및 데이터를 담고있는 오브젝트
     */
    codePicker: IBuiltInCodePicker,

    /**
     * 멀티선택이 가능한지 여부를 정하는 속성입니다.
     */
    canMultiSelect: boolean,

    /**
     * 선택한 데이터를 인풋에 바인딩할수 있는 전략을 커스텀할 수 있는 이벤트
     */
    onDisplaySelectedItem?: (evt: CodePickerDisplayItemEventArgs) => string,

    /**
     * tooltip
     */
    tooltip?: any,

    /**
     * AutoValueBinder 를 통해 바인딩 될때 codeColumn 으로 쓰일 컬럼명
     */
    bindingCodeColumnName?: string,

    /**
     * AutoValueBinder 를 통해 바인딩 될때 textColumn 으로 쓰일 컬럼명
     */
    bindingTextColumnName?: string,

    /**
     * 최초 검색한 데이터 소스를 캐시할것인지 여부
     * @internal
     */
    isCacheDataSource?: boolean,
    /**
     * 
     */
    fetch?: any,

    /**
     *  default: false
     */
    useTotalToEmpty?: boolean,

    /**
     * @default true
     * 코드피커 검색 시에 로딩을 띄울지에 대한 여부
     */
    useLoading?: boolean

    /**
     * @default default
     * 코드피커 로딩 타입 정의
     */
    loadingType?: Type,

    /**
     * 
     */
    getProfileInfo?: (e: {
        value: any
    }) => Promise<UserProfilePopUpParameter>,

    /**
     * @internal
     */
    dialogParameters?: (() => any),

    onBeforeCallCodePicker?: ((e: {
        target: any,
        cancel: boolean,
        keyword: string | null
    }) => void),

    /**
     * @default true
     * 오직 다이얼로그로만 값을 검색해볼지에 대한 여부. false 시에 키보드 입력은 불가.
     */
    allowKeyboardInput?: boolean
}

class OBTCodePicker extends Component<IOBTCodePicker, hasError> implements IFocusable, IRequired {
    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        CommonDefinitions.disabled(),
        CommonDefinitions.readonly(),
        CommonDefinitions.required(),
        CommonDefinitions.value({
            type: ['any[]', 'CodePickerValue'], description: `코드피커에 표시할 데이터입니다.
string, object(json), array 형태로 입력할 수 있으나, onChange 에서는 array 형태로만 리턴되니 array 형태를 추천드립니다.
string 형태로 부여했을 경우 코드로 간주하고 codeSearch 를 동작하여 표기하며
object 형태로 부여했거나 array 형태로 부여했을 경우 해당 값 그대로 표기합니다.` }),
        {
            name: 'codePicker',
            type: 'CodePicker Interface',
            description: '데이터소스등 코드피커가 가지는 기본적인 속성들을 정의합니다. [인터페이스탭] 참조'
        },
        {
            name: 'parameters',
            type: CommonType.any,
            optional: true,
            description: '코드피커의 getData에 넘기는 파라미터'
        },
        {
            name: 'canMultiSelect',
            type: CommonType.boolean,
            default: false,
            optional: true,
            description: '멀티선택이 가능한지 여부를 정하는 속성입니다.'
        },
        {
            name: 'useTotalToEmpty',
            type: CommonType.boolean,
            default: false,
            optional: true,
            description: '페이징을 사용하는 코드피커의 경우 true로 설정시 그리드에 전체선택이 활성화되며 전체선택 모드가 활성화됩니다.'
        },
        {
            name: 'bindingCodeColumnName',
            type: CommonType.string,
            optional: true,
            description: 'OBTAutoValueBinder 를 통해 그리드(DataGrid, CardList) 와 바인딩 되는 경우 코드컬럼에 대한 정의 제공'
        },
        {
            name: 'bindingTextColumnName',
            type: CommonType.string,
            optional: true,
            description: 'OBTAutoValueBinder 를 통해 그리드(DataGrid, CardList) 와 바인딩 되는 경우 텍스트컬럼에 대한 정의 제공'
        },
        CommonDefinitions.placeHolder(),
        CommonDefinitions.tooltip(),
        CommonDefinitions.onChangeCodePicker(),
        {
            name: 'onDisplaySelectedItem',
            type: CommonType.function,
            optional: true,
            parameters: {
                name: 'e',
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    codeProperty: { type: CommonType.string },
                    textProperty: { type: CommonType.string },
                    dataItemTotalCount: { type: CommonType.number },
                    selectedItemLength: { type: CommonType.number },
                    selectedItems: { type: 'any[]' },
                    exceptValue: { type: 'any[] | null' },
                    getDisplayNameWithSeparator: { type: CommonType.function, parameters: { name: 'separator', type: CommonType.string }, result: CommonType.string }
                }
            },
            description: '선택한 코드값을 인풋 컨트롤에 표시할때의 전략을 커스텀할 수 있는 이벤트입니다. 설정하지 않으면 기본전략을 사용합니다. (코드. 벨류)'
        },
        {
            name: 'onBeforeCallCodePicker',
            type: CommonType.function,
            optional: true,
            parameters: {
                name: 'e',
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    keyword: { type: CommonType.string },
                    cancel: { type: CommonType.boolean },
                }
            },
            description: '코드피커 다이얼로그를 열기전에 호출되는 콜백, 다이얼로그 열기 취소가능'
        },
        {
            name: 'getProfileInfo',
            type: CommonType.function,
            parameters: {
                name: 'e',
                type: {
                    value: { type: CommonType.any }
                }
            },
            optional: true,
            result: 'Promise<{compSeq: string, deptSeq: string, empSeq: string}>',
            description: '{ compSeq: string, deptSeq: string, empSeq: string }을 resolve하는 Promise 리턴 처리하는 함수 작성시, 유저프로필 팝업을 불러오는 아이콘을 렌더링한다.'
        },
        CommonDefinitions.onMoveFocus(),
        CommonDefinitions.onClick(),
        CommonDefinitions.onFocus(),
        CommonDefinitions.onBlur(),
        CommonDefinitions.onMouseDown(),
        CommonDefinitions.onMouseMove(),
        CommonDefinitions.onMouseUp(),
        CommonDefinitions.onMouseLeave(),
        CommonDefinitions.onMouseEnter(),
        CommonDefinitions.onKeyDown(),
        CommonDefinitions.onKeyPress(),
        CommonDefinitions.onKeyUp()
    );

    private inputRef = React.createRef<CodePickerController>();
    context!: React.ContextType<typeof OBTContext>;

    public static defaultProps = {
        isCacheDataSource: false,
        canMultiSelect: false,
        paging: false,
        useTotalToEmpty: false,
        useLoading: true,
        allowKeyboardInput: true
    }

    public focus(isLast?: boolean | undefined): void {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    public isEmpty(): boolean {
        if (this.props.value instanceof CodePickerValue) {
            return this.props.value.isEmpty();
        }

        if (Array.isArray(this.props.value)) {
            return (this.props.value.length === 0);
        }

        return true;
    }

    public validate(): boolean {
        if (!this.inputRef.current) {
            return false;
        }
        return this.inputRef.current.validate();
    }

    private handleMoveFocus = (e: Events.MoveFocusEventArgs) => {
        if (this.props.onMoveFocus) {
            Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, e.direction));
        }
    }

    private handleFocus = (e: Events.FocusEventArgs) => {
        devLog(this.context);

        Util.invokeEvent<Events.FocusEventArgs>(this.props.onFocus, e);
        GuideMessageRenderer.handleFocus(
            this, this.props.id, this.context
        );
    }

    private handleBlur = () => {
        Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
        GuideMessageRenderer.handleBlur(
            this.context,
            this.props.id,
        );
    }

    renderComponent = () => {
        let fetch = this.context.fetch;
        if (this.props.fetch) {
            if (this.props.fetch instanceof Fetch) {
                fetch = this.props.fetch;
            } else {
                fetch = new Fetch(this.props.fetch);
            }
        }

        return (
            <CodePickerController
                id={this.props.id}
                ref={this.inputRef}
                className={this.props.className}
                parameters={this.props.parameters}
                disabled={this.props.disabled}
                readonly={this.props.readonly}
                required={this.props.required}
                frozen={this.props.frozen}
                width={this.props.width}
                height={this.props.height}
                isCacheDataSource={this.props.isCacheDataSource}
                inputValueMaxLength={100}
                value={this.props.value || []}
                canMultiSelect={this.props.canMultiSelect}
                useLoading={this.props.useLoading}
                loadingType={this.props.loadingType}
                codePicker={this.props.codePicker}
                tooltip={this.props.tooltip}
                fetch={fetch}
                useShortCut={this.context.useShortCut}
                useTotalToEmpty={this.props.useTotalToEmpty}
                showUserProfileIcon={
                    this.context &&
                    (this.context as IOBTContext).showUserProfile !== null &&
                    this.props.getProfileInfo !== undefined &&
                    this.isEmpty() === false
                }
                allowKeyboardInput={this.props.allowKeyboardInput}
                onBlur={this.handleBlur}
                onChange={this.props.onChange}
                onDisplaySelectedItem={this.props.onDisplaySelectedItem}
                onFocus={this.handleFocus}
                onMoveFocus={this.handleMoveFocus}
                onMouseDown={this.props.onMouseDown}
                onMouseMove={this.props.onMouseMove}
                onMouseUp={this.props.onMouseUp}
                onMouseLeave={this.props.onMouseLeave}
                onMouseEnter={this.props.onMouseEnter}
                onKeyDown={this.props.onKeyDown}
                onKeyPress={this.props.onKeyPress}
                onKeyUp={this.props.onKeyUp}
                onClick={this.props.onClick}
                onBeforeCallCodePicker={this.props.onBeforeCallCodePicker}
                dialogParameters={this.props.dialogParameters}
                onUserProfileClicked={(item) => {
                    if (this.props.getProfileInfo) {
                        this.props.getProfileInfo({
                            value: item ? item : this.props.value
                        }).then(parameter => {
                            const context = (this.context as IOBTContext);

                            if (context && context.showUserProfile) {
                                context.showUserProfile(parameter);
                            }
                        });
                    }
                }}
            />
        )
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        );
    }
}

OBTCodePicker.contextType = OBTContext;
export default OBTCodePicker;
