/**
 * @version 0.1
 * @author 박재성
 * @see common.js
 */
import * as React from 'react';
import { CommonDefinitions, CommonType, CompositeProps, createPropDefinitions, Excel } from '../Common';
import { OBTButton, OBTDialog2, OBTDataGrid, OBTDataGridInterface, OBTConfirm, OBTAlert, OBTSplitButton, OBTSnackbar } from '..'
import iconBtnArrowDownNormal from '../Images/icon-btn-arrow-down-normal.png';
import iconBtnArrowTopNormal from '../Images/icon-btn-arrow-top-normal.png';
import iconBtnArrowLeft from '../Images/icon-arrow-left.png';
import iconBtnArrowRight from '../Images/icon-arrow-right.png';
import { Events, Util } from '../Common'
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { OBTContext } from '../OBTPageContainer/OBTPageContainer';
import { ButtonType } from '../OBTButton';
import { ExcelOption } from '../Common/Excel';
import { OrbitInternalLangPack } from '../Common/Util';

const styles = require('./OBTExcelFormDialog.module.scss');

interface FormDataParam {
    ajaxEbp: any,
    companyCode: string,
    menuCode: string,
    tableName: string,
    isUse?: boolean
}

interface FormData {
    columnNm: string,
    coCd: string,
    tableNm: string,
    columnKorDc: string,
    columntypeFg: string,
    sizeQt: string,
    nullableFg: string,
    menuCd: string,
    remarkKorDc: string,
    columnSq: number
}

/**
 * PropType 정의
 * Events, CommonProps, CompositeProps 에 미리 지정된 Prop interface 를 충분히 활용한다.
 * extends 로 인터페이스 상속을 통해 사용된다.
 * 공용 Api 를 사용하려면 CommonProps.api 인터페이스를 확장한다.
 */
interface IOBTExcelFormDialog extends CompositeProps.Default {
    open?: boolean,
    fileName?: string,
    option?: ExcelOption,
    formDataParam: FormDataParam,
    onSave?: (e: Events.EventArgs) => void,
    onClose?: (e: Events.EventArgs) => void,
    onCreate?: (e: Events.EventArgs) => void,
}

/**
 * State 정의
 */
interface State extends hasError {
    openConfirm: boolean,
    openConfirmCancel: boolean,
    openAlert: boolean,
    labelTextAlert: any,
    gridL: OBTDataGridInterface,
    gridR: OBTDataGridInterface,
    gridDataOrigin: Array<FormData>,
    gridDataL: Array<FormData>,
    gridDataR: Array<FormData>,
    messageText: string,
    buttonDisabled: boolean
}

/**
 * withApi() HOC 를 사용하면 Props 로 Api 를 사용할 수 있다.
 * api 가 Optional 로 선언되었기에 내부에서 ! 오퍼레이터를 사용해서 호출한다.
 * {@code this.props.api!.test();}
 */
export default class OBTExcelFormDialog extends React.Component<IOBTExcelFormDialog, State> {
    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        { name: "open", type: CommonType.boolean, default: false, description: "true 일때 Dialog가 열립니다." },
        { name: "fileName", type: CommonType.string, optional: true, description: "EXPORT 되는 EXCEL파일의 이름을 설정할 수 있습니다.", default: "[menuCode]_EXCEL_IMPORT_[yyyymmddhhmmss].xlsx" },
        {
            name: "formDataParam", type: {
                ajaxEbp: { type: CommonType.any },
                companyCode: { type: CommonType.string },
                menuCode: { type: CommonType.string },
                tableName: { type: CommonType.string },
                isUse: { type: CommonType.boolean, optional: true }
            }, description: "EXCEL IMPORT, EXCEL 양식생성 시 필요한 컬럼의 정보를 받아옴"
        },
        {
            name: "option", type: {
                sheetName: { type: CommonType.string, optional: true }
            }, optional: true, description: "- sheetName : EXPORT 시에 시트 이름을 설정할 수 있습니다."
        },
        {
            name: "onSave", type: CommonType.function, optional: true, parameters: {
                name: "e",
                type: CommonType.any,
                description: "이벤트가 발생한 컴포넌트의 instance"
            }, description: "다이얼로그에서 저장 버튼 클릭 시 발생하는 Callback 함수입니다."
        },
        {
            name: "onClose", type: CommonType.function, optional: true, parameters: {
                name: "e",
                type: CommonType.any,
                description: "이벤트가 발생한 컴포넌트의 instance"
            }, description: "다이얼로그에서 다이얼로그에서 X 버튼, 양식 만들기, 취소 버튼 클릭 시 발생하는 Callback 함수입니다."
        },
        {
            name: "onCreate", type: CommonType.function, optional: true, parameters: {
                name: "e",
                type: CommonType.any,
                description: "이벤트가 발생한 컴포넌트의 instance"
            }, description: "다이얼로그에서 양식만들기 버튼 클릭 시 발생하는 Callback 함수입니다."
        },
    );

    ///////////////////////////////////////////////////////////////////////////// Initialize
    context!: React.ContextType<typeof OBTContext>

    /**
     * Default Props 설정
     */
    public static defaultProps = {
    }

    /**
     * State 정의
     */
    public state: State = {
        openConfirm: false, // 저장 여부 묻는 confirm open
        openConfirmCancel: false,
        openAlert: false, // notnull 컬럼 저장 불가 alert open
        labelTextAlert: '',
        gridL: this.initializeGridL(),
        gridR: this.initializeGridR(),
        gridDataOrigin: [] as any,
        gridDataL: [] as any,
        gridDataR: [] as any,
        messageText: '',//스낵바 메세지 state
        buttonDisabled: false,
    }

    /**
     * Ref 정의
     */
    public myRefs = {
        id: React.createRef<HTMLDivElement>()
    }

    ///////////////////////////////////////////////////////////////////////////// Life Cycle API
    /**
     * component 의 dom 이 생성된 다음 호출된다.
     * jquery 등의 외부 라이브러리 로드에 사용된다.
     * {@code componentDidMount() {
     *     this.ref.input.focus();
     * }}
     */
    componentDidUpdate(prevProps, prevState) {
        try {
            if (prevProps.open !== this.props.open) {
                if (this.props.open) {

                    let fetch = this.context.fetch;
                    if (this.props.formDataParam.ajaxEbp) {
                        fetch = this.props.formDataParam.ajaxEbp;
                    }

                    Excel.getExcelFormData(fetch, '0000', this.props.formDataParam.menuCode, this.props.formDataParam.tableName).then(res => {
                        this.setState({ gridDataL: res });
                    }).catch(err => {
                        this.setState({
                            openAlert: true,
                            labelTextAlert: <pre className={styles.alertPre}>{OrbitInternalLangPack.getText('WE000022679', '엑셀 데이터를 가져올 수 없습니다.')}</pre>
                        })
                    })

                    Excel.getExcelFormData(fetch, this.props.formDataParam.companyCode, this.props.formDataParam.menuCode, this.props.formDataParam.tableName).then(res => {
                        this.setState({ gridDataOrigin: JSON.parse(JSON.stringify(res)), gridDataR: res });
                    })
                }
            }

            if (prevState.gridDataL !== this.state.gridDataL) {
                this.state.gridL.readData();
            }

            if (prevState.gridDataR !== this.state.gridDataR) {
                this.state.gridR.readData();
            }
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    buttons: any[] = [
        OBTDialog2.Button.Cancel(async ({ target, event }) => {
            let isChange = await this.isChange()
            if (isChange) {
                this.setState({ openConfirmCancel: true });
            } else {
                this.onClose();
            }
        }),
        {
            key: 'createExcel',
            onClick: () => this.handleCreateExcel(),
            labelText: OrbitInternalLangPack.getText('WE000022680', '양식 만들기'),
            type: OBTButton.Type.big,
            theme: OBTButton.Theme.blue
        },
    ]

    // component 가 render 될때 호출됨.
    render() {
        return (<ErrorBoundary owner={this} render={this.renderComponent} />)
    }

    renderComponent = () => {
        return (
            <>
                <div id={this.props.id} data-orbit-component={'OBTExcelFormDialog'} className={this.props.className}>
                    <OBTDialog2
                        width={'1024px'}
                        height={'568px'}
                        open={this.props.open}
                        type={OBTDialog2.Type.default}
                        title={OrbitInternalLangPack.getText('WE000022682', '엑셀 양식 만들기')}
                        buttons={this.buttons}
                    >

                        {/* 상단 박스 div */}
                        <div className={styles.topDiv}>
                            {/* 좌측 타이틀 */}
                            <span className={styles.bullet}></span>
                            <div className={styles.leftTopDiv}>
                                {OrbitInternalLangPack.getText('WE000022683', '기본 설정 항목')}
                            </div>

                            <div className={styles.middleTopDiv}>
                            </div>

                            {/* 우측 타이틀*/}
                            <span className={styles.bullet}></span>
                            <div className={styles.rightTopDiv}>
                                <div className={styles.rightTopLeftSubDiv}>

                                    {OrbitInternalLangPack.getText('WE000022684', '사용자 설정 항목')}
                                </div>

                                <div className={styles.rightTopRightSubDiv}>
                                    <OBTButton type={ButtonType.default} labelText={OrbitInternalLangPack.getText('WE000004653', '저장')} onClick={this.handleSave} className={styles.saveButton} />
                                    <OBTSplitButton
                                        value={[
                                            { key: 0, labelText: OrbitInternalLangPack.getText("WE000022669", "새로작성") },
                                            { key: 1, labelText: OrbitInternalLangPack.getText("WE000022669", "새로작성") },
                                            { key: 2, labelText: OrbitInternalLangPack.getText("WE000022670", "필수항목으로 새로작성") },
                                        ]}
                                        type={OBTSplitButton.Type.default}
                                        onClick={this.handleSplitButtonClick.bind(this)}
                                    />
                                    <OBTButton
                                        className={styles.arrowBtn}
                                        imageUrl={iconBtnArrowTopNormal}
                                        width='27px'
                                        height='27px'
                                        onClick={this.handleUp.bind(this)}
                                        tooltip={{ labelText: OrbitInternalLangPack.getText("WE000022671", "위로 이동") }}
                                        disabled={this.state.buttonDisabled}
                                    />

                                    <OBTButton
                                        className={styles.arrowBtn}
                                        imageUrl={iconBtnArrowDownNormal}
                                        width='27px' height='27px'
                                        onClick={this.handleDown.bind(this)}
                                        tooltip={{ labelText: OrbitInternalLangPack.getText("WE000022672", "아래로 이동") }}
                                        disabled={this.state.buttonDisabled}
                                    />
                                </div>
                            </div>
                        </div>


                        {/* 가득찬 div */}
                        <div className={styles.fillDiv}>

                            {/* 좌측 div */}
                            <div className={styles.leftDiv}>
                                {/* 좌측 그리드 */}
                                <div className={styles.leftFillDiv}>
                                    <OBTDataGrid interface={this.state.gridL} />
                                </div>
                            </div>

                            {/* 가운데 div */}
                            <div className={styles.middleDiv}>
                                <div className={styles.middleSubDiv}>
                                    <OBTButton
                                        className={styles.middleBtn}
                                        imageUrl={iconBtnArrowRight}
                                        width='27px'
                                        height='27px'
                                        onClick={this.handleRight.bind(this)}
                                        tooltip={{
                                            labelText: OrbitInternalLangPack.getText('WE000008407', '항목 추가'),
                                            position: 'top'
                                        }}
                                    />
                                    <OBTButton
                                        className={styles.middleBtn}
                                        imageUrl={iconBtnArrowLeft}
                                        width='27px'
                                        height='27px'
                                        onClick={this.handleLeft.bind(this)}
                                        tooltip={{
                                            labelText: OrbitInternalLangPack.getText('WE000022674', '항목 제거'),
                                            position: 'bottom'
                                        }}
                                    />
                                </div>
                            </div>

                            {/* 우측 div */}
                            <div className={styles.rightDiv}>
                                {/* 우측 그리드*/}
                                <div className={styles.rightFillDiv}>
                                    <OBTDataGrid interface={this.state.gridR} />
                                </div>
                            </div>
                        </div>
                    </OBTDialog2>
                </div>
                {this.state.openConfirm === true ?
                    <OBTConfirm
                        title={''}
                        labelText={<pre className={styles.alertPre}>{OrbitInternalLangPack.getText("WE000022675", "데이터가 변경되었습니다. 저장하시겠습니까?")}</pre>}
                        onConfirm={this.handleAlertSave}
                        onCancel={this.handleNotSave}
                        type={OBTConfirm.Type.question} /> : <></>}

                {this.state.openConfirmCancel === true ?
                    <OBTConfirm
                        title={''}
                        labelText={<pre className={styles.alertPre}>{OrbitInternalLangPack.getText("WE000022676", "데이터가 변경되었습니다. 취소하시겠습니까?")}</pre>}
                        onConfirm={() => {
                            this.setState({
                                openConfirmCancel: false,
                            }, () => {
                                this.onClose()
                            })
                        }}
                        onCancel={() => {
                            this.setState({
                                openConfirmCancel: false,
                            })
                        }}
                        type={OBTConfirm.Type.warning} /> : <></>}

                {this.state.openAlert === true ?
                    <OBTAlert
                        title={''}
                        labelText={this.state.labelTextAlert}
                        onClose={() => this.setState({ openAlert: false })}
                        type={OBTAlert.Type.error} /> : <></>}

                {this.state.messageText.length > 0 ?
                    <OBTSnackbar key={this.state.messageText}
                        labelText={this.state.messageText ? this.state.messageText : this.state.messageText} /> : <></>}
            </>
        )
    }

    initializeGridL() {
        const gridL = new OBTDataGridInterface('gridL', {
            gridType: OBTDataGridInterface.GridType.gridView,
            editable: false,
            appendable: false,
            checkable: true,
            indicator: false,
            headerCheckVisible: true
        }).setColumns([
            {
                name: 'columnKorDc',
                header: OrbitInternalLangPack.getText('WE000022658', '항목이름'),
                type: "text",
                width: 120,
                maxLength: 5,
                dynamicStyles: (grid, index, value) => {
                    if (this.state.gridDataL[index.itemIndex].nullableFg === '0') {
                        return {
                            background: "#FEF3F0"
                        }
                    }
                }
            },
            {
                name: 'columnNm',
                header: OrbitInternalLangPack.getText('WE000022660', '컬럼명'),
                type: "text",
                width: 100,
                dynamicStyles: (grid, index, value) => {
                    if (this.state.gridDataL[index.itemIndex].nullableFg === '0') {
                        return {
                            background: "#FEF3F0"
                        }
                    }
                }
            },
            {
                name: 'columntypeFg',
                header: OrbitInternalLangPack.getText('WE000010558', '형식'),
                type: "dropDown",
                alignment: 'center',
                dropDownDataItems: [
                    { code: '0', text: OrbitInternalLangPack.getText('WE000005193', '문자') },
                    { code: '1', text: OrbitInternalLangPack.getText('WE000007419', '숫자') }
                ],
                dropDownCodeProperty: 'code',
                dropDownTextProperty: 'text',
                width: 60,
                dynamicStyles: (grid, index, value) => {
                    if (this.state.gridDataL[index.itemIndex].nullableFg === '0') {
                        return {
                            background: "#FEF3F0"
                        }
                    }
                }
            },
            {
                name: 'sizeQt',
                header: OrbitInternalLangPack.getText('WE000022663', '길이'),
                type: "text",
                width: 60,
                dynamicStyles: (grid, index, value) => {
                    if (this.state.gridDataL[index.itemIndex].nullableFg === '0') {
                        return {
                            background: "#FEF3F0"
                        }
                    }
                }
            },
            {
                name: 'nullableFg',
                header: OrbitInternalLangPack.getText('WE000000215', '필수여부'),
                type: "dropDown",
                alignment: 'center',
                dropDownDataItems: [
                    { code: '0', text: OrbitInternalLangPack.getText('WE000003074', '필수') }, //TODO: Essential로 번역 
                    { code: '1', text: OrbitInternalLangPack.getText('WE000000867', '허용') }
                ],
                dropDownCodeProperty: 'code',
                dropDownTextProperty: 'text',
                width: 60,
                dynamicStyles: (grid, index, value) => {
                    if (this.state.gridDataL[index.itemIndex].nullableFg === '0') {
                        return {
                            background: "#FEF3F0"
                        }
                    }
                }
            }] as any[]).setProvider({
                read: (e) => {
                    return new Promise((resolve) => {
                        resolve(this.state.gridDataL);
                    })
                }
            })
        return gridL;
    }

    initializeGridR() {
        const gridR = new OBTDataGridInterface('gridR', {
            gridType: OBTDataGridInterface.GridType.gridView,
            editable: false,
            appendable: false,
            checkable: true,
            indicator: false,
            headerCheckVisible: true
        }).setColumns([
            {
                name: 'columnKorDc',
                header: OrbitInternalLangPack.getText('WE000022658', '항목이름'),
                type: "text",
                width: 120,
                maxLength: 5,
                dynamicStyles: (grid, index, value) => {
                    if (this.state.gridDataR[index.itemIndex].nullableFg === '0') {
                        return {
                            background: "#FEF3F0"
                        }
                    }
                }
            },
            {
                name: 'columnNm',
                header: OrbitInternalLangPack.getText('WE000022660', '컬럼명'),
                type: "text",
                width: 100,
                dynamicStyles: (grid, index, value) => {
                    if (this.state.gridDataR[index.itemIndex].nullableFg === '0') {
                        return {
                            background: "#FEF3F0"
                        }
                    }
                }
            },
            {
                name: 'columntypeFg',
                header: OrbitInternalLangPack.getText('WE000010558', '형식'), // TODO: form으로 번역 
                type: "dropDown",
                alignment: 'center',
                dropDownDataItems: [
                    { code: '0', text: OrbitInternalLangPack.getText('WE000005193', '문자') },
                    { code: '1', text: OrbitInternalLangPack.getText('WE000007419', '숫자') }
                ],
                dropDownCodeProperty: 'code',
                dropDownTextProperty: 'text',
                width: 60,
                dynamicStyles: (grid, index, value) => {
                    if (this.state.gridDataR[index.itemIndex].nullableFg === '0') {
                        return {
                            background: "#FEF3F0"
                        }
                    }
                }
            },
            {
                name: 'sizeQt',
                header: OrbitInternalLangPack.getText('WE000022663', '길이'),
                type: "text",
                width: 60,
                dynamicStyles: (grid, index, value) => {
                    if (this.state.gridDataR[index.itemIndex].nullableFg === '0') {
                        return {
                            background: "#FEF3F0"
                        }
                    }
                }
            },
            {
                name: 'nullableFg',
                header: OrbitInternalLangPack.getText('WE000000215', '필수여부'),
                type: "dropDown",
                alignment: 'center',
                dropDownDataItems: [
                    { code: '0', text: OrbitInternalLangPack.getText('WE000003074', '필수') }, //TODO: Essential로 번역 
                    { code: '1', text: OrbitInternalLangPack.getText('WE000000867', '허용') }
                ],
                dropDownCodeProperty: 'code',
                dropDownTextProperty: 'text',
                width: 60,
                dynamicStyles: (grid, index, value) => {
                    if (this.state.gridDataR[index.itemIndex].nullableFg === '0') {
                        return {
                            background: "#FEF3F0"
                        }
                    }
                }
            }] as any[]).setProvider({
                read: (e) => {
                    return new Promise((resolve) => {
                        resolve(this.state.gridDataR);
                    })
                }
            })
        gridR.onAfterRead.add((e) => {
            let countData = this.state.gridR.getRowCount();

            this.setState({
                buttonDisabled: countData === 0
            })
        })

        return gridR;
    }

    // 순서 위로(포커스된 행만 이동. 체크된 행은X)
    handleUp() {
        // 
        let countData = this.state.gridR.getRowCount()
        if (countData === 0) {
            return;
        }

        let tempData = this.state.gridDataR;
        let curIdx = this.state.gridR.getSelectedIndex();
        let newIdx = curIdx - 1 >= 0 ? curIdx - 1 : 0;
        let curElement = tempData[curIdx];

        tempData.splice(curIdx, 1);
        tempData.splice(newIdx, 0, curElement);

        this.setState({ gridDataR: tempData });
        this.state.gridR.readData().then(() => {
            this.state.gridR.setSelection(newIdx);
            this.state.gridR.focus();
        });
    }

    // 순서 아래로(포커스된 행만 이동. 체크된 행은X)
    handleDown() {
        let countData = this.state.gridR.getRowCount();
        if (countData === 0) {
            return;
        }

        let tempData = this.state.gridDataR;
        let curIdx = this.state.gridR.getSelectedIndex();
        let newIdx = curIdx + 1 <= tempData.length - 1 ? curIdx + 1 : tempData.length - 1;
        let curElement = tempData[curIdx];

        tempData.splice(curIdx, 1);
        tempData.splice(newIdx, 0, curElement);

        this.setState({ gridDataR: tempData });
        this.state.gridR.readData().then(() => {
            this.state.gridR.setSelection(newIdx);
            this.state.gridR.focus();
        });
    }

    // 삭제(체크된 행이 있으면 체크된 행, 체크된 행이 없으면 포커스된 행)
    handleLeft() {
        let checkedRows = this.state.gridR.getRows({ checkedOnly: true }) as Array<FormData>;

        if (checkedRows.length > 0) {
            checkedRows.forEach(element => {
                let idx = this.state.gridDataR.findIndex(x => x.columnNm === element.columnNm)
                if (idx > -1) {
                    this.state.gridDataR.splice(idx, 1)
                }
            })
        } else {
            this.state.gridDataR.splice(this.state.gridR.getSelectedIndex(), 1)
        }

        this.state.gridL.readData();
        this.state.gridR.readData();
    }

    // 추가(체크된 행이 있으면 체크된 행, 체크된 행이 없으면 포커스된 행)
    handleRight() {
        let checkedRows = this.state.gridL.getRows({ checkedOnly: true });
        let findEle;
        if (checkedRows.length > 0) {
            checkedRows.forEach(element => {
                findEle = this.state.gridDataR.find(dataR =>
                    dataR.columnNm === element.columnNm
                )
                if (!findEle) {
                    let pushEle = this.state.gridDataL.find(dataL =>
                        dataL.columnNm === element.columnNm
                    )
                    if (pushEle) {
                        pushEle.coCd = this.props.formDataParam.companyCode;
                    }
                    this.state.gridDataR.push(pushEle as FormData);
                }
            });
        } else {
            let focusedRow = this.state.gridL.getRow(this.state.gridL.getSelectedIndex());
            findEle = this.state.gridDataR.find(dataR =>
                dataR.columnNm === focusedRow.columnNm
            )
            if (!findEle) {
                let pushEle = this.state.gridDataL.find(dataL =>
                    dataL.columnNm === focusedRow.columnNm
                )
                if (pushEle) {
                    pushEle.coCd = this.props.formDataParam.companyCode;
                }
                this.state.gridDataR.push(pushEle as FormData);
            }
        }

        this.state.gridL.readData();
        this.state.gridR.readData();
    }

    // 양식만들기
    handleCreateExcel = async () => {
        let isExistNotnull = await this.isExistNotnull()
        if (isExistNotnull) {
            await Excel.createExcel(this.state.gridDataR, this.props.formDataParam.menuCode, this.props.fileName, this.props.option);
            await this.handleClose();
            this.onCreate()
        }
    }

    // 저장
    handleSave = async () => {
        let isChange = await this.isChange()
        if (isChange) {
            let isExistNotnull = await this.isExistNotnull()
            if (isExistNotnull) {
                this.state.gridDataR.forEach((element, idx) => {
                    element.columnSq = idx + 1;
                })

                let fetch = this.context.fetch;
                if (this.props.formDataParam.ajaxEbp) {
                    fetch = this.props.formDataParam.ajaxEbp;
                }

                Excel.setExcelFormData(fetch, this.state.gridDataR, this.props.formDataParam.companyCode, this.props.formDataParam.menuCode, this.props.formDataParam.tableName).then(() => {
                    this.setState({
                        openConfirm: false,
                    }, () => {
                        this.onSave();
                        this.onClose();
                    })
                }).catch(() => {
                    this.setState({
                        openConfirm: false,
                        openAlert: true,
                        labelTextAlert: <pre className={styles.alertPre}>{OrbitInternalLangPack.getText('WE000001719', '에러 발생')}</pre>
                    })
                });
            }
        } else {
            this.setState({
                openConfirm: false,
            }, () => {
                this.onSave();
                this.setState({
                    messageText: OrbitInternalLangPack.getText('WE000001294', '저장되었습니다.')
                })
            })
        }
    }

    // 새로작성
    handleNewWrite() {
        this.setState({
            gridDataR: JSON.parse(JSON.stringify(this.state.gridDataL)).map(element => {
                return {
                    ...element,
                    coCd: this.props.formDataParam.companyCode
                }
            })
        }, () => {
            this.state.gridR.readData();
        })
    }

    // 필수항목으로 새로작성
    handleNewWriteNotnull() {
        this.setState({
            gridDataR: this.state.gridDataL.filter(element =>
                element.nullableFg === '0'
            ).map(element => {
                return {
                    ...element,
                    coCd: this.props.formDataParam.companyCode
                }
            })
        }, () => {
            this.state.gridR.readData();
        })
    }


    // <저장하시겠습니까? 알림창> 저장
    handleAlertSave = async () => {
        let isExistNotnull = await this.isExistNotnull()
        if (isExistNotnull) {
            this.state.gridDataR.forEach((element, idx) => {
                element.columnSq = idx + 1;
            })

            let fetch = this.context.fetch;
            if (this.props.formDataParam.ajaxEbp) {
                fetch = this.props.formDataParam.ajaxEbp;
            }

            Excel.setExcelFormData(fetch, this.state.gridDataR, this.props.formDataParam.companyCode, this.props.formDataParam.menuCode, this.props.formDataParam.tableName).then(() => {
                this.setState({
                    openConfirm: false
                }, () => {
                    this.onSave();
                    this.onClose();
                })
            }).catch(() => {
                this.setState({
                    openConfirm: false,
                    openAlert: true,
                    labelTextAlert: <pre className={styles.alertPre}>{OrbitInternalLangPack.getText('WE000001719', '에러 발생')}</pre>
                })
            });
        } else {
            this.setState({
                openConfirm: false,
            })
        }
    }

    // <저장하시겠습니까? 알림창> 저장 안함
    handleNotSave = () => {
        this.setState({ openConfirm: false }, () => {
            this.onClose();
        })
    }

    // 엑셀 양식 만들기 다이얼로그 창 껐을 때
    handleClose = async () => {
        let isChange = await this.isChange()
        if (isChange) {
            this.setState({ openConfirm: true });
        } else {
            this.onClose();
        }
    }

    // 데이터 변화 체크
    isChange = () => {
        return new Promise((resolve) => {
            // 길이가 다름
            if (this.state.gridDataOrigin.length !== this.state.gridDataR.length) {
                resolve(true)
            } else {
                // 길이가 달라도 요소가 다를경우(순서 포함)
                this.state.gridDataOrigin.forEach((element, idx) => {
                    if (element.columnNm !== this.state.gridDataR[idx].columnNm) {
                        resolve(true)
                    }
                })
                resolve(false)
            }
        })
    }

    // Notnull Column이 모두 존재하는지 확인
    isExistNotnull = () => {
        return new Promise((resolve) => {
            let notnulls = this.state.gridDataL.filter(element =>
                element.nullableFg === '0'
            )
            notnulls.forEach((element) => {
                if (!this.state.gridDataR.find(x => x.columnNm === element.columnNm)) {
                    resolve(false);
                    this.setState({
                        openAlert: true,
                        openConfirm: false,
                        labelTextAlert: <pre className={styles.alertPre}>{`${OrbitInternalLangPack.getText("WE000019380", "필수 항목이 선택 되지 않았습니다")}
${OrbitInternalLangPack.getText("WE000022658", "항목이름")}: [${element.columnKorDc}]
${OrbitInternalLangPack.getText("WE000022660", "컬럼명")}: [${element.columnNm}]`}</pre>
                    })
                }
            })
            resolve(true);
        });
    }

    handleSplitButtonClick({ target, event, key }) {
        if (key === 1) {
            this.handleNewWrite()
        } else if (key === 2) {
            this.handleNewWriteNotnull()
        }
    }

    private onSave(): void {
        Util.invokeEvent<Events.EventArgs>(this.props.onSave, new Events.EventArgs(this));
    }

    private onClose(): void {
        Util.invokeEvent<Events.EventArgs>(this.props.onClose, new Events.EventArgs(this));
    }

    private onCreate(): void {
        Util.invokeEvent<Events.EventArgs>(this.props.onCreate, new Events.EventArgs(this));
    }
};

OBTExcelFormDialog.contextType = OBTContext;