import PostCodeDialog from "./PostCodeDialog";
import IBuiltInCodePicker, { CodePickerSize } from "../IBuiltInCodePicker";
import { OrbitInternalLangPack } from "../../../Common/Util";

let postCodePicker: IBuiltInCodePicker = {
    id: "DAUM_POST_CODE",
    placeHolder: OrbitInternalLangPack.getText('WE000000009', '우편번호'),
    dialogTitle: OrbitInternalLangPack.getText('WE000000009', '우편번호'),
    size: CodePickerSize.custom,
    dialogWidth: '400px',
    dialogHeight: '650px',
    codeProperty: "zonecode",
    textProperty: "address",
    customDialogComponent: PostCodeDialog,
}

export default postCodePicker;
