import React from "react";
import {
    OBTAlert,
    OBTButton,
    OBTComplete,
    OBTDatePicker,
    OBTDialog,
    OBTDropDownList,
    OBTFormPanel,
    OBTMultiLineTextField,
    OBTTextField
} from "../../components";
import moment from 'moment'
import { getCookie } from "./cookie";

const warningLevel = [
    { value: 1, labelText: '일반' },
    { value: 2, labelText: '중요' },
    { value: 3, labelText: '긴급' },
];

const updateType = [
    { value: 'doc', labelText: '일반' },
    { value: 'del', labelText: '폐기' },
    { value: 'imp', labelText: '기능개선' },
    { value: 'new', labelText: '새컴포넌트' },
    { value: 'bug', labelText: '버그개선' },
    { value: 'style', labelText: '스타일개선' },
];

const componentName = [
    { type: "Container", name: "OBTAutoValueBinder" },
    { type: "Container", name: "OBTPageContainer" },
    { type: "Container", name: "OBTDockPanel" },
    { type: "Container", name: "OBTFormPanel" },
    { type: "Container", name: "OBTFloatingPanel" },
    { type: "Container", name: "OBTReferencePanel" },
    { type: "Container", name: "OBTPopupPanel" },
    { type: "Container", name: "OBTDialog" },
    { type: "Container", name: "OBTExcelFormDialog" },
    { type: "Container", name: "OBTAccordion" },
    { type: "Container", name: "OBTTab" },
    { type: "Input", name: "OBTTextField" },
    { type: "Input", name: "OBTMultiLineTextField" },
    { type: "Input", name: "OBTNumberField" },
    { type: "Input", name: "OBTMaskedTextField" },
    { type: "Input", name: "OBTComplete" },
    { type: "Input", name: "OBTAutoCompleteChips" },
    { type: "Input", name: "OBTDropDownList" },
    { type: "Input", name: "OBTDropDownList2" },
    { type: "Input", name: "OBTMultiDropDownList" },
    { type: "Input", name: "OBTButton" },
    { type: "Input", name: "OBTButtonGroup" },
    { type: "Input", name: "OBTSplitButton" },
    { type: "Input", name: "OBTRadioButton" },
    { type: "Input", name: "OBTCheckBox" },
    { type: "Input", name: "OBTPreviousNextSelector" },
    { type: "Input", name: "OBTGroupSelector" },
    { type: "Input", name: "OBTTimePicker" },
    { type: "Input", name: "OBTDatePicker" },
    { type: "Input", name: "OBTSingleDatePicker" },
    { type: "Input", name: "OBTSingleYearPicker" },
    { type: "Input", name: "OBTDatePeriodPicker" },
    { type: "Input", name: "OBTYearMonthPeriodPicker" },
    { type: "Data", name: "OBTDataGrid" },
    { type: "Data", name: "OBTListGrid" },
    { type: "Data", name: "OBTCardList" },
    { type: "Data", name: "OBTChip" },
    { type: "Data", name: "OBTTreeView" },
    { type: "Etc", name: "OBTTitle" },
    { type: "Etc", name: "OBTTooltip" },
    { type: "Etc", name: "OBTSnackbar" },
    { type: "Etc", name: "OBTAlert" },
    { type: "Etc", name: "OBTConfirm" },
    { type: "Etc", name: "OBTLoading" },
    { type: "Etc", name: "OBTProgress" },
    { type: "Etc", name: "OBTLinearProgress" },
    { type: "Etc", name: "OBTCalendar" },
    { type: "Etc", name: "OBTScrollbar" },
    { type: "Etc", name: "OBTFileView" },
    { type: "Etc", name: "OBTColorPicker" },
]

export default class UpdateDialog extends React.Component {

    state = {
        updateDate: moment(new Date()).format('YYYYMMDD'),
        warningLevel: 1,
        updateType: 'doc',
        componentName: '',
        subject: '',
        jiraLink: '',
        wikiLink: '',
        detailLink: '',
        notice: '',
        alertOpen: false
    }

    myRefs = {
        formPanelRef: React.createRef(),
        datePickerRef: React.createRef()
    }

    componentDidUpdate(prevState, nextState) {
        if (prevState.editItem && prevState.isEdit && nextState.subject === "") {
            const editItem = prevState.editItem;
            this.setState({
                updateDate: editItem.updateDate,
                warningLevel: editItem.warningLevel,
                updateType: editItem.type,
                componentName: editItem.componentName,
                subject: editItem.subject,
                jiraLink: editItem.jiraLink,
                wikiLink: editItem.wikiLink,
                detailLink: editItem.detailLink,
                notice: editItem.notice,
            })
        }
    }

    searchData = (keyWord) => {
        return new Promise((resolve, reject) => {
            let result = componentName.filter(this.isMatch(keyWord));
            resolve(result);
        });
    }

    isMatch = (keyWord) => {
        return (value) => {
            let result = (
                value["type"].toLowerCase().indexOf(keyWord) !== -1 ||
                value["name"].toLowerCase().indexOf(keyWord) !== -1
            );
            return result;
        }
    }

    handleSearch = (keyWord) => {
        return this.searchData(keyWord);
    }

    getDataInfo = () => {
        return {
            columnWidths: ["50%", "50%"],
            itemInfo: [
                {
                    key: "type",
                    column: 0,
                },
                {
                    key: "name",
                    column: 1,
                    isKeyValue: true
                },
            ]
        }
    }

    handleAddButton = (e) => {
        // 1. 닫기 버튼일 경우 닫아버리기
        if (e.type === "closeButton" || e.type === "esc") {
            this.props.onClose();
            this.setState({
                updateDate: moment(new Date()).format('YYYYMMDD'),
                warningLevel: 1,
                updateType: 'doc',
                componentName: '',
                subject: '',
                jiraLink: '',
                wikiLink: '',
                detailLink: '',
                notice: '',
            });
            return;
        }

        // 2. 필수 컬럼 미작성시 툴팁 띄우기
        if (this.myRefs.formPanelRef.current) {
            const result = this.myRefs.formPanelRef.current.validate(true);
            if (result === false) {
                return;
            }
        }

        fetch('http://10.82.6.190/history/add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getCookie('login')}`,
            },
            body: JSON.stringify({
                updateDate: this.state.updateDate,
                warningLevel: this.state.warningLevel,
                type: this.state.updateType,
                componentName: this.state.componentName,
                subject: this.state.subject,
                jiraLink: this.state.jiraLink,
                wikiLink: this.state.wikiLink,
                detailLink: this.state.detailLink,
                notice: this.state.notice
            })
        }).then((response) => {
            if (response.ok) {
                this.setState({
                    alertOpen: true
                });
                this.props.onRefresh();
            }
        }).catch((e) => {
            console.error(e)
            throw e;
        })
    }

    handleEditButton = (e) => {
        // 1. 닫기 버튼일 경우 닫아버리기
        if (e.type === "closeButton" || e.type === "esc") {
            this.props.onClose();
            this.setState({
                updateDate: moment(new Date()).format('YYYYMMDD'),
                warningLevel: 1,
                updateType: 'doc',
                componentName: '',
                subject: '',
                jiraLink: '',
                wikiLink: '',
                detailLink: '',
                notice: '',
            });
            this.props.onClear();
            return;
        }

        // 2. 필수 컬럼 미작성시 툴팁 띄우기
        if (this.myRefs.formPanelRef.current) {
            const result = this.myRefs.formPanelRef.current.validate(true);
            if (result === false) {
                return;
            }
        }

        // 3. fetch (post로 보내고/수정한다)
        fetch('http://10.82.6.190/history/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getCookie('login')}`
            },
            body: JSON.stringify({
                id: this.props.editItem["_id"],
                updateDate: this.state.updateDate,
                warningLevel: this.state.warningLevel,
                type: this.state.updateType,
                componentName: this.state.componentName,
                subject: this.state.subject,
                jiraLink: this.state.jiraLink,
                wikiLink: this.state.wikiLink,
                detailLink: this.state.detailLink,
                notice: this.state.notice
            })
        }).then((response) => {
            if (response.ok) {
                this.props.onClear();
                this.setState({
                    updateDate: moment(new Date()).format('YYYYMMDD'),
                    warningLevel: 1,
                    updateType: 'doc',
                    componentName: '',
                    subject: '',
                    jiraLink: '',
                    wikiLink: '',
                    detailLink: '',
                    notice: '',
                }, () => {
                    this.props.onClose();
                    this.props.onRefresh();
                });
            }
            // 성공하지 못했을때 처리
        }).catch((e) => {
            console.error(e)
            throw e;
        })
    }

    render() {
        return (
            <>
                <OBTDialog
                    open={this.props.dialog}
                    buttons={
                        this.props.isEdit ?
                            [
                                {
                                    key: 'edit',
                                    onClick: this.handleEditButton,
                                    labelText: '수정',
                                    isClose: true,
                                    theme: OBTButton.Theme.drawer
                                }
                            ]
                            :
                            [
                                {
                                    key: 'add',
                                    onClick: this.handleAddButton,
                                    labelText: '추가',
                                    isClose: true,
                                    theme: OBTButton.Theme.drawer
                                }
                            ]
                    }
                    title={this.props.isEdit ? 'Update Log Edit' : 'Update Log Add'}
                    subTitle={this.props.isEdit ? '업데이트 로그에 내용을 수정합니다.' : '업데이트 로그에 내용을 추가합니다.'}
                    type={OBTDialog.Type.default}
                >
                    <div style={{ margin: '8px' }}></div>
                    <OBTFormPanel ref={this.myRefs.formPanelRef}>
                        <colgroup>
                            <col type='label' />
                            <col width='500px' />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>updateDate</th>
                                <td>
                                    <OBTDatePicker
                                        format={OBTDatePicker.Format.YYYYMMDD}
                                        type={OBTDatePicker.Type.single}
                                        value={this.state.updateDate}
                                        onChange={(e) => this.setState({ updateDate: e.value })}
                                        max={moment(new Date()).format('YYYYMMDD')}
                                        ref={this.myRefs.datePickerRef}
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>warningLevel</th>
                                <td>
                                    <OBTDropDownList
                                        displayType={OBTDropDownList.DisplayType.text}
                                        list={warningLevel}
                                        value={this.state.warningLevel}
                                        onChange={(e) => {
                                            this.setState({
                                                warningLevel: e.value
                                            })
                                        }}
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>updateType</th>
                                <td>
                                    <OBTDropDownList
                                        displayType={OBTDropDownList.DisplayType.text}
                                        list={updateType}
                                        value={this.state.updateType}
                                        onChange={(e) => {
                                            this.setState({
                                                updateType: e.value
                                            })
                                        }}
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>componentName</th>
                                <td>
                                    <OBTComplete
                                        value={this.state.componentName}
                                        onChange={(e) => this.setState({ componentName: e.value })}
                                        onSearch={this.handleSearch}
                                        dataInfo={this.getDataInfo()}
                                        placeHolder="컴포넌트 이름을 입력하세요."
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>subject</th>
                                <td>
                                    <OBTMultiLineTextField
                                        placeHolder='컴포넌트를 업데이트하고자 하는 내용을 작성하세요.'
                                        value={this.state.subject}
                                        onChange={(e) => this.setState({ subject: e.value })}
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>jiraLink</th>
                                <td>
                                    <OBTTextField
                                        placeHolder='해당 이슈 관련 jiraLink를 첨부 시킬 수 있습니다.'
                                        value={this.state.jiraLink}
                                        onChange={(e) => this.setState({ jiraLink: e.value })}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>wikiLink</th>
                                <td>
                                    <OBTTextField
                                        placeHolder='해당 이슈 관련 wikiLink를 첨부 시킬 수 있습니다.'
                                        value={this.state.wikiLink}
                                        onChange={(e) => this.setState({ wikiLink: e.value })}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>detailLink</th>
                                <td>
                                    <OBTTextField
                                        placeHolder='해당 이슈 관련 detailLink를 첨부 시킬 수 있습니다.'
                                        value={this.state.detailLink}
                                        onChange={(e) => this.setState({ detailLink: e.value })}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>notice</th>
                                <td>
                                    <OBTMultiLineTextField
                                        placeHolder='강조하고 싶은 것이 있거나 subject에서 작성한 내용의 주석을 넣을 수 있습니다.'
                                        value={this.state.notice}
                                        onChange={(e) => this.setState({ notice: e.value })}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </OBTFormPanel>
                </OBTDialog>
                {this.state.alertOpen === true ?
                    <OBTAlert
                        title='Success'
                        labelText='성공적으로 추가되었습니다.'
                        onClose={() => {
                            this.setState({
                                alertOpen: false
                            }, () => {
                                this.setState({
                                    updateDate: moment(new Date()).format('YYYYMMDD'),
                                    warningLevel: 1,
                                    updateType: 'doc',
                                    componentName: '',
                                    subject: '',
                                    jiraLink: '',
                                    wikiLink: '',
                                    detailLink: '',
                                    notice: '',
                                });
                            })
                        }}
                    /> : <></>
                }
            </>
        )
    }
}
