import * as React from 'react';
import { OBTButton, OBTTooltip, Util } from '..';
import Images from './Images';
import axios from 'axios';
import { ignoreExceptionTemplate } from '../Common/OrbitInternalUtil';
const styles = require('./OBTPageContainer.module.scss');

interface IOnlineManual {
    menuItem: any | null,
    testRender?: boolean
}

interface IState {
    onlineManualUrls: {
        happy: string,//"yes"
        happyUrl: string,// "http://101.101.211.61/admin/ko/receipt/1001600"
        langCode: string,//"ko"
        menuCode: string, //"UBA"
        moduleCode: string, //"UB"
        onlinemanual: string, //"yes"
        onlinemanualUrl: string, //"http://101.101.211.61/admin/ko/manual/1001600"
        urlCode: string, //"UBA6000"
        video: string, //"yes"
        videoUrl: string, //"http://101.101.211.61/admin/ko/video/1001600"
    } | null
}

class OnlineManual extends React.Component<IOnlineManual, IState> {
    public state: IState = {
        onlineManualUrls: null
    }

    componentDidMount() {
        this.props.testRender ? this.testOnlineManual() : this.checkExistingOnlineManualSession();
    }

    checkExistingOnlineManualSession = () => {
        try {
            const sessionStorageKey = `${Util.safeAccessProperty(this.props, 'menuItem', 'pageCode')}_onlineManual`;
            const getExistingSession = sessionStorage.getItem(sessionStorageKey);

            if (getExistingSession) {
                const existingSession = JSON.parse(getExistingSession);

                this.setState({
                    onlineManualUrls: existingSession
                });
            }
            else this.fetchOnlineManual();
        } catch (e) {
            console.error(e);
            this.fetchOnlineManual();
        }
    }

    fetchOnlineManual = ignoreExceptionTemplate(async () => {
        const url = `https://support.amaranth10.com/api/user/public/menu/check?moduleGubun=` +
            `${this.props.menuItem['moduleCode']}&` +
            `menuCode=${this.props.menuItem['menuCode']}&` +
            `urlPath=${this.props.menuItem['pageCode']}&` +
            `langCode=ko`;

        //`langCode=${this.props['userInfo']['ucUserInfo']['langCode']}`;
        const result = await axios(url);

        if (result && result.status === 200) {
            const resultData = await result.data;
            const sessionStorageKey = `${Util.safeAccessProperty(this.props, 'menuItem', 'pageCode')}_onlineManual`;

            sessionStorage.setItem(sessionStorageKey, JSON.stringify(resultData));

            this.setState({
                onlineManualUrls: resultData
            });
        }
    })

    testOnlineManual = () => {
        this.setState({
            onlineManualUrls: {
                happy: 'yes', video: 'yes', onlinemanual: 'yes', happyUrl: '' , langCode: '', menuCode:'', moduleCode: '', onlinemanualUrl: '', urlCode: '', videoUrl: ''
            }
        })
    }

    renderOnlineManual = () => {
        return (
            <OBTButton
                type={OBTButton.Type.icon}
                imageUrl={{
                    normal: Images.IconHelpOnlineManualNormal,
                    over: Images.IconHelpOnlineManualOver,
                    press: Images.IconHelpOnlineManualClick,
                }}
                tooltip={{
                    labelText: '온라인매뉴얼',
                    position: OBTTooltip.Position.bottom
                }}
                width='25px' height='25px'
                onClick={() => {
                    if (this.state.onlineManualUrls && this.state.onlineManualUrls.onlinemanualUrl) {
                        window.open(this.state.onlineManualUrls.onlinemanualUrl)
                    }
                }}
            />
        )
    }

    renderVideo = () => {
        return (
            <OBTButton
                type={OBTButton.Type.icon}
                imageUrl={{
                    normal: Images.IconHelpVideoNormal,
                    over: Images.IconHelpVideoOver,
                    press: Images.IconHelpVideoClick,
                }}
                tooltip={{
                    labelText: '동영상강의',
                    position: OBTTooltip.Position.bottom
                }}
                width='25px' height='25px'
                onClick={() => {
                    if (this.state.onlineManualUrls && this.state.onlineManualUrls.videoUrl) {
                        window.open(this.state.onlineManualUrls.videoUrl)
                    }
                }}
            />
        )
    }

    renderHappy = () => {
        return (
            <OBTButton
                type={OBTButton.Type.icon}
                imageUrl={{
                    normal: Images.IconHelpHappyNormal,
                    over: Images.IconHelpHappyOver,
                    press: Images.IconHelpHappyClick,
                }}
                tooltip={{
                    labelText: '해피처방전',
                    position: OBTTooltip.Position.bottom
                }}
                width='25px' height='25px'
                onClick={() => {
                    if (this.state.onlineManualUrls && this.state.onlineManualUrls.happyUrl) {
                        window.open(this.state.onlineManualUrls.happyUrl)
                    }
                }}
            />
        )
    }
    
    // TODO: 추후 고도화
    //  <OBTButton
    //     type={OBTButton.Type.default}
    //     imageUrl={{
    //         normal: Images.IconRelateMenuRenewal,  
    //     }}
    //     tooltip={{
    //         labelText: '관련메뉴'   
    //     }}
    //     className={styles.manualButton}
    //     width='25px'
    //     height='25px'
    //     onClick={() => {
    //         // if (this.state.onlineManualUrls.onlinemanualUrl) {
    //         //     window.open(this.state.onlineManualUrls.onlinemanualUrl)
    //         // }
    //     }}
    // />

    render() {
        const { onlineManualUrls } = this.state;
        if (!onlineManualUrls) {
            return (<></>);
        }

        return (
            <div className={styles.onlineManual}>
                {onlineManualUrls.onlinemanual === 'yes' && this.renderOnlineManual()}
                {onlineManualUrls.video === 'yes' && this.renderVideo()}
                {onlineManualUrls.happy === 'yes' && this.renderHappy()}
            </div>
        )
    }
}

export default OnlineManual;