import React from "react";
import PropTypes from "prop-types";
import LUXPopoverController from "luna-rocket/LUXPopoverController/LUXPopoverController";
import { LUXPopoverNoTransition } from "luna-rocket/LUXPopoverController";

import InputElement from "./BaseComponents/InputElement";
import ListElement from "./BaseComponents/ListElement";
import keycode from "keycode";
import update from "react-addons-update";

import globalObj from 'luna-rocket/locale/intlGlobals';
import debounce from 'lodash.debounce';

const getStyles = (props, width) => {
    const styles = {
        // 전체를 감싸고 있는 div 의 Style
        style: {
            position: "relative",
            margin: 0,
            padding: 0
        },
        infoStyle: {
            fontFamily:
                "inherit",
            lineHeight: '13px',
            margin: "5px 0 0 5px",
            fontSize: "12px",
            color:
                (!props.disabled || !props.progress) && props.validationError
                    ? "#fc5356"
                    : (!props.disabled || !props.progress) &&
                        props.validationSuccess
                        ? "#8fe1bd"
                        : "#8d8d8d"
        }
    };
    return styles;
};

class LUXSmartComplete extends React.Component {
    static propTypes = {
        /**
         * Popover 의 anchorOrigin 입니다.
         */
        anchorOrigin: PropTypes.object,
        /**
         * 버튼 엘리멘트입니다.
         */
        button: PropTypes.object,
        /**
         * 항목삭제 가능여부입니다.
         */
        canDelete: PropTypes.bool,
        /**
         * 검색할 때 나타나는 데이터들의 정보입니다.(예제참고)
         */
        dataInfo: PropTypes.oneOfType([
            PropTypes.object.isRequired,
            PropTypes.array.isRequired
        ]),
        /**
         * 입력이 멈춘 후 부터 검색시까지의 지연시간 입니다.
         */
        delayTime: PropTypes.number,
        /**
         * 비활성화 여부 입니다.
         */
        disabled: PropTypes.bool,
        /**
         * 입력된 글자가 없을 때 보여지는 힌트 글자 입니다.
         */
        hintText: PropTypes.string,
        /**
         * 보여주는 데이터의 최대갯수 입니다.
         */
        maxDataCount: PropTypes.number,
        /**
         * 팝오버의 높이(Pixel) 입니다. 지정하지 않은 경우 높이는 자동으로 계산됩니다.
         */
        maxPopOverHeight: PropTypes.number,
        /**
         * 검색이 시작되는 최소 글자 수 입니다.
         */
        minSearchChar: PropTypes.number,
        /**
         * 팝오버의 최소넓이(Pixel) 입니다.
         */
        minPopOverWidth: PropTypes.number,
        /**
         * 현재입력한 값으로 등록하는 버튼을 클릭했을때 호출되는 CallBack입니다.
         */
        onAdd: PropTypes.func,
        /**
         * keyWord 로 검색하는 함수입니다.(외부작성 필요)
         */
        onSearch: PropTypes.func.isRequired,
        /**
         * 데이터가 변경되면 호출되는 Callback 함수입니다.
         */
        onChange: PropTypes.func.isRequired,
        /**
         * 데이터 삭제 버튼을 클릭하면 호출되는 Callback 함수입니다.
         */
        onDelete: PropTypes.func,
        /**
         * 팝오버에 리스트가 없을 때 엔터키를 치면 발생하는 Callback 함수입니다.
         */
        onEnterKeyDown: PropTypes.func,
        /**
         * 포커스 이동을 지시하는 Callback 함수입니다.
         */
        onMoveFocus: PropTypes.func,
        /**
         * 값이 true 이면, 요소가 로딩 중인 상태로 변합니다.
         */
        progress: PropTypes.bool,
        /**
         * 팝오버 리스트의 헤더에 추가 버튼 표시 여부입니다.
         */
        showHeaderButton: PropTypes.bool,
        /**
         * 팝오버 리스트의 헤더에 추가 버튼 표시 여부입니다.(내용이 없어도 표시)
         */
        showAlwaysHeaderButton: PropTypes.bool,
        /**
         * 콤포넌트의 스타일을 값을 오버라이딩 합니다.
         */
        style: PropTypes.object,
        /**
         * Input 태그를 감싸고 있는 Div Style 입니다.
         */
        styleInputDiv: PropTypes.object,
        /**
         * Input 태그를 감싸고 있는 Span Style 입니다.
         */
        styleInputSpan: PropTypes.object,
        /**
         * Input 태그의 Style 입니다.
         */
        styleInput: PropTypes.object,
        /**
         * Place Holder 의 Style 입니다.
         */
        stylePlaceHolder: PropTypes.object,
        /**
         * Popover 리스트의 a Tag 스타일입니다.
         */
        stylePopoverATag: PropTypes.object,
        /**
         * 아이콘 엘레멘트 입니다.
         */
        icon: PropTypes.object,
        /**
         * information 영역인 p element에 적용되는 스타일 속성입니다.
         */
        infoStyle: PropTypes.object,
        /**
         * 팝오버의 targetOrigin 입니다.
         */
        targetOrigin: PropTypes.object,
        /**
         * 툴팁의 float 속성입니다.
         */
        toolTipFloat: PropTypes.string,
        /**
         * null(공란) 값을 툴팁에서 skip  할지 정하는 속성입니다.
         */
        toolTipNullSkip: PropTypes.bool,
        /**
         * SmartComplete 의 타입입니다.
         */
        type: PropTypes.oneOf(["normal", "multiList", "multiListSchema"]),
        /**
         * 검색시 아이템이 선택되지 않은 상태로 시작하여 엔터키를 받아야 하는 경우 사용합니다.
         */
        useEnterEvent: PropTypes.bool,
        /**
         * inputBox 의 값 입니다.
         */
        value: PropTypes.string.isRequired,
        /**
         * 유효성검사 후 Error 스타일 적용을 위한 속성입니다.
         */
        validationError: PropTypes.bool,
        /**
         * 유효성검사 후 Success 스타일 적용을 위한 속성입니다.
         */
        validationSuccess: PropTypes.bool,
        /**
         * 유효성검사 후 결과에 대한 텍스트를 정의하는 속성입니다.
         */
        validationText: PropTypes.string,
        /**
         * 툴팁을 무조건 생성할지 여부
         */
        toolTipAlwaysShow: PropTypes.bool,
        /**
         * 숫자만 입력 가능 여부
         */
        numberOnly: PropTypes.bool,
        /**
         * dropDown 버튼 여부
         */
        isDropDown: PropTypes.bool,
        /**
         * showTooltip 버튼 여부
         */
        isShowTooltip: PropTypes.bool
    };

    static defaultProps = {
        canDelete: false,
        delayTime: 10,
        disabled: false,
        hintText: globalObj.intl.messages["luna.rocket.enterTheSearchKeyword"],
        maxDataCount: 10,
        minSearchChar: 2,
        minPopOverWidth: 100,
        style: { width: "100%" },
        type: "normal",
        validationError: false,
        validationSuccess: false,
        progress: false,
        showHeaderButton: false,
        showAlwaysHeaderButton: false,
        validationText: "",
        toolTipFloat: "left",
        useEnterEvent: false,
        toolTipNullSkip: false,
        toolTipAlwaysShow: false,
        numberOnly: false,
        isShowTooltip: true
    };

    constructor() {
        super();
        this.state = {
            // 전체컨포넌트내에 포커스가 있는지 여부
            isFocused: false,
            // popOver Open 여부
            isPopoverOpen: false,
            // 검색된 데이터
            data: [],
            // 전체 검색된 데이터 수
            totalItemCount: 0,
            // input width
            inputWidth: 0,
            // 포커스가 간 아이템 정보
            focusedInfo: {
                index: -1,
                data: {}
            }
        };
        this.haveToMove = false;

        // 윈도우 크롬 문제로 인하여 선택된 후 일정시간동안은 팝오버를 띄우지 않는 코드
        this.isSelecting = false;
    }

    /**
     * Ref 정의
     */
    myRefs = {
        input: React.createRef(),
        root: React.createRef(),
        popover: React.createRef()
    }

    componentDidUpdate(prevProps, prevState) {
        const { value, delayTime } = this.props;
        if (prevProps.value !== value) {
            if (this.isSelecting) {
                return;
            }

            if (this.state.isFocused) {
                setTimeout(this.search, delayTime, value);
            }

            if (this.haveToMove) {
                this.haveToMove = false;
                this.handleMoveFocus();
            }
        }
    }
    // endregion

    // region Functions

    /**
     * 현재의 컨트롤에 포커스를 주는 함수
     */
    focus() {
        if (this.myRefs.input.current) this.myRefs.input.current.focus();
    }

    showDropDown = () => {
        if (this.props.isDropDown === true && !this.props.disabled && !this.state.isPopoverOpen) {
            setTimeout(this.search, this.props.delayTime, '', this.props.isDropDown);
        }
    }

    /**
     * 키워드로 아이템 검색
     */
    search = debounce((keyword, isDropDown = undefined) => {
        const {
            type,
            maxDataCount,
            onSearch,
            value,
            showAlwaysHeaderButton,
            showHeaderButton,
            minSearchChar,
            useEnterEvent
        } = this.props;

        // 타이머 호출된 후 입력글자가 바뀌었으면 검색하지 않는다.
        if (!isDropDown && (keyword !== value || this.state.isFocused === false)) {
            return;
        }

        // 키워드가 지정된 글자 이하이면 팝오버를 닫고 리턴
        if (!onSearch || (!isDropDown && (value.length < minSearchChar))) {
            this.clearPopOver();
            return;
        }

        let promise = onSearch(keyword);
        promise.then(
            result => {
                let clonedResult;
                if (type === "normal") {
                    clonedResult = [{ data: result.slice(0) }];
                } else if (type === "multiList" || type === "multiListSchema") {
                    clonedResult = result.slice(0);
                } else {
                    this.clearPopOver();
                    return;
                }

                // 검색된 결과가 없는 그룹은 삭제한다.
                clonedResult = clonedResult.filter(data => {
                    return data.data.length > 0;
                });

                // 전체 데이터 숫자를 계산한다.
                let groupCount = clonedResult.length;
                let totalDataCount = 0;
                clonedResult.forEach(data => {
                    if (data.data.length > 0) {
                        totalDataCount += data.data.length;
                    }
                });

                // 검색된 결과가 허용된 갯수보다 크면 허용된 갯수로 조절한다.
                if (maxDataCount) {
                    let groutMaxCount = Math.floor(maxDataCount / groupCount);
                    clonedResult = clonedResult.map(data => {
                        if (data.data.length > groutMaxCount) {
                            return update(data, {
                                data: { $splice: [[groutMaxCount]] }
                            });
                        } else {
                            return data;
                        }
                    });
                }

                // 전체 데이터 숫자를 계산한다.
                totalDataCount = 0;
                clonedResult.forEach(data => {
                    if (data.data.length > 0) {
                        totalDataCount += data.data.length;
                    }
                });

                // 항상헤더 버튼이 나와야 되는 경우 버튼을 추가한다.(데이터가 없어도 추가)
                if (showAlwaysHeaderButton) {
                    if (totalDataCount === 0) {
                        clonedResult = [{ data: [] }];
                    }

                    clonedResult[0].data.unshift("addButton");
                    totalDataCount += 1;
                }

                if (totalDataCount > 0) {
                    // 헤더에 등록 버튼을 추가 해야되는 경우 더미 데이터를 추가한다.
                    if (!showAlwaysHeaderButton && showHeaderButton) {
                        clonedResult[0].data.unshift("addButton");
                        totalDataCount += 1;
                    }
                    this.setState({
                        data: clonedResult,
                        isPopoverOpen: true,
                        focusedInfo: {
                            index: useEnterEvent ? -1 : 0,
                            data: useEnterEvent
                                ? null
                                : clonedResult[0].data[0],
                            dataType: clonedResult[0].dataType
                        },
                        totalItemCount: totalDataCount
                    });
                } else {
                    this.clearPopOver();
                }
            },
            () => {
                this.clearPopOver();
            }
        );
    }, 300);

    /**
     * 팝오버 닫기
     */
    clearPopOver = () => {
        this.setState({
            isPopoverOpen: false,
            data: []
        });
    };

    // endregion

    // region InputElement

    /**
     * inputElement 생성
     */
    createInputElement = (
        style,
        inputProps,
        styleInputDiv,
        styleInputSpan,
        styleInput,
        stylePlaceHolder,
        icon,
        validationError,
        validationSuccess,
        progress,
        isDropDown
    ) => {
        let element = (
            <InputElement
                ref={this.myRefs.input}
                isFocused={this.state.isFocused}
                {...inputProps}
                style={style}
                styleInputDiv={styleInputDiv}
                styleInputSpan={styleInputSpan}
                styleInput={styleInput}
                stylePlaceHolder={stylePlaceHolder}
                icon={icon}
                validationError={validationError}
                validationSuccess={validationSuccess}
                progress={progress}
                isDropDown={this.props.disabled ? false : isDropDown}
                onDropDownClick={() => {
                    if (!this.state.isPopoverOpen) {
                        setTimeout(this.search, this.props.delayTime, '', isDropDown)
                    }
                }}
                isOpen={this.state.isPopoverOpen}
            />
        );
        return element;
    };

    /**
     * input element 에  포커스가 들어오면 발생
     */
    handleFocus = event => {
        if (this.props.disabled) {
            this.setState({
                isFocused: false,
                isPopoverOpen: false
            });
        } else {
            this.setState({ isFocused: true });
            // 키워드가 지정된 글자 이하이면 팝오버를 닫고 리턴
            if (
                !this.props.onSearch ||
                this.props.value.length < this.props.minSearchChar
            ) {

            } else {
                setTimeout(this.search, this.props.delayTime, this.props.value);
            }
        }

        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    };

    /**
     * input element 에 포커스가 나가면 발생
     */
    handleBlur = event => {
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }

        this.setState({
            isFocused: false
        });
        this.clearPopOver();
    };

    /**
     * Input Element 의 KeyDown
     */
    handleKeyDown = event => {
        event.stopPropagation();
        let focusedInfo = Object.assign({}, this.state.focusedInfo);
        switch (keycode(event)) {
            case "up":
                event.preventDefault();
                if (this.props.useEnterEvent && focusedInfo.index === 0) {
                    this.setState({
                        focusedInfo: {
                            index: -1,
                            data: null,
                            dataType: null
                        }
                    });
                } else {
                    if (focusedInfo.index > 0) {
                        this.setState({
                            focusedInfo: {
                                index: focusedInfo.index - 1,
                                data: this.getItemData(focusedInfo.index - 1),
                                dataType: this.getItemDataType(
                                    focusedInfo.index - 1
                                )
                            }
                        });
                    }
                }
                if (
                    this.props.useEnterEvent &&
                    this.state.focusedInfo.index === -1
                ) {
                    if (this.props.onEnterKeyDown) {
                        this.props.onEnterKeyDown('up');
                    }
                } else {
                    if (!(this.state.data.length > 0)) {
                        if (this.props.onEnterKeyDown) {
                            this.props.onEnterKeyDown('up');
                        }
                    }
                }
                break;
            case "down":
                if (focusedInfo.index < this.state.totalItemCount - 1) {
                    event.preventDefault();
                    this.setState({
                        focusedInfo: {
                            index: focusedInfo.index + 1,
                            data: this.getItemData(focusedInfo.index + 1),
                            dataType: this.getItemDataType(
                                focusedInfo.index + 1
                            )
                        }
                    });
                }
                if (
                    this.props.useEnterEvent &&
                    this.state.focusedInfo.index === -1
                ) {
                    if (this.props.onEnterKeyDown) {
                        this.props.onEnterKeyDown('down');
                    }
                } else {
                    if (!(this.state.data.length > 0)) {
                        if (this.props.onEnterKeyDown) {
                            this.props.onEnterKeyDown('down');
                        }
                    }
                }
                break;
            case "enter":
                event.preventDefault();
                if (
                    this.props.useEnterEvent &&
                    this.state.focusedInfo.index === -1
                ) {
                    this.handleMoveFocus();
                    if (this.props.onEnterKeyDown) {
                        this.props.onEnterKeyDown('enter');
                    }
                } else {
                    if (this.state.data.length > 0) {
                        this.handleSelect(
                            event,
                            this.state.focusedInfo.data,
                            this.state.focusedInfo.dataType
                        );
                        break;
                    } else {
                        this.handleMoveFocus();
                        if (this.props.onEnterKeyDown) {
                            this.props.onEnterKeyDown('enter');
                        }
                    }
                }
                break;

            case "tab":
                this.clearPopOver();
                break;

            case "backspace":
                if (this.props.onChange && this.props.numberOnly) {
                    if (!this.numberType(event.target.value)) {
                        let e = {};
                        e.target = {};
                        e.target.value = "";
                        this.props.onChange(e);
                    }
                }
                break;
            default:
                break;
        }

        if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    };

    /**
     * 외부에 컴포넌트 이동을 지시하는 함수 입니다.
     */
    handleMoveFocus = (move = "right") => {
        if (this.props.onMoveFocus) {
            this.props.onMoveFocus(move);
        }
    };

    getItemData = index => {
        let data = this.state.data;
        let currentIndex = 0;
        let itemData;
        for (let i = 0; i < data.length; ++i) {
            if (data[i].data.length + currentIndex > index) {
                itemData = data[i].data[index - currentIndex];
                break;
            } else {
                currentIndex += data[i].data.length;
            }
        }
        return itemData;
    };

    getItemDataType = index => {
        let data = this.state.data;
        let currentIndex = 0;
        let dataType;
        for (let i = 0; i < data.length; ++i) {
            if (data[i].data.length + currentIndex > index) {
                dataType = data[i].dataType;
                break;
            } else {
                currentIndex += data[i].data.length;
            }
        }
        return dataType;
    };

    /**
     * 엔터키 혹은 클릭으로 아이템이 선택되면 발생
     * @param event
     */
    handleSelect = (event, data, dataType) => {
        if (data.disabled) {
            return;
        }

        if (data === "addButton") {
            if (this.props.onAdd) {
                this.props.onAdd(this.props.value);
            }
            this.clearPopOver();
            return;
        }

        let itemInfo = this.props.dataInfo.itemInfo;
        if (this.props.type === "multiListSchema") {
            itemInfo = this.props.dataInfo.find(info => {
                return info.dataType === dataType;
            }).itemInfo;
        }

        let info = itemInfo.filter(item => {
            return item.isKeyValue;
        });

        let value = data[info[0]["key"]];
        if (!value) {
            value = "";
        }

        let e = {};
        e.target = {};
        e.target.value = value;
        e.target.data = data;

        if (this.props.onChange) {
            this.haveToMove = true;
            this.props.onChange(e, data, this.handleMoveFocus);
            this.isSelecting = true;
            setTimeout(() => {
                this.isSelecting = false;
            }, 300);
        }

        if (this.haveToMove) {
            this.haveToMove = false;
            this.handleMoveFocus();
        }

        this.clearPopOver();
    };

    /**
     * input element 의 값이 변경하면 발생
     */
    handleChange = event => {
        // 변경된 값으로 변경 콜백합수를 호출한다.
        if (this.props.onChange) {
            if (this.props.numberOnly) {
                let value = event.target.value;
                if (this.numberType(value)) {
                    this.props.onChange(event);
                } else {
                    let e = {};
                    e.target = {};
                    e.target.value = value.replace(this.props.value, "");
                    if (this.numberType(e.target.value)) {
                        this.props.onChange(e);
                    }
                }
            } else {
                this.props.onChange(event);
            }
        }

        // // 키워드가 지정된 글자 이하이면 팝오버를 닫고 리턴
        // if(!this.props.onSearch || value.length < this.props.minSearchChar) {
        //     this.clearPopOver();
        // }

        //setTimeout(this.search, this.props.delayTime, value);
    };

    handlePaste = event => {
        event.stopPropagation();
        event.preventDefault();

        if (this.props.onChange) {
            let clipboardData = event.clipboardData
                .getData("text")
                .replace(/\n/gi, "");
            if (clipboardData) {
                let e = {};
                e.target = {};
                e.target.value = clipboardData;

                if (this.props.numberOnly) {
                    if (this.numberType(clipboardData)) {
                        this.props.onChange(e);
                    } else {
                        e.target.value = "";
                        this.props.onChange(e);
                    }
                } else {
                    this.props.onChange(e);
                }
            }
        }
    };

    numberType = value => {
        let number = value.replace(/[^0-9]/g, "");

        return String(value) === String(number);
    };

    // endregion

    //region PopOverElement

    /**
     * PopOverElement 생성
     */
    createPopOverElement = (list, width, anchorOrigin, targetOrigin) => {
        let element = (
            <LUXPopoverController
                locationReFresh={true}
                animated={false}
                animation={LUXPopoverNoTransition}
                anchorEl={this.myRefs.popover.current}
                open={this.state.isPopoverOpen}
                style={{ width: Math.max(width, this.props.minPopOverWidth) }}
                onRequestClose={this.clearPopOver}
                anchorOrigin={anchorOrigin}
                targetOrigin={targetOrigin}
            >
                {list}
            </LUXPopoverController>
        );
        return element;
    };

    /**
     * 아이템 삭제
     */
    handleDelete = (event, data) => {
        if (this.props.onDelete) {
            let isDelete = this.props.onDelete(event, data);
            if (isDelete) {
                // 데이터 삭제
                let searchedData = this.state.data;

                for (let i = 0; i < searchedData.length; ++i) {
                    for (let j = 0; j < searchedData[i].data.length; ++j) {
                        if (searchedData[i].data[j] === data) {
                            // 데이터를 삭제한다.
                            let dummyData = update(searchedData, {
                                [i]: { data: { $splice: [[j, 1]] } }
                            });

                            this.setState({
                                data: dummyData
                            });
                            return;
                        }
                    }
                }
            }
        }
    };

    //endregion

    // region ListElement

    /**
     * ListElement 생성
     */
    createListElement = (
        type,
        stylePopoverATag,
        canDelete,
        maxPopOverHeight,
        toolTipFloat
    ) => {
        let element = (
            <ListElement
                canDelete={this.props.canDelete}
                data={this.state.data}
                dataInfo={this.props.dataInfo}
                keyWord={this.props.value}
                focusedInfo={this.state.focusedInfo}
                onSelect={this.handleSelect}
                type={type}
                onDelete={this.handleDelete}
                showHeaderButton={this.props.showHeaderButton}
                stylePopoverATag={stylePopoverATag}
                maxPopOverHeight={maxPopOverHeight}
                toolTipFloat={toolTipFloat}
                toolTipNullSkip={this.props.toolTipNullSkip}
                toolTipAlwaysShow={this.props.toolTipAlwaysShow}
                isShowTooltip={this.props.isShowTooltip}
            />
        );
        return element;
    };

    // endregion

    render() {
        const {
            anchorOrigin,
            button,
            canDelete,
            classNameInput,
            dataInfo,
            delayTime,
            maxPopOverHeight,
            maxDataCount,
            minSearchChar,
            minPopOverWidth,
            disabled,
            onBlur,
            onChange,
            onDelete,
            onEnterKeyDown,
            onFocus,
            onKeyDown,
            onSearch,
            onMoveFocus,
            style,
            styleInputDiv,
            styleInputSpan,
            styleInput,
            stylePlaceHolder,
            stylePopoverATag,
            type,
            targetOrigin,
            toolTipFloat,
            icon,
            infoStyle,
            validationError,
            validationSuccess,
            validationText,
            progress,
            showHeaderButton,
            showAlwaysHeaderButton,
            useEnterEvent,
            toolTipNullSkip,
            toolTipAlwaysShow,
            numberOnly,
            isDropDown,
            isShowTooltip,
            ...other
        } = this.props;

        const inputProps = {
            className: classNameInput,
            disabled,
            onBlur: this.handleBlur,
            onChange: this.handleChange,
            onPaste: this.handlePaste,
            onFocus: this.handleFocus,
            onKeyDown: this.handleKeyDown,
            ...other
        };

        let width = 0;
        if (this.myRefs.input.current) {
            width = this.myRefs.input.current.getInputWidth();
        }

        const styles = getStyles(this.props, width);

        let listElement = this.createListElement(
            type,
            stylePopoverATag,
            canDelete,
            maxPopOverHeight,
            toolTipFloat
        );
        let inputElement = this.createInputElement(
            style,
            inputProps,
            styleInputDiv,
            styleInputSpan,
            styleInput,
            stylePlaceHolder,
            icon,
            validationError,
            validationSuccess,
            progress,
            isDropDown
        );

        // validation text 정의
        let validationTextElement = null;
        if (
            (!disabled && validationError) ||
            (!disabled && validationSuccess)
        ) {
            if (validationText) {
                validationTextElement = (
                    <p style={Object.assign({}, styles.infoStyle, infoStyle)}>
                        {validationText}
                    </p>
                );
            }
        }

        let popOverElement = this.createPopOverElement(
            listElement,
            width,
            anchorOrigin,
            targetOrigin
        );
        return (
            <div ref={this.myRefs.root} style={Object.assign({}, styles.style, style)}>
                <div ref={this.myRefs.popover}>{inputElement}</div>
                {validationTextElement}
                {button}
                {popOverElement}
            </div>
        );
    }

    // endregion
}

export default LUXSmartComplete;
