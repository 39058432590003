import { Events } from '../../Common';

export class CodePickerSearchEventArgs extends Events.EventArgs {
    public readonly target: any;
    public readonly method: "dialog" | "input";

    /**
     * 조회조건 컴포넌트의 파라미터 오브젝트 
     */
    public readonly searchParameter: any | undefined = {};

    /**
     * 컴포넌트에서 입력된 검색 키워드
     * OBTCodePicker일 경우, 인풋에 입력된 값
     * OBTDataGrid일 경우, 셀에 입력된 값
     */
    public readonly keyword: string | undefined;
    /**
     * 빈 조회조건이라 전체 검색인지 여부
     */
    public readonly isEmpty: boolean;

    constructor(target: any, method: "dialog" | "input", keyword: string | undefined, searchParameter: any | undefined, isEmpty: boolean = false) {
        super(target);
        this.target = target;
        this.method = method;
        this.searchParameter = searchParameter;
        this.keyword = keyword;
        this.isEmpty = isEmpty;
    }
}
