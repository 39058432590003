import React from 'react';
import { ISideBarContent } from './SideBar';
import SideBarContent, { SideBarContentType } from './SideBarContent';
import { Util } from '../Common';
import { OrbitInternalLangPack } from '../Common/Util';
const styles = require('./SideBarContent.module.scss');

interface ISBCEditTracker {
    item: ISideBarContent,
    rowKey?: string,
    userInfo?: any,
    fetch: any,
    userDetailDialog?: any,
}

interface IState {
    isUserDetailDialogShow: boolean,
    selectedDetailDialogUserLoginId: string | null,
    insertUser: {
        insertEmpName: string,
        insertEmpPicFileId: string,
        insertCompSeq: string,
        insertDeptSeq: string,
        insertEmpSeq: string,
    },
    modifyUser: {
        modifyEmpName: string,
        modifyEmpPicFileId: string,
        modifyCompSeq: string,
        modifyDeptSeq: string,
        modifyEmpSeq: string,
    }
}

export default class SBCEditTracker extends React.Component<ISBCEditTracker, IState> {
    state: IState = {
        isUserDetailDialogShow: false,
        selectedDetailDialogUserLoginId: null,

        insertUser: {
            insertEmpName: '',
            insertEmpPicFileId: '',
            insertCompSeq: '',
            insertDeptSeq: '',
            insertEmpSeq: '',
        },
        modifyUser: {
            modifyEmpName: '',
            modifyEmpPicFileId: '',
            modifyCompSeq: '',
            modifyDeptSeq: '',
            modifyEmpSeq: '',
        }
    }

    componentDidMount() {
        this.fetchAndSetState();
    }

    componentDidUpdate(prevProps: ISBCEditTracker, prevState: IState) {
        if (this.props.rowKey !== prevProps.rowKey) {
            this.fetchAndSetState();
        }
    }

    fetchAndSetState = () => {
        let promiseList: Promise<any>[] = [];
        if (this.props.item && this.props.item.data && this.props.item.data.modifyId) {
            promiseList.push(this.fetchUserInfo(this.props.item.data.modifyId));
        }

        if (this.props.item && this.props.item.data && this.props.item.data.insertId) {
            promiseList.push(this.fetchUserInfo(this.props.item.data.insertId));
        }

        if (promiseList.length > 0) {
            Promise.all(promiseList).then((dataList) => {
                if (dataList && dataList.length) {
                    let state = {
                        insertUser: {
                            insertEmpName: '',
                            insertEmpPicFileId: '',
                            insertCompSeq: '',
                            insertDeptSeq: '',
                            insertEmpSeq: '',
                        },
                        modifyUser: {
                            modifyEmpName: '',
                            modifyEmpPicFileId: '',
                            modifyCompSeq: '',
                            modifyDeptSeq: '',
                            modifyEmpSeq: '',
                        }
                    }

                    dataList.forEach(data => {
                        if (data && data.erpEmpSeq === this.props.item.data.insertId) {
                            state.insertUser = {
                                insertEmpName: data.data['empName'],
                                insertEmpPicFileId: data.data['picFileId'],
                                insertCompSeq: data.data['compSeq'],
                                insertDeptSeq: data.data['deptSeq'],
                                insertEmpSeq: data.data['empSeq'],
                            }
                        }

                        if (data && data.erpEmpSeq === this.props.item.data.modifyId) {
                            state.modifyUser = {
                                modifyEmpName: data.data['empName'],
                                modifyEmpPicFileId: data.data['picFileId'],
                                modifyCompSeq: data.data['compSeq'],
                                modifyDeptSeq: data.data['deptSeq'],
                                modifyEmpSeq: data.data['empSeq'],
                            }
                        }
                    });
                    this.setState(state);
                }
            })
        } else {
            this.setState({
                insertUser: {
                    insertCompSeq: '',
                    insertDeptSeq: '',
                    insertEmpName: '',
                    insertEmpPicFileId: '',
                    insertEmpSeq: '',
                },
                modifyUser: {
                    modifyCompSeq: '',
                    modifyDeptSeq: '',
                    modifyEmpName: '',
                    modifyEmpPicFileId: '',
                    modifyEmpSeq: ''
                }
            });
        }
    }

    fetchUserInfo = (erpEmpSeq: string) => {
        return new Promise<any>((resolve) => {
            return this.props.fetch.post('/gw/gw066A54',
                {
                    'erpEmpSeq': erpEmpSeq
                },
                {
                    contextType: "application/json"
                }
            ).then((response: any) => {
                const responseJson = (response);
                if (responseJson && responseJson['resultCode'] === 0) {
                    resolve({
                        erpEmpSeq: erpEmpSeq,
                        data: responseJson['resultData']
                    })
                } else {
                    throw new Error('사용자 정보 조회 실패')
                }
            }).catch((error: any) => {
                console.error(error);
            });
        })
    }

    private renderItem(label: string,
        userNameLabel: string,
        userIpLabel: string,
        dateLabel: string,
        name: string,
        id: string,
        ip: string,
        date: Date | string,
        imageUrl: string | null) {
        return (
            <div className={styles.editTracker}>
                <div className={styles.bullet}></div>
                <div className={styles.subtitle}>{label}</div>
                <div className={styles.mainInfo}>
                    <div className={styles.avartar}
                        style={{
                            cursor: !id || id.length === 0 ? 'default' : 'pointer' //'pointer'
                        }}
                        onClick={(e) => {
                            this.setState({
                                isUserDetailDialogShow: true,
                                selectedDetailDialogUserLoginId: !id || id.length === 0 ? null : id
                            })
                        }}>
                        {
                            imageUrl && imageUrl.length > 0 ?
                                (
                                    <img
                                        className={styles.avartarImage}
                                        src={`/gw/contentsImgController/download/${this.props.userInfo.ucUserInfo.groupSeq}/empPic/${imageUrl}`}
                                        alt=""
                                    />
                                ) :
                                (
                                    <div className={styles.avartarImage}></div>
                                )
                        }
                    </div>
                    <div className={styles.userInfo}>
                        <div className={styles.infoItem}>
                            <div className={styles.label}>{userNameLabel}</div>
                            <div className={styles.data}>{name}</div>
                        </div>
                        <div className={styles.infoItem}>
                            <div className={styles.label}>{userIpLabel}</div>
                            <div className={styles.data}>{ip}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.line}></div>
                <div className={styles.dateInfo}>
                    <div className={styles.label}>{dateLabel}</div>
                    <div className={styles.data}>{date ? Util.getDateString(Util.DateOption.simpleDateTime, new Date(date)) : ''}</div>
                </div>
                <div className={styles.line}></div>
            </div>
        )
    }

    render() {
        return (
            <SideBarContent item={Object.assign({}, this.props.item, {
                title: OrbitInternalLangPack.getText('WE000025560', '입력자정보'),
                type: SideBarContentType.accordion,
            })}>
                <div style={{ padding: '10px' }}>
                    {
                        this.renderItem(
                            OrbitInternalLangPack.getText('WE000025561', '입력자 정보'), 
                            OrbitInternalLangPack.getText('WE000025562', '입력자'), 
                            OrbitInternalLangPack.getText('WE000025560', '입력자IP'), 
                            OrbitInternalLangPack.getText('WE000025564', '입력일자'), 
                            this.state.insertUser ? this.state.insertUser.insertEmpName : '',
                            this.props.item.data ? this.props.item.data.insertId : '',
                            this.props.item.data ? this.props.item.data.insertIp : '',
                            this.props.item.data ? this.props.item.data.insertDt : '',
                            this.state.insertUser ? this.state.insertUser.insertEmpPicFileId : '',
                        )
                    }
                    {
                        
                        this.renderItem(
                            OrbitInternalLangPack.getText('WE000025570', '수정자 정보'), 
                            OrbitInternalLangPack.getText('WE000000792', '수정자'), 
                            OrbitInternalLangPack.getText('WE000026227', '수정자IP'), 
                            OrbitInternalLangPack.getText('WE000000659', '수정일자'), 
                            this.state.modifyUser ? this.state.modifyUser.modifyEmpName : '',
                            this.props.item.data ? this.props.item.data.modifyId : '',
                            this.props.item.data ? this.props.item.data.modifyIp : '',
                            this.props.item.data ? this.props.item.data.modifyDt : '',
                            this.state.modifyUser ? this.state.modifyUser.modifyEmpPicFileId : '',

                        )
                    }
                </div>
                {this.props.userDetailDialog && this.state.isUserDetailDialogShow === true && this.state.selectedDetailDialogUserLoginId !== null ?
                    // UAAP012
                    <this.props.userDetailDialog
                        compSeq={
                            this.state.selectedDetailDialogUserLoginId === this.props.item.data.MODIFY_ID ?
                                this.state.modifyUser.modifyCompSeq : this.state.insertUser.insertCompSeq
                        }
                        deptSeq={
                            this.state.selectedDetailDialogUserLoginId === this.props.item.data.MODIFY_ID ?
                                this.state.modifyUser.modifyDeptSeq : this.state.insertUser.insertDeptSeq
                        }
                        empSeq={
                            this.state.selectedDetailDialogUserLoginId === this.props.item.data.MODIFY_ID ?
                                this.state.modifyUser.modifyEmpSeq : this.state.insertUser.insertEmpSeq
                        }
                        onClickClose={(isShow: boolean) => {
                            this.setState({
                                isUserDetailDialogShow: isShow,
                                selectedDetailDialogUserLoginId: null,
                            })
                        }}
                    /> : <></>}
            </SideBarContent>
        )
    }
}
