/**
 * SimpleJsonCodePickerDataSource
 * API 호출을 통한 데이터 리턴이 아닌 테스트등의 목적으로  Fix된 데이터를 리턴하고 싶을 경우 사용
 * 
 * @version 0.1
 * @author 나윤수
 * @see common.js
 */
import { IColumn } from "../../../OBTDataGrid/IColumn";
import { IResponseData } from "../IBuiltInCodePicker";

export default class SimpleJsonCodePickerDataSource {

    private data: any;

    columns: Array<IColumn>;
    codeProperty: string;
    textProperty: string;

    constructor(data: any, columns: Array<IColumn>, codeProperty: string, textProperty: string) {
        this.data = data;
        this.columns = columns;
        this.codeProperty = codeProperty;
        this.textProperty = textProperty;
    }

    getColumns(): IColumn[] {
        return this.columns;
    }

    getData(e: { fetch: any, keyword?: string, parameters?: any }): Promise<any> {
        if (e.keyword) {
            const searchResult = this.data.data.filter((x: Object) => x[this.codeProperty].indexOf(e.keyword) > -1 ||
                x[this.textProperty].indexOf(e.keyword) > -1);
            return Promise.resolve<IResponseData>({ data: searchResult });
        }

        if (e.parameters.searchText) {
            const searchResult = this.data.data.filter((x: Object) => x[this.codeProperty].indexOf(e.parameters.searchText) > -1 ||
                x[this.textProperty].indexOf(e.parameters.searchText) > -1);
            return Promise.resolve<IResponseData>({ data: searchResult });
        }

        return Promise.resolve<IResponseData>({ data: this.data.data });
    }

    getDataWithPaging(pageNumber: number, size: number): Promise<any> {
        return Promise.resolve(this.paginate(pageNumber, size));
    }

    getDataWithParams(parameters: any): Promise<any> {
        const searchResult = this.data.data.filter((x: Object) => x[this.codeProperty].indexOf(parameters.searchText) > -1 ||
            x[this.textProperty].indexOf(parameters.searchText) > -1);
        return Promise.resolve<IResponseData>({ data: searchResult });
    }

    getCodeProperty(): string {
        return this.codeProperty;
    }

    getTextProperty(): string {
        return this.textProperty;
    }

    private paginate(pageNumber: number, size: number) {
        return this.data.data.slice(pageNumber * size, (pageNumber + 1) * size);
    }
}
