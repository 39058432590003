import React from "react";
import PropTypes from 'prop-types';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';

const customStyle = { border: 'none', borderRadius: '3px', margin: '0px' };

export default class ExampleCode extends React.Component {
    static propTypes = {
        code: PropTypes.string.isRequired
    }
    static defaultProps = {
        code: ''
    }
    render() {
        return (
            <SyntaxHighlighter
                language="jsx"
                style={tomorrow}
                customStyle={customStyle}
                showLineNumbers>
                {this.props.code}
            </SyntaxHighlighter>)
    }
}