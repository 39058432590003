import React from 'react';
import { CommonDefinitions, CommonProps, CommonType, createPropDefinitions, toEnumType, Util } from '../Common';
import OBTPortal from '../OBTPortal';
const styles = require('./OBTFloatingPanel.module.scss');

export enum PositionType {
    'top' = 'top',
    'left' = 'left',
    'right' = 'right',
    'bottom' = 'bottom'
}

export enum AlignType {
    'near' = 'near',
    'center' = 'center',
    'far' = 'far'
}

interface State {
    opacity: boolean,
    align: AlignType
}

interface IOBTFloatingPanel extends CommonProps.className, CommonProps.width, CommonProps.height, CommonProps.value<boolean> {
    anchor?: React.RefObject<HTMLElement>,
    anchorEl?: HTMLElement | (() => HTMLElement)
    autoPosition: boolean,
    position: PositionType,
    align: AlignType,
    usePortal?: boolean
}

export default class OBTFloatingPanel extends React.Component<IOBTFloatingPanel, State> {
    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.className(),
        CommonDefinitions.width(),
        CommonDefinitions.height(),
        { name: "value", type: CommonType.boolean, description: "패널 표시 여부", default: false },
        { name: "anchor", type: "React.RefObject<HTMLElment>", optional: true, description: "anchor 로 지정할 ref ( React.createRef() 로 생성된 ) 를 부여합니다. 단 기본 HTML Element 타입만 가능합니다." },
        { name: "anchorEl", type: ["HTMLElement", "() => HTMLElement"], optional: true, description: "anchor 로 지정할 ref를 부여합니다. 함수 형태로 작성할 때 사용" },
        { name: "autoPosition", type: CommonType.boolean, description: "화면에서 벗어나게 되면 자동으로 position 변경을 해준다.", default: false, optional: true },
        { name: "position", type: toEnumType(PositionType), default: "top", description: "anchor 를 기준으로 패널을 표시할 위치를 지정합니다.", optional: true },
        { name: "align", type: toEnumType(AlignType), optional: true, default: "center", description: "anchor 를 기준으로 패널을 정렬할 위치를 지정합니다." },
    );

    public static Position = PositionType;
    public static Align = AlignType;

    static defaultProps = {
        value: false,
        autoPosition: true,
        position: PositionType.top,
        align: AlignType.center
    }

    myRefs = {
        root: React.createRef<HTMLDivElement>(),
        floatingPanel: React.createRef<HTMLDivElement>(),
    }

    state: State = {
        opacity: false,
        align: this.props.align
    }

    dialogContext: any = null;

    get root() {
        return this.myRefs.root.current;
    }

    get floatingPanel() {
        return this.props.usePortal !== false ? this.myRefs.floatingPanel.current : this.myRefs.root.current;
    }

    render() {
        if (this.props.usePortal !== false) {
            return <div ref={this.myRefs.root}
                className={styles.wrapperRoot}
                data-orbit-component={'OBTFloatingPanel'}>
                <OBTPortal>
                    <div
                        ref={this.myRefs.floatingPanel}
                        className={Util.getClassNames(styles.root, this.props.className, !this.props.value ? styles.invisible : null)}
                        style={Util.getWrapperStyle(this.props)}
                        data-orbit-component={'OBTFloatingPanel'}>
                        {this.props.children}
                    </div>
                </OBTPortal>
            </div>
        } else {
            return <div ref={this.myRefs.root}
                className={Util.getClassNames(styles.root, this.props.className, !this.props.value ? styles.invisible : null)}
                style={Util.getWrapperStyle(this.props)}
                data-orbit-component={'OBTFloatingPanel'}>
                {this.props.children}
            </div>;
        }
    }


    componentDidMount() {
        window.addEventListener('scroll', this.setPlacement, true);
        window.addEventListener('resize', this.setPlacement, true);
        if (this.root) {
            (this.root as any)._OBTDialogRelocated = () => {
                this.setPlacement();
            }
        }
        this.setPlacement();
    }

    componentDidUpdate() {
        this.setPlacement();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.setPlacement, true);
        window.removeEventListener('resize', this.setPlacement, true);
    }

    private setPlacement = () => {
        if (this.props.value) {
            const floatPanel = this.floatingPanel;
            const anchor = (() => {
                let anchor = this.props.anchor ? this.props.anchor.current : this.props.anchorEl ? (typeof this.props.anchorEl === 'function' ? this.props.anchorEl() : this.props.anchorEl) : null;
                if (!anchor) {
                    anchor = this.root ? this.root.parentElement : null;
                }
                return anchor;
            })();

            if (floatPanel && anchor) {
                const anchorBounds = anchor.getBoundingClientRect();
                const anchorVisible =
                    [{ x: anchorBounds.left + (anchorBounds.width / 2), y: anchorBounds.top + (anchorBounds.height / 2) },
                    { x: anchorBounds.left, y: anchorBounds.top },
                    { x: anchorBounds.left, y: anchorBounds.top + anchorBounds.height - 1 },
                    { x: anchorBounds.left + anchorBounds.width - 1, y: anchorBounds.top },
                    { x: anchorBounds.left + anchorBounds.width - 1, y: anchorBounds.top + anchorBounds.height - 1 }]
                        .find(pos => {
                            return (Util.elementsFromPoint(pos.x, pos.y) || []).find(el => el === anchor) ? true : false;
                        }) ? true : false;
                const getPanelBounds = (position: PositionType, align: AlignType) => {
                    let left = 0, top = 0;
                    switch (position) {
                        case PositionType.top:
                        case PositionType.bottom:
                            left = align === AlignType.near ? anchorBounds.left :
                                align === AlignType.center ? (anchorBounds.left + (anchorBounds.width / 2)) - (floatPanel.clientWidth / 2) :
                                    align === AlignType.far ? anchorBounds.left + anchorBounds.width - floatPanel.clientWidth :
                                        0;
                            break;
                        case PositionType.left:
                            left = anchorBounds.left - floatPanel.clientWidth;
                            break;
                        case PositionType.right:
                            left = anchorBounds.left + anchorBounds.width;
                            break;
                    }
                    switch (position) {
                        case PositionType.left:
                        case PositionType.right:
                            top = align === AlignType.near ? anchorBounds.top :
                                align === AlignType.center ? (anchorBounds.top + (anchorBounds.height / 2)) - (floatPanel.clientHeight / 2) :
                                    align === AlignType.far ? anchorBounds.top + anchorBounds.height - floatPanel.clientHeight :
                                        0;
                            break;
                        case PositionType.top:
                            top = anchorBounds.top - floatPanel.clientHeight;
                            break;
                        case PositionType.bottom:
                            top = anchorBounds.top + anchorBounds.height;
                            break;
                    }

                    return {
                        left, top,
                        width: floatPanel.clientWidth,
                        height: floatPanel.clientHeight,
                        valid: () => {
                            return !(left < 0 || top < 0 || (left + floatPanel.clientWidth) > window.innerWidth || (top + floatPanel.clientHeight) > window.innerHeight);
                        }
                    };
                };

                let boundary = getPanelBounds(this.props.position, this.props.align);
                if (this.props.autoPosition && !boundary.valid()) {
                    const oppositePosition = this.props.position === PositionType.top ? PositionType.bottom :
                        this.props.position === PositionType.bottom ? PositionType.top :
                            this.props.position === PositionType.left ? PositionType.right :
                                this.props.position === PositionType.right ? PositionType.left :
                                    this.props.position;
                    let tests = [
                        { position: this.props.position, align: AlignType.near },
                        { position: this.props.position, align: AlignType.center },
                        { position: this.props.position, align: AlignType.far },
                        { position: oppositePosition, align: AlignType.near },
                        { position: oppositePosition, align: AlignType.center },
                        { position: oppositePosition, align: AlignType.far }
                    ];
                    tests = tests.concat(
                        [PositionType.top, PositionType.bottom, PositionType.left, PositionType.right].flatMap((position) => {
                            return [
                                { position, align: AlignType.near },
                                { position, align: AlignType.center },
                                { position, align: AlignType.far },
                            ]
                        }).filter(item => {
                            if (tests.find(test => item.position === test.position && item.align === test.align)) return false;
                            return true;
                        }))

                    const newPosition = tests.find(test => {
                        return getPanelBounds(test.position, test.align).valid();
                    });

                    if (newPosition) {
                        boundary = getPanelBounds(newPosition.position, newPosition.align);
                    }
                }

                if (floatPanel.style.left !== `${boundary.left}px`) {
                    floatPanel.style.left = `${boundary.left}px`;
                }
                if (floatPanel.style.top !== `${boundary.top}px`) {
                    floatPanel.style.top = `${boundary.top}px`;
                }
                if (anchorVisible) {
                    if (floatPanel.style.visibility !== '') {
                        floatPanel.style.visibility = '';
                    }
                } else {
                    if (floatPanel.style.visibility !== 'hidden') {
                        floatPanel.style.visibility = 'hidden';
                    }
                }
            }
        }
    };

    public containsFocus = () => {
        if ((this.myRefs.root.current && Util.containsFocus(this.myRefs.root)) ||
            (this.myRefs.floatingPanel.current && Util.containsFocus(this.myRefs.floatingPanel))) {
            return true;
        }
        return false;
    }
}