import React from 'react'
import SideBarContent, { SideBarContentType } from './SideBarContent';
import { LUXCalendar } from 'luna-rocket';
import { ISideBarContent } from './SideBar';
import { Util } from '../Common';
import moment from 'moment'
import { OrbitInternalLangPack } from '../Common/Util';

const styles = require('./SideBarContent.module.scss');

const calendarStyle = {
    width: '100%',
    border: 'none',
    marginTop: 'none'
}

export interface IScheduleItem {
    calType?: string,
    /**
     * 캘린더 seq
     */
    mcalSeq?: string,
    /**
     * 캘린더 색상(빈값일경우 : #b2e1ff)
     */
    calColor?: string,
    /**
     * 캘린더권한(w:캘린더관리자,소유자, r:캘린더참여자 및 공개자)
     */
    calRwGbn?: string,
    /**
     * 일정 마스터 seq
     */
    schmSeq?: string,
    /**
     * 일정 seq
     */
    schSeq?: string,
    /**
     * 제목
     */
    schTitle?: string,
    /**
     * 시작일시 yyyyMMddHHmm
     */
    startDate?: string,
    /**
     * 종료일시 yyyyMMddHHmm
     */
    endDate?: string,
    /**
     * 하루종일 여부 Y/N
     */
    alldayYn?: string,
    /**
     * 구분코드 - E(개인), D(부서), P(프로젝트), G(전사)
     */
    gbnCode?: string,
    /**
     * 일정 유형에 따른 seq
     */
    gbnSeq?: string,
    /**
     * 사용자명 or 부서명 or 프로젝트명
     */
    gbnName?: string,
    /**
     * 등록자명
     */
    createName?: string,
    /**
     * 직책명
     */
    dutyName?: string,
    /**
     * 삭제 가능 여부
     */
    delYn?: string,
    /**
     * 자원 예약여부
     */
    resYn?: string,
    /**
     * 첫번째 자원예약 상태(RE : 승인요청, CO : 승인완료 RC : 예약완료, RJ : 반려, CA : 취소, CR : 승인취소)
     */
    resStatus?: string,
    /**
     * 일정 참여여부(등록자,참여자,공개자)
     */
    joinYn?: string,
    /**
     * 자원 목록
     */
    resList?: string,
    /**
     * 일정 초대 권한구분('':일반일정, 'M':주최자권한, 'W':수정가능, 'S':수정불가)
     */
    inviterType?: string,
    /**
     * 캘린더제목
     */
    calTitle?: string,
    /**
     * 댓글카운트
     */
    commentCnt?: number,
}

interface ISBCSchedule {
    item: ISideBarContent,
    fetch: any,
    userInfo?: {
        ucUserInfo: {
            compSeq: string,
            groupSeq: string,
            deptSeq: string,
            emailAddr?: string,
            emailAdd?: string,
            emailDomain?: string,
            langCode: string
        }
    },
    onScheduleItemClicked: (item: IScheduleItem) => void;
}

interface IState {
    selectedDate: Date,
    scheduleListByMonth: IScheduleItem[],
    scheduleDateList: Date[],
    errorOnFetch: boolean,

}

/**
 * TODO: caching
 */
export default class SBCSchedule extends React.Component<ISBCSchedule, IState> {
    state: IState = {
        selectedDate: new Date(),
        scheduleListByMonth: [],
        scheduleDateList: [],
        errorOnFetch: false
    }

    shouldComponentUpdate(prevProps: ISBCSchedule, prevState: IState) {
        if (this.state.selectedDate !== prevState.selectedDate) {
            return true;
        }

        return false;
    }

    componentDidMount() {
        this.fetchMonthSchedule(this.state.selectedDate);
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.error('SBCSchedule did catch', error, errorInfo);

        this.setState({
            errorOnFetch: true,
        });
    }

    private async fetchMonthSchedule(dateOnMonth: string | Date) {
        // ajaxEbp.post(apiDomain + '/kiss/sc111A03',
        // {
        //     // companyInfo: {
        //     //     compSeq: '1000',
        //     //     groupSeq: 'klagoDev',
        //     //     deptSeq: '1110',
        //     //     emailAddr: 'admin',
        //     //     emailDomain: 'test.com',
        //     // },
        //     startDate: Util.getDateString(Util.DateOption.monthFirstDay, dateOnMonth),
        //     endDate: Util.getDateString(Util.DateOption.monthLastDay, dateOnMonth),
        //     // mySchYn: 'Y',
        //     // langCode: 'kr',
        //     calList: [
        //         { mcalSeq: "2", calType: "E", adminCalYn: "Y" }, // 개인
        //         { mcalSeq: "7", calType: "M", adminCalYn: "Y" } // 
        //     ],
        // },
        // {
        //     contextType: "application/json"
        // }).

        // 내 캘린더 조회
        const userInfo: any = this.props.userInfo ? this.props.userInfo.ucUserInfo : {};
        let calendarList = await (async () => {
            try {
                const response = await this.props.fetch.post('/schres/sc111A02', {
                    companyInfo: {
                        compSeq: userInfo.compSeq || '',
                        groupSeq: userInfo.groupSeq || '',
                        deptSeq: userInfo.deptSeq || '',
                        emailAddr: userInfo.emailAdd || userInfo.emailAddr || '',
                        emailDomain: userInfo.emailDomain || '',
                    },
                    calType: '',
                    langCode: userInfo.langCode || 'kr'
                }, {
                    contextType: "application/json"
                });
                if (response && (response.resultCode === 0 || (response.resultCode >= 200 && response.resultCode < 300))) {
                    return response.resultData.resultList;
                }
            } catch (error) {
                console.error(error);
            }
            return [];
        })();
        let atcalList = await (async () => {
            try {
                const response = await this.props.fetch.post('/schres/sc111A15', {
                    companyInfo: {
                        compSeq: userInfo.compSeq || '',
                        groupSeq: userInfo.groupSeq || '',
                        deptSeq: userInfo.deptSeq || '',
                        emailAddr: userInfo.emailAdd || userInfo.emailAddr || '',
                        emailDomain: userInfo.emailDomain || '',
                    },
                    deptSeq: userInfo.deptSeq || '',
                    empSeq: userInfo.empSeq || '',
                    langCode: userInfo.langCode || 'kr'
                }, {
                    contextType: "application/json"
                });
                if (response && (response.resultCode === 0 || (response.resultCode >= 200 && response.resultCode < 300))) {
                    return response.resultData.takeCalList;
                }
            } catch (error) {
                console.error(error);
            }
            return [];
        })();

        const selectedCalendars = localStorage.getItem('UEA0000_SelectedCalendars');
        if (selectedCalendars) {
            const selectedCalendarList = selectedCalendars.split(',').map(i => i ? i.trim() : i).filter(i => i);
            if (selectedCalendarList.length > 0) {
                calendarList = calendarList.filter(cal => selectedCalendarList.includes(cal.mcalSeq));
                atcalList = atcalList.filter(cal => selectedCalendarList.includes(cal.tcalSeq));
            }
        }

        // 캘린더 조회
        this.props.fetch.post('/schres/sc111A03',
            {
                companyInfo: {
                    compSeq: userInfo.compSeq || '',
                    groupSeq: userInfo.groupSeq || '',
                    deptSeq: userInfo.deptSeq || '',
                    emailAddr: userInfo.emailAdd || userInfo.emailAddr || '',
                    emailDomain: userInfo.emailDomain || '',
                },
                startDate: Util.getDateString(Util.DateOption.monthFirstDay, dateOnMonth),
                endDate: Util.getDateString(Util.DateOption.monthLastDay, dateOnMonth),
                mySchYn: 'N',
                langCode: userInfo.langCode || 'kr',
                calList: calendarList.map(item => {
                    return {
                        ...item,
                        adminYn: 'N'
                    }
                }),
                acalList: atcalList.filter(item => item.calType === 'A').map(item => item.tcalSeq),
                tcalList: atcalList.filter(item => item.calType === 'T').map(item => item.tcalSeq)
            },
            {
                contextType: "application/json"
            }
        ).then(response => {
            if (response && (response.resultCode === 0 || (response.resultCode >= 200 && response.resultCode < 300))) {
                const scheduleList = (response.resultData.resultList || []);
                this.setState({
                    errorOnFetch: false,
                    selectedDate: new Date(dateOnMonth),
                    scheduleListByMonth: scheduleList,
                    scheduleDateList: scheduleList.map(item => {
                        if (item.startDate && item.startDate.length === 12) {
                            return moment(item.startDate.substring(0, 8), "YYYYMMDD").toDate();
                        }
                        return undefined;
                    }).filter(item => item)
                });
            } else {
                console.error(response);
                this.setState({
                    errorOnFetch: true,
                });
            }
        }).catch((error) => {
            console.error(error);

            this.setState({
                errorOnFetch: true,
            });
        });
    }

    private mapCalType = (code?: string) => {
        /* E(인), D(부서), P(프로젝트), G(전사) */
        if (code === 'E') {
            return OrbitInternalLangPack.getText('WE000001834', '개인');
        }
        if (code === 'M') {
            return OrbitInternalLangPack.getText('WE000010465', '공유');
        }
        if (code === 'P') {
            return OrbitInternalLangPack.getText('WE000000309', '프로젝트');
        }
        if (code === 'G') {
            return OrbitInternalLangPack.getText('WE000010744', '구글');
        }
        if (code === 'A') {
            return OrbitInternalLangPack.getText('WE000002367', '근태');
        }
        if (code === 'T') {
            return OrbitInternalLangPack.getText('WE000010552', '구독');
        }
        return code;
    }

    private makeTimeLineString = (startDate?: string, endDate?: string, alldayYn?: string) => {
        if (alldayYn && alldayYn.toUpperCase() === 'Y') {
            return OrbitInternalLangPack.getText('WE000005578', '하루종일');
        }

        let startDateTime: Date | null = null;
        let endDateTime: Date | null = null;

        let result = '';
        if (startDate && moment(startDate, "YYYYMMDDHHSS").isValid()) {
            startDateTime = moment(startDate, "YYYYMMDDHHSS").toDate();
            const hour = this.pad(startDateTime.getHours().toString(), 2);
            const minute = this.pad(startDateTime.getMinutes().toString(), 2);

            result = result + hour + ':' + minute;
        }

        if (result.length > 0) {
            result = result + ' ~ ';
        }

        if (endDate && moment(endDate, "YYYYMMDDHHSS").isValid()) {
            endDateTime = moment(endDate, "YYYYMMDDHHSS").toDate();
            const hour = this.pad(endDateTime.getHours().toString(), 2);
            const minute = this.pad(endDateTime.getMinutes().toString(), 2);

            result = result + hour + ':' + minute;
        }

        return result;
    }

    private pad(value: string, length: number) {
        value = value + '';
        return value.length >= length ? value : new Array(length - value.length + 1).join('0') + value;
    }

    private handleChangeDate = (date: Date) => {
        const currentYearMonth = this.state.selectedDate.getFullYear().toString() + this.state.selectedDate.getMonth().toString();
        const prevYearMonth = date.getFullYear().toString() + date.getMonth().toString();

        if (currentYearMonth !== prevYearMonth) {
            this.fetchMonthSchedule(date);
        } else {
            this.setState({
                selectedDate: date
            });
        }
    }

    private handleChangeDisplayDate = (date: Date) => {
        this.setState({
            selectedDate: date
        }, () => {
            this.fetchMonthSchedule(date);
        });
    }

    private renderScheduleList() {
        if (this.state.errorOnFetch === true) {
            return (
                <div style={{ padding: '10px' }}>{OrbitInternalLangPack.getText('WE000028504', '일정을 불러오지 못했습니다.')}</div>
            );
        }

        const items = this.state.scheduleListByMonth.filter(item => {
            if (item.startDate) {

                let startDate: Date = moment(item.startDate).toDate();
                if (item.startDate && item.startDate.length === 12) {
                    startDate = moment(item.startDate.substring(0, 8), "YYYYMMDD").toDate();
                }

                const selectedDate = this.state.selectedDate;
                return (startDate.getDate() === selectedDate.getDate());
            }

            return false;
        });

        const itemElement = items.map((item, index) => {
            return (
                <div className={styles.scheduleItem}
                    key={item.schSeq}
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={() => { this.handleScheduleItemClick(item) }}>
                    <div className={styles.timeLine}>
                        {
                            this.makeTimeLineString(item.startDate, item.endDate, item.alldayYn)
                        }
                    </div>
                    <div className={styles.itemLine}>
                        <div className={styles.gnbCode}
                            style={{
                                backgroundColor: item.calColor
                            }}>
                            {this.mapCalType(item.calType)}
                        </div>
                        <div className={styles.title}>
                            {item.schTitle}
                        </div>
                    </div>
                    {
                        index === items.length - 1 ? null : (<div className={styles.grayLine} />)
                    }
                </div>
            )
        });

        return (
            <>
                <div className={styles.scheduleListHeader}>
                    <div className={styles.selectedDate}>{Util.getDateString(Util.DateOption.simpleDate, this.state.selectedDate)}</div>
                    <div className={styles.scheduleCount}>{items.length}</div>{OrbitInternalLangPack.getText('WE000000279', '건')}
                </div>
                <div className={styles.scheduleList}>
                    {itemElement}
                </div>
            </>
        )
    }
    handleScheduleItemClick = (item: IScheduleItem) => {
        this.props.onScheduleItemClicked(item);
    }

    render() {
        return (
            <SideBarContent item={Object.assign({}, this.props.item, {
                title: OrbitInternalLangPack.getText('WE000000285', '일정'),
                type: SideBarContentType.accordion
            })}>
                <div className={styles.schedule}>
                    <LUXCalendar
                        className={'sbc_lux_calendar'}
                        initialDate={this.state.selectedDate}
                        scheduleDate={this.state.scheduleDateList}
                        onChange={this.handleChangeDate}
                        onChangeDisplayDate={this.handleChangeDisplayDate}
                        style={calendarStyle}
                        theme={"mini"}
                    />
                    {this.renderScheduleList()}
                </div>
            </SideBarContent>
        )
    }
}
