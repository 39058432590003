import * as React from 'react';
import { CommonProps, Events, Util } from '../Common';
import { IDaumPostCodeOption } from './IDaumPostCodeOption';
import { IPostCodeData } from './IPostCodeData';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

interface IDaumPostCode extends CommonProps.className, CommonProps.width, CommonProps.height, Events.onChange<IPostCodeData> {
    /**
     * 검색어. 검색어가 존재하면 Daum 우편번호 서비스 검색창에 표시된다.
     */
    searchText: string,
    /**
     * 옵션
     */
    option: IDaumPostCodeOption,
    /**
     * 엘리먼트가 준비되면 호출되는 이벤트
     */
    onReady?: () => void,
}

interface IState extends hasError {
    scriptTag: HTMLElement | null,
}

const SCRIPT_URL = "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";

/**
 * @internal
 * 다음 우편번호 서비스 리액트 래퍼
 */
export default class DaumPostCode extends React.Component<IDaumPostCode, IState> {
    public static defaultProps = {
        searchText: "",
        width: "100%",
        height: "100%",
        option: {
            animation: true,
            autoMapping: true,
            shorthand: true,
            pleaseReadGuide: 0,
            pleaseReadGuideTimer: 1.5,
            maxSuggestItems: 10,
            showMoreHName: false,
            hideMapBtn: false,
            hideEngBtn: false,
            alwaysShowEngAddr: false,
            zonecodeOnly: false,
            theme: null,
        },
    }

    public state: IState = {
        scriptTag: null,
    }

    private rootRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        try {
            const id = 'daum-post-code';
            let script = document.getElementById(id);

            if (script === null) {
                const createdScript = document.createElement("script");
                createdScript.id = id;
                createdScript.src = SCRIPT_URL;
                createdScript.type = 'text/javascript';
                createdScript.onload = () => {
                    this.embededPostCode();

                    if (this.props.onReady) {
                        this.props.onReady();
                    }
                }
                document.body.appendChild(createdScript);

                script = createdScript;
            } else {
                this.embededPostCode();

                if (this.props.onReady) {
                    this.props.onReady();
                }
            }

            this.setState({
                scriptTag: script
            });
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    componentWillUnmount() {
        if (this.state.scriptTag) {
            document.body.removeChild(this.state.scriptTag);
        }
    }

    makeDaumPostCodeInstance(): any {
        const postCodeInstance = new window['daum'].Postcode({
            oncomplete: (data: IPostCodeData) => {
                Util.invokeEvent<Events.ChangeEventArgs<IPostCodeData>>(
                    this.props.onChange,
                    new Events.ChangeEventArgs<IPostCodeData>(this, data)
                );
            },
            width: '100%',
            height: '100%',
            ...this.props.option
        });

        return postCodeInstance;
    }

    embededPostCode() {
        const postCodeInstance = this.makeDaumPostCodeInstance();

        if (postCodeInstance && this.rootRef.current) {
            postCodeInstance.embed(this.rootRef.current, { q: this.props.searchText, autoClose: false });
        }
    }

    renderComponent = () => {
        return (
            <div className={this.props.className}
                ref={this.rootRef}
                style={{ width: this.props.width, height: this.props.height }}
            >
                {/* daum post code */}
            </div>
        );
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        )
    }
};
