import React from "react";
import styles from './updateDocuments.module.scss'
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import moment from 'moment'
import FilterBox from './filterBox'
import { OBTConfirm, OBTScrollbar } from "../../components";
import UpdateDialog from "./updateDialog";
import LoginDialog from "./loginDialog";
import { getCookie } from "./cookie";
import emptySetImage from "../../components/Images/img_empty_data.png"

const getClassNames = (...classNames) => {
    return classNames.filter(name => name && name.length > 0).join(' ');
}

export default class DocumentView extends React.Component {
    state = {
        selectedDate: undefined,
        updateDialog: false,
        loginDialog: false,
        showAdminButtons: false,
        showDeleteAlert: false,
        showEditDialog: false,
        isEdit: false,
        deleteId: "",
        editItem: []
    }
    contentsWrapRef = React.createRef();

    componentDidMount() {
        fetch('http://10.82.6.190/auth/success', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${getCookie('login')}`,
            }
        }).then((response) => {
            return response.json()
        }).then((result) => {
            if (result.login === "success") {
                this.setState({
                    showAdminButtons: true
                })
            }
        }).catch((e) => {
            console.error(e)
            throw e;
        })
    }

    deleteUpdateLog = () => {
        fetch('http://10.82.6.190/history/delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${getCookie('login')}`
            },
            body: JSON.stringify({
                id: this.state.deleteId
            })
        }).then((response) => {
            if (response.ok) {
                this.props.onRefresh();
                this.setState({
                    showDeleteAlert: false
                })
            }
            // 성공하지 못했을때 처리
        }).catch((e) => {
            console.error(e)
            throw e;
        }).finally(() => {
            this.setState({
                deleteId: ""
            })
        })
    }

    clearUpdateDialog = () => {
        this.setState({
            isEdit: false,
            editItem: []
        })
    }

    handleDeleteAlertOpen = (deleteId) => {
        this.setState({
            showDeleteAlert: true,
            deleteId: deleteId
        })
    }

    handleEditDialogOpen = (editItem) => {
        this.setState({
            isEdit: true,
            editItem: editItem
        }, () => {
            this.setState({
                updateDialog: true
            })
        })
    }

    handleUpdateDialogClose = () => {
        this.setState({
            updateDialog: false
        })
    }

    handleLoginDialogClose = () => {
        this.setState({
            loginDialog: false
        })
    }

    handleDateAnchorClick = (index, updateDate) => {
        if (this.contentsWrapRef.current) {
            const item = this.contentsWrapRef.current.querySelector(`.updateList${index}`);
            if (item) {
                item.scrollIntoView();
            }
        }
        this.setState({
            selectedDate: updateDate
        })
    }

    render() {
        const updateDateList = this.props.documents.map(item => item.updateDate);

        const uniqueUpdateList = new Set(updateDateList);

        const groupByUpdateDate = {};
        uniqueUpdateList.forEach((updateDate) => {
            groupByUpdateDate[updateDate] = this.props.documents.filter(item => item.updateDate === updateDate);
        })

        const groupByUpdateDateSort = Object.keys(groupByUpdateDate).sort((a, b) => {
            return a.idx < b.idx ? 1 : a.idx > b.idx ? 0 : -1
        })

        const updateItemWrap = groupByUpdateDateSort.map((updateDate, index) => {
            const updateDateFormat = moment(updateDate).format('YYYY. MM. DD')

            return (
                <div key={index} className={getClassNames(`updateList${index}`)}>
                    <div key={index} className={styles.updateDate}>{updateDateFormat}</div>
                    {
                        groupByUpdateDate[updateDate].map((item, index) =>
                            <UpdateItem
                                key={index}
                                documentsItem={item}
                                index={index}
                                showAdminButtons={this.state.showAdminButtons}
                                onDeleteAlertOpen={() => this.handleDeleteAlertOpen(item._id)}
                                onEditDialogOpen={() => this.handleEditDialogOpen(item)}
                            />)
                    }
                </div>
            )
        })

        const anchor = groupByUpdateDateSort.map((updateDate, index) => {
            const dateAnchor = moment(updateDate).format('YYYY. MM. DD')
            return (

                <div key={index}
                    onClick={() => this.handleDateAnchorClick(index, updateDate)}
                    className={this.state.selectedDate === updateDate ? styles.selected : undefined}>
                    {dateAnchor}
                </div>
            )
        })

        const isEmptySet = this.props.emptySet;
        const emptySet = (
            <div className={styles.emptySetRoot}>
                <div className={styles.imageWrapper}>
                    <img width={"150px"} src={emptySetImage} alt="" />
                </div>
                <div className={styles.textWrapper}>
                    <div className={styles.mainText}><b>Whoops!</b></div>
                    <div className={styles.subText}>검색어와 조건이 일치하는 항목이 없습니다.<br />단어의 철자가 정확한지 확인해보세요.</div>
                    <button onClick={this.props.onRefresh}>RETRY</button>
                </div>
            </div>
        )

        return (
            <>
                <div className={styles.root}>
                    <div className={styles.titleRoot}>
                        <div className={styles.title} onClick={this.props.onRefresh}>Update History</div>
                        <div className={styles.description}>업데이트 내역을 확인할 수 있습니다.</div>
                        {this.state.showAdminButtons ?
                            <div>
                                <button
                                    className={styles.loginButton}
                                    onClick={() => {
                                        this.setState({
                                            isEdit: false,
                                            editItem: []
                                        }, () => {
                                            this.setState({
                                                updateDialog: true
                                            })
                                        })
                                    }}
                                >
                                    Update Log Add
                                </button>
                            </div> : <></>}
                        {!this.state.showAdminButtons ?
                            <button
                                className={styles.loginButton}
                                onClick={() => {
                                    this.setState({
                                        loginDialog: true
                                    })
                                }}
                            >
                                Admin Login
                            </button> : <></>
                        }
                    </div>
                    <div className={styles.filterRoot}>
                        <FilterBox
                            onSearch={this.props.onSearch} />
                    </div>
                    {
                        !isEmptySet ? (
                            <div className={styles.contentsRoot}>
                                <OBTScrollbar width='100%' height='100%'>
                                    <div className={styles.updateListWrap}>
                                        <div ref={this.contentsWrapRef}  >
                                            {updateItemWrap}
                                        </div>
                                    </div >
                                </OBTScrollbar>
                                <div className={styles.dateLocatorRoot} ref={this.anchorItemRef}>
                                    <div className={styles.dateLocatorTitle}>
                                        <h4>List</h4>
                                    </div>
                                    <div className={styles.anchorItem}>
                                        <OBTScrollbar width='100%' height='100%'>
                                            {anchor}
                                        </OBTScrollbar>
                                    </div>
                                </div>
                            </div>
                        ) : emptySet
                    }
                </div>
                <UpdateDialog
                    dialog={this.state.updateDialog}
                    onClose={this.handleUpdateDialogClose}
                    onRefresh={this.props.onRefresh}
                    isEdit={this.state.isEdit}
                    editItem={this.state.editItem}
                    onClear={this.clearUpdateDialog}
                />
                <LoginDialog
                    dialog={this.state.loginDialog}
                    onClose={this.handleLoginDialogClose}
                />
                {
                    this.state.showDeleteAlert && this.state.deleteId !== "" ?
                        <OBTConfirm
                            type={OBTConfirm.Type.success}
                            title="Delete"
                            labelText="정말 삭제하시겠습니까?"
                            onCancel={() => this.setState({ showDeleteAlert: false, deleteId: "" })}
                            onConfirm={this.deleteUpdateLog}
                        /> : <></>
                }
            </>
        );
    }
}

class UpdateItem extends React.Component {
    state = {
        viewCode: false,
    };

    handleViewCode = () => {
        this.setState({
            viewCode: !this.state.viewCode
        })
    }

    render() {
        const { documentsItem } = this.props;
        return (
            <div className={styles.itemWrap}>
                <li className={getClassNames(styles.listItemWrap, documentsItem.warningLevel === '1' ? styles.normal : documentsItem.warningLevel === '2' ? styles.important : styles.emergency)}>
                    <span className={styles.componentName} style={{ fontWeight: 'bolder', color: '#000' }}>[ {documentsItem.componentName} ]</span>
                    {
                        (() => {
                            switch (documentsItem.type) {
                                case 'imp': return <span className={styles.badge}>기능</span>;
                                case 'bug': return <span className={styles.bug}>버그</span>;
                                case 'style': return <span className={styles.style}>스타일</span>;
                                case 'new': return <span className={styles.new}>신규</span>;
                                case 'del': return <span className={styles.doc}>폐기</span>;
                                default: return <span className={styles.doc}>일반</span>;
                            }
                        })()
                    }
                    <div className={documentsItem.warningLevel === '1' ? styles.normalWarningLevel : styles.warningLevelWrap}>
                        <span className={documentsItem.warningLevel === '2' ? styles.important : documentsItem.warningLevel === '3' ? styles.warning : undefined}></span>
                        <span className={documentsItem.warningLevel === '2' ? styles.important : documentsItem.warningLevel === '3' ? styles.warning : undefined}></span>
                        <span className={documentsItem.warningLevel === '3' ? styles.warning : undefined}></span>
                    </div>
                    {
                        this.props.showAdminButtons ?
                            <>
                                <small className={styles.deleteEditButton} onClick={this.props.onDeleteAlertOpen}>삭제</small>
                                <small style={{ color: "#d1d3d4" }}>&nbsp;/&nbsp;</small>
                                <small className={styles.deleteEditButton} onClick={this.props.onEditDialogOpen}>수정</small>
                            </>
                            : <></>
                    }
                    <div className={styles.subjectWrap}>
                        <span className={styles.subject}>{documentsItem.subject}</span>
                        {documentsItem.detailLink ?
                            <span className={getClassNames(styles.link, styles.detailLink)} onClick={this.handleViewCode}>DetailView</span>
                            : null}
                        {documentsItem.wikiLink ?
                            <span className={styles.link} onClick={() => { window.open(documentsItem.wikiLink, 'blank') }}>Wiki</span>
                            : null}
                        {documentsItem.jiraLink ?
                            <span className={styles.link} onClick={() => { window.open(documentsItem.jiraLink, 'blank') }}>Jira</span>
                            : null}
                    </div>
                    {documentsItem.notice ? (
                        <div className={styles.noticeBoxWrap}>
                            <div className={styles.noticeBoxDeco}></div>
                            <div className={styles.noticeBox}>
                                <h5>Note</h5>
                                <p style={{ fontSize: '1.0em', fontWeight: 'normal' }}>{documentsItem.notice}</p>
                            </div>
                        </div>
                    ) : null}
                    {this.state.viewCode ? (
                        <div className={styles.code}>
                            <SyntaxHighlighter language="jsx" style={tomorrow} customStyle={{ border: 'none' }} showLineNumbers>
                                {documentsItem.detailLink}
                            </SyntaxHighlighter>
                        </div>
                    ) : null}
                </li>
            </div>
        );
    }
}
