/**
 * OBTConfirm
 * @version 0.1
 * @author 박재성
 * @see common.js
 */
import * as React from 'react';
import { Events, CompositeProps, Util, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import OBTButton, { ButtonTheme, ButtonType } from '../OBTButton';
import IconAlertApproval from '../Images/icon-alert-approval.png';
import IconAlertError from '../Images/icon-alert-error.png';
import IconAlertQuest from '../Images/icon-alert-quest.png';
import IconAlertWarning from '../Images/icon-alert-warning.png';

import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';

import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { OBTContext } from '../OBTPageContainer/OBTPageContainer';
import OBTPortal from '../OBTPortal';
import { OrbitInternalLangPack } from '../Common/Util';

const styles = require('./OBTConfirm.module.scss');

class MouseEventArgs extends Events.MouseEventArgs {
    constructor(target: any, event: React.MouseEvent, public readonly type: string) {
        super(target, event);
    }
    toString(): string { return JSON.stringify({ target: this.target ? 'object' : this.target, event: this.event!.toString(), type: this.type }); }
}

enum Type {
    'default' = 'default',
    'success' = 'success',
    'warning' = 'warning',
    'error' = 'error',
    'question' = 'question'
}

interface IButton {
    key: string,
    theme?: ButtonTheme,
    labelText?: string,
    type?: ButtonType,
    visible?: boolean,
    isClose?: boolean,
    isConfirm?: boolean,
    onClick?: (e: any) => void;
}

/**
 * PropType 정의
 * Events, CommonProps, CompositeProps 에 미리 지정된 Prop interface 를 충분히 활용한다.
 * extends 로 인터페이스 상속을 통해 사용된다.
 * 공용 Api 를 사용하려면 CommonProps.api 인터페이스를 확장한다.
 */
interface IOBTConfirm extends CompositeProps.Default {
    type: Type,
    title?: any,
    labelText?: any,
    confirmText: string,
    cancelText: string,
    onConfirm?: (e: Events.EventArgs) => void,
    onCancel?: (e: Events.EventArgs) => void,
    /**
     * Dialog의 하단의 버튼을 설정합니다.
     */
    buttons?: Array<IButton>,
    /**
     * 좀 더 엄격하게 onConfirm을 체크하여 OBTConfirm의 close 여부를 결정합니다.
     */
    strictConfirmCheck?: boolean;
    /**
     * 좀 더 엄격하게 onCancel 체크하여 OBTConfirm의 close 여부를 결정합니다.
     */
    strictCancelCheck?: boolean;

}

/**
 * State 정의
 */
interface State extends hasError {
    open: boolean;
}

/**
* OBTConfirm
* Prop : { value, labelText, type, open, cancelText, confirmText }
*/
export default class OBTConfirm extends React.Component<IOBTConfirm, State> {

    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        { name: "type", type: toEnumType(Type), optional: true, default: 'default', description: 'type에 따라 해당되는 아이콘이 뜹니다.' },
        { name: 'title', type: 'node', description: '타이틀 값을 지정한다.', optional: true },
        { name: 'labelText', type: 'node', description: '메세지 값을 지정한다.', optional: true },
        { name: 'confirmText', type: CommonType.string, default: '확인', optional: true, description: '확인 텍스트 값을 지정한다.' },
        { name: 'cancelText', type: CommonType.string, description: '취소 텍스트 값을 지정한다.', default: '취소', optional: true },
        {
            name: 'onConfirm', type: CommonType.function, parameters: {
                name: "e",
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                }
            },
            optional: true,
            description: '컴포넌트에서 확인 버튼 클릭, enter 입력 이벤트 발생 시 발생하는 Callback 함수입니다.'
        },
        {
            name: 'onCancel', type: CommonType.function, parameters: {
                name: "e",
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                }
            },
            optional: true,
            description: '컴포넌트에서 취소 버튼 클릭, esc 입력, 배경클릭 이벤트 발생 시 발생하는 Callback 함수입니다.'
        },
        {
            name: 'strictConfirmCheck',
            type: CommonType.boolean,
            default: false,
            optional: true,
            description: '좀 더 엄격하게 onConfirm의 close 여부를 체크합니다.\nOBTConfirm의 onConfirm 속성이 지정되지 않았고, button의 isConfirm 속성이 false일 때 OBTConfirm이 close되지 않습니다.'
        },
        {
            name: 'strictCancelCheck',
            type: CommonType.boolean,
            default: false,
            optional: true,
            description: '좀 더 엄격하게 onConfirm의 close 여부를 체크합니다.\nOBTConfirm의 onConfirm 속성이 지정되지 않았고, button의 isClose 속성이 false일 때 OBTConfirm이 close되지 않습니다.'
        },
        {
            name: 'buttons', type: ['Array<IButton>'], optional: true, description: "Confirm의 하단의 버튼을 설정합니다.\n"
                + "\n * IButton"
                + "\n- key : 유일한 고유키값 (string | number)\n"
                + "\n- onClick: 해당 버튼의 클릭 이벤트를 지정할 수 있습니다. function(target,event,type)\n"
                + "\n- labelText: 해당 버튼의 이름을 지정할 수 있습니다. (string)\n"
                + "\n- type : 해당 버튼의 타입을 지정할 수 있습니다. (big | blue | skyBlue | drawer | drawerImportant)\n"
                + "\n- theme : 해당 버튼의 테마를 지정할 수 있습니다. (default | blue | skyblue)\n "
                + "\n- visible : false로 지정한 버튼은 숨겨집니다. (boolean)\n"
                + "\n- isClose : ESC, X버튼 클릭시 isClose : true인 항목의 onClick이 호출되며 컨펌창이 닫히게 됩니다. (boolean)"
                + "\n(주의! isClose속성 사용시 OBTConfirm 컴포넌트 내부의 onCancel이벤트는 지정하지 마십시오. 중복 지정시 onCancel이벤트가 우선됩니다.) \n"
                + "\n- isConfirm : Enter 입력시 isConfirm : true인 항목의 onClick이 호출되며 컨펌창이 닫히게 됩니다. (boolean)"
                + "\n(주의! isConfirm속성 사용시 OBTConfirm 컴포넌트 내부의 onConfirm이벤트는 지정하지 마십시오. 중복 지정시 onConfirm이벤트가 우선됩니다.) "
        }
    );

    ///////////////////////////////////////////////////////////////////////////// Initialize
    public static Type = Type;
    /**
     * Default Props 설정
     */
    public static defaultProps = {
        type: OBTConfirm.Type.default,
        cancelText: '취소',
        confirmText: '확인',
        strictConfirmCheck: false,
        strictCancelCheck: false,
    };

    /**
     * State 정의
     */
    public state: State = {
        open: true
    };

    public myRefs = {
        ref: React.createRef<HTMLDivElement>()
    };

    private isKeyDownEventTriggered = false;

    ///////////////////////////////////////////////////////////////////////////// Life Cycle API
    componentDidMount() {
        try {
            if (this.myRefs.ref.current) {
                this.myRefs.ref.current.focus();
            }
        } catch (e) {
            Util.handleError(this, e);
        }
    }

    // component 가 render 될때 호출됨.
    render() {
        return (
            <OBTContext.Consumer>
                {
                    value => {
                        return <OBTPortal>
                            <ErrorBoundary
                                owner={this}
                                render={this.renderComponent}
                            />
                        </OBTPortal>;
                    }
                }
            </OBTContext.Consumer>
        );
    }

    renderComponent = () => {
        let {
            type,
            title,
            labelText,
            confirmText,
            cancelText
        } = this.props;

        if (cancelText === '취소') {
            cancelText = OrbitInternalLangPack.getText('WE000001945', '취소');
        }

        if (confirmText === '확인') {
            confirmText = OrbitInternalLangPack.getText('WE000000054', '확인');
        }

        let propButtons = this.props.buttons;
        const propButtonsLength = propButtons ? propButtons.length : 0;
        let visibleCount = 0;
        // visible 설정된 button의 개수를 셈
        if (propButtons) {
            visibleCount = propButtons.filter((button) => {
                if (button.visible === false) {
                    return true;
                }
                return false;
            }).length;
        }
        let buttons;
        // prop으로 받은 버튼값들을 처리해 줌. props.buttons 개수 - visibleCount 가 1 일때 테마를 blue로 해줌
        if (propButtons) {
            buttons = propButtons.map((button) => {
                return button.visible === false ? null :
                    <OBTButton
                        key={button.key}
                        theme={button.theme ? button.theme : propButtonsLength - visibleCount === 1 ? OBTButton.Theme.blue : OBTButton.Theme.default}
                        labelText={button.labelText} onClick={(e) => { if (button.onClick) this.handleClick(button.onClick, e, 'button'); }}
                        type={button.type ? button.type : OBTButton.Type.big} />;
            });
        }

        // icon
        let iconElement;
        if (type !== OBTConfirm.Type.default) {
            if (type === OBTConfirm.Type.success) {
                iconElement = <img className={styles.confirmIconStyle} src={IconAlertApproval} alt="" />;
            } else if (type === OBTConfirm.Type.warning) {
                iconElement = <img className={styles.confirmIconStyle} src={IconAlertWarning} alt="" />;
            } else if (type === OBTConfirm.Type.error) {
                iconElement = <img className={styles.confirmIconStyle} src={IconAlertError} alt="" />;
            } else if (type === OBTConfirm.Type.question) {
                iconElement = <img className={styles.confirmIconStyle} src={IconAlertQuest} alt="" />;
            }
        }

        // title
        let titleElement = title ? <div className={styles.confirmTitleStyle}>{title}</div> : undefined;

        // message
        let messageElement = labelText ? <div className={styles.confirmMessageStyle}>{labelText}</div> : undefined;

        return (
            <div ref={this.myRefs.ref}
                className={Util.getClassNames(styles.root, this.state.open ? undefined : styles.closed, this.props.className)}
                tabIndex={-1}
                onKeyDown={() => {
                    this.isKeyDownEventTriggered = true;
                }}
                onKeyUp={this.handleOnKeyUp}
                id={this.props.id}
                data-orbit-component={'OBTConfirm'}>
                {this.state.open ?
                    <Fade duration={300}><div className={styles.dimmed} /></Fade> : null}
                <div className={styles.wrapper}>
                    {this.state.open ?
                        <Bounce duration={300}>
                            <div className={styles.confirmBoxStyle}>
                                {/* 아이콘 영역 */}
                                {iconElement}

                                {/* 타이틀 영역 */}
                                {titleElement}

                                {/* 메세지 영역 */}
                                {messageElement}

                                {/* 버튼 영역 */}
                                <div className={styles.buttonsDiv}>
                                    {buttons ? buttons :
                                        <>
                                            <OBTButton className={styles.confirmButton} labelText={cancelText} type={OBTButton.Type.big} onClick={this.onCancel.bind(this)} />
                                            <OBTButton className={styles.confirmButton} labelText={confirmText} type={OBTButton.Type.big} theme={OBTButton.Theme.blue} onClick={this.onConfirm.bind(this)} />
                                        </>
                                    }
                                </div>
                            </div>
                        </Bounce> : null}
                    {/* 바탕 부분 */}
                </div>
            </div>);
    };

    private onConfirm(): void {
        const invokeConfirm = () => {
            if (this.props.buttons && !this.props.onConfirm) {
                this.props.buttons.filter((button) => {
                    return button.isConfirm === true;
                }).forEach((button) => {
                    if (button.onClick) {
                        button.onClick(null);
                    }
                });
            }

            Util.invokeEvent<Events.EventArgs>(this.props.onConfirm, new Events.EventArgs(this));
        };

        if (!this.props.strictConfirmCheck) {
            this.setState({ open: false }, () => invokeConfirm());
        } else {
            invokeConfirm();
        }

    }

    private onCancel(): void {
        const invokeCancel = () => {
            if (this.props.buttons && !this.props.onCancel) {
                this.props.buttons.filter((button) => {
                    return button.isClose === true;
                }).forEach((button) => {
                    if (button.onClick) {
                        button.onClick(null);
                    }
                });
            }

            Util.invokeEvent<Events.EventArgs>(this.props.onCancel, new Events.EventArgs(this));
        };

        if (!this.props.strictCancelCheck) {
            this.setState({ open: false }, () => invokeCancel());
        } else {
            invokeCancel();
        }
    }

    handleOnKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (this.isKeyDownEventTriggered === false) {
            return;
        }
        this.isKeyDownEventTriggered = false;

        if (this.state.open) {
            switch (event.keyCode) {
                case 9:  // tab
                    event.preventDefault();
                    break;
                case 13: // enter
                    this.onConfirm();
                    break;
                case 27: // esc
                    this.onCancel();
                    break;
            }
            event.stopPropagation();
        }
    };

    private handleClick = (onClick: any, event: any, type: string): void => {
        Util.invokeEvent<MouseEventArgs>(onClick, new MouseEventArgs(this, event, type));
    };
};
