/**
 * OBTTab
 * @version 0.1
 * @author 전주빈
 * @see common.js
 */
import * as React from 'react';
import { Events, Util, CommonProps, CompositeProps, createPropDefinitions, CommonDefinitions, CommonType } from '../Common';
import { LUXTab } from 'luna-rocket/LUXTabs'
import OBTTooltip, { IOBTTooltip } from '../OBTTooltip/OBTTooltip';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { hasError } from '../Common/CommonState';

interface IOBTTab extends CompositeProps.Default, CommonProps.labelText, CommonProps.disabled, CommonProps.value<string>, Events.onActivate {
    /**
    * 틀팁에 대한 설정 속성입니다.
    */
    tooltip?: IOBTTooltip,
    /**
     * 이미지 여부 속성입니다.
     */
    imageUrl?: any,
    parentId?: string
}

interface State extends hasError {
}

export default class OBTTab extends React.Component<IOBTTab, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.value({ type: CommonType.string, description: "상위 요소인 OBTTabs 에서 구분할 값을 할당합니다." }),
        CommonDefinitions.labelText(),
        { name: "imageUrl", type: CommonType.image, description: "컴포넌트 요소에 보여지는 내용의 아이콘을 지정합니다.", optional: true },
        CommonDefinitions.disabled(),
        CommonDefinitions.onActivate()
    )

    static muiName = 'Tab'; // LUXTab에서 자식 찾을때 필요

    public static defaultProps = {
        disabled: false
    }

    public myRefs = {
        ref: React.createRef<LUXTab>()
    }

    private handleActive = e => {
        Util.invokeEvent<Events.EventArgs>(this.props.onActivate, new Events.EventArgs(this));
    }

    renderComponent = () => {
        const props: any = {
            value: this.props.value,
            theme: 'border',
            width: this.props.width,
            height: this.props.height,
            label: this.props.labelText,
            disabled: this.props.disabled,
            onActive: this.handleActive,
            selected: this.props["selected"],
            onTouchTap: this.props["onTouchTap"],
            itemStyle: Object.assign({ fontFamily: 'inherit' }, this.props['itemStyle']),
            selectedStyle: Object.assign({}, this.props['selectedStyle']),
            showHoverStyle: true,
            hoverStyle: Object.assign({ borderBottomColor: this.props["selected"] === true ? this.props['hoverStyle'].borderBottomColor : 'transparent' }),
        }

        if (this.props.imageUrl !== undefined) {
            props.icon = <img src={this.props.imageUrl} style={{ marginRight: '5px', marginBottom: '2px' }} alt={''}></img>;
        }

        const Tab =
            <LUXTab
                {...props}
            >
            </LUXTab>
        return (
            <OBTTooltip  {...this.props.tooltip} width='100%' ref={this.myRefs.ref} overrideSize={false} rootProps={{
                id: this.props.id,
                'data-orbit-component': 'OBTTab',
                'data-obttab-parent-id': this.props.parentId
            }}>
                {Tab}
            </OBTTooltip>
        )
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        );
    }
};