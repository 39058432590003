const fixedPatternFormatter = (rawExtractor: (value?: string) => string, pattern: string, value?: string, options?: { withMasking?: boolean }) => {
    let raw = rawExtractor(value);
    const withMasking = options && options.withMasking === true;
    let index = 0;
    return pattern.split('').map((char) =>
        (char === '_' || char === '*') && index < raw.length ?
            (char === '*' && withMasking ? (index++ ? '*' : '*') : raw[index++])
            : (char === '*' ? '_' : char)).join('');
}

export interface IMaskPattern {
    rawExtractor: (value?: string) => string,
    formatter: (value?: string, options?: { withMasking?: boolean }) => string,
    validator: (value?: string) => boolean
}

export class biznumber implements IMaskPattern {
    separator = /-/

    rawExtractor(value?: string) {
        return value ? value.replace(/[^\d]/g, '').substr(0, 10) : '';
    }

    formatter(value?: string, options?: { withMasking?: boolean }) {
        return fixedPatternFormatter(this.rawExtractor, '___-__-_____', value, options);
    }

    validator(value?: string) {
        const raw = this.rawExtractor(value);
        if (raw.length === 10) {
            const digits = raw.split('').map((item) => parseInt(item));
            const multiply = [1, 3, 7, 1, 3, 7, 1, 3, 5];
            let checkSum = multiply.filter((item, index) => index <= 7).map((item, index) => item * digits[index]).reduce((accu, curr) => accu + curr, 0);
            let checkSum2 = '0' + String(multiply[8] * digits[8]);
            checkSum2 = checkSum2.substring(checkSum2.length - 2);
            checkSum += Number(checkSum2.charAt(0)) + Number(checkSum2.charAt(1));
            let checker = (10 - (checkSum % 10)) % 10;
            if (digits[9] === checker) return true;
        }
        return false;
    }
};

export class conumber implements IMaskPattern {
    separator = /-/

    rawExtractor(value?: string) {
        return value ? value.replace(/[^\d]/g, '').substr(0, 13) : '';
    }

    formatter(value?: string, options?: { withMasking?: boolean }) {
        return fixedPatternFormatter(this.rawExtractor, '______-_______', value, options);
    }

    validator(value?: string) {
        const raw = this.rawExtractor(value);
        if (raw.length === 13) {
            const digits = raw.split('').map((item) => parseInt(item));
            const multiply = [1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2];
            let checkSum = multiply.map((item, index) => item * digits[index]).reduce((accu, curr) => accu + curr, 0);
            let checker = (10 - (checkSum % 10)) % 10;
            if (digits[12] === checker) return true;
        }
        return false;
    }
};

export class resident implements IMaskPattern {
    separator = /-/

    rawExtractor(value?: string) {
        return value ? value.replace(/[^\d]/g, '').substr(0, 13) : '';
    }

    formatter(value?: string, options?: { withMasking?: boolean }) {
        return fixedPatternFormatter(this.rawExtractor, '______-_******', value, options);
    }

    validator(value?: string) {
        let raw = this.rawExtractor(value);
        if (!/[0-9]{6,6}[1234][0-9]{6,6}/.test(raw)) return false;
        const birthYear = ((Number(raw.charAt(6)) <= 2) ? '19' : '20') + raw.substr(0, 2);
        const birthDate = new Date(Number(birthYear), Number(raw.substr(2, 2)) - 1, Number(raw.substr(4, 2)));
        if (birthDate.getFullYear() % 100 !== Number(raw.substr(0, 2)) ||
            birthDate.getMonth() + 1 !== Number(raw.substr(2, 2)) ||
            birthDate.getDate() !== Number(raw.substr(4, 2))) return false;

        const digits = raw.split('').map(val => Number(val));
        const multipliers = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5];
        const summary = multipliers.map((multiplier, index) => multiplier * digits[index]).reduce((accu, curr) => accu + curr, 0);
        if ((11 - (summary % 11)) % 10 !== digits[12]) return false;
        return true;
    }
};

export class foreign implements IMaskPattern { //990101-5020063
    separator = /-/

    rawExtractor(value?: string) {
        return value ? value.replace(/[^\d]/g, '').substr(0, 13) : '';
    }

    formatter(value?: string, options?: { withMasking?: boolean }) {
        return value && value.length === 13 ? fixedPatternFormatter(this.rawExtractor, '______-_******', value, options) : (value || '');
    }

    validator(value?: string) {
        let raw = this.rawExtractor(value);
        if (!/[0-9]{6,6}[5678][0-9]{6,6}/.test(raw)) return false;
        const birthYear = ((Number(raw.charAt(6)) <= 6) ? '19' : '20') + raw.substr(0, 2); 
        const birthDate = new Date(Number(birthYear), Number(raw.substr(2, 2)) - 1, Number(raw.substr(4, 2)));
        if (birthDate.getFullYear() % 100 !== Number(raw.substr(0, 2)) ||
            birthDate.getMonth() + 1 !== Number(raw.substr(2, 2)) ||
            birthDate.getDate() !== Number(raw.substr(4, 2))) return false;
        const digits = raw.split('').map(val => Number(val)); 
        const multipliers = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5];
        const summary = multipliers.map((multiplier, index) => multiplier * digits[index]).reduce((accu, curr) => accu + curr, 0);
        if ((13 - (summary % 11)) % 10 !== digits[12]) return false;
        return true;
    }
};

export class passport implements IMaskPattern {
    rawExtractor(value?: string) {
        return value ? value.toUpperCase().substr(0, 18) : '';
    }

    formatter(value?: string, options?: { withMasking?: boolean }) {
        const raw = this.rawExtractor(value);
        const withMasking = options && options.withMasking === true;
        return withMasking ? raw.replace(/./g, '*') : raw;
    }

    validator(value?: string) {
        return true;
    }
};

export class driver implements IMaskPattern {
    separator = /-/

    rawExtractor(value?: string) {
        return value ? value.replace(/[^\d]/g, '').substr(0, 24) : '';
    }

    formatter(value?: string, options?: { withMasking?: boolean }) {
        const raw = this.rawExtractor(value);
        const withMasking = options && options.withMasking === true;
        let formatted = raw;
        if (raw.length === 12) {
            formatted = raw.substr(0, 2) + '-' + raw.substr(2, 2) + '-' + raw.substr(4, 6) + '-' + raw.substr(10);
        }
        return withMasking ? formatted.replace(/\d/g, '*') : formatted;
    }

    validator(value?: string) {
        const isFormatted = this.formatter(value).includes('-') ? true : false;
        return isFormatted;
    }
};

/**
 * 아맥스카드번호 3XXX XXXXXX XXXXX 타입 15자리
 * 
 */
export class credit implements IMaskPattern {
    private readonly AMEX_CARD_PATTERN = '____-******-_____';
    private readonly DEFAULT_CARD_PATTERN = '____-****-****-____';

    separator = /-/

    rawExtractor(value?: string) {
        if (!value) {
            return '';
        } else if (value.length === 16) {
            return value.replace(/[^\d]/g, '').substr(0, 16);
        } else if (value.length === 15) {
            return value.replace(/[^\d]/g, '').substr(0, 16);
        } else {
            return value.replace(/[^\d]/g, '').substr(0, 16);
        }
    }

    /**
     * 리얼그리드 마스크 컬럼에 사용될 마스크 스트링
     * @param referenceValue 
     */
    getMask(referenceValue: string) {
        if (referenceValue.length === 15) {
            return this.AMEX_CARD_PATTERN.replace('_', '0').replace('*', '0');
        } else {
            return this.DEFAULT_CARD_PATTERN.replace('_', '0').replace('*', '0');
        }
    }

    formatter(value?: string, options?: { withMasking?: boolean }) {
        // TODO 아맥스 카드번호 마스킹 패턴 확인
        const maskPattern = value && value.length === 15 ? this.AMEX_CARD_PATTERN : this.DEFAULT_CARD_PATTERN;

        return fixedPatternFormatter(
            this.rawExtractor,
            maskPattern,
            value,
            options
        );
    }

    validator(value?: string) {
        const length = this.rawExtractor(value).length;
        return length === 15 || length === 16;
    }
};

export class account implements IMaskPattern {
    separator = /-/

    rawExtractor(value?: string) {
        return value ? value.replace(/[^\d]/g, '').substr(0, 28) : '';
    }

    formatter(value?: string, options?: { withMasking?: boolean }) {
        const raw = this.rawExtractor(value);
        const withMasking = options && options.withMasking === true;
        return withMasking ? raw.replace(/\d/g, '*') : raw;
    }

    validator(value?: string) {
        return true;
    }
};

export class tel implements IMaskPattern {
    separator = /-/

    private static patterns = [
        /^(\d{4,4})$/,
        /^(\d{3,3})(\d{4,4})$/,
        /^(\d{4,4})(\d{4,4})$/,
        /^(\d{2,2})(\d{3,3})(\d{4,4})$/,
        /^(\d{2,2})(\d{4,4})(\d{4,4})$/,
        /^(\d{3,3})(\d{4,4})(\d{4,4})$/,
        /^(\d{4,4})(\d{4,4})(\d{4,4})$/,
        /^(\+\d{2,2})(\d{2,2})(\d{3,3})(\d{4,4})$/,
        /^(\+\d{2,2})(\d{2,2})(\d{4,4})(\d{4,4})$/
    ];

    private static maskPatterns = [
        '****',
        '***-____',
        '****-____',
        '__-***-____',
        '__-****-____',
        '___-****-____',
        '____-****-____',
        '___-__-***-____',
        '___-__-****-____'
    ]

    private static ThreedLengthTelCodeList = [
        //'02',
        '051',
        '053',
        '032',
        '062',
        '042',
        '052',
        '044',
        '031',
        '033',
        '043',
        '041',
        '063',
        '061',
        '054',
        '055',
        '064',

        '010', '011', '016', '017', '019',

        '070'
    ]

    rawExtractor(value?: string) {
        if (value && value.startsWith('+'))
            return value.substr(0, 1) + value.replace(/[^\d]/g, '')
        else return value ? value.replace(/[^\d]/g, '').substr(0, 24) : '';
    }

    localNumberFormatter(raw: string, withMasking?: boolean) {
        const valueCheckReg = /^(\d{3,3})(\d{3,3})(\d{4,4})$/;
        const valueCheckPattern = '___-***-____';

        const result = valueCheckReg.exec(raw);
        if (!result || result.length === 0) {
            return raw;
        }

        const formatted = result.filter((v, i) => i > 0).join('-');
        if (withMasking) {
            return valueCheckPattern.split('').map((maskChar, charIndex) => maskChar === '*' ? '*' : formatted[charIndex]).join('');
        }

        return formatted;
    }

    formatter(value?: string, options?: { withMasking?: boolean }) {
        const raw = this.rawExtractor(value);
        const withMasking = options && options.withMasking === true;

        // 예외 상황
        if (raw && raw.length === 10 && tel.ThreedLengthTelCodeList.includes(raw.substr(0, 3))) {
            return this.localNumberFormatter(raw, withMasking);
        }

        const formatted = tel.patterns.map((pattern, index) => {
            const result = pattern.exec(raw);
            if (result && result.length > 0) {
                const formatted = result.filter((v, i) => i > 0).join('-');
                if (withMasking && index < tel.maskPatterns.length) {
                    return tel.maskPatterns[index].split('').map((maskChar, charIndex) => maskChar === '*' ? '*' : formatted[charIndex]).join('');
                }
                return formatted;
            }
            return null;
        }).find(value => value);
        return (formatted ? formatted : raw);
    }

    validator(value?: string) {
        const isFormatted = this.formatter(value).includes('-') ? true : false;
        return isFormatted && this.rawExtractor(value).length > 8;
    }
};

export class cellular implements IMaskPattern {
    separator = /-/

    private static patterns = [
        /^(\d{3,3})(\d{4,4})(\d{4,4})$/,
    ];

    private static maskPatterns = [
        '___-****-____',
    ]

    private static phoneNumberStartCodeList = [
        '010', '011', '016', '017', '019'
    ]

    rawExtractor(value?: string) {
        return value ? value.replace(/[^\d]/g, '').substr(0, 11) : '';
    }

    getOldVerPhoneNumberFormat(raw: string, withMasking?: boolean) {
        const valueCheckReg = /^(\d{3,3})(\d{3,3})(\d{4,4})$/;
        const valueCheckPattern = '___-***-____';

        const result = valueCheckReg.exec(raw);
        if (!result || result.length === 0) {
            return raw;
        }

        const formatted = result.filter((v, i) => i > 0).join('-');
        if (withMasking) {
            return valueCheckPattern.split('').map((maskChar, charIndex) => maskChar === '*' ? '*' : formatted[charIndex]).join('');
        }

        return formatted;
    }

    formatter(value?: string, options?: { withMasking?: boolean }) {
        const raw = this.rawExtractor(value);
        const withMasking = options && options.withMasking === true;

        // 옛날식 폰번호일때 (011-000-0000) 
        if (raw && raw.length === 10 && cellular.phoneNumberStartCodeList.includes(raw.substr(0, 3))) {
            return this.getOldVerPhoneNumberFormat(raw, withMasking);
        }

        const formatted = cellular.patterns.map((pattern, index) => {
            const result = pattern.exec(raw);

            if (result && result.length > 0 && (raw.startsWith('+') || cellular.phoneNumberStartCodeList.includes(raw.substr(0, 3)))) {
                const formatted = result.filter((v, i) => { return i > 0 }).join('-')

                if (withMasking && index < cellular.maskPatterns.length) {
                    return cellular.maskPatterns[index].split('').map((maskChar, charIndex) => maskChar === '*' ? '*' : formatted[charIndex]).join('');
                }
                return formatted;
            }
            return null;
        }).find(value => { return value; });

        return (formatted ? formatted : raw);
    }

    validator(value?: string) {
        const isFormatted = this.formatter(value).includes('-') ? true : false;
        return isFormatted && this.rawExtractor(value).length > 9;
    }
}

export class landline implements IMaskPattern {
    separator = /-/

    private static patterns = [
        /^(\d{3,3})(\d{4,4})$/,
        /^(\d{4,4})(\d{4,4})$/,
        /^(\d{2,2})(\d{3,3})(\d{4,4})$/,
        /^(\d{2,2})(\d{4,4})(\d{4,4})$/,
        /^(\d{3,3})(\d{4,4})(\d{4,4})$/,
    ];

    private static maskPatterns = [
        '***-____',
        '****-____',
        '__-***-____',
        '__-****-____',
        '___-****-____',
    ]

    private static localNumberStartCodeList = [
        '02',
        '051',
        '053',
        '032',
        '062',
        '042',
        '052',
        '044',
        '031',
        '033',
        '043',
        '041',
        '063',
        '061',
        '054',
        '055',
        '064',
        '070'
    ]

    rawExtractor(value?: string) {
        return value ? value.replace(/[^\d]/g, '').substr(0, 11) : '';
    }

    localNumberFormatter(raw: string, withMasking?: boolean) {
        const valueCheckReg = /^(\d{3,3})(\d{3,3})(\d{4,4})$/;
        const valueCheckPattern = '___-***-____';

        const result = valueCheckReg.exec(raw);
        if (!result || result.length === 0) {
            return raw;
        }

        const formatted = result.filter((v, i) => i > 0).join('-');
        if (withMasking) {
            return valueCheckPattern.split('').map((maskChar, charIndex) => maskChar === '*' ? '*' : formatted[charIndex]).join('');
        }

        return formatted;
    }

    formatter(value?: string, options?: { withMasking?: boolean }) {
        const raw = this.rawExtractor(value);
        const withMasking = options && options.withMasking === true;
        const cellularPhoneStartCodeList = ['010', '011', '016', '017', '019']

        // 번호 가운데가 세자리일때
        if (raw && raw.length === 10 && landline.localNumberStartCodeList.includes(raw.substr(0, 3))) {
            return this.localNumberFormatter(raw, withMasking);
        }

        const formatted = landline.patterns.map((pattern, index) => {
            const result = pattern.exec(raw);
            if (result && result.length > 0 && !cellularPhoneStartCodeList.includes(raw.substr(0, 3))) {
                const formatted = result.filter((v, i) => i > 0).join('-');
                if (withMasking && index < landline.maskPatterns.length) {
                    return landline.maskPatterns[index].split('').map((maskChar, charIndex) => maskChar === '*' ? '*' : formatted[charIndex]).join('');
                }
                return formatted;
            }
            return null;
        }).find(value => value);
        return (formatted ? formatted : raw);
    }

    validator(value?: string) {
        const isFormatted = this.formatter(value).includes('-') ? true : false;
        return isFormatted && this.rawExtractor(value).length > 8;
    }
};

export class email implements IMaskPattern {
    rawExtractor(value?: string) {
        return value || '';
    }

    formatter(value?: string, options?: { withMasking?: boolean }) {
        const raw = this.rawExtractor(value);
        const withMasking = options && options.withMasking === true;
        return withMasking ? raw.replace(/[^@.]/g, '*') : raw;
    }

    validator(value?: string) {
        const raw = this.rawExtractor(value);
        if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(raw))
            return true;
        return false;
    }
}