/* eslint-disable eqeqeq */
import globalObj from 'luna-rocket/locale/intlGlobals';

// 종류(오전/오후) 체크
export function convertTimeType(hours) {
    let convertType = globalObj.intl.messages["luna.rocket.morning"];
    if (hours >= 12) {
        convertType = globalObj.intl.messages["luna.rocket.afternoon"];
    }

    return convertType;
}

// 시간 체크
export function convertTimeHour(hours) {
    if (hours === 0 || hours === 12) {
        return '12';
    } else if (hours === 1 || hours === 13) {
        return '01';
    } else if (hours === 2 || hours === 14) {
        return '02';
    } else if (hours === 3 || hours === 15) {
        return '03';
    } else if (hours === 4 || hours === 16) {
        return '04';
    } else if (hours === 5 || hours === 17) {
        return '05';
    } else if (hours === 6 || hours === 18) {
        return '06';
    } else if (hours === 7 || hours === 19) {
        return '07';
    } else if (hours === 8 || hours === 20) {
        return '08';
    } else if (hours === 9 || hours === 21) {
        return '09';
    } else if (hours === 10 || hours === 22) {
        return '10';
    } else if (hours === 11 || hours === 23) {
        return '11';
    }
}

// 분 체크
export function convertTimeMinute(minute) {
    if (minute >= 0 && minute < 10) {
        let tmpMinutes = minute.toString();
        return '0' + tmpMinutes;
    } else {
        let tmpMinutes = minute.toString();
        return tmpMinutes;
    }
}

// Output 데이터 변환
export function convertOutputData(type, hours, minutes) {
    let output = '';

    if (type == globalObj.intl.messages["luna.rocket.morning"]) {
        if (hours == '12') {
            let convertHours = '00';
            output = convertHours + minutes;
        } else {
            output = hours + minutes;
        }
    } else if (type == globalObj.intl.messages["luna.rocket.afternoon"]) {
        if (hours == '01') {
            let convertHours = '13';
            output = convertHours + minutes;
        } else if (hours == '02') {
            let convertHours = '14';
            output = convertHours + minutes;
        } else if (hours == '03') {
            let convertHours = '15';
            output = convertHours + minutes;
        } else if (hours == '04') {
            let convertHours = '16';
            output = convertHours + minutes;
        } else if (hours == '05') {
            let convertHours = '17';
            output = convertHours + minutes;
        } else if (hours == '06') {
            let convertHours = '18';
            output = convertHours + minutes;
        } else if (hours == '07') {
            let convertHours = '19';
            output = convertHours + minutes;
        } else if (hours == '08') {
            let convertHours = '20';
            output = convertHours + minutes;
        } else if (hours == '09') {
            let convertHours = '21';
            output = convertHours + minutes;
        } else if (hours == '10') {
            let convertHours = '22';
            output = convertHours + minutes;
        } else if (hours == '11') {
            let convertHours = '23';
            output = convertHours + minutes;
        } else if (hours == '12') {
            output = hours + minutes;
        }
    }

    return output;
}

// 종류, 시간, 분으로 index 체크
export function convertListIndex(type, hours, minutes) {
    if (!hours || minutes === undefined || minutes === null) return 1;

    if (hours.substring(0, 1) === '0') {
        hours = Number(hours.substring(1, 3));
    } else {
        hours = Number(hours);
    }

    if (minutes.substring(0, 1) === '0') {
        minutes = Number(minutes.substring(1, 3));
    } else {
        minutes = Number(minutes);
    }

    if (type == globalObj.intl.messages["luna.rocket.morning"]) {
        if (hours == 12) {
            if (minutes == 0) return 1;
            else if (minutes > 0 && minutes <= 30) return 2;
            else if (minutes >= 31 && minutes <= 59) return 3;
        } else if (hours == 1) {
            if (minutes == 0) return 3;
            else if (minutes > 0 && minutes <= 30) return 4;
            else if (minutes >= 31 && minutes <= 59) return 5;
        } else if (hours == 2) {
            if (minutes == 0) return 5;
            else if (minutes > 0 && minutes <= 30) return 6;
            else if (minutes >= 31 && minutes <= 59) return 7;
        } else if (hours == 3) {
            if (minutes == 0) return 7;
            else if (minutes > 0 && minutes <= 30) return 8;
            else if (minutes >= 31 && minutes <= 59) return 9;
        } else if (hours == 4) {
            if (minutes == 0) return 9;
            else if (minutes > 0 && minutes <= 30) return 10;
            else if (minutes >= 31 && minutes <= 59) return 11;
        } else if (hours == 5) {
            if (minutes == 0) return 11;
            else if (minutes > 0 && minutes <= 30) return 12;
            else if (minutes >= 31 && minutes <= 59) return 13;
        } else if (hours == 6) {
            if (minutes == 0) return 13;
            else if (minutes > 0 && minutes <= 30) return 14;
            else if (minutes >= 31 && minutes <= 59) return 15;
        } else if (hours == 7) {
            if (minutes == 0) return 15;
            else if (minutes > 0 && minutes <= 30) return 16;
            else if (minutes >= 31 && minutes <= 59) return 17;
        } else if (hours == 8) {
            if (minutes == 0) return 17;
            else if (minutes > 0 && minutes <= 30) return 18;
            else if (minutes >= 31 && minutes <= 59) return 19;
        } else if (hours == 9) {
            if (minutes == 0) return 19;
            else if (minutes > 0 && minutes <= 30) return 20;
            else if (minutes >= 31 && minutes <= 59) return 21;
        } else if (hours == 10) {
            if (minutes == 0) return 21;
            else if (minutes > 0 && minutes <= 30) return 22;
            else if (minutes >= 31 && minutes <= 59) return 23;
        } else if (hours == 11) {
            if (minutes == 0) return 23;
            else if (minutes > 0 && minutes <= 30) return 24;
            else if (minutes >= 31 && minutes <= 59) return 25;
        }
    } else {
        if (hours == 12) {
            if (minutes == 0) return 25;
            else if (minutes > 0 && minutes <= 30) return 26;
            else if (minutes >= 31 && minutes <= 59) return 27;
        } else if (hours == 1) {
            if (minutes == 0) return 27;
            else if (minutes > 0 && minutes <= 30) return 28;
            else if (minutes >= 31 && minutes <= 59) return 29;
        } else if (hours == 2) {
            if (minutes == 0) return 29;
            else if (minutes > 0 && minutes <= 30) return 30;
            else if (minutes >= 31 && minutes <= 59) return 31;
        } else if (hours == 3) {
            if (minutes == 0) return 31;
            else if (minutes > 0 && minutes <= 30) return 32;
            else if (minutes >= 31 && minutes <= 59) return 33;
        } else if (hours == 4) {
            if (minutes == 0) return 33;
            else if (minutes > 0 && minutes <= 30) return 34;
            else if (minutes >= 31 && minutes <= 59) return 35;
        } else if (hours == 5) {
            if (minutes == 0) return 35;
            else if (minutes > 0 && minutes <= 30) return 36;
            else if (minutes >= 31 && minutes <= 59) return 37;
        } else if (hours == 6) {
            if (minutes == 0) return 37;
            else if (minutes > 0 && minutes <= 30) return 38;
            else if (minutes >= 31 && minutes <= 59) return 39;
        } else if (hours == 7) {
            if (minutes == 0) return 39;
            else if (minutes > 0 && minutes <= 30) return 40;
            else if (minutes >= 31 && minutes <= 59) return 41;
        } else if (hours == 8) {
            if (minutes == 0) return 41;
            else if (minutes > 0 && minutes <= 30) return 42;
            else if (minutes >= 31 && minutes <= 59) return 43;
        } else if (hours == 9) {
            if (minutes == 0) return 43;
            else if (minutes > 0 && minutes <= 30) return 44;
            else if (minutes >= 31 && minutes <= 59) return 45;
        } else if (hours == 10) {
            if (minutes == 0) return 45;
            else if (minutes > 0 && minutes <= 30) return 46;
            else if (minutes >= 31 && minutes <= 59) return 47;
        } else if (hours == 11) {
            if (minutes == 0) return 47;
            else if (minutes > 0 && minutes <= 30) return 48;
            else if (minutes >= 31 && minutes <= 59) return 1;
        }
    }
}

// index로 시간 체크
export function getIndexHours(index) {
    if (index == 1 || index == 2 || index == 25 || index == 26) {
        return '12';
    } else if (index == 3 || index == 4 || index == 27 || index == 28) {
        return '01';
    } else if (index == 5 || index == 6 || index == 29 || index == 30) {
        return '02';
    } else if (index == 7 || index == 8 || index == 31 || index == 32) {
        return '03';
    } else if (index == 9 || index == 10 || index == 33 || index == 34) {
        return '04';
    } else if (index == 11 || index == 12 || index == 35 || index == 36) {
        return '05';
    } else if (index == 13 || index == 14 || index == 37 || index == 38) {
        return '06';
    } else if (index == 15 || index == 16 || index == 39 || index == 40) {
        return '07';
    } else if (index == 17 || index == 18 || index == 41 || index == 42) {
        return '08';
    } else if (index == 19 || index == 20 || index == 43 || index == 44) {
        return '09';
    } else if (index == 21 || index == 22 || index == 45 || index == 46) {
        return '10';
    } else if (index == 23 || index == 24 || index == 47 || index == 48) {
        return '11';
    }
}
