import React from 'react';
import * as Events from './Events';
import mapChildren from './_Common';
import * as OrbitComponent from '../index';
import ajaxEbp from './ajax-ebp';
import moment from 'moment';
import uuid from 'uuid/v1';
import { hasError } from './CommonState';
import Cancellable from './Cancellable';
import { Privacy } from '.';
import { setComponentLocale } from 'luna-rocket/locale/intlGlobals';
import Cookies from 'js-cookie';
import axios from 'axios';

const styles = require('./_Common.scss');
const commonData = require('./Common.json');

enum DateOption {
    default,
    monthFirstDay,
    monthLastDay,
    yearFirstDay,
    yearLastDay,
    yyyyMMdd,
    yyyyMMddHHMMSS,
    ISO,
    simpleDate,
    simpleDateTime,
    shortDate,
}

enum ShortCut {
    'CodePicker' = 'CodePicker',
    'Delete' = 'Delete',
    'Search' = 'Search',
    'Print' = 'Print',
    'GuideMessage' = 'GuideMessage'
}

class Version {
    private versions: number[] = [];

    constructor(versionString: string) {
        if (versionString) {
            this.versions = versionString.split('.').map(version => Number(version));
        }
    }

    public compare(version: Version) {
        for (let i = 0; i < Math.max(this.versions.length, version.versions.length); i++) {
            const thisVersion = i < this.versions.length ? this.versions[i] : 0;
            const checkVersion = i < version.versions.length ? version.versions[i] : 0;
            if (thisVersion !== checkVersion) {
                return checkVersion - thisVersion;
            }
        }
        return 0;
    }
}

export class Fetch {
    public ajaxEbp: any = null;
    public cancellable: Cancellable | undefined;
    public static debugMode: boolean = false;

    constructor(ajaxEbp: any, cancellable?: Cancellable) {
        this.ajaxEbp = ajaxEbp;
        this.cancellable = cancellable;
    }

    private useDebugger = (url: string, args?: any, options?: any, invoke?: (url: string, args?: any, options?: any) => Promise<any>) => {
        if (Fetch.debugMode === true) {
            options = {
                ...options,
                debugMode: true
            };
        }
        return new Promise<any>((resolve, reject) => {
            if (invoke) {
                invoke(url, args, options).then((data: any) => {
                    try {
                        if (Fetch.debugMode === true && window.opener) {
                            if (data && typeof data === 'object' && data.logs) {
                                window.opener.postMessage({
                                    identifier: 'amaranth10-debugger',
                                    command: 'log',
                                    url: url,
                                    logs: data.logs
                                }, '*');
                            }
                        }
                    } catch (error) { /* ignore error */ }
                    resolve(data);
                }).catch((error: any) => {
                    try {
                        if (Fetch.debugMode === true && window.opener) {
                            if (error && typeof error === 'object' && error.logs) {
                                window.opener.postMessage({
                                    identifier: 'amaranth10-debugger',
                                    command: 'log',
                                    url: url,
                                    logs: error.logs
                                }, '*');
                            }
                        }
                    } catch (error) { /* ignore error */ }
                    reject(error);
                })
            } else {
                reject();
            }
        })
    }

    public get = (url: string, args?: any, options?: any): Promise<any> => {
        return this.useDebugger(url, args, options, (url: string, args?: any, options?: any) => {
            if (args && typeof args === 'object') {
                const params = Object.keys(args).map((key) => {
                    return `${encodeURIComponent(key)}=${args[key] ? encodeURIComponent(args[key]) : ''}`;
                }).join('&');
                if (params && params.length > 0) {
                    url = url + (url.indexOf('?') < 0 ? '?' : '') + params;
                }
            }
            return this.cancellable ? new Promise((resolve, reject) => {
                this.ajaxEbp.get(url, options)
                    .then((data: any) => {
                        if (!(this.cancellable && this.cancellable.hasCancelled)) resolve(data);
                    })
                    .catch((error: any) => {
                        if (!(this.cancellable && this.cancellable.hasCancelled)) reject(error);
                    })
            }) : this.ajaxEbp.get(url, options);
        });
    };

    public post = (url: string, args?: any, options?: any): Promise<any> => {
        return this.useDebugger(url, args, options, (url: string, args?: any, options?: any) => {
            return this.cancellable ? new Promise((resolve, reject) => {
                this.ajaxEbp.post(url, args, options)
                    .then((data: any) => {
                        if (!(this.cancellable && this.cancellable.hasCancelled)) resolve(data);
                    })
                    .catch((error: any) => {
                        if (!(this.cancellable && this.cancellable.hasCancelled)) reject(error);
                    })
            }) : this.ajaxEbp.post(url, args, options);
        });
    };

    public put = (url: string, args?: any, options?: any): Promise<any> => {
        return this.useDebugger(url, args, options, (url: string, args?: any, options?: any) => {
            return this.cancellable ? new Promise((resolve, reject) => {
                this.ajaxEbp.put(url, args, options)
                    .then((data: any) => {
                        if (!(this.cancellable && this.cancellable.hasCancelled)) resolve(data);
                    })
                    .catch((error: any) => {
                        if (!(this.cancellable && this.cancellable.hasCancelled)) reject(error);
                    })
            }) : this.ajaxEbp.put(url, args, options);
        });
    };

    public delete = (url: string, args?: any, options?: any): Promise<any> => {
        return this.useDebugger(url, args, options, (url: string, args?: any, options?: any) => {
            return this.cancellable ? new Promise((resolve, reject) => {
                this.ajaxEbp.delete(url, args, options)
                    .then((data: any) => {
                        if (!(this.cancellable && this.cancellable.hasCancelled)) resolve(data);
                    })
                    .catch((error: any) => {
                        if (!(this.cancellable && this.cancellable.hasCancelled)) reject(error);
                    })
            }) : this.ajaxEbp.delete(url, args, options);
        });
    };

    public clone() {
        const cancellable = new Cancellable();
        return { fetch: new Fetch(this.ajaxEbp, cancellable), cancellable: cancellable };
    }
}

/**
 * @internal
 * klago-ui-common LangPack.getText 대체용으로 컴포넌트 내부에서만 사용됨
 */
export const OrbitInternalLangPack = {
    getText: (textCode: string, dfText: string, langCode?: string) => {
        if (!window["bizcubeCommon"] || !window["bizcubeCommon"]["langPackMap"]) {
            return dfText;
        }

        const langPackMap = window["bizcubeCommon"]["langPackMap"];
        const langText =
            langPackMap['kr'][textCode] == null
                ? dfText
                : langPackMap['kr'][textCode];

        let defaultLangCode = 'kr';

        if (langCode) {
            defaultLangCode = langCode;
        } else {
            const userInfoStr = sessionStorage.getItem('userInfo');
            if (userInfoStr) {
                const userInfo = JSON.parse(userInfoStr);
                if (userInfo.ucUserInfo && userInfo.ucUserInfo.langCode) {
                    defaultLangCode = userInfo.ucUserInfo.langCode;
                }
            }
        }

        switch (defaultLangCode.toUpperCase()) {
            case "EN":
                return langPackMap['en'][textCode] == null
                    ? langText
                    : langPackMap['en'][textCode];
            case "JP":
                return langPackMap['jp'][textCode] == null
                    ? langText
                    : langPackMap['jp'][textCode];
            case "CN":
                return langPackMap['cn'][textCode] == null
                    ? langText
                    : langPackMap['cn'][textCode];
            default:
                return langText;
        }

    }
};

// TODO
export function setLunaRocketLanguageFromSession() {
    try {
        let userInfoStr = sessionStorage.getItem('userInfo');
        if (userInfoStr) {
            let userInfo = JSON.parse(userInfoStr);
            if (userInfo && userInfo.ucUserInfo && userInfo.ucUserInfo.langCode && setComponentLocale) {
                let langCode = userInfo.ucUserInfo.langCode;
                if (langCode.toUpperCase() === 'JP') {
                    setComponentLocale('ja');
                }

                if (langCode.toUpperCase() === 'EN') {
                    setComponentLocale('en')
                }

                if (langCode.toUpperCase() === 'CN') {
                    // TODO: 중국어 처리
                }
            }
        }
    } catch (e) { }
}

export function getLangCodeFromSession(): string | null {
    try {
        let userInfoStr = sessionStorage.getItem('userInfo');
        if (userInfoStr) {
            let userInfo = JSON.parse(userInfoStr);
            if (userInfo && userInfo.ucUserInfo && userInfo.ucUserInfo.langCode) {
                let langCode = userInfo.ucUserInfo.langCode;
                return langCode;
            }
        }
    } catch (e) { }

    return null
}

export function devLog(...log: any[]) {
    try {
        if (window.location && window.location.hostname) {
            if (window.location.hostname === 'dev.bizcubex.co.kr' || window.location.hostname === 'dev.amaranth10.co.kr' || window.location.hostname === 'localhost') {
                console.log(...log)
            }
        }
    } catch (e) { }
}

export default class Util {
    static _CommonData = commonData

    /**
     * 이벤트 호출 지원 함수
     * args 에 cancel 이 지원되고, 호출 결과 cancel = true 로 설정되었다면 false 를 리턴함. 그 외 true 리턴
     * @param {Function} event 호출할 이벤트(=callback)
     * @param {EventArgs} args EventArgs 에서 파생된 class
     * @returns {boolean} cancel = true 라면 false, 그 외 true
     * {@code if (!invokeEvent(this.props.onBeforeChange, new CancellationEventArgs(this, this.state.value, e.target.value))) return;}
     * @see CancellationEventArgs
     */
    public static invokeEvent<T extends Events.EventArgs>(event?: (e: T) => void, args?: T): boolean {
        if (event && args) {
            event.call(null, args);
            if (args instanceof Events.CancelableEventArgs && (args as Events.CancelableEventArgs).cancel === true) {
                return false;
            }
        }
        return true;
    }

    public static getCommonClassName(name: string): string {
        return styles[name] || '';
    }

    /**
     * 클래스명을 지정할때 여러 클래스명을 연결하거나, null, '' 인 클래스명을 제외할 때 사용한다.
     * 조건에 따라 클래스명을 지정해야 할때 사용하면 효과적이다.
     * @param {string[]} classNames 연결할 클래스명. null이나 '' 빈값이 들어오면 제외된다.
     * @returns {string} 연결된 클래스 명
     * {@code
     *  const classNames = getClassNames(this.props.required ? styles.required : null, this.props.disabled ? styles.disabled : null);
     *  <div className={classNames} />}
     */
    public static getClassNames(...classNames: any[]): string {
        return classNames.filter(className => className && className.length > 0).join(' ');
    }

    public static getStyle(name: string): any {
        if (Util._CommonData.styles[name])
            return Util._CommonData.styles[name];
        return null;
    }

    public static getInputStateStyle(prop: { required?: boolean, readonly?: boolean, disabled?: boolean }): any {
        let style = {};
        if (prop.disabled === true) style = Object.assign(style, Util.getStyle('disabled'));
        else if (prop.readonly === true) style = Object.assign(style, Util.getStyle('readonly'));
        else if (prop.required === true) style = Object.assign(style, Util.getStyle('required'));
        return style;
    }

    public static getWrapperStyle(props: { frozen?: boolean, width?: string, height?: string }): any {
        let style: any = {};
        if (props.frozen === true) style = Object.assign(style, Util.getStyle('frozen'));
        if (props.width && props.width.length > 0) style.width = props.width;
        if (props.height && props.height.length > 0) style.height = props.height;
        return style;
    }

    public static mapChildren(children: any, callback: (child: any, index: number) => any): any {
        return mapChildren(children, callback);
    }

    public static isOrbitComponent(type: any) {
        return Object.values(OrbitComponent).find((orbitType) => {
            if (type === orbitType) return true;
            return false;
        }) ? true : false;
    }

    public static overrideHandler(handler: any | null | undefined, newHandler: any): any {
        if (handler) {
            return (...args: any[]) => {
                newHandler.call(null, ...args);
                handler.call(null, ...args);
            }
        } else {
            return newHandler;
        }
    }

    public static containsFocus(ref: React.RefObject<any>): boolean {
        const containsFocus = (element: Element | null): boolean => {
            if (element) {
                if (element === ref.current) return true;
                else if (element.parentElement) return containsFocus(element.parentElement);
            }
            return false;
        }
        return containsFocus(document.activeElement);
    }

    public static elementsFromPoint(x: number, y: number): Element[] {
        try {
            // chrome supports this (and future unprefixed IE/Edge hopefully)
            if (typeof (document).elementsFromPoint === "function")
                return Array.prototype.slice.call((document).elementsFromPoint(x, y) || []);
            // IE11/10 should support this
            if (typeof (document as any).msElementsFromPoint === "function") {
                return Array.prototype.slice.call((document as any).msElementsFromPoint(x, y) || []);
            }
        } catch (error) {
            console.error('util-elementsFromPoint', error);
        }

        return [];
    }

    public static getFetch(_ajaxEbp: any, cancellable?: Cancellable) {
        return new Fetch(_ajaxEbp, cancellable);
    }

    public static fetch = Util.getFetch(ajaxEbp);

    public static setDebugMode(debugMode: boolean) {
        Fetch.debugMode = debugMode;
    }

    public static getDebugMode() {
        return Fetch.debugMode;
    }

    public static DateOption = DateOption;

    public static getDateString(option: DateOption = DateOption.default, date: string | Date = new Date()): string {
        if (typeof date === 'string') {
            date = moment(date, 'YYYYMMDD').toDate();
        }
        switch (option) {
            case DateOption.default:
            case DateOption.yyyyMMdd:
                return moment(date).format('YYYYMMDD');
            case DateOption.monthFirstDay:
                return moment(date).date(1).format('YYYYMMDD');
            case DateOption.monthLastDay:
                return moment(date).date(1).add({ M: 1 }).subtract({ d: 1 }).format('YYYYMMDD');
            case DateOption.yearFirstDay:
                return moment(date).month(0).date(1).format('YYYYMMDD');
            case DateOption.yearLastDay:
                return moment(date).month(0).date(1).add({ y: 1 }).subtract({ d: 1 }).format('YYYYMMDD');
            case DateOption.yyyyMMddHHMMSS:
                return moment(date).format('YYYYMMDDHHmmss');
            case DateOption.ISO:
                return moment(date).toISOString();
            case DateOption.simpleDate:
                return moment(date).format('YYYY-MM-DD');
            case DateOption.simpleDateTime:
                return moment(date).format('YYYY-MM-DD HH:mm:ss');
            case DateOption.shortDate:
                return moment(date).format('YY-MM-DD');
        }
        return '';
    }

    public static getDomain(): string {
        let origin = window.location.origin;
        if (!origin) {
            origin = window.location.protocol + "//"
                + window.location.hostname
                + (window.location.port ? ':' + window.location.port : '');
        }
        return origin;
    }

    /** 2022/02/16 김철희
     * 에이전트 포트 변경 요청에 따라 두 포트 모두 지원하기 위해 아래와 같이 처리함.
     * 1. agentDomains 에 따라 순서대로 호출함
     * 2. axios 에러로 response 가 오지 않으면 Network 오류임
     * 3. response 가 오면 해당 인덱스를 로컬 스토리지에 저장하고 다음에 그 인덱스의 주소를 우선 호출함
     */
    public static agentDomains = [
        'https://127.0.0.1:8237/KlagoAgent',
        'https://127.0.0.1:8301/KlagoAgent'
    ];
    private static lastAgentDomainIndex = (() => {
        const lastAgentDomainIndex = localStorage.getItem('amaranth10-last-agent-domain-index');
        if (lastAgentDomainIndex && !isNaN(Number(lastAgentDomainIndex))) {
            const _lastAgentDomainIndex = Number(lastAgentDomainIndex);
            if (_lastAgentDomainIndex >= 0 && _lastAgentDomainIndex < Util.agentDomains.length) {
                return _lastAgentDomainIndex;
            }
        }
        return 0;
    })();
    private static setLastAgentDomainIndex = (agentDomain: string) => {
        const currentIndex = Util.agentDomains.indexOf(agentDomain);
        if (currentIndex !== Util.lastAgentDomainIndex) {
            localStorage.setItem('amaranth10-last-agent-domain-index', String(currentIndex));
            Util.lastAgentDomainIndex = currentIndex;
        }
    }

    private static _fetchAgent = {
        get: async (container: string, module: string, data?: string | object | null, transactionId?: string) => {
            const domain = Util.getDomain();
            const authToken = Cookies.get("oAuthToken");
            const signKey = Cookies.get("signKey");
            const dataString = data != null ? typeof data === 'string' ? data : JSON.stringify(data) : '';
            const agentDomains = Util.agentDomains.filter((domain, index) => index === Util.lastAgentDomainIndex).concat(
                Util.agentDomains.filter((domain, index) => index !== Util.lastAgentDomainIndex)
            );
            for (let i = 0; i < agentDomains.length; i++) {
                const agentDomain = agentDomains[i];
                const url = `${agentDomain}?container=${container}&module=${module}&data=${dataString}&domain=${domain}&authToken=${authToken}&signKey=${signKey}&transactionId=${transactionId || ''}`;
                try {
                    const res = await axios.get(url, {
                        withCredentials: false
                    });
                    if (res) {
                        Util.setLastAgentDomainIndex(agentDomain);
                        return res.data;
                    }
                } catch (error) {
                    if (error && (error as any).response) {
                        Util.setLastAgentDomainIndex(agentDomain);
                        throw error;
                    }
                }
            }
            throw new Error('Unknown Error');
            // return new Promise((resolve, reject) => {
            //     const domain = Util.getDomain();
            //     const authToken = Cookies.get("oAuthToken");
            //     const signKey = Cookies.get("signKey");
            //     const dataString = data != null ? typeof data === 'string' ? data : JSON.stringify(data) : '';
            //     const url = `${Util.agentDomain}?container=${container}&module=${module}&data=${dataString}&domain=${domain}&authToken=${authToken}&signKey=${signKey}&transactionId=${transactionId || ''}`;

            //     axios.get(url, {
            //         withCredentials: false
            //     }).then((res) => {
            //         resolve(res.data);
            //     }).catch((reason) => reject(reason));
            // });
        },
        post: async (container: string, module: string, data?: string | object | null, transactionId?: string) => {
            const domain = Util.getDomain();
            const authToken = Cookies.get("oAuthToken");
            const signKey = Cookies.get("signKey");
            const dataString = data != null ? typeof data === 'string' ? data : JSON.stringify(data) : '';
            const agentDomains = Util.agentDomains.filter((domain, index) => index === Util.lastAgentDomainIndex).concat(
                Util.agentDomains.filter((domain, index) => index !== Util.lastAgentDomainIndex)
            );
            for (let i = 0; i < agentDomains.length; i++) {
                const agentDomain = agentDomains[i];
                try {
                    const res = await axios.post(agentDomain, JSON.stringify({
                        container: container,
                        module: module,
                        data: dataString,
                        domain: domain,
                        authToken: authToken,
                        signKey: signKey,
                        transactionId: transactionId || ''
                    }), {
                        withCredentials: false
                    });
                    if (res) {
                        Util.setLastAgentDomainIndex(agentDomain);
                        return res.data;
                    }
                } catch (error) {
                    if (error && (error as any).response) {
                        Util.setLastAgentDomainIndex(agentDomain);
                        throw error;
                    }
                }
            }
            throw new Error('Unknown Error');
            // return new Promise((resolve, reject) => {
            //     const domain = Util.getDomain();
            //     const authToken = Cookies.get("oAuthToken");
            //     const signKey = Cookies.get("signKey");
            //     const dataString = data != null ? typeof data === 'string' ? data : JSON.stringify(data) : '';
            //     axios.post(Util.agentDomain, JSON.stringify({
            //         container: container,
            //         module: module,
            //         data: dataString,
            //         domain: domain,
            //         authToken: authToken,
            //         signKey: signKey,
            //         transactionId: transactionId || ''
            //     }), {
            //         withCredentials: false
            //     }).then((res) => resolve(res.data)).catch((reason) => reject(reason));
            // });
        },
    }

    public static fetchAgent = {
        get: (container: string, module: string, data?: string | object | null) => {
            return Util._fetchAgent.get(container, module, data);
        },
        post: (container: string, module: string, data?: string | object | null) => {
            return Util._fetchAgent.post(container, module, data);
        },
        transaction: (container: string, module: string, transactionId: string,
            data: string | object | null | undefined,
            onStarted: (data: string) => void | null | undefined,
            onStatusChanged: (data: string) => void | null | undefined) => {
            return new Promise<void>((resolve, reject) => {
                const getTransactionStatus = () => {
                    Util._fetchAgent.get(container, module, null, transactionId)
                        .then((data: any) => {
                            if (data && data.data) {
                                if (onStatusChanged) {
                                    onStatusChanged(data.data);
                                }
                                getTransactionStatus();
                            }
                            else {
                                resolve();
                            }
                        })
                        .catch((error) => {
                            reject(error);
                        })
                }

                Util._fetchAgent.post(container, module, data, transactionId)
                    .then((data: any) => {
                        if (onStarted) {
                            onStarted(data ? data.data : undefined);
                        }
                        getTransactionStatus();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        verify: async () => {
            const currentVersion = new Version('1.0.2');
            try {
                const version = await Util._fetchAgent.get('', '') as {
                    data: string
                };
                if (version && version.data) {
                    const agentVersion = new Version(JSON.parse(version.data).Version);
                    console.log(agentVersion, currentVersion, currentVersion.compare(agentVersion));
                    if (currentVersion.compare(agentVersion) >= 0) {
                        return 'success';
                    }
                    return 'update';
                } else {
                    return 'update';
                }
            } catch (error) {
                return 'notInstalled';
            }
        },
        downloadCenterVerify: async () => {
            const verify = await Util.fetchAgent.verify();
            switch (verify) {
                case 'notInstalled': return 0;
                case 'update': return 1;
                default:
                    return 2;
            }
        },
        version: async () => {
            const verify = await Util.fetchAgent.verify();
            switch (verify) {
                case 'success':
                    return true;
                default:
                    return false;
            }
        }
    }

    public static print(printInfo: any) {
        return new Promise<any>(async (resolve, reject) => {
            const formats = (() => {
                try {
                    const userInfo = (() => {
                        const content = sessionStorage.getItem('userInfo');
                        if (content) {
                            return JSON.parse(content);
                        }
                        return {};
                    })();
                    const erpUserInfo = userInfo.erpUserInfo || {};
                    const formats = {
                        format01: 0,
                        format02: erpUserInfo.sFormat02 || 0,
                        format03: erpUserInfo.sFormat03 || 0,
                        format04: erpUserInfo.sFormat04 || 0,
                        format05: erpUserInfo.sFormat05 || 0,
                        format06: erpUserInfo.sFormat06 || 0,
                        format07: erpUserInfo.sFormat07 || 0,
                        format08: erpUserInfo.sFormat08 || 0,
                        format10: erpUserInfo.sFormat10 || 0
                    };
                    return formats;
                } catch (error) {
                    /* ignore error */
                }
            })();
            const printInfoWithFormats = {
                ...printInfo,
                formats
            };
            if (printInfo && (printInfo.useSendMessage || printInfo.useSendMail)) {
                const { onSendMessage, onSendMail, ...otherPrintInfo } = printInfoWithFormats;
                const transactionId = uuid();
                Util.fetchAgent.transaction('system', 'Print', transactionId, otherPrintInfo,
                    () => { }, (data) => {
                        const request = data ? JSON.parse(data) : null;
                        if (request && request.command && request.pdf) {
                            const getPayload = (pdf: string) => {
                                return {
                                    base64: () => pdf,
                                    blob: () => {
                                        const byteCharacters = atob(pdf);
                                        const byteArrays: Uint8Array[] = [];

                                        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                                            const slice = byteCharacters.slice(offset, offset + 512);

                                            const byteNumbers = new Array(slice.length);
                                            for (let i = 0; i < slice.length; i++) {
                                                byteNumbers[i] = slice.charCodeAt(i);
                                            }

                                            const byteArray = new Uint8Array(byteNumbers);
                                            byteArrays.push(byteArray);
                                        }

                                        const blob = new Blob(byteArrays, { type: 'application/pdf' });
                                        return blob;
                                    },
                                    url: () => {
                                        return 'data:application/pdf;base64,' + pdf;
                                    }
                                }
                            };
                            switch (request.command) {
                                case 'sendMessage':
                                    Util._fetchAgent.post('system', 'Print', 'success', transactionId);
                                    if (onSendMessage) {
                                        onSendMessage(getPayload(request.pdf));
                                    }
                                    break;
                                case 'sendMail':
                                    Util._fetchAgent.post('system', 'Print', 'success', transactionId);
                                    if (onSendMail) {
                                        onSendMail(getPayload(request.pdf));
                                    }
                                    break;
                            }
                        }
                    })
                    .then((data) => resolve(data))
                    .catch((error) => reject(error));
            } else {
                Util.fetchAgent.post('system', 'Print', printInfoWithFormats)
                    .then((data) => resolve(data))
                    .catch((error) => reject(error));
            }
        })
    }

    public static getLang(): any {
        const langInit = {
            langCode: 'kr',
            language: 'kr',
        }

        const userInfoStr = sessionStorage.getItem('userInfo');
        if (userInfoStr) {
            const userInfo = JSON.parse(userInfoStr);
            if (userInfo.erpUserInfo && userInfo.erpUserInfo.language && userInfo.ucUserInfo && userInfo.ucUserInfo.langCode) {
                const lang = userInfo.erpUserInfo.language;
                return {
                    langCode: userInfo.ucUserInfo.langCode,
                    language: lang === 'KOR' ? 'kr' : lang === 'ENG' ? 'en' : lang === 'CHS' ? 'cn' : lang === 'JPN' ? 'jp' : 'kr',
                };
            }
        }
        else {
            return langInit;
        }
    }

    public static cl(value: any, subValue: any, useSubLang?: boolean): any {
        if (!useSubLang || subValue === undefined) {
            return value;
        }
        return this.isSubLang() ? value : subValue;
    }

    public static chooseByLang(value: any, subValue: any, useSubLang?: boolean): any {
        if (useSubLang === false || subValue === undefined) {
            return value;
        }
        return this.isSubLang() ? value : subValue;
    }

    /**
     * @deprecated
     * TODO: 함수명과 리턴값 불일치
     */
    public static isSubLang(): boolean {
        const langs = this.getLang();
        return langs.langCode === langs.language;
    }

    // 에러 관련

    public static handleError(owner: React.Component<any, hasError>, error: any) {
        Util.logError(error)

        owner.setState({
            hasError: true,
        })
    }

    public static getErrorState(error?: any): hasError {
        if (error) {
            Util.logError(error)
        }

        return {
            hasError: true,
            latestError: error
        }
    }

    public static logError(error: any) {
        console.error(error)
    }

    public static hasError(state: hasError): boolean {
        if (!state) {
            return false;
        }

        return state.hasError ? state.hasError : false;
    }

    public static renderError(error?: any) {
        const errorMessage = error && error.props ? error.props : error;
        if (errorMessage) {
            Util.logError(error);
        }
        return (<p>
            <span style={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>오류가 발생했습니다.</span>
            {errorMessage ? <><br /><span style={{ color: 'black', fontSize: '12px' }}>상세 오류 : {errorMessage}</span></> : undefined}
        </p>)
    }

    /**
     * rootObject의 property 목록 안전한 객체탐색 
     * @param rootObject 
     * @param properties 
     */
    public static safeAccessProperty(rootObject?: object | null, ...properties: string[]): any | undefined {
        if (!rootObject) {
            return;
        }

        if (properties.length === 0) {
            return rootObject;
        }

        let target: object | undefined = rootObject;
        properties.forEach(property => {
            if (target) {
                if (property in target) {
                    target = target[property];
                } else {
                    target = undefined;
                }
            }
        });

        return target;
    }

    public static warning(message?: string) {
        console.warn('개발용 경고 [' + message + ']');
    }

    public static log(message?: string) {
        console.log('개발용 로그 [' + message + ']');
    }

    public static getScraping = (versionHandler?: () => void) => {
        const invoke = (parameters: any, options: any = null) => {
            return new Promise<void>((resolve, reject) => {
                (async () => {
                    const version = await Util.fetchAgent.version();
                    if (!version) {
                        if (versionHandler) {
                            versionHandler();
                        }
                        reject('version');
                    }
                    if (options && options.transaction === true) {
                        const transactionId = uuid();
                        Util.fetchAgent.transaction('system', 'Scraping', transactionId, parameters,
                            (data) => {
                                if (options.onStarted && typeof options.onStarted === 'function') {
                                    options.onStarted(data);
                                }
                            },
                            (data) => {
                                if (options.onStatusChanged && typeof options.onStatusChanged === 'function') {
                                    options.onStatusChanged(data);
                                }
                            }).then((result: any) => {
                                resolve(JSON.parse(result.data));
                            }).catch((error) => {
                                reject(JSON.parse(error));
                            });
                    } else {
                        Util.fetchAgent.post('system', 'Scraping', parameters)
                            .then((result: any) => {
                                if (result && result.data) {
                                    resolve(JSON.parse(result.data));
                                }
                                else {
                                    resolve();
                                }
                            })
                            .catch((error) => {
                                reject(JSON.parse(error));
                            });
                    }
                })();
            });
        };
        const update = () => {
            return invoke({
                command: 'update'
            });
        };
        const showCertification = (userFlag: boolean = true) => {
            return new Promise<any>((resolve, reject) => {
                invoke({
                    command: 'certVerification',
                    userFlag: userFlag
                }).then((data: any) => {
                    if (data && data.certFiles && Array.isArray(data.certFiles)) {
                        data.certFiles = data.certFiles.map((fileData: { name: string, data: string }) => {
                            const file = new File([new Uint8Array(atob(fileData.data).split('').map(i => i.charCodeAt(0)))], fileData.name, { type: 'application/octet-stream' });
                            return file;
                        });
                    }
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
            });
        };
        const sendRetrieveData = (payload: any[], options: any = null) => {
            return invoke({
                command: 'sendRetrieveData',
                payload: payload
            }, options);
        }
        const sendRetrieveDataNI = (payload: any[], options: any = null) => {
            return invoke({
                command: 'sendRetrieveDataNI',
                payload: payload
            }, options);
        }
        const homeTaxConfirm = (payload: any[], options: any = null) => {
            return invoke({
                command: 'homeTaxConfirm',
                payload: payload
            }, options);
        }
        const homeTaxSend = (payload: any[], options: any = null) => {
            return invoke({
                command: 'homeTaxSend',
                payload: payload
            }, options);
        }
        const scraping = (payload: any[], options: any = null) => {
            return invoke({
                command: 'dssm',
                localScrap: options && options.localScrap === true ? true : false,
                payload: payload
            }, options);
        };
        const localScraping = (payload: any[], options: any = null) => {
            return invoke({
                command: 'dssm',
                localScrap: true,
                payload: payload
            }, options);
        }

        return {
            update,
            showCertification,
            sendRetrieveData,
            sendRetrieveDataNI,
            homeTaxConfirm,
            homeTaxSend,
            scraping,
            localScraping
        };
    }

    public static scraping = Util.getScraping();

    public static getMessenger = (fetch: Fetch, userInfo?: { ucUserInfo: { empSeq: string, groupSeq: string, langCode: string } }) => {
        const send = (parameters: {
            empSeq?: string,
            groupSeq?: string,
            recvEmpSeq?: string[],
            receivers?: { empSeq: string }[],
            content?: string,
            contents?: string,
            contentType?: string,
            secuYn?: boolean,
            receiptYn?: boolean,
            reserveDate?: string,
            file?: string[],
            files?: string[]
            link?: string[],
            linkMsgId?: string,
            msgId?: string,
            encryptionYn?: boolean,
            langCode: string,
            callName: string
        }) => {
            const recvEmpSeq = parameters.recvEmpSeq && parameters.recvEmpSeq.length > 0 ? parameters.recvEmpSeq :
                parameters.receivers && parameters.receivers.length > 0 ? parameters.receivers.map(item => item.empSeq) :
                    [];
            const content = parameters.content && parameters.content.length > 0 ? parameters.content :
                parameters.contents && parameters.contents.length > 0 ? parameters.contents :
                    '';
            const file = parameters.file && parameters.file.length > 0 ? parameters.file :
                parameters.files && parameters.files.length > 0 ? parameters.files :
                    [];
            const langCode = parameters.langCode && parameters.langCode.length > 0 ? parameters.langCode : userInfo ? userInfo.ucUserInfo.langCode : '';
            return fetch.post('/messenger/messenger01A03', {
                empSeq: ((!parameters.empSeq && userInfo) ? userInfo.ucUserInfo.empSeq : parameters.empSeq) || '',
                groupSeq: ((!parameters.groupSeq && userInfo) ? userInfo.ucUserInfo.groupSeq : parameters.groupSeq) || '',
                recvEmpSeq: recvEmpSeq,
                content: content || '',
                contentType: parameters.contentType || '0',
                secuYn: parameters.secuYn ? 'Y' : 'N',
                receiptYn: parameters.receiptYn ? 'Y' : 'N',
                reserveDate: parameters.reserveDate || '',
                file: file || [],
                link: parameters.link || [],
                linkMsgId: parameters.linkMsgId || '',
                msgId: parameters.msgId || '',
                encryptionYn: parameters.encryptionYn ? 'Y' : 'N',
                langCode: langCode,
                callName: parameters.callName,
                apiName: 'A03'
            }, {
                contextType: 'application/json'
            });
        }
        return {
            send
        };
    }

    public static getShortCut = (e: React.KeyboardEvent): ShortCut | undefined => {
        switch (e.key) {
            case 'F2':
                return ShortCut.CodePicker;
            case 'F7':
                return ShortCut.Delete;
            case 'F10':
                return ShortCut.Search;
            case 'F9':
                return ShortCut.Print;
            case 'F8':
                if (['localhost', 'dev.amaranth10.co.kr', 'orbit.amaranth10.co.kr', '10.82.6.190'].includes(window.location.hostname) && e.altKey && e.ctrlKey) {
                    return ShortCut.GuideMessage;
                }
                break;
        }
        return undefined;
    }

    public static pad(n: string, width: number) {
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
    }

    public static nullToEmpty(array: Array<any> | null | undefined) {
        if (!array) {
            return [];
        }

        return array;
    }

    public static closest = (element: HTMLElement, checker: (element: HTMLElement) => boolean) => {
        return element && checker(element) ? element : (element.parentElement as HTMLElement) ? Util.closest(element.parentElement as HTMLElement, checker) : undefined;
    }

    public static stringEquals = (value1: string, value2: string, caseIntensive: boolean = true): boolean => {
        if (caseIntensive) {
            return value1 === value2
        } else {
            return value1.toUpperCase() === value2.toUpperCase();
        }
    }

    public static getPrivacyValue = (value: string | null | undefined): string | null | undefined => {
        if (value) {
            const privacy = new Privacy(value);
            return privacy.privacyValue;
        }
        return value;
    }

    public static getPrivacyKey = (value: string | null | undefined): string | null | undefined => {
        if (value) {
            const privacy = new Privacy(value);
            return privacy.privacyKey;
        }
        return value;
    }
}