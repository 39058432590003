import React, { Component } from "react";
import PropTypes from 'prop-types';
import shallowEqual from "recompose/shallowEqual";
import keycode from "keycode";
import UFOMaskField from "../UFOMaskField";
import UFOMonthFieldDialog from "./UFOMonthFieldDialog";
import LUXAlert from 'luna-rocket/LUXDialog/LUXAlert';

function getStyles(props, root, className) {
    let yearDisableMarginLeft = '-38px';
    if (props.inputStyle && props.inputStyle.textAlign) {
        if (props.inputStyle.textAlign === "left" || props.inputStyle.textAlign === "right") {
            yearDisableMarginLeft = 0;
        }
    }
    return {
        spanStyle: {
            display: className.indexOf("datedisabled") > 0 ? 'inherit' : 'none',
            position: 'absolute',
            top: '50%',
            left: props.inputStyle && props.inputStyle.textAlign ? props.inputStyle.textAlign === "left" ? '5px' : 'initial' : '50%',
            right: props.inputStyle && props.inputStyle.textAlign && props.inputStyle.textAlign === "right" ? '51px' : 'initial',
            width: '30px',
            height: '14px',
            WebkitPaddingAfter: '1px',
            marginTop: root && root.clientHeight === 23 ? '-8px' : '-7px',
            background: 'rgba(180,180,180,.5)',
            content: '',
            zIndex: 10,
            marginLeft: yearDisableMarginLeft,
        }
    }
}

const MESSAGE_ALERT_REQUIRE = "필수 입력사항입니다.";

class UFOMonthField extends Component {
    static propTypes = {
        /**
         * 입력 값의 자릿수가 다 채워지면 자동으로 다음 입력으로 포커스가 이동됩니다.
         */
        autoMoveNext: PropTypes.bool,
        /**
         * 값이 true 이면, 달력 아이콘이 숨겨져 있다가 마우스 hover 또는 focus 시 아이콘을 노출합니다.
         */
        blinkIcon: PropTypes.bool,
        /**
         * 루트 요소의 클래스명을 지정합니다.
         */
        className: PropTypes.string,
        /**
         * 값이 true 이면, 요소가 비활성화 됩니다.
         */
        disabled: PropTypes.bool,
        /**
         * null 값일 때, 달력에 보여질 연도를 지정합니다.
         */
        displayYear: PropTypes.string,
        /**
         * 컴포넌트에 대한 강제 에러 표시를 정의하는 속성입니다.
         */
        errorStyle: PropTypes.bool,
        /**
         * 값이 true 이면, 요소가 마감(readonly) 상태가 됩니다.
         */
        finish: PropTypes.bool,
        /**
         * 값이 true 이면, 달력 아이콘을 노출하지 않습니다.
         */
        hideIcon: PropTypes.bool,
        /**
         * 값이 true 이면, 요소가 필수 값이 됩니다.
         */
        isRequired: PropTypes.bool,
        /**
         * 입력 가능한 날짜에 대한 범위의 최대(끝) 날짜를 지정하는 속성입니다.(YYYYMM)
         * 기본으로 현재 연도의 12월로 지정되어 있습니다.
         */
        maxValue: PropTypes.number,
        /**
         * 입력 가능한 날짜에 대한 범위의 최소(시작) 날짜를 지정하는 속성입니다.(YYYYMM)
         * 기본으로 현재 연도의 1월로 지정되어 있습니다.
         */
        minValue: PropTypes.number,
        /**
         * 요소에 초점(Focus)이 사라질 때 발생하는 Callback 함수입니다.
         *
         * @param {object} event 요소에서 발생한 이벤트 객체입니다.
         */
        onBlur: PropTypes.func,
        /**
         * 요소의 값이 바뀔 때 발생하는 Callback 함수입니다.
         *
         * @param {object} event 요소에서 발생한 이벤트 객체입니다.
         * @param {object} value 요소의 변경된 값입니다.
         */
        onChange: PropTypes.func,
        /**
         * 요소에 Click 이벤트 동작이 있을 때 발생하는 Callback 함수입니다.
         *
         * @param {object} event 요소에 발생하는 `click` 이벤트입니다.
         */
        onClick: PropTypes.func,
        /**
         * 요소에 Focus 이벤트 동작이 있을 때 발생하는 Callback 함수입니다.
         *
         * @param {object} event 요소에 발생하는 `focus` 이벤트입니다.
         */
        onFocus: PropTypes.func,
        /**
         * 요소에 Key Down 이벤트 동작이 있을 때 발생하는 Callback 함수입니다.
         *
         * @param {object} event 요소에 발생하는 `keydown` 이벤트입니다.
         * @param {string} mode 요소에 `keydown` 이벤트가 발생할 때 컴포넌트의 모드('view/edit')입니다.
         */
        onKeyDown: PropTypes.func,
        /**
         * 요소에서 포커스가 이동되어야 할 시점에 발생하는 Callback 함수입니다.
         *
         * @param {string} move 포커스가 이동되어야 하는 방향입니다.
         */
        onMoveFocus: PropTypes.func,
        /**
         * 요소의 달력 아이콘 영역인 버튼을 클릭했을 때 발생하는 Callback 함수입니다.
         */
        onTouchTapButton: PropTypes.func,
        /**
         * 값이 true 이면, 날짜를 선택할 수 있는 달력 모양의 다이얼로그 창이 띄워집니다.
         */
        showDialog: PropTypes.bool,
        /**
         * 루트 요소의 inline-styles 값을 오버라이드 합니다.
         */
        style: PropTypes.object,
        /**
         * 요소의 값을 지정합니다.
         */
        value: PropTypes.number,
        /**
         * 값이 true 이면, view 모드 시 내부에 파란 색상이 보여집니다.
         */
        viewInnerBlue: PropTypes.bool,
        /**
         *  패널의 정렬 위치를 지정합니다.
         */
        align: PropTypes.string,
        /**
         *  패널의 위치를 지정합니다.
         */
        position: PropTypes.string
    };

    static defaultProps = {
        autoMoveNext: true,
        blinkIcon: false,
        hideIcon: false,
        finish: false,
        minValue: (new Date().getFullYear() - 100) * 100 + 1,
        maxValue: (new Date().getFullYear() + 100) * 100 + 12,
        onChange: () => { },
        isRequired: false,
        showDialog: true,
        value: null,
        viewInnerBlue: true,
        errorStyle: false,
    };

    //OBT ref = createRef 로 수정
    myRefs = {
        root: React.createRef(),
        span: React.createRef(),
        maskField: React.createRef(),
        input: React.createRef(),
        dialogWindow: React.createRef()
    }

    // region Init

    constructor(props) {
        super(props);

        let value = this.getStringValue(props);

        this.state = {
            displayDate: value,
            displayYear: this.getDisplayYear(props),
            oldValue: value,
            value: value,
            isFocused: false,
            maxDate: props.maxValue ? this.getYearMonth(props.maxValue) : null,
            minDate: props.minValue ? this.getYearMonth(props.minValue) : null,
            showIcon: !props.blinkIcon,
        };

        this.isBlured = true;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            let value = this.getStringValue(nextProps);
            this.setState({
                displayDate: value,
                displayYear: this.getDisplayYear(nextProps),
                oldValue: value,
                value: value,
            });
        }

        if (nextProps.isDialogOpen !== this.props.isDialogOpen) {
            this.setState({ isDialogOpen: nextProps.isDialogOpen });
        }

        if (nextProps.blinkIcon !== this.props.blinkIcon) {
            this.setState({ showIcon: !nextProps.blinkIcon });
        }

        // period 때문에 필요
        if (nextProps.minValue !== this.props.minValue) {
            let value = this.getStringValue(nextProps);
            this.setState({
                displayDate: value,
                displayYear: this.getDisplayYear(nextProps),
                oldValue: value,
                value: value,
                maxDate: nextProps.maxValue ? this.getYearMonth(nextProps.maxValue) : null,
                minDate: nextProps.minValue ? this.getYearMonth(nextProps.minValue) : null,
            });
        }
    };

    componentDidMount() {
        let { style } = this.props;
        let spanStyle = this.myRefs.span.current.style;

        if (style && style.width && parseInt(style.width.replace('px', '')) < 91) { // 입력필드 width가 작아질때 음영영역 위치 조정
            let left = parseInt(spanStyle.marginLeft.replace('px', '')) + 3;
            spanStyle.marginLeft = left + 'px';
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isFocused && prevState.isEdited !== this.state.isEdited) {
            // edit/view 모드에 따라서 포커스 이동
            if (this.state.isEdited === false) {
                this.focus();
                this.moveCaretPosition();
            } else {
                if (this.myRefs.maskField.current) this.myRefs.maskField.current.focus();
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !shallowEqual(this.props, nextProps) || !shallowEqual(this.state, nextState);
    }

    componentWillUnmount() {
        this.closeDialog();
    }

    // endregion

    // region get/set Func

    getClassName = () => {
        const { disabled, className } = this.props;
        const { isFocused, isEdited, isDialogOpen, displayYear } = this.state;

        let newClassName = 'LS_ngh_input2 righticon';
        if (displayYear) {
            newClassName += ' datedisabled';
        }
        if (disabled) {
            newClassName += ' disabled';
        }
        if (isFocused || isDialogOpen) {
            if (isEdited) {
                newClassName += ' focus';
            } else {
                newClassName += ' readonly';
            }
        }

        return className || newClassName;
    }

    /**
     * 6자리 숫자로 된 날짜 값을 연도와 월로 구분하여 반환합니다.
     */
    getYearMonth(date) {
        let year;
        let month;

        if (!date) {
            year = new Date().getFullYear();
        } else {
            date = date.toString();
            year = parseInt(date.slice(0, 4));
            month = parseInt(date.slice(4));
        }

        return {
            year: year,
            month: month,
        }
    }

    /**
     * min, max 값에 따라 연도를 고정시킵니다.
     */
    getDisplayYear = (props) => {
        let { maxValue, minValue } = props;

        if (!maxValue || !minValue) return null;

        let maxDate = this.getYearMonth(maxValue);
        let minDate = this.getYearMonth(minValue);

        let displayYear = null;

        if (maxDate.year === minDate.year) { // 회계년도
            displayYear = maxDate.year.toString();
        } else if (maxDate.year - minDate.year === 1 && maxDate.month < minDate.month) { // 중간법인
            displayYear = minDate.year.toString();
        }

        return displayYear;
    }
    //OBT 수정  newValue += '-'; ->  newValue += '-';
    getInputValue = (value) => {
        let length = value.length;
        let newValue = '';

        for (let i = 0; i < length; i++) {
            newValue += value[i];
            if (i === 3) {
                newValue += '-';
            }
        }

        return newValue;
    }

    getStringValue = (props) => {
        let { value } = props;

        if (!value) {
            value = '';
        } else {
            value = value.toString();
        }

        let displayYear = this.getDisplayYear(props);
        if (value === '' && displayYear) {
            value = displayYear;
        }

        return value;
    }

    /**
     * 값에 들어간 마스크를 제외한 스트링을 반환합니다.
     */
    getNoMaskValue(value) {
        let pureValue = '';

        for (let i = 0; i < value.length; i++) {
            if (!this.state.displayYear) { /**/
                if (value[i] === '_') continue;
            }
            pureValue += value[i];
        }

        /**/
        if (this.state.displayYear) {
            let blankIndex = value.indexOf('_');
            if (blankIndex === 4) { // 월 입력안했을때
                pureValue = value.split('_')[0] + "0";
            } else if (blankIndex === 5) { // 월 한글자 입력했을때
                pureValue = value.split('_')[0] + value.split('_')[1];
                if (parseInt(pureValue.slice(-1)) > 0) {
                    pureValue = pureValue.substr(0, blankIndex - 1) + "0" + pureValue.substr(blankIndex - 1);
                }
            }
        }
        /**/

        return pureValue;
    }

    /**
     * 커서의 위치를 지정합니다.
     */
    setCaretPosition = (start, end) => {
        let targetElement = this.myRefs.input.current;

        if (!targetElement) return;

        if (targetElement.setSelectionRange) { // IE >= 9 and Other Browsers
            targetElement.focus();
            targetElement.setSelectionRange(start, end);
        } else if (targetElement.createTextRange) { // IE < 9
            let range = targetElement.createTextRange();
            range.collapse(true);
            range.moveEnd('character', end);
            range.moveStart('character', start);
            range.select();
        }
    };

    // endregion

    // region Events

    callPropsChange = (event, value) => {
        if (this.getNoMaskValue(value) !== this.state.oldValue) {
            if (value === '') {
                // value = null; /**/
                /**/
                if (this.state.displayYear) {
                    value = parseInt(this.state.oldValue);
                    this.setState({ value: this.state.oldValue })
                } else {
                    value = null;
                }
                /**/
            } else {
                value = parseInt(value);
            }
            this.props.onChange(event, value);
        }
    }

    focus(move) {
        if (this.props.disabled) {
            this.handleMoveFocus(move);
            return;
        }

        if (this.myRefs.input.current) this.myRefs.input.current.focus();
    }

    openDialog() {
        if (!this.props.showDialog) return;

        this.setState({
            isDialogOpen: true,
        }, this.myRefs.dialogWindow.current.show);
        // this.myRefs.dialogWindow.current.show();
    }

    closeDialog() {
        if (!this.props.showDialog) return;

        this.setState({
            isDialogOpen: false,
        }, this.myRefs.dialogWindow.current.dismiss);
        // this.myRefs.dialogWindow.current.dismiss();
    }

    /**
     * 커서의 위치를 변경합니다.
     */
    moveCaretPosition = () => {
        let caretPosition = 0;
        if (this.state.value.length === 4) {
            caretPosition = 5;
        }

        this.setCaretPosition(caretPosition, caretPosition);
    }

    isValidValue = (value) => {
        if (value === null) return false;

        let isValid = true;

        if (this.getNoMaskValue(value).length < 6) {
            if (this.getNoMaskValue(value).length === 0 || (this.getNoMaskValue(value).length === 4 && this.state.displayYear)) {
                isValid = !this.props.isRequired;
            } else {
                isValid = false;
            }
        } else {
            let month = parseInt(value.slice(4));
            isValid = 1 <= month && month <= 12 && this.props.minValue <= parseInt(value) && this.props.maxValue >= parseInt(value);
        }

        return isValid;
    }

    shouldDisableBackspace = (caretPosition) => {
        return caretPosition.start <= 5 && this.state.displayYear;
    }

    /**
     * 입력을 막을 날짜를 지정합니다.
     */
    shouldDisableValue = (value, keycodeStr, caretPosition) => {
        let shouldDisable = false;
        switch (caretPosition.start) {
            //연도 입력 시
            case 0:
            case 1:
            case 2:
            case 3:
                shouldDisable = this.state.displayYear;
                break;
            case 4:
            case 5:
                // OBT 수정 case5 내용 주석처리 되어 있던거 해제
                // 월의 첫번째 자리 입력 시
                if (!this.state.displayYear) { /**/
                    if (parseInt(keycodeStr) > 1) {
                        shouldDisable = true;
                    }
                }
                break;
            case 6:
                // 월의 두번째 자리 입력 시
                if (Number(value[4]) > 1) {
                    shouldDisable = true;
                    break;
                }
                if (value[4] === '0' && parseInt(keycodeStr) === 0) {
                    shouldDisable = true;
                } else if (value[4] === '1' && parseInt(keycodeStr) > 2) {
                    shouldDisable = true;
                }
                /**/
                if (this.state.displayYear) {
                    // 월의 첫번째 자리가 1 이외의 숫자이면 두번째 자리 입력못하게 수정
                    if (parseInt(value[4]) > 1) {
                        shouldDisable = true;
                    }
                }
                /**/
                break;
            default:
                break;
        }

        return shouldDisable;
    }

    /**
     * 
     */
    handleAccept = (event, value) => {
        this.handleBlur(event); // IE 에서 blur 두 번 호출되는 문제 때문에 추가
        this.handleMoveFocus();
        this.callPropsChange(event, value.toString());
    }

    /**
     * 
     */
    handleBlur = (event) => {
        if (this.isBlured) return;

        this.isBlured = true;
        let { value, oldValue } = this.state;

        if (this.isValidValue(value) && !this.props.autoMoveNext) {
            this.callPropsChange(event, this.getNoMaskValue(value).length === 4 ? '' : this.getNoMaskValue(value));
        }
        // console.log(" value : ", this.isValidValue(value));

        this.setState({
            isEdited: false,
            isFocused: undefined,
            value: oldValue,
            showIcon: !this.props.blinkIcon,
        });

        if (this.props.onBlur) this.props.onBlur(event);
    }

    /**
     * 
     */
    handleChange = (event, value, caretPosition) => {
        event.stopPropagation();

        if (!value) return;

        let { autoMoveNext, maxValue, minValue } = this.props;
        let { displayYear, maxDate, minDate } = this.state;
        let year = value.slice(0, 4);
        let month = value.slice(4);

        // 회계용이면 입력된 월에 따라 연도를 변경한다
        if ((year + month).indexOf("_") === -1 && displayYear) {
            let newMonth = parseInt(month);

            if (newMonth < minDate.month) {
                year = maxDate.year;
            } else {
                year = minDate.year;
            }

            value = (year * 100 + newMonth).toString();
        }
        // 6자리 다 찼을 때
        // if (value.indexOf("_") === -1) { /**/
        if (this.getNoMaskValue(value).indexOf("_") === -1) { /**/

            // autoMoveNext 이면 다음 컴포넌트로 이동
            if (autoMoveNext && caretPosition && caretPosition.start === 7) {
                value = parseInt(value);

                // 최대/최소를 넘어가는 값에 대해서 이전값이 null 일때는 최대/최소로,
                // 이전값이 유효한 값일때는 이전값으로 적용
                if (value && value > maxValue) {
                    if (this.state.oldValue && this.state.oldValue.length === 6) {
                        value = this.state.oldValue;
                    } else {
                        value = maxValue;
                    }
                } else if (value && value < minValue) {
                    if (this.state.oldValue && this.state.oldValue.length === 6) {
                        value = this.state.oldValue;
                    } else {
                        value = minValue;
                    }
                }

                this.handleBlur(event); // IE 에서 blur 두 번 호출되는 문제 때문에 추가
                this.handleMoveFocus();
                this.callPropsChange(event, value.toString());
                return;
            }

        }

        if (this.getNoMaskValue(value) === '') {
            value = '';
        }
        this.setState({ value: value }, () => {
            // console.log("onchange value : ", this.state.value);
        });
    }

    /**
     * 
     */
    handleClick = () => {
        this.moveCaretPosition();
    }

    /**
     * Alert을 닫고, inputElement에 포커스를 넘긴다.
     */
    handleCloseAlert = (event) => {
        this.setState({ isAlertOpen: false });
        this.focus();
    }

    /**
     * 
     */
    handleRequestClose = () => {
        if (!this.isBlured) return;

        this.closeDialog();
    }

    /**
     * 
     */
    handleDoubleClick = (event) => {
        // 마감 상태이면 입력 불가
        if (this.props.finish || this.props.disabled) return;
        // OBT 수정 아래 if문 추가 & 아래 setState구문 주석처리
        // if (document.selection) {
        //     let range = document.body.createTextRange();
        //     range.moveToElementText(this.myRefs.input.current);
        //     range.select();
        // } else if (window.getSelection) {
        //     let range = document.createRange();
        //     range.selectNode(this.myRefs.input.current);
        //     window.getSelection().removeAllRanges();
        //     window.getSelection().addRange(range);
        // }
        // window.getSelection()
        //     .selectAllChildren(
        //         this.myRefs.input.current
        //     );
        let selection = window.getSelection();
        selection.selectAllChildren(this.myRefs.input.current);
        //this.setState({ isEdited: true });
    }

    /**
     * 
     */
    handleFocus = (event) => {
        this.isBlured = false;

        if (this.state.isFocused || this.props.disabled) return;

        this.setState({
            isFocused: true,
            showIcon: true,
        });

        this.moveCaretPosition();
        if (this.props.onFocus) this.props.onFocus(event);
    }

    /**
     * 
     */
    handleKeyDownMask = (event) => {
        event.stopPropagation();

        if (this.props.onKeyDown) this.props.onKeyDown(event, 'edit');

        let { maxValue, minValue, isRequired } = this.props;
        let { value, oldValue } = this.state;

        switch (keycode(event)) {
            case 'tab':
                // 유효하지 않은 값 일 때
                if (isRequired && value.length !== 6) {
                    event.preventDefault();
                    this.setState({ isAlertOpen: true });
                    break;
                } else {
                    this.closeDialog();
                }
                break;
            case 'backspace':
                event.preventDefault();
                break;
            case 'esc':
                this.setState({
                    value: oldValue,
                    isEdited: this.getNoMaskValue(value).length < 6 && isRequired,
                });
                this.closeDialog();
                break;
            case 'enter':
                let nmv = this.getNoMaskValue(value);

                if (nmv.length === 5) {
                    nmv = nmv.slice(0, 4) + '0' + nmv[4];
                    value = nmv.slice(0);
                } else if (nmv.length === 6) {

                }
                let newDate = nmv.length === 6 ? parseInt(this.getNoMaskValue(value)) : null;

                if (this.isValidValue(value)) {
                    this.callPropsChange(event, this.getNoMaskValue(value).length === 4 ? '' : this.getNoMaskValue(value));
                    this.setState({ isEdited: false });
                    this.handleMoveFocus();

                } else if (newDate && newDate > maxValue) {
                    this.callPropsChange(event, maxValue.toString());
                    // this.setState({isEdited: false}); /**/
                    /**/
                    if (this.state.displayYear) {
                        this.setState({
                            value: maxValue.toString(),
                            isEdited: false
                        })
                    } else {
                        this.setState({ isEdited: false });
                    }
                    /**/

                } else if (newDate && newDate < minValue) {
                    const cValue = `${maxValue.toString().slice(0, 4)}${newDate.toString().slice(4, 6)}`;
                    // const cValue = minValue.toString();

                    this.callPropsChange(event, cValue);

                    if (this.state.displayYear) {
                        this.setState({
                            value: cValue,
                            isEdited: false
                        })
                    } else {
                        this.setState({ isEdited: false });
                    }
                    /**/

                } else {
                    // 유효하지 않은 값 일 때
                    let alertOpen = false;
                    if (this.state.displayYear) {
                        // alertOpen = this.props.isRequired && oldValue.length === 4; /**/
                        alertOpen = this.props.isRequired && value.length === 4; /**/
                    } else {
                        alertOpen = this.props.isRequired && !oldValue;
                    }

                    /**/
                    // this.setState({
                    //     isAlertOpen: alertOpen,
                    //     value: oldValue,
                    // });

                    /**/
                    if (this.state.displayYear) {
                        let noMaskValue = this.getNoMaskValue(value);
                        let sliceMonth = noMaskValue.slice(4, noMaskValue.length);
                        if (parseInt(sliceMonth) > 0 && sliceMonth.length === 1) {
                            noMaskValue = noMaskValue.slice(0, 4) + "0" + sliceMonth;
                        }
                        let validMonth = noMaskValue.length === 6 && parseInt(sliceMonth) > 0
                            && parseInt(noMaskValue) >= this.props.minValue && parseInt(noMaskValue) <= this.props.maxValue;

                        this.setState({
                            isAlertOpen: alertOpen,
                            value: validMonth ? noMaskValue : oldValue,
                            isEdited: false
                        }, () => {
                            if (validMonth) {
                                this.callPropsChange(event, noMaskValue);
                                // this.handleMoveFocus();
                            }
                        });
                    } else {
                        this.setState({
                            isAlertOpen: alertOpen,
                            value: oldValue,
                        });
                    }
                    /**/

                    if (!this.props.isRequired || !alertOpen) {
                        this.handleMoveFocus();
                    }
                    break;
                }

                break;
            default:
                break;
        }
    }

    handleKeyDown = (event) => {
        event.stopPropagation();
        if (this.props.onKeyDown) this.props.onKeyDown(event, 'view');

        // 한글일 때 undefined
        if (!keycode(event)) return;

        let { value } = this.state;

        switch (keycode(event)) {
            case 'tab':
                // 유효하지 않은 값 일 때
                if (this.props.isRequired && value.length !== 6) {
                    event.preventDefault();
                    this.setState({ isAlertOpen: true });
                    break;
                } else {
                    this.closeDialog();
                }
                break;
            case 'esc':
                this.closeDialog();
                break;
            case 'delete':
            case 'backspace':
            case 'space':
                event.preventDefault();
                // 마감 상태이면 입력 불가
                if (this.props.finish || this.props.disabled) break;

                if (this.state.displayYear) {
                    value = this.state.displayYear;
                } else {
                    value = '';
                }

                this.setState({
                    value: value,
                    isEdited: true,
                });
                break;
            case 'enter':
            case 'down':
            case 'up':
            case 'right':
            case 'left':
                event.preventDefault();
                // 유효하지 않은 값 일 때
                if (this.props.isRequired && value.length !== 6) {
                    this.setState({ isAlertOpen: true });
                    break;
                }

                if (this.myRefs.input.current) this.myRefs.input.current.blur();

                this.handleMoveFocus(keycode(event));
                break;
            default:
                // 마감 상태이면 입력 불가
                if (this.props.finish || this.props.disabled) break;
                // 키보드, 키패드 숫자가 아닐 때
                if (!(event.keyCode >= 48 && event.keyCode <= 57) && !(event.keyCode >= 96 && event.keyCode <= 109)) break;

                let keycodeStr = keycode(event).slice(-1);

                // 숫자만인지 체크하는 정규식
                const regNumber = /^[0-9]*$/;
                if (!regNumber.test(keycodeStr)) break;

                let caretStart = value.length % 6;
                if (this.state.displayYear) {
                    caretStart = 4;
                }

                if (this.shouldDisableValue(value, keycodeStr, { start: caretStart })) break;

                if (value.length === 6) {
                    if (this.state.displayYear) {
                        value = this.state.displayYear + keycodeStr;
                    } else {
                        value = keycodeStr;
                    }
                } else {
                    // 2이상 입력 시 0+keycodeStr -> ex 05
                    // value = value + (Number(keycodeStr) > 1 ? ('0'+keycodeStr) : keycodeStr);
                    value = value + keycodeStr;
                }

                this.setState({
                    value: value,
                    isEdited: true,
                });
        }
    }

    handleMoveFocus = (move = 'enter') => {

        this.myRefs.root.current.focus();
        this.myRefs.root.current.blur();

        if (this.props.onMoveFocus) {
            setTimeout(() => this.props.onMoveFocus(move), 10);
        }
    }

    handleMoveFocusMask = (move) => {
        if (move === 'enter') return;
        if (this.getNoMaskValue(this.state.value).length !== 6 && this.props.isRequired) return;

        this.handleMoveFocus(move);
    }

    handleMouseEnter = (event) => {
        if (this.props.onMouseEnter) this.props.onMouseEnter(event);

        if (!this.props.blinkIcon) return;

        this.setState({ showIcon: true });
    }

    handleMouseLeave = (event) => {
        if (this.props.onMouseLeave) this.props.onMouseLeave(event);

        if (!this.props.blinkIcon || this.state.isFocused) return;

        this.setState({ showIcon: false });
    }

    handlePopoverOpen = () => {
        // 마감 상태이면 입력 불가
        if (this.props.finish || this.props.disabled) return;

        this.openDialog();
        this.focus();
        if (this.props.onTouchTapButton) this.props.onTouchTapButton();
    }

    // endregion

    render() {
        const {
            autoMoveNext,
            blinkIcon,
            hideIcon,
            className,
            disabled,
            displayYear,
            errorStyle,
            finish,
            isDialogOpen,
            isRequired,
            maxValue,
            minValue,
            onMoveFocus,
            onFocus,
            onBlur,
            onChange,
            onKeyDown,
            onTouchTapButton,
            showDialog,
            style,
            inputStyle,
            value,
            viewInnerBlue,
            ...other
        } = this.props;

        let { isEdited, isFocused, showIcon } = this.state;

        // blink 일때 달력창 클릭시 아이콘 안 없어지게
        if (blinkIcon && this.getClassName().indexOf('readonly') > -1) {
            showIcon = true;
        }

        if (hideIcon) {
            showIcon = false;
        }

        const inputProps = {
            onBlur: this.handleBlur,
            onClick: this.handleClick,
            onDoubleClick: this.handleDoubleClick,
            onFocus: this.handleFocus,
            ...other,
        };

        const dateIcon = (
            <button
                type='button'
                className='LSbutton'
                disabled={disabled}
                tabIndex={-1}
                onClick={this.handlePopoverOpen}
                style={{ display: showIcon ? 'block' : 'none' }}
            >
                {/* OBT 수정 달력 열기 삭제 */}
                <span className='sp_selene icon_datefield'></span>
            </button>
        );

        const fakeDateIcon = (
            <div className="fakebutton" onClick={this.handlePopoverOpen} style={{ opacity: showIcon ? 1 : 0, display: hideIcon || (blinkIcon && (disabled || finish)) ? 'none' : 'block' }}>
                {/* OBT 수정 달력 열기 삭제, style={this.props.disabled === true ? { opacity: 0.2 } : {}} 추가 */}
                <span style={this.props.disabled === true ? { opacity: 0.2 } : {}} className='sp_selene icon_datefield'></span>
            </div>
        );

        const inputValue = this.getInputValue(this.state.value);
        const dateValue = this.state.displayDate.length === 6 ? parseInt(this.state.displayDate) : null;

        const inputElement = (
            isEdited ?
                <div
                    className="real_inputbox"
                    style={{ opacity: 1, left: 0 }}
                >
                    <UFOMaskField
                        ref={this.myRefs.maskField}
                        disabled={disabled}
                        tabIndex={disabled ? -1 : 0}
                        shouldDisableBackspace={this.shouldDisableBackspace}
                        shouldDisableValue={this.shouldDisableValue}
                        maskCharLengthArray={[4, 2]}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDownMask}
                        onMoveFocus={this.handleMoveFocusMask}
                        value={this.state.value.toString()}
                        //OBT 수정 position:'absolute'추가
                        style={Object.assign({ textAlign: 'center', position: 'absolute' }, inputStyle, hideIcon ? { width: '100%' } : {})}
                        {...inputProps}
                    />
                    {dateIcon}
                </div> :
                <div
                    ref={this.myRefs.input}
                    className="fake_inputbox"
                    style={{ opacity: 1, left: 0, outline: 'none' }}
                    tabIndex={disabled ? -1 : 0}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    onKeyDown={this.handleKeyDown}
                    {...inputProps}
                >
                    <span
                        className="fakeinput"
                        //OBT 수정 position: 'absolute', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' 추가
                        style={Object.assign({ textAlign: 'center', position: 'absolute', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' },
                            inputStyle,
                            viewInnerBlue ? {} : { background: 'transparent' },
                            hideIcon ? { width: '100%' } : {})}
                    >
                        {inputValue.length === 5 ? <span>{inputValue}<span style={{ width: '14px', display: 'inline-block' }} /></span> : inputValue}
                    </span>
                    {fakeDateIcon}
                </div>
        )

        let newClassName = this.getClassName();
        if (!isFocused && errorStyle) {
            newClassName += " error";
        }

        const styles = getStyles(this.props, this.myRefs.root.current, newClassName);

        return (
            <div tabIndex={0} ref={this.myRefs.root} className={newClassName} style={style} onBlur={e => {
                setTimeout(() => {
                    const isParentEqualsThis = (parent) => {
                        if (parent === this.myRefs.root.current) {
                            return true;
                        }
                        else if (parent.parentElement) {
                            return isParentEqualsThis(parent.parentElement);
                        }
                        return false;
                    }
                    if (!isParentEqualsThis(document.activeElement)) {
                        this.closeDialog();
                    }
                }, 0);
            }} >
                <span ref={this.myRefs.span} style={styles.spanStyle} />
                {inputElement}
                <UFOMonthFieldDialog
                    ref={this.myRefs.dialogWindow}
                    anchorEl={this.myRefs.root.current}
                    anchorOrigin={{ "horizontal": "right", "vertical": "bottom" }}
                    targetOrigin={{ "horizontal": "right", "vertical": "top" }}
                    displayYear={displayYear}
                    initialDate={dateValue}
                    onAccept={this.handleAccept}
                    onRequestClose={this.handleRequestClose}
                    maxValue={maxValue}
                    minValue={minValue}
                    align={this.props.align}
                    position={this.props.position}
                />
                <LUXAlert
                    message={MESSAGE_ALERT_REQUIRE}
                    useIcon={true}
                    useIconType="warning"
                    open={this.state.isAlertOpen}
                    onClose={this.handleCloseAlert}
                    confirmButton={this.handleCloseAlert}
                />
            </div>
        );
    }
}

export default UFOMonthField;
