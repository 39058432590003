import { saveAs } from 'file-saver'

let url = '';

interface FormDataParam {
    ajaxEbp: any,
    companyCode: string,
    menuCode: string,
    tableName: string,
    isUse?: boolean
}

interface FormData {
    columnNm: string,
    coCd: string,
    tableNm: string,
    columnKorDc: string,
    columntypeFg: string,
    sizeQt: string,
    nullableFg: string,
    menuCd: string,
    remarkKorDc: string,
    columnSq: number
}

export interface ExcelOption {
    sheetName?: string
}

export default class Excel {
    /**
     * EXCEL IMPORT, EXCEL 양식생성 시 필요한 컬럼의 정보를 받아옴.
     * @param {*} ajaxEbp 로그인 후 쿠키를 가지고있는 상태의 ajaxEbp
     * @param {string} companyCode 회사코드
     * @param {string} menuCode 메뉴코드
     * @param {string} tableName 테이블명
     * @param {boolean} isUse 컬럼사용여부
     */
    public static getExcelFormData(ajaxEbp: any, companyCode: string, menuCode: string, tableName: string, isUse?: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            ajaxEbp.post(`${url}/system/common/getexcelformdata`, {
                companyCode: companyCode,
                menuCode: menuCode,
                tableName: tableName,
                isUse: (isUse === undefined || isUse === true) ? 1 : 0
            }, {
                contextType: "application/json",
                async: false
            }).then(res => {
                let result = res;
                if (!result || !(result.resultCode === 0)) {
                    return null
                }

                resolve(result.resultData);
            }).catch(err => {
                reject(err)
                return;
            })
        });
    }

    /**
     * 순수한 엑셀 데이터 추출
     * @param fileContents 
     */
    public static getWorkSheets(fileContents: Blob): Promise<any> {
        return new Promise((resolve, reject) => {
            import('exceljs').then((ExcelJS) => {
                const reader = new FileReader();
                const wb = new ExcelJS.Workbook();

                reader.readAsArrayBuffer(fileContents);
                reader.onload = () => {
                    const buffer = reader.result;
                    wb.xlsx.load(buffer as ArrayBuffer).then(workbook => {
                        resolve(workbook.worksheets);
                    }).catch(err => {
                        reject(new Error('파일을 읽을 수 없습니다.'));
                    });
                }
            })
        });
    }

    /**
     * EXCEL IMPORT
     * @param {Blob} fileContents 엑셀 파일
     * @param {boolean} checkYn 데이터 유효성 검사여부
     * @param {*} formDataParam { ajaxEbp: 로그인 후 쿠키를 가지고있는 상태의 ajaxEbp, companyCode: 회사코드, menuCode: 메뉴코드, tableName: 테이블명, isUse?: 컬럼사용여부 }
     */
    public static parseExcel(fileContents: Blob, checkYn?: boolean, formDataParam?: FormDataParam): Promise<any> {
        return new Promise((resolve, reject) => {
            import('exceljs').then((ExcelJS) => {
                const reader = new FileReader();
                const wb = new ExcelJS.Workbook();

                reader.readAsArrayBuffer(fileContents);
                reader.onload = () => {
                    const buffer = reader.result;
                    wb.xlsx.load(buffer as ArrayBuffer).then(workbook => {
                        let excelData: any[] = [];
                        if (!workbook || !workbook.worksheets[0]) {
                            reject(new Error('파일을 읽을 수 없습니다.'))
                        }

                        if (workbook.worksheets[0].rowCount < 3) {
                            reject(new Error('잘못된 엑셀 양식 입니다.'))
                        }

                        if (workbook.worksheets[0].rowCount < 4) {
                            reject(new Error('데이터가 없습니다.'))
                        }

                        let columnNm;

                        workbook.worksheets[0].eachRow((row, rowIdx) => {
                            if (rowIdx === 2) {
                                columnNm = Object.keys(row.values).map(key => row.values[key]);
                            }

                            if (rowIdx >= 4) {
                                let element = {} as any;
                                for (let i = 0; i < columnNm.length; i++) {
                                    element[columnNm[i]] = (row.values[i + 1] !== null && row.values[i + 1] !== undefined) ? row.values[i + 1] : undefined;
                                }
                                excelData.push(element);
                            }
                        });

                        if (!checkYn) {
                            resolve(excelData);
                        }

                        if (formDataParam && formDataParam.ajaxEbp) {
                            this.getExcelFormData(formDataParam.ajaxEbp, formDataParam.companyCode, formDataParam.menuCode, formDataParam.tableName, formDataParam.isUse).then(formData => {
                                formData.forEach(formEle => {
                                    excelData.forEach(excelEle => {
                                        const value = excelEle[formEle.columnNm];
                                        if (formEle.nullableFg === '0' && (!value || value.length < 1)) {
                                            reject(new Error(`[${formEle.columnNm}] 이(가) 입력되지 않은 데이터가 존재합니다.`));
                                        }

                                        if (value && value.length > parseInt(formEle.sizeQt)) {
                                            reject(new Error(`[${formEle.columnNm}] 의 길이가 초과한 데이터가 존재합니다.`));
                                        }

                                        if (formEle.columntypeFg === '1' && value && isNaN(value)) {
                                            reject(new Error(`[${formEle.columnNm}] 에 숫자가 아닌 데이터가 존재합니다.`));
                                        }
                                    })
                                });
                                resolve(excelData);
                            }).catch(err => {
                                reject(err);
                            });
                        }
                    }).catch(err => {
                        reject(new Error('파일을 읽을 수 없습니다.'));
                    });
                }
            })
        });
    }

    /**
     * EXCEL EXPORT
     * @param {Array<FormData>} formData 엑셀 양식 데이터
     * @param {string} menuCode 메뉴코드
     * @param {string} fileName 파일명
     */
    public static async createExcel(formData: Array<FormData>, menuCode: string, fileName?: string, option?: ExcelOption): Promise<any> {
        import('exceljs').then((ExcelJS) => {
            const wb = new ExcelJS.Workbook();
            const ws = wb.addWorksheet();

            let row1 = [] as any;
            let row2 = [] as any;
            let row3 = [] as any;
            let columns = [] as any;
            let des = '';

            // 필수값 배경
            let notnullFill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFEF3F0' },
                bgColor: { argb: 'FFFEF3F0' }
            };
            let normalFill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFFFF' },
                bgColor: { argb: 'FFFFFFFF' }
            }
            let exFill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFd3d3d3' },
                bgColor: { argb: 'FFd3d3d3' }
            };

            formData.forEach(element => {
                row1.push(element.columnKorDc || '');
                row2.push(element.columnNm || '');
                des = `타입 : ${element.columntypeFg === '0' ? '문자' : '숫자'}\n길이 : ${element.sizeQt || ''}\n필수 : ${element.nullableFg === '0' ? 'True' : 'False'}\n설명 : ${element.remarkKorDc || ''}`
                row3.push(des);
                columns.push({
                    key: element.columnNm,
                    width: 20,
                    style: {
                        numFmt: element.columntypeFg === '0' ? '@' : ''
                    }
                })
            });

            ws.columns = columns
            ws.addRow(row1);
            ws.addRow(row2);
            ws.addRow(row3);

            // 첫번째 로우의 높이와 폰트 설정
            ws.getRow(1).height = 19;
            ws.getRow(1).eachCell((cell) => {
                cell.style = {
                    font: {
                        size: 9
                    },
                    alignment: {
                        horizontal: 'center'
                    }
                }
            })

            // 두번째 로우의 높이와 폰트 설정
            ws.getRow(2).height = 19;
            ws.getRow(2).eachCell((cell) => {
                cell.style = {
                    font: {
                        size: 9
                    },
                    alignment: {
                        horizontal: 'center'
                    }
                }
            })

            // 세번째 로우의 높이와 폰트 설정
            // ws.getRow(3).height = 45;
            ws.getRow(3).eachCell((cell) => {
                cell.style = {
                    font: {
                        size: 8,
                    },
                    alignment: {
                        wrapText: true,
                        vertical: "top"
                    }
                }
            })

            //테두리, 배경색 설정
            formData.forEach((element, index) => {
                ws.getColumn(index + 1).style = {
                    fill: element.nullableFg === '0' ? notnullFill as any : normalFill as any,
                    numFmt: element.columntypeFg === '0' ? '@' : '',
                    border: { top: { style: 'thin', color: { argb: 'FFC0C0C0' } }, right: { style: 'thin', color: { argb: 'FFC0C0C0' } }, bottom: { style: 'thin', color: { argb: 'FFC0C0C0' } }, left: { style: 'thin', color: { argb: 'FFC0C0C0' } } }
                }
                ws.getColumn(index + 1).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
                    cell.border = { top: { style: 'thin', color: { argb: 'FFC0C0C0' } }, right: { style: 'thin', color: { argb: 'FFC0C0C0' } }, bottom: { style: 'thin', color: { argb: 'FFC0C0C0' } }, left: { style: 'thin', color: { argb: 'FFC0C0C0' } } }
                    cell.value = String(cell.value)
                    if (rowNumber === 3) { // 세번째 행은 회색으로 칠함
                        cell.fill = exFill as any
                    } else {
                        if (element.nullableFg === '0') {
                            cell.fill = notnullFill as any
                        }
                    }
                })
            })

            //옵션 설정
            if (option) {
                //시트명 설정
                if (option.sheetName) {
                    wb.getWorksheet(1).name = option.sheetName;
                }
            }

            wb.xlsx.writeBuffer().then((data: ArrayBuffer) => {
                let date = new Date();
                let yyyymmddhhmmss = date.getFullYear() +
                    this.pad2(date.getMonth() + 1) +
                    this.pad2(date.getDate()) +
                    this.pad2(date.getHours()) +
                    this.pad2(date.getMinutes()) +
                    this.pad2(date.getSeconds());
                saveAs(new Blob([data]), fileName ? `${fileName}.xlsx` : `${menuCode}_EXCEL_IMPORT_${yyyymmddhhmmss}.xlsx`)
            })
        })
    }

    private static pad2(n: any) {
        return n < 10 ? `0${n}` : `${n}`;
    }

    /**
     * EXCEL IMPORT, EXCEL 양식생성 시 필요한 컬럼의 정보를 저장함.
     * @param {*} ajaxEbp 로그인 후 쿠키를 가지고있는 상태의 ajaxEbp
     * @param {Array<FormData>} formData 엑셀 양식 데이터
     * @param {string} companyCode 회사코드
     * @param {string} menuCode 메뉴코드
     * @param {string} tableName 테이블명
     */
    public static setExcelFormData(ajaxEbp: any, formData: Array<FormData>, companyCode: string, menuCode: string, tableName: string): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            ajaxEbp.post(`${url}/system/common/setexcelformdata`, {
                formData: JSON.stringify(formData),
                companyCode: companyCode,
                menuCode: menuCode,
                tableName: tableName
            }, {
                contextType: "application/json",
                async: false
            }).then(res => {
                let result = res;//JSON.parse(res)
                if (!result || !(result.resultCode === 0)) {
                    reject(null)
                }
                resolve();
            }).catch(err => {
                reject(err)
            })
        });
    }
}