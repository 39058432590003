import React from "react";
import moment from "moment";
import { OBTDropDownList, OBTTextField, OBTDatePeriodPicker, OBTTooltip } from "../../components";
import styles from './updateDocuments.module.scss'

const getClassNames = (...classNames) => {
    return classNames.filter(name => name && name.length > 0).join(' ');
}

const setDate = new Date()
const initFromDate = new Date(setDate.setMonth(setDate.getMonth() - 2))
const initFromDateToString = moment(initFromDate).format("YYYYMMDD");
const initToDateToString = moment(new Date()).format("YYYYMMDD");

export default class FilterBox extends React.Component {
    state = {
        updateType: '',
        warningLevel: '0',
        searchText: '',
        date: {
            from: initFromDateToString,
            to: initToDateToString
        },
    }

    handleSearch = (e) => {
        this.props.onSearch(this.state);
    }

    handleWarningLevelChange = ({ value }) => {
        this.setState({
            warningLevel: value
        })
    }

    handleUpdateTypeChange = (e) => {
        this.setState({
            updateType: e.value
        })
    }

    handleDateChange = (e) => {
        this.setState({ date: e.value })
    }

    handleComponentNameChange = (e) => {
        this.setState({
            searchText: e.value
        })
    }
    handleSearchTextResetButtonClick = () => {
        this.setState({
            searchText: '',
        })
    }

    handleClearFilterButtonClick = () => {
        this.setState({
            updateType: '',
            warningLevel: '0',
            searchText: '',
            date: {
                from: '',
                to: ''
            },
        }, () => this.props.onSearch(this.state))
    }

    handleMoveFocus = ({ event, direction }) => {
        switch (direction) {
            case 'enter':
                this.props.onSearch(this.state);
                break;
            default:
                break;
        }
    }

    render() {
        const updateType = [
            { value: '', labelText: 'All' },
            { value: 'doc', labelText: '일반' },
            { value: 'del', labelText: '폐기' },
            { value: 'imp', labelText: '기능개선' },
            { value: 'new', labelText: '새컴포넌트' },
            { value: 'bug', labelText: '버그개선' },
            { value: 'style', labelText: '스타일개선' },
        ];

        const warningLevel = [
            { value: '0', labelText: 'All' },
            { value: '1', labelText: '일반' },
            { value: '2', labelText: '중요' },
            { value: '3', labelText: '긴급' },
        ];

        return (
            <div className={styles.filterBoxWrap}>
                <div className={styles.searchTextBox}>
                    <OBTTextField
                        value={this.state.searchText}
                        onChange={this.handleComponentNameChange}
                        placeHolder='검색어를 입력하세요'
                        onMoveFocus={this.handleMoveFocus}
                    />
                    <button className={styles.searchTextResetButton} onClick={this.handleSearchTextResetButtonClick} />
                    <button className={styles.searchBtn} onClick={this.handleSearch}>Find it now</button>
                </div>
                <div className={styles.filterBox}>
                    <div className={getClassNames(styles.filter, styles.filter1)}>
                        <h4>Type :</h4>
                        <OBTDropDownList
                            list={updateType}
                            value={this.state.updateType}
                            onChange={this.handleUpdateTypeChange}
                            displayType={OBTDropDownList.DisplayType.text}
                            className={styles.updateType}
                            width='100%'
                        />
                    </div>
                    <div className={getClassNames(styles.filter, styles.filter1)}>
                        <h4>Warning Level :</h4>
                        <OBTDropDownList
                            list={warningLevel}
                            value={this.state.warningLevel}
                            onChange={this.handleWarningLevelChange}
                            displayType={OBTDropDownList.DisplayType.text}
                            className='warningLevel'
                            width='100%'
                        />
                    </div>
                    <div className={getClassNames(styles.filter, styles.filter2)}>
                        <h4>Date :</h4>
                        <div className={styles.dateWrap}>
                            <OBTDatePeriodPicker
                                value={this.state.date}
                                onChange={this.handleDateChange}
                            />
                        </div>
                    </div>
                    <div className={getClassNames(styles.clearFilter)} >
                        <OBTTooltip labelText='필터 상태를 초기로 되돌립니다' position='right' theme='blue'>
                            <span className={styles.clearFilterBtn} onClick={this.handleClearFilterButtonClick}>Clear all</span>
                        </OBTTooltip>
                    </div>
                </div>
            </div>
        )
    }
}