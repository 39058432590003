/**
 * OBTTimePicker
 * @version 0.1
 * @author 박재성
 * @see LUXTimePicker
 */
import * as React from 'react';
import LUXTimePicker from './LUXTimePicker';
import { Events, CompositeProps, Util, createPropDefinitions, CommonType, CommonDefinitions, toEnumType } from '../Common';
import OBTTooltip, { IOBTTooltip } from '../OBTTooltip/OBTTooltip';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import GuideMessageRenderer from '../Common/GuideMessageRenderer';
import { OBTContext } from '../OBTPageContainer/OBTPageContainer';
import { removeTrashElement } from '../Common/OrbitInternalUtil';

enum IntervalType {
    'hour' = 'hour',
    'half' = 'half'
}

interface IOBTTimePicker extends CompositeProps.InputClass<string>, Events.onValidate<string>, Events.onClick, Events.onMouseDown,
    Events.onMouseMove, Events.onMouseUp, Events.onMouseLeave, Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress, Events.onKeyUp {
    tooltip?: IOBTTooltip,
    /**
     * @default half
     * list의 시간 간격을 1시간단위 또는 30분단위로 설정합니다.
     * : hour | half
     */
    type: IntervalType,
    shouldDisableTime?: (e: string) => boolean
}

interface State extends hasError {
}

export default class OBTTimePicker extends React.Component<IOBTTimePicker, State> {
    context!: React.ContextType<typeof OBTContext>;

    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.InputClass(CommonType.string),
        { name: "type", type: toEnumType(IntervalType), optional: true, description: "list의 시간 간격을 1시간단위 또는 30분단위로 설정합니다.", default: "half" },
        CommonDefinitions.onValidate(),
        CommonDefinitions.focus(),
        CommonDefinitions.Event(),
        CommonDefinitions.tooltip(),
    )

    public static Type = IntervalType

    public static defaultProps = {
        disabled: false,
        readonly: false,
        required: false,
        type: IntervalType.half
    }

    public state: State = {}

    public myRefs = {
        id: React.createRef<LUXTimePicker>()
    }

    private getWidthHeightStyle(props: { width?: string, height?: string }): any {
        let style: any = {};

        if (props.width && props.width.length > 0) {
            if (props.width === '100%') {
                style.width = 'auto';
            } else {
                style.width = props.width;
            }
        }
        if (props.height && props.height.length > 0) {
            if (props.height === 'auto') {
                style.height = '22px';
            } else {
                style.height = props.height;
            }
        }

        return style;
    }

    public focus(): void {
        if (this.myRefs.id.current) {
            this.myRefs.id.current.focus();
        }
    }

    public blur(): void {
        if (this.myRefs.id.current) {
            this.myRefs.id.current.timePickerFocusOut();
        }
    }

    get canEdit(): boolean {
        return !this.props.disabled && !this.props.readonly;
    }

    private handleMoveFocus = (direction: string): void => {
        Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
    }

    private handleFocus = () => {
        Util.invokeEvent<Events.EventArgs>(this.props.onFocus, new Events.EventArgs(this));
        GuideMessageRenderer.handleFocus(
            this, this.props.id, this.context
        );
    }

    private handleChange = (value: string): void => {
        if (this.canEdit) {
            let oldValue = this.props.value;
            let newValue = value
            if (Util.invokeEvent<Events.ValidateEventArgs<string>>(this.props.onValidate, new Events.ValidateEventArgs(this, oldValue, newValue))) {
                Util.invokeEvent<Events.ChangeEventArgs<string>>(this.props.onChange, new Events.ChangeEventArgs<string>(this, newValue));
            }
        }

        Util.invokeEvent<Events.ChangeEventArgs<string>>(this.props.onChange, new Events.ChangeEventArgs<string>(this, value));
    }

    private handleBlur = () => {
        Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
        removeTrashElement();
        GuideMessageRenderer.handleBlur(
            this.context,
            this.props.id,
        );
    }

    renderComponent = () => {
        const props = {
            disabled: this.props.disabled,
            readonly: this.props.readonly,
            onBlur: this.handleBlur,
            onChange: this.handleChange,
            onFocus: this.handleFocus,
            onKeyDown: this.props.onKeyDown,
            onKeyUp: this.props.onKeyUp,
            style: Object.assign({}, this.props.frozen ? { "pointerEvents": "none" } : undefined),
            time: this.props.value,
            timePickerStyle: Object.assign({}, Util.getInputStateStyle(this.props), this.getWidthHeightStyle(this.props)),
            timePickerResultBoxStyle: { maxHeight: '220px' },
            viewmode: this.props.type,
            onMoveFocus: this.handleMoveFocus,
            shouldDisableTime: this.props.shouldDisableTime
        };
        return (
            <OBTTooltip {...this.props.tooltip}
                className={this.props.className}
                overrideSize={false}
                onClick={this.props.onClick}
                onMouseDown={this.props.onMouseDown}
                onMouseMove={this.props.onMouseMove}
                onMouseUp={this.props.onMouseUp}
                onMouseLeave={this.props.onMouseLeave}
                onMouseEnter={this.props.onMouseEnter}
                onKeyPress={this.props.onKeyPress}
                onFocus={this.handleFocus}
                rootProps={{
                    id: this.props.id,
                    'data-orbit-component': 'OBTTimePicker'
                }}>
                <LUXTimePicker ref={this.myRefs.id} {...props} />
            </OBTTooltip>
        )
    }

    render() {
        return (<ErrorBoundary owner={this} render={this.renderComponent} />)
    }
};

OBTTimePicker.contextType = OBTContext;