/**
 * OBTColorPicker.
 * @version 0.1
 * @author 하성준
 * @see common.js
 */
import * as React from 'react';
import { Events, CompositeProps, Util, CommonProps, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import OBTColorPickerMain from './OBTColorPickerMain';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const styles = require('./OBTColorPicker.module.scss');

interface IOBTColorPicker extends CompositeProps.Default, CommonProps.disabled, CommonProps.value<any>, Events.onChange<any> {
    /**
     * colorPicker의 사용 형태
     * basic: 다른 색 선택하기 버튼 클릭 시 dialog가 열림
     * pupUp: 다른 색 선택하기 버튼 클릭 시 컴포넌트만 바뀜(보통 dialog에 둘다 넣기 위해 dialog 씌워서 사용)
     */
    type?: MainType,
    /**
     * 하단의 다른색 선택 버튼을 클릭 시 발생 하는 함수
     */
    onButtonClick?: (e: Events.EventArgs) => void,
    /**
     * Dialog 가 닫힌경우 호출
     */
    onDialogClosed?: () => void
}

enum MainType {
    'basic' = 'basic',
    /**
     *  dialog에 넣어 사용
     */
    'popUp' = 'popUp'
}

interface State extends hasError {
}

class OBTColorPicker extends React.Component<IOBTColorPicker, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        CommonDefinitions.disabled(),
        CommonDefinitions.value({
            description: `컴포넌트의 색상 값 입니다.
ex) 아래 항목 중 하나 이상 사용 가능
hsl: {h: 0, s: 0, l: 0.6078431372549019, a: 1}
hex: "#9b9b9b"
rgb: {r: 155, g: 155, b: 155, a: 1}
hsv: {h: 0, s: 0, v: 0.6078431372549019, a: 1}`
        }),
        {
            name: 'type',
            type: toEnumType(MainType),
            default: 'basic',
            optional: true,
            description: 'basic 은 Panel 형태, popUp 은 Dialog 형태로 ColorPicker 를 제공합니다.'
        },
        CommonDefinitions.onChange(),
        {
            name: 'onButtonClick',
            type: CommonType.function,
            parameters: {
                name: 'e',
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' }
                }
            },
            optional: true,
            description: `하단의 '다른 색 선택하기' 버튼을 클릭했을 때 발생하는 함수입니다.`
        },
        {
            name: 'onDialogClosed',
            type: CommonType.function,
            optional: true,
            description: 'type = popUp 인 경우 대화상자가 닫혔을 때 호출됩니다.'
        }
    );

    public static defaultProps = {
        disabled: false,
        type: MainType.basic,
    }


    public state: State = {
        hasError: false
    }

    public static Type = MainType;

    private handleChange = (value: any): void => {
        Util.invokeEvent<Events.ChangeEventArgs<string>>(this.props.onChange, new Events.ChangeEventArgs<string>(this, value));
    }

    renderComponent = () => {
        return (
            <div className={Util.getClassNames(styles.root, this.props.className, this.props.frozen === true ? styles.frozen : null, this.props.disabled === true ? styles.disabled : null)}
                style={Util.getWrapperStyle(this.props)}
                id={this.props.id}
                data-orbit-component={'OBTColorPicker'}>
                <OBTColorPickerMain
                    value={this.props.value}
                    type={this.props.type}
                    onButtonClick={this.props.onButtonClick}
                    disabled={this.props.disabled}
                    onChange={this.handleChange}
                    onDialogClosed={this.props.onDialogClosed}
                />
            </div>
        )
    }

    render() {
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        )
    }
};

export default OBTColorPicker
