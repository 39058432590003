/**
 * OBTLoading
 * @version 0.1
 * @author 박재성
 * @see LUXFCircularProgress
 */
import * as React from 'react';
import LUXFCircularProgress from 'luna-rocket/LUXProgress/LUXFCircularProgress';
import { CommonDefinitions, CommonProps, CommonType, createPropDefinitions, toEnumType, Util } from '../Common';
import { hasError } from '../Common/CommonState';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { OBTContext } from '../OBTPageContainer/OBTPageContainer';
import OBTPortal from '../OBTPortal';

const styles = require('./OBTLoading.module.scss');

export enum Type {
    'small' = 'small',
    'default' = 'default',
    'large' = 'large'
}

/**
 * PropType 정의
 * Events, CommonProps, CompositeProps 에 미리 지정된 Prop interface 를 충분히 활용한다.
 * extends 로 인터페이스 상속을 통해 사용된다.
 * 공용 Api 를 사용하려면 CommonProps.api 인터페이스를 확장한다.
 */
interface IOBTLoading extends CommonProps.id, CommonProps.className, CommonProps.labelText, CommonProps.width, CommonProps.height {
    value?: string, // 원안의 텍스트값
    type: Type, // 로딩뷰의 원의 크기
    fullScreen: boolean, // 풀스크린 여부
    open: boolean // 여닫 여부
}

/**
 * State 정의
 */
interface State extends hasError {
    actualOpen: boolean
}

/**
* OBTLoading
* Prop : { className }
*/
export default class OBTLoading extends React.Component<IOBTLoading, State> {

    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.id(),
        CommonDefinitions.className(),
        { name: "value", type: CommonType.number, description: "원 안의 퍼센테이지 값을 지정합니다." },
        { name: "labelText", type: CommonType.string, description: "원 아래 텍스트 값을 지정합니다." },
        CommonDefinitions.width(),
        CommonDefinitions.height(),
        { name: "type", type: toEnumType(Type), description: "원의 크기를 지정합니다.", optional: true, default: "default" },
        { name: "fullScreen", type: CommonType.boolean, description: "OBTLoading Modal이 적용되는 범위를 화면가득 채울지 특정 영역만 채울지를 결정 설정합니다.", optional: true, default: true },
        { name: "open", type: CommonType.boolean, default: false, description: "OBTLoading이 열려있는지 닫혀있는지 설정합니다." },
    );

    ///////////////////////////////////////////////////////////////////////////// Initialize
    public static Type = Type

    /**
     * Default Props 설정
     */
    public static defaultProps = {
        type: Type.default,
        fullScreen: true, // 풀스크린 여부
        open: false,
        labelText: 'Loading...',
        value: 'Loading...'
    }

    /**
     * State 정의
     */
    public state: State = {
        actualOpen: false,
    }

    private readonly LAZY_SHOW_TIMEOUT = 80

    ///////////////////////////////////////////////////////////////////////////// Life Cycle API

    // component 가 render 될때 호출됨.
    render() {
        return <OBTContext.Consumer>
            {
                value => {
                    const resultDOM = (
                        <ErrorBoundary
                            owner={this}
                            render={this.renderComponent}
                        />
                    )

                    if (this.props.fullScreen === true && React.Children.count(this.props.children) === 0) {
                        return <OBTPortal>
                            {value.isPageVisible === true ? resultDOM : <></>}
                        </OBTPortal>;
                    } else {
                        return (value.isPageVisible === true ? resultDOM : <></>);
                    }
                }
            }
        </OBTContext.Consumer>
    }

    renderComponent = () => {
        const defualtColor = "#1C90FB"

        const value = this.props.type === OBTLoading.Type.large ? this.props.value : "";
        const labelText = (this.props.type === OBTLoading.Type.default || this.props.type === OBTLoading.Type.small) ? <span className={styles.labelTextSpan}>{this.props.labelText}</span> : "";

        const props = {
            type: "small",
            color: defualtColor,
            innerText: value,
            size: this.props.type === Type.small ? 21 : this.props.type === Type.default ? 63 : 191,
            guideText: labelText,
            visible: this.props.open,
            dimmedStyle: { background: '#fff' },
            style: {
                overflow: 'hidden'
            }
        };

        const component = <LUXFCircularProgress {...props} />

        const windowBackground = (this.props.type === OBTLoading.Type.default || this.props.type === OBTLoading.Type.large) ? styles.windowBackground : undefined;

        const boxSize = this.props.type === OBTLoading.Type.small ? styles.small :
            this.props.type === OBTLoading.Type.default ? styles.default : styles.large

        const boxBackground = (this.props.type === OBTLoading.Type.default || this.props.type === OBTLoading.Type.small) ? styles.boxBackground : undefined;

        const baseComponent =
            <div id={this.props.id} data-orbit-component={'OBTLoading'} className={Util.getClassNames(styles.root, this.props.fullScreen ? styles.full : styles.notFull, this.props.open ? null : styles.closed, this.props.fullScreen ? this.props.className : null)}>
                <div className={Util.getClassNames(styles.wrapper)}>
                    {windowBackground && this.props.open && this.state.actualOpen ?
                        <Fade duration={3000}>
                            <div className={windowBackground}></div>
                        </Fade> : null}
                    {this.props.open && this.state.actualOpen ?
                        <Bounce duration={300}>
                            <div className={Util.getClassNames(styles.box, boxSize, boxBackground)}>
                                {component}
                            </div>
                        </Bounce> : null}
                </div>
            </div>

        const fullComponent =
            <>
                {this.props.children}
                {baseComponent}
            </>

        const size = {
            width: this.props.width,
            height: this.props.height
        }

        const sectionComponent =
            <div id={this.props.id} data-orbit-component={'OBTLoading'} className={Util.getClassNames(styles.section, this.props.className)} style={size}>
                {this.props.children}
                {baseComponent}
            </div>

        return (this.props.fullScreen ? fullComponent : sectionComponent);
    }

    // 키 입력 방지
    componentDidMount() {
        if (this.props.open === true) {
            setTimeout(() => {
                this.setState({
                    actualOpen: true
                })
            }, this.LAZY_SHOW_TIMEOUT);
        }

        this.setEvent();
    }

    componentDidUpdate(prevProps: IOBTLoading) {
        if (this.props.open !== prevProps.open) {
            if (this.props.open === true) {
                setTimeout(() => {
                    this.setState({
                        actualOpen: true
                    })
                }, this.LAZY_SHOW_TIMEOUT);
            } else {
                this.setState({
                    actualOpen: false
                })
            }
        }

        this.setEvent();
    }

    componentWillUnmount() {
        this.setEvent(true);
    }

    private setEvent = (remove?: boolean) => {
        document.removeEventListener('keydown', this.handleKeyEvents, true);
        if (!remove && this.props.fullScreen && this.props.open) {
            document.addEventListener('keydown', this.handleKeyEvents, true);
        }
    }

    private handleKeyEvents = (e: KeyboardEvent) => {
        e.stopPropagation();
        e.preventDefault();
    }
};
