/**
 * OBTDockPanel
 * @version 0.1
 * @author 안광진
 * @see common.js
 */
import * as React from 'react';
import { Events, CompositeProps, Util, createPropDefinitions, CommonDefinitions, CommonType } from '../Common';
import throttle from 'lodash.throttle';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import OBTDialog2 from '../OBTDialog2';
import OBTDialog from '../OBTDialog';
const styles = require('./OBTDockPanel.module.scss');

/**
 * 컴포넌트의 위치를 정의하는 속성입니다.
 */
enum Dock {
    'top' = 'top',
    'left' = 'left',
    'right' = 'right',
    'bottom' = 'bottom'
}

/**
 * dock으로 설정된 resizable, collapsed, size를 정의하는 속성입니다.
 */
interface IDockSideInfo {
    /**
     * 	dock으로 설정된 사이즈조절 여부를 정의하는 속성입니다.
     */
    resizable: boolean,
    /**
     * dock으로 설정된 열림 / 닫힘 여부를 정의하는 속성입니다.
     */
    collapsed: boolean,
    /**
     * dock으로 설정된 사이즈를 정의하는 속성입니다.
     */
    size: number,
    minSize?: number,
    maxSize?: number,
    overflow?: string
}

/**
 * dock으로 설정된 IDockSideInfo를 정의하는 속성입니다.
 */
interface IDockInfo {
    left?: IDockSideInfo,
    top?: IDockSideInfo,
    right?: IDockSideInfo,
    bottom?: IDockSideInfo
}

/**
 * PropType 정의
 * Events, CommonProps, CompositeProps 에 미리 지정된 Prop interface 를 충분히 활용한다.
 * extends 로 인터페이스 상속을 통해 사용된다.
 * 공용 Api 를 사용하려면 CommonProps.api 인터페이스를 확장한다.
 */
interface IOBTDockPanel extends CompositeProps.Default {
    /**
     * DockPanel 의 각 Dock항목에 대한 추가 조건.
     * value 를 지정한 경우 onChange 이벤트를 반드시 핸들링 해야 합니다.
     * 각 dock 항목중, resizable 를 지정한 경우 size 항목이 반드시 지정되야 합니다.
    */
    value?: IDockInfo,
    onChange?: (e: Events.ChangeEventArgs<IDockInfo>) => void,
    children?: any
}

/**
 * @internal
 * State 정의
 */
interface State extends hasError {
    resizing: boolean
}

class Panel {
    constructor(private readonly owner: OBTDockPanel, private readonly dock: Dock) {
    }

    public ref: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()
    public resizerRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()
    public handleMouseDown(e: MouseEvent) {
        this.owner.handleMouseDown(this.dock, e);
    }
    private getValue(props: IOBTDockPanel) {
        return Object.assign({
            left: {
                resizable: false,
                collapsed: false,
                size: NaN,
                minSize: NaN,
                maxSize: NaN
            },
            top: {
                resizable: false,
                collapsed: false,
                size: NaN,
                minSize: NaN,
                maxSize: NaN
            },
            right: {
                resizable: false,
                collapsed: false,
                size: NaN,
                minSize: NaN,
                maxSize: NaN
            },
            bottom: {
                resizable: false,
                collapsed: false,
                size: NaN,
                minSize: NaN,
                maxSize: NaN
            }
        }, props.value || {})[this.dock];

    }

    public setMouseDownHandler(props: IOBTDockPanel) {
        if (this.getValue(props).resizable && this.resizerRef.current) {
            this.resizerRef.current.removeEventListener('mousedown', this.handleMouseDown.bind(this));
            this.resizerRef.current.addEventListener('mousedown', this.handleMouseDown.bind(this));
        }
    }
    public getClassName(props: IOBTDockPanel) {
        return Util.getClassNames(styles[this.dock],
            (this.getValue(props).resizable ? styles.resizable : ''),
            (this.getValue(props).collapsed ? styles.collapsed : ''));
    }
    public getResizer(props: IOBTDockPanel) {
        return this.getValue(props).resizable ? <div className={styles.resizer + ' ' + styles[this.dock]} ref={this.resizerRef} /> : <></>;
    }
    public getStyle(props: IOBTDockPanel) {
        const value = this.getValue(props);
        let size = this.getValue(props).size;
        if (value.resizable === true && Number.isNaN(size)) {
            size = 300;
        }
        return Object.assign({
            overflow: value.overflow || undefined
        }, !Number.isNaN(size) ?
            (this.dock === Dock.top || this.dock === Dock.bottom) ? { height: size } : { width: size } : {});
    }
}

export default class OBTDockPanel extends React.Component<IOBTDockPanel, State> {
    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        {
            name: "value", type: {
                left: { type: ['IDockSideInfo'], optional: true, description: "왼쪽 영역 지정" },
                top: { type: ['IDockSideInfo'], optional: true, description: "위쪽 영역 지정" },
                right: { type: ['IDockSideInfo'], optional: true, description: "오른쪽 영역 지정" },
                bottom: { type: ['IDockSideInfo'], optional: true, description: "아래쪽 영역 지정" },
            }, optional: true, description: "DockPanel 의 각 Dock항목에 대한 추가 조건."
                + "\n사용방식)"
                + "\n value = {"
                + "\n   top: {"
                + "\n      resizable: boolean //사이즈 변경 가능 여부"
                + "\n      collapsed: boolean //영역의 접힘, 펼침 여부를 지정할 수 있습니다."
                + "\n      size: number, //resizable:true시 size를 반드시 지정하여야합니다."
                + "\n      minSize: number, //최소 몇 px까지 줄어들지 지정할 수 있습니다."
                + "\n      maxSize: number //최대 몇 px까지 늘어날지 지정할 수 있습니다."
                + "\n      overflow: string //영역을 벗어나는 콘텐츠에대한 처리여부를 지정."
                + "\n      → hidden / scroll / visible 등 css의 overflow와 동일하게 사용가능."
                + "\n   },\n"
                + "\n   right: {"
                + "\n      resizable: boolean //사이즈 변경 가능 여부"
                + "\n      collapsed: boolean //영역의 접힘, 펼침 여부를 지정할 수 있습니다."
                + "\n      size: number, //resizable:true시 size를 반드시 지정하여야합니다."
                + "\n      minSize: number, //최소 몇 px까지 줄어들지 지정할 수 있습니다."
                + "\n      maxSize: number //최대 몇 px까지 늘어날지 지정할 수 있습니다."
                + "\n      overflow: string //영역을 벗어나는 콘텐츠에대한 처리여부를 지정."
                + "\n      → hidden / scroll / visible 등 css의 overflow와 동일하게 사용가능."
                + "\n   },"
                + "\n     ... top, right, bottom, left 동일"
                + "\n }"
                + "\n* value 를 지정한 경우 onChange 이벤트를 반드시 핸들링 해야 합니다."
                + "\n* 각 dock 항목중, resizable 를 지정한 경우 size 항목이 반드시 지정되야 합니다."
        },
        {
            name: "onChange", type: CommonType.function, optional: true, parameters: {
                name: "e",
                type: {
                    target: { type: CommonType.any, description: "호출자" },
                    value: { type: ['IDockInfo'], description: "값" }
                }
            }, description: "사이즈 변경 등 dock 설정이 변경된 경우 호출"
        }
    );

    public static IDockSideInfo = createPropDefinitions(
        { name: "resizable", type: CommonType.boolean, description: "dock으로 설정된 사이즈조절 여부를 정의하는 속성입니다." },
        { name: "collapsed", type: CommonType.boolean, description: "dock으로 설정된 열림 / 닫힘 여부를 정의하는 속성입니다." },
        { name: 'size', type: CommonType.number, description: 'dock으로 설정된 사이즈를 정의하는 속성입니다.' },
        { name: 'minSize', type: CommonType.number, description: '최소 단위 크기', optional: true },
        { name: 'maxSize', type: CommonType.number, description: '최대 단위 크기', optional: true },
        { name: 'overflow', type: CommonType.string, description: 'overflow inline-style 을 지정할 수 있습니다.', optional: true }
    );

    ///////////////////////////////////////////////////////////////////////////// Initialize
    public static Dock = Dock

    /**
     * Default Props 설정
     */
    public static defaultProps = {
        width: '100%',
        height: '100%'
    }

    private get value() {
        return Object.assign({
            left: {
                resizable: false,
                collapsed: false,
                size: NaN,
                minSize: NaN,
                maxSize: NaN
            },
            top: {
                resizable: false,
                collapsed: false,
                size: NaN,
                minSize: NaN,
                maxSize: NaN
            },
            right: {
                resizable: false,
                collapsed: false,
                size: NaN,
                minSize: NaN,
                maxSize: NaN
            },
            bottom: {
                resizable: false,
                collapsed: false,
                size: NaN,
                minSize: NaN,
                maxSize: NaN
            }
        }, this.props.value);
    }

    /**
     * State 정의
     */
    public state: State = {
        resizing: false
    }

    private panels = {
        top: new Panel(this, Dock.top),
        left: new Panel(this, Dock.left),
        right: new Panel(this, Dock.right),
        bottom: new Panel(this, Dock.bottom)
    }

    /**
     * Ref 정의
     */
    public myRefs = {
        root: React.createRef<HTMLDivElement>(),
        fill: React.createRef<HTMLDivElement>()
    }

    ///////////////////////////////////////////////////////////////////////////// Life Cycle API
    componentDidMount() {
        try {
            Object.values(this.panels).forEach((panel) => panel.setMouseDownHandler(this.props));
        }
        catch (e) {
            Util.getErrorState(e);
        }

    }

    shouldComponentUpdate(nextProps: IOBTDockPanel, nextState: State): boolean {
        try {
            return this._resize.resizing === true ? false : true;
        }
        catch (e) {
            Util.getErrorState(e);
            return false;
        }
    }

    componentDidUpdate(prevProps: IOBTDockPanel) {
        try {
            const setTransitionAdjustment = (dockSide: string) => {
                if (prevProps.value && prevProps.value[dockSide] && this.props.value && this.props.value[dockSide] &&
                    prevProps.value[dockSide].collapsed !== this.props.value[dockSide].collapsed) {
                    if (this.panels[dockSide].ref.current) {
                        if (this.panels[dockSide].ref.current.style.overflow !== 'hidden') {
                            const overflow = this.panels[dockSide].ref.current.style.overflow;
                            const transitionEnd = (e: TransitionEvent) => {
                                if (this.panels[dockSide].ref.current) {
                                    this.panels[dockSide].ref.current.style.overflow = overflow;
                                    this.panels[dockSide].ref.current.removeEventListener('transitionend', transitionEnd);
                                }
                            }
                            this.panels[dockSide].ref.current.addEventListener('transitionend', transitionEnd);
                            this.panels[dockSide].ref.current.style.overflow = 'hidden';
                        }
                    }
                }
            }
            setTransitionAdjustment('top');
            setTransitionAdjustment('left');
            setTransitionAdjustment('right');
            setTransitionAdjustment('bottom');
            Object.values(this.panels).forEach((panel) => panel.setMouseDownHandler(this.props));
        }
        catch (e) {
            Util.getErrorState(e);
        }
    }

    // component 가 render 될때 호출됨.
    render() {
        return <ErrorBoundary owner={this} render={this.renderComponent} />
    }

    renderComponent = () => {
        const topChildren: any[] = [], bottomChildren: any[] = [], leftChildren: any[] = [], rightChildren: any[] = [], fillChildren: any[] = [];
        React.Children.toArray(Util.mapChildren(this.props.children, (child: any) => {
            if (React.isValidElement(child) && (child.type !== OBTDialog2 && child.type !== OBTDialog)) {
                const childProps = child.props as any;
                let newProps = {};
                switch (childProps['dock']) {
                    case Dock.top:
                    case Dock.bottom:
                        if (Util.isOrbitComponent(child.type)) {
                            newProps['width'] = '100%';
                        } else {
                            newProps['style'] = Object.assign({}, childProps['style'] || {}, { width: '100%' });
                        }
                        break;
                    case Dock.left:
                    case Dock.right:
                        if (Util.isOrbitComponent(child.type)) {
                            newProps['height'] = '100%';
                        } else {
                            newProps['style'] = Object.assign({}, childProps['style'] || {}, { height: '100%' });
                        }
                        break;
                    default:
                        if (Util.isOrbitComponent(child.type)) {
                            newProps['width'] = '100%';
                            newProps['height'] = '100%';
                        } else {
                            newProps['style'] = Object.assign({}, childProps['style'] || {}, { width: '100%', height: '100%' });
                        }
                        break;
                }
                return newProps;
            }
            return null;
        })).forEach((child) => {
            switch (child['props']['dock']) {
                case Dock.top: topChildren.push(child); break;
                case Dock.bottom: bottomChildren.push(child); break;
                case Dock.left: leftChildren.push(child); break;
                case Dock.right: rightChildren.push(child); break;
                default: fillChildren.push(child); break;
            }
        });
        const rootProps = {
            className: Util.getClassNames(styles.root, this.props.className),
            style: Util.getWrapperStyle(this.props),
        }
        return (
            <div {...rootProps} ref={this.myRefs.root} id={this.props.id} data-orbit-component={'OBTDockPanel'}>
                {topChildren && topChildren.length > 0 ?
                    <div className={this.panels.top.getClassName(this.props)} style={this.panels.top.getStyle(this.props)} ref={this.panels.top.ref}>
                        {topChildren}
                        {this.panels.top.getResizer(this.props)}
                    </div> : <></>}
                <div className={styles.middle}>
                    <div className={styles.wrapper}>
                        {leftChildren && leftChildren.length > 0 ?
                            <div className={this.panels.left.getClassName(this.props)} style={this.panels.left.getStyle(this.props)} ref={this.panels.left.ref}>
                                {leftChildren}
                                {this.panels.left.getResizer(this.props)}
                            </div> : <></>}
                        <div className={styles.fill} ref={this.myRefs.fill}><div className={styles.wrapper}>{fillChildren}</div></div>
                        {rightChildren && rightChildren.length > 0 ?
                            <div className={this.panels.right.getClassName(this.props)} style={this.panels.right.getStyle(this.props)} ref={this.panels.right.ref}>
                                {rightChildren}
                                {this.panels.right.getResizer(this.props)}
                            </div> : <></>}
                    </div>
                </div>
                {bottomChildren && bottomChildren.length > 0 ?
                    <div className={this.panels.bottom.getClassName(this.props)} style={this.panels.bottom.getStyle(this.props)} ref={this.panels.bottom.ref}>
                        {bottomChildren}
                        {this.panels.bottom.getResizer(this.props)}
                    </div> : <></>}
            </div>

        )
    }

    ///////////////////////////////////////////////////////////////////////////// Logics

    ///////////////////////////////////////////////////////////////////////////// Event Handlers
    private static _defaultResize = {
        resizing: false,
        target: Dock.top,
        start: { x: -1, y: -1 },
        size: { width: -1, height: -1 },
        maxSize: { width: -1, height: -1 },
        mouseUpHandler: (e: MouseEvent): void => { },
        mouseMoveHandler: (e: MouseEvent): void => { }
    }

    private _resize = Object.assign({}, OBTDockPanel._defaultResize);

    public handleMouseDown(target: Dock, e: MouseEvent) {
        const ref = this.panels[target].ref;
        //document.body.style.pointerEvents = 'none';
        document.body.style.userSelect = 'none';
        this._resize.resizing = true;
        this._resize.target = target;
        this._resize.start = { x: e.clientX, y: e.clientY };
        switch (target) {
            case Dock.left: this._resize.start.x += 4; break;
            case Dock.right: this._resize.start.x -= 4; break;
            case Dock.top: this._resize.start.y += 4; break;
            case Dock.bottom: this._resize.start.y -= 4; break;
        }
        this._resize.size = { width: ref.current!.clientWidth, height: ref.current!.clientHeight };
        this._resize.maxSize = {
            width: this._resize.size.width + this.myRefs.fill.current!.clientWidth,
            height: this._resize.size.height + this.myRefs.fill.current!.clientHeight
        };
        // Remove Transition
        if (ref.current) {
            ref.current.classList.add(styles.resizing);
        }
        this._resize.mouseUpHandler = (e: MouseEvent) => this.handleMouseUp(target, e);
        this._resize.mouseMoveHandler = throttle((e: MouseEvent) => this.handleMoveMove(target, e), 10);
        document.addEventListener('mouseup', this._resize.mouseUpHandler, { capture: true });
        document.addEventListener('mousemove', this._resize.mouseMoveHandler, { capture: true });
        e.preventDefault();
        e.stopPropagation();
    }

    private resize(target: Dock, e: MouseEvent, invokeEvent: boolean = false) {
        if (this._resize.resizing && this._resize.target === target) {
            let newSize = {
                width: target === Dock.left || target === Dock.right ? this._resize.size.width + ((e.clientX - this._resize.start.x) * (target === Dock.right ? -1 : 1)) : NaN,
                height: target === Dock.top || target === Dock.bottom ? this._resize.size.height + ((e.clientY - this._resize.start.y) * (target === Dock.bottom ? -1 : 1)) : NaN,
            };
            let minSize = NaN;
            let maxSize = NaN;
            if (target === Dock.left && this.props.value && this.props.value.left) {
                minSize = this.props.value.left.minSize ? this.props.value.left.minSize : NaN
                maxSize = this.props.value.left.maxSize ? this.props.value.left.maxSize : NaN
            } else if (target === Dock.right && this.props.value && this.props.value.right) {
                minSize = this.props.value.right.minSize ? this.props.value.right.minSize : NaN
                maxSize = this.props.value.right.maxSize ? this.props.value.right.maxSize : NaN
            } else if (target === Dock.top && this.props.value && this.props.value.top) {
                minSize = this.props.value.top.minSize ? this.props.value.top.minSize : NaN
                maxSize = this.props.value.top.maxSize ? this.props.value.top.maxSize : NaN
            } else if (target === Dock.bottom && this.props.value && this.props.value.bottom) {
                minSize = this.props.value.bottom.minSize ? this.props.value.bottom.minSize : NaN
                maxSize = this.props.value.bottom.maxSize ? this.props.value.bottom.maxSize : NaN
            }

            if (newSize.width <= 0) newSize.width = 0;
            if (newSize.height <= 0) newSize.height = 0;
            if (newSize.width > this._resize.maxSize.width) newSize.width = this._resize.maxSize.width;
            if (newSize.height > this._resize.maxSize.height) newSize.height = this._resize.maxSize.height;
            if (e.type === 'mouseup') {
                this._resize.resizing = false;
            }
            if (!Number.isNaN(newSize.width)) {
                if (!Number.isNaN(minSize) && newSize.width < minSize) newSize.width = minSize;
                if (!Number.isNaN(maxSize) && newSize.width > maxSize) newSize.width = maxSize;
                this.panels[target].ref.current!.style.width = `${newSize.width}px`;
                if (invokeEvent) {
                    Util.invokeEvent<Events.ChangeEventArgs<IDockInfo>>(this.props.onChange, new Events.ChangeEventArgs<IDockInfo>(this,
                        Object.assign({}, this.value, {
                            [target]: Object.assign({}, this.value[target] || {}, {
                                size: newSize.width
                            })
                        })));
                }
            }
            if (!Number.isNaN(newSize.height)) {
                if (!Number.isNaN(minSize) && newSize.height < minSize) newSize.height = minSize;
                if (!Number.isNaN(maxSize) && newSize.height > maxSize) newSize.height = maxSize;
                this.panels[target].ref.current!.style.height = `${newSize.height}px`;
                if (invokeEvent) {
                    Util.invokeEvent<Events.ChangeEventArgs<IDockInfo>>(this.props.onChange, new Events.ChangeEventArgs<IDockInfo>(this,
                        Object.assign({}, this.value, {
                            [target]: Object.assign({}, this.value[target] || {}, {
                                size: newSize.height
                            })
                        })));
                }
            }
            e.stopPropagation();
            e.preventDefault();
        } else {
            this.stopResize(target, e);
        }
    }

    private stopResize(target: Dock, e: MouseEvent) {
        if (this._resize.resizing && this._resize.target === target) {
            const ref = this.panels[target].ref;
            // Recover Transition
            if (ref.current) {
                ref.current.classList.remove(styles.resizing);
            }
            this.resize(target, e, true);
            document.removeEventListener('mouseup', this._resize.mouseUpHandler, { capture: true });
            document.removeEventListener('mousemove', this._resize.mouseMoveHandler, { capture: true });
            this._resize = Object.assign({}, OBTDockPanel._defaultResize);
            document.body.style.userSelect = '';
            e.preventDefault();
            e.stopPropagation();
        }
    }

    private handleMoveMove(target: Dock, e: MouseEvent) {
        this.resize(target, e);
    }

    private handleMouseUp(target: Dock, e: MouseEvent) {
        this.stopResize(target, e);
    }
}
