import * as React from 'react';
import { OBTDataGrid, Util } from '../../components';
import ExampleView from "../ExampleComponents";
import memoizeOne from 'memoize-one';
import DetailIcon from "./image/ic_help_m_over.png";
import ResultIcon from "./image/ic_oneflip_m_normal.png";

const styles = require('./GridAttributesPage.module.scss');

let columnList = ['아이콘/이미지/라벨 관련', 'image', 'time', 'number', 'mask', 'dropdown', 'date', 'codePicker', 'check', 'group', 'autoComplete'];
let interfaceList = ['get', 'set', 'check 관련', 'height/width/style 관련', 'providerData 관련', 'treeGrid 관련', 'etc'];
let attributes = {
    common: ExampleView.getAttributeFromPropDefinition(OBTDataGrid.PropDefinitions),
    global: ExampleView.getAttributeFromPropDefinition(OBTDataGrid.GridGlobalOptionDefinitions),
    column: ExampleView.getAttributeFromPropDefinition(OBTDataGrid.IColumnDefinitions),
    interface: ExampleView.getAttributeFromPropDefinition(OBTDataGrid.InterfaceDefinitions),
    event: ExampleView.getAttributeFromPropDefinition(OBTDataGrid.EventDefinitions),
}

export class GridAttributesPage extends React.Component {
    state = {
        isOpenColumnText: false,
        isOpenInterfaceText: false,
        selectedMenu: 'interface',
        selectedDetailList: '',
        refsForAnchor: [],
        parameterDescription: '하늘색 글씨가 포함된 행에 마우스 오버시 자세한 설명이 나옵니다.'
    }

    myRefs = {
        detailAttributeTitle: React.createRef(),
        refsForAnchor: []
    }
    // refForAnchor = [];

    detailLists = (detailLists) => {
        return detailLists.map((list, index) => {
            return (
                <React.Fragment key={index}>
                    &nbsp;&nbsp;
                    <span
                        className={this.state.selectedDetailList === list ? styles.selected : undefined}
                        key={index}
                        onClick={(e) => {
                            this.setState({
                                selectedDetailList: `${list}`
                            }, () => {
                                this.myRefs.refsForAnchor[list].scrollIntoView();
                            })
                        }}

                    >{this.state.selectedDetailList === list ? `* ${list}` : list}</span><br />
                </React.Fragment>
            )
        });
    }


    settingColumnWidth = (selectedMenu) => {
        let colWidth = [];
        if (selectedMenu === 'event' || selectedMenu === 'interface') {
            colWidth = ['20%', '40%', '40%'];
        }
        else if (selectedMenu === 'global') {
            colWidth = ['20%', '15%', '20%', '45%'];
        }
        else colWidth = ['20%', '20%', '10%', '20%', '30%'];
        return colWidth;
    }

    getAttributes = memoizeOne((attributes, searchText) => {
        attributes = attributes && Array.isArray(attributes) ? { 'default': attributes } : attributes;
        const groups = Object.keys(attributes);
        const count = groups.length;
        const { selectedMenu } = this.state;
        const eachColums = (selectedMenu) => {
            return this.settingColumnWidth(selectedMenu).map((col, i) => {
                return <col width={col} key={i} />
            })
        }
        const isInterfaceOrEvent = (selectedMenu) => {
            return selectedMenu === 'interface' || selectedMenu === 'event';
        }
        const isGlobal = (selectedMenu) => {
            return selectedMenu === 'global'
        }

        return groups.map((group, index) => {
            const attrs = attributes[group] || [];
            return (
                <React.Fragment key={index}>
                    {count > 1 ? <div className={styles.attributeTitle}>{group}</div> : undefined}
                    <table className={styles.tableContents}>
                        <colgroup>
                            {eachColums(this.state.selectedMenu)}
                        </colgroup>
                        <thead>
                            <tr>
                                <th>Name</th>
                                {isInterfaceOrEvent(selectedMenu) ? null : <th>Type</th>}
                                {isInterfaceOrEvent(selectedMenu) ? null : <th>Default</th>}
                                {isGlobal(selectedMenu) ? null : <th>TypeDefinition</th>}
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {attrs.map((attribute, index) => {
                                if (attribute.title) {
                                    const titleName = attribute.title.props.children;
                                    return <tr key={index}>
                                        <td
                                            className={styles.detailAttributeTitle}
                                            colSpan={isInterfaceOrEvent(selectedMenu) ?
                                                3 : isGlobal(selectedMenu) ? 4 :
                                                    5}
                                        >
                                            <span ref={refsForAnchor => this.myRefs.refsForAnchor[titleName] = refsForAnchor}>
                                                {attribute.title}
                                            </span>
                                        </td>
                                        {isInterfaceOrEvent(selectedMenu) ? <><td /><td /></> :
                                            isGlobal(selectedMenu) ? <><td /><td /><td /></> :
                                                <><td /><td /><td /><td /></>}
                                    </tr>
                                }
                                if (attribute.tooltip || attribute.resultExample) {
                                    return (
                                        <tr
                                            key={index}
                                            onMouseOver={(e) =>
                                                this.setState({
                                                    parameterDescription: attribute.tooltip ? this.showTooltip(attribute.tooltip) : null,
                                                    resultExample: attribute.resultExample ? attribute.resultExample.props.children : null
                                                })
                                            }
                                            onMouseOut={(e) => {
                                                this.setState({ parameterDescription: "", resultExample: "" })
                                            }}
                                        >
                                            <td>{attribute.name}</td>
                                            {isInterfaceOrEvent(selectedMenu) ? null : <td>{attribute.type}</td>}
                                            {isInterfaceOrEvent(selectedMenu) ? null : <td>{attribute.default}</td>}
                                            {isGlobal(selectedMenu) ? null :
                                                <td>
                                                    {attribute.tooltip && <img src={DetailIcon} alt={DetailIcon} style={{ width: '18px', height: '18px' }} />}
                                                    {attribute.resultExample && <img src={ResultIcon} alt={ResultIcon} style={{ width: '18px', height: '18px' }} />}
                                                    {attribute.sample}
                                                </td>
                                            }
                                            <td>{attribute.description}</td>
                                        </tr>
                                    )
                                }
                                return <tr key={index}>
                                    <td>{attribute.name}</td>
                                    {isInterfaceOrEvent(selectedMenu) ? null : <td>{attribute.type}</td>}
                                    {isInterfaceOrEvent(selectedMenu) ? null : <td>{attribute.default}</td>}
                                    {isGlobal(selectedMenu) ? null : <td>{attribute.sample}</td>}
                                    <td>{attribute.description}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </React.Fragment>);
        });
    });

    showTooltip = (parameters) => {
        if (!parameters) {
            return null;
        }

        return parameters.map((parameter, index) => {
            // 1. 복합 타입
            if (typeof (parameter.type) === "object") {
                // 1. 객체로 있는 경우
                if (Object.keys(parameter.type).length > 1 && !parameter.type.length) {
                    let key = 0;
                    const descriptionResult = [];
                    descriptionResult.push(<React.Fragment key={parameter.name}>{index + 1}. {parameter.name}<br /><br /></React.Fragment>);
                    for (let attr in parameter.type) {
                        key += 1;
                        const paramAttribute = parameter.type[attr];
                        descriptionResult.push(<React.Fragment key={key}>- {attr} {paramAttribute.type} <br />: {paramAttribute.description}<br /><br /></React.Fragment>);
                    }
                    return descriptionResult;
                }
                // 2. ["하나", "둘"]
                else if (parameter.type.length > 0) {
                    let descriptionResult = "";
                    for (let attr in parameter.type) {
                        descriptionResult = descriptionResult + parameter.type[attr] + ','
                    }
                    const attrExistDescription = <React.Fragment key={index}>{index + 1}. {parameter.name} @{descriptionResult}<br />: {parameter.description}<br /><br /></React.Fragment>;
                    const attrNotDescription = <React.Fragment key={index}>{index + 1}. {parameter.name} @{descriptionResult}<br /><br /></React.Fragment>;
                    return parameter.description ? attrExistDescription : attrNotDescription;
                }
            }
            // 2. description 존재
            else if (parameter.description) {
                return <React.Fragment key={index}>{index + 1}. {parameter.name} {parameter.type} <br />: {parameter.description}<br /><br /></React.Fragment>
            }
            // 3. description 미존재
            else if (!parameter.description) {
                return <React.Fragment key={index}><span>{index + 1}. {parameter.name} {parameter.type}</span><br /><br /></React.Fragment>
            }
            return undefined;
        });
    }

    getConditionalAttributes = (selectedMenu) => {
        switch (selectedMenu) {
            case 'global':
                return this.getAttributes(attributes.global)
            case 'interface':
                return this.getAttributes(attributes.interface)
            case 'common':
                return this.getAttributes(attributes.common)
            case 'event':
                return this.getAttributes(attributes.event)
            case 'column':
                return this.getAttributes(attributes.column)
            default:
                return undefined;
        }
    }

    render() {
        return (
            <div className={styles.root}>
                {/* 1. 이름 (전체)*/}
                <div className={styles.title}> DataGrid Attributes </div>
                {/* 1-1. 검색단 (전체)*/}
                {/* <div style={{ height: '150px' }}>
                    
                </div> */}
                <div className={styles.contents}>
                    {/* 2. 속성 anchor (왼쪽)*/}
                    <div className={styles.anchor}>
                        <div className={styles.listBox}>
                            <div className={styles.listItems}>
                                {/* 글로벌옵션 */}
                                <p
                                    className={this.state.selectedMenu === "global" ? styles.selected : undefined}
                                    onClick={(e) => {
                                        this.setState({
                                            selectedMenu: 'global',
                                            isOpenColumnText: false,
                                            isOpenInterfaceText: false
                                        })
                                    }}>
                                    글로벌 옵션
                                </p>
                                {/* 컬럼 옵션 */}
                                <>
                                    <div className={styles.withIcon}>
                                        <p
                                            className={this.state.selectedMenu === "column" ? styles.selected : undefined}
                                            onClick={(e) => {
                                                this.setState({
                                                    selectedMenu: 'column',
                                                    isOpenColumnText: true,
                                                    isOpenInterfaceText: false
                                                })
                                            }}>
                                            컬럼 옵션
                                        </p>
                                        <span
                                            className={Util.getClassNames(styles.arrowIcon, this.state.isOpenColumnText && styles.opened)}
                                            onClick={(e) => {
                                                if (this.state.selectedMenu !== "column") return;

                                                this.setState({
                                                    isOpenColumnText: !this.state.isOpenColumnText
                                                })
                                            }}
                                        />
                                    </div>
                                    <p className={Util.getClassNames(styles.detailList, !this.state.isOpenColumnText && styles.closed)}>
                                        {this.detailLists(columnList)}
                                    </p>
                                </>
                                {/* 인터페이스 옵션 */}
                                <>
                                    <div className={styles.withIcon}>
                                        <p
                                            className={this.state.selectedMenu === "interface" ? styles.selected : undefined}
                                            onClick={(e) => {
                                                this.setState({
                                                    selectedMenu: 'interface',
                                                    isOpenColumnText: false,
                                                    isOpenInterfaceText: true
                                                })
                                            }}>
                                            인터페이스 옵션
                                        </p>
                                        <span
                                            className={Util.getClassNames(styles.arrowIcon, this.state.isOpenInterfaceText && styles.opened)}
                                            onClick={(e) => {
                                                if (this.state.selectedMenu !== "interface") return;

                                                this.setState({
                                                    isOpenInterfaceText: !this.state.isOpenInterfaceText
                                                })
                                            }}
                                        />
                                    </div>
                                    <p className={Util.getClassNames(styles.detailList, !this.state.isOpenInterfaceText && styles.closed)}>
                                        {this.detailLists(interfaceList)}
                                    </p>
                                </>
                                {/* 이벤트 옵션 */}
                                <p className={this.state.selectedMenu === "event" ? styles.selected : undefined}
                                    onClick={(e) => {
                                        this.setState({
                                            selectedMenu: 'event',
                                            isOpenColumnText: false,
                                            isOpenInterfaceText: false
                                        })
                                    }}>이벤트 옵션</p>
                                {/* 공통 옵션 */}
                                <p className={this.state.selectedMenu === "common" ? styles.selected : undefined}
                                    style={{ marginBottom: '20px' }}
                                    onClick={(e) => {
                                        this.setState({
                                            selectedMenu: 'common',
                                            isOpenColumnText: false,
                                            isOpenInterfaceText: false
                                        })
                                    }}>공통 옵션</p>
                                <div style={{ height: '1px', width: '170px', background: 'lightGrey' }}></div>
                                <>
                                    <img src={DetailIcon} alt={DetailIcon} style={{ width: '18px', height: '18px', marginTop: '20px' }} />
                                    <p style={{ paddingTop: '5px', fontWeight: 'bold' }}>Detail Description.</p>
                                    <p style={{ fontSize: '10px', overflow: 'scroll' }}>{this.state.parameterDescription}</p>
                                </>
                                <>
                                    <img src={ResultIcon} alt={ResultIcon} style={{ width: '18px', height: '18px', marginTop: '20px' }} />
                                    <p style={{ paddingTop: '5px', fontWeight: 'bold' }}>Result Example.</p>
                                    <p style={{ fontSize: '10px', overflow: 'scroll' }}>{this.state.resultExample}</p>
                                </>
                            </div>
                        </div>
                    </div>
                    {/* 3. 본문 내용 (그 밖에)*/}
                    <div className={styles.main}>
                        {this.getConditionalAttributes(this.state.selectedMenu)}
                    </div>
                </div>
            </div>
        )
    }
}
