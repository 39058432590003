import React, { Fragment } from "react";
import styles from './ExampleView.module.scss';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import memoizeOne from 'memoize-one';
import { OBTScrollbar } from '../components';
import { CommonType, Util } from "../components/Common";

const getClassNames = (...classNames) => {
    return classNames.filter(name => name && name.length > 0).join(' ');
}
const is = (condition) => {
    return {
        then: (style) => {
            return condition ? style : undefined;
        },
        else: (anything) => {
            return condition ? undefined : anything;
        }
    };
};

export class Example extends React.Component {
    state = {
        collapsed: this.props.isDataGridExample ? true : this.props.collapse ? this.props.collapse.valueOf() : false,
        viewCode: false,
        _collapse: this.props.collapse
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState._collapse !== nextProps.collapse) {
            return {
                _collapse: nextProps.collapse,
                collapsed: nextProps.collapse ? nextProps.collapse.valueOf() : prevState.collapsed,
            };
        }
        return null;
    }

    render() {
        const { example } = this.props;
        return (
            <div className={getClassNames(`example${this.props.index}`, styles.exampleRoot, is(this.state.collapsed).then(styles.collapsed))}>
                <div className={styles.title} onClick={this.state.collapsed ? this.handleCollapse : undefined}>
                    <span onClick={this.handleCollapse}>{example.title}</span>
                    {!this.state.collapsed && example.description &&
                        <div className={styles.description}>
                            {example.description}
                        </div>}
                    {example.extraDescription && this.props.isDataGridExample ?
                        <div className={getClassNames(styles.extraDescription, styles.isDataGridExample)}>
                            {example.extraDescription}
                        </div>
                        : undefined}
                    <div className={getClassNames(styles.collapseButton, is(this.state.collapsed).then(styles.collapsed))} onClick={this.handleCollapse} />
                </div>
                {example.extraDescription && !this.props.isDataGridExample ? (
                    <div className={styles.extraDescription}>
                        {example.extraDescription}
                    </div>
                ) : undefined}
                <div className={styles.component}>
                    {example.component}
                </div>
                {example.code ?
                    <div className={styles.footer}>
                        <div className={getClassNames(styles.viewCodeButton)} onClick={this.handleViewCode} />
                        <span className={getClassNames(styles.viewCodeText)} onClick={this.handleViewCode}>Code View</span>
                    </div> : undefined}
                {is(this.state.viewCode).then(
                    <div className={styles.code}>
                        <SyntaxHighlighter language="jsx" style={tomorrow} customStyle={{ border: 'none' }} showLineNumbers>
                            {example.code}
                        </SyntaxHighlighter>
                    </div>
                )}
            </div>
        );
    }

    handleCollapse = (e) => {
        this.setState({ collapsed: !this.state.collapsed });
        e.stopPropagation();
    };
    handleViewCode = () => this.setState({ viewCode: !this.state.viewCode });
}

export default class ExampleView extends React.Component {
    static getAttributeFromPropDefinition(propDefinition) {
        const getTypeString = (type, depth = 0) => {
            switch (type) {
                case CommonType.string: return 'string';
                case CommonType.number: return 'number';
                case CommonType.boolean: return 'boolean';
                case CommonType.image: return 'image';
                case CommonType.color: return 'color';
                case CommonType.function: return 'function';
                case CommonType.any: return 'any';
                default: break;
            }
            if (Array.isArray(type)) {
                // Enum Type
                if (type.length > 0) {
                    if (!type.find(item => !item.enumName)) {
                        return type.map(item => `${item.enumName}`).join(' | ');
                    }
                }
                // JsonArray 타입
                if (type.length === 1 && typeof type[0] === 'object') {
                    return '[' + getTypeString(type[0], depth).trimStart() + ']';
                }
                // 복합 유형 타입
                return type.map(item => getTypeString(item)).join(',\n');
            } else if (typeof type === 'object') {
                const getJsonTypeString = (name, type, depth) => {
                    const indent = [...new Array(depth || 0)].map(() => ' ').join('');
                    if (type.type) {
                        return `${indent}${name}: ${getTypeString(type.type, (depth || 0) + 2)}${!type.optional ? ', 필수' : ''}${type.description ? `, ${type.description}` : ''}`;
                    } else {
                        const children = Object.keys(type).map(name => {
                            return getJsonTypeString(name, type[name], (depth || 0) + 4);
                        }).join(',\n');
                        if (name) {
                            return `${indent}${name}: {\n${children}\n${indent}}`;
                        } else {
                            return `${indent}{\n${children}\n${indent}}`;
                        }
                    }
                };
                return getJsonTypeString(undefined, type, depth);
            }
            return type;
        }

        const getFunctionDefinition = (definition) => {
            if (definition.type === CommonType.function) {
                if (definition.parameters) {
                    const parameters = Array.isArray(definition.parameters) ? definition.parameters : [definition.parameters];
                    const parameterString = parameters.map(param => {
                        return `${param.name}: ${getTypeString(param.type)}`;
                    }).join(', ');
                    const resultString = definition.result ? getTypeString(definition.result) : 'void';
                    return <pre style={{ fontFamily: 'inherit' }}>{`${definition.name}(` +
                        parameterString +
                        ') => ' + resultString}</pre>;
                }
                return `${definition.name}()`;
            }
        }

        const getFunctionDetailDefinition = (definition) => {
            if (definition.parameters) {
                const parameters = Array.isArray(definition.parameters) ? definition.parameters : [definition.parameters];
                // const parameterString = parameters.map(param => {
                //         return `${getTypeString(param.type)}`;
                //     }).join(', ');
                // const resultString = definition.result ? getTypeString(definition.result) : 'void';
                return parameters;
            }
            return `${definition.name}()`;
        }

        return propDefinition.map(definition => {
            const defaultString = definition.default === undefined || definition.default === null ? '' :
                typeof definition.default === 'object' ? JSON.stringify(definition, null, 2) :
                    typeof definition.default === 'string' ? `'${definition.default}'` :
                        String(definition.default);
            if (definition.title) {
                return {
                    title: <span style={{ fontFamily: 'inherit' }}>{definition.title}</span>
                }
            }
            return {
                name: !definition.optional ? <><span>{definition.name}</span><span style={{ color: '#fb709f', marginLeft: '5px' }}>(필수)</span></> : definition.name,
                type: <pre style={{ fontFamily: 'inherit' }}>{getTypeString(definition.type || '')}</pre>,
                default: defaultString,
                sample: definition.type === CommonType.function ? getFunctionDefinition(definition) : '',
                description: <pre style={{ fontFamily: 'inherit' }}>{definition.description || ''}</pre>,
                tooltip: definition.tooltip ? getFunctionDetailDefinition(definition) : '',
                resultExample: definition.resultExample ? definition.resultExample : ''
            }
        });
    }

    state = (() => {
        const getLocalStorage = (key, def) => {
            const value = localStorage.getItem(key);
            return typeof value === 'undefined' || value === null ? def : value;
        }
        return {
            viewAttributes: false,
            viewAttrFullscreen: true,
            viewAnchor: getLocalStorage('viewAnchor', 'false') === 'true',
            searchText: '',
            viewExtra: undefined,
            exampleCollapse: undefined
        }
    })()

    attributesRoot = React.createRef();
    attributesRef = React.createRef();
    attributesScrollRef = React.createRef();
    contentsWrapRef = React.createRef();
    contentsScrollRef = React.createRef();

    get hasAttributes() { return this.props.attributes ? true : false }

    getAttributes = memoizeOne((attributes, searchText) => {
        attributes = attributes && Array.isArray(attributes) ? { 'default': attributes } : attributes;
        const groups = Object.keys(attributes);
        const count = groups.length;
        const colgroup = <colgroup>
            <col width={'10%'} />
            <col width={'20%'} />
            <col width={'10%'} />
            <col width={'30%'} />
            <col width={'30%'} />
        </colgroup>

        return groups.map((group, index) => {
            const attrs = attributes[group] || [];
            return (
                <Fragment key={index}>
                    {count > 1 ? <div className={styles.attributeTitle}>{group}</div> : undefined}
                    <table className={styles.tableContents}>
                        {colgroup}
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Default</th>
                                <th>TypeDefinition</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {attrs.map((attribute, index) => {
                                return <tr key={index}>
                                    <td>{attribute.name}</td>
                                    <td>{attribute.type}</td>
                                    <td>{attribute.default}</td>
                                    <td>{attribute.sample}</td>
                                    <td>{attribute.description}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </Fragment>);
        });
    })

    getExamples = memoizeOne((examples, exampleCollapse) => {
        return (examples || []).map((example, index) => {
            return (
                <Example
                    key={index}
                    example={example}
                    index={index}
                    collapse={exampleCollapse}
                    isDataGridExample={this.props.isDataGridExample}
                />
            )
        });
    })

    getAnchors = memoizeOne((examples) => {
        return (examples || []).map((example, index) => {
            return (
                <div key={index} className={styles.anchor} onClick={(e) => this.handleAnchor(index)}>
                    {example.title}
                </div>
            );
        })
    });

    getExtraButtons = memoizeOne((extra, viewExtra) => {
        if (extra) {
            return Object.keys(extra).map((key, index) => {
                return (<div key={index} className={getClassNames(styles.extraButton, is(viewExtra && viewExtra === key).then(styles.on))}
                    onClick={() => this.handleViewExtra(key)}>{key}</div>)
            });
        }
        return undefined;
    });

    getExtra = memoizeOne((extra, viewExtra) => {
        if (extra && viewExtra) {
            const component = extra[viewExtra];
            if (component) {
                return (
                    <div className={styles.extraRoot}>
                        {component}
                    </div>
                )
            }
        }
    });

    render() {
        const { componentName, description } = this.props;
        const attributes = this.hasAttributes ? this.getAttributes(this.props.attributes, this.state.searchText) : undefined;
        const examples = this.getExamples(this.props.examples, this.state.exampleCollapse);
        const anchors = this.getAnchors(this.props.examples);
        const extraButtons = this.getExtraButtons(this.props.extra, this.state.viewExtra);
        const extra = this.getExtra(this.props.extra, this.state.viewExtra);

        return (
            <div className={styles.root}>
                <div className={styles.titleRoot}>
                    <div className={styles.componentName}>{componentName}</div>
                    <div className={styles.description}>
                        <span>{description}{(this.state.viewExtra ? ` - ${this.state.viewExtra}` : '')}</span>
                        {this.state.viewExtra ? <div className={styles.closeExtraButton} onClick={() => this.handleViewExtra(this.state.viewExtra)} /> : undefined}
                    </div>
                    {extraButtons}
                    {this.hasAttributes &&
                        <div
                            className={getClassNames(styles.attributeButton, is(this.state.viewAttributes).then(styles.on))}
                            onClick={this.handleViewAttributes}>속성</div>
                    }
                    {this.hasAttributes && this.props.isDataGridExample &&
                        <div
                            className={Util.getClassNames(styles.attributeButton)}
                            onClick={this.handleOpenViewAttributes}>속성 새창 띄우기</div>
                    }
                    <div className={getClassNames(styles.anchorButton, is(this.state.viewAnchor).then(styles.on))}
                        onClick={this.handleViewAnchor}>목록보기</div>
                </div>
                <div className={styles.contentsRoot}>
                    <div ref={this.contentsWrapRef} className={getClassNames(styles.contentsWrap, is(this.state.viewAnchor).then(styles.anchorsShown))}>
                        <OBTScrollbar ref={this.contentsScrollRef} height='100%' width='100%'>
                            <div className={styles.contents}>
                            {this.props.newExample ? this.props.newExample : examples}
                            </div>
                        </OBTScrollbar>
                    </div>
                    <div className={getClassNames(styles.anchors, is(this.state.viewAnchor).then(styles.on))}>
                        <div className={styles.title}>
                            <span>목록</span>
                            <div className={styles.toolbox}>
                                <div className={getClassNames(styles.close)} onClick={this.handleViewAnchor} />
                            </div>
                        </div>
                        {anchors}
                        <div className={styles.footer}>
                            <div className={styles.top} onClick={this.handleAnchorsTop}>맨 위로</div>
                            <div className={styles.fold} onClick={this.handleAnchorsFold}>모두 접기</div>
                            <div className={styles.unfold} onClick={this.handleAnchorsUnfold}>모두 펼치기</div>
                        </div>
                    </div>
                    <div ref={this.attributesRoot}
                        className={getClassNames(styles.attributeRoot,
                            is(this.state.viewAttributes).then(styles.show),
                            is(this.state.viewAttrFullscreen).then(styles.fullscreen))}>
                        <OBTScrollbar ref={this.attributesScrollRef} height='100%' width='100%'>
                            <div
                                ref={this.attributesRef}
                                className={styles.attributes}>
                                <div className={styles.sticky}>
                                    <div className={styles.title}>
                                        <span>속성</span>
                                        <div className={styles.toolbox}>
                                            <div className={styles.top} onClick={this.handleAttributesToTop} />
                                            <div className={getClassNames(styles.full, is(this.state.viewAttrFullscreen).then(styles.on))} onClick={this.handleFullScreen} />
                                            <div className={styles.close} onClick={this.handleViewAttributes} />
                                        </div>
                                    </div>
                                </div>
                                {attributes}
                            </div>
                        </OBTScrollbar>
                    </div>
                    {extra}
                </div>
            </div>
        );
    }

    handleViewExtra = (key) => {
        this.setState({
            viewExtra: this.state.viewExtra === key ? undefined : key,
            viewAttributes: this.state.viewAttributes && this.state.viewAttrFullscreen ? false : this.state.viewAttributes
        });
    }

    handleAttributesToTop = () => {
        if (this.attributesScrollRef.current && this.attributesScrollRef.current.myRefs.root.current) {
            this.attributesScrollRef.current.myRefs.root.current.scrollToTop();
        }
    }

    handleFullScreen = () => {
        this.setState({ viewAttrFullscreen: !this.state.viewAttrFullscreen });
    }

    handleViewAttributes = () => {
        if (!this.state.viewAttributes && this.attributesRoot.current && this.attributesRef.current) {
            const boundary = this.attributesRef.current.getBoundingClientRect();
            this.attributesRoot.current.style.setProperty('--height', `${boundary.height + 10}px`);
        }
        this.setState({
            viewAttributes: !this.state.viewAttributes,
            viewExtra: !this.state.viewAttributes ? undefined : this.state.viewExtra
        });
    }

    handleOpenViewAttributes = () => {
        window.open('#/Documents/GridAttributesPage', 'blank');
    }

    handleViewAnchor = () => {
        this.setState({ viewAnchor: !this.state.viewAnchor }, () => {
            localStorage.setItem('viewAnchor', this.state.viewAnchor);
        })
    }

    handleAnchor = (index) => {
        if (this.contentsWrapRef.current) {
            const example = this.contentsWrapRef.current.querySelector(`.example${index}`);
            if (example) {
                example.scrollIntoView();
            }
        }
    }

    handleAnchorsTop = () => {
        if (this.contentsScrollRef.current && this.contentsScrollRef.current.myRefs.root.current) {
            this.contentsScrollRef.current.myRefs.root.current.scrollToTop();
        }
    }

    handleAnchorsFold = () => {
        this.setState({
            // eslint-disable-next-line no-new-wrappers
            exampleCollapse: new Boolean(true)
        });
    }

    handleAnchorsUnfold = () => {
        this.setState({
            // eslint-disable-next-line no-new-wrappers
            exampleCollapse: new Boolean(false)
        });
    }
}
