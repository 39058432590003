/**
 * OBTCodePickerDialog
 * @version 0.1
 * @author 나윤수
 * @see common.js
 */
import React, { Component } from 'react';
import { OBTDialog2 } from '../../..';
import { Events } from '../../../Common';
import { ChangeEventArgs } from '../../../Common/Events';
import DaumPostCode from '../../../DaumPostCode';
import { IPostCodeData } from '../../../DaumPostCode/IPostCodeData';
import { IDefaultDialog } from '../../BaseComponent/DefaultDialog';
import OBTLoading from '../../../OBTLoading';
import { OrbitInternalLangPack } from '../../../Common/Util';

interface IState {
    isReady: boolean,
}

/**
 * 우편번호 코드도움
 */
export default class PostCodeDialog extends Component<IDefaultDialog, IState> {

    state: IState = {
        isReady: false
    }

    public static defaultProps = {
        width: "300px",
        height: "700px"
    };

    private handleChange = (e: ChangeEventArgs<IPostCodeData>) => {
        this.props.onChangeSelectedItems([e.value]);
        this.props.onConfirmSelectedItems();
    }

    private handleReady = () => {
        this.setState({
            isReady: true
        })
    }

    render() {
        return (
            <OBTDialog2
                title={this.props.title}
                open={this.props.isShow}
                subTitle={this.props.subTitle}
                width={this.props.width}
                height={this.props.height}
                buttons={[OBTDialog2.Button.Close((e: any) => {
                    this.props.onClickClose(new Events.EventArgs(this));
                })]}
            >
                <OBTLoading value={OrbitInternalLangPack.getText('WE000022455', '로딩')}
                    type={OBTLoading.Type.default}
                    open={this.state.isReady === false}
                    fullScreen={true}
                />
                <DaumPostCode
                    searchText={this.props.searchKeyword}
                    onReady={this.handleReady}
                    onChange={this.handleChange}
                />
            </OBTDialog2>
        )
    }
}
