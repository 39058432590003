import React from 'react'
import { OBTLoading } from '../index';
import uuid from 'uuid/v1';
import { Type } from '../OBTLoading/OBTLoading';

interface IStack {
    key: any,
    lock?: boolean,
    labelText?: string,
}

interface IState {
    key?: string,
    loadings: IStack[],
    type?: Type
}

export default class Loading extends React.Component<{}, IState> {
    state: IState = {
        loadings: []
    }

    public showLoading(labelText?: string, option?: {
        key?: string,
        lock?: boolean,
        type?: Type
    }) {
        return new Promise<void>(resolve => {
            const key = option && option.key ? option.key : uuid();
            this.setState({
                key: this.state.key ? this.state.key : uuid(),
                loadings: this.state.loadings.concat([{ key: key, lock: option ? option.lock : undefined, labelText: labelText }]),
                type: option && option.type ? option.type : undefined
            }, () => {
                resolve(key);
            });
        })
    }

    public hideLoading(option?: {
        clearAll?: boolean,
        key?: string
    }) {
        return new Promise<void>(resolve => {
            let nextLoadings: (IStack[] | null) = null;
            if (option && option.clearAll) {
                nextLoadings = [];
            } if (option && option.key) {
                nextLoadings = this.state.loadings.filter(item => {
                    return item.key === option.key ? false : true;
                });
            } else if (this.state.loadings.length > 0) {
                nextLoadings = this.state.loadings.filter(item => {
                    return item.lock === true ? true : false;
                });
            }
            if (nextLoadings) {
                this.setState({
                    key: nextLoadings.length > 0 ? this.state.key : undefined,
                    loadings: nextLoadings
                }, () => {
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    render() {
        const labelText = this.state.loadings.length > 0 ? this.state.loadings[this.state.loadings.length - 1].labelText : undefined;
        return (
            labelText ?
                <OBTLoading
                    key={this.state.key}
                    type={this.state.type === 'small' ? OBTLoading.Type.small :
                        this.state.type === 'large' ? OBTLoading.Type.large :
                            OBTLoading.Type.default
                    }
                    labelText={labelText}
                    fullScreen={true}
                    value='Loading'
                    open={true}
                /> : <></>
        );
    }
}
