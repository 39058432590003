export default class Pagination {
    /**
     * 총 로우 카운트
     */
    public toTotalCount: number;
    /**
     * 페이지당 로우 카운트
     */
    public rowCountPerPage: number;
    /**
     * 현제 패이지 
     */
    public currentPage: number;
    /** 
     * 페이지 수 
     */
    public get pageCount() { return Math.ceil(this.toTotalCount / this.rowCountPerPage); }
    /**
     * 현재 페이지의 시작인덱스
     */
    public get startRowIndex() { return (this.currentPage - 1) * this.rowCountPerPage + 1; };
    /**
     * 현재 페이지의 마지막 인덱스
     */
    public get endRowIndex() { return this.currentPage * this.rowCountPerPage >= this.toTotalCount ? this.toTotalCount : this.currentPage * this.rowCountPerPage; }


    constructor(
        toTotalCount: number | undefined,
        /**
         * 페이지당 로우 카운트
         */
        rowCountPerPage: number | null,
        /**
         * 현제 패이지 
         */
        currentPage: number | undefined) {
        this.rowCountPerPage = rowCountPerPage || 0;
        this.toTotalCount = toTotalCount ? toTotalCount : 0;
        this.currentPage = currentPage ? currentPage : 1;
    }
}
