/**
 * OBTFormPanel
 * @version 0.1
 * @author 안광진
 * @see common.js
 */
import * as React from 'react';
import { Events, CompositeProps, Util, Functions, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import OBTTooltip from '../OBTTooltip';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import OBTRadioButton, { OBTRadioButtonGroup } from '../OBTRadioButton';
import { OBTContext, IOBTContext, IPageAuthority } from '../OBTPageContainer/OBTPageContainer';
import { OrbitInternalLangPack } from '../Common/Util';
import OBTCheckBox from '../OBTCheckBox';
import { usePageAuthority } from '../Common/CommonProps';
const styles = require('./OBTFormPanel.module.scss');

interface IColumn {
    width: string
}

interface IRefItem {
    rootRowIndex: number,
    rootColIndex: number,
    innerIndex: number,
    ref: React.RefObject<any>,
    id: string
}

interface IMatrixItem {
    rowIndex: number,
    colIndex: number,
    rootRowIndex: number,
    rootColIndex: number,
    refItems: IRefItem[],
    id: string
}

enum labelTextAlignType {
    'left' = 'left',
    'center' = 'center',
    'right' = 'right'
}
/**
 * PropType 정의
 * Events, CommonProps, CompositeProps 에 미리 지정된 Prop interface 를 충분히 활용한다.
 * extends 로 인터페이스 상속을 통해 사용된다.
 * 공용 Api 를 사용하려면 CommonProps.api 인터페이스를 확장한다.
 */
interface IOBTFormPanel extends CompositeProps.ContainerClass, usePageAuthority {
    children?: any,
    requiredTooltip?: any,
    // pageAuthority?: IPageAuthority,
    labelTextAlign?: labelTextAlignType,
    onChange?: (e: Events.EventArgs) => void,
    // usePageAuthority?: boolean
}

/**
 * @internal
 * State 정의
 */
interface State extends hasError {
    matrix: IMatrixItem[],
    refs: IRefItem[],
    firstItem: IRefItem | null,
    lastItem: IRefItem | null,
    children: any,
    propChildren: any,
    handleChildrenMoveFocus: any,
    requiredRefItem: IRefItem | null,
    handleChildrenChange: (e: Events.ChangeEventArgs<any>) => void,
    pageAuthority: IPageAuthority,
}

const UnFocusableTypes = [
    OBTRadioButtonGroup,
    OBTRadioButton
]

export default class OBTFormPanel extends React.Component<IOBTFormPanel, State> implements Functions.IFocusable {
    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.ContainerClass(),
        CommonDefinitions.pageAuthority(),
        { name: "labelTextAlign", type: toEnumType(labelTextAlignType), description: "OBTFormPanel의 label에 들어갈 텍스트의 정렬을 지정할 수 있습니다.", optional: true, default: 'right' },
        {
            name: "onChange", type: CommonType.function, parameters: {
                name: "e",
                type: CommonType.any,
                description: "호출자"
            }, optional: true, description: "FormPanel 내 컴포넌트가 수정된 경우 호출됩니다."
        },
        CommonDefinitions.tooltip(),
        CommonDefinitions.focus(),
        CommonDefinitions.isEmpty(),
        {
            name: 'validate', type: CommonType.function, parameters: {
                name: "boolean",
                type: CommonType.boolean,
                description: "생략시 자동으로 false"
            }, optional: true, description: "패널 내 컴포넌트의 validate 를 호출하여 결과를 리턴합니다. (내장함수)"
                + "\n- required 와 같은 Input이 값이 비어있을 경우 false 반환, 값이 모두 있을 경우 true 반환 "
                + "\n- 파라미터에 true가 들어갈 시, 리턴값이 false 일 경우(required 속성이 적용된 Input이 비어있을 경우) 비어있는 곳에 포커싱 되며"
                + "툴팁을 띄웁니다. ExampleValidate 참고"
        }
    );

    ///////////////////////////////////////////////////////////////////////////// Initialize
    context!: React.ContextType<typeof OBTContext>;

    public static Align = labelTextAlignType
    /**
     * Default Props 설정
     */
    public static defaultProps = {
        labelTextAlign: labelTextAlignType.right,
    }

    /**
     * State 정의
     */
    public state: State = {
        matrix: [],
        refs: [],
        firstItem: null,
        lastItem: null,
        children: null,
        propChildren: null,
        handleChildrenMoveFocus: this.handleChildrenMoveFocus.bind(this),
        handleChildrenChange: this.handleChildrenChange.bind(this),
        requiredRefItem: null,
        pageAuthority: (() => {
            const context = this.context as IOBTContext;
            if (this.props.usePageAuthority !== false && this.props.pageAuthority) {
                if (context && context.pageAuthority) {
                    return {
                        ...context.pageAuthority,
                        ...this.props.pageAuthority
                    }
                } else {
                    return this.props.pageAuthority;
                }
            } else if (this.props.usePageAuthority !== false &&  context && context.pageAuthority) {
                return context.pageAuthority
            } else {
                return {
                    modifyAuthYn: "Y",
                    printAuthYn: "Y",
                    selectAuthType: "C",
                    deleteAuthYn: "Y",
                } as IPageAuthority
            }
        })()
    }

    /**
     * Ref 정의
     */
    public myRefs = {
        table: React.createRef<HTMLTableElement>()
    }

    ///////////////////////////////////////////////////////////////////////////// Life Cycle API
    static getDerivedStateFromProps(nextProps: IOBTFormPanel, prevState: State): any {
        try {
            if (nextProps.children !== prevState.propChildren) {

                const isTypeEquals = (element: any, type: any): boolean => {
                    return element && React.isValidElement(element) && element.type === type
                };

                const matrix: IMatrixItem[] = [];
                const refs: IRefItem[] = [];
                let firstItem: IRefItem | null = null,
                    lastItem: IRefItem | null = null,
                    columnLength: number = 0;
                const children = React.Children.map(nextProps.children, (child) => {
                    if (isTypeEquals(child, 'colgroup')) {
                        let props: any = {};
                        columnLength = React.Children.count(child.props.children);
                        if (columnLength > 0) {
                            props.children = React.Children.map(child.props.children, (child) => {
                                if (isTypeEquals(child, 'col') && child.props['type'] === 'label') {
                                    return React.cloneElement(child, {
                                        className: Util.getClassNames(styles.label, child.props.className)
                                    });
                                }
                                return child;
                            });
                        }
                        return React.cloneElement(child, props);
                    } else if (isTypeEquals(child, 'tbody')) {
                        const tbody = child;
                        const id = child.props.id
                        let rowIndex = 0;
                        let props: any = {};
                        if (React.Children.count(child.props.children) > 0) {
                            props.children = React.Children.map(child.props.children, (child, index) => {
                                if (isTypeEquals(child, 'tr')) {
                                    const nextChild = index + 1 < tbody.props.children.length ? tbody.props.children[index + 1] : null;
                                    const prevChild = index > 0 ? tbody.props.children[index - 1] : null;
                                    let colIndex = 0;
                                    let props: any = {};
                                    if (isTypeEquals(nextChild, 'tr') && React.Children.count(nextChild.props.children) <= 0) {
                                        props.className = Util.getClassNames(styles.breakLineAfter, child.props.className);
                                    }
                                    if (isTypeEquals(prevChild, 'tr') && React.Children.count(prevChild.props.children) <= 0) {
                                        props.className = Util.getClassNames(styles.breakLineBefore, child.props.className);
                                    }
                                    if (React.Children.count(child.props.children) > 0) {
                                        props.children = React.Children.map(child.props.children, (child) => {
                                            if (isTypeEquals(child, 'th') || isTypeEquals(child, 'td')) {
                                                const childProps: any = child.props;
                                                const colSpan = childProps.colSpan ? Number(childProps.colSpan) : 1;
                                                const rowSpan = childProps.rowSpan ? Number(childProps.rowSpan) : 1;
                                                let props: any = {};
                                                let childrenLength = React.Children.count(child.props.children);

                                                while (true) {
                                                    if (((rowIndex, colIndex) => matrix.find((item) => item.rowIndex === rowIndex && item.colIndex === colIndex))(rowIndex, colIndex)) {
                                                        colIndex++;
                                                    } else {
                                                        break;
                                                    }
                                                }

                                                if (childrenLength > 0) {
                                                    let innerIndex = 0;
                                                    let refItems: IRefItem[] = [];
                                                    const children = React.Children.map(child.props.children, (child, index) => {
                                                        if (React.isValidElement(child) && Util.isOrbitComponent(child.type)) {
                                                            const childProps: any = child.props;
                                                            let props: any = {
                                                                onMoveFocus: Util.overrideHandler(childProps.onMoveFocus, prevState.handleChildrenMoveFocus),
                                                                onChange: Util.overrideHandler(childProps.onChange, prevState.handleChildrenChange)
                                                            };
                                                            if (childrenLength === 1 && !childProps.width) {
                                                                // 체크박스 예외 처리
                                                                if (child.type !== OBTCheckBox) {
                                                                    props.width = '100%';
                                                                }
                                                            }

                                                            if (nextProps.disabled === true) {
                                                                props.disabled = true;
                                                            } else {
                                                                if (nextProps.usePageAuthority !== false && prevState.pageAuthority && prevState.pageAuthority.modifyAuthYn === "N") {
                                                                    props.disabled = true;
                                                                }
                                                            }

                                                            if (!child['ref']) {
                                                                props.ref = React.createRef<any>();
                                                            }

                                                            // refs
                                                            const refItem: IRefItem = {
                                                                rootRowIndex: rowIndex,
                                                                rootColIndex: colIndex,
                                                                innerIndex: innerIndex++,
                                                                ref: props.ref || child['ref'],
                                                                id: id
                                                            };

                                                            refItems.push(refItem);
                                                            refs.push(refItem);
                                                            if (firstItem === null) firstItem = refItem;
                                                            lastItem = refItem;

                                                            // let required = false;
                                                            if (prevState.requiredRefItem &&
                                                                prevState.requiredRefItem.rootRowIndex === refItem.rootRowIndex &&
                                                                prevState.requiredRefItem.rootColIndex === refItem.rootColIndex &&
                                                                prevState.requiredRefItem.innerIndex === refItem.innerIndex) {

                                                                const requiredChild = childProps.required;
                                                                const isEmptyValue = childProps.value === '' ||
                                                                    childProps.value === undefined ||
                                                                    childProps.value === null ||
                                                                    Number.isNaN(childProps.value) === true ||
                                                                    (typeof childProps.value === 'object' && childProps.value.length <= 0) ||
                                                                    (typeof childProps.value === 'boolean' && childProps.value === false)

                                                                props.tooltip = {
                                                                    labelText: OrbitInternalLangPack.getText('WE000022686', '필수항목을 반드시 입력해 주십시오.'),
                                                                    value: requiredChild && isEmptyValue ? true : false,
                                                                    theme: OBTTooltip.Theme.required,
                                                                    ...nextProps.requiredTooltip
                                                                }
                                                            }
                                                            return React.cloneElement(child, props);
                                                        }
                                                        return child;
                                                    });
                                                    props.children =
                                                        <div className={styles.contentsWrapper}
                                                            style={{
                                                                justifyContent: isTypeEquals(child, 'th') && nextProps.labelTextAlign === labelTextAlignType.center ? 'center' :
                                                                    isTypeEquals(child, 'th') && nextProps.labelTextAlign === labelTextAlignType.left ? 'flex-start' :
                                                                        isTypeEquals(child, 'th') && nextProps.labelTextAlign === labelTextAlignType.right ? 'flex-end' : undefined
                                                            }}
                                                        >
                                                            {children}
                                                        </div>

                                                    // Matrix
                                                    for (let row = 0; row < rowSpan; row++) {
                                                        for (let col = 0; col < colSpan; col++) {
                                                            matrix.push({
                                                                rowIndex: rowIndex + row,
                                                                colIndex: colIndex + col,
                                                                rootRowIndex: rowIndex,
                                                                rootColIndex: colIndex,
                                                                refItems: refItems,
                                                                id: id
                                                            })
                                                        }
                                                    }
                                                }

                                                colIndex += colSpan;
                                                return React.cloneElement(child, props);
                                            }
                                            return child;
                                        });
                                        rowIndex++;
                                    } else {
                                        props.className = Util.getClassNames(styles.breakLine, child.props.className);
                                        props.children = [React.createElement('td', {
                                            key: 0,
                                            colSpan: columnLength
                                        })];
                                    }
                                    return React.cloneElement(child, props);
                                }
                                return child;
                            });
                        }
                        return React.cloneElement(child, props);
                    }
                    return child;
                });



                return {
                    matrix,
                    refs,
                    firstItem,
                    lastItem,
                    children,
                    propChildren: nextProps.children,
                };
            }
            return null;
        }
        catch (e) {
            return Util.getErrorState(e);
        }

    }

    componentDidMount() {
        try {
            this.setFocusRequired();
        }
        catch (e) {
            Util.getErrorState(e);
        }
    }

    // componentDidUpdate() {
    //     try {
    //         // this.setFocusRequired();
    //     }
    //     catch (e) {
    //         Util.getErrorState(e);
    //     }
    // }

    // component 가 render 될때 호출됨.
    render() {
        return <ErrorBoundary owner={this} render={this.renderComponent} />
    }

    renderComponent = () => {
        const rootProps = {
            id: this.props.id,
            'data-orbit-component': 'OBTFormPanel',
            className: Util.getClassNames(styles.table, this.props.disabled ? styles.disabled : null, this.props.className),
            style: Util.getWrapperStyle(this.props),
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            ref: this.myRefs.table
        }

        return (
            <table {...rootProps}>
                {this.state.children}
            </table>
        );
    }

    ///////////////////////////////////////////////////////////////////////////// Logics
    private getIndex(ref: any): IRefItem | null | undefined {
        const { refs } = this.state;
        return refs.find((item) => item.ref.current === ref);
    }

    private getMaxInnerIndex(rowIndex: number, colIndex: number): number {
        const { matrix } = this.state;
        return (matrix.filter((item) => item.rowIndex === rowIndex && item.colIndex === colIndex)
            .map(item => item.refItems)
            .reduce((accu, curr) => curr, []) || [])
            .map(item => item.innerIndex)
            .reduce((accu, curr) => accu > curr ? accu : curr, -1);
    }

    private setFocus(refCurrent: any, direction: string, shift: boolean = true) {
        const { matrix } = this.state;
        const index = this.getIndex(refCurrent);
        if (index) {
            let rowIndex: number = index.rootRowIndex,
                colIndex: number = index.rootColIndex,
                innerIndex: number = index.innerIndex,
                id: string = index.id,
                useShift: boolean = shift;
            while (true) {
                if (useShift) {
                    switch (direction) {
                        case Events.MoveFocusDirection.up:
                            rowIndex--;
                            innerIndex = this.getMaxInnerIndex(rowIndex, colIndex);
                            break;
                        case Events.MoveFocusDirection.down:
                            rowIndex++;
                            innerIndex = 0;
                            break;
                        case Events.MoveFocusDirection.left:
                            if (innerIndex > 0) {
                                innerIndex--;
                            } else {
                                colIndex--;
                                innerIndex = this.getMaxInnerIndex(rowIndex, colIndex);
                            }
                            break;
                        case Events.MoveFocusDirection.right:
                        case Events.MoveFocusDirection.enter:
                            const rows = ((rowIndex) => matrix.filter((item) => item.rowIndex === rowIndex && item.id === id))(rowIndex);
                            const maxColIndex = rows.reduce((accu, curr) => curr.colIndex > accu ? curr.colIndex : accu, -1);

                            if (innerIndex < this.getMaxInnerIndex(rowIndex, colIndex)) {
                                innerIndex++;
                            }
                            else if (maxColIndex > 1 && this.getMaxInnerIndex(rowIndex, colIndex) > 1) {
                                colIndex++;
                            }
                            else {
                                colIndex++;
                                innerIndex = 0;
                            }
                            break;
                    }
                } else {
                    useShift = true;
                }

                const rows = ((rowIndex) => matrix.filter((item) => item.rowIndex === rowIndex && item.id === id))(rowIndex);
                if (!rows || rows.length <= 0) {
                    Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
                    break;
                } else {
                    const maxColIndex = rows.reduce((accu, curr) => curr.colIndex > accu ? curr.colIndex : accu, -1);
                    if (colIndex < 0 || colIndex > maxColIndex) {
                        switch (direction) {
                            case Events.MoveFocusDirection.left:
                                rowIndex--;
                                colIndex = ((rowIndex) => matrix.filter((item) => item.rowIndex === rowIndex && item.id === id))(rowIndex)
                                    .reduce((accu, curr) => curr.colIndex > accu ? curr.colIndex : accu, -1);
                                innerIndex = this.getMaxInnerIndex(rowIndex, colIndex);
                                useShift = false;
                                break;
                            case Events.MoveFocusDirection.right:
                            case Events.MoveFocusDirection.enter:
                                rowIndex++;
                                colIndex = ((rowIndex) => matrix.filter((item) => item.rootRowIndex === rowIndex && item.id === id))(rowIndex)
                                    .reduce((accu, curr) => accu === -1 || curr.colIndex < accu ? curr.colIndex : accu, -1);
                                innerIndex = 0;
                                useShift = false;
                                break;
                        }
                    } else {
                        const cell = ((colIndex) => rows.find((item) => item.colIndex === colIndex && item.id === id))(colIndex);
                        if (cell) {
                            const refItem = ((innerIndex) => cell.refItems.find((item) => item.innerIndex === innerIndex && item.id === id))(innerIndex);
                            if (refItem &&
                                (!shift || refItem.ref.current !== refCurrent) &&
                                refItem.ref.current &&
                                refItem.ref.current.focus) {
                                if (refItem.ref.current.props.disabled !== true &&
                                    refItem.ref.current.props.frozen !== true &&
                                    !UnFocusableTypes.find(type => (refItem.ref.current instanceof type))) {
                                    refItem.ref.current.focus(direction === Events.MoveFocusDirection.left || Events.MoveFocusDirection.up);
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private setFocusRequired() {
        if (this.state.requiredRefItem) {
            const refItem = this.state.refs.find((item) =>
                item.rootRowIndex === this.state.requiredRefItem!.rootRowIndex &&
                item.rootColIndex === this.state.requiredRefItem!.rootColIndex &&
                item.innerIndex === this.state.requiredRefItem!.innerIndex);
            if (refItem &&
                refItem.ref.current &&
                refItem.ref.current.focus) {
                refItem.ref.current.focus();
            }
        }
    }

    public focus(isLast: boolean = false): void {
        this.setFocus(isLast ? (this.state.lastItem ? this.state.lastItem.ref.current : null) :
            (this.state.firstItem ? this.state.firstItem.ref.current : null),
            isLast ? Events.MoveFocusDirection.left : Events.MoveFocusDirection.right, false);
    }

    public isEmpty(): boolean {
        if (((this.state.refs || []).filter((ref: IRefItem) => {
            if (ref.ref.current && ref.ref.current.isEmpty) {
                return ref.ref.current.isEmpty();
            }
            return false;
        }) || []).length > 0) return true;
        return false;
    }

    public validate(showMessage = false): boolean {
        const requiredRefItem = (this.state.refs || []).find((ref: IRefItem) => {
            if (ref.ref.current && ref.ref.current.validate) {
                return !ref.ref.current.validate();
            }
            return false;
        });
        let result = requiredRefItem ? false : true;
        if (!result && showMessage) {
            this.setState({
                propChildren: null,
                requiredRefItem: requiredRefItem!
            }, () => {
                this.setFocusRequired()
            });
        }
        return result;
    }
    ///////////////////////////////////////////////////////////////////////////// Event Handlers
    private hasFocus: boolean = false

    private handleFocus = (e: React.FocusEvent) => {
        if (this.hasFocus === false) {
            this.hasFocus = true;
            Util.invokeEvent<Events.EventArgs>(this.props.onFocus, new Events.EventArgs(this));
        }
    }

    private handleBlur = (e: React.FocusEvent) => {
        setTimeout(() => {
            if (Util.containsFocus(this.myRefs.table) === false) {
                this.hasFocus = false;
                Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
            }
        }, 0);
    }

    private handleChildrenMoveFocus(e: Events.MoveFocusEventArgs): void {
        this.setFocus(e.target, e.direction, true);
    }

    private handleChildrenChange(e: Events.ChangeEventArgs<any>): void {
        if (this.state.requiredRefItem) {
            this.setState({
                propChildren: null,
                requiredRefItem: null
            });
        }
        Util.invokeEvent<Events.EventArgs>(this.props.onChange, new Events.EventArgs(this));
    }
}

OBTFormPanel.contextType = OBTContext;