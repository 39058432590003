/**
 *  검색시 팝 오버에 뿌려질 item 컨트롤 입니다.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import OBTTooltip from "../../../OBTTooltip"
import globalObj from "luna-rocket/locale/intlGlobals";
import scssStyles from '../../OBTComplete.module.scss';

const getStyles = (props, hover, isHaveImage, isSingleRow) => {
    const { focusedInfo, data } = props;

    let isSelect = focusedInfo.data === data;

    const styles = {
        // li Style
        liStyle: {
            margin: 0,
            padding: 0,
            position: "relative",
            clear: "both",
            overflow: "hidden",
            borderTop: "1px solid #f5f5f5",
            background: hover ? "#f5f5f5" : isSelect ? "#e9f5ff" : "#fff",
        },
        aStyle: {
            margin: 0,
            clear: "both",
            overflow: "hidden",
            display: "block",
            //padding: isHaveImage ? "14px 6px 9px" : "3px 6px 1px",
            padding: "3px 6px 1px",
            fontSize: "12px",
            fontFamily: 'inherit',
            // lineHeight: '13px',
            lineHeight: "20px",
            color: "#4a4a4a",
            letterSpacing: "-.5px",
            textAlign: "left",
            textDecoration: "none",
            outline: "none",
            cursor: "pointer"
        },
        pStyle: {
            margin: 0,
            padding: 0,
            paddingRight: "1px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            wordBreak: "break-all",
            textOverflow: "ellipsis",
            fontSize: "12px",
            fontFamily: 'inherit',
            fontHeight: '13px',
        },
        iStyle: {
            margin: 0,
            padding: 0,
            color: "#4a4a4a",
            fontStyle: "normal"
        },
        iColorStyle: {
            color: "#1c90fb"
        },
        containerStyle: {
            display: "block",
            margin: 0,
            padding: 0,
            float: "left",
            paddingRight: "5px",
            boxSizing: "border-box",

            overflow: isHaveImage ? "" : "hidden",
            whiteSpace: "nowrap",
            wordBreak: "break-all",
            textOverflow: "ellipsis"
        },
        containerStyleNoPadding: {
            display: "block",
            margin: 0,
            padding: 0,
            float: "left",
            boxSizing: "border-box",

            overflow: isHaveImage ? "" : "hidden",
            whiteSpace: "nowrap",
            wordBreak: "break-all",
            textOverflow: "ellipsis"
        },
        divImageStyle: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',

            //width: "32px",
            minHeight: "20px",

            //textAlign: "center",
            //marginTop: isSingleRow ? "-7px" : "1px"
            //marginTop: "1px"
        },
        imgStyle: {
            //display: 'block',
            margin: '0px auto',

            maxWidth: '100%',
            maxHeight: '100%',

            //width: "100%",
            //height: "100%",
            //borderRadius: "100px"
        },
        sp_lux: {
            display: "inline-block",
            overflow: "hidden",
            background: `url(${globalObj.staticInfo.path
                }/imgs/common/sp_lux.png) no-repeat`,
            lineHeight: "100em",
            verticalAlign: "top",
            width: "8px",
            height: "8px",
            backgroundPosition: "-199px -278px",
            marginTop: "6px",
            marginLeft: "6px"
        }
    };

    return styles;
};

class LineItem extends Component {
    // region React Base

    static propTypes = {
        /**
         * 항목삭제 가능여부입니다.
         */
        canDelete: PropTypes.bool,
        /**
         * 항목을 삭제하면 발생하는 이벤트 입니다.
         */
        onClickDelete: PropTypes.func,
        /**
         * 데이터에서 현제 아이템이 위치하는 인덱스 입니다.
         */
        index: PropTypes.number,
        /**
         * 툴팁의 float 속성입니다.
         */
        toolTipFloat: PropTypes.string,
        /**
         * null(공란) 값을 툴팁에서 skip  할지 정하는 속성입니다.
         */
        toolTipNullSkip: PropTypes.bool,
        /**
         * 툴팁을 무조건 생성할지 여부
         */
        toolTipAlwaysShow: PropTypes.bool,
        /**
         * 툴팁을 생성 여부
         */
        isShowTooltip: PropTypes.bool
    };

    constructor() {
        super();

        this.state = {
            hover: false,
            isOverFlow: false
        };
    }

    componentWillMount() {
        // 이미지가 있는지 판단
        this.isHaveImage = false;
        this.isSingleRow = true;

        if (this.props.type === "multiListSchema") {
            this.props.dataInfo.forEach(info => {
                info.itemInfo.forEach(item => {
                    if (item.type && item.type === "image") {
                        this.isHaveImage = true;
                    }
                    if (item.row && item.row === 1) {
                        this.isSingleRow = false;
                    }
                });
            });
        } else {
            this.props.dataInfo.itemInfo.forEach(item => {
                if (item.type && item.type === "image") {
                    this.isHaveImage = true;
                }
                if (item.row && item.row === 1) {
                    this.isSingleRow = false;
                }
            });
        }
    }

    componentDidMount() {
        let isOverFlow = false;
        const refs = this._refs.map(item => item.ref);
        if (refs) {
            for (let index in refs) {
                if (
                    refs[index].current.scrollWidth !==
                    refs[index].current.clientWidth
                ) {
                    isOverFlow = true;
                    break;
                }
            }
            if (this.state.isOverFlow !== isOverFlow) {
                this.setState({
                    isOverFlow: isOverFlow
                });
            }
        }
    }

    // endregion

    // region TextElement

    _refs = [];
    getRef(key) {
        let ref = this._refs.find(item => item.key === key)
        if (!ref) {
            ref = { key, ref: React.createRef() };
            this._refs.push(ref);
        }
        return ref.ref;
    }

    /**
     * Style 을 적용한 텍스트 엘레멘트를 생성한다.
     */
    createTextElement = (styles, column) => {
        let text = this.props.data[column.key];

        if (!text) {
            text = "";
        }
        let keyWord = (this.props.keyWord || '').toLowerCase();

        // 키워드와 일치하는 구간을 찾는다.
        const loweredText = text.toLowerCase();
        let keyWordIndexes = [];
        let pos = loweredText.indexOf(keyWord);
        while (pos !== -1 && keyWord) {
            keyWordIndexes.push(pos);
            pos = loweredText.indexOf(keyWord, pos + 1);
        }

        // 키워드와 일치하는 구간은 배경색을 적용한 element 를 생성한다.
        let textElement = [];
        let index = 0;

        for (let i = 0; i < keyWordIndexes.length; ++i) {
            if (index < keyWordIndexes[i]) {
                // 일반 문자열 add
                textElement.push(text.substring(index, keyWordIndexes[i]));
                //textElement.push(this.createSpan(index, styles.iStyle, text.substring(index, keyWordIndexes[i])));
                index = keyWordIndexes[i];
            }

            // 컬러 문자열 add
            textElement.push(
                this.createSpan(
                    index,
                    Object.assign({}, styles.iStyle, styles.iColorStyle),
                    text.substring(index, index + keyWord.length)
                )
            );
            index += keyWord.length;
        }

        // 남은 일반 문자열 add
        if (index < text.length) {
            textElement.push(text.substring(index, text.length));
            //textElement.push(this.createSpan(index, styles.iStyle, text.substring(index, text.length)));
        }

        let fontWeight = { fontWeight: column.isBold ? "bold" : "inherit" };

        let info;
        if (this.props.dataInfo && this.props.dataInfo.itemInfo) {
            info = this.props.dataInfo.itemInfo.find(x => x.key === column.key);
        }
        let style = {};
        if (info && info.style) {
            style = info.style;
        }
        return (
            <p
                key={column.key}
                ref={this.getRef(text)}
                style={Object.assign(style, styles.pStyle, fontWeight)}
            >
                {textElement}
            </p>
        );
    };

    // endregion

    // region ImageElement

    createImageElement = (styles, column) => {
        let text = this.props.data[column.key];
        return (
            <div key={text} ref={this.getRef(text)} style={styles.divImageStyle}>
                <img
                    style={styles.imgStyle}
                    src={text}
                    onError={this.onImageError}
                    alt=''
                />
            </div>
        );
    };

    // 이미지가 없을때 기본이미지를 보여준다.
    onImageError(event) {
        event.target.src = `${globalObj.staticInfo.path
            }/imgs/dummy/@dummy_02.jpg`;
    }

    // endregion

    createNodeElement = (styles, column) => {
        let node = this.props.data[column.key];
        return (
            <div key={node} ref={this.getRef(node)}>
                {node}
            </div>
        );
    }

    // region Item

    createSpan = (key, style, text) => {
        return (
            <span key={key} style={style}>
                {text}
            </span>
        );
    };

    createItemContainers = styles => {
        let items = [];
        let dataInfo = this.props.dataInfo;
        if (this.props.type === "multiListSchema") {
            dataInfo = this.props.dataInfo.find(info => {
                return info.dataType === this.props.dataType;
            });
        }

        for (let column = 0; column < dataInfo.columnWidths.length; ++column) {
            // item 생성
            let item = this.createItems(dataInfo, column, styles);
            let style = Object.assign({}, (dataInfo.columnWidths[column] === '0px' || dataInfo.columnWidths[column] === '0%') ? styles.containerStyleNoPadding : styles.containerStyle, {
                width: dataInfo.columnWidths[column]
            });

            if (dataInfo.itemInfo[column].key === "deleteButton") {
                items.push(
                    <div
                        onClick={this.handleClickDelete}
                        key={column}
                        style={style}
                    >
                        <span key={column} style={styles.sp_lux} />
                    </div>
                );
            } else {
                items.push(
                    <div key={column} style={style}>
                        {item}
                    </div>
                );
            }
        }

        return items;
    };

    createItems = (dataInfo, column, styles) => {
        let columns = dataInfo.itemInfo.filter(itemInfo => {
            if (itemInfo.column === column) {
                return true;
            } else {
                return false;
            }
        });

        let items = columns.map(column => {
            let item;
            if (column.type === 'image') {
                item = this.createImageElement(styles, column);
            } else if (column.type === 'node') {
                item = this.createNodeElement(styles, column);
            } else {
                item = this.createTextElement(styles, column);
            }

            return item;
        });
        return items;
    };

    /**
     * 툴팁 생성
     */
    createToolTip = () => {
        let itemInfo = this.props.dataInfo.itemInfo;
        if (this.props.type === "multiListSchema") {
            itemInfo = this.props.dataInfo.find(info => {
                return info.dataType === this.props.dataType;
            }).itemInfo;
        }

        // 데이터를 순회 하면 툴팁 생성
        let spans = itemInfo.map((item, index) => {
            if (item.type !== "image" && item.type !== "node") {
                try {
                    if (
                        this.props.toolTipNullSkip &&
                        this.props.data[item.key] !== undefined &&
                        this.props.data[item.key] !== null &&
                        this.props.data[item.key] !== ""
                    )
                        return (
                            <div key={index}>
                                <span style={{ marginRight: "10px" }}>
                                    {this.props.data[item.key]}
                                </span>
                            </div>
                        );
                    else if (!this.props.toolTipNullSkip)
                        return (
                            <div key={index}>
                                <span style={{ marginRight: "10px" }}>
                                    {this.props.data[item.key]}
                                </span>
                            </div>
                        );
                } catch (e) {
                    return (
                        <div key={index}>
                            <span style={{ marginRight: "10px" }}>
                                {this.props.data[item.key]}
                            </span>
                        </div>
                    );
                }
            }
            return undefined;
        });

        let toolTipElement = <div>{spans}</div>;
        return toolTipElement;
    };
    // endregion

    // region events

    /**
     * 데이터를 클릭하여 선택하면 발생
     */
    handleClick = event => {
        // 클릭된 데이터를 선택한다.
        event.stopPropagation();
        event.preventDefault();
        if (this.props.onSelect) {
            this.props.onSelect(event, this.props.data, this.props.dataType);
        }
    };

    /**
     * 삭제버튼을 클릭하면 발생
     */
    handleClickDelete = event => {
        event.preventDefault();
        event.stopPropagation();
        if (this.props.onClickDelete) {
            this.props.onClickDelete(event, this.props.data, this.props);
        }
    };

    /**
     * 마우스 오버가 되면 hover state 설정
     */
    handleMouseEnter = () => {
        this.setState({ hover: true });
    };

    /**
     * 마우스 Leave 되면 hover state 설정
     */
    handleMouseLeave = () => {
        this.setState({ hover: false });
    };

    // endregion

    // region Render

    render() {
        const liProps = {
            onClick: !this.props.data['disabled'] ? this.handleClick : undefined,
            onMouseEnter: !this.props.data['disabled'] ? this.handleMouseEnter : undefined,
            onMouseLeave: !this.props.data['disabled'] ? this.handleMouseLeave : undefined
        };

        let toolTipElement = this.createToolTip();
        const styles = getStyles(
            this.props,
            this.state.hover,
            this.isHaveImage,
            this.isSingleRow
        );
        let items = this.createItemContainers(styles);
        let aStyle = Object.assign(
            {},
            styles.aStyle,
            this.props.stylePopoverATag
        );
        aStyle = Object.assign({}, aStyle, {
            marginLeft: "2px",
            marginRight: "2px"
        });

        let lineThrough = this.props.data['disabled'] ? <div className={scssStyles.lineThrough}></div> : undefined;

        let liElement = (
            <li style={styles.liStyle} {...liProps}>
                {lineThrough}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a style={aStyle}>{items}</a>
            </li>
        );

        let element;

        if ((this.state.isOverFlow || this.props.toolTipAlwaysShow) && (this.props.isShowTooltip === undefined || this.props.isShowTooltip === true)) {
            element = (
                <OBTTooltip
                    labelText={toolTipElement}
                    style={{ display: "block" }}
                >
                    {liElement}
                </OBTTooltip>
            );
        } else {
            element = <div>{liElement}</div>;
        }

        return element;
    }

    // endregion
}
export default LineItem;