/**
 * OBTTitle
 * @version 0.1
 * @author 김소현
 * @see common.js
 */
import * as React from 'react';
import { CompositeProps, Util, CommonProps, createPropDefinitions, CommonType, toEnumType } from '../Common';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { hasError } from '../Common/CommonState';

/**
 * @internal
 * CSS Modules 사용방식
 * styles.[className]
 */
const styles = require('./OBTTitle.module.scss');

export enum ChildrenPosition {
    /**
     * near: labelText 영역 가까이 elements 위치
     * far : labetText 영역 멀리 가장 우측에 elements 위치
     */
    'near' = 'near',
    'far' = 'far'
}

interface ISubLabelText {
    text: string,
    color?: string
}

interface IOBTTitle extends CompositeProps.Default, CommonProps.labelText {
    /**
     * 타이틀에 표시할 텍스트를 지정하는 속성입니다.
     */
    labelText: string | any,
    /**
     * 타이틀 우측 서브 타이틀을 표시하는 속성입니다.
     */
    subLabelText?: ISubLabelText,
    /**
     * 타이틀 좌측 불릿 표시 여부를 지정할 수 있습니다.
     */
    useBullet?: boolean,
    /**
     * OBTTitle 우측에 element를 구성할 수 있습니다.
     */
    children?: any,
    /**
     * children에 해당하는 elements의 위치를 지정할 수 있습니다.
     */
    childrenPosition?: ChildrenPosition
}

interface IState extends hasError {
}

export default class OBTTitle extends React.Component<IOBTTitle, IState> {
    public static PropDefinitions = createPropDefinitions(
        { name: "width", type: CommonType.string, description: "컴포넌트의 너비를 지정할 수 있는 속성입니다.", optional: true },
        { name: "labelText", type: 'string | any', description: "컴포넌트의 Title텍스트를 지정할 수 있습니다. \nstring타입으로 지정시 기본 title스타일에 맞춰 표시되고, 만약 labelText의 일부 글자만 컬러를 변경하고 싶거나 스타일 변경을 원한다면 인라인요소 태그로 커스텀 구성할 수 있습니다." },
        { name: "subLabelText", type: '{ text : string , color?: string }', description: '타이틀 우측 서브 타이틀을 표시하는 속성입니다.\n- text: 서브타이틀에 표시하고 싶은 텍스트, \n- color: 서브타이틀 컬러 지정(따로 지정하지 않으면 기본색 빨강)', optional: true },
        { name: "useBullet", type: CommonType.boolean, description: "타이틀 좌측 불릿기호의 표시 여부를 지정할 수 있습니다. true시 불릿 기호를 표시합니다.", default: true, optional: true },
        { name: "childrenPosition", type: toEnumType(ChildrenPosition), description: "OBTTitle 컴포넌트 하위 children element의 위치를 지정할 수 있습니다.\n- near: labelText영역 바로 옆에 붙어서 위치\n- far: 컴포넌트의 제일 우측에 위치 ", default: ChildrenPosition.far, optional: true },
        { name: "className", type: CommonType.string, description: "className 속성을 사용하여 컴포넌트의 스타일 지정이 가능합니다.", optional: true },
    )

    public static defaultProps = {
        useBullet: true,
        childrenPosition: ChildrenPosition.far
    }

    public static ChildrenPosition = ChildrenPosition;

    renderComponent = () => {
        const children = React.Children.map(this.props.children, (child, index) => {
            if (React.isValidElement(child)) {
                const childrenProp = {
                    className: Util.getClassNames(index === this.props.children.length - 1 || (React.Children.toArray(this.props.children).length === 1 && index <= 0) ? styles.lastChild : styles.element)
                }
                return React.cloneElement(child, childrenProp)
            } else {
                return child
            }
        });

        return (
            <div className={Util.getClassNames(styles.root, this.props.className)}
                style={{
                    width: this.props.width ? this.props.width : '100%',
                }} >
                <div className={styles.container}>
                    <div className={styles.labelTextField}
                        style={{
                            flex: this.props.subLabelText || this.props.childrenPosition === ChildrenPosition.near ? '0 0 auto' : '1 1 auto',
                            marginRight: this.props.children && this.props.childrenPosition === ChildrenPosition.near ? '10px' : '5px'
                        }}>
                        {this.props.useBullet ? <span className={styles.bullet}></span> : null}
                        <span className={styles.labelText}>{this.props.labelText}</span>
                    </div>
                    {this.props.subLabelText && this.props.childrenPosition !== ChildrenPosition.near ?
                        <div className={styles.subLabelTextField} style={{ color: this.props.subLabelText.color ? this.props.subLabelText.color : 'red' }}>
                            <span>{this.props.subLabelText.text}</span>
                        </div> : null
                    }
                    {this.props.children ?
                        <div className={styles.elementsField} style={{ flex: this.props.childrenPosition === ChildrenPosition.near ? '1 1 auto' : '0 0 auto' }}>
                            {children}
                        </div> : null
                    }
                </div>
            </div>
        )
    }

    render() {
        return (<ErrorBoundary owner={this} render={this.renderComponent} />)
    }
};

export class Separator extends React.Component<any> {

    render() {
        return (
            <span style={{
                display: 'inline-block',
                width: '0.5px',
                height: '16px',
                margin: '0 10px 0 5px',
                background: '#e6e6e6'
            }}></span>
        )
    }
}
