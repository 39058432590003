import moment from 'moment';
import uuid from 'uuid/v1';
import { Util, Privacy, PrivacyBehaviorEnum } from '../Common';
import * as CommonEvents from '../Common/Events';
import { Fetch } from '../Common/Util';
import emptyImage from '../Images/1x1.png';
import unCheckImg from '../Images/checkbox.png';
import checkImg from '../Images/checkbox_cehcked.png';
import disabledCheckImg from '../Images/checkbox_checked_disable.png';
import disabledUnCheckImg from '../Images/checkbox_disable.png';
import searchPrivacyIcon from '../Images/ic-private-m-normal.png';
import searchPrivacyIconOver from '../Images/ic-open-m-over.png';

import btnTreeGridPlusImg from '../Images/btn_plus.png';
import btnTreeGridMinusImg from '../Images/btn_minus.png';

import calendarIcon from '../Images/ic_inputcal_m_normal.png';
import calendarIconOver from '../Images/ic_inputcal_m_over.png';
import codePickerIcon from '../Images/ic_code_m_normal.png';
import codePickerIconOver from '../Images/ic_code_m_over.png';
import userProfileIcon from '../Images/ic_profile_m_nomal.png';
import userProfileIconOver from '../Images/ic_profile_m_over.png';
import { account, biznumber, conumber, credit, driver, email, foreign, passport, resident, tel, IMaskPattern } from '../OBTMaskedTextField/Patterns';
import { IPageAuthority } from '../OBTPageContainer/OBTPageContainer';
import { ChaningEvent } from './GridBase/ChaningEvent';
import GridImageLabelProcessor, { ImageLabelCanvasTemplate } from './GridBase/GridImageLabelProcessor';
import GridUtil from './GridBase/GridUtil';
import GridStyle from './GridStyle';
import GridStyleForExcel from './GridStyleForExcel';
import ICell from './ICell';
import { buttonVisiblityFunction, CodePickerEditType, ColumnSummaryExpression, ColumnType, DateFormat, IColumn, ImageButton, MaskType, INewColumn, toLegacyColumn, ERPNumberFormatType } from './IColumn';
import IDataProvider from './IDataProvider';
import IGridGlobalOption, { IGridDisplayOption } from './IGridGlobalOption';
import { ITreeViewMethod } from './IOBTDataGridInterface';
import IRecusiveColumnIndex from './IRecusiveColumnIndex';
import * as Events from './OBTDataGridEvents';
import Pagination from './Pagination';
import IRealGridCellStyleOption from './RealGridCellStyleOption';
import OBTDataGrid from './OBTDataGrid';
import { OrbitInternalLangPack } from '../Common/Util';

import { AlignType, PositionType } from '../OBTFloatingPanel/OBTFloatingPanel';
import UserCustomGridLayoutProcessor, { CustomColumnProperty, ChangedLayoutByColumn } from './GridBase/GridUserCustomLayout';
import { ignoreException, IERPNumberFormatType, makeNumberFormatString, makeNumberExcelFormatString } from '../Common/OrbitInternalUtil';
import { GridValidationFailReason } from './OBTDataGridEvents';

const RealGridJS: any = require('./RealGridJS').default;

export type Read = (e: Events.ReadEventArgs) => Promise<any[] | null | undefined>;
export type ReadTotalCount = (e: Events.ReadTotalCountEventsArgs) => Promise<number>;
export type Store = (e: Events.StoreEventArgs) => Promise<any>;
export type StoreMemo = (e: Events.StoreMemoCheckPenEventArgs) => Promise<any>;
export type StoreCheckPen = (e: Events.StoreMemoCheckPenEventArgs) => Promise<any>;
export type ReadFooter = (e: Events.ReadFooterEventsArgs) => Promise<any>;
export type RowValidationAlert = {
    isValidated: boolean,
    rowIndex: number,
    invalidColumns: { columnName: string, reason: Events.GridValidationFailReason }[],
}

type GetValueOption = {
    /**
     * 개인정보암호화 원본 필드의 값을 읽을 것인지 여부
     */
    usePrivacyOriginField?: boolean,
    /**
     * 포메팅된 값을 그대로 가져올것인지 여부
     * @example type = 'number' 일때 numberColumnFormat으로 처리된 소수점 자리수 적용된 값 그대로 가져오기
     * @example type = 'mask' 일때 마스킹 포멧까지 적용된 값 그대로 가져오기
     */
    withFormat?: boolean
}

interface IDetailGridOption {
    useAutoStore?: boolean,
    autoStoreOption?: {
        showLoading?: boolean,
        loadingOption?: {}
    },
    useAutoRead?: boolean,
    autoReadOption?: {
        waitReading?: boolean,
        showLoading?: boolean,
        loadingOption?: {}
    }
    focusAround?: boolean,

}

export const StandardDesign = {
    MessageColorRGB: {
        positive1: '#20c997',
        positive2: '#2dbcb5',
        positive3: '#39b0d2',
        inProgress: '#46a3f0',
        negative1: '#ff8787',
        negative2: '#f8a457',
        pending1: '#f0c325',
        pending2: '#c9b462',
        neutral: '#9da3aa'
    },
    GridColorRGB: {
        /**
         * 셀의 디폴트 백그라운드 컬러 RGB
         * DEFAULT_COLOR_BACKGROUND_COLOR
         */
        columnDefaultBackground: '#ffffff',
        /**
         * required 셀의 백그라운드 컬러 RGB 
         * REQUIRED_COLUMN_BACKGROUND_COLOR
         */
        columnRequiredBackground: '#ffffe8e8',
        /**
         * readOnly 셀의 백그라운드 컬러 RGB 
         * READONLY_COLUMN_BACKGROUND_COLOR
         */
        columnReadonlyBackground: '#eceded',

        /**
         * 
         */
        defaultBorderColor: '#ffe1e1e1',

        /**
         * 
         */
        defaultCellBorderColor: '#ffff0000',

        /**
         *
         */
        footerBackgroundColor: '#fffff1d6',

        /**
         * 
         */
        focusRowBackgroundColor: "#001c90fb",

        /**
         * 
         */
        focusRowActiveBackgroundColor: '#ff1c90fb',
    }
}

/**
 * 특정 셀의 스타일을 파악하기 위한 정보
 */
export type ICellStyleType = {
    checkPen: {
        has: boolean,
        valueIfHas: string
    },
    memo: {
        has: boolean,
    },
    selected: {
        isSelected: boolean,
        required: boolean,
        readonly: boolean,
    }
}

export type EmptySetSize = 'tiny' | 'small' | 'normal';

export interface EmptySet {
    size: EmptySetSize,
    image: string,
    labelText: string,
    hideImage: boolean,
}

export interface EmptySetByType {
    type: EmptySetType,
    emptySet: EmptySet
}

/**
 * 그리드의 데이터 상태, 데이터가 없을때 바디영역의 메시지 표현위함
 */
export enum EmptySetType {
    /**
     * noData : 조회했으나 데이터가 없는 상태
     */
    "noData" = "noData",
    /**
     * beforeSearch : 조회하기기전 데이터가 바인딩되기 전 상태
     * 
     */
    "beforeSearch" = "beforeSearch",
    /**
     * hasData : 조회하고 데이터가 바인딩된 상태
     */
    "hasData" = "hasData",
}

export interface IImageDataByTextValue {
    columnName: string;
    value: string;
    imageDataUrl: string;
}

/**
 * 그리드의 타입(그리드뷰, 트리뷰)에 대한 열거형
 */
export enum GridType {
    'gridView' = 'gridView',
    'treeView' = 'treeView'
}

/**
 * 그리드의 행이 가지는 데이터 변경에 대한 상태값을 표현하는 열거형
 */
export enum GridState {
    'empty' = 'empty',
    'none' = 'none',
    'added' = 'added',
    'modified' = 'modified',
    'deleted' = 'deleted'
}

/**
 * 그리드의 셀에서 데이터의 정렬방식에 대한 열거형
 */
export enum ColumnAlignment {
    'center' = 'center',
    'near' = 'near',
    'far' = 'far',
    'left' = 'left',
    'right' = 'right'
}

/**
 * 그리드 인터페이스
 */
export default class OBTDataGridInterface {
    public static readonly GridType = GridType;
    public static readonly ColumnType = ColumnType;
    public static readonly ColumnAlignment = ColumnAlignment;
    public static readonly ImageLabelTemplate = ImageLabelCanvasTemplate;
    public static readonly PrivacyBehavior = PrivacyBehaviorEnum;

    /**
     * 두 개 이상의 그리드가 check 될 경우 하나의 그리드만 체크되게끔 한다.
     * @param grids 
     */
    public static setSingleGridCheckable(...grids: Array<OBTDataGridInterface>): void {
        grids.forEach((grid) => {
            if (grid.onAfterCheck) {
                grid.onAfterCheck.add((e) => {
                    const others = grids.filter((grid) => {
                        return grid.id !== e.target._id
                    })

                    others.forEach((other) => {
                        other.gridView.checkAll(false, true, true, false);
                    })
                })
            }

            if (grid.onAfterHeaderCheck) {
                grid.onAfterHeaderCheck.add((e) => {
                    const otherHeaders = grids.filter((grid) => {
                        return grid.id !== e.target._id
                    })

                    otherHeaders.forEach((other) => {
                        other.gridView.checkAll(false, true, true, false);
                    })
                })
            }
        });
    }

    /**
     * 디테일 그리드 설정
     * @param detailGrids 
     * @param option 
     */
    public setDetailGrids(detailGrids: Array<OBTDataGridInterface>, option?: IDetailGridOption) {
        const detailGridEventKey = 'detailGridEventKey_owner' + this.id;
        const masterGrid = this;

        // 이전 이벤트 클리어 
        masterGrid.onBeforeChangeRowSelectionAsync.removeByKey(detailGridEventKey);
        masterGrid.onAfterChangeRowSelection.removeByKey(detailGridEventKey);
        masterGrid.onKeyDown.removeByKey(detailGridEventKey);

        detailGrids.forEach((grid, index) => {
            grid.onKeyDown.removeByKey(detailGridEventKey);
            grid.onBeforeChange.removeByKey(detailGridEventKey);
        });

        masterGrid.detailGrids = detailGrids;

        // 마스터 행변경전 디테일그리드 데이터 저장
        if (option && option.useAutoStore === true) {
            masterGrid.onBeforeChangeRowSelectionAsync.add(async (e) => {
                try {
                    const promiseList: Array<Promise<void>> = [];

                    detailGrids.forEach((grid) => {
                        if (grid.isEditing()) {
                            grid.commit();
                        }

                        if (grid.hasDirtyData()) {
                            promiseList.push(grid.storeData());
                        }
                    })

                    await Promise.all(promiseList);

                    return true;
                } catch (e) {
                    console.error(e)
                    return false;
                } finally {

                }
            }, detailGridEventKey)
        }

        // 마스터 행변경시 디테일 조회
        if (option && option.useAutoRead === true) {
            masterGrid.onAfterChangeRowSelection.add((e) => {
                const needWaitDetailGrid = option.autoReadOption && (option.autoReadOption.waitReading === true || option.autoReadOption.showLoading === true)

                if (option.autoReadOption && needWaitDetailGrid) {
                    masterGrid.lockCellChangeTemplate(async () => {
                        await Promise.all(detailGrids.map((grid) => {
                            return (grid.readData())
                        }));
                    })
                } else {
                    detailGrids.forEach((grid) => {
                        grid.readData();
                    })
                }
            }, detailGridEventKey);
        }

        // 그리드간 키보드 포커스 이동
        if (option && option.focusAround === true) {
            const gridList = [
                this, ...detailGrids
            ]

            const headerGrid = gridList[0]

            gridList.forEach((grid, index) => {
                grid.onKeyDown.add((e) => {
                    const keyValue = grid.mapKeyCodeToKeyName(e.keyCode);

                    const isLastCell = grid.isLastEditableColumn(grid.getSelectedColumnName(), grid.getSelectedIndex())
                        || grid.isLastVisibleColumn(grid.getSelectedColumnName(), grid.getSelectedIndex());
                    const isLastIndex = grid.getSelectedIndex() === grid.getRowCount() - 1;
                    const isHeaderGrid = grid === headerGrid;

                    const lockCellChange = grid._lockCellSelectionChange === true || grid._lockRowSelectionChange === true;

                    if (lockCellChange) {
                        e.cancel = true;
                    } else if (keyValue === 'Enter' && ((isHeaderGrid && isLastCell) || (isLastIndex && isLastCell))) {
                        grid.commit();

                        if (index + 1 < gridList.length) {
                            gridList[index + 1].focus();
                            if (gridList[index + 1]._owner) {
                                gridList[index + 1]._owner!.focus();
                            }
                        } else {
                            headerGrid.focus();
                            if (headerGrid._owner) {
                                headerGrid._owner.focus();
                            }

                            const nextCell = headerGrid.getNextActiveCell(headerGrid.getSelectedIndex(), headerGrid.getSelectedColumnName());
                            if (nextCell) {
                                headerGrid.setSelection(nextCell.rowIndex, nextCell.columnName || 0);
                            }
                        }

                        e.cancel = true;
                    }
                }, detailGridEventKey)
            })
        }

        // 마스터 그리드의 상태가 added, empty일때 디테일은 입력되지 못한다. 
        detailGrids.forEach((grid) => {
            grid.onBeforeChange.add(e => {
                const headerGridState = this.getState(this.getSelectedIndex());
                if (headerGridState === GridState.added || headerGridState === GridState.empty) {
                    e.cancel = true;
                    // alert('!!!')
                }
            }, detailGridEventKey)
        })
    }

    public setDetailGrid(grid: OBTDataGridInterface, option?: IDetailGridOption) {
        this.setDetailGrids([grid], option);
    }

    private static readonly PrivacyOriginFieldSuffix = 'OriginPrivacyValue';

    private dateDialogPosition: {
        align: AlignType,
        position: PositionType
    } = {
            align: AlignType.near,
            position: PositionType.bottom
        }

    public static getFieldName(column: IColumn) {
        return column.fieldName || column.name;
    }
    public static getOriginFieldName(column: IColumn) {
        return column.usePrivacy ? `${column.name}${OBTDataGridInterface.PrivacyOriginFieldSuffix}` : this.getFieldName(column);
    }

    /** @internal */
    public readonly _gridElementId: string = `grid_${uuid()}`;

    public readonly dateFieldMinDate = new Date(1900, 10, 30);

    public readonly dateFieldMaxDate = new Date(2060, 10, 30);

    public static readonly reservedIconNames = {
        codePickerIcon: '',
        userProfileIcon: '',
        datePickerIcon: '',
    }

    /** @internal */
    private _owner: OBTDataGrid | null = null;

    private _gridWrapperId: string = '';

    /** @internal */
    private _id: string = '';

    /** @internal */
    private _gridOption: IGridGlobalOption = {};

    /** @internal */
    private _unmanagedGridOptions: any | null = null;

    /**
     * @internal 
     * 리얼그리드의 GridView, TreeView 객체
     */
    private _gridView: any | null = null;

    /** @internal */
    private _provider: IDataProvider | null = null;

    /** 
     * @internal 
     * onCurrentChanging에서 세팅된다. 셀 선택이 변경될 때 행이 변경되었는지 여부를 캐시한다. 
     */
    private _isRowChanged: boolean = false;

    /** @internal */
    private _hasFocus: boolean = false

    /** @internal */
    private _editCommitInfo: any = null;

    /** @internal */
    private _selectedDataRowBeforeSorting: number = -1;


    /** @internal */
    private _codePickerAjaxEbp: any | null = null;

    /** 
     * @internal
     * 이미지 라벨 컬럼의 경우, 컬럼의 값에 매핑되는 이미지 정보를 캐싱하는 필드 
     *  */

    /** 
     * @internal 
     * 사용자로 부터 할당받는 컬럼정보
     */
    private _columns: IColumn[] | null = [];

    /**
     * @internal
     */
    private _firstColumnName: string | null = null;

    /** 
     * @internal 
     * 검색 효율성을 위해 컬럼정보를 기반으로 컬럼명: 컬럼으로 변환되어 관리되는 필드 
     * 컬럼명 키값은 toUpperCase 적용
     */
    private _columnMap: any = {};

    /** 
     * @internal 
     * 검색 효율성을 위해 컬럼정보를 기반으로 컬럼명: 컬럼으로 변환되어 관리되는 필드 
     */
    private _hasSubNameColumnMap: any = {};

    /**
     * @internal
     * 트리그리드에서 접기, 펼치기 동작시 셀 셀렉션 체인지 이벤트를 발생시키기 위함
     * onTreeItemCollapsing, onTreeItemExpanding에서 세팅되고
     * onTreeItemCollapsed, onTreeItemExpand에서 세팅되고 사용된다.
     */
    private _cellChangeOldRowId: number = -1;

    /**
     * @internal
     * appendable 그리드에서 행추가시 여러번의 셀선택변경 이벤트가 발생하는것을 막고 
     * 마지막 addRow 시점에만 셀선택 이벤트를 발생시키기 위함
     */
    private _ignoreManagedCellChangeEvent = false;

    /**
     * @internal
     * true로 설정시 셀이동을 막는다.
     */
    private _lockCellSelectionChange = false;

    /**
     * @internal
     * true로 설정시 셀이동을 막는다.
     */
    private _lockRowSelectionChange = false;

    /**
     * @internal
     * true로 설정시 스크롤 페이징을 막는다.
     */
    private _lockScrollPaging = false;

    /** @internal */
    public _clientKey: string | null = null;

    private _asyncEventInvokeState: {
        storeData: boolean,
        beforeRowChange: boolean
    } = {
            beforeRowChange: false,
            storeData: false,
        };

    /** 
     * @internal
     * keydown시 정보가 채워진다.
     * newRowIndex, newColumnName onCurrentChanged에서 채워진다.
     * keyup이 일어날때 초기화된다.
     * 
     * onKeyDown -> onCurrentChanged -> onKeyUp 순으로 이벤트 호출
     * 아래화살표, 위화살표 입력시 사용자이벤트 onAfterChangeRowSelection의 호출을 onCurrentChanged에서가 아닌 onKeyUp까지 미룬다. 
     */
    private _keyDownForSelectionChange: { keyDown: boolean, keyCode: number | null, oldRowIndex: number | null, oldColumnName: string | null, newRowIndex: number | null, newColumnName: string | null }
        = { keyDown: false, keyCode: null, oldRowIndex: null, oldColumnName: null, newRowIndex: null, newColumnName: null }

    /**
     * @internal
     * 현재 edit중인 셀의 정보.
     * onEditCommit 설정된다.
     * handleEditCommit 에서 설정 -> handleCellEdited 에서 사용
     * handleEditCommit에서 사용자 이벤트로 입력이 cancel 된것을 handleCellEdited에서 해당 행의 state 변경하는 용도
     */
    private _currentEditingCellInfomaiton = {
        columnName: '',
        rowIndex: -1,
        stateBeforeEditing: GridState.none,
        isCanceledOnEditCommit: false,
    }

    /**
     * @internal
     * 페이징 정보 
     * 첫번째 페이지는 0번
     */
    private pagingInfo: Pagination | undefined;

    /**
     * @internal
     * 리얼그리드에서 관리되지 않는 empty state인 dataRow(itemIndex아님)를 담고있는 어레이
     */
    private _emptyDataRows: number[] = [];

    /**
     * @internal
     * initialize 함수가 호출 되었는지 여부
     */
    private _isInitialized = false;

    /**
     * @internal
     */
    public readCallbackList: { key: string, function: Read, done: boolean, canceled: boolean }[] = [];

    /**
     * @internal
     */
    public _initialColumnProperty: ChangedLayoutByColumn | null = null;

    /**
     * @internal
     */
    private invokedDblClicked = false;

    /**
     * @internal
     */
    private _keyDownEnterCellInfo: {
        isCommitCancel: boolean
    } | null = null

    /**
     * @internal
     */
    private _progressTimeoutHandle: any = null;

    /**
     * @internal
     */
    private _retrievePrivaciesToken: string | null = null;

    /**
     * 생성자에서 주입받은 아이디
     */
    public get id() {
        return this._id;
    }

    /**
     * 생성자에서 주입받은 그리드옵션
     */
    public get gridOption() {
        return this._gridOption;
    }

    /**
     * 세팅된 컬럼리스트
     */
    public get columns() {
        return this._columns;
    }

    /**
     * @internal
     * 관리되지않는 그리드옵션
     */
    public get unmanagedGridOptions() {
        return this._unmanagedGridOptions;
    }

    /**
     * @internal
     * 코드피커 getaData로 넘기는 fetch
     */
    public get codePickerAjaxEbp() {
        return this._codePickerAjaxEbp;
    }

    /**
     * 리얼그리드의 gridview
     */
    public get gridView(): any {
        return this._gridView;
    }

    /**
     * provider
     */
    public get provider() {
        return this._provider;
    }

    /**
     * 리얼그리드의 프로바이더
     */
    public get realGridProvider() {
        return this._provider && this._provider._provider ? this._provider._provider : null;
    }

    /** @internal */
    private get _realGridProvider() {
        return this._provider && this._provider._provider ? this._provider._provider : null;
    }

    public get isReady() {
        return this._gridView && this._realGridProvider ? true : false;
    }

    /**
     * 새로운행이 추가되기전에 발생되는 이벤트, cancel 가능
     * [Events.BeforeAddRowEventArgs]
     * 이벤트파리미터 클래스
     */
    public readonly onBeforeAddRow = new ChaningEvent<((e: Events.BeforeAddRowEventArgs) => void)>();

    /**
     * 새로운행이 추가되기된 이후에 발생하는 이벤트
     * [AfterAddRowEventArgs]
     */
    public readonly onAfterAddRow = new ChaningEvent<((e: Events.AfterAddRowEventArgs) => void)>();

    /**
     * 행 삭제전에 발생하는 이벤트
     * cancel가능
     * [BeforeRemoveRowEventArgs]
     */
    public readonly onBeforeRemoveRow = new ChaningEvent<((e: Events.BeforeRemoveRowEventArgs) => void)>();

    /**
     * 행이 삭제된 이후에 발생하는 이벤트
     * [AfterRemoveRowEventArgs]
     */
    public readonly onAfterRemoveRow = new ChaningEvent<((e: Events.AfterRemoveRowEventArgs) => void)>();

    /**
     * 셀의 데이터가 변경되기전 발생하는 이벤트
     * cancel가능 
     * [BeforeChangeEventArgs]
     */
    public readonly onBeforeChange = new ChaningEvent<((e: Events.BeforeChangeEventArgs) => void)>();

    /**
     * 셀의 데이터가 변경되기전에 변경되기 발생하는 이벤트
     * 변경되기 전값과 변경된 값을 이용한 유효성검사를 할 수 있다.
     * cancel가능 
     * [ValidateChangeEventArgs]
     */
    public readonly onValidateChange = new ChaningEvent<((e: Events.ValidateChangeEventArgs) => void)>();

    /**
     * @event
     * 셀의 데이터가 변경된 이후에 발생하는 이벤트
     * [AfterChangeEventArgs]
     */
    public readonly onAfterChange = new ChaningEvent<((e: Events.AfterChangeEventArgs) => void)>();


    /**
     * @event
     * 셀의 데이터가 변경된 이후에 발생하는 이벤트
     * [AfterChangeEventArgs]
     */
    public readonly onAfterChangeAsync = new ChaningEvent<((e: Events.AfterChangeEventArgs) => Promise<void>)>();


    /**
     * 
     */
    public readonly onBeforeCheck = new ChaningEvent<((e: Events.BeforeCheckEventArgs) => void)>();

    /**
     * before
     * checkable 그리드에서 행이 체크상태가 변경된 이후에 발생하는 이벤트
     * [AfterCheckEventArgs]
     */
    public readonly onAfterCheck = new ChaningEvent<((e: Events.AfterCheckEventArgs) => void)>();

    /**
     * checkable 그리드에서 헤더의 전체체크, 해제 여부가 바뀐후 발생하는 이벤트
     * [AfterHeaderCheckEventArgs]
     */
    public readonly onAfterHeaderCheck = new ChaningEvent<((e: Events.AfterHeaderCheckEventArgs) => void)>();

    /**
     * 셀 선택이 바뀌기 전에 발생하는 이벤트 
     * [BeforeSelectChangeEventArgs]
     */
    public readonly onBeforeSelectChange = new ChaningEvent<((e: Events.BeforeSelectChangeEventArgs) => void)>();

    /**
     * 셀선택이 바뀐 이후에 발생하는 이벤트
     * [AfterSelectChangeEventArgs]
     */
    public readonly onAfterSelectChange = new ChaningEvent<((e: Events.AfterSelectChangeEventArgs) => void)>();

    /**
     * 셀 선택이 바뀌기 전에 발생하는 이벤트 
     * [BeforeSelectChangeEventArgs]
     */
    public readonly onBeforeChangeRowSelection = new ChaningEvent<((e: Events.BeforeSelectChangeEventArgs) => void)>();

    /**
     * 셀 선택이 바뀌기 전에 발생하는 이벤트 
     * [BeforeSelectChangeEventArgs]
     */
    public readonly onBeforeChangeRowSelectionAsync = new ChaningEvent<((e: Events.BeforeSelectChangeAsyncEventArgs) => Promise<boolean>)>();


    /**
     * 셀선택이 바뀐 이후에 발생하는 이벤트
     * [AfterSelectChangeEventArgs]
     */
    public readonly onAfterChangeRowSelection = new ChaningEvent<((e: Events.AfterSelectChangeEventArgs) => void)>();

    /**
     * 데이터를 읽은 후에 호출되는 이벤트
     * [AfterReadEventArgs]
     */
    public readonly onAfterRead = new ChaningEvent<((e: Events.AfterReadEventArgs) => void)>();

    /**
     * 데이터 셀을 더블클릭했을때 발생하는 이벤트
     * [DataCellDblClickedEventArgs]
     */
    public readonly onDataCellDblClicked = new ChaningEvent<((e: Events.DataCellClickedEventArgs) => void)>();

    /**
     * 데이터 셀을 클릭했을때 발생하는 이벤트
     * [DataCellClickedEventArgs]
     */
    public readonly onDataCellClicked = new ChaningEvent<((e: Events.DataCellClickedEventArgs) => void)>();


    /**
     * 마우스 오버시 툴팁을 보여주기전 발생
     * [DataCellDblClickedEventArgs]
     */
    public readonly onShowTooltip = new ChaningEvent<((e: Events.ShowTooltipEventArgs) => string)>();

    /**
     * @internal
     * 데이터, 체크등 내부의 데이터가 변경될때 호출
     * [AfterDataChangeEventArgs]
     */
    public readonly onAfterDataChanged = new ChaningEvent<((e: Events.AfterDataChangeEventArgs) => void)>();

    /**
     * PageContainer에서 Drawer를 보여줘야 되는 상황에서 호출되는 이벤트
     * [DrawerEventArgs]
     */
    public readonly onDrawer = new ChaningEvent<((e: Events.DrawerEventArgs) => void)>();

    /**
     * 
     */
    public readonly onAlert = new ChaningEvent<((e: Events.AlertEventArgs) => void)>();

    /**
     * @internal
     * rowSelectMode에서 엔터 입력이 들어왔을때 호출되는 이벤트
     * [SelectByEnterEventArgs]
     */
    public readonly onSelectByEnter = new ChaningEvent<((e: Events.SelectByEnterEventArgs) => void)>();

    /**
     * 수정가능한 그리드에서 엔터, 방향키로 셀 이동시 빈값이면 발생하는 이벤트
     * [SelectByEnterEventArgs]
     */
    public readonly onSkipEmptyCell = new ChaningEvent<((e: Events.SkipEmptyCellEventArgs) => void)>();

    /**
     * 헤더의 컬럼 너비가 바뀌고나서 호출됨
     */
    public readonly onColumnWidthChanged = new ChaningEvent<((e: Events.ColumnWidthChangeEventArgs) => void)>();

    /**
     * hasActionButton true인 컬럼의 액션버튼을 클릭했을때 발생한다.
     */
    public readonly onClickCustomActionButton = new ChaningEvent<((e: Events.ClickCustomActionEventArgs) => void)>();

    /**
     * hasImageButton true인 컬럼의 액션버튼을 클릭했을때 발생한다.
     */
    public readonly onImageButtonClicked = new ChaningEvent<((e: Events.ImageButtonClickedEventArgs) => void)>();

    /**
     * 페이지 넘버가 바뀌기 전에 호출된다.
     */
    public readonly onBeforeChangePageNumber = new ChaningEvent<((e: Events.BeforePageNumberChangeArgs) => void)>();

    /**
     * 페이지 넘버가 바뀐후에 호출된다.
     */
    public readonly onAfterChangePageNumber = new ChaningEvent<((e: Events.AfterPageNumberChangeArgs) => void)>();

    /**
     * 코드피커 다이얼로그가 호출되기 이전에 호출됨, cancel 가능
     */
    public readonly onBeforeCallCodePicker = new ChaningEvent<((e: Events.BeforeCallCodePickerEventArgs) => void)>();

    /**
     * 스크롤이 마지막에 이르렀을때 호출된다.
     */
    public readonly onReachLastPage = new ChaningEvent<((e: Events.ReachEndOfPageArgs) => void)>();

    /**
     * 드래그를 시작하면 발생하는 이벤트
     * cancle = true drag가 취소된다. 
     * [InnerDragStartEventArgs]
     */
    public readonly onInnerDragStart = new ChaningEvent<((e: Events.InnerDragStartEventArgs) => boolean)>();

    /**
     * 드래그 상태에서 마우스버튼을 놓으면 발생하는 이벤트.
     * 행 단위 드래그 이동에 사용
     * cancle = true drag가 취소된다. 
     * [InnerDropEventArgs]
     */
    public readonly onInnerDrop = new ChaningEvent<((e: Events.InnerDropEventArgs) => void)>();

    /**
     * link타입 컬럼 클릭 이벤트
     */
    public readonly onClickLinkColumn = new ChaningEvent<((e: Events.LinkColumnClickedEventArgs) => void)>();

    /**
     * 
     */
    public readonly onDisplayEmptyBody = new ChaningEvent<((e: any) => void)>();

    /**
     * 
     */
    public readonly onMoveFocus = new ChaningEvent<(e: CommonEvents.MoveFocusEventArgs) => void>();

    /**
     * 
     */
    public readonly onKeyDown = new ChaningEvent<(e: Events.KeyDownEventArgs) => void>();

    /**
     * 
     */
    public readonly onKeyUp = new ChaningEvent<(e: Events.KeyUpEventArgs) => void>();

    /**
     * 
     */
    public readonly onAfterColumnHeaderCheck = new ChaningEvent<(e: Events.AfterColumnHeaderCheckEventArgs) => void>();

    /**
    * type = autoComplete 일때, 아이템을 선택했을 때 호출
    */
    public readonly onSearchedItemSelect = new ChaningEvent<(e: Events.SearchedItemSelectEventArgs) => void>();

    /**
     * 개인정보 암호화 - 개인정보 조회 이벤트
     */
    public readonly onGetPrivacy = new ChaningEvent<(e: Events.PrivacyEventArgs) => Promise<{ privacyKey: string, privacyValue: string }[]>>();

    /**
     * 개인정보 암호화 - 개인정보 조회 완료 이벤트
     */
    public readonly onPrivacyRetrieved = new ChaningEvent<(e: Events.PrivacyRetrievedEventArgs) => void>();

    /**
     * 컨텍스트 메뉴
     */
    public readonly onContextMenuItemClicked = new ChaningEvent<(e: Events.ContextMenuItemClickEventArgs) => void>();

    /**
     * 컨텍스트 메뉴 - 엑셀 내려받기
     */
    public readonly onContextMenuExportExcel = new ChaningEvent<(e: Events.ExportExcelRClick) => void>();

    /**
     * @internal
     */
    public onChangeEmptySetType: (emptySetType: EmptySetType) => void
        = (emptySetType: EmptySetType) => { };

    /**
     * @internal
     */
    public onClickContextMenuExportExcel: () => void
        = () => { };

    /**
     * @internal
     */
    public onClickContextMenuShowSearchBox: () => void
        = () => { };

    /**
     * @internal
     */
    public onClickContextMenuCustomColumn: () => void
        = () => { };

    /**
     * @internal
     */
    public onCallCodePickerDialog: ((e: any) => void) | undefined;

    /**
     * @internal
     */
    public onCallCodePickerSearch: ((e: any) => void) | undefined;

    /**
     * @internal
     * OBTDataGrid에 데이트피커 팝오버를 열라고 요청
     */
    public onCallDatePickerDialog: ((e: any) => void) | undefined;

    /**
     * @internal
     * OBTDataGrid에 데이트피커 팝오버를 닫으라고 요청
     */
    public onCloseDatePickerDialog: (() => void) | undefined;

    /**
 * @internal
 * AutoCompleteDialog에 팝오버를 열라고 요청
 */
    public onCallAutoCompleteDialog: ((e: any) => void) | undefined;

    /**
     * @internal
     * AutoCompelteDialog에 팝오버를 닫으라고 요청
     */
    public onCloseAutoCompleteDialog: (() => void) | undefined;

    /**
     * @internal
     * onShowEditor에 의해 처음 세팅될 애들
     */
    public onShowEditorAutoCompleteDialog: ((e: any) => void) | undefined;

    /**
     * @internal
     * AutoCompleteDialog와 연결되는 onKeyDown
     */
    public onKeyDownAutoComplete: ((keyCode: number) => boolean) | undefined;

    /**
     * @internal
     * OBTDataGrid에 데이트피커 팝오버를 닫으라고 요청
     */
    public onKeyDownInternal: ((keyCode: number) => boolean) | undefined;

    private gridImageLabelProcessor = new GridImageLabelProcessor(this);

    private userCustomLayoutProcessor = new UserCustomGridLayoutProcessor();

    /**
     * TODO: @test
     */
    private erpNumberFormatType: IERPNumberFormatType | null = null;
    // {
    //     sFormat02: 2,
    //     sFormat03: 1,
    //     sFormat04: 4,
    //     sFormat05: 5,
    //     sFormat06: 6,
    //     sFormat07: 7,
    //     sFormat08: 8,
    //     sFormat10: 1,
    // };

    /**
     * 일괄저장등의 처리에 연계될 디테일 그리드
     */
    private detailGrids: Array<OBTDataGridInterface> | null = null;

    /**
     * 
     * @param id 그리드의 아이디 한 페이지 내에서는 유일해야한다.
     * @param options 그리드의 전역옵션
     */
    constructor(id: string, options?: IGridGlobalOption) {
        if (id.length <= 0) {
            throw new Error('OBTDataGridInterface.constructor: parameter id required.')
        }

        this._id = id;

        if (options) {
            this.normalizeGridGlobalOption(options);
            this._gridOption = options;
        } else {
            this._gridOption = {};
            this.normalizeGridGlobalOption(this._gridOption);
        }

        if (this._gridOption.codePickerFetch) {
            console.error('OBTPageContainer 하위에 ' + id + ' OBTDataGrid가 위치 한다면 그리드 옵션의 codePickerFetch를 할당하지 않아도 상관없습니다.');
        }

        if (this._gridOption.codePickerFetch && this._gridOption.codePickerFetch instanceof Fetch) {
            this._codePickerAjaxEbp = this._gridOption.codePickerFetch;
        } else if (this._gridOption.codePickerFetch) {
            this._codePickerAjaxEbp = new Fetch(this._gridOption.codePickerFetch);
        }
    }

    /**
     * @internal
     * 할당된 필드값을 기반으로
     * 그리드 옵션을 리턴한다. 
     * indicator.visible, sorting.enabled, fixed.colCount
     * @returns 그리드옵션
     */
    private getRealGridOptions(obtGridOption: IGridGlobalOption): any {
        if (!this._gridView) {
            return;
        }

        const gridOption = {
            softDeleting: true,
            currentChangingFirst: true,
            // resizeDelay: 3000, // TODO:
            header: {
                height: 0,
                minHeight: 33
            },
            display: {
                fitStyle: 'fill', // none, even 
                showEmptyMessage: true,
                emptyMessage: '',
                emptyShowTooltip: this.gridOption.emptyShowTooltip === true ? true : false,
                focusColor: "#00000000",
                focusBorderWidth: 0,
                focusBackground: StandardDesign.GridColorRGB.focusRowBackgroundColor,
                focusActiveColor: StandardDesign.GridColorRGB.focusRowActiveBackgroundColor,
                rowHeight: 33,
                columnResizable: this.gridOption.columnResizable === false ? false : true,
                rowChangeDelay: 100,
                showInnerFocus: true,
                editItemMerging: true,
                rowResizable: false,
                eachRowResizable: obtGridOption.eachRowResizable,// fitRowHeightAll사용을 위함
            },
            // header: {
            //     height: 32,
            //     // itemOffset: 0
            // },
            filtering: {
                enabled: false
            },
            panel: { visible: false },
            // 그리드 앞에 로우별로 변경된 데이터와 행번호를 알수 있는 인디케이터를 노출하는 옵션
            indicator: {
                visible: obtGridOption.indicator,
                footImageUrl: emptyImage,
            },
            // 데이터의 변경여부를 표시해주는 맨앞의 컬럼 표시여부
            stateBar: { visible: false },
            //selection: { style: 'singleRow' },
            // 삭제된 행은 그리드에서 보일것인지 여부
            hideDeletedRows: true,
            sortMode: 'explicit',
            sorting: {
                enabled: obtGridOption.preventSort === true ? false : true,
                style: 'inclusive'
            },
            //filterMode: 'explicit', //TODO: tree에서 동작안함 확인필요
            editor: {
                useCssStyleDropDownList: true, // 드랍다운에 css 스타일 적용
                // useCssStylePopupMenu: true, // 오른쪽 클릭 컨텍스트 메뉴
                // 그리드 내부에 에디터 엘리먼트를 포함( 포커스 검사를 위해 추가 )
                viewGridInside: true,
                //applyCellFont: true, // TODO: 얘를 true로 설정하면 number타입 컬럼의 dynamicStyle.editable이 먹지 않는다.
                validateOnEdited: false,
                exitGridWhenTab: 'grid'
            },
            fixed: {
                colCount: obtGridOption.fixedColumnCount
            }
        }

        return gridOption;
    }

    /**
     * @internal
     * 디폴트로 사용할 그리드 체크바 옵션 리턴
     * @link http://help.realgrid.com/api/types/CheckBar/
     * @returns 체크바옵션 
     */
    private getDefaultCheckBarOption(): any {
        return {
            visible: false,
            disabled: false,
            visibleOnly: false,
            checkableOnly: false,
            showAll: true,
            width: 30,
            exclusive: false,
            syncHeadCheck: true,
            drawCheckBox: true,
            headCheckImageUrl: checkImg,
            headUnCheckImageUrl: unCheckImg,
            checkImageUrl: checkImg,
            unCheckImageUrl: unCheckImg,
            disableCheckImageUrl: disabledCheckImg,
            disableUnCheckImageUrl: disabledUnCheckImg,
            // dynamicStyles: [{
            //     criteria: "not checkable",
            //     styles: {
            //         background: "#eceded",
            //     } as IRealGridCellStyleOption
            // }]
        }
    }

    /**
     * @internal
     * 그리드의 기본적인 edit option
     * @returns edit option
     */
    private getDefaultGridEditOption(): any {
        return {
            deletable: false,
            readOnly: false,
            editable: false,
            updatable: false,
            appendable: false,
            // deleteRowsConfirm: false,
            // // 탭키로 셀간 포커스 이동
            // useTabKey: true,
            // // 엔터키를 탭키와 동일하게 사용한다.
            // enterToTab: true,
            // enterToNextRow: false,
            // validateOnEdited: true,
            // validateOnExit: true,
            // // 셀에 포커스가 있을때 엔터입력시 에디트 모드로 전환
            // enterToEdit: false,
            // checkable: true,
            // checkDiff: true,
            // checkCellDiff: true,
            // //skipReadOnly: true,
            // displayEmptyEditRow: false,
            enterToTab: true,
            checkDiff: true,
            checkCellDiff: true,
            //skipReadOnly: this._editable,
            //skipReadOnlyCell: this._editable, // 테스트 해볼것 : true이면 한 컬럼에서 행간(Vertical 컬럼 그룹 행을 포함) 이동시 readOnly 셀은 건너뛰고 다음 행의 컬럼 셀로 이동한다.
            crossWhenExitLast: true,
            maxLengthToNextCell: true,
            showInvalidFormatMessage: false,
        }
    }

    /**
     * @internal
     * @param owner 
     * @param gridWrapperId 
     * @param clientKey 클라이언트를 구분하기 위한 키값
     * @param pageAuthority 
     */
    public _initialize(owner: OBTDataGrid, gridWrapperId: string, clientKey: string | null, pageAuthority?: IPageAuthority): void {
        this._gridWrapperId = gridWrapperId;
        this._clientKey = clientKey;
        this._owner = owner;

        // 그리드 인스턴스 생성
        this._gridView = (this._gridOption.gridType === GridType.treeView
            ? new RealGridJS.TreeView(this._gridElementId) : new RealGridJS.GridView(this._gridElementId));

        // 그리드 데이터 소스 세팅
        if (this._gridOption.gridType === GridType.treeView) {
            this._gridView.setDataSource(this._realGridProvider ? this._realGridProvider : null);
        } else {
            this._gridView.setDataProvider(this._realGridProvider ? this._realGridProvider : null);
        }

        if (pageAuthority) {
            this.gridOption.pageAuthority = pageAuthority;
        }

        // 높이에 따른 바인딩 
        this.onChangeEmptySetType(EmptySetType.beforeSearch);

        // 그리드 옵션 설정
        this._setGridOption(this.gridOption);

        // this._setColumns(true);

        // 컬럼 세팅, 컬럼세팅은 editable, appendable 설정 이후에
        this._setColumns(this._isInitialized === true ? false : true);

        this._gridView.setStyles(GridStyle.styles);

        // 리얼 그리드 이벤트 바인딩
        this.bindRealGridEventHandler();

        // 오른쪽버튼 클릭시 뜨는 메뉴
        if (this.gridOption.preventRClick !== true) {
            this.setContextMenu([]);
        }

        // _initialize가 한번 호출된 상태
        this._isInitialized = true;
    }

    /**
     * @internal
     * @param erpNumberFormatType 
     */
    public _setErpUserNumberFormat(erpNumberFormatType?: IERPNumberFormatType) {
        this.erpNumberFormatType = erpNumberFormatType ? erpNumberFormatType : null;
    }

    private setContextMenu(additionalMenuItems?: any[]) {
        const contextMenuItems: any[] = []

        if (this.checkPossibleGridAction('printable', false) === true) {
            contextMenuItems.push({ label: OrbitInternalLangPack.getText('WE000026853', '엑셀 변환하기') });
        }

        if (this.gridOption.useCustomLayout === true && this._clientKey) {
            //TODO: 다국어
            contextMenuItems.push({ label: '컬럼 표시' });
        }

        contextMenuItems.push({ label: OrbitInternalLangPack.getText('WE000000999', '찾기') });

        if (additionalMenuItems && additionalMenuItems.length > 0) {
            contextMenuItems.push({ label: '-' });
            additionalMenuItems.forEach(item => {
                contextMenuItems.push(item);
            });
        }

        this._gridView.setContextMenu(contextMenuItems);
    }

    private initializeColumnSetting() {
        const getIndex = (columns: IColumn[], name: string,) => {
            return columns.findIndex((column) => {
                if (column.type === ColumnType.group && column.columns) {
                    return getIndex(column.columns, name);
                }

                return column.name === name;
            })
        }

        // 세션 정보에 따른 컬럼 전처리
        const columnBackup = this._columns;

        ignoreException(() => {
            // 최초 컬럼의 정보를 저장
            if (this.columns && this._clientKey && this.gridOption.useCustomLayout === true) {
                this._initialColumnProperty = (this.getFlatColumns(false, true).filter(item => item.name).reduce((accu, curr) => {
                    accu[curr.name] = {
                        width: curr.width,
                        visible: curr.visible === undefined ? true : curr.visible,
                        displayIndex: getIndex(this.columns!, curr.name)
                    } as CustomColumnProperty;
                    return accu;
                }, {}));

                this._columns = this.userCustomLayoutProcessor.convertUserCustomColumns(this._clientKey, this.columns);

                // 소팅
                const userSorting = this.userCustomLayoutProcessor.getUserSorting(this._clientKey);
                if (userSorting && userSorting.length > 0) {
                    const columnNames = userSorting.map(item => item.orgFieldName)

                    this.gridView.orderBy(
                        columnNames,
                        userSorting.map(item => item.direction)
                    );
                }
            }
        }, (e) => {
            console.error(e)
            this._columns = columnBackup;
        });
    }

    /**
     * 리얼그리드 이벤트 연결
     */
    private bindRealGridEventHandler() {
        this._gridView.onRowInserting = this.handleRowInserting.bind(this);
        this._gridView.onRowInserted = this.handleRowInserted.bind(this);

        // check
        this._gridView.onItemChecked = this.handleItemChecked.bind(this);
        this._gridView.onItemsChecked = this.handleItemsChecked.bind(this);

        this._gridView.onItemAllChecked = this.handleItemAllChecked.bind(this);

        this._gridView.onColumnCheckedChanged = this.handleColumnCheckedChanged.bind(this);

        // edit
        this._gridView.onShowEditor = this.handleShowEditor.bind(this);
        this._gridView.onHideEditor = this.handleHideEditor.bind(this);
        this._gridView.onEditCommit = this.handleEditCommit.bind(this);
        this._gridView.onCellEdited = this.handleCellEdited.bind(this);

        // 자동완성
        this._gridView.onEditChange = this.handleEditChange.bind(this);

        // 편집취소
        this._gridView.onEditCanceled = this.handleEditCanceled.bind(this);

        // click
        this._gridView.onDataCellDblClicked = this.handleDataCellDblClicked.bind(this);
        this._gridView.onDataCellClicked = this.handleDataCellClicked.bind(this);

        // row change
        this._gridView.onCurrentChanging = this.handleCurrentChanging.bind(this);
        this._gridView.onCurrentChanged = this.handleCurrentChanged.bind(this);

        // cell button
        this._gridView.onCellButtonClicked = this.handleCellButtonClick.bind(this);
        this._gridView.onImageButtonClicked = this.handleImageButtonClick.bind(this);

        // 헤더 이미지 버튼
        this._gridView.onColumnHeaderImageClicked = this.handleColumnHeaderImageClicked.bind(this);

        // drag
        this._gridView.onInnerDragStart = this.handleInnerDragStart.bind(this);
        this._gridView.onInnerDrop = this.handleInnerDrop.bind(this);

        // tooltip
        this._gridView.onShowTooltip = this.handleOnShowTooltip.bind(this);

        // 키처리
        this._gridView.onKeyDown = this.handleOnKeyDown.bind(this);
        this._gridView.onKeyUp = this.handleOnKeyUp.bind(this);

        // etc
        this._gridView.onColumnPropertyChanged = this.handleColumnPropertyChanged.bind(this);

        // 컨텍스트 메뉴
        this._gridView.onContextMenuPopup = this.handleContextMenuPopup.bind(this);
        this._gridView.onContextMenuItemClicked = this.handleContextMenuItemClicked.bind(this);

        // 정렬
        this._gridView.onSorting = this.handleSorting.bind(this);
        this._gridView.onSortingChanged = this.handleSortingChanged.bind(this);

        // 페이징 처리용 스크롤 이벤트 
        this._gridView.onScrollToBottom = this.handleScrollToBottom.bind(this);
        this._gridView.onTopItemIndexChanged = this.handleTopItemIndexChanged.bind(this);

        // 링크 컬럼
        this._gridView.onLinkableCellClicked = this.handleLinkableCellClicked.bind(this);

        // 트리뷰 이벤트 
        if (this._gridOption.gridType === GridType.treeView) {
            this._gridView.onTreeItemCollapsed = this.handleTreeItemCollapsed.bind(this);
            this._gridView.onTreeItemExpanded = this.handleTreeItemExpanded.bind(this);
            this._gridView.onTreeItemCollapsing = this.handleTreeItemCollapsing.bind(this);
            this._gridView.onTreeItemExpanding = this.handleTreeItemExpanding.bind(this);
        }

        // 붙여넣기
        this._gridView.onEditRowPasted = this.handleEditRowPasted.bind(this);
    }

    public asTreeView(): ITreeViewMethod {
        if (this.gridOption.gridType === GridType.gridView) {
            throw new Error('gridView로 설정되어 있습니다.');
        }
        return this as ITreeViewMethod;
    }

    /**
     * @internal
     * 컬럼정보를 데이터 프로바이더의 필드로 매핑한다.
     */
    private mapIColumnToProviderFields(columns: IColumn[] | undefined): any[] {
        if (!columns) {
            return [];
        }

        const resultArray: any[] = [];
        columns
            .forEach(column => {
                if (column.type === ColumnType.number) {
                    resultArray.push({
                        fieldName: OBTDataGridInterface.getFieldName(column),
                        dataType: "number",
                        calculateCallback: column.calculateCallback
                    });
                } else if (column.type === ColumnType.date) {
                    resultArray.push({
                        fieldName: OBTDataGridInterface.getFieldName(column),
                        dataType: "text",
                        datetimeFormat: this.resolveDateFormatByColumnProperty(column.dateFormat, 'datetimeFormat')
                    });
                } else if (column.type === ColumnType.dateTime) {
                    resultArray.push({
                        fieldName: OBTDataGridInterface.getFieldName(column),
                        dataType: "datetime",
                        datetimeFormat: "iso",
                        amText: "AM",
                        pmText: "PM"
                    });
                } else if (column.type === ColumnType.mask) {
                    resultArray.push({
                        fieldName: OBTDataGridInterface.getFieldName(column),
                        dataType: "text",
                    });
                } else if (column.type === ColumnType.group) {
                    resultArray.push(...this.mapIColumnToProviderFields(column.columns));
                } else if (column.type === ColumnType.image) {
                    resultArray.push({
                        fieldName: OBTDataGridInterface.getFieldName(column),
                        dataType: "text",
                    });
                } else if (column.type === ColumnType.button) {
                    resultArray.push({
                        fieldName: OBTDataGridInterface.getFieldName(column),
                        dataType: "data",
                    });
                } else {
                    resultArray.push({
                        fieldName: OBTDataGridInterface.getFieldName(column),
                        dataType: "text",
                        calculateCallback: column.calculateCallback
                    });
                    if (column.subName) {
                        resultArray.push({
                            fieldName: column.subName,
                            dataType: "text",
                            calculateCallback: column.calculateCallback
                        });
                    }
                }
                // 개인정보 암호화 를 사용하는 경우 원본 데이터 필드를 추가한다.
                if (this.isUsePrivacy(column.name, column)) {
                    resultArray.push({
                        fieldName: OBTDataGridInterface.getOriginFieldName(column),
                        dataType: "text"
                    });
                }
            });

        return resultArray;
    }

    /**
     * @internal
     * IColumn 타입을 실제 리얼그리드의 컬럼정보 오브젝트로 매핑한다.
     */
    private mapIColumnToRealGridColumn(column: IColumn): any {
        // 오타 수정
        if (column.buttonVisiblity !== undefined) {
            column.buttonVisibility = column.buttonVisiblity
        }

        // 기본적인 컬럼 옵션 설정
        let realGridColumn: any = {
            name: column.name,
            fieldName: column.subName ? Util.cl(column.name, column.subName) : OBTDataGridInterface.getFieldName(column),
            header: typeof column.header === 'string' ? {
                text: column.header,
                visible: column.groupHeaderVisible !== undefined ? column.groupHeaderVisible : true,
                styles: column.headerStyle,
                checkLocation: column.headerCheckLocation ? column.headerCheckLocation : undefined
            } : column.header,
            width: typeof column.width === 'string' && !isNaN(Number(column.width)) ? Number(column.width) : column.width,
            fillWidth: column.fillWidth || column.isAutoWidth === true ? (
                column.fillWidth ? column.fillWidth : 1
            ) : 0,
            require: column.required !== undefined ? column.required : false,
            native: column.unmanaged,
            visible: column.visible !== undefined ? column.visible : true,
            orientation: column.orientation ? column.orientation : 'horizontal',
            // readOnly: column.editable === false ? true : false,
            renderer: {
                "showTooltip": column.useTooltip !== undefined ? column.useTooltip : false
            },
            styles: column.style ? column.style : {},
            dynamicStyles: column.dynamicStyles,
            checked: column.isHeaderCheck ? true : false,
        }

        if (column.unmanaged && column.unmanaged.renderer) {
            realGridColumn.renderer = Object.assign({}, realGridColumn.renderer, column.unmanaged.renderer);
        }

        // hasActionButton 버튼
        if (column.hasActionButton === true) {
            realGridColumn = Object.assign(realGridColumn, {
                button: 'action',
                buttonVisibility: buttonVisiblityFunction(this, column)
            });
        }

        // 아이콘
        if (column.useIcon !== undefined) {
            console.warn('컬럼 속성의 useIcon은 의미없는 옵션입니다. iconImageList 옵션의 존재 여부로 아이콘 렌더링을 결정합니다.');
        }

        if (column.useIcon !== false && column.iconImageList) {
            this.registerColumnImageList(column.name, column.iconImageList);
            realGridColumn.imageList = column.name + '_image';
            realGridColumn.renderer = {
                ...realGridColumn.renderer,
                type: "icon",
                textVisible: column.onlyIcon === true ? false : true,
                showTooltip: column.useTooltip !== undefined ? column.useTooltip : false
            };
        }

        realGridColumn = this.gridImageLabelProcessor.buildColumn(realGridColumn, column);

        // 동적 렌더러
        if (column.renderer) {
            realGridColumn.dynamicStyles = GridUtil.mergeDynamicStyles(realGridColumn.dynamicStyles, (grid, index, value) => {
                if (column.renderer) {
                    if (typeof column.renderer === 'string') {
                        return {
                            renderer: column.renderer,
                        }
                    } else {
                        return {
                            renderer: column.renderer(index.itemIndex, index.column)
                        }
                    }
                }
            });
        }

        // 이미지 버튼
        if (column.imageButtonList && column.imageButtonList.length >= 1) {
            realGridColumn = {
                ...realGridColumn,
                buttonVisibility: buttonVisiblityFunction(this, column),
            };

            realGridColumn.renderer = {
                ...realGridColumn.renderer,
                type: "imageButtons",
                alignment: this.mapAlignmentToRealGridAlignment(column.imageButtonAlignment ? column.imageButtonAlignment : ColumnAlignment.far),
                images: column.imageButtonList,
                cursor: 'pointer'
            };
        }

        if (column.getProfileInfo) {
            realGridColumn = {
                ...realGridColumn,
                buttonVisibility: buttonVisiblityFunction(this, column),
            };

            if (realGridColumn && realGridColumn.renderer && Array.isArray(realGridColumn.renderer.images)) {
                realGridColumn.renderer.images.push({
                    name: 'userProfileIcon',
                    down: userProfileIconOver,
                    hover: userProfileIconOver,
                    up: userProfileIcon,
                    width: 20,
                    cursor: 'pointer'
                } as ImageButton);
            } else {
                realGridColumn.renderer = {
                    ...realGridColumn.renderer,
                    type: "imageButtons",
                    alignment: 'far',
                    images: [
                        {
                            name: 'userProfileIcon',
                            down: userProfileIconOver,
                            hover: userProfileIconOver,
                            up: userProfileIcon,
                            width: 20,
                            cursor: 'pointer'
                        } as ImageButton
                    ]
                };
            }
        }

        // 컬럼 타입별 리얼그리드 컬럼 매핑
        if (column.type === undefined || column.type === ColumnType.text) {
            realGridColumn = this.makeTextColumn(realGridColumn, column);
        } else if (column.type === ColumnType.multiline) {
            realGridColumn = this.makeMultiLineColumn(realGridColumn, column);
        } else if (column.type === ColumnType.number) {
            realGridColumn = this.makeNumberColumn(realGridColumn, column);
        } else if (column.type === ColumnType.dateTime) {
            realGridColumn = this.makeDateTimeColumn(realGridColumn, column);
        } else if (column.type === ColumnType.check) {
            realGridColumn = this.makeCheckColumn(realGridColumn, column);
        } else if (column.type === ColumnType.dropDown) {
            realGridColumn = this.makeDropdownColumn(realGridColumn, column);
        } else if (column.type === ColumnType.codePicker) {
            realGridColumn = this.makeCodePickerColumn(realGridColumn, column);
        } else if (column.type === ColumnType.date) {
            realGridColumn = this.makeDateTextColumn(realGridColumn, column);
        } else if (column.type === ColumnType.mask) {
            realGridColumn = this.makeMaskColumn(realGridColumn, column);
        } else if (column.type === ColumnType.time) {
            realGridColumn = this.makeTimeColumn(realGridColumn, column);
        } else if (column.type === ColumnType.group) {
            realGridColumn = this.makeGroupColumn(realGridColumn, column);
        } else if (column.type === ColumnType.image) {
            realGridColumn = this.makeImageColumn(realGridColumn, column);
        } else if (column.type === ColumnType.progress) {
            realGridColumn = this.makeProgressColumn(realGridColumn, column);
        } else if (column.type === ColumnType.link) {
            realGridColumn = this.makeLinkColumn(realGridColumn, column);
        } else if (column.type === ColumnType.button) {
            realGridColumn = this.makeButtonColumn(realGridColumn, column);
        }

        // 코드피커 타입이 아니어도 코드피커 아이콘을 가질수 있다.
        if (column.type !== ColumnType.codePicker && column.customCodePicker) {
            realGridColumn = this.setCodePickerButton(realGridColumn, column);
        }

        // 정렬 설정
        this.setAlignmentProperty(realGridColumn, column);

        // 푸터 숫자 포매팅
        // const defaultFormat = '#,000';
        let resultFormat = this.makeNumberFormatString(column);//defaultFormat;
        // if (column.numberColumnFormat) {
        //     resultFormat = column.numberColumnFormat;
        // } else if (column.maxLength) {
        //     const resolveResult = this.resolveNumberFormat(column.maxLength ? column.maxLength : null);
        //     resultFormat = resolveResult.format;
        // }

        // 페이징 모드에서 footer 설정
        if (this.gridOption.paging === true && this.provider!.readFooter) {
            // 페이징 모드, readFooter가 지정되어있는 상황에서 footer설정 
            realGridColumn = {
                ...realGridColumn,
                footer: {
                    ...column.footer,
                    styles: {
                        textAlignment: realGridColumn.styles.textAlignment,
                        numberFormat: column.footer && column.footer.style && column.footer.style.numberFormat ? column.footer.style.numberFormat : resultFormat.format, //resultFormat.format,
                        ...column.footer ? column.footer.style : {},
                    },
                    callback: (index: any, footerIndex: number) => {
                        const pagingInfo = this.pagingInfo;

                        if (footerIndex === 0) {
                            if (pagingInfo
                                && pagingInfo.unreadedFooterData
                                && pagingInfo.unreadedFooterData[column.name] !== undefined
                            ) {
                                if (column.footer && column.footer.expression) {
                                    const unreadedFooterData = pagingInfo.unreadedFooterData[column.name];
                                    const readedFooterData = this.getSummary(
                                        column.name,
                                        column.footer.expression
                                    );

                                    if (column.footer!.expression === ColumnSummaryExpression.sum) {
                                        return unreadedFooterData + readedFooterData;
                                    } else if (column.footer.expression === ColumnSummaryExpression.max) {
                                        return Math.max(unreadedFooterData, readedFooterData);
                                    } else if (column.footer.expression === ColumnSummaryExpression.min) {
                                        return Math.min(unreadedFooterData, readedFooterData);
                                    } else if (column.footer.expression === ColumnSummaryExpression.count) {
                                        return unreadedFooterData + readedFooterData;
                                    } else {
                                        throw new Error('(Paging Mode) 지원하지 않는 expression 입니다.: ' + column.footer!.expression)
                                    }
                                } else if (column.footer && column.footer.callback) {
                                    return column.footer.callback(index, footerIndex);
                                } else {
                                    if (pagingInfo
                                        && pagingInfo.unreadedFooterData
                                        && pagingInfo.unreadedFooterData[column.name]) {
                                        return pagingInfo.unreadedFooterData[column.name];
                                    }
                                }
                            }
                        }
                    }
                }
            };
        }

        // 푸터 고정값
        if (column.footerValue || column.footer) {
            if (column.footerValue && !column.footer) {
                column.footer = {};
            }

            realGridColumn = {
                ...realGridColumn,
                footer: {
                    ...column.footer,
                    expression: column.footer!.expression,
                    groupExpression: column.footer!.groupExpression,
                    styles: {
                        textAlignment: realGridColumn.styles.textAlignment,
                        numberFormat: column.footer && column.footer.style && column.footer.style.numberFormat ? column.footer.style.numberFormat : resultFormat.format,
                        ...column.footer ? column.footer.style : {},
                    },
                    groupStyles: {
                        textAlignment: realGridColumn.styles.textAlignment,
                        numberFormat: resultFormat.format,
                        ...column.footer!.groupStyle || {},
                    },
                    callback: column.footer!.callback || column.footerValue ? (index: any, footerIndex: number) => {
                        if (this.isUseSFormat(column)) {

                        }

                        if (column.footerValue) {
                            if (footerIndex === 0) {
                                return column.footerValue
                            }
                        }

                        if (column.footer && column.footer.callback) {
                            return column.footer.callback(index, footerIndex);
                        }
                    } : undefined,
                    groupCallback: column.footer!.groupCallback || column.groupFooterValue ? (footerIndex: number, index: any) => {
                        if (column.groupFooterValue) {
                            if (footerIndex === 0) {
                                return column.groupFooterValue
                            }
                        }

                        if (column.footer && column.footer.groupCallback) {
                            return column.footer.groupCallback(index, footerIndex);
                        }
                    } : undefined,
                }
            };
        }

        //prefix, suffix 세팅
        if (column.prefix) {
            realGridColumn.styles = {
                ...realGridColumn.styles,
                prefix: column.prefix
            };
        }
        if (column.suffix) {
            realGridColumn.styles = {
                ...realGridColumn.styles,
                suffix: column.suffix
            };
        }

        if (column.sortable !== undefined) {
            realGridColumn.sortable = column.sortable;
        }

        // unmanaged TODO: 깊은 레벨까지 동작해야함
        if (column.unmanaged !== undefined && column.unmanaged) {
            const native = column.unmanaged;
            realGridColumn = Object.assign(realGridColumn, native);
        }

        // 기본 다이나믹 스타일
        // realGridColumn.dynamicStyles = this.mergeDynamicStyle(realGridColumn.dynamicStyles, (grid, index, value) => {
        //     const targetColumn = this.getColumnByName(index.column);
        //     const selectedIndex = this.getSelectedIndex();

        //     if (targetColumn) {
        //         const styleType = this.getCellStyleType(index.itemIndex, targetColumn, selectedIndex === index.itemIndex);
        //         return this.makeCellStyleObject(styleType).style;
        //     }
        // });

        // 필요없는 속성제거
        delete realGridColumn.require;
        delete realGridColumn.native;
        delete column.unmanaged;

        return realGridColumn;
    }

    isUseSFormat(column: IColumn): boolean {
        const formatArray = ['sFormat02', 'sFormat03', 'sFormat04', 'sFormat05', 'sFormat06', 'sFormat07', 'sFormat08'];

        if (column.numberColumnFormat && formatArray.includes(column.numberColumnFormat)) {
            return true;
        }

        return false;
    }

    /**
     * @internal
     * 정렬 속성을 realgrid형태로 설정
     */
    private setAlignmentProperty(realGridColumn: any, column: IColumn): void {
        let alignment = column.alignment;

        // 디폴트 값 처리
        if (!alignment) {
            if (column.type === ColumnType.codePicker
                || column.type === ColumnType.dropDown
                || column.type === ColumnType.mask
                || column.type === ColumnType.progress
                || column.type === ColumnType.date) {
                alignment = ColumnAlignment.center;
            } else if (column.type === ColumnType.number) {
                alignment = ColumnAlignment.far;
            } else {
                alignment = ColumnAlignment.near;
            }
        }

        const alignmentString = this.mapAlignmentToRealGridAlignment(alignment);

        if (realGridColumn.editor) {
            realGridColumn.editor = {
                ...realGridColumn.editor,
                textAlignment: alignmentString
            };
        }
        if (realGridColumn.styles) {
            realGridColumn.styles = {
                ...realGridColumn.styles,
                textAlignment: alignmentString
            };
        } else {
            realGridColumn = {
                ...realGridColumn,
                styles: {
                    textAlignment: alignmentString
                }
            };
        }
    }

    /**
     * @internal
     * 이미지 컬럼 만들기
     * 데이터를 이미지 url로 해석한다.
     */
    private makeImageColumn(defaultRealGridColumn: any, column: IColumn): any {
        defaultRealGridColumn = {
            ...defaultRealGridColumn,
            styles: {
                ...defaultRealGridColumn.styles,
                contentFit: column.fittingType ? column.fittingType : "auto"
            },
            renderer: {
                type: "image",
                sommthing: true,
                showTooltip: column.useTooltip !== undefined ? column.useTooltip : false
            }
        };

        return defaultRealGridColumn;
    }

    /**
     * @internal
     * 바 렌더러
     */
    private makeProgressColumn(defaultRealGridColumn: any, column: IColumn): any {
        defaultRealGridColumn = {
            ...defaultRealGridColumn,
            type: "data",
            styles: {
                ...defaultRealGridColumn.styles,
                figureBackground: '#1c90fb'
            },
            renderer: {
                type: "bar",
                minimum: column.minimumValue ? column.minimumValue : 0,
                maximum: column.maximumValue ? column.maximumValue : 100,
                minWidth: 150,
                showLabel: true
            },
        }

        return defaultRealGridColumn;
    }

    private makeLinkColumn(defaultRealGridColumn: any, column: IColumn): any {
        defaultRealGridColumn = {
            ...defaultRealGridColumn,
            editor: {
                type: "text",
            },
            renderer: {
                ...defaultRealGridColumn.renderer,
                type: "link",
                url: "http://www.douzone.com",
                showUrl: true,
                cursor: 'pointer'
            },
            styles: {
                ...defaultRealGridColumn.style,
                foreground: '#ff1c90fb'
            } as IRealGridCellStyleOption,
        };
        return defaultRealGridColumn;
    }

    private makeButtonColumn(defaultRealGridColumn: any, column: IColumn): any {
        defaultRealGridColumn = {
            ...defaultRealGridColumn,
            buttonVisibility: buttonVisiblityFunction(this, column),
            styles: {
                ...column.style,
                iconAlignment: "center"
            } as IRealGridCellStyleOption,
        };

        if (column.imageButtonRenderers) {
            column.imageButtonRenderers.forEach((set) => {
                this.gridView.addCellRenderers([{
                    id: set.id,
                    type: "imageButtons",
                    alignment: 'center',
                    images: set.buttonList.map((button) => {
                        return OBTDataGridInterface.generateImageButton(button.id, button.width, button.labelText);
                    }),
                }]);
            });
        }

        return defaultRealGridColumn;
    }

    /**
     * @internal
     */
    private makeGroupColumn(defaultRealGridColumn: any, column: IColumn): any {
        defaultRealGridColumn = {
            ...defaultRealGridColumn,
            columns: column.columns ? column.columns
                .filter(col => col.type !== ColumnType.data)
                .map(col => this.mapIColumnToRealGridColumn(col)) : []
        }

        return defaultRealGridColumn;
    }

    /**
     * @internal
     */
    private makeTextColumn(defaultRealGridColumn: any, column: IColumn): any {
        defaultRealGridColumn = {
            ...defaultRealGridColumn,
            editor: {
                type: "text",
                maxLength: column.maxLength ? column.maxLength : 100,
                textCase: column.textCase ? column.textCase : 'normal'
            }
        };
        return defaultRealGridColumn;
    }

    /**
     * @internal
     */
    private makeMultiLineColumn(defaultRealGridColumn: any, column: IColumn): any {
        defaultRealGridColumn = {
            ...defaultRealGridColumn,
            editor: {
                type: "multiline",
                maxLength: column.maxLength ? column.maxLength : 1000,
            },
            styles: {
                textAlignment: "center",
                textWrap: "explicit", // "normal" or "explicit"
                ...column.style ? column.style : {},
                ...defaultRealGridColumn.style ? defaultRealGridColumn.style : {},
            },
        };

        return defaultRealGridColumn;
    }

    /**
     * @internal
     */
    private makeCodePickerColumn(defaultRealGridColumn: any, column: IColumn): any {
        const result = this.setCodePickerButton(defaultRealGridColumn, column);

        return {
            ...result,
            editor: {
                type: "text",
                maxLength: column.maxLength ? column.maxLength : 100,
            },
        }
    }

    private setCodePickerButton(defaultRealGridColumn: any, column: IColumn) {
        let result = {
            ...defaultRealGridColumn,
            buttonVisibility: buttonVisiblityFunction(this, column),
        }

        if (defaultRealGridColumn && defaultRealGridColumn.renderer && Array.isArray(defaultRealGridColumn.renderer.images)) {
            // 이미 아이콘이 존재하는 경우, 기존 배열에 코드피커 아이콘을 추가한다.
            result.renderer.images.push({
                name: 'codePickerIcon',
                down: codePickerIconOver,
                hover: codePickerIconOver,
                up: codePickerIcon,
                width: 20,
                cursor: 'pointer'
            } as ImageButton);
        } else {
            result = {
                ...result,
                renderer: {
                    type: "imageButtons",
                    alignment: 'far',
                    showTooltip: column.useTooltip !== undefined ? column.useTooltip : false,
                    images: [{
                        name: 'codePickerIcon',
                        down: codePickerIconOver,
                        hover: codePickerIconOver,
                        up: codePickerIcon,
                        width: 20,
                        cursor: 'pointer'
                    } as ImageButton]
                }
            }
        }

        return result;
    }

    /**
     * @internal
     */
    private makeCheckColumn(defaultRealGridColumn: any, column: IColumn): any {
        return {
            ...defaultRealGridColumn,
            editable: true,
            renderer: {
                ...defaultRealGridColumn.renderer,
                type: "check",
                editable: true,
                // readonly: false,
                startEditOnClick: true,
                trueValues: column.trueValues ? column.trueValues.join(',') : "True",
                falseValues: column.falseValues ? column.falseValues.join(',') : "False",
                labelPosition: "hidden",
                showTooltip: column.useTooltip !== undefined ? column.useTooltip : false
            },
        };
    }

    private makeNumberFormatString(column: IColumn) {
        let resultFormat = '#,000';

        let resultMaxIntegerLength = 0;
        let resultExcelFormat = column.numberColumnFormat ? column.numberColumnFormat : '#,###';
        if (column.numberColumnFormat) {
            resultMaxIntegerLength = 0;
            resultFormat = column.numberColumnFormat;

            const formatArray = ['sFormat02', 'sFormat03', 'sFormat04', 'sFormat05', 'sFormat06', 'sFormat07', 'sFormat08'];
            if (this.erpNumberFormatType
                && (typeof column.numberColumnFormat === 'string' && formatArray.includes(column.numberColumnFormat))) {
                const formatString = makeNumberFormatString(ERPNumberFormatType[column.numberColumnFormat], this.erpNumberFormatType, column.lastCutType);

                if (formatString) {
                    resultFormat = formatString;
                }

                const excelFormatString = makeNumberExcelFormatString(ERPNumberFormatType[column.numberColumnFormat], this.erpNumberFormatType, column.lastCutType);

                if (excelFormatString) {
                    resultExcelFormat = excelFormatString;
                }
            }

            const resolveResult = this.resolveNumberFormat(column.maxLength ? column.maxLength : null);
            resultMaxIntegerLength = resolveResult.integerLength;
        } else if (column.maxLength) {
            const resolveResult = this.resolveNumberFormat(column.maxLength ? column.maxLength : null);
            resultMaxIntegerLength = resolveResult.integerLength;
            resultFormat = column.numberColumnFormat ? column.numberColumnFormat : resolveResult.format;
        }

        return {
            format: resultFormat,
            maxIntegerLength: resultMaxIntegerLength,
            excelFormat: resultExcelFormat
        }
    }

    /**
     * @internal
     * 숫자컬럼 리얼그리드 컬럼으로 매핑
     */
    private makeNumberColumn(defaultRealGridColumn: any, column: IColumn): any {
        const format = this.makeNumberFormatString(column);

        defaultRealGridColumn = {
            ...defaultRealGridColumn,
            displayMinusZero: false,
            dataType: 'number',
            excelFormat: format.excelFormat, //resultFormat,//'#,###', //resultFormat,
            // excelFormulaStatement: resultExcelFormat,
            editor: {
                ...defaultRealGridColumn.editor,
                type: "number",
                multipleChar: "+",
                positiveOnly: column.positiveOnly === true ? true : false,
                maxIntegerLength: format.maxIntegerLength,
                maxLengthExceptComma: true,
                editFormat: format.format
            },
            styles: {
                ...defaultRealGridColumn.styles,
                numberFormat: format.format
            },
        };

        if (column.nanToZero === true) {
            defaultRealGridColumn = {
                ...defaultRealGridColumn,
                nanText: "0"
            };
        }

        if (column.showZero === true) {
            defaultRealGridColumn = {
                ...defaultRealGridColumn,
                zeroText: "0"
            };
        } else {
            defaultRealGridColumn = {
                ...defaultRealGridColumn,
                zeroText: ""
            };
        }

        return defaultRealGridColumn;
    }

    /**
     * @internal
     */
    private resolveNumberFormat(decimalLength: number | null): { integerLength: number, format: string } {
        if (!decimalLength) {
            return {
                integerLength: 0,
                format: '#,##0,###'
            }
        }

        const decimalLengthToString = decimalLength.toString();

        // format 일때 뒤의 포메팅스트링을 붙여서 리턴해야함
        const defaultFormat = "#,##0."

        if (decimalLengthToString.indexOf('.') > -1) {
            const split: string[] = decimalLength.toString().split('.');

            if (split.length === 2) {
                // 16.4 형태의 maxLength처리
                const totalLength = split[0];
                const underZeroLength = split[1];

                return {
                    integerLength: (Number(totalLength) - Number(underZeroLength)),
                    format: defaultFormat + "#".repeat(Number(underZeroLength))
                }
            } else {
                // 16 형태의 maxLength처리
                return {
                    integerLength: decimalLength,
                    format: defaultFormat
                }
            }
        } else {
            return {
                integerLength: decimalLength,
                format: '#,##0'
            }
        }
    }

    /**
     * @internal
     */
    private makeDateTimeColumn(defaultRealGridColumn: any, column: IColumn): any {
        const dateFormat = column.dateFormat || DateFormat.yyyyMMdd;

        defaultRealGridColumn = {
            ...defaultRealGridColumn,
            editButtonVisibility: buttonVisiblityFunction(this, column),
            editor: {
                type: "date",
                editFormat: this.resolveDateFormatByColumnProperty(dateFormat, 'datetimeFormat'),
                mask: {
                    editMask: this.resolveDateFormatByColumnProperty(dateFormat, 'editMask'),
                    includedFormat: false,
                },
                commitOnSelect: true
            },
            styles: {
                datetimeFormat: this.resolveDateFormatByColumnProperty(dateFormat, 'displayStyle'),
            },
        }

        return defaultRealGridColumn;
    }

    /** @internal */
    private makeDropdownColumn(defaultRealGridColumn: any, column: IColumn): any {
        let values: any[] = [];
        let labels: any[] = [];

        if (column.dropDownDataItems && column.dropDownCodeProperty && column.dropDownTextProperty) {
            values = values.concat(column.dropDownDataItems.map((item) => item[column.dropDownCodeProperty ? column.dropDownCodeProperty : 'code']));
            labels = labels.concat(column.dropDownDataItems.map((item) => item[column.dropDownTextProperty ? column.dropDownTextProperty : 'text']));
        }

        defaultRealGridColumn = {
            ...defaultRealGridColumn,
            lookupDisplay: column.dynamicDropDownItemFilter ? false : true,
            values: values,
            labels: labels,
            editButtonVisibility: buttonVisiblityFunction(this, column),
            editor: {
                ...defaultRealGridColumn.editor,
                type: "dropDown",
                dropDownCount: column.dropDownCount ? column.dropDownCount : 4,
                // 목록에 있는 값만 입력 받을 것인지 여부 
                domainOnly: column.allowEditorValue === true ? false : true,
                // 드롭다운 목록에서 label값으로 보여질 것인지 value값으로 보여질 것인지 또는 label value, value label로 보여질것인지의 여부를 지정
                displayLabels: column.dropDownLabelType ? column.dropDownLabelType : undefined,
                // displayLabels 속성이 “valueLabel”, “labelValue”인 경우 두 값들사이의 여백을 자동으로 조정하여 정렬 표시
                itemColumned: true,
                // 키보드 입력 막을 것인지 여부
                // textReadOnly: false,//column.textReadOnly !== undefined ? column.textReadOnly : false,
                textReadOnly: column.textReadOnly !== undefined ? column.textReadOnly : false,
                // true 인 경우 한글 초성만 입력해도 해당하는 라벨 위치로 바로 이동
                partialMatch: true,
                commitOnSelect: true,
                values: values,
                labels: labels,
                separator: '.'
            },
            displayCallback: (grid: any, index: any, value: any) => {
                if (column.dynamicDropDownItemFilter) {
                    return this.dynamicDropDownValueMapping(column, value);
                }
            },
        }

        return defaultRealGridColumn;
    }

    /** @internal */
    private dynamicDropDownValueMapping(column: IColumn, value?: string | null) {
        if (!value) {
            return "";
        }

        const targetCodeProperty = column.dropDownCodeProperty;
        if (!targetCodeProperty) {
            throw new Error('dynamicDropDownValueMapping: targetCodeProperty를 찾을 수 없습니다 (' + targetCodeProperty + ')');
        }

        const targetTextProperty = column.dropDownTextProperty;
        if (!targetTextProperty) {
            throw new Error('dynamicDropDownValueMapping: targetTextProperty를 찾을 수 없습니다 (' + targetTextProperty + ')');
        }

        const originalDropDownList = column.originalDropDownList;
        if (!originalDropDownList) {
            throw new Error('dynamicDropDownValueMapping: originalDropDown를 찾을 수 없습니다 (' + originalDropDownList + ')');
        }

        const result = originalDropDownList.find((item) => {
            return item[targetCodeProperty] && String(item[targetCodeProperty]) === value
        });

        return result ? result[targetTextProperty] : "";
    }

    private makeDateTextColumn(defaultRealGridColumn: any, column: IColumn): any {
        let editMask = "0000-00-00";
        if (column.dateFormat === DateFormat.yyyy) {
            editMask = "0000";
        }
        if (column.dateFormat === DateFormat.yyyyMM) {
            editMask = "0000-00";
        }

        defaultRealGridColumn = {
            ...defaultRealGridColumn,
            editor: {
                type: "text",
                mask: {
                    editMask: editMask,
                    allowEmpty: true,
                },
            },
            renderer: {
                ...defaultRealGridColumn.renderer,
                type: "imageButtons",
                alignment: 'far',
                images: [
                    {
                        name: 'calendarIcon',
                        down: calendarIconOver,
                        hover: calendarIconOver,
                        up: calendarIcon,
                        width: 20,
                        cursor: 'pointer'
                    } as ImageButton
                ]
            },
            buttonVisibility: buttonVisiblityFunction(this, column),
            displayCallback: (grid: any, index: any, value: any) => {
                let result = this.formatDateString(value, column.dateFormat ? column.dateFormat : DateFormat.yyyyMMdd);
                if (result === 'Invalid date') {
                    return '';
                }

                if (result && column.showDayOfWeek === true) {
                    if (column.dateFormat === DateFormat.yyyyMMdd && value.length === 8) {
                        var week = ['일', '월', '화', '수', '목', '금', '토']
                        let toDate = new Date(
                            Number(value.slice(0, 4)),
                            Number(value.slice(4, 6)) - 1,
                            Number(value.slice(6, 8))
                        );
                        result = result + ' (' + week[toDate.getDay()] + '요일)';
                    }
                } else if (result && typeof column.showDayOfWeek === 'function') {
                    let toDate = new Date(
                        Number(value.slice(0, 4)),
                        Number(value.slice(4, 6)) - 1,
                        Number(value.slice(6, 8))
                    );

                    result = result + ' ' + column.showDayOfWeek(toDate.getDay());

                }

                return result;
            }
        }

        return defaultRealGridColumn;
    }

    /** @internal */
    private makeTimeColumn(defaultRealGridColumn: any, column: IColumn): any {
        let dropDownTimeValues: string[] = [
        ];

        for (let index = 0; index < 24; index += 1) {
            dropDownTimeValues.push(Util.pad((index * 100).toString(), 4));
        }

        if (column.timePeriodType === 'half') {
            for (let index = 0; index < 24; index += 1) {
                dropDownTimeValues.push(Util.pad(((index * 100) + 30).toString(), 4));
            }
            dropDownTimeValues = dropDownTimeValues.sort((a, b) => {
                const numberA = Number(a);
                const numberB = Number(b);

                if (numberA > numberB) {
                    return 1;
                } else if (numberA < numberB) {
                    return -1;
                } else {
                    return 0;
                }
            })
        }

        const dropDownTimeLabels = dropDownTimeValues.map((item) => {
            return this.toDisplayTimeValue(item);
        })

        defaultRealGridColumn = {
            ...defaultRealGridColumn,
            // "lookupDisplay": true,
            editor: {
                type: "dropDown",
                maxLength: 4,
                textReadOnly: column.textReadOnly !== undefined ? column.textReadOnly : false,
                dropDownCount: column.dropDownCount ? column.dropDownCount : 8,
                // true 인 경우 한글 초성만 입력해도 해당하는 라벨 위치로 바로 이동
                // partialMatch: false,
                commitOnSelect: true,
                domainOnly: column.allowEditorValue === true ? false : true,
                values: dropDownTimeValues,
                labels: dropDownTimeLabels,
                displayLabels: 'label',
                // mask: {
                //     editMask: '00:00',//'09:99',
                //     // allowEmpty: true,
                //     // restrictNull: false,
                //     // overWrite: true,
                // }
            },
            displayCallback: (grid: any, index: any, value: any) => {
                const mappedValue = column.mapTimeValueCallback ? column.mapTimeValueCallback(value) : value as string;
                if (!mappedValue || isNaN(Number(mappedValue))) {
                    return '';
                }
                return this.toDisplayTimeValue(mappedValue);
            }
        }
        return defaultRealGridColumn;
    }

    /** @internal */
    private toDisplayTimeValue(value?: string | null) {
        if (!value) {
            return '';
        }

        let hour = 0;
        let minutes = 0;
        if (value.length === 4) {
            hour = Number(value.substr(0, 2));
            minutes = Number(value.substr(2, 2));
        } else if (value.length === 2) {
            hour = Number(value);
        } else {
            return ''
        }

        // check validate
        if (hour > 24 || hour < 0) {
            return '';
        }
        if (minutes > 60 || minutes < 0) {
            return '';
        }

        let markAMPM: '오전' | '오후' = '오전';
        if (hour >= 12) {
            markAMPM = "오후";
        }

        // convert string
        if (hour > 12) {
            hour = hour - 12;
        }

        if (hour === 0) {
            hour = 12;
        }

        return markAMPM + ' ' + Util.pad(hour.toString(), 2) + ':' + Util.pad(minutes.toString(), 2);
    }

    public getMaskedValue(rowIndex: number, column: IColumn, value: string, withMasking?: boolean): string | null {
        // 개인정보 암호화
        // 1. 개인정보가 조회되지 않았다면 마스킹처리함.
        // 2. 마스킹 옵션이 always 라면 마스킹 해제함.
        // 3. 현재 선택된 항목이라면 마스킹 해제함
        // 4. 컬럼의 useMasking 옵션 사용
        if (withMasking === undefined) {
            const selection = this.getSelection();
            const useMasking = column.usePrivacy ? true : column.useMasking === true ? true : false;
            withMasking = (column.usePrivacy && this.isPrivacyMasked(rowIndex, column.name, column)) ? true :
                this.getPrivacyBehavior(column.name, column) === PrivacyBehaviorEnum.always ? false :
                    selection.columnName === column.name && selection.rowIndex === rowIndex ? false :
                        useMasking;
        }
        const currentMaskType = typeof column.maskType === 'function' ? column.maskType(rowIndex, this) : column.maskType;
        if (currentMaskType === MaskType.custom && column.customMaskCallback) {
            return column.customMaskCallback(value, rowIndex);
        } else {
            const callback = this.resolveMask(currentMaskType, 'callback');

            if (callback) {
                return callback && value ? callback.formatter(value, { withMasking: withMasking }) : null;
            }
        }
        return value;
    }

    /**
     * rowIndex, targetColumnName에 해당하는 셀의 다음 활성화된 셀 정보를 리턴한다.
     * @param rowIndex 
     * @param targetColumnName 
     */
    public getNextActiveCell(rowIndex: number, targetColumnName: string): ICell | null {
        const column = this.getColumnByName(targetColumnName);

        if (!column) {
            return null;
        }

        return this.getNextActiveCellUsingIColumn(rowIndex, column);
    }

    public getNextActiveCellUsingIColumn(rowIndex: number, column: IColumn): ICell | null {
        if (!this.columns) {
            return null;
        }

        const findFirstActiveColumn = (rowIndexUsingFunction: number, columns: IColumn[]): IColumn | null => {
            let resultColumn: IColumn | null = null;
            columns.find((innerColumn) => {
                if (innerColumn.type === ColumnType.group) {
                    const result = findFirstActiveColumn(rowIndexUsingFunction, innerColumn.columns || []);
                    if (result) {
                        resultColumn = result;
                        return true;
                    }
                }

                if (this.isColumnEditable(innerColumn, rowIndexUsingFunction)) {
                    resultColumn = innerColumn;
                    return true;
                }

                return false;
            })

            return resultColumn;
        }

        const cellIndex = this.getFlatColumns(true, false).findIndex(innerColumn => innerColumn.name === column.name);
        if (cellIndex < 0) {
            return null;
        }

        let targetColumns = this.getFlatColumns(true, false).slice(cellIndex + 1);

        for (let index = rowIndex; index < this.getRowCount(); index++) {
            if (index !== rowIndex) {
                targetColumns = this.getFlatColumns(true, false);
            }

            const element = findFirstActiveColumn(index, targetColumns);
            if (element) {
                return {
                    columnName: element.name,
                    rowIndex: index
                }
            }
        }

        return null;
    }

    /** @internal */
    private makeMaskColumn(defaultRealGridColumn: any, column: IColumn): any {
        // const defaultMaskType = typeof column.maskType === 'function' ? undefined : column.maskType;
        // const editMask = column.editMask ? column.editMask : (column.maskType === MaskType.custom) ?
        //     column.editMask : this.resolveMask(defaultMaskType, "editMask")
        // TODO
        const maskPropertyValue = column.editMask ? {
            editMask: column.editMask,
            allowEmpty: true,
        } : null

        defaultRealGridColumn = {
            ...defaultRealGridColumn,
            editor: {
                type: "text",
                // mask: {
                // editMask: editMask,
                // allowEmpty: true,
                // }
                mask: maskPropertyValue,
                maxLength: column.maxLength ? column.maxLength : 100,
            },
            displayCallback: (grid: any, index: any, value: any) => {
                return this.getMaskedValue(index.itemIndex, column, value);
            },
            dynamicStyles: GridUtil.mergeDynamicStyles(defaultRealGridColumn.dynamicStyles, (grid, index, value) => {
                if (column.allowInvalidValue === true) {
                    const maskType = typeof column.maskType === 'function' ? column.maskType(index.itemIndex, this) : column.maskType;

                    if (maskType) {
                        const maskInstance = this.getMaskInstance(maskType);
                        if (maskInstance) {
                            if (maskInstance.validator(value) === false) {
                                return {
                                    ...column.style,
                                    foreground: '#ffff0000'
                                } as IRealGridCellStyleOption
                            }
                        }
                    }
                }

                return {
                    ...column.style,
                } as IRealGridCellStyleOption
            })
        }

        if (column.usePrivacy) {
            defaultRealGridColumn.buttonVisibility = (grid, index, focused, mouseEntered) => {
                if (this.getPrivacyBehavior(column.name, column) === PrivacyBehaviorEnum.viewByButton &&
                    this.isPrivacyMasked(index.itemIndex, column.name, column)) {
                    return true;
                }
                return false;
            };
            defaultRealGridColumn.renderer = {
                type: "imageButtons",
                alignment: 'far',
                showTooltip: column.useTooltip !== undefined ? column.useTooltip : false,
                images: [{
                    name: 'retrievePrivacy',
                    down: searchPrivacyIcon,
                    hover: searchPrivacyIconOver,
                    up: searchPrivacyIcon,
                    width: 20,
                    cursor: 'pointer'
                } as ImageButton]
            };
            // 헤더 버튼
            if (this.getPrivacyBehavior(column.name, column) !== PrivacyBehaviorEnum.always) {
                defaultRealGridColumn.header = {
                    ...defaultRealGridColumn.header,
                    imageLocation: 'right',
                    imageUrl: searchPrivacyIcon,
                    itemGap: 5
                };
            }
        }
        return defaultRealGridColumn;
    }

    /** @internal */
    private getMaskResolveInfo(maskType: MaskType | undefined): any {
        if (!maskType) {
            return null;
        }

        const resolve = {
            'resident': {
                editMask: '000000-0000000',
                displayRegExp: '([0-9]{6})([0-9]{7})',
                displayReplace: "$1-$2",
                callback: new resident()
            },
            'biznumber': {
                editMask: '000-00-00000',
                displayRegExp: '([0-9]{3})([0-9]{2})([0-9]{5})',
                displayReplace: "$1-$2-$3",
                callback: new biznumber()
            },
            'conumber': {
                editMask: '000000-0000000',
                displayRegExp: '([0-9]{6})([0-9]{7})',
                displayReplace: "$1-$2",
                callback: new conumber()
            },
            'foreign': {
                editMask: '000000-0000000',
                displayRegExp: '([0-9]{6})([0-9]{7})',
                displayReplace: "$1-$2",
                callback: new foreign()
            },
            'passport': {
                callback: new passport()
            },
            'driver': {
                callback: new driver()
            },
            'credit': {
                editMask: '0000-0000-0000-0000',
                displayRegExp: '([0-9]{4})([0-9]{4})([0-9]{4})([0-9]{4})',
                displayReplace: "$1-$2-$3-$4",
                callback: new credit()
            },
            'account': {
                callback: new account()
            },
            'tel': {
                editMask: '000-0000-0000',
                callback: new tel()
            },
            'email': {
                callback: new email()
            },
        }

        return resolve[maskType];
    }

    /** @internal */
    private resolveMask(maskType: MaskType | undefined, as: "editMask" | 'displayRegExp' | 'displayReplace' | "callback"): any {
        if (!maskType) {
            return "";
        }

        const resolve = this.getMaskResolveInfo(maskType);

        if (!resolve[as]) {
            return "";
        }

        return resolve[as];
    }

    /** @internal */
    private formatDateString(value: string, dateFormat: DateFormat | undefined): string | undefined {
        if (!value || value.length === 0) {
            return "";
        }

        let result: string = value;
        if (dateFormat === DateFormat.yyyyMMdd && value.length === 8) {
            result = Util.getDateString(Util.DateOption.simpleDate, value)
        } else if (dateFormat === DateFormat.yyyyMM && value.length === 6) {
            result = [value.slice(0, 4), "-", value.slice(4, 6)].join('');
        } else if (dateFormat === DateFormat.yyyy && value.length === 4) {
            result = value.slice(0, 4);
        }

        return result;
    }

    private getMaskInstance(maskType: MaskType): IMaskPattern | null {
        const resolve = this.getMaskResolveInfo(maskType);
        if (!resolve) {
            return null;
        }
        return resolve['callback'] ? resolve['callback'] : null;
    }

    /** @internal */
    private mapAlignmentToRealGridAlignment(alignment: ColumnAlignment): string {
        switch (alignment) {
            case ColumnAlignment.left: return 'near';
            case ColumnAlignment.right: return 'far';
            default: return alignment.toString();
        }
    }

    /** @internal */
    private resolveDateFormatByColumnProperty(dateFormat: DateFormat | undefined, property: "datetimeFormat" | "editMask" | "displayStyle" | "textMask") {
        const data = {
            'yyyy': {
                datetimeFormat: 'yyyy',
                editMask: '9999',
                displayStyle: 'yyyy',
            },
            'yyyyMM': {
                datetimeFormat: 'yyyyMM',
                editMask: '9999-99',
                displayStyle: 'yyyy-MM',
            },
            'yyyyMMdd': {
                datetimeFormat: 'yyyyMMdd',
                editMask: '9999-99-99',
                displayStyle: 'yyyy-MM-dd',
            },
            'iso': {
                datetimeFormat: 'yyyy.MM.dd HH:mm;2000;AM,PM',
                editMask: '9999-99-99',
                displayStyle: 'yyyy년 M월 d일 a h시m분',
            }
        }

        return (data[dateFormat || 'yyyyMMdd'] || {
            datetimeFormat: 'yyyy.MM.dd HH:mm;2000;AM,PM',
            editMask: '9999-99-99',
            displayStyle: dateFormat,
        })[property];
    }

    /**
     * @internal
     * 데이터를 리턴하는 Promise로 그리드에 드랍다운 컬럼을 세팅한다.
     */
    private setDropDownColumn(
        column: IColumn,
        dropDownCodeProperty: string | undefined,
        dropDownTextProperty: string | undefined,
        dropDownDataSourceCallBack: Promise<any> | (() => Promise<any>)): void {
        if (!dropDownCodeProperty || !dropDownTextProperty) {
            throw new Error('property setting missed');
        }

        if (typeof dropDownDataSourceCallBack === 'function') {
            dropDownDataSourceCallBack().then((response) => {
                column.dropDownDataItems = response.map((obj) => {
                    return {
                        [dropDownCodeProperty]: obj[dropDownCodeProperty],
                        [dropDownTextProperty]: obj[dropDownTextProperty]
                    }
                });

                this.setColumn(column);
            });
        } else {
            dropDownDataSourceCallBack.then((response) => {
                column.dropDownDataItems = response.map((obj) => {
                    return {
                        [dropDownCodeProperty]: obj[dropDownCodeProperty],
                        [dropDownTextProperty]: obj[dropDownTextProperty]
                    }
                });

                this.setColumn(column);
            });
        }
    }

    private setDynamicDropDownColumn(column: IColumn, newIndex: any) {
        if (!column.dynamicDropDownItemFilter || !column.originalDropDownList) {
            throw new Error("OBTDataGridInterface.setDynamicDropDownColumn: 동적 dropDown 설정 중 문제가 발생하였습니다.");
        }

        const values = this.getValues(newIndex.itemIndex);
        const changeDropDownItems = column.originalDropDownList.filter((item) =>
            column.dynamicDropDownItemFilter && column.dynamicDropDownItemFilter(values, item));

        this._setColumn({
            name: newIndex.column,
            dropDownDataItems: changeDropDownItems
        }, true);
    }

    /**
     * @internal
     * 세팅된 IColumn을 리얼그리드 gridView와 dataProvider에 세팅한다.
     */
    private _setColumns(changed: boolean): void {
        if (!this._gridView) {
            return;
        }

        if (!this._columns) {
            throw new Error("OBTDataGridInterface._setColumns: column can't be null");
        }

        if (changed && this._provider && this._provider.fields === undefined && this._realGridProvider) {
            this._realGridProvider.setFields(this.mapIColumnToProviderFields(this._columns));
        }

        // 커스텀 컬럼 설정 
        this.initializeColumnSetting();

        const realGridColumns = this._columns
            .filter(column => column.type !== ColumnType.data)
            .map(column => this.mapIColumnToRealGridColumn(column))
            .filter(column => column);

        this._gridView.setColumns(realGridColumns);

        // 푸터 세팅 푸터설정된 컬럼이 없으면 기본적으로 보이지 않게
        const hasFooterColumn = this.getFlatColumns(true).filter(column => (column.footer || column.footerValue));
        this.setFooter(
            hasFooterColumn.length > 0,
            (this.gridOption.footerCount ? this.gridOption.footerCount : 1),
        );

        if (this._columns) {
            const flatColumns = this.getFlatColumns(true);

            if (flatColumns.length > 0) {
                this._firstColumnName = flatColumns[0].name;
            }

            flatColumns.forEach((column) => {
                if (column.dropDownDataSourceCallBack !== undefined) {
                    this.setDropDownColumn(
                        column,
                        column.dropDownCodeProperty,
                        column.dropDownTextProperty,
                        column.dropDownDataSourceCallBack)
                }

                if (this.hasCodePicker(column)) {//(column.type === ColumnType.codePicker) {
                    column.internalCodePickerState = "unbinded";
                }

                this.setColumnDataComparer(column.name);
            })

            this.updateColumnMap();
        }
    }

    /**
     *  정렬 재정의
     * @param columnName 컬럼명
     */
    private setColumnDataComparer(columnName: string) {
        if (!this.realGridProvider || !this._gridView) {
            throw new Error('OBTDataGridInterface.setColumnDataComparer: dataProvider or gridView null 혹은 undefined 입니다.')
        }

        this.realGridProvider.setDataComparer(columnName, (field: number, row1: number, row2: number) => {
            try {
                const sortSettings = this._gridView.getSortedFields();
                if (sortSettings.length === 0) {
                    return 0;
                }

                const direction = sortSettings[0].direction

                const rowIndex1 = this._gridView.getItemIndex(row1);
                const rowIndex2 = this._gridView.getItemIndex(row2);

                const rowIndex1State = this.getState(rowIndex1);
                const rowIndex2State = this.getState(rowIndex2);

                const cellData1 = this.realGridProvider.getValue(row1, field);
                const cellData2 = this.realGridProvider.getValue(row2, field);

                if (rowIndex1State === GridState.added || rowIndex1State === GridState.empty) {
                    return direction === "descending" ? -1 : 1
                }

                if (rowIndex2State === GridState.added || rowIndex2State === GridState.empty) {
                    return direction === "descending" ? 1 : -1
                }

                if (!cellData1 || cellData1 === '') {
                    return -1;
                }

                if (!cellData2 || cellData2 === '') {
                    return 1;
                }

                return cellData1 > cellData2 ? 1 : (cellData1 === cellData2 ? 0 : -1);
            } catch (e) {
                console.error(e);
                return 1
            }
        });
    }

    /**
     * 컬럼명 검색 최적화를 위한 map 생성 
     * [{ name: 'A' }, { name: 'B' }] => { A: {name: 'A'}, B: { name: 'B } }
     */
    private updateColumnMap() {
        const columnFlat: IColumn[] = [];
        const columnFlatSubName: IColumn[] = [];

        const pushItem = (columns: IColumn[] | null | undefined) => {
            if (!columns) {
                return;
            }

            columns.forEach(item => {
                columnFlat.push(item);

                if (item.subName) {
                    columnFlatSubName.push(item);
                }

                if (item.type === ColumnType.group) {
                    pushItem(item.columns);
                }
            });
        }

        pushItem(this.columns);

        this._columnMap = (columnFlat.filter(item => item.name).reduce((accu, curr) => {
            accu[curr.name.toUpperCase()] = curr;
            return accu;
        }, {}));

        this._hasSubNameColumnMap = (columnFlatSubName.filter(item => item.name).reduce((accu, curr) => {
            accu[curr.subName!.toUpperCase()] = curr;
            return accu;
        }, {}));
    }

    /**
     * @internal
     * 검색효율성을 위한 컬럼맵을 가져온다.
     */
    public getColumnMap() {
        return {
            columnMap: this._columnMap,
            hasSubNameColumnMap: this._hasSubNameColumnMap
        }
    }

    /** @internal */
    public invokeEvent<T>(event: ChaningEvent<(e: T) => void>, eventArgs: T, containData?: boolean, changedData?: boolean) {
        let invokeResult = {
            isCancel: false,
            returnData: null,
        };

        for (let i = 0; i < event.events.length; i++) {
            try {
                const callbackReturnValue = event.events[i](eventArgs);
                if (eventArgs['cancel'] === true) {
                    invokeResult.isCancel = true;
                    break;
                } else {
                    if (containData === true && eventArgs['data']) {
                        invokeResult.returnData = eventArgs['data'];
                    }
                }
            } catch (error) {
                console.error(error);
                invokeResult.isCancel = true;
                break;
            }
        }

        return invokeResult; //false;
    }

    /** @internal */
    private async invokePromiseEvent<T, K>(event: ChaningEvent<(e: T) => Promise<K>>, eventArgs: T) {
        let invokeResult: {
            isCancel: boolean,
            returnData: K | undefined | null
        } = {
            isCancel: false,
            returnData: null,
        };

        for (let i = 0; i < event.events.length; i++) {
            try {
                const callbackReturnValue = await event.events[i](eventArgs);
                if (eventArgs['cancel'] === true) {
                    invokeResult.isCancel = true;
                    break;
                } else if (callbackReturnValue) {
                    invokeResult.returnData = callbackReturnValue;
                }
            } catch (error) {
                console.error(error);
                invokeResult.isCancel = true;
                break;
            }
        }

        return invokeResult; //false;
    }

    /**
     * @internal
     * 로우단위 유효성체크
     */
    private checkRowValidation(rowIndex: number): RowValidationAlert {
        const validationResult = {
            isValidated: true,
            rowIndex: rowIndex,
            invalidColumns: []
        } as RowValidationAlert;

        const dataItem = this.getRow(rowIndex);

        Object.keys(dataItem).forEach((propertyName) => {
            const column = this.getColumnByName(propertyName);
            if (column && this.getColumnRequired(column, rowIndex) === true) {
                const isEmpty = GridUtil.isEmpty(dataItem[propertyName]);
                if (isEmpty === true) {
                    validationResult.isValidated = false;
                    validationResult.invalidColumns.push({
                        columnName: propertyName,
                        reason: Events.GridValidationFailReason.EMPTY_REQUIRED
                    });
                }
            }
        })
        return validationResult;
    }

    /**
 * SearchedItemSelectEventArgs 이벤트 invoke 함수
 * @param eventArgs 
 */
    public invokeSelectEvent(eventArgs: Events.SearchedItemSelectEventArgs) {
        this.invokeEvent<Events.SearchedItemSelectEventArgs>(
            this.onSearchedItemSelect,
            eventArgs
        );
    }

    public invokeAlertEvent(eventArgs: Events.AlertEventArgs) {
        this.invokeEvent<Events.AlertEventArgs>(
            this.onAlert,
            eventArgs
        );
    }

    /**
     * 지정한 셀이 수정가능한 마지막셀인지 체크한다.
     * @param columnName 
     * @param rowIndex 
     */
    public isLastEditableColumn(columnName: string, rowIndex: number) {
        if (!this.columns) {
            return true;
        }

        const targetColumn = this.getColumnByName(columnName);
        if (!targetColumn) {
            throw new Error("OBTDataGridInterface.isLastEditableColumn: 컬럼을 찾을 수 없습니다. -  " + columnName);
        }

        const displayColumnList = this._getFlatColumns(this.columns, true).filter((column) => {
            return this.isColumnEditable(column, rowIndex);
        });

        if (displayColumnList.length > 0) {
            return columnName === displayColumnList[displayColumnList.length - 1].name
        } else {
            return true;
        }
    }

    /**
     * 지정한 셀이 보여지는 마지막셀인지 체크한다.
     * @param columnName 
     * @param rowIndex 
     */
    public isLastVisibleColumn(columnName: string, rowIndex: number) {
        if (!this.columns) {
            return true;
        }

        const targetColumn = this.getColumnByName(columnName);
        if (!targetColumn) {
            throw new Error("OBTDataGridInterface.isLastEditableColumn: 컬럼을 찾을 수 없습니다. -  " + columnName);
        }

        const displayColumnList = this._getFlatColumns(this.columns, true);

        if (displayColumnList.length > 0) {
            return columnName === displayColumnList[displayColumnList.length - 1].name
        } else {
            return true;
        }
    }

    /**
     * 지정한 셀이 보여지는 마지막셀인지 체크한다.
     * @param columnName 
     * @param rowIndex 
     */
    public isLastVisibleColumnUsingIColumn(targetColumn: IColumn, rowIndex: number) {
        if (!this.columns) {
            return true;
        }
        const displayColumnList = this._getFlatColumns(this.columns, true);

        if (displayColumnList.length > 0) {
            return targetColumn.name === displayColumnList[displayColumnList.length - 1].name
        } else {
            return true;
        }
    }

    /**
    * 특정 인덱스의 특정 컬럼이 수정 가능한지 여부를 리턴한다.
    * 컬럼세팅에 수정가능 여부가 지정되어있지 않으면 그리드 레벨의 수정가능 여부를 본다. 
     * @param targetColumn 
     * @param rowIndex 
     * @param columnName 
     */
    public isColumnEditable(targetColumn: IColumn, rowIndex: number): boolean {
        if (targetColumn.readonly === true) {
            return false;
        }

        // 권한체크
        if (this.checkPossibleGridAction('editable', false) === false) {
            return false;
        }

        let editable: boolean;

        if (targetColumn.editable !== undefined) {
            if (typeof targetColumn.editable === 'boolean') {
                editable = targetColumn.editable;
            } else if (typeof targetColumn.editable === 'function') {
                editable = targetColumn.editable({
                    values: this.getRow(rowIndex),
                    rowIndex: rowIndex,
                    columnName: targetColumn.name
                });
            } else {
                throw new Error('OBTDataGridInterface.isColumnEditable: ' + targetColumn.name + '.editable is not a function or boolean');
            }
        } else {
            editable = this.gridOption.editable !== undefined ? this.gridOption.editable : true;
        }

        return editable;
    }

    public isColumnVisible(columnName: string): boolean {
        const targetColumn = this.getColumnByName(columnName);
        if (!targetColumn) {
            throw new Error(columnName + ' 컬럼을 찾을 수 없습니다.');
        }

        if (targetColumn.type === ColumnType.data) {
            return false;
        }

        if (targetColumn.visible === false) {
            return false;
        }

        return true;
    }

    /**
     * rowIndex의 행이 행 그룹 아이템인지 여부
     * @param rowIndex 
     */
    public isGroupItem(rowIndex: number) {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.isGroupItem: 그리드가 준비되지 않았습니다.');
        }

        if (this.gridOption.gridType === GridType.treeView) {
            return false;
        }

        return this.gridView.isGroupItem(rowIndex);
    }

    public isEditing(): boolean {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.isEditing: 그리드가 준비되지 않았습니다.');
        }

        return this.gridView.isEditing();
    }

    /** @internal */
    private processSetValue(rowIndex: number, columnName: string, value: any, source: any, options?: {
        usePrivacyOriginField?: boolean
    }): boolean {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.setValue: 그리드가 준비되지 않았습니다.');
        }

        const values = this.getValues(rowIndex);
        const oldValue = this.getValues(rowIndex)[columnName];

        let isValidate =
            this.processBeforeChange(rowIndex, columnName, value) &&
            this._invokeValidateChange(rowIndex,
                columnName,
                oldValue,
                value,
                values,
                source);

        if (isValidate) {
            let setValueColumnName = columnName;
            if (options) {
                if (options.usePrivacyOriginField) {
                    const column = this.getColumnByName(columnName);
                    if (column && this.isUsePrivacy(columnName, column)) {
                        this._gridView.setValue(rowIndex, OBTDataGridInterface.getOriginFieldName(column), value);
                        value = this.getModifiedValue(value);
                    }
                }
            }
            this._gridView.setValue(rowIndex, setValueColumnName, value);

            const dataRow = this._gridView.getDataRow(rowIndex);
            if (this._isEmptyDataRow(dataRow)) {
                this.setEmptyDataRows(this._emptyDataRows.filter(item => item !== dataRow))
            }

            this._invokeAfterChange(rowIndex, columnName, oldValue, value, this.getValues(rowIndex), source);
        }

        return !isValidate;
    }


    private processSetValueAsync(rowIndex: number, columnName: string, value: any, source: any, options?: {
        usePrivacyOriginField?: boolean
    }): Promise<boolean> {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.setValue: 그리드가 준비되지 않았습니다.');
        }

        const values = this.getValues(rowIndex);
        const oldValue = this.getValues(rowIndex)[columnName];

        let isValidate =
            this.processBeforeChange(rowIndex, columnName, value) &&
            this._invokeValidateChange(rowIndex,
                columnName,
                oldValue,
                value,
                values,
                source);

        if (isValidate) {
            let setValueColumnName = columnName;
            if (options) {
                if (options.usePrivacyOriginField) {
                    const column = this.getColumnByName(columnName);
                    if (column && this.isUsePrivacy(columnName, column)) {
                        this._gridView.setValue(rowIndex, OBTDataGridInterface.getOriginFieldName(column), value);
                        value = this.getModifiedValue(value);
                    }
                }
            }

            this._gridView.setValue(rowIndex, setValueColumnName, value);

            return new Promise(resolve => {
                const dataRow = this._gridView.getDataRow(rowIndex);
                if (this._isEmptyDataRow(dataRow)) {
                    this.setEmptyDataRows(this._emptyDataRows.filter(item => item !== dataRow))
                }

                resolve(!isValidate)
            })
        }

        return Promise.resolve<boolean>(!isValidate)
    }

    /**
     * @internal
     * 이벤트 제어되는 setValues
     */
    private processSetValues(rowIndex: number, values: any, source: any): void {
        try {
            if (!this.isReady) {
                throw new Error('OBTDataGridInterface.setValues: 그리드가 준비되지 않았습니다.');
            }

            const oldValues = this.getValues(rowIndex);

            let hasCancelColumn = false;
            Object.keys(values).forEach((columnName) => {
                let isValidate = this.processBeforeChange(rowIndex, columnName, values[columnName]) &&
                    this._invokeValidateChange(rowIndex, columnName, oldValues[columnName], values[columnName], oldValues, source);

                if (isValidate === false) {
                    hasCancelColumn = true;
                }
            });

            if (hasCancelColumn === false) {
                this._gridView.setValues(rowIndex, values, true);

                const dataRow = this._gridView.getDataRow(rowIndex);
                if (this._isEmptyDataRow(dataRow)) {
                    this.setEmptyDataRows(this._emptyDataRows.filter(item => item !== dataRow))
                }

                Object.keys(values).forEach((columnName) => {
                    this._invokeAfterChange(rowIndex, columnName, oldValues[columnName], values[columnName], {
                        ...oldValues,
                        [rowIndex]: values[columnName]
                    }, source);
                });
            }
        } catch (e) {
            console.error(e, rowIndex, values, source)
            throw e;
        }
    }

    /**
     * @internal
     * after read
     */
    private _invokeOnAfterRead(data: any): void {
        this.invokeEvent<Events.AfterReadEventArgs>(this.onAfterRead,
            new Events.AfterReadEventArgs(this, data || []));
    }

    /**
     * @internal
     */
    private _setFocusStyle(use: boolean) {
        if (!this.isReady) {
            return;
        }

        if (use === true) {
            if (this._hasFocus) {
                this._gridView.setDisplayOptions({
                    focusColor: "#ff1c90fb",
                    focusBorderWidth: 1,
                    focusBackground: "#330094ff",
                    columnMovable: this.gridOption.columnMovable,
                    eachRowResizable: this.gridOption.eachRowResizable
                });
            } else {
                this._gridView.setDisplayOptions({
                    focusColor: "#00000000",
                    focusBorderWidth: 0,
                    focusBackground: "#001c90fb",
                    columnMovable: this.gridOption.columnMovable,
                    eachRowResizable: this.gridOption.eachRowResizable

                });
            }
        }
    }

    /**
     * 
     * @param use 
     */
    public setFocusStyle(use: boolean): OBTDataGridInterface {
        this._gridOption.useFocusStyle = use;
        this._setFocusStyle(use);
        return this;
    }

    /**
     * GlobalGridOption을 리턴한다.
     * 복사본을 리턴하기 때문에 리턴된 오브젝트의 값을 변경해도 그리드에는 영향이 없다.
     */
    public getGridOption() {
        const copy = {
            ...this._gridOption
        };
        return copy;
    }

    /**
     * 그리드옵션을 동적으로 조정한다.
     * 옵션중 할당되지 않은 값에는 디폴트값이 할당된다.
     * @param options 
     */
    public setGridOption(options: IGridGlobalOption): OBTDataGridInterface {
        this.normalizeGridGlobalOption(options);
        this._setGridOption(options);

        return this;
    }

    /** @internal */
    private _setGridOption(options: IGridGlobalOption) {
        // 디폴트 옵션 가져오기
        this._gridOption = options;

        const realGridOptions = this.getRealGridOptions(options);

        this._gridView.setOptions(realGridOptions);

        this.setEditable(this._gridOption.editable !== undefined ? this._gridOption.editable : false);

        this.setAppendable(this._gridOption.appendable !== undefined ? this._gridOption.appendable : false);

        this.setCheckBar({
            ...this.getDefaultCheckBarOption(),
            visible: this._gridOption.checkable !== undefined ? this._gridOption.checkable : false,
            showAll: this._gridOption.headerCheckVisible !== undefined ? this._gridOption.headerCheckVisible : false,
            //checkbox의 외곽라인을 여부를 지정한다.
            drawCheckBox: true,
        });

        this.gridView.setPasteOptions({
            numberSeparator: '.',
            numberChars: ',',
            applyMaxLength: true,
            applyEditMask: true,
            checkReadOnly: true,
            // startEdit: true,
            noEditEvent: true,
            // commitEdit: true,
        });

        this.gridView.setCopyOptions({
            singleMode: true,
        });

        // 왜 인지 setOptions에서 처리하면 안먹는다
        this._gridView.setEditOptions({
            innerDraggable: this._gridOption.rowMovable,
            exitGridWhenTab: 'grid'
        });

        this._gridView.setMobileOptions({
            // 롱탭이 발생하기까지의 누르고 있는 시간을 지정한다.
            longTapDuration: 500,
            // 더블탭이 발생하기까지의 탭간 시간을 지정한다.
            doubleTapInterval: 300,
            // 탭간 동일 탭으로 인지하는 영역범위
            tapThreshold: 4,
            // 툴팁기능
            showTooltip: true,
        });

        // TODO: 의미있나
        if (options.columnMovable === false) {
            this.gridView.setDisplayOptions({ columnMovable: options.columnMovable });
        }

        this._gridView.setSelectOptions({
            style: 'singleRow',
            mobileStyles: {
                background: "#220793f9",
                selectedBackground: "#ff696969",
                inactiveBackground: "#ffd3d3d3",
                border: "#00ffffff,0",
                foreground: "#ff000000",
                selectedForeground: "#ffffffff",
                inactiveForeground: "#ff808080",
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
                iconIndex: "0",
                iconLocation: "left",
                iconAlignment: "center",
                iconOffset: 0,
                iconPadding: 0,
                contentFit: "auto",
                selectionDisplay: "mask",
                hoveredMaskBackground: "#1f5292f7",
                hoveredMaskBorder: "#335292f7,1px",
                figureInactiveBackground: "#ffd3d3d3",
                figureBorder: "#00000000,1px",
                figureBackground: "#00000000"
            }
        });

        if (this.gridOption.gridType !== GridType.treeView) {
            this.setRowGroupOption({
                headerStatement: '${columnHeader} (${rowCount}건)',
                mergeExpander: true,
                mergeMode: true,
                cellDisplay: 'normal',
                expandedAdornments: 'footer',
                collapsedAdornments: 'footer',
                levels: [
                    {
                        footerStyles: {
                            background: "#fffff1d6",
                        }
                    },
                    {
                        footerStyles: {
                            background: "#fffff7e0",
                        }
                    },
                    {
                        footerStyles: {
                            background: "#fffffceb",
                        }
                    },
                ]
            });
        }

        if (this.gridOption.gridType === GridType.treeView) {
            this._gridView.setTreeOptions({
                // lineVisible: true,
                // expanderWidth: 45,
                // iconHeight: 40,
                expandImage: btnTreeGridPlusImg,
                collapseImage: btnTreeGridMinusImg,
                lineStyle: "#e6e6e6,1"
                // lineStyle: {
                //     line: "#aarrggbb,1"
                // } as IRealGridCellStyleOption
            });
        }

        this._gridView.setFooter({
            minHeight: 33 * (this.gridOption.footerCount || 1)
        });
    }

    /**
     * @internal
     * IGridGlobalOption 디폴트값 적용하기
     * TODO: 디플트 오브젝트를 만들어놓고 합셩하는 식으로 
     */
    private normalizeGridGlobalOption(options: IGridGlobalOption) {
        if (options.editable === undefined) {
            options.editable = false;
        }
        if (options.appendable === undefined) {
            options.appendable = false;
        }
        if (options.checkable === undefined) {
            options.checkable = false;
        }
        if (options.indicator === undefined) {
            options.indicator = false;
        }
        if (options.rowSelectMode === undefined) {
            options.rowSelectMode = false;
        }
        if (options.preventSort === undefined) {
            options.preventSort = false;
        }
        if (options.headerCheckVisible === undefined) {
            options.headerCheckVisible = options.checkable;
        }

        if (options.rowCountPerPage !== undefined) {
            options.paging = true;
        }

        if (options.paging === undefined) {
            options.paging = false;
        }

        if (options.hideEmptyImage === undefined) {
            options.hideEmptyImage = false
        }

        if (options.columnMovable === undefined) {
            options.columnMovable = true;
        }

        // 입력이나 수정모드에서는 컬럼을 이동할 수 없다.
        if (options.columnMovable === true && (options.editable === true || options.appendable === true)) {
            options.columnMovable = false;
        }

        if (options.paging === true) {
            options.headerCheckVisible = false;
            if (options.rowCountPerPage === undefined) {
                throw new Error('pageSize is not initialized');
            }

            if (options.rowCountPerPage < 30) {
                throw new Error('minimum rowCountPerPage is 30');
            }
        }

        if (options.initialRowCount === undefined) {
            options.initialRowCount = options.rowCountPerPage;
        }

        // 초기 데이터 없는 경우 안내 문구 설정
        if (options.emptyDataMsg === undefined) {
            options.emptyDataMsg = OrbitInternalLangPack.getText('WE000009420', '데이터가 존재하지 않습니다.')
        }

        if (options.emptySearchMsg === undefined) {
            options.emptySearchMsg = OrbitInternalLangPack.getText('WE000006451', '검색 결과가 없습니다.')
        }

        if (options.rowMovable === undefined) {
            options.rowMovable = false;
        }

        if (options.rowMovable === true && options.appendable === true) {
            throw new Error('rowMovable는 입력모드에서 사용할 수 없습니다.');
        }

        if (options.preventRClick === undefined) {
            options.preventRClick = false;
        }

        if (options.pageAuthority === undefined) {
            options.pageAuthority = {
                deleteAuthYn: "Y",
                modifyAuthYn: "Y",
                printAuthYn: "Y",
                selectAuthType: "C",
            }
        }

        if (options.useInternalStyle === undefined) {
            options.useInternalStyle = true;
        }

        if (options.useCheckPen === undefined) {
            options.useCheckPen = true;
        }

        if (options.treeViewRowsKey === undefined) {
            options.treeViewRowsKey = '_rows';
        }

        if (options.useCustomLayout === undefined) {
            options.useCustomLayout = false;
        }

        options.reservedColumnNames = {
            ...(options.reservedColumnNames || {}),
            memoCode: 'memoCd',
            checkPen: 'checkPen',
            insertDateTime: 'insertDt',
            insertIPAddress: 'insertIp',
            insertUserId: 'insertId',
            modifiedIPAddress: 'modifyIp',
            modifyDateTime: 'modifyDt',
            modifyUserId: 'modifyId'
        };

        if (options.eachRowResizable === undefined) {
            options.eachRowResizable = false;
        }

        if (options.isSystemGrid === undefined) {
            options.isSystemGrid = false;
        }
    }

    public async lockCellChangeTemplate(callback: Promise<void> | (() => Promise<void>)) {
        this._lockCellSelectionChange = true;

        if (typeof callback === 'function') {
            await callback();
        } else {
            await callback;
        }

        this._lockCellSelectionChange = false;
    }

    public async lockRowChangeTemplate(callback: Promise<void> | (() => Promise<void>)) {
        this._lockRowSelectionChange = true;

        if (typeof callback === 'function') {
            await callback();
        } else {
            await callback;
        }

        this._lockRowSelectionChange = false;
    }

    public setLockRowSelectionChange(value: boolean) {
        this._lockRowSelectionChange = value;
    }

    /**
     * 
     * @param visible 
     */
    public setIndicator(visible: boolean): OBTDataGridInterface {
        this._gridOption.indicator = visible;
        if (this._gridView) {
            this._gridView.setIndicator({
                visible: visible,
            });
        }
        return this;
    }

    /**
     * GridView.setEditOptions를 이용해서 그리드 전체의 수정가능여부 설정
     * 각 컬럼별 editable 설정이 우선되며 별로도 세팅하지 않았을때 해당 값을 사용한다. 
     * @param editable 
     * @returns fluent api
     */
    public setEditable(editable: boolean): OBTDataGridInterface {
        this.gridOption.editable = editable;

        if (this._gridView) {
            this._gridView.setEditOptions({
                ...this.getDefaultGridEditOption(),
                deletable: editable,
                readOnly: !editable,
                editable: editable,
                updatable: editable,
                skipReadOnly: editable,
                skipReadOnlyCell: editable,
            });
        }
        return this;
    }

    /**
     * 그리드 옵션의 디스플레이 옵션
     * @param option 
     */
    public setDisplayOptions(option: IGridDisplayOption): OBTDataGridInterface {
        if (this.gridOption.editable === true || this.gridOption.appendable === true) {
            this.gridOption.columnMovable = false;
        } else {
            this.gridOption.columnMovable = option.columnMovable;
        }

        this.gridOption.eachRowResizable = option.eachRowResizable;
        this.gridOption.emptyShowTooltip = option.emptyShowTooltip;

        if (this._gridView) {
            this._gridView.setDisplayOptions({
                eachRowResizable: option.eachRowResizable,
                columnMovable: option.columnMovable,
            });
        }
        return this;
    }

    /**
     * 그리드 옵션 푸터 설정
     * @param visible 
     * @param footerCount  
     */
    public setFooter(visible: boolean, footerCount?: number): OBTDataGridInterface {
        if (footerCount !== undefined) {
            this.gridOption.footerCount = footerCount;
        }

        let option = {
            visible: visible
        };

        option = Object.assign(option, footerCount !== undefined ? {
            height: 20 * (footerCount),
            count: footerCount
        } : {});

        this.gridView.setFooter(option);

        return this;
    }

    /**
     * 헤더 높이 설정
     * heightFill: 헤더의 높이 계산 방식을 지정하는 상수
     * default: 헤더의 높이를 자동으로 계산한다.
     * fixed: 헤더의 높이를 고정한다
     * @param option 
     * @see http://help.realgrid.com/api/types/Header/
     */
    public setHeader(option: {
        height?: number,
        heightFill?: "heightFill" | "default" | "fixed",
        visible?: boolean,
        minHeight?: number,
        resizable?: boolean,
        filterable?: boolean,
        sortable?: boolean,
        subTextGap?: number,
        subTextLocation?: string,
        imageList?: string,
        itemOffset?: number,
        itemGap?: boolean,
        styles?: any,
        subStyles?: any,
        showTooltip?: boolean,
        summary?: boolean,
        tooltipEllipseTextOnly?: boolean,
    }, merge: boolean = false): OBTDataGridInterface {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.setHeader: 그리드가 준비되지 않았습니다.');
        }

        if (merge === true) {
            const header = this.gridView.getHeader();
            const mergedHeaderOption = {
                ...header,
                ...option
            }
            this.gridView.setHeader(mergedHeaderOption);
        } else {
            this.gridView.setHeader(option);
        }

        return this;
    }

    /**
     * 그리드의 마지막 행에서 엔터입력시 새로운 로우를 추가할 것인지 여부
     * @param appendable 
     */
    public setAppendable(appendable: boolean): OBTDataGridInterface {
        this._gridOption.appendable = appendable;
        return this;
    }

    public setCheckBar(option: {
        /**
         * CheckBar의 너비를 픽셀 단위로 지정한다. 최소 너비는 1 픽셀이다.
         */
        width?: number,

        /**
         * checkBar.head 에 “v” 표시 여부를 지정한다.
         */
        showAll?: boolean,

        /**
         * 행 그룹핑시 그룹 헤더 영역에 체크박스 표시 여부를 지정한다.
         */
        showGroup?: boolean,

        /**
         * checkBar.head를 클릭하여 전체 선택시 보이는 행만 체크할 것인지의 여부를 지정한다.
         */
        visibleOnly?: boolean,

        /**
         * 체크 가능한 행만 체크할 수 있는지의 여부를 지정한다. (checkableExpression에서 체크 가능 여부를 지정할 수 있다.)
         */
        checkableOnly?: boolean,

        /**
         * CheckBar를 그리드에 표시할 것인지의 여부를 지정한다.
         */
        visible?: boolean,

        /**
         * 한 행만 체크 가능할지의 여부를 지정한다.
         */
        exclusive?: boolean,

        /**
         * 체크 가능 여부의 수식을 지정한다.
         */
        checkableExpression?: string,

        /**
         * 
         */
        headText?: string,

        /**
         * 
         */
        footText?: string,

        /**
         * 
         */
        headImageUrl?: string,

        /**
         * 
         */
        footImageUrl?: string,

        done?: (() => {}),

        stateStyles?: any,

        dynamicStyles?: IRealGridCellStyleOption[],

        checkImageUrl?: string,

        unCheckImageUrl?: string,

        // disableCheckImageUrl: string

        radioImageUrl?: string,

        headCheckImageUrl?: string,

        headUnCheckImageUrl?: string,

        /**
         * checkbox의 외곽라인을 여부를 지정한다.
         */
        drawCheckBox?: boolean,

        /**
         * true인 경우 데이터 영역의 전체 item 체크 상태가 CheckBar Head의 체크 상태가 연동된다.
         * ex) 데이터행의 모든 item이 체크되면 Head영역에도 자동으로 체크가 됨
         */
        syncHeadCheck?: boolean

    }): OBTDataGridInterface {
        if (option.visible !== undefined) {
            this._gridOption.checkable = option.visible
        }

        if (option.showAll !== undefined) {
            this._gridOption.headerCheckVisible = option.showAll;
        }

        this.gridView.setCheckBar(option);
        return this;
    }

    public setRowGroupOption(option: {
        expandedAdornments?: string,
        collapsedAdornments?: string,
        summaryMode?: ColumnSummaryExpression,
        cellDisplay?: string,
        headerStatement?: string,
        levelIndent?: number,
        mergeExpander?: boolean,
        mergeMode?: boolean,
        footerStatement?: string,
        footerCellMerge?: boolean,
        sorting?: boolean,
        levels?: any[],
        headerCallback?: (groupModel: any, grid: any) => void,
        createFooterCallback?: (grid, groupModel) => void,
    }): OBTDataGridInterface {
        this.gridView.setRowGroup(option);
        return this;
    }

    /**
     * 체크모드 설정
     * gridView.setCheckBar
     * @param checkable 
     */
    public setCheckable(checkable: boolean, showHeaderCheck: boolean): OBTDataGridInterface {
        this._gridOption.checkable = checkable;

        if (this._gridView) {
            this._gridView.setCheckBar({
                visible: checkable,
                showAll: showHeaderCheck,
                //checkbox의 외곽라인을 여부를 지정한다.
                drawCheckBox: true,
            });
        }

        return this;
    }

    /**
     * IColumn배열을 받아 필드에 할당하고 그리드뷰와 데이터프로바이더에 세팅한다.
     * _initialize된 상태에서만 리얼그리드에 컬럼을 세탕한다.
     * fluent api
     */
    public setColumns(columns: IColumn[] | null): OBTDataGridInterface {
        if (!columns) {
            throw new Error('OBTDataGridInterface.setColumns : 컬럼이 존재하지 않습니다.');
        }

        const hostName = window.location.hostname;
        if (this.isDevServer(hostName) && this.hasDuplicatedName(columns)) {
            console.warn('OBTDataGridInterface.setColumns : 중복된 name값이 있습니다. setColumns 메서드는 중복된 name의 value를 사용할 수 없습니다.');
        }

        this._columns = columns.map(this.mapColumnToDefault);

        if (this._isInitialized === true) {
            this._setColumns(true);
        }

        return this;
    }

    mapColumnToDefault(column: IColumn) {
        const result = {
            ...column,
            originalDropDownList: column.type === ColumnType.dropDown && column.dynamicDropDownItemFilter ? column.dropDownDataItems : column.originalDropDownList,
            buttonVisibility: column.buttonVisibility === undefined ? 'mouseOver' : column.buttonVisibility,
            showButtonOnlyEditable: column.showButtonOnlyEditable === undefined ? true : column.showButtonOnlyEditable,
        } as IColumn;

        return result;
    }

    private convertToLegacyColumn(columns: INewColumn[]): IColumn[] {
        return columns.map(toLegacyColumn);
    }

    public buildColumns(columns: INewColumn[]): OBTDataGridInterface {
        return this.setColumns(this.convertToLegacyColumn(columns));
    }

    public buildColumn(column: INewColumn): OBTDataGridInterface {
        return this.setColumn(toLegacyColumn(column));
    }

    /** 
     * index나 컬럼명을 기반으로 컬럼을 제거한다.
     * index를 기반으로 할 경우 그룹 컬럼의 자식을 삭제할 수 없다.
     * @param indexOrName 
     */
    public removeColumn(indexOrName: number | string) {
        if (typeof indexOrName === 'number') {
            if (this._columns && indexOrName >= 0 && indexOrName < this._columns.length) {
                const targetColumn = this._columns[indexOrName];

                this._columns = this._columns.filter(item => item.name !== targetColumn.name);
                this._setColumns(true);
            }
        } else {
            if (this._columns) {
                const columnIndex = this.getRecursiveIndexByColumnName(this._columns, indexOrName);
                if (columnIndex) {
                    this.removeColumnByRecursiveColumnIndex(null, this._columns, columnIndex);
                    this._setColumns(true);
                }
            }
        }
    }

    /**
     * 
     * @param index 
     * @param columns 
     */
    public addColumn(index: number, ...columns: IColumn[]) {
        if (this._columns) {
            if (index >= 0 && index < this._columns.length) {
                this._columns = this._columns.slice(0, index).concat(columns, this._columns.slice(index));
            } else {
                this._columns = this._columns.concat(columns);
            }
        } else {
            this._columns = columns;
        }

        const hostName = window.location.hostname;
        if (this.isDevServer(hostName) && this.hasDuplicatedName(this._columns)) {
            console.warn('OBTDataGridInterface.addColumn : 중복된 name값이 있습니다. addColumn 메서드는 중복된 name의 value를 사용할 수 없습니다.');
        }

        this._setColumns(true);
    }

    /**
     *
     * @param columnNames 
     */
    public groupBy(columnNames: string[]) {
        if (this.gridOption.appendable === true) {
            throw new Error('groupBy함수는 appendable과 함께 사용할 수 없습니다.');
        }

        if (this.gridOption.editable === true) {
            throw new Error('groupBy함수는 editable과 함께 사용할 수 없습니다.');
        }

        if (columnNames.length === 1) {
            this.setRowGroupOption({
                levels: [
                    {
                        footerStyles: {
                            background: "#fffffceb",
                        }
                    },
                ]
            });
        } else if (columnNames.length === 2) {
            this.setRowGroupOption({
                levels: [
                    {
                        footerStyles: {
                            background: "#fffff7e0",
                        }
                    },
                    {
                        footerStyles: {
                            background: "#fffffceb",
                        }
                    },
                ]
            });
        } else if (columnNames.length === 3) {
            this.setRowGroupOption({
                levels: [
                    {
                        footerStyles: {
                            background: "#fffff1d6",
                        }
                    },
                    {
                        footerStyles: {
                            background: "#fffff7e0",
                        }
                    },
                    {
                        footerStyles: {
                            background: "#fffffceb",
                        }
                    },
                ]
            });
        } else {
            this.setRowGroupOption({
                levels: [
                    {
                        footerStyles: {
                            background: "#fffffceb",
                        }
                    },
                ]
            });
        }

        this.gridView.groupBy(columnNames);
    }

    /**
     * 프로바이더 세팅
     * @param provider 
     */
    public setProvider(provider: IDataProvider | null): OBTDataGridInterface {
        try {
            if (provider) {
                if (provider._provider === undefined) {
                    provider._provider =
                        (this._gridOption.gridType === GridType.treeView) ?
                            new RealGridJS.LocalTreeDataProvider() : new RealGridJS.LocalDataProvider();

                    provider._provider.setOptions({
                        checkStates: true,
                        softDeleting: true,
                        deleteCreated: true,
                        restoreMode: 'explicit',
                        commitBeforeDataEdit: true
                    });

                    if (this._columns) {
                        provider._provider.setFields(provider.fields ?
                            provider.fields : this.mapIColumnToProviderFields(this._columns));
                    }
                }
            }
            this._provider = provider;

            if (this._gridView) {
                if (this._gridOption.gridType === GridType.treeView) {
                    this._gridView.setDataSource(this._realGridProvider ? this._realGridProvider : null);
                } else {
                    this._gridView.setDataProvider(this._realGridProvider ? this._realGridProvider : null);
                }
            }
        } catch (e) {
            console.error(e);
            throw e;
        }

        return this;
    }

    /**
     * 컬럼의 width를 컨텐츠 사이즈에 맞춰 조정한다.
     * @param columnName? 적용할 컬럼이름
     * @param maxWidth?
     * @param minWidth?
     */
    public fitColumnWidth(option?: { columnName?: string, maxWidth?: number, minWidth?: number, visibleOnly?: boolean }) {
        if (!option) {
            this.gridView.fitColumnWidth(null, 0, 0, true);
            return;
        }

        this.gridView.fitColumnWidth(
            option.columnName ? option.columnName : null,
            option.maxWidth ? option.maxWidth : 0,
            option.minWidth ? option.minWidth : 0,
            option.visibleOnly ? option.visibleOnly : true,
        );
    }

    /**
     * rowIndex의 height를 컨텐츠에 맞춰 조정한다.
     * @param rowIndex 
     * @param maxHeight 
     */
    public fitRowHeight(itemIndex: number, maxHeight: number, textOnly: boolean = true, refresh: boolean = true) {
        this._gridView.fitRowHeight(itemIndex, maxHeight, textOnly, refresh);
    }

    /**
     * 모든 행의 높이를 표시되는 데이터에 맞게 변경한다.
     * (* displayOptions.eachRowResizable: true로 지정되어 있어야 한다. multiLine인 경우 textWrap: “explicit”로 지정 )
     * * 데이터 행의 수가 많은 경우 브라우져에서 응답없음이 발생할 수 있으므로 주의해서 사용한다.* 
     * @param maxHeight 
     * @param textOnly 
     */
    public fitRowHeightAll(maxHeight: number, textOnly: boolean = true) {
        return new Promise<void>(resolve => {
            setTimeout(() => {
                this._gridView.fitRowHeightAll(maxHeight, textOnly);
                resolve();
            }, 0);
        })
    }

    /**
     * 
     * @param rowIndex 
     * @param height 
     * @param refresh 
     */
    public setRowHeight(rowIndex: number, height: number, refresh: boolean = true) {
        this._gridView.setRowHeight(rowIndex, height, refresh);
    }

    /**
     * 
     * @param renderer 
     */
    public addCellRenderers(renderer: any[]) {
        this._gridView.addCellRenderers(renderer);
    }

    /**
     * @internal
     * 그리드 컬럼설정에서 개발자가 설정한 콜백을 호출한다.
     * @param rowIndex 
     * @param columnName 
     * @param item 
     */
    public _callAfterCodePickerCallback(rowIndex: number, columnName: string, items: any[]): void {
        if (!this.columns) {
            throw new Error("OBTDataGridInterface.callAfterCodePickerCallback: column can't be null");
        }

        const targetColumn = this.getColumnByName(columnName);
        if (targetColumn && targetColumn.afterCodeHelpCallback) {
            const eventArgs = {
                target: this,
                rowIndex: rowIndex,
                columnName: columnName,
                data: items && items.length > 0 ? items[0] : items,
                datas: items
            }
            targetColumn.afterCodeHelpCallback(eventArgs);
        }
    }

    /**
     * 그리드에 바인딩된 데이터의 길이를 리턴한다.
     * @returns 데이터의 길이(count)
     */
    public getRowCount(): number {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.getRowCount: 그리드가 준비되지 않았습니다.');
        }

        return this._gridView.getItemCount();
    }

    /**
     * 체크된 것이나 특정 GridState인 행의 인덱스 배열을 가져온다. 
     * [GridState]
     * @param options 가져오는 기준이되는 옵션
     */
    public getRowIndexes(options?: { checkedOnly?: boolean, states?: GridState[] }): number[] {
        let rowIndexes: number[] | null = null;
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.getRowIndexes: 그리드가 준비되지 않았습니다.');
        }

        if (options && Object.keys(options).length > 0) {
            if (options.checkedOnly === true) {
                rowIndexes = (this._gridView.getCheckedItems(true) || []);
            }

            if (options.states && options.states.length > 0) {
                if (rowIndexes) {
                    rowIndexes = rowIndexes.filter((rowIndex) => {
                        const state = this.getState(rowIndex);
                        return state && options.states!.includes(state);
                    });
                } else {
                    rowIndexes = [];
                    options.states.filter(state => state !== GridState.empty).map(state => {
                        const indexes = this._realGridProvider.getStateRows(this.getRealGridState(state));
                        if (state === GridState.added) {
                            return indexes.filter((index) => !this._emptyDataRows.includes(index));
                        }
                        return indexes;
                    }).concat(options.states.includes(GridState.empty) ? [this._emptyDataRows] : []
                    ).forEach(dataIndexes => {
                        rowIndexes = rowIndexes!.concat(dataIndexes.map(dataIndex => this._gridView.getItemIndex(dataIndex)));
                    });
                }
            }
        } else {
            rowIndexes = (() => {
                let indexes: number[] = [];
                const rowCount = this.getRowCount();
                for (let i = 0; i < rowCount; i++) {
                    indexes.push(i);
                }
                return indexes;
            })()
        }

        return rowIndexes || [];
    }

    /**
     * 체크된 것이나 특정 GridState인 행의 데이터 배열을 가져온다.
     * @param options 가져오는 기준이 되는 옵션
     */
    public getRows(options?: { checkedOnly?: boolean, states?: GridState[] }): any[] {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.getRows: 그리드가 준비되지 않았습니다.');
        }

        let rows: any[] = [];

        if (options && Object.keys(options).length > 0) {
            const rowIndexes = this.getRowIndexes(options);
            return rowIndexes.map((rowIndex) => this._gridView.getValues(rowIndex));
        } else {
            if (this.gridOption.gridType === GridType.treeView) {
                rows = this._realGridProvider.getJsonRows(-1, true, '_rows');
            }
            else {
                rows = this._realGridProvider.getJsonRows(0, -1);
            }
        }

        return rows;
    }

    /**
     * 페이징 모드일때 모든 데이터를 가져온다.
     * @internal
     */
    public async getAllPageRows(): Promise<any[] | null | undefined> {
        if (this.gridOption.paging === false) {
            return Promise.resolve(this.getRows());
        }

        if (this.pagingInfo && this.pagingInfo.isLastPage() === true) {
            return Promise.resolve(this.getRows());
        }

        const pagingInfo = await this.makePagination();
        const item = await this._getAllPageRows(pagingInfo, []);
        const list = item;
        return Promise.resolve(list);
    }

    /**
     * @internal
     * @param pagination 
     * @param item 
     */
    private async _getAllPageRows(pagination: Pagination, item: any[]): Promise<any[] | null | undefined> {
        const newItem = await this._provider!.read(new Events.ReadEventArgs(this, pagination));
        if ((newItem || []).length === 0) {
            pagination.preventRead();
        }

        if (pagination.isLastPage()) {
            return Promise.resolve(item.concat(newItem));
        }

        pagination.nextPage();

        return this._getAllPageRows(pagination, item.concat(newItem));
    }

    /**
     * 그리드뷰에 정렬된 순서로 모든 데이터를 가져온다.
     * @param options 
     */
    public getDisplayRows(options?: {
        checkedOnly?: boolean,
        states?: GridState[],
        /**
         * @deprecated withFormat 옵션을 사용하세요
         */
        containDisplayedValue?: boolean,
        withFormat?: boolean
    }): any[] {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.getDisplayRows: 그리드가 준비되지 않았습니다.');
        }

        let values: any[] = [];
        for (let i = 0; i < this.getRowCount(); i++) {
            if (options) {
                let isOk = true;
                if (options.checkedOnly === true) {
                    isOk = this.getCheck(i) === true;
                }

                if (isOk === true && options.states) {
                    const state = this.getState(i);
                    if (state && options.states.includes(state) === false) {
                        isOk = false;
                    }
                }

                if (isOk === true) {
                    let row = this.getRow(i);
                    if (row.__rowId !== undefined) {
                        row.__rowIndex = this.gridView.getItemIndex(row.__rowId);
                    }

                    if (options.containDisplayedValue || options.withFormat) {
                        row = this._gridView.getDisplayValues(row.__rowIndex, true)
                    }
                    values.push(row);
                }
            } else {
                const row = this.getRow(i);
                if (row.__rowId !== undefined) {
                    row.__rowIndex = this.gridView.getItemIndex(row.__rowId);
                }

                values.push(row);
            }
        }

        return values;
    }

    /**
     * 그룹컬럼을 제외하고 순수 컬럼만을 가져온다.
     */
    public getFlatColumns(visibleOnly: boolean, containGroupColumn: boolean = false): IColumn[] {
        if (!this.columns) {
            return [];
        }

        return this._getFlatColumns(this.columns, visibleOnly, containGroupColumn);
    }

    /**
     * @internal
     * 그룹컬럼을 제외하고 순수 컬럼만을 가져온다.
     */
    _getFlatColumns(targetColumnList: IColumn[], visibleOnly: boolean, containGroupColumn: boolean = false): IColumn[] {
        let result: IColumn[] = [];

        (targetColumnList || []).forEach((col) => {
            if (visibleOnly === true) {
                if (col.type !== ColumnType.data && col.type !== ColumnType.group) {
                    if (col.visible !== false) {
                        result.push(col);
                    }
                }
            } else {
                if (containGroupColumn === true || col.type !== ColumnType.group) {
                    result.push(col);
                }
            }

            if (col.type === ColumnType.group && col.columns) {
                if (visibleOnly === true && col.visible === false) {

                } else {
                    result = result.concat(this._getFlatColumns(col.columns, visibleOnly));
                }
            }
        });

        return result;
    }


    /**
     * 파라미터로 준 인덱스의 행 데이터를 가져온다.
     * @param rowIndex 
     * @param options 
     */
    public getRow(rowIndex: number, options?: GetValueOption): any {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.getRow: 그리드가 준비되지 않았습니다.');
        }

        return this.getValues(rowIndex, options);
    }

    /**
     * 파라미터로 준 인덱스의 행 데이터를 가져온다.
     * @param rowIndex 행의 인덱스
     * @param options 
     * @returns 데이터
     */

    public getValues(rowIndex: number, options?: GetValueOption): any {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.getValues: 그리드가 준비되지 않았습니다.');
        }

        let rawValues = options && options.withFormat === true ?
            this._gridView.getDisplayValues(rowIndex, true) : this._gridView.getValues(rowIndex) as any[];
        if (!rawValues) {
            return null;
        }

        if (options && options.withFormat === true) {
            const numberColumns = this.getFlatColumns(false).filter((item) => item.type === ColumnType.number).map((item) => item.name);
            if (numberColumns.length > 0) {
                numberColumns.forEach(item => {
                    if (rawValues[item] && typeof rawValues[item] === 'string') {
                        rawValues[item] = Number(rawValues[item].replace(/,/gi, ''))
                    }
                });
            }
        }

        const nanToZeroColumns = this.getFlatColumns(false).filter((item) => item.nanToZero === true).map((item) => item.name);
        nanToZeroColumns.forEach(item => {
            if (isNaN(rawValues[item])) {
                rawValues[item] = 0;
            }
        });

        if (options) {
            if (options.usePrivacyOriginField) {
                const privacyColumns = this.getFlatColumns(false).filter(column => column.usePrivacy);
                privacyColumns.forEach(column => {
                    const fieldName = OBTDataGridInterface.getFieldName(column);
                    const originFieldName = OBTDataGridInterface.getOriginFieldName(column);
                    rawValues[fieldName] = rawValues[originFieldName];
                })
            }
        }
        return rawValues;
    }

    /**
     * 특정 셀의 데이터를 가져온다. option.withFormat은 성능 저하를 일으키니 필요한 케이스에서만 사용
     * 
     * @param rowIndex 
     * @param columnName 
     * @param option
     */
    public getValue(rowIndex: number, columnName: string, option?: GetValueOption): any {
        try {
            if (!this.isReady) {
                throw new Error('OBTDataGridInterface.getValue: 그리드가 준비되지 않았습니다. ');
            }

            const column = this.getColumnByName(columnName);
            if (!column) {
                throw new Error("OBTDataGridInterface.getValue: 컬럼을 찾을 수 없습니다. -  " + columnName);
            }

            let rawValue: any = null
            if (option && option.withFormat === true) {
                const row = this._gridView.getDisplayValues(rowIndex, true);
                if (row) {
                    if (column.type === ColumnType.number) {
                        rawValue = row[columnName];
                        if (rawValue && typeof rawValue === 'string') {
                            rawValue = Number(rawValue.replace(/,/gi, ''));
                        }
                    } else {
                        rawValue = row[columnName];
                    }
                }
            } else {
                rawValue = this._gridView.getValue(rowIndex, columnName);
            }

            if (option && option.usePrivacyOriginField === true) {

            }

            // 옵션에 따른 후처리
            if (column.type === ColumnType.number && column.nanToZero === true) {
                if (isNaN(rawValue)) {
                    rawValue = 0;
                }
            }

            return rawValue;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     * 그리드에 세팅된 IColumn을 컬럼이름으로 검색해 가져온다.
     * @param columnName 가져올 데이터의 컬럼이름 IColumn.name 속성과 매핑
     * @returns 이름으로 찾은 IColumn이나 undefined
     */
    public getColumnByName(columnName: string): IColumn | undefined {
        if (!columnName) {
            return;
        }

        if (!this._columnMap) {
            return;
        }

        const column = this._columnMap[columnName.toUpperCase()];

        return column ? column : this._hasSubNameColumnMap[columnName.toUpperCase()]
    }

    /**
     * setColums로 할당한 컬럼 정보
     */
    public getColumns(): IColumn[] {
        return this.columns || []
    }

    /**
     * unmanaged result 리얼그리드의 컬럼정보를 이름으로 가져온다.
     * @param columnName 가져올 데이터의 컬럼이름 IColumn.name 속성과 매핑
     * @returns 리얼그리드에서 관리되는 컬럼 속성
     */
    public getRealGridColumnByName(columnName: string): any | null {
        return this._gridView.columnByName(columnName)
    }
    public hasDuplicatedName(columns: IColumn[]): boolean {
        try {
            const columnNameSet = new Set();
            const duplicatedCheck = (columns: IColumn[]): boolean => {
                for (let column of columns) {
                    if (columnNameSet.has(column.name)) {
                        return true;
                    } else {
                        columnNameSet.add(column.name);
                        if (column.type === "group" && column.columns && duplicatedCheck(column.columns)) {
                            return true;
                        }
                    }
                }
                return false;
            }
            return duplicatedCheck(columns);
        } catch (e) {
            return false;
        }
    }

    public isDevServer(hostName: string): boolean {
        if (hostName === 'localhost' ||
            hostName === '127.0.0.1' ||
            hostName === 'dev.amaranth10.co.kr')
            return true;
        else return false;
    }

    /**
     * IColumn을 기반으로 그리드에 컬럼을 세팅한다.
     * fluent api
     * @param column 세팅할 컬럼
     * @param merge column.name에 일치하는 기존의 컬럼을 column으로 할당한 값과 merge한다. (변경할 값만 업데이트)
     */
    public setColumn(column: IColumn, merge?: boolean): OBTDataGridInterface {
        if (!column || !column.name) {
            throw new Error('OBTDataGridInterface.setColumn : 타겟 컬럼이 존재하지 않거나 컬럼 name을 넣지 않았습니다.');
        }

        const hostName = window.location.hostname;
        if (this.isDevServer(hostName) && this.hasDuplicatedName([column])) {
            console.warn('OBTDataGridInterface.setColumns : 중복된 name값이 있습니다. setColumn 메서드는 중복된 name의 value를 사용할 수 없습니다.');
        }

        if (column.dropDownDataItems) {
            column = {
                ...column,
                originalDropDownList: column.dropDownDataItems
            }
        }

        this._setColumn(column, merge);

        return this;
    }

    /**
    * IColumn을 기반으로 그리드에 컬럼을 세팅한다.
    * @param column 세팅할 컬럼
    * @param merge column.name에 일치하는 기존의 컬럼을 column으로 할당한 값과 merge한다. (변경할 값만 업데이트)
    */
    private _setColumn(column: IColumn, merge?: boolean): void {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.setColumn: 그리드가 준비되지 않았습니다.');
        }

        if (column.name === undefined) {
            throw new Error('OBTDataGridInterface.setColumn: column의 name 속성은 필수값입니다.');
        }

        if (!this._columns) {
            throw new Error('OBTDataGridInterface.setColumn: _columns is null or undefined');
        }

        try {
            if (merge === true) {
                const targetColumn = this.getColumnByName(column.name);
                if (targetColumn === undefined) {
                    throw new Error("컬럼을 찾을수 없습니다. : " + column.name);
                }

                column = Object.assign({}, targetColumn, column);
            }

            let index: IRecusiveColumnIndex | undefined
                = this.getRecursiveIndexByColumnName(this._columns, column.name);

            if (index) {
                this.replaceColumnByRecursiveColumnIndex(
                    this._columns,
                    index,
                    column
                );
            } else {
                this._columns.push(column);
            }

            this.updateColumnMap();

            const realgridColumn = this._gridView.columnByName(column.name);
            let resultRealGridColumn: any;
            if (realgridColumn) {
                resultRealGridColumn = Object.assign({},
                    realgridColumn,
                    this.mapIColumnToRealGridColumn(column)
                );
            } else {
                resultRealGridColumn = this.mapIColumnToRealGridColumn(column);
            }

            if (column.type === ColumnType.data) {
                this._gridView.removeColumn(column.name);
                if (this._columns) {
                    this._realGridProvider.setFields(this.mapIColumnToProviderFields(this._columns));
                }
            } else {
                // setValue는 기본적으로 GridView에서 얻어온 컬럼에서 필요한것만 변경해주는 방식으로 써야한다.
                this._gridView.setColumn(resultRealGridColumn);
            }
        } catch (e) {
            console.error(e)
            throw e;
        }
    }

    /**
     * itemIndex에 json object인 values로 행 데이터를 세팅한다.
     * 다른행에 포커스가 있을때 호출하면 행이동을 우선 수행한다.
     * 다른행으로 포커스 이동을 할 수 없을 때 데이터를 세팅하고 storeData를 호출한다.
     * @param rowIndex 행의 인덱스
     * @param values 데이터
     */
    public setValues(rowIndex: number, values: any, moveRow: boolean = true): void {
        if (this.checkPossibleGridAction('editable', true) === false) {
            return;
        }

        if (moveRow === true && this.getSelectedIndex() !== rowIndex) {
            this.setSelection(rowIndex);
        }

        this.processSetValues(rowIndex, values, null);
    }

    /**
     * 특정 셀의 데이터를 세팅한다.
     * 다른행에 포커스가 있을때 호출하면 행이동을 우선 수행한다.
     * 다른행으로 포커스 이동을 할 수 없을 때 데이터를 세팅하고 storeData를 호출한다.
     * BeforeChange와 ValidateChange에서 cancel되었는지 여부를 리턴한다.
     * @param rowIndex 가져올 데이터의 행
     * @param columnName 가져올 데이터의 컬럼이름 IColumn.name 속성과 매핑
     * @param value 바인딩할 데이터
     */
    public setValue(rowIndex: number, columnName: string, value: any, moveRow: boolean = true, options?: {
        usePrivacyOriginField?: boolean
    }): boolean {
        if (this.checkPossibleGridAction('editable', true) === false) {
            return false;
        }

        if (moveRow === true && this.getSelectedIndex() !== rowIndex) {
            this.setSelection(rowIndex);
        }

        if (this.isEditing() === true) {
            this.commit();
        }

        const result = this.processSetValue(rowIndex, columnName, value, null, options);
        return result;
    }

    public setValueAsync(rowIndex: number, columnName: string, value: any, moveRow: boolean = true, options?: {
        usePrivacyOriginField?: boolean
    }) {
        if (this.checkPossibleGridAction('editable', true) === false) {
            return false;
        }

        if (moveRow === true && this.getSelectedIndex() !== rowIndex) {
            this.setSelection(rowIndex);
        }

        if (this.isEditing() === true) {
            this.commit();
        }

        return this.processSetValueAsync(rowIndex, columnName, value, null, options);
    }

    /**
     * @internal
     * BeforeChange와 ValidateChange 이벤트를 무시한 setValue
     * @param rowIndex 가져올 데이터의 행
     * @param columnName 가져올 데이터의 컬럼이름 IColumn.name 속성과 매핑
     * @param value 바인딩할 데이터
     */
    public setValueUnmanaged(rowIndex: number, columnName: string, value: any) {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                this._gridView.setValue(rowIndex, columnName, value);
                resolve();
            }, 0)
        })
    }

    /**
     * @internal
     */
    public setValueInternal(rowIndex: number, columnName: string, value: any) {
        this._gridView.setValue(rowIndex, columnName, value);
    }

    /**
     * @internal
     * 리얼그리드 내부에 아이콘 식으로 사용할 이미지 리스트를 등록한다.
     *  iconPathList는 columnName + '_image'의 키값으로 저장된다.
     * @param columnName 적용할 컬럼이름, 아이콘세트 이름의 기준이된다.
     * @param iconPathList 아이콘경로리스트
     */
    public registerColumnImageList(columnName: string, iconPathList: string[]): void {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.registerColumnImageList: 그리드가 준비되지 않았습니다.');
        }

        var imgs = new RealGridJS.ImageList(columnName + '_image', '');
        imgs.addUrls(iconPathList);

        this.gridView.registerImageList(imgs);
    }

    /**
     * styleId를 추가한다.
     * @param styleId id
     * @param style 스타일 오브젝트
     */
    public addCellStyle(styleId: string, style?: IRealGridCellStyleOption) {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.addCellStyle: 그리드가 준비되지 않았습니다.');
        }

        this.gridView.addCellStyle(styleId, style);
    }

    /**
     * 특정 셀에 addCellStyle로 지정한 styleId를 지정한다.
     * 여기서 지정된 속성은 다이나믹 스타일보다 우선됨
     * @param rowIndex 
     * @param columnName 
     * @param styleId 적용할 styleId
     */
    public setCellStyle(rowIndex: number, columnName: string, styleId: string) {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.setCellStyle: 그리드가 준비되지 않았습니다.');
        }

        let rowId = this._gridView.getDataRow(rowIndex);

        this.gridView.setCellStyle(rowId, columnName, styleId, true);
    }

    /**
     * 특정 로우에 addCellStyle로 지정한 styleId를 지정한다.
     * 여기서 지정된 속성은 다이나믹 스타일보다 우선됨
     * @param rowIndex 
     * @param columnName 
     * @param styleId 적용할 styleId
     */
    public setRowStyle(rowIndex: number, styleId: string, override?: boolean) {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.setRowStyle: 그리드가 준비되지 않았습니다.');
        }

        this.gridView.setCellStyles(
            [this._gridView.getDataRow(rowIndex)],
            this._getFlatColumns(this.columns!, true).map((col) => col.name),
            styleId
        );
    }

    /**
     * styleId를 제거한다. 적용된 스타일도 제거됨
     * @param styleId 
     */
    public removeCellStyle(styleId: string) {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.removeCellStyle: 그리드가 준비되지 않았습니다.');
        }

        this.gridView.removeCellStyles([styleId]);
    }

    /**
     * 그리드에 빈 행을 추가한다.
     * BeforeAddRow, AfterAddRow 이벤트를 발행한다.
     * @param rowIndex 데이터를 추가할 위치
     */
    public addRow(rowIndex?: number, dataRowValues?: any): void {
        // if (this.checkPossibleGridAction('appendable', true) === false) {
        //     return;
        // }

        this.processAddRow(rowIndex, dataRowValues);
    }

    private processAddRow(rowIndex?: number, dataRowValues?: any): void {
        if (this.isReady === false) {
            throw new Error('OBTDataGridInterface.addRow: 그리드가 준비되지 않았습니다.');
        }

        if (this._gridOption.gridType === GridType.treeView) {
            throw new Error('OBTDataGridInterface.addRow: 트리뷰일 경우 addTreeRow를 사용해주세요. ' + this.id)
        }

        try {
            this._gridView.commit(false);

            let values: any = dataRowValues ? dataRowValues : {};
            const invokeResult = this.invokeEvent<Events.BeforeAddRowEventArgs>(
                this.onBeforeAddRow,
                new Events.BeforeAddRowEventArgs(this, values), true);

            if (invokeResult.isCancel !== true) {
                let dataIndex = this._gridView.getDataRow(rowIndex);
                if (dataIndex < 0) {
                    rowIndex = undefined;
                }

                let newRowIndex: number = -1;
                if (rowIndex !== undefined) {
                    newRowIndex = this._realGridProvider.insertRow(dataIndex, values);
                } else {
                    newRowIndex = this._realGridProvider.addRow(values);
                }

                // _emptyDataRows 재조정
                if (rowIndex) {
                    this.setEmptyDataRows(this._emptyDataRows.map((dataRow) => {
                        if (dataIndex <= dataRow) {
                            return dataRow + 1;
                        }

                        return dataRow;
                    }))
                }

                // 새로 추가되는 행이 비어있지 않다면 emptyDataRows에 추가
                // dataRowValues 인자로 들어온다면 empty로 보지 않는다. 
                // TODO:
                if (this.isEmptyDataItem(values) || !dataRowValues) {
                    if (rowIndex) {
                        this.setEmptyDataRows(this._emptyDataRows.concat(dataIndex));
                    } else {
                        this.setEmptyDataRows(this._emptyDataRows.concat(this._gridView.getDataRow(this.getRowCount() - 1)))
                    }
                }

                this.invokeEvent<Events.AfterAddRowEventArgs>(
                    this.onAfterAddRow,
                    new Events.AfterAddRowEventArgs(this, rowIndex || newRowIndex, values));

                this.invokeEvent<Events.AfterDataChangeEventArgs>(
                    this.onAfterDataChanged,
                    new Events.AfterDataChangeEventArgs(this, [], Events.GridDataChangeAction.ROW_ADDED));
            }

            if (this.getRowCount() > 0) {
                this.onChangeEmptySetType(EmptySetType.hasData);
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public isEmptyDataItem(dataItem: any) {
        if (!dataItem) {
            return true;
        }

        const columns = this.getFlatColumns(false);
        return !columns.some((column) => {
            return dataItem[column.name]
        });
    }

    /**
     * 
     * @param parentRowIndex 
     * @param childRowIndex 
     */
    public addTreeRow(parentRowIndex: number = -1, childRowIndex: number = -1): void {
        if (this.isReady === false) {
            throw new Error('OBTDataGridInterface.addRow: 그리드가 준비되지 않았습니다.');
        }

        if (this.checkPossibleGridAction('appendable', true) === false ||
            this._gridOption.gridType !== GridType.treeView) {
            return;
        }

        this._gridView.commit(false);

        let values: any = {};
        const invokeResult = this.invokeEvent<Events.BeforeAddRowEventArgs>(
            this.onBeforeAddRow,
            new Events.BeforeAddRowEventArgs(this, values, false, parentRowIndex), true);

        if (invokeResult.isCancel !== true) {
            const dataIndex = parentRowIndex >= 0 ? this._gridView.getDataRow(parentRowIndex) : -1;
            const childIndex = childRowIndex >= 0 ? (() => {
                const children = dataIndex >= 0 ? this.getChildrenIndexes(parentRowIndex) : this.getRowIndexes();
                return children.findIndex((index => index === childRowIndex));
            })() : -1;
            let newDataIndex = -1;
            if (childIndex >= 0) {
                newDataIndex = this._realGridProvider.insertChildRow(dataIndex, childIndex, values, -1, false);
            } else {
                newDataIndex = this._realGridProvider.addChildRow(dataIndex, values, -1, false);
            }
            const newRowIndex = this._gridView.getItemIndex(newDataIndex);

            if (newRowIndex >= 0) {
                this.setEmptyDataRows(this._emptyDataRows.concat(newDataIndex));
                this.invokeEvent<Events.AfterAddRowEventArgs>(
                    this.onAfterAddRow,
                    new Events.AfterAddRowEventArgs(this, newRowIndex, values, parentRowIndex));

                this.invokeEvent<Events.AfterDataChangeEventArgs>(
                    this.onAfterDataChanged,
                    new Events.AfterDataChangeEventArgs(this, [], Events.GridDataChangeAction.ROW_ADDED));
            }
            if (this.getRowCount() > 0) {
                this.onChangeEmptySetType(EmptySetType.hasData);
            }
        }
    }

    /**
     * 체크스테이트를 검사해 변경된 데이터가 있는지 여부를 리턴한다.
     * @returns 변경된 데이터가 있는지 여부
     */
    public hasDirtyData(): boolean {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.hasDirtyData: 그리드가 준비되지 않았습니다.');
        }

        const changedRow: any = this._getAllStateRows();

        return Object.keys(changedRow).find((stateName) => {
            return changedRow[stateName].length > 0
        }) ? true : false;
    }

    /**
     * 데이터 커밋
     */
    public commit(): void {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.commit: 그리드가 준비되지 않았습니다.');
        }

        this._gridView.commit(false);
    }

    /**
     * unmanaged result
     * header 정보 리턴
     */
    public getHeader(): any {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.getHeader: 그리드가 준비되지 않았습니다.');
        }

        return this.gridView.getHeader();
    }

    /**
     * 특정 인덱스 체크처리
     * @param rowIndex 
     * @param checked 
     */
    public setCheck(rowIndex: number, checked: boolean, invokeEvent?: boolean): void {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.setCheck: 그리드가 준비되지 않았습니다.');
        }

        this._setCheck(rowIndex, checked, false, invokeEvent !== undefined ? invokeEvent : true);

        this.invokeEvent<Events.AfterDataChangeEventArgs>(
            this.onAfterDataChanged,
            new Events.AfterDataChangeEventArgs(this, [], Events.GridDataChangeAction.CHECK_CHANGED));
    }

    /**
     * @internal
     * 특정 인덱스 체크처리
     * @param rowIndex 
     * @param checked 
     * @param exclusive 
     * @param checkEvent 
     */
    private _setCheck(rowIndex: number, checked: boolean, exclusive: boolean, checkEvent: boolean): void {
        this._gridView.checkItem(rowIndex, checked, exclusive, checkEvent);
    }

    /**
     * 특정 인덱스의 행이 체크되어있는지 여부를 리턴한다.
     * @param rowIndex 
     */
    public isCheckedRow(rowIndex: number): boolean {
        return this._gridView.isCheckedItem(rowIndex);
    }

    /**
     * 특정 인덱스의 행이 체크되어있는지 여부를 리턴한다.
     * @param rowIndex 
     */
    public getCheck(rowIndex: number): boolean {
        return this.isCheckedRow(rowIndex);
    }

    /**
     * 특정 인덱스의 행이 체크가능 여부를 세팅한다.
     * @param rowIndex 
     * @param checkable 
     */
    public setRowCheckable(rowIndex: number, checkable: boolean) {
        this._gridView.setCheckable(rowIndex, checkable);

        // this._gridView.applyCheckables();

    }

    /**
     * 특정 인덱스의 행이 체크가능한지 여부를 리턴한다.
     * @param rowIndex 
     */
    public isRowCheckable(rowIndex: number): boolean {
        return this._gridView.isCheckable(rowIndex);
    }

    /**
     * 조회된 모든 행들을 체크처리한다.
     * @param rowIndex 
     */
    public checkAll() {
        this.gridView.checkAll(true, true);
    }

    /**
     * 조회된 모든 행들을 언체크처리한다.
     * @param rowIndex 
     */
    public unCheckAll() {
        this.gridView.checkAll(false, true);
    }

    /**
     * 그리드에서 체크된 로우를 가져온다.
     */
    public getCheckedRows(): any[] {
        return this.getRows({
            checkedOnly: true
        });
    }

    public getUnCheckedRows(): any[] {
        return this.getRows().filter(((item, index) => {
            return this.getCheck(index) === false;
        }))
    }

    /**
     * 체크한 인덱스 가져오기
     */
    public getCheckedIndexes(): number[] {
        return this.getRowIndexes({
            checkedOnly: true
        });
    }

    /**
     * rowIndex의 스테이트를 가져온다.
     * @param rowIndex 
     */
    public getState(rowIndex: number): GridState | null {
        if (rowIndex < 0) {
            return null;
        }

        const dataRow = this._gridView.getDataRow(rowIndex);
        if (dataRow < 0) {
            return GridState.none;
        }
        if (this._isEmptyDataRow(dataRow)) {
            return GridState.empty;
        }

        return this.getGridState(
            this._realGridProvider.getRowState(this._gridView.getDataRow(rowIndex)));
    }

    /**
     * rowIndex의 스테이트를 state로 설정한다.
     * @param rowIndex 
     * @param state 
     */
    public setState(rowIndex: number, state: GridState, noCheckBeforeState = false): void {
        const dataRow = this._gridView.getDataRow(rowIndex);
        const oldState = this.getState(rowIndex);

        if (noCheckBeforeState === true || oldState !== state) {
            if (state === GridState.empty && !this._isEmptyDataRow(dataRow)) {
                this.setEmptyDataRows(this._emptyDataRows.concat(dataRow));
                state = GridState.added;
            }
            const newState = this.getRealGridState(state);
            this._realGridProvider.setRowState(dataRow, newState);
        }
    }

    /**
     * 선택한 행의 로우를 삭제한다.
     * 여러개의 로우를 한번에 삭제할때는 removeRows를 사용
     * @param rowIndex 
     */
    public async removeRow(rowIndex: number) {
        return this.removeRows([rowIndex]);
    }

    /**
     * 여러건의 로우를 한번에 삭제한다. 
     * @param rowIndexes 
     */
    public async removeRows(rowIndexes: number[]): Promise<void> {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.removeRows: 그리드가 준비되지 않았습니다.');
        }

        // if (this.checkPossibleGridAction('removable', true) === false) {
        //     return Promise.resolve();
        // }

        this._gridView.commit();

        // 삭제할 아이템 인덱스리스트를 로우아이디, cancel여부외 필요한 데이터를 가지고있는 오브젝트로 매핑한다. 
        const beforeRemoveResults = rowIndexes.map(rowIndex => {
            const invokeResult = this.invokeEvent<Events.BeforeRemoveRowEventArgs>(
                this.onBeforeRemoveRow,
                new Events.BeforeRemoveRowEventArgs(this, rowIndex, this.getRow(rowIndex)));

            return {
                rowIndex: rowIndex,
                rowId: this._gridView.getDataRow(rowIndex),
                value: this.getRow(rowIndex),
                isCancel: invokeResult.isCancel,
                state: this.getState(rowIndex)
            }
        });

        this._ignoreManagedCellChangeEvent = true;

        // 이벤트에서 cancle되지 않은 건들 소프트 삭제처리
        this._realGridProvider.removeRows(
            beforeRemoveResults.filter(item => !item.isCancel)
                .map(item => item.rowId)
        );

        this._ignoreManagedCellChangeEvent = false;

        // 삭제된 아이템들을 _emptyDataRows에서 삭제한다.
        const originalEmptyDataRows = [...this._emptyDataRows];

        beforeRemoveResults.filter(item1 => item1.isCancel === false).forEach(item2 => {
            if (this._emptyDataRows.some(item3 => item3 === item2.rowId)) {
                this.setEmptyDataRows(this._emptyDataRows.filter(item4 => item4 !== item2.rowId))
            }
        });

        // storeData 처리이후 이벤트 발행
        return this.storeData({ removeRowsMode: true }).then(() => {
            beforeRemoveResults.filter(i => i.isCancel === false).forEach(i => {
                this.invokeEvent<Events.AfterRemoveRowEventArgs>(
                    this.onAfterRemoveRow,
                    new Events.AfterRemoveRowEventArgs(this, i.rowIndex, i.value));

                this.invokeEvent<Events.AfterDataChangeEventArgs>(
                    this.onAfterDataChanged,
                    new Events.AfterDataChangeEventArgs(this, [], Events.GridDataChangeAction.ROW_DELETED));
            });

            //만약 appendable인데 빈 로우가 없을경우 새로운행을 추가한다.
            if (this.checkPossibleGridAction('appendable', false) && this.getRowCount() === 0) {
                this.processAddRow();
            }

            this.processAfterCellChange(this.getSelectedIndex(), this.getSelectedColumnName(), true);

            this.invokeEvent<Events.DrawerEventArgs>(
                this.onDrawer,
                new Events.DrawerEventArgs(this, this.getCheckedRows()));

            this.onChangeEmptySetType(this.getRowCount() <= 0 ? EmptySetType.noData : EmptySetType.hasData);

        }).catch((e) => {
            console.error(e);

            // 저장중 에러발생시 롤백
            this.setEmptyDataRows(originalEmptyDataRows);

            beforeRemoveResults.forEach((item) => {
                if (item.isCancel === false) {
                    this._realGridProvider.insertRow(item.rowId, item.value);
                    this.setState(item.rowIndex, item.state ? item.state : GridState.none)
                }
            })
        })
    }

    /**
     * @internal
     * @deprecated 
     * getFocusedIndex 폐기 예정인 API입니다. getSelectedIndex를 대신 사용해주세요
     */
    public getFocusedIndex(): number {
        console.warn('getFocusedIndex 폐기 예정인 API입니다. getSelectedIndex를 대신 사용해주세요');

        return this._gridView.getCurrent().itemIndex;
    }

    /**
     * 선택된 로우의 인덱스를 가져온다.
     */
    public getSelectedIndex(): number {
        return this._gridView.getCurrent().itemIndex;
    }

    /**
     * @internal
     * @deprecated
     * getFocusedColumnName 폐기 예정인 API입니다. getSelectedColumnName를 대신 사용해주세요
     */
    public getFocusedColumnName(): string {
        console.warn('getFocusedColumnName 폐기 예정인 API입니다. getSelectedColumnName를 대신 사용해주세요');

        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.getFocusedColumnName: 그리드가 준비되지 않았습니다.');
        }

        return this._gridView.getCurrent().column;
    }

    /**
     * '선택된 컬럼의 이름을 가져온다.'
     */
    public getSelectedColumnName(): string {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.getFocusedIndex: 그리드가 준비되지 않았습니다.');
        }

        return this._gridView.getCurrent().column;
    }

    /**
     * @internal
     * @deprecated
     * getFocusedRow 폐기 예정인 API입니다. getSelectedRow를 대신 사용해주세요
     */
    public getFocusedRow(): any {
        console.warn('getFocusedRow 폐기 예정인 API입니다. getSelectedRow를 대신 사용해주세요');

        if (this.isReady === false) {
            throw new Error('OBTDataGridInterface.getFocusedRow: 그리드가 준비되지 않았습니다.');
        }

        return this.getRow(this._gridView.getCurrent().itemIndex);
    }

    /**
     * 선택되어있는 행의 데이터를 가져온다.
     */
    public getSelectedRow(): any {
        if (this.isReady === false) {
            throw new Error('OBTDataGridInterface.getSelectedRow: 그리드가 준비되지 않았습니다.');
        }

        return this.getRow(this._gridView.getCurrent().itemIndex);
    }

    /**
     * 특정 셀 선택.
     * 포커스까지 옮기지는 않는다.
     * @param rowIndex 
     * @param columnNameOrFieldIndex 
     */
    public setSelection(rowIndex: number, columnNameOrFieldIndex?: string | number): void {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.setSelection: 그리드가 준비되지 않았습니다.');
        }

        // managed event는 리얼그리드 이벤트 콜백에서 처리함
        let setCurrentOption: any = {
            itemIndex: rowIndex,
            column: columnNameOrFieldIndex
        }

        if (columnNameOrFieldIndex === undefined) {
            setCurrentOption = {
                itemIndex: rowIndex,
            }
        } else if (typeof columnNameOrFieldIndex === 'number') {
            setCurrentOption = {
                itemIndex: rowIndex,
                fieldIndex: columnNameOrFieldIndex
            }
        }
        this._gridView.setCurrent(setCurrentOption);
    }

    /**
     * 현재 선택된 데이터
     * @returns { rowIndex: 선택되어있는 인덱스, columnName: 선택되어있는 컬럼이름}
     */
    public getSelection(): ICell {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.getSelection: 그리드가 준비되지 않았습니다.');
        }

        return {
            rowIndex: this.getSelectedIndex(),
            columnName: this.getSelectedColumnName()
        }
    }

    /**
     * 그리드뷰에 포커스를 준다.
     */
    public focus(): void {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.focus: 그리드가 준비되지 않았습니다.');
        }

        this.gridView.setFocus();
    }

    /**
     * 현재 페이징 정보 조회
     */
    public getPagingInfo(): Pagination | undefined {
        return this.pagingInfo;
    }

    /**
     * 데이터를 읽어 그리드에 바인딩한다.
     * @param readCallback 
     */
    public readData(readCallback?: Read): Promise<any[]> {
        if (!this.isReady) {
            console.error('OBTDataGridInterface.readData: 그리드가 준비되지 않았습니다.')
            return Promise.resolve([]);
        }

        this.commit();

        this.pagingInfo = undefined;
        this._lockCellSelectionChange = false;
        this._lockRowSelectionChange = false;

        this.gridImageLabelProcessor.reset();
        // 개인정보 암호화 조회 중지
        this.clearRetrievePrivacies();

        this._keyDownForSelectionChange = {
            keyDown: false,
            keyCode: null,
            oldRowIndex: null,
            oldColumnName: null,
            newRowIndex: null,
            newColumnName: null,
        }

        return new Promise<any[]>((resolve, reject) => {
            const readFunction = readCallback ?
                readCallback : this._provider ?
                    this._provider.read : null;

            if (!readFunction) {
                reject('OBTDataGridInterface.readData: errors occurred at onAfterRead event handler.');
                return;
            }

            const readCallItem = { key: uuid(), function: readFunction, done: false, canceled: false };
            this.readCallbackList.push(readCallItem);
            const currentCount = this.readCallbackList.length;

            const callReadFunctionAndBind = (readCallback: Read, callback: (value: any[]) => void) => {
                readCallback(new Events.ReadEventArgs(
                    this,
                    this.pagingInfo,
                )).then((data) => {
                    const hasNextJob = this.readCallbackList.length > currentCount;
                    if (hasNextJob) {
                        this.readCallbackList = this.readCallbackList.map((item) => {
                            if (item.key === readCallItem.key) {
                                return {
                                    ...item,
                                    canceled: true
                                }
                            }

                            return item;
                        })

                        return;
                    }

                    // 개인정보 암호화
                    // 원본 필드를 다른 필드명으로 저장하고 해당 필드에 값만 저장한다.
                    if (data && data.length > 0 && this.columns) {
                        const changeSource = this.columns
                            .filter(column => column.usePrivacy === true)
                            .map(column => ({
                                origin: OBTDataGridInterface.getOriginFieldName(column),
                                field: OBTDataGridInterface.getFieldName(column)
                            }));
                        data.forEach(row => {
                            changeSource.forEach(change => {
                                const fieldValue = row[change.field];
                                const privacy = new Privacy(fieldValue);
                                row[change.origin] = fieldValue;
                                row[change.field] = privacy.privacyValue ? privacy.privacyValue.replace(/[*]/g, '0') : privacy.privacyValue;
                            });
                        });
                    }

                    this.setEmptyDataRows([]);

                    this._ignoreManagedCellChangeEvent = true;

                    this._realGridProvider.clearSavePoints();

                    this.gridView.checkAll(false, false, false, false);

                    if (this._gridOption.gridType === GridType.treeView) {
                        let json = {
                            [this.gridOption.treeViewRowsKey!]: data
                        }

                        this._realGridProvider.fillJsonData(json, { rows: this.gridOption.treeViewRowsKey });
                    } else {
                        // 기존 데이터행들을 모두 삭제하고 매개변수로 넘어온 행들을 추가한다. 
                        // 개별 행 추가 이벤트는 발생하지 않고 onRowCountChanged 콜백만 호출된다.
                        this._realGridProvider.setRows(data || [], 0, -1);
                    }

                    this._gridView.refresh();

                    if (data) {
                        callback(data);
                    }

                    // 더이상 데이터가 없으면 마지막 페이지로 본다.
                    if ((data || []).length === 0) {
                        if (this.pagingInfo) {
                            this.pagingInfo.markPreventRead = true;
                        }
                    }

                    this.processAfterRead(data);

                    this._ignoreManagedCellChangeEvent = false;

                }).catch((e) => {
                    throw e;
                }).finally(() => {
                    this.readCallbackList = this.readCallbackList.map((item) => {
                        if (item.key === readCallItem.key && item.canceled === false) {
                            return {
                                ...item,
                                done: true,
                            }
                        }

                        return item;
                    })
                })
            }

            if (this.gridOption.paging === true) {
                this.makePagination().then((pagingInfo) => {
                    this.pagingInfo = pagingInfo;

                    let totalPageLength = -1;
                    if (pagingInfo.totalCount) {
                        totalPageLength = Pagination.calculateTotalPageLength(
                            pagingInfo.totalCount,
                            this.gridOption.rowCountPerPage!,
                            this.gridOption.initialRowCount ? this.gridOption.initialRowCount : this.gridOption.rowCountPerPage!,
                        );
                    }

                    // 푸터를 구한다.
                    if (this._provider!.readFooter) {
                        this._provider!.readFooter(new Events.ReadFooterEventsArgs(
                            this,
                            this.pagingInfo.pagingDirection === 'scrollToBottom' ?
                                this.pagingInfo!.currentPage + 1 : 0,
                            this.pagingInfo.pagingDirection === 'scrollToBottom' ?
                                totalPageLength : this.pagingInfo!.currentPage - 1,
                            this.pagingInfo!.pagingDirection === 'scrollToBottom' ?
                                (this.pagingInfo!.currentPage + 1) * this.pagingInfo!.rowCountPerPage : 0,
                            this.pagingInfo!.getLimitCount(),
                        )).then((footerData) => {
                            if (footerData) {
                                this.pagingInfo!.unreadedFooterData = footerData;
                            }
                            this.refresh();
                        });
                    }

                    callReadFunctionAndBind(readFunction, resolve);
                })
            } else {
                callReadFunctionAndBind(readFunction, resolve);
            }
        }).then((data) => {
            // processAfterRead 함수 내에서 이동 
            // onAfterRead 함수내에서 addRow등 이벤트 호출시 행변경 이벤트 발생안함
            this._invokeOnAfterRead(data);

            return data;
        })
    }

    /**
     * rowIndex에 체크펜등 내부적인 셀 스타일을 적용한다.
     * @param rowIndex 
     */
    public applyInternalCellStyle(rowIndex: number, options?: {
        columns?: IColumn[],
        selectedRow?: boolean,
        applyNothingStyle?: boolean,
    }) {
        const selectedRow = options && options.selectedRow !== undefined ? options.selectedRow : true;
        const columns = options && options.columns !== undefined ? options.columns : this._getFlatColumns(this.columns!, true);

        if (columns && columns.length > 0) {
            const dataRow = this.gridView.getDataRow(rowIndex);
            const updates = columns.map(column => {
                const cellStyleType = this.getCellStyleType(rowIndex, column, selectedRow);

                if (this.gridOption.useCheckPen === false) {
                    cellStyleType.checkPen.has = false;
                }

                const cellStyle = this.makeCellStyleObject(cellStyleType);
                this.addCellStyle(cellStyle.id, cellStyle.style);

                if (this.gridView.getCellStyle(dataRow, column.name) !== cellStyle.id) {
                    return {
                        id: cellStyle.id,
                        column: column.name
                    }
                }

                return undefined;
            }).filter(item => item);

            (updates || []).filter((update, index, self) => self.indexOf(update) === index).map(update => (update || {}).id)
                .forEach(id => {
                    if (options && options.applyNothingStyle === true && id === '') {

                    } else {
                        const targetColumns = updates.filter(update => (update || {}).id === id).map(update => (update || {}).column);
                        this.gridView.setCellStyles([dataRow], targetColumns, id, true);
                    }
                });
        }
    }

    /**
     * @internal
     */
    public makePagination(): Promise<Pagination> {
        const pagingDirection = this._gridOption.appendable === true
            ? "scrollToTop" : "scrollToBottom";

        return new Promise<Pagination>((resolve) => {
            let pagingInfo: Pagination;
            if (this._provider!.readTotalCount) {
                this._provider!.readTotalCount(new Events.ReadTotalCountEventsArgs(this)).then((response) => {
                    pagingInfo = new Pagination(
                        pagingDirection,
                        response,
                        this._gridOption.rowCountPerPage!,
                        this._gridOption.initialRowCount!,
                        false
                    );
                    resolve(pagingInfo)
                });
            } else {
                if (pagingDirection === 'scrollToTop') {
                    throw Error('scrollToTop pagingDirection must implement readTotalCount');
                }

                pagingInfo = new Pagination(
                    pagingDirection,
                    undefined,
                    this._gridOption.rowCountPerPage!,
                    this._gridOption.initialRowCount!,
                    false,
                );

                resolve(pagingInfo);
            }
        })
    }

    /**
     * @internal
     * 데이터를 읽은 이후(데이터 바인딩까지 완료)의 프로세스
     */
    private processAfterRead(data: any[] | null | undefined) {
        if (this.checkPossibleGridAction('appendable', false) && this.gridOption.appendable === true && this.gridOption.gridType !== GridType.treeView) {
            this.processAddRow();

            this.setSelection(this.getRowCount() - 1, 0);
        } else {
            if (!data || data.length === 0) {
                this.invokeEvent<Events.AfterSelectChangeEventArgs>(
                    this.onAfterChangeRowSelection,
                    new Events.AfterSelectChangeEventArgs(this, -1, null, true, true, false));

                this.invokeEvent<Events.AfterDataChangeEventArgs>(
                    this.onAfterDataChanged,
                    new Events.AfterDataChangeEventArgs(this, [], Events.GridDataChangeAction.SELECTION_CHANGED));
            } else {
                this.setSelection(0, 0);
            }
        }

        this.invokeEvent<Events.AfterSelectChangeEventArgs>(
            this.onAfterChangeRowSelection,
            new Events.AfterSelectChangeEventArgs(this,
                this.getSelectedIndex(),
                this.getSelectedColumnName(),
                true,
                true,
                this.isGroupItem(this.getSelectedIndex())
            ));

        this.invokeEvent<Events.AfterSelectChangeEventArgs>(
            this.onAfterSelectChange,
            new Events.AfterSelectChangeEventArgs(
                this,
                this.getSelectedIndex(),
                this.getSelectedColumnName(),
                true,
                true,
                this.isGroupItem(this.getSelectedIndex())
            ));


        this.invokeEvent<Events.DrawerEventArgs>(
            this.onDrawer,
            new Events.DrawerEventArgs(this, []));

        // 데이터 상태에 따라 그리드 바디영역 엠프티셋 이미지를 변경
        this.onChangeEmptySetType(this.getRowCount() > 0 ? EmptySetType.hasData : EmptySetType.noData);

        if (this.gridOption.useInternalStyle === true) {
            //셀스타일
            if (data) {
                data.forEach((item, index) => {
                    this.applyInternalCellStyle(index, {
                        selectedRow: false,
                        applyNothingStyle: true
                    });
                })
            }

            // 선택된 행 스타일 적용
            this.applyInternalCellStyle(this.getSelectedIndex(), {
                selectedRow: true,
            });
        }

        this.refresh();

        this.retrievePrivacies();
    }

    public refreshCellStyle() {
        if (this.gridOption.useInternalStyle !== true) {
            return;
        }
        const data = this.getRows();

        //셀스타일
        if (data) {
            data.forEach((item, index) => {
                this.applyInternalCellStyle(index, {
                    selectedRow: false,
                    applyNothingStyle: true
                });
            })
        }

        // 선택된 행 스타일 적용
        this.applyInternalCellStyle(this.getSelectedIndex(), {
            selectedRow: true,
        });
    }

    /**
     * @internal
     * 데이터를 읽어 그리드에 바인딩한다.
     * @param readCallback 
     */
    private appendPagingData(readCallback?: Read): Promise<any[]> {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.appendPagingData: 그리드가 준비되지 않았습니다.');
        }

        return new Promise<any[]>((resolve, reject) => {
            const readFunction = readCallback ?
                readCallback : this._provider ?
                    this._provider.read : null;

            if (!readFunction) {
                reject('OBTDataGridInterface.appendPagingData: errors occurred at onAfterRead event handler.');
                return;
            }

            if (!this.pagingInfo) {
                return;
            }

            // 비동기 푸터바인딩
            if (this.gridOption.paging === true && this._provider!.readFooter) {
                let totalPageLength = -1;
                if (this.pagingInfo!.totalCount) {
                    totalPageLength = Pagination.calculateTotalPageLength(
                        this.pagingInfo!.totalCount,
                        this.gridOption.rowCountPerPage!,
                        this.gridOption.initialRowCount ? this.gridOption.initialRowCount : this.gridOption.rowCountPerPage!,
                    );
                }

                this._provider!.readFooter(new Events.ReadFooterEventsArgs(
                    this,
                    this.pagingInfo.pagingDirection === 'scrollToBottom' ?
                        this.pagingInfo!.currentPage + 1 : 0,
                    this.pagingInfo.pagingDirection === 'scrollToBottom' ?
                        totalPageLength : this.pagingInfo!.currentPage - 1,
                    this.pagingInfo!.pagingDirection === 'scrollToBottom' ?
                        (this.pagingInfo!.currentPage + 1) * this.pagingInfo!.rowCountPerPage : 0,
                    this.pagingInfo!.getLimitCount(),
                )).then((footerData) => {
                    if (footerData) {
                        // 여기서 세팅한 unreadedFooterData을 footer의 콜백이 읽는다.
                        this.pagingInfo!.unreadedFooterData = footerData;
                    }

                    this.refresh();
                });
            }

            readFunction(new Events.ReadEventArgs(
                this,
                this.pagingInfo,
            )).then((data) => {
                if (data && this.pagingInfo) {
                    if (this.pagingInfo.pagingDirection === 'scrollToTop') {
                        // 데이터가 추가되면 dataRow값도 변경된다. 
                        let emptyRowIndexes
                            = this._emptyDataRows.map(dataRow => this._gridView.getItemIndex(dataRow));

                        this.realGridProvider.insertRows(0, data, 0, -1, false, data.length);

                        // emptyDataRows 재설정
                        if (emptyRowIndexes.length > 0) {
                            this._emptyDataRows = [];
                            emptyRowIndexes.forEach(rowIndex => {
                                this.setEmptyDataRows(this._emptyDataRows.concat(this._gridView.getDataRow(rowIndex + data.length)))
                            })
                        }

                        for (let i = 0; i < data.length; i++) {
                            this.setState(i, GridState.none);
                        }
                        this._gridView.setCurrent({ itemIndex: data.length });

                    } else {
                        const beforeRowCount = this.getRowCount();
                        this.realGridProvider.fillJsonData(data, { fillMode: "append" });

                        for (let i = beforeRowCount; i < beforeRowCount + data.length; i++) {
                            this.setState(i, GridState.none);
                        }
                    }

                    resolve(data)
                }
            }).catch((e) => {
                throw e;
            })
        })
    }

    /**
     * 특정 컬럼을 보여준다. visible이 false로 지정된 컬럼에 대해서만 영향이 있다.
     * 보여지지 않는다고해서 required, unique의 유효성 체크가 무시되는것은 아님
     * @param columnName 
     */
    public showColumn(columnName: string) {
        this.setColumnVisible(columnName, true);
    }

    /**
     * 특정 컬럼을 숨간다. 
     * 보여지지 않는다고해서 required, unique의 유효성 체크가 무시되는것은 아님
     * @param columnName 
     */
    public hideColumn(columnName: string) {
        this.setColumnVisible(columnName, false);
    }

    /**
     * 특정 컬럼 보여지는지 여부 지정.
     * 보여지지 않는다고해서 required, unique의 유효성 체크가 무시되는것은 아님
     * 
     * @param columnName 
     * @param isVisible 
     */
    public setColumnVisible(columnName: string, isVisible: boolean): void {
        const column = this.getColumnByName(columnName);
        if (!column) {
            throw new Error("컬럼을 찾을수 없습니다. : " + columnName);
        }

        column.visible = isVisible;
        this.gridView.setColumnProperty(columnName, 'visible', isVisible);
    }

    /**
     * @internal
     * @param columnName 
     * @param property 리얼그리드의 property를 의미함
     * @param value 
     */
    public setColumnProperty(columnName: string, property: string, value: any) {
        const targetColumn = this.getColumnByName(columnName);
        if (!targetColumn) {
            throw new Error("컬럼을 찾을수 없습니다. : " + columnName);
        }

        if (property === 'visible') {
            targetColumn.visible = value;
        } else {
            if (property in targetColumn) {
                targetColumn[property] = value;
            }
        }

        this._gridView.setColumnProperty(columnName, property, value);
    }

    /**
     * @internal
     * @returns 현재 페이지 넘버
     */
    public getCurrentPageNumber(): number {
        if (!this.pagingInfo) {
            throw new Error('OBTDataGridInterface.getCurrentPageNumber: 페이징 모드가 아닙니다.');
        }
        return this.pagingInfo.currentPage;
    }

    /**
     * 특정 컬럼의 데이터에 대한 집계값을 리턴한다.
     * @param columnName 컬럼이름
     * @param expression 지정되어있는 표현식중 하나
     */
    public getSummary(columnName: string, expression: ColumnSummaryExpression): number {
        return this.gridView.getSummary(columnName, expression);
    }

    public setLookups(lookups: any[]) {
        this.gridView.setLookups(lookups);
    }

    public fillLookupData(lookupKey: string, data: { rows: [] }) {
        this.gridView.fillLookupData(lookupKey, data);
    }

    /**
     * @internal
     * rowIndex에 checkPenRGB 세팅하고 저장
     */
    public storeCheckPen(rowIndex: number, checkPenRGB: string) {
        const hasCheckPenColumn = this.getFlatColumns(false).some(item => item.name === this.gridOption.reservedColumnNames!.checkPen);
        if (hasCheckPenColumn) {
            const beforeState = this.getState(rowIndex);
            if (beforeState) {
                this._gridView.setValue(rowIndex, this.gridOption.reservedColumnNames!.checkPen, checkPenRGB);
                if (this._provider && this._provider.storeCheckPen) {
                    const dataItem = this.getRow(rowIndex);
                    return this._provider.storeCheckPen(
                        new Events.StoreMemoCheckPenEventArgs(this,
                            rowIndex,
                            dataItem,
                            null,
                            hasCheckPenColumn ? dataItem[this.gridOption.reservedColumnNames!.checkPen] : null
                        )
                    ).then(() => {
                        this.setState(rowIndex, beforeState);
                    });
                } else {
                    if (this.gridOption.editable !== true) {
                        throw new Error('OBTDataGridInterface.storeData: storeCheckPen가 설정되지 않았습니다.')
                    }
                    return this.storeData({ rowIndex: rowIndex });
                }
            }
        }

        return Promise.resolve();
    }

    /**
     * @internal
     * rowIndex에 memoCode 세팅하고 저장
     */
    public storeMemo(rowIndex: number, memoCode: string | null) {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.storeData: 그리드가 준비되지 않았습니다.');
        }

        if (!this.gridOption.reservedColumnNames) {
            return;
        }

        const hasMemoColumn = this.getFlatColumns(false).some(item => item.name === this.gridOption.reservedColumnNames!.memoCode);
        if (hasMemoColumn) {
            const beforeState = this.getState(rowIndex);
            if (beforeState) {
                this._gridView.setValue(rowIndex, this.gridOption.reservedColumnNames!.memoCode, memoCode);
                if (this._provider && this._provider.storeMemo) {
                    const dataItem = this.getRow(rowIndex);
                    return this._provider.storeMemo(
                        new Events.StoreMemoCheckPenEventArgs(this,
                            rowIndex,
                            dataItem,
                            hasMemoColumn ? dataItem[this.gridOption.reservedColumnNames!.memoCode] : null,
                            null,
                        )
                    ).then(() => {
                        this.setState(rowIndex, beforeState);
                    });
                } else {
                    if (this.gridOption.editable !== true) {
                        throw new Error('OBTDataGridInterface.storeData: storeMemo가 설정되지 않았습니다.')
                    }
                    return this.storeData({ rowIndex: rowIndex });
                }
            }
        }

        return Promise.resolve();
    }

    /**
     * 더티 데이터를 추려내 존재한다면 provider의 store를 호출한다.
     * 삭제시엔 특정로우 지정해서 처리하지 말것
     * @param rowIndex 
     * @param storeCallback 
     */
    public storeData(option?: { rowIndex?: number, store?: Store, removeRowsMode?: boolean }): Promise<void> { //rowIndex?: number, storeCallback?: Store, removeRowsMode?: boolean): Promise<any> {
        try {
            if (!this.isReady) {
                throw new Error('OBTDataGridInterface.storeData: 그리드가 준비되지 않았습니다.');
            }

            // TODO: false에서 true로 변경
            // commit관련 alert창을 막는 이유에서 변경함
            this._gridView.commit(true);

            this._ignoreManagedCellChangeEvent = true;

            let storeFunction = option && option.store ? option.store : this._provider && this._provider.store ?
                this._provider.store : null;

            if (!storeFunction) {
                return new Promise<void>((resolve, reject) => {
                    this._ignoreManagedCellChangeEvent = false;
                    resolve();
                });
            }

            return new Promise<void>((resolve, reject) => {
                if (!storeFunction) {
                    reject('OBTDataGridInterface.storeData: storeCallback or dataProvider.store is required.');
                    return;
                }

                const indexes: Events.IStoreIndexes = {
                    added: [],
                    modified: []
                };
                const data: Events.IStoreData = {
                    added: [],
                    modified: [],
                    deleted: []
                };

                // 개인정보 암호화
                // 원본 필드의 값을 다시 옮긴다.
                const privacyColumns = this.getFlatColumns(false).filter(column => column.usePrivacy);
                const setPrivacyFieldData = (data: any) => {
                    if (privacyColumns && privacyColumns.length > 0) {
                        privacyColumns.forEach(column => {
                            const fieldName = OBTDataGridInterface.getFieldName(column);
                            const originFieldName = OBTDataGridInterface.getOriginFieldName(column);
                            data[fieldName] = data[originFieldName];
                        })
                    }
                    return data;
                }

                if (option && option.rowIndex !== undefined) {
                    // 하나의 행에 대한 처리
                    const state = this.getState(option.rowIndex);
                    if (state) {
                        if (indexes[state]) {
                            indexes[state].push(option.rowIndex);
                        }

                        if (data[state]) {
                            data[state].push(setPrivacyFieldData(this.getValues(option.rowIndex)));
                        }
                    }
                } else {
                    // 전체 행에 대한 처리
                    const statedRows: any = this._getAllStateRows();
                    indexes.added = (statedRows.created || []).map(index => this._gridView.getItemIndex(index));
                    indexes.modified = (statedRows.updated || []).map(index => this._gridView.getItemIndex(index));

                    const getJsonRow = (index: number) => {
                        return setPrivacyFieldData(this._realGridProvider.getJsonRow(index));
                    }

                    data.added = (statedRows.created || []).map(index => getJsonRow(index));
                    data.modified = (statedRows.updated || []).map(index => getJsonRow(index));
                    data.deleted = (statedRows.deleted || []).map(index => getJsonRow(index));

                    // statedDataRows.added = statedRows.created;
                    // statedDataRows.modified = statedRows.updated;
                    // statedDataRows.deleted = statedRows.deleted;
                }

                if (option && option.removeRowsMode === true) {
                    indexes.added = [];
                    data.added = [];
                    // statedDataRows.added = [];
                }

                if (data.added.length > 0 || data.modified.length > 0 || data.deleted.length > 0) {
                    const storeEventArgs = new Events.StoreEventArgs(this, indexes, data);
                    storeFunction(storeEventArgs).then(() => {
                        if (storeEventArgs.cancel === true) {
                            return;
                        }

                        this._realGridProvider.clearSavePoints();

                        if (storeEventArgs.changeState) {
                            if (option && option.rowIndex !== undefined) {
                                this.setState(option.rowIndex, GridState.none);
                            } else {
                                const emptyRowsBackup = this._emptyDataRows.map(dataRow =>
                                    ({
                                        rowIndex: this._gridView.getItemIndex(dataRow),
                                        values: this._gridView.getValues(this._gridView.getItemIndex(dataRow))
                                    })).sort((a, b) => a.rowIndex - b.rowIndex);

                                this._realGridProvider.removeRows(this._emptyDataRows);
                                this._realGridProvider.clearRowStates(true, false);

                                emptyRowsBackup.forEach(item => {
                                    let rowId = this._gridView.getDataRow(item.rowIndex);
                                    delete item.values['__rowId'];
                                    if (rowId < 0) {
                                        this._realGridProvider.addRow(item.values);
                                    } else {
                                        this._realGridProvider.insertRow(rowId, item.values);
                                    }
                                });

                                this.setEmptyDataRows((this._realGridProvider.getAllStateRows() || {}).created || [])
                                // this._emptyDataRows = (this._realGridProvider.getAllStateRows() || {}).created || [];
                            }
                        }

                        this._gridView.refresh();

                        setTimeout(() => {
                            this._ignoreManagedCellChangeEvent = false;
                            resolve();
                        }, 0);
                    }).catch((e) => {
                        this._ignoreManagedCellChangeEvent = false;
                        reject(e)
                    });
                } else {
                    this._ignoreManagedCellChangeEvent = false;
                    resolve();
                }
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * @internal
     * @param emptyDataRows 
     */
    private setEmptyDataRows(emptyDataRows: number[]) {
        this._emptyDataRows = emptyDataRows;
    }

    /**
     * 그리드 클리어
     */
    public clearData(): void {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.clearData: 그리드가 준비되지 않았습니다.');
        }

        this.pagingInfo = undefined;

        this.gridImageLabelProcessor.reset();
        this.setEmptyDataRows([]);

        this._realGridProvider.clearSavePoints();
        this._realGridProvider.clearRows();

        this._gridView.refresh();

        this.invokeEvent<Events.AfterSelectChangeEventArgs>(
            this.onAfterSelectChange,
            new Events.AfterSelectChangeEventArgs(this, -1, null, true, false, false));

        this.invokeEvent<Events.AfterSelectChangeEventArgs>(
            this.onAfterChangeRowSelection,
            new Events.AfterSelectChangeEventArgs(this, -1, null, true, false, false));

        this.invokeEvent<Events.AfterDataChangeEventArgs>(
            this.onAfterDataChanged,
            new Events.AfterDataChangeEventArgs(this, [], Events.GridDataChangeAction.DATA_CLEARED));

        this.invokeEvent<Events.DrawerEventArgs>(
            this.onDrawer,
            new Events.DrawerEventArgs(this, [])
        );

        this.onChangeEmptySetType(EmptySetType.beforeSearch);
    }

    public setColumnHeaderCheck(columnName: string, checked: boolean) {
        var checkColumn = this.gridView.columnByName(columnName);
        checkColumn.checked = checked;
        this.gridView.setColumn(checkColumn)
    }

    /**
     * 
     * @param refresh RealGrid refresh 호출 여부 default true
     */
    public refresh(refresh: boolean = true): void {
        setTimeout(() => {
            if (this._gridView) {
                this._gridView.resetSize();
                if (refresh) {
                    this._gridView.refresh();
                }
            }
        }, 0);
    }

    /**
     * 엑셀 익스포트
     * @link http://help.realgrid.com/api/types/GridExportOptions/
     * @param exportOption 리얼그리드의 GridView.exportGrid 함수의 옵션
     */
    public exportExcel(exportOption?: any) {
        if (this.checkPossibleGridAction('printable', true) === false) {
            return;
        }

        try {
            this._gridView.setStyles(GridStyleForExcel.styles);

            const defaultExportOption = {
                type: "excel",
                target: "local",
                fileName: this.id + '_excel_' + new Date().toISOString() + '.xlsx',
                indicator: 'default', // 'visible' | 'hidden'
                header: 'default', // 'visible' | 'hidden'
                footer: 'default', // 'visible' | 'hidden'
                applyFitStyle: true,
            };

            this.gridView.exportGrid({ ...defaultExportOption, ...exportOption });
        } catch (e) {
            throw e;
        } finally {
            this._gridView.setStyles(GridStyle.styles);
        }
    }

    /**
     * @deprecated searchCell 함수를 이용해 주세요.
     */
    public searchData(startIndex: number, columnNamesForSearch: string[] | null, value: string, selectSearchedCell: boolean, partialMatch: boolean): ICell | null {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.searchData: 그리드가 준비되지 않았습니다.');
        }

        var options = {
            fields: columnNamesForSearch,
            value: value,
            select: selectSearchedCell,
            startItemIndex: startIndex,
            partialMatch: partialMatch,
        }

        var cell = this.gridView.searchCell(options);
        if (!cell) {
            return null;
        }

        return {
            rowIndex: cell.itemIndex,
            columnName: cell.fieldName
        };
    }

    /**
     * 데이터 검색
     * @param startIndex 검색시 시작 인덱스
     * @param columnNamesForSearch 
     * @param value 
     * @param partialMatch 
     */
    public searchCell(option: {
        startIndex: number,
        startFieldIndex: number,
        columnNames: string[] | null,
        value: string,
        selectSearchedCell: boolean,
        partialMatch: boolean,
    }): ICell | null {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.searchData: 그리드가 준비되지 않았습니다.');
        }

        var options = {
            fields: option.columnNames,
            startFieldIndex: option.startFieldIndex,
            value: option.value,
            select: option.selectSearchedCell,
            startItemIndex: option.startIndex,
            partialMatch: option.partialMatch,
        }

        var cell = this.gridView.searchCell(options);
        if (!cell) {
            return null;
        }

        return {
            rowIndex: cell.itemIndex,
            columnName: cell.fieldName
        };
    }

    /**
     * TreeGrid 전용
     * 접혀있는 자식노드를 모두 연다.
     */
    public expandAll(): void {
        if (!this.isReady) {
            throw new Error('OBTDataGridInterface.expandAll: 그리드가 준비되지 않았습니다.');
        }

        if (this._gridOption.gridType === GridType.treeView) {
            this.gridView.expandAll();
        }
    }

    /**
     * TreeGrid 전용 
     * @param rowIndex 행 인덱스
     * @param recursive 하위의 모든 자식들을 열것인지 여부
     * @param force 
     */
    public expand(rowIndex: number, recursive?: boolean, force?: boolean): void {
        if (this._gridOption.gridType !== GridType.treeView) {
            return;
        }

        this.gridView.expand(rowIndex, recursive, force);
    }

    /**
     * TreeGrid 전용 
     * @param rowIndex 행 인덱스
     * @param recursive 하위의 모든 자식들을 접을것인지 여부
     */
    public collapse(rowIndex: number, recursive?: boolean): void {
        if (this._gridOption.gridType !== GridType.treeView) {
            return;
        }

        this.gridView.collapse(rowIndex, recursive);
    }

    /**
     * TreeGrid 전용 
     * 모든 자식노드들을 접는다.
     */
    public collapseAll(): void {
        if (this._gridOption.gridType !== GridType.treeView) {
            return;
        }

        this.gridView.collapseAll();
    }

    /**
     * 해당 인덱스의 행이 자식을 가지고 있는지 여부리턴
     * @param rowIndex 행 인덱스
     * @returns 자식을 가지고 있는지 여부
     */
    public hasChildren(rowIndex: number): boolean {
        if (this._gridOption.gridType !== GridType.treeView) {
            return false;
        }

        return this.gridView.getChildren(rowIndex).length > 0;
    }

    /**
     * TreeGrid 전용 
     * @param rowIndex 행 인덱스
     * @returns 자식 행의 모든 인덱스 
     */
    public getChildrenIndexes(rowIndex: number): number[] {
        if (this._gridOption.gridType !== GridType.treeView) {
            return [];
        }

        return this.gridView.getChildren(rowIndex);;
    }

    /**
     * TreeGrid 전용 
     * 부모의 인덱스를 가져온다.
     * 부모가 없다면 null;
     * @param rowIndex 
     * @returns 부모의 인덱스
     */
    public getParentIndex(rowIndex: number): number | null {
        if (this._gridOption.gridType !== GridType.treeView) {
            return null;
        }

        const parentIndex = this.gridView.getParent(rowIndex);
        if (parentIndex < 0) {
            return null;
        }

        return parentIndex;
    }

    /**
     * TreeGrid 전용 
     * 특정 인덱스의 자식행 체크여부 제어
     * @param rowIndex 
     * @param checked 
     * @param recusive 
     */
    public checkChildren(rowIndex: number, checked: boolean, recusive: boolean): void {
        if (this._gridOption.gridType !== GridType.treeView) {
            return;
        }

        this.gridView.checkChildren(rowIndex, checked, recusive, false, true, true);
    }

    /**
     *
     * @param wrapperHeightPx 
     */
    public getBodyAreaHeight(wrapperHeightPx: number): number {
        const topBorderWeight = 2;
        const bottomBorderWeight = 1;

        const othersHeight = (
            this.gridView!.getHeader().height +
            this.gridView!.getFooter().height +
            topBorderWeight + bottomBorderWeight
        );

        return wrapperHeightPx - othersHeight;
    }

    /**
     *
     * @param wrapperHeightPx 
     */
    public getHeaderHeight(): number {
        return this.gridView!.getHeader().height;
    }

    /**
     *
     * @param wrapperHeightPx 
     */
    public getFooterHeight(): number {
        return this.gridView!.getFooter().height;
    }

    /**
     * columnName의 헤더 텍스트를 가져온다.
     * @param columnName 
     */
    public getHeaderText(columnName: string): string {
        const column = this.getColumnByName(columnName);

        if (!column || !column.header) {
            return '';
        }

        if (column.header && typeof column.header === 'string') {
            return column.header;
        }

        if (column.header && typeof column.header !== 'string' && column.header.text) {
            return column.header.text;
        }

        return ''
    }

    /**
     * @internal
     * 관리되는 이벤트인 beforeChange 이벤트 핸들러 
     */
    private processBeforeChange(rowIndex: number, columnName: string, values: any): boolean {
        // 유니크 체크
        if (this.gridOption.uniqueColumns
            && this.gridOption.uniqueColumns.some(uniqueColumnName => columnName === uniqueColumnName)
            && this.checkUniqueRow(rowIndex, { ...this.getRow(rowIndex), [columnName]: values }, this.gridOption.uniqueColumns) === false) {
            const eventArgs = new Events.AlertEventArgs(this,
                Events.GridAlertReason.VALIDATION_FAIL,
                {
                    rowIndex: rowIndex,
                    invalidColumns: [{
                        columnName: columnName,
                        reason: GridValidationFailReason.DUPLICATED_ROW
                    }],
                    isValidated: false
                }
            );

            this.invokeEvent<Events.AlertEventArgs>(
                this.onAlert,
                eventArgs
            );

            return false;
        }

        // 사용자 이벤트 호출
        const result: boolean = this.onBeforeChange.events.find(event => {
            const e = new Events.BeforeChangeEventArgs(this, rowIndex, columnName, values, values);
            try {
                event(e);
            } catch (error) {
                console.error(error);
                return true;
            }
            return e.cancel;
        }) ? false : true;

        return result;
    }

    /**
     * @internal
     * 노출되는 이벤트인 validateChange 이벤트 핸들러
     */
    private _invokeValidateChange(rowIndex: number, columnName: string, oldValue: any, newValue: any, values: any, source: any): boolean {
        const result: boolean = this.onValidateChange.events.find(event => {
            const e = new Events.ValidateChangeEventArgs(this, rowIndex, columnName, oldValue, newValue, values, source);
            event(e);
            return e.cancel;
        }) ? false : true;

        return result;
    }

    /**
     * @internal
     */
    private _invokeAfterChange(rowIndex: number, columnName: string, oldValue: any, newValue: any, values: any, source: any): void {
        // 셀변경 막는 것 취소 
        this._lockRowSelectionChange = false;
        this._lockCellSelectionChange = false;

        // 개인정보 암호화
        // 개인정보 암호화 필드가 수정되었다면 원본 필드에 업데이트 함
        if (this.isUsePrivacy(columnName)) {
            this.setPrivacyModifiedValue(rowIndex, columnName, newValue);
        }

        this.applyInternalCellStyle(rowIndex);

        // onAfterChange 호출
        this.onAfterChange.events.forEach(event => {
            const e = new Events.AfterChangeEventArgs(this, rowIndex, columnName, oldValue, newValue, values, source);
            try {
                event(e);
            } catch (error) {
                console.error(error);
                throw error
            }
        });

        if (this.onAfterChangeAsync.events.length > 0) {
            this.lockCellChangeTemplate(() => {
                const promiseList = Promise.all(this.onAfterChangeAsync.events.map(event => {
                    const e = new Events.AfterChangeEventArgs(this, rowIndex, columnName, oldValue, newValue, values, source);
                    return event(e);
                })).then((result: void[]) => {
                    return;
                });

                return promiseList;
            }).then(() => {
                const cell = this.getNextActiveCell(rowIndex, columnName);
                if (cell && cell.columnName) {

                    this.setSelection(cell.rowIndex, cell.columnName);
                }
            })
        }

        //onAfterDataChanged 호출
        this.invokeEvent<Events.AfterDataChangeEventArgs>(
            this.onAfterDataChanged,
            new Events.AfterDataChangeEventArgs(this, [columnName], Events.GridDataChangeAction.DATA_CHANGED));
    }

    /**
     * @internal
     * 리얼그리드 이벤트핸들러 onRowInserted
     */
    private handleRowInserted(provider: any, dataIndex: number) {
        try {
            this.setEmptyDataRows(this._emptyDataRows.concat(this._gridView.getDataRow(this.getRowCount() - 1)))

            this.invokeEvent<Events.AfterAddRowEventArgs>(
                this.onAfterAddRow,
                new Events.AfterAddRowEventArgs(this, this._gridView.getItemIndex(dataIndex), this._realGridProvider.getJsonRow(dataIndex)));

            this.invokeEvent<Events.AfterDataChangeEventArgs>(
                this.onAfterDataChanged,
                new Events.AfterDataChangeEventArgs(this, [], Events.GridDataChangeAction.ROW_ADDED));
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * @internal
     */
    private handleRowInserting(grid: any, itemIndex: number) {
    }

    /**
     * @internal
     * 리얼그리드 이벤트핸들러 onItemChecked 
     */
    private handleItemChecked(grid: any, rowIndex: number, checked: boolean): void {
        try {
            const invokeResult = this.invokeEvent<Events.BeforeCheckEventArgs>(
                this.onBeforeCheck,
                new Events.BeforeCheckEventArgs(this, rowIndex, checked)
            );

            if (invokeResult.isCancel === false) {
                this.invokeEvent<Events.AfterCheckEventArgs>(
                    this.onAfterCheck,
                    new Events.AfterCheckEventArgs(this, rowIndex, checked)
                );

                this.invokeEvent<Events.DrawerEventArgs>(
                    this.onDrawer,
                    new Events.DrawerEventArgs(this, this.getCheckedRows())
                );

                this.invokeEvent<Events.AfterDataChangeEventArgs>(
                    this.onAfterDataChanged,
                    new Events.AfterDataChangeEventArgs(this, [], Events.GridDataChangeAction.CHECK_CHANGED));
            } else {
                // 취소 처리
                this._setCheck(rowIndex, !checked, false, false);
            }

            if (this._rowChangeAfterCheck === true) {
                this.setSelection(rowIndex);
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    private _rowChangeAfterCheck = true;

    public setRowChangeAfterCheck(value: boolean) {
        this._rowChangeAfterCheck = value;
    }

    /**
     * @internal
     * shift 클릭으로 여러개의 행을 한번에 체크할때 발생
     * @param grid 
     * @param rowIndexes 
     * @param checked 
     */
    private handleItemsChecked(grid: any, rowIndexes: number[], checked: boolean): void {
        try {
            rowIndexes.forEach((rowIndex) => {
                const invokeResult = this.invokeEvent<Events.BeforeCheckEventArgs>(
                    this.onBeforeCheck,
                    new Events.BeforeCheckEventArgs(this, rowIndex, checked)
                );

                if (invokeResult.isCancel === false) {
                    this.invokeEvent<Events.AfterCheckEventArgs>(
                        this.onAfterCheck,
                        new Events.AfterCheckEventArgs(this, rowIndex, checked)
                    );
                } else {
                    // 캔슬처리
                    this._setCheck(rowIndex, !checked, false, false);
                }
            })

            this.invokeEvent<Events.DrawerEventArgs>(
                this.onDrawer,
                new Events.DrawerEventArgs(this, this.getCheckedRows())
            );

            this.invokeEvent<Events.AfterDataChangeEventArgs>(
                this.onAfterDataChanged,
                new Events.AfterDataChangeEventArgs(this, [], Events.GridDataChangeAction.CHECK_CHANGED)
            );
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * @internal
     * onItemAllChecked 이벤트콜백
     * 헤더 체크바를 클릭해서 모든 아이템들의 체크상태가 변경되었을때 호출
     */
    private handleItemAllChecked(grid: any, checked: boolean): void {
        try {
            if (checked === true) {
                this._emptyDataRows.forEach(item => {
                    const emptyIndex = this.gridView.getItemIndex(item);
                    //this.setCheck(index, false, false);
                    this._setCheck(emptyIndex, false, false, false);
                });

                this.getRows().forEach((item, index) => {
                    if (this.gridView.isCheckable(index) === false) {
                        //this.setCheck(index, false, false);
                        this._setCheck(index, false, false, false);
                    }
                });
            }

            this.invokeEvent<Events.AfterHeaderCheckEventArgs>(
                this.onAfterHeaderCheck,
                new Events.AfterHeaderCheckEventArgs(this, checked)
            );

            this.invokeEvent<Events.DrawerEventArgs>(
                this.onDrawer,
                new Events.DrawerEventArgs(this, this.getCheckedRows())
            );

            this.invokeEvent<Events.AfterDataChangeEventArgs>(
                this.onAfterDataChanged,
                new Events.AfterDataChangeEventArgs(this, [], Events.GridDataChangeAction.CHECK_CHANGED));
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    private handleColumnCheckedChanged(grid: any, column: any, checked: boolean) {
        try {
            this.invokeEvent<Events.AfterColumnHeaderCheckEventArgs>(
                this.onAfterColumnHeaderCheck,
                new Events.AfterColumnHeaderCheckEventArgs(this, column.name, checked)
            );
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    private hasCodePicker(column: IColumn) {
        return column.type === ColumnType.codePicker || column.customCodePicker !== undefined
    }

    /**
     * # readonly컬럼 입력 불가 처리
     * # date type 컬럼 처리
     * # 체크타입컬럼 에디팅 불가 처리 (체크 컬럼에 텍스트가 자유롭게 입력되는것을 방지)
     * # 개인정보 암호화
     * # 개인
     * @internal
     * @param grid 
     * @param index 
     */
    private handleShowEditor(grid: any, index: any): boolean {
        try {
            if (this.isRetrievePrivacies()) return false;

            if (this._lockCellSelectionChange === true || this._lockRowSelectionChange === true) {
                return false;
            }

            const targetColumn = this.getColumnByName(index.column);
            if (!targetColumn) {
                throw new Error("컬럼을 찾을수 없습니다. : " + index.column);
            }

            if (targetColumn.type === ColumnType.check) {
                return false;
            }

            if (this.isColumnEditable(targetColumn, index.itemIndex) === false) {
                return false;
            }

            if (this.hasCodePicker(targetColumn)) {//(targetColumn.type === ColumnType.codePicker) {
                if (targetColumn.textReadOnly === true) {
                    return false;
                }

                if (targetColumn.codePickerEditType) {
                    if (targetColumn.codePickerEditType === CodePickerEditType.onlyDialog) {
                        return false;
                    }
                }
            }

            if (targetColumn.type === ColumnType.date) {
                if (this.onCallDatePickerDialog) {
                    this.onCallDatePickerDialog({
                        rowIndex: index.itemIndex,
                        columnName: targetColumn.name,
                        dateFormat: targetColumn.dateFormat ? targetColumn.dateFormat : DateFormat.yyyyMMdd,
                        value: this.getValue(index.itemIndex, targetColumn.name),
                        dateDialogAlign: targetColumn.dateDialogAlign,
                        dateDialogPosition: targetColumn.dateDialogPosition,
                        // 더블클릭으로 호출되면 다이얼로그로 포커스
                        focusOnDialog: this.invokedDblClicked
                    });
                    this.invokedDblClicked = false;
                }
            }

            if (targetColumn.type === ColumnType.autoComplete) {
                const displayWidth: string = this._gridView.columnByName(targetColumn.name).displayWidth + 'px';
                // 자동완성 컴포넌트 onShowEditor로 초반 세팅 
                if (this.onShowEditorAutoCompleteDialog) {
                    this.onShowEditorAutoCompleteDialog({
                        width: displayWidth,
                        rowIndex: index.itemIndex,
                        column: targetColumn,
                    })
                }
            }

            // 개인정보 암호화
            // 아직 개인정보를 가져오지 않은 항목을 에디팅 시도할 때 개인정보를 가져온 뒤 에디팅 시작함.
            if (this.isUsePrivacy(targetColumn.name, targetColumn) &&
                this.isPrivacyMasked(index.itemIndex, targetColumn.name, targetColumn)) {
                this.getPrivacyValueRead(index.itemIndex, targetColumn.name, targetColumn)
                    .then(() => this.gridView.showEditor());
                return false;
            }

            return true;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * 날짜 팝업 닫기처리
     * @param grid 
     * @param index 
     */
    private handleHideEditor(grid: any, index: any) {
        const targetColumn = this.getColumnByName(index.column);
        if (!targetColumn) {
            throw new Error("컬럼을 찾을수 없습니다. : " + index.column);
        }

        if (targetColumn.type === ColumnType.date) {
            if (this.onCloseDatePickerDialog) {
                this.onCloseDatePickerDialog()
            }
        }
    }

    /**
     * @internal
     * 붙여넣기 이벤트 핸들러
     * @param grid 
     * @param itemIndex 
     * @param dataRow 
     * @param fields 
     * @param oldValues 
     * @param newValues 
     */
    private handleEditRowPasted(grid, itemIndex, dataRow, fields, oldValues, newValues) {
        try {
            const targetColumn = this.getColumnByName(this.realGridProvider.getFieldName(fields[0]));
            if (!targetColumn) {
                throw new Error("OBTDataGridInterface.handleEditRowPasted: column can't find");
            }

            // 수정할 수 없는 셀은 이전 값으로 되돌리기
            if (this.isColumnEditable(targetColumn, itemIndex) === false) {
                this.setValueInternal(itemIndex, targetColumn.name, oldValues[0]);
                return;
            }

            this.handleEditCommit(this.gridView, {
                column: targetColumn.name,
                dataRow: dataRow,
                fieldIndex: fields[0],
                fieldName: "text",
                itemIndex: itemIndex,
            },
                oldValues[0],
                newValues[0]
            );

            this.handleCellEdited(
                grid,
                itemIndex,
                dataRow,
                fields[0]
            );

        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * @internal
     * 그리드 셀 수정
     * 리얼그리드 이벤트핸들러 onEditCommit
     * 코드피커 검색처리
     */
    private handleEditCommit(grid: any, index: any, oldValue: any, newValue: any) {
        if (!this.columns) {
            throw new Error("OBTDataGridInterface.handleEditCommit: columns can't be null");
        }

        try {
            const targetColumn = this.getColumnByName(index.column);
            if (!targetColumn) {
                throw new Error("OBTDataGridInterface.handleEditCommit: 컬럼을 찾을수 없습니다.");
            }

            this._currentEditingCellInfomaiton.columnName = targetColumn.name;
            this._currentEditingCellInfomaiton.rowIndex = index.itemIndex;
            this._currentEditingCellInfomaiton.stateBeforeEditing = this.getState(index.itemIndex)!;
            this._currentEditingCellInfomaiton.isCanceledOnEditCommit = false;

            if (targetColumn.ignoreEditing === true) {
                this._currentEditingCellInfomaiton.isCanceledOnEditCommit = true;
                this._keyDownEnterCellInfo = { isCommitCancel: true }
            }

            // 마스크 타입 유효성 체크
            if (targetColumn.type === ColumnType.mask && targetColumn.allowInvalidValue !== true) {
                const currentMaskType
                    = typeof targetColumn.maskType === 'function' ? targetColumn.maskType(index.itemIndex, this) : targetColumn.maskType;

                const maskInstance = currentMaskType ? this.getMaskInstance(currentMaskType) : null;
                if (maskInstance) {
                    const isValid = maskInstance.validator(newValue);

                    if (isValid === false) {
                        // TODO: 유효하지 않은 마스크 이전값으로 되돌리기

                        this.setValueUnmanaged(index.itemIndex, targetColumn.name, oldValue);
                        this._currentEditingCellInfomaiton.isCanceledOnEditCommit = true;
                        this._keyDownEnterCellInfo = { isCommitCancel: true }

                        return;
                    }
                }
            }

            // 코드피커에서의 editCommit은 검색의 의미라 BeforeChange, validateChange가 필요없다.
            if (!this.hasCodePicker(targetColumn)) {//(targetColumn.type !== ColumnType.codePicker) {
                const isValid: boolean
                    = this.processBeforeChange(index.itemIndex, targetColumn.name, newValue) &&
                    this._invokeValidateChange(
                        index.itemIndex,
                        targetColumn.name,
                        oldValue,
                        newValue,
                        this.getRow(index.itemIndex),
                        null);

                if (isValid === false) {
                    // TODO: setValueUnmanaged로 호출하지 않으면 onBeforeChange등의 이벤트에서 setValue를 호출할때 문제가 생긴다.
                    this.setValueUnmanaged(index.itemIndex, targetColumn.name, oldValue);
                    this._currentEditingCellInfomaiton.isCanceledOnEditCommit = true;
                    this._keyDownEnterCellInfo = { isCommitCancel: true }
                    return;
                }
            }

            if (targetColumn.type === ColumnType.date) {
                // 유효하지 않은 날짜는 이전 입력값으로
                if (newValue && newValue.length > 0 && this.isValidDate(newValue, targetColumn.dateFormat) === false) {
                    this.setValueUnmanaged(index.itemIndex, targetColumn.name, (oldValue || '').toString());
                    this._currentEditingCellInfomaiton.isCanceledOnEditCommit = true;
                    this._keyDownEnterCellInfo = { isCommitCancel: true }
                    return;
                }
            } else if (this.hasCodePicker(targetColumn)) {
                // BeforeCallCodePickerEvent Invoke
                const beforeCallCodePickerEventArgs = new Events.BeforeCallCodePickerEventArgs(this, index.itemIndex,
                    targetColumn.name, newValue
                )
                const invokeResult = this.invokeEvent<Events.BeforeCallCodePickerEventArgs>(
                    this.onBeforeCallCodePicker,
                    beforeCallCodePickerEventArgs
                );

                if (invokeResult.isCancel === true) {
                    const replace = beforeCallCodePickerEventArgs.replaceValue === null ? oldValue : beforeCallCodePickerEventArgs.replaceValue
                    this.setValueUnmanaged(index.itemIndex, targetColumn.name, replace);

                    return;
                }

                // TODO: codePickerEditType notAllowEditorSearch 옵션에 대한 명시가 되어있지 않음
                if (!targetColumn.codePickerEditType) {
                    if (!targetColumn.codePickerId && !targetColumn.customCodePicker) {
                        throw new Error('check codepicker id');
                    }

                    if (!this.onCallCodePickerSearch) {
                        throw new Error('check codepicker callback');
                    }

                    let codePickerParameters: any = {};
                    if (typeof targetColumn.parameters === 'object') {
                        codePickerParameters = targetColumn.parameters;
                    } else if (typeof targetColumn.parameters === 'function') {
                        codePickerParameters = targetColumn.parameters({
                            rowIndex: index.itemIndex,
                            columnName: targetColumn.name,
                            values: this.getRow(index.itemIndex),
                        });
                    }

                    const canMultiSelect = this.getColumnCanMultiSelect(targetColumn, index.itemIndex)
                    const eventArgs = new Events.GridCodePickerCellSearchEventArgs(
                        this,
                        targetColumn.codePickerId,
                        targetColumn.customCodePicker !== undefined,
                        targetColumn.customCodePicker,
                        targetColumn.name,
                        index.itemIndex,
                        codePickerParameters,
                        newValue,
                        oldValue,
                        canMultiSelect,
                        targetColumn.selectedItemDisplayCallBack,
                    );

                    //TODO: state변경처리
                    targetColumn.internalCodePickerState = "search";
                    this.onCallCodePickerSearch(eventArgs);
                    return;
                }
            }

            // 개인정보 암호화
            // 필드가 수정되면 원본 필드에 업데이트 함.
            if (this.isUsePrivacy(targetColumn.name, targetColumn)) {
                const privacy = this.getPrivacy(index.itemIndex, targetColumn.name, targetColumn);
                if (privacy) {
                    privacy.modifiedValue = newValue;
                    this.setValueUnmanaged(index.itemIndex, OBTDataGridInterface.getOriginFieldName(targetColumn), privacy.value);
                }
            }

            // 아직 커밋되지 않아서 handleCellEdited 로 이동
            this._editCommitInfo = {
                itemIndex: index.itemIndex,
                fieldIndex: index.fieldIndex,
                targetColumn: targetColumn,
                oldValue: oldValue,
                newValue: newValue
            };
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private checkUniqueRow(rowIndex: number, newRow: any, uniqueColumnNames: string[]): boolean {
        if (uniqueColumnNames) {
            if (rowIndex >= 0) {
                const displayRows = this.getDisplayRows();

                const hasDuplicated = displayRows.some((row: any, indexInRows: number) => {
                    if (indexInRows === rowIndex) {
                        return false;
                    }

                    const checkResult = uniqueColumnNames.some((uniqueColumnName => {
                        return row[uniqueColumnName] !== newRow[uniqueColumnName]
                    }));

                    return !checkResult;
                });

                if (hasDuplicated) {
                    return false;
                }
            }
        }

        return true;
    }

    /**
     * @internal
     * 리얼그리드 onEditChange 핸들러
     * 셀에 데이터를 수정하고 있을때 지속해서 호출한다
     * 자동완성 컴포넌트
     */
    private handleEditChange(grid: any, index: any, value: any) {
        const targetColumn = this.getColumnByName(index.column);
        if (!targetColumn) {
            throw new Error("OBTDataGridInterface.handleEditChange: 컬럼을 찾을수 없습니다.");
        }

        if (targetColumn.type === ColumnType.autoComplete && this.onCallAutoCompleteDialog && value) {
            this.onCallAutoCompleteDialog({
                column: targetColumn,
                value: value,
            });
        }
    }

    /**
     * @internal
     * 리얼그리드 onCellEdited 핸들러 
     * 셀 편집이 끝난 이후에 호출된다.
     * 셀단위 커밋을 위해 사용
     */
    private handleCellEdited(grid, itemIndex, dataRow, field) {
        try {
            if (this._currentEditingCellInfomaiton.isCanceledOnEditCommit) {
                this.setState(
                    this._currentEditingCellInfomaiton.rowIndex,
                    this._currentEditingCellInfomaiton.stateBeforeEditing,
                    true
                );
                this._currentEditingCellInfomaiton.columnName = "";
                this._currentEditingCellInfomaiton.isCanceledOnEditCommit = false;
                this._currentEditingCellInfomaiton.rowIndex = -1;
                this._currentEditingCellInfomaiton.stateBeforeEditing = GridState.none;
                return;
            } else {
                this.gridView.commit();
            }

            // 입력된 값이 빈값이 아니면 TODO:
            // 데이터가 커밋되었다고해도 빈값이면 emptyRows에서 제거하지 않는다.
            if (GridUtil.isEmpty(grid.getValue(itemIndex, field)) === false) {
                this.setEmptyDataRows(this._emptyDataRows.filter(item => item !== dataRow))
            }

            // added 상태의 행이 변경되었을때 변경된 행이 빈행이라면 다시 empty로 만든다.
            if (this._currentEditingCellInfomaiton.stateBeforeEditing === GridState.added) {
                const isNotEmptyRow = Object.keys(this.getValues(itemIndex)).some(item => {
                    if (item === '__rowId') {
                        return false;
                    }
                    return GridUtil.isEmpty(grid.getValue(itemIndex, item)) === false
                });

                if (isNotEmptyRow === false) {
                    this.setEmptyDataRows(this._emptyDataRows.concat(dataRow));
                }
            }

            // 에디팅이 끝났으니 현 에디트 정보를 초기화
            this._currentEditingCellInfomaiton.columnName = "";
            this._currentEditingCellInfomaiton.isCanceledOnEditCommit = false;
            this._currentEditingCellInfomaiton.rowIndex = -1;
            this._currentEditingCellInfomaiton.stateBeforeEditing = GridState.none;

            // onAfterChange invoke
            if (this._editCommitInfo &&
                this._editCommitInfo.itemIndex === itemIndex &&
                this._editCommitInfo.fieldIndex === field) {
                const editCommitInfo = this._editCommitInfo;
                this._editCommitInfo = null;
                this._invokeAfterChange(editCommitInfo.itemIndex,
                    editCommitInfo.targetColumn.name,
                    editCommitInfo.oldValue,
                    editCommitInfo.newValue,
                    this.getRow(editCommitInfo.itemIndex), null
                );
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    handleEditCanceled(grid: any, index: any) {
        // TODO: 동작없음
    }

    isValidDate(dataString: string, format: DateFormat = DateFormat.yyyyMMdd): boolean {
        if (!dataString) {
            return false;
        }

        let simpleDate: Date;
        if (format === DateFormat.yyyyMMdd) {
            if (dataString.length !== 8) {
                return false;
            } else {
                simpleDate = moment(dataString).toDate();
            }
        } else if (format === DateFormat.yyyyMM) {
            if (dataString.length !== 6) {
                return false;
            } else {
                simpleDate = moment(dataString + "01").toDate();
            }
        } else if (format === DateFormat.yyyy) {
            if (dataString.length !== 4) {
                return false;
            } else {
                simpleDate = moment(dataString + "0101").toDate();
            }
        } else {
            return false;
        }

        if (moment(simpleDate).isBefore(this.dateFieldMinDate)) {
            return false;
        } else if (moment(simpleDate).isAfter(this.dateFieldMaxDate)) {
            return false;
        }

        return moment(dataString, format.toUpperCase(), true).isValid();
    }

    isEmptyValue = (value: any) => {
        if (value === "" || value === undefined || value === null) return true;
        else return false;
    }

    /**
     * @internal
     * 리얼그리드 onCurrentChanging 핸들러 
     * 선택한 셀이 바뀌기 전에 호출된다. 
     * false를 리턴하면 행이 바뀌지 않는다. 
     * onBeforeSelectChange, onBeforeChangeRowSelection, SkipEmptyCellEventArgs
     */
    private handleCurrentChanging(grid: any, oldIndex: any, newIndex: any): boolean {
        try {
            // 현재 로우가 변했는지 여부
            // 현재 로우가 변했는지 onCurrentChanged 에서 알수 없기 때문에 클래스 필드로 관리한다.
            this._isRowChanged = oldIndex.itemIndex !== newIndex.itemIndex;

            // 셀이동 막기 상태에 따른 리턴처리
            if (this._keyDownEnterCellInfo && this._keyDownEnterCellInfo.isCommitCancel === true) {
                this._keyDownEnterCellInfo.isCommitCancel = false;
                return false;
            }


            // 셀이동 막기 상태에 따른 리턴처리
            if (this._lockCellSelectionChange === true) {
                this._keyDownForSelectionChange.keyDown = false;
                return false;
            }

            // 행이동 막기 상태에 따른 리턴처리
            if (this._lockRowSelectionChange === true && this._isRowChanged === true) {
                this._keyDownForSelectionChange.keyDown = false;
                return false;
            }

            if (this._ignoreManagedCellChangeEvent === true) {
                return true;
            }

            const oldTargetColumn = this.getColumnByName(oldIndex.column);
            if (!oldTargetColumn) {
                throw new Error('OBTDataGridInterface.handleCurrentChanging: 컬럼을 찾을 수 없습니다 (' + oldIndex.column + ')');
            }

            const newTargetColumn = this.getColumnByName(newIndex.column);
            if (!newTargetColumn) {
                throw new Error('OBTDataGridInterface.handleCurrentChanging: 컬럼을 찾을 수 없습니다 (' + newIndex.column + ')');
            }

            let willChangeSelection = true;

            // 코드도움 다이얼로그가 열려있는 상태이면 포커스이동을 하지않는다. 
            if (this.hasCodePicker(oldTargetColumn)) {
                if (oldTargetColumn.internalCodePickerState === 'search') {
                    return false;
                }
            }

            // BeforeSelectChangeEvent Inkoke
            if (oldIndex.itemIndex >= 0 &&
                this._asyncEventInvokeState.storeData === false &&
                this._asyncEventInvokeState.beforeRowChange === false) {

                const isNewCellLastVisibleColumn = this.isLastVisibleColumnUsingIColumn(oldTargetColumn, oldIndex.itemIndex)
                let invokeResult = this.invokeEvent<Events.BeforeSelectChangeEventArgs>(
                    this.onBeforeSelectChange,
                    new Events.BeforeSelectChangeEventArgs(this, oldIndex.itemIndex, newIndex.itemIndex, oldIndex.column, newIndex.column, this._isRowChanged, isNewCellLastVisibleColumn));

                if (invokeResult.isCancel === false && this._isRowChanged === true) {
                    invokeResult = this.invokeEvent<Events.BeforeSelectChangeEventArgs>(
                        this.onBeforeChangeRowSelection,
                        new Events.BeforeSelectChangeEventArgs(this, oldIndex.itemIndex, newIndex.itemIndex, oldIndex.column, newIndex.column, this._isRowChanged, isNewCellLastVisibleColumn));
                }

                // cancel 처리
                willChangeSelection = invokeResult.isCancel === false;
                if (willChangeSelection === false) {
                    return false;
                }
            }

            // BeforeSelectChangeAsyncEvent Invoke (비동기 셀변경 이벤트)
            if (oldIndex.itemIndex >= 0
                && this._isRowChanged === true
                && this._asyncEventInvokeState.storeData === false
                && this._asyncEventInvokeState.beforeRowChange === false
                && this.onBeforeChangeRowSelectionAsync.events.length > 0) {

                this._lockCellSelectionChange = true;
                this._keyDownForSelectionChange.keyDown = false;
                this._asyncEventInvokeState.beforeRowChange = true;

                const beforeSelectChangeAsyncEventArgs = new Events.BeforeSelectChangeAsyncEventArgs(
                    this, oldIndex.itemIndex, newIndex.itemIndex,
                    oldIndex.column, newIndex.column,
                    this._isRowChanged
                );

                Promise.all(this.onBeforeChangeRowSelectionAsync.events.map((event) => event(beforeSelectChangeAsyncEventArgs))).then((result: boolean[]) => {
                    this._lockCellSelectionChange = false;
                    this._asyncEventInvokeState.beforeRowChange = true;

                    if (result.some(item => item === false) === false) {
                        this.setSelection(newIndex.itemIndex, newTargetColumn.name);
                    }
                }).finally(() => {
                    this._asyncEventInvokeState.beforeRowChange = false;
                });

                return false;
            }

            let validationResult: RowValidationAlert | null = null;

            //  셀이동이 변경될 때 조건을 체크해 storeData 처리
            if (oldIndex.itemIndex >= 0 && this.getState(oldIndex.itemIndex) === GridState.empty) {
                // empty컬럼은 storeData를 호출하지 않고 행이동도 막지않음
                willChangeSelection = true;
            } else if (oldIndex.itemIndex >= 0
                && this._isRowChanged === true
                && this._asyncEventInvokeState.storeData === false
                && this.gridOption.editable === true
                && willChangeSelection === true
                && (this.getState(oldIndex.itemIndex) === GridState.modified
                    || this.getState(oldIndex.itemIndex) === GridState.empty
                    || this.getState(oldIndex.itemIndex) === GridState.added)) {
                validationResult = this.checkRowValidation(oldIndex.itemIndex);
                if (validationResult.isValidated === true) {
                    this._lockRowSelectionChange = true;
                    this._keyDownForSelectionChange.keyDown = false;
                    this._asyncEventInvokeState.storeData = true;

                    this.storeData({ rowIndex: oldIndex.itemIndex })
                        .then(e => {
                            this._lockRowSelectionChange = false;
                            this.setSelection(newIndex.itemIndex, newTargetColumn.name);
                        }).finally(() => {
                            this._asyncEventInvokeState.storeData = false;
                            this._lockRowSelectionChange = false;

                        }).catch((e) => {
                            console.error(e)
                            throw e;
                        });
                    return false;
                } else {
                    const eventArgs = new Events.AlertEventArgs(this,
                        Events.GridAlertReason.VALIDATION_FAIL,
                        validationResult
                    );

                    this.invokeEvent<Events.AlertEventArgs>(
                        this.onAlert,
                        eventArgs
                    );

                    willChangeSelection = false;
                }
            }

            // 행 변경이 cancel되지 않았을때의 처리
            if (willChangeSelection === true) {

                // 동적 dropDown 이벤트 처리
                if (oldIndex.itemIndex >= 0 &&
                    newTargetColumn.type === ColumnType.dropDown &&
                    newTargetColumn.dynamicDropDownItemFilter) {
                    this.setDynamicDropDownColumn(newTargetColumn, newIndex);
                }

                const oldTargetValue = this.getValue(oldIndex.itemIndex, oldIndex.column);
                const isNewColumnRequired = this.getColumnRequired(newTargetColumn, newIndex.itemIndex);
                const isOldColumnRequired = this.getColumnRequired(oldTargetColumn, oldIndex.itemIndex);

                // 필수컬럼 스낵바 처리
                if (oldIndex.itemIndex >= 0 &&
                    this.getState(oldIndex.itemIndex) !== GridState.empty &&
                    isOldColumnRequired &&
                    this.isEmptyValue(oldTargetValue) &&
                    (oldIndex.itemIndex !== newIndex.itemIndex || isNewColumnRequired === false)
                ) {
                    const validationResult = {
                        isValidated: false,
                        rowIndex: oldIndex.itemIndex,
                        invalidColumns: [{ columnName: oldIndex.column, reason: Events.GridValidationFailReason.EMPTY_REQUIRED }]
                    } as RowValidationAlert;

                    const eventArgs = new Events.AlertEventArgs(this,
                        Events.GridAlertReason.VALIDATION_FAIL,
                        validationResult
                    );

                    this.invokeEvent<Events.AlertEventArgs>(
                        this.onAlert,
                        eventArgs
                    );

                    return false;
                }

                if (this._isRowChanged === true && (this._gridView.isItemEditing() === false || this._gridView.isItemEditing() === null)) {
                    if (oldIndex.itemIndex >= 0) {
                        this.applyInternalCellStyle(oldIndex.itemIndex, {
                            columns: this._getFlatColumns(this.columns!, true).filter(column => column.readonly === true || this.getColumnRequired(column, oldIndex.itemIndex) === true),
                            selectedRow: false
                        });
                    }

                    if (newIndex.itemIndex >= 0) {
                        this.applyInternalCellStyle(newIndex.itemIndex, {
                            columns: this._getFlatColumns(this.columns!, true).filter(column => column.readonly === true || this.getColumnRequired(column, newIndex.itemIndex) === true),
                            selectedRow: true
                        });
                    }
                }

                // onSkipEmptyCell 이벤트 처리
                if (this.onSkipEmptyCell.events.length > 0) {
                    const oldValue = this.getValue(oldIndex.itemIndex, oldIndex.column);
                    if (GridUtil.isEmpty(oldValue)) {
                        // empty 컬럼처리
                        const eventArgs = new Events.SkipEmptyCellEventArgs(this, oldIndex.column, oldIndex.itemIndex, this.getColumnRequired(oldTargetColumn, oldIndex.itemIndex), "");
                        this.invokeEvent<Events.SkipEmptyCellEventArgs>(
                            this.onSkipEmptyCell,
                            eventArgs
                        );

                        if (oldIndex.itemIndex >= 0
                            && this.checkPossibleGridAction('editable', false)
                            && GridUtil.isEmpty(eventArgs.defaultValue) === false
                            && (this.getState(oldIndex.itemIndex) === GridState.added || this.getState(oldIndex.itemIndex) === GridState.empty)
                        ) {
                            this.setValue(oldIndex.itemIndex, oldIndex.column, eventArgs.defaultValue);
                            if (this._isEmptyDataRow(this.gridView.getDataRow(oldIndex.itemIndex))) {
                                this.setEmptyDataRows(this._emptyDataRows.filter(item => item !== this.gridView.getDataRow(oldIndex.itemIndex)))
                            }
                        }
                    }
                }

                // 마스킹을 사용할 경우 displayCallback 재호출을 위해 refresh
                if (oldTargetColumn.type === ColumnType.mask || newTargetColumn.type === ColumnType.mask) {
                    this.refresh();
                }
            }

            return willChangeSelection;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * @internal
     */
    private getColumnCanMultiSelect(column: IColumn, rowIndex?: number): boolean {
        let canMultiSelect = false;
        if (typeof column.canMultiSelect === 'boolean') {
            canMultiSelect = column.canMultiSelect;
        } else if (column.canMultiSelect && typeof column.canMultiSelect === 'function') {
            if (rowIndex === undefined) {
                throw new Error(column.name + ' 컬럼의 canMultiSelect가 동적으로 설정되어 있어 rowIndex 인자 없이는 판단할 수 없습니다.')
            }

            canMultiSelect = column.canMultiSelect({ columnName: column.name, rowIndex: rowIndex });
        }

        return canMultiSelect;
    }

    /**
     * @internal
     */
    private getCellStyleType(rowIndex: number, targetColumn: IColumn, isSelected: boolean): ICellStyleType {
        const reservedColumnNames = this.gridOption.reservedColumnNames!;

        let styleType = {
            checkPen: {
                has: false,
                valueIfHas: ''
            },
            memo: {
                has: false,
            },
            selected: {
                isSelected: false,
                required: false,
                readonly: false,
            }
        } as ICellStyleType;
        if (this.getColumnByName(reservedColumnNames.checkPen) !== undefined) {
            const checkPenValue = this.getValue(rowIndex, reservedColumnNames.checkPen);
            if (checkPenValue && checkPenValue.length > 0) {
                styleType.checkPen.has = true;
                styleType.checkPen.valueIfHas = checkPenValue
            }
        }
        if (this.getColumnByName(reservedColumnNames.memoCode) !== undefined) {
            if (this._firstColumnName === targetColumn.name) {
                const memoValue = this.getValue(rowIndex, reservedColumnNames.memoCode);

                if (memoValue && memoValue.length > 0) {
                    styleType.memo.has = true;
                }
            }
        }

        if (isSelected === true) {
            let columnRequired = this.getColumnRequired(targetColumn, rowIndex);

            styleType.selected.isSelected = true;
            styleType.selected.readonly = targetColumn.readonly ? targetColumn.readonly : false;
            styleType.selected.required = columnRequired//targetColumn.required ? targetColumn.required : false;
        }

        return styleType;
    }

    private getColumnRequired(column: IColumn, rowIndex?: number): boolean {
        let columnRequired = false;
        if (typeof column.required === 'boolean') {
            columnRequired = column.required;
        } else if (column.required && typeof column.required === 'function') {
            if (rowIndex === undefined) {
                throw new Error(column.name + ' 컬럼의 required가 동적으로 설정되어있어 rowIndex 인자없이는 판단할 수 없습니다.')
            }

            columnRequired = column.required({ rowIndex: rowIndex, columnName: column.name });
        }

        return columnRequired;
    }

    /**
     * @internal
     * @param styleType 
     */
    private makeCellStyleObject(styleType: ICellStyleType): { id: string, style: any } {
        let resultStyleObject: any = {};
        let resultStyleId = ''

        if (styleType.checkPen.has === true) {
            resultStyleObject.borderBottom = GridUtil.convertRGBToRealGridColorHex(styleType.checkPen.valueIfHas) + ', 2';

            resultStyleId = resultStyleId + '_CHECKPEN|' + styleType.checkPen.valueIfHas + '_'
        }

        if (styleType.memo.has === true) {
            resultStyleObject.figureName = "leftTop";
            resultStyleObject.figureBackground = "#FF0000FF";
            resultStyleObject.figureSize = "10";

            resultStyleId = resultStyleId + '_MEMO_';
        }

        if (styleType.selected.isSelected === true) {
            if (styleType.selected.required === true) {
                resultStyleObject.background = StandardDesign.GridColorRGB.columnRequiredBackground;
                resultStyleId = resultStyleId + '_SELECTED|REQUIRED_';
            } else if (styleType.selected.readonly === true) {
                resultStyleObject.background = StandardDesign.GridColorRGB.columnReadonlyBackground;
                resultStyleId = resultStyleId + '_SELECTED|READONLY_';
            } else {
                //resultStyleObject.background = StandardDesign.GridColorRGB.columnDefaultBackground;
                resultStyleId = resultStyleId + '_SELECTED|DEFAULT_';
            }
        }

        return { id: resultStyleId, style: resultStyleObject };
    }

    /**
     * @internal
     * 체크컬럼일 경우 체크컬럼의 readOnly 컬럼 속성을 컬럼수정여부와 동기화 시킨다
     */
    private setCheckTypeColumnReadOnlyPropertyFromEditable(rowIndex: number, targetColumn?: IColumn) {
        if (targetColumn) {
            let realGridColumn = this.getRealGridColumnByName(targetColumn.name);
            if (targetColumn.type === ColumnType.check) {
                this.gridView.setColumnProperty(
                    realGridColumn,
                    "readOnly",
                    !this.isColumnEditable(targetColumn, rowIndex));
            }
        }
    }

    /**
     * @internal
     * 리얼그리드 onCurrentChanged 핸들러
     * 선택된 셀이 변경된 이후에 호출된다.
     * onAfterSelectChange, onAfterChangeRowSelection, onAfterDataChanged inkoke
     * 
     * 컬럼의 editable을 dynamicStyle이 아닌 여기서 처리한다 (모바일 버그)
     */
    private handleCurrentChanged(grid: any, newIndex: any): void {
        const targetColumn = this.getColumnByName(newIndex.column);

        // 셀이 변경될 때, 자동완성 컴포넌트일 경우 창을 닫아줌.
        if (targetColumn && targetColumn.type === ColumnType.autoComplete) {
            this.onCloseAutoCompleteDialog && this.onCloseAutoCompleteDialog();
        }

        if (this._keyDownForSelectionChange.keyDown === true && this.isKeyCodeChangeCellSelection(this._keyDownForSelectionChange.keyCode)) {
            this._keyDownForSelectionChange.newRowIndex = newIndex.itemIndex;
            this._keyDownForSelectionChange.newColumnName = newIndex.column;
        } else {
            this.processAfterCellChange(newIndex.itemIndex, newIndex.column, this._isRowChanged);
        }
    }

    /**
     * 셀이 변경된 이후의 이벤트를 호출한다.
     * keyDown -> currentChanged -> keyUp의 순서로 이벤트가 호출
     */
    private processAfterCellChange(rowIndex: number, columnName: string, isRowChanged: boolean) {
        if (this._ignoreManagedCellChangeEvent === true) {
            return;
        }
        const column = this.getColumnByName(columnName);

        this.setCheckTypeColumnReadOnlyPropertyFromEditable(rowIndex, column);

        new Promise<void>((resolve) => {
            // 개인정보 암호화 
            // 선택시 조회 옵션일 경우 개인정보 조회처리
            if (column) {
                if (this.getPrivacyBehavior(column.name, column) === PrivacyBehaviorEnum.viewByFocus &&
                    this.isUsePrivacy(columnName, column) &&
                    this.isPrivacyMasked(rowIndex, columnName, column)) {
                    this.getPrivacyValueRead(rowIndex, columnName, column).then(() => resolve());
                    return;
                }
            }
            resolve();
        }).then(() => {
            this.invokeEvent<Events.AfterSelectChangeEventArgs>(
                this.onAfterSelectChange,
                new Events.AfterSelectChangeEventArgs(this,
                    rowIndex,
                    columnName,
                    isRowChanged,
                    false,
                    this.isGroupItem(rowIndex),
                ));

            if (isRowChanged === true) {
                this.invokeEvent<Events.AfterSelectChangeEventArgs>(
                    this.onAfterChangeRowSelection,
                    new Events.AfterSelectChangeEventArgs(
                        this,
                        rowIndex,
                        columnName,
                        isRowChanged,
                        false,
                        this.isGroupItem(rowIndex),
                    ));
            }
            this.invokeEvent<Events.AfterDataChangeEventArgs>(
                this.onAfterDataChanged,
                new Events.AfterDataChangeEventArgs(this, [columnName], Events.GridDataChangeAction.SELECTION_CHANGED));
        });
    }

    /**
     * @internal
     * 리얼그리드 onShowTooltip 이벤트 콜백 
     */
    private handleOnShowTooltip(grid: any, index: any, value: string): string {
        try {
            const targetColumn = this.getColumnByName(index.column);

            let resultTooltipText = "";
            if (targetColumn) {
                const eventArgs = new Events.ShowTooltipEventArgs(
                    this,
                    index.column,
                    index.itemIndex,
                    this.getRow(index.itemIndex),
                    this.hasCodePicker(targetColumn),
                    ""
                );

                this.invokeEvent<Events.ShowTooltipEventArgs>(
                    this.onShowTooltip,
                    eventArgs);

                resultTooltipText = eventArgs.tooltipText;
            }

            return resultTooltipText;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * @internal
     * 리얼그리드 onInnerDragStart 이벤트 콜백
     * false를 지정하면 드래그가 취소된다.
     */
    public handleInnerDragStart(grid: any, dragCells: any): boolean {
        try {
            if (this.getState(dragCells.startRow) === GridState.modified) {
                return false;
            }

            const eventArgs = new Events.InnerDragStartEventArgs(
                this,
                dragCells.startRow,
                this.getRow(dragCells.startRow),
            );

            this.invokeEvent<Events.InnerDragStartEventArgs>(
                this.onInnerDragStart,
                eventArgs
            );

            return !eventArgs.cancel;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * @internal
     * 리얼그리드 onInnerDrop 이벤트 콜백
     */
    public handleInnerDrop(grid: any, dropIndex: any, dragCells: any): void {
        try {
            const eventArgs = new Events.InnerDropEventArgs(
                this,
                dragCells.startRow,
                this.getRow(dragCells.startRow),
                dropIndex.dataRow,
                this.getRow(dropIndex.dataRow),
            );

            this.invokeEvent<Events.InnerDropEventArgs>(
                this.onInnerDrop,
                eventArgs);


            if (!eventArgs.cancel && dragCells.startRow >= 0 && dropIndex.dataRow >= 0) {
                if (!eventArgs.cancel && dragCells.startRow >= 0 && dropIndex.dataRow >= 0) {
                    if (this._provider && this._provider._provider) {
                        this._provider._provider.moveRow(dragCells.startRow, dropIndex.dataRow);
                    }
                    this.setSelection(dropIndex.dataRow);
                }
            }

        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * @internal
     * 리얼그리드 keyDown 이벤트
     * 이벤트 순서
     *     화살표키, enter, tap 입력 - onKeyDown -> onCurrentChanging -> onCurrentChanged -> onKeyUp
     */
    private handleOnKeyDown(grid: any, key, ctrl, shift, alt): boolean {
        try {
            this.invokeEvent<Events.KeyDownEventArgs>(
            this.onKeyDown,
            new Events.KeyDownEventArgs(this, key, ctrl, shift, alt));

            // TODO:
            // if (invokeResult.isCancel === true) {
            //     return false;
            // }

            if (this.getRowCount() === 0) {
                return false;
            }

            if (this.onKeyDownInternal) {
                const cancel = this.onKeyDownInternal(key);
                if (cancel === false) {
                    return false;
                }
            }

            const keyValue = this.mapKeyCodeToKeyName(key);
            const column = this.getColumnByName(this.getSelectedColumnName());
            const selectedIndex = this.getSelectedIndex();

            if (column) {
                if (this._keyDownForSelectionChange.keyDown === false && this.isKeyCodeChangeCellSelection(key)) {
                    this._keyDownForSelectionChange = {
                        keyDown: true,
                        keyCode: key,
                        newRowIndex: null,
                        newColumnName: null,
                        oldRowIndex: selectedIndex,
                        oldColumnName: column.name,
                    }
                }

                // 자동완성 컴포넌트일 경우 : 다이얼로그가 열린 상태에서 키보드 위/아래 눌렀을 경우 셀 이동 불가하게끔
                if (column.type === ColumnType.autoComplete && this.onKeyDownAutoComplete) {
                    const cancel = this.onKeyDownAutoComplete(key);
                    if (cancel === false) {
                        return false;
                    }
                }

                // 날짜 타입 컬럼에서 space 입력 혹은 F2 입력 후 바로 엔터 입력시 데이터가 클리어되는 문제
                if (column.type === ColumnType.date && this.isEditing() === false && (keyValue === 'Space' || keyValue === 'F2') && this.onCallDatePickerDialog) {
                    this.onCallDatePickerDialog({
                        rowIndex: selectedIndex,
                        columnName: column.name,
                        dateFormat: column.dateFormat ? column.dateFormat : DateFormat.yyyyMMdd,
                        value: this.getValue(selectedIndex, column.name),
                        dateDialogAlign: column.dateDialogAlign,
                        dateDialogPosition: column.dateDialogPosition,
                    })
                    return false;
                }

                // rowSelectMode에서 onSelectByEnter 이벤트 호츨 
                if (keyValue === 'Enter'
                    && this._gridOption.rowSelectMode === true) {

                    this.invokeEvent<Events.SelectByEnterEventArgs>(
                        this.onSelectByEnter,
                        new Events.SelectByEnterEventArgs(this, this.getRow(selectedIndex)));
                }
                
                const keyValueEnterOnLastCell =
                    keyValue === 'Enter' &&
                    (this.isLastVisibleColumn(column.name, selectedIndex) || this.isLastEditableColumn(column.name, selectedIndex));

                // 빈행에서 엔터나 아래 화살표 입력시 새로운 행 추가나 데이터 저장
                if ((keyValueEnterOnLastCell || keyValue === 'Down')
                    && this._emptyDataRows.length === 0
                    && selectedIndex + 1 === this.getRowCount()
                    && this.gridView.isEditing() === false) {
                    const validationResult = this.checkRowValidation(selectedIndex);
                    if (validationResult.isValidated === true) {
                        if (this.gridOption.appendable === true && this.checkPossibleGridAction('appendable', false) === true) {
                            this.processAddRow();
                        } else {
                            this.storeData({
                                rowIndex: selectedIndex
                            });
                        }

                        if (keyValue === 'Enter') {
                            let nextCell = this.getNextActiveCellUsingIColumn(selectedIndex, column);
                            if (nextCell) {
                                this.setSelection(nextCell.rowIndex, nextCell.columnName || undefined);
                                return false;
                            }
                        }
                    } else {
                        const eventArgs = new Events.AlertEventArgs(this,
                            Events.GridAlertReason.VALIDATION_FAIL,
                            validationResult
                        );
                        this.invokeEvent<Events.AlertEventArgs>(
                            this.onAlert,
                            eventArgs
                        );
                    }
                } else if (keyValue === 'Enter') {
                    // 포커스 이동 이벤트
                    if (this.gridOption.appendable === false) {
                        if (selectedIndex + 1 === this.getRowCount() && this.isLastEditableColumn(column.name, selectedIndex)) {
                            const eventArgs = new CommonEvents.MoveFocusEventArgs(this, CommonEvents.MoveFocusDirection.enter);
                            this.invokeEvent<CommonEvents.MoveFocusEventArgs>(
                                this.onMoveFocus,
                                eventArgs
                            );
                        }
                    } else if (this.gridOption.appendable === true) {
                        if (selectedIndex + 1 === this.getRowCount()
                            && this.isLastEditableColumn(column.name, selectedIndex)
                            && this.gridView.isEditing() === false
                            && this._emptyDataRows.length !== 0) {
                            const eventArgs = new CommonEvents.MoveFocusEventArgs(this, CommonEvents.MoveFocusDirection.enter);
                            this.invokeEvent<CommonEvents.MoveFocusEventArgs>(
                                this.onMoveFocus,
                                eventArgs
                            );
                        }
                    }

                    // 엔터 입력시 기본동작 막음
                    // 엔터 입력시 다음 수정가능한 셀로 이동하는것이 컨셉 
                    if (this.isEditing()) {
                        this.commit();

                        if (column.autoMoveNextCell === false) {
                            return false;
                        }
                    }

                    let nextCell = this.getNextActiveCellUsingIColumn(selectedIndex, column);
                    if (nextCell) {
                        if (this._keyDownEnterCellInfo && this._keyDownEnterCellInfo.isCommitCancel === true) {
                            this._keyDownEnterCellInfo = null
                            return false;
                        }

                        this.setSelection(nextCell.rowIndex, nextCell.columnName || undefined);
                        return false;
                    }
                } else if (key === 113) {
                    // F2 입력시 코드피커 다이얼로그 띄우기
                    if (this.hasCodePicker(column)) {
                        this.commit();
                        this.callCodePickerDialog(selectedIndex, column.name);
                    }

                    if (column.type === ColumnType.dropDown) {
                        this.commit();
                        this.gridView.showEditor(true);
                    }
                }
            }

            return true;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    private isKeyCodeChangeCellSelection(keyCode?: number | null) {
        if (!keyCode) {
            return false;
        }

        return (keyCode === 13 || keyCode === 40 || keyCode === 38);
    }

    /**
     * 여기서 행변경 이벤트 처리하니 주의
     * @param grid 
     * @param key 
     * @param ctrl 
     * @param shift 
     * @param alt 
     */
    private handleOnKeyUp(grid: any, key: number, ctrl: boolean, shift: boolean, alt: boolean): void {
        try {
            this.invokeEvent<Events.KeyUpEventArgs>(
                this.onKeyUp,
                new Events.KeyUpEventArgs(this, key, ctrl, shift, alt));

            if (this._keyDownForSelectionChange.keyDown === true && this.isKeyCodeChangeCellSelection(this._keyDownForSelectionChange.keyCode)) {
                if (this._keyDownForSelectionChange.newColumnName !== null && this._keyDownForSelectionChange.newRowIndex !== null) {
                    const rowIndex = this._keyDownForSelectionChange.newRowIndex;
                    const columnName = this._keyDownForSelectionChange.newColumnName;
                    this.processAfterCellChange(rowIndex, columnName, this._keyDownForSelectionChange.oldRowIndex !== null && this._keyDownForSelectionChange.oldRowIndex !== this._keyDownForSelectionChange.newRowIndex);
                }
            }
        } catch (error) {
            console.error(error);
            throw error;
        } finally {
            this._keyDownForSelectionChange = {
                keyDown: false,
                keyCode: null,
                oldRowIndex: null,
                oldColumnName: null,
                newRowIndex: null,
                newColumnName: null,
            }
        }
    }

    /**
     * @internal
     */
    private handleContextMenuPopup(grid: any, x, y, elementName): boolean {
        return elementName !== "HeaderCell";
    }

    /**
     * @internal
     */
    private handleContextMenuItemClicked(grid: any, menuItem, index) {
        if (menuItem.label === OrbitInternalLangPack.getText('WE000026853', '엑셀 변환하기')) {
            this.onClickContextMenuExportExcel();
        } else if (menuItem.label === OrbitInternalLangPack.getText('WE000000999', '찾기')) {
            this.onClickContextMenuShowSearchBox();
        } else if (menuItem.label === '컬럼 표시') {
            this.onClickContextMenuCustomColumn();
        } else {
            this.invokeEvent<Events.ContextMenuItemClickEventArgs>(
                this.onContextMenuItemClicked,
                new Events.ContextMenuItemClickEventArgs(
                    this,
                    index.column,
                    index.itemIndex,
                    menuItem.label,
                    menuItem.tag,
                    menuItem
                )
            );

        }
    }

    /**
     * @internal
     * @param grid 
     */
    private handleSorting(grid: any) {
        this._selectedDataRowBeforeSorting = this.gridView.getDataRow(this.getSelectedIndex());
        if (this._selectedDataRowBeforeSorting < -1) {
            return false;
        }
        return true;
    }

    /**
     * @internal
     * @param grid 
     */
    private handleSortingChanged(grid: any) {
        if (this._clientKey && this.gridOption.useCustomLayout === true) {
            ignoreException(() => {
                this.userCustomLayoutProcessor.saveUserSorting(this._clientKey!, this.gridView.getSortedFields())
            }, (e) => {
                console.error(e);
                this.userCustomLayoutProcessor.removeUserCustomSorting(this._clientKey!);
            })
        }

        this.setSelection(this.gridView.getItemIndex(this._selectedDataRowBeforeSorting));
    }

    /**
     * true로 설정할시 페이징 스크롤이 동작하지 않는다.
     * @param value 
     */
    public setLockScrollPaging(value: boolean) {
        this._lockScrollPaging = value;
    }

    /**
     * 정방향 페이징 처리
     * paging, appendable = false 에서만 처리한다.
     * @internal
     */
    private handleScrollToBottom(grid: any) {
        if (this.gridOption.paging === false) {
            return;
        }

        if (this.gridOption.appendable === true) {
            return;
        }

        if (!this.pagingInfo) {
            return;
        }

        if (this.getRowCount() <= 0) {
            return;
        }

        if (this._lockScrollPaging === true) {
            return;
        }

        const oldCurrentPageNumber = this.pagingInfo.currentPage;
        const beforeEventArgs = new Events.BeforePageNumberChangeArgs(
            this,
            oldCurrentPageNumber,
            oldCurrentPageNumber + 1,
            false
        );

        this.invokeEvent<Events.BeforePageNumberChangeArgs>(
            this.onBeforeChangePageNumber,
            beforeEventArgs
        );


        if (beforeEventArgs.cancel === false) {
            this._lockScrollPaging = true;
            if (this.pagingInfo.isLastPage() === false) {
                this.pagingInfo.nextPage();

                this.appendPagingData().then((appendedData) => {
                    if (this.pagingInfo) {
                        const afterEventArgs = new Events.AfterPageNumberChangeArgs(this, appendedData, this.pagingInfo);

                        this.invokeEvent<Events.AfterPageNumberChangeArgs>(
                            this.onAfterChangePageNumber,
                            afterEventArgs
                        );
                        if ((appendedData || []).length === 0) {
                            if (this.pagingInfo) {
                                this.pagingInfo.preventRead();
                                this.pagingInfo.previousPage();
                            }
                        }
                    }
                }).catch((reason) => {
                    this.pagingInfo!.currentPage = oldCurrentPageNumber;
                }).finally(() => {
                    this._lockScrollPaging = false;
                });
            } else {
                this.invokeEvent<Events.ReachEndOfPageArgs>(
                    this.onReachLastPage,
                    new Events.ReachEndOfPageArgs(this)
                );
                this._lockScrollPaging = false;
            }
        }
    }

    /**
     * @internal
     * 역방향 페이징 처리
     * @param grid 
     * @param itemIndex 
     */
    private handleTopItemIndexChanged(grid: any, itemIndex: number) {
        (() => {
            if (itemIndex !== 0) {
                return;
            }

            if (this.gridOption.paging === false) {
                return;
            }

            if (!this.pagingInfo) {
                return;
            }

            if (this.pagingInfo.pagingDirection !== "scrollToTop") {
                return;
            }

            if (this.getRowCount() <= 0) {
                return;
            }

            if (this.getState(this.getSelectedIndex()) === GridState.modified || this.getState(this.getSelectedIndex()) === GridState.added) {
                return;
            }

            const appendFunctionReturnIsCancel = (pageNumber: number) => {
                const beforeEventArgs = new Events.BeforePageNumberChangeArgs(
                    this,
                    pageNumber,
                    pageNumber - 1,
                    false
                );

                this.invokeEvent<Events.BeforePageNumberChangeArgs>(
                    this.onBeforeChangePageNumber,
                    beforeEventArgs
                );

                if (beforeEventArgs.cancel === false) {
                    if (this.pagingInfo!.isLastPage() === false) {
                        this.pagingInfo!.nextPage();

                        this.appendPagingData().then((appendedData) => {
                            const afterEventArgs = new Events.AfterPageNumberChangeArgs(this, appendedData, this.pagingInfo!);

                            this.invokeEvent<Events.AfterPageNumberChangeArgs>(
                                this.onAfterChangePageNumber,
                                afterEventArgs
                            );
                        }).catch((reason) => {
                            this.pagingInfo!.currentPage = pageNumber;
                        });
                    } else {
                        this.invokeEvent<Events.ReachEndOfPageArgs>(
                            this.onReachLastPage,
                            new Events.ReachEndOfPageArgs(this)
                        );
                    }
                }

                return beforeEventArgs.cancel
            }

            appendFunctionReturnIsCancel(this.pagingInfo.currentPage);
        })();
        this.retrievePrivacies();
    }

    private handleLinkableCellClicked(grid: any, index: any, url: string) {
        this.invokeEvent<Events.LinkColumnClickedEventArgs>(
            this.onClickLinkColumn,
            new Events.LinkColumnClickedEventArgs(this, index.itemIndex, index.column)
        );
    };

    private handleTreeItemCollapsing(tree: any, itemIndex: number, rowId: number) {
        this._cellChangeOldRowId = this._gridView.getDataRow(this.getSelectedIndex());
        return true;
    }

    private handleTreeItemCollapsed(tree: any, itemIndex: number, rowId: number) {
        const currentCell = this.getSelection();
        const newRowId = this._gridView.getDataRow(currentCell.rowIndex);
        if (this._cellChangeOldRowId !== newRowId) {
            this.processAfterCellChange(currentCell.rowIndex, currentCell.columnName!, true);
        }
    }

    private handleTreeItemExpanding(tree: any, itemIndex: number, rowId: number) {
        this._cellChangeOldRowId = this._gridView.getDataRow(this.getSelectedIndex());
        return true;
    }

    private handleTreeItemExpanded(tree: any, itemIndex: number, rowId: number) {
        const currentCell = this.getSelection();
        const newRowId = this._gridView.getDataRow(currentCell.rowIndex);
        if (this._cellChangeOldRowId !== newRowId) {
            this.processAfterCellChange(currentCell.rowIndex, currentCell.columnName!, true);
        }
    }

    /**
     * @internal
     * invoke onColumnWidthChange
     * @param grid 
     * @param column 
     * @param property 
     * @param value 
     */
    private handleColumnPropertyChanged(grid: any, realGridColumn: any, property: string, value: any): void {
        if (property === 'width') {
            // 컬럼의 너비가 변할때 이벤트 발행
            this.invokeEvent<Events.ColumnWidthChangeEventArgs>(
                this.onColumnWidthChanged,
                new Events.ColumnWidthChangeEventArgs(this, realGridColumn.name, value)
            );
        } else if (property === 'visible') {
            // 컬럼의 너비가 변할때 이벤트 발행
            this.invokeEvent<Events.ColumnWidthChangeEventArgs>(
                this.onColumnWidthChanged,
                new Events.ColumnWidthChangeEventArgs(this, realGridColumn.name, 0)
            );
        }

        ignoreException(() => {
            // 정렬및 소계 클라이언트 정보 저장
            if (this.gridOption.useCustomLayout === true) {
                if (property === 'displayIndex' || property === 'visible' || property === 'width') {
                    if (this._clientKey) {
                        if (property === 'displayIndex') {
                            const job = (targetColumns: IColumn[]) => {
                                targetColumns.forEach(column => {
                                    if (column.type === ColumnType.group) {
                                        job(column.columns || [])
                                    }
                                    this.userCustomLayoutProcessor.saveUserCustomLayout(this._clientKey!, column, {
                                        'displayIndex': this.gridView.getColumnProperty(column.name, 'displayIndex')
                                    });
                                })
                            }
                            job(this.columns || []);

                        } else {
                            const targetColumn = this.getColumnByName(realGridColumn.name);
                            if (targetColumn) {
                                this.userCustomLayoutProcessor.saveUserCustomLayout(this._clientKey, targetColumn, {
                                    [property]: value
                                });
                            }
                        }

                        if (this._columns) {
                            this._columns = this.userCustomLayoutProcessor.convertUserCustomColumns(this._clientKey, this._columns);
                        }
                    }

                }
            }
        });
    }

    public resetUserCustomColumn() {
        if (this._clientKey) {
            this.userCustomLayoutProcessor.removeUserCustom(this._clientKey);
        }

        if (this.columns && this._initialColumnProperty) {
            const columns = this.userCustomLayoutProcessor.convertColumns(this.columns, this._initialColumnProperty);
            this.setColumns(columns);
        }
    }

    public _invokeColumnWidthChangedWhenResize() {
        this.invokeEvent<Events.ColumnWidthChangeEventArgs>(
            this.onColumnWidthChanged,
            new Events.ColumnWidthChangeEventArgs(this, null, null, true)
        );
    }

    /**
     * @internal
     * 그리드의 셀버튼 클릭 이벤트핸들러
     * 코드도움과 커스텀 액션버튼에 사용된다.
     * @param grid 
     * @param rowIndex 
     * @param column 
     */
    private handleCellButtonClick(grid: any, rowIndex: number, column: any) {
        const targetColumn = this.getColumnByName(column.name);
        if (!targetColumn) {
            throw new Error("OBTDataGridInterface.handleCellButtonClick: 컬럼을 찾을 수 없습니다. -  " + column.name);
        }

        // 커스텀 액션 버튼일 경우
        if (targetColumn.hasActionButton === true || this.hasCodePicker(targetColumn)) {
            const eventArgs = new Events.ClickCustomActionEventArgs(
                this,
                rowIndex,
                targetColumn.name
            );

            this.invokeEvent<Events.ClickCustomActionEventArgs>(
                this.onClickCustomActionButton,
                eventArgs);
        }
    }

    /**
     * 코드피커 다이얼로그를 연다.
     * @param rowIndex 
     * @param columnName 
     */
    public callCodePickerDialog(rowIndex: number, columnName: string, keyword?: string) {
        const targetColumn = this.getColumnByName(columnName);
        if (!targetColumn) {
            throw new Error("OBTDataGridInterface.handleCellButtonClick: 컬럼을 찾을 수 없습니다. -  " + columnName);
        }

        if (!this.isColumnEditable(targetColumn, rowIndex)) {
            return;
        }

        this.setSelection(rowIndex, columnName);

        let codePickerParameters: any = {};
        if (typeof targetColumn.parameters === 'object') {
            codePickerParameters = targetColumn.parameters;
        } else if (typeof targetColumn.parameters === 'function') {
            codePickerParameters = targetColumn.parameters({
                rowIndex: rowIndex,
                columnName: targetColumn.name,
                values: this.getRow(rowIndex),
            });
        }

        const invokeResult = this.invokeEvent<Events.BeforeCallCodePickerEventArgs>(
            this.onBeforeCallCodePicker,
            new Events.BeforeCallCodePickerEventArgs(this, rowIndex, columnName, keyword)
        );

        const canMultiSelect = this.getColumnCanMultiSelect(targetColumn, rowIndex)
        if (this.onCallCodePickerDialog && invokeResult.isCancel !== true) {
            this.onCallCodePickerDialog({
                useCustomCodePicker: targetColumn.customCodePicker !== undefined,
                codePickerId: targetColumn.codePickerId,
                customCodePicker: targetColumn.customCodePicker,
                columnName: columnName,
                rowIndex: rowIndex,
                parameters: codePickerParameters,
                canMultiSelect: canMultiSelect,
                selectedItemDisplayCallBack: targetColumn.selectedItemDisplayCallBack,
                keyword: keyword,
            });
        }
    }

    getDateDialogPosition() {
        return this.dateDialogPosition;
    }

    setDateDialogPosition(dateDialogPosition: {
        align: AlignType,
        position: PositionType
    }) {
        this.dateDialogPosition = dateDialogPosition;
    }

    /**
     * 이미지 버튼 클릭
     * @param grid 
     * @param itemIndex 
     * @param column 
     * @param buttonIndex 
     * @param name 
     */
    private handleImageButtonClick(grid: any, itemIndex: number, column: any, buttonIndex: number, name: string) {
        const targetColumn = this.getColumnByName(column.name);
        if (!targetColumn) {
            throw new Error(column.name + ' 컬럼을 찾을 수 없습니다.');
        }

        // 개인정보 암호화
        // 셀의 개인정보 조회 버튼 클릭
        if (this.getPrivacyBehavior(targetColumn.name, targetColumn) === PrivacyBehaviorEnum.viewByButton && this.isUsePrivacy(targetColumn.name, targetColumn)) {
            if (this.isPrivacyMasked(itemIndex, targetColumn.name, targetColumn)) {
                this.getPrivacyValueRead(itemIndex, targetColumn.name, targetColumn);
            }
        }

        const eventArgs = new Events.ImageButtonClickedEventArgs(
            this,
            itemIndex,
            targetColumn.name,
            buttonIndex,
            name,
            this.getRow(itemIndex),
        );

        this.invokeEvent<Events.ImageButtonClickedEventArgs>(
            this.onImageButtonClicked,
            eventArgs
        );

        if (this.isColumnEditable(targetColumn, itemIndex) === true) {
            // 날짜 피커
            if (targetColumn.type === ColumnType.date && this.onCallDatePickerDialog) {
                this.onCallDatePickerDialog({
                    rowIndex: itemIndex,
                    columnName: targetColumn.name,
                    dateFormat: targetColumn.dateFormat ? targetColumn.dateFormat : DateFormat.yyyyMMdd,
                    value: this.getValue(itemIndex, targetColumn.name),
                    dateDialogAlign: targetColumn.dateDialogAlign,
                    dateDialogPosition: targetColumn.dateDialogPosition,
                })
            }

            // 코드피커일 경우
            if (this.hasCodePicker(targetColumn) && name === 'codePickerIcon') {
                this.callCodePickerDialog(itemIndex, column.name);
            }
        }
    }

    /**
     * 컬럼 헤더 이미지 버튼 클릭
     * @param grid 
     * @param column 
     */
    private handleColumnHeaderImageClicked(grid: any, column: any) {
        const targetColumn = this.getColumnByName(column.name);
        if (targetColumn && targetColumn.usePrivacy &&
            this.getPrivacyBehavior(targetColumn.name, targetColumn) !== PrivacyBehaviorEnum.always) {
            this.setColumn({
                name: targetColumn.name,
                privacyBehavior: PrivacyBehaviorEnum.always
            }, true);
            this.retrievePrivacies([targetColumn.name]);
        }
    }

    /**
     * @internal
     * 데이터 셀 클릭이벤트
     * @param grid 
     * @param index 
     */
    private handleDataCellClicked(grid: any, index: any) {
        this.onDataCellClicked.events.forEach(event => {
            event(new Events.DataCellClickedEventArgs(this,
                index.itemIndex,
                index.column,
                this.getRow(index.itemIndex)));
        });
    }

    /**
     * @internal
     * 데이터 셀 더블클릭 이벤트 핸들러
     */
    private handleDataCellDblClicked(grid: any, index: any) {
        const targetColumn = this.getColumnByName(index.column);
        if (!targetColumn) {
            throw new Error(index.column + ' 컬럼을 찾을 수 없습니다.');
        }

        this.invokedDblClicked = true;

        this.onDataCellDblClicked.events.forEach(event => {
            event(new Events.DataCellDoubleClickedEventArgs(this,
                index.itemIndex,
                index.column,
                this.getRow(index.itemIndex)));
        });

        if (targetColumn.type === ColumnType.dropDown) {
            this.commit();
            this.gridView.showEditor(true);
        }
    }

    /** 
     *  컬럼 목록에서 columnName으로 컬럼을 검색한다. 
     *  group일 경우 level dept까지 고려해야하므로 recusive하게 검색하기위한 api
     * @internal
     */
    private getRecursiveIndexByColumnName(columns: IColumn[] | undefined, columnName: string): IRecusiveColumnIndex | undefined {
        if (!columns) {
            return undefined;
        }

        let done = false;
        let resultIndex = -1;
        let children: IRecusiveColumnIndex | undefined;
        columns.forEach((item: IColumn, index: number) => {
            if (done === false) {
                if (item.name === columnName) {
                    resultIndex = index;
                    done = true;
                } else if (item.type === ColumnType.group) {
                    if (item.columns) {
                        children = this.getRecursiveIndexByColumnName(item.columns, columnName);
                        resultIndex = index;
                    }
                }
            }
        });

        return {
            index: resultIndex,
            childIndex: children
        }
    }

    /** 
     * @internal  
     */
    private getColumnByRecursiveColumnIndex(columns: IColumn[] | undefined,
        columnIndex: IRecusiveColumnIndex): IColumn | undefined {
        if (!columns) {
            return;
        }

        if (!columnIndex.childIndex) {
            return columns[columnIndex.index];
        }

        return this.getColumnByRecursiveColumnIndex(columns[columnIndex.index].columns, columnIndex.childIndex);
    }

    /** 
     * @internal  
     */
    private replaceColumnByRecursiveColumnIndex(columns: IColumn[] | undefined,
        columnIndex: IRecusiveColumnIndex, replaceColumn: IColumn): void {
        if (!columns) {
            return;
        }

        if (!columnIndex.childIndex) {
            columns[columnIndex.index] = replaceColumn;
            return;
        }

        this.replaceColumnByRecursiveColumnIndex(columns[columnIndex.index].columns, columnIndex.childIndex, replaceColumn);
    }

    /** 
     * @internal  
     */
    private removeColumnByRecursiveColumnIndex(parent: IColumn | null, columns: IColumn[] | undefined,
        columnIndex: IRecusiveColumnIndex): void {
        if (!columns) {
            return;
        }

        if (!columnIndex.childIndex) {
            if (parent) {
                parent.columns = columns.filter((item, index) => columnIndex.index !== index);
            }
            return;
        }

        this.removeColumnByRecursiveColumnIndex(columns[columnIndex.index], columns[columnIndex.index].columns, columnIndex.childIndex);
    }

    /**
     * TODO: fix best way
     * @internal
     * @param keyCode 
     */
    private mapKeyCodeToKeyName(keyCode: number): string {
        if (keyCode === 13) {
            return 'Enter'
        }
        if (keyCode === 40) {
            return 'Down'
        }
        if (keyCode === 38) {
            return 'Up'
        }
        if (keyCode === 38) {
            return 'Up'
        }
        if (keyCode === 32) {
            return 'Space'
        }
        if (keyCode >= 48 && keyCode <= 57) {
            return String(keyCode - 48)
        }
        if (keyCode === 113) {
            return 'F2'
        }

        return '';
    }

    /**
     * @internal
     */
    public _changeCodePickerState(columnName: string, state: "search" | "unbinded" | "binded") {
        const column = this.getColumnByName(columnName);
        if (column) {
            column.internalCodePickerState = state;
        }
    }

    /**
     * @internal
     */
    public _handleFocus() {
        if (this._hasFocus === false) {
            this._hasFocus = true;
            this._handleFocusChanged();
        }
    }

    /**
     * @internal
     */
    public _handleBlur(wrapperRef: any) {
        const isParentEqualsThis = (parent: Element | null): boolean => {
            if (parent) {
                if (parent === wrapperRef.current) return true;
                else if (parent.parentElement) return isParentEqualsThis(parent.parentElement);
            }
            return false;
        }
        if (!isParentEqualsThis(document.activeElement)) {
            this._hasFocus = false;
            this._handleFocusChanged();
        }
    }

    /**
     * @internal
     * 특정 행위에 대해서 권한이 있는지 여부를 리턴
     */
    private checkPossibleGridAction(behavior: 'appendable' | 'editable' | 'removable' | 'printable', invokedPublicMethodCall: boolean): boolean {
        if (!this.gridOption.pageAuthority || this.gridOption.isSystemGrid === true) {
            return true;
        }

        // useExcelExport 를 true로 지정해주었을때는 printable에 대해서는 무조건 true로
        if (behavior === 'printable' && this.gridOption.useExcelExport === true) {
            return true;
        } else if (behavior === 'printable' && this.gridOption.useExcelExport === false) {
            return false;
        }

        if (invokedPublicMethodCall === true) {
            // user public method call
            if (behavior === 'appendable') {
                return (this.gridOption.pageAuthority.modifyAuthYn === "Y");
            } else if (behavior === 'editable') {
                return (this.gridOption.pageAuthority.modifyAuthYn === "Y");
            } else if (behavior === 'removable') {
                return (this.gridOption.pageAuthority.deleteAuthYn === "Y");
            } else if (behavior === 'printable') {
                return (this.gridOption.pageAuthority.printAuthYn === "Y");
            }
        } else {
            // internal behavior
            if (behavior === 'appendable') {
                return (this.gridOption.appendable === true && this.gridOption.pageAuthority.modifyAuthYn === "Y");
            } else if (behavior === 'editable') {
                return (this.gridOption.editable === true && this.gridOption.pageAuthority.modifyAuthYn === "Y");
            } else if (behavior === 'removable') {
                return (this.gridOption.pageAuthority.deleteAuthYn === "Y");
            } else if (behavior === 'printable') {
                return (this.gridOption.pageAuthority.printAuthYn === "Y");
            }
        }

        return false;
    }

    /**
     * @internal
     */
    private _handleFocusChanged() {
        if (this.isReady) {
            this._setFocusStyle(this._gridOption.useFocusStyle === false ? false : true);
            if (!this._hasFocus) {
                if (this._gridView.isItemEditing()) {
                    this._gridView.commit();
                }
            }
        }
    }

    /**
     * @internal
     * @param dataRow 
     */
    private _isEmptyDataRow(dataRow: number): boolean {
        if (this._emptyDataRows.some(item => item === dataRow)) {
            return true;
        }
        return false;
    }

    /**
     * @internal
     */
    private _getAllStateRows(): any {
        const stateRows: any = this._realGridProvider.getAllStateRows();
        if (stateRows && stateRows.created && stateRows.created.length > 0) {
            stateRows.created = stateRows.created.filter((dataRow) => {
                if (this._isEmptyDataRow(dataRow)) return false;
                return true;
            });
        }

        return stateRows;
    }

    /**
     * @internal
     * GridState -> state 문자열 
     */
    private getRealGridState(state: GridState): string {
        if (state === GridState.added) {
            return 'created'
        } else if (state === GridState.modified) {
            return 'updated'
        } else if (state === GridState.deleted) {
            return 'deleted'
        } else if (state === GridState.none) {
            return 'none'
        } else if (state === GridState.empty) {
            return 'empty'
        } else {
            throw new Error("can't resolve state: " + state);
        }
    }

    /**
     * @internal
     * state 문자열 -> GridState
     */
    private getGridState(state: string): GridState {
        if (state === 'created') {
            return GridState.added;
        } else if (state === 'updated') {
            return GridState.modified;
        } else if (state === 'deleted') {
            return GridState.deleted;
        } else if (state === 'none') {
            return GridState.none;
        } else if (state === 'empty') {
            return GridState.empty;
        } else {
            throw new Error("can't resolve state: " + state);
        }
    }

    /**
     * labelText를 기반으로 리얼그리드의 이미지버튼으로 사용될 수 있는 버튼오브젝트를 생성한다. 
     * @param buttonName 
     * @param width 
     * @param labelText 
     */
    static generateImageButton(buttonName: string, width: number, labelText: string) {
        return {
            name: buttonName,
            down: ImageLabelCanvasTemplate.getDefaultImageButton('down', { width: width, height: 22, labelText: labelText }).toDataURL(),
            hover: ImageLabelCanvasTemplate.getDefaultImageButton('hover', { width: width, height: 22, labelText: labelText }).toDataURL(),
            up: ImageLabelCanvasTemplate.getDefaultImageButton('up', { width: width, height: 22, labelText: labelText }).toDataURL(),
            width: width + 4,
            cursor: 'pointer'
        }
    }

    /**
     * 개인정보 암호화
     */
    private getModifiedValue(modifiedValue: string): string | undefined | null {
        const privacy = new Privacy(modifiedValue);
        return privacy.modifiedValue !== undefined && privacy.modifiedValue !== null ? privacy.modifiedValue : privacy.value;
    }

    private isPrivacyMasked(rowIndex: number, columnName: string, column?: IColumn): boolean {
        column = !column ? this.getColumnByName(columnName) : column;
        const privacy = this.getPrivacy(rowIndex, columnName, column);
        if (column && privacy) {
            if ((privacy.modifiedValue === undefined || privacy.modifiedValue === null) &&
                privacy.privacyValue && privacy.privacyValue.includes('*') &&
                privacy.privacyKey) {
                return true;
            }
        }
        return false;
    }

    private clearProgress = async () => {
        if (this._progressTimeoutHandle !== null) {
            clearTimeout(this._progressTimeoutHandle);
            this._progressTimeoutHandle = null;
        }
    }

    private setProgress(percent: number | undefined, visible: boolean | undefined, labelText: string | undefined, immediately?: boolean, cancellation?: () => boolean): Promise<void> {
        if (immediately && this._progressTimeoutHandle !== null) {
            clearTimeout(this._progressTimeoutHandle);
            this._progressTimeoutHandle = null;
        }
        return new Promise<void>((resolve) => {
            if (this._progressTimeoutHandle === null) {
                this._progressTimeoutHandle = setTimeout(() => {
                    this._progressTimeoutHandle = null;
                    if (this._owner && (!cancellation || !cancellation())) {
                        this._owner.setProgress(percent, visible, labelText).then(() => resolve());
                    }
                }, immediately ? 1 : 100);
            } else {
                resolve();
            }
        })
    }

    private storePrivacyValue(e: { rowIndex: number, column: IColumn, privacy: Privacy }) {
        const { rowIndex, column, privacy } = e;
        if (this._realGridProvider) {
            const dataIndex = this.gridView.getDataRow(rowIndex);
            const rowState = this._realGridProvider.getRowState(dataIndex);
            this._realGridProvider.setValue(dataIndex, OBTDataGridInterface.getOriginFieldName(column), privacy.value);
            this._realGridProvider.setValue(dataIndex, OBTDataGridInterface.getFieldName(column), privacy.privacyValue);
            this._realGridProvider.setRowState(dataIndex, rowState);
        }
    }

    public updatePrivacy(rowIndex: number, columnName: string, updatePrivacyValue: string, column?: IColumn) {
        if (this._realGridProvider) {
            const dataIndex = this.gridView.getDataRow(rowIndex);
            const rowState = this._realGridProvider.getRowState(dataIndex);
            column = !column ? this.getColumnByName(columnName) : column;
            const updatePrivacy = new Privacy(updatePrivacyValue);
            const privacy = this.getPrivacy(rowIndex, columnName, column);
            if (privacy && updatePrivacy && column) {
                privacy.privacyValue = privacy.modifiedValue;
                privacy.modifiedValue = null;
                privacy.privacyKey = updatePrivacy.privacyKey;
                this._realGridProvider.setValue(dataIndex, OBTDataGridInterface.getOriginFieldName(column), privacy.value);
                this._realGridProvider.setRowState(dataIndex, rowState);
            }
        }
    }

    public isUsePrivacy(columnName: string, column?: IColumn): boolean {
        column = !column ? this.getColumnByName(columnName) : column;
        return column && column.usePrivacy ? true : false;
    }

    public getPrivacyBehavior(columnName: string, column?: IColumn): PrivacyBehaviorEnum {
        column = !column ? this.getColumnByName(columnName) : column;
        return (column ? (column.privacyBehavior || this.gridOption.privacyBehavior) : this.gridOption.privacyBehavior) || PrivacyBehaviorEnum.viewByButton;
    }

    public getPrivacyOriginValue(rowIndex: number, columnName: string, column?: IColumn): string | undefined | null {
        try {
            if (!this.isReady) {
                throw new Error('OBTDataGridInterface.getValue: 그리드가 준비되지 않았습니다. ');
            }

            column = !column ? this.getColumnByName(columnName) : column;
            if (!column) {
                throw new Error("OBTDataGridInterface.getValue: 컬럼을 찾을 수 없습니다. -  " + columnName);
            }

            // 옵션에 따른 후처리
            return this._gridView.getValue(rowIndex, OBTDataGridInterface.getOriginFieldName(column));
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    public setPrivacyModifiedValue(rowIndex: number, columnName: string, modifiedValue: string, column?: IColumn): void {
        column = !column ? this.getColumnByName(columnName) : column;
        const privacy = this.getPrivacy(rowIndex, columnName, column);
        if (privacy && column) {
            privacy.modifiedValue = this.getModifiedValue(modifiedValue);
            this.gridView.setValue(rowIndex, OBTDataGridInterface.getOriginFieldName(column), privacy.value);
        }
    }

    public getPrivacy(rowIndex: number, columnName: string, column?: IColumn): Privacy | undefined {
        const originValue = this.getPrivacyOriginValue(rowIndex, columnName, column);
        return new Privacy(originValue);
    }

    public getPrivacyKey(rowIndex: number, columnName: string): string | undefined | null {
        const privacy = this.getPrivacy(rowIndex, columnName);
        return privacy ? privacy.privacyKey : null;
    }

    public async getPrivacyValues(e: {
        rowIndexes: number[],
        columns: IColumn[],
        accessType: 'read' | 'download' | 'print' | 'program',
        force?: boolean,
        cancellation?: () => boolean,
        readCallback?: (e: { rowIndex: number, column: IColumn, privacy: Privacy }[]) => void
    }): Promise<void> {
        // 조회 대상 얻기
        const { rowIndexes, columns, accessType, force, readCallback, cancellation } = e;
        const isCancelled = () => (!cancellation ? false : cancellation());
        const readRowIndexes = force ? rowIndexes : rowIndexes.filter(rowIndex => {
            return columns.find(column => {
                const privacy = this.getPrivacy(rowIndex, column.name, column);
                return privacy && this.isPrivacyMasked(rowIndex, column.name, column) ? true : false;
            }) ? true : false;
        });

        // 조회
        const progressLabelText = OrbitInternalLangPack.getText('WE000028533', '개인정보를 조회하고 있습니다.');
        try {
            this.clearProgress();
            if (readRowIndexes && readRowIndexes.length > 0) {
                await this.setProgress(0, true, progressLabelText, true, cancellation);
                const pagingSize = 60;
                for (let i = 0; i < readRowIndexes.length && !isCancelled(); i += pagingSize) {
                    const range = (i + pagingSize < readRowIndexes.length ? i + pagingSize : readRowIndexes.length);
                    const pagedRowIndexes = readRowIndexes.slice(i, range) as number[];
                    this.setProgress(Math.round((range / readRowIndexes.length) * 100), true, progressLabelText, false, cancellation);
                    const result = await this.invokePromiseEvent<Events.PrivacyEventArgs, { privacyKey: string, privacyValue: string }[]>(this.onGetPrivacy,
                        new Events.PrivacyEventArgs(this, accessType, columns.map(column => column.name), pagedRowIndexes.map(rowIndex => {
                            const values = this.getValues(rowIndex);
                            columns.forEach(column => {
                                const fieldName = OBTDataGridInterface.getFieldName(column);
                                const originFieldName = OBTDataGridInterface.getOriginFieldName(column);
                                values[fieldName] = values[originFieldName];
                            });
                            return values;
                        })));
                    if (!result.isCancel && result.returnData && !isCancelled()) {
                        const resultMap = result.returnData.reduce((accu, data) => {
                            accu[data.privacyKey] = data.privacyValue;
                            return accu;
                        }, {} as any);
                        const pagedResults = pagedRowIndexes.flatMap(rowIndex => {
                            return columns.map(column => {
                                const privacy = this.getPrivacy(rowIndex, column.name, column);
                                if (privacy && privacy.privacyKey && resultMap.hasOwnProperty(privacy.privacyKey)) {
                                    privacy.privacyValue = new Privacy(resultMap[privacy.privacyKey]).privacyValue;
                                    return {
                                        rowIndex,
                                        column,
                                        privacy: privacy
                                    }
                                }
                                return null;
                            }).filter(item => item) as { rowIndex: number, column: IColumn, privacy: Privacy }[];
                        });
                        if (readCallback && !isCancelled()) {
                            readCallback(pagedResults);
                            const currentRowIndex = this.getSelectedIndex();
                            if (pagedRowIndexes.includes(currentRowIndex)) {
                                this.invokeEvent<Events.PrivacyRetrievedEventArgs>(this.onPrivacyRetrieved, new Events.PrivacyRetrievedEventArgs(this, columns.map(column => column.name)));
                            }
                        }
                    } else {
                        break;
                    }
                }
            }
        } catch (error) {
            throw error;
        } finally {
            await this.setProgress(100, false, progressLabelText, true, cancellation);
        }
    }

    private async getPrivacyValueRead(rowIndex: number, columnName: string, column?: IColumn): Promise<string | undefined | null> {
        await this.getPrivacyValues({
            rowIndexes: [rowIndex],
            columns: [(!column ? this.getColumnByName(columnName) : column) as IColumn],
            accessType: 'read',
            readCallback: (list) => {
                list.forEach(item => this.storePrivacyValue(item));
            }
        });
        const privacy = this.getPrivacy(rowIndex, columnName, column);

        return privacy ? privacy.privacyValue : undefined;
    }

    public async getPrivacyValue(rowIndex: number, columnName: string, column?: IColumn): Promise<string | undefined | null> {
        await this.getPrivacyValues({
            rowIndexes: [rowIndex],
            columns: [(!column ? this.getColumnByName(columnName) : column) as IColumn],
            accessType: 'program',
            readCallback: (list) => {
                list.forEach(item => this.storePrivacyValue(item));
            }
        });
        const privacy = this.getPrivacy(rowIndex, columnName, column);

        return privacy ? privacy.privacyValue : undefined;
    }

    public retrievePrivacies(columnNames?: string[]): Promise<void> {
        return new Promise<void>((resolve) => {
            const columns = this.getFlatColumns(true);
            const privacyColumns = columns.filter((column) => {
                if (columnNames) {
                    return columnNames.includes(column.name);
                } else {
                    return this.isUsePrivacy(column.name, column) && this.getPrivacyBehavior(column.name, column) === PrivacyBehaviorEnum.always ? true : false;
                }
            });
            if (privacyColumns && privacyColumns.length > 0) {
                if (this.gridView.isItemEditing()) {
                    this.gridView.cancelEditor();
                }
                this.clearRetrievePrivacies();
                const token = uuid();
                this._retrievePrivaciesToken = token;
                const isCancelled = () => {
                    return this._retrievePrivaciesToken !== token;
                };
                setTimeout(async () => {
                    try {
                        if (!isCancelled()) {
                            const columns = this.getFlatColumns(true);
                            let startRowIndex = -1, endRowIndex: number | undefined = -1;
                            startRowIndex = this.gridView.getTopItem();
                            startRowIndex = startRowIndex < 0 ? 0 : startRowIndex;
                            const visibleColumn = columns.find(column => this.gridView.getCellBounds(startRowIndex, column.name) != null);
                            endRowIndex = visibleColumn ? [...new Array(this.getRowCount() - startRowIndex)].map((dummy, index) => index + startRowIndex).find(rowIndex => this.gridView.getCellBounds(rowIndex, visibleColumn.name) == null) : -1;
                            if (endRowIndex === undefined || endRowIndex <= 0) {
                                endRowIndex = this.getRowCount() - 1;
                            }
                            if (startRowIndex > endRowIndex) {
                                startRowIndex = (() => { const swap = endRowIndex; endRowIndex = startRowIndex; return swap; })();
                            }
                            await this.getPrivacyValues({
                                rowIndexes: [...new Array(endRowIndex - startRowIndex)].map((dummy, index) => index + startRowIndex),
                                columns: privacyColumns,
                                accessType: 'read',
                                readCallback: (list) => {
                                    this.gridView.beginUpdate();
                                    list.forEach(item => this.storePrivacyValue(item));
                                    this.gridView.endUpdate();
                                },
                                cancellation: isCancelled
                            })
                        }
                    } catch (error) {
                        throw error;
                    } finally {
                        if (!isCancelled()) {
                            this.clearRetrievePrivacies();
                        }
                        resolve();
                    }
                }, 0);
            } else {
                resolve();
            }
        });
    }

    public clearRetrievePrivacies() {
        this._retrievePrivaciesToken = null;
    }

    public isRetrievePrivacies() {
        return this._retrievePrivaciesToken !== null;
    }

    public getPrivacyComponentHandler = async (id: string): Promise<string> => {
        const value = await this.getPrivacyValueRead(this.getSelectedIndex(), id);
        return value || '';
    }

    public isPrivacyMaskedComponentHandler = (id: string): boolean => {
        return this.isPrivacyMasked(this.getSelectedIndex(), id);
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class OBTDataGridEmptyDataRowManager {

    /**
     * @internal
     * 리얼그리드에서 관리되지 않는 empty state인 dataRow(itemIndex아님)를 담고있는 어레이
     */
    private _emptyDataRows: number[] = [];

    constructor() {
        this._emptyDataRows = [];
    }

    public setEmptyDataRows(emptyDataRows: number[]) {
        if (emptyDataRows === null || emptyDataRows === undefined) {
            this._emptyDataRows = []
        } else {
            this._emptyDataRows = emptyDataRows;
        }
    }

    public isEmptyDataRow(dataRow: number): boolean {
        if (this._emptyDataRows.some(item => item === dataRow)) {
            return true;
        }
        return false;
    }

    public clearDataRows() {
        this._emptyDataRows = [];
    }

    public hasDataRow(dataRow: number) {
        return this._emptyDataRows.includes(dataRow)
    }
}