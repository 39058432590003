import React from 'react'
import reactCSS from 'reactcss'
import tinycolor from 'tinycolor2'
import EditableInput from './EditableInput'


export const OBTColorPickerField = ({ onChange, rgb, hsv, hex }) => {
    const styles = reactCSS({
        'default': {
            fields: {
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '5px',
                paddingBottom: '1px',
                width: '188px',
                position: 'relative',
            },
            RGBwrap: {
                position: 'relative',
                width: '90px',
                display: 'inline-block'
            },
            RGBinput: {
                marginLeft: '55%',
                width: '28%',
                height: '18px',
                border: '1px solid #888888',
                boxShadow: 'inset 0 1px 1px rgba(0,0,0,.1), 0 1px 0 0 #ECECEC',
                marginBottom: '5px',
                fontSize: '13px',
                paddingLeft: '3px',
                marginRight: '10px',
            },
            RGBlabel: {
                left: '0px',
                width: '50px',
                textTransform: 'uppercase',
                fontSize: '13px',
                height: '18px',
                lineHeight: '22px',
                position: 'absolute',
            },
            HEXwrap: {
                position: 'relative',
                width: '130px'
            },
            HEXinput: {
                marginLeft: '55%',
                width: '44%',
                height: '18px',
                border: '1px solid #888888',
                boxShadow: 'inset 0 1px 1px rgba(0,0,0,.1), 0 1px 0 0 #ECECEC',
                marginBottom: '6px',
                fontSize: '13px',
                paddingLeft: '3px',
            },
            HEXlabel: {
                position: 'absolute',
                top: '0px',
                left: '0px',
                textTransform: 'uppercase',
                fontSize: '13px',
                height: '18px',
                lineHeight: '22px',
            },
            fieldSymbols: {
                position: 'relative',
                top: '-5px',
                left: '-8px',
                fontSize: '13px',
            },
            symbol: {
                height: '20px',
                lineHeight: '22px',
                paddingBottom: '7px',
            },
        },
    })

    const isValidHex = (hex) => {
        // disable hex4 and hex8
        const lh = (String(hex).charAt(0) === '#') ? 1 : 0
        return hex.length !== (4 + lh) && hex.length < (7 + lh) && tinycolor(hex).isValid()
    }

    const handleChange = (data, e) => {
        if (data['html(t): #']) {
            isValidHex(data['html(t): #']) && onChange({
                hex: data['html(t): #'],
                source: 'hex',
            }, e)
        } else if (data['빨강(r):'] || data['녹색(g):'] || data['파랑(b):'] || data['빨강(r):'] === 0 || data['녹색(g):'] === 0 || data['파랑(b):'] === 0) {
            onChange({
                r: data['빨강(r):'] === 0 ? '0' : data['빨강(r):'] || rgb.r,
                g: data['녹색(g):'] === 0 ? '0' : data['녹색(g):'] || rgb.g,
                b: data['파랑(b):'] === 0 ? '0' : data['파랑(b):'] || rgb.b,
                source: 'rgb',
            }, e)
        } else if (data['색상(h):'] || data['채도(s):'] || data['명도(v):'] || data['색상(h):'] === 0 || data['채도(s):'] === 0 || data['명도(v):'] === 0) {
            onChange({
                h: data['색상(h):'] === 0 ? String(data['색상(h):']) : data['색상(h):'] || hsv.h,
                s: Number(data['채도(s):']) === 1 ? 0.01 : data['채도(s):'] === 0 ? String(data['채도(s):']) : data['채도(s):'] || hsv.s,
                v: Number(data['명도(v):']) === 1 ? 0.01 : data['명도(v):'] === 0 ? String(data['명도(v):']) : data['명도(v):'] || hsv.v,
                source: 'hsv',
            }, e)
        }
    }

    return (
        <>
            <div style={styles.fields}>
                <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
                    <EditableInput
                        style={{ wrap: styles.RGBwrap, input: styles.RGBinput, label: styles.RGBlabel }}
                        label="빨강(r):"
                        value={rgb.r}
                        onChange={handleChange}
                        max={255}
                    />
                    <EditableInput
                        style={{ wrap: styles.RGBwrap, input: styles.RGBinput, label: styles.RGBlabel }}
                        label="녹색(g):"
                        value={rgb.g}
                        onChange={handleChange}
                        max={255}
                    />
                    <EditableInput
                        style={{ wrap: styles.RGBwrap, input: styles.RGBinput, label: styles.RGBlabel }}
                        label="파랑(b):"
                        value={rgb.b}
                        onChange={handleChange}
                        max={255}
                    />
                </div>
                <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
                    <EditableInput
                        style={{ wrap: styles.RGBwrap, input: styles.RGBinput, label: styles.RGBlabel }}
                        label="색상(h):"
                        value={Math.round(hsv.h)}
                        onChange={handleChange}
                        max={359}
                    />
                    <EditableInput
                        style={{ wrap: styles.RGBwrap, input: styles.RGBinput, label: styles.RGBlabel }}
                        label="채도(s):"
                        value={Math.round(hsv.s * 100)}
                        onChange={e => { handleChange(e) }}
                        max={100}
                    />
                    <EditableInput
                        style={{ wrap: styles.RGBwrap, input: styles.RGBinput, label: styles.RGBlabel }}
                        label="명도(v):"
                        value={Math.round(hsv.v * 100)}
                        onChange={handleChange}
                        max={100}
                    />
                </div>

                <div style={styles.fieldSymbols}>
                    <div style={styles.symbol}>°</div>
                    <div style={styles.symbol}>%</div>
                    <div style={styles.symbol}>%</div>
                </div>

            </div>
            <EditableInput
                style={{ wrap: styles.HEXwrap, input: styles.HEXinput, label: styles.HEXlabel }}
                label="html(t): #"
                value={hex.replace('#', '')}
                onChange={handleChange}
            />
        </>
    )
}

export default OBTColorPickerField