/**
 * Component OBTDropDownList
 * Luna - Orbit 개발시 템플릿 으로 사용.
 * @version 0.1
 * @author 전주빈
 * @see common.js
 */
import * as React from 'react';
import LUXSelectField from 'luna-rocket/LUXSelectField';
import { Events, CommonProps, CompositeProps, Util, Functions, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import OBTTooltip, { IOBTTooltip } from '../OBTTooltip/OBTTooltip';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { OBTContext } from '../OBTPageContainer/OBTPageContainer';
import GuideMessageRenderer from '../Common/GuideMessageRenderer';
import OBTDropDownList2 from '../OBTDropDownList2';
import { removeTrashElement } from '../Common/OrbitInternalUtil';

/**
 * 컴포넌트에서 보여주는 형식 속성입니다.
 */
export enum DisplayType {
    /**
     * numbertext : 숫자. 텍스트
     * text : 텍스트
     * valueText : 값. 텍스트 (default)
     */
    'numbertext' = 'numbertext',
    'text' = 'text',
    'valueText' = 'valueText'
}

/**
 * CSS Modules 사용방식
 * styles.[className]
 * {@code <div className={styles.required}}
 */
const styles = require('./OBTDropDownList.module.scss');

/**
 * 컴포넌트 입력필드에서 보여지는 value값의 리스트를 정의하는 속성입니다.
 */
interface TypeList {
    /**
     * value : 값
     */
    value: string,
    /**
     * labelText : 텍스트
     */
    labelText: any,
    /**
     * labelText : 텍스트
     */
    labelSubText?: string,
    /**
     * imageUrl : 이미지
     */
    imageUrl?: string,

    originalItem?: any,

    displayValue: string
}

/**
 * PropType 정의
 * Events, CommonProps, CompositeProps 에 미리 지정된 Prop interface 를 충분히 활용한다.
 * extends 로 인터페이스 상속을 통해 사용된다.
 * 공용 Api 를 사용하려면 CommonProps.api 인터페이스를 확장한다.
 */
interface IOBTDropDownList extends CompositeProps.InputClass<string>, CommonProps.useSubLang, Events.onValidate<string>, Events.onMouseDown,
    Events.onMouseMove, Events.onMouseUp, Events.onMouseLeave, Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress, Events.onKeyUp {
    /**
    * 컴포넌트 입력필드에서 보여지는 value값의 리스트를 정의하는 속성입니다.
    */
    list: Array<any>,
    /**
    * 컴포넌트에서 보여주는 형식 속성입니다.
    */
    displayType?: DisplayType,
    /**
    * 틀팁에 대한 설정 속성입니다.
    */
    tooltip?: IOBTTooltip,
    /**
    * 컴포넌트에서 데이터소스를 이용하여 매핑 정의하는 속성입니다.
    */
    fieldName?: TypeList,
    /**
    * @internal
    */
    boxStyle?: any,
    /**
     * 새롭게 제작된 OBTDropDownList2 컴포넌트로 대체할 때 사용되는 속성입니다. 
     * true = OBTDropDownList사용 | false = OBTDropDownList2 사용
     */
    useOldVersion?: boolean
}

/**  
 * @internal
 * State 정의
 */
interface State extends hasError {
    list: Array<any>,
    displayList: Array<any>,
    useImage: boolean,
    keyPressValue: string,
    moveFocusContext?: {
        prevProps: string,
        nextProps: string
    }
}

/**
 * withApi() HOC 를 사용하면 Props 로 Api 를 사용할 수 있다.
 * api 가 Optional 로 선언되었기에 내부에서 ! 오퍼레이터를 사용해서 호출한다.
 * {@code this.props.api!.test();}
 */
export default class OBTDropDownList extends React.Component<IOBTDropDownList, State> implements Functions.IFocusable, Functions.IRequired {
    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        {
            name: 'list', type: 'Array<any>', description: "컴포넌트 입력필드에서 보여지는 value값의 리스트를 정의하는 속성입니다."
                + "\n* Array = <value: string, labelText: string, imageUrl?:string>"
                + "\n* list 속성 사용 방법"
                + "\n1. 사용자가 직접 list에 형식대로 넣어서 사용합니다."
                + "\n2. dataSource를 list에 그대로 담아서 fieldName에서 매핑 할 데이터를 입력해서 사용합니다."
        },
        {
            name: 'useOldVersion',
            type: 'boolean',
            default: true,
            description: "OBTDropDownList2로 대체하여 렌더링할 수 있는 옵션입니다."
                + "\ntrue : 기존 OBTDropDownList로 렌더링"
                + "\nfalse : OBTDropDownList2로 렌더링"
            ,
            optional: true
        },
        { name: "displayType", type: toEnumType(DisplayType), description: "컴포넌트에서 보여주는 형식 속성입니다.", optional: true, default: "valueText" },
        {
            name: 'fieldName', type: [{
                value: { type: CommonType.string, description: "값" },
                labelText: { type: ['string', 'node'], description: "텍스트" },
                labelSubText: { type: CommonType.string, description: "서브 텍스트", optional: true }
            }], description: "컴포넌트에서 데이터소스를 이용하여 매핑 정의하는 속성입니다.", optional: true
        },
        CommonDefinitions.InputClass(CommonType.string),
        CommonDefinitions.useSubLang(),
        CommonDefinitions.tooltip(),
        CommonDefinitions.InnerFunc(),
        CommonDefinitions.onValidate(CommonType.string),
        CommonDefinitions.Event(),
    );

    private moveFocusInvoked: boolean = false;

    ///////////////////////////////////////////////////////////////////////////// Initialize
    public static DisplayType = DisplayType;

    context!: React.ContextType<typeof OBTContext>

    /**
     * Default Props 설정
     */
    public static defaultProps = {
        disabled: false,
        readonly: false,
        required: false,
        frozen: false,
        useOldVersion: true
    }
    /**
     * State 정의
     */
    public state: State = {
        list: [],
        displayList: [],
        useImage: false,
        keyPressValue: ''
    }

    /**
     * Ref 정의
     */
    public myRefs = {
        ref: React.createRef<LUXSelectField>(),
        dropDownList2Ref: React.createRef<OBTDropDownList2>()
    }

    /**
     * 초기값 세팅
     * @param nextProps 
     * list 세팅
     * 1. typeList : value , labelText 통일
     * 2. displayList: value, labetText (diplay식 바꾸기)
     * @param prevState 
     */
    static getDerivedStateFromProps(nextProps: IOBTDropDownList, prevState: State): any {
        try {
            if (nextProps.list !== prevState.list) {
                const displayType = nextProps.displayType || DisplayType.valueText;
                const fieldName = Object.assign({
                    value: 'value',
                    labelText: 'labelText',
                    labelSubText: 'labelSubText',
                    imageUrl: 'imageUrl'
                }, nextProps.fieldName || {});
                let useImage = false;
                const displayList = (nextProps.list || []).length <= 0 ? [{ value: '', labelText: '', labelSubText: '', displayValue: '' }] :
                    (nextProps.list || []).map((item, index) => {
                        const value = item[fieldName.value];
                        const labelText = item[fieldName.labelText] || item.text;
                        const labelSubText = item[fieldName.labelSubText] || item.text;
                        const imageUrl = item[fieldName.imageUrl];
                        const displayValue = displayType === DisplayType.numbertext ? String(index) : value;
                        if (imageUrl && !useImage) {
                            useImage = true;
                        }
                        return {
                            value,
                            labelText,
                            labelSubText,
                            imageUrl,
                            displayValue,
                            image: imageUrl,
                            text: ((value: string, labelText: any) => {
                                return typeof (labelText) === 'string' && value && (displayType === DisplayType.valueText || displayType === DisplayType.numbertext) ?
                                    `${value}. ${labelText}` : labelText;
                            })(displayValue, Util.cl(labelText, labelSubText, nextProps.useSubLang))
                        };
                    });
                return {
                    list: nextProps.list,
                    displayList,
                    useImage
                };
            }
            return null;
        } catch (e) {
            return Util.getErrorState(e);
        }
    }

    // component 가 render 될때 호출됨.
    render() {
        if (this.props.useOldVersion === false) {
            return <OBTDropDownList2
                ref={this.myRefs.ref}
                value={this.props.value}
                list={this.props.list}
                displayType={this.props.displayType}
                fieldName={this.props.fieldName}
                className={this.props.className}
                required={this.props.required}
                tooltip={this.props.tooltip}
                width={this.props.width}
                height={this.props.height}
                disabled={this.props.disabled}
                readonly={this.props.readonly}
                frozen={this.props.frozen}
                onFocus={this.props.onFocus}
                onBlur={this.props.onBlur}
                onChange={this.props.onChange}
                onValidate={this.props.onValidate}
                onKeyPress={this.props.onKeyPress}
                onMoveFocus={e => this.handleMoveFocus(e.direction)}
                onKeyDown={this.props.onKeyDown}
                onKeyUp={this.props.onKeyUp}
                onMouseMove={this.props.onMouseMove}
                onMouseDown={this.props.onMouseDown}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave} />
        }
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        );
    }

    renderComponent = () => {
        //리스트가 7개이상이면 늘어나고, 7개이하이면 스크롤
        const listAutoHeight = this.state.displayList && this.state.displayList.length > 7 ? false : true;
        const props: any = {
            //기본
            ref: this.myRefs.ref,
            afterCheckChange: true, //관리 
            checkObjectList: true,  //array보다는 object
            defaultData: this.props.value,
            selectFieldData: this.state.displayList,  //list 데이터들

            //기능
            listAutoHeight: listAutoHeight,
            disabled: this.props.disabled,
            useImage: this.state.useImage,  //이미지사용
            imagePosition: this.state.useImage ? "left" : "right",

            //event
            handleChoiceData: this.handleChange,
            onBlur: this.onBlur,
            onFocus: this.onFocus,
            onMoveFocus: this.onMoveFocus,
            onKeyDown: this.handleKeyDown,

            //style
            style: Object.assign({}, Util.getInputStateStyle(this.props)),
            selectFieldInputStyle: Object.assign({}, Util.getInputStateStyle(this.props), { fontFamily: 'inherit' }), //맨위 텍스트
            selectFieldInputBoxStyle: Object.assign({}, Util.getInputStateStyle(this.props)),
            tooltipProps: {
                float: 'middle',
                tooltipInnerDivStyle: {
                    borderRadius: '3px',
                    backgroundColor: '#fff',
                    borderColor: '#a6a6a6',
                    color: '#000'
                },
                arrowStyle: {
                    backgroundColor: '#fff', color: '#000',
                    transition: 'transform 0.1s ease-out',
                    boxShadow: '1px 1px 0 0 #a6a6a6'
                }
            },
            selectFieldListBoxStyle: Object.assign({}, this.props.boxStyle),
            selectFieldListStyle: {
                style: {
                    background: '#e9f5ff',
                    fontFamily: 'inherit',
                },
                focus: {
                    background: '#e9f5ff',
                    color: '#1c90fb'
                },
                hover: {
                    background: '#e9f5ff'
                }
            }
        }

        if (this.props.width !== undefined) {
            props.fullWidth = true;
        }
        if (this.props.list !== undefined && this.props.list.length > 7) {
            props.selectFieldListBoxStyle = Object.assign({}, { height: '154px' })  //7개 높이 (22 * 7)
        }
        const dropDownList =
            <LUXSelectField
                {...props}
            />
        return (
            <OBTTooltip {...this.props.tooltip}
                className={Util.getClassNames(styles.default, this.props.className)}
                style={Util.getWrapperStyle(this.props)}
                overrideSize={false}
                onMouseDown={this.props.onMouseDown}
                onMouseMove={this.props.onMouseMove}
                onMouseUp={this.props.onMouseUp}
                onMouseLeave={this.props.onMouseLeave}
                onMouseEnter={this.props.onMouseEnter}
                onKeyUp={this.props.onKeyUp}
                onKeyDown={this.props.onKeyDown}
                onKeyPress={this.handleKeyPress}
                rootProps={{
                    id: this.props.id,
                    'data-orbit-component': 'OBTDropDownList'
                }}
            >
                {dropDownList}
            </OBTTooltip>
        )
    }

    ///////////////////////////////////////////////////////////////////////////// Logics

    public focus(isLast: boolean = false): void {
        if (!this.props.frozen && !this.props.disabled) {
            if (this.props.useOldVersion === false && this.myRefs.dropDownList2Ref.current) {
                this.myRefs.dropDownList2Ref.current.focus();
            }
            this.myRefs.ref.current.focus();
        }
    }

    /**
     * @internal
     * 수정가능 함수
     */
    private get canEdit(): boolean {
        return !this.props.disabled && !this.props.readonly;
    }

    public isEmpty(): boolean {
        return this.props.value === '' ? true : false;
    }

    public validate() {
        return !(this.props.required === true && this.isEmpty());
    }

    /**
     * @internal
     * 키 버튼 클릭시 value 값 매칭해서 value 바꾸는 함수
     */
    private keyValueChange(value: string, displayType: DisplayType): void {
        const keyPressValue = this.state.keyPressValue + String(value);
        const founds = this.state.displayList.filter(item => item.displayValue.startsWith(keyPressValue));
        //중복이 2개이상일경우
        if (founds.length > 1) {
            this.setState({ keyPressValue: keyPressValue });
            setTimeout(() => {
                this.handleChange(founds[0].value)
            }, 0)
        } else if (founds.length === 1) { //1개일때 
            this.closeDropDownList();
            setTimeout(() => {
                this.handleChange(founds[0].value)
            }, 0)
        } else {
            this.setState({ keyPressValue: '' })
        }
    }

    /**
     * @internal
     * 닫기 함수 
     */
    private closeDropDownList(): void {
        if (this.myRefs.ref.current &&
            this.myRefs.ref.current.rootRef) {
            const root = this.myRefs.ref.current.rootRef;
            const bound = root.getBoundingClientRect();
            const element = document.elementFromPoint(bound.left, bound.top) as HTMLElement;
            if (element) {
                element.click();
                this.setState({ keyPressValue: '' })
            }
        }
    }

    ///////////////////////////////////////////////////////////////////////////// Event Handlers

    /**
    * @internal
    * Luna - Rocket 의 onMoveFocus 이벤트 핸들러를 받아 재호출
    * @param {string} direction
    */
    private onMoveFocus = (direction: string) => {
        this.moveFocusInvoked = true; // MoveFocus 호출됨 toggle
        // if (direction === 'enter') {
        //     return;
        // }
        this.invokeMoveFocus(direction);
    }

    private invokeMoveFocus = (direction: string) => {
        if (this.props.required && this.isEmpty() && ['right', 'down', 'enter'].includes(direction)) return;
        Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
    }

    private handleMoveFocus = (direction: string) => {
        if (this.props.required && this.isEmpty() && ['right', 'down', 'enter'].includes(direction)) {
            return;
        }
        Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
    }

    /**
    * @internal
    * Luna - Rocket 의 onFocus 이벤트 핸들러를 받아 재호출
    */
    private onFocus = e => {
        Util.invokeEvent<Events.FocusEventArgs>(this.props.onFocus, new Events.FocusEventArgs(this, e));

        GuideMessageRenderer.handleFocus(
            this, this.props.id, this.context
        );
    }

    /**
    * @internal
    * Luna - Rocket 의 onBlur 이벤트 핸들러를 받아 재호출
    */
    private onBlur = () => {
        // onBlur 시 Rocket SelectField 의 오류로 내부의 onBlur 가 호출되지 않는 오류 확인하여 수정함.
        if (this.myRefs.ref.current) {
            window.removeEventListener('keydown', this.myRefs.ref.current.handleListKeyEvent);
        }
        Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
        removeTrashElement();
        GuideMessageRenderer.handleBlur(
            this.context,
            this.props.id
        );
    }

    /**
     * @internal
     * Luna - Rocket 의 onKeyDown 이벤트 핸들러를 받아 재호출
     */
    private handleKeyDown = (e: React.KeyboardEvent) => {
        if (this.moveFocusInvoked) {
            this.moveFocusInvoked = false;
            e.preventDefault();
        }
    }

    /**
     * @internal
     * Luna - Rocket 의 onKeyPress 이벤트 핸들러를 받아 재호출
     * 키 입력시 선택 동시에 닫힘
     */
    private handleKeyPress = (e: Events.KeyEventArgs): void => {
        Util.invokeEvent<Events.KeyEventArgs>(this.props.onKeyPress, e);
        const value = String.fromCharCode(e.event.charCode);
        if (value) {
            const displayType = this.props.displayType === undefined ? DisplayType.valueText : this.props.displayType;
            switch (displayType) {
                case DisplayType.numbertext:
                    this.keyValueChange(value, DisplayType.numbertext);
                    break;
                case DisplayType.text:
                    this.keyValueChange(value, DisplayType.text);
                    break;
                case DisplayType.valueText:
                    this.keyValueChange(value, DisplayType.valueText);
                    break;
                default:
                    break;
            }
        }
    }

    /**
    * @internal
    * Luna - Rocket 의 onChange 이벤트 핸들러를 받아 재호출
    * @param {string} direction
    */
    private handleChange = (value: string) => {
        if (this.props.value !== value) {
            let typeList: Array<TypeList> = this.state.displayList;

            const oldValue: string = this.props.value
            const item: Array<any> = [];
            const oldItem: Array<any> = [];
            if (typeList) {
                typeList.forEach(originList => {
                    if (originList.value === oldValue) oldItem.push(originList);
                    if (originList.value === value) item.push(originList);
                });
            }

            if (!Util.invokeEvent<Events.ValidateEventArgs<string>>(this.props.onValidate, new Events.ValidateEventArgs<string>(this, oldValue, value, oldItem, item))) {
                return;
            }
            if (this.canEdit) {
                Util.invokeEvent<Events.ChangeEventArgs<string>>(this.props.onChange, new Events.ChangeEventArgs<string>(this, value, item));
            }
        }

        this.setState({
            moveFocusContext: { prevProps: this.props.value, nextProps: value }
        });

        // setTimeout(() => {
        //     this.invokeMoveFocus('enter');            
        // }, 500);
    }

    componentDidUpdate(prevProps: IOBTDropDownList, prevState: State) {
        // if(this.props.value !== prevProps.value) {
        //     setTimeout(() => {
        //         this.invokeMoveFocus('enter');
        //     }, 0);
        // }
        if (this.state.moveFocusContext &&
            prevProps.value === this.state.moveFocusContext.prevProps &&
            this.props.value === this.state.moveFocusContext.nextProps) {
            this.setState({ moveFocusContext: undefined }, () => this.invokeMoveFocus('enter'));
        }
    }
};

OBTDropDownList.contextType = OBTContext;