import React, { Component } from 'react';
import { hasError } from '../Common/CommonState';
import { Util } from '../Common';

interface IErrorBoundary {
    owner: React.Component<any, hasError>,
    render?: () => JSX.Element
}

class ErrorBoundary extends Component<IErrorBoundary, hasError> {
    static getDerivedStateFromError(error: any) {
        return Util.getErrorState(error);
    }

    componentDidCatch(error: any, info: any) {
        Util.logError(error)
    }

    render() {
        if (Util.hasError(this.props.owner.state) || Util.hasError(this.state)) {
            const error = this.props.owner.state.latestError || this.state.latestError;
            return Util.renderError(error);
        }

        if (this.props.render) {
            try {
                return this.props.render();
            } catch (e) {
                return Util.renderError(e);
            }
        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundary;