import React from 'react'
import { Events, CompositeProps, Util, Functions, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { OBTContext } from '../OBTPageContainer/OBTPageContainer';
import OBTTooltip, { IOBTTooltip } from '../OBTTooltip/OBTTooltip';
import { AlignType, PositionType } from '../OBTFloatingPanel/OBTFloatingPanel';
import moment from 'moment';
import icon from './icon-date.png';
import memoizeOne from 'memoize-one';
import UFOPeriodDateFieldDialog from '../UFO/UFOPeriodDateFieldDialog';
import UFODateFieldDialog from '../UFO/UFODateField/UFODateFieldDialog';
import UFOPeriodMonthFieldDialog from '../UFO/UFOPeriodMonthField/UFOPeriodMonthFieldDialog';
import UFOMonthFieldDialog from '../UFO/UFOMonthField/UFOMonthFieldDialog';
import OBTYearFieldDialog from '../OBTSingleYearPicker/OBTYearFieldDialog';
import WeekDateDialog from './WeekDateDialog';
import { OrbitInternalLangPack } from '../Common/Util';

const classNames = require('./OBTDatePickerRebuild.module.scss');

export enum FormatType {
    'YYYYMMDD' = 'YYYYMMDD',
    'YYYYMM' = 'YYYYMM',
    'YYYY' = 'YYYY'
}

export enum Type {
    'single' = 'single',
    'period' = 'period',
    'week' = 'week'
}

export enum FormatLength {
    'YYYYMMDD' = 8,
    'YYYYMM' = 6,
    'YYYY' = 4
}

export enum WeekdayEnum {
    'Sunday' = 'Sunday',
    'Monday' = 'Monday',
    'Tuesday' = 'Tuesday',
    'Wednesday' = 'Wednesday',
    'Thursday' = 'Thursday',
    'Friday' = 'Friday',
    'Saturday' = 'Saturday'
}

class Weekday {
    static getDate(value: string | Date, from: WeekdayEnum = WeekdayEnum.Sunday, to: WeekdayEnum = WeekdayEnum.Saturday) {
        const getIndex = (weekday: WeekdayEnum) => {
            switch (weekday) {
                case WeekdayEnum.Sunday: return 0;
                case WeekdayEnum.Monday: return 1;
                case WeekdayEnum.Tuesday: return 2;
                case WeekdayEnum.Wednesday: return 3;
                case WeekdayEnum.Thursday: return 4;
                case WeekdayEnum.Friday: return 5;
                case WeekdayEnum.Saturday: return 6;
                default: return -1;
            }
        }
        const fromIndex = getIndex(from);
        const toIndex = getIndex(to);

        let isSame = false;

        if (moment(value).day() === getIndex(to)) {
            isSame = true; // 사용자가 to로 설정한 값과 사용자가 클릭한 값이 동일할 경우
        }

        const date = () => typeof value === 'string' ? moment(value, 'YYYYMMDD') : moment(value);

        return ((from, to) => {
            return {
                from: isSame ? date().add('-6', 'd').format('YYYYMMDD') : from.format('YYYYMMDD'),
                fromDate: from.toDate(),
                to: isSame ? value.toString() : to.format('YYYYMMDD'),
                toDate: to.toDate()
            }
        })(date().day(from), fromIndex < toIndex ? date().day(to) : date().add(1, 'week').day(to));
    }
}

interface IOBTDatePicker extends CompositeProps.InputClass<any>, Events.onValidate<any>, Events.onClick, Events.onMouseDown,
    Events.onMouseMove, Events.onMouseUp, Events.onMouseLeave, Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress, Events.onKeyUp {
    /**
     *  날짜 포맷을 선택합니다.(ex YYYYMMDD, YYYYMM, YYYY)
     */
    format: FormatType,
    /**
     *  데이터의 타입이 single인지 period 인지 지정합니다.
     */
    type: Type,
    /**
     * 입력 가능한 날짜에 대한 범위의 최대(끝) 날짜를 지정하는 속성입니다.
     */
    max?: string,
    /**
     * 입력 가능한 날짜에 대한 범위의 최소(시작) 날짜를 지정하는 속성입니다.
     */
    min?: string,
    /**
     * 툴팁에 대한 설정입니다.
     */
    tooltip?: IOBTTooltip,
    /**
     *  input을 custom element로 쓸 때 사용합니다.
     */
    customInputElement?: any,
    /**
     *  날짜 선택을 위한 dialog의 정렬을 지정하는 속성입니다.
     */
    dialogAlign?: AlignType,
    /**
     *  날짜 선택을 위한 dialog의 위치를 지정하는 속성입니다.
     */
    dialogPosition?: PositionType,
    /**
     *  periodPicker의 상단 버튼들의 사용여부를 지정하는 속성입니다.
     */
    useControlButton?: boolean,
    /**
     *  periodPicker의 왼쪽 하단의 라벨 부분을 custom element로 쓸 때 사용합니다.
     */
    customLabel?: (from?: string, to?: string, onResetFrom?: () => void, onResetTo?: () => void) => any,
    /**
     * 단축키 사용 여부
     */
    useShortCut?: boolean,
    /**
     * data-orbit-component 타입
     */
    dataOrbitComponent?: string,
    /**
     *  periodPicker의 확인 버튼을 눌렀을때 발생하는 callback 함수 입니다..
     */
    onConfirm?: () => void,
    /**
     *  periodPicker의 취소 버튼을 눌렀을때 발생하는 callback 함수 입니다..
     */
    onCancel?: () => void,
    /**
     *  dateButton을 클릭할 때 발생하는 callback 함수 입니다.
     */
    onDateButtonClick?: (e: Events.CancelableEventArgs) => void,
    /**
     * 상태 ( disabled, readonly, required ) 를 가진 경우라도 기본 스타일을 유지합니다.
     */
    useStatelessStyle?: boolean,
    /**
     * period 에서 from, to 모두 입력되어야만 유효하다 판단합니다.
     */
    allowFullPeriodOnly?: boolean,

    inputStyle?: any,

    weekdayFrom?: WeekdayEnum,

    weekdayTo?: WeekdayEnum,

    showWeekday?: boolean
}

interface State extends hasError {
    _value?: any,
    editingContext?: any,
    focus?: boolean,
    openCodePicker?: boolean,
    dateDialogValue: Date | string | number | null,
}

interface IDataInput {
    className: string,
    format: FormatType,
    value: string,
    onChange: (target: any, value: string) => void,
    onMoveFocus: (direction: string) => void,
    readonly?: boolean,
    disabled?: boolean,
    frozen?: boolean,
    required?: boolean,
    size?: number,
    inputStyle?: any,
    focused?: boolean
}

class DateInput extends React.Component<IDataInput, any> {
    state = {};
    input = React.createRef<HTMLInputElement>();
    format = this.props.format;

    private get canEdit(): boolean {
        return !(this.props.readonly || this.props.disabled || this.props.frozen);
    }

    private isDate = (dateString: string) => {
        if (!this.props.required && (dateString || '').replace(/[-_]/g, '').length <= 0) return true;
        if (this.props.format.replace(/[-]/g, '').length === (dateString || '').replace(/[-_]/g, '').length) {
            return moment(`${dateString.replace(/-/g, '')}0101`.substring(0, 8)).isValid();
        }

        return false;
    }

    private setSelection = (selection: number, next: boolean) => {
        const input = this.input.current;
        if (input) {
            const value = input.value;
            if (selection < 0) selection = 0;
            else if (selection > value.length) selection = value.length;

            while (selection >= 0 && selection < value.length) {
                if (value[selection] === '-') {
                    selection = selection + (!next ? -1 : 1);
                }
                else {
                    input.setSelectionRange(selection, selection);
                    break;
                }
            }
        }
    }

    private setChange = () => {
        if (this.input.current) {
            const value = this.input.current.value.replace(/[-_]/g, '');
            if ((this.props.value || '') !== value) {
                if (!this.isDate(value)) {
                    this.setValue(this.props.value);
                } else {
                    if (this.props.onChange) {
                        this.props.onChange(this, value);
                    }
                }
            }
        }
    }

    private setMoveFocus = (direction: string) => {
        const input = this.input.current;
        if (input) {
            this.setChange();
            this.props.onMoveFocus(direction);
        }
    }

    private handleKeyDown = (e: React.KeyboardEvent) => {
        const input = this.input.current;
        if (input) {
            let value = input.value;
            switch (e.key) {
                case 'Delete':
                case 'Backspace':
                case ' ':
                    if (!this.canEdit) return;
                    const selection = {
                        start: input.selectionStart || 0,
                        end: input.selectionEnd || 0
                    };
                    if (selection.start === selection.end) {
                        if (e.key === 'Delete') {
                            while ((selection.end = selection.end + 1) < value.length &&
                                value[selection.end] === '-') { }
                        } else {
                            while ((selection.start = selection.start - 1) >= 0 &&
                                value[selection.start] === '-') { }
                        }
                    }
                    value = value.split('').map((ch, index) => {
                        if (index >= selection.start && index < selection.end) {
                            return ch === '-' ? '-' : '_';
                        }
                        return ch;
                    }).join('');

                    input.value = value.split('-').map(part => {
                        const length = part.length;
                        return (part.replace(/[_]/g, '') + '____').substr(0, length);
                    }).join('-');

                    this.setSelection(selection.start, e.key === 'Backspace' ? false : true);
                    e.preventDefault();
                    break;
                case 'ArrowLeft':
                    if (input.selectionStart === 0) {
                        this.setMoveFocus('left');
                        e.preventDefault();
                    }
                    break;
                case 'ArrowRight':
                    if (input.selectionEnd === value.length) {
                        this.setMoveFocus('right');
                        e.preventDefault();
                    }
                    break;
                case 'Enter':
                    this.setMoveFocus('enter');
                    e.preventDefault();
                    break;
                case 'ArrowUp':
                    this.setMoveFocus('up');
                    e.preventDefault();
                    break;
                case 'ArrowDown':
                    this.setMoveFocus('down');
                    e.preventDefault();
                    break;
                case 'Tab':
                    if (e.shiftKey) {
                        this.setMoveFocus('left');
                    } else {
                        this.setMoveFocus('enter');
                    }
                    e.preventDefault();
                    break;
                case 'Escape':
                    if (!this.canEdit) return;
                    this.setValue(this.props.value || '');
                    if (this.input.current) {
                        this.input.current.setSelectionRange(0, this.input.current.value.length);
                    }
                    e.preventDefault();
                    break;
            }
        }
    }

    private handleKeyPress = (e: React.KeyboardEvent) => {
        if (!this.canEdit) return;
        const input = this.input.current;
        if (input) {
            let value = input.value;
            const selection = {
                start: input.selectionStart || 0,
                end: input.selectionEnd || 0
            };
            const newChar = e.key;
            if ('0123456789'.indexOf(newChar) >= 0) {
                value = value.split('').map((ch, index) => {
                    if (index >= selection.start && index <= selection.end) {
                        return ch === '-' ? '-' : '_';
                    }
                    return ch;
                }).join('');

                let index = selection.start;
                while (index < value.length) {
                    if (value[index] === '-') {
                        index++;
                    } else {
                        value = ((value: string, index: number) => value.split('').map((ch, i) => {
                            if (index === i) return newChar;
                            return ch;
                        }).join(''))(value, index);
                        break;
                    }
                }
                input.value = value;
                this.setSelection(index + 1, true);

                setTimeout(() => {
                    if (input.selectionStart === input.value.length) {
                        this.setChange()
                    }
                }, 0)
            }
        }
        e.preventDefault();
    }

    private handlePaste = (e: React.ClipboardEvent) => {
        if (!this.canEdit) return;
        const input = this.input.current;
        if (input) {
            let value = input.value;
            const selection = {
                start: input.selectionStart || 0,
                end: input.selectionEnd || 0
            };
            const paste = (() => {
                const paste = e.clipboardData ? e.clipboardData.getData('text') : '';
                return paste.replace(/[^0123456789]/g, '');
            })();
            if (paste) {
                value = value.split('').map((ch, index) => {
                    if (index >= selection.start && index <= selection.end) {
                        return ch === '-' ? '-' : '_';
                    }
                    return ch;
                }).join('');

                this.setValue((value.substr(0, selection.start) + paste + value.substring(selection.start)).replace(/[-_]/g, ''));
                if (paste.length === this.props.format.length) {
                    input.setSelectionRange(0, input.value.length);
                } else {
                    this.setSelection(selection.start + paste.length, true);
                }
            }
        }
        e.preventDefault();
    }

    private handleCut = (e: React.ClipboardEvent) => {
        if (!this.canEdit) return;
        const input = this.input.current;
        if (input) {
            let value = input.value;
            const selection = {
                start: input.selectionStart || 0,
                end: input.selectionEnd || 0
            };

            if (selection.start !== selection.end) {
                if (e.clipboardData) {
                    e.clipboardData.setData('text', input.value.substring(selection.start, selection.end));
                }
            }

            value = value.split('').map((ch, index) => {
                if (index >= selection.start && index <= selection.end) {
                    return ch === '-' ? '-' : '_';
                }
                return ch;
            }).join('');

            input.value = value;
            this.setSelection(selection.start, false);
        }
        e.preventDefault();
    }

    private handleFocus = (e: React.FocusEvent) => {
        setTimeout(() => {
            if (this.input.current) {
                this.input.current.setSelectionRange(0, this.input.current.value.length);
            }
        }, 0)
    }

    private handleBlur = (e: React.FocusEvent) => {
        this.setChange();
    }

    private setValue = (value: string) => {
        if (this.input.current) {
            if (!this.props.focused && !this.props.value) {
                this.input.current.value = '';
            } else {
                this.input.current.value = [this.props.format.length >= 4 ? 4 : 0, this.props.format.length >= 6 ? 2 : 0, this.props.format.length >= 8 ? 2 : 0].filter(length => length > 0).map(length => {
                    const part = (value || '').substr(0, length) || '';
                    value = (value || '').substring(length) || '';
                    return part + '____'.substr(0, length - part.length);
                }).filter(part => part).join('-');
            }
        }
    }

    private handleChange = (e) => {
        if (this.input.current) {
            const value = this.input.current.value;
            const dateValueCheck = /^[0-9]+$/;
            if (!dateValueCheck.test(value)) {
                this.setValue(this.props.value);
                this.input.current.setSelectionRange(0, this.input.current.value.length);
            }
        }
    }

    render() {

        const props = {
            className: Util.getClassNames(
                this.props.format === FormatType.YYYYMMDD ? classNames.inputYMD : undefined,
                this.props.format === FormatType.YYYYMM ? classNames.inputYM : undefined,
                this.props.format === FormatType.YYYY ? classNames.inputY : undefined,
                this.props.className),
            ref: this.input,
            onKeyDown: this.handleKeyDown,
            onKeyPress: this.handleKeyPress,
            onPaste: this.handlePaste,
            onCut: this.handleCut,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            onChange: this.handleChange,
            readOnly: this.props.readonly,
            disabled: this.props.disabled || this.props.frozen,
            style: (() => {
                let width = 62;
                switch (this.props.format) {
                    case FormatType.YYYYMMDD:
                        width = 62;
                        break;
                    case FormatType.YYYYMM:
                        width = 45;
                        break;
                    case FormatType.YYYY:
                        width = 28;
                        break;
                    default:
                        break;
                }

                return {
                    width: width,
                    ...(this.props.inputStyle || {})
                }
            })()
        };
        return <input
            {...props} />
    }

    componentDidMount() {
        this.setValue(this.props.value || '');
    }

    componentDidUpdate() {
        this.setValue(this.props.value || '');
    }

    public focus = () => {
        if (this.input.current) {
            this.input.current.focus();
        }
    }
}

export default class OBTDatePicker extends React.Component<IOBTDatePicker, State> implements Functions.IFocusable, Functions.IRequired {

    ///////////////////////////////////////////////////////////////////////////// PropDefinition
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.InputClass(['from(필수) : string, to(필수) : string']),
        { name: "format", type: toEnumType(FormatType), description: "날짜 포맷을 선택합니다.(ex YYYYMMDD, YYYYMM, YYYY)", default: 'YYYYMMDD', optional: true },
        { name: 'type', type: toEnumType(Type), default: "single", description: "데이터의 타입이 single인지 period 인지 지정합니다.", optional: true },
        { name: 'min', description: '입력 가능한 날짜에 대한 범위의 최소(시작) 날짜를 지정하는 속성입니다.', optional: true, type: CommonType.string, default: 'addYears(new Date(), 100)' },
        { name: 'max', description: '입력 가능한 날짜에 대한 범위의 최대(끝) 날짜를 지정하는 속성입니다.', optional: true, type: CommonType.string, default: 'addYears(new Date(), -100)' },
        { name: 'customInputElement', type: CommonType.any, description: "input을 custom element로 쓸 때 사용합니다.", optional: true },
        { name: 'dialogAlign', type: toEnumType(AlignType), description: '날짜 선택을 위한 dialog의 정렬을 지정하는 속성입니다.', optional: true, default: 'near' },
        { name: 'dialogPosition', type: toEnumType(PositionType), description: '날짜 선택을 위한 dialog의 위치를 지정하는 속성입니다.', optional: true, default: 'bottom' },
        { name: 'useControlButton', type: CommonType.boolean, default: true, description: '날짜 다이얼로그의 control button 표시 여부를 지정하는 속성입니다.', optional: true },
        { name: "allowFullPeriodOnly", type: CommonType.boolean, default: false, optional: true, description: "Period 타입일 경우 from, to 모두 입력되어야지만 유효하다고 판단합니다. \n1. required 인 경우 from, to 가 반드시 입력되어야 입력이 종결됩니다. 빈 값은 허용되지 않습니다. \n2. required 가 아닌 경우, from, to 가 각각 비어있거나 입력되어야 입력이 종결됩니다. from 만 입력 되거나, to 만 입력되어도 유효하다 판단합니다. \n3. required 가 아니며, allowFullPeriodOnly 인 경우 from, to 모두 비어있거나 모두 입력되어야만 유효하다 판단합니다." },
        {
            name: 'customLabel', type: CommonType.function, parameters: [
                { name: 'from', type: CommonType.string, description: '시작일' },
                { name: 'to', type: CommonType.string, description: '마지막 일' },
                { name: 'onResetFrom', type: CommonType.function },
                { name: 'onResetTo', type: CommonType.function }
            ], optional: true
        },
        { name: 'useShortCut', type: CommonType.boolean, description: '단축키 사용 여부', optional: true },
        { name: 'onConfirm', type: CommonType.function, description: 'periodPicker의 확인 버튼을 눌렀을때 발생하는 callback 함수 입니다.', optional: true },
        { name: 'onCancel', type: CommonType.function, description: 'periodPicker의 취소 버튼을 눌렀을때 발생하는 callback 함수 입니다.', optional: true },
        {
            name: 'onDateButtonClick', type: CommonType.function, description: 'dateButton을 클릭할 때 발생하는 callback 함수 입니다.', optional: true, parameters: {
                name: 'e',
                type: {
                    target: { type: CommonType.any, description: '이벤트가 발생한 컴포넌트의 instance' },
                    cancel: { type: CommonType.any, description: 'dialog 오픈 취소 여부' }
                }
            }
        },
        { name: 'useStatelessStyle', type: CommonType.boolean, optional: true, description: "상태 ( disabled, readonly, required ) 를 가진 경우라도 기본 스타일을 유지합니다." },
        { name: "showWeekday", type: CommonType.boolean, default: false, description: "true 로 지정할 경우 요일이 표시됩니다.", optional: true },
        { name: 'inputStyle', description: 'input의 스타일을 지정하는 속성입니다.', type: ['node'], optional: true },
        CommonDefinitions.tooltip(),
        CommonDefinitions.focus(),
        CommonDefinitions.isEmpty(),
        CommonDefinitions.onValidate(CommonType.any),
        CommonDefinitions.Event(),
    );

    public static defaultProps = {
        disabled: false,
        readonly: false,
        required: false,
        type: Type.single,
        format: FormatType.YYYYMMDD,
        useControlButton: true
    }

    public static Format = FormatType;
    public static Type = Type;
    public static DialogAlign = AlignType;
    public static DialogPosition = PositionType;
    public static Weekday = WeekdayEnum;

    public state: State = {
        hasError: false,
        dateDialogValue: null
    }

    private myRefs = {
        root: React.createRef<HTMLDivElement>(),
        from: React.createRef<DateInput>(),
        to: React.createRef<DateInput>(),
        codePicker: React.createRef<any>()
    }

    static getDerivedStateFromProps(nextProps: IOBTDatePicker, prevState: State): any {
        if (nextProps.value !== prevState._value) {
            return {
                _value: nextProps.value,
                editingContext: undefined
            };
        }
        return null;
    }

    private get value() {
        return this.state.editingContext ? this.state.editingContext : this.props.value;
    }

    private toFromTo = (value: any, type: Type = this.props.type, format: FormatType = this.props.format) => {
        return {
            from: this.getDateString((value && type !== Type.single && typeof value === 'object' ? value.from : value) || '', format),
            to: this.getDateString((value && type !== Type.single && typeof value === 'object' ? value.to : '') || '', format)
        }
    }

    private getDate = (value: any) => {
        if (value) {
            if (typeof value === 'string') {
                value = (value || '').replace(/[^0123456789]/g, '');
                if (value && value.length >= 4) {
                    const date = moment(`${value}0101`.substring(0, 8));
                    return moment(date).isValid() ? moment(date).toDate() : null;
                }
            } else if (typeof value === 'number') {
                return this.getDate(String(value));
            } else if (value instanceof Date) return value;
        }
        return null;
    }

    private getDateString = (value?: any, format?: string) => {
        const date = this.getDate(value);
        return date ? moment(date).format(format || 'YYYYMMDD').substring(0, (this.props.format || 'YYYYMMDD').length) : '';
    }

    private getMax = memoizeOne((max?: any) => {
        const maxDate = new Date(2099, 11, 31);
        const date = this.getDate(max);
        return !date || date > maxDate ? maxDate : date;
    });

    private getMaxString = memoizeOne((max?: any) => {
        return this.getDateString(this.getMax(max));
    });

    private getMin = memoizeOne((min?: string) => {
        const minDate = new Date(0, 0, 1);
        const date = this.getDate(min);
        return !date || date < minDate ? minDate : date;
    });

    private getMinString = memoizeOne((min?: string) => {
        return this.getDateString(this.getMin(min));
    });

    private getWidth = memoizeOne((customInputElement: any, format: FormatType, type: Type, inputStyle?: any) => {
        if (!customInputElement) {
            const inputWidth = (() => {
                let width = 62;
                switch (format) {
                    case FormatType.YYYYMMDD:
                        width = 62;
                        break;
                    case FormatType.YYYYMM:
                        width = 45;
                        break;
                    case FormatType.YYYY:
                        width = 28;
                        break;
                    default:
                        break;
                }

                return inputStyle && inputStyle.width ? inputStyle.width : `${width}px`;
            })();
            switch (format) {
                case FormatType.YYYYMMDD:
                    const weekday = this.props.showWeekday && this.props.format === FormatType.YYYYMMDD ? 22 : 0;
                    if (type === Type.single) {
                        return `calc(35px + ${inputWidth} + ${weekday}px)`;
                    } else {
                        return `calc(54px + ${inputWidth} * 2 + ${weekday}px * 2)`;
                    }
                case FormatType.YYYYMM:
                    if (type === Type.single) {
                        return `calc(40px + ${inputWidth})`;
                    } else {
                        return `calc(52px + ${inputWidth} * 2)`;
                    }
                case FormatType.YYYY:
                    return `calc(37px + ${inputWidth})`;
            }
        } else {
            return undefined;
        }
    });

    getYearMonthDatePickerFromDate = (date, value) => {
        const { min, max } = this.props;
        const currentDate = moment(new Date());
        const initialMinValue = min === undefined ? moment(new Date(1900, 1, 1)).format("YYYYMMDD") : min
        const initialMaxValue = max === undefined ? moment(new Date(2099, 11, 31)).format("YYYYMMDD") : max
        const isBetweenMinMax = currentDate.isBetween(initialMinValue, initialMaxValue)
        const isEmptyDate = (this.getDate(date.from) !== null && value.from === "") || this.getDate(date.from) === null ? true : false

        if (isEmptyDate) {
            if (isBetweenMinMax) {
                return this.getDate(new Date());
            }

            if (isBetweenMinMax === false) {
                if (min !== undefined && max !== undefined) {
                    return this.getMin(min);
                }
                else if (min === undefined && max !== undefined) {
                    return this.getMax(max);
                }
                else if (min !== undefined && max === undefined) {
                    return this.getMin(min);
                }
                else {
                    return this.getDate(new Date());
                }
            }
        }

        return this.getDate(date.from)
    }

    getYearMonthDatePickerToDate = (date, value) => {
        const { min, max } = this.props;
        const currentDate = moment(new Date());
        const initialMinValue = min === undefined ? moment(new Date(1900, 1, 1)).format("YYYYMMDD") : min
        const initialMaxValue = max === undefined ? moment(new Date(2099, 11, 31)).format("YYYYMMDD") : max
        const isBetweenMinMax = currentDate.isBetween(initialMinValue, initialMaxValue)
        const isEmptyDate = (this.getDate(date.to) !== null && value.to === "") || this.getDate(date.to) === null ? true : false

        if (isEmptyDate) {
            if (isBetweenMinMax) {
                return this.getDate(new Date());
            }

            if (isBetweenMinMax === false) {
                if (min !== undefined && max !== undefined) {
                    return this.getMin(min);
                }
                else if (min === undefined && max !== undefined) {
                    return this.getMax(max);
                }
                else if (min !== undefined && max === undefined) {
                    return this.getMin(min);
                }
                else {
                    return this.getDate(new Date());
                }
            }
        }

        return this.getDate(date.to)
    }

    getYearMonthPickerFromDate = (date, value) => {
        const { min, max, format } = this.props;
        const currentDate = moment(new Date());
        const initialMinValue = min === undefined ? moment(new Date(1900, 1, 1)).format("YYYYMM") : min
        const initialMaxValue = max === undefined ? moment(new Date(2099, 11, 31)).format("YYYYMM") : max
        const isBetweenMinMax = currentDate.isBetween(initialMinValue, initialMaxValue);
        const isEmptyDate = (this.getDate(date.from) !== null && value.from === "") || this.getDate(date.from) === null ? true : false

        if (isEmptyDate) {
            if (isBetweenMinMax) {
                return Number(this.getDateString(new Date()));
            }

            if (isBetweenMinMax === false) {
                if (min !== undefined && max !== undefined) {
                    return Number(this.getDateString(this.getMin(min)));
                }
                else if (min === undefined && max !== undefined) {
                    return Number(this.getDateString(this.getMax(max)));
                }
                else if (min !== undefined && max === undefined) {
                    return Number(this.getDateString(this.getMin(min)));
                }
                else {
                    return Number(this.getDateString(new Date()));
                }
            }
        }

        return Number(this.getDateString(date.from, format));
    }

    getYearMonthPickerToDate = (date, value) => {
        const { min, max, format } = this.props;
        const currentDate = moment(new Date());
        const initialMinValue = min === undefined ? moment(new Date(1900, 1, 1)).format("YYYYMM") : min
        const initialMaxValue = max === undefined ? moment(new Date(2099, 11, 31)).format("YYYYMM") : max
        const isBetweenMinMax = currentDate.isBetween(initialMinValue, initialMaxValue);
        const isEmptyDate = (this.getDate(date.to) !== null && value.to === "") || this.getDate(date.to) === null ? true : false

        if (isEmptyDate) {
            if (isBetweenMinMax) {
                return Number(this.getDateString(new Date()));
            }

            if (isBetweenMinMax === false) {
                if (min !== undefined && max !== undefined) {
                    return Number(this.getDateString(this.getMin(min)));
                }
                else if (min === undefined && max !== undefined) {
                    return Number(this.getDateString(this.getMax(max)));
                }
                else if (min !== undefined && max === undefined) {
                    return Number(this.getDateString(this.getMin(min)));
                }
                else {
                    return Number(this.getDateString(new Date()));
                }
            }
        }

        return Number(this.getDateString(date.to, format));
    }

    private getCodePicker = memoizeOne((
        type: Type,
        format: FormatType,
        value: any,
        min?: string,
        max?: string,
        useControlButton?: boolean,
        customLabel?: any,
        required?: boolean,
        dialogAlign?: AlignType,
        dialogPosition?: PositionType,
        allowFullPeriodOnly?: boolean) => {
        const date = this.toFromTo(value, type);
        switch (type) {
            case Type.period:
                switch (format) {
                    case FormatType.YYYYMMDD:
                        return <UFOPeriodDateFieldDialog
                            ref={this.myRefs.codePicker}
                            initialDateFrom={this.getYearMonthDatePickerFromDate(date, value)}
                            initialDateTo={this.getYearMonthDatePickerToDate(date, value)}
                            minDate={this.getMin(min)}
                            maxDate={this.getMax(max)}
                            onAccept={this.handleSetValueCodePicker}
                            onRequestClose={this.handleCloseCodePicker}
                            isWeek={false}
                            align={dialogAlign ? dialogAlign : AlignType.near}
                            position={dialogPosition ? dialogPosition : PositionType.bottom}
                            useControlButton={useControlButton}
                            customLabel={customLabel}
                            required={required}
                            allowFullPeriodOnly={allowFullPeriodOnly}
                        />;
                    case FormatType.YYYYMM:
                        return <UFOPeriodMonthFieldDialog
                            ref={this.myRefs.codePicker}
                            initialDateFrom={this.getYearMonthPickerFromDate(date, value)}
                            initialDateTo={this.getYearMonthPickerToDate(date, value)}
                            minValue={Number(this.getMinString(min))}
                            maxValue={Number(this.getMaxString(max))}
                            onAccept={this.handleSetValueCodePicker}
                            onRequestClose={this.handleCloseCodePicker}
                            align={dialogAlign ? dialogAlign : AlignType.near}
                            position={dialogPosition ? dialogPosition : PositionType.bottom}
                            useControlButton={useControlButton}
                            customLabel={customLabel}
                            required={required}
                            allowFullPeriodOnly={allowFullPeriodOnly}
                        />
                    default:
                        return undefined;
                }
            case Type.single:
                const dateValueSetStringType = this.getDateString(this.state.dateDialogValue)
                switch (format) {
                    case FormatType.YYYYMMDD:
                        return <UFODateFieldDialog
                            ref={this.myRefs.codePicker}
                            initialDate={this.state.dateDialogValue}
                            onAccept={(value: any) => { setTimeout(() => this.handleSetValueCodePicker(value), 0) }}
                            minDate={this.getMin(min)}
                            maxDate={this.getMax(max)}
                            align={dialogAlign ? dialogAlign : AlignType.near}
                            position={dialogPosition ? dialogPosition : PositionType.bottom}
                            useControlButton={useControlButton}
                            customLabel={customLabel}
                            required={required}
                            onKeyDown={(e) => {
                                switch (e.key) {
                                    case 'ArrowRight':
                                        this.setState({
                                            dateDialogValue: moment(this.state.dateDialogValue || new Date()).add(1, 'day').toDate()
                                        })
                                        break;
                                    case 'ArrowLeft':
                                        this.setState({
                                            dateDialogValue: moment(this.state.dateDialogValue || new Date()).add(-1, 'day').toDate()
                                        })
                                        break;
                                    case 'ArrowDown':
                                        this.setState({
                                            dateDialogValue: moment(this.state.dateDialogValue || new Date()).add(1, 'week').toDate()
                                        })
                                        break;
                                    case 'ArrowUp':
                                        this.setState({
                                            dateDialogValue: moment(this.state.dateDialogValue || new Date()).add(-1, 'week').toDate()
                                        })
                                        break;
                                    case 'Escape':
                                    case 'Backspace':
                                        this.setState({
                                            openCodePicker: false
                                        })
                                        break;
                                    case 'Enter':
                                        this.handleSetValueCodePicker(dateValueSetStringType)
                                        break;
                                }
                            }}
                        />;
                    case FormatType.YYYYMM:
                        return <UFOMonthFieldDialog
                            ref={this.myRefs.codePicker}
                            initialDate={Number(this.getDateString(this.state.dateDialogValue))}
                            onAccept={(e: any, value: string) => this.handleSetValueCodePicker(value)}
                            minDate={Number(this.getMin(min))}
                            maxDate={Number(this.getMax(max))}
                            minValue={Number(this.getMinString(min))}
                            maxValue={Number(this.getMaxString(max))}
                            align={dialogAlign ? dialogAlign : AlignType.near}
                            position={dialogPosition ? dialogPosition : PositionType.bottom}
                            useControlButton={useControlButton}
                            customLabel={customLabel}
                            required={required}
                            onKeyDown={(e) => {
                                switch (e.key) {
                                    case 'ArrowRight':
                                        this.setState({
                                            dateDialogValue: moment(dateValueSetStringType || new Date()).add(1, 'month').toDate()
                                        })
                                        break;
                                    case 'ArrowLeft':
                                        this.setState({
                                            dateDialogValue: moment(dateValueSetStringType || new Date()).add(-1, 'month').toDate()
                                        })
                                        break;
                                    case 'ArrowDown':
                                        this.setState({
                                            dateDialogValue: moment(dateValueSetStringType || new Date()).add(4, 'month').toDate()
                                        })
                                        break;
                                    case 'ArrowUp':
                                        this.setState({
                                            dateDialogValue: moment(dateValueSetStringType || new Date()).add(-4, 'month').toDate()
                                        })
                                        break;
                                    case 'Escape':
                                    case 'Backspace':
                                        this.setState({
                                            openCodePicker: false
                                        })
                                        break;
                                    case 'Enter':
                                        this.handleSetValueCodePicker(dateValueSetStringType)
                                        break;
                                }

                            }}
                        />;
                    case FormatType.YYYY:
                        return <OBTYearFieldDialog
                            ref={this.myRefs.codePicker}
                            value={dateValueSetStringType}
                            onAccept={(e: any, value: string) => this.handleSetValueCodePicker(value)}
                            min={this.getMinString(min)}
                            max={this.getMaxString(max)}
                            align={dialogAlign ? dialogAlign : AlignType.near}
                            position={dialogPosition ? dialogPosition : PositionType.bottom}
                            // useControlButton={useControlButton}
                            customLabel={customLabel}
                            required={required}
                            onKeyDown={(e) => {
                                switch (e.key) {
                                    case 'ArrowRight':
                                        this.setState({
                                            dateDialogValue: moment(this.state.dateDialogValue || new Date()).add(1, 'year').toDate().getFullYear().toString()
                                        })
                                        break;
                                    case 'ArrowLeft':
                                        this.setState({
                                            dateDialogValue: moment(this.state.dateDialogValue || new Date()).add(-1, 'year').toDate().getFullYear().toString()
                                        })
                                        break;
                                    case 'ArrowDown':
                                        this.setState({
                                            dateDialogValue: moment(this.state.dateDialogValue || new Date()).add(4, 'year').toDate().getFullYear().toString()
                                        })
                                        break;
                                    case 'ArrowUp':
                                        this.setState({
                                            dateDialogValue: moment(this.state.dateDialogValue || new Date()).add(-4, 'year').toDate().getFullYear().toString()
                                        })
                                        break;
                                    case 'Escape':
                                    case 'Backspace':
                                        this.setState({
                                            openCodePicker: false
                                        })
                                        break;
                                    case 'Enter':
                                        this.handleSetValueCodePicker(dateValueSetStringType)
                                        break;
                                }
                            }}
                        />;
                    default:
                        return undefined;
                }
            case Type.week:
                return <WeekDateDialog
                    ref={this.myRefs.codePicker}
                    locale='ko'
                    open={true}
                    firstDayOfWeek={0}
                    initialDate={(() => {
                        const weekFrom = this.getDate(date.from);
                        return weekFrom ? Weekday.getDate(weekFrom, this.props.weekdayFrom, this.props.weekdayTo).fromDate : null;
                    })()}
                    minDate={this.getMin(min)}
                    maxDate={this.getMax(max)}
                    onTouchTapDay={(value: string) => {
                        const weekday = Weekday.getDate(value, this.props.weekdayFrom, this.props.weekdayTo);
                        return this.handleSetValueCodePicker(weekday.from, weekday.to);
                    }}
                    selectedDateTo={(() => {
                        const weekFrom = this.getDate(date.from);
                        return weekFrom ? Weekday.getDate(weekFrom, this.props.weekdayFrom, this.props.weekdayTo).toDate : null;
                    })()}
                    align={dialogAlign ? dialogAlign : AlignType.near}
                    position={dialogPosition ? dialogPosition : PositionType.bottom}
                    useControlButton={useControlButton}
                    customLabel={customLabel}
                    required={required}
                />;
        }
    });

    private renderWeekday = (value?: string) => {
        if (this.props.showWeekday && this.props.format === FormatType.YYYYMMDD && value && moment(value).isValid()) {
            const weekday = moment(value).day();
            const weekdayString = [
                OrbitInternalLangPack.getText('WE000002637', '일'),
                OrbitInternalLangPack.getText('WE000002638', '월'),
                OrbitInternalLangPack.getText('WE000000392', '화'),
                OrbitInternalLangPack.getText('WE000000393', '수'),
                OrbitInternalLangPack.getText('WE000000394', '목'),
                OrbitInternalLangPack.getText('WE000022770', '금'),
                OrbitInternalLangPack.getText('WE000000396', '토'),
            ][weekday];
            return <span className={Util.getClassNames(classNames.weekday, weekday === 0 ? classNames.sunday : undefined, weekday === 6 ? classNames.saturday : undefined)}>
                {`(${weekdayString})`}
            </span>
        }
        return undefined;
    }

    // component 가 render 될때 호출됨.
    private renderComponent = () => {
        const dateInputProps = {
            className: classNames.dateInput,
            format: this.props.format,
            onChange: this.handleChange,
            readonly: this.props.readonly,
            disabled: this.props.disabled,
            frozen: this.props.frozen,
            required: this.props.required,
            inputStyle: this.props.inputStyle
        };
        const width = this.getWidth(this.props.customInputElement, this.props.format, this.props.type, this.props.inputStyle);
        const date = this.toFromTo(this.value);
        return (
            <div id={this.props.id} data-orbit-component={this.props.dataOrbitComponent || 'OBTDatePicker'}
                className={Util.getClassNames(classNames.root, 'OBTDatePicker', this.props.className)}
                style={Util.getWrapperStyle(this.props)}>
                <OBTTooltip
                    {...this.props.tooltip}
                    className={Util.getClassNames(classNames.root)}
                    style={{ width: width }}
                    overrideSize={false}
                    onClick={this.props.onClick}
                    onMouseDown={this.props.onMouseDown}
                    onMouseMove={this.props.onMouseMove}
                    onMouseUp={this.props.onMouseUp}
                    onMouseLeave={this.props.onMouseLeave}
                    onMouseEnter={this.props.onMouseEnter}
                    onKeyUp={this.props.onKeyUp}
                    onKeyDown={this.props.onKeyDown}
                    onKeyPress={this.props.onKeyPress}>
                    <div ref={this.myRefs.root}
                        className={Util.getClassNames(classNames.wrapper,
                            this.props.disabled ? classNames.disabled :
                                this.props.readonly ? classNames.readonly :
                                    this.props.required ? classNames.required : undefined,
                            this.props.useStatelessStyle ? classNames.stateless : undefined,
                            this.props.customInputElement ? classNames.customInput : undefined,
                            this.state.focus && this.canEdit ? classNames.focus : undefined)}
                        onKeyDownCapture={this.handleKeyDown}
                        tabIndex={-1}
                        onFocus={this.handleRootFocus}
                        onBlur={this.handleRootBlur}
                        onClick={this.handleRootClick}>
                        {this.props.customInputElement ? this.props.customInputElement : <>
                            <DateInput
                                {...dateInputProps}
                                ref={this.myRefs.from}
                                value={this.getDateString(date.from)}
                                onMoveFocus={(direction) => this.handleMoveFocus('from', direction)}
                                size={this.props.type === Type.single ? (() => {
                                    switch (this.props.format) {
                                        case FormatType.YYYYMMDD: return 10;
                                        case FormatType.YYYYMM: return 7;
                                        case FormatType.YYYY: return 4;
                                    }
                                    return undefined;
                                })() : undefined}
                                focused={this.state.focus} />
                            {this.renderWeekday(this.getDateString(date.from))}
                            {this.props.type !== Type.single ? <span className={classNames.fromToSeparator}>~</span> : undefined}
                            {this.props.type !== Type.single ? <DateInput
                                {...dateInputProps}
                                ref={this.myRefs.to}
                                value={this.getDateString(date.to)}
                                onMoveFocus={(direction) => this.handleMoveFocus('to', direction)}
                                focused={this.state.focus} /> : undefined}
                            {this.props.type !== Type.single ? this.renderWeekday(this.getDateString(date.to)) : undefined}
                            {<img className={classNames.icon} src={icon} alt={''} onClick={this.handleOpenCodePicker} style={{
                                pointerEvents: !this.canEdit || this.state.openCodePicker ? 'none' : undefined
                            }} />}
                        </>}
                        {this.state.openCodePicker && this.canEdit ?
                            this.getCodePicker(this.props.type, this.props.format,
                                this.props.type === Type.single ? this.state.dateDialogValue : this.value,
                                this.props.min, this.props.max, this.props.useControlButton,
                                this.props.customLabel, this.props.required,
                                this.props.dialogAlign, this.props.dialogPosition) : undefined}
                    </div>
                </OBTTooltip>
            </div>
        )
    }

    private useShortCut = false;
    render() {
        return (<OBTContext.Consumer>
            {
                value => {
                    this.useShortCut = value.useShortCut;
                    return (<ErrorBoundary owner={this} render={this.renderComponent} />);
                }
            }
        </OBTContext.Consumer>);
    }

    public focus = (isLast: boolean = false): void => {
        if (isLast === true && this.myRefs.to.current) {
            this.myRefs.to.current.focus();
            return;
        }
        if (this.myRefs.from.current) {
            this.myRefs.from.current.focus();
        }
    }

    public blur = (): void => {
        if (document.activeElement && Util.containsFocus(this.myRefs.root)) {
            (document.activeElement as HTMLElement).blur();
        }
    }

    private get canEdit(): boolean {
        return !(this.props.readonly || this.props.disabled || this.props.frozen);
    }

    public isEmpty(): boolean {
        const date = this.toFromTo(this.props.value);
        const expectLength = {
            from: this.props.format.length,
            to: this.props.type === Type.single ? 0 : this.props.format.length
        };
        return date.from.length !== expectLength.from || date.to.length !== expectLength.to;
    }

    public validate(): boolean {
        return !(this.props.required === true && this.isEmpty());
    }

    private handleKeyDown = (e: React.KeyboardEvent) => {
        switch (e.key) {
            case 'Esc':
                if (this.state.openCodePicker) {
                    this.handleCloseCodePicker();
                    e.preventDefault();
                }
                break;
            default:
                if ((this.props.useShortCut === undefined ? this.useShortCut : this.props.useShortCut)) {
                    const shortCut = Util.getShortCut(e);
                    if (shortCut === 'CodePicker') {
                        this.handleOpenCodePicker();
                        e.preventDefault();
                    }
                }
                break;
        }
    }

    private setLastFocus = () => {
        return new Promise<void>(resolve =>
            setTimeout(() => {
                if (this.myRefs.from.current) {
                    this.myRefs.from.current.focus();
                } else if (this.myRefs.root.current) {
                    this.myRefs.root.current.focus();
                }
                resolve();
            }, 0)
        );
    }

    private handleOpenCodePicker = () => {
        let initialDate: Date | string | number = this.getDate(this.value);
        let currentDate: Date | string | number = this.getDate(new Date());

        if (!this.state.openCodePicker) {
            if (Util.invokeEvent<Events.CancelableEventArgs>(this.props.onDateButtonClick, new Events.CancelableEventArgs(this))) {
                this.setState({
                    openCodePicker: true,
                    dateDialogValue: this.value === '' && this.props.type === Type.single ? currentDate : initialDate
                }, () => {
                    if (this.myRefs.codePicker.current) {
                        this.myRefs.codePicker.current.show();
                    }
                    if (this.props.type !== Type.single) {
                        this.setLastFocus();
                    }
                });
            }
        } else {
            this.setLastFocus();
        }
    }

    private handleCloseCodePicker = (options?: {
        state?: any,
        focus?: boolean
    }) => {
        return new Promise<void>((resolve) => {
            this.setState({
                openCodePicker: false,
                ...((options || {}).state)
            }, () => {
                if (!(options && options.focus === false)) {
                    this.setLastFocus();
                }
                if (this.props.onCancel) {
                    this.props.onCancel();
                }
                resolve();
            });
        });
    }

    private handleSetValueCodePicker = (from: any, to?: any) => {
        this.handleCloseCodePicker({
            focus: false
        }).then(() => {
            if (this.handleSetValue({
                from: this.getDateString(from),
                to: this.getDateString(to)
            })) {
                this.setLastFocus()
                    .then(() => {
                        if (this.props.onConfirm) {
                            this.props.onConfirm();
                        }
                        // TODO: 컨디션 패널에서 setState전에 onSearch호출 되는 문제로 임시 제거
                        // setTimeout(() => {
                        //     Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, 'enter'));
                        // }, 0);
                    });
            }
        });
    }

    private handleChange = (target: any, value: string) => {
        const date = this.toFromTo(this.value);

        if (this.props.type === Type.week) {
            const weekday = Weekday.getDate(value, this.props.weekdayFrom, this.props.weekdayTo);
            date.from = weekday.from;
            date.to = weekday.to;
        } else {
            if (target === this.myRefs.from.current) {
                date.from = this.getDateString(value);
                setTimeout(() => {
                    if (this.myRefs.to.current) {
                        this.myRefs.to.current.focus();
                    }
                }, 0)
            } else {
                date.to = this.getDateString(value);
            }
        }

        if (this.handleSetValue(date)) {
            // if (this.props.type === Type.week && target === (this.myRefs.to.current ? this.myRefs.to.current : this.myRefs.from.current)) {
            // setTimeout(() => {
            //     Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, 'enter'));
            // }, 0);
            // }
        }
    }

    private handleSetValue = (date: { from: string, to: string }) => {
        const defaultMin = this.getDateString(this.getMin());
        const defaultMax = this.getDateString(this.getMax());
        const setDefaultMin = this.getMinString(defaultMin);
        const setDefaultMax = this.getMaxString(defaultMax);

        const min = this.getMinString(this.props.min);
        const max = this.getMaxString(this.props.max);

        if (this.props.min) {
            if (date.from && Number(date.from) < Number(min)) date.from = min;
            if (date.to && Number(date.to) < Number(min)) date.to = min;
        }
        if (this.props.max) {
            if (date.from && Number(date.from) > Number(max)) date.from = max;
            if (date.to && Number(date.to) > Number(max)) date.to = max;
        }

        if (date.from && Number(date.from) < Number(defaultMin)) date.from = setDefaultMin;
        if (date.to && Number(date.to) < Number(defaultMin)) date.to = setDefaultMin;
        if (date.from && Number(date.from) > Number(defaultMax)) date.from = setDefaultMax;
        if (date.to && Number(date.to) > Number(defaultMax)) date.to = setDefaultMax;

        if (date.from && date.to && Number(date.from) > Number(date.to)) {
            if (this.props.value && this.props.value.to && this.props.value.to !== date.to) {
                date.from = date.to;
            } else if (this.props.value && this.props.value.from && this.props.value.from !== date.from) {
                date.to = date.from;
            }
        }

        if (this.props.type === Type.week) {
            const weekday = Weekday.getDate(date.from, this.props.weekdayFrom, this.props.weekdayTo);
            date.from = weekday.from;
            date.to = weekday.to;
        }

        const changedValue = this.props.type === Type.single ? date.from : date;
        const empty = (!date.from ? true : false) && (this.props.type === Type.single || !date.to ? true : false);
        const fullPeriod = (date.from ? true : false) && (this.props.type === Type.single || date.to ? true : false);
        if (fullPeriod ||
            (!this.props.required && (empty || !this.props.allowFullPeriodOnly))) {
            if (Util.invokeEvent<Events.ValidateEventArgs<any>>(this.props.onValidate, new Events.ValidateEventArgs(this, this.props.value, changedValue))) {
                Util.invokeEvent<Events.ChangeEventArgs<any>>(this.props.onChange, new Events.ChangeEventArgs<any>(this, changedValue));
                return true;
            }
        } else {
            this.setState({
                editingContext: changedValue
            });
            return false;
        }
    }

    private handleMoveFocus = (source: string, direction: string) => {
        switch (direction) {
            case 'up':
            case 'down':
                Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
                break;
            case 'left':
                if (source === 'to' && this.myRefs.from.current) {
                    this.myRefs.from.current.focus();
                } else {
                    Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
                }
                break;
            case 'right':
            case 'enter':
                if (source === 'from' && this.myRefs.to.current) {
                    this.myRefs.to.current.focus();
                } else {
                    Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
                }
                break;
        }
    }

    private handleRootFocus = (e: React.FocusEvent) => {
        if (e.target === this.myRefs.root.current) {
            if (this.myRefs.from.current) {
                e.preventDefault();
                e.stopPropagation();
                this.myRefs.from.current.focus();
                return;
            }
        }
        if (!this.state.focus) {
            this.setState({
                focus: true
            });
        }
        Util.invokeEvent<Events.FocusEventArgs>(this.props.onFocus, new Events.FocusEventArgs(this, e));
    }

    private handleRootBlur = (e: React.FocusEvent) => {
        setTimeout(() => {
            if (!((this.myRefs.root.current && Util.containsFocus(this.myRefs.root)) ||
                ((this.myRefs.codePicker.current && this.myRefs.codePicker.current.containsFocus())))) {
                if (this.state.focus || this.state.openCodePicker || this.state.editingContext) {
                    this.handleCloseCodePicker({
                        state: { focus: false, editingContext: undefined },
                        focus: false
                    }).then(() => {
                        Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
                    });
                } else {
                    Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
                }
            }
        }, 0);
    }

    private handleRootClick = (e: React.MouseEvent) => {
        if (this.props.customInputElement && !this.state.openCodePicker) {
            this.handleOpenCodePicker();
        }
    }
}