/**
 * OBTComplete2
 * @version 0.1
 * @author 신지유
 * @see common.js
 */

import * as React from 'react';
import { Events, CompositeProps, Util, CommonProps, createPropDefinitions, CommonType, CommonDefinitions } from '../Common';
import debounce from 'lodash.debounce';
import { Scrollbars } from 'react-custom-scrollbars';
import OBTTooltip from '../OBTTooltip';
import OBTFloatingPanel from '../OBTFloatingPanel';
import ArrowDown from "./Images/icon-btn-arrow-down-normal.png";
import ArrowUp from "./Images/icon-btn-arrow-top-normal.png";
import DeleteButton from "./Images/ic_close_01_m_normal.png";
import { IOBTTooltip } from '../OBTTooltip/OBTTooltip';
import GuideMessageRenderer from '../Common/GuideMessageRenderer';
import { OBTContext } from '../OBTPageContainer/OBTPageContainer';

const styles = require("./OBTComplete2.module.scss");

class ClickListEventArgs extends Events.EventArgs {
    constructor(
        target: any,
        public readonly selectedValue: any,
        public readonly selectedIndex: any
    ) {
        super(target);
    }
}

class SelectAddPanelEventArgs extends Events.EventArgs {
    constructor(
        target: any,
        public readonly inputValue: string
    ) {
        super(target);
    }
}

class DeleteListEvents extends Events.EventArgs {
    constructor(
        target: any,
        public readonly value: any
    ) {
        super(target);
    }
}

export enum StrictMode {
    'toEmpty' = 'toEmpty',
    'toBefore' = 'toBefore'
}

interface IOBTComplete2 extends CompositeProps.InputClass<any[]>, CommonProps.placeHolder, Events.onValidate<any[]>, Events.onMouseDown, Events.onMouseMove,
    Events.onMouseUp, Events.onMouseLeave, Events.onMouseEnter, Events.onKeyDown, Events.onKeyPress, Events.onKeyUp, Events.onClick, Events.onMoveFocus {
    /**
     * keyWord 로 검색하는 함수이며 외부에서 작성하여 사용합니다.
     */
    onSearch: (e: string) => Promise<any>,
    /**
     * @default 300
     * 입력이 멈춘 후 부터 검색시까지의 지연시간 입니다. (단위 : ms)
     */
    delayTime?: number,
    /**
     * 리턴값을 columnWidths과 itemInfo를 가지는 함수입니다. 데이터를 어떻게 보여줄 것인지 정할 수 있습니다. 
     * 1. columWidths : 하나의 column(행) 들어가는 텍스트 공간(열)의 크기를 조절할 수 있습니다. 
     * ex) 이메일 부분을 넓게 보여주고 싶다면 20 20 60 으로 넣으면 이메일 텍스트 공간의 크기가 커집니다. 
     * 
     * 2. itemInfo : 
     * - key : 아이템의 key
     * - column : column의 순서(예제: 이름 - 회사명 - 이메일)
     * - isKeyValue: 텍스트 필드에 보여지는 값입니다. 현재는 name 이므로 리스트 선택시 텍스트 필드에 이름이 들어갑니다
     */
    dataInfo: {
        columnWidths: number[],
        itemInfo: {
            key: string,
            codeProperty?: boolean,
            textProperty?: boolean
        }[]
    },
    /**
     * @default left
     * dropDownList에서 텍스트 정렬을 설정할 수 있습니다.
     */
    textAlign: "left" | "center" | "right",
    /**
     * 드롭다운을 열 수 있는 버튼을 생성할지에 대한 여부입니다.
     */
    hasDropDownButton?: boolean,
    /**
     * 드롭다운 리스트들에 tooltip을 달 것인지에 대한 여부입니다.
     */
    isShowTooltip?: boolean,
    /**
     * tooltip
     */
    tooltip?: IOBTTooltip,
    /**
     * 값이 어떻게 매핑될지에 대한 모드 설정
     * - StrictMode.toBefore : 직전에 선택한 값으로 다시 선택됩니다.
     * - StrictMode.toEmpty : 필드에 남아있는 문자를 비웁니다.
     */
    isStrictMode?: StrictMode,
    /**
     * 데이터를 추가하는 버튼이 생기는지에 대한 여부를 지정합니다.
     */
    canAdd?: boolean,
    /**
     * 사용자가 입력한 값을 등록하는 버튼을 클릭했을 때 호출되는 콜백함수입니다.
     */
    onAdd?: (e: SelectAddPanelEventArgs) => void,
    /**
     * 항목 삭제여부를 지정할 수 있습니다. (우측에 X 버튼 생성)
     */
    canDelete?: boolean,
    /**
     * 데이터를 삭제할 때 호출되는 콜백함수입니다.
     */
    onDelete?: (e: DeleteListEvents) => boolean,
    /**
      * dropDown을 누를 때 호출되는 Callback 함수입니다.
      */
    onDropDownListClick?: (e: ClickListEventArgs) => void,
}

interface IState {
    inputValue: string,
    dropDownOpen: boolean,
    dataAddPanelOpen: boolean,
    selectedDropDownIndex: number,
    correctKeyword?: string,
    searchData?: any[],
    deletedValue?: any[]
}

export default class OBTComplete2 extends React.Component<IOBTComplete2, IState> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.InputClass(CommonType.any),
        {
            name: "dataInfo",
            type: [{
                columnWidths: { type: 'string[]' },
                itemInfo: { type: ['{ key: string, codeProperty: boolean, textProperty?: boolean }'] }
            }],
            description: '검색할 때 나타나는 데이터들의 정보입니다.'
                + '\n * key : string, 사용할 컬럼의 이름'
                + '\n * codeProperty : 사용자에게 보여지는 값을 설정할때 가장 주가 되는 codeProperty ("코드.네임" 에서 코드에 속함)'
                + '\n * textProperty? : 사용자에게 보여지는 값을 설정할때 보조가 되는 textProperty ("코드.네임" 에서 네임에 속함).'
        },
        { name: 'textAlign', type: ['left', 'center', 'right'], description: 'dropDownList에서 텍스트 정렬을 할 수 있습니다.' },
        { name: 'hasDropDownButton', type: CommonType.boolean, description: '드롭다운을 열 수 있는 버튼을 생성할지에 대한 여부입니다.' },
        { name: 'isShowTooltip', type: CommonType.boolean, description: '드롭다운 리스트들에 tooltip을 달 것인지에 대한 여부입니다.' },
        CommonDefinitions.tooltip(),
        CommonDefinitions.placeHolder(),
        CommonDefinitions.InnerFunc(),
        CommonDefinitions.onValidate(CommonType.any),
        {
            name: 'onSearch', type: CommonType.function, parameters: {
                name: 'e',
                type: CommonType.string
            }, result: 'Promise<any>', description: 'keyWord 로 검색하는 함수입니다.(외부작성 필요)'
        },
        {
            name: 'onDropDownListClick', type: CommonType.function, parameters: {
                name: 'e',
                type: [{
                    target: { type: ['this'] },
                    selectedValue: { type: CommonType.any, description: '선택한 값' },
                    selectedIndex: { type: CommonType.any, description: '선택한 인덱스' }
                }]
            }, description: 'dropDown을 누를 때 호출되는 Callback 함수입니다.'
        },
        { name: 'delayTime', type: CommonType.number, optional: true, description: '타이핑이 끝난 후 검색된 리스트가 나오기 전까지의 지연시간을 설정할 수 있습니다.', default: false },
        { name: 'canAdd', type: CommonType.boolean, optional: true, description: '팝오버 리스트의 헤더에 추가 버튼 표시 여부를 지정할 수 있습니다.', default: false },
        { name: 'canDelete', type: CommonType.boolean, optional: true, description: '항목 삭제여부를 지정할 수 있습니다.', default: false },
        { name: 'onAdd', type: CommonType.function, parameters: { name: 'e', type: CommonType.function }, description: '사용자가 입력한 값을 등록하는 버튼을 클릭했을 때 호출되는 콜백함수입니다.', optional: true },
        { name: 'onDelete', type: CommonType.function, parameters: { name: 'e', type: CommonType.function }, description: '데이터 삭제 버튼을 클릭하면 호출되는 Callback 함수입니다.', optional: true },
        {
            name: 'isStrict', type: ['toBefore', 'toEmpty'], optional: true, description: '데이터에 존재하지 않는 값이 입력 되면, onblur이벤트에서 이전 값으로 되돌리는 속성입니다.'
                + '\n * StrictEnum'
                + '\n 1. toBefore : 무효한 값이 입력되면 이전 값으로 되돌립니다.'
                + '\n 2. toEmpty : 무효한 값이 입력되면 빈 값으로 설정합니다.'
        },
    )
    context!: React.ContextType<typeof OBTContext>;

    public static defaultProps: Partial<IOBTComplete2> = {
        delayTime: 300,
        width: "300px",
        textAlign: "left",
        isStrictMode: StrictMode.toEmpty
    }

    public state: IState = {
        inputValue: "",
        dropDownOpen: false,
        dataAddPanelOpen: false,
        selectedDropDownIndex: this.props.canAdd ? -1 : 0,
        correctKeyword: "",
        searchData: [],
    }

    myRefs = {
        completeBox: React.createRef<HTMLSpanElement>(),
        scrollBar: React.createRef<Scrollbars>(),
        dropDownListRef: React.createRef<HTMLLIElement>(),
        itemRef: React.createRef<HTMLParagraphElement>(),
        inputRef: React.createRef<HTMLInputElement>()
    }

    componentDidUpdate(prevProps: IOBTComplete2, prevState: IState) {
        if (prevProps.delayTime !== this.props.delayTime) {
            this.showSearchedData = this.createShowSearchedData();
        }
    }

    private createShowSearchedData = () => {
        this.showSearchedData = debounce((value: string) => {
            this.props.onSearch(value).then((data) => {
                if (data && data.length > 0 && value && value.length > 1) {
                    this.setState({
                        searchData: data,
                        dropDownOpen: true,
                        selectedDropDownIndex: this.props.canAdd ? -1 : 0
                    }, () => {
                        if (this.state.searchData) {
                            this.setState({
                                correctKeyword: value
                            });
                        }
                    });
                } else {
                    this.setState({
                        dropDownOpen: false,
                        searchData: [],
                        selectedDropDownIndex: this.props.canAdd ? -1 : 0
                    })
                }
            })
        }, this.props.delayTime);
        return this.showSearchedData;
    }

    private showSearchedData = this.createShowSearchedData();

    private updateDropdownScrollTop = () => {
        if (this.state.searchData &&
            this.state.selectedDropDownIndex &&
            this.state.searchData &&
            this.myRefs.scrollBar.current) {

            if (this.state.selectedDropDownIndex === -1 && !this.props.canAdd) {
                this.myRefs.scrollBar.current.scrollTop = 27 * this.state.searchData.length - 1;
                this.setState({
                    selectedDropDownIndex: this.state.searchData.length - 1
                });
            }

            else if (this.state.selectedDropDownIndex === this.state.searchData.length) {
                this.myRefs.scrollBar.current.scrollTop = 0;
                this.setState({
                    selectedDropDownIndex: this.props.canAdd ? -1 : 0
                })
            }

            else {
                const scrollTop = Math.floor(this.myRefs.scrollBar.current.scrollTop);
                const dropDownWrapperHeight = 270;
                const itemHeight = 27;
                const currentItemHeight = itemHeight && itemHeight * this.state.selectedDropDownIndex;

                if (
                    currentItemHeight &&
                    Math.abs(scrollTop - currentItemHeight) >= 270 &&
                    scrollTop + dropDownWrapperHeight <= currentItemHeight) {
                    this.myRefs.scrollBar.current.scrollTop = scrollTop + dropDownWrapperHeight;
                } else if (currentItemHeight && scrollTop > currentItemHeight) {
                    this.myRefs.scrollBar.current.scrollTop = scrollTop - dropDownWrapperHeight;
                }
            }
        }
    }

    private showInputText = (data: any): string => {
        let isCodeProperty, isTextProperty = "";

        const properties = Object.keys(data);

        this.props.dataInfo.itemInfo.forEach((itemInfo) => {
            if (itemInfo.codeProperty) {
                isCodeProperty = itemInfo.key
            }
            else if (itemInfo.textProperty) {
                isTextProperty = itemInfo.key
            }
            return;
        });

        const showText = isCodeProperty && isTextProperty ? `${data[isCodeProperty]}. ${data[isTextProperty]}` :
            isCodeProperty && !isTextProperty ? `${data[isCodeProperty]}` :
                !isCodeProperty && isTextProperty ? `${data[isTextProperty]}` :
                    `${data[properties[0]]}`

        return showText;
    }

    private selectItem = (data: any) => { 
        let isValid = true;

        if (this.props.onDropDownListClick) {
            Util.invokeEvent<ClickListEventArgs>(this.props.onDropDownListClick, new ClickListEventArgs(this, data, this.state.selectedDropDownIndex));
        }

        if (Util.invokeEvent<Events.ValidateEventArgs<any[]>>(this.props.onValidate, new Events.ValidateEventArgs(this, this.props.value, data))) {
            Util.invokeEvent<Events.ChangeEventArgs<any[]>>(
                this.props.onChange,
                new Events.ChangeEventArgs<any[]>(this, data)
            );
        }

        else {
            isValid = false;

            if (this.props.isStrictMode === StrictMode.toEmpty) {
                Util.invokeEvent<Events.ChangeEventArgs<any[]>>(
                    this.props.onChange,
                    new Events.ChangeEventArgs<any[]>(this, [])
                );
            }
        }

        this.setState({
            inputValue: isValid ? this.showInputText(data) : "",
            dropDownOpen: false,
            correctKeyword: "",
            dataAddPanelOpen: false,
            deletedValue: []
        });
    }

    private addItemFromPanel = () => {
        if (this.props.canAdd && this.props.onAdd) {
            Util.invokeEvent<SelectAddPanelEventArgs>(this.props.onAdd, new SelectAddPanelEventArgs(this, this.state.inputValue));
        }
        this.setState({
            dropDownOpen: false,
            dataAddPanelOpen: false
        });
    }

    public focus(): void {
        if (!this.props.frozen && !this.props.disabled && this.myRefs.inputRef.current) {
            this.myRefs.inputRef.current.focus();
        }
    }

    public blur(): void {
        if (!this.props.frozen && !this.props.disabled && this.myRefs.inputRef.current) {
            this.myRefs.inputRef.current.blur();
        }
    }

    public isEmpty(): boolean {
        return (!this.props.value || this.props.value.length <= 0);
    }

    public validate(): boolean {
        return !(this.props.required === true && this.isEmpty());
    }

    private handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.value.length !== 0) {
            if (this.state.deletedValue !== this.props.value) {
                this.setState({
                    deletedValue: this.props.value
                });
            }
        }

        this.setState({
            inputValue: e.target.value
        });
        this.showSearchedData(e.target.value);

        if (this.props.canAdd) {
            if (e.target.value.length > 1) {
                this.setState({
                    dataAddPanelOpen: true
                })
            }
            else {
                this.setState({
                    dataAddPanelOpen: false
                })
            }
        }
    }

    private handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.nativeEvent.isComposing === true) return;

        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();

                if (this.state.dropDownOpen &&
                    this.state.searchData &&
                    this.state.selectedDropDownIndex < this.state.searchData.length) {
                    this.setState((state) => {
                        return {
                            selectedDropDownIndex: state.selectedDropDownIndex + 1
                        }
                    }, () => {
                        this.updateDropdownScrollTop();
                    });
                }
                else {
                    this.handleMoveFocus('down');
                }
                break;
            case 'ArrowUp':
                e.preventDefault();

                if (this.state.dropDownOpen &&
                    this.state.searchData &&
                    this.state.selectedDropDownIndex > -1) {
                    this.setState((state) => {
                        return {
                            selectedDropDownIndex: state.selectedDropDownIndex - 1
                        }
                    }, () => {
                        this.updateDropdownScrollTop();
                    });
                }
                else {
                    this.handleMoveFocus('up');
                }
                break;
            case 'Backspace':
                if (this.props.value.length === 0) return;

                this.setState({
                    deletedValue: this.props.value
                }, () => {
                    if (this.state.inputValue.indexOf(".") !== -1) {
                        this.setState({
                            inputValue: "",
                            correctKeyword: "",
                        });
                    }
                    return;
                });
                break;
            case 'Enter':
                if (this.state.selectedDropDownIndex === -1) {
                    this.addItemFromPanel();
                }
                else if (this.state.searchData && this.state.searchData.length > 0 && this.state.selectedDropDownIndex !== -1) {
                    const selectedItem = this.state.searchData[this.state.selectedDropDownIndex];
                    selectedItem && this.selectItem(selectedItem);
                    setTimeout(() => this.handleMoveFocus('enter'), 0);
                }
                else {
                    this.handleMoveFocus('enter');
                }
                break;
            case 'ArrowRight':
                e.preventDefault();
                this.handleMoveFocus('right');
                break;
            case 'ArrowLeft':
                e.preventDefault();
                this.handleMoveFocus('left');
                break;
        }
    }

    private handleInputBlur = () => {
        // 1. isStrictMode = StrictMode.toBefore
        if (this.props.isStrictMode && this.props.isStrictMode === StrictMode.toBefore && this.state.deletedValue) {
            // 값이 없을 경우
            if (this.props.value.length === 0) {
                const { deletedValue } = this.state;
                const showText = this.showInputText(deletedValue);
                this.setState({
                    inputValue: showText
                });

                Util.invokeEvent<Events.ChangeEventArgs<any[]>>(
                    this.props.onChange,
                    new Events.ChangeEventArgs<any[]>(this, deletedValue)
                );
            }

            else { // onValidate 에서 e.cancel = true 로 주었을 경우 (props.value가 존재하는 상태)
                const { value } = this.props;
                const showText = this.showInputText(value);
                // 블러했을 때 값이 바뀌지 않은 상태이면(inputValue와 기존의 this.props.value가 동일한 상태)
                if (showText !== this.state.inputValue) { 
                    this.setState({
                        inputValue: showText
                    });
                    Util.invokeEvent<Events.ChangeEventArgs<any[]>>(
                        this.props.onChange,
                        new Events.ChangeEventArgs<any[]>(this, value)
                    );
                }
            }
        }

        // 2. toEmpty = StrictMode.toEmpty
        else if (this.props.isStrictMode && this.props.isStrictMode === StrictMode.toEmpty &&
            (this.props.value.length === 0 || (this.props.value === this.state.deletedValue)) &&
            this.state.inputValue !== "") {
            this.setState({
                inputValue: ""
            });
            Util.invokeEvent<Events.ChangeEventArgs<any[]>>(
                this.props.onChange,
                new Events.ChangeEventArgs<any[]>(this, [])
            );
        }

        this.setState({
            selectedDropDownIndex: this.props.canAdd ? -1 : 0,
            dropDownOpen: false,
            dataAddPanelOpen: false,
            searchData: [],
        });

        Util.invokeEvent<Events.EventArgs>(this.props.onBlur, new Events.EventArgs(this));
        GuideMessageRenderer.handleBlur(
            this.context,
            this.props.id,
        );
    }

    private handleInputFocus = () => {
        Util.invokeEvent<Events.EventArgs>(this.props.onFocus, new Events.EventArgs(this));
        GuideMessageRenderer.handleFocus(
            this, this.props.id, this.context
        );
    }

    private handleDropDownMouseMove = (index: number) => {
        this.setState({
            selectedDropDownIndex: index
        });
    }

    private handleDropDownClick = (data: any) => {
        this.selectItem(data);
    }

    private handleDropDownMouseDown = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.preventDefault();
    }

    private handleDropDownButtonClick = () => {
        if (this.state.dropDownOpen) {
            this.setState({
                dropDownOpen: false
            });
            return;
        }

        this.props.onSearch("").then((dropDownList) => {
            this.setState({
                searchData: dropDownList,
                dropDownOpen: true,
            })
        });
    }

    private handleDropDownButtonBlur = () => {
        if (this.state.dropDownOpen) {
            this.setState({
                dropDownOpen: false
            });
        }
    }

    private handleDeleteButtoClick = (e: React.MouseEvent<HTMLImageElement, MouseEvent>, data: any) => {
        e.stopPropagation();
        Util.invokeEvent<DeleteListEvents>(this.props.onDelete, new DeleteListEvents(this, data));
        if (this.props.onDelete) {
            const result = this.props.onDelete(new DeleteListEvents(this, data));
            if (result) {
                this.props.onSearch(this.state.inputValue).then((dropDownList) => {
                    this.setState({
                        searchData: dropDownList,
                        dropDownOpen: true,
                    });
                });
            }
        };
    }

    private handleAddPanelMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
    }

    private handleAddPanelMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.setState({
            selectedDropDownIndex: -1
        });
    }

    private handleAddPanelClick = (e) => {
        this.addItemFromPanel();
    }

    private handleMoveFocus = (direction: string) => {
        if (this.props.required && this.isEmpty() && ['right', 'down', 'enter', 'up'].includes(direction)) {
            return;
        }
        Util.invokeEvent<Events.MoveFocusEventArgs>(this.props.onMoveFocus, new Events.MoveFocusEventArgs(this, direction));
    }

    renderLi = (data: any, index: number) => {
        let dropDownList: any;
        const properties = Object.keys(data);

        dropDownList = properties.map((p, i) => {
            const columnWidth = this.props.dataInfo.columnWidths[i] + "%"
            return (
                <div
                    key={i}
                    style={{
                        width: columnWidth,
                        textAlign: this.props.textAlign ? this.props.textAlign : "left"
                    }}
                    className={styles.dropDownListText}
                >
                    {p === 'img' ?
                        <img
                            src={data[p]}
                            alt={"imageProperty"}
                        />
                        : this.renderText(data[p], i)
                    }
                </div>
            )
        })
        return dropDownList;
    }

    renderText = (data: any, index: number) => {
        let dropDownItem: any;

        if (this.state.correctKeyword) {
            dropDownItem = data.split(this.state.correctKeyword).map((str, i, arr) => {
                return (
                    <React.Fragment key={i}>
                        <span>{str}</span>
                        {i < arr.length - 1 ?
                            <span style={{ color: 'rgb(72,143,243)' }}>
                                {this.state.correctKeyword}
                            </span> : undefined
                        }
                    </React.Fragment>
                )
            })
        } else {
            dropDownItem = (
                <span>{data}</span>
            )
        }
        return dropDownItem;
    }

    render() {
        const inputElement = (
            <span
                className={Util.getClassNames(
                    styles.wrapperSpan,
                    this.props.disabled && styles.disabled,
                    this.props.readonly && styles.readonly,
                    this.props.required && styles.required
                )}
                ref={this.myRefs.completeBox}
            >
                <input
                    type="text"
                    value={this.state.inputValue}
                    className={Util.getClassNames(
                        styles.wrapperInput,
                        this.props.disabled && styles.disabled,
                        this.props.readonly && styles.readonly,
                    )}
                    ref={this.myRefs.inputRef}
                    placeholder={this.props.placeHolder}
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleInputKeyDown}
                    onBlur={this.handleInputBlur}
                    onFocus={this.handleInputFocus}
                    disabled={this.props.disabled}
                    readOnly={this.props.readonly}
                />
                {
                    this.props.hasDropDownButton &&
                    <button
                        className={Util.getClassNames(
                            styles.dropDownButton,
                            this.props.disabled && styles.disabled
                        )}
                        onClick={this.handleDropDownButtonClick}
                        onBlur={this.handleDropDownButtonBlur}
                        disabled={this.props.disabled || this.props.readonly}
                    >
                        <img
                            alt='button'
                            src={this.state.dropDownOpen ? ArrowUp : ArrowDown}
                            className={Util.getClassNames(
                                styles.dropDownButtonImg,
                                this.props.disabled && styles.disabled,
                                this.props.required && styles.required
                            )}
                        />
                    </button>
                }
            </span>
        );

        const dataAddPanel = (
            <div
                className={Util.getClassNames(styles.dataAddPanel)}
                style={{
                    backgroundColor: this.state.selectedDropDownIndex === -1 ? '#eff7ff' : undefined,
                    width: this.props.width ? this.props.width : "300px"
                }}
                onMouseMove={this.handleAddPanelMouseMove}
                onMouseDown={this.handleAddPanelMouseDown}
                onClick={this.handleAddPanelClick}
            >
                <span className={styles.plusIcon} />
                {this.state.inputValue}(으)로 등록
            </div>
        );

        const dropDownElement = (
            <div
                ref={this.myRefs.scrollBar}
                className={Util.getClassNames(styles.dropDown, this.props.canAdd && styles.dropDownWithAddPanel)}
                style={{
                    width: this.props.width ? this.props.width : "300px",
                    height: this.state.searchData && this.state.searchData.length < 10 ?
                        `${this.state.searchData.length * 27}px` : "270px"
                }}
            >
                <ul>
                    {this.state.searchData && this.state.searchData.map((data, index) => {
                        const tooltipLabel = () => {
                            const entireText = [] as any
                            let key = 0;
                            for (let property in data) {
                                key += 1;
                                if (property !== "img") {
                                    entireText.push(<p key={key}>{data[property]}</p>);
                                }
                            }
                            return entireText;
                        }

                        const deleteButton = (
                            <img
                                src={DeleteButton}
                                alt={DeleteButton}
                                className={styles.deleteButton}
                                onClick={(e) => this.handleDeleteButtoClick(e, data)}
                            />
                        )

                        const dropDownListElement = (
                            <li
                                key={index}
                                style={{
                                    backgroundColor: this.state.selectedDropDownIndex === index ? '#eff7ff' : undefined
                                }}
                                className={Util.getClassNames(styles.dropDownList, this.props.canDelete && styles.dropDownWithDeleteButton)}
                                ref={this.myRefs.dropDownListRef}
                                onMouseMove={debounce((e) => {
                                    this.handleDropDownMouseMove(index)
                                }, 40)}
                                onMouseDown={this.handleDropDownMouseDown}
                                onClick={(e) => this.handleDropDownClick(data)}
                            >
                                {this.renderLi(data, index)}
                                {this.props.canDelete && deleteButton}
                            </li>
                        );

                        return (
                            this.props.isShowTooltip ?
                                <OBTTooltip
                                    key={index}
                                    value={this.state.selectedDropDownIndex === index ? true : false}
                                    labelText={tooltipLabel()}
                                    className={styles.tooltip}
                                    overrideSize={false}
                                    focusValue={false}
                                >
                                    {dropDownListElement}
                                </OBTTooltip> : dropDownListElement
                        )
                    })}
                </ul>
            </div>
        );

        return (
            <div
                id={this.props.id}
                data-orbit-component={'OBTComplete2'}
                className={Util.getClassNames(
                    styles.wrapper,
                    this.props.className
                )}
            >
                <OBTTooltip {...this.props.tooltip}
                    style={Util.getWrapperStyle(this.props)}
                    onMoveFocus={(e) => this.handleMoveFocus(e.direction)}
                    overrideSize={false}
                    onClick={this.props.onClick}
                    onMouseDown={this.props.onMouseDown}
                    onMouseMove={this.props.onMouseMove}
                    onMouseUp={this.props.onMouseUp}
                    onMouseLeave={this.props.onMouseLeave}
                    onMouseEnter={this.props.onMouseEnter}
                    onKeyUp={this.props.onKeyUp}
                    onKeyPress={this.props.onKeyPress}
                    onKeyDown={this.props.onKeyDown}
                >
                    {inputElement}
                </OBTTooltip>

                <OBTFloatingPanel
                    value={true}
                    anchor={this.myRefs.completeBox}
                    position={OBTFloatingPanel.Position.bottom}
                    align={OBTFloatingPanel.Align.near}
                >
                    {this.props.canAdd && this.state.dataAddPanelOpen && dataAddPanel}
                    {this.state.dropDownOpen && this.state.searchData && this.state.searchData.length > 0 && dropDownElement}
                </OBTFloatingPanel>
            </div>
        )
    }
}
//테스트