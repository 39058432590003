import React from 'react'
import { OBTConfirm } from '../index';
import { Events } from '../Common';
import uuid from 'uuid/v1';

interface IState {
    key?: string,
    type?: string,
    cancelText?: string,
    confirmText?: string,
    labelText?: string,
    onConfirm?: (e: Events.EventArgs) => void,
    onCancel?: (e: Events.EventArgs) => void
}

export default class Confirm extends React.Component<{}, IState> {
    state: IState = {}

    public setConfirm(type?: string, cancelText?: string, confirmText?: string, labelText?: string, onConfirm?: (e: Events.EventArgs) => void, onCancel?: (e: Events.EventArgs) => void) {
        return new Promise<void>((resolve) => {
            this.setState({
                key: uuid(),
                type,
                cancelText,
                confirmText,
                labelText,
                onConfirm,
                onCancel
            }, () => {
                resolve()
            });
        })
    }

    public close() {
        return new Promise<void>(resolve => {
            this.setState({
                key: undefined
            }, () => resolve());
        });
    }

    render() {
        return (
            this.state.key ? <OBTConfirm key={this.state.key} type={this.state.type as any} labelText={this.state.labelText} cancelText={this.state.cancelText} confirmText={this.state.confirmText}
                onConfirm={this.handleConfirm.bind(this)} onCancel={this.handleConfirmCancel.bind(this)} /> : <></>
        );
    }

    handleConfirm(e: Events.EventArgs) {
        const handleConfirm = this.state.onConfirm;
        this.setState({
            key: undefined
        }, () => {
            if (handleConfirm) {
                handleConfirm(new Events.EventArgs(this));
            }
        });
    }

    handleConfirmCancel(e: Events.EventArgs) {
        const handleCancel = this.state.onCancel;
        this.setState({
            key: undefined
        }, () => {
            if (handleCancel) {
                handleCancel(new Events.EventArgs(this));
            }
        });
    }
}
