/**
 * Component OBTAccordion
 * Luna - Orbit 개발시 템플릿 으로 사용.
 * @version 0.1
 * @author 전주빈
 * @see common.js
 */
import * as React from 'react';
import { Events, CompositeProps, Util, CommonProps, createPropDefinitions, CommonDefinitions, CommonType, toEnumType } from '../Common';
import { LUXAccordion } from 'luna-rocket/LUXAccordion';
import { hasError } from '../Common/CommonState';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { OBTAccordionSection } from '.';
import { OBTAccordion2 } from "../OBTAccordion2"

/**
 * CSS Modules 사용방식
 * styles.[className]
 * {@code <div className={styles.required}}
 */
const stylesAccordion = require('./OBTAccordion.module.scss');

enum AccordionType {
    'default' = 'default',
    'large' = 'large'
}

export interface IStateLabel {
    labelText?: string,
    color?: string
}

interface IAccordion extends CompositeProps.Default, CommonProps.disabled, CommonProps.labelText, Events.onFocus {
    type?: AccordionType,
    /**
     * 컴포넌트의 열리고 닫힘의 설정입니다. true시 오픈, false시 닫힘
     */
    value?: boolean,
    /**
     * 컴포넌트에서 입력된 값이 변경될 때 발생하는 Callback 함수입니다.
     */
    onChange?: (e: Events.ChangeEventArgs<boolean>) => void,
    /**
     * 컴포넌트 요소 안에 들어가는 버튼 요소를 지정합니다.
     */
    button?: any,
    /**
     * 컴포넌트 요소에 보여지는 내용의 아이콘을 지정합니다.
     */
    imageUrl?: any,
    /**
     * 아코디언이 접힌 경우 표시할 imageUrl 입니다.
     */
    collapsedImageUrl?: any,
    /**
     * 아코디언이 펼쳐져 있는 경우 표시할 imageUrl 입니다.
     */
    expandedImageUrl?: any,
    /**
     * 상태를 우측에 표시하는 경우 이 항목을 지정합니다.
     */
    state?: IStateLabel
    /**
     * 버튼 삽입 아코디언시 구분선 사용 여부를 지정합니다.
     */
    useSeparator?: boolean,
    /**
     * 새롭게 제작된 OBTAccordion2 컴포넌트로 대체할 때 사용되는 속성입니다. 
     * true = OBTAccordion 사용 | false = OBTAccordion2 사용
     */
    useOldVersion?: boolean,
    /**
     * key를 지정합니다.
     */
    key?: string | number
}

interface State extends hasError {
    oldValue: boolean,
    value: boolean
}

/**
 * withApi() HOC 를 사용하면 Props 로 Api 를 사용할 수 있다.
 * api 가 Optional 로 선언되었기에 내부에서 ! 오퍼레이터를 사용해서 호출한다.
 * {@code this.props.api!.test();}
 */
export default class OBTAccordion extends React.Component<IAccordion, State> {
    public static PropDefinitions = createPropDefinitions(
        CommonDefinitions.Default(),
        { name: 'key', type: ['number','string'], optional: true, description: 'key를 지정합니다.' },
        CommonDefinitions.disabled(),
        CommonDefinitions.value({ type: CommonType.boolean, optional: true, description: '컴포넌트의 열리고 닫힘의 설정입니다. true시 오픈, false시 닫힘.' }),
        CommonDefinitions.labelText({ description: '아코디언의 타이틀에 표시되는 문구입니다.' }),
        { name: 'button', type: CommonType.any, optional: true, description: '아코디언 타이틀 우측 버튼을 추가합니다.' },
        { name: 'useSeparator', type: CommonType.boolean, default: true, optional: true, description: '아코디언에 버튼 사용시 구분선 사용여부를 지정합니다.' },
        { name: 'imageUrl', type: CommonType.image, optional: true, description: '아코디언 타이틀 좌측 아이콘을 추가합니다.' },
        { name: 'expandedImageUrl', type: CommonType.image, optional: true, description: '아코디언이 펼쳐진경우 imageUrl 을 지정합니다. (없을 경우 imageUrl을 사용합니다.)' },
        { name: 'collapsedImageUrl', type: CommonType.image, optional: true, description: '아코디언이 닫힌경우 imageUrl 을 지정합니다. (없을 경우 imageUrl을 사용합니다.)' },
        {
            name: 'state', type: {
                labelText: { type: CommonType.string, description: '상태표시 문구' },
                color: { type: CommonType.color, description: '상태컬러(HEX)' }
            }, optional: true, description: '컴포넌트 우측에 상태라벨을 표시할 수 있습니다.'
        },
        CommonDefinitions.onChange({ optional: true, description: '아코디언이 접히거나 펼쳐지는 경우 발생하는 Callback 함수입니다.' }),
        CommonDefinitions.onFocus(),
        { name: 'type', type: toEnumType(AccordionType), default: 'default', optional: true, description: '아코디언의 타이틀 크기유형' },
        {
            name: 'useOldVersion',
            type: 'boolean',
            default: true,
            description: "OBTAccordion2로 대체하여 렌더링할 수 있는 옵션입니다."
                + "\ntrue : 기존 OBTAccordion 렌더링"
                + "\nfalse : OBTAccordion2로 렌더링"
            ,
            optional: true
        },
    );
    private static luiName = 'Accordion';
    public static Type = AccordionType;

    public static defaultProps = {
        disabled: false,
        frozen: false,
        useSeparator: true,
        useOldVersion: true
    }

    public state: State = {
        oldValue: this.props.value === undefined ? false : this.props.value,
        value: this.props.value === undefined ? false : this.props.value,
    }

    public myRefs = {
        ref: React.createRef<LUXAccordion>()
    }

    /**
     * component 의 prop 이 변경되었을 경우 state 가 변경되어야 할때 호출된다.
     * @param {json} nextProps
     * @param {json} prevProps
     * @returns {json} 변경될 state / 갱신이 불필요 할경우 null
     * {@code static getDerivedStateFromProps(nextProps, prevState) {
     *     let state = {};
     *     if (prevState.value !== nextProps.value) {
     *         state = { value: nextProps.value };
     *     }
     *
     *     return (Object.keys(state) || 0).length <= 0 ? null : state;
     * }}
     */
    static getDerivedStateFromProps(nextProps: IAccordion, prevState: State): any {
        try {
            if (nextProps.value !== prevState.oldValue) {
                return {
                    oldValue: nextProps.value,
                    value: nextProps.value
                }
            }
            return null;
        } catch (e) {
            return Util.getErrorState(e);
        }
    }

    /**
     * @internal
     * Luna - Rocket 의 TouchTap 이벤트 핸들러를 받아 재호출
     * { target, open value값 }
     * @param {string} direction
     */
    private onChange = (event: any, value: boolean) => {
        this.setState({ value: value }, () => {
            if (this.props.onChange)
                Util.invokeEvent<Events.ChangeEventArgs<boolean>>(this.props.onChange, new Events.ChangeEventArgs<boolean>(this, value));
        })
    }

    render() {
        if (this.props.useOldVersion === false) {
            return (
                <OBTAccordion2
                    id={this.props.id}
                    key={this.props.key}
                    frozen={this.props.frozen}
                    className={this.props.className}
                    width={this.props.width}
                    height={this.props.height}
                    disabled={this.props.disabled}
                    value={this.props.value === undefined ? false : this.props.value}
                    labelText={this.props.labelText}
                    button={this.props.button}
                    useSeparator={this.props.useSeparator}
                    imageUrl={this.props.imageUrl}
                    expandedImageUrl={this.props.expandedImageUrl}
                    collapsedImageUrl={this.props.collapsedImageUrl}
                    stateLabel={this.props.state}
                    onChange={this.props.onChange}
                    type={this.props.type}
                >
                    {this.props.children}
                </OBTAccordion2>
            )
        }
        return (
            <ErrorBoundary owner={this} render={this.renderComponent} />
        );
    }

    renderComponent = () => {
        const imageUrl = this.state.value === true && this.props.expandedImageUrl ? this.props.expandedImageUrl :
            this.state.value === false && this.props.collapsedImageUrl ? this.props.collapsedImageUrl :
                this.props.imageUrl;
        const labelIcon = imageUrl ? <img src={imageUrl} alt={''} className={Util.getClassNames(stylesAccordion.icon, this.props.type === AccordionType.large ? stylesAccordion.large : undefined)}></img> : undefined;
        const usingSection = this.props.children && React.Children.toArray(this.props.children).find(child => React.isValidElement(child) && child.type === OBTAccordionSection) ? true : false;
        const itemStyle = {
            display: 'flex',
            alignItems: 'center',
            height: this.props.type === AccordionType.large ? '40px' : '32px',
            lineHeight: '12px',
            fontSize: '13px',
            fontFamily: 'inherit',
            fontWeight: this.state.value ? 'bold' : 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: this.props.disabled === true ? '#a6a6a6' : (this.state.value ? '#1c90fb' : '#4a4a4a'),
            padding: '0px 0px 0px 10px',
            borderTop: '2px solid #666',
            borderBottom: '1px #dcdbdc',
            borderBottomStyle: 'solid',
            background: 'transparent'
        }
        const contentStyle = {
            position: 'relative',
            background: 'transparent',
            padding: usingSection ? '0px' : '14px 10px',
            height: this.props.height,
            borderTop: '0px',
            borderBottom: '1px #dcdbdc',
            borderBottomStyle: 'solid'
        }
        const button = this.props.button && this.props.useSeparator ? <div className={stylesAccordion.buttonsWrap}>{this.props.button}</div> :
            this.props.button ? this.props.button :
                this.props.state ? <div className={stylesAccordion.stateLabel} style={{ backgroundColor: this.props.state.color }}>{this.props.state.labelText}</div> :
                    undefined;

        const accordionDefault =
            <LUXAccordion
                ref={this.myRefs.ref}
                open={this.state.value}
                disabled={this.props.disabled}
                innerButton={button}
                label={this.props.labelText}
                labelIcon={labelIcon}
                onTouchTap={this.onChange}
                contentStyle={Object.assign({}, contentStyle)}
                itemStyle={itemStyle}
            >
                <>
                    {this.props.children}
                </>
            </LUXAccordion>

        return (
            <div id={this.props.id} className={Util.getClassNames(stylesAccordion.default,
                imageUrl !== undefined ? stylesAccordion.iconstyle : undefined,
                imageUrl !== undefined && this.props.type === AccordionType.large ? stylesAccordion.large : undefined,
                this.props.className)} data-orbit-component='OBTAccordion'
                key={this.props.key}
            >
                {accordionDefault}
                <div style={{ display: 'none' }}>{!this.state.value ? this.props.children : <></>}</div>
            </div >
        )
    }
};

