/**
 * 하나의 이벤트를 구독하는 복수의 이벤트 핸들러를 관리한다.
 */
export class ChaningEvent<T> {

    /**
     * @internal
     */
    private _events: T[] = [];

    /**
     * 
     */
    private _keyEventCache: Map<String, T> = new Map<String, T>();

    /**
     * 등록된 이벤트 핸들러 목록
     */
    public get events() {
        return this._events;
    }

    /**
     * @internal
     */
    public addToFirst(event: T) {
        this._events = [event].concat(this._events)
    }

    /**
     * event 핸들러를 추가한다
     * @param event 
     */
    public add(event: T, key?: string) {
        if (!this._events.includes(event)) {
            this._events.push(event);

            if (key) {
                this._keyEventCache.set(key, event);
            }
        }
    }

    /**
     * 이벤트 핸들러를 제거한다.
     * @param event 
     */
    public remove(event: T) {
        const index = this._events.indexOf(event);
        if (index >= 0) {
            this._events.splice(index, 0);
        }
    }

    /**
     * 이벤트 핸들러를 제거한다.
     * @param event 
     */
    public removeByKey(key: string) {
        const event = this._keyEventCache.get(key);

        if (event) {
            this._keyEventCache.delete(key);
            this.remove(event);
        }
    }

    /**
     * 이벤트 목록을 매개변수 이벤트 핸들러로 대체한다.
     * @param event 
     */
    public set(event: T | null) {
        this._events = event ? [event] : [];
    }
}
