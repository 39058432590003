import { OBTDataGridInterface } from "..";
import * as DataGridEvents from '../OBTDataGridEvents';
import { OBTListGridInterface } from "../../OBTListGrid";
import * as ListGridEvents from "../../OBTListGrid/OBTListGridEvents";
import { IColumn as DataGridIColumn, ColumnType } from "../IColumn";
import { IColumn as ListGridIColumn } from "../../OBTListGrid/IColumn";
import { IRealGridCellDynamicStyleOption } from "../RealGridCellStyleOption";
import IColumnBuilder from "./IColumnBuilder";
import GridUtil from "./GridUtil";

export interface IImageDataByTextValue {
    columnName: string;
    value: string;
    imageDataUrl: string;
}

interface IImageLabelColumnValue{
    columnName: string;
    value: string;
    dataUrlPromise: Promise<string>;
    canvasElement: HTMLElement
}

/**
 * OBTDataGrid, OBTListGrid 이미지 라벨 처리 클래스
 */
export default class GridImageLabelProcessor implements IColumnBuilder {
    private _interface: OBTDataGridInterface | OBTListGridInterface;

    /** 
     * @internal
     * 컬럼의 값에 매핑되는 이미지 정보를 캐싱하는 필드 
     *  */
    private _imageDataByTextValue: IImageDataByTextValue[] = [];

    /**
     * 생성자
     * @param gridInterface 
     */
    constructor(gridInterface: OBTDataGridInterface | OBTListGridInterface) {
        this._interface = gridInterface;
        
        this._interface.onAfterRead.add(this.handleAfterRead);
        if (this._interface instanceof OBTDataGridInterface) {
            this._interface.onAfterDataChanged.add(this.handleAfterDataChanged);
            this._interface.onAfterChange.add(this.handleAfterChange)
            this._interface.onAfterChangePageNumber.add(this.handleChangePageNumber)
        }
    }

    /**
     * 컬럼 설정
     * @param realGridColumn 
     * @param column 
     */
    public buildColumn = (realGridColumn: any, column: DataGridIColumn | ListGridIColumn) => {
        // 이미지 라벨
        if (column.useImageLabel === true) {
            realGridColumn.imageList = column.name + '_image';
            realGridColumn.renderer = Object.assign(realGridColumn.renderer, {
                type: "icon",
                textVisible: column.onlyIcon === true ? false : true,
                showTooltip: column.useTooltip !== undefined ? column.useTooltip : false
            });

            realGridColumn.dynamicStyles = GridUtil.mergeDynamicStyles(realGridColumn.dynamicStyles, (grid, index, value) => {
                const result = this._imageDataByTextValue.filter((item) =>
                    item.columnName === index.column && item.value === value);

                if (result.length > 0) {
                    return {
                        iconIndex: result[0].imageDataUrl,
                        iconAlignment: 'center',
                        iconLocation: 'center',
                    } as IRealGridCellDynamicStyleOption
                } else {
                    return {
                        iconIndex: -1
                    } as IRealGridCellDynamicStyleOption
                }
            });
        }

        // 컬러 아이콘
        let columnAsDataGridColumn = column as DataGridIColumn;
        if (columnAsDataGridColumn && columnAsDataGridColumn.useColorIcon === true) {
            realGridColumn.imageList = column.name + '_image';
            realGridColumn.renderer = Object.assign(realGridColumn.renderer, {
                type: "icon",
                textVisible: column.onlyIcon === true ? false : true,
                showTooltip: column.useTooltip !== undefined ? column.useTooltip : false
            });

            realGridColumn.dynamicStyles = GridUtil.mergeDynamicStyles((grid, index, value) => {
                const result = this._imageDataByTextValue.filter((item) =>
                    item.columnName === index.column && item.value === value);

                if (result.length > 0) {
                    return {
                        iconIndex: result[0].imageDataUrl,
                        iconAlignment: 'far',
                        iconLocation: 'right',
                    } as IRealGridCellDynamicStyleOption
                } else {
                    return {
                        iconIndex: -1
                    } as IRealGridCellDynamicStyleOption
                }
            }, realGridColumn.dynamicStyles);
        }
        return realGridColumn;
    }

    /**
     * 데이터그리드 데이터 변경 이벤트 콜백
     * @param e 
     */
    private handleAfterChange = (e: DataGridEvents.AfterChangeEventArgs) => { 
        const dataGridInterface = this._interface as OBTDataGridInterface;
        if (!dataGridInterface) {
            return;
        }

        const targetColumn = dataGridInterface.getColumnByName(e.columnName);
        if (targetColumn && (targetColumn.useImageLabel === true || targetColumn.useColorIcon === true) && e.newValue && e.newValue.length > 0) {
            let promiseAndValue: IImageLabelColumnValue;
            const canvas = this.setImageLabelColumnCanvas(targetColumn, e.newValue);

            promiseAndValue = {
                columnName: targetColumn.name,
                value: e.newValue,
                dataUrlPromise: Promise.resolve(canvas.toDataURL()),
                canvasElement: canvas
            };

            promiseAndValue.dataUrlPromise.then(dataUrl => {
                if (this._imageDataByTextValue.some(item => item.columnName === targetColumn.name && item.value === e.newValue) === false) {
                    this._imageDataByTextValue.push({
                        columnName: targetColumn.name,
                        imageDataUrl: dataUrl,
                        value: e.newValue
                    });
                }
            });
        }
    }

    private handleChangePageNumber = (e: DataGridEvents.AfterPageNumberChangeArgs) => {
        if (!(this._interface instanceof OBTDataGridInterface)) {
            return;
        }

        let imageLabelColumnList = this._interface.getFlatColumns(true).filter((item) => item.useImageLabel === true || item.useColorIcon === true || item.customImageLabelCallback !== undefined);
        imageLabelColumnList = imageLabelColumnList.concat(this._interface.getFlatColumns(true).filter((item) => item.useColorIcon === true));

        if (imageLabelColumnList.length > 0) {
            if (imageLabelColumnList.length > 0) {
                const promiseAndValue: IImageLabelColumnValue[] = [];

                imageLabelColumnList.forEach((column) => {
                    if (e.appendedData && e.appendedData.length > 0) {
                        const uniqueValueList = new Set<string>(e.appendedData.map(item => item[column.name]));
                        uniqueValueList.forEach((value) => {
                            if (!value || value.length === 0) {
                                return;
                            }

                            if (this._imageDataByTextValue.some(item => column.name === item.columnName && value === item.value)) {
                                return;
                            }

                            const canvas = this.setImageLabelColumnCanvas(column, value);
                            promiseAndValue.push({
                                columnName: column.name,
                                value: value,
                                dataUrlPromise: Promise.resolve(canvas.toDataURL()),
                                canvasElement: canvas
                            });
                        });
                    }
                });

                Promise.all(promiseAndValue.map(item => item.dataUrlPromise)).then(urlList => {
                    urlList.forEach((dataUrl, index) => {
                        const targetItem = promiseAndValue[index];
                        const newItem = {
                            columnName: targetItem.columnName,
                            imageDataUrl: dataUrl,
                            value: targetItem.value
                        };

                        this._imageDataByTextValue.push(newItem);
                    });
                }).finally(() => {
                    this._interface.refresh();
                });
            }
        }
    }
    /**
     * 조회후 이벤트 콜백 
     * @param e 
     */
    private handleAfterRead = (e: DataGridEvents.AfterReadEventArgs | ListGridEvents.AfterReadEventArgs) => {
        let imageLabelColumnList = this.findImageLabelColumns();
        if (imageLabelColumnList.length > 0) {
            const promiseAndValue: IImageLabelColumnValue[] = [];

            imageLabelColumnList.forEach((column) => {
                if (e.data && e.data.length > 0) {
                    const uniqueValueList = new Set<string>(e.data.map(item => item[column.name]));
                    uniqueValueList.forEach((value) => {
                        if (!value || value.length === 0) {
                            return;
                        }
                        const canvas = this.setImageLabelColumnCanvas(column, value);
                        promiseAndValue.push({
                            columnName: column.name,
                            value: value,
                            dataUrlPromise: Promise.resolve(canvas.toDataURL()),
                            canvasElement: canvas
                        });
                    });
                }
            });

            Promise.all(promiseAndValue.map(item => item.dataUrlPromise)).then(urlList => {
                urlList.forEach((dataUrl, index) => {
                    const targetItem = promiseAndValue[index];
                    const newItem = {
                        columnName: targetItem.columnName,
                        imageDataUrl: dataUrl,
                        value: targetItem.value
                    };

                    this._imageDataByTextValue.push(newItem);
                });
            }).finally(() => {
                this._interface.refresh();
            });
        }
    }
    /**
     * 데이터가 change 되고 나서 콜백 
     * @param e 
     */
    private handleAfterDataChanged = (e: DataGridEvents.AfterDataChangeEventArgs | ListGridEvents.AfterDataChangeEventArgs) => { // TOLIST : 리스트 그리드도 한번 확인해볼 필요가 있다.
        let imageLabelColumnList = this.findImageLabelColumns();
        if (imageLabelColumnList.length > 0) {
            const promiseAndValue: IImageLabelColumnValue[] = [];

            imageLabelColumnList.forEach((column) => {
                const getValues = this._interface.getRows().map((row) => {
                    return row[column.name]
                });
                const uniqueValueList = Array.from(new Set(getValues));
                uniqueValueList.forEach((value) => {
                    if (!value || value.length === 0) {
                        return;
                    }
                    const canvas = this.setImageLabelColumnCanvas(column, value);
                    promiseAndValue.push({
                        columnName: column.name,
                        value: value,
                        dataUrlPromise: Promise.resolve(canvas.toDataURL()),
                        canvasElement: canvas
                    });
                });
            });

            Promise.all(promiseAndValue.map(item => item.dataUrlPromise)).then(urlList => {
                urlList.forEach((dataUrl, index) => {
                    const targetItem = promiseAndValue[index];
                    const newItem = {
                        columnName: targetItem.columnName,
                        imageDataUrl: dataUrl,
                        value: targetItem.value
                    };

                    this._imageDataByTextValue.push(newItem);
                });
            }).finally(() => {
                this._interface.refresh();
            });
        }
    }

    /**
     * 초기화
     */
    public reset() {
        this._imageDataByTextValue = [];
    }

    private findImageLabelColumns = () => {
        let imageLabelColumnList: DataGridIColumn[] | ListGridIColumn[] = [];
        if (this._interface instanceof OBTDataGridInterface) {
            imageLabelColumnList = this._interface.getFlatColumns(true).filter((item) => item.useImageLabel === true || item.useColorIcon === true || item.customImageLabelCallback !== undefined);
            imageLabelColumnList = imageLabelColumnList.concat(this._interface.getFlatColumns(true).filter((item) => item.useColorIcon === true));
        } else {
            imageLabelColumnList = this._interface.getFlatColumns(true).filter((item) => item.useImageLabel === true || item.customImageLabelCallback !== undefined);
        } 
        return imageLabelColumnList;
    }

    private setImageLabelColumnCanvas = (column, value) => {
        let canvas: HTMLCanvasElement;
        if (column.customImageLabelCallback) {
            canvas = column.customImageLabelCallback(value, column.imageLabelBackgroundColor);
        } else if (column.useColorIcon) {
            canvas = ImageLabelCanvasTemplate.getDefaultColorLabel(value);
        } else {
            let valueText = null;
            if (column.type === ColumnType.dropDown) {
                if (column.dropDownDataItems) {
                    let dropDownObject = column.dropDownDataItems.filter((item) => {
                        return item[column.dropDownCodeProperty!] === value
                    });
                    if (dropDownObject && dropDownObject.length > 0) {
                        valueText = dropDownObject[0][column.dropDownTextProperty!]
                    }
                }
            }
            canvas = ImageLabelCanvasTemplate.getDefaultImageLabel(value, valueText ? valueText : value, column.imageLabelBackgroundColor);
        }
        return canvas;
    }
}
//#region 이미지 라벨에서 사용하는 캔버스관련 함수

export const ImageLabelCanvasTemplate = {
    getDefaultColorLabel: (
        backgroundColor?: string
    ): HTMLCanvasElement => {
        const canvas = document.createElement('canvas');
        canvas.width = 13; //62//52;
        canvas.height = 13;//18;
        canvas.style.border = '1px solid red'

        var ctx = canvas.getContext("2d");
        if (ctx) {
            if (backgroundColor) {
                ctx.fillStyle = backgroundColor;
                ctx.strokeStyle = backgroundColor;
            } else {
                ctx.fillStyle = '#20C997';
                ctx.strokeStyle = '#20C997';
            }

            // ImageLabelCanvasTemplate.roundRect(ctx, 0, 0, canvas.width, canvas.height, {
            //     tl: 10,
            //     tr: 10,
            //     bl: 10,
            //     br: 10,
            // }, false, false);
            ctx.beginPath();
            ctx.rect(0, 0, 13, 13);

            ctx.fill();
            ctx.stroke();
        }

        return canvas;
    },

    getDefaultImageButton: (type: 'up' | 'down' | 'hover', contents: {
        width: number,
        height: number,
        labelText: string,
    }) => {
        const canvas = document.createElement('canvas');
        canvas.width = contents.width; //40;
        canvas.height = contents.height; //22;

        const ctx = canvas.getContext('2d');
        if (ctx) {
            // 사각형 렌더링
            if (type === 'up') {
                ctx.fillStyle = '#f9f9f9';

            } else if (type === 'down') {
                let grd = ctx.createLinearGradient(0, 0, 0, 5);
                grd.addColorStop(0, 'white');
                grd.addColorStop(1, "#f9f9f9");

                ctx.fillStyle = grd;
            } else if (type === 'hover') {
                let grd = ctx.createLinearGradient(0, 0, 0, 5);
                grd.addColorStop(0, '#f9f9f9');
                grd.addColorStop(1, "white");

                ctx.fillStyle = grd;
            }

            ctx.strokeStyle = "#8c8c8c";
            ImageLabelCanvasTemplate.roundRect(ctx, 0, 0, canvas.width, canvas.height, 3, true, true);

            // 텍스트 렌더링
            ctx.fillStyle = "#000000";
            ctx.font = 'normal 11px arial';
            ctx.textBaseline = 'middle';
            ctx.textAlign = "center";
            ctx.fillText(contents.labelText, canvas.width / 2, canvas.height / 2);
        }

        return canvas;
    },

    getDefaultImageLabel: (
        value: string,
        labelText: string,
        backgroundColor?: ((value: string) => string) | string
    ): HTMLCanvasElement => {
        const canvas = document.createElement('canvas');
        canvas.width = 60; //62//52;
        canvas.height = 18;//18;
        canvas.style.border = '1px solid red'

        var ctx = canvas.getContext("2d");
        if (ctx) {
            const textWidth = ctx.measureText(labelText).width;
            if (labelText.length > 0 && textWidth > canvas.width - 12) {
                ctx.fillStyle = "#FFFFFF";
                ctx.font = "11px 'NSKR','돋움',Dotum,Helvetica,'Apple SD Gothic Neo',sans-serif";
                ctx.textBaseline = 'top';
                ctx.textAlign = "center";

                //canvas 사이즈 재조정
                canvas.width = textWidth + (labelText.length * 2);
            }

            if (backgroundColor) {
                if (typeof backgroundColor === 'string') {
                    ctx.fillStyle = backgroundColor;
                } else if (typeof backgroundColor === 'function') {
                    ctx.fillStyle = backgroundColor(value) || '#20C997';
                } else if (Array.isArray(backgroundColor)) {
                    const filter = Array.of(backgroundColor).filter(item => item['value'] === value);
                    if (filter.length > 0) {
                        ctx.fillStyle = filter[0]['backgroundColor'];
                    }
                }
            } else {
                ctx.fillStyle = '#20C997';
            }

            ImageLabelCanvasTemplate.roundRect(ctx, 0, 0, canvas.width, canvas.height, canvas.height / 2, false, false);

            // 그림자 넣고 싶은때
            // ctx.shadowColor = '#898';
            // ctx.shadowBlur = 20;
            // ctx.shadowOffsetX = 2;
            // ctx.shadowOffsetY = 2;

            ctx.fill();

            ctx.fillStyle = "#FFFFFF";

            ctx.font = "11px 'NSKR','돋움',Dotum,Helvetica,'Apple SD Gothic Neo',sans-serif";
            ctx.textBaseline = 'middle';
            ctx.textAlign = "center";

            ctx.fillText(labelText, canvas.width / 2, canvas.height / 2);
        }

        return canvas;
    },


    roundRect: (ctx, x: number, y: number, width: number, height: number, radius: any, fill: boolean, stroke?: boolean) => {
        if (typeof stroke === 'undefined') {
            stroke = true;
        }
        if (typeof radius === 'undefined') {
            radius = 5;
        }
        // if (typeof radius === 'number') {
        //     radius = { tl: radius, tr: radius, br: radius, bl: radius };
        // } else {
        //     var defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
        //     for (var side in defaultRadius) {
        //         radius[side] = radius[side] || defaultRadius[side];
        //     }
        // }

        // radius = height / 2;
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.arcTo(x + width, y, x + width, y + height, radius);
        ctx.arcTo(x + width, y + height, x, y + height, radius);
        ctx.arcTo(x, y + height, x, y, radius);
        ctx.arcTo(x, y, x + width, y, radius);
        ctx.closePath();

        // ctx.beginPath();
        // ctx.moveTo(x + radius.tl, y);
        // ctx.lineTo(x + width - radius.tr, y);
        // ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
        // ctx.lineTo(x + width, y + height - radius.br);
        // ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
        // ctx.lineTo(x + radius.bl, y + height);
        // ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
        // ctx.lineTo(x, y + radius.tl);
        // ctx.quadraticCurveTo(x, y, x + radius.tl, y);
        // ctx.closePath();
        if (fill) {
            ctx.fill();
        }
        if (stroke) {
            ctx.stroke();
        }
    },

    /**
     * #5379cb, #656565
     */
    getUCImageLabel: (labelText: string, figureColor: string = '#5379cb') => {
        const canvas = document.createElement('canvas');
        canvas.width = 60; //62//52;
        canvas.height = 20;//18;
        canvas.style.border = '1px solid red'

        var ctx = canvas.getContext("2d");
        if (ctx) {
            const textWidth = ctx.measureText(labelText).width;
            canvas.width = textWidth + (labelText.length) + 12;

            // if (labelText.length > 0 && textWidth > canvas.width - 6) {
            //     ctx.fillStyle = figureColor;
            //     ctx.font = "11px 'NSKR','돋움',Dotum,Helvetica,'Apple SD Gothic Neo',sans-serif";
            //     ctx.textBaseline = 'top';
            //     ctx.textAlign = "center";

            //     //canvas 사이즈 재조정
            // }

            // ctx.scale(2, 1);

            ctx.fillStyle = '#ffffff';
            ctx.strokeStyle = figureColor;
            ctx.lineJoin = 'bevel';
            ctx.lineWidth = 1;

            ImageLabelCanvasTemplate.roundRect(ctx, 1, 1, canvas.width - 2, canvas.height - 2, 9, false, true);

            // 그림자 넣고 싶은때
            // ctx.shadowColor = '#898';
            // ctx.shadowBlur = 20;
            // ctx.shadowOffsetX = 2;
            // ctx.shadowOffsetY = 2;

            // ctx.fill();
            // ctx.stroke();

            ctx.fillStyle = figureColor;

            ctx.font = "11px 'NSKR','돋움',Dotum,Helvetica,'Apple SD Gothic Neo',sans-serif";
            ctx.textBaseline = 'middle';
            ctx.textAlign = "center";

            ctx.fillText(labelText, canvas.width / 2, canvas.height / 2);
        }

        return canvas;
    }
}

//#endregion