/* global RealGridJS */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import * as Examples from './example';
import Documents from './playground/Documents';
import MainIntro from './playground/app/MainIntro';
import { CookiesProvider } from 'react-cookie';

const styles = require('./index.module.scss');
RealGridJS.setRootContext("./static/realgrid/");

const examples = Object.keys(Examples).map((key) => {
    if (key.startsWith('OBT') || key.startsWith('Template') || key.startsWith('ECharts') || key.startsWith('Multilingual') || key.startsWith('Example')) {
        return {
            name: key,
            class: (Examples[key].prototype.info || {}).class || 'Etc',
            component: Examples[key]
        }
    } else return null;
}).filter(notNull => notNull);

const groups = [];
examples.forEach(example => {
    if (!groups.includes(example.class)) groups.push(example.class);
});

class Playground extends React.Component {
    state = {
        selectedCompo: ''
    }

    componentDidMount() {
        // 처음 URL에 주소를 입력했을 경우
        this.setSelectedCompo()
    }

    render() {
        return (
            <div className={styles.root}>
                <Router>
                    <Switch>
                        <Route path={'/Documents'} component={Documents} />
                        <Route exact path={'/'} component={MainIntro} />
                    </Switch>
                </Router>
            </div>
        );
    }

    setSelectedCompo = (name = null) => {
        setTimeout(() => {
            this.setState({ selectedCompo: name === null ? document.location.href.substring(document.location.href.lastIndexOf('/') + 1) : name })
        }, 0)
    }
}

ReactDOM.render(
    <CookiesProvider>
        <Playground />
    </CookiesProvider>, 
document.getElementById('OrbitWrap'));